/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { errorMessageViewed } from "../../../../config/typewriter";
import PocInfoItemStyle from "./PocInfoItemStyle";
import Data from "../../../../components/data/Data";
import ExpandableData from "../../../../components/expandableData/ExpandableData";
import { ANALYTICS_ROUTE_NAMES, LINK_CALL } from "../../../../config/constants";

interface Props {
  titleId: string;
  text?: string;
  items?: Array<string>;
  testId: string;
  dispatchEvent: (
    event: any,
    config: Record<string, unknown>,
    optionalData?: any,
  ) => void;
}
const PocInfoItem: React.FunctionComponent<Props> = ({
  titleId,
  text,
  testId,
  items,
  dispatchEvent,
}) => {
  const { t } = useTranslation();
  const classes = PocInfoItemStyle();
  const title = () => {
    return t(titleId);
  };

  useEffect(() => {
    const shouldDispatchById = () => {
      if (
        testId === "poc-info-phone-item" ||
        testId === "poc-info-email-item"
      ) {
        return items?.length === 0;
      }
      return !text;
    };

    if (shouldDispatchById()) {
      dispatchEvent(
        errorMessageViewed,
        {
          error_message: "No data available",
          error_type: testId,
          screen_name: ANALYTICS_ROUTE_NAMES.get(LINK_CALL),
          screen_section: "POC Details",
          is_all_products: null,
          DDC: null,
          DDC_name: null,
          poc_id: null,
          total_gap: null,
          total_vol_gap: null,
        },
        { time_of_day: true, is_resumed: true },
      );
    }
  }, [dispatchEvent, items, testId, text]);

  return (
    <Grid
      container
      direction="column"
      data-testid={testId}
      className={classes.container}
    >
      <div data-testid="poc-info-item-title" className={classes.title}>
        {title()}
      </div>
      {items ? (
        <ExpandableData items={items} testId="poc-info-item-value" />
      ) : (
        <Data
          value={text}
          className={classes.value}
          testId="poc-info-item-value"
        />
      )}
    </Grid>
  );
};
export default React.memo(PocInfoItem);
