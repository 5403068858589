import { createStyles, createTheme, makeStyles } from "@material-ui/core";

export const cardTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: "black",
        backgroundColor: "rgba(0, 0, 0, 0.0)",
        paddingBottom: "14px",
        maxWidth: "366px",
      },
      tooltipPlacementRight: {
        marginLeft: "-16px !important",
        paddingLeft: "0px",
      },
      tooltipPlacementLeft: {
        marginRight: "-16px !important",
        paddingRight: "0px",
      },
    },
  },
});

const monetary = {
  color: "#176D38",
};

const item = {
  padding: "0px",
  marginTop: "8px",
  marginBottom: "8px",
  maxWidth: "90px",
  fontSize: 14,
  fontFamily: "Work Sans",
  justifyContent: "center",
  lineHeight: "20px",
};

export const useDiscountsRangeInfoStyle = makeStyles(() =>
  createStyles({
    constainer: {
      paddingRight: "30px",
      margin: "16px 24px",
      width: "auto",
      justifyContent: "space-between",
    },
    itemHeader: {
      ...item,
      fontWeight: "bold",
      textAlign: "right",
    },
    itemDiv: {
      justifyContent: "space-between",
    },
    itemQuantity: {
      ...item,
      textAlign: "right",
    },
    itemDiscount: {
      ...item,
      ...monetary,
      textAlign: "right",
    },
    itemPrice: {
      ...item,
      ...monetary,
      fontWeight: "bold",
      textAlign: "right",
    },
  }),
);
