import * as React from "react";

function Marketplace() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="-26%"
          x2="50%"
          y2="122.277%"
          id="prefix__c"
        >
          <stop stopColor="#FF0" offset="0%" />
          <stop stopColor="#EEEB00" offset="100%" />
        </linearGradient>
        <ellipse
          id="prefix__a"
          cx={24.671}
          cy={23.438}
          rx={24.671}
          ry={23.438}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1.645 1.563)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <g mask="url(#prefix__b)" fill="url(#prefix__c)">
            <path d="M0 0h49.342v46.875H0z" />
          </g>
        </g>
        <g fillRule="nonzero">
          <path
            d="M42.067 19.09l-1.202-1.14v-3.452c0-.624-.535-1.13-1.194-1.13H36.09c-.66 0-1.194.506-1.194 1.13v3.453l-1.202 1.138c-1.278 1.21-1.981 2.82-1.981 4.532v.453l6.167 6.785h4.974c.66 0 1.194-.506 1.194-1.13V23.62c0-1.7-.713-3.33-1.981-4.532z"
            fill="#BEE75E"
          />
          <path
            d="M42.067 19.09l-1.202-1.14v-3.452c0-.624-.535-1.13-1.194-1.13h-1.79v17.491h4.973c.66 0 1.194-.506 1.194-1.13V23.62c0-1.7-.713-3.33-1.981-4.532z"
            fill="#00CB75"
          />
          <path
            d="M40.865 11.483c0-.625-.535-1.131-1.194-1.131H36.09c-.66 0-1.194.506-1.194 1.13v3.016h5.969v-3.015z"
            fill="#AF8663"
          />
          <path
            d="M40.069 29.728v-4.523c0-.625-.535-1.131-1.194-1.131h-7.162v5.654c0 .625.534 1.131 1.193 1.131h5.97c.658 0 1.193-.506 1.193-1.13z"
            fill="#FFD7A0"
          />
          <path
            d="M38.875 30.86c.66 0 1.194-.507 1.194-1.132v-4.523c0-.625-.535-1.131-1.194-1.131h-.995v6.785h.995z"
            fill="#FFB042"
          />
          <path
            d="M40.865 11.483c0-.625-.535-1.131-1.194-1.131h-1.79v4.146h2.984v-3.015z"
            fill="#896645"
          />
          <path
            d="M29.704 19.09l-1.194-1.14v-3.452c0-.624-.531-1.13-1.186-1.13h-3.558c-.655 0-1.186.506-1.186 1.13v3.453l-1.195 1.138c-1.27 1.21-1.968 2.82-1.968 4.532v.453l6.128 6.785h4.942c.655 0 1.186-.506 1.186-1.13V23.62c0-1.7-.708-3.33-1.969-4.532z"
            fill="#BEE75E"
          />
          <path
            d="M29.704 19.09l-1.194-1.14v-3.452c0-.624-.531-1.13-1.186-1.13h-1.78v17.491h4.943c.655 0 1.186-.506 1.186-1.13V23.62c0-1.7-.708-3.33-1.969-4.532z"
            fill="#00CB75"
          />
          <path
            d="M28.51 11.483c0-.625-.531-1.131-1.186-1.131h-3.558c-.655 0-1.186.506-1.186 1.13v3.016h5.93v-3.015z"
            fill="#AF8663"
          />
          <path
            d="M27.72 29.728v-4.523c0-.625-.532-1.131-1.187-1.131h-7.116v5.654c0 .625.53 1.131 1.186 1.131h5.93c.655 0 1.186-.506 1.186-1.13z"
            fill="#FFD7A0"
          />
          <path
            d="M26.533 30.86c.655 0 1.186-.507 1.186-1.132v-4.523c0-.625-.53-1.131-1.186-1.131h-.988v6.785h.988z"
            fill="#FFB042"
          />
          <path
            d="M28.51 11.483c0-.625-.531-1.131-1.186-1.131h-1.78v4.146h2.966v-3.015z"
            fill="#896645"
          />
          <path
            d="M18.938 19.09l-1.202-1.14v-3.452c0-.624-.535-1.13-1.194-1.13H12.96c-.66 0-1.194.506-1.194 1.13v3.453l-1.202 1.138c-1.278 1.21-1.982 2.82-1.982 4.532v.453l6.168 6.785h4.974c.66 0 1.194-.506 1.194-1.13V23.62c0-1.7-.713-3.33-1.981-4.532z"
            fill="#BEE75E"
          />
          <path
            d="M18.938 19.09l-1.202-1.14v-3.452c0-.624-.535-1.13-1.194-1.13h-1.79v17.491h4.973c.66 0 1.194-.506 1.194-1.13V23.62c0-1.7-.713-3.33-1.981-4.532z"
            fill="#00CB75"
          />
          <path
            d="M17.736 11.483c0-.625-.535-1.131-1.194-1.131H12.96c-.66 0-1.194.506-1.194 1.13v3.016h5.969v-3.015z"
            fill="#AF8663"
          />
          <path
            d="M16.94 29.728v-4.523c0-.625-.535-1.131-1.194-1.131H8.583v5.654c0 .625.535 1.131 1.194 1.131h5.969c.66 0 1.194-.506 1.194-1.13z"
            fill="#FFD7A0"
          />
          <path
            d="M15.746 30.86c.66 0 1.194-.507 1.194-1.132v-4.523c0-.625-.535-1.131-1.194-1.131h-.995v6.785h.995z"
            fill="#FFB042"
          />
          <path
            d="M17.736 11.483c0-.625-.535-1.131-1.194-1.131h-1.79v4.146h2.984v-3.015zM7.042 29.395h37.777v17.578H7.042z"
            fill="#896645"
          />
          <path fill="#63544F" d="M26.316 29.395h18.503v17.578H26.316z" />
          <path
            d="M45.186 27.93H7.446c-.65 0-1.175.51-1.175 1.139v18.23c0 .628.525 1.138 1.174 1.138h37.741c.65 0 1.175-.51 1.175-1.139V29.07c0-.63-.526-1.14-1.175-1.14zM14.18 41.602v-2.28h24.274v2.28H14.179zm24.274 2.278v2.279H14.179V43.88h24.274zm0-9.114v2.278H14.179v-2.278h24.274zM14.18 32.487v-2.279h24.274v2.279H14.179zm29.833 4.557h-3.21v-2.278h3.21v2.278zm-32.182 0H8.62v-2.278h3.21v2.278zm-3.21 2.279h3.21v2.279H8.62v-2.28zm32.182 0h3.21v2.279h-3.21v-2.28zm3.21-6.836h-3.21v-2.279h3.21v2.279zM8.62 30.208h3.21v2.279H8.62v-2.279zm0 13.672h3.21v2.279H8.62V43.88zm35.392 2.279h-3.21V43.88h3.21v2.279z"
            fill="#AF8663"
          />
          <path
            d="M45.186 27.93h-18.87v2.278h12.137v2.279H26.316v2.279h12.137v2.278H26.316v2.279h12.137v2.279H26.316v2.278h12.137v2.279H26.316v2.278h18.87c.65 0 1.175-.51 1.175-1.139V29.07c0-.63-.526-1.14-1.175-1.14zm-1.174 18.229h-3.21V43.88h3.21v2.279zm0-4.557h-3.21v-2.28h3.21v2.28zm0-4.558h-3.21v-2.278h3.21v2.278zm0-4.557h-3.21v-2.279h3.21v2.279z"
            fill="#896645"
          />
        </g>
      </g>
    </svg>
  );
}

export default Marketplace;
