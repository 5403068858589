import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: palette.background.default,
      padding: "0.25rem",
    },
    tagsContainer: {
      marginBottom: "4px",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    detailsContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    detailsArea: {
      display: "flex",
      flexDirection: "column",
    },
    buttonsArea: {
      display: "flex",
      gap: "1.375rem",
      alignSelf: "flex-end",
    },
    titleContainer: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gap: ".5rem",
    },
    title: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: ".875rem",
      lineHeight: "1.25rem",
      fontFamily: "Work Sans",
    },
    defaultText: {
      fontSize: ".75rem",
      lineHeight: "1.125rem",
      fontFamily: "Work Sans",
    },
    sku: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "#757575",
    },
    price: {
      lineHeight: "1.125rem",
      fontWeight: 600,
      fontSize: ".875rem",
      color: "#176D38",
    },
    chevronIcon: {
      width: "fit-content",
    },
    collapseContainer: {
      borderLeft: "1px solid #B7B7B7",
      marginTop: "4px",
      paddingLeft: "8px",
    },
    comboProductContainerDetails: {
      display: "grid",
    },
    comboProductTitle: {
      fontSize: ".75rem",
      lineHeight: "1.125rem",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    comboProductDescription: {
      color: "rgba(0,0,0,0.55)",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0.03px",
    },
    divider: {
      margin: ".25rem 0",
    },
  }),
);
