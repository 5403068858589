/* eslint-disable */

import { useCallback } from "react";
import { useStore } from "effector-react";
import GlobalStore from "../stores/global/GlobalStore";
import AgentCallStore from "../stores/agentCall/AgentCallStore";
import GenericInfosStore from "../stores/genericInfos/GenericInfosStore";
import PocInformationStore from "../stores/callList/pocInformation/PocInformationStore";
import { CURRENCY } from "../config/constants";
import { getValueOrNull, getResponseDate } from "../config/utils/functions";

export const CALL_TRIGGER = {
  NO_ORDER: "no order",
  OUT_OF_PARAMETER: "out of parameter",
};

interface OptionalData {
  time_of_day?: boolean;
  is_resumed?: boolean;
  sales_representative?: boolean;
  is_pasted?: boolean;
  total_sku_bought?: number;
  total_sku_suggested?: number;
  total_sku_gap?: number;
  is_to_recommend_used?: boolean;
  removeProps?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventType = (config: any) => void;

type UseAnalyticsType = () => {
  dispatchGenericEvent: (
    event: EventType,
    config: Record<string, unknown>,
    optionalData?: OptionalData | undefined,
  ) => void;
  dispatchPocEvent: (
    event: EventType,
    config: Record<string, unknown>,
    optionalData?: OptionalData | undefined,
  ) => void;
};

export const useAnalytics: UseAnalyticsType = () => {
  const { user } = useStore(GlobalStore);
  const { callId, orderSku, clientId, gap, isResumed } =
    useStore(AgentCallStore);
  const { isPastedPhone, isNewOfferingsUsed } = useStore(GenericInfosStore);
  const { pocInformation } = useStore(PocInformationStore);

  const dispatchGenericEvent = useCallback(
    (
      event: EventType,
      config: Record<string, unknown>,
      optionalData: OptionalData | undefined,
    ) => {
      let optinalValues = {};
      if (optionalData?.time_of_day) {
        optinalValues = {
          ...optinalValues,
          time_of_day: getResponseDate().local().hour(),
        };
      }
      event({ ...config, ...optinalValues });
    },
    [],
  );

  const dispatchPocEvent = useCallback(
    (
      event: EventType,
      config: Record<string, unknown>,
      optionalData: OptionalData | undefined,
    ) => {
      let optinalValues = {};
      if (optionalData?.time_of_day) {
        optinalValues = {
          ...optinalValues,
          time_of_day: getResponseDate().local().hour(),
        };
      }

      if (optionalData?.is_resumed) {
        optinalValues = { ...optinalValues, is_resumed: isResumed };
      }

      if (optionalData?.is_pasted) {
        optinalValues = { ...optinalValues, is_pasted: isPastedPhone };
      }

      if (optionalData?.is_to_recommend_used) {
        optinalValues = {
          ...optinalValues,
          is_to_recommend_used: isNewOfferingsUsed,
        };
      }

      if (optionalData?.sales_representative) {
        optinalValues = {
          ...optinalValues,
          sales_representative: getValueOrNull(
            pocInformation?.additionalInformation?.salesRepresentative?.name,
          ),
        };
      }
      let eventParams: any = {
        DDC:
          user.zone === "DO"
            ? pocInformation.deliveryCenterId?.split("-")[0]
            : pocInformation.deliveryCenterId,
        call_id: callId || null,
        call_trigger:
          orderSku === 0
            ? CALL_TRIGGER.NO_ORDER
            : CALL_TRIGGER.OUT_OF_PARAMETER,
        country: user.zone,
        currency: CURRENCY[user.zone]?.currency || null,
        poc_id: clientId,
        poc_segment: pocInformation.segment || null,
        poc_subsegment: pocInformation.subSegment || null,
        total_gap: gap.topline.total || null,
        total_vol_gap: gap.volume.total || null,
        credit_available: getValueOrNull(pocInformation.availableCredit),
        ...config,
        ...optinalValues,
      };

      if (optionalData?.removeProps) {
        optionalData.removeProps.forEach(
          (removeProp) => delete eventParams[removeProp],
        );
      }

      event(eventParams);
    },
    [
      pocInformation.deliveryCenterId,
      pocInformation.additionalInformation,
      pocInformation.segment,
      pocInformation.subSegment,
      pocInformation.availableCredit,
      callId,
      orderSku,
      user.zone,
      clientId,
      gap.topline.total,
      gap.volume.total,
      isResumed,
      isPastedPhone,
      isNewOfferingsUsed,
    ],
  );

  return { dispatchGenericEvent, dispatchPocEvent };
};
