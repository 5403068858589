import React, { ReactNode } from "react";
import { Typography } from "@material-ui/core";
import { Combo as ComboType } from "../../../../../domains/promotions/Combo";
import { Promotions as PromotionsType } from "../../../../../domains/promotions/Promotions";
import {
  PROMOTIONS_TYPES,
  PROMOTIONS_ABA_TYPES,
} from "../../../../../config/constants";

import DiscountSection from "./DiscountSection";
import FreeGoodSection from "./FreeGoodSection";
import SteppedFreeGoodSection from "./SteppedFreeGoodSection";
import SteppedDiscountSection from "./SteppedDiscountSection";
import cardStyles from "./CardStyles";

interface Props {
  data: ComboType | PromotionsType;
  type: PROMOTIONS_ABA_TYPES;
  renderAddSection?: (isFreeGood?: PROMOTIONS_TYPES) => ReactNode;
}

const ComboSection: React.FC<Props> = ({ data, type, renderAddSection }) => {
  const classes = cardStyles();

  const getItemsName = () => {
    let itemsName = "";

    if (type === PROMOTIONS_ABA_TYPES.PROMOTION) {
      const promotions = data as PromotionsType;
      return promotions.itemName;
    }

    const combosType = data as ComboType;
    combosType.items.forEach((item) => {
      const concatChar = itemsName ? " + " : "";

      itemsName = `${itemsName}${concatChar}${item.title}`;
    });

    return itemsName;
  };

  const renderSection = () => {
    switch (data.type) {
      case PROMOTIONS_TYPES.COMBO_DISCOUNT:
      case PROMOTIONS_TYPES.PROM_DISCOUNT:
      case PROMOTIONS_TYPES.COMBO_DISCOUNT_V2:
        return <DiscountSection data={data} type={type} />;
      case PROMOTIONS_TYPES.COMBO_FREE_GOOD:
      case PROMOTIONS_TYPES.PROM_FREE_GOOD:
      case PROMOTIONS_TYPES.COMBO_FREE_GOOD_V2:
        return (
          <FreeGoodSection
            renderAddSection={renderAddSection}
            data={data}
            type={type}
          />
        );
      case PROMOTIONS_TYPES.PROM_STEPPED_FREE_GOOD:
        return <SteppedFreeGoodSection data={data as PromotionsType} />;
      case PROMOTIONS_TYPES.PROM_STEPPED_DISCOUNT:
        return <SteppedDiscountSection data={data as PromotionsType} />;
      default:
        return <div />;
    }
  };

  return (
    <>
      <Typography
        className={classes.itemsName}
        data-testid={`combo-name-${data.id}`}
        variant="subtitle1"
      >
        {getItemsName()}
      </Typography>
      {renderSection()}
    </>
  );
};

export default ComboSection;
