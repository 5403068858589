import Dexie from "dexie";
import { Products as CampaignB2OProducts } from "../../../domains/CampaignB2OProducts";

export type CampaignB2OProductsTableType = Dexie.Table<CampaignB2OProducts>;

class CampaignB2OProductsDb extends Dexie {
  CampaignB2OProducts: Dexie.Table<CampaignB2OProducts>;

  constructor() {
    super("CampaignB2OProductsDb");

    this.version(1).stores({
      CampaignB2OProducts:
        "campaignAndSku,sku,campaignTag,productName,unitPrice,image",
    });

    this.CampaignB2OProducts = this.table("CampaignB2OProducts");
  }
}

export default CampaignB2OProductsDb;
