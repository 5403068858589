export interface RewardsChallenge {
  challengeId: string;
  imgURL: string;
  startDate: string;
  endDate: string;
  challengePoints: number;
  challengeTitle: string;
  challengeDescription: string;
  challengeStatus: string;
  challengeType: string;
  executionMethod: string;
  goodPhotoSample: string;
  badPhotoSample: string;
  quantityMin: number;
  quantityPurchased: number;
  stage: {
    value: string;
    activeDays: number;
    remainingDays: number;
  };
  individualTarget: boolean;
  quantity: number;
  skus: SKU[];
  started: number | 0;
  failed: number | 0;
  completed: number | 0;
}

export interface RewardsChallengeStoreItem {
  challengeId: string;
  imgURL: string;
  startDate: string;
  endDate: string;
  challengePoints: number;
  challengeTitle: string;
  challengeDescription: string;
  challengeStatus: string;
  executionMethod: string;
  stage: { value: string; activeDays: number; remainingDays: number };
  goodPhotoSample: string;
  badPhotoSample: string;
  skus: ConvertedSku[];
}

export interface SKU {
  sku: string;
  itemName: string;
  itemImage: string;
  quantity: number;
  price: number;
  quantityPurchased?: number;
  container?: {
    returnable: boolean;
    name: string;
    itemSize: number;
    unitOfMeasurement: string;
    fullContainerDescription: string;
  };
  package?: {
    packageId: string;
    unitCount: number;
    itemCount: number;
    name: string;
    materialType: string;
    pack: string;
    fullPackageDescription: string;
  };
  id?: string;
  vendorItemId?: string;
  vendorId?: string;
  discount?: {
    discountType: string;
    title: string;
    description: string;
    promotionPrices: [
      {
        discountedPrice: number;
        discountedRate: number;
      },
    ];
  };
}

export interface ConvertedSku {
  itemName?: string;
  itemImage: string;
  sku?: string;
  price: number;
  quantity: number;
}

export interface RewardsChallengeState {
  done: boolean;
  isLoading: boolean;
  error: boolean;
  started: number | 0;
  failed: number | 0;
  completed: number | 0;
  rewardsChallengeInformation: RewardsChallengeStoreItem[];
}

export enum CHALLENGE_STATUS {
  PENDING = "PENDING",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
}
