import React from "react";

const DeleteIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="15"
    viewBox="0 0 12 15"
  >
    <g fill="#7C7C7C" fillRule="evenodd">
      <path d="M10.04 3c.53 0 .96.43.96.96v10.08c0 .53-.43.96-.96.96H1.96c-.53 0-.96-.43-.96-.96V3.96c0-.53.43-.96.96-.96h8.08zM4.5 7c-.245 0-.45.177-.492.41L4 7.5v5c0 .276.224.5.5.5.245 0 .45-.177.492-.41L5 12.5v-5c0-.276-.224-.5-.5-.5zm3 0c-.276 0-.5.224-.5.5v5c0 .276.224.5.5.5s.5-.224.5-.5v-5c0-.276-.224-.5-.5-.5zM7.04 0c.53 0 .96.43.96.96v.837h3.5c.276 0 .5.224.5.5s-.224.5-.5.5H.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5H4V.96c0-.53.43-.96.96-.96h2.08zm-.422.725H5.382C5.17.725 5 .895 5 1.106s.17.382.382.382h1.236c.211 0 .382-.17.382-.382 0-.21-.17-.381-.382-.381z" />
    </g>
  </svg>
);

export default DeleteIcon;
