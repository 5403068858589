import * as SearchVendorPocByAccountIdUseCase from "@usecase/pocInformation/SearchVendorPocByAccountId/SearchVendorPocByAccountIdUseCase";
import { createEffect, createEvent } from "effector";

export const searchVendorPocByAccountId = createEffect({
  async handler(accountId: string) {
    return SearchVendorPocByAccountIdUseCase.execute(accountId);
  },
});

export const resetVendorsPoc = createEvent<null>("resetVendorsPoc");
