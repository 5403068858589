import React, { useEffect } from "react";
import { useStore } from "effector-react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import useStyles from "./style";
import {
  sendCustomerNotes,
  getCustomerNotes,
  setCustomerNotes,
} from "../../../../../stores/customerNotes/CustomerNotesEvents";
import CustomerNotesStore from "../../../../../stores/customerNotes/CustomerNotesStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import Notes from "./note/Note";
import { customerNoteUpdated } from "../../../../../config/typewriter";
import Loading from "../../loading/Loading";
import ErrorRetry from "../../../../../components/errorRetry/ErrorRetry";

const CustomerNotes: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    user: { login },
  } = useStore(GlobalStore);
  const { clientId, callId, vendorAccountId } = useStore(AgentCallStore);
  const { customerNotes, isLoadingGetNotes, errorGetNotes } =
    useStore(CustomerNotesStore);

  useEffect(() => {
    getCustomerNotes({
      clientId,
      vendorAccountId,
    });
  }, [clientId, vendorAccountId]);

  const handleUpdateCustomerNote = (notes: string) => {
    sendCustomerNotes({
      clientId,
      vendorAccountId,
      agentEmail: login,
      note: notes,
    });

    const unWatchSendCustomerNotes = sendCustomerNotes.done.watch(() => {
      customerNoteUpdated({
        call_id: callId,
        poc_id: clientId,
        screen_name: "Customer",
        screen_section: "Customer Note",
      });
      setCustomerNotes(notes);
      getCustomerNotes({
        clientId,
        vendorAccountId,
      });
      /* istanbul ignore next */
      if (typeof unWatchSendCustomerNotes === "function") {
        unWatchSendCustomerNotes();
      }
    });
  };

  const retryHandler = () => {
    getCustomerNotes({
      clientId,
      vendorAccountId,
    });
  };

  const renderContent = () => {
    if (isLoadingGetNotes) {
      return (
        <div className={classes.loading}>
          <Loading />
        </div>
      );
    }

    return (
      <Notes
        lastNote={customerNotes?.note || ""}
        lastUpdatedDate={customerNotes?.lastUpdatedDate || null}
        lastUpdatedEmail={customerNotes?.agentEmail || null}
        lastUpdatedTime={customerNotes?.lastUpdatedTime || null}
        isGetNotesLoading={isLoadingGetNotes || false}
        onClick={handleUpdateCustomerNote}
      />
    );
  };

  const renderError = () => {
    return (
      <div className={classes.errorContent}>
        <ErrorRetry
          onClick={retryHandler}
          testId="customer-notes-retry"
          screenName="Customer"
          screenSection="Customer Note"
        />
      </div>
    );
  };

  return (
    <div className={classes.container} data-testid="customer-note-container">
      <Typography className={classes.title}>
        {t("CUSTOMER_PAGE.CUSTOMER_NOTE")}
      </Typography>
      {errorGetNotes ? renderError() : renderContent()}
    </div>
  );
};

export default CustomerNotes;
