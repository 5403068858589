import React from "react";

const HappyFaceIcon: React.FunctionComponent = () => {
  return (
    <svg width={34} height={34} xmlns="http://www.w3.org/2000/svg">
      <g
        transform="translate(1 1)"
        stroke="#1D8445"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle strokeWidth={2} cx={16} cy={16} r={16} />
        <path
          d="M9.778 18.667S12.11 22.222 16 22.222c3.889 0 6.222-3.555 6.222-3.555"
          strokeWidth={2}
        />
        <path strokeWidth={3} d="M11.547 11.556h.017M20.436 11.556h.017" />
      </g>
    </svg>
  );
};

export default HappyFaceIcon;
