import { createEffect, createEvent } from "effector";
import { getPaymentMethod } from "../../services/paymentMethodInfo/PaymentMethodInfoService";

interface GetPaymentMethodParams {
  accountId: string;
  vendorId: string;
}

export const getPaymentMethodInformation = createEffect({
  async handler(params: GetPaymentMethodParams) {
    return getPaymentMethod(params.accountId, params.vendorId);
  },
});

export const resetPaymentMethodInfo = createEvent("resetCart");
