/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CheckoutInfo } from "../../../../../../domains/CheckoutInfo";
import { CheckoutInfoV2 } from "../../../../../../domains/CheckoutInfoV2";
import { Empties } from "../../../../../../domains/cart/Empties";
import { CheckoutDomain } from "../../../../../../services/checkout/domains/CheckoutDomain";
import { CheckoutDomainV2 } from "../../../../../../services/checkout/domains/v2/CheckoutDomainV2";
import { paymentMethodsEnum } from "../../../paymentMethod/PaymentMethodsEnum";

export type payload = {
  params: CheckoutDomainV2 | CheckoutDomain;
  result: CheckoutInfoV2 | CheckoutInfo;
  bodyCheckout;
  isAlternativeDateSelected;
  rangeAlternativeDates;
  orderReason;
  technicalProblem;
  removeDoneWatcher;
  removeFailWatcher;
  cartFormRef;
};

export const callTrigger = (orderSku: number) => {
  return orderSku === 0 ? "no order" : "out of parameter";
};

export const returnMessage = (
  isCheckoutV3Enable: boolean,
  result: CheckoutInfo | CheckoutInfoV2,
) => {
  return isCheckoutV3Enable
    ? (result as CheckoutInfo).carts[0].messages
    : (result as CheckoutInfoV2).messages;
};

export const returnOrderNumber = (
  isCheckoutV3Enable: boolean,
  result: CheckoutInfo | CheckoutInfoV2,
) => {
  return isCheckoutV3Enable
    ? (result as CheckoutInfo).carts[0].order?.orderNumber
    : (result as CheckoutInfoV2).orders[0].orderNumber;
};

export const returnCartId = (
  isCheckoutV3Enable: boolean,
  result: CheckoutInfo | CheckoutInfoV2,
) => {
  return isCheckoutV3Enable
    ? (result as CheckoutInfo).carts[0].cartId
    : (result as CheckoutInfoV2).orders[0].cartId;
};

export const returnDeliveryDate = (
  deliveryDate: string,
  currentDate: string,
) => {
  return deliveryDate.split("T")[0] || currentDate;
};

export const returnValueOrZero = (overdue: number | undefined) => {
  return overdue || 0;
};

export const returnPaymentTerm = (
  paymentMethod: string,
  paymentTerm: number,
) => {
  return paymentMethod === paymentMethodsEnum.BANK_SLIP ||
    paymentMethod === paymentMethodsEnum.BANK_SLIP_INSTALLMENT ||
    paymentMethod === paymentMethodsEnum.CREDIT
    ? paymentTerm
    : null;
};

export const getEmptiesCheckoutV2 = (
  isOrderTakingEmptiesEnabled: boolean,
  empties?: Empties,
) => {
  const emptiesList = empties?.emptiesList;

  if (isOrderTakingEmptiesEnabled) {
    return {
      empties: {
        emptiesList:
          emptiesList?.map((each) => {
            return {
              quantity: each.quantity,
              id: each.groupId,
            };
          }) || [],
        extraAmount: returnValueOrZero(empties?.extraAmount),
        minimumRequired: returnValueOrZero(empties?.minimumRequired),
        totalAmount: returnValueOrZero(empties?.totalAmount),
      },
    };
  }
  return null;
};
