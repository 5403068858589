import {
  getEmails,
  getPhones,
} from "@config/utils/getPhoneAndEmailByPocInformation";
import { AccountsResponse } from "../services/pocInformation/domains/AccountsResponse";
import { OwnerResponse } from "../services/pocInformation/domains/OwnerResponse";
import { MOCK_EMAIL_PHONE } from "../config/mock/accounts";
import { POC_ID_MOCK } from "../config/mock/auth";
import AdditionalInformation from "../domains/AdditionalInformation";
import PocInformation from "../domains/PocInformation";

/* istanbul ignore next */
const formatOwnerName = (owner?: OwnerResponse) => {
  if (!owner) {
    return undefined;
  }
  const { firstName, lastName } = owner;

  if (lastName === null || lastName === undefined) {
    return firstName;
  }
  return `${firstName} ${lastName}`;
};

const getChallengeIds = (account: AccountsResponse | null) => {
  if (account?.challengeIds && account?.challengeIds.length > 0) {
    if (account?.challengeIds.length > 3) {
      return account.challengeIds.slice(0, 3);
    }
    return account.challengeIds;
  }
  return [""];
};
/* istanbul ignore next */
const isMockedContacts = (account: AccountsResponse) => {
  if (account.accountId === POC_ID_MOCK && account.country === "AR") {
    return MOCK_EMAIL_PHONE;
  }
  return account?.contacts;
};
/* istanbul ignore next */
const castAlternativeDiscountToNumber = (accumulativeDiscount) => {
  if (accumulativeDiscount && typeof accumulativeDiscount === "string") {
    return Number(accumulativeDiscount.trim());
  }
  return accumulativeDiscount;
};
/* istanbul ignore next */
const trimMaxVehicleCapacity = (maxVehicleCapacity) => {
  if (maxVehicleCapacity && typeof maxVehicleCapacity === "string") {
    return maxVehicleCapacity.trim();
  }
  return maxVehicleCapacity;
};

const getDeliveryWindows = (account: AccountsResponse | null) => {
  return account?.deliveryWindows ?? [];
};

const getPaymentMethods = (account: AccountsResponse | null) => {
  return account?.paymentMethods ?? [];
};

const getPaymentTerms = (account: AccountsResponse | null) => {
  return account?.paymentTerms ?? [];
};

const getRepresentative = (account: AccountsResponse | null) => {
  return [{ ...account?.salesRepresentative }];
};

const createAdditionalInformation = (
  account: AccountsResponse | null,
  isContractless?: boolean,
): AdditionalInformation | null => {
  if (account) {
    return isContractless
      ? <AdditionalInformation>{
          salesRepresentative: { ...account?.representatives },
          segment: account?.segment,
        }
      : <AdditionalInformation>{
          salesRepresentative: getRepresentative(account),
          potential: account?.potential,
          deliveryCenterId: account?.deliveryCenterId,
          segment: account?.segment,
          subSegment: account?.subSegment,
          channel: account?.channel,
          channelName: account?.metadata?.channelName,
          subChannelCode: account?.metadata?.subChannelCode,
          subChannelName: account?.metadata?.subChannelName,
          segmentName: account?.metadata?.segmentName,
          subSegmentName: account?.metadata?.subsegmentName,
          potentialName: account?.metadata?.potentialName,
        };
  }
  return null;
};

const createContractlessPocInformation = (
  account: AccountsResponse | null,
  additionalInformation: AdditionalInformation | null,
) => {
  return {
    id: account?.accountId ?? "",
    accountId: account?.accountId ?? "",
    name: account?.name,
    owner: formatOwnerName(account?.owner),
    address: account?.deliveryAddress?.address,
    city: account?.deliveryAddress?.city,
    state: account?.deliveryAddress?.state,
    zipcode: account?.deliveryAddress?.zipcode,
    emails: getEmails(account),
    phones: getPhones(account),
    creditInfo: account?.credit ?? undefined,
    totalCredit: account?.credit?.total ?? undefined,
    availableCredit: account?.credit?.available ?? undefined,
    creditExpiringDate: account?.credit?.updatedAt ?? "",
    deliveryCenterId: account?.deliveryCenterId,
    segment: account?.segment,
    deliveryWindows: getDeliveryWindows(account),
    additionalInformation,
    accumulativeDiscount: castAlternativeDiscountToNumber(
      account?.metadata?.accumulativeDiscount,
    ),
    maxVehicleCapacity: trimMaxVehicleCapacity(
      account?.metadata?.maxVehicleCapacity,
    ),
    taxId: account?.taxId,
    liquorLicense: account?.liquorLicense?.[0]?.number,
    maximumOrder: account?.maximumOrder?.value ?? undefined,
    vendorAccountId: account?.vendorAccountId ?? "",
    legacyAccountId: account?.legacyAccountId,
    customerAccountId: account?.customerAccountId,
  };
};

const createDefaultPocInformation = (
  account: AccountsResponse | null,
  additionalInformation: AdditionalInformation | null,
) => {
  const metadata = account?.metadata ?? {};
  return {
    id: account?.accountId ?? "",
    accountId: account?.accountId ?? "",
    name: account?.name,
    owner: formatOwnerName(account?.owner),
    address: account?.deliveryAddress?.address,
    city: account?.deliveryAddress?.city,
    state: account?.deliveryAddress?.state,
    zipcode: account?.deliveryAddress?.zipcode,
    emails: getEmails(account),
    phones: getPhones(account),
    creditInfo: account?.credit ?? undefined,
    totalCredit: account?.credit?.total ?? undefined,
    availableCredit: account?.credit?.available ?? undefined,
    creditExpiringDate: account?.credit?.updatedAt ?? "",
    deliveryCenterId: account?.deliveryCenterId,
    segment: account?.segment,
    subSegment: account?.subSegment,
    deliveryWindows: getDeliveryWindows(account),
    paymentMethods: getPaymentMethods(account),
    paymentTerms: getPaymentTerms(account),
    additionalInformation,
    challengeIds: getChallengeIds(account),
    accumulativeDiscount: castAlternativeDiscountToNumber(
      metadata.accumulativeDiscount,
    ),
    maxVehicleCapacity: trimMaxVehicleCapacity(metadata.maxVehicleCapacity),
    salesOrg: metadata.salesOrg,
    salesAreaName: metadata.salesAreaName,
    salesRegionCode: metadata.salesRegionCode,
    salesRegionName: metadata.salesRegionName,
    salesSubregionCode: metadata.salesSubregionCode,
    salesSubregionName: metadata.salesSubregionName,
    supervision: metadata.supervision,
    salesAreaRepName: metadata.salesAreaRepName,
    salesZone: metadata.salesZone,
    salesZoneName: metadata.salesZoneName,
    distributionCenterName: metadata.distributionCenterName,
    taxId: account?.taxId,
    liquorLicense: account?.liquorLicense?.[0]?.number,
    maximumOrder: account?.maximumOrder?.value ?? undefined,
    vendorAccountId: account?.vendorAccountId ?? "",
    legacyAccountId: account?.legacyAccountId,
    customerAccountId: account?.customerAccountId,
    financialReferenceId: metadata.financialReferenceId,
    hasPONumberRequirement: account?.hasPONumberRequirement,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function AccountsResponseToPocInformationConverter(
  data: Array<AccountsResponse>,
  isContractless?: boolean,
): PocInformation {
  const account = data.length > 0 ? data[0] : null;
  const additionalInformation: AdditionalInformation | null =
    createAdditionalInformation(account, isContractless);

  if (account) {
    account.contacts = isMockedContacts(account);
  }
  return isContractless
    ? createContractlessPocInformation(account, additionalInformation)
    : createDefaultPocInformation(account, additionalInformation);
}
