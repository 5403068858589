import React from "react";

interface Props {
  size?: string;
}

const ErrorRetryIcon: React.FunctionComponent<Props> = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size || "24px"}
    height={size || "24px"}
    viewBox="0 0 72.000000 75.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,75.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path d="M457 643 c-12 -11 -8 -20 19 -52 l26 -31 -147 0 -147 0 -35 -35 c-31 -31 -34 -38 -31 -87 2 -39 7 -53 18 -53 10 0 17 14 20 48 9 77 19 81 180 85 79 2 140 0 140 -6 0 -4 -11 -19 -25 -32 -29 -27 -31 -38 -9 -47 18 -7 114 82 114 107 0 17 -89 110 -106 110 -6 0 -14 -3 -17 -7z" />
      <path d="M547 373 c-4 -3 -7 -24 -7 -45 0 -25 -7 -45 -20 -58 -18 -18 -33 -20 -162 -20 l-142 0 27 28 c30 31 34 47 13 55 -16 6 -116 -92 -116 -114 0 -18 92 -109 110 -109 24 0 22 32 -5 60 l-27 29 152 3 152 3 29 33 c25 27 29 41 29 87 0 39 -4 55 -13 55 -8 0 -17 -3 -20 -7z" />
    </g>
  </svg>
);

export default ErrorRetryIcon;
