import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "flex-end",
    },
    title: {
      paddingRight: "5px",
      color: palette.text.secondary,
      fontWeight: "normal",
    },
    counter: {
      color: palette.error.main,
    },
  }),
);
