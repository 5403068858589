import { useLogService } from "admin-portal-shared-services";
import axios from "@config/axios/axiosInstance";
import Catalog from "@domains/Catalog";
import axiosRetry from "axios-retry";
import { CatalogResponseToCatalogConverterBFF } from "../../../converters/catalog/bff/CatalogResponseToCatalogConverterBFF";
import { getApiHost } from "../../host/HostService";
import CatalogBFFResponse from "./domains/CatalogBFFResponse";
import { GROW_BFF_SERVICE } from "../../../config/services";

export const CATALOG_BFF_ENDPOINT = "/catalog";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getBFFCatalog(
  accountId: string,
  vendorId: string,
  vendorItemIds: string,
): Promise<Catalog> {
  const log = useLogService();

  const params = {
    projection: "ITEM,PRICE,AVAILABILITY,INVENTORY",
    vendorItemIds,
  };

  const headers = {
    vendorId,
  };

  const endpoint = `${getApiHost(
    GROW_BFF_SERVICE,
  )}/v1/accounts/${accountId}${CATALOG_BFF_ENDPOINT}`;

  return axios
    .get<CatalogBFFResponse>(`${endpoint}`, {
      headers,
      params,
    })
    .then((response) => {
      log.info(`Convert Catalog score received from ${endpoint} to our model`);
      return CatalogResponseToCatalogConverterBFF(response.data.items);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
