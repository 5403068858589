import { MISSION_CATEGORIES, MISSION_TYPES } from "../../config/constants";

export interface FlexibleMission {
  missionId: string;
  type: MISSION_TYPES;
  vendorName: string;
  missionName: string;
  category: MISSION_CATEGORIES;
  segmentationId: string;
  startDate: string;
  endDate: string;
  description: string;
  traitsToInclude: Trait[];
  traitsToExclude: Trait[];
  audit: Audit;
  enabled: boolean;
  status: STATUSES;
  country: string;
  deliveryWindow: boolean;
  script: string;
  recurrence: number;
  effectiveness: EffectivenessFlexible;
  items: Item[];
  combos: Combo[];
  order: number;
  excludeBlocklist: boolean;
}

interface Trait {
  code: TRAIT_CODES;
  operator: TRAIT_OPERATORS;
  value: string;
  order: number;
}

enum TRAIT_CODES {
  PWUC = "PWUC",
  CAA = "CAA",
  CTUA = "CTUA",
  DLVL = "DLVL",
  POCID = "POCID",
}

enum TRAIT_OPERATORS {
  LESS_THAN = "LESS_THAN",
  LESS_EQUALS_TO = "LESS_EQUALS_TO",
  GREATER_THAN = "GREATER_THAN",
  GREATER_EQUALS_TO = "GREATER_EQUALS_TO",
  EQUALS_TO = "EQUALS_TO",
  LISTED_ON = "LISTED_ON",
  BETWEEN = "BETWEEN",
}

interface Audit {
  fullName: string;
  email: string;
  createDate: string;
}

enum STATUSES {
  SCHEDULED = "SCHEDULED",
  ONGOING = "ONGOING",
  PAUSED = "PAUSED",
  CONCLUDED = "CONCLUDED",
  EXPIRED = "EXPIRED",
}

export interface EffectivenessFlexible {
  type: EFFECTIVENESS_TYPES;
  value: string;
  timeWindow: TIME_WINDOWS;
  quantityOfDays: number;
}

export enum EFFECTIVENESS_TYPES {
  MINIMUM_TOTAL = "MINIMUM_TOTAL",
  MINIMUM_PER_PRODUCT = "MINIMUM_PER_PRODUCT",
  CREDIT_AS_PAYMENT = "CREDIT_AS_PAYMENT",
  CREDIT_ONBOARDING = "CREDIT_ONBOARDING",
}

export enum EFFECTIVENESS_TYPES_STRINGS {
  CREDIT_AS_PAYMENT = "credit as payment",
}

enum TIME_WINDOWS {
  SAME_DAY = "SAME_DAY",
  WITHIN_NEXT_DAYS = "WITHIN_NEXT_DAYS",
  WHILE_MISSION_LASTS = "WHILE_MISSION_LASTS",
  DAY_AFTER = "DAY_AFTER",
}

interface Item {
  id: string;
  quantity: number;
  itemPlatformId: string;
}

interface Combo {
  id: string;
  comboId: string;
  quantity: number;
  comboPlatformId: string;
}
