import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import axios from "../../config/axios/axiosInstance";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../host/HostService";
import LastContactsResponse from "./domains/LastContactsResponse";
import LastContacts from "../../domains/lastContacts/LastContacts";
import converter from "../../converters/lastContacts/LastContactsResponseToLastContactsConverter";
import { AGENT_CALL_SERVICE } from "../../config/services";
import GlobalStore from "../../stores/global/GlobalStore";
import VendorStore from "../../stores/vendor/VendorStore";

import {
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_AGENT_CALL_SERVICE,
} from "../../config/featureToggles";

export const AGENT_CALL = `/agent-call`;
export const LAST_CONTACTS_ENDPOINT = "/last-contacts";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getLastContacts(
  accountId: string,
): Promise<Array<LastContacts>> {
  const log = useLogService();

  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();

  const isAgentCallV2Enabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_AGENT_CALL_SERVICE,
    user.zone,
  );

  const vendorIdMetadata = vendor?.id;
  const apiHost = getApiHost(AGENT_CALL_SERVICE);
  const v2EndPoint = getV2EndPoint(isAgentCallV2Enabled);
  const vendorIdParam = getVendorIdParam(
    vendorIdMetadata,
    isAgentCallV2Enabled,
  );

  const endpoint = `${AGENT_CALL}${v2EndPoint}/${accountId}${LAST_CONTACTS_ENDPOINT}${vendorIdParam}`;

  const url = `${apiHost}${endpoint}`;

  return axios
    .get<Array<LastContactsResponse>>(url)
    .then((response) => {
      log.info(`Convert LastContacts received from ${endpoint} to our model`);
      return converter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
