import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";

import axios from "@config/axios/axiosInstance";

import CatalogResponseToCatalogConverterV2 from "../../../converters/catalog/v2/CatalogResponseToCatalogConverterV2";
import Catalog from "../../../domains/Catalog";
import { getApiHost, getEndPoint } from "../../host/HostB2BService";
import CatalogResponseV2 from "./domains/CatalogResponseV2";

export const CATALOG_V2_ENDPOINT = "/catalog-service/v2/items";
/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});
export function getCatalogV2(
  accountId: string,
  vendorItemIds?: string,
): Promise<Catalog> {
  const log = useLogService();
  const vendorItemIdsQueryParam = vendorItemIds
    ? `&vendorItemIds=${vendorItemIds}`
    : "";
  const endpoint = `${getApiHost()}${getEndPoint()}${CATALOG_V2_ENDPOINT}?accountId=${accountId}&projection=LIST${vendorItemIdsQueryParam}`;
  return axios
    .get<CatalogResponseV2>(`${endpoint}`)
    .then((response) => {
      log.info(`Convert Catalog score received from ${endpoint} to our model`);
      return CatalogResponseToCatalogConverterV2(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
