import { createStore } from "effector";
import { setVendorConfig, VendorInput } from "./VendorEvents";
import { VendorState } from "./VendorState";

const initialState: VendorState = {
  done: false,
  vendor: {
    id: "",
    displayName: "",
  },
};
const VendorStore = createStore(initialState).on(
  setVendorConfig,
  (state: VendorState, payload: VendorInput) => ({
    ...state,
    done: true,
    vendor: {
      id: payload?.id || undefined,
      displayName: undefined,
    },
  }),
);
export default VendorStore;
