import NewOfferings, {
  NewOfferingsList,
} from "../../../../../domains/newOfferings/NewOfferings";
import { hasSkuOrName } from "./hasSkuOrName";

export const searchByNameOrSku = (
  offerings: Array<NewOfferings>,
  text: string,
): NewOfferingsList => {
  const result =
    text !== "" && text.toString().trim() !== ""
      ? offerings.filter((item) => {
          return hasSkuOrName(item, text);
        })
      : offerings;

  return { result, searchValueAnalytics: text };
};
