import {
  GAPReason,
  MarketplaceOptions,
  ChallengesReasonOptions,
  SponsoredReasonOptions,
  ServicesOptions,
} from "./Mission";

export const LAST_CONTACTS_TYPIFICATION = [
  {
    text: "",
    translation: "NEWCallSummary.EMPTY",
  },
  {
    text: "Success on contact",
    translation: "NEWCallSummary.SUCCESS_ON_CONTACT",
  },
  {
    text: "No Answer",
    translation: "NEWCallSummary.NO_ANSWER_DO_NOT_CALL_AGAIN",
  },
  {
    text: "No Answer - try again",
    translation: "NEWCallSummary.NO_ANSWER_TRY_AGAIN",
  },
  {
    text: "Responsible Not Available",
    translation: "NEWCallSummary.RESPONSIBLE_NOT_AVAILABLE",
  },
  {
    text: "Incorrect Phone Number",
    translation: "NEWCallSummary.INCORRECT_PHONE_NUMBER",
  },
  {
    text: "Poc Closed Duplicated",
    translation: "NEWCallSummary.POC_CLOSED_DUPLICATE",
  },
  {
    text: "Call Later Today",
    translation: "NEWCallSummary.CALL_LATER_TODAY",
  },
  {
    text: "Uplifted",
    translation: "CallTypification.UPLIFT_ON_CALL",
  },
  {
    text: "Uplift on call by Agent",
    translation: "CallTypification.UPLIFT_ON_CALL_BY_AGENT",
  },
  {
    text: "Uplifted after call",
    translation: "CallTypification.UPLIFT_AFTER_CALL",
  },
  {
    text: "No Success On Uplift",
    translation: "CallTypification.NO_SUCCESS_ON_UPLIFT",
  },
  {
    text: "Poc Already Placed The Order",
    translation: "CallTypification.POC_ALREADY_PLACED_THE_ORDER",
  },
];

export const LAST_CONTACT_REASON = [
  ...GAPReason,
  ...MarketplaceOptions,
  ...ChallengesReasonOptions,
  ...SponsoredReasonOptions,
  ...ServicesOptions,
];
