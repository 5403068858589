import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      height: 2,
    },
    barColorPrimary: {
      backgroundColor: "#7a7a7a",
    },
    colorPrimary: {
      backgroundColor: "white",
    },
  }),
);
