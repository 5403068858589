import { useCallback } from "react";
import { useStore } from "effector-react";
import { Effect } from "effector";
import { Product } from "../../../../../domains/products/Product";
import { getValueEmpty } from "../../../../../config/utils/functions";
import {
  isFeatureEnabled,
  TLP_REWARDS_PROGRAM_TRACK_POINTS,
  TLP_REWARDS_PROGRAM_TRACK_POINTS_ADMIN,
  TLP_PALLET_ITEMS,
  TLP_PALLET_ITEMS_ADMIN,
  isFeatureEnabledV2,
  GROW_CART_SERVICE_V4,
  TLP_ORDER_TAKING_EMPTIES,
  GROW_TECH_CART_AUTO_UPDATE,
  GROW_TECH_CART_BFF,
} from "../../../../../config/featureToggles";
import { errorMessageViewed } from "../../../../../config/typewriter";
import { TIMEZONE } from "../../../../../config/timeZone";
import {
  updateCartEffect,
  updateCartEffectV3,
  updateCartSimulationEffect,
  updateSingleCartSimulationEffect,
} from "../../../../../stores/cart/CartItemEvents";
import { setNeedUpdate } from "../../../../../stores/cart/CartEvents";
import CartComboStore from "../../../../../stores/cartCombo/CartComboStore";
import loyaltyProgramStore from "../../../../../stores/loyaltyProgram/LoyaltyProgramStore";
import { getRulesEffect } from "../../../../../stores/loyaltyBusiness/LoyaltyBusinessEvents";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import CartItemStore from "../../../../../stores/cart/CartItemStore";
import CartStore from "../../../../../stores/cart/CartStore";
import PocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import { OrderComboItem } from "../../../../../services/checkout/domains/v1Mobile/CheckoutRequestV1Mobile";
import { ComboItem } from "../../../../../services/checkout/domains/v2/CheckoutRequestV2";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import { CartComboItem } from "../../../../../domains/cart/CartComboItem";
import {
  clearComboCart,
  removeItem,
  updateComboQuantity,
} from "../../../../../stores/cartCombo/CartComboEvents";
import { Item } from "../../../../../services/cartSimulation/domains/CartSimulationRequest";
import {
  updateCartValuesType,
  getPaymentMethodValue,
  getDeliveryDateValue,
  getDeliveryWindowIdValue,
} from "./utils/simulationCart";

interface UseUpdateCartHook {
  needUpdate: boolean;
  setNeedToUpdate: (value: boolean) => void;
  updateCart: (
    field?: string,
    value?: string,
    valuesChanged?: updateCartValuesType,
  ) => void;
  getCombosRequest: () => OrderComboItem[] | ComboItem[];
  isCartLoading: () => boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCurrentUpdateEffect: () => Effect<any, any, any>;
}

export const useUpdateCartHook = (): UseUpdateCartHook => {
  const { balance } = useStore(loyaltyProgramStore);
  const AgentCallStates = useStore(AgentCallStore);
  const CartItemStates = useStore(CartItemStore);
  const { cartComboItems } = useStore(CartComboStore);
  const CartStates = useStore(CartStore);
  const { pocInformation } = useStore(PocInformationStore);
  const { user } = useStore(GlobalStore);
  const { dispatchPocEvent } = useAnalytics();
  const { needUpdate } = CartStates;
  const pendingV3Effect = useStore(updateCartEffectV3.pending);

  const isRewardsProgramEnabled = isFeatureEnabled(
    TLP_REWARDS_PROGRAM_TRACK_POINTS,
    TLP_REWARDS_PROGRAM_TRACK_POINTS_ADMIN,
  );

  const isPalletEnabled = isFeatureEnabled(
    TLP_PALLET_ITEMS,
    TLP_PALLET_ITEMS_ADMIN,
    user.keyToggle,
  );

  const isCartSimulationV4Enabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    user.keyToggle,
  );

  const isOrderTakingEmptiesEnabled = isFeatureEnabledV2(
    TLP_ORDER_TAKING_EMPTIES,
    user.keyToggle,
  );

  const isCartAutoUpdate = isFeatureEnabledV2(
    GROW_TECH_CART_AUTO_UPDATE,
    user.keyToggle,
  );
  const isCartBffEnable = isFeatureEnabledV2(
    GROW_TECH_CART_BFF,
    user.keyToggle,
  );

  /* istanbul ignore next */
  const getCombosRequest = useCallback(
    (isCartSimulationV4Enabled?: boolean | false) => {
      return cartComboItems.map((each: CartComboItem) => {
        if (isCartSimulationV4Enabled) {
          return {
            platformId: each.platformId,
            quantity: each.itemQuantity,
          } as ComboItem;
        }

        return {
          id: each.id,
          quantity: each.itemQuantity,
        } as ComboItem;
      });
    },
    [cartComboItems],
  );
  const getCurrentUpdateEffect = useCallback(() => {
    if (isCartAutoUpdate && isCartBffEnable)
      return updateSingleCartSimulationEffect;
    if (isCartAutoUpdate) return updateCartEffect;
    return isCartSimulationV4Enabled
      ? updateCartSimulationEffect
      : updateCartEffectV3;
  }, [isCartAutoUpdate, isCartSimulationV4Enabled, isCartBffEnable]);

  const setNeedToUpdate = useCallback((value: boolean) => {
    setNeedUpdate(value);
  }, []);

  const updateEmpties = useCallback(() => {
    if (isOrderTakingEmptiesEnabled) {
      const emptiesList = CartItemStates?.empties;
      return {
        empties: emptiesList?.map((each) => {
          return {
            id: each.groupId,
            quantity: each.quantity,
          };
        }),
      };
    }

    return null;
  }, [CartItemStates?.empties, isOrderTakingEmptiesEnabled]);

  /* istanbul ignore next */
  const effectDoneResult = useCallback(
    (result) => {
      if (result.combos && result.combos.length >= 1) {
        cartComboItems.forEach((combo) => {
          const found = result.combos.some((each) => {
            if (isCartSimulationV4Enabled)
              return combo.platformId === each.platformId;

            return combo.id === each.id;
          });
          if (!found) {
            removeItem(combo);
          }
        });

        result.combos.forEach((combo) => {
          const comboItem = cartComboItems.find((each) => {
            if (isCartSimulationV4Enabled)
              return each.platformId === combo.platformId;

            return each.id === combo.id;
          });

          if (comboItem) {
            const aux: CartComboItem = {
              ...comboItem,
              itemQuantity: combo.itemQuantity,
              price: combo.price,
              updated: true,
            };
            updateComboQuantity(aux);
          }
        });
      } else {
        clearComboCart();
      }

      setNeedToUpdate(false);

      if (isRewardsProgramEnabled) {
        if (
          balance !== undefined &&
          balance !== null &&
          result?.lineItems?.length > 0
        ) {
          const platformIds = result.lineItems.map(
            (item: Product) => item.platformId,
          );
          const filtered = platformIds.filter(
            (el: string | undefined) => el !== undefined,
          );

          getRulesEffect({
            accountId: AgentCallStates.clientId,
            platformIds: filtered,
          });
        }
      }
    },
    [
      AgentCallStates.clientId,
      balance,
      cartComboItems,
      isCartSimulationV4Enabled,
      isRewardsProgramEnabled,
      setNeedToUpdate,
    ],
  );

  const callErrorMessageViewedTruck = useCallback(() => {
    /* istanbul ignore next */
    if (isPalletEnabled && !CartItemStates.totalTrucks?.totalTrucks) {
      dispatchPocEvent(
        errorMessageViewed,
        {
          screen_name: "Call - Orders",
          error_type: "Truck calculator error",
          error_message: "No data available",
          screen_section: "Shipping Setup",
          is_all_products: null,
        },
        { is_resumed: true, time_of_day: true },
      );
    }
  }, [
    CartItemStates.totalTrucks?.totalTrucks,
    dispatchPocEvent,
    isPalletEnabled,
  ]);

  const createCartSimulationRequest = useCallback(
    (field?: string, value?: string, valuesChanged?: updateCartValuesType) => {
      const itemsCartSimulation: Item[] = CartItemStates.cartItems.map(
        (each) => {
          return {
            platformId: each.platformId,
            quantity: each.itemQuantity,
          };
        },
      );

      return {
        accountId: getValueEmpty(AgentCallStates.clientId),
        paymentMethod: getPaymentMethodValue(
          CartStates.cartForm.paymentMethod,
          field,
          value,
        ),
        paymentTerm: (valuesChanged?.paymentTerm as never) || 0,
        deliveryWindowId: getDeliveryWindowIdValue(
          CartStates.cartForm.deliveryWindowId,
          field,
          valuesChanged,
        ),
        payment: {
          methodCode: valuesChanged?.paymentMethodCode || null,
        },
        delivery: {
          method: "STANDARD",
          date: getDeliveryDateValue(
            CartStates.cartForm.deliveryDate,
            field,
            value,
          ),
        },
        items: itemsCartSimulation,
        combos: getCombosRequest(isCartSimulationV4Enabled),
        ...updateEmpties(),
        id: CartItemStates.cart?.cartId ?? null,
      };
    },
    [
      AgentCallStates.clientId,
      CartItemStates.cart?.cartId,
      CartItemStates.cartItems,
      CartStates.cartForm.deliveryDate,
      CartStates.cartForm.deliveryWindowId,
      CartStates.cartForm.paymentMethod,
      getCombosRequest,
      isCartSimulationV4Enabled,
      updateEmpties,
    ],
  );

  const updateCartEffectVersion = isCartSimulationV4Enabled
    ? updateCartSimulationEffect
    : updateCartEffectV3;

  const updateCart = useCallback(
    (field?: string, value?: string, valuesChanged?: updateCartValuesType) => {
      setNeedToUpdate(false);
      if (isCartAutoUpdate) return;

      if (isCartSimulationV4Enabled) {
        const cartSimulationRequest = createCartSimulationRequest(
          field,
          value,
          valuesChanged,
        );

        updateCartSimulationEffect(cartSimulationRequest);
      } else {
        const lineItems = CartItemStates.cartItems
          .filter((each) => each.notFound === false)
          .map((each) => {
            return {
              itemQuantity: each.itemQuantity,
              sku: each.sku,
              id: each.id,
              quantity: each.itemQuantity,
            };
          });

        const cartRequestV3Data = {
          accountId: getValueEmpty(AgentCallStates.clientId), // Request V2 don't have accountId
          paymentTerm: 0,
          paymentMethod: getPaymentMethodValue(
            CartStates.cartForm.paymentMethod,
            field,
            value,
          ),
          deliveryDate: getDeliveryDateValue(
            getValueEmpty(CartStates.cartForm.deliveryDate.split("T")[0]),
            field,
            value,
          ),
          deliveryCenterId: getValueEmpty(pocInformation.deliveryCenterId), // Request V3 don't have deliveryCenterId
          items: lineItems,
          empties: [],
          combos: getCombosRequest(),
          language: TIMEZONE[user.zone]?.locale,
        };

        updateCartEffectV3(cartRequestV3Data);
      }

      const removeDoneWatcher = updateCartEffectVersion.done.watch(
        ({ result }) => {
          effectDoneResult(result);

          /* istanbul ignore next */
          if (typeof removeDoneWatcher === "function") {
            removeDoneWatcher();
          }
          /* istanbul ignore next */
          if (typeof removeFailWatcher === "function") {
            removeFailWatcher();
          }
        },
      );

      /* istanbul ignore next */
      const removeFailWatcher = updateCartEffectVersion.fail.watch(() => {
        /* istanbul ignore next */
        removeFailWatcher();
        /* istanbul ignore next */
        removeDoneWatcher();
        /* istanbul ignore next */
        setNeedToUpdate(true);
      });

      /* istanbul ignore next */
      callErrorMessageViewedTruck();
    },
    [
      AgentCallStates.clientId,
      CartItemStates.cartItems,
      CartStates.cartForm.deliveryDate,
      CartStates.cartForm.paymentMethod,
      callErrorMessageViewedTruck,
      createCartSimulationRequest,
      effectDoneResult,
      getCombosRequest,
      isCartSimulationV4Enabled,
      pocInformation.deliveryCenterId,
      setNeedToUpdate,
      updateCartEffectVersion.done,
      updateCartEffectVersion.fail,
      user.zone,
      isCartAutoUpdate,
    ],
  );

  const isCartLoading = useCallback(() => {
    if (isCartAutoUpdate && isCartBffEnable) {
      return CartItemStates.isLoadingBff;
    }
    if (isCartAutoUpdate) {
      return CartItemStates.isLoading;
    }
    return pendingV3Effect;
  }, [
    CartItemStates.isLoading,
    CartItemStates.isLoadingBff,
    isCartAutoUpdate,
    isCartBffEnable,
    pendingV3Effect,
  ]);

  return {
    needUpdate,
    setNeedToUpdate,
    updateCart,
    getCombosRequest,
    getCurrentUpdateEffect,
    isCartLoading,
  };
};
