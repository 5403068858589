import React from "react";

const MinusIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-343.000000, -199.000000) translate(335.000000, 190.000000) translate(8.000000, 9.000000)">
              <rect width="15" height="15" fill="#FFF" rx="3" />
              <path fill="#505050" d="M3 7H12V8H3z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MinusIcon;
