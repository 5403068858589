import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import GlobalStore from "../../../stores/global/GlobalStore";
import {
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_AGENT_CALL_SERVICE,
} from "../../../config/featureToggles";
import axios from "../../../config/axios/axiosInstance";
import AgentCalls from "../../../domains/AgentCall";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../../host/HostService";
import { AgentCallResponse } from "../domains/AgentCallResponse";
import AgentCallResponseToAgentCallConverter from "../../../converters/nextPoc/getOpenCall/AgentCallResponseToAgentCallConverter";
import { AGENT_CALL_SERVICE } from "../../../config/services";
import VendorStore from "../../../stores/vendor/VendorStore";

export const AGENT_CALL = "/agent-call";
export const OPEN_CALL = "/open-call";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getOpenCall(
  agentId: string,
  headers = {},
): Promise<AgentCalls> {
  const log = useLogService();
  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();
  const isAgentCallV2Enabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_AGENT_CALL_SERVICE,
    user.zone,
  );
  const vendorId = vendor?.id;
  const v2EndPoint = getV2EndPoint(isAgentCallV2Enabled);
  const vendorIdParam = getVendorIdParam(vendorId, isAgentCallV2Enabled);

  const baseURL = `${getApiHost(AGENT_CALL_SERVICE)}${AGENT_CALL}`;
  const endpoint = `${baseURL}${v2EndPoint}/${agentId}${OPEN_CALL}${vendorIdParam}`;

  return axios
    .get<AgentCallResponse>(endpoint, {
      headers,
    })
    .then((response) => {
      log.info(`Convert agentCalls received from ${endpoint} to our model`);
      return AgentCallResponseToAgentCallConverter(
        response.data,
        response.headers.date,
      );
    })
    .catch((error) => {
      log.error("GetOpenCallService - getOpenCall error: ", error);
      throw error;
    });
}
