import { UNDEFINED_GAP } from "@config/constants";
import { GROW_NEW_GAP_CALCULATION_ON } from "@config/featureToggles";
import * as GapCalculationService from "@services/gap/GapCalculationService";
import { Gap } from "@stores/agentCall/AgentCallState";
import { isFeatureEnabled } from "grow-shared-services";

export function execute(
  country: string,
  vendorId: string,
  accountId: string,
): Promise<Gap> {
  if (accountId) {
    const isGrowNewGapCalculationOn = isFeatureEnabled(
      GROW_NEW_GAP_CALCULATION_ON,
    );

    return GapCalculationService.getGapCalculationV3(
      country,
      vendorId,
      accountId,
      isGrowNewGapCalculationOn,
    );
  }
  return Promise.resolve(UNDEFINED_GAP);
}
