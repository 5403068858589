import { createStore } from "effector";
import {
  DELIVERY_METHODS,
  DeliveryRange,
} from "../../domains/fulfillment/Fulfillment";
import {
  getDeliveryWindowsEffect,
  setDeliveryMethod,
  setDeliveryRange,
} from "./DeliveryWindowsEvents";
import DeliveryWindowsState from "./DeliveryWindowsState";

const initialState = <DeliveryWindowsState>{
  done: false,
  error: false,
  deliveryMethod: DELIVERY_METHODS.DELIVERY_WINDOW,
  deliveryWindows: [],
  deliveryRange: {} as DeliveryRange,
};

const deliveryWindowsStore = createStore(initialState)
  .on(setDeliveryMethod, (state: DeliveryWindowsState, deliveryMethod) => ({
    ...state,
    deliveryMethod,
  }))
  .on(setDeliveryRange, (state: DeliveryWindowsState, deliveryRange) => ({
    ...state,
    deliveryRange,
  }))
  .on(
    getDeliveryWindowsEffect.done,
    (state: DeliveryWindowsState, { result }) => ({
      ...state,
      deliveryWindows: result?.deliveryWindows,
      done: true,
      error: false,
    }),
  )
  .on(getDeliveryWindowsEffect.fail, (state: DeliveryWindowsState) => ({
    ...state,
    done: true,
    error: true,
  }));

export default deliveryWindowsStore;
