import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import App from "./bootstrap/App";
import { axiosIntercept } from "./config/axios/axiosInstance";
import { setupLocalEnv } from "./index.dev";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (process.env.STANDALONE && process.env.NODE_ENV === "development") {
  setupLocalEnv();
}

axiosIntercept();

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary() {
    return <></>;
  },
});

export const { bootstrap } = reactLifecycles;
export const { mount } = reactLifecycles;
export const { unmount } = reactLifecycles;
