function GetRemainingDays(date?: string): number | null {
  if (!date) {
    return null;
  }

  const oneDay = 1000 * 60 * 60 * 24;
  const endDate = new Date(date);
  const startDate = new Date();

  const end = Date.UTC(
    endDate.getUTCFullYear(),
    endDate.getUTCMonth(),
    endDate.getUTCDate(),
    0,
    0,
    0,
    0,
  );

  const start = Date.UTC(
    startDate.getUTCFullYear(),
    startDate.getUTCMonth(),
    startDate.getUTCDate(),
    0,
    0,
    0,
    0,
  );

  return (end - start) / oneDay;
}

export default GetRemainingDays;
