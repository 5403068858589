import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@hexa-ui/components";
import { warningMessageViewed } from "../../config/typewriter";
import { useAnalytics } from "../../analytics/useAnalytics";
import Data from "../data/Data";
import MinMaxOrdersValueStyles from "../rewardsPointsCard/RewardsPointsCardStyles";

type analyticsProperties = {
  callId: string;
  pocId: string;
  screenName: string;
  warningMessage: string;
  warningType: string;
  outOfStockSkus: string | null;
  lowStockSkus: string | null;
};
interface Props {
  cartUpdating: boolean;
  valueOrder?: number;
  isMinValue: boolean;
  orderValueMessage: string;
  analyticsProperties?: analyticsProperties;
  isCartNewExhibition: boolean;
}

function MinMaxOrdersValue({
  cartUpdating,
  valueOrder,
  isMinValue,
  orderValueMessage,
  analyticsProperties,
  isCartNewExhibition,
}: Props): React.ReactElement {
  const classes = MinMaxOrdersValueStyles();
  const { t } = useTranslation();
  const { dispatchGenericEvent } = useAnalytics();

  useEffect(() => {
    if (!isMinValue) {
      dispatchGenericEvent(warningMessageViewed, {
        call_id: analyticsProperties?.callId,
        poc_id: analyticsProperties?.pocId,
        screen_name: analyticsProperties?.screenName,
        warning_message: analyticsProperties?.warningMessage,
        warning_type: analyticsProperties?.warningType,
        out_of_stock_skus: analyticsProperties?.outOfStockSkus,
        low_stock_skus: analyticsProperties?.lowStockSkus,
      });
    }
  }, [isMinValue, analyticsProperties, dispatchGenericEvent]);

  const getMountMinMaxOrdersValue = () => {
    if (isMinValue) {
      return (
        <span
          className={isCartNewExhibition ? "" : classes.totalPoints}
          data-testid="min-orders-value-card-label"
        >
          {orderValueMessage}
        </span>
      );
    }

    const message = t(orderValueMessage).split("ORDER_VALUE");

    return (
      <span
        className={isCartNewExhibition ? "" : classes.totalPoints}
        data-testid="max-orders-value-card-label"
      >
        <span>{`${message[0]}`} </span>
        <Data
          testId="max-orders-value"
          value={valueOrder?.toFixed(2)}
          type="currency"
        />
        <span>{`${message[1]}`}</span>
      </span>
    );
  };

  return (
    <div
      className={isCartNewExhibition ? classes.newContainer : classes.container}
      data-testid="min-max-orders-container"
    >
      {!cartUpdating ? (
        <>
          {isCartNewExhibition ? (
            <Alert
              data-testid="min-max-orders-content"
              css={{ width: "100%" }}
              message={getMountMinMaxOrdersValue()}
            />
          ) : (
            <span
              className={classes.message}
              data-testid="min-max-orders-content"
            >
              {getMountMinMaxOrdersValue()}
            </span>
          )}
        </>
      ) : (
        <span
          className={classes.message}
          data-testid="min-max-orders-value-loading"
        >
          <CircularProgress size={20} className={classes.loading} />
        </span>
      )}
    </div>
  );
}

MinMaxOrdersValue.defaultProps = {
  valueOrder: null,
  analyticsProperties: null,
};

/* istanbul ignore next */
const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.analyticsProperties === nextProps.analyticsProperties &&
    prevProps.cartUpdating === nextProps.cartUpdating
  );
};

export default React.memo(MinMaxOrdersValue, areEqual);
