import React from "react";
import { useTranslation } from "react-i18next";
import palletSizeStyles from "./PalletSizeStyles";

interface Props {
  value?: number;
}

const PalletSize: React.FunctionComponent<Props> = ({ value }) => {
  const classes = palletSizeStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container} data-testid="pallets-unit-text">
      {value
        ? `${value} ${t("OrderTaking.PALLETS_UNITS")}`
        : t("OrderTaking.PALLETS_UNITS_UNAVAILABLE")}
    </div>
  );
};

export default PalletSize;
