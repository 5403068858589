import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      marginTop: "16px",
      padding: "0px",
    },
  }),
);
