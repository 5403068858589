import LoyaltyResponse from "../services/domains/LoyaltyResponse";
import Rewards from "../domains/Rewards";

export default function LoyaltyResponseToRewardsConverter(
  data: LoyaltyResponse,
): Rewards {
  return {
    tier: data.tier,
    balance: data.balance != null ? data.balance : undefined,
    dateRegistered:
      data.dateRegistered !== null && data.dateRegistered !== ""
        ? data.dateRegistered
        : undefined,
  };
}
