import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { Box, Grid, CircularProgress } from "@material-ui/core";

import GenericCard from "./components/GenericCard";
import TimeCard from "./components/TimeCard";

import MonthlyKpiStore from "../../../stores/agentKPI/MonthlyKPIStore";
import { getMonthlyKPIEffect } from "../../../stores/agentKPI/MonthlyKPIEvents";

import { TARGET_KPI_TYPES } from "../../../config/constants";

import useTargetKpis from "../hooks/useTargetKpis";

import palette from "../../../theme/Palette";
import monthlyKpisStyles from "./MonthlyKpisStyles";

interface Props {
  login: string;
  zone: string;
  vendorId: string;
  vendorName: string;
}

const MonthlyKpis: React.FunctionComponent<Props> = ({
  login,
  zone,
  vendorId,
  vendorName,
}) => {
  const classes = monthlyKpisStyles();
  const { t } = useTranslation();
  const { monthly, isLoading, done } = useStore(MonthlyKpiStore);
  const {
    shouldRenderMonthlyCallListTarget,
    monthlyCallListTarget,
    monthlyToplineUpliftTarget,
    monthlyAverageHandleTimeTarget,
    isTargetVisible,
    shouldRenderMonthlyAht,
    shouldRenderMonthlyToplineUplift,
  } = useTargetKpis();

  useEffect(() => {
    if (!done) {
      getMonthlyKPIEffect({ login, zone, vendorId, vendorName });
    }
  }, [login, zone, done, vendorId, vendorName]);

  /* istanbul ignore next */
  const getPercentage = (value: number, total?: number): number => {
    if (!total) {
      return 0;
    }
    return (value * 100) / total;
  };

  if (isLoading) {
    return (
      <Grid container item xs>
        <Box className={classes.loadingContainer}>
          <CircularProgress data-testid="kpis-loading-monthly" />
        </Box>
      </Grid>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <span>{t("TotalPerformance.TITLE")}</span>
      </div>
      {shouldRenderMonthlyCallListTarget && (
        <div>
          <GenericCard
            mainValue={getPercentage(
              monthly.monthlyPocAssigned,
              monthlyCallListTarget,
            )}
            secondaryValue={monthly.monthlyPocAssigned}
            targetValue={monthlyCallListTarget}
            mainValueLabel={t("TargetCallList.COMPLETED_TEXT")}
            targetValueLabel={t("TargetCallList.TARGET_TEXT")}
            title={t("TargetCallList.TITLE")}
            testId="call-list-monthly"
            data-testid="call-list-monthly-container"
          />
        </div>
      )}

      {isTargetVisible(TARGET_KPI_TYPES.HR) && (
        <div>
          <GenericCard
            mainValue={getPercentage(
              monthly.monthlyHitRate,
              monthly.monthlyPocAssigned,
            )}
            secondaryValue={monthly.monthlyHitRate}
            targetValue={monthly.monthlyPocAssigned}
            mainValueLabel={t("TotalPerformance.EFFECTIVE_CALLS")}
            targetValueLabel={t("TotalPerformance.TOTAL_CALLS")}
            title={t("HitRate.TITLE")}
            testId="hit-rate-monthly"
            targetColor={palette.info.contrastText}
          />
        </div>
      )}

      {shouldRenderMonthlyToplineUplift && (
        <div>
          <GenericCard
            mainValue={getPercentage(
              monthly.monthlyToplineUplift,
              monthly.monthlyTotalGap,
            )}
            secondaryValue={monthly.monthlyToplineUplift}
            targetValue={monthlyToplineUpliftTarget}
            mainValueLabel={t("TotalPerformance.REVENUE_UPLIFTED")}
            targetValueLabel={t("TotalPerformance.TARGET_UPLIFTED")}
            title={t("ToplineUplift.TITLE_CD")}
            testId="tlp-uplift-monthly"
            data-testid="tlp-uplift-monthly-container"
            isCurrency
            targetColor={palette.info.dark}
          />
        </div>
      )}

      {shouldRenderMonthlyAht && (
        <div>
          <TimeCard
            mainValue={monthly.monthlyAverageHandleTime}
            testId="AHT-monthly"
            data-testid="AHT-monthly-container"
            target={monthlyAverageHandleTimeTarget}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(MonthlyKpis);
