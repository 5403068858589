import { createEffect, createEvent } from "effector";
import * as GetCampaignsUseCase from "../../usecase/campaigns/GetCampaignsUseCase";
import { GetCampaignsPayload } from "../../domains/Campaigns";

export const getCampaigns = createEffect({
  async handler(getCampaignsPayload: GetCampaignsPayload) {
    return GetCampaignsUseCase.execute(getCampaignsPayload);
  },
});

export const resetCampaign = createEvent<null>("resetCampaign");
export const setValidCampaigns =
  createEvent<Array<string>>("setValidCampaigns");
