import React from "react";
import { Box } from "@material-ui/core";
import { Paragraph } from "@hexa-ui/components";

import { useCartFreeGoodsHook } from "../hooks/useCartFreeGoodsHook";
import useStyles from "./FreeGoodsItemsList.styles";
import { FreeGoodsItemCard } from "../freeGoodsItemCard/FreeGoodsItemCard";

const FreeGoodsItemsList = (): JSX.Element => {
  const classes = useStyles();

  const { formatedFreeGoodItems } = useCartFreeGoodsHook();

  return (
    <div data-testid="free-goods-list-card-area">
      {formatedFreeGoodItems.map((freeGood) => (
        <Box
          key={freeGood.hashCode}
          data-testid={`free-goods-list-container-${freeGood.hashCode}`}
          className={classes.freeGoodProductCardContainer}
        >
          <Box marginBottom={2}>
            <Paragraph
              data-testid={`free-goods-list-title-${freeGood.hashCode}`}
              size="basis"
              weight="semibold"
            >
              {freeGood.displayTitle}
            </Paragraph>
            <Paragraph
              data-testid={`free-goods-list-subtitle-${freeGood.hashCode}`}
              size="xsmall"
              colortype="secondary"
            >
              {freeGood.displaySubtitle}
            </Paragraph>
          </Box>

          {freeGood.items?.map((product) => (
            <Box
              key={product.platformId}
              className={classes.freeGoodProductCard}
              data-testid={`free-goods-list-item-${product.platformId}`}
            >
              <FreeGoodsItemCard
                data-testid={`free-goods-item-${product.platformId}`}
                key={product.sku}
                item={product}
                disabled={
                  freeGood.availableQuantity === 0 &&
                  product.selectedQuantity === 0
                }
              />
            </Box>
          ))}
        </Box>
      ))}
    </div>
  );
};

export default FreeGoodsItemsList;
