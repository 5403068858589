import React from "react";
import { useTranslation } from "react-i18next";
import { Store, Tag, Package, Maximize2, Gift } from "@hexa-ui/icons";
import { useStore } from "effector-react";
import LocalMissionTag from "../components/localMissionsTag/LocalMissionsTag";
import Data from "../../../../../components/data/Data";

import PromotionsStore from "../../../../../stores/promotions/PromotionsStore";
import {
  MissionProduct,
  Product,
} from "../../../../../domains/products/Product";
import MarketplaceStore from "../../../../../stores/marketplace/MarketplaceStore";
import { getValueWithThousandsSeparator } from "../../../../../config/utils/functions";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import SuggestedOrdersStore from "../../../../../stores/suggestedOrders/SuggestedOrdersStore";
import loyaltyBusinessStore from "../../../../../stores/loyaltyBusiness/LoyaltyBusinessStore";
import EarnRule from "../../../../../domains/EarnRule";

export enum TAGSLIST {
  COMBO = "combo",
  PROMO = "promo",
  MARKETPLACE = "marketplace",
  GAP = "gap",
  REWARDS = "rewards",
}

const useLocalMissionsTag = (
  product: Product | MissionProduct,
): JSX.Element[] => {
  const { t } = useTranslation();
  const { promotions } = useStore(PromotionsStore);
  const { marketplaceList } = useStore(MarketplaceStore);
  const { suggestedOrders } = useStore(SuggestedOrdersStore);
  const { rules } = useStore(loyaltyBusinessStore);
  const {
    user: { zone },
  } = useStore(GlobalStore);

  const itemSku = product?.sku;

  const itemPlatformId = product?.platformId;

  const tagsToRender: JSX.Element[] = [];

  const defaultIconSize = "tiny";

  const marketPlaceTagIcon = <Store size={defaultIconSize} />;

  const promoTagIcon = <Tag size={defaultIconSize} />;

  const comboTagIcon = <Package size={defaultIconSize} />;

  const gapTagIcon = <Maximize2 size={defaultIconSize} />;

  const rewardsTagIcon = <Gift size={defaultIconSize} />;

  function getGap() {
    const suggestedOrderFound = suggestedOrders.find(
      (suggestedOrder) => suggestedOrder.sku === itemSku,
    );
    /* istanbul ignore next */
    return {
      isGap: Boolean(suggestedOrderFound),
      gapValue: getValueWithThousandsSeparator(
        t(`Formater.${zone}.THOUSAND_SEPARATOR`) || ",",
        suggestedOrderFound?.gapBoxes,
      ),
    };
  }

  function isPromo(): boolean {
    return !!promotions?.some((promotion) => promotion.sku === itemSku);
  }

  function isMarketplace(): boolean {
    return marketplaceList.some(
      (marketplaceItem) => marketplaceItem.sku === itemSku,
    );
  }

  function isReward(): boolean {
    return rules.some(
      (rewardsItem) => rewardsItem.platformId === itemPlatformId,
    );
  }

  function getRewardsValues(): EarnRule | undefined {
    return rules.find(
      (rewardsItem) => rewardsItem.platformId === itemPlatformId,
    );
  }

  const rewardValues = getRewardsValues();
  const rewardPoints = rewardValues?.points;
  const rewardAmountSpent = rewardValues?.amountSpent;
  const gap = getGap();

  const renderRewardsTitle = () => {
    /* istanbul ignore next */
    return (
      <>
        {rewardPoints === 1
          ? t("Sponsored.TAG_REWARDS_POINT")
          : `${rewardPoints} ${t("Sponsored.TAG_REWARDS_POINTS")}`}
        <Data value={rewardAmountSpent} type="currency" />
      </>
    );
  };

  function isPointsAndAmountAvailable() {
    return (
      rewardPoints &&
      rewardAmountSpent &&
      rewardPoints > 0 &&
      rewardAmountSpent > 0
    );
  }

  const comboTag = (
    <LocalMissionTag
      title={t("Sponsored.TAG_COMBO")}
      icon={comboTagIcon}
      key={`tag-component-${TAGSLIST.COMBO}`}
      backgroundIconColor="#f7f72a"
    />
  );

  const promoTag = (
    <LocalMissionTag
      title={t("Sponsored.TAG_PROMO")}
      icon={promoTagIcon}
      key={`tag-component-${TAGSLIST.PROMO}`}
      backgroundIconColor="#81ffae"
    />
  );

  const marketplaceTag = (
    <LocalMissionTag
      title={t("Sponsored.TAG_MARKETPLACE")}
      icon={marketPlaceTagIcon}
      key={`tag-component-${TAGSLIST.MARKETPLACE}`}
      backgroundIconColor="#f6b142"
    />
  );

  const gapTag = (
    <LocalMissionTag
      title={`${t("Sponsored.TAG_GAP")} ${gap?.gapValue}`}
      icon={gapTagIcon}
      key={`tag-component-${TAGSLIST.GAP}`}
      backgroundIconColor="#FFA4EE"
    />
  );

  const rewardsTag = (
    <LocalMissionTag
      title={renderRewardsTitle()}
      icon={rewardsTagIcon}
      key={`tag-component-${TAGSLIST.REWARDS}`}
      backgroundIconColor="#C9ABFF"
    />
  );

  if (gap?.isGap) {
    tagsToRender.push(gapTag);
  }

  if (!product?.isSponsored && isMarketplace()) {
    tagsToRender.push(marketplaceTag);
  }

  if (isPromo()) {
    tagsToRender.push(promoTag);
  }

  if (!!product && product.isCombo) {
    tagsToRender.push(comboTag);
  }

  if (isReward() && isPointsAndAmountAvailable()) {
    tagsToRender.push(rewardsTag);
  }

  return tagsToRender;
};

export default useLocalMissionsTag;
