import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    imageWrapper: {
      height: "3.75rem",
      width: "3.75rem",
      "& img": {
        objectFit: "contain",
        height: "3.75rem",
        width: "3.75rem",
      },
    },
  }),
);
