import { createEffect, createEvent } from "effector";
import { executeGetUserConfig } from "../../usecase/globaAdminConfig/GlobalAdminConfigUseCase";

interface GetUserConfigInput {
  userId: string;
  vendorId?: string;
}

export const getUserConfig = createEffect({
  async handler(getUserConfigInput: GetUserConfigInput) {
    const { userId, vendorId } = getUserConfigInput;
    return executeGetUserConfig(userId, vendorId);
  },
});

export const setVendorId = createEvent<string>("setVendorId");
