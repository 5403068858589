import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";
import {
  GROW_TEMP_ACCEPT_LANGUAGE_CHECKOUT,
  isFeatureEnabledV2,
} from "@config/featureToggles";
import { TIMEZONE } from "@config/timeZone";
import { getCountryByToken } from "@config/utils/functions";

import { DEFAULT_LANGUAGE } from "@i18n/domains/LanguageOptions";
import CheckoutResponseToCheckoutInfoConverter from "../../converters/checkout/CheckoutResponseToCheckoutInfoConverter";
import { CheckoutInfo } from "../../domains/CheckoutInfo";
import { getApiHost, getEndPoint } from "../host/HostB2BService";
import CheckoutRequest from "./domains/CheckoutRequest";
import { CheckoutResponse } from "./domains/CheckoutResponse";

const CHECKOUT_SERVICE = "checkout-service";

export function checkout(request: CheckoutRequest): Promise<CheckoutInfo> {
  const log = useLogService();
  const endpoint = `${getApiHost()}${getEndPoint()}/${CHECKOUT_SERVICE}/v3`;
  const country = getCountryByToken();
  const isFixAcceptLanguageEnabled = isFeatureEnabledV2(
    GROW_TEMP_ACCEPT_LANGUAGE_CHECKOUT,
    getCountryByToken(),
  );

  let headers = {};
  if (isFixAcceptLanguageEnabled) {
    /* istanbul ignore next */
    headers = {
      ...headers,
      "Accept-Language": TIMEZONE[country]?.locale ?? DEFAULT_LANGUAGE,
    };
  }

  return axios
    .post<CheckoutResponse>(endpoint, request, {
      headers,
    })
    .then((response) => {
      log.info(
        `Convert Checkout Response received from ${endpoint} to our model`,
      );

      return CheckoutResponseToCheckoutInfoConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
