import { createEvent, createEffect } from "effector";
import { CreditResponse } from "../../../services/pocInformation/domains/CreditResponse";
import * as searchByPocUseCase from "../../../usecase/pocInformation/SearchByPocUseCase";
import PocInformation from "../../../domains/PocInformation";
import SearchPocRequest from "../../../services/pocInformation/domains/SearchPocRequest";

export const findByAccountId = createEvent<PocInformation>("findByAccountId");

export const setCreditPocInformation = createEvent<CreditResponse>(
  "setCreditPocInformation",
);

export const searchPocByAccountId = createEffect({
  async handler(searchPoc: SearchPocRequest) {
    return searchByPocUseCase.execute(searchPoc);
  },
});

export const pocInformationFail = createEvent<null>("pocInformationFail");

export const resetPocInformation = createEvent<null>("resetPocInformation");
