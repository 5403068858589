import {
  isFeatureEnabledV2,
  GROW_BEES_ACCOUNTS_MULTICONTRACT,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../config/featureToggles";
import {
  searchPocByAccountId,
  searchPocByAccountIdV2,
} from "../../services/pocInformation/SearchByPocService";
import { findBeesContracts } from "../../services/pocInformation/beesContracts/BeesContractsService";
import { SearchedPoc } from "../../domains/PocInformation";
import SearchPocRequest from "../../services/pocInformation/domains/SearchPocRequest";

export function execute(searchPoc: SearchPocRequest): Promise<SearchedPoc> {
  const isFindBeesContracts = isFeatureEnabledV2(
    GROW_BEES_ACCOUNTS_MULTICONTRACT,
    searchPoc.country,
  );

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    searchPoc.country,
  );
  const searchPocByAccountIdCallback = isOrganizationConfigEnabled
    ? searchPocByAccountIdV2
    : searchPocByAccountId;

  if (isFindBeesContracts) {
    return searchPocByAccountIdCallback(searchPoc).then((response) => {
      const responseAccounts = response as SearchedPoc;
      const id =
        responseAccounts.customerAccountId ??
        responseAccounts.vendorAccountId ??
        responseAccounts.accountId;

      if (!id) return {};

      if (response.isContractless) return responseAccounts;

      /*
       * customerAccountId to use in beescontracts;
       */
      searchPoc.accountId = id;
      return findBeesContracts(searchPoc).then((response) => {
        const responseContracts = response as SearchedPoc;
        responseAccounts.accountId = responseContracts.accountId;
        responseAccounts.legacyAccountId = responseContracts.legacyAccountId;
        responseAccounts.hasPONumberRequirement =
          responseContracts.hasPONumberRequirement;

        return responseAccounts;
      });
    });
  }

  return searchPocByAccountIdCallback(searchPoc);
}
