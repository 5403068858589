import React from "react";
import { useTranslation } from "react-i18next";
import { Phone } from "domains/phoneRanking/RankedPhones";
import ErrorRetry from "../../../../../../../../components/errorRetry/ErrorRetry";
import { ANALYTICS_ROUTE_NAMES } from "../../../../../../../../config/constants";
import { formatTranslationWithPhoneNumber } from "../../../../../../../../config/utils/functions";

interface PhoneTypeCellProps {
  phones: Phone[];
  currentPhoneNumber: string;
  callTab: string;
  reloadPhones: () => void;
}

const ErrorBar = ({
  phones,
  currentPhoneNumber,
  callTab,
  reloadPhones,
}: PhoneTypeCellProps): JSX.Element => {
  const { t } = useTranslation();

  const renderError = () => {
    /* istanbul ignore next */
    const activePhone = phones.find(
      (phone) => phone.phoneNumber === currentPhoneNumber,
    );

    const isPhoneInactive = activePhone?.score === -99;

    /* istanbul ignore next */
    const message = isPhoneInactive ? "ACTIVATE" : "INACTIVATE";

    return (
      <ErrorRetry
        onClick={reloadPhones}
        screenName={ANALYTICS_ROUTE_NAMES.get(callTab)}
        screenSection="CTI Modal"
        message={t("ErrorRetry.RETRY_INFORMATION_BUTTON_LABEL")}
        size="32px"
        errorMessage={formatTranslationWithPhoneNumber(
          t(`PHONE_RANKING_TOAST_ALERTS.FAIL_${message}`),
          currentPhoneNumber,
        )}
        errorType="no_connection"
      />
    );
  };

  return renderError();
};

export default ErrorBar;
