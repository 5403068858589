import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    callDurationContainer: {
      display: "flex",
      padding: "4px",
      alignItems: "center",
      gap: "0.5rem",
      "& svg": {
        color: "#A7A7A7",
      },
    },
    callDurationInfoContainer: {
      display: "flex",
      gap: "1.25rem",
    },
    callDurationInfoDetailsContainer: {
      display: "flex",
      gap: "0.25rem",

      "& span": {
        fontFamily: "Work Sans",
        fontSize: "0.875rem",
        lineHeight: "1rem",
        fontWeight: "500",
        letterSpacing: "0",
      },
    },
    offlineValue: {
      color: "#c9201d",
    },
    onlineValue: {
      color: "#176D38",
    },
  }),
);
