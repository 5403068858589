import { Box, CircularProgress } from "@material-ui/core";
import { useStore } from "effector-react";
import React, { useCallback, useEffect, useState } from "react";
import MinMaxOrdersValue from "../../../../../components/minMaxOrdersValue/MinMaxOrdersValue";
import RewardsPointsCard from "../../../../../components/rewardsPointsCard/RewardsPointsCard";
import {
  GROW_CART_SERVICE_V4,
  GROW_NEW_CART_EXHIBITION,
  GROW_TECH_CART_AUTO_UPDATE,
  TLP_CREDIT_LIMIT,
  TLP_REWARDS_PROGRAM_TRACK_POINTS,
  TLP_REWARDS_PROGRAM_TRACK_POINTS_ADMIN,
  isFeatureEnabled,
  isFeatureEnabledV2,
} from "../../../../../config/featureToggles";
import { getCountryByToken } from "../../../../../config/utils/functions";
import {
  updateCartEffectV3,
  updateCartSimulationEffect,
} from "../../../../../stores/cart/CartItemEvents";
import cartItemStore from "../../../../../stores/cart/CartItemStore";
import CartComboStore from "../../../../../stores/cartCombo/CartComboStore";
import CreditStore from "../../../../../stores/credit/CreditStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import loyaltyProgramStore from "../../../../../stores/loyaltyProgram/LoyaltyProgramStore";
import Price from "../../orderSummary/price/Price";
import UpdatePrice from "../../orderSummary/updatePrice/UpdatePrice";
import OrderCartStyles from "../OrderCartStyles";
import { updateCartValuesType } from "../hooks/utils/simulationCart";

interface Props {
  needUpdate: boolean;
  loadingUpdateCart: boolean;
  updateCart: (valuesChanged?: updateCartValuesType) => void;
  cartLength: number;
}
const PriceArea: React.FunctionComponent<Props> = ({
  needUpdate,
  loadingUpdateCart,
  updateCart,
  cartLength,
}) => {
  const classes = OrderCartStyles();

  const { user } = useStore(GlobalStore);
  const CartItemStates = useStore(cartItemStore);
  const { cartComboItems } = useStore(CartComboStore);
  const { credit } = useStore(CreditStore);
  const [cartFirstLoadDone, setCartFirstLoadDone] = useState<boolean>(false);

  const isRewardsProgramEnabled = isFeatureEnabled(
    TLP_REWARDS_PROGRAM_TRACK_POINTS,
    TLP_REWARDS_PROGRAM_TRACK_POINTS_ADMIN,
    user.keyToggle,
  );

  const isCreditLimitEnabled = isFeatureEnabled(
    TLP_CREDIT_LIMIT,
    user.keyToggle,
  );

  const isCartSimulationV4Enabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    getCountryByToken(),
  );
  const isCartAutoUpdate = isFeatureEnabledV2(
    GROW_TECH_CART_AUTO_UPDATE,
    user.keyToggle,
  );
  const isCartNewExhibition = isFeatureEnabledV2(
    GROW_NEW_CART_EXHIBITION,
    user.keyToggle,
  );

  const { balance } = useStore(loyaltyProgramStore);

  useEffect(() => {
    /* istanbul ignore else */
    if (isCartAutoUpdate) {
      setCartFirstLoadDone(true);
    }
  }, [isCartAutoUpdate]);

  /* istanbul ignore next */
  const updateCartCallBack = useCallback(
    (totalItems: number) => {
      const updateCartEffectVersion = isCartSimulationV4Enabled
        ? updateCartSimulationEffect
        : updateCartEffectV3;

      if (isCartSimulationV4Enabled) updateCart();
      else if (totalItems > 0 || CartItemStates.totalItems > 0) {
        updateCart();
      } else {
        setCartFirstLoadDone(true);
      }

      const removeFinallyWatch = updateCartEffectVersion.finally.watch(() => {
        setCartFirstLoadDone(true);
        removeFinallyWatch();
      });
    },
    [CartItemStates.totalItems, isCartSimulationV4Enabled, updateCart],
  );

  useEffect(() => {
    const { cartItems } = CartItemStates;

    /* istanbul ignore next */
    const totalItems =
      cartItems
        .filter((item) => item.notFound === false)
        .map((item) => item.itemQuantity)
        .reduce((currentTotal, quantity) => currentTotal + quantity, 0) +
      (cartComboItems
        .map((item) => item.itemQuantity)
        .reduce(
          (currentTotal, quantity) =>
            (currentTotal || 0) + (quantity /* istanbul ignore next */ || 0),
          0,
        ) || 0);

    updateCartCallBack(totalItems);
    /* eslint-disable-next-line */
  }, []);

  const handleRewardsPoints = () => {
    if (
      isRewardsProgramEnabled &&
      cartLength > 0 &&
      balance !== undefined &&
      balance !== null
    ) {
      return (
        <RewardsPointsCard
          isCartNewExhibition={isCartNewExhibition}
          cartUpdating={loadingUpdateCart}
        />
      );
    }

    return <></>;
  };
  const handleMinMaxOrdersValue = () => {
    if (CartItemStates.minimumOrder?.isBelowMinimumOrder) {
      return (
        <MinMaxOrdersValue
          cartUpdating={loadingUpdateCart}
          isCartNewExhibition={isCartNewExhibition}
          isMinValue
          orderValueMessage={CartItemStates.minimumOrder.minOrderMessage ?? ""}
        />
      );
    }

    /* istanbul ignore next */
    if (
      isCreditLimitEnabled &&
      credit?.availableValue &&
      CartItemStates.cart.total &&
      CartItemStates.cart.total > credit.availableValue
    ) {
      return (
        <MinMaxOrdersValue
          cartUpdating={loadingUpdateCart}
          isCartNewExhibition={isCartNewExhibition}
          valueOrder={credit.availableValue}
          isMinValue={false}
          orderValueMessage="CartValidation.CREDIT_LIMIT_VALUE_MESSAGE"
        />
      );
    }

    return <></>;
  };

  /* istanbul ignore next */
  if (!cartFirstLoadDone)
    return (
      <div className={classes.circularProgressWrapper}>
        <CircularProgress
          data-testid="circularProgress"
          size={20}
          disableShrink
        />
      </div>
    );

  return (
    <Box>
      {needUpdate ? (
        <UpdatePrice loading={loadingUpdateCart} onClicked={updateCart} />
      ) : (
        <>
          {isCartNewExhibition ? (
            <>
              {handleRewardsPoints()}
              {!loadingUpdateCart && handleMinMaxOrdersValue()}
              <Price isCartNewExhibition loading={loadingUpdateCart} />
            </>
          ) : (
            <>
              {handleRewardsPoints()}
              {handleMinMaxOrdersValue()}
              <Price isCartNewExhibition={false} loading={loadingUpdateCart} />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default React.memo(PriceArea);
