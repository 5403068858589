import DeliveryWindows from "../../domains/deliveryWindow/DeliveryWindows";
import DeliveryWindowsResponse from "../../services/deliveryWindows/domains/DeliveryWindowsResponse";
import DeliveryWindowsDomain from "../../domains/deliveryWindow/DeliveryWindowsDomain";

export default function DeliveryWindowResponseToDeliveryWindowConverter(
  data: DeliveryWindowsResponse,
): DeliveryWindowsDomain {
  const deliveryWindowsContent: DeliveryWindows[] = [];

  if (data.content?.length > 0) {
    data.content?.forEach((element) => {
      deliveryWindowsContent.push({
        deliveryWindowId: element.deliveryWindowId,
        deliveryScheduleId: element.deliveryScheduleId,
        deliveryDate: element.deliveryDate,
        expirationDate: element.expirationDate,
        type: element.type,
        alternative: element.type === "FLEX",
      });
    });
  }

  const deliveryWindows: DeliveryWindowsDomain = {
    deliveryWindows: deliveryWindowsContent,
  };

  return deliveryWindows;
}
