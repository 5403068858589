import React from "react";
import { useStore } from "effector-react";
import { callUxButtonClicked } from "../../../../../../config/typewriter";
import { PayWithProps } from "../VariablePrice/VariablePriceComponent";
import IconCopy from "../icons/IconCopy";
import ServicesContentStyle from "../ServicesContent/ServicesContentStyle";
import GlobalStore from "../../../../../../stores/global/GlobalStore";
import Data from "../../../../../../components/data/Data";
import AgentCallStore from "../../../../../../stores/agentCall/AgentCallStore";

interface Props {
  remainingBalance: number | undefined;
  pricingPresetValue: number | BigInt;
  pricingMethod: string;
  // eslint-disable-next-line react/require-default-props
  payWith?: PayWithProps;
  copied: boolean;
  setCopied: (boolean) => void;
}

function SubTotal({
  pricingPresetValue,
  remainingBalance,
  pricingMethod,
  payWith,
  copied,
  setCopied,
}: Props): JSX.Element {
  const classes = ServicesContentStyle();
  const { language } = useStore(GlobalStore);
  const { callId } = useStore(AgentCallStore);

  if (pricingPresetValue && Number(pricingPresetValue.valueOf()) > 0)
    return (
      <div
        data-testid="service-preset-calculator-subtotal-points"
        style={{ display: "flex" }}
      >
        {(remainingBalance && remainingBalance > 0) ||
        remainingBalance === 0 ? (
          <button
            data-testid="service-preset-calculator-subtotal-copy-button"
            type="button"
            className={`${!copied && classes.pointer}`}
            style={{
              background: "none",
              color: "inherit",
              border: "none",
              padding: 0,
              font: "inherit",
              outline: "inherit",
            }}
            onClickCapture={() => {
              navigator.clipboard.writeText(`${pricingPresetValue}`);

              callUxButtonClicked({
                button_label: "service subtotal copy button",
                button_name: "service subtotal copy button",
                call_id: callId,
                filter_option: null,
                screen_name: "Call - GAP and Status",
                screen_section: pricingMethod,
                mission: "Services",
                position: null,
                order_date: null,
              });

              setCopied(true);
            }}
            onMouseLeave={() => setCopied(false)}
          >
            <IconCopy color="#0363C4" />
          </button>
        ) : (
          <IconCopy />
        )}
        <span
          className={classes.subTotal}
          style={
            payWith?.type === "price" ? { display: "flex", gap: "10px" } : {}
          }
        >
          {payWith?.type === "price" && `${payWith.currency}` && language.label}
          <Data
            className={`${classes.subTotal} ${classes.blockBox}`}
            value={pricingPresetValue.toLocaleString("en")}
            type="text"
            emptyMessage="0"
          />{" "}
          {payWith?.type === "points" && " pts"}
        </span>
      </div>
    );

  /* istanbul ignore next */
  return (
    <div data-testid="service-preset-calculator-subtotal-disabled">
      <IconCopy />
      <span className={`${classes.subTotal}`}>
        {payWith?.type === "price" && `${payWith.currency} `}0{" "}
        {payWith?.type === "points" && " pts"}
      </span>
    </div>
  );
}

export default SubTotal;
