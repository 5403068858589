import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <defs>
        <path
          id="SearchIcon-1"
          d="M8.1 0a8.1 8.1 0 016.329 13.156l3.307 3.308a.9.9 0 01-1.188 1.347l-.084-.075-3.308-3.307A8.1 8.1 0 118.1 0zm0 1.8a6.3 6.3 0 104.371 10.837c.024-.031.05-.06.078-.088l.088-.078A6.3 6.3 0 008.1 1.8z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-843 -221)">
          <g transform="translate(835 213)">
            <g transform="translate(8 8)">
              <g transform="translate(3 3)">
                <mask id="SearchIcon-2" fill="#fff">
                  <use xlinkHref="#SearchIcon-1" />
                </mask>
                <use fill="#000" fillRule="nonzero" xlinkHref="#SearchIcon-1" />
                <g fill="#707372" mask="url(#SearchIcon-2)">
                  <path d="M0 0H24V24H0z" transform="translate(-3 -3)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SearchIcon;
