import React, { useEffect } from "react";
import { useStore } from "effector-react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  isFeatureEnabled,
  TLP_PROMOTIONS,
  TLP_PROMOTIONS_ADMIN,
  TLP_MISSIONS_LAYOUT,
  TLP_MISSIONS_LAYOUT_ADMIN,
} from "@config/featureToggles";
import GlobalStore from "@stores/global/GlobalStore";
import { errorMessageViewed } from "@config/typewriter";
import { useAnalytics } from "@analytics/useAnalytics";
import TagsStore from "@stores/tags/TagsStore";
import VendorStore from "@stores/vendor/VendorStore";
import AgentCallStore from "@stores/agentCall/AgentCallStore";
import { getGlobalAndLocalMissionsEvent } from "@stores/tags/TagsEvents";
import MissionsIcon from "@assets/newIcons/missions/MissionsSectionIcon";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/v2/ErrorHandlerRetry";
import TabsMissionsSkeleton from "../../components/tabsMissions/TabsMissionsSkeleton";
import CallInfo from "../../components/callInfo/CallInfo";
import TabsMissions from "../../components/tabsMissions/TabsMissions";
import Offerings from "../../components/offerings/Offerings";
import useMissionsTab from "./useMissionsTab";
import useMissionsStyles from "./style";

const Missions: React.FC = () => {
  const { user } = useStore(GlobalStore);
  const {
    isLoaded: missionsServiceIsDone,
    isLoading: missionsServiceIsLoading,
    hasError: missionsServiceHasError,
  } = useStore(TagsStore);
  const missionsTab = useMissionsTab();
  const classes = useMissionsStyles();
  const { t } = useTranslation();
  const { dispatchPocEvent } = useAnalytics();
  const {
    tagsList,
    localMissionIds,
    callId,
    countryCode,
    clientId,
    pocCallOrigin,
  } = useStore(AgentCallStore);
  const { vendor } = useStore(VendorStore);

  const isPromotionsEnabled = isFeatureEnabled(
    TLP_PROMOTIONS,
    TLP_PROMOTIONS_ADMIN,
  );

  const isMissionsEnabled = isFeatureEnabled(
    TLP_MISSIONS_LAYOUT,
    TLP_MISSIONS_LAYOUT_ADMIN,
    user.keyToggle,
  );

  /* istanbul ignore next */
  useEffect(() => {
    if (missionsTab.length < 1 && missionsServiceIsDone) {
      dispatchPocEvent(
        errorMessageViewed,
        {
          screen_name: null,
          error_type: "no tags sent error",
          error_message: "There are no missions to be displayed",
          screen_section: "Gap and Status Header",
          is_all_products: null,
        },
        { time_of_day: true, is_resumed: true },
      );
    }
  }, [dispatchPocEvent, missionsTab, missionsServiceIsDone]);

  /* istanbul ignore next */
  useEffect(() => {
    if (missionsServiceHasError) {
      dispatchPocEvent(
        errorMessageViewed,
        {
          screen_name: "missions",
          error_type: "no_connection",
          error_message: "We couldn't load the missions",
          screen_section: "Local Content",
          is_all_products: null,
          call_id: callId || null,
          country: countryCode || null,
          poc_id: clientId || null,
        },
        { time_of_day: true, is_resumed: true },
      );
    }
  }, [
    callId,
    clientId,
    countryCode,
    dispatchPocEvent,
    missionsServiceHasError,
  ]);

  const renderContent = () => {
    if (missionsTab.length < 1 && missionsServiceIsDone) {
      return (
        <div
          data-testid="no-missions-message"
          className={classes.noMissionsContainer}
        >
          <Typography className={classes.noMissions}>
            {t("MISSIONS.NO_MISSIONS")}
          </Typography>
        </div>
      );
    }

    if (missionsServiceIsLoading) {
      return <TabsMissionsSkeleton data-testid="loading-component-test-id" />;
    }

    if (missionsServiceHasError) {
      return (
        <ErrorHandlerRetry
          data-testid="error-handler-retry-test-id"
          className={classes.errorContainer}
          onClick={() =>
            getGlobalAndLocalMissionsEvent({
              tagsList,
              localMissionIds,
              pocCallOrigin,
              accountId: clientId,
              vendorId: vendor?.id || undefined,
              vendorName: vendor?.displayName || undefined,
            })
          }
          description={t("MISSIONS.requestFailureMessage")}
        />
      );
    }

    return <TabsMissions missionsTab={missionsTab} />;
  };

  return (
    <div className={classes.container}>
      <div className={classes.missionsContainer}>
        <Box
          data-testid="call-missions-title"
          className={classes.titleContainer}
        >
          <MissionsIcon />
          <Typography variant="h1" classes={{ root: classes.title }}>
            {t("MISSIONS.MISSIONS_TITLE")}
          </Typography>
        </Box>
        {renderContent()}
      </div>
      <Box className={classes.promotionsContainer}>
        {isMissionsEnabled && <CallInfo />}

        {isPromotionsEnabled && <Offerings />}
      </Box>
    </div>
  );
};

export default React.memo(Missions);
