import React, { useState, useEffect, useRef } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
} from "@material-ui/core";
import { CURRENCY } from "../../../../../../config/currency";

import VariablePriceStyles from "./VariablePriceStyles";
import GlobalStore from "../../../../../../stores/global/GlobalStore";
import Input from "../../../../../../components/input/Input";
import NumberFormatCustom from "../../../../../../components/numberFormatCustom/NumberFormatCustomComponent";
import { callUxButtonClicked } from "../../../../../../config/typewriter";
import AgentCallStore from "../../../../../../stores/agentCall/AgentCallStore";

export interface PayWithProps {
  type: string;
  currency: string;
}

export interface VariablePriceProps {
  price: number;
  pointsPerCurrency: number;
  payWith: PayWithProps;
}

interface Props {
  getVariablePrice: (variablePrice: bigint) => void;
  getPayWith: (payWith: PayWithProps) => void;
}

const VariablePrice: React.FC<Props> = (props) => {
  const { getVariablePrice, getPayWith } = props;

  const classes = VariablePriceStyles();
  const MAX_ALLOWED_INPUT = 11;

  const { t } = useTranslation();
  const { callId } = useStore(AgentCallStore);

  const { user } = useStore(GlobalStore);

  const prevVariablePrice = useRef<VariablePriceProps | undefined>();

  const [variablePrice, setVariablePrice] = useState({
    price: 0,
    pointsPerCurrency: 0,
    payWith: {
      type: "points",
      currency: CURRENCY[user.zone].prefix,
    },
  });

  useEffect(() => {
    prevVariablePrice.current = variablePrice;
  });

  const variablePricePreviusValue = prevVariablePrice.current ?? variablePrice;

  const isAllowed = (values) => values?.value?.length <= MAX_ALLOWED_INPUT;

  useEffect(() => {
    function calculateVariablePrice() {
      if (variablePrice.payWith.type === "points") {
        const price = variablePrice.price * variablePrice.pointsPerCurrency;

        getVariablePrice(BigInt(price));
        getPayWith(variablePrice.payWith);

        return;
      }

      const price = Math.floor(
        variablePrice.price / variablePrice.pointsPerCurrency,
      );

      getVariablePrice(BigInt(price));
      getPayWith(variablePrice.payWith);
    }

    if (variablePricePreviusValue !== variablePrice) {
      calculateVariablePrice();
    }
  }, [variablePricePreviusValue, variablePrice, getVariablePrice, getPayWith]);

  return (
    <div className={classes.container} data-testid="service-variable-price">
      <FormControl className={classes.control}>
        <strong className={classes.label}>
          {t("SERVICES_MISSION_TAB.POINTS_PER_CURRENCY")}{" "}
          {CURRENCY[user.zone].currency}
        </strong>
        <Input
          className={classes.input}
          style={{ marginBottom: 30, marginTop: 10 }}
          data-testid="service-variable-input-points-per-currency"
          InputLabelProps={{ shrink: false }}
          helperText={`${variablePrice.pointsPerCurrency} ${t(
            "SERVICES_MISSION_TAB.POINTS_IS_EQUAL",
          )} ${CURRENCY[user.zone].currency}`}
          InputProps={{
            inputComponent: NumberFormatCustom as never,
            inputProps: {
              suffix: ` pts`,
              placeholder: "0 pts",
              value:
                variablePrice.pointsPerCurrency === 0
                  ? ""
                  : variablePrice.pointsPerCurrency,
              style: {
                fontSize: 16,
                height: 48,
              },
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setVariablePrice({
                  ...variablePrice,
                  pointsPerCurrency: +e.target.value,
                });

                callUxButtonClicked({
                  button_label: "points per currency field",
                  button_name: "points per currency field",
                  call_id: callId,
                  filter_option: null,
                  screen_name: "Call - GAP and Status",
                  screen_section: "Services Variable Price",
                  mission: "Services",
                  position: null,
                  order_date: null,
                });
              },
              isAllowed,
            },
            classes: {
              input: classes.inputPlaceholder,
            },
          }}
        />
      </FormControl>

      <FormControl className={classes.control}>
        <strong className={classes.label}>
          {t("SERVICES_MISSION_TAB.PAY_WITH")}
        </strong>
        <RadioGroup
          row
          name="payWith"
          value={variablePrice.payWith.type}
          data-testid="payWith-radio"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setVariablePrice({
              ...variablePrice,
              payWith: {
                ...variablePrice.payWith,
                type: event.target.value,
              },
            });

            const callUxButtonValues =
              event.target.value === "points"
                ? "payment method points"
                : "payment method price";

            callUxButtonClicked({
              button_label: callUxButtonValues,
              button_name: callUxButtonValues,
              call_id: callId,
              filter_option: null,
              screen_name: "Call - GAP and Status",
              screen_section: "Services Variable Price",
              mission: "Services",
              position: null,
              order_date: null,
            });
          }}
        >
          <FormControlLabel
            checked={variablePrice.payWith.type === "points"}
            value="points"
            control={<Radio />}
            style={{
              marginRight: "5.3%",
            }}
            label={
              <Typography className={classes.controlLabel}>
                {t("SERVICES_MISSION_TAB.PAY_WITH_POINTS")}
              </Typography>
            }
          />
          <FormControlLabel
            checked={variablePrice.payWith.type === "price"}
            value="price"
            control={<Radio />}
            label={
              <Typography className={classes.controlLabel}>
                {t("SERVICES_MISSION_TAB.PAY_WITH_PRICE")}
              </Typography>
            }
          />
        </RadioGroup>

        {variablePrice.payWith.type === "points" ? (
          <Input
            className={classes.input}
            data-testid="service-variable-input-points"
            InputLabelProps={{ shrink: false }}
            InputProps={{
              inputComponent: NumberFormatCustom as never,
              placeholder: `${CURRENCY[user.zone].prefix} 0`,
              inputProps: {
                prefix: `${CURRENCY[user.zone].prefix} `,
                value: variablePrice.price === 0 ? "" : variablePrice.price,
                style: {
                  fontSize: 16,
                  height: 48,
                  borderRadius: 8,
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setVariablePrice({
                    ...variablePrice,
                    price: +e.target.value,
                  });

                  callUxButtonClicked({
                    button_label: "service value field",
                    button_name: "service value field",
                    call_id: callId,
                    filter_option: null,
                    screen_name: "Call - GAP and Status",
                    screen_section: "Services Variable Price",
                    mission: "Services",
                    position: null,
                    order_date: null,
                  });
                },
                defaultValue: 0,
                isAllowed,
              },
              classes: {
                input: classes.inputPlaceholder,
              },
            }}
          />
        ) : (
          <Input
            className={classes.input}
            InputLabelProps={{ shrink: false }}
            data-testid="service-variable-input-price"
            InputProps={{
              inputComponent: NumberFormatCustom as never,
              placeholder: `0 pts`,
              inputProps: {
                suffix: ` pts`,
                value: variablePrice.price === 0 ? "" : variablePrice.price,
                style: {
                  fontSize: 16,
                  height: 48,
                  borderRadius: 8,
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setVariablePrice({
                    ...variablePrice,
                    price: +e.target.value,
                  });

                  callUxButtonClicked({
                    button_label: "service value field",
                    button_name: "service value field",
                    call_id: callId,
                    filter_option: null,
                    screen_name: "Call - GAP and Status",
                    screen_section: "Services Variable Price",
                    mission: "Services",
                    position: null,
                    order_date: null,
                  });
                },
                defaultValue: 0,
                isAllowed,
              },
              classes: {
                input: classes.inputPlaceholder,
              },
            }}
          />
        )}
      </FormControl>
    </div>
  );
};

export default VariablePrice;
