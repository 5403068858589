import React from "react";
import { Typography } from "@material-ui/core";
import Data from "../data/Data";
import useInfoDateStyle from "./style";

interface InfoDateProps {
  title: string;
  date: string;
  subtitle?: string;
  error?: boolean;
}

const InfoDate: React.FC<InfoDateProps> = ({
  title,
  date,
  error,
  subtitle,
}) => {
  const classes = useInfoDateStyle();
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.date}>
        {!error && !date && subtitle ? (
          <Typography
            className={classes.subtitle}
            data-testid="info-date-subtitle"
          >
            {subtitle}
          </Typography>
        ) : (
          <Data value={date} type="date" />
        )}
      </div>
    </div>
  );
};

export default InfoDate;
