import React from "react";
import TextField, { StandardTextFieldProps } from "@material-ui/core/TextField";

interface Props extends StandardTextFieldProps {
  errorText?: string | boolean;
  helperText?: string;

  touched?: boolean;
}

const Input: React.ForwardRefExoticComponent<Props> = React.forwardRef(
  (props, ref) => {
    const { helperText, errorText, touched, ...rest } = props;
    return (
      <TextField
        {...rest}
        error={!!errorText && touched}
        helperText={errorText && touched ? errorText : helperText ?? ""}
        variant="outlined"
        ref={ref}
      />
    );
  },
);

export default Input;
