import React, { useEffect, useCallback } from "react";
import { useStore } from "effector-react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import {
  isFeatureEnabledV2,
  GROW_ORGANIZATION_ACTIVATION,
} from "@config/featureToggles";
import { VendorByAutodialerProps } from "@domains/AutoDialerEvents";
import stopAutoDialerStyles from "./StopAutoDialerStyles";
import BeeLoading from "../../../call/components/beeLoading/BeeLoading";
import Button from "../../../../components/button/Button";
import useWebSocket from "../../../call/components/callTimerInfo/hooks/useWebSocket";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import { PocCallOrigin } from "../../../../config/constants";
import GlobalStore from "../../../../stores/global/GlobalStore";
import VendorStore from "../../../../stores/vendor/VendorStore";

interface Props {
  executePocSearch: (pocId: string) => void;
  handleSearchInteraction: () => void;
  onStopAutoDialerClick: () => void;
  setPocCallOrigin: (callOrigin: string) => void;
  setVendorByAutodialer: (vendor: VendorByAutodialerProps) => void;
}

const StopAutoDialer: React.FC<Props> = ({
  executePocSearch,
  handleSearchInteraction,
  onStopAutoDialerClick,
  setPocCallOrigin,
  setVendorByAutodialer,
}) => {
  const classes = stopAutoDialerStyles({});
  const { t } = useTranslation();
  const { dispatchGenericEvent } = useAnalytics();
  const globalState = useStore(GlobalStore);
  const { vendor } = VendorStore.getState();
  const { autoDialerEvent, setAutoDialerEvent, setIsAutoDialerCall } =
    useWebSocket();

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    globalState.user.keyToggle,
  );

  const handleSearchByAutoDialerPocId = useCallback(
    (pocId: string) => {
      executePocSearch(pocId);

      if (autoDialerEvent) {
        setVendorByAutodialer(
          "vendor_id" in autoDialerEvent
            ? { id: autoDialerEvent.vendor_id }
            : {},
        );
      }
      if (!isOrganizationConfigEnabled) handleSearchInteraction();
      setAutoDialerEvent({});
    },
    [
      executePocSearch,
      handleSearchInteraction,
      setAutoDialerEvent,
      dispatchGenericEvent,
      globalState.user.zone,
      vendor.id,
      autoDialerEvent?.phone,
    ],
  );

  /* istanbul ignore next */
  useEffect(() => {
    setIsAutoDialerCall(true);
    const pocId: string = autoDialerEvent?.poc_id || "";
    if (pocId) {
      setPocCallOrigin(PocCallOrigin.AUTO_DIALER);
      handleSearchByAutoDialerPocId(pocId);
    }
  }, [
    autoDialerEvent,
    handleSearchByAutoDialerPocId,
    setIsAutoDialerCall,
    setPocCallOrigin,
  ]);

  return (
    <Card className={classes.containerBox} elevation={0}>
      <CardHeader
        title={t("HomeMainPage.CALLING_NEXT_POC")}
        classes={{ title: classes.overwriteTitle }}
        className={classes.title}
      />
      <div
        className={classes.cardContent}
        style={{ justifyContent: "space-between" }}
      >
        <div className={classes.cardContent}>
          <BeeLoading testid="stop-calling-auto-dialer-bee" />
          <Typography
            data-testid="stop-calling-auto-dialer-text"
            className={classes.text}
            variant="body2"
          >
            {t("HomeMainPage.CALLING_AUTO_DIALER_ENABLED")}
            <div className={classes.statusBullet} />
          </Typography>
        </div>
      </div>
      <div
        className={classes.cardContent}
        style={{ alignItems: "end", justifyContent: "flex-end" }}
      >
        <Button
          color="secondary"
          onClick={onStopAutoDialerClick}
          className={classes.stopCalling}
          testId="stop-calling-auto-dialer-button"
        >
          {t("HomeMainPage.STOP_BUTTON_AUTO_DIALER")}
        </Button>
      </div>
    </Card>
  );
};

export default StopAutoDialer;
