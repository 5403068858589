import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    priceSection: {
      height: "2rem",
      marginBottom: "0.25rem",
      fontFamily: "Work Sans",
      fontStyle: "normal",
    },
    originalPrice: {
      height: "12px",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      color: "rgba(0, 0, 0, 0.55)",
      textDecorationLine: "line-through",
    },
    secondRow: {
      display: "flex",
      alignItems: "flex-end",
      gap: "0.5rem",
    },
    mainPrice: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
    normalPrice: {
      color: "#141414",
    },
    discountedPrice: {
      color: "#176D38",
    },
    unitPrice: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      color: "#717171",
    },
  }),
);
