import MonthlyKPI from "domains/agentKPI/MonthlyKPI";
import { createStore } from "effector";
import { getMonthlyKPIEffect } from "./MonthlyKPIEvents";
import MonthlyKPIState from "./MonthlyKPIState";

const initialState = <MonthlyKPIState>{
  done: false,
  isLoading: true,
  error: false,
  monthly: <MonthlyKPI>{
    monthlyYear: "",
    monthlyPocAssigned: 0,
    monthlyHitRate: 0,
    monthlyTotalGap: 0,
    monthlyAverageHandleTime: "",
    monthlyToplineUplift: 0,
  },
};

const MonthlyKPIStore = createStore(initialState)
  .on(getMonthlyKPIEffect.done, (state: MonthlyKPIState, { result }) => ({
    ...state,
    ...result,
    done: true,
    isLoading: false,
    error: false,
  }))
  .on(getMonthlyKPIEffect.fail, (state: MonthlyKPIState) => ({
    ...state,
    done: true,
    isLoading: false,
    error: true,
  }))
  .on(getMonthlyKPIEffect, (state: MonthlyKPIState) => ({
    ...state,
    done: false,
    isLoading: true,
    error: false,
  }));

export default MonthlyKPIStore;
