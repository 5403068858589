import React from "react";

const TooltipIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="21px"
      height="20px"
      viewBox="0 0 21 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M9.1,14.5 L10.9,14.5 L10.9,9.1 L9.1,9.1 L9.1,14.5 L9.1,14.5 Z M10,1 C5.032,1 1,5.032 1,10 C1,14.968 5.032,19 10,19 C14.968,19 19,14.968 19,10 C19,5.032 14.968,1 10,1 L10,1 Z M10,17.2 C6.031,17.2 2.8,13.969 2.8,10 C2.8,6.031 6.031,2.8 10,2.8 C13.969,2.8 17.2,6.031 17.2,10 C17.2,13.969 13.969,17.2 10,17.2 L10,17.2 Z M9.1,7.3 L10.9,7.3 L10.9,5.5 L9.1,5.5 L9.1,7.3 L9.1,7.3 Z"
          id="path-1-tooltip-icon"
        />
      </defs>
      <g
        id="BEES-Grow---customer-page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Customer-page---last-contacts-and-note---red-contact-expand"
          transform="translate(-1196.000000, -305.000000)"
        >
          <g id="Group-12" transform="translate(1035.000000, 258.000000)">
            <g id="Group-10" transform="translate(0.000000, 31.000000)">
              <g id="Icons-/-Info" transform="translate(161.949405, 16.000000)">
                <polygon id="Bounds" points="0 0 20 0 20 20 0 20" />
                <mask id="mask-2" fill="white">
                  <use href="#path-1-tooltip-icon" />
                </mask>
                <use
                  id="Mask"
                  fill="#000000"
                  fillRule="nonzero"
                  href="#path-1-tooltip-icon"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TooltipIcon;
