/* istanbul ignore file */
import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    tag: {
      display: "flex",
      alignItems: "center",
      width: "fit-content",
      height: "24px",
      padding: "4px 8px",
      marginLeft: 8,
      borderRadius: "16px",
      fontSize: "14px",
      fontWeight: 500,
      color: "#212121",
    },
    calledTag: {
      backgroundColor: "#d2adff",
    },
    inactivatedTag: {
      backgroundColor: "#FFDEDE",
    },
    copiedTag: {
      backgroundColor: "#cdfffe",
    },
  }),
);
