import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    enpsButton: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      height: "88px",
      width: "88px",
      bottom: "15vh",
      right: "7vw",
      transition: "transform 0.2s",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.1)",
      },
    },
    container: {
      width: "35rem",
      height: "35rem",
      display: "flex",
      flexDirection: "column",
      padding: "40px",
    },
    header: {
      margin: "0px 0px 20px 0px",
      color: "#505050",
      fontSize: "1rem",
      fontWeight: "bold",
      borderBottom: "1px solid black",
      paddingBottom: "10px",
    },
    formContainer: {
      flex: 1,
      display: "flex",
      border: "1px solid black",
    },
    errorMessage: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    iframe: {
      width: "100%",
      height: "100%",
      border: "none",
    },
    button: {
      alignSelf: "center",
      width: "100%",
      textAlign: "center",
      padding: "15px 20px",
      fontWeight: 500,
      fontSize: "14px",
    },
    closeButton: {
      margin: "24px 10px 0px 0px",
      color: "black",
      backgroundColor: "#fff",
      border: "1px solid black",
    },
    submitButton: {
      color: "#ffff",
      margin: "24px 0px 0px 10px",
      backgroundColor: palette.secondary.main,
    },
  }),
);
