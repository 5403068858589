import React from "react";
import { Box } from "@material-ui/core";
import CardStyles from "./CardStyles";

interface Props {
  className?: string;
  children: React.ReactNode;
}

const Card: React.FunctionComponent<Props> = ({
  className,
  children,
  ...props
}) => {
  const gapClasses = CardStyles();

  return (
    <Box className={`${gapClasses.container} ${className}`} {...props}>
      {children}
    </Box>
  );
};

export default Card;
