import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    iconContainer: {
      display: "flex",
    },
    icon: {
      color: palette.common.white,
      padding: "3px 3px 3px 2px",
    },
    button: {
      zIndex: 1,
      position: "absolute",
      top: "0px",
      right: "-32px",
      minWidth: "17px",
      width: "32px",
      height: "32px",
      color: "#343434",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
      borderTopLeftRadius: "0",
      borderBottomLeftRadius: "0",
      borderLeft: `1px solid ${palette.common.white}`,
      backgroundColor: palette.common.black,
    },
  }),
);
