/* eslint-disable global-require */
import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import Data from "../../../../../../../components/data/Data";
import Styles from "./PriceSection.styles";

interface Props {
  price: number;
  olderPrice?: number;
  unitPrice?: number;
}

const PriceSection = ({ price, olderPrice, unitPrice }: Props): JSX.Element => {
  const classes = Styles();
  const { t } = useTranslation();

  const currency = (price: number | undefined) => (
    <Data value={price} type="currency" />
  );

  return (
    <div data-testid="price-section" className={classes.priceSection}>
      {olderPrice && (
        <div data-testid="original-price" className={classes.originalPrice}>
          {currency(olderPrice)}
        </div>
      )}
      <div className={classes.secondRow}>
        <div
          data-testid="main-price"
          className={`${classes.mainPrice} ${
            olderPrice ? classes.discountedPrice : classes.normalPrice
          }`}
        >
          {currency(price)}
        </div>

        {unitPrice && (
          <div data-testid="unit-price" className={classes.unitPrice}>
            <Typography>
              {currency(unitPrice)}
              {`/${t("OrderTaking.PACKAGE_ID_EA")}`}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceSection;
