import axiosRetry from "axios-retry";
import { useLogService } from "admin-portal-shared-services";
import axios from "../../../config/axios/axiosInstance";
import { FlexibleMission } from "../../../domains/flexibleMission/FlexibleMission";
import { getApiHost } from "../../host/HostB2BService";

const ENDPOINT =
  "/grow-flexible-missions-service/grow-flexible-missions/missions";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export const getFlexibleMissionsByIds = async (
  ids: string[],
  vendorId?: string,
): Promise<FlexibleMission[]> => {
  const log = useLogService();

  const idsList = ids.map((id) => `ids=${id}`).join("&");

  const url = `${getApiHost()}${ENDPOINT}?${idsList}`;

  const headers = {
    vendorId,
  };

  return axios
    .get(url, {
      headers,
    })
    .then((response) => {
      log.info(`Return flexible missions received from ${url}`);
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
};
