export const PROPS_TAG = {
  MARKETPLACE: {
    testId: "marketplace",
    backgroundColor: "#705ea7",
    translationKey: "Tags.MARKETPLACE",
  },
  PROMOTION: {
    testId: "promotion",
    backgroundColor: "#69882d",
    translationKey: "Tags.PROMOTION",
  },
};
