import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    title: {
      maxWidth: 180,
      position: "relative",
      width: "100%",
      padding: "3px 0px 2px 3px",
      fontSize: "10px",
      fontWeight: "bold",
      color: "#FFF",
      background: palette.error.main,
      clipPath: "polygon(100% 10%, 95% 100%, 10% 100%, 0% 100%, 0% 10%)",
    },
    label: {
      color: "#D00505",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "18px",
    },
  }),
);
