export const prodStoreIds: { [key: string]: string } = {
  BR: "de1f0b68-f635-44dd-92c5-15a04ac10a1d",
  BRF_BR: "e2104e78-a2cb-4b40-a664-608834170dc1",
  GPA_BR: "f6f34c61-ad45-4fd2-a9fa-d07f991fed2d",
  NESTLE_BR: "9bcfced2-15f5-48f2-a43d-be9be6c02fa8",
  CO: "4a9296fb-a28c-46e1-b71f-75a9707980be",
  EC: "b962c222-212e-4995-a18c-f496621aa5f8",
  NESTLE_EC: "2d620755-9d81-422a-a1b0-b28557d81621",
  ES: "4ba0e652-5f93-44d9-9ed7-6e1c4708fc50",
  DO: "f4330d52-be6d-4829-afcd-507c6cddff4e",
  MERCASID_DO: "3a1861ae-b7a8-43cf-b505-40b3c9f82739",
  ZA: "166e28b1-9284-484d-898c-ad48cbac37ab",
  PE: "c22602d9-e10e-4b3b-bc62-112c35d4dbef",
  GLORIA_PE: "eaf8d85d-3f7a-4efb-8629-b1a97ea132e0",
  GLORIA_CO: "e5f45f71-ca09-43ab-ada7-96c98dfc1ac6",
  MX: "ab4c7d46-e2fe-4787-8726-db323c1ee1f9",
  PA: "4468ffbd-6c9f-4bfe-8b62-91365bc76004",
  HN: "a5bfd193-7ee7-48cb-96f7-5af632625ab2",
  SV: "7384d74a-bfec-45cd-aca1-232d309a83c8",
  UY: "44394e12-bceb-4fa1-a27e-5c56c10ac98d",
  PY: "a3401b61-9b97-4936-8562-1c6f996f33f1",
  AR: "126a4950-c0ef-4ff2-a587-ddd963349ced",
  NESTLEACTISERVE_PH: "f033bbb1-c8f2-4b91-bb34-604ffa319aff",
  NESTLECAVITE_PH: "982a8dfa-f6e9-4d12-bbf7-c26aae36fdbb",
  NESTLERUKUN_ID: "5bc0c779-9c78-4ce4-8b8d-690079ab5606",
  NESTLECIPTA_ID: "93a8f46c-3440-49da-840e-70b2460eb4c2",
  PEPSICO_BR: "2276be28-60cd-41e8-8862-5cf412390b61",
  NESTLE_CL: "ffe22854-8e8e-49ca-8579-8c3741a43896",
};
