import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: "20px",
      whiteSpace: "nowrap",
      height: "100%",
      fontWeight: 600,
      color: theme.palette.secondary.main,
      margin: 0,
      marginLeft: "5px",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    searchContainer: {
      marginTop: "20px",
    },
    label: {
      fontSize: "12px",
      fontWeight: "normal",
      color: theme.palette.text.secondary,
      paddingTop: "3px",
      height: "13px",
      margin: 0,
    },
    headerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      paddingBottom: "9px",
      marginBottom: "16px",
    },
    iconColor: {
      color: theme.palette.secondary.main,
    },
    inputContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "16px",
    },
    iconContainer: {
      border: "1px solid #5e5e5e",
      borderRadius: "0px 3px 3px 0px",
      padding: "2px 10px 0 10px",
    },
    noItems: {
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.secondary.main,
      marginTop: "30px",
    },
    searchProduct: {
      width: 295,
    },
    imageContainer: {
      marginTop: "24px",
      display: "flex",
      justifyContent: "center",

      "& img": {
        width: "110px",
        height: "132px",
      },
      "& p": {
        fontSize: "14px",
        whiteSpace: "pre-line",
      },
    },
  }),
);
