import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  targetColor?: string;
}

const GenericCardStyles = makeStyles<Theme, Props>(({ palette }) =>
  createStyles({
    overwriteTitle: {
      fontSize: "14px",
      color: palette.primary.main,
    },
    percentage: {
      letterSpacing: "0px",
      color: palette.secondary.main,
      fontSize: "42px",
      height: "52px",
      fontWeight: "bold",
      fontFamily: "Barlow",
    },
    divider: {
      height: "26px",
      width: "2px",
      backgroundColor: "#9e9e9e",
      margin: "0px 9px 4px 9px",
    },
    mainValue: {
      letterSpacing: "0px",
      color: palette.secondary.main,
      fontSize: "23px",
      height: "30px",
      fontWeight: "bold",
      fontFamily: "Barlow",
    },
    title: {
      fontWeight: "normal",
      marginTop: "5px",
      color: `${palette.text.primary} !important`,
      fontSize: 14,
    },
    subtitle: {
      fontWeight: "normal",
      marginTop: "5px",
      color: `${palette.text.secondary} !important`,
    },
    secondValue: {
      letterSpacing: "0px",
      color: (props) =>
        props.targetColor ? props.targetColor : palette.info.main,
      fontSize: "23px",
      fontWeight: "bold",
      alignContent: "right",
      height: "30px",
      fontFamily: "Barlow",
    },
    genericTitleOverwrite: {
      color: palette.primary.main,
      opacity: "1",
    },
    targetLabel: {
      fontWeight: "normal",
      whiteSpace: "nowrap",
      marginTop: "5px",
      color: `${palette.text.secondary} !important`,
    },
    cardContainer: {
      padding: "24px",
    },
  }),
);

export default GenericCardStyles;
