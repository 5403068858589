import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    radioError: {
      color: palette.error.main,
    },
    formHelperText: {
      fontSize: "10px",
      marginTop: "0",
      color: palette.error.main,
    },
  }),
);
