import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import axios from "../../config/axios/axiosInstance";
import { getApiHost } from "../host/HostService";
import { B2BTrainerInfo } from "./domains/B2BTrainerInfo";
import { B2BTrainerResponse } from "./domains/B2BTrainerResponse";
import B2BTrainerResponseToB2BTrainerInfoConverter from "../../converters/b2bTrainer/B2BTrainerResponseToB2BTrainerInfoConverter";
import { B2B_TRAINER_SERVICE } from "../../config/services";
import { EMULATOR_TIMEOUT } from "../../config/constants";

const B2BTRAINER_ENDPOINT = "b2b-trainer/emulator";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function executeB2BTrainer(
  username: string,
  headers = {},
): Promise<B2BTrainerInfo> {
  const log = useLogService();
  const body = {
    timeout: EMULATOR_TIMEOUT,
    note: username,
  };

  const urlb2b = `${getApiHost(B2B_TRAINER_SERVICE)}/${B2BTRAINER_ENDPOINT}`;

  return axios
    .post<B2BTrainerResponse>(urlb2b, body, {
      headers,
    })
    .then((response) => {
      log.info(
        `Convert B2BTrainer Response received from ${B2BTRAINER_ENDPOINT} to our model`,
      );
      return B2BTrainerResponseToB2BTrainerInfoConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}

export function deleteB2bTrainerEmulator(
  publicKey: string,
  headers = {},
): Promise<void> {
  const log = useLogService();

  const urlb2b = `${getApiHost(
    B2B_TRAINER_SERVICE,
  )}/${B2BTRAINER_ENDPOINT}/${publicKey}`;

  return axios
    .delete(urlb2b, {
      headers,
    })
    .then(() => {
      log.info(`Deleting instance ${publicKey} from ${B2BTRAINER_ENDPOINT}`);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}

export function getB2BTrainerEmulator(
  agentId: string,
  headers = {},
): Promise<B2BTrainerInfo> {
  const log = useLogService();

  const urlb2b = `${getApiHost(
    B2B_TRAINER_SERVICE,
  )}/${B2BTRAINER_ENDPOINT}/${agentId}/app`;

  return axios
    .get<B2BTrainerResponse>(urlb2b, {
      headers,
    })
    .then((response) => {
      log.info(
        `Convert B2BTrainer Response received from ${urlb2b} to our model`,
      );
      return B2BTrainerResponseToB2BTrainerInfoConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
