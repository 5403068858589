import { CartInfo } from "../../../domains/cart/CartInfo";
import CartId from "../../../services/cart/cartId/domain/CartIdResponse";

interface ConverterParams {
  accountId: string;
  vendorId: string;
  data?: CartId | null;
}

export default function CartIdResponseToCartInfoConverter({
  accountId,
  data,
}: ConverterParams): CartInfo {
  const defaultCartInfo = {
    accountId,
    cartId: null,
    lineItems: [],
    combos: [],
  };

  // Will consider vendorId after freegoods tests
  const cart = data?.carts ? data?.carts[0] : undefined;
  return cart ? { ...defaultCartInfo, cartId: cart.id } : defaultCartInfo;
}
