import { useEffect, useState } from "react";
import { PromotionsPrice } from "../../../../../domains/promotions/domains/PromotionsPrice";

export const useDiscount = (
  itemQuantity: number,
  prices?: PromotionsPrice[],
  promoType?: string,
  limit?: number,
): number | undefined => {
  const [finalDiscount, setFinalDiscount] = useState<number | undefined>();
  useEffect(() => {
    /* istanbul ignore else */
    if (prices)
      switch (promoType) {
        case "STEPPED_DISCOUNT":
          for (const promo of prices) {
            if (
              promo.stepEnd &&
              promo.stepStart &&
              itemQuantity >= promo?.stepStart &&
              itemQuantity <= promo?.stepEnd
            ) {
              setFinalDiscount(promo.discountedPrice);
              return;
            }
          }
          setFinalDiscount(undefined);
          break;
        case "DISCOUNT":
          if (!limit || itemQuantity <= limit) {
            setFinalDiscount(prices[0].discountedPrice);
          } else {
            setFinalDiscount(undefined);
          }
          break;
        default:
          setFinalDiscount(undefined);
      }
  }, [itemQuantity, prices, promoType, limit]);

  return finalDiscount;
};
