import React from "react";
import { useStore } from "effector-react";
import { Box } from "@material-ui/core";
import {
  GROW_HIDE_VOLUME_GAP,
  isFeatureEnabledV2,
} from "../../../../../config/featureToggles";
import Gap from "./Gap";
import GapBoardStyles from "./GapBoardStyles";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";

const GapBoard: React.FunctionComponent = () => {
  const classes = GapBoardStyles();
  const { user } = useStore(GlobalStore);
  const { gap } = useStore(AgentCallStore);

  const {
    user: { keyToggle },
  } = useStore(GlobalStore);

  const isHideVolumeGapEnabled = isFeatureEnabledV2(
    GROW_HIDE_VOLUME_GAP,
    keyToggle,
  );

  return (
    <Box className={classes.gapBoardContainer}>
      <>
        <div className={`${classes.cardLeft} ${classes.card}`}>
          <Gap
            titleId="PocInformationFooter.TOLPLINE_GAP_LABEL"
            gapValue={gap.topline.total}
            achievedValue={gap.topline.achieved}
            targetValue={gap.topline.target}
            testId="gap-topline"
            gapType="topline"
            userZone={user.zone}
          />
        </div>
        {!isHideVolumeGapEnabled && (
          <div className={`${classes.cardRight} ${classes.card}`}>
            <Gap
              titleId="PocInformationFooter.VOLUME_GAP_LABEL"
              gapValue={gap.volume.total}
              achievedValue={gap.volume.achieved}
              targetValue={gap.volume.target}
              testId="gap-volume"
              gapType="volume"
              userZone={user.zone}
            />
          </div>
        )}
      </>
    </Box>
  );
};

export default GapBoard;
