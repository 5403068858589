import React from "react";
import { useTranslation } from "react-i18next";
import { Info } from "@hexa-ui/icons";
import { Card } from "@hexa-ui/components";
import { Tooltip, ThemeProvider, Grid } from "@material-ui/core";
import {
  cardTheme,
  useDiscountsRangeInfoStyle,
} from "./DiscountsRangeInfoStyles";

export interface DiscountRange {
  quantity: string;
  discount: number;
  price: number;
}

interface Props {
  testId?: string;
  values: DiscountRange[];
}

const DiscontsRangeCard = ({ testId, values }: Props) => {
  const classes = useDiscountsRangeInfoStyle();
  const { t } = useTranslation();

  return (
    <Card border="small" elevated="medium">
      <Grid
        data-testid={`${testId}-card-${values
          .map((value) => `${value.quantity}${value.discount}${value.price}`)
          .join("-")}`}
        container
        className={classes.constainer}
      >
        <Grid item xs={4} className={classes.itemHeader}>
          {t("DiscountsRange.QUANTITY")}
        </Grid>
        <Grid item xs={4} className={classes.itemHeader}>
          {t("DiscountsRange.DISCOUNT")}
        </Grid>
        <Grid item xs={4} className={classes.itemHeader}>
          {t("DiscountsRange.PRICE")}
        </Grid>
        {values.map((value) => {
          return (
            <>
              <Grid
                data-testid={`${testId}-value-quantity-${value.quantity}`}
                item
                xs={4}
                className={classes.itemQuantity}
              >
                {value.quantity}
              </Grid>
              <Grid
                data-testid={`${testId}-value-discount-${value.discount}`}
                item
                xs={4}
                className={classes.itemDiscount}
              >
                {`$ ${value.discount}`}
              </Grid>
              <Grid
                data-testid={`${testId}-value-price-${value.price}`}
                item
                xs={4}
                className={classes.itemPrice}
              >
                {`$ ${value.price}`}
              </Grid>
            </>
          );
        })}
      </Grid>
    </Card>
  );
};

const DiscountsRangeInfo = ({ testId, values }: Props): JSX.Element => {
  return (
    <ThemeProvider theme={cardTheme}>
      <Tooltip
        data-testid={`${testId}-tooltip`}
        interactive
        placement="right-end"
        title={<DiscontsRangeCard testId={testId} values={values} />}
      >
        <Info />
      </Tooltip>
    </ThemeProvider>
  );
};

export default DiscountsRangeInfo;
