import { CartComboItem } from "../../../domains/cart/CartComboItem";
import { CartInfo } from "../../../domains/cart/CartInfo";
import { Empties, EmptyItem } from "../../../domains/cart/Empties";
import { LineItems } from "../../../domains/cart/LineItems";
import { MinimumOrder } from "../../../domains/cart/MinimumOrder";
import { MessageResponse } from "../../../services/cart/domains/MessageResponse";
import CartResponseV3 from "../../../services/cart/v3/domains/CartResponseV3";
import { getMessageType } from "../../../config/utils/functions";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function CartResponseToCartInfoConverterV3(
  data: CartResponseV3,
  accountId: string,
  paymentMethod: string,
  paymentTerm: number,
): CartInfo {
  const minimumOrder = <MinimumOrder>{
    minOrderPercent: data.minimumOrder?.percent,
    minOrderMessage: data.minimumOrder?.message,
  };

  const lineItems = data.items?.map(
    (orderItem) =>
      <LineItems>{
        price: 0,
        unitPrice: orderItem.unitPrice,
        unitPriceInclTax: orderItem.unitPriceInclDiscount,
        quantity: orderItem.quantity,
        quantityChanged: false,
        discountAmount: orderItem.discountAmount,
        deposit: 0,
        subtotal: orderItem.subtotal,
        taxAmount: 0,
        total: orderItem.total,
        totalExclDeposit: orderItem.total,
        sku: orderItem.sku,
        pricingReason: [],
        pricingReasonDetail: [],
        hasInventory: false,
        freeGood: false,
        originalPrice: orderItem.unitPrice,
        outputMessages: [],
        inventoryCount: orderItem.inventoryCount,
        id: orderItem.id,
      },
  );

  const empties = <Empties>{
    totalAmount: data.empties?.totalExpiredAmount,
    extraAmount: data.empties?.totalExpiredAmount,
    minimumRequired: data.empties?.totalMinimumQuantity,
    type: data.empties?.type,
    defaultValue: data.empties?.totalMinimumQuantity,
    openBalance: data.empties?.totalOpenBalance,
    totalEmptiesBalance: data.empties?.totalBalance,
    currentValue: data.empties?.totalBalance,
    emptiesList: data.empties?.emptiesList?.map(
      (empty) =>
        <EmptyItem>{
          emptiesBalance: empty.balance,
          emptiesName: empty.name,
          expiredAmount: empty.expiredAmount,
          extraAmount: empty.expiredAmount,
          minimumRequired: empty.minimumQuantity,
          quantity: empty.quantity,
          groupId: empty.id,
        },
    ),
  };

  const combos = data.combos?.map(
    (comboItem) =>
      <CartComboItem>{
        id: comboItem.id,
        vendorComboId: comboItem.vendorComboId,
        itemQuantity: comboItem.quantity,
        type: comboItem.type,
        originalPrice: comboItem.originalPrice,
        price: comboItem.price,
        unitPriceInclDiscount: comboItem.unitPriceInclDiscount,
        total: comboItem.total,
        title: comboItem.title,
        items: [],
        messages: [],
      },
  );

  const outputMessages = data.messages?.map(
    (message) =>
      <MessageResponse>{
        text: message.text,
        type: getMessageType(message.type),
        subtype: message.module,
      },
  );

  return {
    cartId: data.cartId,
    accountId,
    interestReason: [],
    price: 0,
    paymentMethod,
    paymentTerm,
    total: data.total,
    subtotal: data.subTotal,
    taxAmount: data.taxAmount,
    discountAmount: data.discountAmount,
    minimumOrder,
    lineItems,
    pricingReason: [],
    pricingReasonDetail: [],
    deposit: data.depositAmount,
    accountStatus: "ACTIVE",
    combos,
    outputMessages,
    allowPaymentMethod: true,
    allowPaymentMethodMessages: [],
    totalExclDeposit: data.price,
    empties,
    freeGoods: [],
    pointsEarned: data.loanDeduction,
    interestAmount: data.interest?.detail.find(
      (item) => item.type === "PAYMENT_METHOD_FEE",
    )?.value,
    overprice: data.interest?.detail.find((item) => item.type === "OVERPRICE")
      ?.value,
  };
}
