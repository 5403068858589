import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { useStore } from "effector-react";
import ExpandableData from "../../../../components/expandableData/ExpandableData";
import PocTagsStyles from "./PocTagsStyles";

import { ANALYTICS_ROUTE_NAMES } from "../../../../config/constants";
import { getValueOrNull } from "../../../../config/utils/functions";
import CollapsibleContainer from "./CollapsibleContainer";
import { callUxButtonClicked } from "../../../../config/typewriter";
import Tag from "../../../../components/tag/Tag";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import {
  isFeatureEnabled,
  TLP_FOCUS_SKU,
  TLP_FOCUS_SKU_ADMIN,
} from "../../../../config/featureToggles";

interface Props {
  tags: string[];
  testId: string;
  keyToggle: string;
}

const PocTags: React.FC<Props> = ({ tags, testId, keyToggle }) => {
  const { t } = useTranslation();
  const classes = PocTagsStyles();
  const { callTab } = useStore(CallTabStore);
  const { callId } = useStore(AgentCallStore);
  const isFocusSkuEnabled = isFeatureEnabled(
    TLP_FOCUS_SKU,
    TLP_FOCUS_SKU_ADMIN,
    keyToggle,
  );

  /* istanbul ignore next */
  const handleClick = (isOpen: boolean) => {
    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: t("PocInformationHeaderTemplate.MISSIONS"),
      button_label: !isOpen ? "expand" : "collapse",
      screen_section: "POC Details",
      filter_option: null,
      call_id: callId,
      order_date: null,
      position: null,
    });
  };

  return isFocusSkuEnabled ? (
    <Grid
      container
      direction="column"
      data-testid={testId}
      className={classes.container}
    >
      <div data-testid="poc-tags-item-title" className={classes.title}>
        {t("PocInformationHeaderTemplate.MISSIONS")}
      </div>
      <ExpandableData items={tags} testId="poc-tags-item-value" isTag />
    </Grid>
  ) : (
    <CollapsibleContainer
      onClick={handleClick}
      title={t("PocInformationHeaderTemplate.MISSIONS")}
      defaultOpen
    >
      <div className={classes.containerTags}>
        {tags.map((tag) => (
          <Tag key={tag} text={tag} />
        ))}
      </div>
    </CollapsibleContainer>
  );
};

export default React.memo(PocTags);
