import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { useStore } from "effector-react";
import { EFFECTIVENESS_TYPES_STRINGS } from "../../../../domains/flexibleMission/FlexibleMission";
import { Product } from "../../../../domains/products/Product";
import { Promotions } from "../../../../domains/promotions/Promotions";
import FormatStockExhibition from "../../../../helpers/FormatStockExhibition";
import { MIN_VALUE_INPUT, MISSION_TYPES } from "../../../../config/constants";

import { useDiscount } from "../../../../routes/call/components/marketplaceComponent/hooks/useDiscount";
import { SponsoredMission } from "../../../../domains/sponsored/SponsoredMission";
import GlobalStore from "../../../../stores/global/GlobalStore";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import pocInformationStore from "../../../../stores/callList/pocInformation/PocInformationStore";

import {
  GROW_CART_SERVICE_V4,
  GROW_FLEXIBLE_MISSIONS,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";

import {
  campaignsProductQuantityEdited,
  localProductsQuantityEdited,
  sponsoredProductQuantityEdited,
} from "../../../../config/typewriter";
import { useAnalytics } from "../../../../analytics/useAnalytics";

interface Props {
  discount: number | undefined;
  handleChangeQuantity: (alteredNumber: number) => void;
  handleAddAction: () => void;
  renderSuggestedQuantity: (
    sku?: string,
    classname?: string,
  ) => React.ReactNode;
  renderSuggestedQuantityWithEffectiveness: (
    sku: string,
    classname: string,
  ) => React.ReactNode;
  itemQuantity: number;
  setItemQuantity: Dispatch<SetStateAction<number | undefined>>;
}

interface ProductCard {
  product: Product;
  promotion?: Promotions;
  minValue?: number;
  addAction?: (itemQuantity: number, itemId?: string) => void;
  itemQuantityChangeAnalytics?: (newValue?: number) => void;
  mission?: SponsoredMission;
}

const useProductCard = ({
  product,
  promotion,
  minValue,
  itemQuantityChangeAnalytics,
  mission,
  addAction,
}: ProductCard): Props => {
  const { t } = useTranslation();
  const { dispatchGenericEvent } = useAnalytics();
  const {
    user: { keyToggle },
  } = useStore(GlobalStore);
  const minValues = minValue ?? MIN_VALUE_INPUT;

  const [itemQuantity, setItemQuantity] = useState(minValues);
  const { callId, clientId } = useStore(AgentCallStore);
  const { pocInformation } = useStore(pocInformationStore);
  const discount = useDiscount(
    itemQuantity,
    promotion?.prices,
    promotion?.type,
    promotion?.limit?.daily,
  );

  const isCartSimulationEnabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    keyToggle,
  );

  const isGrowFlexibleMissionsEnabled = isFeatureEnabledV2(
    GROW_FLEXIBLE_MISSIONS,
    keyToggle,
  );

  const itemId = isCartSimulationEnabled ? product.platformId : product.id;

  const renderSuggestedQuantity = (sku, classname) => {
    return product.suggestedQuantity ? (
      <Typography
        variant="subtitle1"
        className={classname}
        data-testid={`${sku}-suggested-quantity`}
      >
        {`${t(
          "RecommendedProducts.SUGGESTED_QUANTITY",
        )}: ${FormatStockExhibition(product.suggestedQuantity)} un`}{" "}
      </Typography>
    ) : (
      <></>
    );
  };

  const renderSuggestedQuantityWithEffectiveness = (sku, classname) => {
    if (
      mission?.effectiveness?.type === "SPECIFIED_QUANTITY" &&
      product.suggestedQuantity
    ) {
      return (
        <Typography
          variant="subtitle1"
          className={classname}
          data-testid={`${sku}-suggested-quantity`}
        >{`${t("LocalMission.MINIMUM_QUANTITY")} ${FormatStockExhibition(
          product.suggestedQuantity,
        )} un`}</Typography>
      );
    }
    return <></>;
  };

  const quantityChangesAnalytics = () => {
    if (mission?.sponsorPartnerId) {
      dispatchGenericEvent(sponsoredProductQuantityEdited, {
        call_id: callId,
        poc_id: clientId,
      });
    } else if (mission && !mission?.sponsorPartnerId) {
      const event = isGrowFlexibleMissionsEnabled
        ? campaignsProductQuantityEdited
        : localProductsQuantityEdited;

      const isFlexible = mission?.missionType === MISSION_TYPES.FLEXIBLE;

      const effectivenessCriteria = isFlexible
        ? EFFECTIVENESS_TYPES_STRINGS.CREDIT_AS_PAYMENT
        : mission?.effectiveness?.type;

      const extraParams = {
        flexile_mission_title: mission?.missionTitle,
        flexible_mission_id: mission?.id,
        effectiveness_criteria: effectivenessCriteria,
      };

      dispatchGenericEvent(event, {
        localMissionTitle: mission?.missionTitle,
        localMissionId: mission?.id,
        sponsorPartner: mission?.sponsorPartnerId,
        effectivenessCriteria,
        pocId: pocInformation.id,
        callId,
        ...(isFlexible ? extraParams : {}),
      });
    }
  };

  const handleChangeQuantity = (alteredQuantity: number) => {
    quantityChangesAnalytics();
    setItemQuantity(alteredQuantity);
    /* istanbul ignore else */
    if (itemQuantityChangeAnalytics)
      itemQuantityChangeAnalytics(alteredQuantity);
  };

  const handleAddAction = () => {
    /* istanbul ignore else */
    if (addAction) addAction(itemQuantity, itemId);

    setItemQuantity(minValues);
  };

  return {
    discount,
    handleChangeQuantity,
    handleAddAction,
    renderSuggestedQuantity,
    renderSuggestedQuantityWithEffectiveness,
    itemQuantity,
    setItemQuantity,
  };
};

export default useProductCard;
