import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: "flex",
      paddingRight: "20px",
    },
    title: {
      paddingRight: "5px",
      fontSize: "12px",
      color: palette.text.secondary,
    },
    online: {
      color: palette.info.main,
      fontWeight: "bold",
      fontSize: "12px",
    },
    offline: {
      color: palette.error.main,
      fontWeight: "bold",
      fontSize: "12px",
    },
  }),
);
