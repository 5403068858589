import React from "react";
import { Phone } from "domains/phoneRanking/RankedPhones";
import { classificationTypes } from "../../../ClassificationButton/ClassificationTypeEnum";
import ClassificationButton from "../../../ClassificationButton/ClassificationButton";

interface ClassificationButtonsProps {
  isConnected: boolean;
  sanitizedPhone: string;
  item: Phone;
  index: number;
  lastCalledPhone: string;
  needClassification: boolean;
  handleOnClick: (
    classification: classificationTypes,
    item: Phone,
    index: number,
  ) => void;
}

const ClassificationButtons = ({
  isConnected,
  sanitizedPhone,
  item,
  index,
  lastCalledPhone,
  needClassification,
  handleOnClick,
}: ClassificationButtonsProps): JSX.Element => {
  const renderClassificationButtons = () => {
    const shouldRender = isConnected
      ? sanitizedPhone === lastCalledPhone && needClassification
      : true;
    return shouldRender ? (
      <>
        <ClassificationButton
          data-testid="classBtn"
          phoneNumber={item.phoneNumber}
          classificationType={classificationTypes.INACTIVATE}
          handleOnClick={(classification) =>
            handleOnClick(classification, item, index)
          }
        />
        <ClassificationButton
          data-testid="classBtn"
          phoneNumber={item.phoneNumber}
          classificationType={classificationTypes.NO_ANSWER}
          handleOnClick={(classification) =>
            handleOnClick(classification, item, index)
          }
        />
        <ClassificationButton
          data-testid="classBtn"
          phoneNumber={item.phoneNumber}
          classificationType={classificationTypes.GOOD}
          handleOnClick={(classification) =>
            handleOnClick(classification, item, index)
          }
        />
      </>
    ) : (
      <></>
    );
  };

  return renderClassificationButtons();
};

export default ClassificationButtons;
