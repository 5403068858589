import React, { useCallback, useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import MissionScriptIcon from "../../../../assets/newIcons/missions/ScriptIcon";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/v2/ErrorHandlerRetry";
import GlobalAdminConfigStore from "../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import { getScript } from "../../../../stores/script/ScriptEvents";
import ScriptStore from "../../../../stores/script/ScriptStore";
import BeeLoading from "../beeLoading/BeeLoading";
import ScriptComponentStyles from "./ScriptComponentStyles";

const ScriptComponent: React.FC = () => {
  const classes = ScriptComponentStyles();
  const { t } = useTranslation();

  const scriptStore = useStore(ScriptStore);
  const { user } = useStore(GlobalStore);
  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);

  const { vendorId } = GlobalAdminConfigState.userConfig;

  const refreshServicesScript = useCallback(() => {
    getScript({ countryId: user.zone, vendorId });
  }, [vendorId, user.zone]);

  useEffect(() => {
    refreshServicesScript();
  }, [refreshServicesScript]);

  const renderContent = () => {
    if (scriptStore.error) {
      return (
        <div data-testid="services-script-error-handler-component">
          <div className={classes.header}>
            <MissionScriptIcon style={{ width: "18px", height: "18px" }} />

            <Typography className={classes.title}>
              {t("Services.SCRIPT_TITLE")}
            </Typography>
          </div>
          <ErrorHandlerRetry
            className={classes.containerError}
            onClick={refreshServicesScript}
            description={t("Services.SCRIPT_REQUEST_FAILURE_MESSAGE")}
            event={{
              screenName: "Call - GAP and Status",
              screenSection: "Services Script",
              errorType: "Information not found",
              errorMessage: "It was not possible to load the script",
            }}
          />
        </div>
      );
    }

    return scriptStore.script ? (
      <Box data-testid="services-script-information-component">
        <div>
          <div className={classes.header}>
            <MissionScriptIcon style={{ width: "18px", height: "18px" }} />

            <Typography className={classes.title}>
              {t("Services.SCRIPT_TITLE")}
            </Typography>
          </div>
          <Box className={classes.card}>
            <div className={classes.cardContent}>
              <Typography
                style={{ whiteSpace: "pre-line" }}
                data-testid="service-script-content"
                component="p"
                variant="subtitle1"
                className={classes.description}
              >
                {scriptStore.script}
              </Typography>
            </div>
          </Box>
        </div>
      </Box>
    ) : (
      <></>
    );
  };

  return scriptStore.loading ? (
    <div data-testid="services-script-loading-component">
      <div className={classes.header}>
        <MissionScriptIcon style={{ width: "18px", height: "18px" }} />

        <Typography className={classes.title}>
          {t("Services.SCRIPT_TITLE")}
        </Typography>
      </div>
      <Box className={classes.card}>
        <div
          className={classes.cardContent}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BeeLoading testid="bee-loading-component" />
        </div>
      </Box>
    </div>
  ) : (
    <>{renderContent()}</>
  );
};

export default ScriptComponent;
