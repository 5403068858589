import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";

import CheckoutResponseV2ToCheckoutInfoConverter from "../../../converters/checkout/v2/CheckoutResponseV2ToCheckoutInfoConverter";
import { CheckoutInfoV2 } from "../../../domains/CheckoutInfoV2";
import { getApiHost, getEndPoint } from "../../host/HostB2BService";
import CheckoutRequestV2 from "../domains/v2/CheckoutRequestV2";
import { CheckoutResponseV2 } from "../domains/v2/CheckoutResponseV2";

const CHECKOUT_SERVICE = "checkout-service";

export function checkout(request: CheckoutRequestV2): Promise<CheckoutInfoV2> {
  const log = useLogService();
  const endpoint = `${getApiHost()}${getEndPoint()}/${CHECKOUT_SERVICE}/v2`;

  return axios
    .post<CheckoutResponseV2>(endpoint, request)
    .then((response) => {
      log.info(
        `Convert Checkout Response received from ${endpoint} to our model`,
      );
      return CheckoutResponseV2ToCheckoutInfoConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
