/* istanbul ignore file */
import { makeStyles, createStyles } from "@material-ui/core/styles";

const NewPhoneFormStyles = makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      marginTop: 20,
      position: "relative",
      height: "100%",
    },

    input: {
      marginBottom: "1rem",
      "& .tlp-MuiInputBase-root": {
        height: 48,
        width: "50%",
        fontSize: 16,
        "& fieldset": {
          borderRadius: "8px",
        },
      },
      "& .tlp-MuiInputBase-root fieldset": {
        borderColor: palette.grey.A100,
      },
    },

    saveButton: {
      backgroundColor: palette.common.black,
      color: palette.common.white,
      borderRadius: "20px",
      width: "13rem",
      height: "3rem",
      display: "flex",
      position: "absolute",
      bottom: 80,
      right: 20,
    },

    inputPlaceholder: {
      "&&::placeholder": {
        color: palette.common.black,
      },
    },
  }),
);

export default NewPhoneFormStyles;
