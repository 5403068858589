import { createStore } from "effector";
import * as CallDurationEvent from "./CallDurationEvent";
import CallDurationState from "./CallDurationState";

const initialState = <CallDurationState>{
  minDurationCall: 0,
  currentPocCallDuration: 0,
  done: false,
  error: false,
};

const CallDurationStore = createStore(initialState)
  .on(
    CallDurationEvent.setCurrentPocCallDuration,
    (state: CallDurationState, currentPocCallDuration: number) => {
      return {
        ...state,
        currentPocCallDuration,
      };
    },
  )
  .on(CallDurationEvent.createCallDurationEffect, (state) => {
    return Object.assign({}, state, {
      ...state,
      done: false,
      error: false,
    });
  })
  .on(CallDurationEvent.createCallDurationEffect.done, (state) => {
    return Object.assign({}, state, {
      ...state,
      done: true,
      error: false,
    });
  });

export default CallDurationStore;
