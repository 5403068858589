import { PROGRAMS_COMBOS_NOT_FOUND } from "../../config/errorCodes";
import { Reedeemables } from "../../domains/redeem/Redeem";
import { getRedeemables } from "../../services/redeemables/RedeemablesService";

export function execute(pocId: string): Promise<Reedeemables> {
  return getRedeemables(pocId)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const code = error?.response?.data?.code;

      if (code === PROGRAMS_COMBOS_NOT_FOUND) {
        return {
          redeemableItems: [],
        };
      }

      throw error;
    });
}
