import React, {
  useEffect,
  useState,
  MutableRefObject,
  useCallback,
} from "react";
import { Typography } from "@material-ui/core";
import QuantityControl from "@components/quantityControl/QuantityControl";
import { ComboItem } from "@services/promotions/domains/CombosResponseV2";
import ProductStyle from "./Products.style";
import ImageSection from "../../../../searchCatalog/productItemV2/components/ImageSection/ImageSection";
import PriceSection from "../../../../searchCatalog/productItemV2/components/PriceSection/PriceSection";
import StockSection from "../../../../searchCatalog/productItemV2/components/StockSection/StockSection";

interface Product {
  comboId: string;
  item: ComboItem;
  totalComboRef: MutableRefObject<
    Array<{
      platformId?: string;
      total: number;
      itemQuantity: number;
      comboId: string;
    }>
  >;
  setTotalCombo: (total: number) => void;
}

export default function Product({
  comboId,
  item,
  totalComboRef,
  setTotalCombo,
}: Readonly<Product>): JSX.Element {
  const classes = ProductStyle();
  const [itemQuantity, setItemQuantity] = useState(0);
  const [isQtdTouched, setIsQtdTouched] = useState(false);

  const updateTotalComboRef = useCallback(
    (priceValue = 0) => {
      if (priceValue && isQtdTouched) {
        const currentPosition = totalComboRef?.current?.find(
          ({ platformId }) => item?.platformId === platformId,
        );
        if (currentPosition) {
          currentPosition.total = itemQuantity * priceValue;
          currentPosition.itemQuantity = itemQuantity;
        } else
          totalComboRef?.current?.push({
            platformId: item?.platformId,
            total: itemQuantity * priceValue,
            itemQuantity,
            comboId,
          });

        const total = totalComboRef?.current
          ?.map(({ total }) => total)
          .reduce(
            (previousTotal, currentTotal) => previousTotal + currentTotal,
            0,
          );

        setTotalCombo(total);
      }
    },
    [isQtdTouched, itemQuantity, item, comboId, totalComboRef],
  );

  useEffect(() => {
    if (item?.price?.discountValue !== null) {
      updateTotalComboRef(item?.price?.discountValue);
    } else {
      updateTotalComboRef(item?.price?.originalValue);
    }
  }, [itemQuantity]);

  const getPrice = (value: number | undefined) =>
    (value === undefined ? 0 : value) * (itemQuantity === 0 ? 1 : itemQuantity);

  return (
    <div className={classes.container}>
      <Typography
        className={classes.productName}
        variant="h6"
        title={item.title}
        data-testid="product-item-name"
      >
        {item.title}
      </Typography>
      <div className={classes.contentFlex}>
        <ImageSection productItem={{ image: item.image ? item.image : "" }} />
        <div className={classes.infoContainer}>
          <div className={classes.productDetails}>
            <div className={classes.additionalInfo}>
              <Typography
                className={classes.productSku}
                data-testid="product-sku"
              >
                {item.sku ? item.sku : ""}
              </Typography>

              <Typography
                className={classes.fullPackageDescription}
                data-testid="full-container-description"
              >
                {item.fullContainerDescription
                  ? item.fullContainerDescription
                  : ""}
              </Typography>

              <Typography
                className={classes.fullPackageDescription}
                data-testid="full-package-description"
              >
                {item.fullPackageDescription ? item.fullPackageDescription : ""}
              </Typography>
            </div>
          </div>
          {item?.price?.discountValue ? (
            <PriceSection
              price={getPrice(item?.price?.discountValue)}
              olderPrice={getPrice(item?.price?.originalValue)}
            />
          ) : (
            <PriceSection price={getPrice(item?.price?.originalValue)} />
          )}
        </div>
      </div>

      <div className={classes.quantityContainer}>
        <div className={classes.quantity}>
          <QuantityControl
            value={itemQuantity}
            onChange={(value) => {
              setIsQtdTouched(true);
              setItemQuantity(value);
            }}
            setItemQuantity={setItemQuantity}
            testId={`${item.platformId}-cart-item-quantity-control`}
            minValue={item.minimumQuantity}
            maxValue={item.maxQuantity}
            data-testid={`${item.platformId}-cart-item-quantity-control`}
          />
        </div>
        <StockSection stock={item?.availability} />
      </div>
    </div>
  );
}
