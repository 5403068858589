import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>(() =>
  createStyles({
    campaignsContainer: {
      marginBottom: 24,
    },
    loadingContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    errorContainer: {},
  }),
);
