import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    rectangle: {
      minWidth: "670px",
      borderRadius: "12px",
      backgroundColor: "#FFFFFF",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.16)",
      padding: "19px 16px 0px",
    },
    header: {
      position: "relative",
    },
    title: {
      display: "inline-block",
      color: "rgba(0,0,0,0.9)",
      fontFamily: "Work Sans",
      fontSize: "16px",
      fontWeight: 600,
      letterSpacing: "0.1px",
      lineHeight: "24px",
      marginRight: "64px",
    },
    closingX: {
      position: "absolute",
      top: "8px",
      right: "16px",
      borderRadius: "50%",
      border: "none",
      height: "32px",
      width: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    body: {
      margin: "21px 0px 33px",
      display: "flex",
    },
    calculator: {
      width: "50%",
    },
    description: {
      width: "50%",
      color: "rgba(0,0,0,0.9)",
      fontFamily: "Work Sans",
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      whiteSpace: "pre-line",
    },
    flexSpaceBetween: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      gap: "50px",
    },
    subTotal: {
      color: "rgba(0,0,0,0.9)",
      fontFamily: "Barlow",
      fontSize: "24px",
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "32px",
    },
    remainingBalance: {
      color: "rgba(0,0,0,0.55)",
      fontFamily: "Work Sans",
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      marginTop: "8px",
    },
    pointer: {
      cursor: "pointer",
    },
    blockBox: {
      maxWidth: "100%!important",
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
    },
  }),
);
