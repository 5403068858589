import React from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FormikErrors, FormikTouched } from "formik";
import { Box, FormControl, Typography } from "@material-ui/core";
import Select from "../../../../../components/select/Select";
import GAP_REASONS, { gapReasonValues } from "./gapReasons";
import GapModalStyles from "./GapModalStyles";
import OrdersStore from "../../../../../stores/order/OrdersStore";
import {
  isFeatureEnabled,
  TLP_CALL_SUMMARY_FLOW_ORDER_PLACEMENT,
  TLP_CALL_SUMMARY_FLOW_ORDER_PLACEMENT_ADMIN,
} from "../../../../../config/featureToggles";

interface Props {
  values: FormValues;
  errors: FormikErrors<FormValues>;
  touched: FormikTouched<FormValues>;
  handleChange: (e: React.ChangeEvent<unknown>) => void;
}

export interface FormValues {
  gapReason: string;
  gapDescription: string;
}

const GapReasonForm: React.FunctionComponent<Props> = ({
  values,
  errors,
  touched,
  handleChange,
}) => {
  const { t } = useTranslation();

  const classes = GapModalStyles();
  const { orderInfo, ordersList, hasTechnicalProblem } = useStore(OrdersStore);

  const callSummaryEnabled = isFeatureEnabled(
    TLP_CALL_SUMMARY_FLOW_ORDER_PLACEMENT,
    TLP_CALL_SUMMARY_FLOW_ORDER_PLACEMENT_ADMIN,
  );

  const shouldDisableGapReason = () => {
    return !!(
      callSummaryEnabled &&
      values.gapReason === gapReasonValues.TECHNICAL_PROBLEM &&
      hasTechnicalProblem &&
      (orderInfo?.orderId || ordersList?.length > 0)
    );
  };

  const gapReasons = () => {
    return GAP_REASONS.filter((element) => element.value !== "NOT_IDENTIFIED");
  };

  return (
    <>
      <Typography className={classes.title}>
        {t("GapReason.GAP_REASON_LABEL")}
      </Typography>
      <Box
        className={classNames(classes.inputContainer, {
          [classes.inputWithError]: !!errors.gapReason && touched.gapReason,
        })}
      >
        <div className={classes.label}>
          <span className={classes.labelText} data-testid="gap-reason-label">
            {t("GapReason.REASON")}
          </span>
          <FormControl error={!!errors.gapReason && touched.gapReason}>
            <Select
              name="gapReason"
              value={values.gapReason}
              options={gapReasons()}
              onChange={handleChange}
              error={errors.gapReason}
              touched={touched.gapReason}
              testId="gap-reason-select"
              disabled={shouldDisableGapReason()}
            />
          </FormControl>
        </div>
      </Box>

      <Box className={classes.inputContainer}>
        <label htmlFor="gap-description" className={classes.label}>
          <span
            className={classes.labelText}
            data-testid="gap-reason-details-label"
          >
            {t("GapReason.DETAILS")}
          </span>
          <textarea
            id="gap-description"
            name="gapDescription"
            placeholder={t("GapReason.TYPE_DETAILS")}
            className={classes.textarea}
            value={values.gapDescription}
            onChange={handleChange}
            data-testid="gap-reason-details-value"
          />
        </label>
      </Box>
    </>
  );
};

export default GapReasonForm;
