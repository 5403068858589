import React, { useCallback, useEffect, useRef, useState } from "react";
import { useStore } from "effector-react";
import {
  FixedSizeList,
  ListChildComponentProps,
  ListItemKeySelector,
} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Button } from "@hexa-ui/components";
import { ThemeProvider } from "@hexa-ui/theme";
import { useTranslation } from "react-i18next";
import { CatalogItem } from "../../../../../domains/Catalog";
import { productListLoaded } from "../../../../../config/typewriter";
import AgentCallsStore from "../../../../../stores/agentCall/AgentCallStore";
import { useCollapsibleSideNavContext } from "../../../../../contexts/CollapsibleSideNavContext";
import SearchCatalogStore from "../../../../../stores/search/SearchCatalogStore";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import CatalogVirtualizedTableStyles from "./CatalogVirtualizedTableStyles";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_PALLET_ITEMS,
  TLP_PALLET_ITEMS_ADMIN,
  GROW_BEES_PRODUCT_SEARCH,
  GROW_NEW_CART_EXHIBITION,
} from "../../../../../config/featureToggles";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import ProductItemV2 from "../productItemV2/ProductItemV2";
import ProductItem from "../productItem/ProductItem";

interface Props {
  catalogs: CatalogItem[];
  searchValue?: string;
  onClick?: () => void;
  moreItemsToShow?: boolean;
}

interface ListProps {
  width: number;
  itemKey: ListItemKeySelector | undefined;
  itemData: CatalogItem[];
  row: React.FunctionComponent<ListChildComponentProps>;
  // eslint-disable-next-line react/no-unused-prop-types
  collapsibleIsOpen: boolean;
  isPalletEnabled: boolean;
  isProductSearchEnabled: boolean;
  setShowLoadMoreButton: (showButton: boolean) => void;
  firstNewItem: number;
  doneMoreItems: boolean;
  isNewCartExhibitionEnabled?: boolean;
}

export const List: React.FC<ListProps> = ({
  width,
  itemKey,
  itemData,
  row,
  isPalletEnabled,
  isProductSearchEnabled,
  setShowLoadMoreButton,
  firstNewItem,
  doneMoreItems,
  isNewCartExhibitionEnabled,
}) => {
  const { list, listProductSearch } = CatalogVirtualizedTableStyles();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listRef = useRef<any>();

  const itemSize = () => {
    /* istanbul ignore else */
    if (isNewCartExhibitionEnabled) {
      return 200;
    }

    return isPalletEnabled ? 180 : 160;
  };

  const totalItems = itemData.length;
  const itemContainerHeight = 430;
  const scrollContainerHeight = itemSize() * totalItems - itemContainerHeight;

  /* istanbul ignore next */
  useEffect(() => {
    if (doneMoreItems) {
      listRef.current.scrollToItem(firstNewItem);
    }
  }, [doneMoreItems, firstNewItem]);

  const onScroll = () => {
    if (listRef.current && isProductSearchEnabled) {
      const { scrollOffset } = listRef.current?.state;
      if (scrollContainerHeight === scrollOffset) {
        setShowLoadMoreButton(true);
      } else {
        setShowLoadMoreButton(false);
      }
    }
  };

  return (
    <FixedSizeList
      className={isProductSearchEnabled ? listProductSearch : list}
      height={itemContainerHeight}
      width={width}
      itemSize={itemSize()}
      itemKey={itemKey}
      itemCount={itemData.length}
      itemData={itemData}
      ref={listRef}
      onScroll={() => onScroll()}
      data-testid="fixed-size-list"
    >
      {row}
    </FixedSizeList>
  );
};

const CatalogVirtualizedTable: React.FunctionComponent<Props> = ({
  catalogs,
  searchValue,
  onClick,
  moreItemsToShow,
}) => {
  const agentCallsStore = useStore(AgentCallsStore);
  const { collapsibleIsOpen } = useCollapsibleSideNavContext();
  const { dispatchPocEvent } = useAnalytics();
  const GlobalStates = useStore(GlobalStore);
  const { loadingSearchMore, firstNewItem, doneMoreItems } =
    useStore(SearchCatalogStore);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const { t } = useTranslation();
  const isPalletEnabled = isFeatureEnabled(
    TLP_PALLET_ITEMS,
    TLP_PALLET_ITEMS_ADMIN,
    GlobalStates.user.keyToggle,
  );

  const isBeesProductSearchEnabled = isFeatureEnabledV2(
    GROW_BEES_PRODUCT_SEARCH,
    GlobalStates.user.zone,
  );

  const isNewCartExhibitionEnabled = isFeatureEnabledV2(
    GROW_NEW_CART_EXHIBITION,
    GlobalStates.user.zone,
  );

  /* istanbul ignore next */
  const itemKey = (index: number) => catalogs[index].sku;

  useEffect(() => {
    dispatchPocEvent(
      productListLoaded,
      {
        list_quantity: catalogs.length,
        is_resumed: agentCallsStore.isResumed,
        search_query: searchValue || null,
      },
      {
        removeProps: [
          "total_gap",
          "total_vol_gap",
          "is_resumed",
          "DDC",
          "poc_potential",
          "poc_recurrence",
          "credit_available",
        ],
      },
    );
  }, [
    agentCallsStore.isResumed,
    catalogs.length,
    dispatchPocEvent,
    searchValue,
  ]);

  /* istanbul ignore next */
  const Row: React.FunctionComponent<ListChildComponentProps<CatalogItem>> =
    useCallback(
      (props) => {
        const { index } = props;
        const item = catalogs[index];
        item.itemQuantity = 1;

        if (isNewCartExhibitionEnabled) {
          return (
            <ProductItemV2 productItem={item} childComponentProps={props} />
          );
        }

        return <ProductItem productItem={item} childComponentProps={props} />;
      },
      [catalogs, isNewCartExhibitionEnabled],
    );

  /* istanbul ignore next */
  return (
    <AutoSizer>
      {({ width }) => (
        <>
          <List
            width={width}
            itemKey={itemKey}
            itemData={catalogs}
            row={Row}
            collapsibleIsOpen={collapsibleIsOpen}
            isPalletEnabled={isPalletEnabled}
            isProductSearchEnabled={isBeesProductSearchEnabled}
            setShowLoadMoreButton={setShowLoadMoreButton}
            firstNewItem={firstNewItem}
            doneMoreItems={doneMoreItems}
            isNewCartExhibitionEnabled={isNewCartExhibitionEnabled}
          />
          {showLoadMoreButton && moreItemsToShow && isBeesProductSearchEnabled && (
            <div
              style={{
                width,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "24px",
              }}
            >
              <ThemeProvider theme="grow">
                <Button
                  size="medium"
                  variant="secondary"
                  isLoading={loadingSearchMore}
                  onClick={onClick}
                  data-testid="load-more-search-products-button"
                >
                  {t("OrderTaking.SEARCH_LOAD_MORE")}
                </Button>
              </ThemeProvider>
            </div>
          )}
        </>
      )}
    </AutoSizer>
  );
};

CatalogVirtualizedTable.defaultProps = {
  searchValue: "",
};

export default React.memo(CatalogVirtualizedTable);
