import React from "react";
import { Typography } from "@material-ui/core";
import { LinearProgressBar, ProgressBarStyles } from "./ProgressBarStyles";

interface Props {
  percentage: number;
  value: number;
  text: string;
}

const ProgressBar: React.FunctionComponent<Props> = ({
  percentage,
  value,
  text,
}) => {
  const classes = ProgressBarStyles();

  return (
    <div className={classes.container} data-testid="progress-bar-container">
      <div className={classes.barTextContainer}>
        <Typography
          className={classes.barTopTextValue}
          variant="subtitle1"
          data-testid="progress-bar-main-text"
        >
          {text}
        </Typography>
        <div className={classes.arrowDown} />
      </div>

      <div className={classes.barValueContainer}>
        <div
          className={classes.percentage}
          data-testid="progress-bar-percentage-text"
        >
          {percentage ? `${percentage}%` : "0%"}
        </div>
        <LinearProgressBar
          variant="determinate"
          data-testid="progress-bar-percentage-bar"
          value={percentage || 0}
        />
      </div>

      <div className={classes.barTextContainer}>
        <Typography
          className={classes.barBottomTextValue}
          variant="subtitle1"
          data-testid="progress-bar-main-value"
        >
          {value || 0}
        </Typography>
      </div>
    </div>
  );
};

export default ProgressBar;
