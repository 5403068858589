import { Typography } from "@material-ui/core";
import React from "react";
import TooltipInfoStyles from "./TooltipInfo.styles";
import { ComboItemDetail } from "../../domains/products/Product";

interface Props {
  comboDetails?: Array<ComboItemDetail>;
  itemName?: string;
  fullContainerDescription?: string;
  fullPackageDescription?: string;
  testid?: string;
}

const TooltipInfo = ({
  comboDetails,
  itemName,
  fullContainerDescription,
  fullPackageDescription,
  testid,
}: Props): JSX.Element => {
  const classes = TooltipInfoStyles();

  if (comboDetails !== undefined && comboDetails.length) {
    return (
      <div className={classes.tooltipContent} data-testid={testid}>
        {comboDetails.map((combo, index) => (
          <>
            <Typography
              className={classes.tooltipText}
              data-testid={`${testid}-tooltip-title`}
              key={`tooltip-title-key-${combo.id}`}
            >
              {combo.name}
            </Typography>
            <Typography
              className={classes.tooltipText}
              variant="h2"
              data-testid={`${testid}-tooltip-description`}
              key={`tooltip-description-key-${combo.id}`}
            >
              {combo.packageName && `• ${combo.packageName} `}
              {combo.packageContainer && `• ${combo.packageContainer} `}
            </Typography>
            {index !== comboDetails.length - 1 && (
              <br style={{ paddingBottom: 10 }} />
            )}
          </>
        ))}
      </div>
    );
  }

  return (
    <div className={classes.tooltipContent} data-testid={testid}>
      <Typography
        className={classes.tooltipText}
        data-testid={`${testid}-tooltip-title`}
      >
        {itemName}
      </Typography>
      <Typography
        className={classes.tooltipText}
        variant="h2"
        data-testid={`${testid}-tooltip-description`}
      >
        {fullPackageDescription && `• ${fullPackageDescription} `}
        {fullContainerDescription && `• ${fullContainerDescription} `}
      </Typography>
    </div>
  );
};

export default TooltipInfo;
