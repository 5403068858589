/* eslint-disable global-require */
import React, { useState } from "react";
import Styles from "./ImageSection.styles";

const productPlaceholder =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require("../../../../../../../assets/images/ProductPlaceholder.png") as string;

interface Props {
  productItem: { image: string };
}

const ImageSection = ({ productItem }: Props): JSX.Element => {
  const classes = Styles();

  const [src, setSrc] = useState(productItem.image);

  return (
    <div className={classes.imageWrapper}>
      <img
        src={src || productPlaceholder}
        onError={() => setSrc(productPlaceholder)}
        alt="A product displayed"
        data-testid="product-image"
      />
    </div>
  );
};

export default ImageSection;
