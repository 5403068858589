import { Skeleton } from "@material-ui/lab";
import React from "react";
import TagCarouselStyles from "./TagCarouselStyles";

const LocalMissionTagsSkeleton = (): JSX.Element => {
  const classes = TagCarouselStyles({
    contentContainerWidth: 0,
  });

  return (
    <div className={classes.skeletonContainer}>
      <Skeleton
        width={80}
        height={24}
        variant="rect"
        style={{ borderRadius: "16px" }}
      />
      <Skeleton
        width={80}
        height={24}
        variant="rect"
        style={{ borderRadius: "16px" }}
      />
      <Skeleton
        width={80}
        height={24}
        variant="rect"
        style={{ borderRadius: "16px" }}
      />
    </div>
  );
};

export default LocalMissionTagsSkeleton;
