import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    title: {
      color: "#c9201d",
      fontSize: "14px",
    },
    reloadTitle: {
      marginRight: "4px",
      borderBottom: "1px solid",
      fontSize: "14px",
    },
    reloadContent: {
      marginTop: "6px",
    },
  }),
);
