import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    title: {
      paddingBottom: "6px",
      fontSize: "12px",
      fontWeight: 600,
      color: palette.common.white,
    },
    container: {
      paddingBottom: "8px",
      paddingLeft: "28px",
    },
    containerTags: {
      paddingLeft: "28px",
    },
  }),
);
