import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../../../../assets/CloseButtonIcon";
import Modal from "../../../../../../components/modal/Modal";
import NewPhoneForm from "./components/NewPhoneForm";
import NewPhoneModalStyles from "./NewPhoneModalStyles";

interface Props {
  openNewPhone: boolean;
  onClose: (open: boolean) => void;
  setCurrentPage: (value: number) => void;
}

export default function NewPhoneModal({
  openNewPhone,
  onClose,
  setCurrentPage,
}: Props): React.ReactElement {
  const classes = NewPhoneModalStyles();
  const { t } = useTranslation();

  const handleOnClose = () => onClose(false);

  const handleCloseOnSuccess = () =>
    setTimeout(
      // istanbul ignore next
      () => onClose(false),
      1000,
    );

  return (
    <Modal open={openNewPhone} borderRadius="1.5rem">
      <>
        <div
          className={classes.container}
          data-testid="phone-ranking-new-phone-modal"
        >
          <IconButton
            onClick={() => handleOnClose()}
            data-testid="phone-ranking-new-phone-close-modal-button"
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
          <section className={classes.header}>
            <Typography
              variant="h1"
              className={classes.title}
              data-testid="phone-ranking-new-phone-title"
            >
              {t("PHONE_RANKING.ADD_PHONE")}
            </Typography>
          </section>
          <NewPhoneForm
            handleCloseOnSuccess={handleCloseOnSuccess}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </>
    </Modal>
  );
}
