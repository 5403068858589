import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import {
  isFeatureEnabledV2,
  TLP_CAMPAIGN_FOCUS_VENDOR,
  TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  GROW_ORGANIZATION_ACTIVATION,
} from "@config/featureToggles";
import GlobalAdminConfigStore from "@stores/globaAdminConfig/GlobalAdminConfigStore";
import CampaignsStore from "@stores/campaigns/CampaignsStore";
import { getCampaigns } from "@stores/campaigns/CampaignsEvents";
import PocInformationStore from "@stores/callList/pocInformation/PocInformationStore";
import AgentCallStore from "@stores/agentCall/AgentCallStore";
import GlobalStore from "@stores/global/GlobalStore";
import VendorStore from "@stores/vendor/VendorStore";
import CollapsibleTitle from "@components/collapsableTitle/CollapsibleTitle";
import MissionsIcon from "@assets/newIcons/FocusSkuIcon";
import { getUserInfos, returnVendorId } from "@config/utils/functions";
import { getVendorName } from "grow-shared-services";
import { v4 as guid } from "uuid";
import MissionsProducts from "../missionsProducts/MissionsProducts";

type CampaignsItems = Array<{
  sku: string;
  productName: string;
  unitPrice: number;
  image: string;
  tag: string;
  campaignName: string;
}>;

interface Props {
  handleOpen: () => void;
  open: boolean;
}

const MissionsFocusSkuComponent: React.FC<Props> = ({ handleOpen, open }) => {
  const { t } = useTranslation();
  const { error, focus, loading } = useStore(CampaignsStore);
  const { clientId, isTypedPoc, pocCallOrigin } = useStore(AgentCallStore);
  const {
    pocInformation: { deliveryCenterId, segment },
  } = useStore(PocInformationStore);
  const { user } = GlobalStore.getState();
  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { vendor } = useStore(VendorStore);
  const { orgId } = getUserInfos();

  const isCampaignFocusVendorEnabled = isFeatureEnabledV2(
    TLP_CAMPAIGN_FOCUS_VENDOR,
    user.keyToggle,
  );

  const isMultiContractCampaignServiceEnabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
    user.zone,
  );

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const Items = useMemo(() => {
    return focus
      ?.map((item) => {
        return Object.values({
          ...item.campaignProducts.map((focusProduct) => ({
            ...focusProduct,
            tag: item.campaignTag,
            campaignName: item.campaignName,
          })),
        });
      })
      .flat();
  }, [focus]);

  const onRetryMissionsProductsHandler = () => {
    const vendorId = returnVendorId(userConfig.vendorId);
    const vendorName = getVendorName(userConfig.vendorId);
    const bodyMultiContract = {
      DDC: deliveryCenterId || "",
      segment: segment || "",
      accountId: clientId,
      isTypedPoc,
      pocCallOrigin,
      vendorId:
        isMultiContractCampaignServiceEnabled || isOrganizationConfigEnabled
          ? vendor?.id
          : vendorId,
      vendorName:
        isMultiContractCampaignServiceEnabled || isOrganizationConfigEnabled
          ? undefined
          : vendorName,
      country: user.zone,
      isCampaignFocusVendorEnabled,
      isMultiContractCampaignServiceEnabled,
    };
    const bodyOrganization = {
      orgId,
      requestTraceId: guid(),
    };

    /* istanbul ignore next */
    getCampaigns({
      ...bodyMultiContract,
      ...bodyOrganization,
    });
  };

  const shouldShowMissionsProducts = () => {
    if (error) {
      return true;
    }
    return (Items && Items.length > 0) || loading;
  };

  return (
    <>
      {shouldShowMissionsProducts() && (
        <CollapsibleTitle
          icon={<MissionsIcon />}
          title={t("FOCUS_SKU.FOCUS_SKU_TITLE")}
          testId="missions-focus-sku-collapse"
          open={open}
          handleOpen={handleOpen}
        >
          <MissionsProducts
            onRetryHandler={onRetryMissionsProductsHandler}
            Items={Items as CampaignsItems}
            error={error}
            Loading={loading}
            screenSection="Target products"
          />
        </CollapsibleTitle>
      )}
    </>
  );
};

export default React.memo(MissionsFocusSkuComponent);
