import React from "react";
import { Typography } from "@material-ui/core";
import Button from "../../../../../../components/button/Button";
import useStyles from "./ButtonPhoneRankingStyles";

interface ButtonPhoneRankingProps {
  onClick: () => void;
  title: string;
  dataTestId?: string;
}

const ButtonPhoneRanking: React.FC<ButtonPhoneRankingProps> = ({
  onClick,
  title,
  dataTestId,
}) => {
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      className={classes.phoneRankingButton}
      data-testid={`${dataTestId}-button`}
    >
      <Typography variant="button" className={classes.btnText}>
        {title}
      </Typography>
    </Button>
  );
};

export default ButtonPhoneRanking;
