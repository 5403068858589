import React from "react";

const DownArrow: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="5"
    viewBox="0 0 10 5"
  >
    <g fill="none">
      <g fill="#7C7C7C">
        <g>
          <g>
            <path
              d="M59 15L64 20 54 20z"
              transform="translate(-1013 -437) translate(951 342) translate(8 80) rotate(-180 59 17.5)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default DownArrow;
