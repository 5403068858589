import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";

import axios from "../../config/axios/axiosInstance";
import {
  TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
  isFeatureEnabledV2,
} from "../../config/featureToggles";
import { getCountryByToken } from "../../config/utils/functions";
import ScriptResponse from "../../domains/script/Script";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export async function getServicesScript(
  countryId: string,
  vendorId?: string,
): Promise<ScriptResponse> {
  const log = useLogService();

  const isScriptServiceV2Enabled = isFeatureEnabledV2(
    TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
    getCountryByToken(),
  );

  const baseUrl = "/grow-missions-service/grow-missions/admin";

  const url = isScriptServiceV2Enabled
    ? `${baseUrl}/v2/services/${countryId}/script?vendorId=${vendorId ?? ""}`
    : `${baseUrl}/services/${countryId}/script`;

  return axios
    .get<ScriptResponse>(`${url}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
