import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import useStyles from "./style";
import OrderInfo from "../../../../../../domains/customer/OrderInfo";

interface CommentsAndOrderProps {
  id: string;
  email: string;
  comment?: string;
  orders: Array<OrderInfo>;
}

const CommentsAndOrder: React.FC<CommentsAndOrderProps> = ({
  id,
  comment,
  orders,
  email,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [formattedOrders, setFormattedOrders] = useState<Array<string>>([]);
  useEffect(() => {
    const updatedOrders = orders?.map((each) => each.orderId);
    setFormattedOrders(updatedOrders);
  }, [orders]);
  return (
    <>
      <div>
        <Typography
          className={classes.email}
          data-testid={`comments-and-order-email-${id}`}
        >
          {email}
        </Typography>
        {!!orders?.length && (
          <Typography
            className={classes.orders}
            data-testid={`comments-and-order-orders-${id}`}
          >
            {t("CUSTOMER_PAGE.ORDER")} {formattedOrders?.join(", ")}
          </Typography>
        )}
      </div>
      {comment && (
        <div className={classes.commentContainer}>
          <Typography
            className={classes.comment}
            data-testid={`comments-and-order-comments-${id}`}
          >{`“${comment}”`}</Typography>
        </div>
      )}
    </>
  );
};

export default React.memo(CommentsAndOrder);
