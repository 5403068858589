import { useLogService } from "admin-portal-shared-services";
import qs from "qs";

import axios from "@config/axios/axiosInstance";

import AccountsResponseToPocInformationConverter from "../../../converters/AccountsResponseToPocInformationConverter";
import { AccountsResponseToSearchedPocConverter } from "../../../converters/AccountsResponseToSearchedPocConverter";
import PocInformation, { SearchedPoc } from "../../../domains/PocInformation";
import { getApiHost, getEndPoint } from "../../host/HostB2BService";
import { BeesContractsResponse } from "../domains/BeesContractsResponse";
import SearchPocRequest from "../domains/SearchPocRequest";

const BEES_CONTRACTS_ENDPOINT = "/accounts/v2/contracts";

export const findBeesContracts = ({
  accountId,
  vendorId,
  isSearchPoc,
}: SearchPocRequest): Promise<PocInformation | SearchedPoc> => {
  const log = useLogService();

  return axios
    .get<BeesContractsResponse>(
      `${getApiHost()}${getEndPoint()}${BEES_CONTRACTS_ENDPOINT}`,
      {
        params: createParams(accountId, vendorId, isSearchPoc),

        paramsSerializer: /* istanbul ignore next */ (params) => {
          return qs.stringify(params);
        },
      },
    )
    .then((response) => {
      log.info(
        `Get bees contract by accountId and vendorId were received from ${BEES_CONTRACTS_ENDPOINT} to our model`,
      );

      if (isSearchPoc)
        return AccountsResponseToSearchedPocConverter(response.data?.content);

      return AccountsResponseToPocInformationConverter(response.data?.content);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
};

function createParams(
  accountId: string,
  vendorId: string,
  isSearchPoc?: boolean,
) {
  if (isSearchPoc) {
    return {
      customerAccountId: accountId,
      vendorId,
      projection: `${createProjection()}`,
    };
  }

  return { accountId, vendorId, projection: `${createProjection()}` };
}

function createProjection() {
  return "legacyAccountId, customerAccountId, hasPONumberRequirement";
}
