import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    accomplished: {
      alignItems: "left",
    },
    notaccomplished: {
      alignItems: "left",
    },
    divider: {
      width: "1px",
      height: "100%",
      border: "solid 1px #dfdede",
      alignItems: "center",
      marginLeft: 25,
      marginRight: 8,
    },
  }),
);
