import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import classNames from "classnames";
import { useStore } from "effector-react";
import { getVendorName } from "grow-shared-services";
import { Container, Box, Divider } from "@material-ui/core";
import { IconButton } from "@hexa-ui/components";
import { X as closeIcon } from "@hexa-ui/icons";
import { Formik, Form } from "formik";
import { object, string, number, array, boolean } from "yup";
import { ScreenSection, useNextPocHook } from "@hooks/useNextPocHook";
import { Phone } from "../../../../../domains/phoneRanking/RankedPhones";
import CustomToast, {
  TOAST_TYPES,
} from "../../../../../components/CustomToast/CustomToast";
import CallLogsStore from "../../../../../stores/callLogs/CallLogsStore";
import {
  resetCallLogsEvent,
  updateTempCallLogsEffect,
} from "../../../../../stores/callLogs/CallLogsEvents";
import AutoDialerStore from "../../../../../stores/autodialer/AutoDialerStore";
import { resetSponsoredEvents } from "../../../../../stores/sponsored/SponsoredEvents";
import {
  isEmpty,
  getResponseDate,
  getValueOrNull,
  isNoHitClassification,
  filterMissionsTag,
  getB2OTag,
  returnVendorIdWithFT,
  getVendorNameWithFT,
  getBooleanOrFalse,
  clearPocVendorId,
} from "../../../../../config/utils/functions";
import {
  isFeatureEnabled,
  TLP_B2B_TRAINER,
  TLP_B2B_TRAINER_ADMIN,
  TLP_SEARCH_BY_POC,
  TLP_SEARCH_BY_POC_ADMIN,
  TLP_MULTI_VENDOR,
  TLP_MULTI_VENDOR_ADMIN,
  TLP_CALL_SUMMARY_PROMOTE_MARKET_PLACE_TAG,
  TLP_CALL_SUMMARY_PROMOTE_MARKET_PLACE_TAG_ADMIN,
  TLP_CALL_SUMMARY_REMOVE_FIELDS,
  TLP_CALL_SUMMARY_REWARDS_CHALLENGES_FEEDBACK,
  GROW_LOCAL_CALL_SUMARY,
  GROW_MISSION_REWARDS_REDEMPTION,
  GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
  isFeatureEnabledV2,
  GROW_EXCLUDE_TOPLINE_FROM_CALL_SUMMARY,
  GROW_EXCLUDE_MKTPLACE_FROM_CALL_SUMMARY,
  GROW_EXCLUDE_REWARDS_FROM_CALL_SUMMARY,
  GROW_EXCLUDE_SERVICE_FROM_CALL_SUMMARY,
  GROW_EXCLUDE_NOREWARDS_FROM_CALL_SUMMARY,
  GROW_CALL_SUMMARY_CTI_CALL_DURATION,
  GROW_CALL_SUMMARY_NEW_REGISTER_BUTTONS,
  TLP_CUSTOMER_PAGE,
  GROW_BEES_CTI_CALL_LOGS_TEMP,
  TLP_PHONE_RANKING_VENDOR,
  GROW_TECH_INCORRECT_PHONE_ON_VALID_CALL,
  TLP_CALL_AUTO_DIALER,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../../../../config/featureToggles";
import Modal from "../../../../../components/modal/Modal";
import useStyles from "./styles";
import FinalActions from "../finalActions/FinalActions";
import MissionsEffectiveness from "../missionsEffectiveness/MissionsEffectiveness";
import { callLaterOptions } from "../callTypification/classifications";
import { usageTypes } from "../../../../../config/usageTypes";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import {
  LINK_HOME,
  ANALYTICS_ROUTE_NAMES,
  DATE_FORMAT_STRING,
  ANALYTICS_REGISTRATION_ERROR_TYPE,
  OrderNavigationTabName,
  OFFERINGS_SECTION,
  B2O_PREFIX,
  FOCUS_SKU_TYPE,
  FOCUS_SKU_EQUALIZER,
  CALL_ID,
  EFFECTIVENESS_ENUM,
  PocCallOrigin,
  TAB_CUSTOMER_ROUTE,
  TAB_GAP_ROUTE,
  MISSION_TYPES,
  EndCallActions,
} from "../../../../../config/constants";
import { update as updateAgentCall } from "../../../../../usecase/agentCall/AgentCallUseCase";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import * as AgentCallEvents from "../../../../../stores/agentCall/AgentCallEvents";
import CampaignsStore from "../../../../../stores/campaigns/CampaignsStore";
import * as OrderTabEvents from "../../../../../stores/navigation/orderTab/OrderTabEvents";
import * as OfferingEvents from "../../../../../stores/navigation/offering/OfferingEvents";
import B2BTrainerStore from "../../../../../stores/emulator/EmulatorStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import * as GetNextPocEvents from "../../../../../stores/nextPoc/getNextPoc/GetNextPocEvents";
import { b2bTrainerDeleteEmulatorEffect } from "../../../../../stores/emulator/EmulatorEvents";
import { clearCatalog } from "../../../../../stores/catalog/CatalogEvents";
import { clearCampaignB2OProductsDb } from "../../../../../stores/campaigns/campaignsB2OProducts/CampaignsB2OProductsEvents";
import * as CartItemEvents from "../../../../../stores/cart/CartItemEvents";
import * as CartComboEvents from "../../../../../stores/cartCombo/CartComboEvents";
import * as CartEvents from "../../../../../stores/cart/CartEvents";
import * as PaymentMethodInfoEvents from "../../../../../stores/paymentsInformation/PaymentMethodInfoEvents";
import * as PromotionsEvents from "../../../../../stores/promotions/PromotionsEvents";
import * as PocInformationEvents from "../../../../../stores/callList/pocInformation/PocInformationEvents";
import * as CtiEvents from "../../../../../stores/cti/CtiEvents";
import * as SuggestedOrdersEvents from "../../../../../stores/suggestedOrders/SuggestedOrdersEvents";
import * as OrdersEvents from "../../../../../stores/order/OrdersEvents";
import * as TagsEvents from "../../../../../stores/tags/TagsEvents";
import { resetGenericInfos } from "../../../../../stores/genericInfos/GenericInfosEvents";
import * as CampaignsEvents from "../../../../../stores/campaigns/CampaignsEvents";
import { clearNewOfferings } from "../../../../../stores/newOfferings/NewOfferingsEvents";
import { createCallDurationEffect } from "../../../../../stores/callDuration/CallDurationEvent";
import { resetEffectiveMissionsEvent } from "../../../../../stores/globalAndLocalMissions/EffectivenessMissionEvents";
import * as CallTabEvents from "../../../../../stores/navigation/callTab/CallTabEvents";
import {
  callEndFailed,
  callEnded,
  callGetNextPocFailed,
} from "../../../../../config/typewriter";
import CallTabStore from "../../../../../stores/navigation/callTab/CallTabStore";
import CtiStore from "../../../../../stores/cti/CtiStore";
import loyaltyBusinessStore from "../../../../../stores/loyaltyBusiness/LoyaltyBusinessStore";
import LoyaltyProgramStore from "../../../../../stores/loyaltyProgram/LoyaltyProgramStore";
import PocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import CallCounterStore from "../../../../../stores/callCounter/CallCounterStore";
import RedeemStore from "../../../../../stores/redeem/RedeemStore";
import CallSummaryForm from "../callSummaryForm/CallSummaryForm";
import orderStore from "../../../../../stores/order/OrdersStore";
import TagsStore from "../../../../../stores/tags/TagsStore";
import {
  CLASSIFICATION_VALUES,
  TopLineMissionClassificationValues,
  GAPReasonValues,
  Mission as MissionModel,
} from "../../../../../domains/Mission";
import { clearRedeemItems } from "../../../../../stores/redeem/RedeemEvents";
import CustomerNotesStore from "../../../../../stores/customerNotes/CustomerNotesStore";
import PhonesRankedStore from "../../../../../stores/phoneRankingStore/phoneRankedStore";
import GlobalAdminConfigStore from "../../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import {
  addNewPhone,
  getPhonesRanked,
  resetPhonesRanking,
  updatePhoneRanking,
} from "../../../../../stores/phoneRankingStore/phoneRankedEvents";
import SponsoredStore from "../../../../../stores/sponsored/SponsoredStore";
import { TABS_TAGS_ENUM } from "../../../../../domains/tags/TabsToTagsEnum";
import EffectivenessMissionStore from "../../../../../stores/globalAndLocalMissions/EffectivenessMissionStore";
import { SponsoredMission } from "../../../../../domains/sponsored/SponsoredMission";
import RewardsChallengeStore from "../../../../../stores/RewardsChallenge/RewardsChallengeStore";
import CurrentPocCallDuration from "../../../../../stores/callDuration/CallDurationStore";
import { FormValues } from "../formTypes/FormTypes";
import CallStatus from "../callStatus/CallStatus";
import { missionTypesByTag } from "../../../../../domains/missions/MissionTypesByTag";
import { classificationTypes } from "../../phoneRanking/components/ClassificationButton/ClassificationTypeEnum";
import UpdateRankedPhonesRequest from "../../../../../domains/phoneRanking/UpdateRankedPhonesRequest";

const EMPTY = "";
const TAG_TOPLINEGAP = "#TopLineGAP";
const TAG_SPONSORED = "#sponsored";
const TAG_NOREWARDSREDEMPTION = "#norewardsredemption";
const TAG_NOREWARDSREDEMPTION_NEW_CASE = "#NoRewardsRedemption";
const TAG_LOCALMISSION = "#LocalMissions";
const TAG_FLEXIBLEMISSIONS = "#flexible_missions";

interface Mission {
  tag: string;
  type: string;
  typification: string;
  reason: string;
  comments: string;
  effectiveness: boolean | undefined;
  priority?: string;
}

interface Props {
  testId: string;
  open: boolean;
  onClose: () => void;
  onRegister: () => void;
  getTimer: () => number;
  lastCalledOrCopiedPhone: string;
}

interface Campaign {
  campaignTag: string;
  campaignName: string;
  campaignType: string;
}

interface CampaignAnalyticsProps {
  tags: Array<string>;
  names: Array<string>;
  types: Array<string>;
}

const CallSummaryMissionsModal: React.FunctionComponent<Props> = ({
  testId,
  open,
  onClose,
  onRegister,
  getTimer,
  lastCalledOrCopiedPhone,
  ...props
}) => {
  const { t } = useTranslation();
  const { dispatchPocEvent, dispatchGenericEvent } = useAnalytics();
  const history = useHistory();
  const classes = useStyles();
  const { ordersList, orderInfo, hasTechnicalProblem } = useStore(orderStore);
  const { autoDialerEnabled } = useStore(AutoDialerStore);
  const { callLogs, tempCallLogs } = useStore(CallLogsStore);
  const agentCallState = useStore(AgentCallStore);
  const { publicKey } = useStore(B2BTrainerStore);
  const globalState = useStore(GlobalStore);
  const { callTab } = useStore(CallTabStore);
  const ctiStore = useStore(CtiStore);
  const { totalPoints } = useStore(loyaltyBusinessStore);
  const { pocInformation, searchedPoc } = useStore(PocInformationStore);
  const callCounter = useStore(CallCounterStore);
  const { customerNotes } = useStore(CustomerNotesStore);
  const { balance: loyaltBalance } = useStore(LoyaltyProgramStore);
  const { redeemItemsIds, redeemItems } = useStore(RedeemStore);
  const { phones } = useStore(PhonesRankedStore);
  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);
  const [expanded, setExpanded] = useState<Map<string, boolean>>(new Map());
  const { list } = useStore(TagsStore);
  const [missions, setMissions] = useState<Array<Mission>>([]);
  const [classificationValue, setClassificationValue] = useState<string>("");
  const { sponsoredMissions, localMissions } = useStore(SponsoredStore);
  const { effectivenessMissions } = useStore(EffectivenessMissionStore);
  const { rewardsChallengeInformation } = useStore(RewardsChallengeStore);
  const { currentPocCallDuration } = useStore(CurrentPocCallDuration);
  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { getNextPoc } = useNextPocHook();

  const accountId = agentCallState.clientId;
  const { pocCallOrigin } = agentCallState;

  const [allMissions, setAllMissions] = useState<MissionModel[]>([]);

  const {
    validCampaigns = [],
    campaigns: allCampaigns = [],
    focus: allFocus = [],
  } = useStore(CampaignsStore);

  const [shouldShowLoading, setShouldShowLoading] = useState(false);

  const isCustomerPageEnabled = isFeatureEnabledV2(
    TLP_CUSTOMER_PAGE,
    globalState.user.keyToggle,
  );

  const b2bTrainerEnabled = isFeatureEnabled(
    TLP_B2B_TRAINER,
    TLP_B2B_TRAINER_ADMIN,
    globalState.user.keyToggle,
  );

  const isIncorrectPhoneOnValidCall = isFeatureEnabled(
    GROW_TECH_INCORRECT_PHONE_ON_VALID_CALL,
    globalState.user.keyToggle,
  );

  const isSearchByPocEnabled = isFeatureEnabled(
    TLP_SEARCH_BY_POC,
    TLP_SEARCH_BY_POC_ADMIN,
    globalState.user.keyToggle,
  );

  const isMultiVendor = isFeatureEnabled(
    TLP_MULTI_VENDOR,
    TLP_MULTI_VENDOR_ADMIN,
  );

  const isPromoteMarketPlaceTagSummaryEnabled = isFeatureEnabled(
    TLP_CALL_SUMMARY_PROMOTE_MARKET_PLACE_TAG,
    TLP_CALL_SUMMARY_PROMOTE_MARKET_PLACE_TAG_ADMIN,
    globalState.user.keyToggle,
  );

  const isCallSummaryRemoveFieldsEnabled = isFeatureEnabledV2(
    TLP_CALL_SUMMARY_REMOVE_FIELDS,
    globalState.user.keyToggle,
  );

  const isCallSummaryRewardsChallengesFeedBackEnabled = isFeatureEnabledV2(
    TLP_CALL_SUMMARY_REWARDS_CHALLENGES_FEEDBACK,
    globalState.user.keyToggle,
  );

  const isWipRewardsRedemptionEnable = isFeatureEnabledV2(
    GROW_MISSION_REWARDS_REDEMPTION,
    globalState.user.keyToggle,
  );

  const isGrowLocalCallSummaryEnabled = isFeatureEnabledV2(
    GROW_LOCAL_CALL_SUMARY,
    globalState.user.keyToggle,
  );

  const isLocalAndGlobalMissionsServiceEnable = isFeatureEnabledV2(
    GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
    globalState.user.keyToggle,
  );

  const isExcludeMktPlaceFromCallSummary = isFeatureEnabledV2(
    GROW_EXCLUDE_MKTPLACE_FROM_CALL_SUMMARY,
    globalState.user.keyToggle,
  );

  const isExcludeTopineFromCallSummary = isFeatureEnabledV2(
    GROW_EXCLUDE_TOPLINE_FROM_CALL_SUMMARY,
    globalState.user.keyToggle,
  );

  const isExcludeRewardsFromCallSummary = isFeatureEnabledV2(
    GROW_EXCLUDE_REWARDS_FROM_CALL_SUMMARY,
    globalState.user.keyToggle,
  );

  const isExcludeServiceFromCallSummary = isFeatureEnabledV2(
    GROW_EXCLUDE_SERVICE_FROM_CALL_SUMMARY,
    globalState.user.keyToggle,
  );
  const isExcludeNoRewardsFromCallSummary = isFeatureEnabledV2(
    GROW_EXCLUDE_NOREWARDS_FROM_CALL_SUMMARY,
    globalState.user.keyToggle,
  );

  const isNewRegisterButtonsEnabled = isFeatureEnabledV2(
    GROW_CALL_SUMMARY_NEW_REGISTER_BUTTONS,
    globalState.user.keyToggle,
  );

  const [actionAfterCall, setActionAfterCall] = useState(
    isSearchByPocEnabled &&
      agentCallState.isTypedPoc &&
      agentCallState.pocCallOrigin === PocCallOrigin.SEARCH_BY_POC
      ? EndCallActions.GO_HOME
      : EndCallActions.NEXT_POC,
  );

  const isCTIStatusCallSummaryEnabled = isFeatureEnabledV2(
    GROW_CALL_SUMMARY_CTI_CALL_DURATION,
    globalState.user.keyToggle,
  );

  const isAutodialerNewButtonsEnabled = isFeatureEnabledV2(
    TLP_CALL_AUTO_DIALER,
    globalState.user.keyToggle,
  );

  const isPhoneRankingVendor = isFeatureEnabledV2(
    TLP_PHONE_RANKING_VENDOR,
    globalState.user.keyToggle,
  );

  const isEnabledCtiCallLogsTempEvents = isFeatureEnabledV2(
    GROW_BEES_CTI_CALL_LOGS_TEMP,
    globalState.user.keyToggle,
  );

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    globalState.user.keyToggle,
  );

  const vendorId = returnVendorIdWithFT(
    userConfig?.vendorId,
    isPhoneRankingVendor,
  );
  const vendorName = getVendorNameWithFT(vendorId, isPhoneRankingVendor);

  const resetStoreStates = () => {
    AgentCallEvents.resetAgentCall();
    CartEvents.resetCart();
    CartItemEvents.resetItemCart();
    CartComboEvents.resetCombos();
    /* istanbul ignore next */
    PaymentMethodInfoEvents.resetPaymentMethodInfo();
    resetSponsoredEvents();
  };

  /* istanbul ignore next */
  const getPhoneList = () => {
    const getPhoneTypeArray = (phoneType: string) => {
      return phoneType.replace(/\s+/g, "").split(",");
    };

    return phones?.map((item) => ({
      phone_number: item.phoneNumber,
      phone_score: item.score,
      phone_source: getPhoneTypeArray(item.phoneType),
    }));
  };

  const handleActionAfterCall = (value: string) => {
    setActionAfterCall(value);
  };

  const hasPlacedOrder = useMemo(() => {
    return orderInfo?.orderId || ordersList?.length > 0;
  }, [orderInfo, ordersList]);

  const isGapWithOrderPlaced = useCallback(
    (tag: string) => {
      return tag === t(`tags.${TAG_TOPLINEGAP}`) && hasPlacedOrder;
    },
    [t, hasPlacedOrder],
  );

  const isEffectiveness = useCallback(
    (tag: string, missionId?: string) => {
      if (isGapWithOrderPlaced(tag)) {
        return true;
      }
      if (
        tag === TAG_LOCALMISSION ||
        tag === TAG_SPONSORED ||
        tag === TAG_FLEXIBLEMISSIONS
      ) {
        return effectivenessMissions.find(
          (mission) => mission.missionId === missionId,
        )?.effectiveness;
      }

      return undefined;
    },
    [isGapWithOrderPlaced, effectivenessMissions],
  );

  const typificationMessage = useCallback(
    (tag: string) => {
      if (isGapWithOrderPlaced(tag)) {
        return TopLineMissionClassificationValues.UPLIFT_ON_CALL_BY_AGENT;
      }
      return "";
    },
    [isGapWithOrderPlaced],
  );

  const effectivenessOrderPlaced = useCallback(
    (tag: string, missionEffectivenessParam?: boolean) => {
      if (isGapWithOrderPlaced(tag)) return true;
      return missionEffectivenessParam;
    },
    [isGapWithOrderPlaced],
  );

  const reasonMessage = useCallback(
    (tag: string) => {
      if (isGapWithOrderPlaced(tag) && hasTechnicalProblem) {
        return GAPReasonValues.TECHNICAL_PROBLEM;
      }

      return "";
    },
    [isGapWithOrderPlaced, hasTechnicalProblem],
  );

  const getMissionType = useCallback((tag: string) => {
    return missionTypesByTag[tag.startsWith(B2O_PREFIX) ? "#b2o" : tag];
  }, []);

  /* istanbul ignore next */
  const enrichMissionModelByGlobalAndLocal = useCallback(
    (globalAndLocalMission: SponsoredMission, mission: MissionModel) => {
      let tagMissionChanged = TAG_LOCALMISSION;
      let missionTypeChanged = globalAndLocalMission.sponsorPartnerId
        ? getMissionType(TAG_SPONSORED)
        : getMissionType(TAG_LOCALMISSION);
      if (globalAndLocalMission.missionType === MISSION_TYPES.FLEXIBLE) {
        tagMissionChanged = TAG_FLEXIBLEMISSIONS;
        missionTypeChanged = "global";
      }
      mission.tag = tagMissionChanged;
      mission.missionId = globalAndLocalMission.id;
      mission.missionTitle = globalAndLocalMission.missionTitle;
      mission.type = missionTypeChanged;
    },
    [getMissionType],
  );

  /* istanbul ignore next */
  const returnMissionsArrayWhenSearchByPoc = useCallback(() => {
    if (allCampaigns && allCampaigns.length > 0) {
      return allCampaigns.map((campaign) => ({
        tag: `#${campaign.campaignType}-${campaign.campaignTag}`,
        type: getMissionType(campaign.campaignTag),
        typification: "",
        reason: "",
        comments: "",
        effectiveness: undefined,
        priority: "Secondary",
      }));
    }

    return effectivenessMissions.map((missionAux) => ({
      tag: missionAux.tag,
      type: missionAux.type,
      typification: typificationMessage(missionAux.tag),
      reason: reasonMessage(missionAux.tag),
      comments: "",
      effectiveness: effectivenessOrderPlaced(
        missionAux.tag,
        missionAux.effectiveness,
      ),
      priority: "Secondary",
      missionId: missionAux?.missionId,
      missionTitle: missionAux?.missionTitle,
    }));
  }, [
    allCampaigns,
    effectivenessMissions,
    getMissionType,
    typificationMessage,
    reasonMessage,
    effectivenessOrderPlaced,
  ]);

  const definedPriority = (index: number) => {
    if (index === 0) {
      return "Primary";
    }

    return "Secondary";
  };

  const isThereRedemptionTag = (missions: Mission[]) => {
    return (
      missions &&
      !!(
        missions.some((mission) => mission.tag === TAG_NOREWARDSREDEMPTION) ||
        missions.some(
          (mission) => mission.tag === TAG_NOREWARDSREDEMPTION_NEW_CASE,
        )
      )
    );
  };

  const isMissionEffectiveness = (mission: SponsoredMission) => {
    const index = effectivenessMissions.findIndex((missionEffectiveness) => {
      return missionEffectiveness.missionId === mission.id;
    });

    return effectivenessMissions[index]?.effectiveness ?? false;
  };

  const handleSponsoredMissionsIds = () => {
    if (isLocalAndGlobalMissionsServiceEnable) {
      return sponsoredMissions
        .concat(localMissions)
        .map((mission) => {
          if (mission.stopCallingWhen === EFFECTIVENESS_ENUM.firstContactMade) {
            return mission.id;
          }

          if (
            mission.missionType === "simple" &&
            mission.stopCallingWhen === EFFECTIVENESS_ENUM.criteriaReached &&
            isMissionEffectiveness(mission)
          ) {
            return mission.id;
          }

          return undefined;
        })
        .filter(Boolean);
    }

    return !searchedPoc.vendorAccountId
      ? sponsoredMissions.map((sponsored) => {
          return sponsored.id;
        }) || []
      : [];
  };

  const missionTypeMap = useMemo(() => {
    return {
      [TABS_TAGS_ENUM.quickOrder]: !isGrowLocalCallSummaryEnabled,
      [TABS_TAGS_ENUM.sponsored]: !isGrowLocalCallSummaryEnabled,
      [TABS_TAGS_ENUM.localMissions]: !isGrowLocalCallSummaryEnabled,
      "#flexible_missions": !isGrowLocalCallSummaryEnabled,
      [TABS_TAGS_ENUM.marketplaceactivation]: !isExcludeMktPlaceFromCallSummary,
      [TABS_TAGS_ENUM.service]: !isExcludeServiceFromCallSummary,
      [TABS_TAGS_ENUM.toplineGap]: !isExcludeTopineFromCallSummary,
      [TABS_TAGS_ENUM.marketplace]: !isExcludeMktPlaceFromCallSummary,
      [TABS_TAGS_ENUM.rewards]: !isExcludeRewardsFromCallSummary,
      [TABS_TAGS_ENUM.norewards]: !isExcludeNoRewardsFromCallSummary,
    };
  }, [
    isGrowLocalCallSummaryEnabled,
    isExcludeMktPlaceFromCallSummary,
    isExcludeServiceFromCallSummary,
    isExcludeTopineFromCallSummary,
    isExcludeRewardsFromCallSummary,
    isExcludeNoRewardsFromCallSummary,
  ]);
  const shouldRenderMissionType = useCallback(
    (missionType: string) => missionTypeMap[missionType] ?? true,
    [missionTypeMap],
  );

  const createMission = useCallback(
    (
      tag: string,
      priority: number,
      globalAndLocalMission?: SponsoredMission,
    ): Mission => {
      const mission = {
        tag,
        type: getMissionType(tag),
        typification: typificationMessage(tag),
        reason: reasonMessage(tag),
        comments: "",
        effectiveness: isEffectiveness(tag, globalAndLocalMission?.id),
        priority: definedPriority(priority),
      } as MissionModel;

      if (globalAndLocalMission) {
        enrichMissionModelByGlobalAndLocal(globalAndLocalMission, mission);
      }

      return mission;
    },
    [
      getMissionType,
      enrichMissionModelByGlobalAndLocal,
      isEffectiveness,
      reasonMessage,
      typificationMessage,
    ],
  );

  useEffect(() => {
    let updatedMissions;
    if (
      isSearchByPocEnabled &&
      agentCallState.isTypedPoc &&
      agentCallState.pocCallOrigin === PocCallOrigin.SEARCH_BY_POC
    ) {
      updatedMissions = returnMissionsArrayWhenSearchByPoc();
    } else {
      const filteredMissions = filterMissionsTag(list, validCampaigns);

      updatedMissions = filteredMissions?.flatMap((tag, index) => {
        if (
          tag === TAG_SPONSORED ||
          tag === TAG_LOCALMISSION ||
          tag === TAG_FLEXIBLEMISSIONS
        ) {
          return (
            tag === TAG_SPONSORED ? sponsoredMissions : localMissions
          ).map((globalAndLocalMission) =>
            createMission(tag, index, globalAndLocalMission),
          );
        }
        return createMission(tag, index);
      });
    }

    if (isWipRewardsRedemptionEnable && redeemItems.length) {
      if (isThereRedemptionTag(updatedMissions))
        updatedMissions = updatedMissions.filter(
          (mission) =>
            mission.tag !== TAG_NOREWARDSREDEMPTION &&
            mission.tag !== TAG_NOREWARDSREDEMPTION_NEW_CASE,
        );

      updatedMissions.push(createMission(TAG_NOREWARDSREDEMPTION, 1));
    } else if (
      isWipRewardsRedemptionEnable &&
      !redeemItems.length &&
      isThereRedemptionTag(updatedMissions)
    ) {
      updatedMissions = updatedMissions.filter(
        (mission) =>
          mission.tag !== TAG_NOREWARDSREDEMPTION &&
          mission.tag !== TAG_NOREWARDSREDEMPTION_NEW_CASE,
      );
    }

    setAllMissions(updatedMissions);

    updatedMissions = updatedMissions?.filter((mission) =>
      shouldRenderMissionType(mission.tag),
    );

    setMissions(updatedMissions);
  }, [
    rewardsChallengeInformation,
    agentCallState.isTypedPoc,
    allCampaigns,
    isSearchByPocEnabled,
    list,
    open,
    isEffectiveness,
    getMissionType,
    typificationMessage,
    reasonMessage,
    validCampaigns,
    sponsoredMissions,
    isWipRewardsRedemptionEnable,
    redeemItems.length,
    isGrowLocalCallSummaryEnabled,
    localMissions,
    shouldRenderMissionType,
    agentCallState.pocCallOrigin,
    createMission,
    returnMissionsArrayWhenSearchByPoc,
  ]);

  const returnStringWithOrderReason = (orderReason: string) => {
    if (orderReason) {
      return `- ${orderReason}`;
    }
    return "";
  };
  const concatenateOrderList = useCallback(() => {
    const concatenaredOrders = ordersList ? [...ordersList] : [];
    const haveFindOrderId = ordersList?.find((each) => {
      return each.orderId === orderInfo?.orderId;
    });
    if (!haveFindOrderId && orderInfo?.orderId) {
      concatenaredOrders.push(orderInfo);
    }
    const OrderListMaped = concatenaredOrders.map((each) => {
      if (hasTechnicalProblem) {
        return `${each.orderId} - ${t(
          "GapReason.HAS_TECHNICAL_PROBLEM",
        )} ${returnStringWithOrderReason(each.orderReason)}`;
      }

      return `${each.orderId} - ${each.orderReason}`;
    });
    return OrderListMaped?.length > 0 ? OrderListMaped?.join("\n") : "";
  }, [ordersList, hasTechnicalProblem, orderInfo, t]);

  const getInitialClassification = () => {
    return hasPlacedOrder ? CLASSIFICATION_VALUES.SUCCESS_ON_CONTACT : EMPTY;
  };

  const initialValues: FormValues = {
    classification: getInitialClassification(),
    classificationDetails: concatenateOrderList(),
    callLater: 0.25,
    missions,
  };

  /* istanbul ignore next */
  const missionsHasField = (tag?: string) => {
    const filteredByMissions = missions.filter(
      (e) =>
        e.tag === t("tags.#TopLineGAP") ||
        e.tag === t("tags.#marketplaceactivation") ||
        e.tag === t("tags.#digital_services") ||
        (e.tag === t("tags.#promoterewardschallenges", { lng: "en" }) &&
          isCallSummaryRewardsChallengesFeedBackEnabled) ||
        (e.tag === t("tags.#PromoteMarketPlace", { lng: "en" }) &&
          isPromoteMarketPlaceTagSummaryEnabled) ||
        e.type === getMissionType("#sponsored"),
    );

    const filteredByField = filteredByMissions.filter((e) => {
      if (e.tag === "#digital_services") {
        return e.effectiveness
          ? false
          : isEmpty(e.reason) || (isEmpty(e.typification) && e.effectiveness);
      }
      if (e.type === getMissionType("#sponsored")) {
        return isEmpty(e.reason);
      }
      return isEmpty(e.reason) || (isEmpty(e.typification) && e.effectiveness);
    });

    const findMissionsByTag = filteredByField.find(
      (mission) => mission.tag === tag,
    );

    return !!findMissionsByTag;
  };

  /* istanbul ignore next */
  const validationSchema = object().shape({
    classification: string().required(
      t("CallTypification.CLASSIFICATION_REQUIRED_MESSAGE"),
    ),
    callLater: number().required(
      t("CallTypification.CLASSIFICATION_REQUIRED_MESSAGE"),
    ),

    missions: array().of(
      object().shape({
        typification: string().test({
          name: "Validate typification",
          message:
            String(t("CallTypification.CLASSIFICATION_REQUIRED_MESSAGE")) ?? "",
          test(value: string) {
            /* eslint-disable-next-line */
            const { tag, effectiveness } = this.parent;
            const hasValue = !!value;

            const verifyExistTypification =
              tag !== t("tags.#promoterewardschallenges", { lng: "en" }) &&
              tag !== t("tags.#digital_services", { lng: "en" });

            const shouldVerifyAllFields = isCallSummaryRemoveFieldsEnabled
              ? false
              : verifyExistTypification;

            if (effectiveness && shouldVerifyAllFields) {
              if (missionsHasField(tag) && hasValue === false) {
                return false;
              }
            }

            return true;
          },
        }),

        reason: string().test({
          name: "Validate reason",
          message:
            String(t("CallTypification.CLASSIFICATION_REQUIRED_MESSAGE")) ?? "",
          test(value: string) {
            const hasValue = !!value;
            /* eslint-disable-next-line */
            const { tag, effectiveness } = this.parent;

            if (isCallSummaryRemoveFieldsEnabled && effectiveness) {
              return true;
            }

            if (missionsHasField(tag) && hasValue === false) {
              return false;
            }

            return true;
          },
        }),

        effectiveness: boolean().required(
          t("CallTypification.CLASSIFICATION_REQUIRED_MESSAGE"),
        ),
      }),
    ),
  });

  useEffect(() => {
    const expandedItems = new Map();
    /* istanbul ignore next */
    if (missions) {
      missions.forEach((item) => expandedItems.set(item.tag, false));
    }

    setExpanded(expandedItems);
  }, [missions]);

  /* istanbul ignore next */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleExpanded = useCallback(
    (tag: string, isExpanded: boolean) => {
      const expandedItems = new Map();
      missions.forEach((item) =>
        expandedItems.set(item.tag, item.tag === tag ? isExpanded : false),
      );
      setExpanded(expandedItems);
    },
    [missions],
  );

  const campaignAnalytics = useMemo(() => {
    const analytics: CampaignAnalyticsProps = {
      tags: [],
      names: [],
      types: [],
    };
    if (
      isSearchByPocEnabled &&
      agentCallState.isTypedPoc &&
      agentCallState.pocCallOrigin === PocCallOrigin.SEARCH_BY_POC
    ) {
      const campaignsSearchByPoc = allCampaigns.map((campaign) => {
        return {
          campaignTag: campaign.campaignTag,
          campaignName: campaign.campaignName,
          campaignType: campaign.campaignType,
        } as Campaign;
      });
      campaignsSearchByPoc.forEach(
        ({ campaignTag, campaignName, campaignType }) => {
          analytics.tags.push(campaignTag);
          analytics.names.push(campaignName);
          analytics.types.push(campaignType);
        },
      );
    } else {
      const validCampaignsByPoc = allCampaigns
        .filter((campaign) =>
          validCampaigns.some((tag) => tag === campaign.campaignTag),
        )
        .map((campaign) => {
          return {
            campaignTag: campaign.campaignTag,
            campaignName: campaign.campaignName,
            campaignType: campaign.campaignType,
          } as Campaign;
        });
      validCampaignsByPoc.forEach(
        ({ campaignTag, campaignName, campaignType }) => {
          analytics.tags.push(campaignTag);
          analytics.names.push(campaignName);
          analytics.types.push(campaignType);
        },
      );
    }

    const validFocusByPoc = allFocus.map((campaign) => {
      return {
        campaignTag: campaign.campaignTag,
        campaignName: campaign.campaignName,
        campaignType: campaign.campaignType,
      } as Campaign;
    });
    validFocusByPoc.forEach(({ campaignTag, campaignName, campaignType }) => {
      analytics.tags.push(campaignTag);
      analytics.names.push(campaignName);
      analytics.types.push(
        campaignType === "" ||
          campaignType == null ||
          campaignType === FOCUS_SKU_EQUALIZER
          ? FOCUS_SKU_TYPE
          : campaignType,
      );
    });
    analytics.types = [...new Set(analytics.types)];
    return analytics;
  }, [
    allFocus,
    validCampaigns,
    allCampaigns,
    isSearchByPocEnabled,
    agentCallState.isTypedPoc,
    agentCallState.pocCallOrigin,
  ]);

  const validCampaignsAnalytics = useMemo(() => {
    const analytics: Array<string> = [];

    validCampaigns.forEach((campaign) => {
      analytics.push(getB2OTag(campaign));
    });
    return analytics;
  }, [validCampaigns]);

  /* istanbul ignore next */
  const getDuration = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result: any,
    duration: string,
    reponseDate?: Moment.Moment,
  ) => {
    if (duration === "service" && result.startServiceDate) {
      const date = reponseDate || getResponseDate();
      const resultTime = date.diff(result.startServiceDate, "seconds");
      return Math.abs(resultTime || 0);
    }

    if (duration === "analysis") {
      const time = Moment.utc(result.startCall);
      const resultTime = time.diff(result.startServiceDate, "seconds");
      return Math.abs(resultTime || 0);
    }

    return null;
  };

  /* istanbul ignore next */
  const getCallDuration = (
    startCall: string | undefined,
    responseDate: Moment.Moment = getResponseDate(),
  ) => Moment.utc(responseDate).diff(Moment.utc(startCall), "seconds");

  const updatePhones = async (
    accountId: string,
    vendorId: string,
    vendorName: string,
    phoneList: UpdateRankedPhonesRequest[],
  ): Promise<UpdateRankedPhonesRequest> => {
    try {
      await addNewPhone({
        accountId,
        vendorId,
        vendorName,
        requestBody: phoneList,
      });
      CustomToast({
        type: TOAST_TYPES.SUCCESS,
        message: t("PHONE_RANKING.SAVED"),
      });
      await getPhonesRanked({
        accountId,
        vendorId,
        vendorName,
        phones: pocInformation?.phones || [],
      });
      return phoneList[0];
    } catch (error) {
      CustomToast({
        type: TOAST_TYPES.ERROR,
        message: t("PHONE_RANKING.ERROR_SAVE"),
        errorCode: error?.config?.headers?.requestTraceId,
        InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
      });
      return {
        phoneNumber: "",
        classification: "",
        contactName: "",
        phoneType: "",
      };
    }
  };

  const createPhoneRequest = (
    phone: Phone,
    classification: classificationTypes,
  ) => {
    return [
      {
        phoneNumber: phone.phoneNumber,
        classification,
        contactName: phone.phoneContact,
        phoneType: phone.phoneType,
      },
    ];
  };

  const createNewContact = (phones: Phone[], phoneNumber: string) => {
    const baseName = "New Contact";
    const suffix = phones.filter(({ phoneContact }) =>
      phoneContact?.startsWith(baseName),
    ).length;
    const newName = `${baseName}${suffix > 0 ? ` ${suffix}` : ""}`;

    return [
      {
        phoneNumber,
        classification: "INPUTTED",
        contactName: newName,
        phoneType: "INPUTTED_PHONE",
      },
    ];
  };

  /* istanbul ignore next */
  const handleCallClassification = async () => {
    const { minDurationCall } = await createCallDurationEffect({
      vendorId: GlobalAdminConfigState.userConfig.vendorId,
    });

    const callIsValid =
      Math.floor(currentPocCallDuration / 1000) >= minDurationCall;

    const foundPhone =
      phones.find((phone) => phone.phoneNumber === lastCalledOrCopiedPhone) ??
      null;

    const requestPayload = defineRequestPayload(
      foundPhone,
      callIsValid,
      classificationValue,
    );

    if (!requestPayload) return;

    if (!foundPhone) {
      const addedPhone = await updatePhones(
        accountId,
        vendorId,
        vendorName,
        requestPayload,
      );

      if (!addedPhone) return;
    }

    updatePhoneRanking({
      accountId,
      vendorId,
      vendorName,
      requestBody: requestPayload,
    });
  };

  const defineRequestPayload = (
    foundPhone: Phone | null,
    callIsValid: boolean,
    classificationValue: string,
  ) => {
    const isPhoneFoundAndValid =
      foundPhone && callIsValid && foundPhone?.phoneNumber !== "";
    const isSuccessfulContact =
      classificationValue === CLASSIFICATION_VALUES.SUCCESS_ON_CONTACT;

    if (!isPhoneFoundAndValid) {
      if (
        !foundPhone &&
        lastCalledOrCopiedPhone !== "" &&
        callIsValid &&
        isSuccessfulContact
      ) {
        return createNewContact(phones, lastCalledOrCopiedPhone);
      }

      return null;
    }

    if (isSuccessfulContact) {
      const isPhoneInactive = foundPhone.score === -99;
      const classification = isPhoneInactive
        ? classificationTypes.ACTIVATE
        : classificationTypes.GOOD;
      return createPhoneRequest(foundPhone, classification);
    }

    if (
      isIncorrectPhoneOnValidCall &&
      classificationValue === CLASSIFICATION_VALUES.INCORRECT_PHONE_NUMBER
    ) {
      return createPhoneRequest(foundPhone, classificationTypes.INACTIVATE);
    }

    return null;
  };

  /* istanbul ignore next */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  const onRegisterHandler = async (values: any) => {
    setShouldShowLoading(true);

    const { classification, classificationDetails, callLater, missions } =
      values;

    const getOption = () => {
      const optionSelected = callLaterOptions.find(
        (option) => option.value === callLater,
      );
      if (shouldCallLater && optionSelected) {
        return optionSelected.label.split(".")[1];
      }
      return null;
    };

    const isB2BTrained = () => {
      const createByClicked = localStorage.getItem("createEmulator");
      return createByClicked === "createByClick";
    };

    const isPromotionOffered = () => {
      const lookedPromotion = localStorage.getItem("lookedPromotion");
      localStorage.setItem("lookedPromotion", "false");
      return lookedPromotion === "true";
    };

    const shouldCallLater =
      classification === CLASSIFICATION_VALUES.CALL_LATER_TODAY ||
      classification === CLASSIFICATION_VALUES.NO_ANSWER_TRY_AGAIN;

    const callLaterTime = getResponseDate()
      .add(callLater, "hours")
      .format(DATE_FORMAT_STRING);

    if (agentCallState.pocCallOrigin === PocCallOrigin.AUTO_DIALER) {
      await handleCallClassification();
    }

    if (isMultiVendor) {
      const { vendorId } = GlobalAdminConfigState.userConfig;
      const vendorName = getVendorName(vendorId);
      updateAgentCall(
        {
          ...agentCallState,
          callTypification: classification,
          typificationDescription: classificationDetails,
          endCall: getResponseDate().format(DATE_FORMAT_STRING),
          callLater: shouldCallLater ? callLaterTime : "",
          usage: usageTypes.AGENT_CALL_SERVICE.END_CALL,
          callLaterHour: shouldCallLater ? (callLater as number) : undefined,
          duration: getTimer(),
          campaignsTagB2O: validCampaigns,
          sponsoredMissionsIds: handleSponsoredMissionsIds(),
          missions: allMissions,
          missionsEffetiveness: effectivenessMissions,
          callLogs,
          pocCallOrigin: autoDialerEnabled
            ? PocCallOrigin.AUTO_DIALER
            : agentCallState.pocCallOrigin,
          vendorId,
        },
        pocInformation,
        vendorId,
        vendorName,
      );

      if (isEnabledCtiCallLogsTempEvents && tempCallLogs.length) {
        updateTempCallLogsEffect(tempCallLogs);
      }
    } else {
      updateAgentCall(
        {
          ...agentCallState,
          callTypification: classification,
          typificationDescription: classificationDetails,
          endCall: getResponseDate().format(DATE_FORMAT_STRING),
          callLater: shouldCallLater ? callLaterTime : "",
          usage: usageTypes.AGENT_CALL_SERVICE.END_CALL,
          callLaterHour: shouldCallLater ? (callLater as number) : undefined,
          duration: getTimer(),
          campaignsTagB2O: validCampaigns,
          sponsoredMissionsIds: handleSponsoredMissionsIds(),
          missions: allMissions,
          missionsEffetiveness: effectivenessMissions,
          callLogs,
          pocCallOrigin: autoDialerEnabled
            ? PocCallOrigin.AUTO_DIALER
            : agentCallState.pocCallOrigin,
          vendorId,
        },
        pocInformation,
      );
      if (isEnabledCtiCallLogsTempEvents && tempCallLogs.length) {
        updateTempCallLogsEffect(tempCallLogs);
      }
    }

    if (isOrganizationConfigEnabled) {
      clearPocVendorId();
    }

    resetCallLogsEvent(null);
    const unWatchonChangeAgentCall = AgentCallEvents.onChangeAgentCall.watch(
      (result) => {
        /* istanbul ignore next */
        if (typeof unWatchonChangeAgentCall === "function") {
          unWatchonChangeAgentCall();
          unWatchUpdateAgentCallFail();
        }

        onRegister();
        clearCatalog();
        clearCampaignB2OProductsDb();
        clearNewOfferings();
        clearRedeemItems();
        resetPhonesRanking();
        resetEffectiveMissionsEvent();

        if (result.usage === usageTypes.AGENT_CALL_SERVICE.END_CALL) {
          // REMOVE CALLID
          localStorage.removeItem(CALL_ID);

          const topLineGap = missions.find(
            (mission: Mission) => mission.tag === TAG_TOPLINEGAP,
          );
          let typification = result.callTypification;
          let reason = null;
          let reasonDescription = null;
          if (topLineGap != null) {
            const {
              effectiveness,
              typification: typificationGap,
              reason: reasonGap,
              comments,
            } = topLineGap;

            if (
              effectiveness &&
              typification === CLASSIFICATION_VALUES.SUCCESS_ON_CONTACT
            ) {
              typification = typificationGap;
            }

            reason = reasonGap;
            reasonDescription = comments;
          }

          const missionAnalitycs = createMissionAnalitycs(allMissions);

          dispatchPocEvent(
            callEnded,
            {
              is_cti_connected: ctiStore.isConnected,
              analysis_time: getDuration(
                result,
                "analysis",
                result.reponseDate,
              ),
              call_duration: getCallDuration(
                result.startCall,
                result.reponseDate,
              ),
              service_duration: getDuration(
                result,
                "service",
                result.reponseDate,
              ),
              screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
              typification,
              typification_description: getValueOrNull(
                result.typificationDescription,
              ),
              reason,
              reason_description: reasonDescription,
              retry_call_time: shouldCallLater ? getOption() : null,
              callLaterLabel: getOption(),
              next_action: actionAfterCall,
              is_typed: ctiStore.wasTyped,
              is_b2b_trained: isB2BTrained(),
              is_promotion_offered: isPromotionOffered(),
              points_lost: totalPoints || 0,
              tags:
                result.isTypedPoc &&
                result.pocCallOrigin === PocCallOrigin.SEARCH_BY_POC
                  ? validCampaignsAnalytics
                  : list,
              overdue:
                pocInformation.creditInfo && pocInformation.creditInfo.overdue,
              call_attempts: callCounter.count,
              is_searched_by_poc:
                !!result.isTypedPoc &&
                result.pocCallOrigin === PocCallOrigin.SEARCH_BY_POC,
              campaigns_assigned: campaignAnalytics.names,
              campaign_tags_assigned: campaignAnalytics.tags,
              campaign_types_assigned: campaignAnalytics.types,
              accumulative_discount: pocInformation.accumulativeDiscount,
              missions: missionAnalitycs,
              reward_point_balance: loyaltBalance ?? null,
              redemption_combos_available: redeemItemsIds,
              customer_note: customerNotes?.note ?? null,
              DDC: pocInformation.deliveryCenterId,
              DDC_name: pocInformation.distributionCenterName,
              phones: getPhoneList(),
              vendor_id: GlobalAdminConfigState?.userConfig?.vendorId,
            },
            {
              time_of_day: true,
              is_resumed: true,
              sales_representative: true,
              is_pasted: true,
              is_to_recommend_used: true,
              removeProps: ["poc_potential"],
            },
          );
          OrderTabEvents.changeOrderNavigationTab(
            OrderNavigationTabName.SUGGESTED_ORDER,
          );

          OfferingEvents.setOfferingSection(OFFERINGS_SECTION.PROMOTIONS);

          if (b2bTrainerEnabled && publicKey) {
            const deleteData = {
              publicKey,
              country: globalState.user.zone,
            };

            b2bTrainerDeleteEmulatorEffect(deleteData);
          }

          // ANALITYCS CALLENDED B2BTRAINER
          localStorage.removeItem("createEmulator");
        }

        PromotionsEvents.resetPromotions(null);
        CtiEvents.resetCti(null);
        PocInformationEvents.resetPocInformation(null);
        SuggestedOrdersEvents.resetSuggestedOrders(null);
        OrdersEvents.resetOrdersStore(null);
        CampaignsEvents.resetCampaign(null);
        resetGenericInfos();
        TagsEvents.resetTags(null);

        if (
          actionAfterCall === EndCallActions.GO_HOME ||
          actionAfterCall === EndCallActions.GO_HOME_TAKE_BREAK
        ) {
          if (agentCallState.pocCallOrigin === PocCallOrigin.AUTO_DIALER) {
            navigator.clipboard.writeText("");
          }
          let state: { message: string; type: string; autodialer?: boolean } = {
            message: "CallTypification.ON_REGISTER_SUCCESS",
            type: TOAST_TYPES.SUCCESS,
          };
          if (
            isAutodialerNewButtonsEnabled &&
            actionAfterCall === EndCallActions.GO_HOME_TAKE_BREAK
          ) {
            state = { ...state, autodialer: true };
          }

          resetStoreStates();

          history.push({
            pathname: LINK_HOME,
            state,
          });
        } else {
          const unWatchGetNextPocFail = GetNextPocEvents.getNextPocFail.watch(
            () => {
              /* istanbul ignore next */
              if (typeof unWatchGetNextPocFail === "function") {
                unWatchGetNextPocFail();
                unWatchGetNextPocDone();
                unWatchonChangeAgentCall();
                unWatchUpdateAgentCallFail();
              }

              dispatchGenericEvent(
                callGetNextPocFailed,
                {
                  screen_name: ANALYTICS_ROUTE_NAMES.get(callTab),
                  screen_section: "Call Typification",
                  error_type: "unable to get next poc error",
                  error_message: "Service unavailable. Try again later.",
                },
                { time_of_day: true },
              );
            },
          );

          const unWatchGetNextPocDone = GetNextPocEvents.getNextPocDone.watch(
            () => {
              /* istanbul ignore next */
              if (typeof unWatchGetNextPocDone === "function") {
                unWatchGetNextPocDone();
                unWatchGetNextPocFail();
                unWatchonChangeAgentCall();
                unWatchUpdateAgentCallFail();
              }

              AgentCallEvents.showAgentCallSuccessMessage(
                t("CallTypification.ON_REGISTER_SUCCESS"),
              );
            },
          );

          resetStoreStates();
          getNextPoc(ScreenSection.CALL);

          const route = isCustomerPageEnabled
            ? TAB_CUSTOMER_ROUTE
            : TAB_GAP_ROUTE;

          CallTabEvents.setCallTab(route);
          history.push({
            pathname: route,
          });
        }
      },
    );

    const unWatchUpdateAgentCallFail =
      AgentCallEvents.updateAgentCallFail.watch((result) => {
        /* istanbul ignore next */
        if (typeof unWatchUpdateAgentCallFail === "function") {
          unWatchonChangeAgentCall();
          unWatchUpdateAgentCallFail();
        }

        if (result.usage === usageTypes.AGENT_CALL_SERVICE.END_CALL) {
          dispatchPocEvent(
            callEndFailed,
            {
              is_cti_connected: ctiStore.isConnected,
              analysis_time: getDuration(result, "analysis"),
              call_duration: getCallDuration(result.startCall),
              service_duration: getDuration(result, "service"),
              screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
              error_type: ANALYTICS_REGISTRATION_ERROR_TYPE,
              error_message: t(result.message),
              is_typed: ctiStore.wasTyped,
              is_b2b_trained: isB2BTrained(),
              is_promotion_offered: isPromotionOffered(),
              points_lost: totalPoints || 0,
              tags: result.isTypedPoc ? validCampaignsAnalytics : list,
              overdue:
                pocInformation.creditInfo && pocInformation.creditInfo.overdue,
              call_attempts: callCounter.count,
              is_searched_by_poc:
                !!result.isTypedPoc &&
                result.pocCallOrigin === PocCallOrigin.SEARCH_BY_POC,
              campaigns_assigned: campaignAnalytics.names,
              campaign_tags_assigned: campaignAnalytics.tags,
              campaign_types_assigned: campaignAnalytics.types,
              accumulative_discount: pocInformation.accumulativeDiscount,
              missions: createMissionAnalitycs(allMissions),
              reward_point_balance: loyaltBalance ?? null,
              redemption_combos_available: redeemItemsIds,
              customer_note: customerNotes?.note ?? null,
              DDC: pocInformation.deliveryCenterId,
              DDC_name: pocInformation.distributionCenterName,
              phones: getPhoneList(),
              vendor_id: GlobalAdminConfigState?.userConfig?.vendorId,
            },
            {
              time_of_day: true,
              is_resumed: true,
              sales_representative: true,
              is_pasted: true,
              is_to_recommend_used: true,
              removeProps: ["poc_potential"],
            },
          );
        }
      });
  };

  const createMissionAnalitycs = (missionsParam: Array<MissionModel>) => {
    return missionsParam.map((param) => {
      const { missionId, missionTitle, ...aux } = param;
      return {
        ...aux,
        mission_id: param.missionId,
        mission_title: param.missionTitle,
        effectiveness: getBooleanOrFalse(param.effectiveness),
      };
    });
  };

  /* istanbul ignore next */
  const showLoading = agentCallState.updateCallLoading && shouldShowLoading;
  const isSearchByPoc = pocCallOrigin === PocCallOrigin.SEARCH_BY_POC;
  const isAutoDialer = pocCallOrigin === PocCallOrigin.AUTO_DIALER;

  const renderFinalActionsTitle = () => {
    if (isNewRegisterButtonsEnabled) {
      if ((isAutoDialer && !isAutodialerNewButtonsEnabled) || isSearchByPoc) {
        return t("NEWCallSummary.FINAL_ACTIONS_V2_SEARCH_BY_POC");
      }
      return t("NEWCallSummary.FINAL_ACTIONS_V2");
    }
    return t("NEWCallSummary.FINAL_ACTIONS");
  };

  return (
    <Modal data-testid={testId} open={open} {...props}>
      <Container
        className={classNames(
          classes.container,
          isNewRegisterButtonsEnabled && classes.containerRelative,
        )}
        style={
          missions?.length > 0
            ? {
                width: "970px",
              }
            : {
                width: "500px",
              }
        }
      >
        {isNewRegisterButtonsEnabled && (
          <IconButton
            variant="tertiary"
            size="small"
            icon={closeIcon}
            data-testid="close-modal-call-summary"
            onClick={onClose}
            className={classes.closeButton}
          />
        )}

        {isCTIStatusCallSummaryEnabled && (
          <CallStatus
            data-testid="call-duration-call-summary"
            hasMission={missions?.length > 0}
          />
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={
            /* istanbul ignore next */
            !isNoHitClassification(classificationValue)
              ? validationSchema
              : false
          }
          onSubmit={onRegisterHandler}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form noValidate>
              <Box
                data-testid={`${testId}-call-summary`}
                className={classes.content}
              >
                <CallSummaryForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setClassificationValue={setClassificationValue}
                  hasMission={missions?.length > 0}
                />
                {values.missions?.length > 0 && (
                  <>
                    <Divider
                      orientation="vertical"
                      classes={{ vertical: classes.divider }}
                      data-testid={`${testId}-divider`}
                    />
                    <Box
                      data-testid={`${testId}-missions-effectivess`}
                      className={classNames(
                        classes.missionsEffectivenessContent,
                        {
                          [classes.missionsEffectivenessContentDisable]:
                            isNoHitClassification(values.classification),
                        },
                      )}
                    >
                      <Box className={classes.missionsEffectivenessLabel}>
                        {t("NEWCallSummary.MISSIONS_EFFECTIVENESS_LABEL")}
                      </Box>
                      <MissionsEffectiveness
                        handleExpanded={handleExpanded}
                        expanded={expanded}
                        setFieldValue={setFieldValue}
                        testId="missions-effectivess"
                        missions={values.missions}
                        errors={errors}
                        touched={touched}
                      />
                    </Box>
                  </>
                )}
              </Box>
              <Box
                className={classNames(
                  classes.finalActionsContent,
                  isNewRegisterButtonsEnabled && classes.finalActionsContentV2,
                )}
              >
                <Box className={classes.finalActionsLabel}>
                  {renderFinalActionsTitle()}
                </Box>
                <FinalActions
                  onClose={onClose}
                  loading={showLoading}
                  onChange={handleActionAfterCall}
                  actionAfterCall={actionAfterCall}
                  isSearchByPocEnable={isSearchByPocEnabled}
                  getTimer={getTimer}
                  hasMission={missions?.length > 0}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

/* istanbul ignore next */
const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.open === nextProps.open;
};

export default React.memo(CallSummaryMissionsModal, areEqual);
