import React, { useRef, useState, useLayoutEffect } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import Moment from "moment";
import MomentTimeZone from "moment-timezone";
import ReactNumberFormat from "react-number-format";
import Ellipsis from "react-ellipsis-text";
import { Tooltip } from "@material-ui/core";
import GlobalStore from "../../stores/global/GlobalStore";
import { CURRENCY, DATE_FORMATTER } from "../../config/constants";
import { TIMEZONE } from "../../config/timeZone";
import { isEmpty } from "../../config/utils/functions";

interface Props {
  type?: "text" | "currency" | "number" | "date";
  timeZone?: boolean;
  toFixed?: number;
  value?: number | string | Date;
  prefix?: string;
  suffix?: string;
  ellipsis?: boolean;
  ellipsisSize?: number;
  className?: string;
  testId?: string;
  emptyMessage?: string;
  hasTooltip?: boolean;
}

const isDate = ({ value, timeZone, user, dateFormatter, t }) => {
  let date;
  if (timeZone) {
    date = MomentTimeZone.tz(
      new Date(value),
      TIMEZONE[user.zone].timeZone,
    ).format();
  } else {
    date = Moment(value);
  }

  let formatedDateValue = "";
  const validDate = Moment(date);
  if (validDate.isValid()) {
    formatedDateValue = validDate.format(dateFormatter);
    return formatedDateValue;
  }
  formatedDateValue = t("GlobalMessage.NO_DATA_AVAILABLE");
  return formatedDateValue;
};

const Data: React.FunctionComponent<Props> = ({
  type,
  timeZone,
  toFixed,
  value = "",
  prefix,
  suffix,
  ellipsis,
  ellipsisSize,
  className,
  testId,
  emptyMessage,
  hasTooltip,
}) => {
  const { t } = useTranslation();
  const { user } = useStore(GlobalStore);
  const dateFormatter = DATE_FORMATTER[user.zone];
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLInputElement | null>();

  useLayoutEffect(() => {
    const updateSize = () => {
      if (textElementRef.current && hasTooltip)
        setIsOverflow(
          textElementRef.current.scrollWidth >
            textElementRef.current.clientWidth ||
            textElementRef.current.scrollHeight >
              textElementRef.current.clientHeight,
        );
    };

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, [hasTooltip]);

  const setNumberPrefix = () => {
    let numberPrefix = "";
    if (type === "currency" && prefix === undefined) {
      /* istanbul ignore next */
      numberPrefix = `${CURRENCY[user.zone]?.prefix || "$"} `;
    }
    if (prefix) {
      numberPrefix = prefix;
    }
    return numberPrefix;
  };

  const setNumberValue = () => {
    let numberValue = value;
    if (type === "currency") {
      numberValue = Number(value).toFixed(2);
    }
    if (toFixed) {
      numberValue = Number(value).toFixed(toFixed);
    }
    return Number(numberValue);
  };

  const setDecimalScale = () => {
    let decimalScale = toFixed;
    if (type === "currency") {
      decimalScale = 2;
      if (user.zone === "PY" || user.zone === "ID") {
        decimalScale = 0;
      }
    }
    return decimalScale;
  };

  const formatSeparator = (separator: string) => {
    if (separator === "tousand") {
      return t(`Formater.${user.zone}.THOUSAND_SEPARATOR`);
    }

    return t(`Formater.${user.zone}.DECIMAL_SEPARATOR`);
  };

  if (isEmpty(value)) {
    return (
      <span className={className} data-testid={testId}>
        {emptyMessage && t(emptyMessage)}
      </span>
    );
  }

  if (type === "currency" || type === "number") {
    return (
      <ReactNumberFormat
        data-testid={testId}
        value={setNumberValue()}
        displayType="text"
        thousandSeparator={formatSeparator("tousand")}
        decimalSeparator={formatSeparator("decimal")}
        prefix={setNumberPrefix()}
        suffix={suffix}
        decimalScale={setDecimalScale()}
        fixedDecimalScale
        className={className}
      />
    );
  }

  if (type === "date") {
    const formatedDateValue = isDate({
      value,
      timeZone,
      user,
      dateFormatter,
      t,
    });

    return (
      <span className={className} data-testid={testId}>
        {formatedDateValue}
      </span>
    );
  }

  if (ellipsisSize || ellipsis) {
    return (
      <Ellipsis
        text={value.toString()}
        length={ellipsisSize || 40}
        className={className}
        data-testid={testId}
      />
    );
  }

  if (hasTooltip) {
    return (
      <Tooltip
        title={value}
        arrow
        ref={textElementRef}
        disableHoverListener={!isOverflowed}
      >
        <span className={className} data-testid={testId}>
          {value}
        </span>
      </Tooltip>
    );
  }

  return (
    <span className={className} data-testid={testId}>
      {value}
    </span>
  );
};

Data.defaultProps = {
  toFixed: 0,
  type: "text",
  prefix: undefined,
  suffix: "",
  ellipsis: false,
  ellipsisSize: 0,
  testId: "",
  emptyMessage: "GlobalMessage.NO_DATA_AVAILABLE",
  timeZone: false,
  hasTooltip: false,
};

export default Data;
