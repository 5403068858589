import { makeStyles, createStyles } from "@material-ui/core/styles";

const styles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      width: "448px",
      padding: "32px",
      flexDirection: "column",
      gap: "16px",
      borderRadius: "24px",
      background: "var(--light-bg-bg, #FFF)",
      boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.16)",
    },
    title: {
      color: "var(--light-txt-txt, #141414)",
      fontFamily: "Barlow",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "40px",
      margin: "0",
    },
    text: {
      color: "var(--light-txt-txt, #141414)",
      fontFamily: "Work Sans",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
    },
    secondaryText: {
      color: "var(--light-txt-secondary, #757575)",
      fontFamily: "Work Sans",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      alignSelf: "center",
    },
  }),
);

export default styles;
