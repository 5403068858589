import React, { useEffect } from "react";

import { useAnalyticsService } from "admin-portal-shared-services";
import { InitialDataWrapper } from "grow-shared-services";

import { ThemeProvider as HubThemeProvider } from "@hexa-ui/theme";
import {
  CssBaseline,
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import AppbarConfig from "../components/appConfig/AppbarConfig";
import { WRAPPER_PROJECT_NAME_INSTANCE } from "../config/constants";
import { CartAutoUpdateContextProvider } from "../contexts/CartAutoUpdateContext";
import { CollapsibleSideNavContextProvider } from "../contexts/CollapsibleSideNavContext";
import CustomTheme from "../theme/Theme";
import AppRouter from "./AppRouter";
import "../i18n/i18n";
import "./initGlobalStores";

interface AppProps {
  TLP_SEGMENT_KEY: string;
  ONCUSTOMER_OPTIMIZELY_KEY: string;
  europeCountries?: string;
}

function useSegmentAnalytics(TLP_SEGMENT_KEY: string) {
  const service = useAnalyticsService();

  /* istanbul ignore next */
  useEffect(() => {
    service.load(TLP_SEGMENT_KEY);
  }, [TLP_SEGMENT_KEY, service]);
}

function addEuropeCountriesToLocalStorage(europeCountries?: string) {
  localStorage.setItem(
    "europeCountries",
    europeCountries || process.env.EUROPE_COUNTRIES_APP || "",
  );
}

function App(props: AppProps): JSX.Element {
  useEffect(() => {
    document.getElementsByTagName("html")[0].setAttribute("translate", "no");
    return () => {
      document.getElementsByTagName("html")[0].removeAttribute("translate");
    };
  }, []);

  const { TLP_SEGMENT_KEY, ONCUSTOMER_OPTIMIZELY_KEY, europeCountries } = props;

  addEuropeCountriesToLocalStorage(europeCountries);
  useSegmentAnalytics(TLP_SEGMENT_KEY);

  /* istanbul ignore next */
  const generateClassName = createGenerateClassName({
    productionPrefix: WRAPPER_PROJECT_NAME_INSTANCE,
    seed: WRAPPER_PROJECT_NAME_INSTANCE,
  });

  return (
    <InitialDataWrapper
      projectName={WRAPPER_PROJECT_NAME_INSTANCE}
      optKey={ONCUSTOMER_OPTIMIZELY_KEY}
      shouldCheckGroupAndOrgId
    >
      <StylesProvider generateClassName={generateClassName}>
        <HubThemeProvider theme="grow">
          <ThemeProvider theme={CustomTheme}>
            <AppbarConfig>
              <CollapsibleSideNavContextProvider>
                <CartAutoUpdateContextProvider>
                  <CssBaseline />
                  <AppRouter />
                </CartAutoUpdateContextProvider>
              </CollapsibleSideNavContextProvider>
            </AppbarConfig>
          </ThemeProvider>
        </HubThemeProvider>
      </StylesProvider>
    </InitialDataWrapper>
  );
}

export default App;
