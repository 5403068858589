import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    tooltipTitleContainer: {
      margin: 0,
      padding: 0,
      borderRadius: "8px",
      maxWidth: "250px",
      backgroundColor: palette.common.black,
      boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.24)",
    },
    tooltipText: {
      fontSize: "14px",
      fontFamily: "Work Sans",
      fontWeight: 500,
      color: "rgba(255, 255, 255, 0.95)",
      textAlign: "center",
      lineHeight: "16px",
    },
    tooltipArrow: {
      color: palette.common.black,
      boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.24)",
    },
    tooltipContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "8px 10px 12px",
    },
  }),
);
