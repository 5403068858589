import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

type StyleProps = {
  isOutOfStock: boolean;
  disabled: boolean;
};

export default makeStyles<Theme, StyleProps>(() =>
  createStyles({
    container: {
      display: "flex",
      gap: "1rem",
    },
    detailsArea: {
      display: "flex",
      flexDirection: "column",
      gap: ".5rem",
    },
    itemDetailsContainer: {
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontFamily: "Work Sans",
      fontSize: ".875rem",
      fontWeight: 600,
      lineHeight: "1.25rem",
      color: "#141414",
      textOverflow: "ellipsis",
    },
    subtitle: {
      fontFamily: "Work Sans",
      fontSize: ".75rem",
      lineHeight: "1.125rem",
      color: "#757575",
    },
    freeText: {
      color: (props) => (props.disabled ? "#999999" : "#176D38"),
      fontFamily: "Work Sans",
      fontSize: ".875rem",
      fontWeight: 600,
      lineHeight: "1.25rem",
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: ".4375rem",
    },
    img: {
      opacity: (props) =>
        props.isOutOfStock || props.disabled ? 0.6 : "inherit",
    },
    stockInfo: {
      fontFamily: "Work Sans",
      fontSize: ".75rem",
      lineHeight: "1.125rem",
      color: (props) => (props.isOutOfStock ? " #c9201d" : "#757575"),
    },
  }),
);
