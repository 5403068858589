/* istanbul ignore file */
import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    modal: {
      borderRadius: "1.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      position: "relative",
      padding: "16px",
      width: "642px",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    title: {
      fontSize: "16px",
    },
    closeButtonContainer: {
      width: "32px",
      height: "32px",
      background: palette.grey[100],
      borderRadius: "50%",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    productsContainer: {
      maxHeight: "432px",
      overflow: "scroll",
      display: "flex",
      flexWrap: "wrap",
      gap: "0px 8px",
    },
    productCardContainer: {
      width: "48%",
      height: "76px",
      display: "flex",
      marginTop: "16px",
    },
    cardInfosContainer: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "219px",
      width: "100%",
      marginLeft: "14px",
      height: "100%",
    },
    cardTitleLabel: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      whiteSpace: "normal",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      width: "fit-content",
      fontWeight: "normal",
    },
    cardSkuLabel: {
      color: palette.text.secondary,
      marginBottom: "4px",
    },
    totalPriceContainer: {
      display: "flex",
      justifyContent: "end",
      marginBottom: "16px",
      paddingRight: "34px",
    },
    bottomSection: {
      display: "flex",
      gap: "12px",
      width: "100%",
      height: "24px",
      margin: "4px 0",
      alignItems: "center",
    },
    footerText: {
      fontWeight: 100,
      color: palette.text.secondary,
    },
    footerSection: {
      marginTop: "16px",
    },
  }),
);
