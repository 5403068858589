import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { setCallTab } from "../../../../../stores/navigation/callTab/CallTabEvents";
import Button from "../../../../../components/button/Button";
import { TAB_ORDERS } from "../../../../../config/constants";
import CheckMarkIcon from "../../../../../assets/CheckMarkIcon";
import AddedToCartTextStyles from "./AddedToCartText.styles";

interface AddedToCartTextProps {
  itemQuantity: number;
}

const AddedToCartText = ({
  itemQuantity,
}: AddedToCartTextProps): JSX.Element => {
  const classes = AddedToCartTextStyles();
  const { t } = useTranslation();

  const translation = t("RecommendedProducts.ADDED_TO_CART");

  const splitSpaces = translation.split(" ");
  const cardWord = splitSpaces[splitSpaces.length - 1];
  const Added = splitSpaces[splitSpaces.length - 3];
  const AddedTo = splitSpaces[splitSpaces.length - 2];

  return (
    <div className={classes.container}>
      <CheckMarkIcon height={24} width={24} data-testid="icon-image" />
      <Typography
        variant="h3"
        className={classes.addedItemText}
        data-testid="added-text"
      >
        {Added}
      </Typography>
      <Typography
        variant="h3"
        className={classes.addedItemSubText}
        data-testid="item-quantity-text"
      >
        {itemQuantity}{" "}
        {itemQuantity === 1 ? t("Empties.ITEM") : t("Empties.ITEMS")}
      </Typography>
      <Typography
        variant="h3"
        className={classes.addedItemText}
        data-testid="added-text"
      >
        {AddedTo}
      </Typography>
      <Button
        className={classes.addedItemTextLink}
        variant="outlined"
        onClick={() => {
          setCallTab(TAB_ORDERS);
        }}
        data-testid="added-order-link"
      >
        {cardWord}
      </Button>
    </div>
  );
};

export default AddedToCartText;
