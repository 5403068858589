import React from "react";

export default function IconCalendar(): JSX.Element {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-testid="icon-currency"
    >
      <defs>
        <path
          d="M24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 Z M24,4.36363636 C13.1551358,4.36363636 4.36363636,13.1551358 4.36363636,24 C4.36363636,34.8448642 13.1551358,43.6363636 24,43.6363636 C34.8448642,43.6363636 43.6363636,34.8448642 43.6363636,24 C43.6363636,13.1551358 34.8448642,4.36363636 24,4.36363636 Z M24,5.81818182 C25.1189146,5.81818182 26.0411065,6.66045132 26.1671395,7.74555391 L26.1818182,8 L26.181,11.636 L31.2727273,11.6363636 C32.4777122,11.6363636 33.4545455,12.6131969 33.4545455,13.8181818 C33.4545455,14.9370964 32.6122759,15.8592884 31.5271734,15.9853213 L31.2727273,16 L26.181,16 L26.181,21.818 L27.6363637,21.8181818 C31.65298,21.8181819 34.9090909,25.0742928 34.9090909,29.0909091 C34.9090909,32.9736382 31.8664361,36.1457173 28.0353978,36.3528751 L27.6363636,36.3636364 L26.181,36.363 L26.1818182,40 C26.1818182,41.2049849 25.2049849,42.1818182 24,42.1818182 C22.8810854,42.1818182 21.9588935,41.3395487 21.8328605,40.2544461 L21.8181818,40 L21.818,36.363 L15.2727273,36.3636364 C14.0677424,36.3636364 13.0909091,35.3868031 13.0909091,34.1818182 C13.0909091,33.0629036 13.9331786,32.1407116 15.0182812,32.0146787 L15.2727273,32 L21.818,32 L21.818,26.181 L20.3636364,26.1818182 C16.34702,26.1818182 13.0909091,22.9257073 13.0909091,18.9090909 C13.0909091,15.0263618 16.1335638,11.8542827 19.9646022,11.6471249 L20.3636364,11.6363636 L21.818,11.636 L21.8181818,8 C21.8181818,6.79501509 22.7950151,5.81818182 24,5.81818182 Z M27.6363636,26.1818182 L26.181,26.181 L26.181,32 L27.6363636,32 C29.2430101,32 30.5454545,30.6975556 30.5454545,29.0909091 C30.5454545,27.5787712 29.3917322,26.3360996 27.9165285,26.1951352 L27.6363636,26.1818182 Z M21.818,16 L20.3636364,16 C18.7569898,16 17.4545455,17.3024444 17.4545455,18.9090909 C17.4545455,20.4212288 18.6082678,21.6639005 20.0834715,21.8048648 L20.3636364,21.8181818 L21.818,21.818 L21.818,16 Z"
          id="currency-path-1"
        />
      </defs>
      <g
        id="___Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ZZ_Color-Override/Primary/Black"
          transform="translate(8.000000, 7.666667)"
        >
          <mask id="currency-mask-2" fill="white">
            <use xlinkHref="#currency-path-1" />
          </mask>
          <g id="Mask" fillRule="nonzero" />
          <g
            id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            mask="url(#currency-mask-2)"
            fill="#000000"
            fillOpacity="0.9"
          >
            <g transform="translate(-8.000000, -7.666667)" id="Rectangle">
              <rect x="0" y="0" width="64" height="64" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
