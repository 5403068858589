import { getMarketplace } from "../../services/marketplace/MarketplaceService";
import { MarketplaceItem } from "../../domains/Marketplace";

export function execute(
  country: string,
  vendorId?: string,
  orgId?: string,
  requestTraceId?: string,
): Promise<Array<MarketplaceItem>> {
  return getMarketplace(country, vendorId, orgId, requestTraceId);
}
