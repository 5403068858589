import React, { useEffect, useState } from "react";

import { Button, IconButton, Paragraph, TextButton } from "@hexa-ui/components";
import {
  ChevronDown,
  ChevronUp,
  Gift,
  Package,
  Store,
  Truck,
} from "@hexa-ui/icons";
import { Collapse } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { usePromotionsHook } from "../../../tabsContent/missions/hooks/usePromotionsHook";

import Tooltip from "../../../../../components/tooltipCard/TooltipCard";
import { PROMOTIONS_TYPES } from "../../../../../config/constants";
import {
  FormatedItem,
  FormatedPromotion,
} from "../../../../../domains/promotions/FormatedPromotion";
import ProductTag from "../../localMissionsTag/components/localMissionsTag/LocalMissionsTag";
import AddedToCartText from "../AddedToCartText/AddedToCartText";
import QuantityInputAndStock from "../QuantityInputAndStock/QuantityInputAndStock";
import PromotionCardHeader from "../PromotionCardHeader/PromotionCardHeader";
import PromotionCardItem from "../PromotionCardItem/PromotionCardItem";
import ShowMoreInfo from "../card/showMoreInfo/ShowMoreInfoCombos";
import SteppedDiscountPrices from "../SteppedDiscountPrices/SteppedDiscountPrices";
import SteppedFreeGoodSection from "../SteppedFreeGoodSection/SteppedFreeGoodSection";
import PromotionPrices from "../PromotionPrices/PromotionPrices";
import styles from "./PromotionCard.styles";

interface PromotionCardProps {
  promotion: FormatedPromotion;
  isExpanded: boolean;
}

export function PromotionCard({
  promotion,
  isExpanded,
}: PromotionCardProps): JSX.Element {
  const classes = styles();
  const { t } = useTranslation();

  const { isCombo, isMarketplace, platformId } = promotion;

  const [isCollapsed, setIsCollapsed] = useState(false);

  const {
    itemQuantity,
    showCartLink,
    addItemToCart,
    handleChangeQuantity,
    showMoreInfoComponentProps,
  } = usePromotionsHook();

  useEffect(() => {
    setIsCollapsed(isExpanded);
  }, [isExpanded]);

  const collapseIcon = isCollapsed ? ChevronUp : ChevronDown;

  const isFreeGood =
    promotion.type === PROMOTIONS_TYPES.COMBO_FREE_GOOD_V2 ||
    promotion.type === PROMOTIONS_TYPES.PROM_FREE_GOOD;

  const isSteppedDiscount =
    promotion.type === PROMOTIONS_TYPES.PROM_STEPPED_DISCOUNT;

  const isSteppedFreeGood =
    promotion.type === PROMOTIONS_TYPES.PROM_STEPPED_FREE_GOOD;

  const isInteractiveCombo =
    promotion.type === PROMOTIONS_TYPES.COMBO_INTERACTIVE;

  const renderPromotionList = () => {
    return promotion.items.map((item) => (
      <div key={`${item.sku}-order-item`}>
        {renderPromotionCardItem(item, "order-item")}
      </div>
    ));
  };

  const renderPromotionCardItem = (item: FormatedItem, testId: string) => {
    return (
      <PromotionCardItem
        data-testid={`${item.sku}-${testId}`}
        key={`${item.sku}-${testId}`}
        item={item}
      />
    );
  };

  const renderShowMore = () => {
    return (
      <Tooltip
        value={<ShowMoreInfo {...showMoreInfoComponentProps(promotion)} />}
      >
        <TextButton
          size="small"
          data-testid="card-show-more"
          className={classes.showMoreText}
        >
          {t("Promotions.SHOW_MORE")}
        </TextButton>
      </Tooltip>
    );
  };

  const renderAddButton = () => {
    return (
      <Button
        size="small"
        data-testid="add-to-cart-button"
        onClick={() => addItemToCart(promotion)}
      >
        {t("RecommendedProducts.ADD_TO_CART")}
      </Button>
    );
  };

  return (
    <div className={classes.container} data-testid={`${platformId}-container`}>
      <div className={classes.tagContainer}>
        {isCombo && (
          <ProductTag
            title={t("COMMONS_PRODUCTS_INFOS.TAGS.COMBO_LABEL")}
            icon={<Package size="tiny" />}
            data-testid={`${platformId}-combo-tag`}
            backgroundIconColor="#f7f72a"
            backgroundColor="#F2F2F2"
          />
        )}
        {isMarketplace && (
          <ProductTag
            title={t("COMMONS_PRODUCTS_INFOS.TAGS.MARKETPLACE_LABEL")}
            icon={<Store size="tiny" />}
            data-testid={`${platformId}-marketplace-tag`}
            backgroundIconColor="#FFAA33"
            backgroundColor="#F2F2F2"
          />
        )}
      </div>

      <Paragraph
        weight="medium"
        size="small"
        className={classes.baseText}
        data-testid={`${platformId}-name`}
      >
        {promotion.name}
      </Paragraph>

      <div
        className={classes.collapseContainer}
        data-testid={`${platformId}-description-container`}
      >
        <Paragraph
          weight="medium"
          size="small"
          className={classes.baseText}
          data-testid={`${platformId}-description-label`}
        >
          {t("Promotions.DESCRIPTION")}
        </Paragraph>

        <IconButton
          variant="inherit"
          size="small"
          className={classes.iconButton}
          icon={collapseIcon}
          onClick={() => setIsCollapsed(!isCollapsed)}
          data-testid={`${platformId}-collapse-icon`}
        />
      </div>

      <Collapse
        in={isCollapsed}
        data-testid={`${platformId}-collapse-container`}
      >
        <div
          className={classes.detailsContainer}
          data-testid={`${platformId}-details-container`}
        >
          {promotion.description && (
            <Paragraph
              size="small"
              className={classes.baseText}
              data-testid={`${platformId}-description`}
            >
              {promotion.description}
            </Paragraph>
          )}

          {isFreeGood ? (
            <PromotionCardHeader
              data-testid="promotion-card-header-order"
              title={t(promotion.orderItemsLabel)}
              icon={<Truck />}
            >
              {renderPromotionList()}
            </PromotionCardHeader>
          ) : (
            renderPromotionList()
          )}

          {isSteppedDiscount && (
            <>
              <PromotionPrices promotion={promotion} />
              <SteppedDiscountPrices promotion={promotion} />
            </>
          )}

          {isSteppedFreeGood && (
            <SteppedFreeGoodSection promotion={promotion} />
          )}

          {!isInteractiveCombo && (
            <div
              data-testid="promotion-prices-container"
              className={classNames(
                classes.containerPrices,
                isFreeGood && classes.padding,
                isSteppedDiscount && classes.steppedDiscountContainer,
              )}
            >
              {!isSteppedDiscount && <PromotionPrices promotion={promotion} />}

              <QuantityInputAndStock
                promotion={promotion}
                itemQuantity={itemQuantity}
                handleChangeQuantity={handleChangeQuantity}
                showCartLink={showCartLink}
                isSteppedDiscount={isSteppedDiscount}
              />

              {isSteppedDiscount && !showCartLink && renderAddButton()}
            </div>
          )}

          {isFreeGood && !isCombo && (
            <PromotionCardHeader
              data-testid="promotion-card-header-promo"
              title={t(promotion.titleFreegoodSection)}
              icon={<Gift />}
            >
              {promotion.freegoodsPromotion.map((item) =>
                renderPromotionCardItem(item, "promotion-item"),
              )}
            </PromotionCardHeader>
          )}

          {isFreeGood && isCombo && (
            <PromotionCardHeader
              data-testid="promotion-card-header-combo"
              title={t(promotion.titleFreegoodSection)}
              icon={<Gift />}
            >
              {promotion.freegoodsCombo.map((item) =>
                renderPromotionCardItem(item, "combo-item"),
              )}
            </PromotionCardHeader>
          )}

          {!isInteractiveCombo && (
            <div className={classes.footerSection}>
              {showCartLink ? (
                <AddedToCartText itemQuantity={itemQuantity} />
              ) : (
                <>
                  {renderShowMore()}
                  {!isSteppedDiscount && renderAddButton()}
                </>
              )}
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
}
