import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      margin: "80px 150px",
    },
    messageHeader: {
      backgroundColor: "#525252",
    },
    button: {
      backgroundColor: "#525252",
    },
    errorCode: {
      fontSize: "28px",
      fontWeight: "bold",
      color: palette.secondary.main,
    },
    errorMessage: {
      fontSize: "18px",
      color: palette.primary.main,
      fontWeight: "bold",
      paddingTop: "24px",
      paddingBottom: "32px",
    },
    reloadButton: {
      width: "270px",
      height: "40px",
      borderRadius: "3px",
      backgroundColor: palette.secondary.main,
      color: "white",
      fontFamily: "Barlow",
      fontSize: "16px",
      fontWeight: "bold",
    },
    divider: {
      backgroundColor: palette.grey[200],
    },
  }),
);
