import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface Props {
  isMissionsEnabled?: boolean;
}

export default makeStyles<Theme, Props>(({ palette }) =>
  createStyles({
    timeOnCall: {
      height: "16px",
      fontSize: "14px",
      fontWeight: 500,
      textAlign: "center",
      color: palette.text.secondary,
      marginRight: "10px",
    },
    timer: {
      height: "25px",
      fontSize: "22px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "right",
      color: palette.secondary.main,
    },
    callInfo: {
      display: "flex",
      justifyContent: "start",
      flexDirection: "column",
      flex: 1,
    },
    callStatusTimeOnCall: {
      display: "flex",
      alignItems: "center",
    },
    noDeliveryWindows: {
      fontSize: 10,
      fontWeight: 500,
      color: palette.text.primary,
      fontFamily: "Work Sans",
      flex: 1,
    },

    noDeliveryWindowsContainer: {
      backgroundColor: palette.error.light,
      padding: "6px 8px",
      width: "fit-content",
      borderRadius: 8.5,
      marginTop: (props) => (props.isMissionsEnabled ? 9 : 0),
      display: "flex",
      alignItems: "center",
    },

    errorIcon: {
      fontSize: "14px",
      marginRight: 5,
    },

    button: {
      width: "220px",
      height: "40px",
      borderRadius: "3px",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      alignSelf: "flex-end",
      padding: "0px 10px 0px 15px",
      textTransform: "none",
    },

    buttonMissions: {
      width: "100%",
      height: "40px",
      borderRadius: "3px",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      alignSelf: "flex-end",
      padding: "0px 10px 0px 15px",
      textTransform: "none",
    },

    startCall: {
      fontSize: "16px",
      fontWeight: "normal",
      textAlign: "center",
      color: "#ffffff",
      display: "flex",
      alignItems: "center",
      marginBottom: 1,
    },
    callIconStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "24px",
      height: "24px",
      borderRadius: "100%",
      backgroundColor: "yellow",
    },

    callIconStyleStarted: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: palette.error.main,
      width: "24px",
      height: "24px",
      borderRadius: "100%",
      backgroundColor: "white",
    },

    callStatusWrapper: {
      display: "flex",
      alignItems: "center",
      alignSelf: "flex-start",
    },

    callStatusContainer: {
      maxWidth: "320px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      paddingRight: 10,
    },
    timerContainer: {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      width: 80,
    },

    timeOnCallMissions: {
      fontSize: "14px",
      fontWeight: "normal",
      color: palette.text.secondary,
    },
    timerMissions: {
      height: "25px",
      fontSize: "20px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: palette.secondary.main,
    },
    callInfoMissions: {
      display: "flex",
      justifyContent: "start",
      flexDirection: "column",
      flex: 1,
    },

    startButton: {
      backgroundColor: palette.secondary.main,
    },
    stopButton: {
      backgroundColor: palette.error.main,
      color: "white",
    },
    startCallMissions: {
      width: "200px",
      height: "32px",
      fontSize: "14px",
      fontWeight: "bold",
      textAlign: "center",
      color: "#ffffff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderLeft: "1px solid #ffffff",
    },
    callIconStyleMissions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "32px",
    },
    containerMissions: {
      display: "flex",
      width: "99%",
    },
    innerContainerMissions: {
      justifyContent: "center",
      display: "flex",
      flex: "1",
      minWidth: "260px",
      flexDirection: "column",
      padding: "20px 17px 17px 16px",
      borderRadius: "11px",
      boxShadow: "0 5px 5px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#FFF",
      marginBottom: 12,
    },
    statusContainerMissions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "12px",
    },
  }),
);
