import { createEvent } from "effector";
import AgentCalls from "../../../domains/AgentCall";

export const getOpenCall = createEvent<null>("getOpenCall");

export const getOpenCallDone = createEvent<AgentCalls>("getOpenCallDone");

export const getOpenCallFail = createEvent<null>("getOpenCallFail");

export const handlePastePhoneEventWasCalled = createEvent<boolean>(
  "handlePastePhoneEventWasCalled",
);
