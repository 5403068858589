import React from "react";

interface Props {
  color?: string;
}

function PhoneOutlineIcon({ color = "black" }: Props): any {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75%"
      height="75%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21 16.477v2.71c.004 1.035-.928 1.9-1.974 1.806C10 21 3 13.935 3.008 4.969 2.913 3.93 3.774 3.001 4.808 3h2.715c.44-.004.865.151 1.198.437.947.812 1.556 3.57 1.322 4.667-.183.856-1.046 1.455-1.633 2.04a14.467 14.467 0 005.43 5.42c.587-.585 1.187-1.446 2.045-1.629 1.1-.234 3.877.376 4.686 1.329.287.338.44.77.428 1.213z"
      />
    </svg>
  );
}
PhoneOutlineIcon.defaultProps = {
  color: "#000",
};
export default PhoneOutlineIcon;
