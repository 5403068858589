import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    gapBoardContainer: {
      paddingTop: 16,
    },
    flex: {
      display: "flex",
      flexDirection: "row",
    },
    titleUpdate: {
      fontSize: 11,
      fontWeight: "normal",
      color: "#7a7a7a",
      whiteSpace: "nowrap",
      marginRight: 10,
      marginTop: "9px",
      marginLeft: 16,
      paddingBottom: "5px",
    },
    refreshButton: {
      padding: 0,
      minWidth: 0,
      border: 0,
    },
    refreshButtonIcon: {
      width: 20,
      height: 19,
      fontWeight: "normal",
      color: "#505050",
    },
  }),
);
