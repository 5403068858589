import React from "react";
import { Box, Typography, Container, ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { resetVendorsPoc } from "@stores/callList/VendorPocByAccount/VendorPocByAccountEffect";
import Button from "../button/Button";
import Modal from "../modal/Modal";
import Data from "../data/Data";
import useStyles from "./styles";

const PocInfoModal: React.FC<Props> = ({
  open,
  testId,
  pocId,
  name,
  owner,
  email,
  onClose,
  goToPoc,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderField = (title: string, value: string | undefined) => {
    return (
      <Box className={classes.fieldContainer}>
        <Typography className={classes.fieldTitle}>{title}</Typography>
        <Typography className={classes.fieldValue}>
          <Data value={value} />
        </Typography>
      </Box>
    );
  };
  return (
    <Modal data-testid={testId} open={open} onClose={onClose}>
      <Container className={classes.container}>
        <Typography className={classes.title}>
          {t("PocInformationModal.TITLE")}
        </Typography>
        <Box>
          <Typography className={classes.pocId}>
            {t("PocInformationModal.ID")}
            {": "}
            <Data value={pocId} testId="poc-info-id-value" />
          </Typography>
          {renderField(t("PocInformationModal.NAME"), name)}
          {renderField(t("PocInformationModal.OWNER"), owner)}
          {renderField(t("PocInformationModal.EMAIL"), email)}
        </Box>
        <Box className={classes.buttonsContainer}>
          <ButtonBase
            onClick={onClose}
            className={classNames(classes.button, classes.cancelButton)}
            data-testid="poc-info-close-button"
          >
            {t("PocInformationModal.CLOSE")}
          </ButtonBase>
          <Button
            onClick={() => {
              goToPoc();
              resetVendorsPoc(null);
            }}
            className={classNames(classes.button, classes.goPocButton)}
            testId="poc-info-go-to-poc-button"
          >
            {t("PocInformationModal.GO_TO_POC")}
          </Button>
        </Box>
      </Container>
    </Modal>
  );
};

export default React.memo(PocInfoModal);

PocInfoModal.defaultProps = {
  testId: "poc-info-modal",
};

interface Props {
  open: boolean;
  testId?: string;
  pocId: string | undefined;
  name: string | undefined;
  owner: string | undefined;
  email: string | undefined;
  onClose: () => void;
  goToPoc: () => void;
}
