import palette from "./Palette";

export default {
  MuiCssBaseline: {
    "@global": {
      "*": {
        fontFamily: "Work Sans",
      },
      "::-webkit-scrollbar": {
        width: "8px",
      },
      "::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px transparent",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#939191",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#939191",
      },
    },
  },
  MuiAccordionDetails: {
    root: {
      padding: "5px 15px 10px 40px",
    },
  },
  MuiTypography: {
    h4: {
      color: `${palette.text.secondary}`,
    },
  },
  MuiTableCell: {
    body: {
      backgroundColor: "#F3F3F3",
      color: `${palette.text.secondary}`,
    },
    footer: {
      left: 0,
      bottom: 0,
      zIndex: 2,
      position: "sticky",
    } as const,

    sizeSmall: {
      padding: "7px",
      borderBottom: `1px solid ${palette.grey[100]}`,
    },
  },
  MuiButton: {
    outlinedPrimary: {
      borderColor: `${palette.primary.main}`,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },

    containedPrimary: {
      color: "#FFFFFF",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  MuiInput: {
    fontFamily: "Work Sans",
    underline: {
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: `2px solid ${palette.secondary.main}`,
      },
      "&:after": {
        borderBottom: `2px solid ${palette.secondary.main}`,
      },
    },
  },
  MuiInputBase: {
    root: {
      fontFamily: "Work Sans",
      color: palette.text.secondary,
      width: "100%",
      "& fieldset": {
        borderRadius: "2px",
        borderColor: "#B6B6B6",
      },
      "& input": {
        "&::placeholder": {
          color: "#BDBDBD",
        },
      },
    },
  },
  MuiTextField: {
    root: {
      "& div": {
        "& input": {
          paddingTop: "5px",
          paddingBottom: "5px",
          height: "20px",
        },
        "& fieldset": {
          borderRadius: "3px 0px 0px 3px",
        },
        paddingRight: "0px",
      },
    },
  },
  MuiAlert: {
    message: {
      fontWeight: 500,
    },
    standardSuccess: {
      color: "white",
      backgroundColor: palette.success.main,
      "& .hive-MuiAlert-icon": {
        color: "white",
      },
    },
    standardError: {
      color: "white",
      backgroundColor: palette.error.main,
      "& .hive-MuiAlert-icon": {
        color: "white",
      },
    },
  },
  MuiButtonBase: {
    root: {
      borderRadius: "30px",
    },
  },
  MuiIconButton: {
    root: {
      color: palette.primary.main,
      padding: 0,
    },
    label: {
      padding: "1px",
    },
  },
  MuiPickersStaticWrapper: {
    staticWrapperRoot: {
      minWidth: "0",
    },
  },
  MuiPickersBasePicker: {
    pickerViewLandscape: {
      padding: "0 0",
    },
    pickerView: {
      display: "initial",
      border: "solid 1px #9e9e9e",
      borderRadius: "3px",
      minWidth: "170px",
      maxWidth: "170px",
      minHeight: "200px",
    },
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      width: "168px",
      marginTop: "7px",
    },
    daysHeader: {
      "& span": {
        fontSize: "12px",
        color: "#212121",
      },
      padding: "0",
      height: "27px",
      maxHeight: "27px",
      borderTop: "0.8px solid #dfdede",
      borderBottom: "0.8px solid #dfdede",
    },
    iconButton: {
      height: "17px",
      width: "17px",
      padding: "1px",
      color: "#7a7a7a",
      "& span": {
        height: "17px",
        width: "17px",
        "& svg": {
          height: "17px",
          width: "17px",
        },
      },
    },
    transitionContainer: {
      height: "15px",
      "& p": {
        fontWeight: 600,
        fontSize: "12px",
        color: "#000000e6",
        fontFamily: "Work Sans",
      },
    },
  },
  MuiPickersDay: {
    day: {
      color: "#000000",
      width: "20px",
      height: "20px",
      border: "1px solid #000000",
      borderRadius: "20px",
      "& span p": {
        fontSize: "11px",
        fontWeight: 500,
      },
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.15)",
      },
    },
    daySelected: {
      backgroundColor: palette.secondary.main,
      color: palette.common.white,
      borderRadius: "20px",
      "&:hover": {
        backgroundColor: palette.primary.main,
      },
    },
    dayDisabled: {
      color: "#DFDEDE",
      border: "none",
    },

    current: {
      color: "",
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      minHeight: "120px",
      marginTop: "6px",
    },
  },
  MuiCardHeader: {
    action: {
      margin: 0,
      display: "flex",
      alignItems: "center",
      alignSelf: "center",
      marginTop: 0,
      marginRight: 0,
    },
  },
};
