import React from "react";

export default function IconStarUnfilled(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 64 64"
      data-testid="icon-starUnfilled"
    >
      <defs>
        <path
          id="starUnfilled-a"
          d="M15.8102648,13.8860682 L1.86845541,15.930935 L1.62034199,15.9813041 C0.0163843514,16.4010687 -0.571517932,18.4587322 0.661801144,19.6635963 L10.7467658,29.5161006 L8.3674915,43.4370846 L8.33906846,43.6780557 C8.23132556,45.3440908 10.0053886,46.5494994 11.5331272,45.7436617 L24,39.1680778 L36.4668728,45.7436617 L36.6865671,45.8452669 C38.2329394,46.4630734 39.9242967,45.1434727 39.6325085,43.4370846 L37.2510526,29.5161006 L47.3381989,19.6635963 L47.5096382,19.476782 C48.5628481,18.1928648 47.8350949,16.1806829 46.1315446,15.930935 L32.1875536,13.8860682 L25.9562588,1.21962848 C25.1559699,-0.406542827 22.8440301,-0.406542827 22.0437412,1.21962848 L15.8102648,13.8860682 Z M24,7.12902778 L28.7848924,16.8545795 L28.9174402,17.0869642 C29.2572664,17.6062361 29.8045607,17.9601503 30.42563,18.0512018 L41.1277599,19.6190495 L33.3858362,27.1849258 L33.2061285,27.3833802 C32.8181015,27.8685524 32.6515551,28.5009695 32.7578056,29.1223265 L34.5829529,39.8048325 L25.0154296,34.758773 L24.7717289,34.6487515 C24.1918086,34.4287086 23.5407918,34.4653824 22.9845704,34.758773 L13.4148655,39.8048325 L15.2421944,29.1223265 L15.2711781,28.855815 C15.3005339,28.2345287 15.0644237,27.6247973 14.6141638,27.1849258 L6.87005846,19.6190495 L17.57437,18.0512018 C18.2841635,17.9471429 18.8975978,17.4997532 19.2151076,16.8545795 L24,7.12902778 Z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(8 9)">
        <mask id="starUnfilled-b" fill="#fff">
          <use xlinkHref="#starUnfilled-a" />
        </mask>
        <use xlinkHref="#starUnfilled-a" fill="#000" fillRule="nonzero" />
        <g fill="#000" fillOpacity=".9" mask="url(#starUnfilled-b)">
          <rect width="64" height="64" transform="translate(-8 -9)" />
        </g>
      </g>
    </svg>
  );
}
