import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    pricesContainer: {
      paddingTop: "7px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "48px",
    },
    newPricesValuesContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "16px",
      gap: "8px",
      width: "100%",
    },
    pricesValuesContainer: {
      width: "100%",
    },
    lineContainer: {
      position: "relative",
      height: "18px",
      padding: "2px",
      display: "flex",
      alignItems: "center",
    },
    newLineContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    dashedLine: {
      width: "100%",
      height: "1px",
      borderTop: `1px dashed ${theme.palette.text.primary}`,
      borderBottom: 0,
      zIndex: 1,
      bottom: 0,
      margin: "0",
      marginTop: "1px",
    },
    priceTitle: {
      fontSize: "12px",
      fontWeight: 500,
      color: theme.palette.text.primary,
      position: "absolute",
      left: 0,
      bottom: 0,
      zIndex: 2,
      backgroundColor: "white",
      paddingRight: "6px",
    },
    newText: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#141414",
    },
    newDiscountText: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#176D38",
    },
    newTotalText: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
      color: "#141414",
    },
    priceValue: {
      fontSize: "12px",
      fontWeight: 500,
      textAlign: "right",
      color: theme.palette.text.primary,
      position: "absolute",
      right: 0,
      bottom: 0,
      zIndex: 2,
      backgroundColor: "white",
      paddingLeft: "8px",
    },
    totalContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    totalPrice: {
      fontSize: "14px",
      fontWeight: 900,
      color: theme.palette.text.primary,
    },
    totalPriceValue: {
      fontSize: "18px",
      fontWeight: 900,
      color: theme.palette.text.primary,
    },
    line: {
      width: "100%",
      height: "1px",
      borderTop: `1px solid ${theme.palette.text.primary}`,
    },
  }),
);
