import React from "react";

const UpliftIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 25 20"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path
                stroke="#7A7A7A"
                strokeWidth="3"
                d="M0 18L7 11 8.75 13 17.5 6.25"
                transform="translate(-363 -598) translate(135 592) translate(230 6)"
              />
              <path
                fill="#7A7A7A"
                d="M17 3L22 9 12 9z"
                transform="translate(-363 -598) translate(135 592) translate(230 6) rotate(47 17 6)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default UpliftIcon;
