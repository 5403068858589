import { createEffect, createEvent } from "effector";
import * as GetCallDurationUseCase from "../../usecase/callDuration/CallDurationUseCase";

export const createCallDurationEffect = createEffect({
  /* istanbul ignore next */
  async handler({ vendorId }: { vendorId: string }) {
    return GetCallDurationUseCase.execute({ vendorId });
  },
});

export const setCurrentPocCallDuration = createEvent<number>(
  "setCurrentPocCallDuration",
);

export const resetCallDurationEvent = createEvent<null>(
  "resetCallDurationEvent",
);
