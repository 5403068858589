import { createStore } from "effector";
import { PaymentMethodInfoState } from "./PaymentMethodInfoState";
import {
  getPaymentMethodInformation,
  resetPaymentMethodInfo,
} from "./PaymentMethodInfoEvents";

const initialState = <PaymentMethodInfoState>{
  isLoading: true,
  error: false,
  done: false,
  paymentMethodInfo: {},
};

const PaymentMethodInfoStore = createStore(initialState)
  .on(getPaymentMethodInformation, (state) => ({
    ...state,
    isLoading: true,
    error: false,
    done: false,
  }))
  .on(getPaymentMethodInformation.done, (state, { result }) => ({
    ...state,
    isLoading: false,
    error: false,
    done: true,
    paymentMethodInfo: result,
  }))
  .on(getPaymentMethodInformation.fail, (state) => ({
    ...state,
    isLoading: false,
    error: true,
    done: true,
  }))
  .reset(resetPaymentMethodInfo);

export default PaymentMethodInfoStore;
