import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useStore } from "effector-react";
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import { Phone } from "domains/phoneRanking/RankedPhones";
import CloseIcon from "../../../../assets/CloseButtonIcon";
import Modal from "../../../../components/modal/Modal";
import RankTable from "./components/RankTable/RankTable";
import Button from "./components/ButtonPhoneRanking/ButtonPhoneRanking";
import PhoneRankingModalTitle from "./components/PhoneRankingModalTitle/PhoneRankingModalTitle";
import { updatePhoneRanking } from "../../../../stores/phoneRankingStore/phoneRankedEvents";
import NewPhoneModal from "./components/NewPhoneModal/NewPhoneModal";
import PhonesRankedStore from "../../../../stores/phoneRankingStore/phoneRankedStore";
import PhoneRankingStyles from "./PhoneRankingStyles";
import ToastAlert, {
  ToastErrorStructure,
} from "./components/ToastAlert/ToastAlert";
import CustomToast, {
  TOAST_TYPES,
} from "../../../../components/CustomToast/CustomToast";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  items: Array<Phone>;
  lastCalledPhone: string;
  needClassification: boolean;
  setNeedClassification: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldOpenClassificationModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  makeCallWebSocket: (
    phone: string,
    isTyped: boolean,
    section?: string,
  ) => void;
}

function PhoneRanking({
  isOpen,
  onClose,
  items,
  lastCalledPhone,
  needClassification,
  setNeedClassification,
  setShouldOpenClassificationModal,
  makeCallWebSocket,
}: Props): JSX.Element {
  const classes = PhoneRankingStyles();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);
  const { calledPhones, error, loading, errorToUpdatePhoneRanking } =
    useStore(PhonesRankedStore);
  const [shouldOpenNewPhoneModal, setShouldOpenNewPhoneModal] = useState(false);
  const [loadingRanked, setLoadingRanked] = useState<boolean>(loading);
  const [toastError, setToastError] = useState<ToastErrorStructure>({
    message: "",
    type: "error",
    codeError: "",
  });

  // condition to show the close button
  const inv = true;

  const itemsPerPage = 4;

  const numberOfPages = () => {
    return Math.ceil(items.length / itemsPerPage);
  };

  useEffect(() => {
    const position = items.findIndex((item) =>
      item.phoneNumber.includes(lastCalledPhone),
    );

    /* istanbul ignore next */
    if (position !== -1) {
      const lastCalledPhoneCurrentPage = Math.ceil(
        (position + 1) / itemsPerPage,
      );
      setCurrentPage(lastCalledPhoneCurrentPage);
    }
  }, [lastCalledPhone, items]);

  /* istanbul ignore next */
  useEffect(() => {
    const unWatchUpdatePhoneRankingClassificationFail =
      updatePhoneRanking.fail.watch(() => {
        setLoadingRanked(false);
        if (typeof unWatchUpdatePhoneRankingClassificationFail === "function") {
          unWatchUpdatePhoneRankingClassificationFail();
        }
      });

    return () => unWatchUpdatePhoneRankingClassificationFail();
  }, []);

  function renderNewPhoneModal(): JSX.Element {
    if (!shouldOpenNewPhoneModal) return <></>;
    return (
      <NewPhoneModal
        openNewPhone={shouldOpenNewPhoneModal}
        onClose={setShouldOpenNewPhoneModal}
        setCurrentPage={setCurrentPage}
      />
    );
  }

  const handelCopyButtonClick = () => {
    CustomToast({
      type: TOAST_TYPES.SUCCESS,
      message: t("PHONE_RANKING.COPIED_PHONE"),
    });
  };

  const validateCloseButton = () => {
    return (!needClassification || error || !items.length) && !loadingRanked;
  };

  const showToastAlert = () => {
    if (toastError.message) {
      return (
        <ToastAlert toastError={toastError} setToastError={setToastError} />
      );
    }
    return null;
  };

  return (
    <Modal
      open={isOpen}
      borderRadius="1.5rem"
      data-testid="phone-ranking-modal"
    >
      <>
        <aside
          className={classes.container}
          data-testid="phone-ranking-modal-container"
        >
          {showToastAlert()}
          <section
            className={classes.header}
            data-testid="phone-ranking-header-section"
          >
            <PhoneRankingModalTitle
              phone={lastCalledPhone}
              needClassification={needClassification}
            />
            {validateCloseButton() && (
              <IconButton
                onClick={onClose}
                className={classNames({ [classes.invisible]: !inv })}
                data-testid="phone-ranking-close-modal-button"
              >
                <CloseIcon />
              </IconButton>
            )}
          </section>

          <section
            className={classes.body}
            data-testid="phone-ranking-body-section"
          >
            <RankTable
              setNeedClassification={setNeedClassification}
              needClassification={needClassification}
              items={items}
              calledPhones={calledPhones}
              currentPage={currentPage}
              lastCalledPhone={lastCalledPhone}
              hasError={error}
              loading={loadingRanked}
              setLoadingRanked={setLoadingRanked}
              onClose={onClose}
              setToastError={setToastError}
              makeCallWebSocket={(
                phone: string,
                isTyped: boolean,
                section?: string | undefined,
              ) => {
                makeCallWebSocket(phone, isTyped, section);
              }}
              handelCopyButtonClick={handelCopyButtonClick}
            />
          </section>

          {!loadingRanked && (
            <section className={classes.bottomSection}>
              <div className={classes.buttonAddPhone}>
                {((!error &&
                  !errorToUpdatePhoneRanking &&
                  !needClassification) ||
                  !items.length) && (
                  <Button
                    onClick={() => setShouldOpenNewPhoneModal(true)}
                    title={t("PHONE_RANKING.ADD_PHONE")}
                    dataTestId="phone-ranking-add-new-phone"
                  />
                )}
              </div>

              <div
                className={classes.paginationContainer}
                data-testid="phone-ranking-pagination-container"
              >
                {items?.length !== 0 && (
                  <Pagination
                    page={currentPage === 0 ? 1 : currentPage}
                    count={numberOfPages()}
                    defaultPage={1}
                    shape="rounded"
                    siblingCount={0}
                    boundaryCount={1}
                    data-testid="phone-ranking-pagination"
                    classes={{ ul: classes.items }}
                    onChange={(_, page) => {
                      setCurrentPage(page);
                    }}
                  />
                )}
              </div>

              <div className={classes.buttonCallSummary}>
                {((!errorToUpdatePhoneRanking && !needClassification) ||
                  !items.length) && (
                  <Button
                    onClick={() => {
                      setShouldOpenClassificationModal(true);
                    }}
                    title={t("PHONE_RANKING.CALL_SUMMARY")}
                    dataTestId="phone-ranking-call-summary"
                  />
                )}
              </div>
            </section>
          )}
        </aside>
        {renderNewPhoneModal()}
      </>
    </Modal>
  );
}

export default React.memo(PhoneRanking);
