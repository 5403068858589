import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useStore } from "effector-react";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import { Paper, Box, Button, Typography, Fab } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FloatingCustomerNotesStyles from "./FloatingCustomerNotes.style";
import CustomerNotesStore from "../../../../../stores/customerNotes/CustomerNotesStore";
import CallTabStore from "../../../../../stores/navigation/callTab/CallTabStore";
import {
  customerUxButtonClicked,
  customerNoteUpdated,
} from "../../../../../config/typewriter";
import {
  sendCustomerNotes,
  getCustomerNotes,
} from "../../../../../stores/customerNotes/CustomerNotesEvents";
import Loading from "../../loading/Loading";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import ErrorRetry from "../../../../../components/errorRetry/ErrorRetry";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import { getValueOrNull } from "../../../../../config/utils/functions";
import { ANALYTICS_ROUTE_NAMES } from "../../../../../config/constants";

const TEXTAREA_MAX_LENGTH = 200;
const EMPTY = "";

const FloatingCustomerNotes: React.FunctionComponent = () => {
  const { dispatchGenericEvent } = useAnalytics();
  const {
    customerNotes,
    isLoadingGetNotes,
    isLoadingSendNotes,
    errorGetNotes,
  } = useStore(CustomerNotesStore);
  const { callTab } = useStore(CallTabStore);
  const { clientId, callId, vendorAccountId } = useStore(AgentCallStore);
  const {
    user: { login },
    language,
  } = useStore(GlobalStore);
  const classes = FloatingCustomerNotesStyles();
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [showLikePlaceholder, setShowLikePlaceholder] = useState(true);
  const [note, setNote] = useState<string | null>(EMPTY);

  const handleWriteNotes = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setShowLikePlaceholder(false);
      setNote(event.target?.value);
    },
    [],
  );

  useEffect(() => {
    setNote(customerNotes?.note || EMPTY);
  }, [customerNotes]);

  useEffect(() => {
    setChecked(false);
  }, [callTab]);

  const onClickNotes = useCallback(() => {
    setShowLikePlaceholder(false);
  }, []);

  const getPlaceholder = useMemo(() => {
    if (customerNotes?.note?.trim() === EMPTY) {
      return EMPTY;
    }

    if (note === EMPTY && customerNotes !== null) {
      return EMPTY;
    }

    if (customerNotes?.note) {
      return `“${customerNotes?.note}”`;
    }

    return t("CUSTOMER_PAGE.FIRST_NOTE");
  }, [customerNotes, note, t]);

  const handleUpdateCustomerNote = useCallback(() => {
    const getNote = () =>
      showLikePlaceholder ? customerNotes?.note || EMPTY : note || EMPTY;

    sendCustomerNotes({
      clientId,
      vendorAccountId,
      agentEmail: login,
      note: getNote(),
      isFloatingComponent: true,
    });

    /* istanbul ignore next */
    const unWatchSendCustomerNotes = sendCustomerNotes.done.watch(() => {
      customerNoteUpdated({
        call_id: callId,
        poc_id: clientId,
        screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
        screen_section: "Floating Note Component",
      });
      setShowLikePlaceholder(true);
      setNote(null);
      getCustomerNotes({
        clientId,
        vendorAccountId,
      });

      if (typeof unWatchSendCustomerNotes === "function") {
        unWatchSendCustomerNotes();
      }
    });
  }, [
    clientId,
    login,
    showLikePlaceholder,
    customerNotes?.note,
    note,
    callId,
    callTab,
    vendorAccountId,
  ]);

  const retryHandler = useCallback(() => {
    getCustomerNotes({
      clientId,
      vendorAccountId,
    });
  }, [clientId, vendorAccountId]);

  const toggleCustomerNote = useCallback(() => {
    const toggleChecked = !checked;
    setChecked(toggleChecked);
    dispatchGenericEvent(customerUxButtonClicked, {
      call_id: callId,
      button_name: toggleChecked
        ? "floating-note-component-expand"
        : "floating-note-component-collapse",
      button_label: toggleChecked ? "expand" : "collapse",
      screen_section: "Floating Note Component",
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      typification: null,
    });
  }, [checked, callId, callTab, dispatchGenericEvent]);

  const renderError = () => {
    return (
      <div className={classes.errorContent}>
        <ErrorRetry
          onClick={retryHandler}
          testId="customer-notes-retry"
          screenName={getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab))}
          screenSection="Floating Note Component"
        />
      </div>
    );
  };

  const renderContent = () => {
    if (isLoadingGetNotes || isLoadingSendNotes) {
      return (
        <div className={classes.loading}>
          <Loading />
        </div>
      );
    }

    return (
      <Box>
        <Box className={classes.textContainer}>
          <Box className={classes.textArea}>
            <textarea
              id="customer-notes-textarea"
              name="customerNotes"
              className={classes.textInput}
              maxLength={TEXTAREA_MAX_LENGTH}
              placeholder={getPlaceholder}
              value={showLikePlaceholder ? EMPTY : note || EMPTY}
              onChange={handleWriteNotes}
              onClick={onClickNotes}
              data-testid="floating-customer-notes-textarea"
            />
          </Box>
          <Typography
            className={classes.textAreaCounter}
            variant="h3"
            component="span"
            data-testid="floating-customer-notes-textarea-length"
          >
            {note?.length}/{TEXTAREA_MAX_LENGTH}
          </Typography>
        </Box>
        <Button
          className={classes.registerNoteBtn}
          data-testid="floating-customer-notes-update-btn"
          variant="outlined"
          onClick={handleUpdateCustomerNote}
        >
          {t("CUSTOMER_PAGE.UPDATE_NOTE_BTN")}
        </Button>
      </Box>
    );
  };

  const FloatingBox = () => {
    const floatingBoxSpace = language.label === "PT" ? "-118px" : "-70px";
    if (checked) {
      return (
        <Fab
          className={classes.floatingButton}
          onClick={toggleCustomerNote}
          style={{
            margin: "-20px",
          }}
          data-testid="floating-customer-notes-fab"
        >
          <ArrowBackIcon className={classes.arrowBackIcon} />
        </Fab>
      );
    }
    return (
      <Fab
        className={classes.notesBox}
        onClick={toggleCustomerNote}
        variant="extended"
        data-testid="floating-customer-notes-fab"
        style={{
          marginLeft: floatingBoxSpace,
        }}
      >
        <span className={classes.notes}>{t("CUSTOMER_PAGE.FLOATING_BOX")}</span>
        <div
          className={classes.floatingButton}
          style={{
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ArrowForwardIcon className={classes.arrowIcon} />
        </div>
      </Fab>
    );
  };

  return (
    <Box data-testid="floating-customer-notes">
      <Box className={classes.container}>
        <Paper
          elevation={3}
          className={checked ? classes.paper : classes.paperClosed}
        >
          {checked && (
            <Box
              className={classes.boxChecked}
              data-testid="floating-customer-notes-content"
            >
              <Typography variant="h2" data-testid="customer-notes-times">
                {t("CUSTOMER_PAGE.NOTES_FOR_POC")}
              </Typography>
              {errorGetNotes ? renderError() : renderContent()}
            </Box>
          )}
        </Paper>

        <Box className={classes.containerButton}>
          <FloatingBox />
        </Box>
      </Box>
    </Box>
  );
};

export default FloatingCustomerNotes;
