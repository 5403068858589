import styled from "styled-components";

const LoadingProducts = styled.div`
  text-align: center;
  justify-content: center;
  display: grid;
  margin: 3.35rem;
`;

const ContainerDots = styled.div`
  display: grid;
  justify-content: center;
  margin: 1.5rem;
`;

export { LoadingProducts, ContainerDots };
