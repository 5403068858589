import React from "react";

export default function iconZap(): JSX.Element {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-testid="icon-zap"
    >
      <defs>
        <path
          d="M0.560748527,29.6656595 L0.397969222,29.8835016 C-0.638716338,31.4393525 0.462179461,33.6013499 2.4041273,33.6013499 L21.27892,33.6013499 L19.2194467,50.1005133 C18.9222533,52.4780608 21.9099235,53.7749854 23.4438374,51.9342888 L47.4392515,23.1397919 L47.6020308,22.9219498 C48.6387163,21.3660989 47.5378205,19.2041015 45.5958727,19.2041015 L26.7186804,19.2041015 L28.7805533,2.70493807 C29.0777467,0.327390587 26.0900765,-0.969534045 24.5561626,0.871162612 L0.560748527,29.6656595 Z M23.0017908,10.2322161 L21.6189881,21.3060164 L21.6001731,21.5898968 C21.5903132,22.9012339 22.652902,24.0031843 24,24.0031843 L40.4704522,24.0031843 L24.9958097,42.5708357 L26.3810119,31.499435 L26.3998269,31.2155546 C26.4096868,29.9042175 25.347098,28.8022671 24,28.8022671 L7.52714821,28.8022671 L23.0017908,10.2322161 Z"
          id="zap-path-1"
        />
      </defs>
      <g
        id="___Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ZZ_Color-Override/Primary/Black"
          transform="translate(8.000000, 6.000000)"
        >
          <mask id="zap-mask-2" fill="white">
            <use xlinkHref="#zap-path-1" />
          </mask>
          <g id="Mask" fillRule="nonzero" />
          <g
            id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            mask="url(#zap-mask-2)"
            fill="#000000"
            fillOpacity="0.9"
          >
            <g transform="translate(-8.000000, -6.000000)" id="Rectangle">
              <rect x="0" y="0" width="64" height="64" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
