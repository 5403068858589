import React from "react";

const CopyIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="14px"
      height="16px"
      viewBox="0 0 14 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Bees-Grow---Current"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="SPRINT-56---05"
          transform="translate(-1030.000000, -473.000000)"
          fill="#505050"
          fillRule="nonzero"
        >
          <g id="Group-2" transform="translate(935.000000, 332.000000)">
            <g id="copy-(1)" transform="translate(95.000000, 141.000000)">
              <path
                d="M11.4127773,0 L4.71924171,0 C3.74695735,0 2.95590521,0.791052133 2.95590521,1.76333649 L2.95590521,1.97975355 L1.78964929,1.97975355 C0.817364929,1.97975355 0.0263127962,2.77080569 0.0263127962,3.74309005 L0.0263127962,14.1690237 C0.0263127962,15.1413081 0.817364929,15.9323602 1.78964929,15.9323602 L8.48318483,15.9323602 C9.45546919,15.9323602 10.2464455,15.1413081 10.2464455,14.1690237 L10.2464455,13.9526066 L11.4127014,13.9526066 C12.3849858,13.9526066 13.1760379,13.1615545 13.1760379,12.1892701 L13.1760379,1.76333649 C13.1760379,0.791052133 12.3850616,0 11.4127773,0 Z M9.10908057,14.1690237 C9.10908057,14.5141232 8.82828436,14.7949194 8.48326066,14.7949194 L1.78964929,14.7949194 C1.44454976,14.7949194 1.16375355,14.5141232 1.16375355,14.1690237 L1.16375355,3.74301422 C1.16375355,3.39791469 1.44454976,3.11711848 1.78964929,3.11711848 L8.48318483,3.11711848 C8.82828436,3.11711848 9.10908057,3.39791469 9.10908057,3.74301422 L9.10908057,14.1690237 L9.10908057,14.1690237 Z M12.038673,12.1892701 C12.038673,12.5343697 11.7578768,12.8151659 11.4127773,12.8151659 L10.2465213,12.8151659 L10.2465213,3.74301422 C10.2465213,2.77072986 9.45546919,1.97967773 8.48326066,1.97967773 L4.09334597,1.97967773 L4.09334597,1.76326066 C4.09334597,1.41816114 4.37414218,1.13736493 4.71924171,1.13736493 L11.4127773,1.13736493 C11.7578768,1.13736493 12.038673,1.41816114 12.038673,1.76326066 L12.038673,12.1892701 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CopyIcon;
