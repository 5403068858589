import { createStore } from "effector";
import {
  getTagsEvent,
  getTabsEvent,
  getGlobalAndLocalMissionsEvent,
  resetTags,
} from "./TagsEvents";
import TagsState from "./TagsState";

const initialState: TagsState = {
  list: [],
  tabs: [],
  isLoaded: false,
  isLoading: false,
  hasError: false,
};

const TagsStore = createStore(initialState)
  .on(getTagsEvent, (state: TagsState, list: Array<string>) => ({
    ...state,
    list,
    isLoaded: true,
  }))
  .on(getTabsEvent, (state: TagsState, tabs: Array<string>) => ({
    ...state,
    tabs,
    isLoaded: true,
  }))
  .on(getGlobalAndLocalMissionsEvent.done, (state, { result }) => ({
    ...state,
    list: result?.missions || [],
    tabs: result?.missionsTabs || [],
    isLoading: false,
    isLoaded: true,
    hasError: false,
  }))
  .on(getGlobalAndLocalMissionsEvent, (state) => ({
    ...state,
    hasError: false,
    isLoading: true,
    isLoaded: false,
  }))
  .on(getGlobalAndLocalMissionsEvent.fail, (state) => ({
    ...state,
    hasError: true,
    isLoading: false,
    isLoaded: false,
  }))
  .reset(resetTags);

export default TagsStore;
