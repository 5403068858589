import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      width: "370px",
      padding: "32px",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontSize: "16px",
      fontWeight: "bold",
      color: palette.text.secondary,
      paddingBottom: "8px",
      marginBottom: "24px",
      borderBottom: `1px solid ${palette.grey[200]}`,
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      padding: "0 0 24px 0",
    },
    inputWithError: {
      paddingBottom: "9px",
    },
    label: {
      fontSize: "12px",
      fontWeight: 600,
      color: palette.text.secondary,
      display: "flex",
      flexDirection: "column",
    },
    labelText: {
      marginBottom: "8px",
    },
    textarea: {
      resize: "none",
      height: "72px",
      borderRadius: "3px",
      border: `solid 1px ${palette.text.secondary}`,
      padding: "10px",
      outline: "none",
      "&:focus": {
        border: `solid 1px ${palette.common.black}`,
      },
    },
    buttonsContainer: {
      display: "flex",
      width: "100%",
      padding: "0",
      marginTop: "7px",
    },
    button: {
      height: "40px",
      fontSize: "14px",
      fontWeight: 600,
      display: "flex",
      flex: 1,
      borderRadius: "3px",
    },
    cancelButton: {
      backgroundColor: palette.grey[100],

      color: palette.text.primary,
      marginRight: "5px",
    },
    registerButton: {
      backgroundColor: palette.text.secondary,
      color: palette.common.white,
      margingLeft: "5px",
    },
  }),
);
