import { createStyles, Theme, makeStyles } from "@material-ui/core";

interface Props {
  isFocusSkuEnabled?: boolean;
  isInMissions?: boolean;
}

export default makeStyles<Theme, Props>(({ palette }) =>
  createStyles({
    loadingContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    container: {
      padding: "10px 0 10px 0",
      backgroundColor: "#fafafa !important",
      height: "50%",
      display: "flex",
      flexDirection: "column",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    alignRight: {
      textAlign: "right",
    },
    rowHighlighted: {
      backgroundColor: "#f2f2f2",
    },
    defaultRow: {
      backgroundColor: "#fafafa !important",
    },
    title: {
      marginLeft: 10,
      fontWeight: 500,
      fontSize: "18px",
      letterSpacing: 0,
      color: "#525252",
      opacity: 1,
    },
    table: {
      paddingTop: "10px",
      width: "100%",
      backgroundColor: "#fafafa !important",
      overflow: "auto",
    },
    refreshButton: {
      padding: 0,
      minWidth: 0,
      border: 0,
    },
    refreshButtonIcon: {
      width: 20,
      height: 19,
      fontWeight: "normal",
      color: "#505050",
    },
    noPreviousOrder: {
      fontSize: "18px",
      color: "#7c7c7c",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableNavigationContainer: {
      backgroundColor: "#fafafa !important",
      maxHeight: (props) => {
        /* istanbul ignore next */
        if (props.isInMissions) {
          return "100%";
        }
        return props.isFocusSkuEnabled ? "60%" : "94%";
      },
      display: "flex",
      flexDirection: "column",
    },
    titleUpdate: {
      fontSize: 11,
      fontWeight: "normal",
      color: "#7a7a7a",
      whiteSpace: "nowrap",
      marginRight: 10,
      marginTop: "9px",
      marginLeft: 16,
    },
    flex: {
      display: "flex",
      flexDirection: "row",
    },
    channelText: {
      color: `${palette.text.secondary} !important`,
      fontWeight: "bold",
    },
  }),
);
