import React from "react";
import { Box } from "@material-ui/core";
import { useStore } from "effector-react";
import GlobalStore from "../../../../stores/global/GlobalStore";
import {
  GROW_CUSTOMER_NOTES,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";
import MissionsListComponent from "../../components/missionsList/MissionsListComponent";
import CallInfo from "../../components/callInfo/CallInfo";
import useStyle from "./style";
import CustomerNotes from "../../components/customer/customerNotes/CustomerNotes";
import LastContacts from "../../components/customer/lastContacts/LastContacts";

const CustomerPage: React.FC = () => {
  const classes = useStyle();
  const { user } = useStore(GlobalStore);

  const isCostumerNotesEnabled = isFeatureEnabledV2(
    GROW_CUSTOMER_NOTES,
    user.zone,
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.centerContainer}>
        {isCostumerNotesEnabled && (
          <CustomerNotes data-testid="customer-notes" />
        )}
        <LastContacts />
      </Box>
      <Box className={classes.rightContent}>
        <CallInfo />
        <MissionsListComponent />
      </Box>
    </Box>
  );
};

export default CustomerPage;
