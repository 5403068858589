/* istanbul ignore file */
import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      position: "relative",
      padding: "2rem",
      paddingBottom: "2.38rem",
      minWidth: 1053,
      minHeight: 527,
    },
    header: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
    title: {
      fontSize: "1.5rem",
    },
    modal: {
      borderRadius: "1.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    body: {
      minHeight: 325,
    },

    invisible: {
      display: "none",
    },
    bottomSection: {
      display: "flex",
      marginTop: "2rem",
      alignItems: "center",
      width: "100%",
    },
    buttonAddPhone: {
      width: "33%",
    },
    buttonCallSummary: {
      width: "33%",
      display: "flex",
      justifyContent: "flex-end",
    },
    paginationContainer: {
      width: "33%",
      display: "flex",
      justifyContent: "center",
    },
    footerSection: {
      display: "flex",
      justifyContent: "center",
    },

    items: {
      "& li": {
        width: 44,
        heigth: 40,
        borderRadius: 0,
        borderLeft: "none",
        borderTop: "0.5px solid #dfdede",
        borderBottom: "0.5px solid #dfdede",
        display: "flex",
        justifyContent: "center",
      },

      "& li button": {
        borderRadius: 0,
        margin: 0,
        height: 40,
        width: 44,
      },

      "& li div": {
        minHeight: 40,
        paddingTop: 8,
        borderRadius: 0,
      },

      "& li:first-child > button": {
        borderRadius: "22px 0px 0px 22px",
      },
      "& li:first-child ": {
        borderRadius: "22px 0px 0px 22px",
        border: "0.5px solid #dfdede",
      },

      "& li:last-child > button": {
        borderRadius: "0px 22px 22px 0px",
      },

      "& li:last-child ": {
        borderRadius: "0px 22px 22px 0px",
        border: "0.5px solid #dfdede",
      },

      "& li > button.Mui-selected": {
        borderBottom: "2px solid #000000",
        fontWeight: 600,
        backgroundColor: "#ffffff",
      },
    },

    headerMessageContainer: {
      width: 1053,
      height: "auto",
      position: "absolute",
      top: 0,
    },
  }),
);
