import { TFunction } from "i18next";

export function b2oTagNameFormatter(tag: string): string {
  const b2oTag = "#b2o-";
  const removedFirstB2o = tag.replace(b2oTag, "");
  const normalizedTag = removedFirstB2o.replace("#", "");
  const tagName = normalizedTag[0].toUpperCase() + normalizedTag.slice(1);
  return `B2O - ${tagName}`;
}
export function tagNameFormatterGlobal(tag: string): string {
  const removeHash = tag.replace(/#/g, "");
  return `#${removeHash}`;
}

export function ConvertAndFormatTag(t: TFunction, tag: string): string {
  switch (tag.includes("#b2o") ? "#b2o" : tag.toLowerCase()) {
    case "#toplinegap": {
      return t("MISSIONS.CARD.toplineGap.title");
    }
    case "#rewardsredemption": {
      return t("MISSIONS.CARD.rewardsRedemption.title");
    }
    case "#promotemarketplace": {
      return t("MISSIONS.CARD.marketplace.title");
    }
    case "#marketplaceactivation": {
      return t("MISSIONS.CARD.marketplace.title");
    }
    case "#promoterewardschallenges": {
      return t("MISSIONS.CARD.rewardsChallenges.title");
    }
    case "#norewardsredemption": {
      return t("MISSIONS.CARD.rewardsRedemption.title");
    }
    case "#promotealgoselling": {
      return t("MISSIONS.CARD.quickOrder.title");
    }
    case "#b2o": {
      return b2oTagNameFormatter(tag);
    }
    case "#digital_services": {
      return t("MISSIONS.CARD.services.title");
    }
    default:
      return tag;
  }
}
