import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    wrapper: {
      padding: "8px 12px",
      height: "83px",
    },
    container: {
      display: "flex",
      width: "100%",
      color: palette.common.white,
    },
    title: {
      fontWeight: "bold",
      fontSize: "12px",
      color: palette.common.white,
    },
    value: {
      fontWeight: "bold",
      fontSize: "22px",
    },
    notEnrolled: {
      width: "150px",
      fontSize: "12px",
      fontWeight: 600,
      color: palette.common.white,
      paddingTop: 5,
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      paddingRight: "8px",
    },
    infoClub: {
      color: palette.common.white,
    },
    imageSizeClubB: {
      width: "2.5rem",
      height: "1rem",
    },
    imageSizeClubBDark: {
      width: "4.5886rem",
      height: "1rem",
    },
    imageSizeClubBMax: {
      width: "4.8235rem",
      height: "1rem",
    },
  }),
);
