import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    addedItemText: {
      marginLeft: "4px",
      height: "18px",
      fontSize: "10px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "18px",
    },
    addedItemSubText: {
      marginLeft: "4px",
      height: "18px",
      fontSize: "10px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "18px",
      textTransform: "lowercase",
    },
    addedItemTextLink: {
      marginLeft: "4px",
      height: "18px",
      fontSize: "10px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "18px",
      color: "#0371e2",
      textDecoration: "underline",
    },
    inheritDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    inheritDivText: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      textAlign: "right",
      flexWrap: "wrap",
    },
    addButton: {
      marginLeft: "8px",
    },
    addContainer: {
      display: "flex",
      flexwrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    recommendedCardActions: {
      height: 32,
      display: "flex",
    },
  }),
);
