import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import {
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  GROW_ORGANIZATION_ACTIVATION,
} from "@config/featureToggles";
import axios from "../../config/axios/axiosInstance";
import { CampaignsResponse } from "./domains/CampaignsResponse";

import { getApiHost } from "../host/HostService";
import { TLP_CAMPAIGN_SERVICE } from "../../config/services";
import { Campaigns } from "../../domains/Campaigns";
import CampaignsResponseToCampaignsConverter from "../../converters/campaigns/CampaignsResponseToCampaignsConverter";
import CampaignsResponseToCampaignsConverterV3, {
  CampaignsResponseV3,
  CampaignsV3,
} from "../../converters/campaigns/CampaignsResponseToCampaignsConverterV3";
import { CampaignsRequest } from "./domains/CampaignsRequest";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getCampaigns(
  request: CampaignsRequest,
  headers = {},
): Promise<Campaigns> {
  const log = useLogService();

  const {
    DDC,
    accountId,
    country,
    isTypedPoc,
    segment,
    vendorId,
    vendorAccountId,
    vendorName,
    requestTraceId,
    orgId,
  } = request;

  const isMulticontractTlpCampaignEnable = isFeatureEnabledV2(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
    country,
  );

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
  );

  let CAMPAINGS_ENDPOINT = isMulticontractTlpCampaignEnable
    ? "/tlp-campaign/v2"
    : "/tlp-campaign";

  if (isOrganizationConfigEnabled) {
    CAMPAINGS_ENDPOINT = "/v3/campaigns";
  }

  let urlmarket = `${getApiHost(
    TLP_CAMPAIGN_SERVICE,
  )}${CAMPAINGS_ENDPOINT}/${accountId}/products`;

  if (isOrganizationConfigEnabled) {
    urlmarket = `${getApiHost(TLP_CAMPAIGN_SERVICE)}${CAMPAINGS_ENDPOINT}`;
  }

  let params: {
    projection?: "PORTAL";
    accountId?: string;
    deliveryCenterId: string;
    segment: string;
    isTypedPoc: boolean;
    vendorId?: string;
    vendorAccountId?: string;
  } = {
    deliveryCenterId: DDC,
    segment,
    isTypedPoc: isTypedPoc ?? false,
    vendorAccountId,
  };

  if (isOrganizationConfigEnabled) {
    params = {
      ...params,
      projection: "PORTAL",
      accountId,
      vendorId,
    };
  }

  if (isMulticontractTlpCampaignEnable) {
    params = {
      ...params,
      vendorId,
    };
  }

  const getHeaders = () => {
    const vendorData = !isMulticontractTlpCampaignEnable
      ? {
          vendorId,
          vendorName,
        }
      : {};

    return { ...vendorData, ...headers };
  };

  return axios
    .get<CampaignsResponse>(`${urlmarket}`, {
      params,
      headers: isOrganizationConfigEnabled
        ? { ...headers, country, requestTraceId, orgId }
        : getHeaders(),
    })
    .then((response) => {
      log.info(`Convert New Offerings received from ${urlmarket} to our model`);

      if (response.data) {
        return isOrganizationConfigEnabled
          ? (CampaignsResponseToCampaignsConverterV3(
              response.data as CampaignsResponseV3,
            ) as CampaignsV3)
          : CampaignsResponseToCampaignsConverter(response.data);
      }

      return <Campaigns | CampaignsV3>{
        campaigns: [],
        focus: [],
      };
    })
    .catch((error) => {
      log.error(error);
      throw error;
    }) as Promise<Campaigns>; // Explicitly cast the return type to Promise<Campaigns>
}
