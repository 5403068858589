import React from "react";

function LocalMissions() {
  return (
    <svg
      width="48px"
      height="48px"
      viewBox="0 0 53 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stop-color="#F5F338" offset="0%"></stop>
          <stop stop-color="#EEEB00" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="📄-Local-mission"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Local-mission---1366-x-768"
          transform="translate(-353.000000, -206.000000)"
        >
          <g id="mission-header" transform="translate(334.000000, 191.000000)">
            <g id="Group-5" transform="translate(19.000000, 15.000000)">
              <ellipse
                id="Oval"
                fill="url(#linearGradient-1)"
                cx="26.5"
                cy="25"
                rx="26.5"
                ry="25"
              ></ellipse>
              <g
                id="map-(1)"
                transform="translate(3.785714, 7.142857)"
                fill-rule="nonzero"
              >
                <path
                  d="M45.3628669,41.9844308 L38.8165387,27.0340681 C38.7136146,26.7990234 38.4720979,26.6459263 38.204229,26.6459263 L7.2096989,26.6459263 C6.94183003,26.6459263 6.70022457,26.7991071 6.59738919,27.0340681 L0.050972333,41.9844308 C-0.0342949661,42.1789621 -0.0115806804,42.4011998 0.111573338,42.5764788 C0.234638628,42.7518415 0.441640302,42.8571429 0.663370771,42.8571429 L44.7505572,42.8571429 C44.9721989,42.8571429 45.1792893,42.7517578 45.3023546,42.5764788 C45.4254199,42.4012835 45.4480454,42.1790458 45.3628669,41.9844308 Z"
                  id="Path"
                  fill="#D1F7F6"
                ></path>
                <path
                  d="M42.6388384,42.8571429 L44.7505572,42.8571429 C44.9721989,42.8571429 45.1792893,42.7517578 45.3023546,42.5764788 C45.4254199,42.4012835 45.4481342,42.1790458 45.3629556,41.9844308 L38.8166275,27.0340681 C38.7137034,26.7990234 38.4721866,26.6459263 38.2043178,26.6459263 L35.5404467,26.6459263 L42.6388384,42.8571429 Z"
                  id="Path"
                  fill="#B4F5F4"
                ></path>
                <g id="Group" transform="translate(4.250276, 26.631948)">
                  <path
                    d="M6.6706356,5.65245536 L8.92955357,0 L2.9174548,0 C2.64958594,0 2.40798047,0.153180804 2.30514509,0.388141741 L0,5.65245536 L6.6706356,5.65245536 Z"
                    id="Path"
                    fill="#1AF07E"
                  ></path>
                  <path
                    d="M33.9119849,0 L13.0364916,0 L19.1252511,5.00884487 L36.5476406,5.00884487 L34.5243834,0.388225446 C34.4213705,0.153264509 34.1798538,0 33.9119849,0 Z"
                    id="Path"
                    fill="#1AF07E"
                  ></path>
                  <polygon
                    id="Path"
                    fill="#4CE166"
                    points="36.7039788 5.36601563 36.8294397 5.65245536 36.8294397 5.65245536"
                  ></polygon>
                </g>
                <polygon
                  id="Path"
                  fill="#FFDB56"
                  points="42.8109701 36.2383929 40.7979165 31.6407924 23.3754383 31.6407924 17.2867675 26.6319475 13.2022776 26.6319475 12.6825108 26.6319475 10.4785153 31.6395368 4.53278483 31.6395368 3.19725582 34.6894252 2.51902144 36.2383929 8.47105157 36.2383929 5.57915035 42.8431641 10.9896755 42.8431641 15.6345694 32.1142299 29.0294322 42.8431641 36.9375527 42.8431641 28.9321866 36.2383929"
                ></polygon>
                <path
                  d="M22.6649958,0 C14.7653931,0 8.33866988,6.10856585 8.33866988,13.6170201 C8.33866988,16.2055246 9.07972345,18.9518136 10.541512,21.779548 C11.698166,24.017327 13.3075973,26.3131138 15.3248211,28.6032087 C18.7472251,32.4883929 22.1171911,34.9827288 22.2590666,35.0871094 C22.3786716,35.1750837 22.5217893,35.2188616 22.6649958,35.2188616 C22.8082022,35.2188616 22.9514087,35.1749163 23.0709249,35.0871094 C23.2127117,34.9827288 26.5826777,32.4883929 30.0051704,28.6032087 C32.0224829,26.3131138 33.6319143,24.017327 34.7884796,21.779548 C36.2502681,18.9517299 36.9913217,16.2055246 36.9913217,13.6170201 C36.9913217,6.10856585 30.5645984,0 22.6649958,0 Z M22.6649958,8.43733259 C25.6746386,8.43733259 28.1144723,10.7563895 28.1144723,13.6170201 C28.1144723,16.4776507 25.6746386,18.7967076 22.6649958,18.7967076 C19.6553529,18.7967076 17.2155192,16.4776507 17.2155192,13.6170201 C17.2155192,10.7563895 19.6553529,8.43733259 22.6649958,8.43733259 Z"
                  id="Shape"
                  fill="#FF6F71"
                ></path>
                <path
                  d="M22.6649958,0 C22.1935856,0 21.7274991,0.0224330357 21.2676236,0.0648716518 C28.5138356,0.73468192 34.1965772,6.55664063 34.1965772,13.6170201 C34.1965772,16.2055246 33.4555237,18.9518136 31.9937352,21.779548 C30.8370812,24.017327 29.2276498,26.3131138 27.210426,28.6032087 C24.863224,31.2678013 22.5410432,33.2778181 21.2676236,34.3116629 C21.851008,34.7852679 22.2145253,35.0542969 22.2589779,35.0870257 C22.3785828,35.175 22.5217006,35.2187779 22.664907,35.2187779 C22.8081135,35.2187779 22.95132,35.1748326 23.0708362,35.0870257 C23.212623,34.9826451 26.582589,32.4883092 30.0050817,28.603125 C32.0223942,26.3130301 33.6318256,24.0172433 34.7883909,21.7794643 C36.2501794,18.9516462 36.9912329,16.2054408 36.9912329,13.6169364 C36.9912329,6.10856585 30.5645984,0 22.6649958,0 Z"
                  id="Path"
                  fill="#F03D40"
                ></path>
                <polygon
                  id="Path"
                  fill="#1AF07E"
                  points="10.9896755 42.8431641 29.0294322 42.8431641 15.6345694 32.1142299"
                ></polygon>
                <polygon
                  id="Path"
                  fill="#FFBB24"
                  points="37.6916492 31.6407924 39.7047915 36.2383929 42.8109701 36.2383929 40.7979165 31.6407924"
                ></polygon>
                <path
                  d="M37.6916492,31.6407924 L40.7979165,31.6407924 L38.7746593,27.020173 C38.6717352,26.7851283 38.4302184,26.6320313 38.1623496,26.6320313 L35.4984785,26.6320313 L37.6916492,31.6407924 Z"
                  id="Path"
                  fill="#44C868"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LocalMissions;
