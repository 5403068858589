import React from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useStore } from "effector-react";
import RewardBadgeIcon from "../../../../../assets/RewardBadgeIcon";
import LoyaltyProgramStyles from "./LoyaltyProgramStyles";
import Data from "../../../../../components/data/Data";
import LoyaltyProgramStore from "../../../../../stores/loyaltyProgram/LoyaltyProgramStore";
import brandImage from "../../../../../assets/images/Bees-Brand.svg";
import brandImageDark from "../../../../../assets/images/Bees-Brand-Dark.svg";
import brandImageMax from "../../../../../assets/images/Bees-Brand-Max.svg";

const LoyaltyProgram: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = LoyaltyProgramStyles();
  const loyaltyProgramStates = useStore(LoyaltyProgramStore);
  const { tier } = loyaltyProgramStates;
  const loadingLoyaltyProgram = loyaltyProgramStates.loading;

  const getLoyaltyProgramValue = () => {
    if (loyaltyProgramStates.error) {
      return undefined;
    }
    return loyaltyProgramStates.balance;
  };

  const notEnrolled =
    loyaltyProgramStates.dateRegistered === undefined &&
    !loyaltyProgramStates.error;

  if (loadingLoyaltyProgram) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="94px"
      >
        <CircularProgress data-testid="loyalty-loading" size={20} />
      </Box>
    );
  }

  if (!loyaltyProgramStates.done) {
    return <Box data-testid="loyalty-not-loaded" />;
  }

  const renderClubType = () => {
    switch (tier) {
      case "CLUB_B":
        return renderImage(
          brandImage,
          classes.imageSizeClubB,
          t("LoyaltProgram.ALT_SUBSCRIBE_CLUB_B"),
        );

      case "CLUB_B_MAX":
        return renderImage(
          brandImageMax,
          classes.imageSizeClubBMax,
          t("LoyaltProgram.ALT_SUBSCRIBE_CLUB_B_MAX"),
        );

      case "CLUB_B_BLACK":
        return renderImage(
          brandImageDark,
          classes.imageSizeClubBDark,
          t("LoyaltProgram.ALT_SUBSCRIBE_CLUB_B_BLACK"),
        );

      default:
        return t("LoyaltProgram.NOT_SUBSCRIBE_CLUB");
    }
  };
  const renderImage = (
    imageToUse: string,
    imageClass: string,
    altImageText: string,
  ) => {
    return (
      <img
        src={imageToUse}
        alt={altImageText}
        className={imageClass}
        data-testId="club-info-img"
      />
    );
  };

  return (
    <Box className={classes.wrapper}>
      <span className={classes.title} data-testid="loyalty-program-label">
        {t("LoyaltProgram.LOYALT_PROGRAM_LABEL")}
      </span>

      <Box className={classes.container}>
        <span className={classes.iconContainer}>
          <RewardBadgeIcon />
        </span>
        {notEnrolled ? (
          <Box className={classes.notEnrolled} data-testid="not-enrolled-text">
            {t("LoyaltProgram.NOT_ENROLLED")}
          </Box>
        ) : (
          <>
            <Data
              value={getLoyaltyProgramValue()}
              type="number"
              emptyMessage="GlobalMessage.N_A"
              className={classes.value}
              testId="loyalty-program-value"
            />
          </>
        )}
      </Box>
      <Typography
        variant="h3"
        className={classes.infoClub}
        data-testId="club-info-container"
      >
        {renderClubType()}
      </Typography>
    </Box>
  );
};

export default React.memo(LoyaltyProgram);
