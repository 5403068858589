import { getValueEmpty } from "../../config/utils/functions";
import { CartComboItem } from "../../domains/cart/CartComboItem";
import CartSimulationRequest, {
  Item,
} from "../../services/cartSimulation/domains/CartSimulationRequest";
import CartItemState from "../../stores/cart/CartItemState";
import CartState from "../../stores/cart/CartState";
import CartComboState from "../../stores/cartCombo/CartComboState";
import { ComboItem } from "../../services/checkout/domains/v2/CheckoutRequestV2";
import AgentCallState from "../../stores/agentCall/AgentCallState";

export default function StoresToCartSimulationRequestConverter(
  cartItemStates: CartItemState,
  agentCallStates: AgentCallState,
  cartStates: CartState,
  cartComboState: CartComboState,
  isOrderTakingEmptiesEnabled: boolean,
): CartSimulationRequest {
  const itemsCartSimulation: Item[] = cartItemStates.cartItems.map((each) => {
    return {
      platformId: each.platformId,
      quantity: each.itemQuantity,
    };
  });

  return {
    accountId: getValueEmpty(agentCallStates.clientId),
    paymentMethod: cartStates.cartForm?.paymentMethod,
    paymentTerm: cartStates.cartForm?.paymentTerm,
    deliveryWindowId: cartStates.cartForm?.deliveryWindowId || null,
    payment: {
      methodCode: cartStates.cartForm?.paymentMethodCode || null,
    },
    delivery: {
      method: "STANDARD",
      date:
        cartStates.cartForm?.deliveryDate?.split("T")[0] ||
        new Date().toISOString().split("T")[0],
    },
    items: itemsCartSimulation,
    combos: getCombosRequest(cartComboState.cartComboItems),
    ...updateEmpties(isOrderTakingEmptiesEnabled, cartItemStates),
    id: cartItemStates.cart?.cartId ?? null,
  };
}

function getCombosRequest(cartComboItems) {
  return cartComboItems.map((each: CartComboItem) => {
    return {
      platformId: each.platformId,
      quantity: each.itemQuantity,
    } as ComboItem;
  });
}

function updateEmpties(isOrderTakingEmptiesEnabled, cartItemStates) {
  if (isOrderTakingEmptiesEnabled) {
    const emptiesList = cartItemStates?.empties;
    return {
      empties: emptiesList?.map((each) => {
        return {
          id: each.groupId,
          quantity: each.quantity,
        };
      }),
    };
  }
  return null;
}
