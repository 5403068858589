/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_DISPLAY_ADDITIONAL_METADATA_INFO,
  TLP_SALES_STRUCTURE,
  TLP_SALES_STRUCTURE_ADMIN,
} from "../../../../config/featureToggles";
import GlobalStore from "../../../../stores/global/GlobalStore";
import { ANALYTICS_ROUTE_NAMES } from "../../../../config/constants";
import { getValueOrNull, isEmpty } from "../../../../config/utils/functions";
import { callUxButtonClicked } from "../../../../config/typewriter";
import PocInfoItem from "./PocInfoItem";
import AdditionalInformation from "../../../../domains/AdditionalInformation";
import CollapsibleContainer from "./CollapsibleContainer";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";
import PocInfoContainer from "../../../../components/pocInfoContainer/PocInfoContainer";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";

interface Props {
  data: AdditionalInformation | null;
  dispatchEvent: (
    event: any,
    config: Record<string, unknown>,
    optionalData?: any,
  ) => void;
}

const AdditionalInformationInfo: React.FC<Props> = ({
  data,
  dispatchEvent,
}) => {
  const { user } = useStore(GlobalStore);
  const { t } = useTranslation();
  const { callTab } = useStore(CallTabStore);
  const { callId } = useStore(AgentCallStore);

  const isSalesStructureEnabled = isFeatureEnabled(
    TLP_SALES_STRUCTURE,
    TLP_SALES_STRUCTURE_ADMIN,
    user.keyToggle,
  );

  const isDisplayAdditionalMetadataInfoEnabled = isFeatureEnabledV2(
    TLP_DISPLAY_ADDITIONAL_METADATA_INFO,
    user.keyToggle,
  );

  /* istanbul ignore next */
  const handleClickCollapse = (isOpen: boolean) => {
    const getButtonName = () =>
      t("PocInformationHeaderTemplate.ADDITIONAL_INFORMATION");

    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: getButtonName(),
      button_label: !isOpen ? "expand" : "collapse",
      screen_section: "POC Details",
      filter_option: null,
      call_id: callId,
      order_date: null,
      position: null,
      mission: null,
    });
  };

  const getAdditionalInfoArray = (
    code: string | undefined,
    description: string | undefined,
  ) => {
    const items: Array<string> = [];
    if (code) {
      items.push(code);
    }
    if (description) {
      items.push(description);
    }
    return items;
  };

  const segmentNotEmpty = !isEmpty(data?.segment);
  const subSegmentNotEmpty = !isEmpty(data?.subSegment);
  const potentialNotEmpty = !isEmpty(data?.potential);
  const channelNotEmpty = !isEmpty(data?.channel);
  const subChannelCodeNotEmpty = !isEmpty(data?.subChannelCode);

  const showMorePrimaryValidation =
    segmentNotEmpty ||
    subSegmentNotEmpty ||
    potentialNotEmpty ||
    channelNotEmpty ||
    subChannelCodeNotEmpty;

  const showMoreSecondaryValidation =
    potentialNotEmpty || segmentNotEmpty || subSegmentNotEmpty;

  const renderInfoWithSalesStructureDisable = () => {
    return (
      <>
        <PocInfoContainer>
          <PocInfoItem
            titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_NAME"
            text={data?.salesRepresentative?.[0].name}
            testId="additional-information-name-item"
            dispatchEvent={dispatchEvent}
          />
        </PocInfoContainer>
        <PocInfoContainer>
          <PocInfoItem
            titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_PHONE"
            text={data?.salesRepresentative?.[0].phone}
            testId="additional-information-phone-item"
            dispatchEvent={dispatchEvent}
          />
        </PocInfoContainer>
        <PocInfoContainer>
          <PocInfoItem
            titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_EMAIL"
            text={data?.salesRepresentative?.[0].email}
            testId="additional-information-email-item"
            dispatchEvent={dispatchEvent}
          />
        </PocInfoContainer>
        <PocInfoContainer>
          <PocInfoItem
            titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_DDC"
            text={data?.deliveryCenterId}
            testId="additional-information-ddc-item"
            dispatchEvent={dispatchEvent}
          />
        </PocInfoContainer>
      </>
    );
  };

  return (
    <>
      {isDisplayAdditionalMetadataInfoEnabled
        ? showMorePrimaryValidation && (
            <CollapsibleContainer
              onClick={handleClickCollapse}
              title={t("PocInformationHeaderTemplate.SHOW_MORE")}
            >
              {!isSalesStructureEnabled ? (
                renderInfoWithSalesStructureDisable()
              ) : (
                <></>
              )}

              {segmentNotEmpty && (
                <PocInfoContainer>
                  <PocInfoItem
                    titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_SEGMENT"
                    items={getAdditionalInfoArray(
                      data?.segment,
                      data?.segmentName,
                    )}
                    testId="additional-information-segment-metadata-item"
                    dispatchEvent={dispatchEvent}
                  />
                </PocInfoContainer>
              )}
              {subSegmentNotEmpty && (
                <PocInfoContainer>
                  <PocInfoItem
                    titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_SUB_SEGMENT"
                    items={getAdditionalInfoArray(
                      data?.subSegment,
                      data?.subSegmentName,
                    )}
                    testId="additional-information-subsegment-item"
                    dispatchEvent={dispatchEvent}
                  />
                </PocInfoContainer>
              )}
              {potentialNotEmpty && (
                <PocInfoContainer>
                  <PocInfoItem
                    titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_POTENTIAL"
                    items={getAdditionalInfoArray(
                      data?.potential,
                      data?.potentialName,
                    )}
                    testId="additional-information-potential-item"
                    dispatchEvent={dispatchEvent}
                  />
                </PocInfoContainer>
              )}
              {channelNotEmpty && (
                <PocInfoContainer>
                  <PocInfoItem
                    titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_CHANNEL"
                    items={getAdditionalInfoArray(
                      data?.channel,
                      data?.channelName,
                    )}
                    testId="additional-information-channel-item"
                    dispatchEvent={dispatchEvent}
                  />
                </PocInfoContainer>
              )}
              {subChannelCodeNotEmpty && (
                <PocInfoContainer>
                  <PocInfoItem
                    titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_SUB_CHANNEL"
                    items={getAdditionalInfoArray(
                      data?.subChannelCode,
                      data?.subChannelName,
                    )}
                    testId="additional-information-subchannel-item"
                    dispatchEvent={dispatchEvent}
                  />
                </PocInfoContainer>
              )}
            </CollapsibleContainer>
          )
        : showMoreSecondaryValidation && (
            <CollapsibleContainer
              onClick={handleClickCollapse}
              title={t("PocInformationHeaderTemplate.ADDITIONAL_INFORMATION")}
            >
              {potentialNotEmpty && (
                <PocInfoContainer>
                  <PocInfoItem
                    titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_POTENTIAL"
                    text={data?.potential}
                    testId="additional-information-potential-item"
                    dispatchEvent={dispatchEvent}
                  />
                </PocInfoContainer>
              )}
              {!isSalesStructureEnabled ? (
                renderInfoWithSalesStructureDisable()
              ) : (
                <></>
              )}
              {segmentNotEmpty && (
                <PocInfoContainer>
                  <PocInfoItem
                    titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_SEGMENT"
                    text={data?.segment}
                    testId="additional-information-segment-item"
                    dispatchEvent={dispatchEvent}
                  />
                </PocInfoContainer>
              )}
              {subSegmentNotEmpty && (
                <PocInfoContainer>
                  <PocInfoItem
                    titleId="PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_SUB_SEGMENT"
                    text={data?.subSegment}
                    testId="additional-information-subsegment-item"
                    dispatchEvent={dispatchEvent}
                  />
                </PocInfoContainer>
              )}
            </CollapsibleContainer>
          )}
    </>
  );
};

export default React.memo(AdditionalInformationInfo);
