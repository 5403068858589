/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";

import { Box, Grid } from "@material-ui/core";
import classNames from "classnames";
import Lottie from "react-lottie";

import { getValueOrNull } from "@config/utils/functions";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_B2B_TRAINER,
  TLP_B2B_TRAINER_ADMIN,
  TLP_MISSIONS_LAYOUT,
  TLP_MISSIONS_LAYOUT_ADMIN,
  GROW_CHECKOUT_V3,
  GROW_SEARCH_CATALOG_V3,
} from "@config/featureToggles";
import UpdateCanceledError from "../../services/cart/domains/UpdateCanceledError";
import * as AgentCallEvents from "../../stores/agentCall/AgentCallEvents";
import {
  showErrorMessage,
  checkoutV2Effect,
  checkoutV3Effect,
} from "../../stores/checkout/CheckoutEvents";
import {
  b2bTrainerEmulatorEffect,
  b2bTrainerDeleteEmulatorEffect,
} from "../../stores/emulator/EmulatorEvents";
import {
  getCatalogEffect,
  getCatalogV3MultipleEffect,
} from "../../stores/catalog/CatalogEvents";
import { pocInformationFail } from "../../stores/callList/pocInformation/PocInformationEvents";
import AgentCallStore from "../../stores/agentCall/AgentCallStore";
import EmulatorStore from "../../stores/emulator/EmulatorStore";
import {
  ANALYTICS_REGISTRATION_ERROR_TYPE,
  ANALYTICS_ROUTE_NAMES,
  LINK_CALL,
  LOGIN_COUNTRY_B2B_TRAINNING,
  PASSWORD_COUNTRY_B2B_TRAINNING,
  CURRENCY,
  TAB_B2B_TRAINER_ROUTE,
  TAB_ORDERS_ROUTE,
} from "../../config/constants";
import GlobalStore from "../../stores/global/GlobalStore";
import Loading from "../../assets/lotties/loading.json";
import Button from "../../components/button/Button";
import EndSimulatorBtn from "../../assets/EndSimulatorBtn";
import { useAnalytics } from "../../analytics/useAnalytics";
import {
  b2BTrainerFailed,
  callUxButtonClicked,
  productQuantityEdited,
  productRemoved,
  productValidationMessageViewed,
  errorMessageViewed,
} from "../../config/typewriter";
import { CartInfo } from "../../domains/cart/CartInfo";
import CallTabStore from "../../stores/navigation/callTab/CallTabStore";
import { sendCustomerNotes } from "../../stores/customerNotes/CustomerNotesEvents";
import { simulateCartEffect } from "../../stores/cart/CartItemEvents";
import { useCollapsibleSideNavContext } from "../../contexts/CollapsibleSideNavContext";
import CustomToast, {
  TOAST_TYPES,
  toastTypes,
} from "../../components/CustomToast/CustomToast";
import BackDropLoad from "../../components/backDropLoad/BackDropLoad";
import CallInfo from "./components/callInfo/CallInfo";
import CallTabItems from "./components/callTabItems/CallTabItems";
import B2bLoginInformation from "./components/b2bLoginInformation/B2bLoginInformation";
import { useUpdateCartHook } from "./components/orderCart/hooks/useUpdateCartHook";
import CollapsibleSideNav from "./components/collapsibleSideNav/CollapsibleSideNav";
import Header from "./components/header/Header";
import CallMainPageStyles from "./CallMainPageStyles";

type ErrorPropsResponse = {
  response: {
    status: number;
  };
};

function CallMainPage(): React.ReactElement {
  const { t } = useTranslation();
  const { collapsibleIsOpen } = useCollapsibleSideNavContext();
  const classes = CallMainPageStyles({ collapsibleIsOpen });
  const { dispatchPocEvent, dispatchGenericEvent } = useAnalytics();

  const { user } = useStore(GlobalStore);
  const { callTab } = useStore(CallTabStore);
  const {
    clientId,
    callId,
    isLoading: agentCallLoading,
    isLoaded: agentCallLoaded,
  } = useStore(AgentCallStore);
  const { emulatorLink, publicKey, isLoading, isLoaded } =
    useStore(EmulatorStore);
  const { getCurrentUpdateEffect } = useUpdateCartHook();

  const [loadingEmulator, setLoadingEmulator] = useState(true);

  const b2bTrainerEnabled = isFeatureEnabled(
    TLP_B2B_TRAINER,
    TLP_B2B_TRAINER_ADMIN,
    user.keyToggle,
  );

  const isMissionsEnabled = isFeatureEnabled(
    TLP_MISSIONS_LAYOUT,
    TLP_MISSIONS_LAYOUT_ADMIN,
    user.keyToggle,
  );

  const isCheckoutV3Enable = isFeatureEnabledV2(
    GROW_CHECKOUT_V3,
    user.keyToggle,
  );

  const checkoutEffectVersion = isCheckoutV3Enable
    ? checkoutV3Effect
    : checkoutV2Effect;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const isSearchCatalogV3Enabled = isFeatureEnabledV2(
    GROW_SEARCH_CATALOG_V3,
    user.keyToggle,
  );

  const dispatchAnalyticsPocEvent = useCallback(
    (
      errorMessage: string,
      errorType: string,
      screenSection: string,
      screenName: string = getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
    ) => {
      dispatchPocEvent(
        errorMessageViewed,
        {
          error_message: errorMessage,
          error_type: errorType,
          screen_name: screenName || "",
          screen_section: screenSection,
          is_all_products: null,
          total_gap: null,
          total_vol_gap: null,
        },
        { time_of_day: true, is_resumed: true },
      );
    },
    [dispatchPocEvent],
  );

  const EMPTY_STRING = "";

  /* istanbul ignore next */
  const handleEventsProductRemovedOrQuantityEdited = (message: string) => {
    if (
      message.includes("removed") ||
      message.includes("retirados") ||
      message.includes("eliminados") ||
      message.includes("atualizamos") ||
      message.includes("actualizamos")
    ) {
      productRemoved({
        call_id: callId,
        country: user.zone,
        currency: CURRENCY[user.zone]?.currency,
        name: "Many",
        original_quantity: null,
        poc_id: clientId,
        quantity: null,
        sku: "Many",
        is_out_of_stock: true,
        combo_id: null,
        is_combo: false,
        remove_method: "Automatic upon Out of Stock Items",
      });
    }
    if (
      message.includes("updated") ||
      message.includes("atualizada") ||
      message.includes("actualizado") ||
      message.includes("atualizamos") ||
      message.includes("actualizamos")
    ) {
      productQuantityEdited({
        call_id: callId,
        country: user.zone,
        currency: CURRENCY[user.zone]?.currency,
        original_quantity: null,
        poc_id: clientId,
        quantity: null,
        screen_section: "Order Cart",
        is_out_of_stock: false,
        combo_id: null,
        is_combo: false,
        edit_method: "Automatic upon Low Stock Items",
      });
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    AgentCallEvents.showAgentCallSuccessMessage.watch((successMessage) => {
      CustomToast({
        type: TOAST_TYPES.SUCCESS,
        message: t(successMessage),
      });
    });
    const unWatchSendCustomerNotesDone = sendCustomerNotes.done.watch(() => {
      CustomToast({
        type: TOAST_TYPES.SUCCESS,
        message: t("CUSTOMER_PAGE.SUCCESS_UPDATE_NOTE"),
      });

      unWatchSendCustomerNotesDone();
    });

    const handleCartInfoToast = (cartInfo: CartInfo | null) => {
      cartInfo?.outputMessages?.forEach((cartMessage) => {
        let message = cartMessage.text;

        if (cartMessage.text.includes("Order amount")) {
          message = t("OrderTaking.MSG_ORDER_LIMIT");
        }

        if (cartMessage.subtype === "GROW_BFF_MODULE") {
          message = t(`CART_BFF_MESSAGES.${cartMessage.text}`);
        }

        CustomToast({
          type: toastTypes[cartMessage.type?.toLowerCase()],
          message,
        });

        dispatchGenericEvent(productValidationMessageViewed, {
          call_id: callId,
          poc_id: clientId,
          message_type: cartMessage.type,
          message_subtype: cartMessage.subtype,
          message,
        });

        if (cartMessage.subtype === "OUT_OF_STOCK_ADJUSTMENT") {
          handleEventsProductRemovedOrQuantityEdited(cartMessage.text);
        }
      });
    };

    const updateCartEffectVersion = getCurrentUpdateEffect();

    const unWatchUpdateCartDone = updateCartEffectVersion.done.watch(
      ({ result: cartInfo }) => {
        handleCartInfoToast(cartInfo);
      },
    );

    const unWatchSimulateCartDone = simulateCartEffect.done.watch(
      ({ result: cartInfo }) => {
        handleCartInfoToast(cartInfo);
      },
    );

    return () => {
      unWatchUpdateCartDone();
      unWatchSimulateCartDone();
    };
  }, [
    t,
    dispatchGenericEvent,
    clientId,
    callId,
    user.zone,
    handleEventsProductRemovedOrQuantityEdited,
  ]);

  const stopEmulator = () => {
    setLoadingEmulator(true);

    const deleteData = {
      publicKey,
      country: user.zone,
    };

    b2bTrainerDeleteEmulatorEffect(deleteData);

    dispatchGenericEvent(callUxButtonClicked, {
      button_label: "close-simulator",
      button_name: "close-simulator",
      filter_option: null,
      screen_name: getValueOrNull(
        ANALYTICS_ROUTE_NAMES.get(TAB_B2B_TRAINER_ROUTE),
      ),
      screen_section: "B2B trainer simulator",
      call_id: callId,
      order_date: null,
      position: null,
    });
  };

  // Error handlings
  /* istanbul ignore next */
  useEffect(() => {
    const unUpdateAgentCallFail = AgentCallEvents.updateAgentCallFail.watch(
      (message) => {
        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: t(message.message),
          errorCode: message?.error?.config?.headers?.requestTraceId,
          InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
        });

        dispatchAnalyticsPocEvent(
          t(message.message),
          ANALYTICS_REGISTRATION_ERROR_TYPE,
          "Gap and Status Header",
        );
      },
    );

    const unWatchPocInformationFail = pocInformationFail.watch(() => {
      CustomToast({
        type: TOAST_TYPES.ERROR,
        message: t("NextPoc.AGENT_CALL_SERVICE_UNAVAILABLE"),
      });

      dispatchAnalyticsPocEvent(
        t("NextPoc.AGENT_CALL_SERVICE_UNAVAILABLE"),
        "poc information error",
        "Gap and Status Header",
      );
    });

    showErrorMessage.watch((messages) => {
      messages.forEach((toastInfo) => {
        CustomToast({
          type: toastTypes[toastInfo.type.toLowerCase()],
          message: toastInfo.message,
        });

        if (toastInfo.type.toLowerCase() === "error") {
          dispatchAnalyticsPocEvent(
            toastInfo.message,
            "poc don't have credit enough to place the order",
            "Order Cart",
            ANALYTICS_ROUTE_NAMES.get(LINK_CALL),
          );
        }
      });
    });

    const watchWhenFail = ({ error }) => {
      const status500 = error?.response?.status === 502;
      CustomToast({
        type: TOAST_TYPES.ERROR,
        message: status500
          ? t("ServerError.ERROR_MESSAGE")
          : t("OrderTaking.MSG_ORDER_ERROR"),
        errorCode: error?.config?.headers?.requestTraceId,
        InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
      });

      dispatchAnalyticsPocEvent(
        status500
          ? t("ServerError.ERROR_MESSAGE")
          : t("OrderTaking.MSG_ORDER_ERROR"),
        "checkout error",
        "Order Cart",
      );
    };

    const getCatalogEffectVersion = isSearchCatalogV3Enabled
      ? getCatalogV3MultipleEffect
      : getCatalogEffect;

    const unWatchCheckoutEffectFail =
      checkoutEffectVersion.fail.watch(watchWhenFail);

    const unWatchGetCatalogFail = getCatalogEffectVersion.fail.watch(
      ({ error }) => {
        const status500 =
          (error as unknown as ErrorPropsResponse)?.response?.status === 502;
        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: status500
            ? t("ServerError.ERROR_MESSAGE")
            : t("OrderTaking.MSG_CATALOG_AND_CART_ERROR"),
          errorCode: error?.config?.headers?.requestTraceId,
          InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
        });

        dispatchAnalyticsPocEvent(
          status500
            ? t("ServerError.ERROR_MESSAGE")
            : t("OrderTaking.MSG_CATALOG_AND_CART_ERROR"),
          "get catalog error",
          "Product Search Result",
        );
      },
    );

    const handleCartErrorToastInfo = (errorWrapper: { error: Error }) => {
      if (errorWrapper.error instanceof UpdateCanceledError) {
        return;
      }
      CustomToast({
        type: TOAST_TYPES.ERROR,
        message: t("OrderTaking.MSG_CATALOG_AND_CART_ERROR"),
        errorCode: errorWrapper.error?.config?.headers?.requestTraceId,
        InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
      });

      dispatchAnalyticsPocEvent(
        t("OrderTaking.MSG_CATALOG_AND_CART_ERROR"),
        "update cart error",
        "Order Cart",
      );
    };

    const updateCartEffectVersion = getCurrentUpdateEffect();
    const unWatchUpdateCartFail = updateCartEffectVersion.fail.watch(
      (errorWrapper: { error: Error }) =>
        handleCartErrorToastInfo(errorWrapper),
    );

    const unWatchSimulateCartFail = simulateCartEffect.fail.watch(
      (errorWrapper: { error: Error }) =>
        handleCartErrorToastInfo(errorWrapper),
    );

    const unWatchB2bTrainerEmulatorFail = b2bTrainerEmulatorEffect.fail.watch(
      ({ error }) => {
        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: t("B2BTrainer.ERROR_B2B_LOADING"),
          errorCode: error?.config?.headers?.requestTraceId,
          InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
        });

        dispatchAnalyticsPocEvent(
          t("B2BTrainer.ERROR_B2B_LOADING"),
          "emulator error",
          "B2B Trainer",
        );

        dispatchPocEvent(
          b2BTrainerFailed,
          {
            error_type: "emulator error",
            error_message: t("B2BTrainer.ERROR_B2B_LOADING"),
            button_label: "start-emulator",
          },
          {
            removeProps: [
              "call_trigger",
              "DDC",
              "poc_segment",
              "poc_subsegment",
              "total_gap",
              "total_vol_gap",
              "currency",
              "credit_available",
              "poc_potential",
              "marketing_tag",
            ],
          },
        );
      },
    );
    const unWatchSendCustomerNotesFail = sendCustomerNotes.fail.watch(
      (response) => {
        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: t("CUSTOMER_PAGE.ERROR_UPDATE_NOTE"),
          errorCode: response?.error?.config?.headers?.requestTraceId,
          InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
        });

        dispatchAnalyticsPocEvent(
          "Something went wrong, the note has not been updated!",
          "note saving error",
          response.params.isFloatingComponent
            ? "Floating Note Component"
            : "Customer Note",
        );
      },
    );
    const unWatchB2bTrainerDeleteEmulatorFail =
      b2bTrainerDeleteEmulatorEffect.fail.watch(({ error }) => {
        setLoadingEmulator(false);
        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: t("B2BTrainer.ERROR_B2B_CLOSING"),
          errorCode: error?.config?.headers?.requestTraceId,
          InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
        });

        dispatchAnalyticsPocEvent(
          t("B2BTrainer.ERROR_B2B_CLOSING"),
          "emulator error",
          "B2B Trainer simulator",
        );
      });

    return () => {
      unWatchCheckoutEffectFail();
      unWatchGetCatalogFail();
      unWatchUpdateCartFail();
      unWatchSimulateCartFail();
      unWatchB2bTrainerEmulatorFail();
      unWatchB2bTrainerDeleteEmulatorFail();
      unWatchPocInformationFail();
      unWatchSendCustomerNotesFail();
      unUpdateAgentCallFail();
    };
  }, [callTab, dispatchPocEvent, t]);

  const isMissionLayoutAndGapOrder = () =>
    isMissionsEnabled && callTab === TAB_ORDERS_ROUTE;

  const renderCallInfo = () => {
    return (isMissionLayoutAndGapOrder() || !isMissionsEnabled) && <CallInfo />;
  };

  if (agentCallLoading || !agentCallLoaded) {
    return <BackDropLoad isLoading />;
  }

  return (
    <>
      {clientId && (
        <Box className={classes.mainContainer}>
          <CollapsibleSideNav />
          <Grid
            container
            style={{
              padding: isMissionsEnabled ? "0px" : "0px 24px",
            }}
            direction="column"
            className={classes.mainContent}
            data-testid="grid-container"
          >
            <div
              style={{ height: "34px", width: "30%", justifySelf: "center" }}
            />
            <Grid
              item
              className={classNames({
                [classes.callInfoHidden]: callTab === TAB_B2B_TRAINER_ROUTE,
                [classes.newLayout]:
                  /* istanbul ignore next */
                  callTab === TAB_ORDERS_ROUTE && isMissionsEnabled,
              })}
            >
              {renderCallInfo()}
            </Grid>
            {b2bTrainerEnabled && (isLoading || isLoaded) && (
              <Grid
                item
                className={classNames({
                  [classes.iFrameHidden]: callTab !== TAB_B2B_TRAINER_ROUTE,
                  [classes.iFrameContainer]: callTab === TAB_B2B_TRAINER_ROUTE,
                })}
              >
                {loadingEmulator && (
                  <div
                    className={classes.loadingIcon}
                    data-testid="emulator-loading"
                  >
                    <Lottie options={defaultOptions} height={100} width={100} />
                  </div>
                )}
                {emulatorLink && (
                  <>
                    <iframe
                      onLoad={() => setLoadingEmulator(false)}
                      scrolling="no"
                      className={classNames({
                        [classes.iframeHidden]: loadingEmulator,
                        [classes.iframe]: !loadingEmulator,
                      })}
                      height="600px"
                      width="300px"
                      src={emulatorLink}
                      title="B2BTrainer emulator"
                    />

                    <Button
                      className={classNames({
                        [classes.stopSimaltorHidden]: loadingEmulator,
                        [classes.stopSimulator]: !loadingEmulator,
                      })}
                      onClick={() => stopEmulator()}
                      data-testid="stop-emulator-btn"
                    >
                      <EndSimulatorBtn />
                      <span className={classes.tooltiptext}>
                        {t("B2BTrainer.B2B_STOP")}
                      </span>
                    </Button>
                    <Grid className={classes.gridB2bLoginInformation}>
                      <B2bLoginInformation
                        login={
                          LOGIN_COUNTRY_B2B_TRAINNING.get(user.zone) ??
                          EMPTY_STRING
                        }
                        password={
                          PASSWORD_COUNTRY_B2B_TRAINNING.get(user.zone) ??
                          EMPTY_STRING
                        }
                        data-testid="b2b-login-information"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            <Grid item container direction="row">
              {!isMissionsEnabled && (
                <Grid
                  item
                  className={classNames({
                    [classes.callInfoHidden]: callTab === TAB_B2B_TRAINER_ROUTE,
                  })}
                >
                  <Header />
                </Grid>
              )}

              <Grid
                item
                className={classes.container}
                style={{
                  padding: isMissionsEnabled ? "0px 25px" : "0px",
                }}
              >
                <CallTabItems />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default CallMainPage;
