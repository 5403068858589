import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import CreditResponseToPocInformationCreditInfoConverter from "../../converters/creditPocInformation/CreditResponseToPocInformationCreditInfoConverter";
import axios from "../../config/axios/axiosInstance";
import { getApiHost, getEndPoint } from "../host/HostB2BService";
import { CreditResponse } from "../pocInformation/domains/CreditResponse";
import { BeesContractsResponse } from "./domains/BeesContractsResponse";

const ACCOUNT_METADATA_ENDPOINT = "/account-business/v2/credits/search";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1000;
  },
  retryCondition: (error) => {
    if (error) {
      return error.response?.status === 400 || error.response?.status === 401;
    }

    return false;
  },
});

export const creditPocInformation = (
  accountId: string,
  vendorId: string,
): Promise<CreditResponse> => {
  const log = useLogService();

  const endpoint = `${ACCOUNT_METADATA_ENDPOINT}`;

  return axios
    .post<BeesContractsResponse>(`${getApiHost()}${getEndPoint()}${endpoint}`, {
      accountId,
      vendorIds: [vendorId],
    })
    .then((response) => {
      log.info(
        `Get accounts by accountid where received from ${endpoint} to our model`,
      );

      return CreditResponseToPocInformationCreditInfoConverter(response.data);
    })
    .catch((error) => {
      log.error("CreditPocInformationService: ", error);
      throw error;
    });
};
