import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    card: {
      width: "100%",
      height: "106px",
      display: "flex",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
    },
    image: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "9px",
      height: "100%",
      padding: 8,
      "& img": {
        width: 80,
        height: "100%",
      },
    },
    title: {
      fontSize: "12px",
      color: "#505050",
      fontWeight: "bold",
      height: "28px",
    },
    contentContainer: {
      paddingLeft: "5px",
      paddingBottom: "15px !important",
    },
  }),
);
