import React from "react";
import { useTranslation } from "react-i18next";
import { Box, ButtonBase } from "@material-ui/core";
import ErrorRetryIcon from "../../assets/ErrorRetryIcon";
import ErrorRetryStyles from "./style";
import {
  errorMessageViewed,
  retryButtonClicked,
} from "../../config/typewriter";
import { useAnalytics } from "../../analytics/useAnalytics";

interface Props {
  onClick: () => void;
  testId?: string;
  screenName: string | undefined;
  screenSection: string;
  errorMessage?: string;
  errorType?: string;
  message?: string;
  size?: string;
}

const ErrorRetry: React.FunctionComponent<Props> = ({
  onClick,
  testId,
  screenName,
  screenSection,
  errorMessage,
  errorType,
  message,
  size,
}) => {
  const { t } = useTranslation();
  const classes = ErrorRetryStyles();
  const { dispatchPocEvent, dispatchGenericEvent } = useAnalytics();

  const clickAction = () => {
    dispatchGenericEvent(retryButtonClicked, {
      screen_name: screenName,
      screen_section: screenSection,
      error_message: errorMessage || "Information not found",
      error_type: errorType || "Information not found",
    });
    onClick();
  };

  React.useEffect(() => {
    dispatchPocEvent(
      errorMessageViewed,
      {
        error_message: errorMessage || "Information not found",
        error_type: errorType || "Information not found",
        screen_name: screenName,
        screen_section: screenSection,
        is_all_products: null,
      },
      { time_of_day: true, is_resumed: true },
    );
  }, [dispatchPocEvent, errorMessage, errorType, screenName, screenSection]);

  return (
    <Box data-testid={`${testId}-error-retry`}>
      <div className={classes.title}>
        {t("ErrorHandlerRetry.INFORMATION_NOT_FOUND")}
      </div>
      <ButtonBase
        onClick={clickAction}
        data-testid={`${testId}-error-retry-button`}
        className={classes.reloadContent}
      >
        <span className={classes.reloadTitle}>
          {message || t("ErrorRetry.RETRY_BUTTON_LABEL")}
        </span>
        <ErrorRetryIcon size={size} />
      </ButtonBase>
    </Box>
  );
};

ErrorRetry.defaultProps = {
  testId: "",
};

export default ErrorRetry;
