import { makeStyles, createStyles } from "@material-ui/core";

const UpdateCardButtonStyles = makeStyles(({ palette }) =>
  createStyles({
    icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "30px",
    },
    updateButton: {
      width: "120px",
      paddingRight: "0px",
    },
    updateButtonText: {
      fontSize: "12px",
      fontWeight: 600,
      color: palette.text.primary,
      fontFamily: "Barlow",
      textTransform: "none",
    },
  }),
);
export default UpdateCardButtonStyles;
