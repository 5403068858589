import React from "react";

function Sponsored() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <defs>
        <circle
          id="path-1-sponsored-icon"
          cx="21.094"
          cy="21.094"
          r="21.094"
        ></circle>
        <linearGradient
          id="linearGradient-3-sponsored-icon"
          x1="50%"
          x2="50%"
          y1="0%"
          y2="174.916%"
        >
          <stop offset="0%" stopColor="#9CFFFD"></stop>
          <stop offset="100%" stopColor="#7CE5E3"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-167 -530)">
          <g transform="translate(112 152)">
            <g transform="translate(24 76)">
              <g transform="translate(0 74)">
                <g transform="translate(0 206)">
                  <g transform="translate(16 16)">
                    <g transform="translate(15.406 6.406)">
                      <g>
                        <mask id="mask-2-sponsored-icon" fill="#fff">
                          <use xlinkHref="#path-1-sponsored-icon"></use>
                        </mask>
                        <g
                          fill="url(#linearGradient-3-sponsored-icon)"
                          mask="url(#mask-2-sponsored-icon)"
                        >
                          <path d="M0 0H42.188V42.188H0z"></path>
                        </g>
                        <g
                          fillRule="nonzero"
                          mask="url(#mask-2-sponsored-icon)"
                        >
                          <g transform="translate(6.328 7.734)">
                            <path
                              fill="#384F63"
                              d="M25.34 0h-2.796v46.406h2.795a4.197 4.197 0 004.192-4.202V4.202A4.197 4.197 0 0025.34 0z"
                            ></path>
                            <path
                              fill="#506480"
                              d="M22.544 0H4.192A4.197 4.197 0 000 4.202v38.002a4.197 4.197 0 004.192 4.202h18.352a4.197 4.197 0 004.192-4.202l-7.778-19 7.778-19.002A4.197 4.197 0 0022.544 0z"
                            ></path>
                            <path
                              fill="#FABF1E"
                              d="M22.544 43.605h2.795c.772 0 1.397-.627 1.397-1.4V4.201c0-.774-.625-1.4-1.397-1.4h-2.795v40.803z"
                            ></path>
                            <path
                              fill="#FFD754"
                              d="M19.876 2.801c-.742 0-1.452.295-1.977.82l-1.157 1.16a2.791 2.791 0 01-3.95 0c-.002 0-.617-.615-1.16-1.16a2.792 2.792 0 00-1.976-.82H4.192c-.772 0-1.397.627-1.397 1.4v38.003c0 .774.625 1.401 1.397 1.401h18.352c.772 0 1.398-.627 1.398-1.4V4.201c0-.774-.626-1.4-1.398-1.4h-2.668z"
                            ></path>
                            <ellipse
                              cx="14.766"
                              cy="2.801"
                              fill="#384F63"
                              rx="1"
                              ry="1"
                            ></ellipse>
                          </g>
                        </g>
                      </g>
                      <text
                        fill="#E58F00"
                        fontFamily="Barlow-SemiBold, Barlow"
                        fontSize="24"
                        fontWeight="500"
                      >
                        <tspan x="14.29" y="33.313">
                          $
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Sponsored;
