/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable func-names */
/* eslint-disable no-multi-assign */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-expressions */
function loadAnalyticsSnipped() {
  // Analytics snipped here
  !(function () {
    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error("Segment snippet included twice.");
      else {
        analytics.invoked = !0;
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageview",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "once",
          "off",
          "on",
          "addSourceMiddleware",
          "addIntegrationMiddleware",
          "setAnonymousId",
          "addDestinationMiddleware",
        ];
        analytics.factory = function (e) {
          return function () {
            const t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (let e = 0; e < analytics.methods.length; e++) {
          const t = analytics.methods[e];
          analytics[t] = analytics.factory(t);
        }
        analytics.load = function (e, t) {
          const n = document.createElement("script");
          n.type = "text/javascript";
          n.async = !0;
          n.src = `https://cdn.segment.com/analytics.js/v1/${e}/analytics.min.js`;
          const a = document.getElementsByTagName("script")[0];
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = t;
        };
        analytics.SNIPPET_VERSION = "4.13.2";
      }
  })();
}

export { loadAnalyticsSnipped };
