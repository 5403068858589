import React, { useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import SponsoredCard from "../sponsoredCard/SponsoredCard";
import SponsoredCardSkeleton from "../sponsoredCard/SponsoredCardSkeleton";
import SponsoredStore from "../../../../stores/sponsored/SponsoredStore";
import PromotionsStore from "../../../../stores/promotions/PromotionsStore";
import SponsoredComponentSkeleton from "./SponsoredComponentSkeleton";
import { getSponsoredProductsEvent } from "../../../../stores/sponsored/SponsoredEvents";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/v2/ErrorHandlerRetry";
import SponsoredComponentStyles from "./SponsoredComponent.styles";
import GlobalStore from "../../../../stores/global/GlobalStore";
import pocInformationStore from "../../../../stores/callList/pocInformation/PocInformationStore";
import {
  isFeatureEnabledV2,
  GROW_LOCAL_HEADER_REDESIGN,
  GROW_ADMIN_LOCAL_MISSIONS_STEP2,
} from "../../../../config/featureToggles";
import { SponsoredMission } from "../../../../domains/sponsored/SponsoredMission";
import useGlobalAndLocalComponent from "./hooks/useGlobalAndLocalComponent";
import CollapseTitleGlobalAndLocal from "./components/CollapseTitleGlobalAndLocal";
import { useWindowResize } from "../../../../hooks/useWindowResize";

const SIZE_LIMIT_ITEMS = 20;

const SponsoredComponent: React.FC = () => {
  const windowWidth = useWindowResize(window?.innerWidth);
  const classes = SponsoredComponentStyles();
  const {
    sponsoredMissions,
    isSponsoredMissionsLoading,
    isSponsoredProductsLoading,
    sponsoredMissionsError,
    sponsoredProductsError,
    sponsoredMissionsIds,
    done: sponsoredMissionsDone,
  } = useStore(SponsoredStore);
  const { done: promotionsDone, error: promotionError } =
    useStore(PromotionsStore);
  const { user } = useStore(GlobalStore);
  const { pocInformation } = useStore(pocInformationStore);
  const {
    handleGetSponsoredMissionsRetry,
    handleTryAgain,
    sponsoredItemsOutOfSync,
    handleEventErrorMessageViewed,
    dispatchEventLocalLoad,
    dispatchEventsponsoredProductsLoad,
  } = useGlobalAndLocalComponent();

  const isHeaderRedesign = isFeatureEnabledV2(
    GROW_LOCAL_HEADER_REDESIGN,
    user.keyToggle,
  );

  const isLocalMissionsStep2Enabled = isFeatureEnabledV2(
    GROW_ADMIN_LOCAL_MISSIONS_STEP2,
    user.keyToggle,
  );
  const [openSponsoredMissionIndex, setOpenSponsoredMissionIndex] =
    useState<number>(0);
  const { t } = useTranslation();

  const promotionIsLoading = !promotionsDone && !promotionError;

  const loadMissions = useCallback(() => {
    const sponsoredMission = sponsoredMissions[openSponsoredMissionIndex];

    if (
      sponsoredItemsOutOfSync(openSponsoredMissionIndex, sponsoredMissions) &&
      sponsoredMissionsDone &&
      !sponsoredProductsError
    ) {
      getSponsoredProductsEvent({
        sponsoredId: sponsoredMission.id,
        items: sponsoredMission.items,
        combos: sponsoredMission.combos,
      });
    }
  }, [
    openSponsoredMissionIndex,
    sponsoredItemsOutOfSync,
    sponsoredMissions,
    sponsoredMissionsDone,
    sponsoredProductsError,
  ]);

  useEffect(() => {
    loadMissions();
  }, [loadMissions]);

  const isSponsoredMissionDetailOk =
    sponsoredItemsOutOfSync(openSponsoredMissionIndex, sponsoredMissions) ===
      false &&
    promotionsDone &&
    sponsoredMissionsDone;

  useEffect(() => {
    /* istanbul ignore else */
    handleEventErrorMessageViewed();
  }, [handleEventErrorMessageViewed]);

  const renderCollapseChildren = (
    mission: SponsoredMission,
    isRedesign: boolean,
  ) => {
    let sponsoredMissionProducts = [...(mission.products || [])];
    if (
      isLocalMissionsStep2Enabled &&
      mission.effectiveness?.type === "SPECIFIED_QUANTITY"
    ) {
      sponsoredMissionProducts = sponsoredMissionProducts.filter(
        /* istanbul ignore next */
        (missionProduct) =>
          missionProduct.suggestedQuantity &&
          missionProduct.suggestedQuantity > 0,
      );
    }

    return (isSponsoredProductsLoading || promotionIsLoading) &&
      !isSponsoredMissionDetailOk ? (
      <SponsoredCardSkeleton isRedesign={isHeaderRedesign} />
    ) : (
      <SponsoredCard
        mission={mission}
        products={sponsoredMissionProducts}
        hasError={sponsoredMissionsError}
        tryAgain={() => handleTryAgain(openSponsoredMissionIndex, mission)}
        pocInformation={pocInformation}
        isRedesign={isRedesign}
        isSponsored
        windowWidth={windowWidth}
      />
    );
  };

  return (
    <>
      {!isSponsoredMissionsLoading && sponsoredMissionsError && (
        <ErrorHandlerRetry
          onClick={() => handleGetSponsoredMissionsRetry(sponsoredMissionsIds)}
          description={t(
            "Sponsored.SPONSORED_MISSIONS_REQUEST_FAILURE_MESSAGE",
          )}
          className={isHeaderRedesign ? classes.containerError : ""}
        />
      )}
      {isSponsoredMissionsLoading ? (
        <SponsoredComponentSkeleton />
      ) : (
        sponsoredMissions?.slice(0, SIZE_LIMIT_ITEMS).map((mission, index) => {
          dispatchEventLocalLoad(mission, true);
          dispatchEventsponsoredProductsLoad(mission);
          return (
            <CollapseTitleGlobalAndLocal
              mission={mission}
              index={index}
              openSponsoredMissionIndex={openSponsoredMissionIndex}
              setOpen={setOpenSponsoredMissionIndex}
              key={`collapse-card-${mission.id}`}
            >
              {renderCollapseChildren(mission, isHeaderRedesign)}
            </CollapseTitleGlobalAndLocal>
          );
        })
      )}
    </>
  );
};

export default React.memo(SponsoredComponent);
