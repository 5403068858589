import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      borderRadius: "10px",
      width: "100%",
      margin: "0 86px 11px 0",
      alignSelf: "center",
      fontFamily: "Barlow",
    },
    title: {
      marginLeft: 5,
      fontWeight: 600,
      fontSize: "20px",
      color: palette.secondary.main,
      opacity: 1,
      width: "300px",
      fontFamily: "Barlow",
    },
    loading: {
      position: "unset",
      width: "40px",
      height: "30px",
    },
    containerSkeleton: {
      textAlign: "center",
      border: "0px solid rgba(0, 0, 0, 0.16)",
      backgroundColor: "transparent",
    },
    gridSkeleton: {
      flex: 1,
      flexDirection: "row",
      display: "flex",
      marginTop: 16,
    },
    blocoSkeleton: {
      marginLeft: 8,
    },
  }),
);
