import React, { useEffect, useRef } from "react";
import { Box, FormControl, Grid } from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import { useStore } from "effector-react";
import {
  isFeatureEnabledV2,
  TLP_CALL_SUMMARY_REMOVE_FIELDS,
} from "../../../../../../../config/featureToggles";
import GlobalStore from "../../../../../../../stores/global/GlobalStore";
import Select from "../../../../../../../components/select/Select";
import { Mission, OptionsMission } from "../../../../../../../domains/Mission";
import MissionsStyles from "../../MissionsStyles";

interface TargetValue {
  name?: string;
  value: string;
}

interface Props {
  handleChange: (
    e: React.ChangeEvent<TargetValue>,
    tag: string,
    usage: string,
  ) => void;
  values: Mission;
  errors?: FormikErrors<Mission>;
  touched?: FormikTouched<Mission>;
  showClassification?: boolean;
  showGapReason?: boolean;
  disableClassification?: boolean;
  disableGapReason?: boolean;
  classification?: Array<OptionsMission>;
  gapReason?: OptionsMission[];
  isClassificationEmpty?: boolean;
  isGapEmpty?: boolean;
}

const GenericMissions: React.FunctionComponent<Props> = ({
  values,
  errors,
  handleChange,
  touched,
  showClassification = true,
  showGapReason = true,
  disableClassification,
  disableGapReason,
  classification = [],
  gapReason = [],
  isClassificationEmpty,
  isGapEmpty,
}) => {
  const classes = MissionsStyles();
  const { tag, effectiveness } = values;
  const {
    user: { zone },
  } = useStore(GlobalStore);

  const prevEffectiveness = useRef<boolean | undefined>();

  const isCallSummaryRemoveFieldsEnabled = isFeatureEnabledV2(
    TLP_CALL_SUMMARY_REMOVE_FIELDS,
    zone,
  );

  const isEffectiveness = () => {
    if (values.effectiveness) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    prevEffectiveness.current = values.effectiveness;
  });

  const effectivenessPreviusValue =
    prevEffectiveness.current ?? values.effectiveness;

  useEffect(() => {
    if (effectivenessPreviusValue !== effectiveness) {
      values.reason = "";
      values.typification = "";
    }
  }, [effectivenessPreviusValue, effectiveness, values]);

  const renderClassification = () => {
    const shouldRenderClassification =
      isEffectiveness() &&
      !isCallSummaryRemoveFieldsEnabled &&
      showClassification;

    return (
      shouldRenderClassification && (
        <Box
          className={classes.select}
          key={`mission-box-classification-${values.tag}`}
        >
          <FormControl
            key={`control-classification-${values.tag}`}
            error={!!errors?.typification && touched?.typification}
            data-testid="missions-classifications"
          >
            <Select
              key={`classification-${values.tag}`}
              name="classification"
              value={values.typification}
              options={classification}
              onChange={(event) =>
                handleChange(
                  event as React.ChangeEvent<TargetValue>,
                  tag,
                  "classification",
                )
              }
              error={errors?.typification}
              touched={touched?.typification}
              testId="missions-classifications-select"
              disabled={disableClassification}
              ellipsis
              isEmpty={isClassificationEmpty}
            />
          </FormControl>
        </Box>
      )
    );
  };

  const renderReasons = () => {
    const shouldRenderReasons =
      (!isEffectiveness() && showGapReason) ||
      (!isCallSummaryRemoveFieldsEnabled && showGapReason);

    return (
      shouldRenderReasons && (
        <Box className={classes.select} key={`mission-box-gap-${values.tag}`}>
          <FormControl
            key={`control-gapreason-${values.tag}`}
            error={!!errors?.reason && touched?.reason}
            data-testid="missions-gapReason"
          >
            <Select
              key={`gap-reason-${values.tag}`}
              name="reason"
              value={values.reason}
              options={gapReason}
              onChange={(event) =>
                handleChange(
                  event as React.ChangeEvent<TargetValue>,
                  tag,
                  "gapreason",
                )
              }
              error={errors?.reason}
              touched={touched?.reason}
              testId="missions-gapReason-select"
              disabled={disableGapReason}
              ellipsis
              isEmpty={isGapEmpty}
            />
          </FormControl>
        </Box>
      )
    );
  };

  return (
    <Grid key={`is-topline-typography-${values.tag}`}>
      {renderClassification()}
      {renderReasons()}
    </Grid>
  );
};

export default GenericMissions;
