import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Button from "../../../../../../components/button/Button";
import ClassificationButtonStyles from "./ClassificationButton.styles";
import { classificationTypes } from "./ClassificationTypeEnum";
import InactivateConfirmationModal from "./components/InactivateConfirmationModal/InactivateConfirmationModal";

interface Props {
  classificationType: classificationTypes;
  phoneNumber: string;
  handleOnClick: (classification: classificationTypes) => void;
}

function ClassificationButton({
  classificationType,
  handleOnClick,
  phoneNumber,
}: Props): JSX.Element {
  const classes = ClassificationButtonStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const label = {
    WRONG: t("PHONE_RANKING.WRONG"),
    INACTIVATE: t("PHONE_RANKING.INACTIVATE"),
    ACTIVATE: t("PHONE_RANKING.ACTIVATE"),
    NO_ANSWER: t("PHONE_RANKING.NO_ANSWER"),
    GOOD: t("PHONE_RANKING.SUCCESS"),
  };

  const classificationIsInactivate =
    classificationType === classificationTypes.INACTIVATE;

  /* istanbul ignore next */
  const handleOpenModal = () => {
    setIsOpen(true);
  };

  /* istanbul ignore next */
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const renderContent = () => {
    if (classificationIsInactivate) {
      return renderInactivateConfirmationModal();
    }
    return renderClassificationButton(classificationType);
  };

  const renderInactivateConfirmationModal = () => {
    return (
      <>
        <Button
          className={`${classes.classificationButton} ${classes.inactiveButton}`}
          data-testId={`${classificationTypes.INACTIVATE}-classification-button`}
          variant="outlined"
          onClick={() => handleOpenModal()}
        >
          <span
            className={`${classes.classificationButtonLabel} ${classes.inactiveButtonLabel}`}
          >
            {label[classificationTypes.INACTIVATE]}
          </span>
        </Button>
        <InactivateConfirmationModal
          isOpen={isOpen}
          onClose={handleCloseModal}
          handleInactivateButtonClick={handleOnClick}
          phoneNumber={phoneNumber}
        />
      </>
    );
  };

  const renderClassificationButton = (
    classificationType: classificationTypes,
  ) => {
    return (
      <Button
        className={classNames(classes.classificationButton, {
          [classes.wrongButton]: classificationType === "WRONG",
          [classes.noAnswerButton]: classificationType === "NO_ANSWER",
          [classes.successButton]: classificationType === "GOOD",
          [classes.activateButton]: classificationType === "ACTIVATE",
        })}
        data-testId={`${classificationType}-classification-button`}
        variant="outlined"
        onClick={() => handleOnClick(classificationType)}
      >
        <span
          className={classNames(classes.classificationButtonLabel, {
            [classes.wrongButtonLabel]: classificationType === "WRONG",
            [classes.noAnswerButtonLabel]: classificationType === "NO_ANSWER",
            [classes.successButtonLabel]: classificationType === "GOOD",
            [classes.activateButtonLabel]: classificationType === "ACTIVATE",
          })}
        >
          {label[classificationType]}
        </span>
      </Button>
    );
  };

  return renderContent();
}

export default ClassificationButton;
