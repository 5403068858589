import React from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Link } from "@material-ui/core";
import AppSimulatorStyles from "./AppSimulatorStyles";
import BEESCellphoneIcon from "../../../../assets/BEESCellphoneIcon";
import Button from "../../../../components/button/Button";
import { b2bTrainerEmulatorEffect } from "../../../../stores/emulator/EmulatorEvents";
import GlobalStore from "../../../../stores/global/GlobalStore";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import { b2BTrainerStarted } from "../../../../config/typewriter";

import { WEB_VERSION_LINKS, WB_VERSION } from "../../../../config/constants";

const AppSimulator: React.FunctionComponent = () => {
  const classes = AppSimulatorStyles();
  const { t } = useTranslation();
  const { user } = useStore(GlobalStore);
  const { dispatchPocEvent } = useAnalytics();

  const sendEvent = (buttonLabel: string) => {
    dispatchPocEvent(
      b2BTrainerStarted,
      { button_label: buttonLabel },
      {
        removeProps: [
          "call_trigger",
          "DDC",
          "poc_segment",
          "poc_subsegment",
          "total_gap",
          "total_vol_gap",
          "currency",
          "credit_available",
          "poc_potential",
          "marketing_tag",
        ],
      },
    );
  };

  const actionClick = () => {
    const createData = {
      username: user.login,
      country: user.zone,
    };

    b2bTrainerEmulatorEffect(createData);

    /* istanbul ignore next */
    const unWatchb2bTrainerEmulatorEffect = b2bTrainerEmulatorEffect.done.watch(
      () => {
        // ANALITYCS CALLENDED B2BTRAINER
        localStorage.setItem("createEmulator", "createByClick");
        sendEvent("start-emulator");
        unWatchb2bTrainerEmulatorEffect();
      },
    );
  };

  const getWebVersionLink = () => {
    const isDevelopmentEnviroment = process.env.NODE_ENV === "development";

    if (isDevelopmentEnviroment) {
      const devVersion = WB_VERSION.get(`${user.zone}_UAT`) as string;
      return WEB_VERSION_LINKS.get(devVersion);
    }

    const prodVersion = WB_VERSION.get(`${user.zone}_PROD`) as string;
    return WEB_VERSION_LINKS.get(prodVersion);
  };

  return (
    <Box className={classes.appSimulatorBox}>
      <Typography className={classes.simulatorTitle}>
        {t("B2BTrainer.B2B_LABEL")}
      </Typography>
      <BEESCellphoneIcon />
      <Button
        data-testid="start-emulator-button"
        className={classes.startSimulatorButton}
        onClick={() => actionClick()}
      >
        {t("B2BTrainer.B2B_START")}
      </Button>
      <Typography className={classes.webVersionText}>
        {t("B2BTrainer.B2B_OR_USE")}
        <Link
          onClick={() => sendEvent("web-version")}
          href={getWebVersionLink()}
          className={classes.webVersionLink}
          target="_blank"
        >
          {t("B2BTrainer.B2B_WV")}
        </Link>
      </Typography>
    </Box>
  );
};

export default AppSimulator;
