import React from "react";
import { useTranslation } from "react-i18next";
import { Box, ButtonBase } from "@material-ui/core";
import RetryIcon from "../../assets/RetryIcon";
import ErrorHandlerRetryStyles from "./ErrorHandlerRetryStyles";
import {
  errorMessageViewed,
  retryButtonClicked,
} from "../../config/typewriter";
import { useAnalytics } from "../../analytics/useAnalytics";

interface Props {
  onClick: () => void;
  testId?: string;
  screenName: string | undefined;
  screenSection: string;
}

const ErrorHandlerRetry: React.FunctionComponent<Props> = ({
  onClick,
  testId,
  screenName,
  screenSection,
}) => {
  const { t } = useTranslation();
  const classes = ErrorHandlerRetryStyles();
  const { dispatchPocEvent, dispatchGenericEvent } = useAnalytics();

  const clickAction = () => {
    dispatchGenericEvent(retryButtonClicked, {
      screen_name: screenName,
      screen_section: screenSection,
      error_message: "Information not found",
      error_type: "Information not found",
    });
    onClick();
  };

  React.useEffect(() => {
    dispatchPocEvent(
      errorMessageViewed,
      {
        error_message: "Information not found",
        error_type: "Information not found",
        screen_name: screenName,
        screen_section: screenSection,
        is_all_products: null,
        DDC: null,
        DDC_name: null,
        total_gap: null,
        total_vol_gap: null,
      },
      { time_of_day: true, is_resumed: true },
    );
  }, [dispatchPocEvent, screenName, screenSection]);

  return (
    <Box
      className={classes.errorContainer}
      data-testid={`${testId}-retry-container`}
    >
      <div className={classes.errorText}>
        {t("ErrorHandlerRetry.INFORMATION_NOT_FOUND")}
      </div>
      <ButtonBase onClick={clickAction} data-testid={`${testId}-retry-button`}>
        <span className={classes.buttonLabel}>
          {t("ErrorHandlerRetry.RELOAD_BUTTON_LABEL")}
        </span>
        <RetryIcon />
      </ButtonBase>
    </Box>
  );
};

ErrorHandlerRetry.defaultProps = {
  testId: "",
};

export default ErrorHandlerRetry;
