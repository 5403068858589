/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@material-ui/core";
import classNames from "classnames";
import PocInformation from "@domains/PocInformation";
import { useAnalytics } from "../../../../../../analytics/useAnalytics";
import GlobalStore from "../../../../../../stores/global/GlobalStore";
import {
  idTabInteraction,
  errorMessageViewed,
} from "../../../../../../config/typewriter";
import {
  ANALYTICS_ROUTE_NAMES,
  LINK_CALL,
} from "../../../../../../config/constants";
import TabIdsStyles from "./TabIdsStyles";
import Data from "../../../../../../components/data/Data";
import AgentCallStore from "../../../../../../stores/agentCall/AgentCallStore";
import {
  isFeatureEnabled,
  TLP_TAX_ID_POC_INFO,
  TLP_TAX_ID_POC_INFO_ADMIN,
  TLP_LIQUOR_LICENSE_POC_INFO,
  TLP_LIQUOR_LICENSE_POC_INFO_ADMIN,
} from "../../../../../../config/featureToggles";
import ContractlessTab from "./ContractlessTab";

interface TabIdsProps {
  growId: string;
  pocInformation: PocInformation;
}

const TabIds: React.FunctionComponent<TabIdsProps> = ({
  growId,
  pocInformation,
}) => {
  const classes = TabIdsStyles();
  const { t } = useTranslation();
  const { dispatchPocEvent } = useAnalytics();

  const { callId } = useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);

  const [activeIndex, setActiveIndex] = useState(0);

  const [activeTab, setActiveTab] = useState(
    "tabids-customer-account-id-button",
  );

  const challengeIdsEmpty = "";

  const {
    challengeIds,
    taxId,
    liquorLicense,
    customerAccountId,
    vendorAccountId,
  } = pocInformation;

  const isTaxId = isFeatureEnabled(
    TLP_TAX_ID_POC_INFO,
    TLP_TAX_ID_POC_INFO_ADMIN,
    user.keyToggle,
  );

  const isLiquorLicense = isFeatureEnabled(
    TLP_LIQUOR_LICENSE_POC_INFO,
    TLP_LIQUOR_LICENSE_POC_INFO_ADMIN,
    user.keyToggle,
  );

  /* istanbul ignore next */
  useEffect(() => {
    const dispatchEventErrorMessageViewed = (field: string) => {
      dispatchPocEvent(
        errorMessageViewed,
        {
          error_message: "No data available",
          error_type: field,
          screen_name: ANALYTICS_ROUTE_NAMES.get(LINK_CALL),
          screen_section: "Identification Number",
          is_all_products: null,
          total_gap: null,
          total_vol_gap: null,
        },
        { time_of_day: true, is_resumed: true },
      );
    };

    if (isLiquorLicense && !liquorLicense) {
      dispatchEventErrorMessageViewed("id-numbers-liquor-license");
    } else if (isTaxId && !taxId) {
      dispatchEventErrorMessageViewed("id-numbers-tax-id");
    } else if (!isLiquorLicense && !isTaxId) {
      const error =
        challengeIds?.find((item) => item === challengeIdsEmpty) != null;
      if (error) {
        dispatchEventErrorMessageViewed("id-numbers-erpid-id");
      }
    }
  }, [
    challengeIds,
    dispatchPocEvent,
    isLiquorLicense,
    liquorLicense,
    isTaxId,
    taxId,
  ]);

  const tabsToRender = useMemo(() => {
    const tabList = [
      {
        id: "tabids-customer-account-id-button",
        text: "CUSTOMER_ACCOUNT_ID",
        value: customerAccountId,
      },
      {
        id: "tabids-vendor-account-id-button",
        text: "VENDOR_ACCOUNT_ID",
        value: vendorAccountId,
      },
      {
        id: "tabids-growid-button",
        text: "GROW_ID",
        value: growId,
      },
      {
        id: "tabids-tax-id-button",
        text: "TAX_ID",
        value: taxId,
      },
      {
        id: "tabids-erpid-button",
        text: "ERP_ID",
        value: challengeIds,
      },
      {
        id: "tabids-liquor-license-button",
        text: "LIQUOR_LICENSE",
        value: liquorLicense,
      },
    ];
    return tabList.filter((tab) => tab?.value && tab?.value?.length > 0);
  }, [
    customerAccountId,
    vendorAccountId,
    growId,
    taxId,
    challengeIds,
    liquorLicense,
  ]);

  const handleTabClick = (index) => {
    setActiveIndex(index);
  };

  const truncateTo30Chars = (text?: string) =>
    text && text.length > 30 ? `${text.slice(0, 30)}...` : text;

  const handleAfterClick = (label: string) => {
    idTabInteraction({
      call_id: callId,
      tab_label: label,
    });
  };

  const startIndex = activeIndex - 1 < 0 ? 0 : activeIndex - 1;

  const renderTabs = useCallback(() => {
    const renderVisibleItems = () => {
      const endIndex =
        startIndex + 3 >= tabsToRender.length
          ? tabsToRender.length
          : startIndex + 3;

      return tabsToRender.slice(startIndex, endIndex);
    };

    return renderVisibleItems().map((tab, index) => (
      <div key={tab.id} className={classes.tabButtonWrapper}>
        <Button
          className={classNames(classes.tabButton, {
            [classes.active]: activeIndex === index + startIndex && "ACTIVE",
          })}
          onClick={() => {
            setActiveTab(tab.id);
            handleAfterClick(tab.id);
            handleTabClick(index + startIndex);
          }}
          data-testid={tab.id}
        >
          {t(`PocInformationHeaderTemplate.${tab.text}`)}
        </Button>
      </div>
    ));
  }, [tabsToRender, activeTab]);

  const renderTabsContent = () => {
    switch (activeTab) {
      case "tabids-vendor-account-id-button":
        return (
          <span
            title={vendorAccountId}
            data-testid="tabids-tab-content-vendorAccountId"
          >
            <Typography component="h5" variant="h5" className={classes.id}>
              <Data
                testId="vendorAccountId"
                value={truncateTo30Chars(vendorAccountId)}
              />
            </Typography>
          </span>
        );
      case "tabids-growid-button":
        return (
          <span title={growId} data-testid="tabids-tab-content-growid">
            <Typography component="h5" variant="h5" className={classes.id}>
              <Data testId="growid" value={truncateTo30Chars(growId)} />
            </Typography>
          </span>
        );
      case "tabids-tax-id-button":
        return (
          <span title={taxId} data-testid="tabids-tab-content-taxId">
            <Typography component="h5" variant="h5" className={classes.id}>
              <Data testId="taxId" value={truncateTo30Chars(taxId)} />
            </Typography>
          </span>
        );
      case "tabids-erpid-button":
        return (
          <ContractlessTab
            data-testid="tabids-tab-content-contractless"
            challengeIds={challengeIds}
          />
        );
      case "tabids-liquor-license-button":
        return (
          <span
            title={liquorLicense}
            data-testid="tabids-tab-content-liquorLicense"
          >
            <Typography component="h5" variant="h5" className={classes.id}>
              <Data
                testId="liquorLicense"
                value={truncateTo30Chars(liquorLicense)}
              />
            </Typography>
          </span>
        );
      default:
        return (
          <span
            title={customerAccountId}
            data-testid="tabids-tab-content-customerAccountId"
          >
            <Typography component="h5" variant="h5" className={classes.id}>
              <Data
                testId="customerAccountId"
                value={truncateTo30Chars(customerAccountId)}
              />
            </Typography>
          </span>
        );
    }
  };

  return (
    <div className={classes.container} data-testid="tabids-container">
      <div className={classes.tabs} data-testid="tabids-tabs">
        {renderTabs()}
      </div>
      <div className={classes.content} data-testid="tabids-tab-content">
        {renderTabsContent()}
      </div>
    </div>
  );
};

export default React.memo(TabIds);
