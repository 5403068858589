import { createEvent } from "effector";

export const updateCartForm =
  createEvent<Record<string | number, string | number>>("updateCartForm");
export const setNeedUpdate = createEvent<boolean>("updateCartForm");
export const setIsErrorMessageOrderModalOpen =
  createEvent<boolean>("updateCartForm");
export const setIsOrderItemOutOfStockOpenModal =
  createEvent<boolean>("updateCartForm");
export const resetCart = createEvent("resetCart");
