import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    container: {
      padding: "3px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      borderBottom: "1px solid #dedede",
    },
    noStyle: {
      padding: "0px",
      display: "block",
    },
    quantityContainer: {
      /* width: "72px",
      height: "25px",
      borderRadius: "3px",
      border: "1px solid #505050",
      marginLeft: "auto",
      margin: "2px 3px 9px 58px",
      padding: "0 8px 0 10px", */
      width: "72px",
      height: "25px",
      margin: "2px 3px 9px 58px",
      padding: "0 8px 0 10px",
      borderRadius: "3px",
      border: "solid 1px #505050",
      backgroundColor: "#fff",
      marginLeft: "auto",
    },
    title: {
      width: "180px",
      whiteSpace: "nowrap",
      fontSize: "10px",
      fontWeight: 500,
      color: "#7c7c7c",
    },
    name: {
      whiteSpace: "nowrap",
      width: "93 px",
      fontSize: "12px",
      fontWeight: 500,
      color: theme.palette.secondary.main,
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontFamily: "Roboto",
    },
    id: {
      width: "93 px",
      whiteSpace: "nowrap",
      fontSize: "10px",
      fontWeight: 500,
      color: "#b1b1b1",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left",
      direction: "rtl",
      paddingLeft: "6px",
      fontFamily: "Roboto",
    },
    balance: {
      fontSize: "10px",
      fontweight: 500,
      color: theme.palette.secondary.main,
      marginRight: "5px",
      fontFamily: "Roboto",
    },
    required: {
      fontSize: "10px",
      fontweight: 500,
      color: "#d00505",
      marginRight: "5px",
      fontFamily: "Roboto",
    },
    inputQuantity: {
      width: "100%",
      contain: "content",
      display: "flex",
      borderRadius: "3px",
      flexDirection: "row-reverse",
      "&& input": {
        height: "23px",
        width: "100%",
        border: "none",
        paddingLeft: "8px",
        outline: "none",
      },
      "& .rc-input-number-handler-wrap": {
        backgroundColor: "white",
        height: "23px",
        width: "100%",
        display: "flex",
        padding: "2px",
        flexDirection: "column",
        justifyContent: "center",
        "& span": {
          cursor: "pointer",
          justifyContent: "center",
          height: "10px",
          width: "100%",
          display: "flex",
          alignItems: "center",
        },
      },
    },
    itemsNumber: {
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "right",
      whiteSpace: "nowrap",
      color: "#7c7c7c",
    },
    header: {
      paddingBottom: "10px",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "-18px",
      width: "100%",
    },
    totalContainer: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      width: "100%",
      margin: "2%",
    },
    totalRequired: {
      fontSize: "14px",
      fontWeight: 900,
      color: "#7c7c7c",
      alignSelf: "center",
    },
    totalEmptiesRequired: {
      fontSize: "14px",
      fontWeight: 900,
      color: "#7c7c7c",
      alignSelf: "center",
    },
  }),
);
