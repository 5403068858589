import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    noMissionsProductsText: {
      fontSize: "16px",
      color: palette.secondary.main,
      paddingBottom: "14px",
      textAlign: "center",
    },
    loading: {
      paddingBottom: "14px",
      paddingTop: "14px",
      textAlign: "center",
    },
  }),
);
