/* eslint-disable no-console */
import React, { useState, useCallback, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Timer, { TimerControls } from "react-compound-timer";
import { getVendorName } from "grow-shared-services";
import { useStore } from "effector-react";
import Moment from "moment";
import CallInfoStyles from "./CallTimerInfoStyles";
import PhoneIcon from "../../../../assets/PhoneOutlineIcon";
import { update as updateAgentCall } from "../../../../usecase/agentCall/AgentCallUseCase";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import CtiStore from "../../../../stores/cti/CtiStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";
import PocInfoStore from "../../../../stores/callList/pocInformation/PocInformationStore";
import CallCounterStore from "../../../../stores/callCounter/CallCounterStore";
import { usageTypes } from "../../../../config/usageTypes";
import Button from "../../../../components/button/Button";
import {
  getValueOrNull,
  getResponseDate,
  getPhoneSanitized,
} from "../../../../config/utils/functions";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import * as AgentCallEvents from "../../../../stores/agentCall/AgentCallEvents";
import {
  callUxButtonClicked,
  callFailed,
  errorMessageViewed,
  ctiDisconnected,
} from "../../../../config/typewriter";
import {
  DATE_FORMAT_STRING,
  ANALYTICS_ROUTE_NAMES,
  ANALYTICS_REGISTRATION_ERROR_TYPE,
  TAB_GAP_ROUTE,
  TAB_CUSTOMER_ROUTE,
  PocCallOrigin,
} from "../../../../config/constants";
import {
  isFeatureEnabled,
  TLP_CTI,
  TLP_CTI_ADMIN,
  TLP_DELIVERY_WINDOW_VALIDATION,
  TLP_DELIVERY_WINDOW_VALIDATION_ADMIN,
  TLP_MISSIONS_LAYOUT,
  TLP_MISSIONS_LAYOUT_ADMIN,
  TLP_MULTI_VENDOR,
  TLP_MULTI_VENDOR_ADMIN,
  GROW_CTI_START_CALL_AUTOMATICALLY,
  GROW_NON_CTI_START_CALL_AUTOMATICALLY,
  TLP_CALL_AUTO_DIALER,
  GROW_CART_SERVICE_V4,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";
import CTIFailedToConnectModal from "../ctiFailedToConnect/CTIFailedToConnectModal";
import DeliveryWindowsStore from "../../../../stores/deliveryWindows/DeliveryWindowsStore";
import {
  resetAttemptRetry,
  setShouldContinueWithoutCTI,
  setIsCTIFailedOpen,
} from "../../../../stores/cti/CtiEvents";
import SoftphoneStatus from "./softphoneStatus/SoftphoneStatus";
import { SoftphoneWS } from "../../../../domains/cti/SoftphoneWS";
import CTILostConnectionModal from "../ctiLostConnectionModal/CTILostConnectionModal";
import CallCounter from "../../../../components/callCounter/CallCounter";
import CallSummaryMissionModal from "../callSummaryMissions/callSummaryMissionsModal/CallSummaryMissionsModal";
import ErrorIcon from "../../../../assets/newIcons/ErrorIcon";
import useWebSocket from "./hooks/useWebSocket";
import PhoneRankingModal from "../phoneRanking/PhoneRanking";
import PhonesRankedStore from "../../../../stores/phoneRankingStore/phoneRankedStore";
import { setIsModalOpen } from "../../../../stores/phoneRankingStore/phoneRankedEvents";
import GlobalAdminConfigStore from "../../../../stores/globaAdminConfig/GlobalAdminConfigStore";

enum SCREEN_SECTIONS {
  GAP_STATUS = "Gap and Status Header",
  CTI_MODAL = "CTI Modal",
  CTI_FAIL = "CTI Fail To Connect Modal",
  RECONNECT_BTN = "Reconnect CTI",
}
enum CTI_ACTION_RECONNECT {
  RETRY = "retry",
  CONTINUE = "continue",
}

const CallTimerInfo: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [hasStarted, setHasStarted] = useState(false);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const agentCallStates = useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);
  const { callTab } = useStore(CallTabStore);
  const { count: callCount } = useStore(CallCounterStore);
  const { pocInformation } = useStore(PocInfoStore);
  const { phones, isModalOpen, loading, done } = useStore(PhonesRankedStore);
  const { dispatchPocEvent } = useAnalytics();
  const { lastCalledPhone, originalPhones } = useStore(CtiStore);
  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);
  const { deliveryWindows, deliveryRange } = useStore(DeliveryWindowsStore);

  const {
    callCti,
    connectWebSocket,
    makeCallWebSocket,
    CTILoading,
    webSocketState,
    isCTILostConnectionOpen,
    isConnected,
    isCallStatusOpen,
    setIsCallStatusOpen,
    resetTimer,
    pingPong,
    ctiPingPong,
    dispatchCallStartedEvent,
    getStatusConnectionCTI,
    attemptRetry,
    shouldContinueWithoutCTI,
    isCTIFailedOpen,
    needClassification,
    setNeedClassification,
  } = useWebSocket();
  const { lastCalledOrCopiedPhone } = PhonesRankedStore.getState();
  const ctiEnabled = isFeatureEnabled(TLP_CTI, TLP_CTI_ADMIN, user.zone);
  /* istanbul ignore next */

  const isMissionsEnabled = isFeatureEnabled(
    TLP_MISSIONS_LAYOUT,
    TLP_MISSIONS_LAYOUT_ADMIN,
    user.keyToggle,
  );

  const isCtiStartCallAutomaticallyEnabled = isFeatureEnabledV2(
    GROW_CTI_START_CALL_AUTOMATICALLY,
    user.zone,
  );

  const isNonCtiStartCallAutomaticallyEnabled = isFeatureEnabledV2(
    GROW_NON_CTI_START_CALL_AUTOMATICALLY,
    user.zone,
  );

  const classes = CallInfoStyles({ isMissionsEnabled });

  const isDeliveryWindowsMultiContractEnabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    user.zone,
  );

  const isDeliveryWindowEnabled = isFeatureEnabled(
    TLP_DELIVERY_WINDOW_VALIDATION,
    TLP_DELIVERY_WINDOW_VALIDATION_ADMIN,
    user?.zone,
  );

  const isMultiVendor = isFeatureEnabled(
    TLP_MULTI_VENDOR,
    TLP_MULTI_VENDOR_ADMIN,
  );

  const isCallInAutoDialerEnabled = isFeatureEnabledV2(
    TLP_CALL_AUTO_DIALER,
    user.keyToggle,
  );

  const shouldStartHandlerAutomatically =
    (!agentCallStates.isTypedPoc ||
      agentCallStates.pocCallOrigin !== PocCallOrigin.SEARCH_BY_POC) &&
    !agentCallStates.startCall &&
    !hasStarted &&
    done;

  const shouldHaveAutoCallEnabledOrAutoDialer =
    (isCtiStartCallAutomaticallyEnabled && ctiEnabled) ||
    (isNonCtiStartCallAutomaticallyEnabled && !ctiEnabled) ||
    (isCallInAutoDialerEnabled &&
      agentCallStates.pocCallOrigin === PocCallOrigin.AUTO_DIALER);
  const isPhonesValid = phones && phones.length > 0;
  const isOriginalPhonesValid = originalPhones && originalPhones.length > 0;
  const dispatchCTIDisconectedEvent = (action: string) => {
    ctiDisconnected({
      call_id: agentCallStates.callId,
      poc_id: agentCallStates.clientId,
      action,
      screen_section: "CTI Fail To Connect Modal",
      country: agentCallStates.countryCode,
      call_duration: null,
      phone_source: null,
      phone_rank: null,
      phone_score: null,
    });
  };

  /* istanbul ignore next */
  const dispatchCallFailedEvent = (
    message?: string,
    screenSection?: string,
  ) => {
    const phonesRanked = PhonesRankedStore.getState().phones;
    const selectedPhone = phonesRanked.filter(
      (phone) =>
        getPhoneSanitized(phone.phoneNumber) === lastCalledOrCopiedPhone,
    );
    let score: number | null = null;
    let index: number | null = null;
    if (selectedPhone) {
      const phone = selectedPhone[0];
      if (phone) {
        score = phone.score;
        index = phonesRanked.indexOf(phone) + 1;
      }
    }
    dispatchPocEvent(
      callFailed,
      {
        screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
        error_type: ANALYTICS_REGISTRATION_ERROR_TYPE,
        error_message: message ? t(message) : null,
        screen_section: screenSection,
        phone_rank: index,
        phone_score: score,
      },
      {
        time_of_day: true,
        is_resumed: true,
      },
    );
  };
  /* istanbul ignore next */
  const dispatchErrorModalViewed = useCallback(() => {
    dispatchPocEvent(
      errorMessageViewed,
      {
        error_message: "Error: Fail to connect at CTI",
        error_type: "CTI connection error",
        screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
        screen_section: "CTI Fail To Connect Modal",
        is_all_products: null,
      },
      { time_of_day: true, is_resumed: true },
    );
  }, [callTab, dispatchPocEvent]);

  /* istanbul ignore next */
  const handleStatusConnectionCTI = () => {
    if (ctiPingPong) {
      if (pingPong && pingPong.current) {
        clearTimeout(pingPong.current);
      }
      getStatusConnectionCTI(webSocketState as SoftphoneWS);
    }
  };

  /* istanbul ignore next */
  const handleUnwatchCallSuccessAndFail = (
    unWatchAgentCallSuccess,
    unWatchAgentCallFail,
  ) => {
    if (
      typeof unWatchAgentCallSuccess === "function" &&
      typeof unWatchAgentCallFail === "function"
    ) {
      unWatchAgentCallSuccess();
      unWatchAgentCallFail();
    }
  };

  const handleMultiVendorUpdate = () => {
    const { vendorId } = GlobalAdminConfigState.userConfig;
    const vendorName = getVendorName(vendorId);
    updateAgentCall(
      {
        ...agentCallStates,
        startCall: getResponseDate().format(DATE_FORMAT_STRING),
        usage: usageTypes.AGENT_CALL_SERVICE.START_CALL,
      },
      pocInformation,
      vendorId,
      vendorName,
    );
  };

  const handleNormalUpdate = () => {
    updateAgentCall(
      {
        ...agentCallStates,
        startCall: getResponseDate().format(DATE_FORMAT_STRING),
        usage: usageTypes.AGENT_CALL_SERVICE.START_CALL,
      },
      pocInformation,
    );
  };

  /* istanbul ignore next */
  const handlePhoneNumberUpdates = () => {
    let phoneNumber = "";

    if (isPhonesValid) {
      phoneNumber = phones[0].phoneNumber;
    } else if (isOriginalPhonesValid) {
      phoneNumber = originalPhones[0].phone;
    }

    if (
      phoneNumber &&
      agentCallStates.pocCallOrigin !== PocCallOrigin.AUTO_DIALER
    ) {
      makeCallWebSocket(phoneNumber, false, SCREEN_SECTIONS.CTI_MODAL);
    } else {
      setIsCallStatusOpen(true);
    }
  };

  const startHandler = (start: () => void) => {
    handleStatusConnectionCTI();

    if (
      !isConnected &&
      agentCallStates.pocCallOrigin !== PocCallOrigin.AUTO_DIALER
    ) {
      setIsModalOpen(true);
      setNeedClassification(true);
    }

    if (isMultiVendor) {
      handleMultiVendorUpdate();
    } else {
      handleNormalUpdate();
    }

    const unWatchAgentCallFail = AgentCallEvents.updateAgentCallFail.watch(
      (message) => {
        if (message.usage === usageTypes.AGENT_CALL_SERVICE.START_CALL) {
          dispatchCallFailedEvent(message.message, SCREEN_SECTIONS.CTI_MODAL);

          handleUnwatchCallSuccessAndFail(
            unWatchAgentCallSuccess,
            unWatchAgentCallFail,
          );
        }
      },
    );

    const unWatchAgentCallSuccess = AgentCallEvents.onChangeAgentCall.watch(
      (result) => {
        if (result.usage === usageTypes.AGENT_CALL_SERVICE.START_CALL) {
          start();
          setHasStarted(true);

          if (callCti()) {
            handlePhoneNumberUpdates();
          } else {
            dispatchCallStartedEvent(SCREEN_SECTIONS.GAP_STATUS, null);
          }

          handleUnwatchCallSuccessAndFail(
            unWatchAgentCallSuccess,
            unWatchAgentCallFail,
          );
        }
      },
    );

    if (shouldHaveAutoCallEnabledOrAutoDialer) {
      setHasStarted(true);
    }
  };

  const shouldOpenModalHandler = () => {
    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: t("CallTypification.STOP_CALL"),
      button_label: "call-timer-info-toggle-call-button",
      screen_section: "Gap and Status Header",
      filter_option: "",
      call_id: agentCallStates.callId,
      order_date: null,
      position: null,
      mission: null,
    });
    setShouldOpenModal(true);
  };

  useEffect(() => {
    if (hasStarted === false && agentCallStates.startCall) {
      setHasStarted(true);
    }
  }, [
    agentCallStates.startCall,
    hasStarted,
    isCtiStartCallAutomaticallyEnabled,
    isNonCtiStartCallAutomaticallyEnabled,
  ]);

  const shouldRenderDeliveryWindowError = useCallback(() => {
    if (isDeliveryWindowsMultiContractEnabled) {
      return (
        (!deliveryRange.maxDays ||
          deliveryRange.maxDays < 0 ||
          !deliveryRange.minDays ||
          deliveryRange.minDays < 0) &&
        (!deliveryWindows || deliveryWindows?.length === 0)
      );
    }

    return (
      pocInformation?.id &&
      (!pocInformation.deliveryWindows ||
        pocInformation.deliveryWindows.length === 0)
    );
  }, [
    deliveryRange.maxDays,
    deliveryRange.minDays,
    deliveryWindows,
    isDeliveryWindowsMultiContractEnabled,
    pocInformation?.deliveryWindows,
    pocInformation?.id,
  ]);

  const dispatchDeliveryWindowErrorViewed = useCallback(() => {
    if (
      isDeliveryWindowEnabled &&
      pocInformation?.id &&
      shouldRenderDeliveryWindowError()
    ) {
      dispatchPocEvent(
        errorMessageViewed,
        {
          error_message:
            "The call was ended. Select a number below to make another call.",
          error_type: "No delivery window error",
          screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
          screen_section: "Gap and Status Header",
          is_all_products: null,
        },
        { time_of_day: true, is_resumed: true },
      );
    }
  }, [
    callTab,
    dispatchPocEvent,
    isDeliveryWindowEnabled,
    pocInformation,
    shouldRenderDeliveryWindowError,
  ]);

  useEffect(() => {
    dispatchDeliveryWindowErrorViewed();
  }, [dispatchDeliveryWindowErrorViewed]);

  const initialTimerHandler = useCallback(() => {
    if (hasStarted === false && agentCallStates.startCall) {
      const time = getResponseDate();
      const updatedInitialTime = Moment.duration(
        Math.abs(time.diff(agentCallStates.startCall)),
      );
      return updatedInitialTime.asMilliseconds();
    }
    return 0;
  }, [agentCallStates.startCall, hasStarted]);

  const renderStartCallButtonLogic = (start: () => void) => {
    /* istanbul ignore next */
    if (shouldStartHandlerAutomatically) {
      startHandler(start);
    }
  };
  const renderStartCallBtn = (start: () => void) => {
    if (shouldHaveAutoCallEnabledOrAutoDialer) {
      renderStartCallButtonLogic(start);
    }
    const onClickHandlerWithoutAutoCall = () => {
      if (hasStarted) shouldOpenModalHandler();
      else startHandler(start);
    };

    const onClickHandlerWithAutoCall = () => {
      if (
        agentCallStates.isTypedPoc ||
        agentCallStates.pocCallOrigin === PocCallOrigin.SEARCH_BY_POC
      )
        onClickHandlerWithoutAutoCall();
      else shouldOpenModalHandler();
    };

    return (
      <Button
        onClick={
          shouldHaveAutoCallEnabledOrAutoDialer
            ? onClickHandlerWithAutoCall
            : onClickHandlerWithoutAutoCall
        }
        loading={loading}
        data-testid="call-timer-info-toggle-call-button"
        className={classNames(
          isMissionLayoutAndGapTab() ? classes.buttonMissions : classes.button,
          {
            [classes.startButton]: !hasStarted,
            [classes.stopButton]: hasStarted,
          },
        )}
      >
        <Typography
          className={classes.startCall}
          data-testid="call-timer-info-toggle-call-button-label"
        >
          {hasStarted
            ? t("CallTypification.STOP_CALL")
            : t("CallTypification.START_CALL")}
        </Typography>
        <div
          className={
            hasStarted ? classes.callIconStyleStarted : classes.callIconStyle
          }
        >
          <PhoneIcon color={!hasStarted ? "#000000" : "#ff6f71"} />
        </div>
      </Button>
    );
  };

  /* istanbul ignore next */
  const shouldOpenCTILostConnectionModal = () => {
    return isCTILostConnectionOpen && !shouldContinueWithoutCTI;
  };

  const isMissionLayoutAndGapTab = () => {
    return (
      (isMissionsEnabled && callTab === TAB_GAP_ROUTE) ||
      callTab === TAB_CUSTOMER_ROUTE
    );
  };

  const renderNoDeliveryWindows = () => {
    return (
      shouldRenderDeliveryWindowError() && (
        <div className={classes.noDeliveryWindowsContainer}>
          <ErrorIcon className={classes.errorIcon} />
          <Typography className={classes.noDeliveryWindows}>
            {t("NO_DELIVERY_WINDOW")}
          </Typography>
        </div>
      )
    );
  };

  const shouldCTIComponentsRender = () => {
    return (
      isCTIFailedOpen || shouldOpenCTILostConnectionModal() || isCallStatusOpen
    );
  };

  const renderPhoneRankingModal = (isOpen: boolean = isModalOpen) => {
    return (
      <PhoneRankingModal
        lastCalledPhone={lastCalledPhone}
        isOpen={isOpen}
        onClose={handleClosePhoneRankingModal}
        items={phones || []}
        needClassification={needClassification}
        setNeedClassification={setNeedClassification}
        setShouldOpenClassificationModal={setShouldOpenModal}
        makeCallWebSocket={
          /* istanbul ignore next */
          (phone: string, isTyped: boolean, section?: string | undefined) => {
            makeCallWebSocket(phone, isTyped, section);
          }
        }
      />
    );
  };

  /* istanbul ignore next */
  const renderCTIComponents = () => {
    return (
      ctiEnabled && (
        <>
          <CTIFailedToConnectModal
            data-testid="failed-to-connect-modal"
            open={isCTIFailedOpen}
            dispatchErrorModalViewed={dispatchErrorModalViewed}
            loading={CTILoading}
            retryCount={attemptRetry}
            onClose={() => {
              setIsCTIFailedOpen(false);
              setShouldContinueWithoutCTI(true);
              dispatchCTIDisconectedEvent(CTI_ACTION_RECONNECT.CONTINUE);
              resetAttemptRetry();
            }}
            retryDisabled={attemptRetry >= 4}
            connectWebSocket={() => {
              connectWebSocket();
              dispatchCTIDisconectedEvent(CTI_ACTION_RECONNECT.RETRY);
              resetTimer();
            }}
          />
          {ctiEnabled && (
            <CTILostConnectionModal
              open={shouldOpenCTILostConnectionModal()}
              data-testid="lost-connection-modal"
              dispatchErrorModalViewed={dispatchErrorModalViewed}
              loading={CTILoading}
              retryCount={attemptRetry}
              onClose={() => {
                setShouldContinueWithoutCTI(true);
                dispatchCTIDisconectedEvent(CTI_ACTION_RECONNECT.CONTINUE);
                resetAttemptRetry();
              }}
              retryDisabled={attemptRetry >= 4}
              connectWebSocket={() => {
                connectWebSocket();
                dispatchCTIDisconectedEvent(CTI_ACTION_RECONNECT.RETRY);
                resetTimer();
                setShouldContinueWithoutCTI(false);
              }}
            />
          )}
          {hasStarted && renderPhoneRankingModal(isCallStatusOpen)}
        </>
      )
    );
  };

  /* istanbul ignore next */
  const renderCallSummaryModal = (
    getTime: () => number,
    stop: () => void,
    reset: () => void,
  ) => {
    return (
      <CallSummaryMissionModal
        testId="call-summary-mission-modal"
        data-testid="call-summary-mission-modal"
        open={shouldOpenModal}
        getTimer={getTime}
        onClose={() => setShouldOpenModal(false)}
        onRegister={() => {
          onRegisterHandler(stop, reset);
        }}
        lastCalledOrCopiedPhone={lastCalledOrCopiedPhone}
      />
    );
  };

  const handleClosePhoneRankingModal = () => {
    // istanbul ignore next
    if (isConnected || isCallStatusOpen) {
      setIsCallStatusOpen(false);
    } else {
      setIsModalOpen(false);
    }
  };

  /* istanbul ignore next */
  const onRegisterHandler = (stop: () => void, reset: () => void) => {
    setShouldOpenModal(false);
    setHasStarted(false);
    setIsCTIFailedOpen(false);
    setShouldContinueWithoutCTI(false);
    stop();
    reset();
  };

  const renderCallSummaryComponents = (
    getTime: () => number,
    stop: () => void,
    reset: () => void,
  ) => {
    return <>{renderCallSummaryModal(getTime, stop, reset)}</>;
  };

  const getValue = (value: number) => (value < 10 ? `0${value}` : value);
  return (
    <>
      <Timer
        initialTime={initialTimerHandler()}
        formatValue={(value) => {
          /* istanbul ignore next */
          return `${getValue(value)}`;
        }}
        startImmediately={initialTimerHandler() > 0}
      >
        {({ start, stop, reset, getTime }: TimerControls) => {
          return isMissionLayoutAndGapTab() ? (
            <>
              <Box>
                <Box className={classes.innerContainerMissions}>
                  {renderStartCallBtn(start)}
                  <Box className={classes.statusContainerMissions}>
                    <Box className={classes.timerContainer}>
                      <Typography
                        className={classes.timeOnCallMissions}
                        variant="subtitle1"
                      >
                        {t("NEWCallSummary.TIME_ON_CALL")}
                      </Typography>
                      <span
                        className={classes.timerMissions}
                        data-testid="call-timer-info-timer"
                      >
                        <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                      </span>
                    </Box>
                    <Box className={classes.callStatusContainer}>
                      <CallCounter
                        value={callCount}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      />
                      {ctiEnabled && (
                        <SoftphoneStatus
                          isConnected={isConnected}
                          style={{
                            paddingRight: "0px",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
                {renderNoDeliveryWindows()}
              </Box>
              {shouldCTIComponentsRender() &&
                agentCallStates.pocCallOrigin !== PocCallOrigin.AUTO_DIALER &&
                renderCTIComponents()}
              {shouldOpenModal &&
                renderCallSummaryComponents(getTime, stop, reset)}
              {isModalOpen && renderPhoneRankingModal()}
            </>
          ) : (
            <>
              <div className={classes.callInfo}>
                <div className={classes.callStatusTimeOnCall}>
                  <span className={classes.timeOnCall}>
                    {t("CallTypification.TIME_ON_CALL")}
                  </span>
                  <span
                    className={classes.timer}
                    data-testid="call-timer-info-timer"
                  >
                    <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
                  </span>
                </div>
                {renderNoDeliveryWindows()}
              </div>
              <div className={classes.callStatusWrapper}>
                <Box className={classes.callStatusContainer}>
                  {ctiEnabled && <SoftphoneStatus isConnected={isConnected} />}
                  <CallCounter
                    value={callCount}
                    style={{ marginRight: "20px" }}
                  />
                </Box>
                {renderStartCallBtn(start)}
              </div>
              {shouldCTIComponentsRender() &&
                agentCallStates.pocCallOrigin !== PocCallOrigin.AUTO_DIALER &&
                renderCTIComponents()}
              {shouldOpenModal &&
                renderCallSummaryComponents(getTime, stop, reset)}
              {isModalOpen && renderPhoneRankingModal()}
            </>
          );
        }}
      </Timer>
    </>
  );
};

export default React.memo(CallTimerInfo);
