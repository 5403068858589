import { useLogService } from "admin-portal-shared-services";
import { CheckoutInfo } from "domains/CheckoutInfo";

import { CheckoutInfoV2 } from "../../../domains/CheckoutInfoV2";
import * as CheckoutService from "../../../services/checkout/CheckoutServiceV3";
import { CheckoutDomain } from "../../../services/checkout/domains/CheckoutDomain";
import CheckoutRequest from "../../../services/checkout/domains/CheckoutRequest";
import { CheckoutDomainV2 } from "../../../services/checkout/domains/v2/CheckoutDomainV2";
import * as CheckoutV2Service from "../../../services/checkout/v2/CheckoutV2Service";
import PaymentMethodInfoStore from "../../../stores/paymentsInformation/PaymentMethodInfoStore";

export function execute(
  checkoutData: CheckoutDomainV2,
): Promise<CheckoutInfoV2> {
  return CheckoutV2Service.checkout(checkoutData.request);
}

export function executeV3({
  request,
  analytics,
}: CheckoutDomain): Promise<CheckoutInfo> {
  /* istanbul ignore next */
  validatePaymentMethodCode(request, analytics?.callId ?? "");

  return CheckoutService.checkout(request);
}

// This code will be removed after the validation of the following bug: https://ab-inbev.atlassian.net/browse/BEESGR-18610
/* istanbul ignore next */
function validatePaymentMethodCode(
  checkoutDataRequest: CheckoutRequest,
  callId: string,
) {
  const log = useLogService();
  const { paymentMethodInfo } = PaymentMethodInfoStore.getState();

  checkoutDataRequest.carts?.forEach(
    ({ payment: { paymentMethod, paymentMethodCode } }) => {
      const { groupedPaymentMethodInfos } = paymentMethodInfo;

      const groupedPaymentDetails = groupedPaymentMethodInfos[paymentMethod];

      if (groupedPaymentDetails.length > 0) {
        const { vendorPaymentMethod } = groupedPaymentDetails[0];

        if (
          !!vendorPaymentMethod &&
          vendorPaymentMethod !== paymentMethodCode
        ) {
          log.warn(
            `Wrong paymentMethodCode. Expected '${vendorPaymentMethod}' but got '${paymentMethodCode}'. accountId: ${checkoutDataRequest.accountId} callId: ${callId}`,
          );
        }
      }
    },
  );
}
