import { getEnvironment } from "../../config/utils/functions";
import { sitStoreIds } from "../../config/storeIds/sit";
import { uatStoreIds } from "../../config/storeIds/uat";
import { prodStoreIds } from "../../config/storeIds/prod";

export function getStoreIdHost(country: string, vendorName: string): string {
  const countryEnv = vendorName
    ? `${vendorName.toUpperCase()}_${country.toUpperCase()}`
    : country;

  switch (getEnvironment()) {
    case "SIT":
      return sitStoreIds[countryEnv];
    case "PROD":
      return prodStoreIds[countryEnv];
    case "localhost":
      return uatStoreIds[countryEnv];
    default:
      return uatStoreIds[countryEnv];
  }
}
