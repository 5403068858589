import { RewardsChallengesItemResponse } from "@services/rewardsChallenges/domain/RewardsChallengesResponse";
import {
  RewardsChallenge,
  RewardsChallengeState,
  CHALLENGE_STATUS,
} from "../../domains/rewardsChallenges/RewardsChallenges";

export default function RewardsChallengesResponseToRewardsChallengesStore(
  data: RewardsChallenge[] | RewardsChallengesItemResponse[],
): RewardsChallengeState {
  const currentDate = new Date();
  let started = 0;
  let failed = 0;
  let completed = 0;

  const rewardsChallengeInformation = data?.map((item) => {
    if (item.startDate) {
      const date = new Date(item.startDate);
      if (date.getFullYear() === currentDate.getFullYear()) {
        switch (item.challengeStatus) {
          case CHALLENGE_STATUS.PENDING:
            started += 1;
            break;
          case CHALLENGE_STATUS.FAILED:
            failed += 1;
            break;
          case CHALLENGE_STATUS.COMPLETED:
            completed += 1;
            break;
          default:
            break;
        }
      }
    }

    const fieldItem = (fieldItem) => {
      return fieldItem || "";
    };

    return {
      challengeId: item.challengeId,
      imgURL: fieldItem(item.imgURL),
      startDate: item.startDate,
      endDate: item.endDate,
      challengePoints: item.challengePoints,
      challengeTitle: fieldItem(item.challengeTitle),
      challengeDescription: item.challengeDescription,
      challengeStatus: item.challengeStatus,
      executionMethod: item.executionMethod,
      stage: item.stage,
      goodPhotoSample: fieldItem(item.goodPhotoSample),
      badPhotoSample: fieldItem(item.badPhotoSample),
      skus:
        item.skus?.map((product) => ({
          itemName: product.itemName,
          itemImage: fieldItem(product.itemImage),
          sku: product.sku,
          price: product.price,
          quantity: product.quantity,
        })) ?? [],
    };
  });

  return {
    started,
    failed,
    completed,
    rewardsChallengeInformation,
  };
}
