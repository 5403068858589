import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/button/Button";
import OrderCartStyles from "../OrderCartStyles";

interface Props {
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
}
const PlaceOrderButton: React.FunctionComponent<Props> = ({
  disabled,
  loading,
  onClick,
}) => {
  const { t } = useTranslation();
  const classes = OrderCartStyles();

  return (
    <Button
      loading={loading}
      loadingProps={{ size: 20 }}
      className={classNames(classes.button, {
        [classes.disabledButton]: disabled && !loading,
        [classes.activeButton]: !disabled,
      })}
      data-testid="submit-order-cart"
      disabled={disabled}
      onClick={onClick}
    >
      {t("OrderTaking.PLACE_ORDER_BUTTON")}
    </Button>
  );
};

export default PlaceOrderButton;
