import { createEffect, createEvent } from "effector";
import { CallLogs } from "./CallLogsState";
import * as UpdateTempCallLogsUseCase from "../../usecase/tempCallLogs/TempCallLogsUseCase";

export const setCallLogsEvent = createEvent<CallLogs>("setCallLogsEvent");
export const setTempCallLogsEvent = createEvent<CallLogs>(
  "setDuringCallLogsEvent",
);

export const updateTempCallLogsEffect = createEffect({
  async handler(tempCallLogs: Array<CallLogs>) {
    return UpdateTempCallLogsUseCase.execute(tempCallLogs);
  },
});

export const resetCallLogsEvent = createEvent<null>("resetCallLogsEvent");
