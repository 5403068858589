import React from "react";
import { Box, Divider, Grid, CardContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GenericStyles from "./GenericCardStyles";

interface Props {
  mainValue?: string | null;
  testId: string;
  target?: string | null;
}

const TimeCard: React.FunctionComponent<Props> = ({
  mainValue,
  testId,
  target,
}) => {
  const classes = GenericStyles({});
  const { t } = useTranslation();

  return (
    <CardContent classes={{ root: classes.cardContainer }}>
      <Typography
        classes={{ root: classes.genericTitleOverwrite }}
        className={classes.title}
        data-testid={`${testId}-time-card-title`}
      >
        {t("AverageHandleTime.TITLE")}
      </Typography>
      <Divider classes={{ vertical: classes.divider }} />

      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid item xs={9}>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            alignItems="flex-end"
          >
            <Typography
              className={classes.percentage}
              data-testid={`${testId}-time-mainValue`}
            >
              {mainValue ? (
                <>
                  {mainValue}
                  <span style={{ fontSize: "20px" }}>
                    {` ${t("AverageHandleTime.TIME")}`}
                  </span>
                </>
              ) : (
                t("GlobalMessage.N_A")
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Typography
            align="right"
            className={classes.secondValue}
            data-testid={`${testId}-time-targetValue`}
          >
            {target ?? t("GlobalMessage.N_A")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-around" spacing={0}>
        <Grid item xs={7}>
          <Typography
            classes={{ root: classes.genericTitleOverwrite }}
            className={classes.title}
            data-testid={`${testId}-time-mainValue-title`}
            variant="subtitle1"
          >
            {t("AverageHandleTime.COMPLETED_TEXT")}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            align="right"
            classes={{ root: classes.genericTitleOverwrite }}
            className={classes.targetLabel}
            data-testid={`${testId}-time-targetValue-title`}
            variant="subtitle1"
          >
            {t("AverageHandleTime.TARGET_TEXT")}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default TimeCard;
