import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ breakpoints }) =>
  createStyles({
    container: {
      display: "flex",
      height: "100%",
    },
    centerContainer: {
      flex: 1,
      marginRight: "15px",
    },
    rightContent: {
      width: "280px",
      marginLeft: "15px",
      // padding: "45px 0 4em 0",
      [breakpoints.up(1440)]: {
        width: "300px",
      },
    },
  }),
);
