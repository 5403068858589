import * as CallCounterService from "../../services/callCounter/CallCounterService";
import * as callCounterEvents from "../../stores/callCounter/CallCounterEvents";

export function execute(
  accountId: string,
  vendorId?: string,
  vendorName?: string,
): Promise<void> {
  let headers = {};
  if (vendorId && vendorName) headers = { vendorId, vendorName };
  return CallCounterService.getCallCounter(accountId, headers)
    .then((response) => {
      callCounterEvents.setCallCounter(response);
    })
    .catch(() => {
      callCounterEvents.setCallCounter(null);
    });
}
