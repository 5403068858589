import { createStore } from "effector";
import * as CallTabEvents from "./CallTabEvents";
import CallTabState from "./CallTabState";
import { TAB_GAP_ROUTE } from "../../../config/constants";

const initialState: CallTabState = {
  callTab: TAB_GAP_ROUTE,
  isFromSidebar: false,
};

const CallTabStore = createStore(initialState).on(
  CallTabEvents.setCallTab,
  (state: CallTabState, tab: string) => ({
    ...state,
    callTab: tab,
  }),
);
export default CallTabStore;
