import React, { useMemo, createRef, useEffect, useState } from "react";
import { Box, Typography, Tooltip } from "@material-ui/core";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import useStyles from "./MissionsTimelineStyles";
import {
  Mission,
  LastContactsClassification,
} from "../../../../../../domains/Mission";
import {
  isFeatureEnabledV2,
  TLP_CALL_SUMMARY_REMOVE_FIELDS,
  TLP_SERVICE_MISSION_EFFECTIVENESS_CALL_SUMMARY,
} from "../../../../../../config/featureToggles";
import GlobalStore from "../../../../../../stores/global/GlobalStore";
import { LAST_CONTACT_REASON } from "../../../../../../domains/LastContactsValues";
import MissionTooltip from "../../missionTooltip/MissionTooltip";
import { ConvertAndFormatTag } from "../../../../../../helpers/TagNameFormatter";

export const MISSION_TAG_TEXT_MAX_WIDTH = 190;
const LOCAL_MISSION = "local";
const SPONSORED_MISSION = "sponsored";
const FLEXIBLE_MISSIONS = "#flexible_missions";

interface Props {
  title: string;
  missions?: Mission[];
  isNotAccomplished?: boolean;
}

const MissionsTimeline: React.FC<Props> = ({
  title,
  missions,
  isNotAccomplished,
}) => {
  const classes = useStyles();
  const GlobalStates = useStore(GlobalStore);

  const { t } = useTranslation();

  const missionTagTextRef: React.RefObject<HTMLHeadingElement>[] | undefined =
    useMemo(() => missions?.map(() => createRef()), [missions]);

  const isServicesOnLastContactsEnabled = isFeatureEnabledV2(
    TLP_SERVICE_MISSION_EFFECTIVENESS_CALL_SUMMARY,
    GlobalStates.user.keyToggle,
  );

  const missionsFiltered = missions?.filter((mission) => {
    const isTagServices = mission.tag === "#digital_services";

    if (isTagServices && !isServicesOnLastContactsEnabled) {
      return false;
    }

    return true;
  });

  const [missionTagTextRefState, setMissionTagTextRefState] = useState<
    React.RefObject<HTMLHeadingElement>[] | undefined
  >();

  useEffect(() => {
    setMissionTagTextRefState(missionTagTextRef);
  }, [missionTagTextRef]);

  const isCallSummaryRemoveFieldsEnabled = isFeatureEnabledV2(
    TLP_CALL_SUMMARY_REMOVE_FIELDS,
    GlobalStates.user.keyToggle,
  );

  const renderTooltip = (mission: Mission) => {
    if (isCallSummaryRemoveFieldsEnabled && mission.effectiveness) {
      return false;
    }
    if (mission.comments || mission.typification || mission.reason) {
      return true;
    }
    return false;
  };

  const callTypificationTranslation = (value: string) => {
    if (value === "") return value;

    const translationPath = LastContactsClassification.find((each) => {
      return each.value === value;
    });

    return t(translationPath?.label || "");
  };

  const gapReasonTranslation = (value: string) => {
    if (value === "") return value;

    const translationPath = LAST_CONTACT_REASON.find((each) => {
      return each.value === value;
    });

    return t(translationPath?.label || "");
  };

  /* istanbul ignore next */
  const isTooltipDisabled = (refStateCurrentWidth: number | undefined) => {
    if (
      refStateCurrentWidth &&
      refStateCurrentWidth === MISSION_TAG_TEXT_MAX_WIDTH
    ) {
      return false;
    }
    return true;
  };

  const isLocalOrSponsored = (type: string) => {
    if (type === LOCAL_MISSION || type === SPONSORED_MISSION) return true;

    return false;
  };

  const getTypePrefix = (type: string) => {
    if (type === LOCAL_MISSION) return `${t("MISSIONS.localMissions")} - `;
    if (type === SPONSORED_MISSION) return `${t("MISSIONS.sponsored")} - `;
    return "";
  };

  const getMissionTag = (mission: Mission) => {
    if (!isLocalOrSponsored(mission.type) && mission.tag !== FLEXIBLE_MISSIONS)
      return ConvertAndFormatTag(t, mission.tag.replace(/\s/g, "\u00A0"));

    return `${getTypePrefix(mission.type)}${mission.missionTitle}`;
  };

  const renderMissionTag = (mission: Mission, index: number) => {
    const missionTagText = getMissionTag(mission);

    const missionTagTextRefByIndex =
      missionTagTextRef && missionTagTextRef[index];

    const missionTagTextRefStateCurrentWidth =
      missionTagTextRefState &&
      missionTagTextRefState[index].current?.offsetWidth;

    const tooltipClassesProps = {
      tooltip: classes.tooltipStyle,
      arrow: classes.tooltipArrow,
    };

    const spanStyleProps = {
      maxWidth: `${MISSION_TAG_TEXT_MAX_WIDTH}px`,
    };

    return (
      <Tooltip
        arrow
        placement="top"
        disableHoverListener={isTooltipDisabled(
          missionTagTextRefStateCurrentWidth,
        )}
        title={missionTagText}
        classes={{ ...tooltipClassesProps }}
      >
        <span
          ref={missionTagTextRefByIndex}
          className={classes.withEllipsis}
          style={{ ...spanStyleProps }}
        >
          {missionTagText}
        </span>
      </Tooltip>
    );
  };

  return (
    <Box>
      <Box
        className={
          isNotAccomplished ? classes.notAccomplished : classes.accomplished
        }
      >
        <Typography className={classes.title}>{title}</Typography>
      </Box>

      {missionsFiltered && missionsFiltered.length > 0 && (
        <Typography className={classes.mission}>
          {missionsFiltered.map((mission, index) => {
            return (
              <span
                key={`missions-${index + mission.typification}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {renderMissionTag(mission, index)}
                {renderTooltip(mission) && (
                  <MissionTooltip
                    missionClassification={callTypificationTranslation(
                      mission.tag === "#digital_services"
                        ? ""
                        : mission.typification,
                    )}
                    missionGapReason={gapReasonTranslation(mission.reason)}
                    missionComment={mission.comments}
                  />
                )}
                {`${missionsFiltered.length - 1 > index ? ", " : "  "}`}
                &nbsp;
              </span>
            );
          })}
        </Typography>
      )}
    </Box>
  );
};

export default MissionsTimeline;
