import * as React from "react";

function MissionsSectionIcon(props: any): any {
  return (
    <svg
      width={32}
      height={32}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M2.033 6.372l1.793 1.794a10.973 10.973 0 00-1.304 4.106h3.614a1.227 1.227 0 01.143 2.447l-.143.008H2.522a11.05 11.05 0 009.75 9.751v-3.614a1.227 1.227 0 012.447-.143l.008.143.001 3.614a10.973 10.973 0 004.106-1.304l1.794 1.793A13.437 13.437 0 0113.5 27C6.044 27 0 20.956 0 13.5c0-2.617.744-5.06 2.033-7.128zm1.174-4.887l.115.102 22.091 22.09a1.227 1.227 0 01-1.62 1.838l-.115-.102L1.587 3.323a1.227 1.227 0 011.62-1.838zM13.5 0C20.956 0 27 6.044 27 13.5c0 2.617-.744 5.06-2.033 7.128l-1.793-1.794a10.973 10.973 0 001.304-4.106h-3.614a1.227 1.227 0 01-.143-2.447l.143-.008h3.614a11.05 11.05 0 00-9.75-9.751v3.614a1.227 1.227 0 01-2.447.143l-.008-.143-.001-3.614a10.973 10.973 0 00-4.106 1.304L6.372 2.033A13.428 13.428 0 0113.066.007L13.5 0z"
          id="MissionsSectionIcon__a"
        />
      </defs>
      <g transform="translate(4.5 4.5)" fill="none" fillRule="evenodd">
        <mask id="MissionsSectionIcon__b" fill="#fff">
          <use xlinkHref="#MissionsSectionIcon__a" />
        </mask>
        <use
          fill="#000"
          fillRule="nonzero"
          xlinkHref="#MissionsSectionIcon__a"
        />
        <g mask="url(#MissionsSectionIcon__b)" fill="#000">
          <path d="M-4.5-4.5h36v36h-36z" />
        </g>
      </g>
    </svg>
  );
}

export default MissionsSectionIcon;
