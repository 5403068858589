import React from "react";
import { useStore } from "effector-react";
import { Grid, Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { OFFERINGS_SECTION } from "../../../../config/constants";
import PromotionsIcon from "../../../../assets/newIcons/OfferingsIcon";
import Select from "../../../../components/select/Select";
import { setOfferingSection } from "../../../../stores/navigation/offering/OfferingEvents";
import OfferingStore from "../../../../stores/navigation/offering/OfferingStore";
import { handleIsNewOfferingsUsed } from "../../../../stores/genericInfos/GenericInfosEvents";
import { offeringsDropdownMenuInteraction } from "../../../../config/typewriter";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import OfferingsTitleStyles from "./OferringsTitleStyle";

const OferringsTitle: React.FunctionComponent = () => {
  const classes = OfferingsTitleStyles();
  const { dispatchGenericEvent } = useAnalytics();
  const { t } = useTranslation();
  const { section, options } = useStore(OfferingStore);

  const handleChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const { value } = e.target;

    const tabLabel =
      value === OFFERINGS_SECTION.PROMOTIONS
        ? t("Promotions.CHOOSE_OPTION_DEALS")
        : t("Promotions.NEW_OFFERINGS");

    if (value === OFFERINGS_SECTION.NEW_OFFERINGS) {
      handleIsNewOfferingsUsed(true);
    }
    setOfferingSection(value as OFFERINGS_SECTION);
    dispatchGenericEvent(offeringsDropdownMenuInteraction, {
      tab_label: tabLabel,
    });
  };

  return (
    <Grid item container className={classes.titleContainer}>
      <Box className={classes.titleInnerContainer}>
        <PromotionsIcon />
        <Typography variant="subtitle1" classes={{ root: classes.title }}>
          {t("Promotions.OFFERINGS_TITLE")}
        </Typography>
      </Box>
      <Box>
        <Select
          name="offeringsSectionSelect"
          value={section}
          options={options}
          onChange={(e) => handleChange(e)}
          testId="offerings-select"
          className={classes.select}
          disabled={options.length === 1}
        />
      </Box>
    </Grid>
  );
};

export default OferringsTitle;
