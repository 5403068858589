import React from "react";
import { useStore } from "effector-react";
import SkuCard from "@grow-shared-components/challenge-sku-card";
import { ChallengeSkuListCard } from "@grow-shared-components/challenge-sku-list-card";
import { useTranslation } from "react-i18next";
import useSkuItemsStyle from "./SkuItems.styles";
import { ConvertedSku as SkuType } from "../../../../../../domains/rewardsChallenges/RewardsChallenges";
import { CURRENCY } from "../../../../../../config/constants";
import GlobalStore from "../../../../../../stores/global/GlobalStore";

interface Props {
  data: SkuType[];
  defaultImage: string;
}

const SkuItems = ({ data, defaultImage }: Props): JSX.Element => {
  const classes = useSkuItemsStyle();
  const { t } = useTranslation();
  const { user } = useStore(GlobalStore);

  if (!data.length) return <></>;

  if (data.length <= 2) {
    return (
      <div
        className={classes.cardContainer}
        data-testid="rewards-challenges-sku-card-container"
      >
        {data.map((item) => (
          <SkuCard
            title={item.itemName}
            description={item.sku}
            price={{
              value: item.price,
              prefix: `${CURRENCY[user.zone]?.prefix || "$"}`,
              decimalSeparator: t(`Formater.${user.zone}.DECIMAL_SEPARATOR`),
              thousandSeparator: t(`Formater.${user.zone}.THOUSAND_SEPARATOR`),
            }}
            image={item.itemImage}
            defaultImage={defaultImage}
            conditionText={t(
              "REWARDS_CHALLENGES_MISSION_TAB.SKU_ITEM_REQUIRED_AMOUNT",
            )}
            conditionValue={item.quantity}
            testId={`${item.itemName}${item.sku}`}
            key={item.sku}
          />
        ))}
      </div>
    );
  }

  return (
    <div data-testid="rewards-challenges-sku-list-container">
      <ChallengeSkuListCard
        imgHeader={t("REWARDS_CHALLENGES_MISSION_TAB.SKU_ITEM_IMG_HEADER")}
        nameAndPriceHeader={t(
          "REWARDS_CHALLENGES_MISSION_TAB.SKU_ITEM_NAME_PRICE_HEADER",
        )}
        amountHeader={t(
          "REWARDS_CHALLENGES_MISSION_TAB.SKU_ITEM_REQUIRED_AMOUNT_HEADER",
        )}
        defaultImage={defaultImage}
        testId="sku-list-component"
        items={data}
        priceFormat={{
          prefix: `${CURRENCY[user.zone]?.prefix || "$"}`,
          decimalSeparator: t(`Formater.${user.zone}.DECIMAL_SEPARATOR`),
          thousandSeparator: t(`Formater.${user.zone}.THOUSAND_SEPARATOR`),
        }}
      />
    </div>
  );
};

export default SkuItems;
