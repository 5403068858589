import React from "react";
import { Box, Typography } from "@material-ui/core";
import style from "./ContentStyles";

const Content: React.FunctionComponent<Props> = ({ title, text, testId }) => {
  const classes = style();

  return (
    <Box
      className={classes.container}
      flexDirection="column"
      data-testid={testId}
    >
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.text}>{text}</Typography>
    </Box>
  );
};

Content.defaultProps = {
  testId: "content-component",
};

interface Props {
  title: string;
  text: string;
  testId?: string;
}

export default React.memo(Content);
