import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    title: {
      width: "100%",
      fontFamily: "Work Sans",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
    },
    containerDescription: {
      display: "flex",
      justifyContent: "space-between",
    },
    description: {
      fontFamily: "Work Sans",
      fontSize: ".75rem",
      lineHeight: "1.125rem",
      color: "#757575",
    },
  }),
);
