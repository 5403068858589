import React from "react";
import { useTranslation } from "react-i18next";
import Call from "@material-ui/icons/Call";
import CardInfo from "../../../components/cardInfo/CardInfo";
import cardInfoStyles from "../../../components/cardInfo/CardInfoStyles";

interface Props {
  target?: number | null;
  countKPI?: number | null;
}

function TargetCallList({ target, countKPI }: Props): React.ReactElement {
  const { t } = useTranslation();
  const classes = cardInfoStyles({});

  return (
    <CardInfo
      key="card-info"
      title={t("TargetCallList.TITLE")}
      mainLabel={t("TargetCallList.COMPLETED_TEXT")}
      secondaryLabel={t("TargetCallList.TARGET_TEXT")}
      mainValue={countKPI}
      secondaryValue={target}
      testId="card-call-list"
      actionButton={<Call className={classes.actionButtonIcon} />}
    />
  );
}

TargetCallList.defaultProps = {
  countKPI: undefined,
};

export default TargetCallList;
