import { createStore } from "effector";

import { CartInfo } from "../../domains/cart/CartInfo";
import { CartItem, TotalTrucks } from "../../domains/cart/CartItem";
import { Empty } from "../../domains/cart/Empty";
import { MinimumOrder } from "../../domains/cart/MinimumOrder";
import { SelectedFreeGoods } from "../../domains/cart/SelectedFreeGoods";
import UpdateCanceledError from "../../services/cart/domains/UpdateCanceledError";
import * as CartComboEvents from "../cartCombo/CartComboEvents";
import {
  setIsErrorMessageOrderModalOpen,
  setIsOrderItemOutOfStockOpenModal,
} from "./CartEvents";
import * as CartItemEvents from "./CartItemEvents";
import CartItemState, { getQuantity } from "./CartItemState";

const initialState = <CartItemState>{
  done: false,
  isLoading: false,
  isLoadingBff: false,
  error: false,
  cart: <CartInfo>{},
  totalItems: 0,
  cartItems: [],
  empties: [],
  freeGoods: [],
  selectedFreeGoods: [],
  shouldAddSuggested: true,
  totalPallets: 0,
  totalTrucks: {
    totalItems: 0,
    percentage: 0,
    totalTrucks: 0,
  },
  minimumOrder: <MinimumOrder>{},
  suggestedOrdersLoaded: false,
  promoItensLoaded: false,
  highlightTitle: false,
  deleteIsLoading: false,
};

const findItemIndex = (cartItems: Array<CartItem>, each: CartItem) => {
  return cartItems.findIndex(
    (eachCartItems) => eachCartItems.platformId === each.platformId,
  );
};

const reverseToAddSuggestedAtTop = (suggestedItems: Array<CartItem>) => {
  return suggestedItems.reverse();
};

const mergeItems = (
  cartItem: CartItem,
  newCartItems: Array<CartItem>,
  isAddItem: boolean,
  updateQuantity?: boolean,
) => {
  const maxQuantity = 9999;
  const index = findItemIndex(newCartItems, cartItem);
  let totalQuantity = 0;

  if (index !== -1) {
    const item = { ...newCartItems[index] };
    const quantity = Number(item.itemQuantity);
    if (quantity + cartItem.itemQuantity <= maxQuantity) {
      totalQuantity = updateQuantity
        ? cartItem.itemQuantity
        : quantity + cartItem.itemQuantity;
    } else {
      totalQuantity = maxQuantity;
    }
    item.itemQuantity = totalQuantity;
    newCartItems[index] = { ...item };
  } else if (isAddItem) {
    if (cartItem.originalQuantity) {
      newCartItems.push(cartItem);
    } else {
      cartItem.originalQuantity = cartItem.itemQuantity;
      newCartItems.push(cartItem);
    }
  } else {
    newCartItems.unshift(cartItem);
  }
};

const hasUnavailableitems = (lineItems) => {
  return lineItems?.some((item) => {
    const displayInventoryCountOrAvailabilityCount =
      (item.availabilityCount || item.inventoryCount) ?? 0;

    return (
      displayInventoryCountOrAvailabilityCount === 0 ||
      (displayInventoryCountOrAvailabilityCount !== -1 &&
        item.quantity > displayInventoryCountOrAvailabilityCount)
    );
  });
};

const zeroIfUndefined = (value: number | undefined): number => {
  return value || 0;
};

const effectDone = (
  state: CartItemState,
  result: CartInfo,
  type: string,
): CartItemState => {
  /* istanbul ignore next line */
  const empties =
    result.empties?.emptiesList?.map((each) => {
      const quantity = getQuantity(each, state.empties);
      return {
        groupId: each.groupId,
        quantity,
      };
    }) || [];

  const carts: Array<CartItem> = [];

  /* istanbul ignore next line */
  if (type === "cart-simulation") {
    /* istanbul ignore next line */
    result.lineItems?.forEach((item) => {
      const aux: CartItem = {
        ...item,
        itemQuantity: item.quantity,
        price: zeroIfUndefined(item?.unitPrice),
        unitPrice: zeroIfUndefined(item?.unitPrice),
        total: zeroIfUndefined(item?.total),
        updated: true,
        sku: item?.sku,
        notFound: false,
        id: item.sku,
        platformId: item.platformId,
        itemName: item.itemName,
        inventoryCount: item.inventoryCount,
      };
      carts.push(aux);
    });

    const totalPallets = zeroIfUndefined(result.totalPallets);
    const totalTrucks = calcTrucks(totalPallets, result.maxVehicleCapacity);

    CartComboEvents.addCombosSimulation(result.combos);

    const updatedSelectedFreeGoods = state.selectedFreeGoods.filter((sf) =>
      result.freeGoods?.some((rf) => rf.hashCode === sf.freeGoodHashCode),
    );

    return {
      ...state,
      cart: { ...result },
      totalPallets,
      totalTrucks,
      done: true,
      error: false,
      isLoading: false,
      isLoadingBff: false,
      cartItems: carts,
      empties,
      minimumOrder: {
        isBelowMinimumOrder: Boolean(
          result.minimumOrder &&
            result.minimumOrder.minOrderPercent &&
            result.minimumOrder.minOrderPercent < 100,
        ),
        minOrderMessage: result?.minimumOrder?.minOrderMessage,
      },
      freeGoods: result.freeGoods ?? [],
      selectedFreeGoods: updatedSelectedFreeGoods,
    };
  }

  // eslint-disable-next-line no-unused-expressions
  result.lineItems?.forEach((item) => {
    const lineItem = state.cartItems.find((each) => each.sku === item.sku);

    if (lineItem) {
      const aux: CartItem = {
        ...lineItem,
        itemQuantity: item.quantity,
        price: item?.unitPrice || 0,
        total: item?.total || 0,
        updated: true,
      };
      carts.push(aux);
    }
  });

  const hasError = result?.outputMessages?.find((each) => {
    return each.type === "ERROR";
  });

  if (hasError) {
    setIsErrorMessageOrderModalOpen(true);
  }

  if (hasUnavailableitems(result.lineItems)) {
    setIsOrderItemOutOfStockOpenModal(true);
  }

  return {
    ...state,
    cart: { ...result },
    done: true,
    isLoading: false,
    isLoadingBff: false,
    error: false,
    cartItems: carts,
    empties,
    minimumOrder: {
      isBelowMinimumOrder: Boolean(
        result.minimumOrder &&
          result.minimumOrder.minOrderPercent &&
          result.minimumOrder.minOrderPercent < 100,
      ),
      minOrderMessage: result.minimumOrder?.minOrderMessage,
    },
  };
};

const effectFail = (state: CartItemState, errorWrapper: { error: Error }) => {
  if (errorWrapper.error instanceof UpdateCanceledError) {
    return state;
  }
  return {
    ...state,
    done: true,
    isLoading: false,
    isLoadingBff: false,
    error: true,
  };
};
const effectLoading = (state: CartItemState) => {
  return { ...state, isLoading: true };
};

const effectLoadingBff = (state: CartItemState) => {
  return { ...state, isLoadingBff: true };
};

const cartItemStore = createStore(initialState)
  .on(
    CartItemEvents.setItemCart,
    (state: CartItemState, cartItems: Array<CartItem>) => {
      const newCartItems = [...state.cartItems];
      if (state.shouldAddSuggested) {
        if (newCartItems.length > 0) {
          reverseToAddSuggestedAtTop(cartItems).forEach((each) => {
            mergeItems(each, newCartItems, false);
          });
        } else {
          newCartItems.push(...cartItems);
        }
      }

      const calcPallets =
        state.totalPallets || calcTotalPalletsCart(newCartItems);

      let totalTrucks: TotalTrucks = {};
      /* istanbul ignore next line */
      if (cartItems.length) {
        totalTrucks = calcTrucks(calcPallets, cartItems[0].maxVehicleCapacity);
      }
      return {
        ...state,
        cartItems: [...newCartItems],
        totalPallets: calcPallets,
        totalTrucks,
      };
    },
  )
  .on(CartItemEvents.updateCartEffectV3, effectLoading)
  .on(
    CartItemEvents.updateCartEffectV3.done,
    (state: CartItemState, { result }) => {
      return effectDone(state, result, "others");
    },
  )
  .on(CartItemEvents.updateCartEffectV3.fail, effectFail)
  .on(CartItemEvents.updateCartSimulationEffect, effectLoading)
  .on(
    CartItemEvents.updateCartSimulationEffect.done,
    (state: CartItemState, { result }) => {
      return effectDone(state, result, "cart-simulation");
    },
  )
  .on(CartItemEvents.updateCartSimulationEffect.fail, effectFail)

  .on(CartItemEvents.deleteCartSimulationEffect, (state: CartItemState) => ({
    ...state,
    deleteIsLoading: true,
  }))
  .on(
    CartItemEvents.deleteCartSimulationEffect.done,
    (state: CartItemState) => ({
      ...state,
      deleteIsLoading: false,
    }),
  )
  .on(
    CartItemEvents.deleteCartSimulationEffect.fail,
    (state: CartItemState) => ({
      ...state,
      deleteIsLoading: false,
    }),
  )

  .on(CartItemEvents.updateCartEffect, effectLoading)
  .on(
    CartItemEvents.updateCartEffect.done,
    (state: CartItemState, { result }) => {
      if (!result) {
        return {
          ...state,
          isLoading: false,
        };
      }
      return effectDone(state, result, "cart-simulation");
    },
  )
  .on(CartItemEvents.updateCartEffect.fail, effectFail)
  .on(CartItemEvents.updateSingleCartSimulationEffect, effectLoadingBff)
  .on(
    CartItemEvents.updateSingleCartSimulationEffect.done,
    (state: CartItemState, { result }) => {
      if (!result) {
        return {
          ...state,
          isLoadingBff: false,
        };
      }
      return effectDone(state, result, "cart-simulation");
    },
  )
  .on(CartItemEvents.updateSingleCartSimulationEffect.fail, effectFail)
  .on(CartItemEvents.simulateCartEffect, effectLoadingBff)
  .on(
    CartItemEvents.simulateCartEffect.done,
    (state: CartItemState, { result }) => {
      if (!result) {
        return {
          ...state,
          isLoadingBff: false,
        };
      }
      return effectDone(state, result, "cart-simulation");
    },
  )
  .on(CartItemEvents.simulateCartEffect.fail, effectFail)
  .on(
    CartItemEvents.getCartIdEffect.done,
    (state: CartItemState, { result }) => {
      if (!result) {
        return {
          ...state,
        };
      }
      return effectDone(state, result, "cart-simulation");
    },
  )
  .on(
    CartItemEvents.upsertItemEffect.done,
    (state: CartItemState, { result }) => {
      if (!result) {
        return {
          ...state,
        };
      }
      return effectDone(state, result, "cart-simulation");
    },
  )
  .on(CartItemEvents.clearItemCart, (state: CartItemState) => {
    return {
      ...state,
      empties: [],
      cartItems: [],
      cart: <CartInfo>{},
      totalPallets: 0,
      freeGoods: [],
      selectedFreeGoods: [],
    };
  })
  .on(
    CartItemEvents.changeItemQuantity,
    (
      state: CartItemState,
      cartItem: CartItem & { position: number; toggle?: boolean },
    ) => {
      const newCartItems = [...state.cartItems];
      /* istanbul ignore next line */
      const calcPallets =
        state.totalPallets || calcTotalPalletsCart(newCartItems);

      if (cartItem.toggle) {
        newCartItems[cartItem.position].updated = false;

        const newCartItemsAux = newCartItems.map((item) =>
          item.platformId === cartItem.platformId &&
          item.price === cartItem.price
            ? cartItem
            : item,
        );

        return {
          ...state,
          cartItems: [...newCartItemsAux],
          totalPallets: calcPallets,
          totalTrucks: calcTrucks(calcPallets, cartItem.maxVehicleCapacity),
        };
      }

      newCartItems[cartItem.position] = cartItem;
      newCartItems[cartItem.position].updated = false;

      return {
        ...state,
        cartItems: [...newCartItems],
        totalPallets: calcPallets,
        totalTrucks: calcTrucks(calcPallets, cartItem.maxVehicleCapacity),
      };
    },
  )
  .on(
    CartItemEvents.removeItem,
    (
      state: CartItemState,
      cartItem: CartItem & {
        position: number;
        toggle?: boolean;
      },
    ) => {
      const newCartItems = [...state.cartItems];

      if (cartItem.toggle) {
        const newCartItemsAux = newCartItems.map((item) => {
          if (
            item.platformId === cartItem.platformId &&
            item.price === cartItem.price
          ) {
            item.itemQuantity = 0;
          }
          return item;
        });

        const calcPallets =
          state.totalPallets || calcTotalPalletsCart(newCartItems);

        return {
          ...state,
          cartItems: [...newCartItemsAux],
          totalPallets: calcPallets,
          totalTrucks: calcTrucks(calcPallets, cartItem.maxVehicleCapacity),
        };
      }

      newCartItems.splice(cartItem.position, 1);
      const calcPallets = calcTotalPalletsCart(newCartItems);
      return {
        ...state,
        cartItems: [...newCartItems],
        totalPallets: calcPallets,
        totalTrucks: calcTrucks(calcPallets, cartItem.maxVehicleCapacity),
      };
    },
  )
  .on(CartItemEvents.addItem, (state: CartItemState, cartItem: CartItem) => {
    const newCartItems = [...state.cartItems];
    mergeItems(cartItem, newCartItems, true);
    const calcPallets =
      state.totalPallets || calcTotalPalletsCart(newCartItems);
    return {
      ...state,
      cartItems: [...newCartItems],
      totalPallets: calcPallets,
      totalTrucks: calcTrucks(calcPallets, cartItem.maxVehicleCapacity),
    };
  })
  .on(
    CartItemEvents.updateOrAddItem,
    (state: CartItemState, cartItem: CartItem) => {
      const newCartItems = [...state.cartItems];
      mergeItems(cartItem, newCartItems, true, true);

      const calcPallets =
        state.totalPallets || calcTotalPalletsCart(newCartItems);
      return {
        ...state,
        cartItems: [...newCartItems],
        totalPallets: calcPallets,
        totalTrucks: calcTrucks(calcPallets, cartItem.maxVehicleCapacity),
      };
    },
  )
  .on(
    CartItemEvents.setTotalItems,
    (state: CartItemState, totalItems: number) => {
      return {
        ...state,
        totalItems,
      };
    },
  )
  .on(
    CartItemEvents.setShouldAddSuggested,
    (state: CartItemState, shouldSetSuggested: boolean) => {
      return {
        ...state,
        shouldAddSuggested: shouldSetSuggested,
      };
    },
  )
  .on(CartItemEvents.setEmpty, (state: CartItemState, empty: Empty) => {
    const empties = [
      ...(state.empties?.filter((e) => e.groupId !== empty.groupId) || []),
      empty,
    ];
    return {
      ...state,
      empties,
    };
  })
  .on(
    CartItemEvents.setSuggestedOrdersLoaded,
    (state: CartItemState, suggestedOrdersLoaded: boolean) => {
      return {
        ...state,
        suggestedOrdersLoaded,
      };
    },
  )
  .on(
    CartItemEvents.setPromoItensLoaded,
    (state: CartItemState, promoItensLoaded: boolean) => {
      return {
        ...state,
        promoItensLoaded,
      };
    },
  )
  .on(
    CartItemEvents.setHighlightTitle,
    (state: CartItemState, highlightTitle: boolean) => {
      return {
        ...state,
        highlightTitle,
      };
    },
  )
  .on(
    CartItemEvents.editSelectedFreeGoodItem,
    (state: CartItemState, freeGoodItem: SelectedFreeGoods) => {
      if (freeGoodItem.quantity < 1) {
        const filteredFreeGoods = [
          ...state.selectedFreeGoods.filter(
            (f) =>
              f.freeGoodHashCode !== freeGoodItem.freeGoodHashCode ||
              f.itemPlatformId !== freeGoodItem.itemPlatformId,
          ),
        ];

        return {
          ...state,
          highlightTitle: false,
          selectedFreeGoods: filteredFreeGoods,
        };
      }

      const updatedFreeGoods = state.selectedFreeGoods.map((f) => {
        if (
          f.itemPlatformId === freeGoodItem.itemPlatformId &&
          f.freeGoodHashCode === freeGoodItem.freeGoodHashCode
        ) {
          return { ...f, quantity: freeGoodItem.quantity };
        }
        return f;
      });

      if (
        !updatedFreeGoods.some(
          (f) =>
            f.itemPlatformId === freeGoodItem.itemPlatformId &&
            f.freeGoodHashCode === freeGoodItem.freeGoodHashCode,
        )
      ) {
        updatedFreeGoods.push(freeGoodItem);
      }

      return {
        ...state,
        highlightTitle: false,
        selectedFreeGoods: updatedFreeGoods,
      };
    },
  )
  .reset(CartItemEvents.resetItemCart);

const calcTotalPalletsCart = (itemsCart: Array<CartItem>): number => {
  return itemsCart
    .filter((cart) => cart.itemQuantity !== 0)
    .reduce(
      (prevValue, item) =>
        // eslint-disable-next-line no-bitwise
        prevValue +
        (item.palletQuantity
          ? Math.floor(item.itemQuantity / item.palletQuantity)
          : 0),
      0,
    );
};

const calcTrucks = (totalPallets: number, valuePerTruck = 0) => {
  if (!valuePerTruck) {
    return {};
  }
  const totalTrucks = Math.floor(totalPallets / valuePerTruck) + 1;

  const totalItems = valuePerTruck * totalTrucks;

  const percentage = Math.floor((totalPallets * 100) / totalItems);

  return {
    totalItems,
    percentage,
    totalTrucks,
  };
};

export default cartItemStore;
