import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const autoDialerOnStyles = makeStyles<Theme>(() =>
  createStyles({
    containerBox: {
      display: "flex",
      flexDirection: "column",
      border: "1px solid #ccc",
      borderRadius: "15px",
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.16)",
      overflowX: "auto",
      height: "auto",
      padding: "16px 16px 24px 16px",
      margin: "8px",
    },
    title: {
      display: "flex",
      padding: "0px",
      alignSelf: "left",
    },
    overwriteTitle: {
      fontFamily: "Barlow !important",
      fontWeight: 600,
      fontSize: "16px",
      marginBottom: "16px",
    },
    text: {
      fontFamily: "Work Sans",
      fontSize: "14px",
      lineHeight: "20px",
      maxWidth: "250px !important",
    },
    cardContent: {
      gap: "16px",
      display: "flex",
      flexDirection: "row",
    },
    statusBullet: {
      height: "16px",
      width: "16px",
      borderRadius: "100%",
      marginBottom: "-3px",
      marginLeft: "2px",
      display: "inline-block",
      backgroundColor: `#1F8E4A`,
    },
    stopCalling: {
      minWidth: "114px",
      padding: "8px 16px",
      height: "32px",
      borderRadius: "16px",
      display: "flex",
      cursor: "pointer",
      textTransform: "none",
      backgroundColor: "black",
      fontFamily: "Work Sans",
      fontSize: "14px",
    },
  }),
);

export default autoDialerOnStyles;
