import React from "react";

function RedeemItemsIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <defs>
        <path
          id="RedeemItemsIcon-1"
          d="M8.24 0c.442 0 .811.333.855.771l.624 6.231a2.232 2.232 0 011.44 2.084V12h1.717V9.086c0-.95.6-1.764 1.44-2.084l.625-6.23A.857.857 0 0115.795 0h2.747c.441 0 .81.333.854.771l.624 6.231a2.232 2.232 0 011.44 2.084V12h1.717c.474 0 .859.384.859.857v10.286a.858.858 0 01-.859.857H.858A.858.858 0 010 23.143V12.857C0 12.384.385 12 .858 12h1.717V9.086c0-.95.6-1.764 1.44-2.084L4.64.772A.857.857 0 015.494 0zM22.32 13.714H1.717v8.572h20.602v-8.572zm-7.726 1.715a.858.858 0 110 1.714h-5.15a.858.858 0 110-1.714h5.15zm3.173-13.715h-1.194l-.61 6.086a.857.857 0 01-.854.771.515.515 0 00-.515.515V12h5.15V9.086a.515.515 0 00-.514-.515.857.857 0 01-.854-.771l-.61-6.086zm-10.301 0H6.27L5.66 7.8a.857.857 0 01-.854.771.515.515 0 00-.515.515V12h5.15V9.086a.515.515 0 00-.514-.515.857.857 0 01-.855-.771l-.608-6.086z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-334 -289)">
          <g transform="translate(334 93)">
            <g transform="translate(0 194)">
              <g transform="translate(0 2)">
                <g transform="translate(4.006 4)">
                  <mask id="RedeemItemsIcon-mask-2" fill="#fff">
                    <use xlinkHref="#RedeemItemsIcon-1" />
                  </mask>
                  <use
                    fill="#000"
                    fillRule="nonzero"
                    xlinkHref="#RedeemItemsIcon-1"
                  />
                  <g fill="#000" mask="url(#RedeemItemsIcon-mask-2)">
                    <path
                      d="M0 0H32.048V32H0z"
                      transform="translate(-4.006 -4)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RedeemItemsIcon;
