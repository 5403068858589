import React, { useState } from "react";
import { Typography } from "@material-ui/core";

import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { CreditCard } from "@hexa-ui/icons";
import {
  GROW_LOCAL_HEADER_REDESIGN,
  isFeatureEnabledV2,
  GROW_ADMIN_LOCAL_MISSIONS_STEP2,
} from "../../../../../config/featureToggles";
import CollapsibleTitle from "../../../../../components/collapsableTitle/CollapsibleTitle";

import { changeEffectivenessByMissionEvent } from "../../../../../stores/globalAndLocalMissions/EffectivenessMissionEvents";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import { SponsoredMission } from "../../../../../domains/sponsored/SponsoredMission";

import MegaPhoneIcon from "../../../../../assets/newIcons/MegaPhoneIcon";
import CoverageTypeIcon from "../../../../../assets/CoverageTypeIcon";
import SimpleTypeIcon from "../../../../../assets/SimpleTypeIcon";
import CheckWithDot from "../../../../../assets/newIcons/CheckWithDot";
import SponsoredComponentStyles from "../SponsoredComponent.styles";
import useGlobalAndLocalComponent from "../hooks/useGlobalAndLocalComponent";
import { SponsoredCardFooter } from "../../sponsoredCard/components/SponsoredCardFooter";
import EffectivenessMissionStore from "../../../../../stores/globalAndLocalMissions/EffectivenessMissionStore";
import { MISSION_TYPES } from "../../../../../config/constants";
import { EFFECTIVENESS_TYPES } from "../../../../../domains/flexibleMission/FlexibleMission";
import Data from "../../../../../components/data/Data";

interface Props {
  mission: SponsoredMission;
  index: number;
  children?: React.ReactNode;
  setOpen: React.Dispatch<React.SetStateAction<number>>;
  openSponsoredMissionIndex: number;
}

const CollapseTitleGlobalAndLocal = ({
  mission,
  index,
  children,
  setOpen,
  openSponsoredMissionIndex,
}: Props): JSX.Element => {
  const classes = SponsoredComponentStyles();
  const {
    handleMarkAndUnmarkAnalytics,
    isLocalCallSumaryEnabled,
    handleOpenOrCloseSponsoredMission,
  } = useGlobalAndLocalComponent();
  const { t } = useTranslation();

  const { user } = useStore(GlobalStore);
  const { effectivenessMissions } = useStore(EffectivenessMissionStore);

  const effectivenessMission = effectivenessMissions.find(
    (effectivenessMission) => {
      return effectivenessMission.missionId === mission.id;
    },
  );

  const initialAcceptedStatus = effectivenessMission?.effectiveness
    ? effectivenessMission.effectiveness
    : false;

  const [accepted, setAccepted] = useState(initialAcceptedStatus);

  const isHeaderRedesign = isFeatureEnabledV2(
    GROW_LOCAL_HEADER_REDESIGN,
    user.keyToggle,
  );

  const isLOCALMISSIONSSTEP2Enabled = isFeatureEnabledV2(
    GROW_ADMIN_LOCAL_MISSIONS_STEP2,
    user.zone,
  );

  const isFlexibleMission = mission.missionType === MISSION_TYPES.FLEXIBLE;

  const handleSetPocAccepted = () => {
    changeEffectivenessByMissionEvent(mission.id);
    setAccepted(!accepted);
    handleMarkAndUnmarkAnalytics(accepted, mission);
  };

  const renderMissionTotal = (mission: SponsoredMission) => {
    if (
      mission.effectiveness?.type === "AT_LEAST" &&
      mission.effectiveness?.quantity
    ) {
      return (
        <Typography
          variant="h3"
          data-testid="custom-mission-effectiveness-total"
        >
          {`${t("LocalMission.MINIMUM_TOTAL_QUANTITY")} ${
            mission.effectiveness?.quantity
          }.`}
        </Typography>
      );
    }
    return <div />;
  };

  const renderIcon = (missionType: string, accepted: boolean) => {
    if (!isHeaderRedesign) {
      return <MegaPhoneIcon />;
    }
    if (!accepted) {
      const type = missionType.toUpperCase();
      switch (type) {
        case MISSION_TYPES.SIMPLE:
          return (
            <div className={classes.icon}>
              <SimpleTypeIcon />
            </div>
          );

        case MISSION_TYPES.COVERAGE:
          return (
            <div className={classes.icon}>
              <CoverageTypeIcon />
            </div>
          );

        case MISSION_TYPES.FLEXIBLE:
          return (
            <div className={classes.icon} data-testid="creditCard">
              <CreditCard size="medium" />
            </div>
          );
        default:
          return <MegaPhoneIcon size="22px" />;
      }
    }

    return <CheckWithDot />;
  };

  const renderFlexibleMissionFooter = () => {
    if (
      mission.flexibleEffectiveness?.type ===
      EFFECTIVENESS_TYPES.CREDIT_AS_PAYMENT
    ) {
      return (
        <div
          className={classes.flexibleFooter}
          data-testid="flexibe-footer-container"
        >
          <Typography
            variant="subtitle1"
            data-testid="flexible-payment-subtitle"
          >
            <b>{t("LocalMission.PAYMENT_METHOD")}</b>
            {t("LocalMission.CREDIT")}
          </Typography>
          {Number(mission.flexibleEffectiveness?.value) > 0 && (
            <Typography
              variant="subtitle1"
              data-testid="flexible-suggested-subtitle"
            >
              <b>{t("LocalMission.SUGGESTED_VALUE")}</b>
              <Data
                value={Number(mission.flexibleEffectiveness?.value)}
                type="currency"
                testId="flexible-suggested-value-currency"
              />
            </Typography>
          )}
        </div>
      );
    }

    return <div data-testid="empty-div" />;
  };

  return (
    <CollapsibleTitle
      key={mission.id}
      icon={renderIcon(mission.missionType, accepted)}
      title={mission.missionTitle}
      alternativeTitleStyle={classes.sponsoredTitleCollapse}
      testId="global-mission-collapse"
      open={openSponsoredMissionIndex === index}
      handleOpen={() =>
        handleOpenOrCloseSponsoredMission(
          index,
          openSponsoredMissionIndex,
          setOpen,
          mission.missionTitle,
        )
      }
      hasError={false}
      hasSeparator={false}
      isRedesign={isHeaderRedesign}
    >
      {children}
      {isLocalCallSumaryEnabled && (
        <div className={classes.footer}>
          {isLOCALMISSIONSSTEP2Enabled &&
            !isFlexibleMission &&
            renderMissionTotal(mission)}
          {isFlexibleMission && renderFlexibleMissionFooter()}
          <SponsoredCardFooter
            handleSetPocAccepted={handleSetPocAccepted}
            pocAccepted={accepted}
          />
        </div>
      )}
    </CollapsibleTitle>
  );
};

export default CollapseTitleGlobalAndLocal;
