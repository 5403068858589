import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    gapBoardContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
    },
    card: {
      flex: 1,
    },
    cardLeft: {
      marginRight: "15px",
    },
    cardRight: {
      marginLeft: "15px",
    },
    gapValue: {
      fontWeight: 500,
      fontSize: "13px !important",
      color: palette.common.black,
      margin: "0 8px 0 0",
      flex: 1,
    },
    gridContainer: {
      marginTop: "0",
      padding: "16px",
      height: "72px",
      alignItems: "center",
    },
    divider: {
      margin: "0 2px",
    },
    sufix: {
      fontSize: "11px",
    },
    dividerContainer: {
      padding: "8px 0",
    },
    data: {
      paddingLeft: "4px",
      fontWeight: 500,
      fontSize: "13px !important",
    },
    gapValueContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    otherValues: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      fontWeight: "normal",
      color: palette.text.primary,
      fontSize: "12px",
      maxWidth: 200,
    },
    loadingContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "127px",
    },
    title: {
      fontWeight: 600,
      color: palette.error.main,
      lineHeight: "normal",
    },
    subtitle: {
      fontWeight: 500,
      fontSize: "12px",
      color: palette.primary.main,
      lineHeight: "normal",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
    measure: {
      fontSize: "12px",
      fontWeight: 500,
      color: palette.text.secondary,
      paddingLeft: "2px",
      lineHeight: "normal",
    },
    loading: {
      alignSelf: "center",
    },
  }),
);
