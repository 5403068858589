import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    card: {
      backgroundColor: palette.common.white,
      display: "flex",
      flexDirection: "column",
      height: "auto",
      alignItems: "center",
      padding: "24px",
      borderRadius: "15px",
      border: "1px solid rgba(0, 0, 0, 0.16)",
      "& .tlp-MuiTypography-root": {
        marginBottom: "8px",
      },
    },
    description: {
      color: palette.text.secondary,
      lineHeight: "20px",
      fontSize: "14px",
    },
    button: {
      height: "40px",
      minWidth: "127px",
      padding: "0 28px",
    },
  }),
);
