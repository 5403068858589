import { MutableRefObject } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { CheckoutInfoV2 } from "domains/CheckoutInfoV2";
import { FreeGood } from "services/checkout/domains/CheckoutRequest";
import { SelectedFreeGoods } from "domains/cart/SelectedFreeGoods";
import { getVendorName } from "grow-shared-services";
import { OrderComboItem } from "../../../../../services/checkout/domains/v1Mobile/CheckoutRequestV1Mobile";
import { ComboItem } from "../../../../../services/checkout/domains/v2/CheckoutRequestV2";
import { CheckoutDomainV2 } from "../../../../../services/checkout/domains/v2/CheckoutDomainV2";
import { update } from "../../../../../usecase/agentCall/AgentCallUseCase";
import { usageTypes } from "../../../../../config/usageTypes";
import { orderCompletedEventData } from "../CheckoutEvents";
import { CURRENCY, CHANNEL } from "../../../../../config/constants";
import {
  checkoutV2Effect,
  checkoutV3Effect,
} from "../../../../../stores/checkout/CheckoutEvents";
import {
  clearComboCart,
  resetCombos,
} from "../../../../../stores/cartCombo/CartComboEvents";
import { clearItemCart } from "../../../../../stores/cart/CartItemEvents";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_ORDER_TAKING_EMPTIES,
  TLP_ORDER_TAKING_EMPTIES_ADMIN,
  TLP_MULTI_VENDOR,
  TLP_MULTI_VENDOR_ADMIN,
  GROW_CHECKOUT_V3,
  GROW_WIP_FREE_GOODS,
} from "../../../../../config/featureToggles";
import {
  orderCompleted,
  productValidationMessageViewed,
} from "../../../../../config/typewriter";
import { updateCartForm } from "../../../../../stores/cart/CartEvents";
import TagsStore from "../../../../../stores/tags/TagsStore";
import loyaltyBusinessStore from "../../../../../stores/loyaltyBusiness/LoyaltyBusinessStore";
import CartItemStore from "../../../../../stores/cart/CartItemStore";
import CartStore from "../../../../../stores/cart/CartStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import PocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import { CartForm } from "../../../../../stores/cart/CartState";
import GlobalAdminConfigStore from "../../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import { getValueEmpty } from "../../../../../config/utils/functions";
import { CheckoutDomain } from "../../../../../services/checkout/domains/CheckoutDomain";
import { CheckoutInfo } from "../../../../../domains/CheckoutInfo";
import CustomToast, {
  TOAST_TYPES,
  toastTypes,
} from "../../../../../components/CustomToast/CustomToast";
import { DELIVERY_METHODS } from "../../../../../domains/fulfillment/Fulfillment";
import DeliveryWindowsStore from "../../../../../stores/deliveryWindows/DeliveryWindowsStore";
import {
  returnCartId,
  returnMessage,
  returnOrderNumber,
  returnDeliveryDate,
  returnValueOrZero,
  returnPaymentTerm,
  getEmptiesCheckoutV2,
  callTrigger,
  payload,
} from "./utils/placementOrders";

type useRequestToPlaceOrderType = () => {
  sendRequestToPlaceOrder: (
    technicalProblem: boolean,
    orderReason: string,
    rangeAlternativeDates: Array<string>,
    getCombosRequest: () => OrderComboItem[] | ComboItem[],
    isAlternativeDateSelected: (value?: string | undefined) => boolean,
    cartFormRef: MutableRefObject<CartForm>,
  ) => void;
};

export const useRequestToPlaceOrder: useRequestToPlaceOrderType = () => {
  const TagStates = useStore(TagsStore);
  const { totalPoints } = useStore(loyaltyBusinessStore);
  const CartItemStates = useStore(CartItemStore);
  const CartStates = useStore(CartStore);
  const { dispatchPocEvent, dispatchGenericEvent } = useAnalytics();
  const { user } = useStore(GlobalStore);
  const { pocInformation } = useStore(PocInformationStore);
  const AgentCallStates = useStore(AgentCallStore);
  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);
  const { deliveryMethod } = useStore(DeliveryWindowsStore);

  const { t } = useTranslation();

  const { cart } = CartItemStates;

  const isOrderTakingEmptiesEnabled = isFeatureEnabled(
    TLP_ORDER_TAKING_EMPTIES,
    TLP_ORDER_TAKING_EMPTIES_ADMIN,
    user.keyToggle,
  );

  const empties = cart?.empties;

  const isMultiVendor = isFeatureEnabled(
    TLP_MULTI_VENDOR,
    TLP_MULTI_VENDOR_ADMIN,
  );

  const isCheckoutV3Enable = isFeatureEnabledV2(
    GROW_CHECKOUT_V3,
    user.keyToggle,
  );

  const isGrowWipFreeGoodsEnable = isFeatureEnabledV2(
    GROW_WIP_FREE_GOODS,
    user.keyToggle,
  );

  const hasAlternativeDates = (
    rangeAlternativeDates: Array<string>,
  ): boolean => {
    return rangeAlternativeDates.length > 0;
  };

  type payloadWatcher = {
    params: CheckoutDomainV2 | CheckoutDomain;
    result: CheckoutInfoV2 | CheckoutInfo;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  const checkDeliveryMethod = (obj: any) => {
    if (deliveryMethod === DELIVERY_METHODS.DELIVERY_RANGE) {
      return null;
    }
    return obj;
  };

  const deliveryWindowId = checkDeliveryMethod(
    getValueEmpty(CartStates.cartForm.deliveryWindowId),
  );

  const updateCartAfterSuccessPurchage = (cartFormRef) => {
    clearItemCart();
    resetCombos();
    clearComboCart();
    updateCartForm({
      ...cartFormRef.current,
      details: "",
    });
  };

  const checkoutWatcher: (payload: payload) => void = ({
    result,
    bodyCheckout,
    isAlternativeDateSelected,
    rangeAlternativeDates,
    orderReason,
    technicalProblem,
    removeDoneWatcher,
    removeFailWatcher,
    cartFormRef,
  }) => {
    const cartId = returnCartId(isCheckoutV3Enable, result);
    const analyticsData = orderCompletedEventData(bodyCheckout, cartId);

    callToastPlacementOrder(result);
    const orderNumber = returnOrderNumber(isCheckoutV3Enable, result) ?? cartId;

    dispatchPocEvent(orderCompleted, {
      ...analyticsData,
      poc_potential: null,
      overdue: returnValueOrZero(pocInformation?.creditInfo?.overdue),
      is_alternative_date: isAlternativeDateSelected(),
      is_alternative_date_available: hasAlternativeDates(rangeAlternativeDates),
      has_overprice: !!CartItemStates.cart?.overprice,
    });

    if (isMultiVendor) {
      const { vendorId } = GlobalAdminConfigState.userConfig;
      const vendorName = getVendorName(vendorId);
      update(
        {
          ...AgentCallStates,
          orderInfo: {
            orderId: orderNumber,
            orderReason,
          },
          hasTechnicalProblem: technicalProblem,
          usage: usageTypes.CHECKOUT,
          tagsList: TagStates.list,
          sponsoredMissionsIds: [],
        },
        pocInformation,
        vendorId,
        vendorName,
      );
    } else {
      update(
        {
          ...AgentCallStates,
          orderInfo: {
            orderId: orderNumber,
            orderReason,
          },
          hasTechnicalProblem: technicalProblem,
          usage: usageTypes.CHECKOUT,
          sponsoredMissionsIds: [],
          tagsList: TagStates.list,
        },
        pocInformation,
      );
    }

    updateCartAfterSuccessPurchage(cartFormRef);

    /* istanbul ignore next */
    if (typeof removeDoneWatcher === "function") {
      removeDoneWatcher();
      removeFailWatcher();
    }
  };

  const mountBodyCheckout = (values, analytics) => {
    return {
      request: {
        channel: CHANNEL,
        paymentMethod: getValueEmpty(CartStates.cartForm.paymentMethod),
        paymentTerm: returnPaymentTerm(
          CartStates.cartForm.paymentMethod,
          CartStates.cartForm.paymentTerm,
        ),
        ...values,
      },
      analytics,
    };
  };

  const mountBodyCheckoutV3 = (values, analytics): CheckoutDomain => {
    const getFreeGoods = () => {
      let freeGoods: FreeGood[];
      if (isGrowWipFreeGoodsEnable) {
        freeGoods = CartItemStates.selectedFreeGoods
          .filter((selectedFreeGood) => selectedFreeGood.quantity > 0)
          .map((selectedFreeGood) => {
            const mappedFreeGood: FreeGood = {
              id: selectedFreeGood.itemPlatformId,
              quantity: selectedFreeGood.quantity,
              discount: { hashcode: selectedFreeGood.freeGoodHashCode },
            };
            return mappedFreeGood;
          });
        return freeGoods;
      }
      return [];
    };

    return {
      request: {
        accountId: values.accountId,
        channel: CHANNEL,
        note: values.note,
        carts: [
          {
            cartId: values.cartId,
            deliveryWindowId,
            payment: {
              paymentMethod: getValueEmpty(CartStates.cartForm.paymentMethod),
              paymentTerm: returnPaymentTerm(
                CartStates.cartForm.paymentMethod,
                CartStates.cartForm.paymentTerm,
              ),
              paymentMethodCode: CartStates.cartForm?.paymentMethodCode,
            },
            poDate: values.poDate,
            poNumber: CartStates.cartForm.poNumber,
            freeGood: getFreeGoods(),
          },
        ],
      },
      analytics,
    };
  };

  const mountyBodyByVersion = (
    analytics,
    getCombosRequest: () => OrderComboItem[] | ComboItem[],
    orderReason?,
  ) => {
    const requestItemsV2 = cart.lineItems?.map((each) => {
      return {
        quantity: each.quantity,
        id: each.id,
      };
    });

    const currentDate = new Date().toISOString().split("T")[0];

    const deliveryRangeObj = {
      date: returnDeliveryDate(CartStates.cartForm.deliveryDate, currentDate),
      note: CartStates.cartForm.details,
    };

    const delivery = checkDeliveryMethod(deliveryRangeObj);

    let bodyCheckout = mountBodyCheckout(
      {
        accountId: getValueEmpty(AgentCallStates.clientId),
        poDate: currentDate,
        delivery,
        items: requestItemsV2,
        ...getEmptiesCheckoutV2(isOrderTakingEmptiesEnabled, empties),
        combos: getCombosRequest() as OrderComboItem[],
        payment: {
          paymentMethodCode: CartStates.cartForm.paymentMethodCode,
        },
      },
      analytics,
    );

    if (isCheckoutV3Enable) {
      bodyCheckout = mountBodyCheckoutV3(
        {
          accountId: getValueEmpty(AgentCallStates.clientId),
          poNumber: new Date().toISOString().replace(/[a-zA-Z-:.]/g, ""),
          poDate: currentDate,
          cartId: CartItemStates.cart.cartId,
          note: orderReason,
        },
        analytics,
      );
    }

    checkoutEffectVersion()(bodyCheckout);

    return bodyCheckout;
  };

  const sendRequestToPlaceOrder = (
    technicalProblem: boolean,
    orderReason: string,
    rangeAlternativeDates: Array<string>,
    getCombosRequest: () => OrderComboItem[] | ComboItem[],
    isAlternativeDateSelected: (value?: string) => boolean,
    cartFormRef: MutableRefObject<CartForm>,
  ) => {
    const selectedFreeGoods: Array<SelectedFreeGoods> = [];
    if (CartItemStates.selectedFreeGoods) {
      CartItemStates.selectedFreeGoods
        .filter((selectedFreeGood) => selectedFreeGood.quantity > 0)
        .forEach((selectedFreeGood) =>
          selectedFreeGoods.push(selectedFreeGood),
        );
    }

    const analytics = {
      callId: AgentCallStates.callId,
      isResumed: AgentCallStates.isResumed,
      callTrigger: callTrigger(AgentCallStates.orderSku),
      currency: CURRENCY[user.zone]?.currency,
      hadTechnicalProblem: technicalProblem,
      reasonOfTheOrder: orderReason,
      deliveryCenterId: pocInformation.deliveryCenterId,
      segment: pocInformation.segment,
      subSegment: pocInformation.subSegment,
      totalGap: AgentCallStates.gap?.topline?.total,
      totalVolGap: AgentCallStates.gap?.volume?.total,
      creditAvailable: pocInformation.availableCredit,
      cartItems: CartItemStates.cartItems,
      discount: cart.discountAmount,
      paymentMethod: CartStates.cartForm.paymentMethod,
      revenue: cart.subtotal,
      tax: cart.taxAmount,
      details: CartStates.cartForm.details,
      pointsLost: totalPoints,
      tags: TagStates.list,
      accumulativeDiscount: pocInformation.accumulativeDiscount,
      shippingCases: CartItemStates.totalItems,
      shippingPallets: CartItemStates.totalPallets,
      shippingTrucks: CartItemStates.totalTrucks?.totalTrucks,
      freeGood: selectedFreeGoods,
    };

    const bodyCheckout = mountyBodyByVersion(
      analytics,
      getCombosRequest,
      orderReason,
    );

    const dispatchCheckoutWatcher = (payload: payloadWatcher) => {
      checkoutWatcher({
        ...payload,
        bodyCheckout,
        isAlternativeDateSelected,
        rangeAlternativeDates,
        orderReason,
        technicalProblem,
        removeDoneWatcher,
        removeFailWatcher,
        cartFormRef,
      });
    };

    /* istanbul ignore next */
    const removeDoneWatcher = checkoutEffectVersion().done.watch(
      dispatchCheckoutWatcher,
    );

    const removeFailWatcher = checkoutEffectVersion().fail.watch(() => {
      /* istanbul ignore next */
      if (typeof removeFailWatcher === "function") {
        removeDoneWatcher();
        removeFailWatcher();
      }
    });
  };

  const checkoutEffectVersion = () => {
    return isCheckoutV3Enable ? checkoutV3Effect : checkoutV2Effect;
  };

  const callToastPlacementOrder = (result: CheckoutInfo | CheckoutInfoV2) => {
    const orderNumber = returnOrderNumber(isCheckoutV3Enable, result);

    if (orderNumber) {
      CustomToast({
        type: TOAST_TYPES.SUCCESS,
        message: t("OrderTaking.MSG_ORDER_SUCCESS").replace("X", orderNumber),
      });
    }

    const messages = returnMessage(isCheckoutV3Enable, result);

    if (messages) {
      messages.forEach((checkoutMessage) => {
        CustomToast({
          type: toastTypes[checkoutMessage.type?.toLowerCase()],
          message: checkoutMessage.text,
        });

        dispatchGenericEvent(productValidationMessageViewed, {
          call_id: AgentCallStates.callId,
          poc_id: AgentCallStates.clientId,
          message: checkoutMessage.text,
          message_type: checkoutMessage.type,
          message_subtype: null,
        });
      });
    }
  };

  return {
    sendRequestToPlaceOrder,
  };
};
