import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    boxContent: {
      borderRadius: "24px",
      backgroundColor: palette.common.white,
      boxShadow: "0 0 0 1px rgba(0,0,0,0.16)",
      alignItems: "center",
      display: "flex",
      "&& input": {
        number: {
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              "-webkit-appearance": "none",
              margin: 0,
            },
        },
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0,
        },
        "&:focus": {
          outline: "none",
        },
      },
    },
    defaultBoxContentSize: {
      maxWidth: "106px",
      minWidth: "100px",
      height: "32px",
    },
    orderViewBoxContentSize: {
      width: "122px",
      height: "34px",
    },
    iconCircle: {
      width: "24px",
      height: "24px",
      backgroundColor: "rgba(0,0,0,0.05)",
      margin: "4px",
      borderRadius: "24px",
      border: "none",
      "&:hover": {
        cursor: "pointer",
      },
    },
    quantity: {
      height: "24px",
      width: "100%",
      color: "#000000",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
      letterSpacing: "0",
      textAlign: "center",
      border: "none",
      background: "transparent",
    },
    buttonDisabled: {
      pointerEvents: "none",

      "& > svg": {
        opacity: 0.2,
      },
    },
    inputDisabled: {
      color: "#999999",
      pointerEvents: "none",
    },
  }),
);
