import {
  EnrolledSinceCovertedReturn,
  EnrolledSinceResponse,
} from "../../domains/redeem/Redeem";

const EnrolledSinceResponseToEnrolledSinceConverter = (
  data: EnrolledSinceResponse,
): EnrolledSinceCovertedReturn => {
  return {
    enrolledSince: data?.dateRegistered,
  };
};

export default EnrolledSinceResponseToEnrolledSinceConverter;
