import { createStore } from "effector";
import * as PromotionsEvents from "./PromotionsEvents";
import { PromotionsStateV3 } from "./PromotionsState";

const initialState = <PromotionsStateV3>{
  combos: [],
  promotions: [],
  done: false,
  error: false,
  searchText: "",
  searchResults: { combos: [], promotions: [] },
};

const PromotionsStore = createStore(initialState)
  .on(PromotionsEvents.getPromotionsV3Effect.done, (state, { result }) => {
    return {
      ...state,
      combos: [...result.combos],
      promotions: [...result.promotions],
      done: true,
      error: false,
    };
  })
  .on(PromotionsEvents.getPromotionsV3Effect, (state) => ({
    ...state,
    done: false,
    error: false,
  }))
  .on(PromotionsEvents.getPromotionsV3Effect.fail, (state) => ({
    ...state,
    done: true,
    error: true,
  }))
  .on(PromotionsEvents.onSearch, (state, searchData) => ({
    ...state,
    searchText: searchData.searchText,
    searchResults: {
      combos: [...searchData.combos],
      promotions: [...searchData.promotions],
    },
  }))
  .reset(PromotionsEvents.resetPromotions);

export default PromotionsStore;
