import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useTabsStyle from "../style";

interface MissionsDescriptionProps {
  description: string;
  boldText?: string;
}

const MissionsDescription = ({
  description,
  boldText,
}: MissionsDescriptionProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useTabsStyle();
  return (
    <div className={classes.missionsDescriptionContainer}>
      <Typography className={classes.missionsDescription} variant="subtitle1">
        {boldText && (
          <span className={classes.missionsBoldText} data-testid="bold-text">
            {t(boldText)}
          </span>
        )}
        {t(description)}
      </Typography>
    </div>
  );
};

MissionsDescription.defaultProps = {
  boldText: undefined,
};

export default MissionsDescription;
