import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    appSimulatorBox: {
      width: "370px",
      height: "379px",
      borderRadius: "3px",
      border: `solid 1px ${theme.palette.grey[100]}`,
      backgroundColor: theme.palette.grey[50],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    simulatorTitle: {
      marginBottom: "27px",
      fontSize: "18px",
      whiteSpace: "nowrap",
      fontWeight: "bold",
      color: theme.palette.secondary.main,
    },
    startSimulatorButton: {
      marginTop: "25px",
      marginBottom: "16px",
      width: "230px",
      height: "40px",
      borderRadius: "3px",
      backgroundColor: theme.palette.secondary.main,
    },
    webVersionText: {
      fontSize: "14px",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      color: theme.palette.secondary.main,
    },
    webVersionLink: {
      color: "#148cc6",
    },
  }),
);
