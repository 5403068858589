import Dexie from "dexie";
import { RedeemItem } from "../../../domains/redeem/Redeem";

export type RedeemItemsTable = Dexie.Table<RedeemItem>;

class RedeemItemsDb extends Dexie {
  redeemItems: Dexie.Table<RedeemItem>;

  constructor() {
    super("RedeemItemsDb");

    this.version(1).stores({
      redeemItems: "id,title,image,points",
    });

    this.redeemItems = this.table("redeemItems");
  }
}

export default RedeemItemsDb;
