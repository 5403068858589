import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@material-ui/core";
import { Combo as ComboType } from "../../../../../domains/promotions/Combo";
import { Promotions as PromotionsType } from "../../../../../domains/promotions/Promotions";
import Data from "../../../../../components/data/Data";
import cardStyles from "./CardStyles";
import {
  PROMOTIONS_ABA_TYPES,
  PROMOTIONS_TYPES,
} from "../../../../../config/constants";

interface Props {
  data: ComboType | PromotionsType;
  type: PROMOTIONS_ABA_TYPES;
  renderAddSection?: (isFreeGood?: PROMOTIONS_TYPES) => ReactNode;
}

const FreeGoodSection: React.FC<Props> = ({ data, type, renderAddSection }) => {
  const classes = cardStyles();
  const { t } = useTranslation();

  const getKey = (id: string, index: number) => `${id}-${index}`;

  const renderFreeGood = () => {
    if (type === PROMOTIONS_ABA_TYPES.PROMOTION) {
      const promotions = data as PromotionsType;

      const qtyFreeGoods = promotions.legacyFreeGoods
        ? promotions.legacyFreeGoods.length
        : 0;

      return (
        <div
          className={classes.freeGoodContainer}
          key={promotions.id}
          data-testid={promotions.id}
        >
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            <Typography className={classes.promotionsItemsName}>
              <Data
                type="text"
                value={promotions.sku}
                testId={`combo-name-${promotions.id}`}
              />
            </Typography>
            <Data
              type="currency"
              className={classes.promotionsPrice}
              value={promotions.originalPrice}
              testId={`currency-${promotions.id}`}
            />
          </Box>
          <div
            className={classes.optionFreeGoodTitle}
            data-testid={`label-option_${promotions.id}`}
          >
            {qtyFreeGoods === 1
              ? t("Promotions.WIN_ALL")
              : t("Promotions.CHOOSE_OPTION")}
          </div>
          {promotions.legacyFreeGoods?.map((freeGood, index: number) => (
            <div
              className={classes.freeGoodItemsContainer}
              data-testid={`free-good-container_${freeGood.id}`}
              key={`free-good-container_${getKey(freeGood.id, index)}`}
            >
              <div key={getKey(freeGood.id, index)}>
                {qtyFreeGoods > 1 && (
                  <div className={classes.freeGoodTitle}>{`${t(
                    "Promotions.OPTION",
                  )} ${index + 1}`}</div>
                )}
                {freeGood.items?.map((item) => (
                  <div
                    className={classes.freeGoodItem}
                    key={getKey(item.id, index)}
                    data-testid={item.id}
                  >
                    {`${item.amount} ${item.title}`}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      );
    }

    const combos = data as ComboType;

    return (
      <div className={classes.freeGoodContainer}>
        <Data value={combos.price} type="currency" className={classes.price} />
        {renderAddSection?.(PROMOTIONS_TYPES.COMBO_FREE_GOOD_V2)}
        <div className={classes.freeGoodItemsContainer}>
          <div className={classes.freeGoodTitle}>
            {t("Promotions.WIN_ALL")}:
          </div>
          {combos.legacyFreeGoods?.map((item) => (
            <div className={classes.freeGoodItem} key={item.id}>
              {`${item.amount} ${item.title}`}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return renderFreeGood();
};

export default FreeGoodSection;
