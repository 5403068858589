/* eslint-disable global-require */
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { Typography } from "@material-ui/core";
import PocInformationStore from "../../../../../../stores/callList/pocInformation/PocInformationStore";
import AgentCallStore from "../../../../../../stores/agentCall/AgentCallStore";
import CampaignsStore from "../../../../../../stores/campaigns/CampaignsStore";
import TagsStore from "../../../../../../stores/tags/TagsStore";
import Data from "../../../../../../components/data/Data";
import {
  isFeatureEnabled,
  TLP_SEARCH_BY_POC,
  TLP_SEARCH_BY_POC_ADMIN,
  TLP_SALES_STRUCTURE,
  TLP_SALES_STRUCTURE_ADMIN,
  TLP_DISPLAY_VENDOR_ACCOUNT_ID,
  TLP_DISPLAY_VENDOR_ACCOUNT_ID_ADMIN,
} from "../../../../../../config/featureToggles";
import GlobalStore from "../../../../../../stores/global/GlobalStore";
import TabIds from "../tabIds/TabIds";
import {
  B2O_PREFIX,
  POC_INFO_TAB,
  PocCallOrigin,
} from "../../../../../../config/constants";
import SalesStructure from "../../../salesStructure/SalesStructure";
import PocInfo from "../../../pocInfo/PocInfo";
import InfoTabs from "./components/infoTabs/InfoTabs";
import CollapsibleSideNavStyles from "./PocDetailsStyles";

interface Props {
  tab: string;
  setTab: (tab: string) => void;
}

function PocDetails({ tab, setTab }: Readonly<Props>): React.ReactElement {
  const classes = CollapsibleSideNavStyles();
  const { t } = useTranslation();

  const { pocInformation, isLoaded } = useStore(PocInformationStore);
  const GlobalStates = useStore(GlobalStore);
  const userZone = GlobalStates.user.zone;

  const { clientId, isTypedPoc, callId, pocCallOrigin } =
    useStore(AgentCallStore);
  const { list: tagsList } = useStore(TagsStore);
  const { campaigns: allCampaigns } = useStore(CampaignsStore);

  const isSearchByPocEnabled = isFeatureEnabled(
    TLP_SEARCH_BY_POC,
    TLP_SEARCH_BY_POC_ADMIN,
    userZone,
  );

  const isSalesStructureEnabled = isFeatureEnabled(
    TLP_SALES_STRUCTURE,
    TLP_SALES_STRUCTURE_ADMIN,
    userZone,
  );

  const isDisplayVendorAccountId = isFeatureEnabled(
    TLP_DISPLAY_VENDOR_ACCOUNT_ID,
    TLP_DISPLAY_VENDOR_ACCOUNT_ID_ADMIN,
    GlobalStates.user.keyToggle,
  );

  const tags = useMemo(() => {
    const tagsFiltered = tagsList.filter((tag) => !tag.startsWith(B2O_PREFIX));
    /* istanbul ignore next */
    if (
      isSearchByPocEnabled &&
      isTypedPoc &&
      (pocCallOrigin === PocCallOrigin.SEARCH_BY_POC ||
        pocCallOrigin === PocCallOrigin.AUTO_DIALER)
    ) {
      if (allCampaigns.length > 0) {
        tagsFiltered.push(t("tags.#B2OCampaign"));
      }
      return tagsFiltered;
    }

    const b2oTags = tagsList.filter((tag) => tag.startsWith(B2O_PREFIX));

    if (b2oTags.length > 0) {
      tagsFiltered.push(t("tags.#B2OCampaign"));
    }
    return tagsFiltered;
  }, [
    tagsList,
    isSearchByPocEnabled,
    isTypedPoc,
    pocCallOrigin,
    allCampaigns,
    t,
  ]);

  const getAccountId = () => {
    if (isDisplayVendorAccountId && pocInformation.vendorAccountId) {
      return pocInformation.vendorAccountId;
    }
    return clientId;
  };

  const pocId = () => (
    <Typography
      component="h5"
      variant="h5"
      data-testid="poc-info-id"
      className={classes.id}
    >
      {t("PocInformationHeaderTemplate.POC_ID")}
      <Data ellipsisSize={30} value={getAccountId()} data-testid="growid" />
    </Typography>
  );

  return (
    <>
      {isSalesStructureEnabled && (
        <InfoTabs
          data-testid="infoTabs-wrapper"
          callId={callId}
          tab={tab}
          setTab={setTab}
        />
      )}
      <div className={classes.content} data-testid="tabids-tab-content">
        {tab === POC_INFO_TAB ? (
          <>
            <TabIds
              data-testid="tabIds-wrapper"
              growId={getAccountId()}
              pocInformation={pocInformation}
            />
            {pocId()}
            <PocInfo
              data-testid="pocInfo-wrapper"
              isLoaded={isLoaded}
              pocInformation={pocInformation}
              tags={tags}
              userZone={userZone}
              keyToggle={GlobalStates.user.keyToggle}
            />
          </>
        ) : (
          <SalesStructure
            data-testid="salesStructure-wrapper"
            pocInformation={pocInformation}
            isLoaded={isLoaded}
          />
        )}
      </div>
    </>
  );
}
export default PocDetails;
