import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    Accordion: {
      borderRadius: "10px",
      margin: "0 0 16px 0",
      boxShadow: " 0 4px 4px 0 rgba(0, 0, 0, 0.16)",

      "&.Mui-expanded": {
        margin: "0 0 16px 0",
      },
      "&:before": {
        height: 0,
      },
      "&.tlp-MuiAccordion-rounded:last-child": {
        borderRadius: "10px",
      },
    },
    accordionSummaryRoot: {
      padding: 0,
    },
    accordionText: {
      fontSize: "14px",
      fontWeight: 500,
      color: palette.secondary.main,
      padding: "8px 16px",
      borderBottom: `solid 1px ${palette.grey[100]}`,
      lineHeight: 1.14,
      cursor: "default",
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
    },
    accordionExpandedBackground: {
      backgroundColor: palette.grey[50],
      borderRadius: "10px",
    },
    accordionSummaryContent: {
      display: "flex",
      flexDirection: "column",
      margin: 0,

      "&.Mui-expanded": {
        margin: 0,
      },
    },
    accordionIcon: {
      borderRadius: "3px",
      backgroundColor: palette.secondary.main,
      color: palette.common.white,
      height: "15px",
      width: "15px",
      marginRight: "11px",
    },
    accordionExpandButtonLabel: {
      fontSize: "14px !important",
      fontWeight: 500,
      color: palette.secondary.main,
      fontStretch: "normal",
    },
    accordionExpandButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "8px 16px",
    },
    accordionDetails: {
      display: "flex",
      flexDirection: "column",
    },
    descriptionAvailable: {
      fontSize: "12px",
      lineHeight: "normal",
      color: palette.text.secondary,
      fontWeight: 500,
      marginTop: "-8px",
    },
    descriptionSelection: {
      fontSize: "12px",
      lineHeight: "normal",
      color: palette.text.secondary,
      fontWeight: 500,
    },
    descriptionCombo: {
      fontSize: "12px",
      lineHeight: "normal",
      color: palette.text.secondary,
      fontWeight: 500,
      marginTop: "2px",
    },
    itemsName: {
      color: palette.text.primary,
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "normal",
      marginBottom: "8px",
      marginTop: "2px",
    },
    priceContainer: {
      display: "flex",
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "normal",
      marginBottom: "5px",
      color: palette.secondary.main,
    },
    originalPrice: {
      color: palette.error.main,
      textDecoration: "line-through",
      marginRight: "4px",
      fontWeight: 600,
    },
    originalPriceSteppedDiscount: {
      marginLeft: "8px",
    },
    price: {
      color: "#007a78",
      marginLeft: "4px",
      fontWeight: 600,
    },
    button: {
      width: "142px",
      height: "32px",
      gap: "8px",
      background: palette.secondary.main,
      color: palette.common.white,
      margin: "16px 0",
      fontSize: "13px",
    },
  }),
);
