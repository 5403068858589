import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    container: {
      width: "80px",
      maxWidth: "80px",
      height: "40px",
      margin: "5px 0px 10px 0px",
      fontSize: "11px",
      fontWeight: 500,
      backgroundColor: theme.palette.grey[50],
      display: "table-cell",
      verticalAlign: "middle",
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      borderTop: "5px solid white",
    },
  }),
);
