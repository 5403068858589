import { getCatalogV2 } from "../../services/catalog/v2/CatalogV2Service";
import { getCatalogV3Multiple } from "../../services/catalog/v3/CatalogV3Service";
import Catalog from "../../domains/Catalog";

export function execute(accountId: string): Promise<Catalog> {
  return getCatalogV2(accountId);
}

export function executeV3Multiple(accountId: string): Promise<Catalog> {
  return getCatalogV3Multiple(accountId, "", 0);
}
