import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import classNames from "classnames";
import { Container, ButtonBase, Box, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Formik, Form } from "formik";
import { object, string } from "yup";
import Modal from "../../../../../components/modal/Modal";
import Button from "../../../../../components/button/Button";
import PocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import OrdersStore from "../../../../../stores/order/OrdersStore";
import ConfirmationModalStyles from "./ConfirmationModalStyles";
import GAP_REASONS from "../../header/gapReason/gapReasons";
import * as CheckoutEvents from "../../../../../stores/checkout/CheckoutEvents";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import {
  isFeatureEnabled,
  TLP_ORDER_TAKING,
  TLP_ORDER_TAKING_ADMIN,
} from "../../../../../config/featureToggles";
import {
  setHasTechnicalProblem,
  setOrderReason,
} from "../../../../../stores/order/OrdersEvents";

interface Props {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (technicalProblem: boolean, orderReason: string) => void;
}

export interface FormValues {
  confirmDescription: string;
  technicalProblems: boolean;
}

const ConfirmationModal: React.FunctionComponent<Props> = ({
  open,
  loading,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const classes = ConfirmationModalStyles();
  const { pocInformation } = useStore(PocInformationStore);
  const { gapReason } = useStore(AgentCallStore);
  const { orderInfo, ordersList } = useStore(OrdersStore);
  const GlobalStates = useStore(GlobalStore);

  const hasTechnicalProblem = () => {
    return gapReason === GAP_REASONS[4].value || false;
  };

  const getLastOrderReason = () => {
    if (orderInfo?.orderId && orderInfo?.orderReason) {
      return `${orderInfo.orderReason}`;
    }
    if (!ordersList) {
      return null;
    }

    const lastOrderInfo = ordersList.slice(-1)[0];
    return lastOrderInfo && lastOrderInfo.orderReason
      ? `${lastOrderInfo.orderReason}`
      : "";
  };

  const initialValues: FormValues = {
    confirmDescription: getLastOrderReason() || "",
    technicalProblems: hasTechnicalProblem(),
  };

  const validationSchema = object().shape({
    confirmDescription: string().when("technicalProblems", {
      is: (technicalProblems) => !technicalProblems,
      then: string().required(t("OrderTaking.MSG_MANDATORY_REASON")),
    }),
  });

  const handleSubmit = (values: FormValues) => {
    const isOrderTakingEnabled = isFeatureEnabled(
      TLP_ORDER_TAKING,
      TLP_ORDER_TAKING_ADMIN,
      GlobalStates.user.keyToggle,
    );

    if (isOrderTakingEnabled) {
      setHasTechnicalProblem(values.technicalProblems);
      setOrderReason(values.confirmDescription);
      onSubmit(values.technicalProblems, values.confirmDescription);
    } else {
      onClose();
      CheckoutEvents.showErrorMessage([
        {
          type: "error",
          message: t("OrderTaking.ORDER_CANNOT_BE_PLACED"),
        },
      ]);
    }
  };

  const getConfirmationMessage = () =>
    t("OrderTaking.MESSAGE_CONFIRM_ORDER").replace(
      "[POC name]",
      pocInformation.name || "",
    );
  return (
    <Modal open={open} onClose={onClose}>
      <Container className={classes.container}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors, touched, handleChange }) => {
            const showDescriptionError =
              !!errors.confirmDescription && touched.confirmDescription;

            return (
              <Form className={classes.form} noValidate>
                <Typography className={classes.title}>
                  {t("OrderTaking.CONFIRMATION_MODAL")}
                </Typography>

                <div className={classes.messageConfirmOrder}>
                  {getConfirmationMessage()}
                </div>
                <Box className={classes.checkboxContainer}>
                  <FormControlLabel
                    checked={values.technicalProblems}
                    onChange={() =>
                      setFieldValue(
                        "technicalProblems",
                        !values.technicalProblems,
                      )
                    }
                    control={<Checkbox />}
                    data-testid="technical-problems-control"
                    disabled={hasTechnicalProblem() || gapReason !== ""}
                    label={t("OrderTaking.CHECKBOX_TP")}
                  />
                </Box>
                <Box className={classes.inputContainer}>
                  <textarea
                    id="order-taking-confirm-description"
                    name="confirmDescription"
                    maxLength={160}
                    placeholder={t("OrderTaking.TYPE_REASON_ORDER")}
                    className={classNames(classes.textarea, {
                      [classes.details]: !showDescriptionError,
                      [classes.detailsError]: showDescriptionError,
                    })}
                    value={values.confirmDescription}
                    onChange={handleChange}
                    data-testid="order-taking-confirm-details-value"
                  />
                  <small>{values.confirmDescription.length} - 160</small>
                  {showDescriptionError && (
                    <span
                      className={classes.errorMessage}
                      data-testid="order-taking-description-error"
                    >
                      {errors.confirmDescription}
                    </span>
                  )}
                </Box>

                <Box className={classes.buttonsContainer}>
                  <ButtonBase
                    onClick={onClose}
                    className={classNames(classes.button, classes.cancelButton)}
                    data-testid="order-taking-cancel-button"
                  >
                    {t("OrderTaking.CANCEL_CONFIRM_ORDER")}
                  </ButtonBase>
                  <Button
                    type="submit"
                    loading={loading}
                    className={classNames(
                      classes.button,
                      classes.registerButton,
                    )}
                    testId="order-taking-confirm-button"
                  >
                    {t("OrderTaking.CONFIRM_ORDER")}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Modal>
  );
};

export default ConfirmationModal;
