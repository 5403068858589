export const paymentMethodsEnum = {
  CREDIT: "CREDIT",
  CASH: "CASH",
  BANK_SLIP: "BANK_SLIP",
  BANK_SLIP_INSTALLMENT: "BANK_SLIP_INSTALLMENT",
  CHECK: "CHECK",
  CREDIT_CARD_POS: "CREDIT_CARD_POS",
  PIX_AT_DELIVERY: "PIX_AT_DELIVERY",
  GPA_PIX: "GPA_PIX",
  DIRECT_PAY_AT_DELIVERY: "DIRECT_PAY_AT_DELIVERY",
  CREDIT_CARD_ONLINE: "CREDIT_CARD_ONLINE",
};

export const paymentMethodsLabels: { [key: string]: string } = {
  [paymentMethodsEnum.CREDIT]: "OrderTaking.PM_CREDIT",
  [paymentMethodsEnum.CASH]: "OrderTaking.PM_CASH",
  [paymentMethodsEnum.BANK_SLIP]: "OrderTaking.PM_BANK_SLIP",
  [paymentMethodsEnum.BANK_SLIP_INSTALLMENT]:
    "OrderTaking.PM_BANK_SLIP_INSTALLMENT",
  [paymentMethodsEnum.CHECK]: "OrderTaking.PM_CHECK",
  [paymentMethodsEnum.CREDIT_CARD_POS]: "OrderTaking.PM_CREDIT_CARD_POS",
  [paymentMethodsEnum.PIX_AT_DELIVERY]: "OrderTaking.PIX_AT_DELIVERY",
  [paymentMethodsEnum.GPA_PIX]: "OrderTaking.PIX_AT_DELIVERY",
  [paymentMethodsEnum.DIRECT_PAY_AT_DELIVERY]:
    "OrderTaking.DIRECT_PAY_AT_DELIVERY",
  [paymentMethodsEnum.CREDIT_CARD_ONLINE]: "OrderTaking.CREDIT_CARD_ONLINE",
};
