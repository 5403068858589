import React, { useState, useCallback, useRef } from "react";
import { Typography } from "@material-ui/core";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import SearchOfferingsBar, {
  SEARCH_OFFERINGS_METHOD,
} from "../../../../components/searchBar/SearchOfferingsBar";
import { offeringsSearchInteraction } from "../../../../config/typewriter";
import NewOfferingsStore from "../../../../stores/newOfferings/NewOfferingsStore";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/ErrorHandlerRetry";
import { ANALYTICS_ROUTE_NAMES, LINK_CALL } from "../../../../config/constants";
import { setOfferings } from "../../../../stores/newOfferings/NewOfferingsEvents";
import LoadingBox from "../offerings/loadingBox/LoadingBox";
import NewOfferingsVirtualizedTable from "./virtualized/NewOfferingsVirtualizedTable";
import { searchByNameOrSku } from "./searchFunction/searchFunction";
import NewOfferingsStyles from "./NewOfferingsStyles";

const TEST_ID = "new-offerings-";
const SCREEN_SECTION = "To Recommend";
const SCREEN_NAME = ANALYTICS_ROUTE_NAMES.get(LINK_CALL);

interface Props {
  showRecommendedTab: boolean;
  loadOfferings: () => void;
  fetchAllOfferings: () => Promise<void>;
}

const NewOfferings = ({
  showRecommendedTab,
  loadOfferings,
  fetchAllOfferings,
}: Props): JSX.Element => {
  const classes = NewOfferingsStyles();
  const { t } = useTranslation();
  const { dispatchGenericEvent } = useAnalytics();
  const firstRender = useRef(true);
  const [value, setValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { done, error, offerings } = useStore(NewOfferingsStore);

  const handleSearchValue = useCallback((searchValue: string) => {
    setValue(searchValue);
  }, []);

  const onSearch = useCallback(
    (searchMethod: string) => {
      dispatchGenericEvent(offeringsSearchInteraction, {
        button_label:
          searchMethod === SEARCH_OFFERINGS_METHOD.ENTER_KEY
            ? SEARCH_OFFERINGS_METHOD.SEARCH_FIELD
            : SEARCH_OFFERINGS_METHOD.GO_BUTTON,
        search_query: value,
        filter_option: "",
        screen_section: "To Recommend",
      });
      const result = searchByNameOrSku(offerings, value);

      setSearchQuery(value);
      setOfferings(result.result);
      firstRender.current = true;
    },
    [dispatchGenericEvent, offerings, value],
  );

  const clearSearch = useCallback(() => {
    fetchAllOfferings();
  }, [fetchAllOfferings]);

  const noResultsText = () => (
    <div className={classes.noItems} data-testid="no-offerings-to-display">
      {t("Promotions.NO_PROMOTIONS")}
    </div>
  );

  if (error) {
    return (
      <ErrorHandlerRetry
        onClick={loadOfferings}
        testId={`${TEST_ID}-error`}
        screenName={SCREEN_NAME}
        screenSection={SCREEN_SECTION}
      />
    );
  }

  if (!done) {
    return <LoadingBox />;
  }

  if (!showRecommendedTab) {
    return noResultsText();
  }

  return (
    <>
      <SearchOfferingsBar
        value={value}
        handleSearchValue={handleSearchValue}
        testId="offerings-search"
        onSearch={onSearch}
        clearSearch={clearSearch}
      />
      <Typography className={classes.itemsFound}>
        {`${t("Search_Component.ITEMS_FOUND_LABEL")} (${
          offerings?.length || 0
        })`}
      </Typography>
      <NewOfferingsVirtualizedTable
        offerings={offerings}
        searchValue={searchQuery}
        firstRender={firstRender}
      />
    </>
  );
};

export default NewOfferings;
