import { TABS_TAGS_ENUM } from "../../domains/tags/TabsToTagsEnum";
import { MissionTagResponse } from "../../domains/flexibleMission/MissionTagResponse";
import { FlexibleMissionsConfigObject } from "../../domains/flexibleMission/FlexibleMissionsConfigObject";

const getMissionsByEnum = (
  missions: MissionTagResponse[],
): TABS_TAGS_ENUM[] => {
  const tags = missions.map(({ tag }) => tag);

  const convertedTags = tags.map((tag) => {
    if (tag === "#campaign") return TABS_TAGS_ENUM.localMissions;
    return tag;
  });

  const missionsFromEnum = convertedTags.filter((tag) =>
    Object.values(TABS_TAGS_ENUM).includes(tag as unknown as TABS_TAGS_ENUM),
  ) as TABS_TAGS_ENUM[];

  return [...new Set(missionsFromEnum)];
};

const getTabs = (missions: TABS_TAGS_ENUM[]): string[] => {
  const tabs = missions
    .map((mission) => {
      const indexOfTab = Object.values(TABS_TAGS_ENUM).indexOf(mission);
      return Object.keys(TABS_TAGS_ENUM)[indexOfTab];
    })
    .filter((tab) => tab);

  return tabs;
};

const getLocalOrGlobalIds = (
  missionsResponse: MissionTagResponse[],
  type: "local" | "global",
): string[] => {
  const campaignMissions = missionsResponse.filter(
    ({ tag }) => tag === "#campaign",
  );
  const missions = campaignMissions[0]?.missions ?? [];

  const localMissions = missions.filter(
    (mission) => mission.type.toLowerCase() === type,
  );
  const missionsIds = localMissions.map(({ id }) => id);
  return missionsIds;
};

const getSponsoredIds = (missionsResponse: MissionTagResponse[]): string[] => {
  const sponsoredMissions = missionsResponse.filter(
    ({ tag }) => tag === TABS_TAGS_ENUM.sponsored,
  );
  const missions = sponsoredMissions[0]?.missions ?? [];
  const missionsIds = missions.length > 0 ? missions.map(({ id }) => id) : [];
  return missionsIds;
};

export const FlexibleMissionsResponseToMissionsConfigConverter = (
  missionsResponse: MissionTagResponse[],
): FlexibleMissionsConfigObject => {
  const missions = getMissionsByEnum(missionsResponse);

  const missionsTabs = getTabs(missions);
  return {
    missions,
    missionsTabs,
    localMissionIds: getLocalOrGlobalIds(missionsResponse, "local"),
    sponsoredMissionsIds: getSponsoredIds(missionsResponse),
    flexibleMissionIds: getLocalOrGlobalIds(missionsResponse, "global"),
  };
};
