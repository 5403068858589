import SuggestedOrdersDomainV2 from "@domains/suggestedOrders/SuggestedOrdersDomainV2";
import { createEvent } from "effector";
import SuggestedOrdersDomain from "../../domains/suggestedOrders/SuggestedOrdersDomain";
import SuggestedOrdersState from "./SuggestedOrdersState";

export const getSuggestedOrders = createEvent<null>("getSuggestedOrders");

export const getSuggestedOrdersDone = createEvent<
  SuggestedOrdersDomain | SuggestedOrdersDomainV2
>("getSuggestedOrdersDone");

export const getSuggestedOrdersFail = createEvent<null>(
  "getSuggestedOrdersFail",
);

export const getSuggestedOrdersFinally = createEvent<null>(
  "getSuggestedOrdersFinally",
);

export const setSuggestedOrdersTotals = createEvent<
  SuggestedOrdersState["totals"]
>("setSuggestedOrdersTotals");

export const resetSuggestedOrders = createEvent<null>("resetSuggestedOrders");

export const setSuggestedOrders =
  createEvent<SuggestedOrdersState["suggestedOrders"]>("setSuggestedOrders");

export const setOrderBy =
  createEvent<SuggestedOrdersState["orderBy"]>("setOrderBy");

export const setOrderDirection =
  createEvent<SuggestedOrdersState["orderDirection"]>("setOrderDirection");
