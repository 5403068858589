import React from "react";

const ChevronUp: React.FunctionComponent = () => (
  <svg
    width="12px"
    height="8px"
    viewBox="0 0 12 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M0.25105133,0.25105133 C0.560037583,-0.0579349224 1.04623563,-0.0817030957 1.38248524,0.179746811 L1.46323438,0.25105133 L6,4.78714286 L10.5367656,0.25105133 C10.8457519,-0.0579349224 11.3319499,-0.0817030957 11.6681995,0.179746811 L11.7489487,0.25105133 C12.0579349,0.560037583 12.0817031,1.04623563 11.8202532,1.38248524 L11.7489487,1.46323438 L6.60609153,6.60609153 C6.29710527,6.91507778 5.81090723,6.93884595 5.47465762,6.67739605 L5.39390847,6.60609153 L0.25105133,1.46323438 C-0.0836837768,1.12849928 -0.0836837768,0.585786438 0.25105133,0.25105133 Z"
        id="chevronUp-path-1"
      ></path>
    </defs>
    <g
      id="chevronUp"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="2.0-Order-Taking---With-combos.-Combo-details-on-Cart"
        transform="translate(-1137.000000, -294.000000)"
      >
        <g id="Main-content" transform="translate(335.000000, 188.000000)">
          <g id="Cart-resume" transform="translate(600.000000, 51.000000)">
            <g id="Content" transform="translate(15.000000, 46.000000)">
              <g id="Group-28" transform="translate(88.000000, 4.000000)">
                <g
                  id="02_Icons/chevron-down"
                  transform="translate(105.000000, 9.071429) rotate(-180.000000) translate(-105.000000, -9.071429) translate(99.000000, 5.642857)"
                >
                  <g
                    id="ZZ_Color-Override/Primary/Black"
                    transform="translate(0.000000, 0.000000)"
                  >
                    <mask id="mask-2-chevron-up-icon" fill="white">
                      <use xlinkHref="#chevronUp-path-1"></use>
                    </mask>
                    <g id="Mask-chevron-up-icon" fillRule="nonzero"></g>
                    <g
                      id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
                      mask="url(#mask-2-chevron-up-icon)"
                      fill="#000000"
                      fillOpacity="0.55"
                    >
                      <g
                        transform="translate(-2.000000, -4.500000)"
                        id="Rectangle"
                      >
                        <rect x="0" y="0" width="16" height="16"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ChevronUp;
