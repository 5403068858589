import { createStore } from "effector";
import { getRulesEffect } from "./LoyaltyBusinessEvents";
import { LoyaltyBusinessPointsState } from "./LoyaltyBusinessState";
import { calculatePointsRewards } from "../../config/utils/functions";

const initialState = <LoyaltyBusinessPointsState>{
  error: false,
  done: false,
  loading: false,
  totalPoints: 0,
  rules: [],
};

const loyaltyBusinessStore = createStore(initialState)
  .on(getRulesEffect, (state) => ({
    ...state,
    error: false,
    done: false,
    loading: true,
  }))
  .on(getRulesEffect.done, (state, { result }) => {
    const totalPoints = calculatePointsRewards(result);

    return {
      ...state,
      error: false,
      done: true,
      loading: false,
      totalPoints,
      rules: result,
    };
  })
  .on(getRulesEffect.fail, (state) => {
    return {
      ...state,
      loading: false,
      error: true,
      done: true,
    };
  });
export default loyaltyBusinessStore;
