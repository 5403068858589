import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";
import { PROGRAMS_COMBOS_NOT_FOUND } from "@config/errorCodes";
import {
  RewardsChallenge,
  RewardsChallengeState,
} from "@domains/rewardsChallenges/RewardsChallenges";

import RewardsChallengesConverter from "../../converters/rewardsChallenges/RewardsChallengesResponseToRewardsChallengesStore";
import { getApiHost, getEndPoint } from "../host/HostB2BService";

export const LOYALTY_BUSINESS_SERVICE = "/loyalty-business-service";

const getExecutionMethodParams = (executionMethods?: string[]) => {
  if (!executionMethods || executionMethods.length < 1) return "";
  return `?${executionMethods
    .map((executionMethod) => `executionMethod=${executionMethod}`)
    .join("&")}`;
};

export function getRewardsChallenge(
  accountId: string,
  executionMethods?: string[],
): Promise<RewardsChallengeState> {
  const log = useLogService();

  const endpoint = `${getApiHost()}${getEndPoint()}${LOYALTY_BUSINESS_SERVICE}`;

  return axios
    .get<RewardsChallenge[]>(
      `${endpoint}/challenges/${accountId}${getExecutionMethodParams(
        executionMethods,
      )}`,
    )
    .then((response) => {
      log.info(
        `Convert RewardsChallenge items received from ${endpoint} to our model`,
      );
      return RewardsChallengesConverter(response.data);
    })
    .catch((error) => {
      const code = error?.response?.data?.code;

      if (code === PROGRAMS_COMBOS_NOT_FOUND) {
        return Promise.resolve({
          completed: 0,
          failed: 0,
          started: 0,
          rewardsChallengeInformation: [],
        });
      }

      log.error(error);
      throw error;
    });
}
