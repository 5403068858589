import MonthlyKPI from "../../domains/agentKPI/MonthlyKPI";
import MonthlyKPIDomain from "../../domains/agentKPI/MonthlyKPIDomain";
import MonthlyKPIResponse from "../../services/agentKPI/domains/MonthlyKPIResponse";
import TargetsKPIResponseToTargetsKPIConverter from "./TargetsKPIResponseToTargetsKPIConverter";

export default function MonthlyKPIResponseToMonthlyKPIConverter(
  data: MonthlyKPIResponse,
): MonthlyKPIDomain {
  const monthly = <MonthlyKPI>{
    monthlyYear: data.monthlyYear,
    monthlyPocAssigned: data.monthlyPocAssigned || 0,
    monthlyHitRate: data.monthlyHitRate || 0,
    monthlyTotalGap: data.monthlyTotalGap,
    monthlyAverageHandleTime: data.monthlyAverageHandleTime,
    monthlyToplineUplift: data.monthlyToplineUplift,
    ...TargetsKPIResponseToTargetsKPIConverter(data.targets),
  };

  return <MonthlyKPIDomain>{
    monthly,
  };
}
