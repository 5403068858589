import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { Credit } from "domains/Credit";
import axios from "@config/axios/axiosInstance";
import CreditResponseToCreditConverter from "../../converters/credit/CreditResponseToCreditConverter";
import { getApiHost, getEndPoint } from "../host/HostB2BService";
import { CreditResponse } from "./domains/CreditResponse";

const CREDIT_SERVICE = "credits";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getCredit(accountId: string): Promise<Credit> {
  const log = useLogService();
  const endpoint = `${getApiHost()}${getEndPoint()}/${CREDIT_SERVICE}/?accountId=${accountId}`;

  return axios
    .get<CreditResponse>(endpoint)
    .then((response) => {
      log.info(
        `Convert Credit Response received from ${endpoint} to our model`,
      );
      return CreditResponseToCreditConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
