/* istanbul ignore file */
import { makeStyles, createStyles } from "@material-ui/core/styles";

const CallButtonStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      height: "32px",
      width: "32px",
      marginRight: 8,
      color: "rgba(255, 255, 255, 0.95) !important",
      backgroundColor: "#212121",
      "&:hover": {
        backgroundColor: "#212121",
      },
    },
    iconButtonDisabled: {
      backgroundColor: "#DFDEDE !important",
    },
  }),
);

export default CallButtonStyles;
