import React, { useEffect, useMemo, useRef } from "react";
import { Card, Grid, Typography, Divider } from "@material-ui/core";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import * as DOMPurify from "dompurify";
import ReactHtmlParser from "react-html-parser";
import { MISSION_TYPES } from "../../../../config/constants";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/v2/ErrorHandlerRetry";
import ProductCardV2 from "../../../../components/ProductCardV2/ProductCardV2";
import ProductCardV3 from "../../../../components/ProductCardV3/ProductCardV3";
import {
  isFeatureEnabledV2,
  TLP_SPONSORED_PRODUCTS_ORDER_PAGE_LINK,
  GROW_LOCAL_SCRIPT_REDESIGN_BEESGR_8281,
  GROW_LOCAL_PRODUCTS_REDESIGN,
} from "../../../../config/featureToggles";
import { callUxButtonClicked } from "../../../../config/typewriter";
import PocInformation from "../../../../domains/PocInformation";
import { Product } from "../../../../domains/products/Product";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import SponsoredCardStyle from "./SponsoredCard.styles";
import CardScript from "./TagsHtml.style";
import useProductCardContainer from "./hooks/useProductCardContainer";
import { SponsoredMission } from "../../../../domains/sponsored/SponsoredMission";
import { getRulesEffect } from "../../../../stores/loyaltyBusiness/LoyaltyBusinessEvents";
import SponsoredStore from "../../../../stores/sponsored/SponsoredStore";

interface Props {
  mission: SponsoredMission;
  hasError: boolean;
  tryAgain: () => void;
  products?: Product[];
  pocInformation: PocInformation;
  isRedesign?: boolean;
  isSponsored?: boolean;
  windowWidth: number | undefined;
}

const MIN_WIDTH_TO_RESPONSIVENESS = 1678;

const SponsoredCard: React.FC<Props> = ({
  mission,
  products,
  hasError,
  tryAgain,
  pocInformation,
  isRedesign,
  isSponsored,
  windowWidth,
}) => {
  const { t } = useTranslation();
  const { dispatchGenericEvent } = useAnalytics();

  const classes = SponsoredCardStyle({
    isRedesign,
  });

  /* istanbul ignore next */
  const productsRef = useRef(products ?? []);

  const { callId, clientId: accountId } = useStore(AgentCallStore);
  const { flexibleMissionsError } = useStore(SponsoredStore);

  const {
    user: { zone },
  } = useStore(GlobalStore);

  const isAddedTextOrderLinkEnabled = isFeatureEnabledV2(
    TLP_SPONSORED_PRODUCTS_ORDER_PAGE_LINK,
    zone,
  );

  const isScriptRedesignEnabled = isFeatureEnabledV2(
    GROW_LOCAL_SCRIPT_REDESIGN_BEESGR_8281,
    zone,
  );

  const isLocalProductsRedesignEnabled = isFeatureEnabledV2(
    GROW_LOCAL_PRODUCTS_REDESIGN,
    zone,
  );

  const sanitizedScript = DOMPurify.sanitize(mission.scriptText);

  const handleCollapsibleAnalytics = (buttonLabel: string) => {
    const analyticsObject = {
      button_label: buttonLabel,
      button_name: `sponsored-single-product-${buttonLabel}`,
      call_id: callId,
      filter_option: null,
      order_date: null,
      screen_name: "Call - GAP and Status",
      screen_section: mission.missionTitle,
      mission: "Sponsored",
      position: null,
    };

    dispatchGenericEvent(callUxButtonClicked, analyticsObject);
  };

  const handleScript = () => {
    return isScriptRedesignEnabled
      ? ReactHtmlParser(sanitizedScript)
      : mission.scriptText;
  };

  const { showStock, showAddButton, addAction, maxValue } =
    useProductCardContainer({
      products,
      pocInformation,
    });

  useEffect(() => {
    /* istanbul ignore next */
    if (productsRef.current.length > 0) {
      const platformIds = productsRef.current.map((item) => item.platformId);

      const filtered = platformIds.filter((el) => el !== undefined);

      getRulesEffect({
        accountId,
        platformIds: filtered,
      });
    }
  }, [accountId]);

  /* istanbul ignore next */
  const getVerticalDividerInProductCardGrid = (cardIndex: number) => {
    if (!windowWidth || windowWidth > MIN_WIDTH_TO_RESPONSIVENESS) {
      return (
        (cardIndex + 1) % 3 !== 0 && (
          <Divider orientation="vertical" className={classes.verticalDivider} />
        )
      );
    }
    return (
      (cardIndex + 1) % 2 !== 0 && (
        <Divider orientation="vertical" className={classes.verticalDivider} />
      )
    );
  };

  /* istanbul ignore next */
  const getHorizontalDividerInProductCardGrid = (cardIndex: number) => {
    if (!windowWidth || windowWidth > MIN_WIDTH_TO_RESPONSIVENESS) {
      return cardIndex > 2 && <Divider />;
    }
    return cardIndex > 1 && <Divider />;
  };

  const missionsErrorMessage = useMemo(() => {
    if (isSponsored) {
      return t("Sponsored.SPONSORED_MISSIONS_REQUEST_FAILURE_MESSAGE");
    }
    return t("LocalMission.LOCAL_MISSIONS_REQUEST_FAILURE_MESSAGE");
  }, [isSponsored, t]);

  /* istanbul ignore next */
  const productCardsContainerClassName = useMemo(() => {
    if (
      products &&
      windowWidth &&
      products.length > 2 &&
      windowWidth > MIN_WIDTH_TO_RESPONSIVENESS
    )
      return classes.productCardsContainerWithThreeColumns;
    return classes.productCardsContainerWithTwoColumns;
  }, [windowWidth, classes, products]);

  const handleProductCard = (products: Product[]) => {
    if (isLocalProductsRedesignEnabled) {
      return (
        <div className={classes.scrollContainer}>
          <div className={productCardsContainerClassName}>
            {products.map((item, index) => {
              item.isSponsored = !!mission.sponsorPartnerId;

              return (
                <>
                  <div className={classes.productCardAndHorizontalDivider}>
                    {getHorizontalDividerInProductCardGrid(index)}
                    <div
                      className={classes.productCard}
                      key={item.sku || item.id}
                    >
                      <ProductCardV3
                        product={item}
                        mission={mission}
                        data-testid={`sponsored-product-${index}`}
                      />
                    </div>
                  </div>
                  {getVerticalDividerInProductCardGrid(index)}
                </>
              );
            })}
          </div>
        </div>
      );
    }

    return (
      <Grid container>
        {products.map((item, index) => {
          item.isSponsored = !!mission.sponsorPartnerId;
          return (
            <Grid item xs={6} key={item.sku || item.id}>
              <ProductCardV2
                product={item}
                mission={mission}
                description={item.name}
                showAddedInfoText={isAddedTextOrderLinkEnabled}
                data-testid={`sponsored-product-${index}`}
                comboDetailsAnalytics={(event) =>
                  handleCollapsibleAnalytics(event)
                }
                showStock={showStock(item.isCombo)}
                showAddButton={showAddButton(item.isCombo)}
                addAction={addAction(item, mission)}
                maxValue={maxValue(item)}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const shouldRenderError = useMemo(() => {
    if (mission.missionType === MISSION_TYPES.FLEXIBLE && flexibleMissionsError)
      return true;
    if (mission.missionType !== MISSION_TYPES.FLEXIBLE) return hasError;
    return false;
  }, [flexibleMissionsError, hasError, mission.missionType]);

  return (
    <>
      {shouldRenderError ? (
        <ErrorHandlerRetry
          onClick={tryAgain}
          description={missionsErrorMessage}
          className={isRedesign ? classes.containerError : ""}
        />
      ) : (
        <Card
          className={
            isRedesign ? classes.cardContainerRedesign : classes.cardContainer
          }
          elevation={isRedesign ? 0 : undefined}
          data-testid="sponsored-card"
        >
          {mission.scriptText && (
            <div data-testid="box-container-script">
              <Typography variant="h1" className={classes.subtitle}>
                {t("Sponsored.SCRIPT_TITLE")}
              </Typography>
              <Typography
                variant="subtitle1"
                className={
                  isScriptRedesignEnabled
                    ? classes.descriptionRedesign
                    : classes.description
                }
              >
                <CardScript> {handleScript()} </CardScript>
              </Typography>
            </div>
          )}

          {mission.scriptText && mission.missionType !== "simple" && (
            <Divider
              data-testid="sponsored-divider"
              className={
                isLocalProductsRedesignEnabled
                  ? classes.separatorRedesign
                  : classes.separator
              }
            />
          )}

          {products && products.length > 0 && (
            <>{handleProductCard(products)}</>
          )}
          {!products?.length && (
            <div
              data-testid="no-products-message"
              className={classes.noProductsTypography}
            >
              {mission.missionType !== "simple" && (
                <Typography data-testid="no-products-text" variant="h2">
                  {isSponsored
                    ? /* istanbul ignore next */
                      t("Sponsored.NO_SPONSORED_PRODUCTS_TO_SHOW_MESSAGE")
                    : t("LocalMission.NO_LOCAL_PRODUCTS_TO_SHOW_MESSAGE")}
                </Typography>
              )}
            </div>
          )}
        </Card>
      )}
    </>
  );
};

export default SponsoredCard;
