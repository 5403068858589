import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import { PromotionDetail } from "@services/searchCatalog/domains/Search";
import { PACKAGE_TYPE } from "../config/constants";
import {
  GROW_CATALOG_VARIANTS_STOCK_CALCULATION,
  isFeatureEnabledV2,
} from "../config/featureToggles";
import {
  CatalogItem,
  PriceDetailsItem,
  ProductVariants,
} from "../domains/Catalog";
import GlobalStore from "../stores/global/GlobalStore";

interface UseProductCommonsHookProps {
  getDisplayPackageType: (
    packageTypeId?: string,
    packageName?: string,
  ) => string | null;
  shouldShowQuantitySelector: (
    maxOrderQuantity?: number,
    multiplierQuantity?: number,
  ) => boolean;
  getProductsWithVariants: (product: CatalogItem) => ProductVariants[];
}

const getSkuPrice = (product: CatalogItem): number | undefined => {
  if (!product.itemCount || product.itemCount <= 1) {
    return undefined;
  }
  const getSkuPriceItem = (
    details?: PriceDetailsItem,
    promotionDetail?: PromotionDetail,
  ) => {
    if (details?.preferredPrice && details?.price) {
      if (promotionDetail?.discountPrice) {
        return promotionDetail.discountPrice;
      }
      return details.price;
    }
    return null;
  };
  const uomPrice = getSkuPriceItem(
    product.priceData?.priceDetails?.uom,
    product.promofusion?.promotions?.[0]?.uom,
  );
  if (uomPrice) {
    return uomPrice;
  }
  const containerPrice = getSkuPriceItem(
    product.priceData?.priceDetails?.container,
    product.promofusion?.promotions?.[0]?.container,
  );
  if (containerPrice) {
    return containerPrice;
  }
  return undefined;
};

export const useProductCommonsHook = (): UseProductCommonsHookProps => {
  const { t } = useTranslation();

  const { user } = useStore(GlobalStore);

  const isVariantManualStockCalculationEnabled = isFeatureEnabledV2(
    GROW_CATALOG_VARIANTS_STOCK_CALCULATION,
    user.keyToggle,
  );

  const getDisplayPackageType = (
    packageTypeId?: string,
    packageName?: string,
  ) => {
    switch (packageTypeId) {
      case PACKAGE_TYPE.DS:
        return t("COMMONS_PRODUCTS_INFOS.PACKAGE_TYPES.DISPLAY");

      case PACKAGE_TYPE.CS:
        return t("COMMONS_PRODUCTS_INFOS.PACKAGE_TYPES.BOX");

      case PACKAGE_TYPE.EA:
        return t("COMMONS_PRODUCTS_INFOS.PACKAGE_TYPES.UNIT");

      default:
        return packageName ?? null;
    }
  };

  const shouldShowQuantitySelector = (
    maxOrderQuantity?: number,
    multiplierQuantity?: number,
  ) => {
    return !!multiplierQuantity && multiplierQuantity > 1 && !!maxOrderQuantity;
  };

  const getCalculatedInventory = (
    variantInventoryCount: number,
    unityInventoryCount: number,
    itemCount: number | undefined,
  ): number => {
    if (!isVariantManualStockCalculationEnabled) {
      return variantInventoryCount;
    }

    if (!itemCount || !unityInventoryCount || itemCount <= 0) {
      return unityInventoryCount;
    }

    return Math.floor(unityInventoryCount / itemCount);
  };

  const getProductsWithVariants = (product: CatalogItem): ProductVariants[] => {
    return [
      {
        platformId: product.platformId as string,
        packageId: product.packageId,
        id: product.id?.toString(),
        pricePerUoM: product.pricePerUoM,
        unitOfMeasurement: product.unitOfMeasurement,
        price: product.price,
        skuPrice: getSkuPrice(product),
        unitPrice: product.price,
        discountedPrice: product.discountedPrice,
        sku: product.sku?.toString(),
        itemName: product.name,
        defaultItemQuantity: product.assortment?.quantityMultiplier ?? 1,
        quantityMultiplier: product.assortment?.quantityMultiplier,
        inventoryCount: product.availabilityCount,
        availabilityCount: product.availabilityCount,
        itemCount: product.itemCount,
        palletQuantity: product.palletQuantity,
        maxOrderQuantity: product.maxOrderQuantity,
        isVariant: (product.variants?.length ?? 0) > 0,
        fullPackageDescription: product.fullPackageDescription,
        salesRanking: product.salesRanking,
      },
      ...(product.variants?.map((variant) => ({
        platformId: variant.platformId,
        packageId: variant.pack?.packageId,
        id: variant.productId,
        pricePerUoM: variant.price?.pricePerUoM,
        unitOfMeasurement: variant.pack?.unitOfMeasurement,
        price: variant.price?.price,
        unitPrice: variant.price?.price,
        discountedPrice: variant.discountedPrice,
        sku: variant.sku,
        itemName: variant.itemName,
        defaultItemQuantity: variant.assortment?.quantityMultiplier ?? 1,
        quantityMultiplier: variant.assortment?.quantityMultiplier,
        inventoryCount: getCalculatedInventory(
          variant.availability?.count,
          product.availabilityCount,
          variant.pack?.itemCount,
        ),
        availabilityCount: getCalculatedInventory(
          variant.availability?.count,
          product.availabilityCount,
          variant.pack?.itemCount,
        ),
        itemCount: variant.pack?.itemCount,
        palletQuantity: getCalculatedInventory(
          variant.availability?.count,
          product.availabilityCount,
          variant.pack?.itemCount,
        ),
        skuPrice: product.discountedPrice ?? product.price,
        isVariant: true,
        fullPackageDescription: variant.pack?.fullPackageDescription,
        salesRanking: variant.salesRanking,
        maxOrderQuantity: variant.maxOrderQuantity,
      })) ?? []),
    ];
  };

  return {
    getDisplayPackageType,
    shouldShowQuantitySelector,
    getProductsWithVariants,
  };
};
