import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    chip: {
      fontWeight: "bold",
      textTransform: "none",
      height: "13px",
      fontSize: "10px",
    },
  }),
);
