import React from "react";

import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import QuantityControl from "../../../../../components/quantityControl/QuantityControl";
import SkuClipBoardButton from "../../../../../components/skuClipBoardButton/SkuClipBoardButton";
import { FormatedFreeGoodsItem } from "../../../../../domains/cart/FormatedFreeGoods";
import FormatStockExhibition from "../../../../../helpers/FormatStockExhibition";
import styles from "./FreeGoodsItemCard.styles";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import { freeGoodQuantityEdited } from "../../../../../config/typewriter";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import { CURRENCY } from "../../../../../config/constants";

interface FreeGoodsItemCardProps {
  item: FormatedFreeGoodsItem;
  disabled?: boolean;
}

const MIN_QUANTITY_CONTROL_VALUE = 0;

export function FreeGoodsItemCard({
  item,
  disabled = false,
}: FreeGoodsItemCardProps): JSX.Element {
  const { t } = useTranslation();
  const { dispatchGenericEvent } = useAnalytics();
  const { callId, clientId } = useStore(AgentCallStore);
  const { user } = GlobalStore.getState();

  const isOutOfStock =
    item.availability <= 0
      ? t("RecommendedProducts.OUT_OF_STOCK")
      : `${FormatStockExhibition(item.availability)} ${t(
          "RecommendedProducts.IN_STOCK",
        )}`;

  const classes = styles({
    isOutOfStock: item.availability <= 0,
    disabled,
  });

  const quantityControlChanged = (newQuantity: number) => {
    const { currency } = CURRENCY[user.zone];

    item.onChangeQuantity(newQuantity);
    dispatchGenericEvent(freeGoodQuantityEdited, {
      quantity: newQuantity,
      sku: item.sku,
      call_id: callId,
      country: user.zone,
      currency,
      poc_id: clientId,
    });
  };

  return (
    <Box data-testid="free-good-container" className={classes.container}>
      <img
        data-testid="free-good-img"
        className={classes.img}
        src={item.imageUrl}
        alt={`${item.name}`}
        width={56}
        height={56}
      />

      <Box className={classes.detailsArea}>
        <Box className={classes.itemDetailsContainer}>
          <span className={classes.title}>{item.name}</span>
          <span
            className={classes.subtitle}
            data-testid="free-good-item-subtitle"
          >
            <SkuClipBoardButton item={item} />
          </span>
          <span className={classes.freeText} data-testid="free-good-free">
            {t("OrderTaking.FREE_GOODS_SECTION.CARD.FREE")}
          </span>
        </Box>

        <Box className={classes.buttonsContainer}>
          <QuantityControl
            value={item.selectedQuantity}
            onChange={(newQuantity) => quantityControlChanged(newQuantity)}
            testId={`${item.platformId}-free-good-item-quantity-control`}
            minValue={MIN_QUANTITY_CONTROL_VALUE}
            maxValue={item.maxQuantity}
            data-testid={`${item.platformId}-free-good-item-quantity-control`}
            isOrderVisualization
          />

          <span
            data-testid="free-good-item-stock"
            className={classes.stockInfo}
          >
            {isOutOfStock}
          </span>
        </Box>
      </Box>
    </Box>
  );
}
