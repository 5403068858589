import { useStore } from "effector-react";
import { useCallback, useMemo } from "react";

import { getValueEmpty, hasMarketplace } from "@config/utils/functions";
import MarketplaceStore from "@stores/marketplace/MarketplaceStore";
import { CURRENCY } from "../../../../../config/constants";
import {
  GROW_CART_SERVICE_V4,
  isFeatureEnabledV2,
} from "../../../../../config/featureToggles";
import { productRemoved } from "../../../../../config/typewriter";
import { CartComboItem as CartComboItemType } from "../../../../../domains/cart/CartComboItem";
import { CartItem as CartItemType } from "../../../../../domains/cart/CartItem";
import { DisplayCartItem } from "../../../../../domains/orderSummary/DisplayCartItem";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import PocinformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import {
  changeItemQuantity,
  removeItem as removeProduct,
} from "../../../../../stores/cart/CartItemEvents";
import CartItemStore from "../../../../../stores/cart/CartItemStore";
import {
  removeItem as removeCombo,
  updateComboQuantity,
} from "../../../../../stores/cartCombo/CartComboEvents";
import CartComboStore from "../../../../../stores/cartCombo/CartComboStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import SuggestedOrdersStore from "../../../../../stores/suggestedOrders/SuggestedOrdersStore";
import { useUpdateCartHook } from "../../orderCart/hooks/useUpdateCartHook";

interface FormatedOrderItem {
  displayCartItem: DisplayCartItem;
  onChangeQuantity: (newQuantity: number) => void;
  onRemove: () => void;
}

interface UseOrderSummaryHookProps {
  formatedItems: FormatedOrderItem[];
  shouldUpdatePrice: () => void;
}

const DEFAULT_QUANTITY_MULTIPLIER = 1;

export const useOrderSummaryHook = (): UseOrderSummaryHookProps => {
  const { cartItems } = useStore(CartItemStore);
  const { cartComboItems } = useStore(CartComboStore);
  const callStore = useStore(AgentCallStore);
  const GlobalStates = useStore(GlobalStore);
  const { suggestedOrders } = useStore(SuggestedOrdersStore);
  const { pocInformation } = useStore(PocinformationStore);
  const { marketplaceList } = useStore(MarketplaceStore);

  const { setNeedToUpdate } = useUpdateCartHook();

  const isCartSimulationV4Enabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    GlobalStates.user.keyToggle,
  );

  const checkIsMarketplace = (sku: string) =>
    hasMarketplace(sku, marketplaceList);

  const shouldUpdatePrice = useCallback(() => {
    setNeedToUpdate(true);
  }, [setNeedToUpdate]);

  const sendRemoveItemEvent = useCallback(
    (
      id: string,
      itemName: string | undefined,
      itemQuantity: number,
      isCombo: boolean,
      inventoryCount?: number,
    ) => {
      productRemoved({
        call_id: callStore.callId,
        country: callStore.countryCode,
        currency: CURRENCY[GlobalStates.user.zone]?.currency,
        name: getValueEmpty(itemName),
        original_quantity: isCombo
          ? null
          : suggestedOrders.find((el) => el.sku === id)?.gapBoxes ?? null,
        poc_id: callStore.clientId,
        quantity: itemQuantity,
        sku: id,
        is_out_of_stock: isCombo ? null : inventoryCount === 0,
        combo_id: !isCombo ? null : id,
        is_combo: isCombo,
        remove_method: "Remove Icon",
      });
    },
    [
      GlobalStates.user.zone,
      callStore.callId,
      callStore.clientId,
      callStore.countryCode,
      suggestedOrders,
    ],
  );

  const handleChangeProductQuantity = useCallback(
    (item: CartItemType, newQuantity: number, position: number) => {
      changeItemQuantity({
        ...item,
        itemQuantity: Number(newQuantity),
        maxVehicleCapacity: pocInformation.maxVehicleCapacity,
        position,
        toggle: isCartSimulationV4Enabled,
      });
      shouldUpdatePrice();
    },
    [
      isCartSimulationV4Enabled,
      pocInformation.maxVehicleCapacity,
      shouldUpdatePrice,
    ],
  );

  const handleRemoveProduct = useCallback(
    (item: CartItemType, position: number) => {
      removeProduct({
        ...item,
        maxVehicleCapacity: pocInformation.maxVehicleCapacity,
        position,
        toggle: isCartSimulationV4Enabled,
      });
      shouldUpdatePrice();
      sendRemoveItemEvent(
        item.sku,
        item.itemName,
        item.itemQuantity,
        false,
        item.availabilityCount ?? item.inventoryCount,
      );
    },
    [
      isCartSimulationV4Enabled,
      pocInformation.maxVehicleCapacity,
      sendRemoveItemEvent,
      shouldUpdatePrice,
    ],
  );

  const handleChangeComboQuantity = useCallback(
    (item: CartComboItemType, newQuantity: number) => {
      updateComboQuantity({
        ...item,
        itemQuantity: newQuantity,
      });
      shouldUpdatePrice();
    },
    [shouldUpdatePrice],
  );

  const handleRemoveCombo = useCallback(
    (item: CartComboItemType) => {
      removeCombo({
        ...item,
        toggle: isCartSimulationV4Enabled,
      });
      shouldUpdatePrice();
      sendRemoveItemEvent(item.id, item.title, item.itemQuantity, true);
    },
    [isCartSimulationV4Enabled, sendRemoveItemEvent, shouldUpdatePrice],
  );

  const renderUnityText = (productDescription: string | undefined) => {
    /* istanbul ignore else */
    if (productDescription) {
      /* istanbul ignore next */
      const unityMatch = productDescription.match(/\d+[xX]\d+/);

      /* istanbul ignore else */
      if (unityMatch) {
        return `${unityMatch[0]}un`;
      }
    }

    return undefined;
  };

  const formatedItems = useMemo(() => {
    const formattedProducts = cartItems
      ?.filter((each) => each.itemQuantity !== 0)
      .map((product, position) => {
        const displayCartItem = {
          id: product.sku,
          platformId: product.platformId,
          title: product.itemName,
          price: product.total,
          quantity: product.itemQuantity,
          displayQuantity: product.availabilityCount ?? product.inventoryCount,
          maxQuantity: product.maxOrderQuantity,
          quantityMultiplier:
            product.quantityMultiplier ?? DEFAULT_QUANTITY_MULTIPLIER,
          isCombo: false,
          isMarketplace: checkIsMarketplace(product.sku),
          packageId: product.packageId,
          unitOfMeasurement: product.unitOfMeasurement,
          nextUnitOfMeasurement: product.nextUnitOfMeasurement,
        } as DisplayCartItem;

        return {
          displayCartItem,
          onChangeQuantity: (newQuantity) =>
            handleChangeProductQuantity(product, newQuantity, position),
          onRemove: () => handleRemoveProduct(product, position),
        };
      });

    const formattedCombos = cartComboItems
      ?.filter((each) => each.itemQuantity !== 0)
      .map((combo) => {
        const comboItems = combo.items?.map((productItem) => {
          return {
            id: productItem.id,
            productName: productItem.title,
            packageDescription: renderUnityText(
              productItem.fullPackageDescription,
            ),
          };
        });
        const displayCartItem = {
          id: combo.id,
          platformId: combo.platformId ?? combo.id,
          title: combo.title,
          price: combo.price,
          quantity: combo.itemQuantity,
          comboItems,
          isCombo: true,
          isMarketplace: checkIsMarketplace(combo.id),
          quantityMultiplier: DEFAULT_QUANTITY_MULTIPLIER,
        } as DisplayCartItem;

        return {
          displayCartItem,
          onChangeQuantity: (newQuantity) =>
            handleChangeComboQuantity(combo, newQuantity),
          onRemove: () => handleRemoveCombo(combo),
        };
      });

    return [...formattedProducts, ...formattedCombos];
  }, [
    cartComboItems,
    cartItems,
    handleChangeComboQuantity,
    handleChangeProductQuantity,
    handleRemoveCombo,
    handleRemoveProduct,
  ]);

  return {
    formatedItems,
    shouldUpdatePrice,
  };
};
