import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderBottom: `1px solid ${palette.grey.A100}`,
      marginBottom: "16px",
    },
    title: {
      marginLeft: 10,
      fontWeight: 500,
      fontSize: "19px",
      letterSpacing: 0,
      color: palette.secondary.main,
      opacity: 1,
    },
    tabTitle: {
      marginRight: "16px",
      padding: "2px",
      backgroundColor: "white",
      fontSize: "12px",
      fontWeight: 600,
      borderBottom: "2px solid transparent",
      color: palette.secondary.main,
      cursor: "pointer",
      borderRadius: "0px !important",
    },
    tabTitleActive: {
      borderBottom: `2px solid ${palette.primary.light}`,
      lineHeight: 1.5,
    },
    tabTitleContainer: {
      marginTop: "16px",
      marginBottom: "24px",
    },
    noPromotionsText: {
      fontSize: "16px",
      color: palette.secondary.main,
      paddingBottom: "14px",
      textAlign: "center",
    },
    combosContainer: {
      maxHeight: "360px",
      overflowY: "auto",
      paddingRight: "5px",
    },
    box: {
      maxHeight: "70%",
      paddingRight: 5,
      overflow: "hidden",
      "&:hover": {
        overflow: "auto",
      },
      paddingBottom: 15,
    },
    listCardsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      marginBottom: "1rem",
    },
    expandButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "0px",
      marginBottom: "17px",
    },
    icon: {
      borderRadius: "3px",
      backgroundColor: palette.secondary.main,
      color: palette.common.white,
      height: "15px",
      width: "15px",
      marginRight: "10px",
    },
    expandButtonLabel: {
      fontSize: "12px",
      fontWeight: "bold",
      color: palette.secondary.main,
      marginBottom: 2,
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "column",
    },
    expandAndCollapseButton: {
      color: "#0363C4",
      marginBottom: ".75rem",
      alignSelf: "end",
    },
  }),
);
