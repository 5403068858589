import { createEffect } from "effector";
import * as LoyaltyBusinessUseCase from "../../usecase/loyaltyBusiness/LoyaltyBusinessUseCase";

export const getRulesEffect = createEffect({
  async handler(props: {
    accountId: string;
    platformIds: Array<string | undefined>;
  }) {
    return LoyaltyBusinessUseCase.executeV2(props.accountId, props.platformIds);
  },
});
