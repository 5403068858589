import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";

import { TLP_ACCOUNT_SERVICE } from "../../config/services";
import { RequestBody } from "../../domains/customerNote/customerNote";
import { getApiHost } from "../host/HostService";

const ACCOUNT_SERVICE = "/tlp-account";
const ACCOUNTS_NOTES = "/accounts/notes";

export async function sendCustomerNotes(body: RequestBody): Promise<void> {
  const log = useLogService();

  const apiHost = getApiHost(TLP_ACCOUNT_SERVICE);

  const url = `${apiHost}${ACCOUNT_SERVICE}${ACCOUNTS_NOTES}`;

  try {
    await axios.post(`${url}`, body);
    log.info(`${url} returned success`);
  } catch (error) {
    log.error(error);
    throw error;
  }
}
