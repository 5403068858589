import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ServicesContentStyle from "../ServicesContent/ServicesContentStyle";
import IconStarFilled from "../icons/IconStarFilled";
import Data from "../../../../../../components/data/Data";
import { PayWithProps } from "../VariablePrice/VariablePriceComponent";
import SubTotal from "./SubTotal";

export default function PresetCalculator(props: {
  remainingBalance: number | undefined;
  pricingPresetValue: number | BigInt;
  pricingMethod: string;
  // eslint-disable-next-line react/require-default-props
  payWith?: PayWithProps;
  notEnrolled: boolean;
}): JSX.Element {
  const {
    remainingBalance,
    pricingPresetValue,
    pricingMethod,
    payWith,
    notEnrolled,
  } = props;

  const [copied, setCopied] = useState(false);
  const classes = ServicesContentStyle();

  const { t } = useTranslation();

  const validateAndFormatValue = (value) => {
    if (value && value > 0) {
      return (
        <span>
          <Data value={value} type="number" emptyMessage="0" /> pts
        </span>
      );
    }
    return <span>0 pts</span>;
  };

  return (
    <div data-testid="service-preset-calculator">
      <div className={`${classes.subTotal} ${classes.flexSpaceBetween}`}>
        <span className={`${classes.subTotal}`}>
          {t("LoyaltProgram.SUBTOTAL")}
        </span>
        {notEnrolled ? (
          <div className={`${classes.subTotal}`}>
            {t("LoyaltProgram.NOT_ENROLLED")}
          </div>
        ) : (
          <SubTotal
            copied={copied}
            pricingMethod={pricingMethod}
            pricingPresetValue={pricingPresetValue}
            remainingBalance={remainingBalance}
            setCopied={setCopied}
            payWith={payWith}
          />
        )}
      </div>
      <div
        data-testid="service-preset-calculator-remaining-balance"
        className={`${classes.remainingBalance} ${classes.flexSpaceBetween}`}
      >
        <span>{t("SERVICES_MISSION_TAB.REMAINING_BALANCE")}</span>
        <div className="service-preset-calculator-remaining-balance-price">
          <IconStarFilled />
          {validateAndFormatValue(remainingBalance)}
        </div>
      </div>
    </div>
  );
}
