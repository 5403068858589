import SuggestedOrdersDomainV2 from "@domains/suggestedOrders/SuggestedOrdersDomainV2";
import { SuggestedOrderResponseV2 } from "@services/suggestedOrders/domains/SuggestedOrderResponseV2";

export default function SuggestedOrdersResponseToSuggestedOrdersConverterV2(
  data: Array<SuggestedOrderResponseV2>,
): SuggestedOrdersDomainV2 {
  const suggestedOrders = data.map((item) => ({
    sku: item.vendorItemId,
    isMarketplace: item.marketplace,
    isPromotion: !!item.promotion?.platformId,
    inventoryCount: stockOption(
      item.limit?.balance,
      item.inventory?.inventoryCount,
    ),
    mc: item.name,
    unitPrice: item.price?.pricePerUnit ?? item.price?.originalPrice,
    suggestedOrder: item.suggestedQuantity,
  }));

  return {
    suggestedOrders,
  };

  function stockOption(balance?: number, inventory?: number) {
    if (balance && inventory) {
      if (Number(inventory) === -1) {
        return Number(balance);
      }
      return Math.min(Number(inventory), Number(balance));
    }
    if (balance == null && inventory == null) {
      return 0;
    }
    return Number(balance) || Number(inventory);
  }
}
