import { CatalogTable } from "../../../catalogDb/CatalogDb";
import Catalog from "../../../../../domains/Catalog";
import { hasSkuOrName } from "./hasSkuOrName";

export const searchByNameOrSku = async (
  catalog: CatalogTable,
  text: string,
): Promise<Catalog> => {
  const result = await catalog
    .filter((item) => {
      return hasSkuOrName(item, text);
    })
    .toArray();

  return { items: result, searchValueAnalytics: text };
};
