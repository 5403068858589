import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { useStore } from "effector-react";
import { isFeatureEnabled } from "grow-shared-services";
import { Box, Grid, CircularProgress, Divider } from "@material-ui/core";
import { v4 as guid } from "uuid";
import { useAnalytics } from "../../analytics/useAnalytics";
import CustomToast, {
  TOAST_TYPES,
} from "../../components/CustomToast/CustomToast";
import {
  LINK_SERVER_ERROR,
  ANALYTICS_ROUTE_NAMES,
  LINK_HOME,
  SEGMENTATION_COUNTRIES,
  TARGET_KPI_TYPES,
} from "../../config/constants";
import {
  TLP_CTI,
  TLP_ENPS_AGENTS,
  TLP_MARKETPLACE,
  TLP_AUTO_UPDATE_DAILY_KPIS,
  TLP_MULTI_VENDOR,
  TLP_KPI_VENDOR,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../config/featureToggles";
import {
  callGetNextPocFailed,
  agentKpIsUxButtonClicked,
  errorMessageViewed,
} from "../../config/typewriter";
import { getUserInfos } from "../../config/utils/functions";
import * as AgentCallEvents from "../../stores/agentCall/AgentCallEvents";
import AgentCallStore from "../../stores/agentCall/AgentCallStore";
import { getMonthlyKPIEffect } from "../../stores/agentKPI/MonthlyKPIEvents";
import * as UpdateKPIEvents from "../../stores/agentKPI/UpdateKPIEvents";
import UpdateKPIStore from "../../stores/agentKPI/UpdateKPIStore";
import * as AgentSurveyEvents from "../../stores/agentSurvey/AgentSurveyEvents";
import AgentSurveyStore from "../../stores/agentSurvey/AgentSurveyStore";
import { showModalStopAutoDialerEvent } from "../../stores/autodialer/AutoDialerEvents";
import AutoDialerStore from "../../stores/autodialer/AutoDialerStore";
import { clearCatalog } from "../../stores/catalog/CatalogEvents";
import GlobalAdminConfigStore from "../../stores/globaAdminConfig/GlobalAdminConfigStore";
import LoyaltyProgramStore from "../../stores/loyaltyProgram/LoyaltyProgramStore";
import { getMarketplace } from "../../stores/marketplace/MarketplaceEvents";
import { clearNewOfferings } from "../../stores/newOfferings/NewOfferingsEvents";
import * as GetNextPocEvents from "../../stores/nextPoc/getNextPoc/GetNextPocEvents";
import * as GetOpenCallEvents from "../../stores/nextPoc/getOpenCall/GetOpenCallEvents";
import VendorStore from "../../stores/vendor/VendorStore";
import * as GetOpenCallUseCase from "../../usecase/nextPoc/getOpenCall/GetOpenCallUseCase";
import AverageHandleTime from "./averageHandleTime/AverageHandleTime";
import EnpsAgents from "./enpsAgents/EnpsAgents";
import Header from "./header/Header";
import HitRate from "./hitRate/HitRate";
import HomeMainPageStyles from "./HomeMainPageStyles";
import useTargetKpis from "./hooks/useTargetKpis";
import MonthlyKpis from "./monthlyKpis/MonthlyKpis";
import TargetCallList from "./targetCallList/TargetCallList";
import ToplineUplift from "./toplineUplift/ToplineUplift";
import UpdateCardButton from "./updateCardButton/UpdateCardButton";

function HomeMainPage(): React.ReactElement {
  const { t } = useTranslation();
  const classes = HomeMainPageStyles();
  const history = useHistory();
  const location = useLocation();
  const { clientId } = useStore(AgentCallStore);
  const { autoDialerEnabled } = useStore(AutoDialerStore);

  const AgentSurveyStates = useStore(AgentSurveyStore);
  const { balance: loyaltBalance } = useStore(LoyaltyProgramStore);
  const { dispatchGenericEvent, dispatchPocEvent } = useAnalytics();
  const updateKPIState = useStore(UpdateKPIStore);
  const { updateKPI } = updateKPIState;
  const {
    isMonthlyKpiEnabled,
    dailyCallListTarget,
    dailyAverageHandleTimeTarget,
    dailyToplineUpliftTarget,
    isTargetVisible,
    shouldRenderDailyAht,
    shouldRenderDailyToplineUplift,
  } = useTargetKpis();
  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);
  const { vendor, done: vendorIsDone } = useStore(VendorStore);

  const ctiEnabled = isFeatureEnabled(TLP_CTI);

  const { vendorId, userEmail, country, toggle, orgId } = getUserInfos();

  const vendorName = "getVendorName(vendorId)";

  const isEnpsAgentsEnabled = isFeatureEnabled(TLP_ENPS_AGENTS);

  const isMarketplaceEnabled = isFeatureEnabled(TLP_MARKETPLACE);

  const isOrganizationConfigEnabled = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const isAutoUpdateDailyKpisEnable = isFeatureEnabled(
    TLP_AUTO_UPDATE_DAILY_KPIS,
  );

  const isMultiVendor = isFeatureEnabled(TLP_MULTI_VENDOR);

  const isKpiVendor = isFeatureEnabled(TLP_KPI_VENDOR);

  const validateVendorName = isKpiVendor ? vendorName : "";

  const dispatchAnalyticsPocEvent = useCallback(
    (errorMessage: string, errorType: string, screenSection: string) => {
      dispatchPocEvent(
        errorMessageViewed,
        {
          error_message: errorMessage,
          error_type: errorType,
          screen_name: ANALYTICS_ROUTE_NAMES.get(LINK_HOME),
          screen_section: screenSection,
          is_all_products: null,
          total_gap: null,
          total_vol_gap: null,
        },
        { time_of_day: true, is_resumed: true },
      );
    },
    [dispatchPocEvent],
  );

  /* istanbul ignore next */
  const handleUpdateCardButton = () => {
    const buttonName = t("HomeMainPage.UPDATE_CARD_BUTTON");
    UpdateKPIEvents.getUpdateKPIEffect({
      login: userEmail,
      zone: country,
      vendorId,
      vendorName: validateVendorName,
    });
    dispatchGenericEvent(agentKpIsUxButtonClicked, {
      button_name: buttonName,
      button_label: "update-agents-kpis-card",
      screen_section: "Daily Results",
      filter_option: "",
    });
    return false;
  };

  //  get the survey on first render and after changes at isLoaded state
  useEffect(() => {
    clearCatalog();
    clearNewOfferings();

    if (!AgentSurveyStates.isLoaded && isEnpsAgentsEnabled) {
      const aux = {
        agentId: userEmail,
      };

      AgentSurveyEvents.AgentSurveyFirstSurveyForAgentEffect(aux);
    }
  }, [userEmail, AgentSurveyStates.isLoaded, isEnpsAgentsEnabled]);

  useEffect(() => {
    if (ctiEnabled && !window.localStorage.getItem("shouldShowCTIMessage")) {
      CustomToast({
        type: TOAST_TYPES.SUCCESS,
        message: t("CTIbrazil.NOTIFICATION"),
      });

      window.localStorage.setItem("shouldShowCTIMessage", "true" as string);
    }
  }, [ctiEnabled, t]);

  useEffect(() => {
    const unWatchGetNextPocFail = GetNextPocEvents.getNextPocFail.watch(
      (message) => {
        dispatchGenericEvent(
          callGetNextPocFailed,
          {
            screen_name: "Agent KPIs",
            screen_section: "Home Header",
            error_type: "unable to get next poc error",
            error_message: "Service unavailable. Try again later.",
          },
          { time_of_day: true },
        );

        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: t(message[0]),
          errorCode: message[1],
          InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
        });

        dispatchAnalyticsPocEvent(
          t(message[0]),
          "get next poc error",
          "Home Header",
        );

        /* istanbul ignore next */
        if (typeof unWatchGetNextPocFail === "function") {
          unWatchGetNextPocDone();
          unWatchGetNextPocFail();
        }
      },
    );

    /* istanbul ignore next */
    const notHasSegmentationUserConfig = () => {
      const { segmentation } = GlobalAdminConfigState.userConfig;
      return (
        SEGMENTATION_COUNTRIES.indexOf(toggle) > -1 &&
        (segmentation === null ||
          segmentation === undefined ||
          segmentation.length <= 0)
      );
    };

    const unWatchGetNextPocDone = GetNextPocEvents.getNextPocDone.watch(
      (response) => {
        /* istanbul ignore if */
        if (notHasSegmentationUserConfig()) {
          CustomToast({
            type: TOAST_TYPES.ERROR,
            message: t("HomeMainPage.NO_SEGMENTATION"),
          });

          dispatchAnalyticsPocEvent(
            t("HomeMainPage.NO_SEGMENTATION"),
            "",
            "Home Header",
          );
        } else if (!response.clientId) {
          location.state = null;

          CustomToast({
            type: TOAST_TYPES.WARNING,
            message: t("HomeMainPage.CALL_LIST_COMPLETE"),
          });

          dispatchAnalyticsPocEvent(
            t("HomeMainPage.CALL_LIST_COMPLETE"),
            "",
            "Home Header",
          );
        }
      },
    );

    if (location.state) {
      CustomToast({
        type: TOAST_TYPES.WARNING,
        message: t(location.state.message),
      });

      if (location.state.analyticsErrorType === "error") {
        dispatchAnalyticsPocEvent(t(location.state.message), "", "");
      }
      /* istanbul ignore next */
      if (
        location.state.autodialer !== undefined &&
        autoDialerEnabled &&
        location.state.autodialer
      ) {
        showModalStopAutoDialerEvent(true);
      }

      history.replace({
        ...location,
        state: null,
      });
    }
    /* istanbul ignore next */
    if (typeof unWatchGetNextPocDone === "function") {
      unWatchGetNextPocDone();
      unWatchGetNextPocFail();
    }
  }, [
    userEmail,
    toggle,
    t,
    location,
    dispatchGenericEvent,
    history,
    loyaltBalance,
    GlobalAdminConfigState.userConfig,
    dispatchAnalyticsPocEvent,
    autoDialerEnabled,
  ]);

  useEffect(() => {
    const unWatchGetOpenCallFail = GetOpenCallEvents.getOpenCallFail.watch(
      () => {
        history.push(LINK_SERVER_ERROR);
      },
    );

    /* istanbul ignore next */
    if (GlobalAdminConfigState.done && !GlobalAdminConfigState.error) {
      if (isMultiVendor) {
        const { vendorId } = GlobalAdminConfigState.userConfig;
        const vendorName = "getVendorName(vendorId)";
        GetOpenCallUseCase.execute(userEmail, vendorId, vendorName);
      } else {
        GetOpenCallUseCase.execute(userEmail);
      }

      /* istanbul ignore next */
      if (isAutoUpdateDailyKpisEnable) {
        UpdateKPIEvents.getUpdateKPIEffect({
          login: userEmail,
          zone: country,
          vendorId,
          vendorName: validateVendorName,
        });
      }
    }

    /* istanbul ignore next */
    return () => {
      if (typeof unWatchGetOpenCallFail === "function") {
        unWatchGetOpenCallFail();
      }
    };
  }, [GlobalAdminConfigState.done, isMultiVendor]);

  useEffect(() => {
    AgentCallEvents.setIsResumed(clientId !== "");
  }, [clientId]);

  useEffect(() => {
    const unWatchUpdateKPI = UpdateKPIEvents.getUpdateKPIEffect.fail.watch(
      ({ error }) => {
        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: t("ErrorHandling_KPI.ERROR_UPDATE_KPI"),
          errorCode: error?.config?.headers?.requestTraceId,
          InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
        });

        dispatchAnalyticsPocEvent(
          t("ErrorHandling_KPI.ERROR_UPDATE_KPI"),
          "update agent kpi error",
          "Daily Results",
        );
      },
    );

    /* istanbul ignore next */
    return () => {
      if (typeof unWatchUpdateKPI === "function") {
        unWatchUpdateKPI();
      }
    };
  }, [t, dispatchAnalyticsPocEvent]);

  /* istanbul ignore next */
  useEffect(() => {
    const unWatchMonthlyKpi = getMonthlyKPIEffect.fail.watch(({ error }) => {
      CustomToast({
        type: TOAST_TYPES.ERROR,
        message: t("HomeMainPage.ERROR_GET_AGENT_KPIS"),
        errorCode: error?.config?.headers?.requestTraceId,
        InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
      });

      dispatchAnalyticsPocEvent(
        t("HomeMainPage.ERROR_GET_AGENT_KPIS"),
        "get monthly kpi error",
        "Monthly Results",
      );
    });

    return () => {
      if (typeof unWatchMonthlyKpi === "function") {
        unWatchMonthlyKpi();
      }
    };
  }, [t, isMonthlyKpiEnabled, dispatchAnalyticsPocEvent]);

  /* istanbul ignore next */
  useEffect(() => {
    if (isOrganizationConfigEnabled && isMarketplaceEnabled) {
      getMarketplace({
        country,
        vendorId,
        orgId,
        requestTraceId: guid(),
      });
    }
    if (!isOrganizationConfigEnabled && isMarketplaceEnabled && vendorIsDone) {
      getMarketplace({
        country,
        vendorId: vendor?.id ?? "",
        requestTraceId: guid(),
      });
    }
  }, [
    isMarketplaceEnabled,
    isOrganizationConfigEnabled,
    country,
    vendor?.id,
    vendorIsDone,
  ]);

  // eslint-disable-next-line
  const isDailyKpiUpdating = (): boolean => {
    return updateKPIState.isLoading;
  };

  return (
    <Box
      data-testid="page-home"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      height="90%"
    >
      <div className={classes.container}>
        <div>
          <Header />
        </div>

        <Grid container style={{ overflowY: "auto", minHeight: "100px" }}>
          <Grid
            container
            item
            xs={7}
            style={{ paddingRight: "10px" }}
            direction="column"
            data-testid="daily-kpis-container"
          >
            <div className={classes.dailyHeader}>
              <div className={classes.dailyKpiTitleContainer}>
                <span className={classes.kpisHeaderLabel}>
                  {t("HomeMainPage.DAILY_RESULTS")}
                </span>
                <UpdateCardButton click={handleUpdateCardButton} />
              </div>
              <Divider className={classes.divider} />
            </div>

            {isDailyKpiUpdating() ? (
              <Grid container item xs>
                <Box className={classes.loadingContainer}>
                  <CircularProgress data-testid="kpis-loading" />
                </Box>
              </Grid>
            ) : (
              <Grid container item xs>
                <Grid
                  container
                  item
                  xs
                  direction="column"
                  style={{
                    overflowX: "auto",
                    width: "300px",
                  }}
                >
                  {isTargetVisible(TARGET_KPI_TYPES.CALLS) && (
                    <TargetCallList
                      target={dailyCallListTarget}
                      countKPI={updateKPI.pocAssigned}
                    />
                  )}
                  {isTargetVisible(TARGET_KPI_TYPES.HR) && (
                    <HitRate
                      target={updateKPI.pocAssigned}
                      countKPI={updateKPI.hitRate}
                    />
                  )}
                  {shouldRenderDailyAht && (
                    <AverageHandleTime
                      target={dailyAverageHandleTimeTarget}
                      countKPI={updateKPI.averageHandleTime}
                    />
                  )}
                </Grid>

                {shouldRenderDailyToplineUplift && (
                  <Grid container item xs>
                    <Grid item xs={12}>
                      <ToplineUplift
                        target={dailyToplineUpliftTarget}
                        countKPI={updateKPI.pocAssigned}
                        gapUplift={updateKPI.toplineUplift}
                        totalGapUplift={updateKPI.totalGap}
                      />
                    </Grid>
                  </Grid>
                )}

                {isEnpsAgentsEnabled && AgentSurveyStates.surveyId && (
                  <EnpsAgents
                    form={AgentSurveyStates.surveyLink}
                    error={AgentSurveyStates.error}
                    surveyId={AgentSurveyStates.surveyId}
                    agentId={userEmail}
                  />
                )}
              </Grid>
            )}
          </Grid>
          {isMonthlyKpiEnabled ? (
            <Grid
              container
              direction="column"
              item
              xs={5}
              style={{
                paddingLeft: "10px",
              }}
              data-testid="monthly-kpis-container"
            >
              <div className={classes.monthlyHeader}>
                <span
                  className={classes.kpisHeaderLabel}
                  data-testid="monthly-results-label"
                >
                  {t("HomeMainPage.MONTHLY_RESULTS")}
                </span>
                <Divider className={classes.divider} />
              </div>
              <MonthlyKpis
                login={userEmail}
                zone={country}
                vendorId={vendorId}
                vendorName={validateVendorName}
              />
            </Grid>
          ) : null}
        </Grid>
      </div>
    </Box>
  );
}

export default HomeMainPage;
