import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: palette.secondary.main,
    },
    date: {
      fontWeight: 500,
      color: "#505050",
      fontSize: "0.75rem",
    },
    subtitle: {
      fontWeight: 500,
    },
    title: {
      marginRight: 5,
      fontFamily: "Work Sans",
    },
  }),
);
