import { GROW_INTERACTIVE_COMBO } from "@config/featureToggles";
import { ComboInterface } from "@services/promotions/domains/CombosResponseV2";
import { isFeatureEnabled } from "grow-shared-services";
import { ConvertedComboInterface } from "../../converters/promotions/promotionsV3/domains/CombosResponseToCombosInfoConverterDomain";
import { PromotionsInfoV3 } from "../../converters/promotions/promotionsV3/domains/PromotionsV3ResponseToPromotionsInfoConverterDomain";
import {
  getCombos,
  getCombosV2,
} from "../../services/promotions/promotionsV3/CombosService";
import { getPromotions } from "../../services/promotions/promotionsV3/PromotionsService";

interface ExecutePromotionsReturn {
  combos: ConvertedComboInterface[] | ComboInterface[];
  promotions: PromotionsInfoV3[];
}

export function execute(
  accountId: string,
  country: string,
  vendorName: string,
  vendorAccountId?: string,
  legacyAccountId?: string,
): Promise<ExecutePromotionsReturn> {
  const isGrowInteractiveComboIsEnabled = isFeatureEnabled(
    GROW_INTERACTIVE_COMBO,
  );

  const getCombosCall = isGrowInteractiveComboIsEnabled
    ? getCombosV2
    : getCombos;

  return Promise.allSettled([
    getPromotions(accountId, country, vendorName),
    getCombosCall(
      accountId,
      country,
      vendorName,
      vendorAccountId,
      legacyAccountId,
    ),
  ])
    .then((response) => {
      const promotions =
        response[0].status === "fulfilled" ? response[0].value : [];
      const combos =
        response[1].status === "fulfilled" ? response[1].value : [];

      const rejectedResponse = response.find(
        (item) => item.status === "rejected",
      );
      if (rejectedResponse) {
        throw new Error(
          rejectedResponse.status === "rejected" && rejectedResponse.reason,
        );
      }

      return { promotions, combos };
    })
    .catch((error) => {
      throw error;
    });
}
