import React, { FunctionComponent } from "react";
import TableCellBase from "@material-ui/core/TableCell";
import Data from "../data/Data";
import CollapsibleTableStyles from "./CollapsibleTableStyles";

interface Props {
  text: string | number;
  head?: boolean;
  className?: string; // To build a class with hight priority use "th&" selector https://sass-lang.com/documentation/style-rules/parent-selector
  color?: string;
  type?: "text" | "currency" | "number" | "date";
  testId: string;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customStyle?: any;
  timeZone?: boolean;
}

const TableCell: FunctionComponent<Props> = ({
  text,
  head,
  color,
  className,
  type,
  testId,
  title,
  customStyle,
  timeZone,
}) => {
  const classes = CollapsibleTableStyles();

  const getCellClasses = () => {
    let cellClasses = `${classes.tableItemCell} ${className}`;
    if (title) {
      cellClasses = `${cellClasses} ${classes.tooltip}`;
    }
    if (head) {
      cellClasses = `${cellClasses} ${classes.tableHeaderCell}`;
    }
    return cellClasses;
  };

  return (
    <TableCellBase
      align={type === "currency" ? "right" : "center"}
      classes={{ root: getCellClasses() }}
      style={{ color, ...customStyle }}
      data-testid={`${testId}-table-cell`}
      title={title}
    >
      <Data
        testId={`${testId}-number`}
        value={text}
        type={type}
        timeZone={timeZone}
      />
    </TableCellBase>
  );
};

TableCell.defaultProps = {
  className: "",
  head: false,
  color: "",
  type: "text",
  timeZone: false,
};

export default TableCell;
