import React from "react";
import { useTranslation } from "react-i18next";
import { Paragraph } from "@hexa-ui/components";
import Data from "../../../../../components/data/Data";
import { PromotionsPrice } from "../../../../../domains/promotions/domains/PromotionsPrice";
import { FormatedPromotion } from "../../../../../domains/promotions/FormatedPromotion";
import SteppedDiscountPricesStyles from "./SteppedDiscountPrices.styles";

interface Props {
  promotion: FormatedPromotion;
}

const SteppedDiscountPrices: React.FC<Props> = ({ promotion }) => {
  const classes = SteppedDiscountPricesStyles();
  const { t } = useTranslation();

  const testIdPrefix = "stepped-discount";

  const getStep = (item: PromotionsPrice) => {
    return ` ${item.stepStart} ${t("Promotions.TO")} ${item.stepEnd}`;
  };

  const getPercentage = (price: number) => {
    const difference =
      promotion.originalPrice && promotion.originalPrice - price;

    if (difference && promotion.originalPrice) {
      return `${Math.round((difference / promotion.originalPrice) * 100)}% ${t(
        "Promotions.OFF",
      )}`;
    }

    return "";
  };

  return (
    <div className={classes.container}>
      {promotion.steppedDiscountedPrice?.map((price) => (
        <div className={classes.contentContainer} key={price.id}>
          <Paragraph
            size="xsmall"
            colortype="primary"
            data-testid={`${testIdPrefix}-quantity-to-step`}
          >
            {t("Promotions.QUANTITY")}:{getStep(price)}
          </Paragraph>

          <div className={classes.percentagePriceContainer}>
            <Paragraph
              data-testid={`${testIdPrefix}-percentage-discounted`}
              size="xsmall"
              colortype="secondary"
              className={classes.uppercase}
            >
              {getPercentage(price.discountedPrice)}
            </Paragraph>
            <Paragraph size="small" colortype="success" weight="semibold">
              <Data
                value={price.discountedPrice}
                type="currency"
                testId={`${testIdPrefix}-${price.id}`}
                key={`${testIdPrefix}-${price.id}`}
              />
            </Paragraph>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SteppedDiscountPrices;
