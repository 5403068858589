import { CheckoutInfo } from "../../domains/CheckoutInfo";
import { CheckoutResponse } from "../../services/checkout/domains/CheckoutResponse";

export default function CheckoutResponseToCheckoutInfoConverter(
  data: CheckoutResponse,
): CheckoutInfo {
  return {
    carts: data.carts,
  };
}
