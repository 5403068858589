import PocInformation from "domains/PocInformation";
import { createStore } from "effector";
import { CreditResponse } from "services/pocInformation/domains/CreditResponse";
import {
  findByAccountId,
  pocInformationFail,
  resetPocInformation,
  searchPocByAccountId,
  setCreditPocInformation,
} from "./PocInformationEvents";
import PocInformationState from "./PocInformationState";

const initialState = <PocInformationState>{
  isLoaded: false,
  done: false,
  error: false,
  loading: false,
  searchedPoc: {
    email: "",
    id: "",
    name: "",
    owner: "",
    vendorAccountId: "",
    hasPONumberRequirement: false,
  },
  pocInformation: {
    id: "",
    accountId: "",
    name: "",
    owner: "",
    address: "",
    emails: [],
    phones: [],
    city: "",
    state: "",
    zipcode: "",
    totalCredit: undefined,
    availableCredit: undefined,
    creditExpiringDate: "",
    deliveryCenterId: "",
    segment: "",
    subSegment: "",
    deliveryWindows: [],
    paymentMethods: [],
    paymentTerms: [],
    additionalInformation: {},
    challengeIds: [],
    creditInfo: undefined,
    accumulativeDiscount: undefined,
    maxVehicleCapacity: undefined,
    salesOrg: "",
    salesAreaName: "",
    salesRegionCode: "",
    salesRegionName: "",
    salesSubregionCode: "",
    salesSubregionName: "",
    supervision: "",
    salesAreaRepName: "",
    salesZone: "",
    salesZoneName: "",
    distributionCenterName: "",
    taxId: "",
    liquorLicense: "",
    maximumOrder: undefined,
    vendorAccountId: "",
    vendorId: "",
    customerAccountId: "",
    representatives: [],
    metadata: [],
    hasPONumberRequirement: false,
  },
};

const pocInformationStore = createStore(initialState)
  .on(
    findByAccountId,
    (state: PocInformationState, pocInformation: PocInformation) => {
      return {
        ...state,
        isLoaded: true,
        pocInformation: {
          ...state.pocInformation,
          ...pocInformation,
        },
      };
    },
  )
  .on(
    setCreditPocInformation,
    (state: PocInformationState, creditPocInformation: CreditResponse) => {
      return {
        ...state,
        isLoaded: true,
        pocInformation: {
          ...state.pocInformation,
          creditInfo: creditPocInformation,
          totalCredit: creditPocInformation?.total ?? 0,
          availableCredit: creditPocInformation?.available ?? 0,
          creditExpiringDate: creditPocInformation?.updatedAt ?? "",
        },
      };
    },
  )
  .on(searchPocByAccountId.done, (state, value) => ({
    ...state,
    done: true,
    loading: false,
    error: false,
    searchedPoc: value.result,
  }))
  .on(searchPocByAccountId.fail, (state) => ({
    ...state,
    done: true,
    loading: false,
    error: true,
  }))
  .on(searchPocByAccountId, (state) => ({
    ...state,
    done: false,
    loading: true,
    error: false,
  }))
  .on(pocInformationFail, (state) => ({
    ...state,
    isLoaded: true,
    error: true,
  }))
  .reset(resetPocInformation);

export default pocInformationStore;
