import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import EmptyItem from "../emptyItem/EmptyItem";
import CartItemStore from "../../../../../stores/cart/CartItemStore";
import EmptyItemListStyles from "./EmptyItemListStyles";
import { EmptyItem as Empty } from "../../../../../domains/cart/Empties";

interface Props {
  shouldUpdateItem: (groupId: string, quantity: number) => void;
  empties: Array<Empty>;
}

const EmptyItemList: React.FC<Props> = ({ shouldUpdateItem, empties }) => {
  const classes = EmptyItemListStyles();
  const { t } = useTranslation();
  const { totalItems } = useStore(CartItemStore);

  return (
    <>
      {empties && empties.length > 0 && totalItems > 0 ? (
        empties?.map((each) => {
          return (
            <div className={classes.list} key={each.groupId}>
              <EmptyItem
                shouldUpdateItem={shouldUpdateItem}
                item={each}
                key={each.groupId}
              />
            </div>
          );
        })
      ) : (
        <div className={classes.noEmpties} data-testid="no-empties">{`${t(
          "Empties.NO_EMPTIES",
        )}`}</div>
      )}
    </>
  );
};

export default React.memo(EmptyItemList);
