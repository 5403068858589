import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";

import axios from "@config/axios/axiosInstance";
import {
  GROW_CUSTOMER_NOTES_V2,
  TLP_MULTICONTRACT_ACCOUNT_SERVICE,
  isFeatureEnabledV2,
} from "@config/featureToggles";
import { TLP_ACCOUNT_SERVICE } from "@config/services";

import CustomerNotesResponseToCustomerNotesConverter from "../../converters/customerNotes/CustomerNotesResponseToCustomerNotesConverter";
import { CustomerNote } from "../../domains/customerNote/customerNote";
import GlobalStore from "../../stores/global/GlobalStore";
import VendorStore from "../../stores/vendor/VendorStore";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../host/HostService";
import CustomerNoteResponse from "./domains/CustomerNoteResponse";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

const ACCOUNT_SERVICE = "/tlp-account";
const ACCOUNTS_PATH = "/accounts";
const NOTES_ENDPOINT = "/notes";

export function getCustomerNotes(
  accountId: string,
  vendorAccountId: string | undefined,
): Promise<CustomerNote | null> {
  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();
  const log = useLogService();

  const isMulticontractEnabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_ACCOUNT_SERVICE,
    user.zone,
  );

  // Technical/Temporary toggle
  const isCustomerNotesV2Enabled = isFeatureEnabledV2(
    GROW_CUSTOMER_NOTES_V2,
    user.zone,
  );

  const isV2Enabled = isMulticontractEnabled && isCustomerNotesV2Enabled;

  const vendorId = vendor?.id;

  const apiHost = getApiHost(TLP_ACCOUNT_SERVICE);
  const v2EndPoint = getV2EndPoint(isV2Enabled);
  const vendorIdParam = getVendorIdParam(vendorId, isV2Enabled);

  const idPathParameter = isV2Enabled
    ? vendorAccountId ?? accountId
    : accountId;

  const url = `${apiHost}${ACCOUNT_SERVICE}${v2EndPoint}${ACCOUNTS_PATH}/${idPathParameter}${NOTES_ENDPOINT}${vendorIdParam}`;

  return axios
    .get<CustomerNoteResponse>(url)
    .then((response) => {
      log.info(`Convert CustomerNotes received from ${url} to our model`);
      return CustomerNotesResponseToCustomerNotesConverter(response.data);
    })
    .catch((error) => {
      log.error(error);

      throw error;
    });
}
