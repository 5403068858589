import React from "react";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function IconCalendar({ color = "#000000" }): JSX.Element {
  return (
    <div
      style={{
        transform: "translateY(3px)",
        display: "inline-block",
      }}
    >
      <svg
        width="24px"
        height="24px"
        transform="translateY(-20)"
        viewBox="0 0 64 64"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path
            d="M41.4545455,15.2727273 C45.0695002,15.2727273 48,18.2032271 48,21.8181818 L48,41.4545455 C48,45.0695002 45.0695002,48 41.4545455,48 L21.8181818,48 C18.2032271,48 15.2727273,45.0695002 15.2727273,41.4545455 L15.2727273,21.8181818 C15.2727273,18.2032271 18.2032271,15.2727273 21.8181818,15.2727273 Z M41.4545455,19.6363636 L21.8181818,19.6363636 C20.6131969,19.6363636 19.6363636,20.6131969 19.6363636,21.8181818 L19.6363636,41.4545455 C19.6363636,42.6595304 20.6131969,43.6363636 21.8181818,43.6363636 L41.4545455,43.6363636 C42.6595304,43.6363636 43.6363636,42.6595304 43.6363636,41.4545455 L43.6363636,21.8181818 C43.6363636,20.6131969 42.6595304,19.6363636 41.4545455,19.6363636 Z M26.1818182,0 C29.7146148,0 32.5937146,2.79880897 32.722758,6.30006735 L32.7272727,6.54545455 L32.7272727,8.72727273 C32.7272727,9.93225764 31.7504395,10.9090909 30.5454545,10.9090909 C29.3952417,10.9090909 28.4529108,10.0190424 28.3696208,8.89010438 L28.3636364,8.72727273 L28.3636364,6.54545455 C28.3636364,5.39524168 27.4735879,4.45291078 26.3446498,4.3696208 L26.1818182,4.36363636 L6.54545455,4.36363636 C5.39524168,4.36363636 4.45291078,5.25368486 4.3696208,6.3826229 L4.36363636,6.54545455 L4.36363636,26.1818182 C4.36363636,27.332031 5.25368486,28.274362 6.3826229,28.3576519 L6.54545455,28.3636364 L8.72727273,28.3636364 C9.93225764,28.3636364 10.9090909,29.3404696 10.9090909,30.5454545 C10.9090909,31.6956674 10.0190424,32.6379983 8.89010438,32.7212883 L8.72727273,32.7272727 L6.54545455,32.7272727 C3.01265788,32.7272727 0.133558078,29.9284638 0.00451469679,26.4272054 L0,26.1818182 L0,6.54545455 C0,3.01265788 2.79880897,0.133558078 6.30006735,0.00451469679 L6.54545455,0 L26.1818182,0 Z"
            id="copy-path-1"
          />
        </defs>
        <g
          id="___Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            transform="translate(8.000000, 8.000000)"
          >
            <mask id="copy-mask-2" fill="white">
              <use xlinkHref="#copy-path-1" />
            </mask>
            <g id="Mask" fillRule="nonzero" />
            <g
              mask="url(#copy-mask-2)"
              fill={color}
              fillOpacity="0.9"
              id="Rectangle"
            >
              <g transform="translate(-8.000000, -8.000000)">
                <rect x="0" y="0" width="64" height="64" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
