import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { getUserInfos } from "@config/utils/functions";
import axios from "../../config/axios/axiosInstance";
import { getApiHost } from "../host/HostB2BService";
import DeliveryWindowsDomain from "../../domains/deliveryWindow/DeliveryWindowsDomain";
import DeliveryWindowsResponse from "./domains/DeliveryWindowsResponse";
import DeliveryWindowResponseToDeliveryWindowConverter from "../../converters/deliveryWindow/DeliveryWindowResponseToDeliveryWindowConverter";

export const ACCOUNT_ENDPOINT = `account-business`;
export const DELIVERY_WINDOWS_ENDPOINT = "delivery-windows";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getDeliveryWindow(
  accountId: string,
): Promise<DeliveryWindowsDomain> {
  const log = useLogService();

  const { vendorId } = getUserInfos();

  const endpoint = `/api/v1/${ACCOUNT_ENDPOINT}/v2/${DELIVERY_WINDOWS_ENDPOINT}?accountId=${accountId}&vendorId=${vendorId}`;

  return axios
    .get<DeliveryWindowsResponse>(`${getApiHost()}${endpoint}`)
    .then((response) => {
      log.info(`Return the data from ${endpoint}`);
      return DeliveryWindowResponseToDeliveryWindowConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
