import React, { useMemo } from "react";
import { useStore } from "effector-react";
import { Box } from "@material-ui/core";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";

import {
  TAB_B2B_TRAINER_ROUTE,
  TAB_CUSTOMER_ROUTE,
  TAB_ORDERS_ROUTE,
} from "../../../../config/constants";
import {
  isFeatureEnabled,
  TLP_FLOATING_CUSTOMER_NOTES,
  TLP_FLOATING_CUSTOMER_NOTES_ADMIN,
  TLP_CUSTOMER_PAGE,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";
import B2BTrainer from "../../tabsContent/B2BTrainer";
import Orders from "../../tabsContent/orders/Orders";
import Missions from "../../tabsContent/missions/Missions";
import CustomerPage from "../../tabsContent/customer/CustomerPage";
import FloatingCustomerNotes from "../customer/floatingCustomerNotes/FloatingCustomerNotes";
import CallTabItemsStyles from "./CallTabItems.style";
import GlobalStore from "../../../../stores/global/GlobalStore";

const CallTabItems: React.FunctionComponent = () => {
  const { callTab } = useStore(CallTabStore);
  const classes = CallTabItemsStyles();
  const {
    user: { zone },
  } = useStore(GlobalStore);

  const isFloatingNotesEnabled = isFeatureEnabled(
    TLP_FLOATING_CUSTOMER_NOTES,
    TLP_FLOATING_CUSTOMER_NOTES_ADMIN,
    zone,
  );

  const isCustomerPageEnabled = isFeatureEnabledV2(TLP_CUSTOMER_PAGE, zone);

  let component;
  switch (callTab) {
    case TAB_B2B_TRAINER_ROUTE:
      component = <B2BTrainer />;
      break;
    case TAB_ORDERS_ROUTE:
      component = <Orders />;
      break;
    case TAB_CUSTOMER_ROUTE:
      component = <CustomerPage />;
      break;
    // default is TAB_GAP
    default:
      component = <Missions />;
      break;
  }
  const showCustomerNotes = useMemo(
    () => callTab !== TAB_CUSTOMER_ROUTE && callTab !== TAB_B2B_TRAINER_ROUTE,
    [callTab],
  );

  return (
    <>
      {component}
      {showCustomerNotes && isCustomerPageEnabled && isFloatingNotesEnabled && (
        <Box className={classes.container}>
          <FloatingCustomerNotes />
        </Box>
      )}
    </>
  );
};

export default React.memo(CallTabItems);
