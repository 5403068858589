import {
  LastRedeemResponse,
  LastRedeemConvertedReturn,
} from "../../domains/redeem/Redeem";

const LastRedeemResponseToLastRedeemConverter = (
  data: LastRedeemResponse,
): LastRedeemConvertedReturn => {
  return {
    lastRedeem: data[0]?.timestamp,
  };
};

export default LastRedeemResponseToLastRedeemConverter;
