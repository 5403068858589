export const gapReasonValues = {
  EMPTY: "",
  NO_CREDIT_AVAILABLE: "NO_CREDIT_AVAILABLE",
  FINANCIAL_REASON: "FINANCIAL_REASON",
  LOW_DEMAND: "LOW_DEMAND",
  TECHNICAL_PROBLEM: "TECHNICAL_PROBLEM",
  LACK_OF_KNOWLEDGE: "LACK_OF_KNOWLEDGE",
  FORGOT: "FORGOT",
  ANOTHER_SUPPLIER: "ANOTHER_SUPPLIER",
  NOT_IDENTIFIED: "NOT_IDENTIFIED",
};

export default [
  {
    value: gapReasonValues.EMPTY,
    label: "GapReason.EMPTY",
  },
  {
    value: gapReasonValues.NO_CREDIT_AVAILABLE,
    label: "GapReason.NO_CREDIT_AVAILABLE",
  },
  {
    value: gapReasonValues.FINANCIAL_REASON,
    label: "GapReason.FINANCIAL_REASON",
  },
  {
    value: gapReasonValues.LOW_DEMAND,
    label: "GapReason.LOW_DEMAND",
  },
  {
    value: gapReasonValues.TECHNICAL_PROBLEM,
    label: "GapReason.TECHNICAL_PROBLEM",
  },
  {
    value: gapReasonValues.LACK_OF_KNOWLEDGE,
    label: "GapReason.LACK_OF_KNOWLEDGE",
  },
  {
    value: gapReasonValues.FORGOT,
    label: "GapReason.FORGOT",
  },
  {
    value: gapReasonValues.ANOTHER_SUPPLIER,
    label: "GapReason.ANOTHER_SUPPLIER",
  },
  {
    value: gapReasonValues.NOT_IDENTIFIED,
    label: "GapReason.NOT_IDENTIFIED",
  },
];
