/* eslint-disable global-require */
import React from "react";
import { ButtonBase } from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ChevronLeft";
import ArrowRightIcon from "@material-ui/icons/ChevronRight";
import CollapseButtonStyles from "./CollapseButtonStyles";

interface Props {
  tab: string;
  collapsibleIsOpen: boolean;
  handleCollapsible: (value: string) => void;
}

function CollapseButton({
  tab,
  collapsibleIsOpen,
  handleCollapsible,
}: Readonly<Props>): React.ReactElement {
  const classes = CollapseButtonStyles();

  const renderArrow = () =>
    collapsibleIsOpen ? (
      <span data-testid="arrow-left-icon" className={classes.iconContainer}>
        <ArrowLeftIcon classes={{ root: classes.icon }} />
      </span>
    ) : (
      /* istanbul ignore next */
      <span data-testid="arrow-right-icon" className={classes.iconContainer}>
        <ArrowRightIcon classes={{ root: classes.icon }} />
      </span>
    );

  return (
    <ButtonBase
      className={classes.button}
      onClick={/* istanbul ignore next */ () => handleCollapsible(tab)}
      data-testid="collapsible-button"
    >
      {renderArrow()}
    </ButtonBase>
  );
}
export default CollapseButton;
