import { makeStyles, Theme, createStyles } from "@material-ui/core";

const tableViewStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      backgroundColor: theme.palette.background.paper,
      paddingRight: "10px",
      whiteSpace: "nowrap",
      display: "flex",
      flex: 1,
    },
    tableHeader: {
      boxShadow: "8px 0px 8px 0px rgba(0, 0, 0, 0.25)",
    },
    tableFooter: {
      width: "100%",
      backgroundColor: "#343434",
      paddingRight: "10px",
      whiteSpace: "nowrap",
    },
    alignLeft: {
      textAlign: "left",
    },
    productName: {
      borderRight: "1px solid #979797",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "400px",
      minWidth: "200px",
      marginRight: "8px",

      [theme.breakpoints.down(1440)]: {
        maxWidth: "200px",
      },
      [theme.breakpoints.down(1800)]: {
        maxWidth: "320px",
      },
    },
    toolbar: {
      padding: "0px 8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      minHeight: "18px",
      height: "18px",
      marginTop: "8px",
      marginBottom: "10px",
    },
    gapOnly: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      whiteSpace: "nowrap",
    },
    switchStyles: {
      backgroundColor: "#7a7a7a",
      width: 32,
    },
    switchBase: {
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          border: "none",
        },
      },
    },
    thumb: {
      backgroundColor: "#505050",
      width: 18,
      height: 18,
    },
    checked: {},
    subtitle: {
      fontWeight: 600,
      color: theme.palette.text.secondary,
    },
  }),
);
export default tableViewStyles;
