import React from "react";
import Switch from "@material-ui/core/Switch";
import CustomSwitchStyles from "./CustomSwitch.styles";

interface CustomSwitchProps {
  onChange: () => void;
  checked?: boolean;
  testId?: string;
}

const CustomSwitch = ({
  onChange,
  checked,
  testId,
}: CustomSwitchProps): JSX.Element => {
  const classes = CustomSwitchStyles();

  return (
    <Switch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
      }}
      onChange={() => onChange()}
      checked={checked}
      data-testid={testId}
    />
  );
};

export default CustomSwitch;

CustomSwitch.defaultProps = {
  checked: false,
  testId: "switch-component",
};
