import { Box, ButtonBase, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import classNames from "classnames";
import { useStore } from "effector-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import ControlledAddition from "../../../../../components/controlledAddition/ControlledAddition";
import Data from "../../../../../components/data/Data";
import FormattedStock from "../../../../../components/formattedStock/FormattedStock";
import GenericTag from "../../../../../components/tag/GenericTag";
import { PROPS_TAG } from "../../../../../components/tag/constants";
import Tooltip from "../../../../../components/tooltipCard/TooltipCard";
import {
  PROMOTIONS_ABA_TYPES,
  PROMOTIONS_TYPES,
} from "../../../../../config/constants";
import {
  GROW_WIP_FREE_GOODS,
  TLP_OFFERINGS_COMBOS_CONSUMPTION,
  TLP_OFFERINGS_COMBO_ADD_TO_CART,
  TLP_OFFERINGS_COMBO_REDIRECT_LINK,
  TLP_OFFERINGS_PROMOTIONS_ADD_TO_CART,
  TLP_OFFERINGS_PROMOTIONS_REDIRECT_LINK,
  TLP_OFFERINGS_PROMOTIONS_STOCK,
  isFeatureEnabledV2,
} from "../../../../../config/featureToggles";
import {
  offeringsProductAdded,
  offeringsProductQuantityEdited,
  offeringsUxButtonClicked,
} from "../../../../../config/typewriter";
import { ConvertedComboInterface as ComboTypeV2 } from "../../../../../converters/promotions/promotionsV3/domains/CombosResponseToCombosInfoConverterDomain";
import { Combo as ComboType } from "../../../../../domains/promotions/Combo";
import { Promotions as PromotionsType } from "../../../../../domains/promotions/Promotions";
import GetRemainingDays from "../../../../../helpers/GetRemainingDays";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import PocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import { addItem as addProductItem } from "../../../../../stores/cart/CartItemEvents";
import { addItem } from "../../../../../stores/cartCombo/CartComboEvents";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import cardStyles from "./CardStyles";
import ComboSection from "./ComboSection";
import ShowMoreInfo from "./showMoreInfo/ShowMoreInfoCombos";

const { MARKETPLACE } = PROPS_TAG;

interface Props {
  data: ComboType | PromotionsType;
  type: PROMOTIONS_ABA_TYPES;
  allExpanded: boolean;
}

export enum BUTTON_LABELS {
  EXPAND = "expand",
  COLLAPSE = "collapse",
}
const Card: React.FC<Props> = ({ data, type, allExpanded }) => {
  const classes = cardStyles();
  const { t } = useTranslation();

  const AgentCallStates = useStore(AgentCallStore);
  const GlobalStates = useStore(GlobalStore);
  const { pocInformation } = useStore(PocInformationStore);

  const [isOpen, setIsOpen] = useState(false);
  const [itemQuantity, setItemQuantity] = useState<number>(1);

  const { dispatchGenericEvent } = useAnalytics();

  const isOfferingsComboAddToCartEnabled = isFeatureEnabledV2(
    TLP_OFFERINGS_COMBO_ADD_TO_CART,
    GlobalStates.user.zone,
  );

  const isCombosConsumptionEnabled = isFeatureEnabledV2(
    TLP_OFFERINGS_COMBOS_CONSUMPTION,
    GlobalStates.user.zone,
  );

  const isComboRedirectLinkEnabled = isFeatureEnabledV2(
    TLP_OFFERINGS_COMBO_REDIRECT_LINK,
    GlobalStates.user.keyToggle,
  );

  const isFreeGoodCardEnabled = isFeatureEnabledV2(
    GROW_WIP_FREE_GOODS,
    GlobalStates.user.keyToggle,
  );

  const isAddPromoItemEnabled = isFeatureEnabledV2(
    TLP_OFFERINGS_PROMOTIONS_ADD_TO_CART,
    GlobalStates.user.keyToggle,
  );

  const isPromotionsRedirectLinkEnabled = isFeatureEnabledV2(
    TLP_OFFERINGS_PROMOTIONS_REDIRECT_LINK,
    GlobalStates.user.keyToggle,
  );

  const isPromotionsStockEnabled = isFeatureEnabledV2(
    TLP_OFFERINGS_PROMOTIONS_STOCK,
    GlobalStates.user.keyToggle,
  );

  const handleOpenAccordion = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    const getButtonName = () =>
      isOpen ? t("Promotions.DESCRIPTION") : t("Promotions.SEE_MORE");
    dispatchGenericEvent(offeringsUxButtonClicked, {
      button_name: getButtonName(),
      button_label: !isOpen ? BUTTON_LABELS.EXPAND : BUTTON_LABELS.COLLAPSE,
      promotions_section: type,
    });

    if (!isOpen) localStorage.setItem("lookedPromotion", "true");
  };

  useEffect(() => {
    if (allExpanded) localStorage.setItem("lookedPromotion", "true");
    setIsOpen(allExpanded);
  }, [allExpanded]);

  const setAnalyticsOfferObjectToCart = (quantity: number) => {
    return {
      call_id: AgentCallStates.callId,
      combo_id: type === PROMOTIONS_ABA_TYPES.COMBO ? data.id : null,
      is_out_of_stock:
        type === PROMOTIONS_ABA_TYPES.PROMOTION
          ? (data as PromotionsType).inventoryCount === 0
          : null,
      poc_id: AgentCallStates.clientId,
      promotions_section: type,
      quantity,
      sku:
        type === PROMOTIONS_ABA_TYPES.PROMOTION
          ? (data as PromotionsType).sku
          : null,
    };
  };

  const addCombo = (id: string, quantity: number) => {
    const combo = data as ComboType;

    /* istanbul ignore next */
    const item = {
      id,
      title: combo?.title,
      description: data?.description,
      price: combo?.price || 0,
      limit: combo.limit,
      platformId: combo.platformId,
      itemQuantity: quantity,
      items: combo?.items || [],
    };

    addItem({ ...item });

    offeringsProductAdded(setAnalyticsOfferObjectToCart(quantity));
  };

  const handleAddPromoItem = () => {
    const productItem = data as PromotionsType;

    const item = {
      id: productItem.itemId,
      platformId: productItem.platformId,
      price: productItem.originalPrice,
      unitPrice: productItem.originalPrice,
      sku: productItem.sku,
      itemName: productItem.itemName,
      itemQuantity,
      returnable: false,
      notFound: false,
      segment: "",
      total: productItem.originalPrice * itemQuantity,
      inventoryCount: productItem.inventoryCount,
      palletQuantity: productItem.palletQuantity,
      availabilityCount: productItem.availability?.count,
    };

    addProductItem({
      ...item,
      maxVehicleCapacity: pocInformation.maxVehicleCapacity,
    });

    offeringsProductAdded(setAnalyticsOfferObjectToCart(itemQuantity));
  };

  const handleChangeQuantity = (alteredQuantity: number) => {
    offeringsProductQuantityEdited({
      poc_id: AgentCallStates.clientId,
      call_id: AgentCallStates.callId,
    });
    setItemQuantity(alteredQuantity);
  };

  const getComboMaxValueV1 = (data: ComboType) => {
    return Number(data.limit?.daily) > 0 ? data.limit?.daily : undefined;
  };

  const getComboMaxValueV2 = (data: ComboTypeV2) => {
    return Number(data.limit?.balance) > 0 ? data.limit?.balance : null;
  };

  const isComboPromotion = (isFreeGood?: PROMOTIONS_TYPES) =>
    type === PROMOTIONS_ABA_TYPES.COMBO &&
    (data.type === PROMOTIONS_TYPES.COMBO_DISCOUNT ||
      data.type === PROMOTIONS_TYPES.COMBO_DISCOUNT_V2 ||
      (isFreeGood && isFreeGoodCardEnabled));

  const renderAddSection = (isFreeGood?: PROMOTIONS_TYPES) => {
    if (isComboPromotion(isFreeGood) && isOfferingsComboAddToCartEnabled) {
      const maxValuePermited = isCombosConsumptionEnabled
        ? getComboMaxValueV2(data as unknown as ComboTypeV2)
        : getComboMaxValueV1(data as ComboType);

      return (
        <div className={classes.ControlledAddition}>
          <ControlledAddition
            testId="combo-controlled-addition"
            addAction={() =>
              maxValuePermited !== null && addCombo(data.id, itemQuantity)
            }
            itemQuantityChangeAction={handleChangeQuantity}
            showCartLink={isComboRedirectLinkEnabled}
            minValue={maxValuePermited ? 1 : 0}
            maxValue={maxValuePermited || 0}
            itemQuantity={maxValuePermited ? itemQuantity : 0}
            setItemQuantity={setItemQuantity}
          />
        </div>
      );
    }

    if (type === PROMOTIONS_ABA_TYPES.PROMOTION && isAddPromoItemEnabled)
      return (
        <Box className={classes.promoAddButtonSection}>
          <ControlledAddition
            testId={`deals-promotion-${data.id}`}
            addAction={handleAddPromoItem}
            itemQuantityChangeAction={handleChangeQuantity}
            showCartLink={isPromotionsRedirectLinkEnabled}
            itemQuantity={itemQuantity}
            setItemQuantity={setItemQuantity}
          />
          {isPromotionsStockEnabled &&
            renderStockSection(data as PromotionsType)}
        </Box>
      );

    return null;
  };

  const getTitlePromotions = (title: string) => {
    return title ? title.toString() : t("GlobalMessage.NO_DATA_AVAILABLE");
  };

  const renderShowMore = () => {
    if (isCombosConsumptionEnabled && type === PROMOTIONS_ABA_TYPES.COMBO) {
      const combo = data as unknown as ComboTypeV2;
      if (
        (combo.remainingDays != null && combo.remainingDays >= 0) ||
        (combo.limit?.totalDailyBalance != null &&
          combo.limit?.totalMonthlyBalance != null)
      )
        return (
          <Tooltip
            value={
              <ShowMoreInfo
                dailyBalance={combo.limit?.totalDailyBalance}
                monthlyBalance={combo.limit?.totalMonthlyBalance}
                remainingDays={combo.remainingDays}
              />
            }
          >
            <Typography
              variant="h3"
              className={classes.showMoreWhenCombos}
              data-testid="card-show-more"
            >
              {t("Promotions.SHOW_MORE")}
            </Typography>
          </Tooltip>
        );
    }

    if (isPromotionsStockEnabled && type === PROMOTIONS_ABA_TYPES.PROMOTION) {
      const promotion = data as PromotionsType;
      /* istanbul ignore else */
      if (
        promotion.availability?.count &&
        promotion.availability.count > 0 &&
        promotion.endDate
      )
        return (
          <Tooltip
            value={
              <ShowMoreInfo
                remainingDays={GetRemainingDays(promotion.endDate)}
              />
            }
          >
            <Typography
              variant="h3"
              className={classes.showMoreWhenPromotions}
              data-testid="card-show-more"
            >
              {t("Promotions.SHOW_MORE")}
            </Typography>
          </Tooltip>
        );
    }

    return null;
  };

  const renderStockSection = (data: PromotionsType) => {
    return <FormattedStock quantity={data.availability?.count} />;
  };

  return (
    <Accordion
      classes={{ root: classes.accordion }}
      expanded={isOpen}
      data-testid={`promotions-card-accordion-${data.id}`}
    >
      <AccordionSummary
        classes={{
          root: classes.accordionSummaryRoot,
          content: classes.accordionSummaryContent,
        }}
      >
        <div
          className={classes.title}
          data-testid={`promotions-card-title-${data.id}`}
        >
          <Data
            value={getTitlePromotions(data.title)}
            type="text"
            testId={`promotions-card-title-${data.id}`}
          />
          {data.marketplace && (
            <div className={classes.marketplace}>
              <GenericTag
                translationKey={MARKETPLACE.translationKey}
                style={{
                  backgroundColor: MARKETPLACE.backgroundColor,
                }}
                testId={`promotions-${data.id}-${MARKETPLACE.testId}`}
              />
            </div>
          )}
        </div>

        <ButtonBase
          className={classNames(classes.expandButton, {
            [classes.accordionExpandedBackground]: isOpen,
          })}
          onClick={handleOpenAccordion}
          data-testid={`promotions-card-expand-button-${data.id}`}
        >
          {isOpen ? (
            <RemoveIcon
              className={classes.icon}
              data-testid={`promotions-card-remove-icon-${data.id}`}
            />
          ) : (
            <AddIcon
              className={classes.icon}
              data-testid={`promotions-card-add-icon-${data.id}`}
            />
          )}

          <div className={classes.expandButtonLabel}>
            {isOpen ? t("Promotions.DESCRIPTION") : t("Promotions.SEE_MORE")}
          </div>
        </ButtonBase>
      </AccordionSummary>
      <AccordionDetails
        className={classNames(classes.accordionDetails, {
          [classes.accordionExpandedBackground]: isOpen,
        })}
      >
        <Typography className={classes.description}>
          <Data
            value={data.description}
            type="text"
            testId={`promotions-card-description-${data.id}`}
          />
        </Typography>
        <div className={classes.itemsContainer}>
          <ComboSection
            data={data}
            type={type}
            renderAddSection={renderAddSection}
          />
        </div>
        {renderAddSection()}
        {renderShowMore()}
      </AccordionDetails>
    </Accordion>
  );
};

export default Card;
