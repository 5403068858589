import { makeStyles, Theme, createStyles } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      width: 0,
    },
    notesBox: {
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "16px",
      backgroundColor: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
      boxShadow: "0 4px 16px 0 rgba(0,0,0,0.16)",
      height: "41px",
      width: "auto",
      padding: "2px 4px",
      marginBottom: "-22px",
    },
    notes: {
      margin: "10px 6px",
      color: "rgba(0,0,0,0.9)",
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontWeight: 600,
      letterSpacing: "0.1px",
      lineHeight: "20px",
    },
    arrowBackIcon: {
      color: "#FFFFFF",
      marginLeft: "6px",
      width: "16px",
      height: "17px",
    },
    arrowIcon: {
      color: "#FFFFFF",
      width: "16px",
      height: "17px",
    },
    floatingButton: {
      width: "36px",
      height: "36px",
      backgroundColor: "#000000",
      "&:hover": {
        backgroundColor: "#000000",
      },
    },
    paper: {
      width: "327px",
      marginLeft: "-350px",
      borderRadius: "16px",
      minHeight: "181px",
    },
    paperClosed: {
      width: 0,
      marginRight: "-30px",
      borderRadius: "16px",
      height: 0,
    },
    registerNoteBtn: {
      border: `solid 1px ${theme.palette.common.black}`,
      color: theme.palette.common.black,
      fontSize: "14px",
      borderRadius: "16px",
      height: "32px",
      textTransform: "none",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
    },
    textArea: {
      marginTop: "4px",
      flex: 1,
    },
    textInput: {
      width: "280px",
      borderRadius: "0.2rem",
      height: "60px",
      border: `solid 1px ${theme.palette.grey.A100}`,
      padding: "0.43rem",
      outline: "none",
      "&:focus": {
        border: `solid 1px ${theme.palette.common.black}`,
      },
      resize: "none",
    },
    boxChecked: {
      padding: "24px",
    },
    containerButton: {
      display: "flex",
      alignItems: "flex-end",
      height: "0px",
      marginTop: "178px",
    },
    errorContent: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    loading: {
      marginTop: "1rem",
    },
    textAreaCounter: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
  }),
);
