import GlobalAdminConfigDomain from "domains/globaAdminConfig/globaAdminConfig";
import { isFeatureEnabled } from "grow-shared-services";
import { GROW_ORGANIZATION_ACTIVATION } from "@config/featureToggles";
import { getUserConfig } from "../../services/globaAdminConfig/GlobaAdminConfigService";

export function executeGetUserConfig(
  userId: string,
  vendorId?: string,
): Promise<GlobalAdminConfigDomain> {
  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  if (isOrganizationEnabled) {
    return Promise.resolve({
      userConfig: {
        vendorId,
      },
    } as GlobalAdminConfigDomain);
  }

  return getUserConfig(userId, vendorId);
}
