import { createEffect } from "effector";
import * as UpdateKPIUseCase from "../../usecase/agentKPI/UpdateKPIUseCase";

interface User {
  login: string;
  zone: string;
  vendorId: string;
  vendorName: string;
}

export const getUpdateKPIEffect = createEffect({
  async handler(user: User) {
    return UpdateKPIUseCase.execute(
      user.login,
      user.zone,
      user.vendorId,
      user.vendorName,
    );
  },
});
