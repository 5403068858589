import styled from "styled-components";

const CardScript = styled.div`
  .editor-text-bold {
    font-weight: bold;
    font-size: 16px;
  }

  .editor-text-italic {
    font-style: italic;
  }

  .editor-text-link {
    color: rgb(33, 111, 219);
    text-decoration: none;
  }

  .editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
  }

  .editor-paragraph:last-child {
    margin-bottom: 0;
  }

  .editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }

  .editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }

  .editor-listitem {
    margin: 2px 0 2px 10px;
  }

  .editor-nested-listitem {
    list-style-type: none;
  }
  .editor-text-link {
    color: #0363c4;
    text-decoration: underline;
  }
  .editor-text-link:hover {
    color: black;
  }
`;

export default CardScript;
