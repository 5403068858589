/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, useCallback, useEffect, useMemo } from "react";

import { Effect, Event, Unit, attach, forward } from "effector";
import { useStore } from "effector-react";

import { TAB_ORDERS_ROUTE } from "../config/constants";
import {
  GROW_TECH_CART_AUTO_UPDATE,
  GROW_TECH_CART_BFF,
  isFeatureEnabledV2,
} from "../config/featureToggles";
import AgentCallStore from "../stores/agentCall/AgentCallStore";
import * as CartEvents from "../stores/cart/CartEvents";
import * as CartItemEvents from "../stores/cart/CartItemEvents";
import cartItemStore from "../stores/cart/CartItemStore";
import CartStore from "../stores/cart/CartStore";
import * as CartComboEvents from "../stores/cartCombo/CartComboEvents";
import CartComboStore from "../stores/cartCombo/CartComboStore";
import CallTabStore from "../stores/navigation/callTab/CallTabStore";
import GlobalStore from "../stores/global/GlobalStore";

interface CartContextType {
  updateAndSimulate: () => void;
}

export const CartAutoUpdateContext = createContext({} as CartContextType);

const orderPageEvents = [
  CartComboEvents.updateComboQuantity,
  CartComboEvents.removeItem,
  CartItemEvents.setItemCart,
  CartItemEvents.changeItemQuantity,
  CartItemEvents.updateOrAddItem,
  CartItemEvents.removeItem,
  CartItemEvents.setEmpty,
];

const outsideOrderPageEvents = [
  CartComboEvents.addItem,
  CartItemEvents.addItem,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setForward(fromEvents: Event<any>[], toEffect: Effect<any, any, any>) {
  forward({
    from: fromEvents as ReadonlyArray<Unit<unknown>>,
    to: [toEffect],
  });
}

/* istanbul ignore next */
export function CartAutoUpdateContextProvider({ children }) {
  const CartStates = useStore(CartStore);
  const AgentCallStates = useStore(AgentCallStore);
  const cartComboState = useStore(CartComboStore);
  const cartItemsState = useStore(cartItemStore);
  const { user } = useStore(GlobalStore);
  const { callTab } = useStore(CallTabStore);

  const { cart } = cartItemsState;
  const { clientId } = AgentCallStates;
  const { cartForm } = CartStates;

  const isCartBffEnabled = isFeatureEnabledV2(
    GROW_TECH_CART_BFF,
    user?.keyToggle,
  );

  const isCartAutoUpdateEnabled = isFeatureEnabledV2(
    GROW_TECH_CART_AUTO_UPDATE,
    user?.keyToggle,
  );

  const handleUpsertItemEffect = attach({
    effect: CartItemEvents.upsertItemEffect,
    source: {
      agentCallState: AgentCallStore,
      cartComboState: CartComboStore,
      cartItemsState: cartItemStore,
    },
    mapParams: (_, stores) => {
      return { ...stores };
    },
  });

  const autoUpdateCartEffect = attach({
    effect: CartItemEvents.updateCartEffect,
    source: {
      agentCallState: AgentCallStore,
      cartState: CartStore,
      cartComboState: CartComboStore,
      cartItemsState: cartItemStore,
    },
    mapParams: (_, stores) => {
      return { ...stores };
    },
  });

  const handleUpdateSingleCartSimulationEffect = attach({
    effect: CartItemEvents.updateSingleCartSimulationEffect,
    source: {
      agentCallState: AgentCallStore,
      cartState: CartStore,
      cartComboState: CartComboStore,
      cartItemsState: cartItemStore,
    },
    mapParams: (_, stores) => {
      return { ...stores };
    },
  });

  // Within the definitive cart auto update, we remove this useEffect and put it outside the component
  useEffect(() => {
    if (isCartAutoUpdateEnabled) {
      if (isCartBffEnabled) {
        setForward(
          [...orderPageEvents],
          handleUpdateSingleCartSimulationEffect,
        );

        setForward([...outsideOrderPageEvents], handleUpsertItemEffect);
      } else {
        setForward(
          [
            ...orderPageEvents,
            ...outsideOrderPageEvents,
            CartEvents.updateCartForm,
          ],
          autoUpdateCartEffect,
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartAutoUpdateEnabled, isCartBffEnabled]);

  useEffect(() => {
    if (callTab !== TAB_ORDERS_ROUTE || !isCartBffEnabled) {
      return;
    }

    const simulateCart = (cartId: string) => {
      CartItemEvents.simulateCartEffect({
        cartId,
        accountId: clientId,
        cartForm,
      });
    };

    if (!cart.cartId && CartItemEvents.getCartIdEffect.pending) {
      const unwatchGetCartId = CartItemEvents.getCartIdEffect.done.watch(
        ({ result }) => {
          if (result?.cartId) {
            simulateCart(result.cartId);
          }
          unwatchGetCartId();
        },
      );
    } else if (cart.cartId) {
      simulateCart(cart.cartId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callTab, isCartBffEnabled]);

  useEffect(() => {
    const unWatchGetNextPocFail = CartEvents.updateCartForm.watch(
      (updateCartFormResponse) => {
        const updatedCartState = {
          ...CartStates,
          cartForm: {
            ...CartStates.cartForm,
            ...updateCartFormResponse,
          },
        };

        if (callTab === TAB_ORDERS_ROUTE && isCartBffEnabled) {
          CartItemEvents.updateSingleCartSimulationEffect({
            agentCallState: AgentCallStates,
            cartState: updatedCartState,
            cartComboState,
            cartItemsState,
          });
        }
      },
    );

    return () => {
      unWatchGetNextPocFail();
    };
  }, [
    AgentCallStates,
    CartStates,
    callTab,
    cartComboState,
    cartItemsState,
    isCartBffEnabled,
  ]);

  const updateAndSimulate = useCallback(() => {
    CartItemEvents.updateSingleCartSimulationEffect({
      agentCallState: AgentCallStates,
      cartState: CartStates,
      cartComboState,
      cartItemsState,
    });
  }, [AgentCallStates, CartStates, cartComboState, cartItemsState]);

  const contextReturn = useMemo(() => {
    return {
      updateAndSimulate,
    };
  }, [updateAndSimulate]);

  return (
    <CartAutoUpdateContext.Provider value={contextReturn}>
      {children}
    </CartAutoUpdateContext.Provider>
  );
}
