import { TABS_TAGS_ENUM } from "../../domains/tags/TabsToTagsEnum";
import { MissionsResponse } from "../../domains/missions/MissionsResponse";
import { MissionsConfigObject } from "../../domains/missions/MissionsConfigObject";

const getMissionsByEnum = (missions: string[]): TABS_TAGS_ENUM[] => {
  const missionsFromEnum = missions.filter((mission) =>
    Object.values(TABS_TAGS_ENUM).includes(
      mission as unknown as TABS_TAGS_ENUM,
    ),
  ) as TABS_TAGS_ENUM[];
  // [...new Set(missionsFromEnum)] is removing duplicated items
  return [...new Set(missionsFromEnum)];
};

const getTabs = (missions: TABS_TAGS_ENUM[]): string[] => {
  const tabs = missions
    .map((mission) => {
      const indexOfTab = Object.values(TABS_TAGS_ENUM).indexOf(mission);
      return Object.keys(TABS_TAGS_ENUM)[indexOfTab];
    })
    .filter((tab) => tab);
  return tabs;
};

export const MissionsResponseToMissionsConfigConverter = (
  missionsResponse: MissionsResponse,
): MissionsConfigObject => {
  const { globals, ids } = missionsResponse;
  const missions = getMissionsByEnum(globals || []);
  const missionsTabs = getTabs(missions);
  return {
    missions,
    missionsTabs,
    localMissionIds: ids?.local || [],
    sponsoredMissionsIds: ids?.sponsored || [],
  };
};
