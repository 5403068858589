import React from "react";
import { ButtonBase, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CartItem } from "domains/cart/CartItem";
import { useAnalytics } from "../../analytics/useAnalytics";
import { warningMessageViewed } from "../../config/typewriter";
import AgentCallStore from "../../stores/agentCall/AgentCallStore";
import Modal from "../../components/modal/Modal";
import WarningOutOfStockStyles from "./WarningOutOfStockStyles";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  cartItems: Array<CartItem>;
}

const WarningOutOfStockModal: React.FunctionComponent<Props> = ({
  isOpen,
  onClose,
  cartItems,
}) => {
  const { t } = useTranslation();
  const classes = WarningOutOfStockStyles();
  const agentCallState = AgentCallStore.getState();
  const { dispatchGenericEvent } = useAnalytics();

  const handleOrderItemOutOfStock = () => {
    onClose();

    const listOutOfStockSkus: Array<string> = [];
    const listLowStockSkus: Array<string> = [];

    cartItems.forEach((item) => {
      if (item.inventoryCount === 0) {
        listOutOfStockSkus.push(item.sku);
      }

      if (
        item.itemQuantity > item.inventoryCount &&
        item.inventoryCount !== 0
      ) {
        listLowStockSkus.push(item.sku);
      }
    });

    dispatchGenericEvent(warningMessageViewed, {
      call_id: agentCallState.callId,
      poc_id: agentCallState.clientId,
      warning_message: t("WarningOutOfStockModal.MESSAGE"),
      out_of_stock_skus: listOutOfStockSkus,
      low_stock_skus: listLowStockSkus,
    });
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleOrderItemOutOfStock}
        data-testid="warning-out-of-stock-modal"
      >
        <div className={classes.container}>
          <div
            className={classes.header}
            data-testid="warning-out-of-stock-header"
          >
            {t("WarningOutOfStockModal.TITLE")}
          </div>
          <div
            className={classes.formContainer}
            data-testid="warning-out-of-stock-iframe-container"
          >
            <div
              data-testid="warning-out-of-stock-message"
              className={classes.message}
            >
              {t("WarningOutOfStockModal.MESSAGE")}
            </div>
          </div>
          <Box display="flex" flexDirection="row">
            <ButtonBase
              onClick={() => handleOrderItemOutOfStock()}
              className={classes.closeButton}
              data-testid="warning-out-of-stock-button"
            >
              {t("WarningOutOfStockModal.BUTTON")}
            </ButtonBase>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(WarningOutOfStockModal);
