import { createEffect } from "effector";
import * as B2BTrainerUseCase from "../../usecase/b2bTrainer/B2BTrainerUseCase";

interface CreateData {
  username: string;
  country: string;
}

export const b2bTrainerEmulatorEffect = createEffect({
  async handler(createData: CreateData) {
    return B2BTrainerUseCase.execute(createData.username, createData.country);
  },
});

interface DeleteData {
  publicKey: string;
  country: string;
}

export const b2bTrainerDeleteEmulatorEffect = createEffect({
  async handler(deleteData: DeleteData) {
    return B2BTrainerUseCase.executeDelete(
      deleteData.publicKey,
      deleteData.country,
    );
  },
});

interface FindData {
  agentId: string;
  country: string;
}
export const b2bTrainerGetEmulatorEffect = createEffect({
  async handler(findData: FindData) {
    return B2BTrainerUseCase.executeGet(findData.agentId, findData.country);
  },
});
