import React, { memo, useState } from "react";
import { InteractiveCombo } from "@domains/promotions/InteractiveCombo";
import { PROMOTIONS_TYPES } from "@config/constants";
import { ComboInterface } from "@services/promotions/domains/CombosResponseV2";
import PromotionsStore from "@stores/promotions/PromotionsStore";
import { useStore } from "effector-react";
import { ComboInteractiveTray } from "../ComboInteractiveTray/ComboInteractiveTray";
import { ComboInteractiveCard } from "../card/ComboInteractiveCard";
import comboInteractiveStyles from "./ComboInteractiveStyles";

interface Props {
  allExpanded: boolean;
  interactiveCombo: InteractiveCombo;
}

export const ComboInteractive: React.FC<Props> = ({
  allExpanded,
  interactiveCombo,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const PromotionStates = useStore(PromotionsStore);
  const classes = comboInteractiveStyles();
  const { type, id } = interactiveCombo;

  return type === PROMOTIONS_TYPES.COMBO_INTERACTIVE ? (
    <section
      className={classes.Container}
      key={id}
      data-testId="interactive-combo"
    >
      <ComboInteractiveTray
        combos={
          PromotionStates.combos.filter(
            ({ id: comboId }) => comboId === id,
          ) as unknown as Array<ComboInterface>
        }
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
      <ComboInteractiveCard
        allExpanded={allExpanded}
        interactiveCombo={interactiveCombo}
        setDrawerOpen={setDrawerOpen}
      />
    </section>
  ) : null;
};
memo(ComboInteractive);
