import { useState, useRef, useEffect } from "react";

/* istanbul ignore next */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCallTimer = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const interval = useRef<any>();
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    return () => clearTimeout(interval.current);
  });

  const resetTimer = () => {
    clearTimeout(interval.current);
    setIsConnected(true);
    interval.current = setTimeout(() => {
      setIsConnected(false);
    }, 15000);
  };

  return {
    interval: interval.current,
    resetTimer,
    isConnected,
  };
};

export default useCallTimer;
