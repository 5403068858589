export enum TABS_TAGS_ENUM {
  toplineGap = "#TopLineGAP",
  quickOrder = "#PromoteAlgoSelling",
  marketplace = "#PromoteMarketPlace",
  marketplaceactivation = "#marketplaceactivation",
  rewards = "#promoterewardschallenges",
  service = "#digital_services",
  sponsored = "#sponsored",
  localMissions = "#LocalMissions",
  norewards = "#norewardsredemption",
}
