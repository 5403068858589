import { ComboItem } from "../../../services/checkout/domains/v2/CheckoutRequestV2";
import { CartComboItem } from "../../../domains/cart/CartComboItem";
import UpsertItemRequest, {
  Item,
} from "../../../services/cart/upsertItem/domain/UpsertItemRequest";
import CartItemState from "../../../stores/cart/CartItemState";
import CartComboState from "../../../stores/cartCombo/CartComboState";

export default function StoresToUpsertItemRequestConverter(
  cartComboState: CartComboState,
  cartItemStates: CartItemState,
): UpsertItemRequest {
  const lineItems: Item[] = cartItemStates.cartItems.map((each) => {
    return {
      platformId: each.platformId,
      quantity: each.itemQuantity,
    };
  });
  return {
    items: lineItems,
    combos: getCombosRequest(cartComboState.cartComboItems),
  };
}

function getCombosRequest(cartComboItems) {
  return cartComboItems.map((each: CartComboItem) => {
    return {
      platformId: each.platformId,
      quantity: each.itemQuantity,
    } as ComboItem;
  });
}
