import React, { ReactText } from "react";
import { toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import CloseButtom from "@components/CloseButtom/CloseButtom";
import SuccessIcon from "@assets/newIcons/SuccessIcon";
import ErrorIcon from "@assets/newIcons/ErrorIcon";
import WarningIcon from "@assets/newIcons/WarningIcon";
import InfoIcon from "@assets/newIcons/InfoIcon";

export enum TOAST_TYPES {
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  DEFAULT = "default",
}

export const toastTypes = {
  success: TOAST_TYPES.SUCCESS,
  error: TOAST_TYPES.ERROR,
  info: TOAST_TYPES.INFO,
  warning: TOAST_TYPES.WARNING,
};

export enum TOAST_COLORS {
  SUCCESS = "#E6F2EB",
  ERROR = "#FFDEDE",
  WARNING = "#FFEBD0",
  INFO = "#E9F1FF",
}

interface CustomToastProps {
  message?: string;
  type?: TOAST_TYPES;
  toastId?: string;
  errorCode?: string;
  InforErrorType?: string;
}

const CustomToast = ({
  message,
  type = TOAST_TYPES.DEFAULT,
  toastId = message,
  InforErrorType,
  errorCode,
}: CustomToastProps): ReactText => {
  const getRequestTraceIdComponent = () => {
    return (
      errorCode && (
        <>
          <br />
          {`${InforErrorType} ${errorCode}`}
        </>
      )
    );
  };

  const content = (
    <Typography
      variant="body2"
      style={{
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#141414",
      }}
      data-testid="header-message-text"
    >
      {message}
      {getRequestTraceIdComponent()}
    </Typography>
  );

  const getIcon = (type: TOAST_TYPES): JSX.Element => {
    return (
      <div id="iconContainer" style={{ lineHeight: "0px" }}>
        {type === TOAST_TYPES.SUCCESS && <SuccessIcon />}
        {type === TOAST_TYPES.ERROR && <ErrorIcon />}
        {type === TOAST_TYPES.WARNING && <WarningIcon />}
        {type === TOAST_TYPES.INFO && <InfoIcon />}
      </div>
    );
  };

  if (type !== TOAST_TYPES.DEFAULT) {
    return toast(content, {
      icon: getIcon(type),
      closeButton: CloseButtom,
      style: {
        backgroundColor: TOAST_COLORS[type.toUpperCase()],
      },
      toastId,
    });
  }

  return toast(message);
};

export default CustomToast;
