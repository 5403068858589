import * as SuggestedOrdersService from "../../services/suggestedOrders/SuggestedOrdersService";
import * as SuggestedOrdersEvents from "../../stores/suggestedOrders/SuggestedOrdersEvents";

export function execute(
  accountId: string,
  country: string,
  vendorId?: string,
  vendorName?: string,
): Promise<void> {
  SuggestedOrdersEvents.getSuggestedOrders(null);

  return SuggestedOrdersService.getSuggestedOrders(
    accountId,
    country,
    vendorId,
    vendorName,
  )
    .then((response) => {
      SuggestedOrdersEvents.getSuggestedOrdersDone(response);
    })
    .catch(() => {
      SuggestedOrdersEvents.getSuggestedOrdersFail(null);
    })
    .finally(() => {
      SuggestedOrdersEvents.getSuggestedOrdersFinally(null);
    });
}
