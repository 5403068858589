import React from "react";

const BEESCellphoneIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90"
    height="172"
    viewBox="0 0 90 172"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-675 -375) translate(675 375)">
          <rect width="90" height="172" fill="#505050" rx="12.607" />
          <rect
            width="82.848"
            height="130.576"
            x="3.602"
            y="12.607"
            fill="#FFF"
            rx="12.607"
          />
          <g fill="#505050" fillRule="nonzero">
            <path
              d="M25.632.314c.419 0 .759.34.759.759v2.57c0 .42-.34.76-.76.76h-10.83V1.07c0-.419.34-.759.758-.759h10.073m0 12.272H14.8v3.332c0 .419.34.759.76.759h10.072c.419 0 .759-.34.759-.76v-2.57c0-.42-.34-.76-.76-.76M24.415 9.78V7.21c0-.42-.34-.76-.76-.76h-8.786v4.09h8.787c.42-.001.76-.341.76-.76M39.268.314H29.196c-.42 0-.76.34-.76.759v3.33H39.27c.419 0 .759-.34.759-.758v-2.57c0-.42-.34-.761-.76-.761m0 12.27H28.438v3.332c0 .419.34.759.759.759h10.073c.419 0 .759-.34.759-.76v-2.57c0-.42-.34-.76-.76-.76M38.052 9.78V7.21c0-.42-.34-.76-.76-.76h-8.787v4.09h8.788c.419-.001.759-.341.759-.76M.144 15.916c0 .419.34.759.759.759h6.842c2.474 0 4.538-1.758 5.01-4.09H.145v3.33zm41.93-2.571v2.57c0 .42.34.761.759.761h6.16c2.473 0 4.536-1.757 5.01-4.09h-11.17c-.42-.002-.76.34-.76.759M53.244.314h-6.5c-2.474 0-4.538 1.757-5.01 4.09h11.51c.42 0 .76-.34.76-.76v-2.57c0-.419-.34-.76-.76-.76M7.746 6.45H.144v4.09h12.612c-.475-2.333-2.538-4.09-5.01-4.09m41.248 0h-7.26c.473 2.333 2.536 4.09 5.01 4.09h7.26c-.475-2.333-2.538-4.09-5.01-4.09M6.668.315L.903.314c-.42 0-.76.34-.76.76v3.33h10.564C10.71 2.162 8.903.34 6.668.315"
              transform="translate(18 66)"
            />
          </g>
          <circle cx="45" cy="158" r="8" fill="#FFF" />
          <rect width="36" height="3" x="27" y="5" fill="#FFF" rx="1.351" />
        </g>
      </g>
    </g>
  </svg>
);

export default BEESCellphoneIcon;
