import React, { useState, useCallback } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { searchByNameOrSku } from "./searchFunction/searchFunction";
import SearchComponent from "./SearchCatalogStyles";
import Catalog from "../../../../domains/Catalog";
import { CatalogTable } from "../../catalogDb/CatalogDb";
import CatalogVirtualizedTable from "./virtualized/CatalogVirtualizedTable";
import { productSearchInteraction } from "../../../../config/typewriter";
import SearchResultIcon from "../../../../assets/newIcons/SearchResultIcon";
import SearchBar, {
  SEARCH_METHOD,
} from "../../../../components/searchBar/SearchBar";
import GlobalStore from "../../../../stores/global/GlobalStore";
import {
  isFeatureEnabledV2,
  GROW_BEES_PRODUCT_SEARCH,
} from "../../../../config/featureToggles";
import SearchProducts from "../searchProducts/SearchProducts";

interface Props {
  catalog: CatalogTable;
}

const SearchCatalog: React.FunctionComponent<Props> = ({ catalog }) => {
  const classes = SearchComponent();
  const { t } = useTranslation();

  const {
    user: { zone },
  } = useStore(GlobalStore);

  const isBeesProductSearchEnabled = isFeatureEnabledV2(
    GROW_BEES_PRODUCT_SEARCH,
    zone,
  );

  const [searchResult, setSearchResult] = useState<Catalog>({
    items: [],
    searchValueAnalytics: "",
  });
  const [value, setValue] = useState("");
  const onSearch = useCallback(
    async (searchMethod: string) => {
      productSearchInteraction({
        button_label:
          searchMethod === SEARCH_METHOD.ENTER_KEY
            ? "search_field"
            : SEARCH_METHOD.SEARCH_BUTTON,
        button_name: "Search",
        search_query: value,
      });

      const result = await searchByNameOrSku(catalog, value);
      setSearchResult(result);
    },
    [value, catalog],
  );

  const clearSearch = useCallback(async () => {
    setSearchResult({
      items: [],
      searchValueAnalytics: "",
    });
  }, []);

  if (isBeesProductSearchEnabled) {
    return <SearchProducts catalog={catalog} />;
  }
  return (
    <Box className={classes.searchContainer}>
      <div className={classes.headerContainer}>
        <div className={classes.titleContainer}>
          <SearchResultIcon id="searchResultCatalogTitleIcon" />
          <Typography className={classes.title} variant="h1">
            {t("OrderTaking.SEARCH_RESULTS_LABEL")}
          </Typography>
        </div>
        <h5 className={classes.label}>{t("OrderTaking.PRICE_TOTAL_LABEL")}</h5>
      </div>

      <SearchBar
        value={value}
        setValue={setValue}
        testId="catalog-search"
        onSearch={onSearch}
        clearSearch={clearSearch}
        productSearch
      />

      {searchResult?.items?.length !== 0 ? (
        <CatalogVirtualizedTable
          catalogs={searchResult?.items}
          searchValue={searchResult?.searchValueAnalytics}
        />
      ) : (
        <div className={classes.noItems} data-testid="no-items-to-display">
          {t("OrderTaking.MSG_NF_SEARCH")}
        </div>
      )}
    </Box>
  );
};

export default SearchCatalog;
