import React from "react";
import { Paragraph } from "@hexa-ui/components";
import classNames from "classnames";
import FormattedStock from "../../../../../components/formattedStock/FormattedStock";
import QuantityControl from "../../../../../components/quantityControl/QuantityControl";
import { FormatedPromotion } from "../../../../../domains/promotions/FormatedPromotion";
import QuantityInputAndStockStyles from "./QuantityInputAndStock.styles";

interface QuantityInputAndStockProps {
  promotion: FormatedPromotion;
  itemQuantity: number;
  showCartLink: boolean;
  handleChangeQuantity: (alteredQuantity: number) => void;
  isSteppedDiscount?: boolean;
}

const QuantityInputAndStock = ({
  promotion,
  itemQuantity,
  showCartLink,
  handleChangeQuantity,
  isSteppedDiscount = false,
}: QuantityInputAndStockProps): JSX.Element => {
  const classes = QuantityInputAndStockStyles();

  const renderStockSection = () => {
    const { platformId, stockCount } = promotion;

    return (
      <Paragraph
        weight="normal"
        size="xsmall"
        colortype="secondary"
        className={classNames(
          classes.stockText,
          isSteppedDiscount && classes.steppedDiscountStockText,
        )}
        data-testid={`${platformId}-stock-text`}
      >
        <FormattedStock quantity={stockCount} />
      </Paragraph>
    );
  };

  return (
    <div className={classes.container}>
      {!showCartLink && (
        <div>
          <QuantityControl
            value={itemQuantity}
            onChange={handleChangeQuantity}
            testId={`${promotion.platformId}-quantity-control`}
          />
          {renderStockSection()}
        </div>
      )}
    </div>
  );
};

export default QuantityInputAndStock;
