import React from "react";
import { useTranslation } from "react-i18next";
import { Mission } from "../../../../../../../domains/Mission";
import MissionsTimeline from "../MissionsTimeline";

interface Props {
  missions?: Mission[];
}

const MissionsNotAccomplished: React.FC<Props> = ({ missions }) => {
  const { t } = useTranslation();
  return (
    <MissionsTimeline
      title={t("CUSTOMER_PAGE.MISSIONS_NOT_ACCOMPLISHED")}
      missions={missions}
      isNotAccomplished
    />
  );
};

export default MissionsNotAccomplished;
