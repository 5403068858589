import React from "react";

function SuccessIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM9 1.63636C13.0668 1.63636 16.3636 4.93318 16.3636 9C16.3636 13.0668 13.0668 16.3636 9 16.3636C4.93318 16.3636 1.63636 13.0668 1.63636 9C1.63636 4.93318 4.93318 1.63636 9 1.63636ZM12.6629 6.08709C12.2968 5.72097 11.7032 5.72097 11.3371 6.08709L7.875 9.54938L6.66291 8.33709L6.61391 8.29141C6.24561 7.97166 5.68729 7.98689 5.33709 8.33709C4.97097 8.7032 4.97097 9.2968 5.33709 9.66291L7.21209 11.5379L7.26109 11.5836C7.62939 11.9033 8.18771 11.8881 8.53791 11.5379L12.6629 7.41291L12.7086 7.36391C13.0283 6.99561 13.0131 6.43729 12.6629 6.08709Z"
        fill="#176D38"
      />
    </svg>
  );
}

export default SuccessIcon;
