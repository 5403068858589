import axios, { CancelTokenSource } from "axios";

import {
  GROW_TECH_CART_BFF,
  isFeatureEnabledV2,
} from "../../../config/featureToggles";
import { CartInfo } from "../../../domains/cart/CartInfo";
import * as CartSimulationService from "../../../services/cartSimulation/CartSimulationService";
import AgentCallState from "../../../stores/agentCall/AgentCallState";
import CartItemState from "../../../stores/cart/CartItemState";
import CartState from "../../../stores/cart/CartState";
import CartComboState from "../../../stores/cartCombo/CartComboState";
import GlobalStore from "../../../stores/global/GlobalStore";

let debounceUpdateCart: NodeJS.Timeout;
let cancelLastRequest: CancelTokenSource;

export function execute(
  agentCallState: AgentCallState,
  cartState: CartState,
  cartComboState: CartComboState,
  cartItemsState: CartItemState,
): Promise<CartInfo | null> {
  const { user } = GlobalStore.getState();

  const isCartBffEnable = isFeatureEnabledV2(
    GROW_TECH_CART_BFF,
    user.keyToggle,
  );
  if (isCartBffEnable) {
    return Promise.resolve(null);
  }

  if (!agentCallState.clientId) {
    return Promise.resolve(null);
  }
  if (debounceUpdateCart) {
    clearTimeout(debounceUpdateCart);
  }
  if (cancelLastRequest) {
    cancelLastRequest.cancel();
  }

  return new Promise((resolve) => {
    debounceUpdateCart = setTimeout(() => {
      cancelLastRequest = axios.CancelToken.source();
      resolve(
        CartSimulationService.autoSaveCart(
          agentCallState,
          cartState,
          cartComboState,
          cartItemsState,
          cancelLastRequest.token,
        ),
      );
    }, 1000);
  });
}
