import { getPhones } from "@config/utils/getPhoneAndEmailByPocInformation";
import { SearchedPoc } from "../domains/PocInformation";
import { AccountsResponse } from "../services/pocInformation/domains/AccountsResponse";
import { OwnerResponse } from "../services/pocInformation/domains/OwnerResponse";

const formatOwnerName = (owner?: OwnerResponse) => {
  if (!owner) {
    return undefined;
  }

  const { firstName, lastName } = owner;

  if (lastName === null || lastName === undefined) {
    return firstName;
  }
  return `${firstName} ${lastName}`;
};

export function AccountsResponseToSearchedPocConverter(
  data: Array<AccountsResponse>,
): SearchedPoc {
  const account = data.length > 0 ? data[0] : null;

  return {
    id: account?.accountId,
    accountId: account?.accountId,
    customerAccountId: account?.customerAccountId,
    legacyAccountId: account?.legacyAccountId,
    vendorAccountId: account?.vendorAccountId,
    name: account?.name,
    owner: formatOwnerName(account?.owner),
    email: account?.owner?.email,
    hasPONumberRequirement: account?.hasPONumberRequirement,
    isContractless: account?.isContractless,
  };
}

export function AccountsResponseToSearchedPocConverterV2(
  data: AccountsResponse,
): SearchedPoc {
  const account = data;
  const { metadata } = account || {};

  const getEmailAccount = account?.contacts?.find(
    (element) => element.type === "EMAIL",
  )?.value;

  const emailContact = getEmailAccount ? [getEmailAccount] : [];

  return {
    id: account?.accountId,
    accountId: account?.accountId,
    customerAccountId: account?.customerAccountId,
    legacyAccountId: account?.legacyAccountId,
    vendorAccountId: account?.vendorAccountId,
    name: account?.name,
    owner: formatOwnerName(account?.owner),
    phones: getPhones(account),
    email: account?.owner?.email,
    hasPONumberRequirement: account?.hasPONumberRequirement,
    isContractless: account?.isContractless,
    vendorName: account?.vendorName,
    address: account?.deliveryAddress.address,
    city: account?.deliveryAddress.city,
    state: account?.deliveryAddress.state,
    zipcode: account?.deliveryAddress.zipcode,
    emailAccount: emailContact,
    representatives: account?.representatives?.[0],
    deliveryCenterId: account?.deliveryCenterId,
    salesOrg: metadata?.salesOrg,
    salesAreaName: metadata?.salesAreaName,
    salesRegionCode: metadata?.salesRegionCode,
    salesRegionName: metadata?.salesRegionName,
    salesSubregionCode: metadata?.salesSubregionCode,
    salesSubregionName: metadata?.salesSubregionName,
    supervision: metadata?.supervision,
    salesAreaRepName: metadata?.salesAreaRepName,
    salesZone: metadata?.salesZone,
    salesZoneName: metadata?.salesZoneName,
    distributionCenterName: metadata?.distributionCenterName,
    metadata: data?.metadata,
    taxId: account?.taxId,
    liquorLicense: account?.liquorLicense?.[0]?.number,
  };
}
