import { createStore } from "effector";
import * as CartEvents from "./CartEvents";
import CartState from "./CartState";
import { paymentMethodsEnum } from "../../routes/call/components/paymentMethod/PaymentMethodsEnum";

const initialState = <CartState>{
  cartForm: {
    paymentMethod: paymentMethodsEnum.CASH,
    paymentTerm: 0,
    paymentMethodCode: "",
    poNumber: "",
    details: "",
    deliveryDate: "",
    deliveryWindowId: "",
  },
  needUpdate: false,
  isErrorMessageOrderModalOpen: false,
  isOrderItemOutOfStockOpenModal: false,
};

const CartStore = createStore(initialState)
  .on(
    CartEvents.updateCartForm,
    (state: CartState, cartForm: Record<string | number, string | number>) => {
      return {
        ...state,
        cartForm: { ...state.cartForm, ...cartForm },
      };
    },
  )
  .on(CartEvents.setNeedUpdate, (state: CartState, needUpdate) => {
    return {
      ...state,
      needUpdate,
    };
  })
  .on(
    CartEvents.setIsErrorMessageOrderModalOpen,
    (state: CartState, isErrorMessageOrderModalOpen) => {
      return {
        ...state,
        isErrorMessageOrderModalOpen,
      };
    },
  )
  .on(
    CartEvents.setIsOrderItemOutOfStockOpenModal,
    (state: CartState, isOrderItemOutOfStockOpenModal) => {
      return {
        ...state,
        isOrderItemOutOfStockOpenModal,
      };
    },
  )
  .reset(CartEvents.resetCart);

export default CartStore;
