import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface Props {
  contentContainerWidth: number;
}

export default makeStyles<Theme, Props>(() =>
  createStyles({
    mainContainer: {
      width: "100%",
      maxWidth: "300px",
      position: "relative",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginTop: "8px",
      "& > button": {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        backgroundColor: "#ffffff",
        height: "100%",
        width: "20px",
        color: "inherit",
        border: "none",
        padding: 0,
        font: "inherit",
        cursor: "pointer",
        outline: "inherit",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    scrollableContainer: {
      overflowX: "scroll",
      scrollBehavior: "smooth",
      scrollSnapType: "x mandatory",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    contentContainer: (props) => ({
      width: `${props.contentContainerWidth}px`,
      display: "flex",
      gap: "10px",
    }),
    leftButton: {
      left: 0,
    },
    rightButton: {
      right: 0,
    },
    skeletonContainer: {
      maxWidth: "300px",
      display: "flex",
      gap: "10px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  }),
);
