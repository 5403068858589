import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    updateButton: {
      fontSize: "11px",
      color: "#7a7a7a",
      fontFamily: "Barlow !important",
      textTransform: "none",
    },
    updateTitle: {
      textAlign: "center",
      fontSize: "14px",
      height: "101px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonContainer: {
      height: "32px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
);
