import { createEvent } from "effector";

export const handleIsNewOfferingsUsed = createEvent<boolean>(
  "handleIsNewOfferingsUsed",
);

export const handlePastePhoneEventWasCalled = createEvent<boolean>(
  "handlePastePhoneEventWasCalled",
);
export const resetGenericInfos = createEvent("resetGenericInfos");
