import axiosRetry from "axios-retry";
import { useLogService } from "admin-portal-shared-services";
import axios from "../../config/axios/axiosInstance";
import { MissionsResponseToMissionsConfigConverter } from "../../converters/missions/MissionsResponseToMissionsConfigConverter";
import {
  GROW_LOCAL_AGENT_SPECIALIZED,
  isFeatureEnabledV2,
  TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
} from "../../config/featureToggles";
import { getCountryByToken } from "../../config/utils/functions";
import { getApiHost, getEndPoint } from "../host/HostB2BService";
import { MissionsConfigObject } from "../../domains/missions/MissionsConfigObject";
import { GLobalAndLocalMissionsRequest } from "./domains/GlobalAndLocalMissionsRequest";
import GlobalAdminConfigStore from "../../stores/globaAdminConfig/GlobalAdminConfigStore";

const ENDPOINT = "/grow-missions-service/grow-missions/missions";
const ENDPOINT_V2 = "/grow-missions-service/grow-missions/v2/missions";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export const getGlobalAndLocalMissionsService = (
  tagsString: string,
  localMissionsIdsString: string,
  vendorId?: string,
  vendorName?: string,
): Promise<MissionsConfigObject> => {
  const log = useLogService();
  const GlobalAdminConfigState = GlobalAdminConfigStore.getState();
  const isMulticontractGrowMissionsV2Enable = isFeatureEnabledV2(
    TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
    getCountryByToken(),
  );

  const isGrowLocalCallSummaryEnable = isFeatureEnabledV2(
    GROW_LOCAL_AGENT_SPECIALIZED,
    getCountryByToken(),
  );

  const endpoint = isMulticontractGrowMissionsV2Enable ? ENDPOINT_V2 : ENDPOINT;
  const url = `${getApiHost()}${getEndPoint()}${endpoint}`;
  const missionsConfigParams = {
    globals: tagsString,
    ids: localMissionsIdsString,
  };
  const params = isMulticontractGrowMissionsV2Enable
    ? {
        vendorId: vendorId || "",
        ...missionsConfigParams,
      }
    : missionsConfigParams;

  const headers =
    vendorName === "BRF" && !isMulticontractGrowMissionsV2Enable
      ? {
          vendorId,
          vendorName,
        }
      : undefined;

  if (isGrowLocalCallSummaryEnable) {
    const params = isMulticontractGrowMissionsV2Enable
      ? { vendorId: vendorId || "" }
      : {};

    const requestBody: GLobalAndLocalMissionsRequest = {
      tags: missionsConfigParams.globals.split(","),
      ids: missionsConfigParams.ids.split(","),
      buConfigPriority: GlobalAdminConfigState.buConfig.missionPriority,
      userConfigPriority: GlobalAdminConfigState.userConfig.missionPriority,
    };

    return axios
      .post(url, requestBody, {
        params,
        headers,
      })
      .then((response) => {
        log.info(`Convert Missions received from ${url} to our model`);
        return MissionsResponseToMissionsConfigConverter(response.data);
      })
      .catch((error) => {
        log.error(error);
        throw error;
      });
  }

  return axios
    .get(url, {
      params,
      headers,
    })
    .then((response) => {
      log.info(`Convert Missions received from ${url} to our model`);
      return MissionsResponseToMissionsConfigConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
};
