import React, { useCallback } from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { FormikErrors, FormikTouched } from "formik";
import { Mission } from "../../../../../domains/Mission";
import OrdersStore from "../../../../../stores/order/OrdersStore";
import useStyles from "./styles";

const MissionCompletion: React.FC<MissionCompletionProps> = ({
  handleChange,
  completed,
  errors,
  touched,
  tag,
  testId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { orderInfo, ordersList } = useStore(OrdersStore);
  const isTopLineGap = () => {
    if (tag === t("tags.#TopLineGAP") && hasPlacedOrder()) {
      return true;
    }
    return false;
  };

  const hasPlacedOrder = () => {
    return !!orderInfo?.orderId || ordersList?.length > 0;
  };

  const getValue = () => {
    if (tag === t("tags.#TopLineGAP") && hasPlacedOrder()) {
      return VALUES.YES;
    }

    if (completed === undefined) {
      return completed;
    }

    return completed ? VALUES.YES : VALUES.NO;
  };

  const onChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      handleChange(tag, event.currentTarget.value === VALUES.YES);
    },
    [handleChange, tag],
  );

  const hasError = (effectiveness?: boolean) =>
    effectiveness ? classes.radioError : "";

  return (
    <FormControl
      key={`control-completion-${tag}`}
      component="fieldset"
      data-testid={testId}
    >
      <RadioGroup
        row
        value={getValue()}
        onChange={onChange}
        key={`radio-group-${tag}`}
      >
        <FormControlLabel
          key={`radio-yes-${tag}`}
          value={VALUES.YES}
          control={
            <Radio
              classes={{
                root: hasError(
                  (errors?.effectiveness && touched?.effectiveness) || false,
                ),
              }}
            />
          }
          label={t("MissionCompletion.YES")}
          disabled={isTopLineGap()}
        />
        <FormControlLabel
          key={`radio-no-${tag}`}
          value={VALUES.NO}
          control={
            <Radio
              classes={{
                root: hasError(
                  (errors?.effectiveness && touched?.effectiveness) || false,
                ),
              }}
            />
          }
          label={t("MissionCompletion.NO")}
          disabled={isTopLineGap()}
        />
      </RadioGroup>
      {errors?.effectiveness && touched?.effectiveness && (
        <FormHelperText className={classes.formHelperText}>
          {errors?.effectiveness}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const VALUES = {
  YES: "yes",
  NO: "no",
};
interface MissionCompletionProps {
  completed: boolean | undefined;
  tag: string;
  errors?: FormikErrors<Mission>;
  touched?: FormikTouched<Mission>;
  handleChange: (tag: string, completed: boolean) => void;
  testId?: string;
}

MissionCompletion.defaultProps = {
  testId: "mission-completion-component",
};

export default React.memo(MissionCompletion);
