import { B2BTrainerInfo } from "../../services/b2bTrainer/domains/B2BTrainerInfo";
import { B2BTrainerResponse } from "../../services/b2bTrainer/domains/B2BTrainerResponse";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function B2BTrainerResponseToB2BTrainerInfoConverter(
  data: B2BTrainerResponse,
): B2BTrainerInfo {
  return {
    country: data.country,
    emulatorURL: data.appLink,
    publicKey: data.publicKey,
    createDate: data.createDate,
  };
}
