import * as React from "react";

function Campaigns() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient x1="50%" y1="153.507%" x2="50%" y2="0%" id="prefix__a">
          <stop stopColor="#DCD4F4" offset="0%" />
          <stop stopColor="#F0ECFC" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <ellipse
          fill="url(#prefix__a)"
          cx={26.316}
          cy={25}
          rx={26.316}
          ry={25}
        />
        <g fillRule="nonzero">
          <path
            d="M40.72 10.274a3.29 3.29 0 00-2.283-.899h-12.3a3.3 3.3 0 00-2.285.899L7.525 25.784c-.61.58-.946 1.35-.946 2.17 0 .82.336 1.59.946 2.17l12.3 11.685c.61.58 1.422.9 2.285.9.862 0 1.674-.32 2.284-.9L40.72 26.3c.61-.58.947-1.35.947-2.17V12.444c0-.82-.337-1.591-.946-2.17zm-5.587 6.284c-.567 0-1.028-.437-1.028-.976 0-.54.461-.977 1.028-.977.568 0 1.028.438 1.028.977 0 .539-.46.976-1.028.976z"
            fill="#FF6F71"
          />
          <path
            d="M19.826 41.809c.61.58 1.42.9 2.284.9.862 0 1.674-.32 2.284-.9L40.72 26.3c.61-.58.947-1.35.947-2.17V12.444c0-.82-.337-1.591-.946-2.17l-4.86 4.617a.95.95 0 01.3.69c0 .54-.46.977-1.028.977-.283 0-.54-.11-.726-.286L13.676 35.966l6.15 5.843z"
            fill="#F1595B"
          />
          <path
            d="M24.123 23.193c1.78 0 3.23-1.377 3.23-3.069s-1.45-3.068-3.23-3.068c-1.781 0-3.23 1.376-3.23 3.068 0 1.692 1.449 3.069 3.23 3.069zm0-4.184c.647 0 1.174.5 1.174 1.115s-.527 1.116-1.174 1.116c-.648 0-1.174-.5-1.174-1.116 0-.615.526-1.115 1.174-1.115z"
            fill="#FAF0FA"
          />
          <path
            d="M24.123 28.89c-1.781 0-3.23 1.377-3.23 3.069s1.449 3.068 3.23 3.068c1.78 0 3.23-1.376 3.23-3.068 0-1.692-1.45-3.069-3.23-3.069zm0 4.184c-.648 0-1.174-.5-1.174-1.115s.526-1.116 1.174-1.116c.647 0 1.174.5 1.174 1.116 0 .615-.527 1.115-1.174 1.115z"
            fill="#E1E6F5"
          />
          <path
            d="M30.73 25.065H17.516c-.568 0-1.028.437-1.028.977 0 .539.46.976 1.028.976h13.212c.568 0 1.028-.437 1.028-.976 0-.54-.46-.977-1.028-.977z"
            fill="#FAF0FA"
          />
          <path
            d="M30.73 27.018c.567 0 1.027-.437 1.027-.976 0-.54-.46-.977-1.028-.977h-5.578l-2.056 1.953h7.634z"
            fill="#E1E6F5"
          />
        </g>
      </g>
    </svg>
  );
}

export default Campaigns;
