import { useStore } from "effector-react";
import React from "react";
import NumericFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import GlobalStore from "../../stores/global/GlobalStore";
import FormattedStockStyles from "./FormattedStockStyles";

interface Props {
  quantity?: number;
}

const FormattedStock = ({ quantity }: Props): JSX.Element => {
  const { user } = useStore(GlobalStore);
  const { t } = useTranslation();
  const classes = FormattedStockStyles();

  const decimalSeparator = t(`Formater.${user.zone}.DECIMAL_SEPARATOR`);
  const thousandSeparator = t(`Formater.${user.zone}.THOUSAND_SEPARATOR`);

  if (quantity === undefined || quantity === null || quantity < 0) {
    return <></>;
  }

  if (quantity === 0) {
    return (
      <div className={classes.container} data-testid="out-of-stock">
        <Typography color="error">{t("Promotions.OUT_OF_STOCK")}</Typography>
      </div>
    );
  }

  if (quantity > 999999 && quantity <= 999999999) {
    return (
      <div className={classes.container}>
        <Typography>
          <NumericFormat
            displayType="text"
            value={Math.floor(quantity / 1000000)}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={0}
            suffix=" mi"
            data-testid="formatted-stock"
          />
          <span> {t("Promotions.IN_STOCK")}</span>
        </Typography>
      </div>
    );
  }

  if (quantity >= 999999999) {
    return (
      <div className={classes.container}>
        <Typography>
          <NumericFormat
            displayType="text"
            value={999999}
            thousandSeparator={thousandSeparator}
            decimalSeparator={decimalSeparator}
            decimalScale={0}
            suffix=" mi+"
            data-testid="formatted-stock"
          />
          <span> {t("Promotions.IN_STOCK")}</span>
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Typography>
        <NumericFormat
          displayType="text"
          value={quantity}
          thousandSeparator={thousandSeparator}
          decimalSeparator={decimalSeparator}
          decimalScale={0}
          data-testid="formatted-stock"
        />
        <span> {t("Promotions.IN_STOCK")}</span>
      </Typography>
    </div>
  );
};

export default FormattedStock;
