import React from "react";
import classNames from "classnames";
import { Typography, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import PocInformationStore from "../../../../stores/callList/pocInformation/PocInformationStore";
import { isEmpty } from "../../../../config/utils/functions";
import Data from "../../../../components/data/Data";
import PocCreditStyles from "./PocCredit.styles";

interface PocCreditProps {
  isNewCreditPaymentInfoEnabled: boolean;
}

function PocCredit({
  isNewCreditPaymentInfoEnabled,
}: PocCreditProps): React.ReactElement {
  const { t } = useTranslation();
  const classes = PocCreditStyles();
  const {
    isLoaded,
    pocInformation: {
      totalCredit,
      availableCredit,
      creditExpiringDate,
      creditInfo,
      accumulativeDiscount,
    },
  } = useStore(PocInformationStore);

  if (!isLoaded) {
    return <></>;
  }

  const totalCreditNotEmpty = !isEmpty(totalCredit);
  const balanceNotEmpty = !isEmpty(creditInfo?.balance);
  const availableCreditNotEmpty = !isEmpty(availableCredit);
  const accumulativeDiscountNotEmpty = !isEmpty(accumulativeDiscount);
  const creditExpiringDateNotEmpty = !isEmpty(creditExpiringDate);
  const overdueNotEmpty = !isEmpty(creditInfo?.overdue);

  const showDivider =
    (availableCreditNotEmpty ||
      totalCreditNotEmpty ||
      balanceNotEmpty ||
      accumulativeDiscountNotEmpty) &&
    (overdueNotEmpty || creditExpiringDateNotEmpty);

  const showCredit =
    totalCreditNotEmpty ||
    balanceNotEmpty ||
    availableCreditNotEmpty ||
    accumulativeDiscountNotEmpty ||
    creditExpiringDateNotEmpty ||
    overdueNotEmpty;

  if (showCredit) {
    return (
      <section className={classes.container} data-testid="credit-info-section">
        <div
          className={classes.titleContainer}
          data-testid="credit-info-title-container"
        >
          <Typography
            className={classNames(classes.text, classes.boldText)}
            data-testid="credit-info-title"
          >
            {t("CREDIT_INFORMATION.CREDIT_TITLE")}
          </Typography>
        </div>

        <div
          className={classes.dataContainer}
          data-testid="credit-info-data-container"
        >
          {totalCreditNotEmpty && (
            <div className={classes.itemContainer}>
              <Typography
                className={classNames(classes.text, {
                  [classes.boldText]: !isNewCreditPaymentInfoEnabled,
                })}
                data-testid="total-info-label"
              >
                {t("CREDIT_INFORMATION.TOTAL_CREDIT_LABEL")}
              </Typography>

              <Typography
                className={classNames(classes.text, classes.value, {
                  [classes.boldText]: !isNewCreditPaymentInfoEnabled,
                })}
                data-testid="total-info-value"
              >
                <Data value={totalCredit} type="currency" />
              </Typography>
            </div>
          )}

          {balanceNotEmpty && isNewCreditPaymentInfoEnabled && (
            <div className={classes.itemContainer}>
              <Typography
                className={classes.text}
                data-testid="used-credit-label"
              >
                {t("CREDIT_INFORMATION.USED_CREDIT_LABEL")}
              </Typography>

              <Typography
                className={classNames(classes.text, classes.value)}
                data-testid="used-credit-value"
              >
                <Data value={creditInfo?.balance} type="currency" />
              </Typography>
            </div>
          )}

          <div
            className={isNewCreditPaymentInfoEnabled ? classes.spacement : ""}
          >
            {availableCreditNotEmpty && (
              <div
                className={classNames(classes.itemContainer, {
                  [classes.oldItemContainer]: !isNewCreditPaymentInfoEnabled,
                })}
              >
                <Typography
                  className={classNames(classes.text, classes.boldText)}
                  data-testid="available-credit-label"
                >
                  {t("CREDIT_INFORMATION.AVAILABLE_CREDIT_LABEL")}
                </Typography>
                <Typography
                  className={classNames(
                    classes.text,
                    classes.boldText,
                    classes.value,
                  )}
                  data-testid="available-credit-value"
                >
                  <Data value={availableCredit} type="currency" />
                </Typography>
              </div>
            )}

            {accumulativeDiscountNotEmpty && (
              <div
                className={classNames(
                  classes.itemContainerAccumulativeDiscount,
                  {
                    [classes.oldItemContainer]: !isNewCreditPaymentInfoEnabled,
                  },
                )}
              >
                <Typography
                  className={classNames(classes.text, classes.boldText)}
                  data-testid="accumulative-discount-label"
                >
                  {t("CREDIT_INFORMATION.ACCUMULATIVE_DISCOUNT_LABEL")}
                </Typography>
                <Typography
                  className={classNames(
                    classes.text,
                    classes.boldText,
                    classes.value,
                  )}
                  data-testid="accumulative-discount-value"
                >
                  <Data value={accumulativeDiscount} type="currency" />
                </Typography>
              </div>
            )}
          </div>

          {isNewCreditPaymentInfoEnabled && showDivider && (
            <Divider className={classes.divider} />
          )}

          {creditExpiringDateNotEmpty && (
            <div
              className={classNames(classes.itemContainer, {
                [classes.oldItemContainer]: !isNewCreditPaymentInfoEnabled,
              })}
            >
              <Typography
                className={classNames(classes.text, {
                  [classes.boldText]: !isNewCreditPaymentInfoEnabled,
                })}
                data-testid="expiration-date-label"
              >
                {t("CREDIT_INFORMATION.EXPIRE_DATE_LABEL")}
              </Typography>

              <Typography
                className={classNames(classes.text, classes.value, {
                  [classes.boldText]: !isNewCreditPaymentInfoEnabled,
                })}
                data-testid="expiration-date-value"
              >
                <Data value={creditExpiringDate} type="date" />
              </Typography>
            </div>
          )}

          {overdueNotEmpty && (
            <div
              className={classNames(classes.itemContainer, {
                [classes.oldItemContainer]: !isNewCreditPaymentInfoEnabled,
              })}
            >
              <Typography
                className={classNames(classes.text, {
                  [classes.boldText]: !isNewCreditPaymentInfoEnabled,
                })}
                data-testid="overdue-amount-label"
              >
                {t("CREDIT_INFORMATION.POC_DEBIT")}
              </Typography>
              <Typography
                className={classNames(classes.text, classes.value, {
                  [classes.boldText]: !isNewCreditPaymentInfoEnabled,
                })}
                data-testid="overdue-amount-value"
              >
                <Data value={creditInfo?.overdue} type="currency" />
              </Typography>
            </div>
          )}
        </div>
      </section>
    );
  }

  return <></>;
}

export default React.memo(PocCredit);
