import { useEffect } from "react";
import { useStore } from "effector-react";
import { getRedeemItems } from "@stores/redeem/RedeemEvents";
import pocInformationStore from "@stores/callList/pocInformation/PocInformationStore";
import {
  getFlexibleMissionsByIdsEffect,
  getSponsoredMissionsEvent,
  resetGetSponsoredMissionsEventIsDone,
} from "../stores/sponsored/SponsoredEvents";
import SponsoredStore from "../stores/sponsored/SponsoredStore";
import VendorStore from "../stores/vendor/VendorStore";
import {
  TLP_CALL_AUTO_DIALER,
  GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
  GROW_LOCAL_SEARCH_BY_POC,
  GROW_GLOBAL_SEARCH_BY_POC,
  isFeatureEnabledV2,
  GROW_FLEXIBLE_MISSIONS,
  GROW_TECH_EFFECTIVENESS_BUG,
} from "../config/featureToggles";
import { getGlobalAndLocalMissionsEvent } from "../stores/tags/TagsEvents";
import TagsStore from "../stores/tags/TagsStore";
import {
  CALL_ID,
  PocCallOrigin,
  LocalGlobalFilterEnum,
} from "../config/constants";
import GlobalStore from "../stores/global/GlobalStore";

export const useMountMissions = (
  tagsList: string[],
  localMissionIdsFromAgentCallStore: string[],
  agentCallStoreIsLoaded: boolean,
  vendorAccountId: string,
  clientId: string,
  pocCallOrigin?: PocCallOrigin,
): void => {
  const { done: vendorConfigIsDone, vendor } = useStore(VendorStore);
  const {
    sponsoredMissionsIds,
    localMissionsIds,
    flexibleMissionsIds,
    getSponsoredMissionsEventIsDone,
  } = useStore(SponsoredStore);
  const { user } = useStore(GlobalStore);
  const { isLoaded: tagStoreIsDone } = useStore(TagsStore);
  const {
    pocInformation: { id: pocId },
  } = useStore(pocInformationStore);

  const isCallId = !!localStorage.getItem(CALL_ID);

  const isLocalAndGlobalMissionsServiceEnable = isFeatureEnabledV2(
    GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
    user.keyToggle,
  );

  const isLocalMissionsServiceBySearchByPocEnable = isFeatureEnabledV2(
    GROW_LOCAL_SEARCH_BY_POC,
    user.keyToggle,
  );

  const isGlobalMissionsServiceBySearchByPocEnable = isFeatureEnabledV2(
    GROW_GLOBAL_SEARCH_BY_POC,
    user.keyToggle,
  );

  const isCallInAutoDialerEnabled = isFeatureEnabledV2(
    TLP_CALL_AUTO_DIALER,
    user.keyToggle,
  );

  const isGrowFlexibleMissionsEnabled = isFeatureEnabledV2(
    GROW_FLEXIBLE_MISSIONS,
    user.keyToggle,
  );

  const isEffectivenessBugFixEnabled = isFeatureEnabledV2(
    GROW_TECH_EFFECTIVENESS_BUG,
    user.keyToggle,
  );

  const { id: vendorId, displayName: vendorName } = vendor || {};

  useEffect(() => {
    if (getSponsoredMissionsEventIsDone && isEffectivenessBugFixEnabled) {
      resetGetSponsoredMissionsEventIsDone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, isEffectivenessBugFixEnabled]);

  useEffect(() => {
    let shouldShowMissions =
      vendorConfigIsDone &&
      agentCallStoreIsLoaded &&
      isLocalAndGlobalMissionsServiceEnable &&
      clientId &&
      pocId &&
      isCallId;

    let missionTypeFilter = LocalGlobalFilterEnum.BOTH;

    if (pocCallOrigin === PocCallOrigin.SEARCH_BY_POC) {
      const bothMissionEnable =
        isLocalMissionsServiceBySearchByPocEnable &&
        isGlobalMissionsServiceBySearchByPocEnable;

      const bothMissionNotEnable =
        !isLocalMissionsServiceBySearchByPocEnable &&
        !isGlobalMissionsServiceBySearchByPocEnable;

      if (bothMissionEnable) {
        missionTypeFilter = LocalGlobalFilterEnum.BOTH;
      } else if (bothMissionNotEnable) {
        shouldShowMissions = false;
      } else {
        missionTypeFilter = isLocalMissionsServiceBySearchByPocEnable
          ? LocalGlobalFilterEnum.LOCAL
          : LocalGlobalFilterEnum.GLOBAL;
      }
    }

    if (shouldShowMissions) {
      getGlobalAndLocalMissionsEvent({
        tagsList,
        localMissionIds: localMissionIdsFromAgentCallStore,
        vendorAccountId,
        clientId,
        pocCallOrigin,
        vendorId,
        vendorName,
        LocalGlobalMissionsType: missionTypeFilter,
      });
    }

    if (pocId) {
      getRedeemItems(pocId);
    }
  }, [
    tagsList,
    localMissionIdsFromAgentCallStore,
    vendorConfigIsDone,
    agentCallStoreIsLoaded,
    isLocalAndGlobalMissionsServiceEnable,
    vendorAccountId,
    clientId,
    isCallId,
    pocId,
    pocCallOrigin,
    isCallInAutoDialerEnabled,
    isLocalMissionsServiceBySearchByPocEnable,
    isGlobalMissionsServiceBySearchByPocEnable,
    vendorId,
    vendorName,
  ]);

  useEffect(() => {
    if (isGrowFlexibleMissionsEnabled && !!flexibleMissionsIds.length) {
      getFlexibleMissionsByIdsEffect(flexibleMissionsIds);
    }
  }, [flexibleMissionsIds, isGrowFlexibleMissionsEnabled]);

  /* istanbul ignore next */
  useEffect(() => {
    if (
      !isLocalMissionsServiceBySearchByPocEnable &&
      pocCallOrigin === PocCallOrigin.SEARCH_BY_POC
    )
      return;

    if (
      (sponsoredMissionsIds.length || localMissionsIds.length) &&
      clientId &&
      isLocalAndGlobalMissionsServiceEnable &&
      tagStoreIsDone &&
      (!getSponsoredMissionsEventIsDone || !isEffectivenessBugFixEnabled)
    ) {
      getSponsoredMissionsEvent({
        clientId,
        missionsIds: sponsoredMissionsIds.concat(localMissionsIds),
        vendorId,
      });
    }
  }, [
    pocId,
    sponsoredMissionsIds,
    localMissionsIds,
    clientId,
    isLocalAndGlobalMissionsServiceEnable,
    tagStoreIsDone,
    pocCallOrigin,
    isLocalMissionsServiceBySearchByPocEnable,
    vendorId,
    getSponsoredMissionsEventIsDone,
    isEffectivenessBugFixEnabled,
  ]);
};
