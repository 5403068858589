import { Products as CampaignB2OProducts } from "../../../domains/CampaignB2OProducts";
import CampaignB2OProductDB from "./CampaignB2OProductDB";

interface INewOfferingsQuery {
  findByTag(tag: string): Promise<CampaignB2OProducts[]>;
  findAll(): Promise<CampaignB2OProducts[]>;
}

class CampaignsB2OProductsQueryDB implements INewOfferingsQuery {
  private db: CampaignB2OProductDB;

  constructor(db: CampaignB2OProductDB) {
    if (db == null) {
      throw new Error("db cannot be null");
    }
    this.db = db;
  }

  async findByTag(tag?: string): Promise<CampaignB2OProducts[]> {
    try {
      const resultQuery = await this.db.CampaignB2OProducts.filter(
        (products) => {
          return tag?.toLowerCase() === products.campaignTag.toLowerCase();
        },
      ).toArray();

      const items: Array<CampaignB2OProducts> = resultQuery.map(
        (item) => <CampaignB2OProducts>{ ...item },
      );

      return items;
    } catch (error) {
      return [];
    }
  }

  async findAll(): Promise<CampaignB2OProducts[]> {
    const resultQuery = await this.db.CampaignB2OProducts.toArray();

    const items: Array<CampaignB2OProducts> = resultQuery.map(
      (item) => <CampaignB2OProducts>{ ...item },
    );

    return items;
  }
}

export default CampaignsB2OProductsQueryDB;
