import { createStyles, makeStyles } from "@material-ui/core";

export const ProductListStyles = makeStyles(({ palette }) =>
  createStyles({
    row: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    cell: {
      backgroundColor: palette.background.default,
      color: palette.text.secondary,
      fontWeight: 600,
      fontSize: "11px",
    },
    cellPrice: {
      backgroundColor: palette.background.default,
      color: palette.info.main,
      fontWeight: 600,
      fontSize: "11px",
    },
    cellTotalPrice: {
      backgroundColor: palette.background.default,
      color: palette.error.main,
      fontWeight: 600,
      fontSize: "11px",
    },
    table: {
      padding: "20px",
    },
    cellTitle: {
      color: palette.grey[200],
      fontWeight: "normal",
      fontSize: 12,
    },
    tableHeader: {
      borderBottom: "none",
      paddingBottom: "0px",
    },
    backgroundColor: {
      backgroundColor: palette.background.default,
    },
    tableCellWithDiscount: {
      backgroundColor: palette.background.default,
      flexDirection: "column",
    },
    steppedDiscountText: {
      fontFamily: "Work Sans",
      color: "#757575",
      fontSize: "0.65rem",
      fontWeight: 400,
      textDecoration: "line-through",
    },
  }),
);
