import React from "react";
import classNames from "classnames";
import { useStore } from "effector-react";
import PhoneIcon from "@assets/PhoneOutlineIcon";
import Button from "@components/button/Button";
import GetNextPocButtonStyles from "./GetNextPocButtonStyles";
import GlobalStore from "../../../../stores/global/GlobalStore";
import {
  isFeatureEnabledV2,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../../../config/featureToggles";

interface Props {
  label: string;
  loading: boolean;
  click: () => void;
  newStyle?: boolean;
}

function GetNextPocButton({
  label,
  loading,
  click,
  newStyle,
}: Props): React.ReactElement {
  const classes = GetNextPocButtonStyles();
  const globalState = useStore(GlobalStore);

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    globalState.user.keyToggle,
  );

  return (
    <>
      <Button
        color="secondary"
        loading={loading}
        onClick={click}
        className={classNames({
          [classes.nextPocButton]: !newStyle,
          [classes.nextPocButtonOptions]: newStyle,
        })}
        loadingProps={{ size: 20 }}
        testId="get-next-poc-button"
      >
        <div
          className={classNames({
            [classes.label]: !newStyle,
            [classes.labelOptions]: newStyle,
          })}
          data-testid="get-next-poc-button-label"
        >
          {label}
        </div>
        {!newStyle && (
          <div
            className={
              isOrganizationConfigEnabled
                ? classes.phoneIconGreen
                : classes.phoneIconYellow
            }
          >
            <PhoneIcon />
          </div>
        )}
      </Button>
    </>
  );
}

export default GetNextPocButton;
