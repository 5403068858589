import React from "react";
import { Box, Divider, Grid, CardContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GenericStyles from "./GenericCardStyles";
import Data from "../../../../components/data/Data";

interface Props {
  mainValue?: number | null;
  secondaryValue: number;
  targetValue?: number | string;
  title: string;
  mainValueLabel: string;
  targetValueLabel: string;
  testId: string;
  isCurrency?: boolean;
  targetColor?: string;
}

const GenericCard: React.FunctionComponent<Props> = ({
  mainValue,
  secondaryValue,
  targetValue,
  mainValueLabel,
  targetValueLabel,
  title,
  testId,
  isCurrency,
  targetColor,
}) => {
  const classes = GenericStyles({ targetColor });
  const { t } = useTranslation();

  const pad = (num: number | string, size: number): string => {
    let s = `${num}`;
    while (s.length < size) s = `0${s}`;
    return s;
  };

  return (
    <CardContent classes={{ root: classes.cardContainer }}>
      <Typography
        classes={{ root: classes.genericTitleOverwrite }}
        className={classes.title}
        data-testid={`${testId}-card-title`}
        variant="h5"
      >
        {title}
      </Typography>
      <Divider classes={{ vertical: classes.divider }} />

      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid item xs={9}>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            alignItems="flex-end"
          >
            <Typography
              className={classes.percentage}
              data-testid={`${testId}-mainValue`}
            >
              {mainValue
                ? `${
                    mainValue % 1 !== 0
                      ? mainValue.toFixed(2)
                      : pad(mainValue, 2)
                  }%`
                : t("GlobalMessage.N_A")}
            </Typography>
            <Divider
              orientation="vertical"
              classes={{ vertical: classes.divider }}
            />
            <Data
              className={classes.mainValue}
              testId={`${testId}-secondaryValue`}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              value={
                secondaryValue
                  ? pad(secondaryValue, 2)
                  : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    t("GlobalMessage.N_A")!
              }
              type={isCurrency && secondaryValue ? "currency" : "text"}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Typography
            align="right"
            className={classes.secondValue}
            data-testid={`${testId}-targetValue`}
          >
            {targetValue ? pad(targetValue, 2) : t("GlobalMessage.N_A")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-around" spacing={0}>
        <Grid item xs={7}>
          <Typography
            classes={{ root: classes.genericTitleOverwrite }}
            className={classes.subtitle}
            data-testid={`${testId}-mainValue-title`}
            variant="subtitle1"
          >
            {mainValueLabel}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography
            align="right"
            classes={{ root: classes.genericTitleOverwrite }}
            className={classes.targetLabel}
            data-testid={`${testId}-targetValue-title`}
            variant="subtitle1"
          >
            {targetValueLabel}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default GenericCard;
