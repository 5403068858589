import { useLogService } from "admin-portal-shared-services";
import axios from "axios";
import axiosRetry from "axios-retry";

import axiosInstance from "@config/axios/axiosInstance";

import {
  DEFAULT_LANGUAGE,
  getLanguageByZone,
} from "@i18n/domains/LanguageOptions";
import { GROW_BFF_SERVICE } from "../../../config/services";
import StoresToSimulateCartRequestConverter from "../../../converters/cart/cartStateSimulation/StoresToSimulateCartRequestConverter";
import CartSimulationResponseToCartInfoConverter from "../../../converters/cartSimulation/CartSimulationResponseToCartInfoConverter";
import { CartInfo } from "../../../domains/cart/CartInfo";
import { CartForm } from "../../../stores/cart/CartState";
import GlobalStore from "../../../stores/global/GlobalStore";
import { getApiHost } from "../../host/HostService";
import UpdateCanceledError from "../domains/UpdateCanceledError";
import CartStateSimulationResponse from "./domains/CartStateSimulationResponse";

const CARTS = "/carts";

/* istanbul ignore next */
axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function CartStateSimulation(
  cartId: CartInfo,
  accountId: string,
  cartForm: CartForm,
): Promise<CartInfo | null> {
  const log = useLogService();

  const zone = GlobalStore.getState()?.user?.zone;
  const language = getLanguageByZone(zone)?.value ?? DEFAULT_LANGUAGE;
  const request = StoresToSimulateCartRequestConverter(cartForm);
  const endpoint = `${getApiHost(
    GROW_BFF_SERVICE,
  )}/accounts/${accountId}${CARTS}/${cartId}/simulation`;

  return axiosInstance
    .post<CartStateSimulationResponse>(endpoint, request, {
      headers: {
        "Accept-Language": language,
      },
    })
    .then((response) => {
      log.info(
        `Convert CartId Response received from ${endpoint} to our model`,
      );
      return CartSimulationResponseToCartInfoConverter(
        response.data,
        accountId,
        cartForm.paymentMethod,
        cartForm.paymentTerm,
      );
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        throw new UpdateCanceledError();
      }
      log.error(error);
      throw error;
    });
}
