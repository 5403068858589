import React from "react";

function RewardsChallengesIcon(): JSX.Element {
  return (
    <svg
      width="18px"
      height="26px"
      viewBox="0 0 18 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M8.58333333,2.84217094e-14 C13.3237774,2.84217094e-14 17.1666667,3.84288923 17.1666667,8.58333333 C17.1666667,11.3786691 15.8304165,13.8619069 13.7618363,15.4291267 L14.9913131,24.7018104 C15.0981107,25.506764 14.2570461,26.0856937 13.5512439,25.7272028 L13.4855042,25.6908263 L8.583,22.749 L3.68116242,25.6908263 C2.98481651,26.1086338 2.11936563,25.5666391 2.16809287,24.7764538 L2.17537237,24.7016686 L3.40682375,15.4306365 C1.33710863,13.863502 1.8189894e-12,11.3795669 1.8189894e-12,8.58333333 C1.8189894e-12,3.84288923 3.84288923,2.84217094e-14 8.58333333,2.84217094e-14 Z M11.8867179,16.5079585 C10.8698974,16.9323117 9.75398361,17.1666667 8.58333333,17.1666667 C7.41268306,17.1666667 6.29676931,16.9323117 5.27994878,16.5079585 L4.4305,22.9085 L8.06883758,20.7258404 C8.36115978,20.5504471 8.72074818,20.5369553 9.02341902,20.685365 L9.09782909,20.7258404 L12.7355,22.9085 Z M8.58333333,2 C4.94745873,2 2,4.94745873 2,8.58333333 C2,12.2192079 4.94745873,15.1666667 8.58333333,15.1666667 C9.86757784,15.1666667 11.0659351,14.7989398 12.0788553,14.163036 C12.1200446,14.1290858 12.1650989,14.0996389 12.2126239,14.0738749 C13.9933701,12.8972478 15.1666667,10.8773096 15.1666667,8.58333333 C15.1666667,4.94745873 12.2192079,2 8.58333333,2 Z"
          id="RewardsChallengesIcon-1"
        ></path>
      </defs>
      <g
        id="📄-Missions"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="2.0-Mission.-Challenges---Challenge-hover"
          transform="translate(-341.000000, -626.000000)"
        >
          <g id="Mission-content" transform="translate(333.000000, 100.000000)">
            <g id="Redeem-items" transform="translate(0.000000, 503.000000)">
              <g id="Title" transform="translate(1.000000, 0.000000)">
                <g id="icon" transform="translate(7.500000, 23.000000)">
                  <g id="badge" transform="translate(0.000000, 0.000000)">
                    <mask id="RewardsChallengesIcon-mask-2" fill="white">
                      <use xlinkHref="#RewardsChallengesIcon-1"></use>
                    </mask>
                    <g id="Mask" fill-rule="nonzero"></g>
                    <g
                      id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
                      mask="url(#RewardsChallengesIcon-mask-2)"
                      fill="#000000"
                      fill-opacity="0.9"
                    >
                      <g
                        transform="translate(-7.500000, -3.000000)"
                        id="Rectangle"
                      >
                        <rect x="0" y="0" width="32" height="32"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default RewardsChallengesIcon;
