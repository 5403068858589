import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      flexGrow: 1,
    },
    header: {
      paddingBottom: "10px",
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      marginTop: "-18px",
      width: "100%",
    },
    title: {
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      whiteSpace: "nowrap",
      color: "#525252",
    },
    itemsNumber: {
      fontSize: "12px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "right",
      whiteSpace: "nowrap",
      color: "#7c7c7c",
      justifyContent: "flex-end",
    },
    itemsContainer: {
      backgroundColor: "#f9f9f9",
    },
    buttonContainer: {
      margin: "16px 0 10px 0",
    },
    line: {
      border: "1px solid #f49a30",
    },
    list: {
      height: "213px",
      width: "100%",
      backgroundColor: "#f9f9f9",
      overflow: "hidden",
      flexGrow: 1,
      padding: "5px 0 5px 0",
      "&:hover": {
        overflow: "auto",
      },
    },
    newList: {
      height: "213px",
      width: "100%",
      overflow: "scroll",
      display: "flex",
      flexDirection: "column",
      gap: ".5rem",
    },
    footer: {
      width: "100%",
      verticalAlign: "center",
    },
    clearCartContainer: {
      width: "100%",
      backgroundColor: "#f9f9f9",
      padding: "20px 0 0 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    clearCartButton: {
      height: "14px",
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      color: "#505050",
      borderRadius: "3px !important",
      marginBottom: "16px",
      borderBottom: "1px solid #505050",
    },
    newClearCartButton: {
      width: "fit-content",
      alignSelf: "center",
      marginTop: ".75rem",
      fontWeight: 500,
      fontSize: ".875rem",
      lineHeight: "1rem",
      color: "#0363C4",

      "&:disabled": {
        color: "#505050",
      },
    },
  }),
);
