import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { hasMarketplace } from "../../config/utils/functions";
import PoductCardStyles from "./ProductCardStyles";
import GenericTag from "../tag/GenericTag";
import Data from "../data/Data";

import MarketplaceStore from "../../stores/marketplace/MarketplaceStore";

// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
const productCardDefaultImage = require("../../assets/images/ProductPlaceholder.png");

interface Props {
  product: {
    productName: string;
    price: number;
    sku: string;
    image?: string;
    tagName?: string;
    isB2O?: boolean;
    isMark?: boolean;
  };
}

const ProductCard: React.FC<Props> = ({ product }) => {
  const classes = PoductCardStyles();
  const [src, setSrc] = useState(product.image);
  const { t } = useTranslation();

  const marketplaceState = MarketplaceStore.getState();

  const { marketplaceList } = marketplaceState;

  const isMarketplace = hasMarketplace(product.sku, marketplaceList);

  /* istanbul ignore next */
  return (
    <Box>
      <Card className={classes.card}>
        <div className={classes.image}>
          <img
            src={src || productCardDefaultImage}
            onError={() => setSrc(productCardDefaultImage)}
            alt=""
          />
        </div>

        <CardContent className={classes.cardContentContainer}>
          <Typography className={classes.title}>
            <Data ellipsis ellipsisSize={55} value={product.productName} />
          </Typography>

          {!product.isB2O && product.tagName && (
            <GenericTag
              label={product.tagName}
              style={{ backgroundColor: "#705ea7", alignItems: "center" }}
              testId="tag-product-card"
            />
          )}

          {isMarketplace && !product.isB2O && product.tagName && (
            <GenericTag
              translationKey="Tags.MARKETPLACE"
              style={{
                backgroundColor: "#705ea7",
                alignItems: "center",
                marginLeft: "5px",
              }}
              testId="tag-product-card"
            />
          )}
          <Typography className={classes.price}>
            {`${t("TargetProducts.UNIT_PRICE")}: `}
            <Data type="currency" value={product.price} />
          </Typography>
          <Typography className={classes.sku}>{product.sku}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProductCard;
