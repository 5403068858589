/* istanbul ignore file */

import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      width: "27rem",
      height: "17rem",
      display: "flex",
      flexDirection: "column",
      padding: "40px",
    },
    header: {
      margin: "0px 0px 20px 0px",
      color: "#505050",
      fontSize: "1rem",
      fontWeight: "bold",
      borderBottom: "1px solid black",
      paddingBottom: "10px",
    },
    formContainer: {
      flex: 1,
      display: "flex",
    },
    closeButton: {
      height: "40px",
      fontSize: "14px",
      fontWeight: 600,
      display: "flex",
      flex: 1,
      backgroundColor: palette.text.primary,
      color: palette.common.white,
      margingLeft: "5px",
    },
    message: {
      fontSize: "16px",
      fontFamily: "Barlow",
      letterSpacing: "1px",
      color: "#505050",
      marginBottom: "20px",
    },
  }),
);
