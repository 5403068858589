import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import DeliveryDateLabelStyle from "./DeliveryDateLabel.styles";

const DeliveryDateLabel = (): JSX.Element => {
  const classes = DeliveryDateLabelStyle();
  const { t } = useTranslation();
  const iconLabelText = "1";

  return (
    <div
      className={classes.labelContainer}
      data-testid="delivery-date-label-container"
    >
      <div className={classes.labelItem} data-testid="selected-date-label">
        <Typography
          className={classNames(classes.labelIcon, classes.selectedIcon)}
          data-testid="selected-date-icon"
        >
          {iconLabelText}
        </Typography>
        <Typography
          className={classes.labelText}
          data-testid="selected-date-label-text"
        >
          {t("OrderTaking.SELECTED_DELIVERY_DATE_LABEL")}
        </Typography>
      </div>

      <div className={classes.labelItem} data-testid="regular-date-label">
        <Typography
          className={classNames(classes.labelIcon, classes.regularIcon)}
          data-testid="regular-date-icon"
        >
          {iconLabelText}
        </Typography>
        <Typography
          className={classes.labelText}
          data-testid="regular-date-label-text"
        >
          {t("OrderTaking.REGULAR_DELIVERY_DATE_LABEL")}
        </Typography>
      </div>

      <div className={classes.labelItem} data-testid="flexible-date-label">
        <Typography
          className={classNames(classes.labelIcon, classes.flexibleIcon)}
          data-testid="flexible-date-icon"
        >
          {iconLabelText}
        </Typography>
        <Typography
          className={classNames(
            classes.labelText,
            classes.labelTextFlexibleIcon,
          )}
          data-testid="flexible-date-label-text"
        >
          {t("OrderTaking.FLEXIBLE_DELIVERY_DATE_LABEL")}
        </Typography>
      </div>
    </div>
  );
};

export default DeliveryDateLabel;
