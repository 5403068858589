import React from "react";

export default function IconX(): JSX.Element {
  return (
    <svg
      version="1.1"
      width="16px"
      height="16px"
      viewBox="0 0 64.0 64.0"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath id="xIcon-i0">
          <path d="M4.83869833,0.629113837 L5.12132034,0.878679656 L24,19.757 L42.8786797,0.878679656 C44.0502525,-0.292893219 45.9497475,-0.292893219 47.1213203,0.878679656 C48.2027722,1.96013154 48.2859608,3.66182471 47.3708862,4.83869833 L47.1213203,5.12132034 L28.243,24 L47.1213203,42.8786797 C48.2928932,44.0502525 48.2928932,45.9497475 47.1213203,47.1213203 C46.0398685,48.2027722 44.3381753,48.2859608 43.1613017,47.3708862 L42.8786797,47.1213203 L24,28.243 L5.12132034,47.1213203 C3.94974747,48.2928932 2.05025253,48.2928932 0.878679656,47.1213203 C-0.202772228,46.0398685 -0.285960835,44.3381753 0.629113837,43.1613017 L0.878679656,42.8786797 L19.757,24 L0.878679656,5.12132034 C-0.292893219,3.94974747 -0.292893219,2.05025253 0.878679656,0.878679656 C1.96013154,-0.202772228 3.66182471,-0.285960835 4.83869833,0.629113837 Z" />
        </clipPath>
        <clipPath id="xIcon-i1">
          <path d="M64,0 L64,64 L0,64 L0,0 L64,0 Z" />
        </clipPath>
      </defs>
      <g transform="translate(8.0 8.0)">
        <g clipPath="url(#xIcon-i0)">
          <g transform="translate(-8.0 -8.0)">
            <g clipPath="url(#xIcon-i1)">
              <polygon
                points="8,8 56,8 56,56 8,56 8,8"
                stroke="none"
                fill="rgba(0, 0, 0, 0.9)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
