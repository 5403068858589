import { FormControlLabel, Radio, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "../../VendorSelectDialog.styles";

interface Props {
  vendorName: string;
  pocName: string;
  vendorId: string;
}

const VendorRadioButton: React.FC<Props> = ({
  vendorName,
  pocName,
  vendorId,
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <FormControlLabel
        value={vendorId}
        control={<Radio />}
        label={<Typography className={classes.text}>{vendorName}</Typography>}
      />
      <Typography className={classes.secondaryText}>{pocName}</Typography>
    </div>
  );
};

export default VendorRadioButton;
