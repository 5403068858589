import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    drwHeaderTxt: {
      font: "Barlow",
      fontWeight: 600,
      fontSize: "24px",
      color: "#141414",
    },
    listItem: {
      backgroundColor: palette.common.white,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: 0,
      borderBottom: "1px solid #DBDADA",
    },
    combosTitle: {
      fontWeight: 600,
      fontSize: "16px",
      fontFamily: "Work Sans",
      marginBottom: "8px",
    },
    geralDescription: {
      color: "#141414",
      fontSize: "14px",
      fontFamily: "Work Sans",
      marginBottom: "10px",
      marginTop: "10px",
    },
    section: {
      fontSize: "20px",
      fontFamily: "Barlow",
      color: "#757575",
      marginBottom: "5px",
      marginTop: "10px",
    },
    button: {
      padding: "10px 20px",
    },
    cancelBtn: {
      border: `solid 1px ${palette.common.black}`,
      color: palette.common.black,
      padding: "10px 20px",
      textTransform: "none",
      marginRight: "15px",
    },
    divider: {
      color: palette.grey[200],
      width: "100%",
    },
    productContainer: {
      marginTop: "10px",
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRight: "none !important",
      borderBottom: "none !important",
      borderTop: "none !important",
      paddingLeft: "20px",
    },
  }),
);
