import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import useCallCounterStyles from "./CallCounterStyles";
import {
  isFeatureEnabled,
  TLP_MISSIONS_LAYOUT,
  TLP_MISSIONS_LAYOUT_ADMIN,
} from "../../config/featureToggles";
import GlobalStore from "../../stores/global/GlobalStore";

interface Props {
  value: number | null;
  style?: React.CSSProperties;
}

const CallCounter: React.FunctionComponent<Props> = ({ value, style }) => {
  const { t } = useTranslation();
  const classes = useCallCounterStyles();
  const { user } = useStore(GlobalStore);

  const isMissionsEnabled = isFeatureEnabled(
    TLP_MISSIONS_LAYOUT,
    TLP_MISSIONS_LAYOUT_ADMIN,
    user.keyToggle,
  );

  return (
    <Box className={classes.container} style={style}>
      <Typography
        data-testid="call-counter-attempts-title"
        className={classes.title}
        variant="h2"
      >
        {`${t(
          isMissionsEnabled
            ? "NEWCallSummary.CALL_ATTEMPTS"
            : "CallTypification.CALL_ATTEMPTS",
        )}:`}
      </Typography>
      <Typography
        data-testid="call-counter-attempts-value"
        className={classes.counter}
        variant="h2"
      >
        {typeof value === "number" && value >= 0
          ? value
          : t("GlobalMessage.NO_DATA_AVAILABLE")}
      </Typography>
    </Box>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.value === nextProps.value;
};

export default React.memo(CallCounter, areEqual);
