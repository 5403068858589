import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import axios from "../../../config/axios/axiosInstance";
import { getApiHost, getEndPoint } from "../../host/HostB2BService";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function deleteCart(cartId: string): Promise<boolean> {
  const log = useLogService();

  const CART_ENDPOINT = `/cart-service/v4/carts/${cartId}`;

  const endpoint = `${getApiHost()}${getEndPoint()}${CART_ENDPOINT}`;

  return axios
    .delete<boolean>(endpoint)
    .then((response) => {
      log.info(`Delete Cart done received from ${endpoint} to our model`);
      return response.status === 204;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
