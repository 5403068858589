import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

interface Props {
  isRedesign: boolean | undefined;
}

export default makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    CollapsibleTitleContainer: {
      display: "flex",
      alignItems: "center",
      paddingBottom: ({ isRedesign }) => (isRedesign ? 0 : 9),
    },
    separator: {
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
    },
    titleContainer: { display: "flex", alignItems: "center" },
    title: {
      marginLeft: 5,
      color: theme.palette.text.primary,
      fontFamily: "Barlow",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: 1.33,
      fontStretch: "normal",
      letterSpacing: "normal",
    },
    icon: {
      fontSize: 32,
      color: ({ isRedesign }) =>
        isRedesign ? "#0363C4" : theme.palette.text.primary,
    },
    childrenContainer: {
      display: "flex",
      flexDirection: "column",
    },
    container: {
      position: "relative",

      "& + &": {
        marginTop: 20,
      },
    },
    containerRedesign: {
      position: "relative",
      boxShadow: "2px 2px 10px 0px rgba(0,0,0,0.1)",
      background: "white",
      borderRadius: 14,
      padding: 8,

      "& + &": {
        marginTop: 20,
      },
    },
  }),
);
