/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPromotionComboTypeValid } from "@config/utils/functions";
import { ConvertedComboInterface } from "@converters/promotions/promotionsV3/domains/CombosResponseToCombosInfoConverterDomain";
import Catalog, {
  CatalogItem,
  PriceDetails,
  PriceDetailsItem,
} from "../../domains/Catalog";
import {
  Container,
  Product,
  PromotionPrices,
  Range,
} from "../../domains/products/Product";
import { SponsoredItemsOrCombosResponse } from "../../domains/sponsored/SponsoredMissionServiceResponse";
import PromotionsStore from "../../stores/promotions/PromotionsStore";
import { PromotionsInfoV3 } from "../promotions/promotionsV3/domains/PromotionsV3ResponseToPromotionsInfoConverterDomain";

const getSuggestedQuantity = (
  sponsoredProducts: SponsoredItemsOrCombosResponse[],
  productItem: CatalogItem,
  useVendorItemId?: boolean,
): number | undefined => {
  return sponsoredProducts?.find((sItem) =>
    useVendorItemId
      ? sItem.id === productItem.vendorItemId
      : sItem.itemPlatformId === productItem.platformId,
  )?.quantity;
};

const getFullPackageDescription = (
  productItem: CatalogItem,
  promoIndex: number,
  promotions: PromotionsInfoV3[],
  isOnSale: boolean,
): string | undefined => {
  if (isOnSale) return promotions[promoIndex]?.fullPackageDescription;

  return productItem.fullPackageDescription;
};

const getFullContainerDescription = (
  productItem: CatalogItem,
  promoIndex: number,
  promotions: PromotionsInfoV3[],
  isOnSale: boolean,
): string | undefined => {
  if (isOnSale) return promotions[promoIndex]?.fullContainerDescription;

  return productItem.fullContainerDescription;
};

const getContainer = (
  productItem: CatalogItem,
  promoIndex: number,
  promotions: PromotionsInfoV3[],
  isOnSale: boolean,
  isCatalogV3?: boolean,
): Container | undefined => {
  if (!isCatalogV3) return undefined;

  if (isOnSale) {
    const container = promotions[promoIndex]?.container;

    return <Container>{
      name: promotions[promoIndex]?.containerName,
      unitOfMeasurement: promotions[promoIndex]?.containerUnit,
      itemSize: promotions[promoIndex]?.containerItemSize,
      originalPrice: container?.originalPrice,
      preferredPrice: container?.preferredPrice,
    };
  }

  if (!productItem.container) return undefined;

  return <Container>{
    name: productItem.container.name,
    returnable: productItem.container.returnable,
    unitOfMeasurement: productItem.container.unitOfMeasurement,
    itemSize: productItem.container.itemSize,
    fullContainerDescription: productItem.container.fullContainerDescription,
    material: productItem.container.material,
  };
};

const getRanges = (
  productItem: CatalogItem,
  promoIndex: number,
  promotions: PromotionsInfoV3[],
  isOnSale: boolean,
  isCatalogV3?: boolean,
): Range[] => {
  if (!isCatalogV3) return [];

  if (isOnSale) {
    if (promotions[promoIndex]?.ranges) {
      const ranges = promotions[promoIndex]?.ranges;
      return ranges.map((range) => {
        return <Range>{
          discountRate: range.discountRate,
          stepStart: range.stepStart,
          stepEnd: range.stepEnd,
          pack: <PriceDetailsItem>{
            originalPrice: range.pack?.originalPrice,
            price: range.pack.discountPrice,
            discountAmount: range.pack.discountAmount,
            lowestDiscountPrice: range.pack?.lowestDiscountPrice,
            highestDiscountPrice: range.pack?.highestDiscountPrice,
            preferredPrice: range.pack.preferredPrice,
            promotionalPrice: range.pack?.promotionalPrice,
          },
          uom: <PriceDetailsItem>{
            originalPrice: range.uom?.originalPrice,
            price: range.uom?.discountPrice,
            discountAmount: range.uom?.discountAmount,
            lowestDiscountPrice: range.uom?.lowestDiscountPrice,
            highestDiscountPrice: range.uom?.highestDiscountPrice,
            preferredPrice: range.uom?.preferredPrice,
          },
          container: <PriceDetailsItem>{
            originalPrice: range.container?.originalPrice,
            price: range.container?.discountPrice,
            discountAmount: range.container?.discountAmount,
            lowestDiscountPrice: range.container?.lowestDiscountPrice,
            highestDiscountPrice: range.container?.highestDiscountPrice,
            preferredPrice: range.container?.preferredPrice,
          },
        };
      });
    }
    return [];
  }

  const ranges: Range[] = [];
  if (productItem.priceData?.priceDetails) {
    const range = <Range>{
      pack: productItem.priceData?.priceDetails.pack,
      uom: productItem.priceData?.priceDetails.uom,
      container: productItem.priceData?.priceDetails.container,
    };
    ranges.push(range);
  }

  return ranges;
};

const getPriceDetails = (
  productItem: CatalogItem,
  promoIndex: number,
  promotions: PromotionsInfoV3[],
  isOnSale: boolean,
  isCatalogV3?: boolean,
): PriceDetails | undefined => {
  if (!isCatalogV3) return undefined;

  if (isOnSale) {
    const priceDetails = <PriceDetails>{
      pack: <PriceDetailsItem>{
        preferredPrice: promotions[promoIndex]?.pack?.preferredPrice,
        discountAmount: promotions[promoIndex]?.pack?.discountAmount,
        discountPrice: promotions[promoIndex]?.pack?.discountPrice,
      },
      container: <PriceDetailsItem>{
        preferredPrice: promotions[promoIndex]?.container?.preferredPrice,
        price: promotions[promoIndex]?.container?.discountPrice,
      },
      uom: <PriceDetailsItem>{
        preferredPrice: promotions[promoIndex]?.uom?.preferredPrice,
        price: promotions[promoIndex]?.uom?.discountPrice,
      },
    };

    return priceDetails;
  }

  return productItem.priceData?.priceDetails;
};

export const SponsoredProductConverter = (
  sponsoredProducts: SponsoredItemsOrCombosResponse[],
  combosData: Array<ConvertedComboInterface>,
  sponsoredCombos: SponsoredItemsOrCombosResponse[],
  productsData: Catalog,
  isCatalogV3?: boolean,
  isOrganizationConfigEnabled?: boolean,
): Product[] => {
  const { promotions } = PromotionsStore.getState();

  const useVendorItemId = isCatalogV3 && isOrganizationConfigEnabled;

  const products =
    productsData?.items
      ?.filter((productItem) =>
        sponsoredProducts?.some((sponsored) =>
          useVendorItemId
            ? sponsored.id === productItem.vendorItemId
            : sponsored.itemPlatformId === productItem.platformId,
        ),
      )
      .map((productItem) => {
        const promoIndex = promotions?.findIndex(
          (each) => each.platformId === productItem.platformId,
        );

        const isOnSale = promoIndex > -1;

        const promotionProps = isOnSale
          ? getPromotion(promotions[promoIndex])
          : {};

        return <Product>{
          id: productItem.id,
          vendorItemId: productItem.vendorItemId,
          sku: productItem.sku,
          name: productItem.name,
          image: productItem.image,
          price: productItem.price,
          originalPrice: productItem.originalPrice,
          stock: productItem.availabilityCount,
          suggestedQuantity: getSuggestedQuantity(
            sponsoredProducts,
            productItem,
            useVendorItemId,
          ),
          isCombo: false,
          inventoryCount: productItem.inventoryCount,
          palletQuantity: productItem.palletQuantity,
          availabilityCount: productItem.availabilityCount,
          fullPackageDescription: getFullPackageDescription(
            productItem,
            promoIndex,
            promotions,
            isOnSale,
          ),
          fullContainerDescription: getFullContainerDescription(
            productItem,
            promoIndex,
            promotions,
            isOnSale,
          ),
          ...promotionProps,
          container: getContainer(
            productItem,
            promoIndex,
            promotions,
            isOnSale,
            isCatalogV3,
          ),
          priceDetails: getPriceDetails(
            productItem,
            promoIndex,
            promotions,
            isOnSale,
            isCatalogV3,
          ),
          ranges: getRanges(
            productItem,
            promoIndex,
            promotions,
            isOnSale,
            isCatalogV3,
          ),
          isOnSale,
          platformId: productItem.platformId,
          variant: productItem.variant,
          package: {
            packageId: productItem.packageId,
            name: productItem.packagename,
          },
        };
      }) || [];

  const combos = combosData.map((combo) => {
    const findComboById = sponsoredCombos?.find((sItem) => {
      return (
        sItem.comboPlatformId === combo.platformId ||
        sItem.id === combo.vendorComboId ||
        sItem.comboId === combo.vendorComboId
      );
    });

    return <Product>{
      id: findComboById?.id,
      comboId: combo.id,
      vendorComboId: combo.vendorComboId,
      platformId: combo.platformId,
      name: combo.title,
      description: combo.description,
      image: combo.image,
      price: combo.price,
      suggestedQuantity: findComboById?.quantity,
      comboPlatformId: combo.platformId,
      isCombo: true,
      endDate: combo.endDate,
      comboDetails: combo.items.map((item) => {
        return {
          id: item.id,
          vendorItemId: item.vendorItemId,
          name: item.title,
          packageName: item.fullPackageDescription,
          packageContainer: item.fullContainerDescription,
          sku: item.sku,
          unit: item.unit,
          image: item.image,
        };
      }),
      limit: combo.limit,
      isPromotion: isPromotionComboTypeValid(combo.type),
      originalPrice: combo.originalPrice,
    };
  });

  return combos.concat(products);
};

interface PromotionProps {
  isPromotion?: boolean;
  price?: number;
  prices?: Array<PromotionPrices>;
  discountType?: string;
  originalPrice?: number;
  type?: string;
  limit?: {
    daily: number;
    totalDailyBalance?: number | null;
    monthly?: number;
    totalMonthlyBalance?: number | null;
    balance?: number | null;
  };
}

const getPromotion = (promotion: PromotionsInfoV3): PromotionProps => {
  switch (promotion.type) {
    case "STEPPED_DISCOUNT":
      return {
        isPromotion: true,
        prices: promotion.prices?.map((price, index) => {
          return {
            ...price,
            discountAmount: promotion?.ranges[index].pack.discountAmount,
          };
        }),
        limit: promotion.limit,
        discountType: promotion.type,
      };
    case "DISCOUNT":
      return {
        isPromotion: true,
        price: promotion.prices[0].discountedPrice,
        discountType: promotion.type,
        limit: promotion.limit,
        originalPrice: promotion.originalPrice,
      };
    default:
      return {};
  }
};
