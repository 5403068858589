import React from "react";
import { useTranslation } from "react-i18next";
import { Promotions as PromotionsType } from "../../../../../domains/promotions/Promotions";
import { PromotionsFreeGood } from "../../../../../domains/promotions/domains/PromotionsFreeGood";
import Data from "../../../../../components/data/Data";
import cardStyles from "./CardStyles";

interface Props {
  data: PromotionsType;
}

const SteppedFreeGoodSection: React.FC<Props> = ({ data }) => {
  const classes = cardStyles();
  const { t } = useTranslation();
  const steppedFreeGoodPrefix = "stepped-free-good";

  const getStep = (item: PromotionsFreeGood) => {
    return ` ${item.stepStart} ${t("Promotions.TO")} ${item.stepEnd}`;
  };

  const getKey = (id: string, index: number) => `${id}-${index}`;

  return (
    <div className={classes.freeGoodContainer}>
      <div className={classes.skuContainer}>
        <div className={classes.skuColor}>{data.sku}</div>
        <Data
          value={data.originalPrice}
          type="currency"
          testId={`${steppedFreeGoodPrefix}${data.sku}-original-price`}
          className={classes.price}
        />
      </div>
      <div className={classes.freeGoodItemsContainer}>
        {data.legacyFreeGoods?.map((item, freeGoodIndex) => (
          <div
            className={classes.steppedContainer}
            key={getKey(item.id, freeGoodIndex)}
          >
            <span
              data-testid={`${steppedFreeGoodPrefix}-quantity-${item.stepStart}-to-${item.stepEnd}`}
            >
              {t("Promotions.QUANTITY")}:{getStep(item)}
            </span>
            <div className={classes.freeGoodTitle}>
              {t("Promotions.WIN_ALL")}:
            </div>
            {item.items?.map((option, promotionIndex) => (
              <div
                className={classes.freeGoodItem}
                key={getKey(option.id, promotionIndex)}
                data-testid={`${steppedFreeGoodPrefix}${data.sku}-${option.id}`}
              >
                {`${option.amount} ${option.title}`}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SteppedFreeGoodSection;
