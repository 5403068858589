import React from "react";
import { Paragraph } from "@hexa-ui/components";
import classNames from "classnames";
import { FormatedPromotion } from "../../../../../domains/promotions/FormatedPromotion";
import Data from "../../../../../components/data/Data";
import { PROMOTIONS_TYPES } from "../../../../../config/constants";
import PromotionPricesStyles from "./PromotionPrices.styles";

interface PromotionPricesProps {
  promotion: FormatedPromotion;
}

const PromotionPrices = ({ promotion }: PromotionPricesProps): JSX.Element => {
  const classes = PromotionPricesStyles();

  const {
    originalPrice,
    discountedPrice,
    platformId,
    type: promotionType,
  } = promotion;

  const isSteppedDiscount =
    promotionType === PROMOTIONS_TYPES.PROM_STEPPED_DISCOUNT;

  const formattedCurrencyText = (price: number | undefined) => (
    <Data value={price} type="currency" />
  );

  const renderPriceSection = () => {
    if (discountedPrice) {
      return (
        <>
          <Paragraph
            weight="normal"
            size="xsmall"
            className={classes.lineThrough}
            colortype="secondary"
            data-testid={`${platformId}-original-price-text-line-through`}
          >
            {formattedCurrencyText(originalPrice)}
          </Paragraph>
          <Paragraph
            weight="semibold"
            colortype="success"
            size="small"
            data-testid={`${platformId}-discounted-price-text`}
          >
            {formattedCurrencyText(discountedPrice)}
          </Paragraph>
        </>
      );
    }

    return (
      <Paragraph
        weight="semibold"
        size="small"
        data-testid={`${platformId}-original-price-text`}
        className={classNames(isSteppedDiscount && classes.steppedDiscountText)}
      >
        {formattedCurrencyText(originalPrice)}
      </Paragraph>
    );
  };

  return <div className={classes.container}>{renderPriceSection()}</div>;
};

export default PromotionPrices;
