import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";

import { Typography } from "@material-ui/core";

import PocInformationStore from "../../../../stores/callList/pocInformation/PocInformationStore";
import PaymentMethodInfoStore from "../../../../stores/paymentsInformation/PaymentMethodInfoStore";
import { isEmpty } from "../../../../config/utils/functions";

import { paymentMethodsLabels } from "../paymentMethod/PaymentMethodsEnum";
import PocPaymentInfoStyles from "./PocPaymentInfo.styles";

function PocPaymentInfo(): React.ReactElement {
  const classes = PocPaymentInfoStyles();
  const { t } = useTranslation();
  const {
    pocInformation: {
      financialReferenceId,
      paymentTerms: paymentTermsPocInformation,
    },
  } = useStore(PocInformationStore);

  const { done: isLoaded, paymentMethodInfo } = useStore(
    PaymentMethodInfoStore,
  );

  const { paymentMethods, paymentTerms } = paymentMethodInfo;

  const paymentMethod = paymentMethods?.[0];
  const paymentPeriod =
    paymentTerms?.[0]?.termPeriods?.[0]?.days ??
    paymentTermsPocInformation?.[0]?.termPeriods?.[0]?.days;

  const paymentMethodNotEmpty = !isEmpty(paymentMethod);
  const paymentPeriodNotEmpty = !isEmpty(paymentPeriod);

  const financialReferenceIdNotEmpty = !isEmpty(financialReferenceId);
  const showPaymentInfo =
    paymentMethodNotEmpty ||
    paymentPeriodNotEmpty ||
    financialReferenceIdNotEmpty;

  /* istanbul ignore next */
  const getDaysLabel = (days: number | string) => {
    return days === 1 ? t("OrderTaking.1_DAY") : t("OrderTaking.X_DAY");
  };

  if (!isLoaded) {
    return <></>;
  }

  if (showPaymentInfo) {
    return (
      <section className={classes.container} data-testid="payment-info-section">
        <div
          className={classes.titleContainer}
          data-testid="payment-info-title-container"
        >
          <Typography
            className={classNames(classes.text, classes.boldText)}
            data-testid="payment-info-title"
          >
            {t("PAYMENT_INFORMATION.TITLE")}
          </Typography>
        </div>

        <div
          className={classes.dataContainer}
          data-testid="payment-info-data-container"
        >
          {paymentMethodNotEmpty && (
            <div className={classes.itemContainer}>
              <Typography
                className={classes.text}
                data-testid="payment-method-label"
              >
                {t("PAYMENT_INFORMATION.METHOD")}
              </Typography>

              <Typography
                className={classNames(classes.text, classes.value)}
                data-testid="payment-method-value"
              >
                {t(paymentMethodsLabels[paymentMethod ?? ""])}
              </Typography>
            </div>
          )}

          {paymentPeriodNotEmpty && (
            <div className={classes.itemContainer}>
              <Typography
                className={classes.text}
                data-testid="payment-period-label"
              >
                {t("PAYMENT_INFORMATION.PERIOD")}
              </Typography>

              <Typography
                className={classNames(classes.text, classes.value)}
                data-testid="payment-period-value"
              >
                {paymentPeriod} {getDaysLabel(paymentPeriod ?? "")}
              </Typography>
            </div>
          )}

          {financialReferenceIdNotEmpty && (
            <div className={classes.itemContainer}>
              <Typography
                className={classes.text}
                data-testid="reference-ID-label"
              >
                {t("PAYMENT_INFORMATION.REFERENCE_ID")}
              </Typography>

              <Typography
                className={classNames(classes.text, classes.value)}
                data-testid="reference-ID-value"
              >
                {financialReferenceId}
              </Typography>
            </div>
          )}
        </div>
      </section>
    );
  }
  return <></>;
}

export default React.memo(PocPaymentInfo);
