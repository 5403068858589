import Dexie from "dexie";
import { CatalogItem } from "../../../domains/Catalog";

export type CatalogTable = Dexie.Table<CatalogItem>;

class CatalogDb extends Dexie {
  catalog: Dexie.Table<CatalogItem>;

  constructor() {
    super("CatalogDb");

    this.version(1).stores({
      catalog:
        "sku,price,stockAvailable,name,image,brandName,classification,package.name,marketplace,inventoryCount,palletQuantity,id,pricePerUoM,platformId",
    });

    this.catalog = this.table("catalog");
  }
}

export default CatalogDb;
