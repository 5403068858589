import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { Tabs, Tab } from "@material-ui/core";
import classNames from "classnames";
import useTabsStyle from "./style";
import { tabs, TABSLIST } from "./Tabs";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import { missionsTabInteraction } from "../../../../config/typewriter";
import GlobalStore from "../../../../stores/global/GlobalStore";
import {
  GROW_TECH_LOCAL_TO_CAMPAIGN_POC_ACCEPTANCE,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";

interface Props {
  missionsTab: string[];
}

const TabsMissions: React.FC<Props> = ({ missionsTab }) => {
  const [value, setValue] = useState<string>("toplineGap");
  const classes = useTabsStyle();
  const { t } = useTranslation();
  const { callId } = useStore(AgentCallStore);

  const { user } = GlobalStore.getState();
  const isLocalToCampaignsEnabled = isFeatureEnabledV2(
    GROW_TECH_LOCAL_TO_CAMPAIGN_POC_ACCEPTANCE,
    user.keyToggle,
  );

  const EnglishTabsList = new Map<string, string>([
    [TABSLIST.GENERAL, "GENERAL"],
    [TABSLIST.TOPLINE_GAP, "TOPLINE GAP"],
    [TABSLIST.QUICK_ORDER, "QUICK ORDER"],
    [TABSLIST.MARKETPLACE, "MARKETPLACE"],
    [TABSLIST.MARKETPLACE_ACTIVATION, "MARKETPLACE"],
    [TABSLIST.REWARDS, "CHALLENGES"],
    [TABSLIST.REWARDS_REDEMPTIONS, "REDEMPTION"],
    [TABSLIST.REWARDS_AND_REDEMPTIONS, "REWARDS"],
    [TABSLIST.CAMPAIGNS, "CAMPAIGNS"],
    [TABSLIST.SPONSORED, "SPONSORED"],
    [TABSLIST.SERVICE, "SERVICE"],
    [
      TABSLIST.LOCAL_MISSIONS,
      isLocalToCampaignsEnabled ? "CAMPAIGNS" : "LOCAL MISSIONS",
    ],
  ]);

  const handleChange = (_: unknown, newValue: string) => {
    setValue(newValue);

    /* istanbul ignore next */
    const tabLabel = EnglishTabsList.get(newValue) || null;

    missionsTabInteraction({
      tab_label: tabLabel,
      call_id: callId,
    });
  };
  useEffect(() => {
    if (missionsTab?.length) {
      setValue(missionsTab[0]);
    }
  }, [missionsTab]);

  const TabsList = new Map<string, string>([
    [TABSLIST.GENERAL, t("MISSIONS.general")],
    [TABSLIST.TOPLINE_GAP, t("MISSIONS.toplineGap")],
    [TABSLIST.QUICK_ORDER, t("MISSIONS.quickOrder")],
    [TABSLIST.MARKETPLACE, t("MISSIONS.marketplace")],
    [TABSLIST.MARKETPLACE_ACTIVATION, t("MISSIONS.marketplace")],
    [TABSLIST.REWARDS, t("MISSIONS.CHALLENGES")],
    [TABSLIST.REWARDS_REDEMPTIONS, t("MISSIONS.REDEMPTION")],
    [TABSLIST.REWARDS_AND_REDEMPTIONS, t("MISSIONS.rewards")],
    [TABSLIST.CAMPAIGNS, t("MISSIONS.campaigns")],
    [TABSLIST.SPONSORED, t("MISSIONS.sponsored")],
    [TABSLIST.SERVICE, t("MISSIONS.service")],
    [
      TABSLIST.LOCAL_MISSIONS,
      isLocalToCampaignsEnabled
        ? t("MISSIONS.campaigns")
        : t("MISSIONS.localMissions"),
    ],
  ]);

  const renderTabPanel = () => {
    return tabs[value](value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.tabsContainer}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="missions tab"
        >
          {missionsTab?.map((each) => (
            <Tab
              value={each}
              key={each}
              data-testid={`${each}-tab-mission`}
              className={classNames(classes.tabItem, {
                [classes.active]: value === each,
              })}
              label={
                <span className={classes.tabItemText}>
                  {TabsList.get(each)}
                </span>
              }
            />
          ))}
        </Tabs>
      </div>
      {renderTabPanel()}
    </div>
  );
};

export default React.memo(TabsMissions);
