import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      marginBottom: "1.5rem",
    },
    control: {
      display: "flex",
      flexDirection: "column",
    },
    controlLabel: {
      fontFamily: "Work Sans",
      fontSize: 16,
    },
    label: {
      color: "rgba(0,0,0,0.9)",
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontWeight: 600,
      letterSpacing: "0.1px",
      lineHeight: "24px",
      marginRight: "64px",
    },
    input: {
      margin: "1rem 0",
      "& .admin-portal-tlp-beta-mfe-MuiInputBase-root fieldset": {
        borderRadius: 8,
      },
      "& .tlp-MuiInputBase-root": {
        width: "100%",
      },
      "& .tlp-MuiInputBase-root::after": {
        content: "pts",
      },
      "& .tlp-MuiInputBase-root fieldset": {
        borderColor: palette.grey.A100,
        borderRadius: 8,
      },
    },
    inputPlaceholder: {
      "&&::placeholder": {
        color: palette.common.black,
      },
    },
    radioContainer: {
      display: "flex",
    },
  }),
);
