import { makeStyles, Theme, createStyles } from "@material-ui/core";

interface Props {
  missionCommentMarginTop: number;
}

export default makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    tooltipContainer: {
      margin: "6px 0",
      padding: "16px 16px 20px 16px",
      borderRadius: "8px",
      backgroundColor: theme.palette.common.black,
    },

    tooltipArrow: {
      color: theme.palette.common.black,
    },

    classificationText: {
      fontSize: "16px",
      fontWeight: 600,
      letterSpacing: "0.1px",
      lineHeight: 1.5,
      color: theme.palette.primary.light,
    },

    gapReasonText: {
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: 1.43,
      color: "#f2f2f2",
    },

    commentTitle: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: 1.5,
      marginTop: ({ missionCommentMarginTop }) => missionCommentMarginTop,
      color: "#f2f2f2",
    },

    commentText: {
      fontSize: "12px",
      letterSpacing: "0.03px",
      lineHeight: 1.33,
      color: "#f2f2f2",
    },
    icon: {
      marginLeft: "4px",
    },
  }),
);
