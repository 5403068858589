/* eslint-disable camelcase */
import {
  useLogService,
  useAuthenticationService,
} from "admin-portal-shared-services";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
} from "axios";
import { v4 as guid } from "uuid";

import {
  getTokenLocalStorage,
  getCanaryRelease,
  isTokenExpired,
  getEuropeClusterOrUrlParam,
  getUserInfos,
  isRefreshExpired,
} from "@config/utils/functions";
import { Logout } from "@config/logout/logout";
import { LOCAL_STORAGE_REFRESH_TOKEN } from "@config/constants";

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  retry?: boolean;
  "axios-retry"?: {
    retryCount: number;
  };
}

const axiosInstance = axios.create();

let isDoingRefresh = false;

const axiosIntercept = (): void => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getTokenLocalStorage();
      const log = useLogService();

      const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
      const authenticationService = useAuthenticationService();
      const expirationJwt: string | null =
        authenticationService.getJWTExpiration();
      const expiration: number | null = expirationJwt
        ? parseInt(expirationJwt, 10)
        : null;

      const { vendorId, orgId, country: countryUser } = getUserInfos();

      if (!countryUser || isTokenExpired(expiration)) {
        Logout();
      }

      config.headers.requestTraceId = guid();
      config.headers.canary = getCanaryRelease();
      config.headers.Accept = "application/json";
      config.headers.Authorization = token;
      config.headers.country = countryUser;

      if (orgId) {
        config.headers.orgId = orgId;

        if (vendorId) {
          config.headers.vendorId = vendorId;
        }
      }

      if (config.url) {
        config.url = getEuropeClusterOrUrlParam(config.url);
      }

      if (isRefreshExpired(expiration) && !isDoingRefresh && refreshToken) {
        isDoingRefresh = true;
        callRefreshToken(refreshToken)
          .then(() => {
            isDoingRefresh = false;

            return axios(config);
          })
          .catch((err) => {
            isDoingRefresh = false;
            log.error(err);
          });
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    },
  );
};

const callRefreshToken = (token: string): Promise<AxiosStatic> => {
  const authenticationService = useAuthenticationService();

  return (
    axios
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .post<any, AxiosResponse>("/auth/token/refresh", {
        refreshToken: token,
      })
      .then((res) => {
        if (res.status === 201 && res.data) {
          const { access_token, token_type, expires_in, refresh_token } =
            res.data;

          if (access_token && token_type && expires_in && refresh_token) {
            authenticationService.setAuthHeader(
              `${token_type} ${access_token}`,
            );

            localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refresh_token);
          }
        }

        return res;
      })
      .catch((err) => err)
  );
};

export { axiosIntercept };
export default axiosInstance;
