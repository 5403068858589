export const uatStoreIds: { [key: string]: string } = {
  BR: "a01deb9a-e47a-4e18-88e4-43018c64f03e",
  BRF_BR: "436605f3-f36c-4fc8-9460-dd54fb39c0b1",
  GPA_BR: "ff02a652-cea9-4c19-b615-c212ce03f506",
  NESTLE_BR: "604c9ad9-55b9-4ce2-9505-c0985f8ea164",
  CO: "eb6a886d-32f1-4555-bc5d-9a2ee8992c5e",
  EC: "8e030f4b-25dc-4eec-af43-a1992097ee9f",
  NESTLE_EC: "de6848e8-a830-4651-b727-7b8e6dac26e8",
  ES: "c56fec6f-8453-4df8-84f6-4550fc094560",
  DO: "d6ad9fa2-b3a7-4771-84d4-9f7d228d2412",
  MERCASID_DO: "f9e9d1ca-5bf0-4908-8289-51345bb592fb",
  ZA: "18f694bf-ec6a-4cd5-b680-24926124f07f",
  PE: "cb8048a7-5275-4e50-bbdd-986f3b132289",
  GLORIA_PE: "ea0302dc-0b9b-49c9-8a51-f58fc789456f",
  GLORIA_CO: "c1929e7b-1225-4715-8426-2a5b540318a0",
  MX: "ce141576-4a9d-4bad-8fd8-5bf5cce1c06c",
  PA: "57ad8a23-8f32-46c2-81b4-dc2419afc2a7",
  HN: "9fea9ccb-f4df-4c3f-8e39-db39f18eda35",
  SV: "1d8cacb7-7762-4dfc-b64a-fc1b5dc874d0",
  UY: "c17c734a-2bfe-485e-886c-89dc0bb49e84",
  PY: "027afce6-65a6-4bc8-8f11-2f542098f123",
  AR: "a8fba145-455d-43d2-b6a5-ed625ba483f4",
  NESTLEACTISERVE_PH: "3105edec-f042-4352-9860-36a04ce2c56e",
  NESTLECAVITE_PH: "da6600c4-7f4c-45a0-81fe-e0b4723441b5",
  NESTLERUKUN_ID: "73fff8e1-eee9-4853-9ff7-62db9bf96129",
  NESTLECIPTA_ID: "7f52f6c6-7dc9-4047-a0d5-27533c9a4a42",
  PEPSICO_BR: "e610cc98-d779-45db-8f10-060a8035028e",
  NESTLE_CL: "41b73630-3e20-4e1e-858a-1ad24d046878",
  NESTLE_TH: "0bd7c4c9-20ef-4b23-939c-27dc43fff984",
  GH: "94ac3e84-27c8-450e-ab96-778fa006e9b8",
};
