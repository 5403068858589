import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    actionAfterCallContainer: {
      paddingTop: "16px",
    },
    actionAfterCallContainerV2: {
      display: "flex",
      paddingTop: 0,
      marginTop: "8px",
      marginBottom: "8px",
      justifyContent: "space-between",
    },
    actionButtonsContainer: {
      display: "flex",
      width: "100%",
      padding: "0",
      marginTop: "8px",
      marginBottom: "24px",
      justifyContent: "space-between",
    },
    actionButtonsContainerV2: {
      marginTop: 0,
      marginBottom: 0,
    },
    label: {
      fontSize: "14px",
      fontWeight: "normal",
      color: palette.text.secondary,
      display: "flex",
      flexDirection: "column",
    },
    labelV2: {
      color: "rgba(0,0,0,0.9)",
      margin: "3px 0 0 0",
    },
    options: {
      fontSize: 14,
      fontWeight: 500,
      color: palette.text.primary,
      fontFamily: "Work Sans",
    },
    optionsContainer: {
      flexDirection: "row",
    },
    button: {
      alignSelf: "center",
      minWidth: "148px",
      padding: "15px 20px",
      height: "40px",
      borderRadius: "30px",
      whiteSpace: "nowrap",
    },
    closeButton: {
      marginRight: "20px",
      color: "black",
      backgroundColor: "#fff",
      border: "1px solid black",
    },
    submitButton: {
      color: "#ffff",
      backgroundColor: palette.secondary.main,
    },
    radioGroup: {
      alignSelf: "center",
    },
    goHomeButton: {
      minWidth: "132px",
      padding: "7px 16px",
      marginRight: "20px",
      color: "black",
      backgroundColor: "#fff",
      border: "1px solid black",
      lineHeight: "24px",
      height: "40px",
      "& .tlp-MuiCircularProgress-indeterminate": {
        color: "#212121 !important",
      },
    },
    goHomeButtonTypedPoc: {
      minWidth: "132px",
      padding: "7px 16px",
      color: "#ffff",
      backgroundColor: palette.secondary.main,
      lineHeight: "24px",
      height: "40px",
    },
    nextPocButton: {
      minWidth: "140px",
      padding: "8px 16px",
      color: "#ffff",
      backgroundColor: palette.secondary.main,
      lineHeight: "24px",
      height: "40px",
    },
    nextPocButtonWidth: {
      minWidth: "219px",
    },
  }),
);
