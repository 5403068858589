import { createStyles, makeStyles } from "@material-ui/core";

export const CloseButtomStyles = makeStyles(() =>
  createStyles({
    closeButtom: {
      width: "20px",
      height: "20px",
      opacity: "50%",
      marginTop: "2px",
    },
  }),
);
