import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "effector-react";

import SuggestedOrderIcon from "../../../../assets/newIcons/SuggestedOrderIcon";
import CollapsibleTitle from "../../../../components/collapsableTitle/CollapsibleTitle";
import { ANALYTICS_ROUTE_NAMES } from "../../../../config/constants";
import {
  TLP_GAP_BOARD,
  TLP_GAP_BOARD_ADMIN,
  TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT,
  TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT_ADMIN,
  isFeatureEnabled,
} from "../../../../config/featureToggles";
import { callUxButtonClicked } from "../../../../config/typewriter";
import { getValueOrNull } from "../../../../config/utils/functions";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import GlobalAdminConfigStore from "../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import { getLastMarketplaceOrders } from "../../../../stores/lastMarketplaceOrders/LastMarketplaceOrdersEvents";
import LastMarketplaceOrdersStore from "../../../../stores/lastMarketplaceOrders/LastMarketplaceOrdersStore";
import LastOrdersStore from "../../../../stores/lastOrders/LastOrdersStore";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";
import * as LastOrdersUseCase from "../../../../usecase/lastOrders/LastOrdersUseCase";
import GapBoard from "../header/gapBoard/GapBoard";
import MissionLastOrderContainer from "../missionLastOrderContainer/MissionLastOrderContainer";
import MissionsFocusSkuComponent from "../missionsFocusSkuComponent/MissionsFocusSkuComponent";
import SuggestedOrder from "../suggestedOrder/SuggestedOrder";
import useGapAndOrderStyles from "./styles";

const GapAndOrderComponent: React.FC = () => {
  const [suggestedOpen, setSuggestedOpen] = useState(true);
  const [lastOrderOpen, setLastOrderOpen] = useState(false);
  const [MissionsProductsOpen, setMissionsProductsOpen] = useState(true);
  const { callTab } = useStore(CallTabStore);
  const { callId, clientId } = useStore(AgentCallStore);
  const { orders, isLoading, error } = useStore(LastOrdersStore);
  const {
    isLoading: isLoadingMarketplace,
    lastOrders,
    error: errorMarketplace,
  } = useStore(LastMarketplaceOrdersStore);
  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { vendorId } = userConfig;
  const { t } = useTranslation();
  const {
    user: { keyToggle, zone },
  } = useStore(GlobalStore);

  const isLastMarketplaceOrdersEndpointEnabled = isFeatureEnabled(
    TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT,
    TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT_ADMIN,
    zone,
  );

  const handleOpenSuggestedOrder = () => {
    const buttonName = suggestedOpen
      ? "suggested-order-component-collapse"
      : "suggested-order-component-expand";

    const screenSection = "Suggested Orders";
    const isExpanded = !suggestedOpen;
    setSuggestedOpen(!suggestedOpen);

    callEventCallUx(buttonName, screenSection, isExpanded);
  };

  const callLastOrders = () => {
    if (isLastMarketplaceOrdersEndpointEnabled) {
      getLastMarketplaceOrders(clientId);
    } else {
      LastOrdersUseCase.execute(clientId, vendorId);
    }
  };

  const handleOpenLastOrder = () => {
    const buttonName = lastOrderOpen
      ? "last-orders-component-collapse"
      : "last-orders-component-expand";
    const screenSection = "Last Orders";
    const isExpanded = !lastOrderOpen;
    callEventCallUx(buttonName, screenSection, isExpanded);
    setLastOrderOpen(!lastOrderOpen);
  };

  const handleOpenMissionsProducts = () => {
    const buttonName = MissionsProductsOpen
      ? "target-products-component-collapse"
      : "target-products-component-expand";

    const screenSection = "Target products";
    const isExpanded = !MissionsProductsOpen;
    callEventCallUx(buttonName, screenSection, isExpanded);
    setMissionsProductsOpen(!MissionsProductsOpen);
  };

  const classes = useGapAndOrderStyles();
  const isGapBoardEnabled = isFeatureEnabled(
    TLP_GAP_BOARD,
    TLP_GAP_BOARD_ADMIN,
    keyToggle,
  );

  const callEventCallUx = (
    buttonName: string,
    screenSection: string,
    isExpanded: boolean,
  ) => {
    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: buttonName,
      button_label: isExpanded ? "expand" : "collapse",
      screen_section: screenSection,
      filter_option: null,
      call_id: callId,
      order_date: null,
      position: null,
    });
  };

  const refreshLastOrders = () => {
    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: "Refresh",
      button_label: "call-last-orders-refresh",
      screen_section: "Last Orders",
      filter_option: "",
      call_id: callId,
      order_date: null,
      position: null,
    });

    if (isLastMarketplaceOrdersEndpointEnabled) {
      getLastMarketplaceOrders(clientId);
    } else {
      LastOrdersUseCase.execute(clientId, vendorId);
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <CollapsibleTitle
        icon={<SuggestedOrderIcon />}
        title={t("SuggestedOrder.SUGGESTED_ORDER_LABEL")}
        testId="suggested-order-collapse"
        open={suggestedOpen}
        handleOpen={handleOpenSuggestedOrder}
      >
        {isGapBoardEnabled && (
          <div className={classes.gapBoardContainer}>
            <GapBoard />
          </div>
        )}
        <SuggestedOrder keyToggle={keyToggle} isNewLayout />
      </CollapsibleTitle>

      <MissionLastOrderContainer
        error={
          isLastMarketplaceOrdersEndpointEnabled ? errorMarketplace : error
        }
        handleOpenLastOrder={handleOpenLastOrder}
        lastOrderOpen={lastOrderOpen}
        refreshLastOrders={refreshLastOrders}
        title={t("LastOrders.LAST_ORDERS_LABEL")}
        zone={keyToggle}
        titleUpdate={t("LastOrders.UPDATE_LIST")}
        orders={isLastMarketplaceOrdersEndpointEnabled ? lastOrders : orders}
        isLoading={
          isLastMarketplaceOrdersEndpointEnabled
            ? isLoadingMarketplace
            : isLoading
        }
        errorFunction={callLastOrders}
      />

      <MissionsFocusSkuComponent
        handleOpen={handleOpenMissionsProducts}
        open={MissionsProductsOpen}
      />
    </div>
  );
};

export default React.memo(GapAndOrderComponent);
