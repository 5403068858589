import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    title: {
      fontSize: "16px",
      fontWeight: 600,
    },
    time: {
      color: "#707372",
    },
    dotSeparatorHit: {
      margin: "1px",
      backgroundColor: "#1af07e",
    },
    dotSeparatorNoHit: {
      margin: "1px",
      backgroundColor: "#ff6f71",
    },
    connector: {
      backgroundColor: "#dfdede",
    },
    boxCollapse: {
      display: "flex",
      marginTop: "-4px",
    },
    oppositeContainer: {
      flex: "none",
      padding: "0px 4px 0px 0px",
      marginTop: "-4px",
      minWidth: "70px",
    },
    content: {
      padding: "0px 0px 14px 8px",
    },
    icon: {
      padding: "0px",
    },
    item: {
      minHeight: "53px",
    },
    divider: {
      marginTop: "0.5rem",
      color: "#dfdede",
      border: "1px solid",
    },
    timelineBaseContainer: {
      padding: "6px 0px",
    },
  }),
);
