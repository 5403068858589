import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CTIModalGeneric from "../modal/CTIModalGeneric";

export interface Props {
  open: boolean;
  onClose: () => void;
  connectWebSocket?: () => void;
  retryCount: number;
  loading: boolean;
  retryDisabled: boolean;

  dispatchErrorModalViewed: () => void;
}
function CTIFailedToConnectModal({
  onClose,
  open,
  connectWebSocket,
  retryCount,
  loading,
  retryDisabled,
  dispatchErrorModalViewed,
  ...props
}: Props): React.ReactElement {
  const { t } = useTranslation();
  /* istanbul ignore next */
  useEffect(() => {
    if (open) {
      dispatchErrorModalViewed();
    }
  }, [dispatchErrorModalViewed, open]);
  return (
    <CTIModalGeneric
      {...props}
      titleModal={t("CTIbrazil.MODAL_TITLE_FAIL")}
      messageFailConect={t("CTIbrazil.MODAL_FAIL_CONNECT")}
      messageWhithoutCti={t("CTIbrazil.CONTINUE_WITHOUT_CTI")}
      attemptsLabel={t("CTIbrazil.ATTEMPTS")}
      mainButton={t("CTIbrazil.RETRY")}
      secondaryButton={t("CTIbrazil.CONTINUE")}
      onClose={onClose}
      open={open}
      connectWebSocket={connectWebSocket}
      retryCount={retryCount}
      loading={loading}
      retryDisabled={retryDisabled}
    />
  );
}

CTIFailedToConnectModal.defaultProps = {
  connectWebSocket: undefined,
};

export default CTIFailedToConnectModal;
