import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import useStyles from "./style";

// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
const image = require("../../../../../../assets/images/empty-stage.png");

const EmptyLastContacts: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container} data-testid="empty-last-contacts">
      <div>
        <img src={image} alt="empty state" width="100%" />
      </div>
      <Typography className={classes.text}>
        {t("CUSTOMER_PAGE.NO_PREVIOUS_CONTACTS")}
      </Typography>
    </div>
  );
};

export default EmptyLastContacts;
