/* istanbul ignore file */
import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    classificationButton: {
      width: 140,
      height: 40,
      borderRadius: 20,
    },
    wrongButton: {
      border: `solid 1px ${palette.error.main}`,
      marginRight: 8,
    },
    inactiveButton: {
      border: `solid 1px ${palette.error.main}`,
      marginRight: 8,
    },
    noAnswerButton: {
      border: `solid 1px #979797`,
      marginRight: 8,
    },
    successButton: {
      border: `solid 1px #1d8445`,
    },
    activateButton: {
      border: `solid 1px #000000`,
    },
    classificationButtonLabel: {
      padding: "10px",
    },
    wrongButtonLabel: {
      color: "#c9201d",
    },
    inactiveButtonLabel: {
      color: "#c9201d",
    },
    noAnswerButtonLabel: {
      color: "#707372",
    },
    successButtonLabel: {
      color: "#1d8445",
    },
    activateButtonLabel: {
      color: "#000000",
    },
  }),
);
