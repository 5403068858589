export const classificationsValues = {
  EMPTY: "",
  UPLIFT_ON_CALL: "Uplifted",
  UPLIFT_AFTER_CALL: "Uplifted after call",
  UPLIFT_ON_CALL_BY_AGENT: "Uplift on call by Agent",
  NO_ANSWER: "No Answer",
  NO_ANSWER_TRY_AGAIN: "No Answer - try again",
  RESPONSIBLE_NOT_AVAILABLE: "Responsible Not Available",
  NO_SUCCESS_ON_UPLIFT: "No Success On Uplift",
  POC_ALREADY_PLACED_THE_ORDER: "Poc Already Placed The Order",
  INCORRECT_PHONE_NUMBER: "Incorrect Phone Number",
  POC_CLOSED_DUPLICATED: "Poc Closed Duplicated",
  CALL_LATER_TODAY: "Call Later Today",
};

export default [
  {
    value: "",
    label: "CallTypification.EMPTY",
  },
  {
    value: classificationsValues.UPLIFT_ON_CALL,
    label: "CallTypification.UPLIFT_ON_CALL",
  },
  {
    value: classificationsValues.UPLIFT_AFTER_CALL,
    label: "CallTypification.UPLIFT_AFTER_CALL",
  },
  {
    value: classificationsValues.UPLIFT_ON_CALL_BY_AGENT,
    label: "CallTypification.UPLIFT_ON_CALL_BY_AGENT",
  },
  {
    value: classificationsValues.NO_ANSWER,
    label: "CallTypification.NO_ANSWER",
  },
  {
    value: classificationsValues.NO_ANSWER_TRY_AGAIN,
    label: "CallTypification.NO_ANSWER_TRY_AGAIN",
  },
  {
    value: classificationsValues.RESPONSIBLE_NOT_AVAILABLE,
    label: "CallTypification.RESPONSIBLE_NOT_AVAILABLE",
  },
  {
    value: classificationsValues.NO_SUCCESS_ON_UPLIFT,
    label: "CallTypification.NO_SUCCESS_ON_UPLIFT",
  },
  {
    value: classificationsValues.POC_ALREADY_PLACED_THE_ORDER,
    label: "CallTypification.POC_ALREADY_PLACED_THE_ORDER",
  },
  {
    value: classificationsValues.INCORRECT_PHONE_NUMBER,
    label: "CallTypification.INCORRECT_PHONE_NUMBER",
  },
  {
    value: classificationsValues.POC_CLOSED_DUPLICATED,
    label: "CallTypification.POC_CLOSED_DUPLICATED",
  },
  {
    value: classificationsValues.CALL_LATER_TODAY,
    label: "CallTypification.CALL_LATER_TODAY",
  },
];

export const callLaterOptions = [
  {
    value: 0.25,
    label: "CallTypification.15_MINUTES",
  },
  {
    value: 0.5,
    label: "CallTypification.30_MINUTES",
  },
  {
    value: 1,
    label: "CallTypification.1_HOUR",
  },
  {
    value: 1.5,
    label: "CallTypification.1_HOUR_AND_30_MINUTES",
  },
  {
    value: 2,
    label: "CallTypification.2_HOURS",
  },
  {
    value: 2.5,
    label: "CallTypification.2_HOUR_AND_30_MINUTES",
  },
  {
    value: 3,
    label: "CallTypification.3_HOURS",
  },
  {
    value: 3.5,
    label: "CallTypification.3_HOUR_AND_30_MINUTES",
  },
  {
    value: 4,
    label: "CallTypification.4_HOURS",
  },
  {
    value: 4.5,
    label: "CallTypification.4_HOUR_AND_30_MINUTES",
  },
  {
    value: 5,
    label: "CallTypification.5_HOURS",
  },
  {
    value: 5.5,
    label: "CallTypification.5_HOUR_AND_30_MINUTES",
  },
  {
    value: 6,
    label: "CallTypification.6_HOURS",
  },
  {
    value: 6.5,
    label: "CallTypification.6_HOUR_AND_30_MINUTES",
  },
  {
    value: 7,
    label: "CallTypification.7_HOURS",
  },
  {
    value: 7.5,
    label: "CallTypification.7_HOUR_AND_30_MINUTES",
  },
  {
    value: 8,
    label: "CallTypification.8_HOURS",
  },
];
