import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import LoadErrorIcon from "../../assets/LoadErrorIcon";
import Button from "../button/Button";
import LoadErrorStyles from "./LoadErrorStyles";

interface Props {
  testId: string;
  description: string;
  onClick: () => void;
  className?: string;
}

const LoadError: React.FunctionComponent<Props> = ({
  testId,
  className = "",
  description,
  onClick,
}) => {
  const classes = LoadErrorStyles();
  const { t } = useTranslation();

  return (
    <div className={`${classes.container} ${className}`} data-testid={testId}>
      <div className={classes.loadErrorIcon}>
        <LoadErrorIcon />
      </div>
      <div className={classes.infoContainer}>
        <Typography variant="h1" data-testid="h1-testid">
          {t("ErrorRetry.ERROR_RETRY_COMPONENT_TITLE")}
        </Typography>
        <Typography
          className={classes.subtitle}
          data-testid="description-testid"
        >
          {description}
        </Typography>

        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            data-testid={`${testId}-button`}
            onClick={onClick}
          >
            {t("ErrorRetry.TRY_AGAIN_BUTTON")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoadError;
