import React from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@assets/CloseButtonIcon";
import { CloseButtomStyles } from "./CloseButtom.styles";

export interface CloseButtomProps {
  closeToast: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const CloseButtom = ({ closeToast }: CloseButtomProps): JSX.Element => {
  const classes = CloseButtomStyles();

  return (
    <IconButton
      className={classes.closeButtom}
      onClick={closeToast}
      data-testid="custom-toast-close-buttom"
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButtom;
