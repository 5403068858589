import {
  MarketplaceResponse,
  MarketplaceItem,
} from "../../domains/Marketplace";

export default function MarketplaceResponseToMarketplaceItemConverter(
  data: Array<MarketplaceResponse>,
): Array<MarketplaceItem> {
  return [
    ...data.map((each) => {
      return {
        active: each.active,
        sku: each.sku,
        skuName: each.skuName,
        vendorId: each.vendorId,
      };
    }),
  ];
}
