import {
  GROW_DISABLE_FOCUS_SKU,
  isFeatureEnabledV2,
} from "../../config/featureToggles";
import GlobalStore from "../../stores/global/GlobalStore";
import { Campaigns } from "../../domains/Campaigns";
import {
  CampaignsResponse,
  CampaignB2O,
  FocusSku,
  CampaingProduct,
} from "../../services/campaigns/domains/CampaignsResponse";

export default function CampaignsResponseToCampaignsConverter(
  data: CampaignsResponse,
): Campaigns {
  const { campaigns: campaignsResponse, focus: focusResponse } = data || {};

  const { user } = GlobalStore.getState();

  const isDisableFocusSKUCampaign = isFeatureEnabledV2(
    GROW_DISABLE_FOCUS_SKU,
    user.keyToggle,
  );

  const getProduct = (product: CampaingProduct) => {
    return {
      id: product?.id,
      pricePerUoM: product?.pricePerUoM,
      palletQuantity: product?.palletQuantity,
      inventoryCount: product?.inventoryCount,
      sku: product?.sku,
      productName: product?.productName,
      unitPrice: product?.unitPrice,
      image: product?.image,
      stock: product?.stock,
      itemPlatformId: product?.itemPlatformId,
      vendorItemId: product.vendorItemId,
    };
  };

  const campaigns =
    campaignsResponse?.map((campaign: CampaignB2O) => ({
      campaignTag: campaign?.campaignTag,
      campaignName: campaign?.campaignName,
      campaignType: campaign?.campaignType,
      campaignDescription: campaign?.campaignDescription,
      campaignScript: campaign?.campaignScript,
      campaignImage: campaign?.campaignImage,
      campaignProducts: campaign?.campaignProducts?.map(getProduct) || [],
    })) || [];

  const focus =
    !isDisableFocusSKUCampaign && focusResponse
      ? focusResponse.map((campaign: FocusSku) => ({
          campaignTag: campaign?.campaignTag,
          campaignName: campaign?.campaignName,
          campaignType: campaign?.campaignType,
          campaignProducts: campaign?.campaignProducts?.map(getProduct) || [],
        }))
      : [];

  return {
    campaigns,
    focus,
  };
}
