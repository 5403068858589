import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { isFeatureEnabled } from "grow-shared-services";
import { RecommendedMarketplaceProductsV2Converter } from "@converters/RecommendedMarketplaceProducts/RecommendedMarketplaceProductsV2Converter";
import { NewOfferingsItemV2 } from "@services/newOfferings/domains/NewOfferingsResponseItem";
import {
  GROW_ORGANIZATION_ACTIVATION,
  TLP_MULTICONTRACT_CALL_LIST_SERVICE,
} from "@config/featureToggles";
import axios from "@config/axios/axiosInstance";

import { RecommendedMarketplaceItemResponse } from "@domains/RecommendedMarketplaceProducts";
import { Product } from "@domains/products/Product";
import { RecommendedMarketplaceProductsConverter } from "@converters/RecommendedMarketplaceProducts/RecommendedMarketplaceProductsConverter";
import { CALL_LIST_SERVICE, GROW_BFF_SERVICE } from "@config/services";
import VendorStore from "@stores/vendor/VendorStore";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../host/HostService";

export const CALL_LIST_ENDPOINT = `/call-list`;
export const RECOMMENDED_PRODUCTS_ENDPOINT = "recommended";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
  retryCondition: (error) => {
    if (error) {
      return error.response?.status === 400 || error.response?.status === 401;
    }

    return false;
  },
});

export function getRecommendedMarketplaceProducts(
  accountId: string,
): Promise<Product[]> {
  const { vendor } = VendorStore.getState();
  const log = useLogService();

  const isCallListV2Enabled = isFeatureEnabled(
    TLP_MULTICONTRACT_CALL_LIST_SERVICE,
  );
  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  const vendorId = vendor?.id;

  const endpoint = getEndpoint(
    isOrganizationEnabled,
    isCallListV2Enabled,
    accountId,
    vendorId,
  );

  if (isOrganizationEnabled) {
    return axios
      .get<NewOfferingsItemV2[]>(endpoint)
      .then((response) => {
        log.info(
          `Convert RecommendedMarketplaceProducts data received from ${endpoint} to our model`,
        );
        return RecommendedMarketplaceProductsV2Converter(response.data);
      })
      .catch((error) => {
        log.error(
          "RecommendedMarketplaceProductsService - getRecommendedMarketplaceProducts error: ",
          error,
        );
        throw error;
      });
  }

  return axios
    .get<RecommendedMarketplaceItemResponse[]>(endpoint)
    .then((response) => {
      log.info(
        `Convert RecommendedMarketplaceProducts data received from ${endpoint} to our model`,
      );
      return RecommendedMarketplaceProductsConverter(response.data);
    })
    .catch((error) => {
      log.error(
        "RecommendedMarketplaceProductsService - getRecommendedMarketplaceProducts error: ",
        error,
      );
      throw error;
    });
}

function getEndpoint(
  isOrganizationEnabled: boolean,
  isCallListV2Enabled: boolean,
  accountId: string,
  vendorId?: string,
) {
  if (isOrganizationEnabled) {
    return `${getApiHost(
      GROW_BFF_SERVICE,
    )}/v1/accounts/${accountId}/recommendations?onlyMarketplaceItems=true`;
  }
  const baseUrl = `${getApiHost(CALL_LIST_SERVICE)}${CALL_LIST_ENDPOINT}`;
  const v2EndPoint = getV2EndPoint(isCallListV2Enabled);
  const vendorIdParam = getVendorIdParam(vendorId, isCallListV2Enabled);
  return `${baseUrl}${v2EndPoint}/${accountId}/${RECOMMENDED_PRODUCTS_ENDPOINT}${vendorIdParam}`;
}
