import React, { useCallback } from "react";
import { Box, Select, MenuItem } from "@material-ui/core";
import style from "./DropdownStyles";

const Dropdown: React.FunctionComponent<Props> = ({
  testId,
  items,
  onChange,
  value,
}) => {
  const classes = style();

  const handleChange = useCallback(
    (ev) => {
      const item =
        items.find((each) => each.campaignTag === ev.target.value) || null;
      onChange(item);
    },
    [onChange, items],
  );

  return (
    <Box className={classes.container}>
      <Select
        data-testid={testId}
        variant="outlined"
        value={value?.campaignTag || ""}
        classes={{
          select: classes.select,
        }}
        onChange={handleChange}
      >
        {items.map((item) => (
          <MenuItem key={item.campaignTag} value={item.campaignTag}>
            {item.campaignName}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

Dropdown.defaultProps = {
  testId: "dropdown-component",
};

interface Campaign {
  campaignTag: string;
  campaignName: string;
  campaignType: string;
  campaignScript: string;
  campaignDescription: string;
  campaignImage: string;
}

interface Props {
  testId?: string;
  items: Array<Campaign>;
  value: Campaign | null;
  onChange: (value: Campaign | null) => void;
}

export default Dropdown;
