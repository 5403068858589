import { AgentSurvey } from "../../domains/AgentSurvey";
import * as AgentSurveyService from "../../services/agentSurvey/AgentSurveyService";

export function executeFirstSurveyForAgent(
  agentId: string,
): Promise<AgentSurvey> {
  return AgentSurveyService.firstSurveyForAgent(agentId);
}

export function executeUpdateSurvey(
  surveyId: string,
  agentId: string,
): Promise<void> {
  return AgentSurveyService.updateSurvey(surveyId, agentId);
}
