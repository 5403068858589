import * as GlobalEvents from "../../stores/global/GlobalEvents";
import Toast from "../../domains/Toast";

function loadBackDrop(): void {
  GlobalEvents.loadBackDrop(null);
}

function loadBackDropDone(): void {
  GlobalEvents.loadBackDropDone(null);
}

function toast(payload: Toast): void {
  GlobalEvents.toast(payload);
}

function toastHide(): void {
  GlobalEvents.toastHide(null);
}
export { loadBackDrop, loadBackDropDone, toast, toastHide };
