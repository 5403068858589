import { createStore } from "effector";
import { PhoneRankingState } from "./phoneRankedState";
import {
  resetPhonesRanking,
  getPhonesRanked,
  setIsPhoneCalled,
  updatePhoneRanking,
  setPhonesRanking,
  setCalledPhones,
  setLastCopiedPhone,
  setIsModalOpen,
  setLastCalledOrCopiedPhone,
} from "./phoneRankedEvents";
import { Phone } from "../../domains/phoneRanking/RankedPhones";

const initialState = <PhoneRankingState>{
  phones: [],
  calledPhones: [],
  lastCopiedPhone: "",
  isModalOpen: false,
  done: false,
  error: false,
  errorToUpdatePhoneRanking: false,
  loading: false,
  classifiedPhone: false,
  lastCalledOrCopiedPhone: "",
};
const phoneRankedStore = createStore(initialState)
  .on(
    getPhonesRanked.done,
    /* istanbul ignore next */
    (state, { result }) => {
      const updatedPhones = result?.phones?.map((each) => {
        let phone: Phone = {
          phoneContact: "",
          phoneNumber: "",
          phoneType: "",
          score: 0,
          isCalled: false,
        };
        const findedStatePhone = state.phones.find(
          (eachPhone) => eachPhone.phoneNumber === each.phoneNumber,
        );
        if (findedStatePhone?.isCalled) {
          phone = {
            ...findedStatePhone,
            ...each,
            isCalled: findedStatePhone.isCalled,
          };
        } else {
          phone = each;
        }
        return phone;
      });
      return {
        ...state,
        phones: updatedPhones || [],
        error: false,
        done: true,
        loading: false,
      };
    },
  )
  .on(getPhonesRanked.fail, (state) => ({
    ...state,
    error: true,
    done: false,
    loading: false,
  }))
  .on(getPhonesRanked, (state) => ({
    ...state,
    error: false,
    done: false,
    loading: true,
  }))
  .on(setCalledPhones, (state, calledPhones) => {
    return {
      ...state,
      calledPhones,
    };
  })
  .on(setIsModalOpen, (state, value) => {
    return {
      ...state,
      isModalOpen: value,
    };
  })
  .on(setIsPhoneCalled, (state, value) => {
    const phoneIndex = state.phones.findIndex((each) => {
      return each.phoneNumber === value;
    });
    const phones = [...state.phones];
    phones[phoneIndex] = {
      ...phones[phoneIndex],
      isCalled: true,
    };

    return {
      ...state,
      phones,
    };
  })
  .on(
    updatePhoneRanking.done,
    /* istanbul ignore next */
    (state) => {
      return {
        ...state,
        errorToUpdatePhoneRanking: false,
        done: true,
      };
    },
  )
  .on(updatePhoneRanking.fail, (state) => ({
    ...state,
    errorToUpdatePhoneRanking: true,
    done: false,
    error: true,
  }))
  .on(updatePhoneRanking, (state) => ({
    ...state,
    errorToUpdatePhoneRanking: false,
    done: false,
  }))
  .on(setPhonesRanking, (state, phones) => ({
    ...state,
    phones,
  }))
  .on(setLastCopiedPhone, (state, lastCopiedPhone) => {
    return {
      ...state,
      lastCopiedPhone,
    };
  })
  .on(setLastCalledOrCopiedPhone, (state, lastCalledOrCopiedPhone) => {
    return {
      ...state,
      lastCalledOrCopiedPhone,
    };
  })
  .reset(resetPhonesRanking);

export default phoneRankedStore;
