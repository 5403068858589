import CallDuration from "../../stores/callDuration/CallDurationState";
import * as CallDurationService from "../../services/callDuration/CallDurationService";
import * as CallDurationServiceV3 from "../../services/callDuration/v3/CallDurationServiceV3";
import {
  GROW_ORGANIZATION_ACTIVATION,
  isFeatureEnabledV2,
} from "../../config/featureToggles";
import GlobalStore from "../../stores/global/GlobalStore";

export function execute({
  vendorId,
}: {
  vendorId: string;
}): Promise<CallDuration> {
  const { user } = GlobalStore.getState();
  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    user.keyToggle,
  );

  if (isOrganizationConfigEnabled) {
    return CallDurationServiceV3.getCallDurationV3();
  }
  return CallDurationService.getCallDuration(vendorId);
}
