import React from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import PriceStyles from "./PriceStyles";
import CartItemStore from "../../../../../stores/cart/CartItemStore";
import Data from "../../../../../components/data/Data";

interface Props {
  loading: boolean;
  isCartNewExhibition: boolean;
}

const Price: React.FunctionComponent<Props> = ({
  loading,
  isCartNewExhibition,
}) => {
  const { t } = useTranslation();
  const CartItemState = useStore(CartItemStore);
  const classes = PriceStyles();

  const getLine = (
    dataTestId: string,
    title: string,
    value: number | undefined,
    optional: boolean,
    classOverride?: string,
  ) => {
    if (optional && !value) {
      return <></>;
    }
    return (
      <>
        {isCartNewExhibition ? (
          <div
            className={classes.newLineContainer}
            data-testid={`${dataTestId}-container`}
          >
            <div className={classOverride ?? classes.newText}>{t(title)}</div>
            <div
              className={classOverride ?? classes.newText}
              data-testid={`${dataTestId}-value`}
            >
              {dataTestId === "cart-discount" && <span>- </span>}
              <Data
                testId={`${dataTestId}-value-data`}
                value={(value ?? 0).toFixed(2)}
                type="currency"
              />
            </div>
          </div>
        ) : (
          <div
            className={classes.lineContainer}
            data-testid={`${dataTestId}-container`}
          >
            <div className={classes.priceTitle}>{t(title)}</div>
            <hr className={classes.dashedLine} />
            <div
              className={classes.priceValue}
              data-testid={`${dataTestId}-value`}
            >
              <Data
                testId={`${dataTestId}-value-data`}
                value={value?.toFixed(2)}
                type="currency"
              />
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <div className={classes.pricesContainer}>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <div
          className={
            isCartNewExhibition
              ? classes.newPricesValuesContainer
              : classes.pricesValuesContainer
          }
        >
          {getLine(
            "cart-subtotal",
            "OrderTaking.SUBTOTAL_ORDER",
            CartItemState.cart.subtotal,
            true,
          )}

          {isCartNewExhibition ? (
            <>
              {getLine(
                "cart-tax",
                "OrderTaking.TAX_ORDER",
                CartItemState.cart.taxAmount,
                true,
              )}
              {getLine(
                "cart-discount",
                "OrderTaking.DISCOUNT_ORDER",
                CartItemState.cart.discountAmount,
                true,
                classes.newDiscountText,
              )}
            </>
          ) : (
            <>
              {getLine(
                "cart-discount",
                "OrderTaking.DISCOUNT_ORDER",
                CartItemState.cart.discountAmount,
                true,
              )}
              {getLine(
                "cart-tax",
                "OrderTaking.TAX_ORDER",
                CartItemState.cart.taxAmount,
                true,
              )}
            </>
          )}

          {typeof CartItemState.cart.interestAmount !== "undefined" && (
            <>
              {getLine(
                "cart-interest-amount",
                "OrderTaking.BANK_SLIP_FEE",
                CartItemState.cart.interestAmount,
                false,
              )}
            </>
          )}
          {getLine(
            "cart-overprice",
            "OrderTaking.OVERPRICE",
            CartItemState.cart.overprice,
            true,
          )}
          {isCartNewExhibition ? (
            <>
              {getLine(
                "cart-total",
                "OrderTaking.TOTAL_ORDER_PRICE",
                CartItemState.cart.total,
                false,
                classes.newTotalText,
              )}
            </>
          ) : (
            <>
              <hr className={classes.line} />
              <div
                className={classes.totalContainer}
                data-testid="cart-total-container"
              >
                <div className={classes.totalPrice}>
                  {t("OrderTaking.TOTAL_ORDER_PRICE")}
                </div>
                <div
                  className={classes.totalPriceValue}
                  data-testid="cart-total-container"
                >
                  <Data
                    data-testid="total-value-data"
                    value={CartItemState.cart.total?.toFixed(2)}
                    type="currency"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Price;
