import React, { FunctionComponent } from "react";
import TableCellBase from "@material-ui/core/TableCell";
import { Tooltip, withStyles } from "@material-ui/core";
import Data from "../../data/Data";
import CollapsibleTableStyles from "../CollapsibleTableStyles";

interface Props {
  text: string | number;
  head?: boolean;
  className?: string; // To build a class with hight priority use "th&" selector https://sass-lang.com/documentation/style-rules/parent-selector
  color?: string;
  type?: "text" | "currency" | "number" | "date";
  testId: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customStyle?: any;
  timeZone?: boolean;
}

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#505050",
    borderRadius: "2px",
    bottom: "-40px",
    color: "#fff",
    content: "attr(title)",
    left: "-0px",
    position: "absolute",
    height: "34px",
    padding: "12px",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.4)",
    textAlign: "center",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: 1.33,
  },
}))(Tooltip);

const TableCellCustom: FunctionComponent<Props> = ({
  text,
  head,
  color,
  className,
  type,
  testId,
  title,
  customStyle,
  timeZone,
}) => {
  const classes = CollapsibleTableStyles();

  const getCellClasses = () => {
    let cellClasses = `${classes.tableItemCell} ${className}`;
    if (head) {
      cellClasses = `${cellClasses} ${classes.tableHeaderCell}`;
    }
    return cellClasses;
  };

  return (
    <LightTooltip title={title}>
      <TableCellBase
        align={type === "currency" ? "right" : "center"}
        classes={{ root: getCellClasses() }}
        style={{ color, ...customStyle }}
        data-testid={`${testId}-table-cell`}
      >
        <Data
          testId={`${testId}-number`}
          value={text}
          type={type}
          timeZone={timeZone}
        />
      </TableCellBase>
    </LightTooltip>
  );
};

TableCellCustom.defaultProps = {
  className: "",
  head: false,
  color: "",
  type: "text",
  timeZone: false,
};

export default TableCellCustom;
