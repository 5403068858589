import { RedeemItem } from "../../../domains/redeem/Redeem";
import RedeemItemsDb from "./RedeemItemsDb";

interface IRedeemItemQuery {
  getAll(): Promise<RedeemItem[]>;
}

class RedeemItemsQueryDb implements IRedeemItemQuery {
  private db: RedeemItemsDb;

  constructor(db: RedeemItemsDb) {
    if (db == null) {
      throw new Error("db cannot be null");
    }
    this.db = db;
  }

  async getAll(): Promise<RedeemItem[]> {
    const resultQuery = await this.db.redeemItems.orderBy("points").toArray();

    const items: Array<RedeemItem> = resultQuery.map(
      (item) => <RedeemItem>{ ...item },
    );

    return items;
  }
}

export default RedeemItemsQueryDb;
