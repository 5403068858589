import React from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import backDropLoadStyles from "./BackDropLoadStyles";

interface Props {
  isLoading: boolean;
}

function BackDropLoad({ isLoading }: Props): React.ReactElement {
  const classes = backDropLoadStyles();
  return (
    <Backdrop open={isLoading} className={classes.BackDrop}>
      <CircularProgress
        disableShrink
        size={70}
        classes={{ root: classes.circular }}
      />
    </Backdrop>
  );
}

export default BackDropLoad;
