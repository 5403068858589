import { RecommendedMarketplaceItemResponse } from "../../domains/RecommendedMarketplaceProducts";
import { Product } from "../../domains/products/Product";
import MarketplaceStore from "../../stores/marketplace/MarketplaceStore";
import CampaignsStore from "../../stores/campaigns/CampaignsStore";
import GlobalStore from "../../stores/global/GlobalStore";
import { hasMarketplace } from "../../config/utils/functions";

import PromotionsStore from "../../stores/promotions/PromotionsStore";

export const RecommendedMarketplaceProductsConverter = (
  data: RecommendedMarketplaceItemResponse[],
): Product[] => {
  const { marketplaceList } = MarketplaceStore.getState();
  const { focus } = CampaignsStore.getState();
  const { promotions } = PromotionsStore.getState();

  const {
    user: { zone },
  } = GlobalStore.getState();

  const focusFiltered = focus.flatMap((each) => {
    return each.campaignProducts.filter((product) =>
      hasMarketplace(product.sku, marketplaceList),
    );
  });

  const formatedItems = focusFiltered.map((each) => {
    const filteredPromotionBySku = promotions.filter(
      (promotion) => promotion.sku === each.sku,
    )[0];
    return {
      id: each.id,
      pricePerUoM: each.pricePerUoM,
      platformId: each.itemPlatformId,
      palletQuantity: each.palletQuantity,
      inventoryCount: each.inventoryCount,
      availabity: {
        count: each.availabity?.count,
        source: each.availabity?.source,
      },
      sku: each.sku,
      name: each.productName,
      stock: each.stock,
      unitPrice: each.unitPrice,
      image: each.image,
      priority: null,
      country: zone,
      isFocus: true,
      isRecommended: false,
      isPromotion: promotions.some((promo) => each.sku === promo.sku),
      promotion: filteredPromotionBySku,
    };
  });
  const result = data.map((item) => {
    const filteredPromotionBySku = promotions.filter(
      (promotion) => promotion.sku === item.sku,
    )[0];
    return {
      ...item,
      platformId: item.itemPlatformId,
      isFocus: false,
      isRecommended: true,
      isPromotion: promotions.some((promo) => item.sku === promo.sku),
      promotion: filteredPromotionBySku,
    };
  });

  const recommendedProducts: Product[] = [...result, ...formatedItems].reduce(
    (acc: Product[], value) => {
      const valueIndex = acc.findIndex(
        (item: Product) => item.sku === value.sku,
      );
      if (valueIndex === -1) {
        return acc.concat([value]);
      }
      acc[valueIndex] = {
        ...acc[valueIndex],
        isFocus: true,
        isRecommended: true,
      };
      return acc;
    },
    [],
  );

  return recommendedProducts.splice(0, 20);
};
