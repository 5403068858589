import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { DEFAULT_LANGUAGE } from "@i18n/domains/LanguageOptions";
import { getVendorName } from "grow-shared-services";
import { TIMEZONE } from "../../../config/timeZone";
import { getUserInfos } from "../../../config/utils/functions";
import SearchResponseToSearchConverter from "../../../converters/search/SearchResponseToSearchConverter";
import GlobalAdminConfigStore from "../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "../../../stores/global/GlobalStore";
import VendorStore from "../../../stores/vendor/VendorStore";
import { getApiHost, getEndPoint } from "../../host/HostB2BService";
import { getStoreIdHost } from "../../host/HostStoreId";
import { SearchDomain, SearchRequest, SearchResponse } from "../domains/Search";
import axiosInstance from "../../../config/axios/axiosInstance";

export const SEARCH_V2 = "/search/v2";

/* istanbul ignore next */
axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function SearchCatalog(request: SearchRequest): Promise<SearchDomain> {
  const log = useLogService();
  const endpoint = `${getApiHost()}${getEndPoint()}${SEARCH_V2}`;

  const { vendor } = VendorStore.getState();
  const { userConfig } = GlobalAdminConfigStore.getState();
  const {
    user: { zone },
  } = GlobalStore.getState();

  const { country } = getUserInfos();

  const storeId = getStoreIdHost(
    country,
    getVendorName(vendor?.id ?? userConfig.vendorId),
  );

  const payload = {
    accountId: request.accountId,
    storeId,
    page: request.page,
    pageSize: request.pageSize,
    query: request.valueTyped,
    vendorIds: [request.vendorId],
    projections: [
      "PRICE",
      "AVAILABILITY",
      "VARIANTS",
      "ASSORTMENT",
      "SINGLE_PROMOTION",
    ],
  };

  return axiosInstance
    .post<SearchResponse>(endpoint, payload, {
      headers: {
        originSystem: "WEB",
        "Accept-Language": TIMEZONE[zone]?.locale ?? DEFAULT_LANGUAGE,
      },
    })
    .then((response) => {
      log.info(`Convert Search received from ${endpoint} to our model`);
      return SearchResponseToSearchConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
