import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    drawerModal: {
      "& .tlp-MuiBackdrop-root": {
        backgroundColor: "transparent",
      },

      "& .admin-portal-tlp-beta-mfe-MuiDrawer-paperAnchorRight": {
        width: "433px",
        padding: "24px",
        boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
      },
    },
    titleSection: {
      display: "flex",
      alignItems: "center",

      "& > button": {
        marginRight: "16px",
      },
    },
    contentSection: {
      marginTop: "20px",
    },
  }),
);
