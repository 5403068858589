import React from "react";
import { Typography } from "@material-ui/core";
import Data from "../data/Data";
import { ProductCardV3Styles } from "../ProductCardV3/ProductCardV3Styles";

interface Props {
  renderNewItemPriceNormalDiscount: boolean;
  productPrice: number;
  sku: string;
  originalPrice: number | undefined;
  shouldRenderOriginalPrice: boolean;
}

const ItemOriginalPriceWithNormalDiscount: React.FC<Props> = ({
  renderNewItemPriceNormalDiscount,
  productPrice,
  sku,
  originalPrice,
  shouldRenderOriginalPrice,
}) => {
  const classes = ProductCardV3Styles();

  const renderOriginalPrice = () => {
    if (shouldRenderOriginalPrice)
      return (
        <Data
          type="currency"
          className={classes.recommendedCardInformationLineThrough}
          value={originalPrice ?? productPrice}
          testId={`${sku}-original-price-value`}
        />
      );

    return null;
  };

  if (productPrice != null && renderNewItemPriceNormalDiscount) {
    return (
      <Typography
        variant="subtitle1"
        className={classes.strikethroughPriceText}
        data-testid={`${sku}-strikethrough-price-text`}
      >
        <Data
          type="currency"
          className={classes.recommendedCardInformationLineThrough}
          value={originalPrice}
          testId={`${sku}-original-price-value`}
        />
      </Typography>
    );
  }

  return (
    renderOriginalPrice() && (
      <Typography
        variant="subtitle1"
        className={classes.strikethroughPriceText}
        data-testid={`${sku}-strikethrough-price-text`}
      >
        {renderOriginalPrice()}
      </Typography>
    )
  );
};

export default ItemOriginalPriceWithNormalDiscount;
