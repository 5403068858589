import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";
import {
  PaymentMethodInfoItem,
  PaymentMethodInfoStore,
} from "@domains/paymentMethodInfo/PaymentMethodInfo";

import PaymentMethodInfoConverter from "../../converters/paymentMethodInfo/PaymentMethodInfoResponseToPaymentMethodInfoStore";
import { getApiHost, getEndPoint } from "../host/HostB2BService";
import { getUserInfos } from "../../config/utils/functions";
import { getLanguageByZone } from "../../i18n/domains/LanguageOptions";

const PAYMENT_METHOD_SERVICE = "/payment-method-service/v2/checkout";

export function getPaymentMethod(
  accountId: string,
  vendorId: string,
): Promise<PaymentMethodInfoStore> {
  const log = useLogService();
  const { country } = getUserInfos();

  const endpoint = `${getApiHost()}${getEndPoint()}${PAYMENT_METHOD_SERVICE}`;

  const { label: languageLabel } = getLanguageByZone(country);

  const language = `${languageLabel.toLowerCase()}-${country}`;

  return axios
    .get<PaymentMethodInfoItem[]>(
      `${endpoint}?accountId=${accountId}&vendorId=${vendorId}`,
      {
        headers: { "Accept-Language": language },
      },
    )
    .then((response) => {
      log.info(
        `Convert PaymentMethodInfoItem items received from ${endpoint} to our model`,
      );
      return PaymentMethodInfoConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
