import { CreditResponse } from "../../services/credit/domains/CreditResponse";
import { Credit } from "../../domains/Credit";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function CreditResponseToCreditConverter(
  data: CreditResponse,
): Credit {
  return {
    accountId: data[0]?.accountId || "",
    balance: data[0]?.balance || 0,
    overdue: data[0]?.overdue || 0,
    available: data[0]?.available || 0,
    paymentTerms: data[0]?.paymentTerms || "",
    total: data[0]?.total || 0,
    consumption: data[0]?.consumption || 0,
    updatedAt: data[0]?.updatedAt || "",
    availableValue:
      data[0]?.available && data[0]?.consumption
        ? data[0]?.available - data[0]?.consumption
        : 0,
  };
}
