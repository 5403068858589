import axios from "@config/axios/axiosInstance";
import { useLogService } from "admin-portal-shared-services";
import qs from "qs";
import { RewardsChallengeState } from "domains/rewardsChallenges/RewardsChallenges";
import { PROGRAMS_COMBOS_NOT_FOUND } from "@config/errorCodes";
import { getApiHost, getEndPoint } from "../../host/HostB2BService";
import { RewardsChallengesResponse } from "../domain/RewardsChallengesResponse";
import RewardsChallengesConverter from "../../../converters/rewardsChallenges/RewardsChallengesResponseToRewardsChallengesStore";

export const LOYALTY_BUSINESS_SERVICE = "/loyalty-business-service";

export const CHALLENGES_ENDPOINT_V2 = "v2/challenges/accounts";
const FIRST_PAGE = 0;
const MAX_PAGE_SIZE = 99999;

export function getRewardsChallengeV2(
  accountId: string,
  vendorId: string,
  executionMethods?: string[],
  page = FIRST_PAGE,
  pageSize = MAX_PAGE_SIZE,
): Promise<RewardsChallengeState> {
  const log = useLogService();

  const endpoint = `${getApiHost()}${getEndPoint()}${LOYALTY_BUSINESS_SERVICE}`;

  /* istanbul ignore next */
  return axios
    .get<RewardsChallengesResponse>(
      `${endpoint}/${CHALLENGES_ENDPOINT_V2}/${accountId}`,
      {
        params: {
          vendorIds: vendorId,
          executionMethod: executionMethods,
          page,
          pageSize,
        },
        paramsSerializer: (params) => {
          /* istanbul ignore next */
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      },
    )
    .then((response) => {
      log.info(
        `Convert RewardsChallenge items received from ${endpoint} to our model`,
      );
      return RewardsChallengesConverter(response.data.content);
    })
    .catch((error) => {
      const code = error?.response?.data?.code;

      if (code === PROGRAMS_COMBOS_NOT_FOUND) {
        return Promise.resolve({
          completed: 0,
          failed: 0,
          started: 0,
          rewardsChallengeInformation: [],
        });
      }

      log.error(error);
      throw error;
    });
}
