import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    root: {
      width: 36,
      height: 22,
      padding: 0,
      display: "flex",
      alignItems: "center",
    },
    switchBase: {
      padding: 1,
      "&.Mui-checked": {
        transform: "translateX(15px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#1d8445",
          opacity: 1,
          border: "none",
        },
      },
      "&.focusVisible $thumb": {
        color: "#1d8445",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 11,
      backgroundColor: "#B2B2B2",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
  }),
);
