import LastContacts from "../../domains/lastContacts/LastContacts";
import LastContactsResponse from "../../services/lastContacts/domains/LastContactsResponse";

export default function LastContactsResponseToLastContactsConverter(
  items: Array<LastContactsResponse>,
): Array<LastContacts> {
  const lastContacts: Array<LastContacts> = [];

  items.forEach((item) => {
    const lastContact: LastContacts = {
      endCallDate: item.endCallDate,
      endCallTime: item.endCallTime,
      callTypification: item.callTypification || "",
      typificationDescription: item.typificationDescription,
      agentId: item.agentId,
      missions: item.missions,
      orderInfo: item.orderInfo,
    };

    lastContacts.push(lastContact);
  });

  return lastContacts;
}
