import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    marketplaceSwithContainer: {
      display: "flex",
      marginTop: "17px",
      marginBottom: "18px",
    },
    marketplaceSwithLabel: {
      fontSize: "14px",
      fontFamily: "Work Sans",
      marginRight: "8px",
    },
  }),
);
