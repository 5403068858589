import Dexie from "dexie";
import NewOfferings from "../../../domains/newOfferings/NewOfferings";

export type NewOfferingsTable = Dexie.Table<NewOfferings>;

class NewOfferingsDb extends Dexie {
  newOfferings: Dexie.Table<NewOfferings>;

  constructor() {
    super("NewOfferingsDb");

    this.version(1).stores({
      newOfferings: "sku,name,price,quantity,score,image,total,platformId",
    });

    this.newOfferings = this.table("newOfferings");
  }
}

export default NewOfferingsDb;
