import { createEffect, createEvent } from "effector";
import {
  execute,
  executeV3Multiple,
} from "../../usecase/catalog/CatalogUseCase";

export const getCatalogEffect = createEffect({
  async handler(accountId: string) {
    return execute(accountId);
  },
});

export const getCatalogV3MultipleEffect = createEffect({
  async handler(accountId: string) {
    return executeV3Multiple(accountId);
  },
});

export const clearCatalog = createEvent("clearCatalog");

export const setHasBeenAddedToDb = createEvent<boolean>("setHasBeenAddedToDb");
