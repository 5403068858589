import { createEffect, createEvent } from "effector";
import { execute } from "../../usecase/newOfferings/NewOfferingsUseCase";
import NewOfferingsRequest from "../../services/newOfferings/domains/NewOfferingsRequest";
import NewOfferingsDomain from "../../domains/newOfferings/NewOfferings";

export const getNewOfferingsEffect = createEffect({
  async handler(newOfferingsRequest: NewOfferingsRequest) {
    return execute(newOfferingsRequest);
  },
});

export const clearNewOfferings = createEvent("clearNewOfferings");

export const setHasBeenAddedToDb = createEvent<boolean>("setHasBeenAddedToDb");

export const setOfferings = createEvent<NewOfferingsDomain[]>("setOfferings");
