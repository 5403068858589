import React from "react";
import { MissionsCardSkeleton } from "./MissionsCardSkeleton";
import MissionsListStyles from "./MissionsListStyles";

export const MissionsListSkeleton = (): JSX.Element => {
  const classes = MissionsListStyles();
  return (
    <div className={classes.container}>
      <MissionsCardSkeleton />
      <MissionsCardSkeleton />
      <MissionsCardSkeleton />
      <MissionsCardSkeleton />
    </div>
  );
};
