import { createStyles, makeStyles } from "@material-ui/core";

const useTooltipStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      width: 210,
      color: "rgba(0,0,0,0.55)",
      fontSize: 12,
      background: "#FFFFFF",
      borderRadius: 6,
      boxShadow: "2px 2px 10px 0px rgba(0,0,0,0.1)",
      padding: 16,
    },
  }),
);

export { useTooltipStyles };
