import React, { useState, useMemo, useEffect } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Divider } from "@material-ui/core";
import PaymentMethodInfoStore from "../../../../../stores/paymentsInformation/PaymentMethodInfoStore";
import { setHighlightTitle } from "../../../../../stores/cart/CartItemEvents";
import CreditStore from "../../../../../stores/credit/CreditStore";
import OrderSummary from "../../orderSummary/OrderSummary";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import CartStore from "../../../../../stores/cart/CartStore";
import CartItemStore from "../../../../../stores/cart/CartItemStore";
import PocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import CartComboStore from "../../../../../stores/cartCombo/CartComboStore";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_DELIVERY_WINDOW_VALIDATION,
  TLP_DELIVERY_WINDOW_VALIDATION_ADMIN,
  TLP_ORDER_ITEM_OUT_STOCK,
  TLP_ORDER_ITEM_OUT_STOCK_ADMIN,
  TLP_PALLET_ITEMS,
  TLP_PALLET_ITEMS_ADMIN,
  TLP_EMPTIES_CHECKOUT,
  TLP_EMPTIES_CHECKOUT_ADMIN,
  TLP_CREDIT_LIMIT,
  GROW_CHECKOUT_V3,
  GROW_WIP_FREE_GOODS,
  GROW_CART_SERVICE_V4,
} from "../../../../../config/featureToggles";
import PriceArea from "../priceArea/PriceArea";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import PlaceOrderButton from "../placeOrderButton/PlaceOrderButton";
import Tab from "../../../../../components/cartTab/Tab";
import Tabs from "../../../../../components/cartTab/Tabs";

import OrderCartStyles from "../OrderCartStyles";
import { getCatalogEffect } from "../../../../../stores/catalog/CatalogEvents";
import {
  checkoutV2Effect,
  checkoutV3Effect,
  showErrorMessage,
} from "../../../../../stores/checkout/CheckoutEvents";
import DeliveryWindowsStore from "../../../../../stores/deliveryWindows/DeliveryWindowsStore";
import { DELIVERY_METHODS } from "../../../../../domains/fulfillment/Fulfillment";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import {
  orderCartTabInteraction,
  errorMessageViewed,
} from "../../../../../config/typewriter";
import { paymentMethodsEnum } from "../../paymentMethod/PaymentMethodsEnum";
import Empties from "../../orderSummary/empties/Empties";
import PalletTab from "./components/palletTab/PalletTab";
import { useUpdateCartHook } from "../hooks/useUpdateCartHook";
import { useCartFreeGoodsHook } from "../hooks/useCartFreeGoodsHook";

const CartSummary = ({
  updateCart,
  needUpdate,
  sendRequestToPlaceOrder,
  title,
}): JSX.Element => {
  const { user } = useStore(GlobalStore);
  const CartStates = useStore(CartStore);
  const CartItemStates = useStore(CartItemStore);
  const { pocInformation } = useStore(PocInformationStore);
  const { cartComboItems } = useStore(CartComboStore);
  const AgentCallStates = useStore(AgentCallStore);
  const { credit } = useStore(CreditStore);
  const { deliveryMethod, deliveryRange, deliveryWindows } =
    useStore(DeliveryWindowsStore);
  const paymentMethodStore = useStore(PaymentMethodInfoStore);
  const { isCartLoading } = useUpdateCartHook();
  const { hasItemsToAdd } = useCartFreeGoodsHook();

  const { dispatchGenericEvent } = useAnalytics();
  const classes = OrderCartStyles();

  const { t } = useTranslation();

  const [shouldOpenConfirmationModal, setShouldOpenConfirmationModal] =
    useState(false);

  const [shouldLoadPriceArea, setShouldLoadPriceArea] = useState(false);

  /* istanbul ignore next */
  useEffect(() => {
    if (CartItemStates.suggestedOrdersLoaded && CartItemStates.promoItensLoaded)
      setShouldLoadPriceArea(true);
  }, [CartItemStates.suggestedOrdersLoaded, CartItemStates.promoItensLoaded]);

  // ------------------- Feature Toggle ------------------------

  const isDeliveryWindowEnabled = isFeatureEnabled(
    TLP_DELIVERY_WINDOW_VALIDATION,
    TLP_DELIVERY_WINDOW_VALIDATION_ADMIN,
    user?.keyToggle,
  );

  const isOrderItemOutStock = isFeatureEnabled(
    TLP_ORDER_ITEM_OUT_STOCK,
    TLP_ORDER_ITEM_OUT_STOCK_ADMIN,
    user.keyToggle,
  );

  const isPalletEnabled = isFeatureEnabled(
    TLP_PALLET_ITEMS,
    TLP_PALLET_ITEMS_ADMIN,
    user.keyToggle,
  );

  const isEmptiesEnabled = isFeatureEnabled(
    TLP_EMPTIES_CHECKOUT,
    TLP_EMPTIES_CHECKOUT_ADMIN,
    user.keyToggle,
  );

  const isCreditLimitEnabled = isFeatureEnabled(
    TLP_CREDIT_LIMIT,
    user.keyToggle,
  );

  const isCheckoutV3Enable = isFeatureEnabledV2(
    GROW_CHECKOUT_V3,
    user.keyToggle,
  );

  const isGrowWipFreeGoodsEnable = isFeatureEnabledV2(
    GROW_WIP_FREE_GOODS,
    user.keyToggle,
  );

  const isDeliveryWindowsMultiContractEnabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    user.keyToggle,
  );

  // ------------------- End Feature Toggle ------------------------

  // --------------- Loadings ---------------------

  /* istanbul ignore next */
  const checkoutEffectVersion = isCheckoutV3Enable
    ? checkoutV3Effect
    : checkoutV2Effect;

  /* istanbul ignore next */
  useEffect(() => {
    const unWatchGetCatalogFail = getCatalogEffect.fail.watch(() => {
      setShouldLoadPriceArea(true);
    });

    /* istanbul ignore next */
    return () => {
      unWatchGetCatalogFail();
    };
  }, []);

  const loadingUpdateCart = isCartLoading();

  const loadingPlaceOrder = useStore(checkoutEffectVersion.pending);

  const validateUnavailableitems = CartItemStates.cartItems.some((item) => {
    const displayInventoryCountOrAvailabilityCount =
      item.availabilityCount || item.inventoryCount || 0;

    return (
      displayInventoryCountOrAvailabilityCount === 0 ||
      (displayInventoryCountOrAvailabilityCount !== -1 &&
        item.itemQuantity > displayInventoryCountOrAvailabilityCount)
    );
  });

  /* istanbul ignore next */
  const shouldSubmitButtonBeDisabled: boolean = useMemo(() => {
    const loadingCart = loadingUpdateCart || loadingPlaceOrder || needUpdate;

    const paymentMethodNotLoaded =
      !paymentMethodStore.done || paymentMethodStore.error;

    const missingCreditLimit =
      isCreditLimitEnabled &&
      credit?.availableValue &&
      CartItemStates?.cart?.total &&
      CartItemStates?.cart?.total > credit?.availableValue;

    const isDeliveryWindowEmpty = isDeliveryWindowsMultiContractEnabled
      ? deliveryWindows?.length === 0 || !deliveryWindows
      : pocInformation?.deliveryWindows?.length === 0 ||
        !pocInformation?.deliveryWindows;

    const missingDeliveryWindow =
      isDeliveryWindowEnabled &&
      deliveryMethod === DELIVERY_METHODS.DELIVERY_WINDOW &&
      isDeliveryWindowEmpty;

    const notFoundItemsInCart =
      CartItemStates?.cartItems?.filter((each) => {
        return each.notFound === false;
      }).length === 0 && cartComboItems?.length === 0;

    const deliveryRangeMissing =
      deliveryMethod === DELIVERY_METHODS.DELIVERY_RANGE &&
      (!deliveryRange?.minDays || !deliveryRange?.maxDays);

    const callNotStarted = !AgentCallStates?.startCall;

    const hasUnavailableItems = isOrderItemOutStock && validateUnavailableitems;

    return !!(
      paymentMethodNotLoaded ||
      loadingCart ||
      missingCreditLimit ||
      missingDeliveryWindow ||
      notFoundItemsInCart ||
      deliveryRangeMissing ||
      hasUnavailableItems ||
      callNotStarted
    );
  }, [
    loadingUpdateCart,
    loadingPlaceOrder,
    needUpdate,
    isCreditLimitEnabled,
    credit?.availableValue,
    CartItemStates?.cart?.total,
    CartItemStates?.cartItems,
    isDeliveryWindowEnabled,
    deliveryMethod,
    deliveryWindows,
    pocInformation?.deliveryWindows,
    cartComboItems?.length,
    deliveryRange?.minDays,
    deliveryRange?.maxDays,
    AgentCallStates?.startCall,
    isOrderItemOutStock,
    validateUnavailableitems,
    paymentMethodStore.done,
    paymentMethodStore.error,
  ]);
  // ----------- End Loadings --------------------

  // ------------ Submit ---------------------------
  const handleConfirmation = (value: boolean) => {
    setShouldOpenConfirmationModal(value);
  };

  const paymentTermIsValid = (value: React.ReactText) => {
    /* istanbul ignore next */
    if (
      (CartStates.cartForm.paymentMethod === paymentMethodsEnum.BANK_SLIP ||
        CartStates.cartForm.paymentMethod ===
          paymentMethodsEnum.BANK_SLIP_INSTALLMENT) &&
      (value === 0 || value === undefined)
    ) {
      showErrorMessage([
        { type: "error", message: t("OrderTaking.PAYMENT_TERM_REQUIRED") },
      ]);

      return false;
    }

    return true;
  };

  const poNumberIsInvalid = (value: string | undefined) => {
    return pocInformation.hasPONumberRequirement && !value;
  };

  const handleSubmit = (technicalProblem: boolean, orderReason: string) => {
    handleConfirmation(false);
    if (poNumberIsInvalid(CartStates.cartForm.poNumber)) {
      showErrorMessage([
        { type: "error", message: t("OrderTaking.PURCHASE_ORDER_REQUIRED") },
      ]);
    } else if (
      CartStates.cartForm.paymentMethod === paymentMethodsEnum.CREDIT
    ) {
      if (
        pocInformation.totalCredit === undefined ||
        pocInformation.totalCredit === null
      ) {
        showErrorMessage([
          { type: "error", message: t("OrderTaking.CREDIT_NOT_AVAILABLE") },
        ]);
      } else if (
        CartItemStates.cart.total &&
        CartItemStates.cart.total <= pocInformation.totalCredit
      ) {
        sendRequestToPlaceOrder(technicalProblem, orderReason);
      } else {
        showErrorMessage([
          {
            type: "error",
            message: t("OrderTaking.POC_DONT_HAVE_CREDIT_ENOUGHT"),
          },
        ]);
      }
    } else if (paymentTermIsValid(CartStates.cartForm.paymentTerm)) {
      sendRequestToPlaceOrder(technicalProblem, orderReason);
    }
  };

  const verifyFreeGoods = () => {
    if (isGrowWipFreeGoodsEnable) {
      if (hasItemsToAdd) {
        setHighlightTitle(true);
        dispatchGenericEvent(errorMessageViewed, {
          error_message: t("OrderTaking.FREE_GOODS_SECTION.FREE_GOODS_WARNING"),
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
        setShouldOpenConfirmationModal(false);
      } else {
        setShouldOpenConfirmationModal(true);
      }
    } else {
      setShouldOpenConfirmationModal(true);
    }
  };

  // -------------- End Submit --------------------------------------

  const handleAnalytics = (tabLabel: string) => {
    dispatchGenericEvent(orderCartTabInteraction, {
      call_id: AgentCallStates.callId,
      tab_label: tabLabel,
    });
  };

  const renderPalletTab = () => {
    if (!isPalletEnabled) return <></>;
    return (
      <Tab
        title={t("OrderTaking.SHIPPING_SETUP")}
        onClick={() => handleAnalytics(t("OrderTaking.SHIPPING_SETUP"))}
        data-testid="order-cart-pallet-tab-button"
      >
        <Divider orientation="horizontal" data-testid="pallet-divider" />
        <PalletTab />
      </Tab>
    );
  };

  const renderEmptiesTab = (): JSX.Element => {
    if (!isEmptiesEnabled) {
      return <></>;
    }

    return (
      <Tab
        title={t("Empties.TITLE")}
        onClick={() => handleAnalytics(t("Empties.TITLE"))}
        data-testid="order-cart-Empty-tab-button"
      >
        <Divider orientation="horizontal" data-testid="empties-divider" />
        <Empties />
      </Tab>
    );
  };

  return (
    <Tabs>
      <Tab
        title={title}
        onClick={() => handleAnalytics(t("OrderTaking.ORDER_SUMMARY_LABEL"))}
        data-testid="order-cart-summary-tab-button"
      >
        <Divider
          orientation="horizontal"
          className={classes.orderSummaryDivider}
          data-testid="order-summary-divider"
        />
        <OrderSummary />
        {shouldLoadPriceArea ? (
          <PriceArea
            needUpdate={needUpdate}
            loadingUpdateCart={loadingUpdateCart}
            updateCart={updateCart}
            cartLength={CartItemStates.cartItems.length}
            data-testid="priceArea"
          />
        ) : (
          <div className={classes.circularProgressWrapper}>
            <CircularProgress
              data-testid="circularProgress"
              size={20}
              disableShrink
            />
          </div>
        )}
        <ConfirmationModal
          open={shouldOpenConfirmationModal}
          loading={loadingPlaceOrder}
          onClose={() => handleConfirmation(false)}
          onSubmit={handleSubmit}
          data-testid="confirmationModal"
        />
        <div className={classes.buttonContainer}>
          <PlaceOrderButton
            loading={loadingPlaceOrder}
            disabled={shouldSubmitButtonBeDisabled}
            onClick={() => verifyFreeGoods()}
          />
        </div>
      </Tab>
      {renderPalletTab()}
      {renderEmptiesTab()}
    </Tabs>
  );
};

export default React.memo(CartSummary);
