import React, { useState, useEffect } from "react";
import { useStore } from "effector-react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { v4 as guid } from "uuid";
import {
  isFeatureEnabledV2,
  GROW_ORGANIZATION_ACTIVATION,
  TLP_CAMPAIGN_FOCUS_VENDOR,
  TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
} from "@config/featureToggles";
import { getVendorName } from "grow-shared-services";
import GlobalStore from "@stores/global/GlobalStore";
import { callUxButtonClicked } from "@config/typewriter";
import { ANALYTICS_ROUTE_NAMES } from "@config/constants";
import CallTabStore from "@stores/navigation/callTab/CallTabStore";
import CampaignsStore from "@stores/campaigns/CampaignsStore";
import ErrorHandlerRetry from "@components/errorHandlerRetry/ErrorHandlerRetry";
import { getCampaigns } from "@stores/campaigns/CampaignsEvents";
import pocInformationStore from "@stores/callList/pocInformation/PocInformationStore";
import GlobalAdminConfigStore from "@stores/globaAdminConfig/GlobalAdminConfigStore";
import Loading from "@components/loadingView/LoadingView";
import VendorStore from "@stores/vendor/VendorStore";
import CollapsibleTitle from "@components/collapsableTitle/CollapsibleTitle";
import FocusSkuIcon from "@assets/newIcons/FocusSkuIcon";
import CampaignsB2OProductsStore from "@stores/campaigns/campaignsB2OProducts/CampaignsB2OProductsStore";
import AgentCallStore from "@stores/agentCall/AgentCallStore";
import { getCampaignsB2OProducts } from "@stores/campaigns/campaignsB2OProducts/CampaignsB2OProductsEvents";
import MissionsProducts from "../missionsProducts/MissionsProducts";
import useGetCampaignsHook, { Campaign } from "./useGetCampaignsHook";
import Campaigns from "../campaigns/Campaigns";
import useCampaignsStyle from "./style";
import {
  getUserInfos,
  getValueOrNull,
  returnVendorId,
} from "../../../../config/utils/functions";

const CampaignsComponent: React.FC = () => {
  const {
    user: { zone, keyToggle },
  } = useStore(GlobalStore);
  const { error: productError, loading: productLoading } = useStore(
    CampaignsB2OProductsStore,
  );
  const { clientId, callId, isTypedPoc, pocCallOrigin } =
    useStore(AgentCallStore);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    null,
  );
  const [isCampaignOpen, setIsCampaignOpen] = useState(true);
  const { callTab } = useStore(CallTabStore);
  const classes = useCampaignsStyle();
  const { t } = useTranslation();
  const { orgId } = getUserInfos();

  const [campaignsProductsOpen, setCampaignsProductsOpen] = useState(true);
  const [campaigns, Items] = useGetCampaignsHook();
  const { error: campaignError, loading: campaignLoading } =
    useStore(CampaignsStore);
  const { pocInformation } = useStore(pocInformationStore);
  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { vendor } = useStore(VendorStore);

  const isCampaignFocusVendorEnabled = isFeatureEnabledV2(
    TLP_CAMPAIGN_FOCUS_VENDOR,
    keyToggle,
  );

  const isMultiContractCampaignServiceEnabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
    keyToggle,
  );

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
  );

  useEffect(() => {
    setSelectedCampaign(campaigns[0]);
  }, [campaigns]);

  const handleOpenCampaignsProducts = () => {
    const buttonName = campaignsProductsOpen
      ? "campaigns-component-collapse"
      : "campaigns-component-expand";
    const screenSection = "Campaign Products";
    const isExpanded = !campaignsProductsOpen;
    callEventCallUx(buttonName, screenSection, isExpanded);
    setCampaignsProductsOpen((prevState) => !prevState);
  };

  const onRetryHandler = () => {
    const vendorId = returnVendorId(userConfig.vendorId);
    const vendorName = getVendorName(userConfig.vendorId);

    getCampaignsB2OProducts({
      country: zone,
      accountId: clientId,
      tag: selectedCampaign?.campaignTag || "",
      vendorId,
      vendorName,
      isCampaignFocusVendorEnabled,
      isMultiContractCampaignServiceEnabled,
    });
  };

  const callEventCallUx = (
    buttonName: string,
    screenSection: string,
    isExpanded: boolean,
  ) => {
    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: buttonName,
      button_label: isExpanded ? "expand" : "collapse",
      screen_section: screenSection,
      filter_option: null,
      call_id: callId,
      order_date: null,
      position: null,
      mission: null,
    });
  };

  const reloadCampaigns = () => {
    const vendorId = returnVendorId(userConfig.vendorId);
    const vendorName = getVendorName(userConfig.vendorId);
    const bodyMultiContract = {
      DDC: pocInformation?.deliveryCenterId || "",
      segment: pocInformation?.segment || "",
      accountId: clientId,
      pocCallOrigin,
      isTypedPoc,
      vendorId:
        isMultiContractCampaignServiceEnabled || isOrganizationConfigEnabled
          ? vendor?.id
          : vendorId,
      vendorName:
        isMultiContractCampaignServiceEnabled || isOrganizationConfigEnabled
          ? undefined
          : vendorName,
      country: zone,
      isCampaignFocusVendorEnabled,
      isMultiContractCampaignServiceEnabled,
    };
    const bodyOrganization = {
      orgId,
      requestTraceId: guid(),
    };

    /* istanbul ignore next */
    getCampaigns({
      ...bodyMultiContract,
      ...bodyOrganization,
    });
  };

  if (campaignLoading)
    return (
      <Box className={classes.campaignContainer}>
        <Box className={classes.loadingContainer}>
          <Loading data-testid="campaigns-loading" />
        </Box>
      </Box>
    );

  if (campaignError)
    return (
      <div className={classes.errorContainer}>
        <ErrorHandlerRetry
          onClick={reloadCampaigns}
          screenName={ANALYTICS_ROUTE_NAMES.get(callTab)}
          screenSection="Campaigns"
        />
      </div>
    );

  return (
    <div style={{ flex: 1 }}>
      <div className={classes.campaignsContainer}>
        <Campaigns
          userZone={zone}
          campaigns={campaigns}
          selectedCampaign={selectedCampaign}
          isOpen={isCampaignOpen}
          setIsOpen={setIsCampaignOpen}
          setSelectedCampaign={setSelectedCampaign}
        />
      </div>

      <CollapsibleTitle
        icon={<FocusSkuIcon />}
        title={t("CAMPAIGNS.CAMPAIGN_PRODUCTS_TITLE")}
        testId="missions-campaigns-products-collapse"
        open={campaignsProductsOpen}
        handleOpen={handleOpenCampaignsProducts}
      >
        <MissionsProducts
          onRetryHandler={onRetryHandler}
          Items={Items}
          error={productError}
          Loading={productLoading}
          screenSection="Campaign Products"
        />
      </CollapsibleTitle>
    </div>
  );
};

export default React.memo(CampaignsComponent);
