/* istanbul ignore file */
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";

const RankTableStyles = makeStyles(() =>
  createStyles({
    container: { position: "relative" },
    errorContainer: {
      position: "absolute",
      right: 0,
      left: 0,
      top: "250%",
      textAlign: "center",
    },
    loading: {
      height: "268px",
      right: 0,
      left: 0,
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    phoneCall: {
      display: "flex",
      alignItems: "center",
    },
    phoneNumber: {
      fontSize: 14,
      color: "#212121",
    },
    icons: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
    },
    iconButton: {
      height: "32px",
      width: "32px",
      marginRight: 8,
      color: "rgba(255, 255, 255, 0.95) !important",
      backgroundColor: "#212121",
      "&:hover": {
        backgroundColor: "#212121",
      },
    },
    iconButtonDisabled: {
      backgroundColor: "#DFDEDE !important",
    },
    headerCell: {
      paddingLeft: "1rem",
    },
    type: {
      color: "#212121",
      fontSize: "14px",
      fontFamily: "Work Sans",
    },
    customCell: {
      padding: 0,
      paddingLeft: 16,
    },
  }),
);

const CustomHeaderCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.black,
    borderRight: "6px solid white",
  },
  body: {
    fontSize: 14,
    fontWeight: 600,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}))(TableRow);

const CustomBodyCell = withStyles(({ palette }) => ({
  root: {
    backgroundColor: palette.common.white,
    color: "#212121",
    height: 63,

    borderRight: "6px solid white",
  },
}))(TableCell);

export { CustomHeaderCell, CustomBodyCell, StyledTableRow, RankTableStyles };
