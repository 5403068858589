import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    searchTextField: {
      color: palette.secondary.main,
      "&.Mui-focused fieldset.tlp-MuiOutlinedInput-notchedOutline": {
        borderColor: palette.secondary.main,
        borderWidth: "1px",
        borderRadius: "100%",
      },
      borderRadius: "100%",
      height: "100%",
    },
    searchIconColor: {
      color: palette.secondary.main,
    },
    placeholder: {
      "::placeholder": {
        color: "red",
      },
    },
    searchInput: {
      flex: 1,
      color: "black",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.14,
      marginLeft: 10,
      width: "100%",
      "&::placeholder": {
        fontSize: "14 !important",
        color: "#707372 !important",
        fontFamily: "Work Sans",
        fontWeight: 500,
      },
    },
    searchInputBlocked: {
      cursor: "not-allowed",
      flex: 1,
      color: "black",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.14,
      marginLeft: 10,
      width: "100%",
      "&::placeholder": {
        fontSize: "14 !important",
        color: "#707372 !important",
        fontFamily: "Work Sans",
        fontWeight: 500,
      },
    },
    searchInputContainer: {
      display: "flex",
      marginBottom: "16px",
      height: "40px",
      padding: 5,
      borderRadius: "20px",
      backgroundColor: "#f2f2f2",
      border: 0,
      flex: 1,
      alignItems: "center",
      paddingLeft: 10,
    },
    searchIconContainer: {
      border: `1px solid ${palette.secondary.main}`,
      borderRadius: "100%",
    },
    searchClearContainer: {
      fontSize: "12px",
      backgroundColor: "#dfdede",
      width: 24,
      height: 24,
    },
  }),
);
