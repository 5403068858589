import React from "react";

function NewRewards() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <defs>
        <linearGradient id="NewRewards" x1="50%" x2="50%" y1="153.507%" y2="0%">
          <stop offset="0%" stopColor="#DCD4F4" />
          <stop offset="100%" stopColor="#F0ECFC" />
        </linearGradient>
      </defs>
      <g fill="none">
        <path
          fill="url(#NewRewards)"
          d="M6.16 41.095a.332.332 0 01-.29-.107C2.2 36.778 0 31.404 0 25.548c0-13.503 11.706-24.45 26.147-24.45 14.44 0 26.146 10.947 26.146 24.45 0 5.856-2.2 11.23-5.87 15.44-.07.08-.18.12-.29.107-6.297-.763-13.014-1.175-19.986-1.175-6.973 0-13.69.412-19.987 1.175z"
        />
        <path
          fill="#FF0"
          d="M28.839 20.04l3.569 1.131c.716.228 1.505.11 2.111-.312l3.02-2.108c.255-.178.616-.005.612.291l-.048 3.525c-.01.706.354 1.373.97 1.78l3.078 2.036c.259.171.195.546-.108.634l-3.599 1.046c-.721.21-1.287.739-1.511 1.414l-1.119 3.365c-.094.283-.495.343-.678.1l-2.176-2.877a2.353 2.353 0 00-1.905-.908l-3.768.046c-.317.003-.502-.335-.312-.573l2.254-2.824a2.035 2.035 0 00.335-1.975l-1.21-3.337c-.103-.281.184-.55.485-.454z"
        />
        <path
          fill="#8A48DB"
          d="M38.115 9.269l-26.327 4.34c-2.04-3.522-.999-7.91 2.441-10.296l.848-.588c.94-.652 2.248-.562 3.076.212l6.308 5.901 3.77-7.563C28.725.284 29.92-.22 31.043.092l1.012.281c4.105 1.142 6.688 4.934 6.06 8.896z"
        />
        <path
          fill="#C9ABFF"
          d="M38.115 9.269l-26.327 4.34c-1.92-3.317-1.11-7.403 1.866-9.86a3.119 3.119 0 00-.16.495c-.77 3.163 2.224 6.019 5.64 5.455l2.835-.467a4.346 4.346 0 012.345-1.173c.19-.032.377-.05.564-.058l3.353-6.726C28.725.283 29.92-.22 31.043.092l1.012.281c.103.029.206.06.307.092l-2.449 4.912c-.573 1.151.476 2.43 1.813 2.209l6.323-1.043a7.52 7.52 0 01.066 2.726z"
        />
        <path
          fill="#8A48DB"
          d="M29.26 11.297a3.72 3.72 0 01-.116 1.842c-.086.02-.174.037-.263.051-2.321.383-4.535-1.067-4.945-3.238a3.727 3.727 0 01.115-1.842c.087-.02.174-.037.263-.051 2.322-.383 4.536 1.066 4.945 3.238zM12.06 46.15a25.793 25.793 0 01-6.19-5.163V28.475c0-.826.716-1.497 1.6-1.497h6.19c-.884 0-1.6.67-1.6 1.497V46.15zm1.84-26.747l-.927-4.914c-.153-.814-.983-1.358-1.854-1.214l-6.323 1.042c-.87.144-1.452.92-1.298 1.734l.926 4.915c.154.814.984 1.357 1.855 1.214l6.322-1.043c.871-.143 1.452-.92 1.299-1.734z"
        />
        <path
          fill="#B476FF"
          d="M46.423 40.987C41.63 46.487 34.326 50 26.147 50c-6.326 0-12.127-2.1-16.649-5.596V28.475c0-.826.717-1.497 1.6-1.497h33.725c.884 0 1.6.67 1.6 1.497v12.512zm1.302-27.162L46.8 8.911c-.154-.814-.984-1.358-1.855-1.214L9 13.624c-.87.143-1.452.92-1.298 1.734l.926 4.914c.154.814.984 1.358 1.855 1.215l35.944-5.927c.87-.144 1.452-.92 1.298-1.735z"
        />
        <path
          fill="#C9ABFF"
          d="M31.63 18.122l-10.51 1.733a.107.107 0 01-.124-.081l-1.49-7.903c-.01-.055.029-.106.087-.116l10.51-1.733a.105.105 0 01.123.081l1.49 7.903c.01.054-.028.106-.086.116zM20.704 49.469c1.755.348 3.576.531 5.443.531 1.866 0 3.687-.183 5.442-.53V26.977c0-.055-.048-.1-.106-.1H20.81c-.058 0-.106.045-.106.1V49.47z"
        />
        <path
          fill="#FFB042"
          d="M33.412 27.01l-1.87.691c-.432.16-.773.479-.944.883l-.738 1.749c-.093.22-.427.22-.52 0l-.738-1.75a1.626 1.626 0 00-.944-.882l-1.87-.69a.255.255 0 010-.486l1.87-.69c.432-.16.774-.48.944-.883l.738-1.75c.093-.22.427-.22.52 0l.738 1.75c.17.404.512.723.944.882l1.87.69c.236.088.236.4 0 .487zm13.485-6.838l-1.384.511c-.32.118-.573.355-.699.653l-.546 1.295c-.069.163-.316.163-.385 0l-.546-1.295a1.203 1.203 0 00-.699-.653l-1.384-.51a.19.19 0 010-.36l1.384-.511c.32-.118.573-.355.7-.654l.545-1.294c.07-.163.316-.163.385 0l.546 1.294c.126.3.38.536.699.654l1.384.51a.19.19 0 010 .36z"
        />
      </g>
    </svg>
  );
}

export default NewRewards;
