import React, { FunctionComponent } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import classNames from "classnames";
import Data from "../data/Data";
import TableListViewStyle from "./TableListViewStyle";

interface Props {
  id: string;
  text?: string | number;
  className?: string; // To build a class with hight priority use "th&" selector https://sass-lang.com/documentation/style-rules/parent-selector
  color?: string;
  testId: string;
  colSpan?: number;
  style?: Record<string, unknown>;
  hideSortIcon?: boolean;
  sortHandler?: (id: string) => void;
  orderBy?: string;
  orderDirection?: "asc" | "desc";
}

const TableSortListCell: FunctionComponent<Props> = ({
  id,
  text,
  className,
  color,
  testId,
  colSpan,
  style,
  hideSortIcon = false,
  sortHandler,
  orderBy,
  orderDirection,
}) => {
  const classes = TableListViewStyle();

  const getCellClasses = (active) => {
    let baseClasses = `${classes.tableItemCell} ${className} ${classes.tableHeaderCell}`;
    if (active) {
      baseClasses += ` ${classes.tableHeaderCellActive}`;
    }
    return baseClasses;
  };

  return (
    <TableCell
      align="right"
      className={getCellClasses(orderBy === id)}
      style={{ ...style, color, flexDirection: "row" }}
      data-testid={`${testId}-table-cell`}
      colSpan={colSpan || 1}
    >
      <TableSortLabel
        data-testid={`${testId}-table-sort-cell`}
        active={orderBy === id}
        direction={orderBy === id ? orderDirection : "asc"}
        onClick={() => {
          if (sortHandler) {
            sortHandler(id);
          }
        }}
        hideSortIcon={hideSortIcon}
      >
        <Data
          value={text}
          className={classNames(classes.tableBaseText, classes.tableHeaderText)}
        />
      </TableSortLabel>
    </TableCell>
  );
};

TableSortListCell.defaultProps = {
  className: "",
  color: "",
};

export default TableSortListCell;
