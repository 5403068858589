import React, { useState } from "react";
import { useStore } from "effector-react";
import classNames from "classnames";
import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Collapse,
  Divider,
} from "@material-ui/core";
import Ellipsis from "react-ellipsis-text";
import ComboTag from "../../../../../components/comboTag/ComboTag";
import {
  removeItem,
  updateComboQuantity,
} from "../../../../../stores/cartCombo/CartComboEvents";
import DeleteIcon from "../../../../../assets/DeleteIcon";
import ChevronDown from "../../../../../assets/ChevronDown";
import ChevronUp from "../../../../../assets/ChevronUp";
import { CartComboItem as CartComboItemType } from "../../../../../domains/cart/CartComboItem";
import Data from "../../../../../components/data/Data";
import QuantityInput from "../../../../../components/quantityInput/QuantityInput";
import {
  CURRENCY,
  MAX_VALUE_INPUT,
  MIN_VALUE_INPUT,
} from "../../../../../config/constants";
import CartComboItemStyles from "./CartComboItemStyles";
import CartItemStyles from "../cartItem/CartItemStyles";
import {
  productRemoved,
  productQuantityEdited,
} from "../../../../../config/typewriter";
import {
  GROW_CART_SERVICE_V4,
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_CART_DISPLAY_COMBO_DESCRIPTION,
  TLP_CART_DISPLAY_COMBO_DESCRIPTION_ADMIN,
} from "../../../../../config/featureToggles";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";

interface Props {
  item: CartComboItemType;
  shouldUpdatePrice: () => void;
  position: number;
}

const CartComboItem: React.FunctionComponent<Props> = ({
  item,
  shouldUpdatePrice,
  position,
}) => {
  const classesCartCombo = CartComboItemStyles();
  const classesCartItem = CartItemStyles();
  const callStore = useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const isComboDisplayEnabled = isFeatureEnabled(
    TLP_CART_DISPLAY_COMBO_DESCRIPTION,
    TLP_CART_DISPLAY_COMBO_DESCRIPTION_ADMIN,
    user.zone,
  );

  const isCartSimulationV4Enabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    user.zone,
  );

  const sendRemoveItemEvent = () => {
    productRemoved({
      call_id: callStore.callId,
      country: callStore.countryCode,
      currency: CURRENCY[user.zone]?.currency,
      name: item.title,
      original_quantity: null,
      poc_id: callStore.clientId,
      quantity: item.itemQuantity,
      sku: item.id,
      is_out_of_stock: null,
      combo_id: item.id,
      is_combo: true,
      remove_method: "Remove Icon",
    });
  };

  const sendProductQuantityEdited = (value: number) => {
    productQuantityEdited({
      call_id: callStore.callId,
      country: user.zone,
      currency: CURRENCY[user.zone]?.currency,
      original_quantity: null,
      poc_id: callStore.clientId,
      quantity: value,
      screen_section: "Order Cart",
      is_out_of_stock: null,
      combo_id: item.id,
      is_combo: true,
      edit_method: "Arrow or Input",
    });
  };

  const renderIcon = () => {
    if (isCollapsed) return <ChevronUp />;
    return <ChevronDown />;
  };

  const renderUnityText = (productDescription: string | undefined) => {
    /* istanbul ignore next */
    if (productDescription) {
      const unityMatch = productDescription.match(/\d+[xX]\d+/);

      /* istanbul ignore next */
      if (unityMatch) {
        return `${unityMatch[0]}un`;
      }
    }

    return undefined;
  };

  return (
    <Grid>
      <Grid
        item
        container
        direction="row"
        className={classesCartItem.container}
      >
        <Grid item className={classesCartItem.quantityContainer}>
          <QuantityInput
            maxValue={item.limit?.daily || MAX_VALUE_INPUT}
            minValue={MIN_VALUE_INPUT}
            value={item?.itemQuantity}
            notFound={item.notFound}
            data-testid="cart-combo-item-input-quantity"
            onChange={(value) => {
              updateComboQuantity({
                ...item,
                itemQuantity: Number(value),
              });
              shouldUpdatePrice();
              sendProductQuantityEdited(Number(value));
            }}
          />
        </Grid>

        <Grid
          item
          container
          direction="column"
          className={classesCartCombo.title}
        >
          <Grid className={classesCartCombo.comboTitleContainer}>
            <Data
              value={item.title}
              className={classesCartCombo.comboItemNameEllipsis}
              hasTooltip={isComboDisplayEnabled}
            />
            {isComboDisplayEnabled && (
              <IconButton
                aria-label="toggle dropdown visibility"
                onClick={() => setIsCollapsed(!isCollapsed)}
                data-testid="collapse-button-combo-details"
                className={classesCartCombo.iconButton}
              >
                {renderIcon()}
              </IconButton>
            )}
          </Grid>

          <Grid className={classesCartCombo.tagPosition}>
            <Typography
              className={classesCartCombo.skuTitle}
              variant="subtitle1"
            >
              <Tooltip title={`${item.id}`}>
                <Ellipsis text={`${item.id}`} length={40} />
              </Tooltip>
            </Typography>
            <ComboTag />
          </Grid>
        </Grid>

        <Grid
          item
          container
          className={classesCartItem.cartItemPrice}
          alignItems="flex-start"
        >
          <Data
            value={item?.price.toFixed(2)}
            type="currency"
            className={classNames(classesCartItem.price)}
          />
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              removeItem({
                ...item,
                toggle: isCartSimulationV4Enabled,
                position,
              });
              shouldUpdatePrice();
              sendRemoveItemEvent();
            }}
            aria-label="delete"
            className={classNames(classesCartCombo.comboDeleteButton)}
            data-testid="delete-item"
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>

      {isComboDisplayEnabled && (
        <Grid
          direction="column"
          container
          className={classesCartCombo.comboContainer}
          data-testid="cart-combo-item-details"
        >
          {item.description && (
            <Data
              value={item.description}
              className={classesCartCombo.comboItemDescription}
              data-testid="cart-combo-item-details-description"
              hasTooltip
            />
          )}

          <Collapse in={isCollapsed} data-testid="cart-combo-products-collapse">
            {item.items.map((combo, index) => (
              <Box
                key={`cart-combo-products-collapse-container-${combo.id}`}
                data-testid={`cart-combo-products-collapse-container-${combo.id}`}
                className={classesCartCombo.comboProductContainer}
              >
                <Box className={classesCartCombo.comboProductContainerDetails}>
                  <Data
                    value={combo.title}
                    className={classesCartCombo.comboProductTitle}
                    data-testid={`cart-combo-products-collapse-product-title-${combo.id}`}
                    hasTooltip
                  />
                  {renderUnityText(combo.fullPackageDescription) && (
                    <Data
                      value={renderUnityText(combo.fullPackageDescription)}
                      className={classesCartCombo.comboProductDescription}
                      data-testid={`cart-combo-products-collapse-product-unity-${combo.id}`}
                    />
                  )}
                </Box>
                {item.items.length > 1 && index !== item.items.length - 1 && (
                  <Divider className={classesCartCombo.divider} />
                )}
              </Box>
            ))}
          </Collapse>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(CartComboItem);
