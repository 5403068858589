export const channels = {
  B2B_WEB: "B2B_WEB",
  B2B_APP: "B2B_APP",
  TELESALES: "TELESALES",
  CX_TLP: "CX_TLP",
  B2B_FORCE: "B2B_FORCE",
  OTHER: "OTHER",
  NOT_IDENTIFIED: "N/A",
};

export default [
  {
    value: channels.B2B_WEB,
    label: "WEB",
  },
  {
    value: channels.B2B_APP,
    label: "APP",
  },
  {
    value: channels.TELESALES,
    label: "TLS",
  },
  {
    value: channels.CX_TLP,
    label: "GROW",
  },
  {
    value: channels.B2B_FORCE,
    label: "FORCE",
  },
  {
    value: channels.OTHER,
    label: "OTR",
  },
  {
    value: channels.NOT_IDENTIFIED,
    label: "N/A",
  },
];
