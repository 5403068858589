import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";

import PhoneRankingModalTitleStyles from "./PhoneRankingModalTitle.styles";
import CTIStore from "../../../../../../stores/cti/CtiStore";

interface Props {
  phone: string;
  needClassification: boolean;
}

function PhoneRankingModalTitle({
  phone,
  needClassification,
}: Props): JSX.Element {
  const { title } = PhoneRankingModalTitleStyles();
  const { isConnected } = useStore(CTIStore);
  const { t } = useTranslation();

  return needClassification && isConnected ? (
    <Typography
      className={title}
      data-testid="phone-ranking-title-rate-call"
    >{`${t("PHONE_RANKING.RATE_CALL")} ${phone}`}</Typography>
  ) : (
    <Typography
      className={title}
      data-testid="phone-ranking-title-choose-number"
    >{`${t("PHONE_RANKING.CHOOSE_NUMBER")}`}</Typography>
  );
}

export default PhoneRankingModalTitle;
