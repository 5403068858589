import React from "react";
import { Box } from "@material-ui/core";
import { useStore } from "effector-react";
import GlobalStore from "../../../../stores/global/GlobalStore";
import { HeaderStyles } from "./HeaderStyles";
import GapBoard from "./gapBoard/GapBoard";
import GapReason from "./gapReason/GapReason";
import {
  isFeatureEnabled,
  TLP_GAP_REASON,
  TLP_GAP_REASON_ADMIN,
} from "../../../../config/featureToggles";

function Header(): React.ReactElement {
  const classes = HeaderStyles();

  const GlobalStates = useStore(GlobalStore);

  const isGapReasonEnabled = isFeatureEnabled(
    TLP_GAP_REASON,
    TLP_GAP_REASON_ADMIN,
    GlobalStates.user.keyToggle,
  );

  return (
    <Box className={classes.container}>
      <GapBoard />
      {isGapReasonEnabled && <GapReason />}
    </Box>
  );
}

export default Header;
