import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    redeemInfosContainer: {
      display: "flex",
      justifyContent: "space-between",
      padding: 18,
      margin: "16px 0px",
      backgroundColor: "#fafafa",
    },
    content: {
      marginTop: "34px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    pointsContainer: {
      display: "flex",
      height: "100%",
      alignItems: "flex-end",
      paddingBottom: "10px",
    },
    pointsValue: {
      color: "#1d8445",
      fontWeight: 500,
      fontSize: "18px",
    },
    noItemsContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      height: "60px",
      alignItems: "center",
    },
    noItemsMessage: {
      fontSize: "18px",
      color: "#7c7c7c",
    },
  }),
);
