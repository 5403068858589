import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import VendorStore from "../../stores/vendor/VendorStore";
import {
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_AGENT_CALL_SERVICE,
} from "../../config/featureToggles";
import axios from "../../config/axios/axiosInstance";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../host/HostService";
import CallCounterResponseToCallCounterConverter from "../../converters/callCounter/CallCounterResponseToCallCounterConverter";
import { CallCounterResponse } from "./domains/CallCounterResponse";
import { AGENT_CALL_SERVICE } from "../../config/services";
import GlobalStore from "../../stores/global/GlobalStore";

export const AGENT_CALL = "agent-call";
export const CALL_COUNTER = "call-counter";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getCallCounter(
  accountId: string,
  headers = {},
): Promise<number> {
  const log = useLogService();

  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();

  const isCallCounterV2Enabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_AGENT_CALL_SERVICE,
    user.zone,
  );
  const vendorID = vendor?.id;
  const v2EndPoint = getV2EndPoint(isCallCounterV2Enabled);
  const vendorIdParam = getVendorIdParam(vendorID, isCallCounterV2Enabled);

  const baseUrl = `${getApiHost(AGENT_CALL_SERVICE)}/${AGENT_CALL}`;
  const endpoint = `${baseUrl}${v2EndPoint}/${accountId}/${CALL_COUNTER}${vendorIdParam}`;

  return axios
    .get<CallCounterResponse>(`${endpoint}`, {
      headers,
    })
    .then((response) => {
      log.info(`Convert Call Counter received from ${endpoint} to our model`);
      return CallCounterResponseToCallCounterConverter(response.data);
    })
    .catch((error) => {
      log.error("CallCounterService - getCallCounter error: ", error);
      throw error;
    });
}
