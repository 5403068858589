import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    list: {
      overflow: "hidden !important",
      "&:hover": {
        overflow: "auto !important",
      },
    },
    listProductSearch: {
      overflow: "hidden !important",
      marginTop: ".5rem",
      "& div": {
        heigth: "430px !important",
      },
      "&:hover": {
        overflowY: "auto !important",
      },
      "&::-webkit-scrollbar-thumb": {
        height: "121px",
        marginLeft: "10px !important",
        backgroundColor: "rgba(0,0,0,0.15)",
      },
    },
  }),
);
