import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    loading: {
      color: "white",
    },
    button: {
      lineHeight: "15px",
      padding: "8px 16px",
      borderRadius: "3px",
    },

    rounded: {
      borderRadius: "20px !important",
      fontWeight: 500,
      fontSize: "16px",
    },
    contained: {
      backgroundColor: palette.primary.main,
      color: "white",
    },
    outlined: {
      backgroundColor: "#white",
      color: palette.primary.main,
    },
    loadingOutlined: {
      color: palette.primary.main,
    },
    loadingContained: {
      color: "white",
    },
  }),
);
