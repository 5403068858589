/* eslint-disable global-require */
import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import Styles from "./StockSection.styles";

interface Props {
  stock: number;
}

const StockSection = ({ stock }: Props): JSX.Element => {
  const classes = Styles();

  const { t } = useTranslation();

  return (
    <div data-testid="stock-section" className={classes.stockSection}>
      {stock && stock !== 0 ? (
        <Typography className={classes.inStock} data-testid="in-stock">
          {`${stock} ${t("COMMONS_PRODUCTS_INFOS.STOCK.IN_STOCK")}`}
        </Typography>
      ) : (
        <Typography className={classes.noStock} data-testid="no-stock">
          {t("COMMONS_PRODUCTS_INFOS.STOCK.NO_STOCK")}
        </Typography>
      )}
    </div>
  );
};

export default StockSection;
