import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardUpliftStyles from "./CardUpliftStyles";
import PercetegeIcon from "../../assets/PercetegeIcon";
import MoneyIcon from "../../assets/MoneyIcon";
import Data from "../data/Data";

interface Props {
  title: string;
  titleIcon: ReactNode;
  mainLabel: string;
  secondaryLabel: string;
  totalGapLabel: string;
  // eslint-disable-next-line react/no-unused-prop-types
  mainValue?: number | null;
  secondaryValue?: number;
  moneyValue?: number;
  moneyValueSecondary?: number;
  testId: string;
}

CardUplift.defaultProps = {
  moneyValue: "0",
  moneyValueSecondary: "0",
};

function CardUplift({
  title,
  titleIcon,
  mainLabel,
  secondaryLabel,
  totalGapLabel,
  secondaryValue,
  moneyValue,
  moneyValueSecondary,
  testId,
}: Props): React.ReactElement {
  const { t } = useTranslation();

  const classes = CardUpliftStyles();
  const percentage =
    moneyValue && moneyValueSecondary && moneyValueSecondary > 0
      ? Math.round((moneyValue / moneyValueSecondary) * 100)
      : 0;
  const pad = (num: number, size: number): string => {
    let s = `${num}`;
    while (s.length < size) s = `0${s}`;
    return s;
  };

  const shouldDisplayNotApplicable = () => {
    return !moneyValue && !moneyValueSecondary;
  };

  return (
    <Card className={classes.containerBox} data-testid={testId} elevation={0}>
      <CardHeader
        title={title}
        classes={{ title: classes.overwriteTitle }}
        className={classes.title}
        data-testid={`${testId}-header`}
        action={titleIcon}
      />

      <CardContent classes={{ root: classes.cardContent }}>
        <Grid classes={{ root: classes.gridContent }}>
          <Grid classes={{ root: classes.gridBox }}>
            <PercetegeIcon />
            <Box className={classes.centerBox}>
              <Grid>
                <Typography
                  className={classes.percentage}
                  data-testid={`${testId}-percentage`}
                >
                  {shouldDisplayNotApplicable()
                    ? t("GlobalMessage.N_A")
                    : `${pad(percentage, 2)}%`}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  classes={{ root: classes.labelOverwrite }}
                  className={classes.mainLabel}
                  data-testid={`${testId}-mainValue-text`}
                  variant="subtitle1"
                >
                  {mainLabel}
                </Typography>
              </Grid>
            </Box>
            <Box className={classes.labelBox}>
              <Grid>
                <Typography
                  className={classes.percentageSecondary}
                  data-testid={`${testId}-secondaryValue`}
                >
                  {secondaryValue !== undefined
                    ? `${pad(secondaryValue, 2)}%`
                    : t("GlobalMessage.N_A")}
                </Typography>
              </Grid>
              <Grid>
                <Typography
                  align="right"
                  classes={{ root: classes.labelOverwrite }}
                  className={classes.secondaryLabel}
                  data-testid={`${testId}-secondaryValue-text`}
                  variant="subtitle1"
                >
                  {secondaryLabel}
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Divider
            orientation="vertical"
            classes={{ vertical: classes.divider }}
            data-testid={`${testId}-divider`}
          />
          <Grid item xs classes={{ root: classes.gridBoxSecondary }}>
            <MoneyIcon />
            <Box className={classes.centerBox}>
              <Grid
                className={classes.moneyValue}
                data-testid={`${testId}-moneyValue-value`}
              >
                {shouldDisplayNotApplicable() ? (
                  t("GlobalMessage.N_A")
                ) : (
                  <Data
                    type="currency"
                    className={classes.moneyValue}
                    value={moneyValue}
                  />
                )}
              </Grid>
              <Grid>
                <Typography
                  classes={{ root: classes.labelOverwrite }}
                  className={classes.mainLabel}
                  data-testid={`${testId}-mainValuePercentage-text`}
                >
                  {mainLabel}
                </Typography>
              </Grid>
            </Box>
            <Box className={classes.labelBox}>
              <Grid
                className={classes.moneyValueSecondary}
                data-testid={`${testId}-totalGapValue-value`}
              >
                {shouldDisplayNotApplicable() ? (
                  t("GlobalMessage.N_A")
                ) : (
                  <Data type="currency" value={moneyValueSecondary} />
                )}
              </Grid>
              <Grid>
                <Typography
                  align="right"
                  classes={{ root: classes.labelOverwrite }}
                  className={classes.secondaryLabel}
                  data-testid={`${testId}-totalGapLabel-text`}
                >
                  {totalGapLabel}
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CardUplift.defaultProps = {
  mainValue: undefined,
};

export default CardUplift;
