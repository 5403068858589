export interface updateCartValuesType {
  deliveryWindowId?: string;
  paymentTerm?: string;
  paymentMethodCode?: string;
  cartFirstLoadDone?: boolean | false;
}

export const getPaymentMethodValue = (
  valueStore: string,
  field?: string,
  value?: string,
): string => {
  return field === "paymentMethod" && value ? value : valueStore;
};

export const getDeliveryDateValue = (
  valueStore: string,
  field?: string,
  value?: string,
): string => {
  return field === "deliveryDate" && value
    ? value.split("T")[0]
    : valueStore?.split("T")[0] || new Date().toISOString().split("T")[0];
};

export const getDeliveryWindowIdValue = (
  valueStore?: string,
  field?: string,
  changedValues?: updateCartValuesType,
): string | null => {
  return field === "deliveryDate" && changedValues?.deliveryWindowId
    ? changedValues?.deliveryWindowId
    : valueStore || null;
};
