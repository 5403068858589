import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "auto",
      maxWidth: "25vw",
      padding: "13px 16px 13px 16px",
      margin: "0 30px 8px 0px",
      borderRadius: "4px",
      backgroundColor: "#f8f8f8",
      maxHeight: "380px",
    },
    header: {
      display: "flex",
      alignContent: "center",
      justifyContent: "space-between",
    },
    inputWithError: {
      paddingBottom: "9px",
    },
    tagTitleWithEllipsis: {
      maxWidth: "214px",
      fontSize: "14px",
      fontWeight: "normal",
      color: palette.text.primary,
      letterSpacing: "0.1px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    iconComments: {
      fontSize: "16px",
      color: "#000",
    },
    select: {
      fontSize: "12px",
      fontWeight: 600,
      color: palette.text.secondary,
      display: "flex",
      flexDirection: "column",
      marginTop: "10px",
    },
    textArea: {
      display: "flex",
      fontSize: "12px",
      flexDirection: "column",
      resize: "none",
      height: "50px",
      borderRadius: "3px",
      border: `solid 1px ${palette.text.secondary}`,
      padding: "10px",
      outline: "none",
      "&:focus": {
        border: `solid 1px ${palette.common.black}`,
      },
      width: "100%",
      marginTop: "10px",
    },
    disabledIcon: {
      opacity: "30%",
    },
    hasFieldErrors: {
      color: palette.error.main,
      maxWidth: "214px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "14px",
    },
    tooltipStyle: {
      left: "101px",
      top: "5px",
      backgroundColor: "#000",
      borderRadius: "8px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.24)",
      padding: "16px 16px 20px 16px",
      color: "#f2f2f2",
      lineHeight: 1.43,
      textAlign: "center",
      fontSize: "14px",
      fontFamily: "Barlow",
      fontWeight: "normal",
      letterSpacing: "0.1px",
    },
    tooltipArrow: {
      color: "#000",
      fontSize: "0.625rem",
    },
  }),
);
