import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>(({ palette }) =>
  createStyles({
    card: {
      width: "100%",
      height: "106px",
      display: "flex",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
      borderRadius: "11px",
    },
    image: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "9px",
      height: "100%",
      padding: 8,
      "& img": {
        width: 80,
        height: "100%",
      },
    },
    title: {
      fontSize: "12px",
      color: palette.text.primary,
      fontWeight: 600,
    },
    tag: {
      backgroundColor: "#ec9316",
      alignItems: "center",
    },
    price: {
      color: palette.info.main,
      fontSize: "12px",
      fontWeight: 600,
    },
    sku: {
      fontSize: "12px",
      color: "#9e9e9e",
    },
    cardContentContainer: {
      padding: "8px 8px 8px 0px",
    },
  }),
);
