import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { getUserInfos } from "@config/utils/functions";
import { FulfillmentContent } from "../../domains/fulfillment/Fulfillment";
import axios from "../../config/axios/axiosInstance";
import { getApiHost } from "../host/HostB2BService";
import FulfillmentResponse from "./domains/FulfillmentResponse";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

const ENDPOINT = `/api/v1/fulfillment`;

export async function getFulfillment(
  accountId: string,
): Promise<FulfillmentContent[]> {
  const log = useLogService();

  const { vendorId } = getUserInfos();

  const body = {
    accountId,
    vendorIds: [vendorId],
  };

  return axios
    .post<FulfillmentResponse>(`${getApiHost()}${ENDPOINT}`, body)
    .then((response) => {
      log.info(`Return the data from ${ENDPOINT}`);
      return response?.data?.content;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
