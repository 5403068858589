/* istanbul ignore file */
import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    container: {
      width: "336px",
      height: "220px",
      padding: "24px",
    },
    title: {
      maxWidth: "208px",
      minHeight: "96px",
      color: "rgba(0,0,0,0.9)",
      fontFamily: "Barlow",
      fontSize: "24px",
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "32px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    closeButton: {
      backgroundColor: "rgba(0,0,0,0.15)",
      width: "32px",
      height: "32px",
      float: "right",
      "& span svg": {
        color: "rgba(0,0,0,0.9)",
      },
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.15)",
      },
    },
    actionsContainer: {
      display: "flex",
      justifyContent: "right",
      marginTop: "36px",
    },
    inactivateButton: {
      marginRight: "8px",
      backgroundColor: "#C9201D",
      border: "1px solid #C9201D",
      color: "rgba(255,255,255,0.95)",
      fontFamily: "Work Sans",
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: 0,
      "&:hover": {
        color: "rgba(255,255,255,0.95)",
        backgroundColor: "#C9201D",
      },
      "&:active": {
        color: "rgba(255,255,255,0.95)",
      },
    },
    cancelButton: {
      color: "#000000",
      fontFamily: "Work Sans",
      fontSize: "18px",
      fontWeight: 500,
      letterSpacing: 0,
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&:active": {
        color: "#000000",
      },
    },
  }),
);
