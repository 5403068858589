import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";

import {
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_ACCOUNT_SERVICE,
} from "../../config/featureToggles";
import { TLP_ACCOUNT_SERVICE } from "../../config/services";
import converter from "../../converters/phoneRanking/UpdateRankedPhonesResponseToRankedPhonesConverter";
import RankedPhones from "../../domains/phoneRanking/RankedPhones";
import UpdateRankedPhonesRequest from "../../domains/phoneRanking/UpdateRankedPhonesRequest";
import GlobalStore from "../../stores/global/GlobalStore";
import VendorStore from "../../stores/vendor/VendorStore";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../host/HostService";

export const ACCOUNT_SERVICE = `tlp-account`;
export const RANKED_PHONES_ENDPOINT = "ranked-phones";

export const execute = async (
  accountId: string,
  vendorId: string,
  vendorName: string,
  request: Array<UpdateRankedPhonesRequest>,
): Promise<RankedPhones | null> => {
  const log = useLogService();
  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();

  const isTLPAccountMulticontract = isFeatureEnabledV2(
    TLP_MULTICONTRACT_ACCOUNT_SERVICE,
    user.zone,
  );

  const vendorIdMetadata = vendor?.id;

  const apiHost = getApiHost(TLP_ACCOUNT_SERVICE);
  const v2EndPoint = getV2EndPoint(isTLPAccountMulticontract);
  const vendorIdParam = getVendorIdParam(
    vendorIdMetadata,
    isTLPAccountMulticontract,
  );

  const url = `${apiHost}/${ACCOUNT_SERVICE}${v2EndPoint}/${accountId}/${RANKED_PHONES_ENDPOINT}${vendorIdParam}`;

  try {
    const response = await axios.put(`${url}`, request, {
      headers: {
        vendorId,
        vendorName,
      },
    });

    log.info(`Convert UpdateRankedPhonesResponse from ${url} to our model`);

    return converter(response.data);
  } catch (error) {
    log.error(error);
    throw error;
  }
};
