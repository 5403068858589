import { CALL_ID, DATE_FORMAT_STRING } from "@config/constants";
import { getResponseDate } from "@config/utils/functions";
import GetNextPocPayload from "@domains/GetNextPocPayload";
import AgentCallStore from "@stores/agentCall/AgentCallStore";
import GlobalAdminConfigStore from "@stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "@stores/global/GlobalStore";
import { useStore } from "effector-react";
import { useUserConfig, getVendorName } from "grow-shared-services";
import * as GetNextPocUseCase from "@usecase/nextPoc/getNextPoc/GetNextPocUseCase";

export enum ScreenSection {
  CALL = "Call",
  SIDEBAR = "Sidebar",
  HOME_HEADER = "Home Header",
}

export interface UseNextPocHookProps {
  agentNotInCall: boolean;
  getNextPoc: (screenSection: ScreenSection) => void;
}

export const useNextPocHook = (): UseNextPocHookProps => {
  const { user } = useStore(GlobalStore);
  const { clientId } = useStore(AgentCallStore);
  const { buConfig, userConfig } = useStore(GlobalAdminConfigStore);
  const {
    data: { vendorId },
  } = useUserConfig();

  const isCallIdUndefined =
    localStorage.getItem(CALL_ID) === null ||
    localStorage.getItem(CALL_ID) === "undefined" ||
    localStorage.getItem(CALL_ID) === "";

  const agentNotInCall = !clientId && isCallIdUndefined;

  const getNextPoc = (screenSection: ScreenSection) => {
    const request: GetNextPocPayload = {
      email: user.login,
      date: getResponseDate().format(DATE_FORMAT_STRING),
      country: user.zone,
      buConfigRequest: buConfig,
      userConfigRequest: {
        ...userConfig,
        vendorId,
        vendorName: getVendorName(vendorId),
      },
    };

    GetNextPocUseCase.execute(request, screenSection, user.keyToggle);
  };

  return {
    agentNotInCall,
    getNextPoc,
  };
};
