import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    list: {
      display: "flex",
      width: "100%",
      padding: "9px 11px 0",
    },
    noEmpties: {
      display: "flex",
      width: "100%",
      height: "115px",
      justifyContent: "center",
      alignItems: "center",
      padding: "5px",
      overflow: "hidden",
      flexGrow: 1,
      fontWeight: 500,
      color: "#505050",
      "&:hover": {
        overflow: "auto",
      },
    },
  }),
);
