import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    email: {
      fontSize: "0.75rem",
      display: "inline-block",
      color: palette.text.secondary,
      marginRight: "0.5rem",
    },
    orders: {
      display: "inline",
      fontSize: "0.75rem",
      color: palette.text.primary,
    },
    commentContainer: {
      backgroundColor: palette.background.default,
      padding: "0.5rem",
      marginTop: "0.7rem",
      marginBottom: "0.5rem",
    },
    comment: {
      fontSize: "0.75rem",
      color: palette.text.secondary,
    },
  }),
);
