import { createStore } from "effector";
import { getFulfillmentEffect } from "./FulfillmentEvents";
import FulfillmentState from "./FulfillmentState";

const initialState = <FulfillmentState>{
  done: false,
  error: false,
  fulfillment: [],
};

const FulfillmentStore = createStore(initialState)
  .on(getFulfillmentEffect.done, (state: FulfillmentState, { result }) => ({
    ...state,
    fulfillment: result,
    done: true,
    error: false,
  }))
  .on(getFulfillmentEffect.fail, (state: FulfillmentState) => ({
    ...state,
    done: true,
    error: true,
  }));

export default FulfillmentStore;
