import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import MissionTooltipStyles from "./style";

interface Props {
  missionClassification?: string;
  missionGapReason?: string;
  missionComment?: string;
}

function MissionTooltip({
  missionClassification,
  missionGapReason,
  missionComment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const {
    tooltipContainer,
    tooltipArrow,
    classificationText,
    gapReasonText,
    commentTitle,
    commentText,
    icon,
  } = MissionTooltipStyles({
    missionCommentMarginTop: missionClassification || missionGapReason ? 8 : 0,
  });

  return (
    <Tooltip
      title={
        <>
          {missionClassification && (
            <Typography className={classificationText}>
              {missionClassification}
            </Typography>
          )}
          {missionGapReason && (
            <Typography className={gapReasonText}>
              {missionGapReason}
            </Typography>
          )}

          {missionComment && (
            <>
              <Typography className={commentTitle}>
                {t("CUSTOMER_PAGE.TOOLTIP_COMMENT")}{" "}
              </Typography>
              <Typography className={commentText}>{missionComment}</Typography>
            </>
          )}
        </>
      }
      arrow
      placement="bottom-start"
      classes={{
        tooltip: tooltipContainer,
        arrow: tooltipArrow,
      }}
    >
      <InfoOutlinedIcon className={icon} />
    </Tooltip>
  );
}

MissionTooltip.defaultProps = {
  missionClassification: null,
  missionGapReason: null,
  missionComment: null,
};

export default MissionTooltip;
