import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      fontSize: "12px",
      fontWeight: "normal",
      width: "100%",
      wordBreak: "break-word",
      color: palette.common.white,
      whiteSpace: "break-spaces",
    },
    item: {
      color: palette.grey[100],
    },
    button: {
      fontSize: "10px",
      fontWeight: "bold",
      color: palette.common.white,
      paddingRight: "2px",
    },
    icon: {
      height: "20px",
      width: "20px",
      marginRight: "4px",
      marginLeft: "-4px",
    },
  }),
);
