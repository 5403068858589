import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      margin: "0 15px",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    loadingContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    errorContainer: {
      paddingTop: "24px",
      width: "608px",
      fontSize: "18px",
      fontWeight: 500,
      color: "#7a7a7a",
    },
    divider: {
      color: palette.grey[200],
      width: "100%",
    },
    dailyHeader: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      height: "30px",
    },
    dailyKpiTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
    },
    monthlyHeader: {
      marginTop: "5px",
      width: "calc(100% - 10px)",
      display: "flex",
      flexDirection: "column",
    },
    kpisHeaderLabel: {
      fontSize: "20px",
      marginBottom: "3px",
      fontWeight: 600,
      color: palette.primary.main,
      fontFamily: "Barlow",
    },

    monthlyContainer: {
      maxHeight: "70vh",
      overflowY: "auto",
    },
    kpisContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    loading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
  }),
);
