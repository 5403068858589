import { STOCKOUT_OPTIONS } from "../../config/constants";
import MarketplaceStore from "../../stores/marketplace/MarketplaceStore";
import PromotionsStore from "../../stores/promotions/PromotionsStore";
import SuggestedOrdersDomain from "../../domains/suggestedOrders/SuggestedOrdersDomain";
import SuggestedOrder from "../../domains/suggestedOrders/SuggestedOrder";
import SuggestedOrderResponse from "../../services/suggestedOrders/domains/SuggestedOrderResponse";
import sort from "../../routes/call/components/suggestedOrder/SuggestedOrderSorting";
import { hasMarketplace } from "../../config/utils/functions";
import GlobalStore from "../../stores/global/GlobalStore";
import {
  isFeatureEnabledV2,
  TLP_SUGGESTED_INVENTORY_AND_ENFORCEMENT,
} from "../../config/featureToggles";

export default function SuggestedOrdersResponseToSuggestedOrdersConverter(
  data: Array<SuggestedOrderResponse>,
): SuggestedOrdersDomain {
  const marketplaceState = MarketplaceStore.getState();
  const { marketplaceList } = marketplaceState;
  const { promotions } = PromotionsStore.getState();
  const { user } = GlobalStore.getState();

  const isSuggestedInventoryAndEnforcementEnabled = isFeatureEnabledV2(
    TLP_SUGGESTED_INVENTORY_AND_ENFORCEMENT,
    user.keyToggle,
  );

  let totalSuggested = 0;

  let suggestedOrders = data.map((item) => {
    const isMarketplace = hasMarketplace(item.sku, marketplaceList);

    totalSuggested += item.totalPrice;

    const isPromotion = promotions.some((promo) => item.sku === promo.sku);

    return <SuggestedOrder>{
      id: item.id,
      itemPlatformId: item.itemPlatformId,
      date: item.date != null ? new Date(item.date) : undefined,
      countryCode: item.countryCode != null ? item.countryCode : undefined,
      clientId: item.clientId != null ? item.clientId : undefined,
      sku: item.sku,
      mc: item.mc != null ? item.mc : undefined,
      segment: item.segment != null ? item.segment : undefined,
      boxesOrder: item.boxesOrder != null ? item.boxesOrder : undefined,
      gapBoxes: item.gapBoxes != null ? item.gapBoxes : undefined,
      suggestedOrder:
        item.suggestedOrder != null ? item.suggestedOrder : undefined,
      unitPrice: item.unitPrice != null ? item.unitPrice : undefined,
      totalPrice: item.totalPrice != null ? item.totalPrice : undefined,
      isMarketplace,
      isPromotion,
      stock: stockOption(
        Number(item.suggestedOrder),
        item.limit?.balance,
        isSuggestedInventoryAndEnforcementEnabled
          ? item.availability?.count
          : item.inventoryCount,
      ),
      pricePerUoM: item.pricePerUoM,
      palletQuantity: item.palletQuantity,
      priceDetails: item.priceDetails,
      availability: item.availability,
      inventoryCount: item.inventoryCount,
      variant: item.variant,
      package: item.package,
    };
  });

  suggestedOrders = sort("gapBoxes", false, suggestedOrders);

  return {
    suggestedOrders,
    totalSuggested,
  };
}

function stockOption(
  suggestedOrder: number,
  balance?: number,
  inventory?: number,
) {
  let value = 0;

  if (balance && inventory) {
    if (Number(inventory) === -1) {
      value = Number(balance);
    } else value = Math.min(Number(inventory), Number(balance));
  } else if (balance == null && inventory == null) {
    return {
      valueStockOut: 0,
      stockAction: STOCKOUT_OPTIONS.DONT_DO_ANYTHING,
    };
  } else {
    value = Number(balance) || Number(inventory);
  }

  if (value === 0) {
    return {
      valueStockOut: 0,
      stockAction: STOCKOUT_OPTIONS.DISPLAY_INDICATOR,
    };
  }

  if (value !== -1 && value < suggestedOrder) {
    return {
      valueStockOut: value,
      stockAction: STOCKOUT_OPTIONS.DISPLAY_WARN,
    };
  }

  return {
    valueStockOut: value,
    stockAction: STOCKOUT_OPTIONS.DONT_DO_ANYTHING,
  };
}
