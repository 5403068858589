import React from "react";
import { useTranslation } from "react-i18next";
import {
  Select as BaseSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import SelectStyles from "./SelectStyles";
import { noop } from "../../config/utils/functions";

interface Option {
  value: string | number;
  label: string;
}

interface Props {
  name: string;
  value: string | number;
  error?: string;
  touched?: boolean;
  options: Array<Option>;
  onChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  onOpen?: (e: React.ChangeEvent<unknown>) => void;
  onClose?: (e: React.ChangeEvent<unknown>) => void;
  testId: string;
  disabled?: boolean;
  className?: string;
  ellipsis?: boolean;
  isEmpty?: boolean;
}

export interface FormValues {
  classification: string;
  classificationDetails: string;
}

const Select: React.FunctionComponent<Props> = ({
  name,
  value,
  error,
  touched,
  options,
  onChange,
  onOpen,
  onClose,
  testId,
  disabled,
  className,
  ellipsis,
  isEmpty,
}) => {
  const { t } = useTranslation();
  const classes = SelectStyles();

  function getKeyMenuItem(index: number) {
    return `${index}-select-menu-item`;
  }

  function getKeyMenuItemText(index: number) {
    return `${index}-menu-item-text`;
  }

  return (
    <>
      <BaseSelect
        disabled={disabled}
        value={value}
        onChange={onChange}
        onOpen={onOpen}
        onClose={onClose}
        displayEmpty
        className={[classes.selectOutline, className].join(" ")}
        variant="outlined"
        classes={{
          root: classes.select,
          select: isEmpty ? classes.emptyInput : classes.focusedSelect,
        }}
        inputProps={{
          name,
          "data-testid": `${testId}-input`,
        }}
        data-testid={testId}
      >
        {options.map((r, index) => (
          <MenuItem
            value={r.value}
            disabled={!r.value}
            key={getKeyMenuItem(index)}
            className={classes.selectItem}
            data-testid={`${testId}-${index}-option`}
          >
            {ellipsis ? (
              <div
                key={getKeyMenuItemText(index)}
                className={classes.menuItemText}
              >
                {t(r.label)}
              </div>
            ) : (
              t(r.label)
            )}
          </MenuItem>
        ))}
      </BaseSelect>
      {error && touched && (
        <FormHelperText
          classes={{ root: classes.errorMessage }}
          data-testid={`${testId}-error-message`}
        >
          {error}
        </FormHelperText>
      )}
    </>
  );
};

Select.defaultProps = {
  error: "",
  touched: false,
  onOpen: noop,
  onClose: noop,
  disabled: false,
  ellipsis: false,
  isEmpty: false,
};

export default Select;
