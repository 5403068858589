import React from "react";

const MediumFaceIcon: React.FunctionComponent = () => {
  return (
    <svg width={34} height={34} xmlns="http://www.w3.org/2000/svg">
      <g
        transform="translate(1 1)"
        stroke="#939191"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle strokeWidth={2} cx={16} cy={16} r={16} />
        <path strokeWidth={2} d="M9.6 20.8h12.8" />
        <path strokeWidth={3} d="M11.2 11.2h.016M20.8 11.2h.016" />
      </g>
    </svg>
  );
};

export default MediumFaceIcon;
