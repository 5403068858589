import React from "react";

const LoginInformation: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g transform="translate(-935 -332) translate(935 332)">
            <circle cx="13" cy="13" r="13" fill="#505050" />
            <g fill="#F8F8F8">
              <path
                d="M14.492 1.508c-2.01-2.01-5.282-2.01-7.292 0C5.827 2.88 5.35 4.905 5.944 6.74L.137 12.548c-.088.088-.137.207-.137.331v2.652c0 .26.21.469.469.469H3.12c.124 0 .243-.05.331-.137l.663-.664c.101-.1.15-.243.134-.385l-.082-.712.987-.093c.224-.021.401-.199.422-.423l.093-.987.712.083c.133.018.266-.027.366-.115.1-.09.157-.217.157-.35v-.873h.857c.124 0 .243-.05.331-.138l1.202-1.186c1.836.595 3.825.154 5.198-1.22 2.01-2.01 2.01-5.282 0-7.292zm-1.326 3.315c-.548.548-1.44.548-1.989 0-.548-.549-.548-1.44 0-1.989.549-.548 1.44-.548 1.989 0 .548.548.548 1.44 0 1.989z"
                transform="translate(5 5)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LoginInformation;
