import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useStore } from "effector-react";
import { isFeatureEnabled, useUserConfig } from "grow-shared-services";

import { CALL_TRIGGER, useAnalytics } from "@analytics/useAnalytics";
import {
  ANALYTICS_ROUTE_NAMES,
  LINK_HOME,
  PocCallOrigin,
  SEGMENTATION_COUNTRIES,
  TAB_CUSTOMER_ROUTE,
  TAB_GAP_ROUTE,
  isCallRoute,
} from "@config/constants";
import { TLP_CUSTOMER_PAGE } from "@config/featureToggles";
import { getValueOrNull } from "@config/utils/functions";
import { LoadingBuzz } from "@hexa-ui/components";
import AgentCallStore from "@stores/agentCall/AgentCallStore";
import GlobalAdminConfigStore from "@stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "@stores/global/GlobalStore";
import CallTabStore from "@stores/navigation/callTab/CallTabStore";
import VendorStore from "@stores/vendor/VendorStore";

import { ScreenSection, useNextPocHook } from "@hooks/useNextPocHook";
import { callGetNextPoc, callGetNextPocFailed } from "../../config/typewriter";
import * as CallTabEvents from "../../stores/navigation/callTab/CallTabEvents";
import {
  getNextPocDone,
  getNextPocFail,
} from "../../stores/nextPoc/getNextPoc/GetNextPocEvents";
import CallRoute from "./CallRoute";

/* istanbul ignore next */
export function CallRouteWrapper(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatchPocEvent, dispatchGenericEvent } = useAnalytics();
  const {
    data: { vendorId },
  } = useUserConfig();

  const AgentCallStates = useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);
  const {
    vendor: { id: vendorStoreId },
  } = useStore(VendorStore);
  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { callTab } = useStore(CallTabStore);
  const { getNextPoc, agentNotInCall } = useNextPocHook();

  const isCustomerPageEnabled = isFeatureEnabled(TLP_CUSTOMER_PAGE);
  const isVendorIdUpdated = !!vendorStoreId && !!vendorId;

  const notCallGetNextPoc =
    SEGMENTATION_COUNTRIES.indexOf(user.keyToggle) > -1 &&
    (userConfig.segmentation === null ||
      userConfig.segmentation === undefined ||
      userConfig?.segmentation?.length === 0);

  useEffect(() => {
    if (agentNotInCall && isCallRoute(history.location.pathname)) {
      getNextPoc(ScreenSection.SIDEBAR);
    }

    if (isCustomerPageEnabled) {
      CallTabEvents.setCallTab(TAB_CUSTOMER_ROUTE);
    } else {
      CallTabEvents.setCallTab(TAB_GAP_ROUTE);
    }
  }, []);

  useEffect(() => {
    const isGetNextPoc = !(
      AgentCallStates.pocCallOrigin === PocCallOrigin.SEARCH_BY_POC ||
      AgentCallStates.pocCallOrigin === PocCallOrigin.AUTO_DIALER
    );
    if (notCallGetNextPoc && !AgentCallStates.isTypedPoc && isGetNextPoc) {
      history.push({
        pathname: LINK_HOME,
        state: {
          message: t("HomeMainPage.NO_SEGMENTATION"),
          type: "error",
        },
      });
    }
  }, [
    notCallGetNextPoc,
    AgentCallStates.isTypedPoc,
    history,
    t,
    // eslint-disable-next-line no-restricted-globals
    location.pathname,
    AgentCallStates.pocCallOrigin,
  ]);

  useEffect(() => {
    /* istanbul ignore next */
    const getValue = (orderSku: number) => {
      return orderSku === 0
        ? CALL_TRIGGER.NO_ORDER
        : CALL_TRIGGER.OUT_OF_PARAMETER;
    };
    const unWatchGetNextPocDone = getNextPocDone.watch((response) => {
      if (response.clientId) {
        dispatchPocEvent(
          callGetNextPoc,
          {
            screen_name: ANALYTICS_ROUTE_NAMES.get(callTab),
            screen_section:
              /* istanbul ignore next */
              response.screenSection === "Call"
                ? "Call Typification"
                : response.screenSection,
            call_id: response.callId || null,
            call_trigger: getValue(response.orderSku),
            poc_id: response.clientId,
            country: response.countryCode,
            total_gap: getValueOrNull(response.gap.topline.total),
            total_vol_gap: getValueOrNull(response.gap.volume.total),
            vendor_id: userConfig?.vendorId,
          },
          {
            time_of_day: true,
            removeProps: [
              "DDC",
              "poc_segment",
              "credit_available",
              "poc_recurrence",
              "poc_potential",
              "poc_subsegment",
              "marketing_tag",
            ],
          },
        );
      } else if (!notCallGetNextPoc) {
        history.push({
          pathname: LINK_HOME,
          state: {
            message: t("HomeMainPage.CALL_LIST_COMPLETE"),
            type: "warning",
          },
        });
      }
    });

    const unWatchGetNextPocFail = getNextPocFail.watch(() => {
      dispatchGenericEvent(
        callGetNextPocFailed,
        {
          screen_name: ANALYTICS_ROUTE_NAMES.get(callTab),
          screen_section: "Sidebar",
          error_type: "unable to get next poc error",
          error_message: "Service unavailable. Try again later.",
        },
        { time_of_day: true },
      );
    });
    return () => {
      unWatchGetNextPocDone();
      unWatchGetNextPocFail();
    };
  }, [
    callTab,
    dispatchPocEvent,
    history,
    t,
    notCallGetNextPoc,
    dispatchGenericEvent,
    userConfig.vendorId,
  ]);

  useEffect(() => {
    const unWatchGetNextPocFail = getNextPocFail.watch((errorMessage) => {
      history.push({
        pathname: LINK_HOME,
        state: {
          message: t(errorMessage[0]),
          type: "error",
          analyticsErrorType: "get next poc error",
        },
      });
    });

    return () => {
      unWatchGetNextPocFail();
    };
  }, [history, t]);

  if (!isVendorIdUpdated) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          width: "90vw",
        }}
      >
        <LoadingBuzz size="xxlarge" />
      </div>
    );
  }

  return <CallRoute />;
}
