import { createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    toastContainer: {
      width: "0px !important",
      top: "5em !important",
      padding: "0 !important",
      transform: "translateX(-47%) !important",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    toast: {
      minHeight: "3rem !important",
      maxHeight: "9rem !important",
      padding: "12px !important",
      boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.16) !important",
      borderRadius: "8px !important",
      color: "#212121 !important",
      flexShrink: 0,
      maxWidth: "950px !important",
      minWidth: "580px",
    },
    toastBody: {
      padding: "0px !important",
    },
    closeIcon: {
      fontSize: "16px",
      margin: "10px",
    },
  }),
);
