import React from "react";

type Props = {
  hasErrors?: boolean;
};

const ChatBubbleIcon: React.FC<Props> = ({ hasErrors }) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="In-Development---Waiting-for-Approval"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Missions-Proposal-5---Call-Summary-Updated-4"
        transform="translate(-1073.000000, -317.000000)"
        fill={hasErrors ? "#e02020" : "#000000"}
        fillRule="nonzero"
      >
        <g id="Group-3" transform="translate(336.000000, 154.000000)">
          <g id="messenger" transform="translate(737.000000, 163.000000)">
            <path
              d="M8,0 C3.57861328,0 0,3.57800294 0,8 C0,9.40319825 0.365966813,10.7736816 1.06091309,11.9824219 L0.030151375,15.1833496 C-0.0417480625,15.4064941 0.017334,15.6511231 0.183105469,15.8168945 C0.347290031,15.9810791 0.591308594,16.0423584 0.816650375,15.9698486 L4.01757812,14.9390869 C5.22631837,15.6340332 6.59680175,16 8,16 C12.4213867,16 16,12.4219971 16,8 C16,3.57861328 12.4219971,0 8,0 Z M8,14.75 C6.73254394,14.75 5.49707031,14.3963623 4.42712403,13.7272949 C4.27319337,13.6311035 4.08203125,13.6051025 3.90417481,13.6623535 L1.59350587,14.4064941 L2.3376465,12.0958252 C2.39404297,11.9205322 2.37023925,11.729126 2.272583,11.572876 C1.60363769,10.5029297 1.25,9.26745606 1.25,8 C1.25,4.27807619 4.27807619,1.25 8,1.25 C11.7219238,1.25 14.75,4.27807619 14.75,8 C14.75,11.7219238 11.7219238,14.75 8,14.75 Z M8.78125,8 C8.78125,8.4313965 8.43151856,8.78125 8,8.78125 C7.56848144,8.78125 7.21875,8.4313965 7.21875,8 C7.21875,7.56848144 7.56848144,7.21875 8,7.21875 C8.43151856,7.21875 8.78125,7.56848144 8.78125,8 Z M11.90625,8 C11.90625,8.4313965 11.5565186,8.78125 11.125,8.78125 C10.6934814,8.78125 10.34375,8.4313965 10.34375,8 C10.34375,7.56848144 10.6934814,7.21875 11.125,7.21875 C11.5565186,7.21875 11.90625,7.56848144 11.90625,8 Z M5.65625,8 C5.65625,8.4313965 5.30651856,8.78125 4.875,8.78125 C4.4436035,8.78125 4.09375,8.4313965 4.09375,8 C4.09375,7.56848144 4.4436035,7.21875 4.875,7.21875 C5.30651856,7.21875 5.65625,7.56848144 5.65625,8 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ChatBubbleIcon;
