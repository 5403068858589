import Moment from "moment";
import { gapReasonValues } from "../../../routes/call/components/header/gapReason/gapReasons";
import { AgentCallResponse } from "../../../services/nextPoc/domains/AgentCallResponse";
import AgentCall from "../../../domains/AgentCall";
import { SQ_FORECAST, PocCallOrigin } from "../../../config/constants";
import { getTabs } from "../../../config/utils/functions";
import GlobalStore from "../../../stores/global/GlobalStore";
import {
  TLP_SPONSORED_MISSION_TAB,
  isFeatureEnabledV2,
  TLP_SERVICE_MISSION,
  GROW_MISSION_REWARDS_REDEMPTION,
} from "../../../config/featureToggles";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function AgentCallResponseToAgentCallConverter(
  data: AgentCallResponse,
  reponseDate: string,
): AgentCall {
  const { user } = GlobalStore.getState();

  const isSponsoredMissionsTabsEnabled = isFeatureEnabledV2(
    TLP_SPONSORED_MISSION_TAB,
    user.zone,
  );

  const isServiceMissionsTabsEnabled = isFeatureEnabledV2(
    TLP_SERVICE_MISSION,
    user.zone,
  );

  const isWipRewardsRedemptionEnabled = isFeatureEnabledV2(
    GROW_MISSION_REWARDS_REDEMPTION,
    user.zone,
  );

  const round = (value: number, places = 2): number =>
    Number(value.toFixed(places));

  const {
    agentId,
    callId,
    gapDescription,
    startCallDate,
    startServiceDate,
    orderInfo,
  } = data;

  const {
    clientId,
    countryCode,
    date,
    gapNetRevenue,
    netRevenueForecast,
    orderNetRevenue,
    gapHl,
    hlForecast,
    achievedHL,
    orderSku,
    tags,
    sponsoredIds,
    localMissionIds,
    vendorAccountId,
    legacyAccountId,
  } = data.orderGap || {
    clientId: "",
    countryCode: "",
    date: "",
    gapNetRevenue: null,
    netRevenueForecast: null,
    orderNetRevenue: null,
    gapHl: null,
    hlForecast: null,
    achievedHL: null,
    orderSku: 0,
    tags: [],
    sponsoredIds: [],
    localMissionIds: [],
  };

  const gap = {
    topline: {
      total: gapNetRevenue != null ? round(gapNetRevenue) : undefined,
      achieved: orderNetRevenue != null ? round(orderNetRevenue) : undefined,
      target:
        netRevenueForecast != null ? round(netRevenueForecast) : undefined,
    },
    volume: {
      total: gapHl != null ? round(gapHl) : undefined,
      achieved: achievedHL != null ? round(achievedHL) : undefined,
      target: hlForecast != null ? round(hlForecast) : undefined,
    },
  };

  let tagsList: string[] = [];
  let tabs: string[] = [];

  if (tags) {
    tagsList = tags.filter((item) => item !== SQ_FORECAST);
    tabs = getTabs(tagsList, {
      sponsored: isSponsoredMissionsTabsEnabled,
      service: isServiceMissionsTabsEnabled,
      wipRewardsRedemption: isWipRewardsRedemptionEnabled,
    });
  }

  return {
    agentId,
    callId,
    clientId,
    countryCode,
    date,
    callTypification: "",
    typificationDescription: "",
    gapReason: data.gapReason !== null ? data.gapReason : "",
    gapDescription,
    startServiceDate,
    startCall: startCallDate,
    callLater: "",
    endCall: "",
    gap,
    orderSku,
    tagsList,
    hasTechnicalProblem: data.gapReason === gapReasonValues.TECHNICAL_PROBLEM,
    ordersList: orderInfo,
    isTypedPoc: data.isTypedPoc,
    pocCallOrigin: data.pocCallOrigin as PocCallOrigin,
    reponseDate: Moment(Date.parse(reponseDate)),
    tabs,
    sponsoredMissionsIds: sponsoredIds,
    localMissionIds: localMissionIds ?? [],
    vendorAccountId,
    legacyAccountId,
    vendorId: data.vendorId,
  };
}
