import * as React from "react";

function Marketplace() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="BEES-Grow---customer-page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Customer-page---no-last-contacts"
          transform="translate(-1052.000000, -601.000000)"
        >
          <g id="Group-12" transform="translate(1035.000000, 258.000000)">
            <g id="Group-8" transform="translate(0.000000, 327.000000)">
              <g id="Group-3" transform="translate(15.678832, 15.170697)">
                <g
                  id="Graphics/Illustrations/BeerPack"
                  transform="translate(1.530931, 1.555832)"
                >
                  <g id="ZZ_Color-Override/Primary/Black">
                    <mask id="mask-2" fill="white">
                      <use href="#path-1" />
                    </mask>
                    <g id="Mask" />
                    <g
                      id="Group"
                      mask="url(#mask-2)"
                      fill="url(#linearGradient-3)"
                    >
                      <g id="ZZ_Color-Override/01_Primary/BEESBlack">
                        <rect
                          id="Rectangle-Copy"
                          x="0"
                          y="0"
                          width="45.9279197"
                          height="46.6749644"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="beer-box-(1)"
                    transform="translate(4.305742, 8.751556)"
                    fillRule="nonzero"
                  >
                    <g id="Group" transform="translate(23.681584, 0.000000)">
                      <g id="Path">
                        <g>
                          <path
                            d="M9.63767764,8.70032277 L8.51888833,7.56654584 L8.51888833,4.12910416 C8.51888833,3.507186 8.02138525,3.00298484 7.40772899,3.00298484 L4.07425094,3.00298484 C3.46059467,3.00298484 2.96309159,3.507186 2.96309159,4.12910416 L2.96309159,7.56654584 L1.84422821,8.70047291 C0.65491732,9.90572088 0,11.5082637 0,13.2127579 L0,13.663581 L5.74098996,20.4202969 L10.3708206,20.4202969 C10.9844768,20.4202969 11.4819799,19.9160958 11.4819799,19.2941776 L11.4819799,13.2127579 C11.4819799,11.5202757 10.8185437,9.8970873 9.63767764,8.70032277 Z"
                            fill="#BEE75E"
                          />
                          <path
                            d="M9.63767764,8.70032277 L8.51888833,7.56654584 L8.51888833,4.12910416 C8.51888833,3.507186 8.02138525,3.00298484 7.40772899,3.00298484 L5.74098996,3.00298484 L5.74098996,20.4202969 L10.3708206,20.4202969 C10.9844768,20.4202969 11.4819799,19.9160958 11.4819799,19.2941776 L11.4819799,13.2127579 C11.4819799,11.5202757 10.8185437,9.8970873 9.63767764,8.70032277 Z"
                            fill="#00CB75"
                          />
                          <path
                            d="M8.51888833,1.12611932 C8.51888833,0.504201155 8.02138525,0 7.40772899,0 L4.07425094,0 C3.46059467,0 2.96309159,0.504201155 2.96309159,1.12611932 L2.96309159,4.12910416 L8.51888833,4.12910416 L8.51888833,1.12611932 Z"
                            fill="#AF8663"
                          />
                        </g>
                        <path
                          d="M7.77811543,19.2941776 L7.77811543,14.7897004 C7.77811543,14.1677822 7.28061236,13.663581 6.66695609,13.663581 L0,13.663581 L0,19.2941776 C0,19.9160958 0.497503079,20.4202969 1.11115935,20.4202969 L6.66695609,20.4202969 C7.28061236,20.4202969 7.77811543,19.9160958 7.77811543,19.2941776 Z"
                          fill="#FFD7A0"
                        />
                        <path
                          d="M6.66695609,20.4202969 C7.28061236,20.4202969 7.77811543,19.9160958 7.77811543,19.2941776 L7.77811543,14.7897004 C7.77811543,14.1677822 7.28061236,13.663581 6.66695609,13.663581 L5.74098996,13.663581 L5.74098996,20.4202969 L6.66695609,20.4202969 Z"
                          fill="#FFB042"
                        />
                      </g>
                      <path
                        d="M8.51888833,1.12611932 C8.51888833,0.504201155 8.02138525,0 7.40772899,0 L5.74098996,0 L5.74098996,4.12910416 L8.51888833,4.12910416 L8.51888833,1.12611932 Z"
                        id="Path"
                        fill="#896645"
                      />
                    </g>
                    <g id="Group" transform="translate(12.236405, 0.000000)">
                      <g id="Path">
                        <g>
                          <path
                            d="M9.57589766,8.70032277 L8.46428007,7.56654584 L8.46428007,4.12910416 C8.46428007,3.507186 7.96996612,3.00298484 7.36024354,3.00298484 L4.04813395,3.00298484 C3.43841137,3.00298484 2.94409742,3.507186 2.94409742,4.12910416 L2.94409742,7.56654584 L1.83240623,8.70047291 C0.650792734,9.90579596 0,11.5082637 0,13.2127579 L0,13.663581 L5.70418875,20.4202969 L10.304341,20.4202969 C10.9140635,20.4202969 11.4083775,19.9160958 11.4083775,19.2941776 L11.4083775,13.2127579 C11.4083775,11.5202757 10.7491941,9.8970873 9.57589766,8.70032277 Z"
                            fill="#BEE75E"
                          />
                          <path
                            d="M9.57589766,8.70032277 L8.46428007,7.56654584 L8.46428007,4.12910416 C8.46428007,3.507186 7.96996612,3.00298484 7.36024354,3.00298484 L5.70418875,3.00298484 L5.70418875,20.4202969 L10.304341,20.4202969 C10.9140635,20.4202969 11.4083775,19.9160958 11.4083775,19.2941776 L11.4083775,13.2127579 C11.4083775,11.5202757 10.7491941,9.8970873 9.57589766,8.70032277 Z"
                            fill="#00CB75"
                          />
                          <path
                            d="M8.46428007,1.12611932 C8.46428007,0.504201155 7.96996612,0 7.36024354,0 L4.04813395,0 C3.43841137,0 2.94409742,0.504201155 2.94409742,1.12611932 L2.94409742,4.12910416 L8.46428007,4.12910416 L8.46428007,1.12611932 Z"
                            fill="#AF8663"
                          />
                        </g>
                        <path
                          d="M7.72825572,19.2941776 L7.72825572,14.7897004 C7.72825572,14.1677822 7.23394176,13.663581 6.62421919,13.663581 L0,13.663581 L0,19.2941776 C0,19.9160958 0.494313956,20.4202969 1.10403653,20.4202969 L6.62421919,20.4202969 C7.23394176,20.4202969 7.72825572,19.9160958 7.72825572,19.2941776 Z"
                          fill="#FFD7A0"
                        />
                        <path
                          d="M6.62421919,20.4202969 C7.23394176,20.4202969 7.72825572,19.9160958 7.72825572,19.2941776 L7.72825572,14.7897004 C7.72825572,14.1677822 7.23394176,13.663581 6.62421919,13.663581 L5.70418875,13.663581 L5.70418875,20.4202969 L6.62421919,20.4202969 Z"
                          fill="#FFB042"
                        />
                      </g>
                      <path
                        d="M8.46428007,1.12611932 C8.46428007,0.504201155 7.96996612,0 7.36024354,0 L5.70418875,0 L5.70418875,4.12910416 L8.46428007,4.12910416 L8.46428007,1.12611932 Z"
                        id="Path"
                        fill="#896645"
                      />
                    </g>
                    <g id="Group" transform="translate(2.152871, 0.000000)">
                      <g id="Path">
                        <g>
                          <path
                            d="M9.63767764,8.70032277 L8.51888833,7.56654584 L8.51888833,4.12910416 C8.51888833,3.507186 8.02138525,3.00298484 7.40772899,3.00298484 L4.07425094,3.00298484 C3.46059467,3.00298484 2.96309159,3.507186 2.96309159,4.12910416 L2.96309159,7.56654584 L1.84422821,8.70047291 C0.65491732,9.90572088 0,11.5082637 0,13.2127579 L0,13.663581 L5.74098996,20.4202969 L10.3708206,20.4202969 C10.9844768,20.4202969 11.4819799,19.9160958 11.4819799,19.2941776 L11.4819799,13.2127579 C11.4819799,11.5202757 10.8185437,9.8970873 9.63767764,8.70032277 Z"
                            fill="#BEE75E"
                          />
                          <path
                            d="M9.63767764,8.70032277 L8.51888833,7.56654584 L8.51888833,4.12910416 C8.51888833,3.507186 8.02138525,3.00298484 7.40772899,3.00298484 L5.74098996,3.00298484 L5.74098996,20.4202969 L10.3708206,20.4202969 C10.9844768,20.4202969 11.4819799,19.9160958 11.4819799,19.2941776 L11.4819799,13.2127579 C11.4819799,11.5202757 10.8185437,9.8970873 9.63767764,8.70032277 Z"
                            fill="#00CB75"
                          />
                          <path
                            d="M8.51888833,1.12611932 C8.51888833,0.504201155 8.02138525,0 7.40772899,0 L4.07425094,0 C3.46059467,0 2.96309159,0.504201155 2.96309159,1.12611932 L2.96309159,4.12910416 L8.51888833,4.12910416 L8.51888833,1.12611932 Z"
                            fill="#AF8663"
                          />
                        </g>
                        <path
                          d="M7.77811543,19.2941776 L7.77811543,14.7897004 C7.77811543,14.1677822 7.28061236,13.663581 6.66695609,13.663581 L0,13.663581 L0,19.2941776 C0,19.9160958 0.497503079,20.4202969 1.11115935,20.4202969 L6.66695609,20.4202969 C7.28061236,20.4202969 7.77811543,19.9160958 7.77811543,19.2941776 Z"
                          fill="#FFD7A0"
                        />
                        <path
                          d="M6.66695609,20.4202969 C7.28061236,20.4202969 7.77811543,19.9160958 7.77811543,19.2941776 L7.77811543,14.7897004 C7.77811543,14.1677822 7.28061236,13.663581 6.66695609,13.663581 L5.74098996,13.663581 L5.74098996,20.4202969 L6.66695609,20.4202969 Z"
                          fill="#FFB042"
                        />
                      </g>
                      <path
                        d="M8.51888833,1.12611932 C8.51888833,0.504201155 8.02138525,0 7.40772899,0 L5.74098996,0 L5.74098996,4.12910416 L8.51888833,4.12910416 L8.51888833,1.12611932 Z"
                        id="Path"
                        fill="#896645"
                      />
                    </g>
                    <polygon
                      id="Path"
                      fill="#896645"
                      points="0.717623745 18.9617043 35.8811873 18.9617043 35.8811873 36.464816 0.717623745 36.464816"
                    />
                    <polygon
                      id="Path"
                      fill="#63544F"
                      points="18.6582174 18.9617043 35.8811873 18.9617043 35.8811873 36.464816 18.6582174 36.464816"
                    />
                    <path
                      d="M36.2231798,17.5031117 L1.09325492,17.5031117 C0.489486672,17.5031117 0,18.0110476 0,18.6375726 L0,36.7889477 C0,37.4154726 0.489486672,37.9234086 1.09325492,37.9234086 L36.2231798,37.9234086 C36.8269481,37.9234086 37.3164348,37.4154726 37.3164348,36.7889477 L37.3164348,18.6375726 C37.3164348,18.0110476 36.8269481,17.5031117 36.2231798,17.5031117 Z M7.36124983,31.116643 L7.36124983,28.8477211 L29.9551849,28.8477211 L29.9551849,31.116643 L7.36124983,31.116643 Z M29.9551849,33.3855648 L29.9551849,35.6544867 L7.36124983,35.6544867 L7.36124983,33.3855648 L29.9551849,33.3855648 Z M29.9551849,24.3098773 L29.9551849,26.5787992 L7.36124983,26.5787992 L7.36124983,24.3098773 L29.9551849,24.3098773 Z M7.36124983,22.0409554 L7.36124983,19.7720335 L29.9551849,19.7720335 L29.9551849,22.0409554 L7.36124983,22.0409554 Z M35.1299249,26.5787992 L32.1416948,26.5787992 L32.1416948,24.3098773 L35.1299249,24.3098773 L35.1299249,26.5787992 Z M5.17473998,26.5787992 L2.18650985,26.5787992 L2.18650985,24.3098773 L5.17473998,24.3098773 L5.17473998,26.5787992 Z M2.18650985,28.8477211 L5.17473998,28.8477211 L5.17473998,31.116643 L2.18650985,31.116643 L2.18650985,28.8477211 Z M32.1416948,28.8477211 L35.1299249,28.8477211 L35.1299249,31.116643 L32.1416948,31.116643 L32.1416948,28.8477211 Z M35.1299249,22.0409554 L32.1416948,22.0409554 L32.1416948,19.7720335 L35.1299249,19.7720335 L35.1299249,22.0409554 Z M2.18650985,19.7720335 L5.17473998,19.7720335 L5.17473998,22.0409554 L2.18650985,22.0409554 L2.18650985,19.7720335 Z M2.18650985,33.3855648 L5.17473998,33.3855648 L5.17473998,35.6544867 L2.18650985,35.6544867 L2.18650985,33.3855648 Z M35.1299249,35.6544867 L32.1416948,35.6544867 L32.1416948,33.3855648 L35.1299249,33.3855648 L35.1299249,35.6544867 Z"
                      id="Shape"
                      fill="#AF8663"
                    />
                    <path
                      d="M36.2231798,17.5031117 L18.6582174,17.5031117 L18.6582174,19.7720335 L29.9551849,19.7720335 L29.9551849,22.0409554 L18.6582174,22.0409554 L18.6582174,24.3098773 L29.9551849,24.3098773 L29.9551849,26.5787992 L18.6582174,26.5787992 L18.6582174,28.8477211 L29.9551849,28.8477211 L29.9551849,31.116643 L18.6582174,31.116643 L18.6582174,33.3855648 L29.9551849,33.3855648 L29.9551849,35.6544867 L18.6582174,35.6544867 L18.6582174,37.9234086 L36.2231798,37.9234086 C36.8269481,37.9234086 37.3164348,37.4154726 37.3164348,36.7889477 L37.3164348,18.6375726 C37.3164348,18.0110476 36.8269481,17.5031117 36.2231798,17.5031117 Z M35.1299249,35.6544867 L32.1416948,35.6544867 L32.1416948,33.3855648 L35.1299249,33.3855648 L35.1299249,35.6544867 Z M35.1299249,31.116643 L32.1416948,31.116643 L32.1416948,28.8477211 L35.1299249,28.8477211 L35.1299249,31.116643 Z M35.1299249,26.5787992 L32.1416948,26.5787992 L32.1416948,24.3098773 L35.1299249,24.3098773 L35.1299249,26.5787992 Z M35.1299249,22.0409554 L32.1416948,22.0409554 L32.1416948,19.7720335 L35.1299249,19.7720335 L35.1299249,22.0409554 Z"
                      id="Shape"
                      fill="#896645"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Marketplace;
