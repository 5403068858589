import * as MonthlyKPIService from "../../services/agentKPI/monthlyKPI/MonthlyKPIService";
import MonthlyKPIDomain from "../../domains/agentKPI/MonthlyKPIDomain";

export function execute(
  agentId: string,
  country: string,
  vendorId: string,
  vendorName: string,
): Promise<MonthlyKPIDomain> {
  return MonthlyKPIService.getMonthlyKPI(
    agentId,
    country,
    vendorId,
    vendorName,
  );
}
