import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      marginBottom: ".3rem",
    },
    title: {
      color: "#141414",
      fontFamily: "Work Sans",
      fontSize: ".75rem",
      fontStyle: "normal",
      fontWeight: 600,
    },
    description: {
      color: "#757575",
      fontFamily: "Work Sans",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: 400,
    },
    icon: {
      width: "1.5rem",
      height: "1.5rem",
      marginRight: ".25rem",
      color: "#0363C4",
    },
    child: {
      display: "grid",
      padding: "0 .40rem",
      marginTop: ".50rem",
      gap: ".5rem",
    },
  }),
);
