import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginRight: "6px",
    },
    text: {
      marginTop: "1px",
      fontSize: "12px",
      textTransform: "capitalize",
    },
  }),
);
