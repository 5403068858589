/* istanbul ignore file */
import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: "24px",
      height: "32px",
      margin: "0 402px 32px 0",
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
  }),
);
