import {
  ComboInterface,
  ComboItem,
} from "@services/promotions/domains/CombosResponseV2";
import { updateOrAddItem } from "@stores/cart/CartItemEvents";

interface CartItemsProps {
  price: number;
  unitPrice: number;
  sku: string;
  total: number;
  quantity: number;
  itemQuantity: number;
  platformId: string;
}

interface ComboRef {
  platformId: string;
  total: number;
  itemQuantity: number;
  comboId: string;
}

const getItemInRef = (totalComboRef, outsidePlatformId, outsideComboId) => {
  return totalComboRef.current.find(
    ({ platformId, comboId }) =>
      platformId === outsidePlatformId && comboId === outsideComboId,
  );
};

export const getItemsToBeInTruck = (
  totalComboRef: { current: ComboRef[] },
  combos: ComboInterface[],
): (ComboItem & { itemQuantity: number })[] => {
  const itemsToBeInTruck = [] as (ComboItem & { itemQuantity: number })[];
  combos.forEach((combo) =>
    combo.items.forEach((item) => {
      const itemInRef = getItemInRef(totalComboRef, item.platformId, combo.id);
      if (itemInRef)
        itemsToBeInTruck.push({
          ...item,
          itemQuantity: itemInRef.itemQuantity,
        });
    }),
  );
  return itemsToBeInTruck;
};

export const sendItemsToCart = (
  itemsToBeInTruck: (ComboItem & { itemQuantity: number })[],
): void => {
  itemsToBeInTruck.forEach((product) => {
    const cartItems = {
      quantity: product.itemQuantity,
      itemQuantity: product.itemQuantity,
      platformId: product.platformId,
    };
    updateOrAddItem(cartItems as CartItemsProps);
  });
};
