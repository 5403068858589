/* eslint-disable react/require-default-props */
import React from "react";
import { useTranslation } from "react-i18next";
import WarningStyles from "./StockoutWarning.styles";
import WarningIcon from "../../assets/newIcons/WarningIcon";

interface Props {
  value: number;
  itemQuantity?: number;
  isDisplayStockCatalogEnabled?: boolean;
}

const StockoutWarning = ({
  value = 0,
  itemQuantity = 0,
  isDisplayStockCatalogEnabled,
}: Props): React.ReactElement => {
  const classes = WarningStyles();
  const { t } = useTranslation();

  /* istanbul ignore next */
  return (
    <div className={classes.container}>
      {(!isDisplayStockCatalogEnabled || itemQuantity > value) && (
        <WarningIcon className={classes.icon} />
      )}
      <span data-testid="stock-warning-message" className={classes.text}>
        {`${value} ${t("SuggestedOrder.STOCK_ITEMS_LEFT")}`}
      </span>
    </div>
  );
};

export default StockoutWarning;
