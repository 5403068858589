import React from "react";
import {
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import {
  clearItemCart,
  deleteCartSimulationEffect,
} from "../../../../../stores/cart/CartItemEvents";
import {
  resetCombos,
  clearComboCart,
} from "../../../../../stores/cartCombo/CartComboEvents";
import clearCartModalStyles from "../../modal/CTIModalGenericStyles";
import {
  GROW_CART_SERVICE_V4,
  isFeatureEnabledV2,
} from "../../../../../config/featureToggles";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import cartItemStore from "../../../../../stores/cart/CartItemStore";

export interface Props {
  open: boolean;
  onClose: () => void;
}
const ClearCartModal: React.FC<Props> = ({ onClose, open }) => {
  const classes = clearCartModalStyles();
  const { t } = useTranslation();
  const GlobalStates = useStore(GlobalStore);
  const { cart } = useStore(cartItemStore);

  const isCartSimulationV4Enabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    GlobalStates.user.keyToggle,
  );

  const clearCartList = () => {
    clearItemCart();
    if (isCartSimulationV4Enabled) {
      deleteCartSimulationEffect(cart.cartId ?? "");
    }
    resetCombos();
    clearComboCart();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        {t("OrderTaking.CLEAR_CART")}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogContentText}>
          <span className={classes.message}>
            {t("OrderTaking.MSG_CLEAR_CART")}
          </span>
          <span className={classes.confirmMessage}>
            {t("OrderTaking.MSG_CLEAR_CART_QUESTION")}
          </span>
        </DialogContentText>

        <DialogActions className={classes.dialogActions}>
          <ButtonBase
            onClick={onClose}
            className={`${classes.button} ${classes.cancelButton}`}
            data-testid="close-clear-cart-modal-button"
          >
            {t("OrderTaking.CANCEL_CLEAN")}
          </ButtonBase>
          <ButtonBase
            onClick={clearCartList}
            className={`${classes.button} ${classes.confirmButton}`}
            data-testid="confirm-clear-cart-button"
          >
            {t("OrderTaking.CONFIRM_CLEAN")}
          </ButtonBase>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ClearCartModal;
