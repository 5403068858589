import { useLogService } from "admin-portal-shared-services";
import CallDuration from "../../stores/callDuration/CallDurationState";
import axios from "../../config/axios/axiosInstance";
import { getApiHost } from "../host/HostService";
import { GROW_ADMIN_CONFIG_SERVICE } from "../../config/services";

const MIN_DURATION_CALL_URL = "/grow-admin-config/min-duration-call";

export async function getCallDuration(vendorId: string): Promise<CallDuration> {
  const log = useLogService();
  const urlAgentCall = `${getApiHost(
    GROW_ADMIN_CONFIG_SERVICE,
  )}${MIN_DURATION_CALL_URL}`;

  try {
    const response = await axios.get<CallDuration>(
      `${urlAgentCall}?vendorId=${vendorId}`,
    );
    return response.data;
  } catch (error) {
    log.error("CallDurationService - get error: ", error);
    throw error;
  }
}
