import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    stockText: {
      marginTop: "-0.625rem",
      textAlign: "center",
    },
    lineThrough: {
      textDecoration: "line-through",
    },
    steppedDiscountText: {
      fontFamily: "Work Sans",
      color: "#757575",
      fontSize: "0.75rem",
      fontWeight: 400,
      textDecoration: "line-through",
    },
  }),
);
