import axiosRetry from "axios-retry";
import { useLogService } from "admin-portal-shared-services";
import qs from "qs";
import axios from "../../config/axios/axiosInstance";
import { MissionsConfigObject } from "../../domains/missions/MissionsConfigObject";
import {
  isFeatureEnabledV2,
  TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
  GROW_TECH_SEARCHPOC_BY_USERCONFIG_BEESGR_16675,
} from "../../config/featureToggles";
import { getCountryByToken } from "../../config/utils/functions";
import { MissionsResponseToMissionsConfigConverter } from "../../converters/missions/MissionsResponseToMissionsConfigConverter";
import { LocalGlobalFilterEnum } from "../../config/constants";
import GlobalAdminConfigStore from "../../stores/globaAdminConfig/GlobalAdminConfigStore";
import { getApiHost } from "../host/HostB2BService";

const ENDPOINT = "/grow-missions-service/grow-missions/missions/search-by-poc";
const ENDPOINT_V2 =
  "/grow-missions-service/grow-missions/v2/missions/search-by-poc";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export const getGlobalAndLocalMissionsBySearchByPocService = (
  vendorAccountId: string,
  vendorId?: string,
  vendorName?: string,
  LocalGlobalMissionsType?: LocalGlobalFilterEnum,
): Promise<MissionsConfigObject> => {
  const log = useLogService();

  const isMulticontractGrowMissionsV2Enable = isFeatureEnabledV2(
    TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
    getCountryByToken(),
  );

  const isFilterByUserOrBuConfig = isFeatureEnabledV2(
    GROW_TECH_SEARCHPOC_BY_USERCONFIG_BEESGR_16675,
    getCountryByToken(),
  );

  const endpoint = isMulticontractGrowMissionsV2Enable ? ENDPOINT_V2 : ENDPOINT;
  const url = `${getApiHost()}${endpoint}`;
  let params: {
    vendorId?: string;
    accountId: string;
    localGlobalFilter?: LocalGlobalFilterEnum;
    buConfigPriority?: Array<string>;
    userConfigPriority?: Array<string>;
  } = isMulticontractGrowMissionsV2Enable
    ? {
        vendorId: vendorId ?? "",
        accountId: vendorAccountId,
        localGlobalFilter: LocalGlobalMissionsType,
      }
    : {
        accountId: vendorAccountId,
        localGlobalFilter: LocalGlobalMissionsType,
      };

  if (isFilterByUserOrBuConfig) {
    const { buConfig, userConfig } = GlobalAdminConfigStore.getState();

    params = {
      ...params,
      buConfigPriority: buConfig.missionPriority,
      userConfigPriority: userConfig.missionPriority,
    };
  }

  const headers =
    vendorName === "BRF" && !isMulticontractGrowMissionsV2Enable
      ? {
          vendorId,
          vendorName,
        }
      : undefined;

  return axios
    .get(url, {
      params,
      headers,
      paramsSerializer: /* istanbul ignore next */ (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    })
    .then((response) => {
      log.info(`Convert Missions received from ${url} to our model`);
      return MissionsResponseToMissionsConfigConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
};
