import { createStore } from "effector";
import { RewardsChallengeState } from "../../domains/rewardsChallenges/RewardsChallenges";
import {
  getRewardsChallengeEffect,
  getRewardsChallengeEffectV2,
  resetRewards,
} from "./RewardsChallengeEvents";

const initialState = <RewardsChallengeState>{
  done: false,
  isLoading: true,
  error: false,
  started: 0,
  failed: 0,
  completed: 0,
  rewardsChallengeInformation: [],
};

const updateLoadingState = (state: RewardsChallengeState) => ({
  ...state,
  done: false,
  isLoading: true,
  error: false,
});

const updateCompletedState = (state: RewardsChallengeState, { result }) => ({
  ...state,
  done: true,
  isLoading: false,
  error: false,
  started: result.started,
  failed: result.failed,
  completed: result.completed,
  rewardsChallengeInformation: result.rewardsChallengeInformation,
});

const updateErrorState = (state: RewardsChallengeState) => ({
  ...state,
  done: true,
  isLoading: false,
  error: true,
});

const RewardsChallengeStore = createStore(initialState)
  .on(getRewardsChallengeEffect, updateLoadingState)
  .on(getRewardsChallengeEffect.fail, updateErrorState)
  .on(getRewardsChallengeEffect.done, updateCompletedState)

  .on(getRewardsChallengeEffectV2, updateLoadingState)
  .on(getRewardsChallengeEffectV2.fail, updateErrorState)
  .on(getRewardsChallengeEffectV2.done, updateCompletedState)
  .reset(resetRewards);

export default RewardsChallengeStore;
