import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    tableContainer: {
      boxShadow: "none",
      borderRadius: "0px",
      backgroundColor: "white",
      padding: "0px !important",
    },
    tableItemCell: {
      fontWeight: 500,
      fontSize: "11px",
      color: "#343433",
      padding: "12px 0",
      lineHeight: 1.33,
    },
    tableHeaderCell: {
      fontWeight: 600,
      fontSize: "12px",
      color: palette.text.primary,
      border: 0,
    },
    row: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    icon: {
      borderRadius: "3px",
      backgroundColor: `${palette.grey[300]} !important`,
      color: palette.common.white,
      height: "15px",
      width: "15px",
    },
    iconButton: {
      height: "15px",
      width: "15px",
      marginBottom: "3px",
    },
    iconCell: {
      backgroundColor: "#fafafa !important",
      padding: "9px 12px 9px 0",
    },
    iconCellExpanded: {
      backgroundColor: "#f2f2f2 !important",
    },
    subCell: {
      backgroundColor: "#f8f8f8",
      paddingBottom: 0,
      paddingTop: 0,
      paddingLeft: 35,
      borderBottom: `1px solid ${palette.grey[100]}`,
    },
    subCellOpened: {
      padding: "0px 0px 0px 33px",
      backgroundColor: "#f8f8f8",
      borderBottom: "none",
    },
    tooltip: {
      position: "relative",
      "&:hover:after": {
        backgroundColor: "#505050",
        borderRadius: "2px",
        bottom: "-20px",
        color: "#fff",
        content: "attr(title)",
        left: "0%",
        position: "absolute",
        zIndex: "98",
        height: "34px",
        padding: "12px",
        boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.4)",
        textAlign: "center",
      },
      "&:hover:before": {
        border: "solid",
        borderColor: "#505050 transparent",
        borderWidth: "0 6px 6px 6px",
        bottom: "-4px",
        content: "",
        left: "0%",
        position: "absolute",
        zIndex: "99",
      },
    },
  }),
);
