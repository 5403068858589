import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      background: "#ffffff",
      height: "24px",
      borderRadius: 3,
    },
    select: {
      height: "14px",
      padding: "8px",
      width: "270px",
    },
    input: {
      fontSize: "12px",
    },
  }),
);
