export enum SUGGESTED_ORDER_FIELDS {
  MC = "mc",
  PRICE_PER_UOM = "pricePerUoM",
  UNIT_PRICE = "unitPrice",
  BOXES_ORDER = "boxesOrder",
  SUGGESTED_ORDER = "suggestedOrder",
  INVENTORY_COUNT = "inventoryCount",
  PACKAGE_TYPE = "packageType",
  GAP_BOXES = "gapBoxes",
  TOTAL_PRICE = "totalPrice",
}

export enum SORT_DIRECTION {
  ASC = "asc",
  DESC = "desc",
}
