import { createStyles, makeStyles } from "@material-ui/core/styles";

const SalesStructureContainerStyles = makeStyles(() =>
  createStyles({
    container: {
      marginLeft: "28px",
    },
  }),
);

export default SalesStructureContainerStyles;
