import React from "react";
import { useTranslation } from "react-i18next";
import { FreeGood } from "../../../../../services/promotions/domains/PromotionsResponseV3";
import { FormatedPromotion } from "../../../../../domains/promotions/FormatedPromotion";
import SteppedFreeGoodSectionStyles from "./SteppedFreeGoodSection.styles";

interface Props {
  promotion: FormatedPromotion;
}

const SteppedFreeGoodSection: React.FC<Props> = ({ promotion }) => {
  const classes = SteppedFreeGoodSectionStyles();
  const { t } = useTranslation();

  const steppedFreeGoodPrefix = "stepped-free-good";

  const getStep = (item: FreeGood) => {
    return ` ${item.stepStart} ${t("Promotions.TO")} ${item.stepEnd}`;
  };

  const getKey = (id: string, index: number) => `${id}-${index}`;

  return (
    <div className={classes.freeGoodContainer}>
      {promotion.freegoods?.map((item) => (
        <div className={classes.steppedContainer} key={promotion.key}>
          <span
            data-testid={`${steppedFreeGoodPrefix}-quantity-${item.stepStart}-to-${item.stepEnd}`}
          >
            {t("Promotions.QUANTITY")}:{getStep(item)}
          </span>
          <div className={classes.freeGoodTitle}>
            {t("Promotions.WIN_ALL")}:
          </div>
          {item.freegoodItems?.map((option, promotionIndex) => (
            <div
              className={classes.freeGoodItem}
              key={getKey(option.itemId, promotionIndex)}
              data-testid={`${steppedFreeGoodPrefix}-${option.sku}-${option.itemId}`}
            >
              {`${option.quantity} ${option.name}`}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SteppedFreeGoodSection;
