import { createEvent } from "effector";
import Toast from "../../domains/Toast";
import { LanguageOption } from "../../i18n/domains/LanguageOptions";

interface User {
  login: string;
  zone: string;
  keyToggle: string;
}

export const loadBackDrop = createEvent<null>("loadBackDrop");
export const loadBackDropDone = createEvent<null>("loadBackDropDone");
export const toast = createEvent<Toast>("toast");
export const toastHide = createEvent<null>("toastHide");
export const changeSelectedLanguage = createEvent<LanguageOption>(
  "changeSelectedLanguage",
);
export const setUser = createEvent<User>("setUser");
export const setSegmentation = createEvent<string[]>("setSegmentation");

export const setIsIdentified = createEvent<boolean>("setIsIdentified");
