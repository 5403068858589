/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Typography, IconButton } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import classNames from "classnames";
import Carousel from "react-material-ui-carousel";
import TabIdsStyles from "./TabIdsStyles";
import Data from "../../../../../../components/data/Data";

interface Props {
  challengeIds?: Array<string>;
}

interface NavButtonProps {
  prev: boolean;
  onClick: any;
  challengeIds: Array<string>;
  classes: any;
}

const ContractlessTab = ({ challengeIds }: Props) => {
  const classes = TabIdsStyles();

  if (!challengeIds || challengeIds?.length === 0)
    return (
      <span data-testid="contractless-empty">
        <Typography component="h5" variant="h5" className={classes.id}>
          <Data value={undefined} />
        </Typography>
      </span>
    );

  return (
    <span data-testid="tabids-tab-content-erpid">
      <Carousel
        autoPlay={false}
        className={classes.carousel}
        data-testid="erpid-carouse"
        animation="slide"
        indicatorIconButtonProps={{
          className: "indicator-button",
          style: {
            fontSize: "8px !important",
          },
        }}
        activeIndicatorIconButtonProps={{
          className: "active",
          style: {
            color: "yellow",
          },
        }}
        NavButton={(props) => (
          <NavButton {...props} challengeIds={challengeIds} classes={classes} />
        )}
      >
        {challengeIds?.map((item, index) => (
          <Typography
            component="h5"
            variant="h5"
            data-testid={`erpid-carousel-id-${item}`}
            className={classes.carouselItem}
            key={item}
          >
            <Data testId={`challenge-id-${index}`} value={item} />
          </Typography>
        ))}
      </Carousel>
    </span>
  );
};

export default React.memo(ContractlessTab);

const NavButton = ({
  prev,
  onClick,
  challengeIds,
  classes,
}: NavButtonProps) => {
  return (
    <IconButton
      onClick={() => onClick()}
      className={classes.carouselButton}
      size="small"
      disabled={challengeIds?.length === 1}
      data-testid={prev ? "carousel-prev-button" : "carousel-next-button"}
    >
      {prev ? (
        <ArrowBackIosIcon
          style={{ marginLeft: "6px" }}
          className={classNames(classes.carouselIcon, {
            [classes.carouselIconActive]: challengeIds?.length > 1,
          })}
          data-testid="back-button-icon"
        />
      ) : (
        <ArrowForwardIosIcon
          className={classNames(classes.carouselIcon, {
            [classes.carouselIconActive]: challengeIds?.length > 1,
          })}
          fontSize="inherit"
          data-testid="foward-button-icon"
        />
      )}
    </IconButton>
  );
};
