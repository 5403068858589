import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      borderRadius: "10px",
      width: "100%",
      margin: "0 86px 11px 0",
      alignSelf: "center",
      fontFamily: "Barlow",
    },
    title: {
      width: "100%",
      height: "20px",
      margin: "0 0 4px 0",
      fontFamily: "Barlow",
      fontSize: "16px",
      fontWeight: 600,
      fontStretch: "normal",
      lineHeight: "1.25",
      letterSpacing: "normal",
      color: "#212121",
    },
    card: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      margin: "11px 0 16px",
      padding: "16px 18px 18px 16px",
      borderRadius: "16px",
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.16)",
    },
    tag: {
      fontSize: "14px",
      height: "16px",
      margin: "4px 19px 6px 0",
      padding: "1px 9.2px 2px 9px",
      borderRadius: "16px",
      backgroundColor: "#d2adff",
      fontWeight: 500,
    },
    tooltip: {
      width: "18px",
      height: "18px",
      paddingBottom: "2px",
    },
    svg: {
      width: "49px",
      height: "49px",
      margin: "0 11px 0 0",
      objectFit: "contain",
    },
    titleContainer: {
      width: 150,
    },
    verticalCenter: {
      margin: "auto 0",
    },
  }),
);
