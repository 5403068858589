import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    skuColor: {
      color: palette.text.secondary,
    },
    skuContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "8px",
    },
    price: {
      color: "#007a78",
      marginLeft: "4px",
      fontWeight: 600,
    },
    freeGoodContainer: {
      display: "flex",
      flexDirection: "column",
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "normal",
      color: palette.secondary.main,
    },
    freeGoodTitle: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "normal",
      color: palette.secondary.main,
      marginBottom: "4px",
      marginTop: "2px",
      textDecoration: "underline",
    },
    steppedContainer: {
      margin: "0px 0px 14px 0px",

      "&:last-child": {
        margin: 0,
      },
    },
    freeGoodItem: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "normal",
      color: "#148cc6",
      marginBottom: "4px",
    },
  }),
);
