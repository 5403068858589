import React, { useMemo, useState } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { LinearProgress } from "@material-ui/core";
import GeneralIcon from "../../../../assets/GeneralIcon";
import GeneralInformationIcon from "../../../../assets/GeneralInformationIcon";
import { getValueOrNull } from "../../../../config/utils/functions";
import { ANALYTICS_ROUTE_NAMES } from "../../../../config/constants";
import {
  TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT,
  TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT_ADMIN,
  isFeatureEnabled,
} from "../../../../config/featureToggles";
import { callUxButtonClicked } from "../../../../config/typewriter";
import { getLastMarketplaceOrders } from "../../../../stores/lastMarketplaceOrders/LastMarketplaceOrdersEvents";
import LastMarketplaceOrdersStore from "../../../../stores/lastMarketplaceOrders/LastMarketplaceOrdersStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import CampaignsStore from "../../../../stores/campaigns/CampaignsStore";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import LastOrdersStore from "../../../../stores/lastOrders/LastOrdersStore";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";
import * as LastOrdersUseCase from "../../../../usecase/lastOrders/LastOrdersUseCase";
import MissionInformation from "../tabsMissions/missionInformation/MissionInformation";
import MissionLastOrderContainer from "../missionLastOrderContainer/MissionLastOrderContainer";
import MissionsFocusSkuComponent from "../missionsFocusSkuComponent/MissionsFocusSkuComponent";
import GlobalAdminConfigStore from "../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import useStyle from "./styles";

const GeneralComponent: React.FC = () => {
  const classes = useStyle();
  const { t } = useTranslation();

  const [lastOrderOpen, setLastOrderOpen] = useState(true);
  const [missionsProductsOpen, setMissionsProductsOpen] = useState(true);

  const { user } = useStore(GlobalStore);
  const { callTab } = useStore(CallTabStore);
  const { callId, clientId } = useStore(AgentCallStore);
  const { orders, isLoading, error } = useStore(LastOrdersStore);
  const { loading, validCampaigns = [] } = useStore(CampaignsStore);
  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { vendorId } = userConfig;
  const {
    isLoading: isLoadingMarketplace,
    lastOrders,
    error: errorMarketplace,
  } = useStore(LastMarketplaceOrdersStore);

  const hasCampaigns = useMemo(
    () => validCampaigns.length > 0,
    [validCampaigns],
  );

  const isLastMarketplaceOrdersEndpointEnabled = isFeatureEnabled(
    TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT,
    TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT_ADMIN,
    user.keyToggle,
  );

  const callLastOrders = () => {
    if (isLastMarketplaceOrdersEndpointEnabled) {
      getLastMarketplaceOrders(clientId);
    } else {
      LastOrdersUseCase.execute(clientId, vendorId);
    }
  };

  const handleOpenLastOrder = () => {
    const buttonName = lastOrderOpen
      ? "last-orders-component-collapse"
      : "last-orders-component-expand";
    const buttonLabel = !lastOrderOpen ? "expand" : "collapse";
    const screenSection = "Last Orders";
    callEventCallUx(buttonName, buttonLabel, screenSection);
    setLastOrderOpen(!lastOrderOpen);
  };

  const refreshLastOrders = () => {
    callEventCallUx("Refresh", "call-last-orders-refresh", "Last Orders");

    callLastOrders();
  };

  const handleOpenMissionsProducts = () => {
    const buttonName = missionsProductsOpen
      ? "target-products-component-collapse"
      : "target-products-component-expand";
    const buttonLabel = !missionsProductsOpen ? "expand" : "collapse";
    const screenSection = "Target products";
    callEventCallUx(buttonName, buttonLabel, screenSection);
    setMissionsProductsOpen(!missionsProductsOpen);
  };

  const callEventCallUx = (
    buttonName: string,
    buttonLabel: string,
    screenSection: string,
  ) => {
    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: buttonName,
      button_label: buttonLabel,
      screen_section: screenSection,
      filter_option: null,
      call_id: callId,
      order_date: null,
      position: null,
    });
  };

  if (loading) {
    return (
      <LinearProgress
        data-testid="navigation-linear-progress"
        classes={{
          root: classes.root,
          barColorPrimary: classes.barColorPrimary,
          colorPrimary: classes.colorPrimary,
        }}
      />
    );
  }

  return (
    <>
      <MissionInformation
        Icon={hasCampaigns ? GeneralInformationIcon : GeneralIcon}
        title={
          hasCampaigns
            ? "MissionsDescription.GENERAL_TITLE_WITH_CAMPAIGNS"
            : "MissionsDescription.GENERAL_TITLE"
        }
        description={
          hasCampaigns
            ? "MissionsDescription.GENERAL_DESCRIPTION_WITH_CAMPAIGNS"
            : "MissionsDescription.GENERAL_DESCRIPTION"
        }
      />

      <div style={{ flex: 1 }}>
        <MissionLastOrderContainer
          error={
            isLastMarketplaceOrdersEndpointEnabled ? errorMarketplace : error
          }
          handleOpenLastOrder={handleOpenLastOrder}
          lastOrderOpen={lastOrderOpen}
          refreshLastOrders={refreshLastOrders}
          title={t("LastOrders.LAST_ORDERS_LABEL")}
          zone={user.keyToggle}
          titleUpdate={t("LastOrders.UPDATE_LIST")}
          orders={isLastMarketplaceOrdersEndpointEnabled ? lastOrders : orders}
          isLoading={
            isLastMarketplaceOrdersEndpointEnabled
              ? isLoadingMarketplace
              : isLoading
          }
          errorFunction={callLastOrders}
          data-testid="mission-last-order-general-container"
        />

        <MissionsFocusSkuComponent
          handleOpen={handleOpenMissionsProducts}
          open={missionsProductsOpen}
        />
      </div>
    </>
  );
};

export default React.memo(GeneralComponent);
