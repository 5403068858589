import { useLogService } from "admin-portal-shared-services";
import { isFeatureEnabled } from "grow-shared-services";
import axiosRetry from "axios-retry";
import axios from "../../../config/axios/axiosInstance";
import UpdateKPIDomain from "../../../domains/agentKPI/UpdateKPIDomain";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../../host/HostService";
import UpdateKPIResponse from "../domains/UpdateKPIResponse";
import UpdateKPIResponseToUpdateKPIConverter from "../../../converters/agentKPI/UpdateKPIResponseToUpdateKPIConverter";
import { AGENTS_KPI_SERVICE } from "../../../config/services";
import GlobalStore from "../../../stores/global/GlobalStore";
import VendorStore from "../../../stores/vendor/VendorStore";
import {
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_AGENTS_KPI_SERVICE,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../../config/featureToggles";

export const AGENTS_KPI_ENDPOINT = "/agents-kpi";
export const AGENTS_KPI_ENDPOINT_V3 = "/v3/agents";
export const UPDATE_KPIS_ENDPOINT = "update-kpis";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getUpdateKPI(
  agentId: string,
  country: string,
  vendorId: string,
  vendorName: string,
): Promise<UpdateKPIDomain> {
  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();
  const log = useLogService();

  const isOrganizationConfigEnabled = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const isTLPAgentsKPIMulticontract = isFeatureEnabledV2(
    TLP_MULTICONTRACT_AGENTS_KPI_SERVICE,
    user.zone,
  );

  const vendorIdMetadata = vendor?.id;
  const apiHost = getApiHost(AGENTS_KPI_SERVICE);
  const v2EndPoint = getV2EndPoint(isTLPAgentsKPIMulticontract);
  const vendorIdParam = getVendorIdParam(
    vendorIdMetadata,
    isTLPAgentsKPIMulticontract,
  );

  const url = isOrganizationConfigEnabled
    ? `${apiHost}${AGENTS_KPI_ENDPOINT_V3}/${agentId}/kpis/refresh`
    : `${apiHost}${AGENTS_KPI_ENDPOINT}${v2EndPoint}/${agentId}/${UPDATE_KPIS_ENDPOINT}${vendorIdParam}`;

  if (isOrganizationConfigEnabled) {
    return axios
      .put<UpdateKPIResponse>(url)
      .then((response) => {
        log.info(`Convert UpdateKPI score received from ${url} to our model`);
        return UpdateKPIResponseToUpdateKPIConverter(response.data);
      })
      .catch((error) => {
        log.error(error);
        throw error;
      });
  }

  return axios
    .post<UpdateKPIResponse>(url, "", {
      headers: {
        country,
        vendorId,
        vendorName,
      },
    })
    .then((response) => {
      log.info(`Convert UpdateKPI score received from ${url} to our model`);
      return UpdateKPIResponseToUpdateKPIConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
