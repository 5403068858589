import { useLogService } from "admin-portal-shared-services";
import axios, { CancelToken } from "axios";

import axiosInstance from "@config/axios/axiosInstance";

import {
  GROW_CART_SERVICE_V4,
  GROW_TECH_CART_AUTO_UPDATE,
  TLP_ORDER_TAKING_EMPTIES,
  isFeatureEnabledV2,
} from "../../config/featureToggles";
import { CALL_LIST_SERVICE } from "../../config/services";
import CartSimulationResponseToCartInfoConverter from "../../converters/cartSimulation/CartSimulationResponseToCartInfoConverter";
import StoresToCartSimulationRequestConverter from "../../converters/cartSimulation/StoresToCartSimulationRequestConverter";
import { CartInfo } from "../../domains/cart/CartInfo";
import AgentCallState from "../../stores/agentCall/AgentCallState";
import CartItemState from "../../stores/cart/CartItemState";
import CartState from "../../stores/cart/CartState";
import CartComboState from "../../stores/cartCombo/CartComboState";
import GlobalStore from "../../stores/global/GlobalStore";
import UpdateCanceledError from "../cart/domains/UpdateCanceledError";
import * as CartServiceV3 from "../cart/v3/CartServiceV3";
import { getApiHost } from "../host/HostService";
import CartSimulationRequest from "./domains/CartSimulationRequest";
import CartSimulationResponse from "./domains/CartSimulationResponse";

// This will be removed on https://ab-inbev.atlassian.net/browse/BEESGR-14349

const CART_ENDPOINT = "/call-list/cart-simulation";

export function updateCart(request: CartSimulationRequest): Promise<CartInfo> {
  const log = useLogService();
  const { language } = GlobalStore.getState();

  const endpoint = `${getApiHost(CALL_LIST_SERVICE)}${CART_ENDPOINT}`;

  return axiosInstance
    .post<CartSimulationResponse>(
      endpoint,
      {
        ...request,
      },
      {
        headers: {
          "Accept-Language": language.value,
        },
      },
    )
    .then((response) => {
      log.info(`Convert Cart Response received from ${endpoint} to our model`);
      return CartSimulationResponseToCartInfoConverter(
        response.data,
        request.accountId,
        request.paymentMethod,
        request.paymentTerm,
      );
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}

export function autoSaveCart(
  agentCallState: AgentCallState,
  cartState: CartState,
  cartComboState: CartComboState,
  cartItemsState: CartItemState,
  cancelRequestToken: CancelToken,
): Promise<CartInfo | null> {
  const { user, language } = GlobalStore.getState();
  const isAutoUpdateEnabled = isFeatureEnabledV2(
    GROW_TECH_CART_AUTO_UPDATE,
    user.keyToggle,
  );
  // Temporary validation. Should be removed with the temp toggle
  if (!isAutoUpdateEnabled) return Promise.resolve(null);

  const isCartSimulationV4Enabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    user.keyToggle,
  );

  // Temporary validation. Should be removed when all countries are multicontract
  if (!isCartSimulationV4Enabled) {
    return CartServiceV3.autoSaveCart(
      agentCallState,
      cartState,
      cartComboState,
      cartItemsState,
      cancelRequestToken,
    );
  }

  const isOrderTakingEmptiesEnabled = isFeatureEnabledV2(
    TLP_ORDER_TAKING_EMPTIES,
    user.keyToggle,
  );

  const request = StoresToCartSimulationRequestConverter(
    cartItemsState,
    agentCallState,
    cartState,
    cartComboState,
    isOrderTakingEmptiesEnabled,
  );

  const log = useLogService();

  const endpoint = `${getApiHost(CALL_LIST_SERVICE)}${CART_ENDPOINT}`;

  return axiosInstance
    .post<CartSimulationResponse>(endpoint, request, {
      headers: {
        "Accept-Language": language.value,
      },
      cancelToken: cancelRequestToken,
    })
    .then((response) => {
      log.info(`Convert Cart Response received from ${endpoint} to our model`);
      return CartSimulationResponseToCartInfoConverter(
        response.data,
        request.accountId,
        request.paymentMethod,
        request.paymentTerm,
      );
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        throw new UpdateCanceledError();
      }
      log.error(error);
      throw error;
    });
}
