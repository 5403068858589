import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    box: {
      padding: "4px 0 4px 8px ",
      display: "flex",
      alignItems: "flex-end",
    },
    title: {
      paddingBottom: "6px",
      fontSize: "12px",
      fontWeight: "bold",
      color: palette.common.white,
      textTransform: "none",
    },
    container: {
      paddingBottom: "8px",
    },
    code: {
      fontSize: "12px",
      fontWeight: "normal",
      width: "100%",
      wordBreak: "break-word",
      color: "#f2f2f2",
      textTransform: "none",
      letterSpacing: 0.03,
    },
    value: {
      paddingBottom: "8px",
      fontSize: "12px",
      fontWeight: "normal",
      width: "100%",
      wordBreak: "break-word",
      color: "#f2f2f2",
      textTransform: "none",
      letterSpacing: 0.03,
    },
  }),
);
