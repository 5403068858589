import { FlexibleMission } from "@domains/flexibleMission/FlexibleMission";
import { getFlexibleMissionsByIds } from "@services/missions/getFlexibleMissionsByIds/getFlexibleMissionsByIds";
import { isFeatureEnabled } from "grow-shared-services";
import { GROW_ORGANIZATION_ACTIVATION } from "@config/featureToggles";
import { getMissionsV3 } from "@services/missions/getMissionsV3/getMissionsV3";

export const GetFlexibleMissionsUseCase = async (
  flexibleMissionIds: string[],
): Promise<FlexibleMission[]> => {
  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  if (isOrganizationEnabled) {
    return getMissionsV3(flexibleMissionIds);
  }

  return getFlexibleMissionsByIds(flexibleMissionIds);
};
