import { createEffect, createEvent } from "effector";
import * as GetCampaignsB2OProductsUseCase from "../../../usecase/campaigns/campaignsB2OProducts/GetCampaignsB2OProductsUseCase";
import { CampaignProduct } from "./CampaignsB2OProductsState";

type GetCampaignsB2OPayload = {
  tag: string;
  country: string;
  accountId: string;
  vendorId?: string;
  vendorName?: string;
  isCampaignFocusVendorEnabled?: boolean;
  isMultiContractCampaignServiceEnabled?: boolean;
};

export const getCampaignsB2OProducts = createEffect({
  async handler({
    tag,
    country,
    accountId,
    vendorId,
    vendorName,
    isCampaignFocusVendorEnabled,
    isMultiContractCampaignServiceEnabled,
  }: GetCampaignsB2OPayload) {
    return GetCampaignsB2OProductsUseCase.execute(
      tag,
      country,
      accountId,
      vendorId,
      vendorName,
      isCampaignFocusVendorEnabled,
      isMultiContractCampaignServiceEnabled,
    );
  },
});

export const resetCampaignB2OProducts = createEvent<null>(
  "resetCampaignB2OProducts",
);
export const clearCampaignB2OProductsDb = createEvent(
  "clearCampaignB2OProductsDb",
);

export const setCampaignB2OProducts = createEvent<Array<CampaignProduct>>(
  "setCampaignB2OProducts",
);
