import React, { useState, useEffect } from "react";
import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import classNames from "classnames";
import { Phone } from "domains/phoneRanking/RankedPhones";
import UpdateRankedPhonesRequest from "../../../../../../domains/phoneRanking/UpdateRankedPhonesRequest";
import { useAnalytics } from "../../../../../../analytics/useAnalytics";
import {
  ANALYTICS_ROUTE_NAMES,
  PocCallOrigin,
} from "../../../../../../config/constants";
import {
  formatTranslationWithPhoneNumber,
  getPhoneSanitized,
  returnVendorIdWithFT,
  getVendorNameWithFT,
} from "../../../../../../config/utils/functions";
import {
  errorMessageViewed,
  phoneClassified,
} from "../../../../../../config/typewriter";
import {
  TLP_CTI,
  TLP_CTI_ADMIN,
  TLP_PHONE_RANKING_VENDOR,
  GROW_NON_CTI_START_CALL_AUTOMATICALLY,
  isFeatureEnabledV2,
  isFeatureEnabled,
} from "../../../../../../config/featureToggles";
import {
  updatePhoneRanking,
  getPhonesRanked,
  setCalledPhones,
  setIsPhoneCalled,
  setLastCopiedPhone,
  setLastCalledOrCopiedPhone,
} from "../../../../../../stores/phoneRankingStore/phoneRankedEvents";
import CallTabStore from "../../../../../../stores/navigation/callTab/CallTabStore";
import PocInformationStore from "../../../../../../stores/callList/pocInformation/PocInformationStore";
import CTIStore from "../../../../../../stores/cti/CtiStore";
import PhonesRankedStore from "../../../../../../stores/phoneRankingStore/phoneRankedStore";
import GlobalStore from "../../../../../../stores/global/GlobalStore";
import GlobalAdminConfigStore from "../../../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import AgentCallStore from "../../../../../../stores/agentCall/AgentCallStore";
import BeeLoading from "../../../beeLoading/BeeLoading";
import ClassificationButton from "../ClassificationButton/ClassificationButton";
import { classificationTypes } from "../ClassificationButton/ClassificationTypeEnum";
import { ToastErrorStructure } from "../ToastAlert/ToastAlert";
import RateAgainButton from "../RateAgainButton/RateAgainButton";
import EditButton from "./components/EditButton/EditButton";
import CallButton from "./components/CallButton/CallButton";
import CopyButton from "./components/CopyButton/CopyButton";
import PhoneRankingTags from "./components/PhoneRankingTags/PhoneRankingTags";
import ClassificationButtons from "./components/ClassificationButtons/ClassificationButtons";
import ScoreCell from "./components/ScoreCell/ScoreCell";
import PhoneTypeCell from "./components/PhoneTypeCell/PhoneTypeCell";
import ErrorBar from "./components/ErrorBar/ErrorBar";
import {
  CustomHeaderCell,
  CustomBodyCell,
  StyledTableRow,
  RankTableStyles,
} from "./RankTableStyles.styles";

interface Props {
  items: Array<Phone>;
  calledPhones: string[];
  currentPage: number;
  hasError: boolean;
  lastCalledPhone: string;
  loading: boolean;
  needClassification: boolean;
  setNeedClassification: (value: boolean) => void | undefined;
  onClose: () => void;
  makeCallWebSocket: (
    phone: string,
    isTyped: boolean,
    section?: string,
  ) => void;
  handelCopyButtonClick: () => void;
  setLoadingRanked: (value: boolean) => void | undefined;
  setToastError: React.Dispatch<React.SetStateAction<ToastErrorStructure>>;
}

function RankTable({
  items,
  calledPhones,
  currentPage,
  lastCalledPhone,
  hasError,
  loading,
  needClassification,
  setNeedClassification,
  onClose,
  makeCallWebSocket,
  handelCopyButtonClick,
  setLoadingRanked,
  setToastError,
}: Props): JSX.Element {
  const agentCallState = useStore(AgentCallStore);
  const { isConnected } = useStore(CTIStore);
  const [phones, setPhones] = useState<Array<Phone>>([]);
  const [infoError, setInfoError] = useState<string>("");
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState<string>("");
  const classes = RankTableStyles();
  const { t } = useTranslation();
  const { callTab } = useStore(CallTabStore);
  const { pocInformation } = useStore(PocInformationStore);
  const { lastCopiedPhone } = useStore(PhonesRankedStore);
  const { user } = useStore(GlobalStore);
  const { userConfig } = useStore(GlobalAdminConfigStore);

  const { dispatchPocEvent, dispatchGenericEvent } = useAnalytics();

  const tableHeaders = [
    { title: t("PHONE_RANKING.PHONE"), width: "28%" },
    { title: t("PHONE_RANKING.SCORE"), width: "69px" },
    { title: t("PHONE_RANKING.SOURCE"), width: "142px" },
    { title: t("PHONE_RANKING.PHONE_CLASSIFICATION"), width: "38%" },
  ];

  const ctiEnabled = isFeatureEnabled(TLP_CTI, TLP_CTI_ADMIN, user.zone);

  const isPhoneRankingVendor = isFeatureEnabledV2(
    TLP_PHONE_RANKING_VENDOR,
    user.keyToggle,
  );

  const isNonCtiStartCallAutomatically = isFeatureEnabledV2(
    GROW_NON_CTI_START_CALL_AUTOMATICALLY,
    user.keyToggle,
  );

  const vendorId = returnVendorIdWithFT(
    userConfig?.vendorId,
    isPhoneRankingVendor,
  );
  const vendorName = getVendorNameWithFT(vendorId, isPhoneRankingVendor);

  useEffect(() => {
    setPhones(items);
  }, [items]);

  /* istanbul ignore next */
  useEffect(() => {
    const unWatchGetPhonesRankedDone = getPhonesRanked.done.watch(() => {
      setLoadingRanked(false);

      if (typeof unWatchGetPhonesRankedDone === "function") {
        unWatchGetPhonesRankedDone();
      }
    });

    return () => unWatchGetPhonesRankedDone();
  }, [setLoadingRanked]);

  /* istanbul ignore next */
  const reloadPhones = () => {
    const phones = pocInformation?.phones ? [...pocInformation.phones] : [];
    getPhonesRanked({
      accountId: agentCallState.clientId,
      vendorId,
      vendorName,
      phones,
    });
  };

  const isCalled = (phoneNumber) => calledPhones?.includes(phoneNumber);

  const itemsOfCurrentPage = React.useMemo(() => {
    const itemsPerPage = 4;
    const start =
      (currentPage === 1 || currentPage === 0 ? 0 : currentPage - 1) *
      itemsPerPage;
    const end = start + itemsPerPage;
    return phones?.slice(start, end);
  }, [currentPage, phones]);

  /* istanbul ignore next */
  const showInactivateActivateToast = (
    phoneNumber: string,
    classification: classificationTypes,
    type: "success" | "error",
  ): void => {
    if (
      classification === classificationTypes.ACTIVATE ||
      classification === classificationTypes.INACTIVATE
    ) {
      const messages = {
        [classificationTypes.ACTIVATE]: {
          success: "SUCCESS_ACTIVATE",
          error: "FAIL_ACTIVATE",
        },
        [classificationTypes.INACTIVATE]: {
          success: "SUCCESS_INACTIVATE",
          error: "FAIL_INACTIVATE",
        },
      };
      setToastError({
        message: formatTranslationWithPhoneNumber(
          t(`PHONE_RANKING_TOAST_ALERTS.${messages[classification][type]}`),
          phoneNumber,
        ),
        type,
        codeError: type === "error" ? infoError : "",
      });
    }
  };

  const handleOnClick = async (
    classification: classificationTypes,
    item: Phone,
    index: number,
  ) => {
    setLoadingRanked(true);

    const { phoneNumber, phoneType, phoneContact } = item;

    setCurrentPhoneNumber(phoneNumber);

    const accountId = agentCallState.clientId;

    const requestBody: Array<UpdateRankedPhonesRequest> = [
      {
        phoneNumber,
        classification,
        contactName: phoneContact,
        phoneType,
      },
    ];

    const classificationIsInactivateorActivate =
      classification === classificationTypes.INACTIVATE ||
      classification === classificationTypes.ACTIVATE;

    const unWatchUpdatePhoneRankingDone = updatePhoneRanking.done.watch(() => {
      setLoadingRanked(true);

      if (!isConnected && !classificationIsInactivateorActivate) {
        setIsPhoneCalled(phoneNumber);
      }

      setLastCalledOrCopiedPhone("");
      setNeedClassification(false);

      reloadPhones();

      dispatchGenericEvent(phoneClassified, {
        call_id: agentCallState.callId,
        poc_id: accountId,
        phone_number: phoneNumber,
        phone_classification: classification,
        phone_source: phoneType,
        phone_rank: index + 1,
      });

      // istanbul ignore next
      if (typeof unWatchUpdatePhoneRankingDone === "function") {
        unWatchUpdatePhoneRanking();
        unWatchUpdatePhoneRankingDone();
      }
    });

    const unWatchUpdatePhoneRanking = updatePhoneRanking.fail.watch((props) => {
      setLoadingRanked(false);

      showInactivateActivateToast(phoneNumber, classification, "error");
      const errorRequestTraceId = props?.error?.config?.headers?.requestTraceId;
      setInfoError(errorRequestTraceId);
      /* istanbul ignore next */
      if (!classificationIsInactivateorActivate) {
        dispatchPocEvent(
          errorMessageViewed,
          {
            error_message: "Something went wrong. The phone was not classified",
            error_type: "save phone classification error",
            screen_name: ANALYTICS_ROUTE_NAMES.get(callTab),
            screen_section: "CTI Modal",
            is_all_products: null,
          },
          { time_of_day: true, is_resumed: true },
        );
      }

      // istanbul ignore next
      if (typeof unWatchUpdatePhoneRanking === "function") {
        unWatchUpdatePhoneRankingDone();
        unWatchUpdatePhoneRanking();
      }
    });

    // istanbul ignore next
    const unWatchGetPhonesRankedFinally = getPhonesRanked.finally.watch(() => {
      setLoadingRanked(false);

      showInactivateActivateToast(phoneNumber, classification, "success");

      if (typeof unWatchGetPhonesRankedFinally === "function") {
        unWatchGetPhonesRankedFinally();
      }
    });

    if (!isConnected && !classificationIsInactivateorActivate) {
      setCalledPhones([...calledPhones, phoneNumber]);
    }

    await updatePhoneRanking({ accountId, vendorId, vendorName, requestBody });
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (
      !lastCopiedPhone &&
      itemsOfCurrentPage.length > 0 &&
      !agentCallState.isTypedPoc &&
      agentCallState.pocCallOrigin !== PocCallOrigin.SEARCH_BY_POC &&
      isNonCtiStartCallAutomatically &&
      !ctiEnabled
    ) {
      const phoneWithHighestScore = [...itemsOfCurrentPage].sort(
        (firstPhone, secondPhone) => secondPhone.score - firstPhone.score,
      )[0];
      navigator.clipboard
        .writeText(phoneWithHighestScore.phoneNumber)
        .then(() => {
          setLastCopiedPhone(phoneWithHighestScore.phoneNumber);
          handelCopyButtonClick();
        });
    }
  }, [
    agentCallState.isTypedPoc,
    agentCallState.pocCallOrigin,
    ctiEnabled,
    handelCopyButtonClick,
    isNonCtiStartCallAutomatically,
    itemsOfCurrentPage,
    lastCopiedPhone,
  ]);

  const renderHeaderCell = (text: string, width: string) => (
    <CustomHeaderCell
      className={classes.headerCell}
      style={{ width }}
      align="left"
      data-testid={`table-header-cell-${text}`}
      key={text}
    >
      {text}
    </CustomHeaderCell>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <div>
          <BeeLoading testid="rank-table" />
        </div>
      );
    }

    if (hasError) {
      return (
        <ErrorBar
          phones={phones}
          currentPhoneNumber={currentPhoneNumber}
          reloadPhones={reloadPhones}
          callTab={callTab}
        />
      );
    }

    return itemsOfCurrentPage.map((item: Phone, index) => {
      const sanitizedPhone = getPhoneSanitized(item.phoneNumber);
      const isInactive = item.score === -99;
      const disableButton = isInactive;
      const phoneIndex = `${index}-${item.phoneNumber}`;
      return (
        <StyledTableRow key={phoneIndex}>
          <CustomBodyCell>
            <div className={classes.phoneCall}>
              <CallButton
                sanitizedPhone={sanitizedPhone}
                isConnected={isConnected}
                index={index}
                disableButton={disableButton}
                needClassification={needClassification}
                makeCallWebSocket={makeCallWebSocket}
                onClose={onClose}
              />
              <CopyButton
                sanitizedPhone={sanitizedPhone}
                score={item.score}
                index={index}
                disableButton={disableButton}
                isConnected={isConnected}
                setNeedClassification={setNeedClassification}
                handleCopyButtonClick={handelCopyButtonClick}
              />
              <EditButton phone={item} isInactive={isInactive} />
              {item.phoneNumber}
              <PhoneRankingTags
                isCalled={item.isCalled}
                sanitizedPhone={sanitizedPhone}
                lastCalledPhone={lastCalledPhone}
                lastCopiedPhone={lastCopiedPhone}
                isConnected={isConnected}
                isInactive={isInactive}
              />
            </div>
          </CustomBodyCell>
          <ScoreCell score={item.score} />
          <PhoneTypeCell type={item.phoneType} />
          <CustomBodyCell>
            <div className={classes.phoneCall}>
              {isInactive && (
                <ClassificationButton
                  data-testid="activate-button"
                  phoneNumber={item.phoneNumber}
                  classificationType={classificationTypes.ACTIVATE}
                  handleOnClick={(classification) => {
                    handleOnClick(classification, item, index);
                  }}
                />
              )}
              {!isInactive &&
                (!isConnected && isCalled(item.phoneNumber) ? (
                  <RateAgainButton
                    isConnected={isConnected}
                    calledPhones={calledPhones}
                    item={item}
                  />
                ) : (
                  <ClassificationButtons
                    isConnected={isConnected}
                    sanitizedPhone={sanitizedPhone}
                    item={item}
                    index={index}
                    lastCalledPhone={lastCalledPhone}
                    needClassification={needClassification}
                    handleOnClick={handleOnClick}
                  />
                ))}
            </div>
          </CustomBodyCell>
        </StyledTableRow>
      );
    });
  };

  return (
    <Table className={classes.container} data-testid="rank-table-container">
      <TableHead>
        <TableRow>
          {tableHeaders.map(({ title, width }) =>
            renderHeaderCell(title, width),
          )}
        </TableRow>
      </TableHead>
      <TableBody
        className={classNames({
          [classes.errorContainer]: hasError,
          [classes.loading]: loading,
        })}
      >
        {renderContent()}
      </TableBody>
    </Table>
  );
}

export default RankTable;
