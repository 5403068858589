import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    clipBoardButton: {
      background: "transparent",
      border: 0,
      color: "#757575",
      fontSize: ".75rem",
      fontFamily: "Work Sans",
      lineHeight: "1.125rem",
      padding: 0,
      cursor: "pointer",
      marginRight: "0.1875rem",
    },
  }),
);
