import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    tag: {
      width: "45px",
      height: "13px",
      padding: "0 4px",
      borderRadius: "6.5px",
      backgroundColor: "#007a78",
      textAlign: "center",
    },
    text: {
      width: "37px",
      height: "13px",
      fontFamily: "Barlow",
      fontSize: "10px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: palette.common.white,
    },
  }),
);
