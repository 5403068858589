import UpdateKPI from "../../domains/agentKPI/UpdateKPI";
import UpdateKPIDomain from "../../domains/agentKPI/UpdateKPIDomain";
import UpdateKPIResponse from "../../services/agentKPI/domains/UpdateKPIResponse";
import TargetsKPIResponseToTargetsKPIConverter from "./TargetsKPIResponseToTargetsKPIConverter";

export default function UpdateKPIResponseToUpdateKPIConverter(
  data: UpdateKPIResponse,
): UpdateKPIDomain {
  const updateKPI = <UpdateKPI>{
    date: data.date,
    pocAssigned: data.pocAssigned,
    hitRate: data.hitRate,
    toplineUplift: data.toplineUplift,
    topLineVolume: data.topLineVolume,
    totalGap: data.totalGap,
    averageHandleTime: data.averageHandleTime,
    ...TargetsKPIResponseToTargetsKPIConverter(data.targets),
  };

  return <UpdateKPIDomain>{
    updateKPI,
  };
}
