import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "@grow-shared-components/modal";
import {
  ModalTitle,
  InformationSection,
  InformationItem,
  InformationTitle,
  PointsTag,
  GenericTag,
  StatusAndTypeLabel,
  ChallengeDescription,
  SkusSection,
} from "./RewardsChallengesDetailsModal.styles";
import { ConvertedSku as SkuType } from "../../../../domains/rewardsChallenges/RewardsChallenges";
import SkuItems from "./components/SkuItems/SkuItems";
import ImageContainer from "./components/ImageContainer/ImageContainer";
// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
const itemCardDefaultImage = require("../../../../assets/images/ChallengePlaceholder.svg");

interface RewardsChallengesDetailsModalProps {
  challengeId?: string;
  challengeTitle?: string;
  pointsTagLabel?: number;
  timeLeftTag?: { title: string; color: string };
  challengeStatus?: string;
  challengeType?: string;
  imageURL?: string;
  challengeDescription?: string;
  isModalOpen: boolean;
  setIsModalOpen: (arg: boolean) => void;
  skus: SkuType[];
  type: string;
  goodPhotoSample?: string;
  badPhotoSample?: string;
}

const RewardsChallengesDetailsModal = ({
  challengeId,
  challengeTitle,
  pointsTagLabel,
  timeLeftTag,
  challengeStatus,
  challengeType,
  imageURL,
  challengeDescription,
  isModalOpen,
  setIsModalOpen,
  skus,
  type,
  goodPhotoSample,
  badPhotoSample,
}: RewardsChallengesDetailsModalProps): JSX.Element => {
  const { t } = useTranslation();
  const noSkus: string[] = ["TAKE_PHOTO", "PURCHASE_MULTIPLE_VOLUME_FIXED"];

  const handlePoints =
    pointsTagLabel === 1
      ? "REWARDS_CHALLENGES_MISSION_TAB.POINT_LABEL"
      : "REWARDS_CHALLENGES_MISSION_TAB.POINTS_LABEL";

  const renderChallengeSkus = (): JSX.Element => {
    if (noSkus.includes(type)) return <></>;
    if (!skus?.length) return <></>;
    return (
      <SkusSection data-testId="challenge-modal-sku-items-section">
        <SkuItems data={skus} defaultImage={itemCardDefaultImage} />
      </SkusSection>
    );
  };

  return (
    <Modal
      isOpen={isModalOpen}
      setIsOpen={() => setIsModalOpen(false)}
      testId={`challeng-${challengeId}-detail-modal`}
    >
      <div>
        <ModalTitle data-testId="challenge-title">{challengeTitle}</ModalTitle>

        <InformationSection>
          <InformationItem data-testId="reward-information-section">
            {pointsTagLabel && (
              <>
                <InformationTitle data-testId="reward-points-title">
                  {t("REWARDS_CHALLENGES_MISSION_TAB.REWARD_LABEL")}
                </InformationTitle>
                <PointsTag data-testId="reward-information">{`${pointsTagLabel} ${t(
                  handlePoints,
                )}`}</PointsTag>
              </>
            )}
          </InformationItem>
          {timeLeftTag?.title && (
            <InformationItem data-testId="time-left-information-section">
              <InformationTitle data-testId="time-left-title">
                {t("REWARDS_CHALLENGES_MISSION_TAB.TIME_LEFT_LABEL")}
              </InformationTitle>
              <GenericTag
                data-testId="time-left-information"
                style={{
                  backgroundColor: timeLeftTag.color,
                }}
              >
                {timeLeftTag?.title}
              </GenericTag>
            </InformationItem>
          )}
          <InformationItem data-testId="status-information-section">
            {challengeStatus && (
              <>
                <InformationTitle data-testId="status-title">
                  Status
                </InformationTitle>
                <StatusAndTypeLabel data-testId="status-information">
                  {challengeStatus}
                </StatusAndTypeLabel>
              </>
            )}
          </InformationItem>

          <InformationItem data-testId="type-information-section">
            {challengeType && (
              <>
                <InformationTitle data-testId="type-title">
                  {t("REWARDS_CHALLENGES_MISSION_TAB.TYPE_LABEL")}
                </InformationTitle>
                <StatusAndTypeLabel data-testId="type-information">
                  {challengeType}
                </StatusAndTypeLabel>
              </>
            )}
          </InformationItem>
        </InformationSection>
        <ImageContainer
          img={imageURL}
          type={type}
          goodSample={goodPhotoSample}
          badSample={badPhotoSample}
        />

        <ChallengeDescription data-testId="challenge-description">
          {challengeDescription}
        </ChallengeDescription>
        {renderChallengeSkus()}
      </div>
    </Modal>
  );
};

export default RewardsChallengesDetailsModal;

RewardsChallengesDetailsModal.defaultProps = {
  challengeId: "",
  challengeTitle: "",
  pointsTagLabel: null,
  timeLeftTag: null,
  challengeStatus: null,
  challengeType: null,
  imageURL: "",
  challengeDescription: "",
  goodPhotoSample: "",
  badPhotoSample: "",
};
