import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    accomplished: {
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "16px 0 6px 0",
      padding: "0px 4px",
      borderRadius: "16px",
      backgroundColor: "#81ffad",
    },
    notAccomplished: {
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px 8px",
      margin: "16px 0 6px 0",
      borderRadius: "16px",
      backgroundColor: "#ffdede",
    },
    title: {
      minWidth: "152px",
      fontSize: "14px",
      fontWeight: 500,
      textAlign: "center",
      color: "#212121",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "nowrap",
    },
    mission: {
      display: "flex",
      flexWrap: "wrap",
    },
    withEllipsis: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    tooltipStyle: {
      left: "90px",
      top: "8px",
      backgroundColor: "#000",
      borderRadius: "8px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.24)",
      padding: "16px 16px 20px 16px",
      color: "#f2f2f2",
      lineHeight: 1.43,
      textAlign: "center",
      fontSize: "14px",
      fontFamily: "Barlow",
      fontWeight: "normal",
      letterSpacing: "0.1px",
    },
    tooltipArrow: {
      color: "#000",
      fontSize: "0.625rem",
    },
  }),
);
