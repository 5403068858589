import * as React from "react";

function LastOrdersIcon(props: any): any {
  return (
    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M27.735 4.5c.527 0 .954.428.954.956v25.088a.956.956 0 01-1.527.765l-3.346-2.513-2.42 2.424a.953.953 0 01-1.35 0l-2.331-2.335-2.332 2.335a.953.953 0 01-1.35 0l-2.42-2.424-3.346 2.513a.955.955 0 01-1.527-.765V5.456c0-.528.427-.956.954-.956zm-.954 1.911H8.649v22.222l2.48-1.864a.952.952 0 011.248.089l2.332 2.335 2.331-2.335a.95.95 0 011.35 0l2.33 2.335 2.332-2.335a.952.952 0 011.247-.089l2.482 1.864V6.41zm-4.056 14.145a.955.955 0 010 1.912h-10.02a.955.955 0 010-1.912zm0-5.017a.955.955 0 010 1.911h-10.02a.955.955 0 010-1.911zm0-5.018a.955.955 0 010 1.912h-10.02a.955.955 0 010-1.912z"
        fill="#000"
        fillRule="nonzero"
        id="LastOrdersIcon__a"
      />
    </svg>
  );
}

export default LastOrdersIcon;
