import { FormikErrors, FormikTouched } from "formik";
import React from "react";

import { Mission, ServicesOptions } from "../../../../../../../domains/Mission";
import GenericMissions from "../genericMissions/GenericMissions";

interface TargetValue {
  name?: string;
  value: string;
}

interface Props {
  handleChange: (
    e: React.ChangeEvent<TargetValue>,
    tag: string,
    usage: string,
  ) => void;
  values: Mission;
  errors?: FormikErrors<Mission>;
  touched?: FormikTouched<Mission>;
}

const DigitalServices: React.FunctionComponent<Props> = ({
  handleChange,
  values,
  errors,
  touched,
}) => {
  const isClassificationEmpty = () => {
    return values.typification === "";
  };

  const isGapEmpty = () => {
    return values.reason === "";
  };

  const isEffectiveness = () => {
    if (values.effectiveness) {
      return true;
    }
    return false;
  };

  return (
    <GenericMissions
      values={values}
      handleChange={handleChange}
      errors={errors}
      touched={touched}
      disableClassification={false}
      disableGapReason={false}
      gapReason={ServicesOptions}
      isClassificationEmpty={isClassificationEmpty()}
      isGapEmpty={isGapEmpty()}
      showClassification={false}
      showGapReason={!isEffectiveness()}
    />
  );
};

export default DigitalServices;
