import { createStore } from "effector";
import {
  handlePastePhoneEventWasCalled,
  handleIsNewOfferingsUsed,
  resetGenericInfos,
} from "./GenericInfosEvents";
import GenericInfosState from "./GenericInfosState";

const initialState = <GenericInfosState>{
  isPastedPhone: false,
  isNewOfferingsUsed: false,
};

const GenericInfosStore = createStore(initialState)
  .on(
    handlePastePhoneEventWasCalled,
    (state: GenericInfosState, isPastedPhone: boolean) => {
      return {
        ...state,
        isPastedPhone,
      };
    },
  )
  .on(
    handleIsNewOfferingsUsed,
    (state: GenericInfosState, isNewOfferingsUsed: boolean) => {
      return {
        ...state,
        isNewOfferingsUsed,
      };
    },
  )
  .reset(resetGenericInfos);

export default GenericInfosStore;
