/* istanbul ignore file */
import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      position: "absolute",
      width: "100%",
      top: "-66px",
      left: 0,
    },
  }),
);
