import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ChallengeCard from "@grow-shared-components/challenge-card";
import { useStore } from "effector-react";
import { Typography } from "@material-ui/core";
import {
  getRewardsChallengeEffect,
  getRewardsChallengeEffectV2,
} from "../../../../stores/RewardsChallenge/RewardsChallengeEvents";
import GlobalStore from "../../../../stores/global/GlobalStore";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";
import RewardsChallengeStore from "../../../../stores/RewardsChallenge/RewardsChallengeStore";
import { RewardsChallengeStoreItem } from "../../../../domains/rewardsChallenges/RewardsChallenges";
import RewardsChallengesIcon from "../../../../assets/newIcons/RewardsChallengesIcon";
import RewardsChallengesStatisticsIcon from "../../../../assets/newIcons/RewardsChallengesStatisticsIcon";
import CollapsibleTitle from "../../../../components/collapsableTitle/CollapsibleTitle";
import RewardsChallengesComponentStyles from "./RewardsChallengesComponent.styles";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/ErrorHandlerRetry";
import BeeLoading from "../beeLoading/BeeLoading";
import RewardsChallengesDetailsModal from "../RewardsChallengesDetailsModal/RewardsChallengesDetailsModal";
import RewardsChallengesStatistics from "./components/Statistics/RewardsChallengesStatistics";
import { ANALYTICS_ROUTE_NAMES } from "../../../../config/constants";
import {
  getUserInfos,
  getValueOrNull,
} from "../../../../config/utils/functions";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import {
  callUxButtonClicked,
  errorMessageViewed,
} from "../../../../config/typewriter";
import {
  isFeatureEnabledV2,
  TLP_REWARDS_CHALLENGES_STATISTICS,
  TLP_REWARDS_CHALLENGES_DETAILS,
  GROW_MULTICONTRACT_REWARDS,
} from "../../../../config/featureToggles";

// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
const itemCardDefaultImage = require("../../../../assets/images/ChallengePlaceholder.svg");

const RewardsChallengesComponent: React.FC = () => {
  const classes = RewardsChallengesComponentStyles();
  const { dispatchPocEvent } = useAnalytics();
  const { t } = useTranslation();

  const { user } = useStore(GlobalStore);
  const { callTab } = useStore(CallTabStore);
  const { clientId, callId } = useStore(AgentCallStore);

  const { vendorId } = getUserInfos();

  const {
    completed: statisticCompleted,
    started: statisticStarted,
    failed: statisticFailed,
    done: rewardsChallengeDone,
    rewardsChallengeInformation,
    error: rewardsChallengeError,
    isLoading,
  } = useStore(RewardsChallengeStore);

  const isRewardsChallengesDetails = isFeatureEnabledV2(
    TLP_REWARDS_CHALLENGES_DETAILS,
    user.zone,
  );

  const isRewardsChallengesStatistics = isFeatureEnabledV2(
    TLP_REWARDS_CHALLENGES_STATISTICS,
    user.zone,
  );

  const isGrowMulticontractRewardsEnabled = isFeatureEnabledV2(
    GROW_MULTICONTRACT_REWARDS,
    user.keyToggle,
  );

  const [isRwardsChallengesComponentOpen, setIsRwardsChallengesComponentOpen] =
    useState(true);

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [detailModalInfo, setDetailModalInfo] =
    useState<RewardsChallengeStoreItem | null>();

  const [
    isRewardsChallengesStatisticsOpen,
    setIsRewardsChallengesStatisticsOpen,
  ] = useState(true);

  const isNewTag = (rewardsChallenge: RewardsChallengeStoreItem) => {
    if (rewardsChallenge?.stage?.value === "NEW") {
      return {
        title: t("REWARDS_CHALLENGES_MISSION_TAB.NEW_TAG_LABEL"),
        color: "#D1F7F6",
      };
    }

    return null;
  };

  const timeLeftTag = (
    rewardsChallenge: RewardsChallengeStoreItem,
  ): { title: string; color: string } | undefined => {
    const daysLeft = rewardsChallenge?.stage?.remainingDays;

    if (daysLeft < 0 || daysLeft === undefined) {
      return undefined;
    }

    const timeLeftLabelTranslation =
      daysLeft === 1
        ? t("REWARDS_CHALLENGES_MISSION_TAB.SINGLE_DAY_TAG_LABEL")
        : t("REWARDS_CHALLENGES_MISSION_TAB.TIMELEFT_TAG_LABEL");

    return {
      title: `${daysLeft} ${timeLeftLabelTranslation}`,
      color: daysLeft > 10 ? "#D1F7F6" : "#FFEBD0",
    };
  };

  const handleLoadRewardsChalengesInfos = useCallback(() => {
    if (isGrowMulticontractRewardsEnabled) {
      getRewardsChallengeEffectV2({
        accountId: clientId,
        vendorId,
      });
    } else {
      getRewardsChallengeEffect(clientId);
    }
  }, [clientId, vendorId, isGrowMulticontractRewardsEnabled]);

  useEffect(() => {
    if (!rewardsChallengeDone || rewardsChallengeError) {
      handleLoadRewardsChalengesInfos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCollapsibleComponentOpen = () => {
    setIsRwardsChallengesComponentOpen((prevState) => !prevState);
  };

  const handleCollapsibleStatisticsComponentOpen = () => {
    const buttonName = isRewardsChallengesStatisticsOpen
      ? "rewards-challenges-statistics-expand"
      : "rewards-challenges-statistics-collapse";

    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: buttonName,
      button_label: isRewardsChallengesStatisticsOpen ? "expand" : "collapse",
      screen_section: "Rewards Challenges Statistics",
      filter_option: null,
      call_id: callId,
      order_date: null,
      position: null,
    });

    setIsRewardsChallengesStatisticsOpen((prevState) => !prevState);
  };

  const handleNoChallengesAvailable = () => {
    return rewardsChallengeInformation.some(
      (challengeItem) =>
        challengeItem.challengeStatus !== "EXPIRED" &&
        challengeItem.challengeStatus !== "COMPLETED",
    );
  };

  const statusTranslated = new Map<string, string>([
    ["FAILED", t("REWARDS_CHALLENGES_MISSION_TAB.FAILED_STATUS")],
    ["PENDING", t("REWARDS_CHALLENGES_MISSION_TAB.PENDING_STATUS")],
    ["COMPLETED", t("REWARDS_CHALLENGES_MISSION_TAB.COMPLETED_STATUS")],
    ["ACCEPTED", t("REWARDS_CHALLENGES_MISSION_TAB.ACCEPTED_STATUS")],
    ["AVAILABLE", t("REWARDS_CHALLENGES_MISSION_TAB.AVAILABLE_STATUS")],
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const challengesTypeTranslated = new Map<string, string>([
    ["PURCHASE", t("REWARDS_CHALLENGES_MISSION_TAB.PURCHASE")],
    [
      "PURCHASE_MULTIPLE",
      t("REWARDS_CHALLENGES_MISSION_TAB.PURCHASE_MULTIPLE"),
    ],
    [
      "PURCHASE_MULTIPLE_VOLUME_FIXED",
      t("REWARDS_CHALLENGES_MISSION_TAB.PURCHASE_FIXED_VOLUME"),
    ],
    ["TAKE_PHOTO", t("REWARDS_CHALLENGES_MISSION_TAB.TAKE_PHOTO")],
  ]);

  const isCardClicable = useCallback(
    (executionMethod: string): boolean => {
      return (
        isRewardsChallengesDetails &&
        Boolean(challengesTypeTranslated.get(executionMethod))
      );
    },
    [isRewardsChallengesDetails, challengesTypeTranslated],
  );

  const openDetailsModal = (
    rewardsChallengeItem: RewardsChallengeStoreItem,
    index: number,
  ) => {
    if (isCardClicable(rewardsChallengeItem.executionMethod)) {
      callUxButtonClicked({
        screen_name: "Call - GAP and Status",
        button_name: "Single Rewards Challenge Details",
        button_label: "Single Rewards Challenge Details",
        screen_section: "Challenges for the customer",
        filter_option: null,
        call_id: callId,
        order_date: null,
        position: index + 1,
      });

      setIsDetailModalOpen(true);
      setDetailModalInfo(rewardsChallengeItem);
    }
  };

  const renderDetailsModal = () => {
    if (isDetailModalOpen && detailModalInfo) {
      return (
        <RewardsChallengesDetailsModal
          isModalOpen={isDetailModalOpen}
          setIsModalOpen={setIsDetailModalOpen}
          challengeTitle={detailModalInfo.challengeTitle}
          pointsTagLabel={detailModalInfo.challengePoints}
          timeLeftTag={timeLeftTag(detailModalInfo)}
          challengeStatus={statusTranslated.get(
            detailModalInfo.challengeStatus,
          )}
          challengeType={challengesTypeTranslated.get(
            detailModalInfo.executionMethod,
          )}
          imageURL={detailModalInfo.imgURL}
          challengeDescription={detailModalInfo.challengeDescription}
          data-testId={`${detailModalInfo.challengeId}-details-modal`}
          skus={detailModalInfo.skus}
          type={detailModalInfo.executionMethod}
          goodPhotoSample={detailModalInfo.goodPhotoSample}
          badPhotoSample={detailModalInfo.badPhotoSample}
        />
      );
    }

    return <></>;
  };

  const triggerErrorMessageViewed = () => {
    dispatchPocEvent(
      errorMessageViewed,
      {
        error_message: "Information not Found",
        error_type: null,
        screen_name: "Call - GAP and Status",
        screen_section: "Single Rewards Challenge Details",
        is_all_products: null,
        DDC: null,
        DDC_name: null,
        total_gap: null,
        total_vol_gap: null,
      },
      { time_of_day: true, is_resumed: true },
    );
  };

  const handlePointsCard = (points: number) => {
    if (points === 1) {
      return `${points} pt`;
    }

    if (!points) {
      return "";
    }

    return `${points} pts`;
  };

  const renderStatisticsContent = () => {
    if (rewardsChallengeError) {
      return (
        <ErrorHandlerRetry
          onClick={handleLoadRewardsChalengesInfos}
          screenName={ANALYTICS_ROUTE_NAMES.get(callTab)}
          screenSection="Rewards Challenges Statistics"
        />
      );
    }

    return (
      <RewardsChallengesStatistics
        started={statisticStarted}
        failed={statisticFailed}
        completed={statisticCompleted}
        isLoading={isLoading}
      />
    );
  };

  const challengeHasMissingInfo = (
    rewardsChallengeItem: RewardsChallengeStoreItem,
  ) => {
    return (
      !rewardsChallengeItem.challengeTitle ||
      !rewardsChallengeItem.challengePoints ||
      !rewardsChallengeItem.stage?.remainingDays
    );
  };

  const isChallengeExpiredForMoreThan30Days = (
    rewardsChallengeItem: RewardsChallengeStoreItem,
  ) => {
    return (
      rewardsChallengeItem.stage?.remainingDays &&
      rewardsChallengeItem.stage?.remainingDays < -30
    );
  };

  const isChallengeStatusValid = (
    rewardsChallengeItem: RewardsChallengeStoreItem,
  ) => {
    return (
      rewardsChallengeItem.challengeStatus !== "EXPIRED" &&
      rewardsChallengeItem.challengeStatus !== "COMPLETED"
    );
  };

  const handlePeriodTag = (rewardsChallengeItem: RewardsChallengeStoreItem) => {
    if (rewardsChallengeItem.stage?.remainingDays >= 0) {
      return (
        isNewTag(rewardsChallengeItem) ?? timeLeftTag(rewardsChallengeItem)
      );
    }

    return undefined;
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={classes.centralizedContainer}>
          <BeeLoading testid="rewards-challenges-component-collapsible-loading" />
        </div>
      );
    }

    if (rewardsChallengeError) {
      return (
        <ErrorHandlerRetry
          onClick={handleLoadRewardsChalengesInfos}
          screenName={ANALYTICS_ROUTE_NAMES.get(callTab)}
          screenSection="Rewards Challenges"
        />
      );
    }

    if (
      (!rewardsChallengeError && !rewardsChallengeInformation?.length) ||
      !handleNoChallengesAvailable()
    ) {
      return (
        <div
          data-testid="rewards-challenges-component-collapsible-no-items"
          className={classes.centralizedContainer}
        >
          <Typography className={classes.noDataAvailable}>
            {t("REWARDS_CHALLENGES_MISSION_TAB.NO_CHALLENGE_AVAILABLE")}
          </Typography>
        </div>
      );
    }

    return (
      <div className={classes.challengesCardContainer}>
        {rewardsChallengeInformation?.map((rewardsChallengeItem, index) => {
          if (isChallengeStatusValid(rewardsChallengeItem)) {
            if (challengeHasMissingInfo(rewardsChallengeItem)) {
              triggerErrorMessageViewed();
            }

            return (
              !isChallengeExpiredForMoreThan30Days(rewardsChallengeItem) && (
                <ChallengeCard
                  key={`challenge-card-${rewardsChallengeItem.challengeId}`}
                  id={rewardsChallengeItem.challengeId}
                  title={rewardsChallengeItem.challengeTitle}
                  pointsTag={handlePointsCard(
                    rewardsChallengeItem.challengePoints,
                  )}
                  periodTag={handlePeriodTag(rewardsChallengeItem)}
                  image={rewardsChallengeItem.imgURL}
                  data-testId={`${rewardsChallengeItem.challengeId}-challenge-card`}
                  placeholderImage={itemCardDefaultImage}
                  onClick={() => openDetailsModal(rewardsChallengeItem, index)}
                  isClicable={isCardClicable(
                    rewardsChallengeItem.executionMethod,
                  )}
                />
              )
            );
          }

          return (
            <React.Fragment
              key={`challenge-card-not-available-${rewardsChallengeItem.challengeId}`}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {isRewardsChallengesStatistics && (
        <CollapsibleTitle
          icon={<RewardsChallengesStatisticsIcon />}
          title={t("REWARDS_CHALLENGES_MISSION_TAB.STATISTICS_TITLE")}
          hasSeparator={false}
          open={isRewardsChallengesStatisticsOpen}
          handleOpen={handleCollapsibleStatisticsComponentOpen}
          testId="rewards-challenges-statistics-collapsible-title"
        >
          {renderStatisticsContent()}
        </CollapsibleTitle>
      )}

      <CollapsibleTitle
        icon={<RewardsChallengesIcon />}
        title={t("REWARDS_CHALLENGES_MISSION_TAB.TITLE")}
        hasSeparator={false}
        open={isRwardsChallengesComponentOpen}
        handleOpen={handleCollapsibleComponentOpen}
        testId="rewards-challenges-component-collapsible-title"
      >
        {renderDetailsModal()}
        {renderContent()}
      </CollapsibleTitle>
    </div>
  );
};

export default React.memo(RewardsChallengesComponent);
