import SuggestedOrderV2 from "@domains/suggestedOrders/SuggestedOrderV2";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { useStore } from "effector-react";
import { getVendorName } from "grow-shared-services";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/ErrorHandlerRetry";
import {
  TLP_FOCUS_SKU,
  TLP_FOCUS_SKU_ADMIN,
  isFeatureEnabled,
} from "../../../../config/featureToggles";
import { returnVendorId } from "../../../../config/utils/functions";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import GlobalAdminConfigStore from "../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";
import {
  setOrderBy,
  setOrderDirection,
} from "../../../../stores/suggestedOrders/SuggestedOrdersEvents";
import SuggestedOrdersStore from "../../../../stores/suggestedOrders/SuggestedOrdersStore";
import { execute } from "../../../../usecase/suggestedOrders/SuggestedOrdersUseCase";
import SuggestedOrderStyle from "./SuggestedOrderStyle";

import { useAnalytics } from "../../../../analytics/useAnalytics";
import { ANALYTICS_ROUTE_NAMES, LINK_CALL } from "../../../../config/constants";
import {
  callUxButtonClicked,
  errorMessageViewed,
} from "../../../../config/typewriter";
import SuggestedOrderDomain from "../../../../domains/suggestedOrders/SuggestedOrder";
import {
  SORT_DIRECTION,
  SUGGESTED_ORDER_FIELDS,
} from "./SuggestedOrderConstants";
import sort from "./SuggestedOrderSorting";
import TableView from "./table/TableView";

enum FILTER_OPTIONS {
  GAP_ONLY = "gap only",
  ALL_RESULTS = "all results",
}

interface Props {
  keyToggle: string;
  isNewLayout?: boolean;
}

function SuggestedOrder({ keyToggle, isNewLayout }: Props): React.ReactElement {
  const { t } = useTranslation();
  const isFocusSkuEnabled = isFeatureEnabled(
    TLP_FOCUS_SKU,
    TLP_FOCUS_SKU_ADMIN,
    keyToggle,
  );

  const { clientId, callId } = useStore(AgentCallStore);
  const {
    suggestedOrders,
    isLoading,
    isLoaded,
    error,
    orderBy,
    orderDirection,
    totalSuggested: total,
  } = useStore(SuggestedOrdersStore);
  const [updatedSuggestedOrder, setUpdatedSuggestedOrder] = useState<
    Array<SuggestedOrderDomain | SuggestedOrderV2>
  >([]);
  const { user } = useStore(GlobalStore);
  const { callTab } = useStore(CallTabStore);
  const { dispatchGenericEvent, dispatchPocEvent } = useAnalytics();
  const classes = SuggestedOrderStyle({
    isFocusSkuEnabled,
    suggestedLength: suggestedOrders?.length || 0,
    isNewLayout,
  });
  const { userConfig } = GlobalAdminConfigStore.getState();

  const reloadSuggestedOrders = () => {
    const vendorId = returnVendorId(userConfig.vendorId);
    const vendorName = getVendorName(userConfig.vendorId);

    /* istanbul ignore next */
    if (vendorId && vendorName) {
      execute(clientId, user.zone, vendorId, vendorName);
    } else {
      execute(clientId, user.zone, "", "");
    }
  };

  useEffect(() => {
    setUpdatedSuggestedOrder(suggestedOrders);
    if (suggestedOrders && suggestedOrders.length > 0) {
      const suggestedOrdersWithoutUnitPrice = suggestedOrders.filter(
        (order) => !order.sku || !order.unitPrice || !order.mc,
      );
      if (suggestedOrdersWithoutUnitPrice.length > 0) {
        const isAllProducts =
          suggestedOrders.length === suggestedOrdersWithoutUnitPrice.length;
        dispatchPocEvent(
          errorMessageViewed,
          {
            error_message: "No data available",
            error_type: " no-data-available-sku",
            screen_name: ANALYTICS_ROUTE_NAMES.get(callTab),
            screen_section: "Suggested Orders",
            is_all_products: isAllProducts,
            total_gap: null,
            total_vol_gap: null,
          },
          { time_of_day: true, is_resumed: true },
        );
      }
    }
  }, [suggestedOrders, callTab, dispatchPocEvent]);

  if (isLoading) {
    return (
      <Box className={classes.tableNavigationContainer}>
        <Box className={classes.loadingContainer}>
          <CircularProgress data-testid="suggested-orders-loading" />
        </Box>
      </Box>
    );
  }

  if (!isLoaded) {
    return <Grid item md={8} data-testid="suggested-orders-not-loaded" />;
  }

  const sortHandler = (id: string) => {
    let isAsc;
    if (
      id === SUGGESTED_ORDER_FIELDS.MC &&
      orderBy !== SUGGESTED_ORDER_FIELDS.MC
    ) {
      isAsc = true;
    } else {
      isAsc = orderBy === id && orderDirection === SORT_DIRECTION.DESC;
    }

    const suggestedOrdersSorted = sort(id, isAsc, updatedSuggestedOrder);

    setOrderDirection(isAsc ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC);
    setOrderBy(id);
    setUpdatedSuggestedOrder(suggestedOrdersSorted);
  };

  const dispatchUXbuttonClickedOnGapyHandler = (filterOption: string) => {
    dispatchGenericEvent(callUxButtonClicked, {
      screen_name: ANALYTICS_ROUTE_NAMES.get(LINK_CALL),
      button_name: "gap-only-switch",
      button_label: t("SuggestedOrder.GAP_ONLY_SWITCHER"),
      screen_section: "Suggested Orders",
      filter_option: filterOption,
      call_id: callId,
      order_date: null,
      position: null,
    });
  };

  const gapOnlyHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredSuggestedOrder = (
      suggestedOrders as SuggestedOrderDomain[]
    ).filter((each) => {
      return each.gapBoxes > 0;
    });
    if (event.target.checked) {
      setUpdatedSuggestedOrder(filteredSuggestedOrder);
      dispatchUXbuttonClickedOnGapyHandler(FILTER_OPTIONS.GAP_ONLY);
    } else {
      setUpdatedSuggestedOrder(suggestedOrders);
      dispatchUXbuttonClickedOnGapyHandler(FILTER_OPTIONS.ALL_RESULTS);
    }
  };

  const renderTable = () => {
    return suggestedOrders.length ? (
      <TableView
        gapOnlyHandler={gapOnlyHandler}
        suggestedOrder={{
          suggestedOrders: updatedSuggestedOrder as SuggestedOrderDomain[],
        }}
        sortHandler={sortHandler}
        orderBy={orderBy}
        orderDirection={orderDirection}
        total={total}
      />
    ) : (
      <div
        className={classes.suggestedEmpty}
        data-testid="suggested-order-empty"
      >
        {t("SuggestedOrder.NOT_FOUND")}
      </div>
    );
  };

  return (
    <Box
      data-testid="call-suggested-order"
      className={classes.tableNavigationContainer}
    >
      {error ? (
        <ErrorHandlerRetry
          onClick={reloadSuggestedOrders}
          screenName={ANALYTICS_ROUTE_NAMES.get(callTab)}
          screenSection="Suggested Orders"
        />
      ) : (
        renderTable()
      )}
    </Box>
  );
}
SuggestedOrder.defaultProps = {
  isNewLayout: false,
};

export default React.memo(SuggestedOrder, (prevProps, nextProps) => {
  return prevProps.keyToggle === nextProps.keyToggle;
});
