import { createEffect, createEvent } from "effector";
import { Empty } from "domains/cart/Empty";
import CartRequestV3 from "../../services/cart/v3/domains/CartRequestV3";
import CartSimulationRequest from "../../services/cartSimulation/domains/CartSimulationRequest";
import * as UpdateCartUseCaseV3 from "../../usecase/cart/v3/UpdateCartUseCaseV3";
import * as UpdateCartSimulationUseCase from "../../usecase/cartSimulation/UpdateCartSimulationUseCase";
import * as AutoUpdateCartUseCase from "../../usecase/cart/autoUpdate/AutoUpdateCartUseCase";
import * as UpdateSingleCartSimulationUseCase from "../../usecase/cart/autoUpdate/UpdateSingleCartSimulationUseCase";
import * as DeleteCartSimulationUseCase from "../../usecase/deleteCartSimulation/DeleteCartSimulationUseCase";
import { CartIdParams, CartItem } from "../../domains/cart/CartItem";
import { SelectedFreeGoods } from "../../domains/cart/SelectedFreeGoods";
import { CartIdByAccountId } from "../../services/cart/cartId/CartIdService";
import { UpsertItemToCart } from "../../services/cart/upsertItem/UpsertItemService";
import { CartStateSimulation } from "../../services/cart/cartStateSimulation/CartStateSimulationService";

export const updateCartEffectV3 = createEffect({
  async handler(cartRequest: CartRequestV3) {
    return UpdateCartUseCaseV3.execute(cartRequest);
  },
});

export const updateCartSimulationEffect = createEffect({
  async handler(cartRequest: CartSimulationRequest) {
    return UpdateCartSimulationUseCase.execute(cartRequest);
  },
});

export const setItemCart = createEvent<Array<CartItem>>("setItemCart");

export const clearItemCart = createEvent("clearItemCart");

export const deleteCartSimulationEffect = createEffect({
  async handler(cartId: string) {
    return DeleteCartSimulationUseCase.execute(cartId);
  },
});

export const changeItemQuantity = createEvent<
  CartItem & { position: number; toggle: boolean }
>("changeItemQuantity");

export const removeItem = createEvent<
  CartItem & { position: number; toggle: boolean }
>("removeItem");

export const addItem = createEvent<CartItem>("addItem");

export const updateOrAddItem = createEvent<CartItem>("updateOrAddItem");

export const resetItemCart = createEvent("resetItemCart");

export const setTotalItems = createEvent<number>("setTotalItems");

export const setShouldAddSuggested = createEvent<boolean>(
  "setShouldAddSuggested",
);

export const setSuggestedOrdersLoaded = createEvent<boolean>(
  "setSuggestedOrdersLoaded",
);

export const setPromoItensLoaded = createEvent<boolean>("setPromoItensLoaded");

export const setEmpty = createEvent<Empty>("setEmpty");

export const updateCartEffect = createEffect({
  async handler({ agentCallState, cartState, cartComboState, cartItemsState }) {
    return AutoUpdateCartUseCase.execute(
      agentCallState,
      cartState,
      cartComboState,
      cartItemsState,
    );
  },
});

export const editSelectedFreeGoodItem = createEvent<SelectedFreeGoods>(
  "editSelectedFreeGoodItem",
);

export const setHighlightTitle = createEvent<boolean>("setHighlightTitle");

export const getCartIdEffect = createEffect({
  async handler({ accountId, vendorId }: CartIdParams) {
    return CartIdByAccountId(accountId, vendorId);
  },
});

export const updateSingleCartSimulationEffect = createEffect({
  async handler({ agentCallState, cartState, cartComboState, cartItemsState }) {
    return UpdateSingleCartSimulationUseCase.execute(
      agentCallState,
      cartState,
      cartComboState,
      cartItemsState,
    );
  },
});

export const simulateCartEffect = createEffect({
  async handler({ cartId, accountId, cartForm }) {
    return CartStateSimulation(cartId, accountId, cartForm);
  },
});

export const upsertItemEffect = createEffect({
  async handler({ agentCallState, cartComboState, cartItemsState }) {
    return UpsertItemToCart(agentCallState, cartComboState, cartItemsState);
  },
});
