import React from "react";

const PlusIconControl: React.FunctionComponent = () => {
  return (
    <svg
      width="14px"
      height="12px"
      viewBox="0 0 70 50"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="plus" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M32,8 C33.5385075,8 34.8065215,9.15812057 34.9798168,10.6501366 L35,11 L35,29 L53,29 C54.6568542,29 56,30.3431458 56,32 C56,33.5385075 54.8418794,34.8065215 53.3498634,34.9798168 L53,35 L35,35 L35,53 C35,54.6568542 33.6568542,56 32,56 C30.4614925,56 29.1934785,54.8418794 29.0201832,53.3498634 L29,53 L29,35 L11,35 C9.34314575,35 8,33.6568542 8,32 C8,30.4614925 9.15812057,29.1934785 10.6501366,29.0201832 L11,29 L29,29 L29,11 C29,9.34314575 30.3431458,8 32,8 Z"
          id="Combined-Shape"
          fill="#000000"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export default PlusIconControl;
