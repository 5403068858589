import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { ButtonBase } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import classNames from "classnames";
import collapsibleContainerStyles from "./CollapsibleContainerStyles";

interface Props {
  children: React.ReactNode;
  onClick: (isOpen: boolean) => void;
  title: string;
  defaultOpen?: boolean;
}

const CollapsibleContainer: React.FC<Props> = ({
  children,
  onClick,
  title,
  defaultOpen = false,
}) => {
  const classes = collapsibleContainerStyles();
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  const handleOpenCollapsible = () => {
    onClick(isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <Accordion
      classes={{ root: classes.accordion }}
      expanded={isOpen}
      data-testid={`accordion-${title}`}
    >
      <ButtonBase
        className={classNames(classes.expandButton, {
          [classes.accordionExpandedBackground]: isOpen,
        })}
        onClick={() => handleOpenCollapsible()}
        data-testid={`collapsible-container-expand-button-${title}`}
      >
        {isOpen ? (
          <RemoveIcon
            className={classes.icon}
            data-testid="collapsible-container-remove-icon"
          />
        ) : (
          <AddIcon
            className={classes.icon}
            data-testid="collapsible-container-add-icon"
          />
        )}
        <div className={classes.expandButtonLabel}>{title}</div>
      </ButtonBase>

      <AccordionDetails
        className={classNames(classes.accordionDetails, {
          [classes.accordionExpandedBackground]: isOpen,
        })}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default CollapsibleContainer;
