import getRankedPhonesService from "../../services/phoneRanking/RankedPhonesService";
import RankedPhones from "../../domains/phoneRanking/RankedPhones";

export const execute = (
  accountId: string,
  vendorId: string,
  vendorName: string,
  phones: string[],
): Promise<RankedPhones | null> => {
  return getRankedPhonesService(accountId, vendorId, vendorName, phones);
};
