import React from "react";
import Grid from "@material-ui/core/Grid";
import { useStore } from "effector-react";
import {
  TLP_CAMPAIGNS_PRODUCTS_ADD_TO_CART,
  TLP_CAMPAIGNS_PRODUCTS_SHOW_STOCK,
  isFeatureEnabledV2,
} from "../../../../../config/featureToggles";
import {
  campaignsProductQuantityEdited,
  campaignsProductAdded,
} from "../../../../../config/typewriter";

import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import { addItem } from "../../../../../stores/cart/CartItemEvents";
import PocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";

import { CartItem } from "../../../../../domains/cart/CartItem";
import ProductCard from "../../../../../components/ProductCard/ProductCard";
import ProductCardV2 from "../../../../../components/ProductCardV2/ProductCardV2";
import { useAnalytics } from "../../../../../analytics/useAnalytics";

interface Props {
  campaignItems: Array<{
    sku: string;
    productName: string;
    unitPrice: number;
    image: string;
    tag: string;
    campaignName: string;
    isB2O?: boolean;
    platformId?: string;
  }>;
}

interface AlteredProduct {
  id: string;
  productName: string;
  price: number;
  pricePerUoM: number;
  sku: string;
  image?: string;
  tagName?: string;
  isB2O?: boolean;
  inventoryCount: number;
  palletQuantity?: number;
  stock: number;
  platformId?: string;
}

const MissionsProductsGrid: React.FC<Props> = ({ campaignItems }) => {
  const { user } = GlobalStore.getState();
  const { pocInformation } = useStore(PocInformationStore);
  const { dispatchGenericEvent } = useAnalytics();
  const { callId, clientId: accountId } = useStore(AgentCallStore);

  const isProductAddToCartEnabled = isFeatureEnabledV2(
    TLP_CAMPAIGNS_PRODUCTS_ADD_TO_CART,
    user.keyToggle,
  );

  const isProductShowStockEnabled = isFeatureEnabledV2(
    TLP_CAMPAIGNS_PRODUCTS_SHOW_STOCK,
    user.keyToggle,
  );

  const handleAddToCard = (product: AlteredProduct, itemQuantity: number) => {
    const finalItem = {
      id: product.id || product.sku,
      pricePerUoM: product.pricePerUoM,
      price: product.price,
      unitPrice: product.price,
      sku: product.sku || product.id,
      itemName: product.productName,
      itemQuantity,
      returnable: false,
      notFound: false,
      segment: "",
      total: product.price * itemQuantity,
      inventoryCount: product.inventoryCount,
      palletQuantity: product.palletQuantity,
      availabilityCount: product.stock,
      platformId: product.platformId,
    } as CartItem;

    addItem({
      ...finalItem,
      maxVehicleCapacity: pocInformation.maxVehicleCapacity,
    });

    dispatchGenericEvent(campaignsProductAdded, {
      call_id: callId,
      poc_id: accountId,
      name: product.productName,
      quantity: itemQuantity,
      sku: product.sku,
      combo_id: null,
      is_out_of_stock: product.stock === 0,
      is_promotion: false,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const flatProduct = (campaign: any) => {
    const getItemData = () => {
      return {
        ...campaign,
        price: campaign.unitPrice,
        tagName: campaign.tag,
        platformId: campaign.platformId,
      };
    };

    return getItemData();
  };

  const itemQuantityChangeAnalytics = () => {
    dispatchGenericEvent(campaignsProductQuantityEdited, {
      call_id: callId,
      poc_id: accountId,
    });
  };

  const renderProductCardV2 = (product: AlteredProduct) => {
    return (
      <ProductCardV2
        product={product}
        description={product.productName}
        hasStyleBorder
        showAddButton={isProductAddToCartEnabled}
        addAction={(quantity) => handleAddToCard(product, quantity)}
        showStock={isProductShowStockEnabled}
        itemQuantityChangeAnalytics={itemQuantityChangeAnalytics}
      />
    );
  };

  return (
    <Grid container spacing={2} style={{ paddingTop: 16 }}>
      {campaignItems.map((campaign) => (
        <Grid
          style={{ minWidth: "280px", padding: "4px" }}
          item
          md={6}
          key={`${campaign.sku}_${campaign.tag}`}
        >
          {campaign.isB2O ? (
            renderProductCardV2(flatProduct(campaign))
          ) : (
            <ProductCard product={flatProduct(campaign)} />
          )}
        </Grid>
      ))}
    </Grid>
  );
};
export default React.memo(MissionsProductsGrid);
