import * as LoyaltyBusinessServices from "../../services/loyaltyBusiness/LoyaltyBusinessServices";
import EarnRule from "../../domains/EarnRule";

export function executeV2(
  accountId: string,
  platformIds: Array<string | undefined>,
): Promise<Array<EarnRule>> {
  return LoyaltyBusinessServices.getLoyaltyBusinessRulesV2(
    accountId,
    platformIds,
  );
}
