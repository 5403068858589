import { createStore } from "effector";
import MarketplaceState from "./MarketplaceState";
import { getMarketplace } from "./MarketplaceEvents";

const initialState = <MarketplaceState>{
  done: false,
  error: false,
  marketplaceList: [],
};

const MarketplaceStore = createStore(initialState)
  .on(getMarketplace, (state) => ({
    ...state,
    done: false,
    error: false,
  }))
  .on(getMarketplace.done, (state, { result }) => ({
    ...state,
    marketplaceList: result,
    error: false,
    done: true,
  }))
  .on(getMarketplace.fail, (state) => ({
    ...state,
    error: true,
    done: true,
  }));

export default MarketplaceStore;
