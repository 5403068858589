import React from "react";

const MinusIconControl: React.FunctionComponent = () => {
  return (
    <svg
      width="11px"
      height="20px"
      viewBox="0 0 64 55"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="minus"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M53.3333333,29 C54.8060927,29 56,30.1939073 56,31.6666667 C56,33.0342289 54.9705595,34.1613524 53.644323,34.3153927 L53.3333333,34.3333333 L10.6666667,34.3333333 C9.19390733,34.3333333 8,33.139426 8,31.6666667 C8,30.2991044 9.02944051,29.1719809 10.355677,29.0179406 L10.6666667,29 L53.3333333,29 Z"
          id="Path"
          fill="#000000"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export default MinusIconControl;
