import React, { useState, useCallback, useEffect } from "react";

import {
  SearchField,
  EmptyState,
  LoadingDots,
  Toast,
} from "@hexa-ui/components";
import { Box, Typography } from "@material-ui/core";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import { getUserInfos } from "@config/utils/functions";
import SearchResultIcon from "../../../../assets/newIcons/SearchResultIcon";
import {
  GROW_TECH_DISABLE_FREE_GOODS,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";
import { productSearchInteraction } from "../../../../config/typewriter";
import {
  PromotionFreeGoodResponse,
  PromotionFreeGoodItemResponse,
} from "../../../../converters/promotions/promotionsV3/domains/PromotionsV3ResponseToPromotionsInfoConverterDomain";
import { CatalogItem } from "../../../../domains/Catalog";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import PromotionsStore from "../../../../stores/promotions/PromotionsStore";
import {
  getSearchItems,
  getSearchMoreItems,
  resetSearchItems,
} from "../../../../stores/search/SearchApiCatalogEvents";
import SearchCatalogStore from "../../../../stores/search/SearchCatalogStore";
import SearchComponent from "../searchCatalog/SearchCatalogStyles";
import CatalogVirtualizedTable from "../searchCatalog/virtualized/CatalogVirtualizedTable";
import { LoadingProducts, ContainerDots } from "./SearchProductsStyles";

const SearchProducts: React.FunctionComponent = () => {
  const classes = SearchComponent();

  const { t } = useTranslation();
  const AgentCallStates = useStore(AgentCallStore);
  const {
    loading,
    totalPages,
    error,
    searchItems,
    totalElements,
    done,
    errorSearchMore,
  } = useStore(SearchCatalogStore);
  const { user } = GlobalStore.getState();
  const { promotions } = PromotionsStore.getState();
  const [searchResult, setSearchResult] = useState<CatalogItem[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [freegoodsItemsSkus, setFreegoodsItemsSkus] = useState<Array<string>>(
    [],
  );
  const [page, setPage] = useState(0);
  const { vendorId } = getUserInfos();

  const isFreegoodsDisabled = isFeatureEnabledV2(
    GROW_TECH_DISABLE_FREE_GOODS,
    user.keyToggle,
  );

  const clearSearch = useCallback(() => {
    resetSearchItems();
    resetSearchItems.watch(() => {
      setSearchResult([]);
    });
  }, []);

  useEffect(() => {
    if (isFreegoodsDisabled) {
      let freegoods: Array<PromotionFreeGoodResponse> = [];
      let freegoodsItems: Array<PromotionFreeGoodItemResponse> = [];

      promotions.forEach((promotion) => {
        freegoods = freegoods.concat(promotion?.freegoods ?? []);
      });

      freegoods.forEach((freeGood) => {
        freegoodsItems = freegoodsItems.concat(freeGood.items);
      });

      setFreegoodsItemsSkus(
        freegoodsItems.map((freeGoodItem) => freeGoodItem.sku),
      );
    }
  }, [promotions, isFreegoodsDisabled]);

  useEffect(() => {
    if (searchValue === "") {
      clearSearch();
    }

    setSearchResult(
      isFreegoodsDisabled
        ? searchItems.filter(
            (catalogItem) =>
              !freegoodsItemsSkus.some(
                (freeGoodItemSku) => freeGoodItemSku === catalogItem.sku,
              ),
          )
        : searchItems,
    );
  }, [
    searchItems,
    searchValue,
    clearSearch,
    freegoodsItemsSkus,
    isFreegoodsDisabled,
  ]);

  const onSearch = useCallback(
    async (currentValue: string) => {
      if (currentValue) {
        productSearchInteraction({
          button_label: "search_field",
          button_name: "Search",
          search_query: currentValue,
        });

        setSearchValue(currentValue);

        await getSearchItems({
          accountId: AgentCallStates.clientId,
          valueTyped: currentValue,
          vendorId,
          page: 0,
          pageSize: 50,
        });

        setSearchValue(currentValue);
        setPage(1);
      }
    },
    [AgentCallStates.clientId, vendorId],
  );

  const onLoadMore = useCallback(async () => {
    await getSearchMoreItems({
      accountId: AgentCallStates.clientId,
      valueTyped: searchValue,
      vendorId,
      page,
      pageSize: 50,
    });

    setPage(page + 1);
  }, [searchValue, page, AgentCallStates.clientId, vendorId]);

  const renderLoading = () => {
    return (
      loading && (
        <LoadingProducts data-testid="loading-products">
          <ContainerDots>
            <LoadingDots />
          </ContainerDots>
          <Typography
            variant="h2"
            style={{ color: "rgba(0,0,0,0.55)", fontWeight: 400 }}
          >
            {t("OrderTaking.CATALOG_LOADING_LABEL")}
          </Typography>
        </LoadingProducts>
      )
    );
  };

  const moreItemsToShow = page < totalPages;

  const renderCatalog = () => {
    return (
      !error &&
      (searchResult?.length !== 0 ? (
        <CatalogVirtualizedTable
          catalogs={searchResult}
          searchValue={searchValue}
          onClick={onLoadMore}
          moreItemsToShow={moreItemsToShow}
          data-testid="catalog-virtualized-table-products"
        />
      ) : (
        renderNoResults()
      ))
    );
  };

  const renderError = () => {
    return (
      <div className={classes.imageContainer}>
        <EmptyState.PageLevel
          description={t("OrderTaking.CATALOG_ERROR_LABEL")}
          ilustrationName="beesPageCouldntLoad"
          actions={[
            {
              action: () => onSearch(searchValue),
              name: t("OrderTaking.CATALOG_RELOAD_LABEL"),
            },
          ]}
          data-testid="error-products"
        />
      </div>
    );
  };

  const renderNoResults = () => {
    return done ? (
      <div className={classes.imageContainer}>
        <EmptyState.PageLevel
          description={`${t("OrderTaking.CATALOG_NO_RESULTS")}\n${t(
            "OrderTaking.CATALOG_SEARCH_ANOTHER",
          )}`}
          data-testid="no-result-products"
        />
      </div>
    ) : (
      <div className={classes.imageContainer}>
        <EmptyState.PageLevel
          description={t("OrderTaking.SEARCH_EMPTY_PRODUCTS")}
          ilustrationName="beesSearch"
          data-testid="empty-products"
        />
      </div>
    );
  };

  return (
    <>
      {errorSearchMore && (
        <Toast.Provider duration={3000} swipeDirection="right">
          <Toast.Root
            message={t("OrderTaking.SEARCH_ERROR_LOST_CONNECTION")}
            position="top"
            type="error"
            componentVariant="toast"
            data-testid="toast-error-connection"
          />
        </Toast.Provider>
      )}
      <Box className={classes.searchContainer}>
        <div className={classes.headerContainer}>
          <div className={classes.titleContainer}>
            <SearchResultIcon id="searchResultCatalogTitleIcon" />
            <Typography className={classes.title} variant="h1">
              {t("OrderTaking.SEARCH_RESULTS_LABEL")}
            </Typography>
          </div>
          <h5 className={classes.label} data-testid="products-results-label">
            {totalElements}
            {totalElements === 1
              ? t("OrderTaking.SEARCH_PRODUCTS_RESULT")
              : t("OrderTaking.SEARCH_PRODUCTS_RESULTS")}
          </h5>
        </div>

        <SearchField.Root
          placeholder={t("OrderTaking.SEARCH_PRODUCTS_LABEL")}
          onSearch={onSearch}
          onClear={clearSearch}
          className={classes.searchProduct}
          data-testid="search-products-field"
        />
        {!loading && error && renderError()}
        {loading ? renderLoading() : renderCatalog()}
      </Box>
    </>
  );
};

export default SearchProducts;
