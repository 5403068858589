import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import useStyles from "./RefreshTableButton.styles";

interface RefreshTableButtonProps {
  titleUpdate: string;
  refreshFunction: () => void;
  containerTestId?: string;
  buttonTestId: string;
}

const RefreshTableButton = ({
  refreshFunction,
  titleUpdate,
  buttonTestId,
  containerTestId,
}: RefreshTableButtonProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.flex} data-testid={containerTestId}>
      <Typography className={classes.titleUpdate}>{titleUpdate}</Typography>
      <Button
        aria-label="refresh"
        data-testid={buttonTestId}
        className={classes.refreshButton}
        variant="outlined"
        onClick={refreshFunction}
      >
        <AutorenewIcon className={classes.refreshButtonIcon} />
      </Button>
    </Box>
  );
};

RefreshTableButton.defaultProps = {
  containerTestId: "",
};

export default RefreshTableButton;
