export const CALL_LIST_SERVICE = "tlp-call-list-service";
export const AGENT_CALL_SERVICE = "agent-call-service";
export const ORDER_GAP_SERVIE = "order-gap-service";
export const CART_SERVICE = "cart-service";
export const CHECKOUT_SERVICE = "checkout-service";
export const B2B_TRAINER_SERVICE = "b2b-trainer-service";
export const AGENTS_KPI_SERVICE = "agents-kpi-service";
export const AGENT_SURVEY_SERVICE = "agent-survey-service";
export const TLP_ACCOUNT_SERVICE = "tlp-account-service";
export const TLP_ITEM_SERVICE = "tlp-item-service";
export const TLP_CAMPAIGN_SERVICE = "tlp-campaign-service";
export const GROW_ADMIN_CONFIG_SERVICE = "grow-admin-config-service";
export const CUSTOM_MISSIONS_SERVICE = "grow-missions-service";
export const CUSTOM_FLEXIBLE_MISSIONS_SERVICE =
  "grow-flexible-missions-service";
export const GROW_BFF_SERVICE = "bees-grow-bff";
export const GROW_ORDER_TAKING = "bees-grow-order-taking-bff-service";
export const SMART_CALL_QUEUES = "smart-call-queues-service";

/**
 * Use to call microservices on uat
 [CALL_LIST_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 [AGENT_CALL_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 [B2B_TRAINER_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 [AGENTS_KPI_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 [AGENT_SURVEY_SERVICE,"https://cx-tlp-uat.bees-platform.dev"],
 [TLP_ACCOUNT_SERVICE,"https://cx-tlp-uat.bees-platform.dev"],
 [TLP_ITEM_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 [TLP_CAMPAIGN_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 [GROW_ADMIN_CONFIG_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 [CUSTOM_MISSIONS_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 [CUSTOM_FLEXIBLE_MISSIONS_SERVICE, "https://cx-tlp-uat.bees-platform.dev"],
 [GROW_BFF_SERVICE, "https://cx-tlp-uat.bees-platform.dev/api/v1/bees-grow-bff"],
 */
export const MICRO_SERVICES_HOSTS = new Map<string, string>([
  [CALL_LIST_SERVICE, "http://localhost:8081"],
  [AGENT_CALL_SERVICE, "http://localhost:8082"],
  [B2B_TRAINER_SERVICE, "http://localhost:8084"],
  [AGENTS_KPI_SERVICE, "http://localhost:8085"],
  [AGENT_SURVEY_SERVICE, "http://localhost:8086"],
  [TLP_ACCOUNT_SERVICE, "http://localhost:8087"],
  [TLP_ITEM_SERVICE, "http://localhost:8088"],
  [TLP_CAMPAIGN_SERVICE, "http://localhost:8089"],
  [GROW_ADMIN_CONFIG_SERVICE, "http://localhost:8090"],
  [CUSTOM_MISSIONS_SERVICE, "http://localhost:8090"],
  [CUSTOM_FLEXIBLE_MISSIONS_SERVICE, "http://localhost:8089"],
  [GROW_BFF_SERVICE, "http://localhost:8080"],
  [GROW_ORDER_TAKING, "http://localhost:8080"],
  [SMART_CALL_QUEUES, "http://localhost:8080"],
]);

export const MICROSERVICES_NAME = new Map<string, string>([
  [CALL_LIST_SERVICE, CALL_LIST_SERVICE],
  [AGENT_CALL_SERVICE, AGENT_CALL_SERVICE],
  [B2B_TRAINER_SERVICE, B2B_TRAINER_SERVICE],
  [AGENTS_KPI_SERVICE, AGENTS_KPI_SERVICE],
  [AGENT_SURVEY_SERVICE, AGENT_SURVEY_SERVICE],
  [TLP_ACCOUNT_SERVICE, TLP_ACCOUNT_SERVICE],
  [TLP_ITEM_SERVICE, TLP_ITEM_SERVICE],
  [TLP_CAMPAIGN_SERVICE, TLP_CAMPAIGN_SERVICE],
  [GROW_ADMIN_CONFIG_SERVICE, GROW_ADMIN_CONFIG_SERVICE],
  [CUSTOM_MISSIONS_SERVICE, CUSTOM_MISSIONS_SERVICE],
  [CUSTOM_FLEXIBLE_MISSIONS_SERVICE, CUSTOM_FLEXIBLE_MISSIONS_SERVICE],
  [GROW_BFF_SERVICE, GROW_BFF_SERVICE],
  [GROW_ORDER_TAKING, GROW_ORDER_TAKING],
  [SMART_CALL_QUEUES, SMART_CALL_QUEUES],
]);
