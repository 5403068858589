import React from "react";
import { Typography } from "@material-ui/core";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import CartItemStore from "../../../../../../../stores/cart/CartItemStore";
import PocInformationStore from "../../../../../../../stores/callList/pocInformation/PocInformationStore";

import ProgressBar from "../../../../../../../components/progressBar/ProgressBar";
import usePalletTabStyles from "./PalletTab.styles";

const PalletTab = (): JSX.Element => {
  const { t } = useTranslation();

  const CartItemStates = useStore(CartItemStore);
  const { pocInformation } = useStore(PocInformationStore);

  const classes = usePalletTabStyles();

  type trucksValueType = (value: number) => number | string;
  const renderTrucksValue: trucksValueType = (value: number) => {
    return value !== null && value >= 0
      ? Number(value)
      : String(t("GlobalMessage.NO_DATA_AVAILABLE"));
  };

  return (
    <>
      <div data-testid="shipping-setup-loading-capacity-container">
        <Typography className={classes.shippingSetup}>
          {t("OrderTaking.LOADING_CAPACITY")}
        </Typography>
        <Typography
          className={classes.shippingValues}
          data-testid="shipping-setup-loading-capacity-value"
        >
          {`${t("OrderTaking.TRUCK_LABEL")}: ${renderTrucksValue(
            pocInformation.maxVehicleCapacity as number,
          )}`}
        </Typography>
      </div>

      <Typography className={classes.shippingSetup}>
        {t("OrderTaking.CURRENT_SHIPPING")}
      </Typography>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography className={classes.shippingValues}>
          {`${t("OrderTaking.PALLETS_NAME")}: ${CartItemStates.totalPallets}`}
        </Typography>
        <ProgressBar
          value={CartItemStates?.totalTrucks?.totalItems || 0}
          percentage={CartItemStates?.totalTrucks?.percentage || 0}
          text={t("OrderTaking.NEW_TRUCK")}
        />
      </div>
      <Typography className={classes.shippingValues}>
        {`${t("OrderTaking.CASES_NAME")}: ${CartItemStates.totalItems}`}
      </Typography>
      <Typography
        className={classes.shippingValues}
        style={{ marginTop: "30px" }}
        data-testid="shipping-setup-trucks-values"
      >
        {`${t("OrderTaking.TRUCKS")}: ${
          /* istanbul ignore next-line */
          CartItemStates?.totalTrucks?.totalTrucks || 0
        }`}
      </Typography>
    </>
  );
};

export default PalletTab;
