import React, { useState, useEffect, useMemo } from "react";
import { Box, Grid, Divider } from "@material-ui/core";
import useStyles from "./AllMissionsStyles";
import { Mission } from "../../../../../../domains/Mission";
import MissionAccomplished from "./missionsAccomplished/MissionAccomplished";
import MissionsNotAccomplished from "./missionsNotAccomplished/MissionsNotAccomplished";

interface Props {
  missions?: Mission[];
}

const AllMissions: React.FC<Props> = ({ missions }) => {
  const classes = useStyles();
  const [accomplishedMissions, setAccomplishedMissions] = useState<Mission[]>(
    [],
  );
  const [notAccomplishedMissions, setNotAccomplishedMissions] = useState<
    Mission[]
  >([]);

  useEffect(() => {
    const newAccomplishedMissions =
      missions?.filter((mission) => mission.effectiveness) || [];
    const newNotAccomplishedMissions =
      missions?.filter((mission) => !mission.effectiveness) || [];
    setAccomplishedMissions(newAccomplishedMissions);
    setNotAccomplishedMissions(newNotAccomplishedMissions);
  }, [missions]);

  const gridWidth = useMemo(
    () =>
      !!accomplishedMissions.length && !!notAccomplishedMissions.length
        ? 5
        : true,
    [accomplishedMissions, notAccomplishedMissions],
  );

  return (
    <Grid container>
      {!!accomplishedMissions.length && (
        <Grid item xs={gridWidth}>
          <Box
            className={classes.accomplished}
            data-testid="missions-accomplished-box"
          >
            <MissionAccomplished missions={accomplishedMissions} />
          </Box>
        </Grid>
      )}
      {!!accomplishedMissions.length && !!notAccomplishedMissions.length && (
        <Grid item data-testid="missions-divider">
          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
        </Grid>
      )}
      {!!notAccomplishedMissions.length && (
        <Grid item xs={gridWidth}>
          <Box
            className={classes.notaccomplished}
            data-testid="missions-not-accomplished-box"
          >
            <MissionsNotAccomplished missions={notAccomplishedMissions} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default AllMissions;
