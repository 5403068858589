import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";

import { isFeatureEnabled } from "grow-shared-services";

import { GROW_BFF_SERVICE } from "@config/services";
import { getUserInfos } from "@config/utils/functions";
import axios from "../../../config/axios/axiosInstance";
import { GROW_ORGANIZATION_ACTIVATION } from "../../../config/featureToggles";
import { PromotionsInfoV3 } from "../../../converters/promotions/promotionsV3/domains/PromotionsV3ResponseToPromotionsInfoConverterDomain";
import PromotionsV3ResponseToPromotionsInfoConverter from "../../../converters/promotions/promotionsV3/PromotionsV3ResponseToPromotionsInfoConverter";
import { getApiHost as getApiB2bHost } from "../../host/HostB2BService";
import { getApiHost } from "../../host/HostService";
import { getStoreIdHost } from "../../host/HostStoreId";
import PromotionsResponseV3 from "../domains/PromotionsResponseV3";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getPromotions(
  accountId: string,
  country: string,
  vendorName: string,
): Promise<PromotionsInfoV3[]> {
  const log = useLogService();

  const { vendorId } = getUserInfos();

  const storeId = getStoreIdHost(country, vendorName);

  const isBffPromotionsEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  const promotionsEndpoint = `${getApiB2bHost()}/api/v1/promo-fusion-service/v3/promotions?accountId=${accountId}&storeId=${storeId}`;

  const bffPromotionsEndpoint = `${getApiHost(
    GROW_BFF_SERVICE,
  )}/v1/accounts/${accountId}/promotions`;

  const endpoint = isBffPromotionsEnabled
    ? bffPromotionsEndpoint
    : promotionsEndpoint;

  return axios
    .get<PromotionsResponseV3>(endpoint, {
      headers: isBffPromotionsEnabled ? { vendorId } : {},
    })
    .then((response) => {
      log.info(
        `Convert Promotions Response received from ${endpoint} to our model`,
      );
      return PromotionsV3ResponseToPromotionsInfoConverter(
        response.data.promotions,
        vendorId,
      );
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
