import { createStore } from "effector";
import { getServicesMissionsEvent } from "./ServicesMissionsEvents";
import ServicesMissionsState from "./ServicesMissionsState";

const initialState = <ServicesMissionsState>{
  isLoading: false,
  error: false,
  servicesMissions: [],
};

const ServicesMissionsStore = createStore(initialState)
  .on(getServicesMissionsEvent, (state: ServicesMissionsState) => ({
    ...state,
    isLoading: true,
    error: false,
  }))
  .on(
    getServicesMissionsEvent.done,
    (state: ServicesMissionsState, { result: servicesMissions }) => ({
      ...state,
      servicesMissions,
      isLoading: false,
      error: false,
    }),
  )
  .on(getServicesMissionsEvent.fail, (state: ServicesMissionsState) => ({
    ...state,
    isLoaded: true,
    isLoading: false,
    error: true,
  }));

export default ServicesMissionsStore;
