import { createStore } from "effector";
import { LastMarketplaceOrdersState } from "../../domains/lastMaketplaceOrders/LastMarketplaceOrders";
import { getLastMarketplaceOrders } from "./LastMarketplaceOrdersEvents";

const initialState = <LastMarketplaceOrdersState>{
  isLoading: true,
  error: false,
  lastOrders: [],
  marketplaceOrders: [],
};

const LastMarketplaceOrdersStore = createStore(initialState)
  .on(getLastMarketplaceOrders, (state) => ({
    ...state,
    isLoading: true,
    error: false,
  }))
  .on(getLastMarketplaceOrders.fail, (state) => ({
    ...state,
    isLoading: false,
    error: true,
  }))
  .on(getLastMarketplaceOrders.done, (state, { result }) => ({
    ...state,
    isLoading: false,
    error: false,
    lastOrders: result.lastOrders,
    marketplaceOrders: result.marketplaceOrders,
  }));

export default LastMarketplaceOrdersStore;
