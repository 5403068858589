import React from "react";
import { Skeleton } from "@material-ui/lab";
import { useStore } from "effector-react";
import { Card } from "@material-ui/core";
import SponsoredComponentSkeletonStyles from "./SponsoredComponent.styles";
import SponsoredCardSkeleton from "../sponsoredCard/SponsoredCardSkeleton";
import GlobalStore from "../../../../stores/global/GlobalStore";
import {
  isFeatureEnabledV2,
  GROW_LOCAL_HEADER_REDESIGN,
} from "../../../../config/featureToggles";

const SponsoredComponentSkeleton: React.FC = () => {
  const classes = SponsoredComponentSkeletonStyles();
  const { user } = useStore(GlobalStore);

  const isHeaderRedesign = isFeatureEnabledV2(
    GROW_LOCAL_HEADER_REDESIGN,
    user.keyToggle,
  );

  const cardTitle = (width: string) => {
    return (
      <Card
        className={classes.skeletonContainerRedesign}
        data-testid="skeleton-redesign-component"
      >
        <Skeleton width={width} height={25} />
      </Card>
    );
  };

  return isHeaderRedesign ? (
    <>
      <SponsoredCardSkeleton />
      {cardTitle("155px")}
      {cardTitle("210px")}
    </>
  ) : (
    <div className={classes.skeletonContainer} data-testid="skeleton-component">
      <div className={classes.collapsibleSkeleton}>
        <Skeleton width={151} height={29} />
        <Skeleton width={57} height={29} />
      </div>
      <SponsoredCardSkeleton />
    </div>
  );
};

export default SponsoredComponentSkeleton;
