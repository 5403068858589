import React from "react";
import { useTranslation } from "react-i18next";
import NoStockStyles from "./NoStock.styles";

interface NoStockProps {
  isDisplayStockCatalog?: boolean;
}

const NoStock = ({
  isDisplayStockCatalog,
}: NoStockProps): React.ReactElement => {
  const classes = NoStockStyles();
  const { t } = useTranslation();

  return (
    <div
      className={isDisplayStockCatalog ? classes.label : classes.title}
      data-testid="stock-out-message-container"
    >
      <span data-testid="stock-out-message">
        {t("SuggestedOrder.STOCK_OUT")}
      </span>
    </div>
  );
};

export default NoStock;
