import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    stockSection: {
      marginBottom: "5px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
    },
    inStock: {
      color: "#757575",
    },
    noStock: {
      color: "#C9201D",
    },
  }),
);
