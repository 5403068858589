import { createEvent } from "effector";

interface OrderInfo {
  orderId: string;
  orderReason: string;
}

interface OrdersOnGetOpenCall {
  ordersList: OrderInfo[];
  hasTechnicalProblem: boolean;
}

export const resetOrdersStore = createEvent<null>("resetOrdersStore");

export const setHasTechnicalProblem = createEvent<boolean>(
  "setHasTechnicalProblem",
);
export const setOrderReason = createEvent<string>("setOrderReason");

export const addOrdersInfoToList = createEvent<OrderInfo>("setOrdersList");

export const setOrdersOnGetOpenCall = createEvent<OrdersOnGetOpenCall>(
  "setOrdersOnGetOpenCall",
);
