import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      padding: "1.1rem",
    },
    text: {
      fontSize: "1rem",
      color: palette.text.secondary,
      letterSpacing: "0.1px",
    },
  }),
);
