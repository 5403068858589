import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import Moment from "moment";

import { Box, Typography } from "@material-ui/core";

import { Heading, Badge, Paragraph } from "@hexa-ui/components";
import { Warehouse } from "@hexa-ui/icons";
import WarningOutOfStockModal from "../../../order/WarningOutOfStockModal";
import ErrorOrderModal from "../../../order/errorOrderModal/ErrorOrderModal";
import PocInformationStore from "../../../../stores/callList/pocInformation/PocInformationStore";
import {
  GROW_ORGANIZATION_ACTIVATION,
  GROW_TECH_CART_AUTO_UPDATE,
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_ERROR_MESSAGE_ORDER_MODAL,
  TLP_ERROR_MESSAGE_ORDER_MODAL_ADMIN,
  TLP_ORDER_ITEM_OUT_STOCK,
  TLP_ORDER_ITEM_OUT_STOCK_ADMIN,
  TLP_PALLET_ITEMS,
  TLP_PALLET_ITEMS_ADMIN,
} from "../../../../config/featureToggles";
import CartItemStore from "../../../../stores/cart/CartItemStore";
import CartStore from "../../../../stores/cart/CartStore";
import {
  setIsOrderItemOutOfStockOpenModal,
  setIsErrorMessageOrderModalOpen,
} from "../../../../stores/cart/CartEvents";
import { CartForm } from "../../../../stores/cart/CartState";
import GlobalStore from "../../../../stores/global/GlobalStore";
import { errorMessageViewed } from "../../../../config/typewriter";
import { getDatesRange } from "../../../../config/utils/functions";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import CartIcon from "../../../../assets/newIcons/OrderCartIcon";
import { useRequestToPlaceOrder } from "./hooks/useRequestToPlaceOrder";
import { useUpdateCartHook } from "./hooks/useUpdateCartHook";
import { useCartFreeGoodsHook } from "./hooks/useCartFreeGoodsHook";
import PaymentCartForm from "./paymentCartForm/PaymentCartForm";
import CartSummary from "./cartSummary/CartSummary";
import FreeGoodsCartSection from "./freeGoodsCartSection/FreeGoodsCartSection";
import OrderCartStyles from "./OrderCartStyles";

const DATE_FORMAT = "YYYY-MM-DD";

const OrderCart: React.FunctionComponent = () => {
  const classes = OrderCartStyles();
  const { t } = useTranslation();

  // update
  const { dispatchPocEvent } = useAnalytics();
  const { pocInformation } = useStore(PocInformationStore);

  const { sendRequestToPlaceOrder } = useRequestToPlaceOrder();
  const { user } = useStore(GlobalStore);
  const CartItemStates = useStore(CartItemStore);
  const CartStates = useStore(CartStore);
  const { needUpdate, setNeedToUpdate, updateCart, getCombosRequest } =
    useUpdateCartHook();
  const { showFreeGoodsCartSection } = useCartFreeGoodsHook();

  const isErrorMessageOrderModalEnabled = isFeatureEnabled(
    TLP_ERROR_MESSAGE_ORDER_MODAL,
    TLP_ERROR_MESSAGE_ORDER_MODAL_ADMIN,
    user.keyToggle,
  );
  const isOrderItemOutStockEnabled = isFeatureEnabled(
    TLP_ORDER_ITEM_OUT_STOCK,
    TLP_ORDER_ITEM_OUT_STOCK_ADMIN,
    user.keyToggle,
  );

  const { isOrderItemOutOfStockOpenModal, isErrorMessageOrderModalOpen } =
    CartStates;
  const errorModalOpen =
    isErrorMessageOrderModalEnabled && isErrorMessageOrderModalOpen;

  const isCartAutoUpdate = isFeatureEnabledV2(
    GROW_TECH_CART_AUTO_UPDATE,
    user.keyToggle,
  );

  const isPalletEnabled = isFeatureEnabled(
    TLP_PALLET_ITEMS,
    TLP_PALLET_ITEMS_ADMIN,
    user.keyToggle,
  );

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const cartNeedUpdate = isCartAutoUpdate ? false : needUpdate;

  const rangeAlternativeDates = useMemo(() => {
    const alternativeDates =
      pocInformation.deliveryWindows?.filter((item) => item.alternative) || [];
    const dates = new Set();
    alternativeDates.forEach((item) => {
      const { startDate, endDate } = item;
      const range = getDatesRange(startDate, endDate, "days");
      range.forEach((eachItem: Moment.Moment) => {
        dates.add(eachItem.format(DATE_FORMAT));
      });
    });
    return Array.from(dates);
  }, [pocInformation.deliveryWindows]);

  const cartFormRef = React.useRef<CartForm>({
    paymentMethod: "",
    paymentTerm: 0,
    details: "",
    deliveryDate: "",
  });

  cartFormRef.current = CartStates.cartForm;

  const isAlternativeDateSelected = (value?: string) => {
    const { deliveryDate } = CartStates.cartForm;
    return rangeAlternativeDates.includes(
      Moment(value || deliveryDate)
        .utc()
        .format(DATE_FORMAT),
    );
  };

  const prevCartTotalItem = useRef<number | undefined>();

  useEffect(() => {
    prevCartTotalItem.current = CartItemStates?.totalItems;
  });

  const CartTotalItemValue =
    prevCartTotalItem.current ?? CartItemStates?.totalItems;

  /* istanbul ignore next */
  useEffect(() => {
    if (
      CartTotalItemValue !== CartItemStates?.totalItems &&
      CartItemStates?.totalItems > 0
    ) {
      setNeedToUpdate(true);
    }
  }, [setNeedToUpdate, CartItemStates?.totalItems, CartTotalItemValue]);

  /* istanbul ignore next */
  if (isPalletEnabled) {
    if (!pocInformation.maxVehicleCapacity) {
      dispatchPocEvent(
        errorMessageViewed,
        {
          screen_name: "Call - Orders",
          error_type: "Loading capacity information not found",
          error_message: "No data available",
          screen_section: "Shipping Setup",
          is_all_products: null,
          DDC: null,
        },
        { time_of_day: true, is_resumed: true },
      );
    }
  }

  const handleSubmit = (technicalProblem: boolean, orderReason: string) => {
    sendRequestToPlaceOrder(
      technicalProblem,
      orderReason,
      rangeAlternativeDates as string[],
      getCombosRequest,
      isAlternativeDateSelected,
      cartFormRef,
    );
  };

  const handleUpdateCart = () => updateCart();

  const errorText = () => {
    if (CartItemStates.cart?.outputMessages === undefined) return "";

    const position = CartItemStates.cart?.outputMessages.findIndex((each) => {
      return each.type === "ERROR";
    });

    return CartItemStates.cart.outputMessages[position].text;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerContainer} style={{ flex: "0 1 auto" }}>
        <div className={classes.titleContainer}>
          <CartIcon />
          <Typography className={classes.title} variant="h1">
            {t("OrderTaking.ORDER_CART_LABEL")}
          </Typography>
        </div>
      </div>
      <Box className={classes.cartContainer}>
        <PaymentCartForm
          isAlternativeDateSelected={isAlternativeDateSelected}
        />

        <Box className={classes.cartSummaryArea}>
          {showFreeGoodsCartSection && (
            <FreeGoodsCartSection data-testid="cart-free-goods-section" />
          )}
          <Box className={classes.cartSummaryContainer}>
            <div className={classes.cartTitleCounterContainer}>
              <Heading size="H5">{t("LastOrders.PRODUCTS_TEXT")}</Heading>
              {CartItemStates.totalItems > 0 && (
                <Badge.Counter
                  counterLabel={CartItemStates.totalItems}
                  size="small"
                  className={classes.counter}
                  data-testid="total-items-counter"
                />
              )}
            </div>

            {isOrganizationConfigEnabled &&
              CartItemStates.cart?.vendorName &&
              CartItemStates.totalItems > 0 && (
                <div
                  className={classes.fulfilledContainer}
                  data-testid="fulfilled-container"
                >
                  <Warehouse size="medium" />
                  <Paragraph size="xsmall">
                    {t("OrderTaking.FULFILLED_BY")}{" "}
                    {CartItemStates.cart.vendorName}.
                  </Paragraph>
                </div>
              )}

            <CartSummary
              title={t("OrderTaking.ORDER_SUMMARY_LABEL")}
              updateCart={handleUpdateCart}
              needUpdate={cartNeedUpdate}
              sendRequestToPlaceOrder={handleSubmit}
            />
          </Box>
        </Box>
      </Box>

      {errorModalOpen && (
        <ErrorOrderModal
          isOpen={isErrorMessageOrderModalOpen}
          setIsOpen={setIsErrorMessageOrderModalOpen}
          error={errorText()}
        />
      )}

      {isOrderItemOutOfStockOpenModal &&
        isOrderItemOutStockEnabled &&
        !errorModalOpen && (
          <WarningOutOfStockModal
            isOpen={isOrderItemOutOfStockOpenModal}
            onClose={() => {
              setIsOrderItemOutOfStockOpenModal(false);
            }}
            cartItems={CartItemStates.cartItems}
          />
        )}
    </div>
  );
};

export default OrderCart;
