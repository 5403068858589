import { createEffect, createEvent } from "effector";
import { getRewardsChallengeV2 } from "../../services/rewardsChallenges/v2/RewardsChallengesServiceV2";
import { getRewardsChallenge } from "../../services/rewardsChallenges/RewardsChallengesService";

const executionMethods = [
  "MARK_COMPLETE",
  "TAKE_PHOTO",
  "PURCHASE",
  "PURCHASE_MULTIPLE",
  "PURCHASE_MULTIPLE_VOLUME_FIXED",
  "PURCHASE_MULTIPLE_CURRENCY_FIXED",
  "MY_PROGRESS",
  "PURCHASE_SINGLE_VOLUME_FIXED",
];

export const getRewardsChallengeEffect = createEffect({
  async handler(accountId: string) {
    return getRewardsChallenge(accountId, executionMethods);
  },
});

export const getRewardsChallengeEffectV2 = createEffect({
  async handler({ accountId, vendorId }) {
    return getRewardsChallengeV2(accountId, vendorId, executionMethods);
  },
});

export const resetRewards = createEvent("resetRewards");
