import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useStore } from "effector-react";
import { Container, ButtonBase, Box } from "@material-ui/core";
import { Formik, Form } from "formik";
import { object, string } from "yup";
import Modal from "../../../../../components/modal/Modal";
import Button from "../../../../../components/button/Button";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import GapModalStyles from "./GapModalStyles";
import GapReasonForm, { FormValues } from "./GapReasonForm";
import { usageTypes } from "../../../../../config/usageTypes";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
}

const GapModal: React.FunctionComponent<Props> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const classes = GapModalStyles();
  const { gapReason, gapDescription, updateCallLoading, usage } =
    useStore(AgentCallStore);

  const initialValues: FormValues = {
    gapReason,
    gapDescription: gapDescription || "",
  };

  const validationSchema = object().shape({
    gapReason: string().required(t("GapReason.REASON_REQUIRED_MESSAGE")),
  });

  const handleSubmit = (values: FormValues) => {
    onSubmit(values);
  };

  const showLoading =
    updateCallLoading && usage === usageTypes.AGENT_CALL_SERVICE.END_CALL;

  return (
    <Modal open={open} onClose={onClose}>
      <Container className={classes.container}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form className={classes.form} noValidate>
              <GapReasonForm
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
              />

              <Box className={classes.buttonsContainer}>
                <ButtonBase
                  onClick={onClose}
                  className={classNames(classes.button, classes.cancelButton)}
                  data-testid="gap-reason-cancel-button"
                >
                  {t("GapReason.CANCEL")}
                </ButtonBase>
                <Button
                  type="submit"
                  loading={showLoading}
                  className={classNames(classes.button, classes.registerButton)}
                  testId="gap-reason-register-button"
                >
                  {t("GapReason.REGISTER")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

/* istanbul ignore next */
const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.open === nextProps.open;
};

export default React.memo(GapModal, areEqual);
