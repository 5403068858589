import { EarnRuleResponse } from "../services/loyaltyBusiness/domains/LoyaltyBusinessResponse";

interface EarnRuleV1 {
  itemId: string;
  amountSpent: number;
  points: number;
  rule: string;
  platformId: string;
}
const LoyaltyBusinessResponseToEarnRulesConverter = (
  data: Array<EarnRuleResponse>,
): Array<EarnRuleV1> => {
  return data.map(
    (item) =>
      <EarnRuleV1>{
        itemId: item.itemId,
        amountSpent: item.amountSpent,
        points: item.points,
        rule: item.rule,
        platformId: item.platformId,
      },
  );
};

export default LoyaltyBusinessResponseToEarnRulesConverter;
