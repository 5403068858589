import React from "react";
import BouncingDotsLoader from "../bouncingDotsLoader/bouncingDotsLoader";

interface Props {
  color?: string;
}
const Loading: React.FC<Props> = ({ color }: Props) => {
  return (
    <div className="loading" data-testid="loading-component">
      <BouncingDotsLoader color={color || "#000"} />
    </div>
  );
};

export default Loading;
