import { useMemo } from "react";
import { useStore } from "effector-react";

import {
  isFeatureEnabledV2,
  GROW_MISSION_REWARDS_REDEMPTION,
  GROW_LOCAL_SEARCH_BY_POC,
  GROW_ALWAYS_SHOW_REWARDS_CHALLENGE,
} from "@config/featureToggles";
import RewardsChallengeStore from "@stores/RewardsChallenge/RewardsChallengeStore";
import { MISSION_TABS, PocCallOrigin } from "@config/constants";

import { getCountryByToken } from "@config/utils/functions";
import AgentCallStore from "@stores/agentCall/AgentCallStore";
import TagsStore from "@stores/tags/TagsStore";
import RedeemStore from "@stores/redeem/RedeemStore";
import useGetCampaignsHook from "../../components/campaignsComponent/useGetCampaignsHook";
import { TABSLIST } from "../../components/tabsMissions/Tabs";

const useMissionsTab = (): string[] => {
  const { tabs } = useStore(TagsStore);
  const { isTypedPoc, pocCallOrigin } = useStore(AgentCallStore);
  const [campaigns] = useGetCampaignsHook();
  const { rewardsChallengeInformation } = useStore(RewardsChallengeStore);
  const { redeemItems } = useStore(RedeemStore);

  const isWipRewardsRedemptionEnable = isFeatureEnabledV2(
    GROW_MISSION_REWARDS_REDEMPTION,
    getCountryByToken(),
  );

  const isMissionsServiceBySearchByPocEnable = isFeatureEnabledV2(
    GROW_LOCAL_SEARCH_BY_POC,
    getCountryByToken(),
  );

  const isAlwaysShowRewardsChallengeEnable = isFeatureEnabledV2(
    GROW_ALWAYS_SHOW_REWARDS_CHALLENGE,
  );

  const isSearchByPocOrAutoDialer =
    isTypedPoc &&
    (pocCallOrigin === PocCallOrigin.SEARCH_BY_POC ||
      pocCallOrigin === PocCallOrigin.AUTO_DIALER);

  return useMemo(() => {
    let tabsToRender = [...tabs];

    if (isSearchByPocOrAutoDialer) {
      const localMissionsTabs = tabs.filter(
        (tab) =>
          tab === MISSION_TABS.TAB_LOCAL_MISSIONS ||
          tab === MISSION_TABS.TAB_SPONSORED,
      );
      if (localMissionsTabs.length && isMissionsServiceBySearchByPocEnable) {
        tabsToRender.push(...localMissionsTabs);
      }
      tabsToRender.push(TABSLIST.GENERAL);
      if (campaigns.length) {
        tabsToRender.push(TABSLIST.CAMPAIGNS);
      }
    }

    tabsToRender = tabsToRender.filter(
      (tab) => !(campaigns.length === 0 && tab === TABSLIST.CAMPAIGNS),
    );

    if (isWipRewardsRedemptionEnable && redeemItems.length) {
      tabsToRender.push(MISSION_TABS.TAB_REWARDS_REDEMPTIONS);
    }

    if (
      isAlwaysShowRewardsChallengeEnable &&
      rewardsChallengeInformation?.length
    ) {
      tabsToRender.push(MISSION_TABS.TAB_REWARDS_CHALLENGES);
    }

    return Array.from(new Set(tabsToRender));
  }, [
    tabs,
    rewardsChallengeInformation?.length,
    isSearchByPocOrAutoDialer,
    isWipRewardsRedemptionEnable,
    redeemItems.length,
    isMissionsServiceBySearchByPocEnable,
    campaigns.length,
  ]);
};

export default useMissionsTab;
