import { Reedeemables } from "@domains/redeem/Redeem";
import { GetRedeemablesResponse } from "@services/redeemables/model/GetRedeemablesResponse";

export default function RedeemablesResponseToRedeemablesDomainConverter(
  data: GetRedeemablesResponse,
): Reedeemables {
  return {
    redeemableItems: data.content?.splice(0, 30).map((each) => ({
      id: each.platformId,
      image: each.image,
      points: each.points,
      title: each.title,
    })),
  };
}
