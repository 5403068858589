import { hasMarketplace } from "../../config/utils/functions";
import {
  CatalogItem,
  ItemContainer,
  Price,
  PriceDetails,
  PriceDetailsItem,
} from "../../domains/Catalog";
import {
  PromoFusion,
  SearchDomain,
  SearchResponse,
} from "../../services/searchCatalog/domains/Search";
import MarketplaceStore from "../../stores/marketplace/MarketplaceStore";

const SearchResponseToSearchConverter = (
  data: SearchResponse,
): SearchDomain => {
  const marketplaceState = MarketplaceStore.getState();
  const { marketplaceList } = marketplaceState;

  const getDiscountedPrice = (promotions: PromoFusion): number | undefined =>
    promotions?.promotions?.find((promo) => promo.pack?.preferredPrice)?.pack
      ?.discountPrice;

  const searchItems = data.products?.map((item) => {
    const isMarketplace = hasMarketplace(item.sku, marketplaceList);

    const discountedPrice = getDiscountedPrice(item.promofusion);

    const variants = item.variants?.map((variant) => {
      return {
        ...variant,
        discountedPrice: getDiscountedPrice(variant.promofusion),
      };
    });

    return <CatalogItem>{
      sku: item.sku,
      price: item.price.originalPrice ?? item.price.price,
      discountedPrice,
      name: item.itemName,
      image: item.itemImage,
      brandName: item.brandName,
      classification: item.classification,
      packagename: item.pack.name,
      palletQuantity: item.palletQuantity,
      unitOfMeasurement: item.pack.unitOfMeasurement,
      fullPackageDescription: item.pack?.fullPackageDescription,
      fullContainerDescription: item.container.fullContainerDescription,
      pricePerUoM: item.price.pricePerUoM,
      id: item.id,
      vendorItemId: item.sourceData.vendorItemId,
      vendorId: item.vendorId,
      marketplace: isMarketplace,
      itemCount: item.pack.itemCount,
      container: <ItemContainer>{
        name: item.container.name,
        returnable: item.container.returnable,
        unitOfMeasurement: item.container.unitOfMeasurement,
        itemSize: item.container.itemSize,
        fullContainerDescription: item.container.fullContainerDescription,
        material: item.container.material,
      },
      priceData: <Price>{
        pricePerUoM: item.price.pricePerUoM,
        pricePerContainer: item.price.pricePerContainer,
        price: item.price.price,
        priceDetails: <PriceDetails>{
          pack: <PriceDetailsItem>{
            preferredPrice: item.price.priceDetails.pack.preferredPrice,
            price: item.price.priceDetails.pack.price,
          },
          container: <PriceDetailsItem>{
            preferredPrice: item.price.priceDetails.container.preferredPrice,
            price: item.price.priceDetails.container.price,
          },
          uom: <PriceDetailsItem>{
            preferredPrice: item.price.priceDetails.uom.preferredPrice,
            price: item.price.priceDetails.uom.price,
          },
        },
      },
      platformId: item.platformId,
      availabilityCount: item.availability.count,
      packageId: item.pack?.packageId,
      variants,
      assortment: item.assortment,
      maxOrderQuantity: item.maxOrderQuantity,
      salesRanking: item.salesRanking,
      promofusion: item.promofusion,
    };
  });

  return { items: searchItems, pagination: data.pagination };
};

export default SearchResponseToSearchConverter;
