/* eslint-disable global-require */
import React, { useEffect, useState } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { ListItem, Grid, Box, Typography } from "@material-ui/core";
import { ListChildComponentProps } from "react-window";
import { getUserInfos } from "@config/utils/functions";
import Button from "../../../../../components/button/Button";
import Data from "../../../../../components/data/Data";
import ProductItemStyles from "./ProductItem.styles";
import { addItem } from "../../../../../stores/cart/CartItemEvents";
import { CatalogItem } from "../../../../../domains/Catalog";
import QuantityInput from "../../../../../components/quantityInput/QuantityInput";
import { useCollapsibleSideNavContext } from "../../../../../contexts/CollapsibleSideNavContext";
import {
  productAdded,
  productQuantityEdited,
} from "../../../../../config/typewriter";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import {
  CURRENCY,
  MAX_VALUE_INPUT,
  MIN_VALUE_INPUT,
} from "../../../../../config/constants";
import GenericTag from "../../../../../components/tag/GenericTag";
import { PROPS_TAG } from "../../../../../components/tag/constants";
import NoStock from "../../../../../components/stockout/NoStock";
import StockoutWarning from "../../../../../components/stockout/StockoutWarning";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_OUT_OF_STOCK_SEARCH_CATALOG,
  TLP_OUT_OF_STOCK_SEARCH_CATALOG_ADMIN,
  TLP_PALLET_ITEMS,
  TLP_PALLET_ITEMS_ADMIN,
  TLP_PRICE_PER_KG,
  TLP_PRICE_PER_KG_ADMIN,
  TLP_DISPLAY_STOCK_CATALOG,
} from "../../../../../config/featureToggles";
import PalletSize from "../palletSize/PalletSize";
import PocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import cartItemStore from "../../../../../stores/cart/CartItemStore";

const { MARKETPLACE } = PROPS_TAG;

const productPlaceholder =
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require("../../../../../assets/images/ProductPlaceholder.png") as string;

interface Props {
  productItem: CatalogItem;
  childComponentProps: ListChildComponentProps;
}
const ProductItem: React.FunctionComponent<Props> = ({
  productItem,
  childComponentProps,
}) => {
  const classes = ProductItemStyles();
  const { collapsibleIsOpen } = useCollapsibleSideNavContext();
  const [itemQuantity, setItemQuantity] = useState(productItem.itemQuantity);
  const AgentCallStates = useStore(AgentCallStore);
  const GlobalStates = useStore(GlobalStore);
  const { t } = useTranslation();
  const { pocInformation } = useStore(PocInformationStore);
  const { deleteIsLoading } = useStore(cartItemStore);

  const { vendorId } = getUserInfos();

  const isStockoutEnabled = isFeatureEnabled(
    TLP_OUT_OF_STOCK_SEARCH_CATALOG,
    TLP_OUT_OF_STOCK_SEARCH_CATALOG_ADMIN,
    GlobalStates.user.keyToggle,
  );

  const isPalletEnabled = isFeatureEnabled(
    TLP_PALLET_ITEMS,
    TLP_PALLET_ITEMS_ADMIN,
    GlobalStates.user.keyToggle,
  );

  const isPricePerKg = isFeatureEnabled(
    TLP_PRICE_PER_KG,
    TLP_PRICE_PER_KG_ADMIN,
    GlobalStates.user.keyToggle,
  );

  const isDisplayStockCatalog = isFeatureEnabledV2(
    TLP_DISPLAY_STOCK_CATALOG,
    GlobalStates.user.keyToggle,
  );

  const [src, setSrc] = useState(productItem.image);

  const displayInventoryCountOrAvailabilityCount =
    productItem.availabilityCount ?? productItem.inventoryCount;

  const shouldDisplayNoStockWarning =
    isStockoutEnabled && displayInventoryCountOrAvailabilityCount === 0;

  const shouldDisplayStockoutWarning =
    isStockoutEnabled &&
    displayInventoryCountOrAvailabilityCount < itemQuantity;

  const enableAdd = () => {
    if (productItem.vendorId) {
      return vendorId === productItem.vendorId;
    }

    return true;
  };

  const setAnalyticsObjectToCart = (value: number) => {
    return {
      name: productItem.name,
      quantity: value,
      sku: productItem.sku,
      call_id: AgentCallStates.callId,
      country: GlobalStates.user.zone,
      currency: CURRENCY[GlobalStates.user.zone]?.currency,
      original_quantity: productItem.itemQuantity,
      poc_id: AgentCallStates.clientId,
      quantity_per_pallet: productItem.palletQuantity,
      is_out_of_stock: displayInventoryCountOrAvailabilityCount === 0,
    };
  };

  const setAnalyticsObjectToProductQuantity = (value: number) => {
    return {
      call_id: AgentCallStates.callId,
      country: GlobalStates.user.zone,
      currency: CURRENCY[GlobalStates.user.zone]?.currency,
      original_quantity: productItem.itemQuantity,
      poc_id: AgentCallStates.clientId,
      quantity: value,
      is_out_of_stock: displayInventoryCountOrAvailabilityCount === 0,
      combo_id: null,
      is_combo: false,
      edit_method: "Arrow or Input",
    };
  };

  const addToCart = () => {
    const item = {
      id: productItem.id,
      pricePerUoM: productItem.pricePerUoM,
      price: productItem.price,
      unitPrice: productItem.price,
      sku: productItem.sku,
      itemName: productItem.name,
      itemQuantity,
      returnable: false,
      notFound: false,
      segment: "",
      total: productItem.price * itemQuantity,
      inventoryCount: productItem.inventoryCount,
      availabilityCount: productItem.availabilityCount,
      palletQuantity: productItem.palletQuantity,
      platformId: productItem.platformId,
    };
    addItem({ ...item, maxVehicleCapacity: pocInformation.maxVehicleCapacity });
    productAdded(setAnalyticsObjectToCart(itemQuantity));
  };

  const changeQuantity = (value: number) => {
    setItemQuantity(value);
    productQuantityEdited({
      ...setAnalyticsObjectToProductQuantity(value),
      screen_section: "Product Search Results",
    });
  };

  /* istanbul ignore next */
  useEffect(() => {
    setItemQuantity(productItem.itemQuantity);
  }, [productItem]);

  const renderUnitPrice = () => {
    return (
      <Typography className={classes.itemUnitPrice} component="span">
        <span>{`${t("OrderTaking.PRICE_UNIT_PRODUCT")}: `}</span>
        <Data
          value={productItem.price}
          type="currency"
          testId={`product-item-unit-price-${productItem.sku}`}
        />
        {productItem.marketplace && (
          <span className={classes.marketplaceTag}>
            <GenericTag
              translationKey={MARKETPLACE.translationKey}
              style={{
                backgroundColor: MARKETPLACE.backgroundColor,
              }}
              testId={`product-item-${productItem.sku}-${MARKETPLACE.testId}`}
            />
          </span>
        )}
      </Typography>
    );
  };

  const renderPricePerUoM = () => {
    return (
      <>
        <Typography className={classes.itemUnitPrice} component="span">
          <Data
            value={productItem.price}
            type="currency"
            testId={`product-item-unit-price-${productItem.sku}`}
          />
          <span>{`/${t("OrderTaking.PRICE_UNIT_INITIAL")}`}</span>
        </Typography>
        <Typography className={classes.itemPricePerUoM} component="span">
          <span> | </span>
          <Data
            value={productItem.pricePerUoM}
            type="currency"
            testId={`product-item-uom-price-${productItem.sku}`}
          />
          <span>{`/${t("OrderTaking.PRICE_UNIT_PER_UOM")}`}</span>
          {productItem.marketplace && (
            <span className={classes.marketplaceTag}>
              <GenericTag
                translationKey={MARKETPLACE.translationKey}
                style={{
                  backgroundColor: MARKETPLACE.backgroundColor,
                }}
                testId={`product-item-${productItem.sku}-${MARKETPLACE.testId}`}
              />
            </span>
          )}
        </Typography>
      </>
    );
  };

  const marginBottomPallet = isPalletEnabled ? "5px" : "0px";

  return (
    <ListItem
      className={classes.listItem}
      style={{ ...childComponentProps.style, height: "160px" }}
    >
      <Box className={classes.container}>
        <Grid item container direction="row" wrap="nowrap">
          <div className={classes.image}>
            <img
              src={src || productPlaceholder}
              onError={() => setSrc(productPlaceholder)}
              alt=""
            />
            {isPalletEnabled && (
              <PalletSize value={productItem.palletQuantity} />
            )}
          </div>
          <Grid
            item
            container
            direction="column"
            justifyContent={isPalletEnabled ? "space-between" : undefined}
          >
            <div>
              <Grid
                item
                container
                direction={collapsibleIsOpen ? "column" : "row"}
                justifyContent="space-between"
                alignItems="flex-start"
                data-testid="product-item-name-grid"
              >
                <Data
                  ellipsisSize={collapsibleIsOpen ? 35 : 30}
                  className={classes.itemName}
                  value={productItem.name}
                  type="text"
                  testId={`product-item-name-${productItem.sku}`}
                />

                <Data
                  className={classes.itemPrice}
                  value={itemQuantity * productItem.price}
                  type="currency"
                  testId={`product-item-total-price-${productItem.sku}`}
                />
                {!isDisplayStockCatalog && shouldDisplayNoStockWarning && (
                  <NoStock />
                )}
              </Grid>
              {isPricePerKg ? renderPricePerUoM() : renderUnitPrice()}
              <Typography
                className={classes.itemSku}
                data-testid={`product-item-sku-${productItem.sku}`}
                variant="subtitle1"
              >
                {productItem.sku}
              </Typography>

              {isDisplayStockCatalog && shouldDisplayNoStockWarning && (
                /* istanbul ignore next */
                <NoStock isDisplayStockCatalog={isDisplayStockCatalog} />
              )}

              {displayInventoryCountOrAvailabilityCount > 0 &&
                (isDisplayStockCatalog || shouldDisplayStockoutWarning) && (
                  <StockoutWarning
                    value={displayInventoryCountOrAvailabilityCount}
                    itemQuantity={itemQuantity}
                    isDisplayStockCatalogEnabled={isDisplayStockCatalog}
                  />
                )}
            </div>
            {enableAdd() ? (
              <div style={{ marginBottom: marginBottomPallet }}>
                <Grid
                  item
                  container
                  direction="row"
                  wrap="nowrap"
                  className={classes.btnWrapper}
                >
                  <Grid item className={classes.quantityContainer}>
                    <QuantityInput
                      maxValue={MAX_VALUE_INPUT}
                      minValue={MIN_VALUE_INPUT}
                      value={itemQuantity}
                      onChange={(value) => {
                        changeQuantity(Number(value));
                      }}
                      testId={`product-quantity-input-${productItem.sku}`}
                    />
                  </Grid>

                  <Button
                    testId={`product-item-add-button-${productItem.sku}`}
                    className={classes.addButton}
                    loading={deleteIsLoading}
                    onClick={() => {
                      addToCart();
                    }}
                  >
                    {t("OrderTaking.ADD_CART_BUTTON")}
                  </Button>
                </Grid>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </ListItem>
  );
};

export default ProductItem;
