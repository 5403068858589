import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { isFeatureEnabled } from "grow-shared-services";

import { GROW_BFF_SERVICE, GROW_ORDER_TAKING } from "@config/services";
import axios from "../../../config/axios/axiosInstance";
import {
  GROW_COMBOS_SERVICE_V3,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../../config/featureToggles";
import { getUserInfos } from "../../../config/utils/functions";
import CombosConverter from "../../../converters/promotions/promotionsV3/CombosResponseToCombosInfoConverter";
import { ConvertedComboInterface } from "../../../converters/promotions/promotionsV3/domains/CombosResponseToCombosInfoConverterDomain";
import { getApiHost as getApiHostB2b } from "../../host/HostB2BService";
import { getApiHost } from "../../host/HostService";
import { getStoreIdHost } from "../../host/HostStoreId";
import { CombosResponse } from "../domains/CombosResponse";
import { ComboInterface, CombosResponseV2 } from "../domains/CombosResponseV2";

axiosRetry(axios, {
  retries: 3,
  /* istanbul ignore next */
  retryDelay: () => 1500,
});

function getUrl(
  accountId: string,
  country: string,
  vendorName: string,
  legacyAccountId?: string,
  vendorAccountId?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { url: string; params?: any; headers?: any } {
  const isMulticontractCombosServiceEnable = isFeatureEnabled(
    GROW_COMBOS_SERVICE_V3,
  );
  const isOrganizationConfigEnabled = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );
  const { vendorId } = getUserInfos();
  const storeId = getStoreIdHost(country, vendorName);
  const params = { accountId: legacyAccountId || vendorAccountId || accountId };

  if (isOrganizationConfigEnabled) {
    const url = `${getApiHost(
      GROW_BFF_SERVICE,
    )}/v1/accounts/${accountId}/combos`;

    return {
      url,
      headers: { vendorId },
    };
  }

  const baseUrl = `${getApiHostB2b()}/api/v1/promo-fusion-service`;
  const version = isMulticontractCombosServiceEnable ? "v3" : "v2";

  return {
    url: `${baseUrl}/${version}/combos`,
    params: isMulticontractCombosServiceEnable
      ? { accountId, storeId }
      : params,
  };
}

export function getCombos(
  accountId: string,
  country: string,
  vendorName: string,
  vendorAccountId?: string,
  legacyAccountId?: string,
): Promise<ConvertedComboInterface[]> {
  const log = useLogService();
  const { url, headers, params } = getUrl(
    accountId,
    country,
    vendorName,
    legacyAccountId,
    vendorAccountId,
  );

  return axios
    .get<CombosResponse>(url, {
      params,
      headers,
    })
    .then((response) => {
      log.info(`Convert Combos Response received from ${url} to our model`);
      return CombosConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}

export async function getCombosV2(
  accountId: string,
  country: string,
  vendorName: string,
  vendorAccountId?: string,
  legacyAccountId?: string,
  page = 0,
  pageSize = 50,
): Promise<ComboInterface[]> {
  const log = useLogService();
  const { headers, params } = getUrl(
    accountId,
    country,
    vendorName,
    legacyAccountId,
    vendorAccountId,
  );

  const url = `${getApiHost(
    GROW_ORDER_TAKING,
  )}/v1/accounts/${accountId}/combos?page=${page}&pageSize=${pageSize}`;

  return axios
    .get<CombosResponseV2>(url, {
      params,
      headers,
    })
    .then((response) => {
      return response.data.combos;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
