import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderBottom: `1px solid ${palette.grey.A100}`,
      marginBottom: "16px",
      justifyContent: "space-between",
      paddingBottom: 6,
    },
    title: {
      marginLeft: 4,
      fontWeight: 600,
      fontSize: "19px",
      letterSpacing: 0,
      color: palette.secondary.main,
      textTransform: "none",
      opacity: 1,
    },
    select: {
      minWidth: 117,
      borderRadius: 10,
    },
    titleInnerContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  }),
);
