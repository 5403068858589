import { getGlobalAndLocalMissionsService } from "../../services/missions/getGlobalAndLocalMissionsService";
import {
  setLocalMissionsIds,
  setSponsoredMissionsIds,
} from "../../stores/sponsored/SponsoredEvents";
import { getGlobalAndLocalMissionsBySearchByPocService } from "../../services/missions/getGlobalAndLocalMissionsBySearchByPocService";
import { PocCallOrigin, LocalGlobalFilterEnum } from "../../config/constants";
import {
  getEffectiveMissions,
  setEffectiveMissionsEvent,
} from "../../stores/globalAndLocalMissions/EffectivenessMissionEvents";

export const execute = (
  tags: string[],
  localMissionIds: string[],
  vendorAccountId: string,
  pocCallOrigin?: string,
  vendorId?: string,
  vendorName?: string,
  LocalGlobalMissionsType?: LocalGlobalFilterEnum,
): Promise<{ missions: string[]; missionsTabs: string[] }> => {
  const tagString = tags.toString();
  const localMissionsIdsString = localMissionIds.toString();
  if (
    pocCallOrigin === PocCallOrigin.SEARCH_BY_POC ||
    pocCallOrigin === PocCallOrigin.AUTO_DIALER
  ) {
    return getGlobalAndLocalMissionsBySearchByPocService(
      vendorAccountId,
      vendorId,
      vendorName,
      LocalGlobalMissionsType,
    )
      .then((response) => {
        setSponsoredMissionsIds(response?.sponsoredMissionsIds || []);
        setLocalMissionsIds(response?.localMissionIds || []);

        setEffectiveMissionsEvent(
          getEffectiveMissions({
            sponsoredMissions: [],
            localMissions: [],
            globalCallListMissions: response?.missions,
          }),
        );

        return {
          missions: response?.missions || [],
          missionsTabs: response?.missionsTabs || [],
        };
      })
      .catch((error) => {
        throw error;
      });
  }
  return getGlobalAndLocalMissionsService(
    tagString,
    localMissionsIdsString,
    vendorId,
    vendorName,
  )
    .then((response) => {
      setSponsoredMissionsIds(response?.sponsoredMissionsIds || []);
      setLocalMissionsIds(response?.localMissionIds || []);

      setEffectiveMissionsEvent(
        getEffectiveMissions({
          sponsoredMissions: [],
          localMissions: [],
          globalCallListMissions: response?.missions,
        }),
      );

      return {
        missions: response?.missions || [],
        missionsTabs: response?.missionsTabs || [],
      };
    })
    .catch((error) => {
      throw error;
    });
};
