import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { LinearProgress as MaterialLinearProgress } from "@material-ui/core";

const LinearProgressBar = withStyles(({ palette }) => ({
  root: {
    height: "8px",
    borderRadius: "5px",
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "#EAEAEA",
  },
  bar: {
    borderRadius: 10,
    backgroundColor: palette.primary.main,
  },
}))(MaterialLinearProgress);

const ProgressBarStyles = makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      flex: 1,
    },
    arrowDown: {
      width: "0",
      height: "0",
      borderLeft: "7px solid transparent",
      borderRight: "7px solid transparent",
      borderTop: `12px solid ${palette.error.main}`,
      alignSelf: "flex-end",
    },
    barTextContainer: {
      display: "flex",
      alignSelf: "flex-end",
      flexDirection: "column",
      height: "36px",
    },
    barTopTextValue: {
      fontSize: "12px",
      color: palette.error.main,
      textTransform: "lowercase",
      fontWeight: 400,
    },

    barValueContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    percentage: {
      fontSize: "14px",
      marginRight: "5px",
    },
    barBottomTextValue: {
      fontSize: "14px",
      color: palette.error.main,
      textTransform: "lowercase",
      fontWeight: 500,
    },
  }),
);

export { LinearProgressBar, ProgressBarStyles };
