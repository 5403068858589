import React from "react";
import {
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import CTIModalGenericStyles from "./CTIModalGenericStyles";
import Button from "../../../../components/button/Button";

export interface Props {
  titleModal: string;
  messageFailConect: string;
  messageWhithoutCti: string;
  attemptsLabel: string;
  mainButton: string;
  secondaryButton: string;
  open: boolean;
  onClose: () => void;
  connectWebSocket?: () => void;
  retryCount: number;
  loading: boolean;
  retryDisabled: boolean;
}
const CTIModalGeneric: React.FC<Props> = ({
  titleModal,
  messageFailConect,
  messageWhithoutCti,
  attemptsLabel,
  mainButton,
  secondaryButton,
  onClose,
  open,
  connectWebSocket,
  retryCount,
  loading,
  retryDisabled,
  ...props
}) => {
  const classes = CTIModalGenericStyles();
  return (
    <Dialog
      {...props}
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialog }}
      disableBackdropClick
    >
      <DialogTitle className={classes.dialogTitle}>{titleModal}</DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.dialogContentText}>
          <span className={classes.errorMessage}>{messageFailConect}</span>
          <span className={classes.continueMessage}>{messageWhithoutCti}</span>
          <span className={classes.modalSubTitle}>{attemptsLabel}</span>
          <span className={classes.modalSubTitleError}>{retryCount}</span>
        </DialogContentText>

        <DialogActions className={classes.dialogActions}>
          <ButtonBase
            onClick={onClose}
            className={`${classes.buttonCTI} ${classes.cancelButton}`}
            data-testid="confirm-whithout-cti-button"
          >
            {secondaryButton}
          </ButtonBase>
          <Button
            loading={loading}
            data-testid="retry-cti-modal-button"
            onClick={connectWebSocket}
            disabled={retryDisabled}
            className={`${classes.buttonCTI} ${classes.confirmButton} ${
              retryDisabled ? classes.disabledButton : ""
            }`}
          >
            {mainButton}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default CTIModalGeneric;
