import { createStore } from "effector";
import { getRewardsEffect, getRewardsEffectV2 } from "./LoyaltyProgramEvents";
import LoyaltyProgramState from "./LoyaltyProgramState";

const initialState = <LoyaltyProgramState>{
  error: false,
  done: false,
  loading: false,
  balance: 0,
  dateRegistered: "",
  tier: "",
};

const updateLoadingState = (state: LoyaltyProgramState) => ({
  ...state,
  error: false,
  loading: true,
  done: false,
});

const updateCompletedState = (state: LoyaltyProgramState, { result }) => ({
  ...result,
  error: false,
  loading: false,
  done: true,
});

const updateErrorState = (state: LoyaltyProgramState) => ({
  ...state,
  balance: undefined,
  tier: "",
  error: true,
  loading: false,
  done: true,
});

const loyaltyProgramStore = createStore(initialState)
  .on(getRewardsEffect, updateLoadingState)
  .on(getRewardsEffect.done, updateCompletedState)
  .on(getRewardsEffect.fail, updateErrorState)

  .on(getRewardsEffectV2, updateLoadingState)
  .on(getRewardsEffectV2.done, updateCompletedState)
  .on(getRewardsEffectV2.fail, updateErrorState);

export default loyaltyProgramStore;
