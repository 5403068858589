import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
      height: "72px",
      padding: "24px 0px",
    },
    title: {
      fontWeight: 600,
      fontSize: "20px",
      marginLeft: "5px",
      color: palette.primary.main,
    },
    description: {
      fontSize: "14px",
      lineHeight: "20px",

      "& + p": {
        marginTop: "5px",
      },
    },
    card: {
      backgroundColor: "#ffffff",
      height: "auto",
      alignItems: "center",
      padding: "20px 20px",
      borderRadius: "15px 15px 15px 15px",
      boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.16)",
    },
    cardContent: {
      overflow: "auto",
      maxHeight: "148px",
    },
    containerError: {
      border: "1px solid #e1e1e1",
      borderRadius: 8,
      padding: 20,
      background: "#fff",
    },
  }),
);
