import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useStore } from "effector-react";
import OfferingStore from "../../../../stores/navigation/offering/OfferingStore";
import NewOfferingsStore from "../../../../stores/newOfferings/NewOfferingsStore";
import { OFFERINGS_SECTION } from "../../../../config/constants";
import { useOfferings } from "../../../../hooks/useOfferings/useOfferings";
import OfferingsTitle from "../offeringsTitle/OferringsTitle";
import Promotions from "../promotions/Promotions";
import NewOfferings from "../newOfferings/NewOfferings";
import LoadingBox from "./loadingBox/LoadingBox";

const Offerings = (): JSX.Element => {
  const { section, options } = useStore(OfferingStore);
  const {
    done: newOfferingsDone,
    error: newOfferingsError,
    hasBeenAddedToDb,
  } = useStore(NewOfferingsStore);

  const { showDealsTab, showRecommendedTab, loadOfferings, fetchAllOfferings } =
    useOfferings();

  useEffect(() => {
    loadOfferings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newOfferingsDone && !newOfferingsError && hasBeenAddedToDb) {
      fetchAllOfferings();
    }
  }, [
    newOfferingsDone,
    newOfferingsError,
    fetchAllOfferings,
    hasBeenAddedToDb,
  ]);

  const renderSection = () => {
    if (options.length === 0) {
      return <LoadingBox />;
    }

    return section === OFFERINGS_SECTION.NEW_OFFERINGS ? (
      <NewOfferings
        showRecommendedTab={showRecommendedTab}
        loadOfferings={loadOfferings}
        fetchAllOfferings={fetchAllOfferings}
      />
    ) : (
      <Promotions showDealsTab={showDealsTab} />
    );
  };

  return (
    <Box>
      <OfferingsTitle />
      {renderSection()}
    </Box>
  );
};

export default React.memo(Offerings);
