import { NextPOCResponse } from "../../../services/nextPoc/domains/NextPOCResponse";
import AgentCall from "../../../domains/AgentCall";
import { SQ_FORECAST } from "../../../config/constants";
import { getTabs } from "../../../config/utils/functions";
import GlobalStore from "../../../stores/global/GlobalStore";
import {
  isFeatureEnabledV2,
  TLP_SPONSORED_MISSION_TAB,
  TLP_SERVICE_MISSION,
  GROW_MISSION_REWARDS_REDEMPTION,
} from "../../../config/featureToggles";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function NextPOCResponseToAgentCallConverter(
  data: NextPOCResponse,
): AgentCall {
  const { user } = GlobalStore.getState();

  const isSponsoredMissionsTabsEnabled = isFeatureEnabledV2(
    TLP_SPONSORED_MISSION_TAB,
    user.zone,
  );

  const isServiceMissionsTabsEnabled = isFeatureEnabledV2(
    TLP_SERVICE_MISSION,
    user.zone,
  );

  const isWipRewardsRedemptionEnabled = isFeatureEnabledV2(
    GROW_MISSION_REWARDS_REDEMPTION,
    user.zone,
  );

  const round = (value: number, places = 2): number =>
    Number(value.toFixed(places));

  const {
    agentId,
    clientId,
    callId,
    orderSku,
    startServiceDate,
    tags,
    countryCode,
    isTypedPoc,
    pocCallOrigin,
    vendorId,
    vendorName,
    vendorAccountId,
    customerAccountId,
    legacyAccountId,
    sponsoredIds,
    localMissionIds,
  } = data;

  const { date, callTypification, gapReason, gapDescription } = {
    date: "",
    callTypification: "",
    gapReason: "",
    gapDescription: "",
  };

  const gap = {
    topline: {
      total: data.gapNetRevenue != null ? round(data.gapNetRevenue) : undefined,
      achieved:
        data.orderNetRevenue != null ? round(data.orderNetRevenue) : undefined,
      target:
        data.netRevenueForecast != null
          ? round(data.netRevenueForecast)
          : undefined,
    },
    volume: {
      total: data.gapHL != null ? round(data.gapHL) : undefined,
      achieved: data.achievedHL != null ? round(data.achievedHL) : undefined,
      target: data.hlForecast != null ? round(data.hlForecast) : undefined,
    },
  };

  let tagsList: string[] = [];
  let tabs: string[] = [];

  if (tags) {
    tagsList = tags.filter((item) => item !== SQ_FORECAST);
    tabs = getTabs(tagsList, {
      sponsored: isSponsoredMissionsTabsEnabled,
      service: isServiceMissionsTabsEnabled,
      wipRewardsRedemption: isWipRewardsRedemptionEnabled,
    });
  }

  return {
    agentId,
    callId,
    clientId,
    countryCode,
    date,
    callTypification,
    gapReason,
    gapDescription,
    gap,
    orderSku,
    startServiceDate,
    tagsList,
    hasTechnicalProblem: false,
    isTypedPoc,
    pocCallOrigin,
    tabs,
    vendorId,
    vendorName,
    vendorAccountId,
    customerAccountId,
    legacyAccountId,
    sponsoredMissionsIds: sponsoredIds,
    localMissionIds: localMissionIds ?? [],
  };
}
