import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import cardInfoStyles from "./CardInfoStyles";

interface Props {
  title: string;
  mainLabel: string;
  secondaryLabel: string;
  mainValue?: number | string | null;
  secondaryValue?: number | string | null;
  testId: string;
  actionButton: ReactNode;
  time?: boolean;
  targetColor?: string;
}

function CardInfo({
  title,
  mainLabel,
  secondaryLabel,
  mainValue,
  secondaryValue,
  testId,
  actionButton,
  time,
  targetColor,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const numericMainValue = Number(mainValue);
  const numericSecondaryValue = Number(secondaryValue || 0);

  const classes = cardInfoStyles({ targetColor });
  let percentage: number | null = null;

  if (secondaryValue != null && secondaryValue > 0) {
    percentage = mainValue
      ? Math.round((numericMainValue / numericSecondaryValue) * 100)
      : 0;
  }

  const pad = (num: number, size: number): string => {
    let s = `${num}`;
    while (s.length < size) s = `0${s}`;
    return s;
  };

  const getTotalCalls = () => {
    if (secondaryValue == null) {
      return t("GlobalMessage.N_A");
    }

    return time ? secondaryValue : pad(numericSecondaryValue, 2);
  };

  const renderTime = () =>
    mainValue == null ? (
      t("GlobalMessage.N_A")
    ) : (
      <>
        {mainValue}
        <span className={classes.minute}>{t("AverageHandleTime.TIME")}</span>
      </>
    );

  const renderPercentage = () =>
    mainValue == null ? t("GlobalMessage.N_A") : `${pad(percentage || 0, 2)}%`;

  const renderNumericMainValue = () =>
    mainValue == null ? t("GlobalMessage.N_A") : pad(numericMainValue, 2);

  return (
    <Card className={classes.containerBox} data-testid={testId} elevation={0}>
      <CardHeader
        title={title}
        classes={{ title: classes.overwriteTitle }}
        className={classes.title}
        data-testid={`${testId}-header`}
        action={actionButton}
      />

      <CardContent classes={{ root: classes.cardContent }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item xs={9}>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="flex-end"
            >
              {time ? (
                <Typography
                  className={classes.mainValueTime}
                  data-testid={`${testId}-mainValue`}
                >
                  {renderTime()}
                </Typography>
              ) : (
                <>
                  <Typography
                    className={classes.percentage}
                    data-testid={`${testId}-percentage`}
                  >
                    {renderPercentage()}
                  </Typography>
                  <Divider
                    orientation="vertical"
                    classes={{ vertical: classes.divider }}
                    data-testid={`${testId}-divider`}
                  />
                  <Typography
                    className={classes.mainValue}
                    data-testid={`${testId}-mainValue`}
                  >
                    {renderNumericMainValue()}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Typography
              align="right"
              className={classes.primaryValue}
              data-testid={`${testId}-secondaryValue`}
            >
              {getTotalCalls()}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-around" spacing={0}>
          <Grid item xs={7}>
            <Typography
              classes={{ root: classes.labelOverwrite }}
              className={classes.mainLabel}
              data-testid={`${testId}-mainValue-text`}
              variant="subtitle1"
            >
              {mainLabel}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography
              align="right"
              classes={{ root: classes.labelOverwrite }}
              className={classes.mainLabel}
              data-testid={`${testId}-secondaryValue-text`}
              variant="subtitle1"
            >
              {secondaryLabel}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

CardInfo.defaultProps = {
  mainValue: undefined,
  secondaryValue: undefined,
  time: false,
  targetColor: "",
};

export default CardInfo;
