import { isFeatureEnabled } from "grow-shared-services";
import { GROW_ORGANIZATION_ACTIVATION } from "@config/featureToggles";
import { SearchBFFCatalog } from "../../services/searchCatalog/bff/SearchBFFCatalogService";
import { SearchDomain } from "../../services/searchCatalog/domains/Search";
import { SearchCatalog } from "../../services/searchCatalog/v2/SearchApiCatalogService";

export function executeSearchItems(
  accountId: string,
  valueTyped: string,
  vendorId?: string,
  page?: number,
  pageSize?: number,
): Promise<SearchDomain> {
  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  if (isOrganizationEnabled) {
    return SearchBFFCatalog({
      accountId,
      vendorId,
      page,
      pageSize,
      valueTyped,
    });
  }

  return SearchCatalog({ accountId, vendorId, page, pageSize, valueTyped });
}
