import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: "#ffffff",
      height: "auto",
      display: "flex",
      alignItems: "center",
      marginBottom: 25,
      borderRadius: "0px 15px 15px 15px",
      boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.16)",
    },
    title: {
      borderBottom: "1px solid #f2f2f2",
      width: "95%",
      fontSize: "18px",
      fontWeight: 600,
      color: "#343434",
      paddingBottom: "3px",
      marginTop: "17px",
      fontFamily: "Barlow",
    },
    iconContainer: {
      marginLeft: "21px",
      marginRight: "21px",
    },
    descriptionContainer: {
      marginBottom: "10px",
      marginTop: "8px",
    },
    description: {
      width: "95%",
      paddingBottom: "11px",
    },
    missionsContainer: {
      padding: "0px",
    },
  }),
);
