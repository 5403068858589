import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    box: {
      position: "relative",
    },
    container: {
      backgroundColor: palette.common.black,
      height: "100%",
      boxShadow: "2px 2px 4px 0 rgba(0, 0, 0, 0.16)",
      width: 254,
      borderRadius: 0,
      flexDirection: "column",
      paddingBottom: "3.5rem",
    },
    containerTabs: {
      backgroundColor: "rgba(33, 33, 33, 0.7)",
      width: "250px",
      margin: "9px 0 18px",
      padding: "9px 57px 9px 17px",
      display: "flex",
    },
    id: {
      fontSize: "12px",
      fontWeight: 500,
      color: palette.common.white,
      marginBottom: "16px",
      paddingLeft: "27px",
    },
    image: {
      borderRadius: "3px",
      marginRight: "16px",
      marginTop: "-10%",
      marginBottom: "10%",
      "& img": {
        objectFit: "contain",
      },
    },
    loading: {
      margin: "6rem",
    },
    tabButton: {
      display: "block",
      color: "#9A9A9A",
      fontSize: "12px",
      paddingBottom: "0px",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      textTransform: "none",
      padding: "0 6px 6px",
      textAlign: "inherit",
      borderBottom: "2px solid transparent",
      lineHeight: "20px",
    },
    buttonItem: {
      flex: 1,
    },
    reloadButton: {
      alignSelf: "center",
      padding: "2px",
    },
    content: {
      display: "inline-block",
      color: "white",
      whiteSpace: "normal",
      textOverflow: "ellipsis",
    },
    active: {
      borderBottom: `2px solid ${palette.primary.light}`,
      color: "white",
      lineHeight: "20px",
    },
    errorText: {
      color: "#FFFF",
      fontSize: "12px",
      textAlign: "left",
      padding: "8px",
    },
    containerOverflow: {
      overflow: "auto",
      overflowX: "hidden",
    },
  }),
);
