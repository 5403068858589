import React from "react";
import { useTranslation } from "react-i18next";
import CardUplift from "../../../components/cardUplift/CardUplift";
import UpliftIcon from "../../../assets/UpliftIcon";

interface Props {
  target?: number;
  countKPI?: number | null;
  gapUplift?: number;
  totalGapUplift?: number;
}

ToplineUplift.defaultProps = {
  countKPI: "0",
  gapUplift: "0",
  totalGapUplift: "0",
};

function ToplineUplift({
  target,
  countKPI,
  gapUplift,
  totalGapUplift,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  return (
    <CardUplift
      key="card-info"
      title={t("ToplineUplift.TITLE_CD")}
      titleIcon={<UpliftIcon />}
      mainLabel={t("ToplineUplift.COMPLETED_TEXT")}
      secondaryLabel={t("ToplineUplift.TARGET_TEXT")}
      totalGapLabel={t("ToplineUplift.TOPLINE_TOTAL_GAP")}
      mainValue={countKPI}
      secondaryValue={target}
      moneyValue={gapUplift}
      moneyValueSecondary={totalGapUplift}
      testId="card-topline-uplift"
    />
  );
}

export default ToplineUplift;
