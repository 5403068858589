import { createEffect, createEvent } from "effector";
import { Combo } from "../../domains/promotions/Combo";
import { Promotions } from "../../domains/promotions/Promotions";
import { execute } from "../../usecase/promotions/PromotionsUseCase";

interface PromotionsV3Request {
  accountId: string;
  country: string;
  vendorName: string;
  vendorAccountId?: string;
  legacyAccountId?: string;
}

export const onSearch = createEvent<{
  searchText: string;
  combos: Array<Combo>;
  promotions: Array<Promotions>;
}>("onSearch");

export const resetPromotions = createEvent<null>("resetPromotions");

export const getPromotionsV3Effect = createEffect({
  async handler(promotionsV3Request: PromotionsV3Request) {
    return execute(
      promotionsV3Request.accountId,
      promotionsV3Request.country,
      promotionsV3Request.vendorName,
      promotionsV3Request.vendorAccountId,
      promotionsV3Request.legacyAccountId,
    );
  },
});
