import * as React from "react";

function SearchResultIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M6.658 17.046a.547.547 0 01.386.934l-2.668 2.668a1.094 1.094 0 01-1.547 0L.161 17.98a.547.547 0 01.387-.934zm11.389-.093a1.094 1.094 0 010 2.188h-7.11a1.094 1.094 0 010-2.188h7.11zm3.828-7.656a1.094 1.094 0 010 2.187H10.937a1.094 1.094 0 010-2.187h10.938zm3.281-7.563a1.094 1.094 0 110 2.188H10.938a1.094 1.094 0 110-2.188zM3.555 0c.604 0 1.093.49 1.093 1.094V17.5H2.461V1.094A1.093 1.093 0 013.555 0z"
          id="SearchResultIcon__a"
        />
      </defs>
      <g transform="translate(4.375 7.11)" fill="none" fillRule="evenodd">
        <mask id="SearchResultIcon__b" fill="#fff">
          <use xlinkHref="#SearchResultIcon__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#SearchResultIcon__a" />
        <g mask="url(#SearchResultIcon__b)" fill="#000">
          <path d="M-4.375-7.11h35v35h-35z" />
        </g>
      </g>
    </svg>
  );
}

export default SearchResultIcon;
