import React from "react";

import { IconContainer, TagContainer, Text } from "./LocalMissionsTag.style";

interface Props {
  title: string | React.ReactNode;
  icon: React.ReactNode;
  backgroundIconColor: string;
  backgroundColor?: string;
}

const LocalMissionTag: React.FC<Props> = ({
  title,
  icon,
  backgroundIconColor,
  backgroundColor,
}) => {
  return (
    <TagContainer
      data-testid={`${title}-tag`}
      backgroundColor={backgroundColor}
    >
      <IconContainer backgroundColor={backgroundIconColor}>
        {icon}
      </IconContainer>
      <Text>{title}</Text>
    </TagContainer>
  );
};

export default LocalMissionTag;
