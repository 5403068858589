import { useStore } from "effector-react";
import React from "react";
import { useTranslation } from "react-i18next";
import VendorStore from "../../../../stores/vendor/VendorStore";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import { getGlobalAndLocalMissionsEvent } from "../../../../stores/tags/TagsEvents";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/v2/ErrorHandlerRetry";
import CampaignsStore from "../../../../stores/campaigns/CampaignsStore";
import TagsStore from "../../../../stores/tags/TagsStore";
import MissionsListStyles from "./MissionsListStyles";
import MissionCardComponent from "../missionCard/MissionCardComponent";
import useMissionsList from "./useMissionsList";
import { MissionsListSkeleton } from "./MissionsListSkeleton";

const MissionsListComponent: React.FC = () => {
  const { t } = useTranslation();
  const classes = MissionsListStyles();
  const { list, isLoading, hasError } = useStore(TagsStore);
  const { validCampaigns } = useStore(CampaignsStore);
  const missions = useMissionsList(list, validCampaigns);
  const { tagsList, localMissionIds, clientId, pocCallOrigin } =
    useStore(AgentCallStore);
  const { vendor } = useStore(VendorStore);

  if (
    list.some((tagActivation) => tagActivation === "#marketplaceactivation") &&
    list.some((tagMarketplace) => tagMarketplace === "#PromoteMarketPlace")
  ) {
    const index = list.findIndex((each) => each === "#PromoteMarketPlace");
    list.splice(index, 1);
  }

  if (!isLoading && hasError)
    return (
      <>
        <div className={classes.container}>
          <div className={classes.title} data-testid="missions-list-title">
            {t("MISSIONS.MISSIONS_TITLE")}
          </div>
          <div className={classes.title} data-testid="hasError">
            <ErrorHandlerRetry
              className={classes.containerSkeleton}
              description={t(
                "LocalMission.LOCAL_MISSIONS_REQUEST_FAILURE_MESSAGE",
              )}
              onClick={() =>
                getGlobalAndLocalMissionsEvent({
                  tagsList,
                  localMissionIds,
                  clientId,
                  pocCallOrigin,
                  vendorId: vendor?.id || undefined,
                  vendorName: vendor?.displayName || undefined,
                  accountId: clientId,
                })
              }
            />
          </div>
        </div>
      </>
    );

  if (isLoading)
    return (
      <>
        <div className={classes.container}>
          <div className={classes.title} data-testid="missions-list-title">
            {t("MISSIONS.MISSIONS_TITLE")}
          </div>
          <div className={classes.title} data-testid="loading">
            <MissionsListSkeleton />
          </div>
        </div>
      </>
    );

  return (
    <>
      <div className={classes.container}>
        {missions && missions.length > 0 ? (
          <>
            <div className={classes.title} data-testid="missions-list-title">
              {t("MISSIONS.MISSIONS_TITLE")}
            </div>
            {missions.map((filteredMission) => {
              return (
                <MissionCardComponent
                  {...filteredMission}
                  key={`mission-card-${filteredMission.index}`}
                />
              );
            })}
          </>
        ) : (
          <div className={classes.title} data-testid="no-missions">{`${t(
            "MISSIONS.NO_MISSIONS_TITLE",
          )}`}</div>
        )}
      </div>
    </>
  );
};

export default MissionsListComponent;
