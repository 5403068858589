import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";
import { getUserInfos } from "@config/utils/functions";
import Catalog from "@domains/Catalog";

import {
  CatalogResponseToCatalogConverterV3,
  CatalogMultipleConverterV3,
} from "../../../converters/catalog/v3/CatalogResponseToCatalogConverterV3";
import { getApiHost, getEndPoint } from "../../host/HostB2BService";
import { getStoreIdHost } from "../../host/HostStoreId";
import CatalogResponseV3, { Item } from "./domains/CatalogResponseV3";

export const CATALOG_V3_ENDPOINT = "/catalog-service/v3/items";

export function getCatalogV3(
  accountId: string,
  platformIds: string,
  country: string,
  vendorName: string,
): Promise<Catalog> {
  const log = useLogService();
  const storeId = getStoreIdHost(country, vendorName);

  const endpoint = `${getApiHost()}${getEndPoint()}${CATALOG_V3_ENDPOINT}?storeId=${storeId}&accountId=${accountId}&projection=ITEM,PRICE,AVAILABILITY,INVENTORY&platformIds=${platformIds}`;
  return axios
    .get<CatalogResponseV3>(`${endpoint}`, {
      headers: {
        originSystem: "WEB",
        "Accept-Language": "",
      },
    })
    .then((response) => {
      log.info(`Convert Catalog score received from ${endpoint} to our model`);
      return CatalogResponseToCatalogConverterV3(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}

const PAGE_SIZE = 100;

export function getCatalogV3Multiple(
  accountId: string,
  vendorName: string,
  page: number,
): Promise<Catalog> {
  const log = useLogService();

  const { country } = getUserInfos();

  const storeId = getStoreIdHost(country, vendorName);

  const { vendorId } = getUserInfos();

  const dataItems: Array<Item> = [];

  return callCatalogAPI(accountId, page, storeId, vendorId, dataItems)
    .then(() => CatalogMultipleConverterV3(dataItems))
    .catch((error) => {
      log.error(error);
      throw error;
    });
}

export function callCatalogAPI(
  accountId: string,
  page: number,
  storeId: string,
  vendorId: string,
  dataItems: Array<Item>,
): Promise<Array<Item>> {
  const endpoint = `${getApiHost()}${getEndPoint()}${CATALOG_V3_ENDPOINT}`;

  return new Promise((resolve, reject) => {
    axios
      .get<CatalogResponseV3>(`${endpoint}`, {
        headers: {
          originSystem: "WEB",
          "Accept-Language": "",
        },
        params: {
          storeId,
          accountId,
          projection: "ITEM,PRICE,AVAILABILITY,INVENTORY",
          vendorIds: vendorId,
          page,
          pageSize: PAGE_SIZE,
        },
      })
      .then((response) => {
        const { data } = response;
        const { pagination } = data;

        /* istanbul ignore next */
        if (data.items) {
          dataItems.push(...data.items);
        }

        /* istanbul ignore next */
        if (pagination.hasNext) {
          callCatalogAPI(
            accountId,
            page + 1,
            storeId,
            vendorId,
            dataItems,
          ).then((nextPromise) => {
            resolve(nextPromise);
          });
        } else {
          resolve(dataItems);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
