import { createStyles, makeStyles } from "@material-ui/core";

export const PocInfoStyles = makeStyles(() =>
  createStyles({
    gridContainer: {
      flex: "1",
      display: "flex",
      marginRight: "-10px",
    },
    loading: {
      margin: "6rem",
    },
    box: {
      padding: "0 20px 24px 0",
      width: 254,
      position: "relative",
    },
    boxName: {
      margin: "0 0 24px",
    },
    container: {
      padding: "25px 24px",
    },
    name: {
      fontSize: "20px",
      fontWeight: 600,
      color: "#3d060a",
      textTransform: "none",
    },
    id: {
      fontSize: "12px",
      fontWeight: 500,
      color: "#7c7c7c",
      paddingTop: "8px",
    },
    borderContainer: {
      margin: "0 0 24px",
      borderRadius: "8px",
      border: `solid 1px #424242`,
      background: "#343434",
    },
    borderCredit: {
      borderRadius: "3px",
      borderTop: 0,
    },
    containerLoyaltyProgram: {
      marginLeft: "16px",
    },
  }),
);
