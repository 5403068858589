/* eslint-disable global-require */
import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@material-ui/core";
import { Building } from "@hexa-ui/icons";
import BoxPocInfoStyles from "./BoxPocInfoStyles";

interface Props {
  pocName: string | undefined;
  vendorName: string | undefined;
}

function BoxPocInfo({
  pocName,
  vendorName,
}: Readonly<Props>): React.ReactElement {
  const { t } = useTranslation();
  const classes = BoxPocInfoStyles();

  return (
    <Box className={classes.pocInfo}>
      <Typography
        component="h1"
        variant="h1"
        data-testid="poc-info-name"
        className={classes.pocName}
      >
        {pocName}
      </Typography>
      {vendorName && (
        <Box className={classes.vendorName} data-testid="vendor-name-container">
          <Building size="medium" />
          <span>
            {t("POC_INFORMATION_TAB.SELLER")}: {vendorName}
          </span>
        </Box>
      )}
    </Box>
  );
}
export default BoxPocInfo;
