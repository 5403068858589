import * as React from "react";

function SuggestedOrderIcon(props: any): any {
  return (
    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.695 4.5c1.441 0 2.61 1.12 2.61 2.502h1.304c2.117 0 3.842 1.613 3.912 3.627l.002.127v17.516c0 2.03-1.682 3.684-3.782 3.75l-.132.003H10.953c-2.118 0-3.842-1.613-3.912-3.627l-.002-.126V10.756c0-2.031 1.681-3.685 3.782-3.752l.132-.002h1.305c0-1.382 1.168-2.502 2.609-2.502zM12.258 9.505h-1.305c-.69 0-1.256.514-1.302 1.165l-.003.086v17.516c0 .662.537 1.204 1.216 1.248l.089.003h15.656c.69 0 1.256-.515 1.302-1.166l.003-.085V10.756c0-.663-.537-1.205-1.216-1.249l-.09-.002h-1.304c0 1.382-1.168 2.502-2.61 2.502h-7.827c-1.441 0-2.61-1.12-2.61-2.502zm11.017 13.45a1.173 1.173 0 010 2.345h-9.274a1.173 1.173 0 010-2.346h9.274zM20.79 13.92a1.14 1.14 0 01.237 1.573l-.06.077-3.475 4.096a1.26 1.26 0 01-1.596.255l-.085-.055-2.491-1.787a1.142 1.142 0 01-.247-1.641 1.258 1.258 0 011.63-.292l.082.055 1.55 1.112 2.735-3.223a1.26 1.26 0 011.72-.17zm2.486 3.169a1.173 1.173 0 110 2.345h-1.323a1.173 1.173 0 110-2.345h1.323zm-.58-10.088h-7.828v2.503h7.828V7.002z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SuggestedOrderIcon;
