import { useStore } from "effector-react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  TLP_TAB_PROMOTIONS,
  TLP_TAB_PROMOTIONS_ADMIN,
  isFeatureEnabled,
} from "../../config/featureToggles";
import {
  getNewOfferingsEffect,
  setHasBeenAddedToDb,
  setOfferings,
} from "../../stores/newOfferings/NewOfferingsEvents";
import {
  NEW_OFFERINGS_RECOMMENDATIONS,
  OFFERINGS_SECTION,
} from "../../config/constants";
import AgentCallStore from "../../stores/agentCall/AgentCallStore";
import { SelectOption } from "../../stores/navigation/offering/OfferingState";
import PromotionsStore from "../../stores/promotions/PromotionsStore";
import NewOfferingsStore from "../../stores/newOfferings/NewOfferingsStore";
import {
  setOfferingSection,
  setOptions,
} from "../../stores/navigation/offering/OfferingEvents";
import NewOfferingsQueryDb from "../../routes/call/newOfferingsDb/NewOfferingsQueryDb";
import NewOfferingsDb from "../../routes/call/newOfferingsDb/NewOfferingsDb";

const { USE_CASE, USE_CASE_TYPE } = NEW_OFFERINGS_RECOMMENDATIONS;

interface HookProps {
  showDealsTab: boolean;
  showRecommendedTab: boolean;
  loadOfferings: () => void;
  fetchAllOfferings: () => Promise<void>;
}

export const useOfferings = (): HookProps => {
  const { t } = useTranslation();

  const { clientId, countryCode } = useStore(AgentCallStore);
  const { done: newOfferingsDone, offerings } = useStore(NewOfferingsStore);
  const {
    done: promotionsDone,
    searchResults,
    promotions,
    combos,
  } = useStore(PromotionsStore);

  const newOfferingsDb = useRef(new NewOfferingsDb());

  const isPromotionsTabEnabled = isFeatureEnabled(
    TLP_TAB_PROMOTIONS,
    TLP_TAB_PROMOTIONS_ADMIN,
  );

  const showDealsTab = useMemo(() => {
    const noPromotionsResults = !!(
      searchResults.promotions.length === 0 && promotions.length === 0
    );

    const noCombosResults = !!(
      searchResults.combos.length === 0 && combos.length === 0
    );

    return (isPromotionsTabEnabled && !noPromotionsResults) || !noCombosResults;
  }, [searchResults, promotions, combos, isPromotionsTabEnabled]);

  const showRecommendedTab = offerings?.length !== 0;

  const fetchAllOfferings = useCallback(async () => {
    const query = new NewOfferingsQueryDb(newOfferingsDb.current);
    const allOfferings = await query.findAll();
    setOfferings(allOfferings);
  }, []);

  useEffect(() => {
    if (!promotionsDone || !newOfferingsDone) return;

    const showDealsTabOpt =
      showDealsTab || (!showDealsTab && !showRecommendedTab);
    const showRecommendedTabOpt =
      showRecommendedTab || (!showDealsTab && !showRecommendedTab);

    const optArr = [] as SelectOption[];
    if (showDealsTabOpt) {
      optArr.push({
        value: OFFERINGS_SECTION.PROMOTIONS,
        label: t("Promotions.CHOOSE_OPTION_DEALS"),
      });
    }
    if (showRecommendedTabOpt) {
      optArr.push({
        value: OFFERINGS_SECTION.NEW_OFFERINGS,
        label: t("Promotions.NEW_OFFERINGS"),
      });
    }

    setOptions(optArr);
    if (optArr.length) {
      setOfferingSection(optArr[0]?.value);
    }
  }, [promotionsDone, newOfferingsDone, showDealsTab, showRecommendedTab, t]);

  const loadOfferings = () => {
    const request = {
      useCase: USE_CASE.CROSS_SELL_UP_SELL,
      useCaseType: USE_CASE_TYPE.ACCOUNT,
      useCaseId: clientId,
      accountId: clientId,
      countryCode,
    };
    getNewOfferingsEffect(request);
  };

  /* istanbul ignore next */
  useEffect(() => {
    const unWatchNewOfferingsEffect = getNewOfferingsEffect.done.watch(
      async (response) => {
        await newOfferingsDb.current.newOfferings.bulkPut(response.result);
        setHasBeenAddedToDb(true);
      },
    );
    return () => {
      if (typeof unWatchNewOfferingsEffect === "function") {
        unWatchNewOfferingsEffect();
      }
    };
  }, []);

  return {
    showDealsTab,
    showRecommendedTab,
    loadOfferings,
    fetchAllOfferings,
  };
};
