import {
  WebsocketEventData,
  WebSocketStatus,
} from "../../../domains/cti/WebsocketEventData";
import { WS_EVENTS } from "../../../config/constants";
import { SoftphoneWS } from "../../../domains/cti/SoftphoneWS";

export function onMessageWS(
  ws: SoftphoneWS,
  isTyped: boolean,
  callBack: (data: WebsocketEventData, section?: string) => void,
  section?: string,
): void {
  ws.onmessage = (evt: MessageEvent) => {
    const message: WebsocketEventData = evt.data as WebsocketEventData;
    message.isTyped = isTyped;
    callBack(message, section);
  };
}

/* istanbul ignore next */
export function onOpenWS(
  ws: SoftphoneWS,
  callBack: (data: WebsocketEventData) => void,
): void {
  ws.onopen = () => {
    const message: WebsocketEventData = {
      status: WebSocketStatus.OPENED,
    };
    callBack(message);
  };
}

export function onErrorWS(
  ws: SoftphoneWS,
  callBack: (
    data: WebsocketEventData,
    eventFunction?: () => void,
    section?: string,
  ) => void,
  eventFunction?: () => void,
  section?: string,
): void {
  ws.onerror = () => {
    const message: WebsocketEventData = {
      status: WebSocketStatus.ERROR,
    };

    callBack(message, eventFunction, section);
  };
}

/* istanbul ignore next */
export function sendMessageWS(
  ws: SoftphoneWS,
  phoneNumber: string,
  section: string,
  wasTyped: boolean | null | undefined,
  callBack?: (
    screenSection: string,
    isTyped?: boolean | null | undefined,
    phoneNumber?: string,
  ) => void,
  isPing = false,
): void {
  if (isPing) {
    ws.send({ comando: WS_EVENTS.GET_STATUS });
  } else {
    const call = {
      comando: WS_EVENTS.MAKE_CALL,
      numero: phoneNumber,
      gid: "615|254387|1",
    };

    ws.send(call);

    if (callBack) {
      callBack(section, wasTyped, phoneNumber);
    }
  }
}
