import { createEvent } from "effector";
import { WebsocketEventData } from "../../domains/cti/WebsocketEventData";

interface Phones {
  phone: string;
  rank: string;
}

export const setCtiData = createEvent<Array<Phones>>("setCtiData");
export const setCalledNumber = createEvent<string>("setCalledNumber");
export const setAttemptRetry = createEvent("setAttemptRetry");
export const resetAttemptRetry = createEvent("resetAttemptRetry");
export const resetCti = createEvent<null>("resetCti");
export const setWasTyped = createEvent<boolean>("setWasTyped");
export const setShouldContinueWithoutCTI = createEvent<boolean>(
  "setShouldContinueWithoutCTI",
);
export const setIsCTIFailedOpen = createEvent<boolean>("setIsCTIFailedOpen");

export const setIsConnected = createEvent<boolean>("setIsConnected");

export const setCtiEvent = createEvent<WebsocketEventData>("setCtiEvent");
