import { styled } from "@bees/vision-tokens";

export const ModalTitle = styled("div", {
  fontFamily: "Barlow",
  fontSize: 24,
  fontWeight: "bold",
  color: "#000000e6",
});

export const InformationSection = styled("section", {
  display: "flex",
  gap: 16,
  marginTop: 24,
});

export const InformationItem = styled("div", {
  width: 145,
  display: "flex",
  flexDirection: "column",
});

export const InformationTitle = styled("span", {
  fontWeight: "bold",
});

export const GenericTag = styled("span", {
  fontFamily: "Work Sans",
  padding: "2px 8px",
  fontSize: 14,
  borderRadius: 16,
  fontWeight: 500,
  maxWidth: "fit-content",
});

export const PointsTag = styled(GenericTag, {
  backgroundColor: "#0000000d",
});

export const StatusAndTypeLabel = styled("span", {
  letterSpacing: "0.1px",
});

export const ImageContainer = styled("section", {
  width: "100%",
  height: 312,
});

export const Image = styled("img", {
  objectFit: "fill",
  width: "100%",
  height: "100%",
  margin: "25px 0px",
  borderRadius: "8px",
});

export const ChallengeDescription = styled("span", {
  fontFamily: "Work Sans",
  fontSize: 16,
  letterSpacing: 0.1,
});

export const SkusSection = styled("div", {
  marginTop: "1.5rem",
});
