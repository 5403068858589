import { makeStyles, createStyles } from "@material-ui/core";

const HeaderStyles = makeStyles(() =>
  createStyles({
    headerContainer: {
      margin: "29px 0 16px 0",
      flexWrap: "nowrap",
    },
    search: {
      height: "40px",
      width: "245px",
      marginRight: "47px",
    },

    searchCallOptions: {
      height: "40px",
      width: "245px",
      marginRight: "23px",
      marginBottom: "8px",
      alignSelf: "flex-end",
    },
  }),
);
export default HeaderStyles;
