import * as React from "react";

function QuickOrder() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="-26%"
          x2="50%"
          y2="122.277%"
          id="prefix__c"
        >
          <stop stopColor="#FF0" offset="0%" />
          <stop stopColor="#EEEB00" offset="100%" />
        </linearGradient>
        <ellipse
          id="prefix__a"
          cx={24.671}
          cy={23.438}
          rx={24.671}
          ry={23.438}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1.645 1.563)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <g mask="url(#prefix__b)" fill="url(#prefix__c)">
            <path d="M0 0h49.342v46.875H0z" />
          </g>
        </g>
        <g transform="translate(5.5 9.62)" fillRule="nonzero">
          <path
            d="M31.526 0H10.879c-.657 0-1.191.507-1.191 1.132v37.493h29.779c.657 0 1.19-.507 1.19-1.132V8.676L31.527 0z"
            fill="#FFF5F5"
          />
          <path
            d="M25.173 0v38.625h14.294c.657 0 1.19-.507 1.19-1.132V8.676L31.527 0h-6.353z"
            fill="#E2DFF4"
          />
          <path
            d="M31.526 0v7.544c0 .625.533 1.132 1.19 1.132h7.942L31.526 0z"
            fill="#D5D3EA"
          />
          <path
            d="M23.982 6.79h-9.53c-.657 0-1.19-.507-1.19-1.132 0-.625.533-1.132 1.19-1.132h9.53c.657 0 1.191.507 1.191 1.132 0 .625-.534 1.132-1.191 1.132zm0 4.526h-9.53c-.657 0-1.19-.507-1.19-1.132 0-.624.533-1.131 1.19-1.131h9.53c.657 0 1.191.507 1.191 1.131 0 .625-.534 1.132-1.191 1.132z"
            fill="#707372"
          />
          <path
            d="M35.608 24.722c-.56-.971-1.496-1.7-2.707-2.107a48.731 48.731 0 01-.834-.287V17.82c.62.16.937.457.959.48a1.235 1.235 0 001.676.104c.496-.41.548-1.126.116-1.596-.451-.492-1.4-1.112-2.75-1.299v-.8c0-.624-.534-1.13-1.191-1.13-.658 0-1.191.506-1.191 1.13v.932c-.123.028-.248.06-.375.096a4.273 4.273 0 00-3.027 3.29c-.288 1.45.204 2.85 1.284 3.654.543.405 1.211.775 2.118 1.164v5.747c-.738-.07-1.244-.267-2.075-.783-.55-.342-1.289-.196-1.649.327-.36.523-.205 1.224.345 1.566 1.32.82 2.235 1.085 3.379 1.16v.953c0 .625.533 1.132 1.191 1.132.657 0 1.191-.507 1.191-1.132v-1.053a5.247 5.247 0 002.374-1.18 5.054 5.054 0 001.66-2.91 4.391 4.391 0 00-.494-2.951zm-6.57-3.82c-.372-.277-.534-.848-.413-1.454.1-.51.432-1.115 1.06-1.422v3.284a5.49 5.49 0 01-.647-.408zm4.714 6.396a2.837 2.837 0 01-1.685 2.092v-4.654l.04.013c1.836.617 1.724 2.107 1.645 2.549zM16.994 38.625H2.382C1.067 38.625 0 37.612 0 36.362V15.993c0-1.25 1.067-2.263 2.382-2.263h14.612c1.315 0 2.382 1.013 2.382 2.263v20.369c0 1.25-1.067 2.263-2.382 2.263z"
            fill="#FFB042"
          />
          <path
            d="M16.994 13.73H9.688v24.895h7.306c1.315 0 2.382-1.013 2.382-2.263V15.993c0-1.25-1.067-2.263-2.382-2.263z"
            fill="#FF9D15"
          />
          <ellipse
            fill="#E2DFF4"
            cx={14.453}
            cy={24.292}
            rx={1.191}
            ry={1.132}
          />
          <ellipse
            fill="#FFF5F5"
            cx={4.923}
            cy={24.292}
            rx={1.191}
            ry={1.132}
          />
          <ellipse
            fill="#E2DFF4"
            cx={14.453}
            cy={28.818}
            rx={1.191}
            ry={1.132}
          />
          <ellipse
            fill="#FFF5F5"
            cx={4.923}
            cy={28.818}
            rx={1.191}
            ry={1.132}
          />
          <ellipse
            fill="#E2DFF4"
            cx={14.453}
            cy={32.967}
            rx={1.191}
            ry={1.132}
          />
          <ellipse
            fill="#FFF5F5"
            cx={4.923}
            cy={32.967}
            rx={1.191}
            ry={1.132}
          />
          <path
            d="M2.541 20.52h14.294c.22 0 .397-.17.397-.378V16.37a.388.388 0 00-.397-.377H2.54a.388.388 0 00-.397.377v3.772c0 .209.178.378.397.378z"
            fill="#8CBCFF"
          />
          <path
            d="M16.835 15.993H9.688v4.527h7.147c.22 0 .397-.17.397-.378V16.37a.388.388 0 00-.397-.377z"
            fill="#4895FF"
          />
          <ellipse
            fill="#FFF5F5"
            cx={9.688}
            cy={24.292}
            rx={1.191}
            ry={1.132}
          />
          <path
            d="M9.688 23.16v2.263c.658 0 1.191-.507 1.191-1.131 0-.625-.533-1.132-1.191-1.132z"
            fill="#E2DFF4"
          />
          <ellipse
            fill="#FFF5F5"
            cx={9.688}
            cy={28.818}
            rx={1.191}
            ry={1.132}
          />
          <path
            d="M9.688 27.686v2.263c.658 0 1.191-.506 1.191-1.131s-.533-1.132-1.191-1.132z"
            fill="#E2DFF4"
          />
          <ellipse
            fill="#FFF5F5"
            cx={9.688}
            cy={32.967}
            rx={1.191}
            ry={1.132}
          />
          <path
            d="M9.688 31.835V34.1c.658 0 1.191-.507 1.191-1.132 0-.625-.533-1.132-1.191-1.132z"
            fill="#E2DFF4"
          />
        </g>
      </g>
    </svg>
  );
}

export default QuickOrder;
