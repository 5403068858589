import { CatalogItem } from "../../../../../domains/Catalog";

export const hasSkuOrName = (item: CatalogItem, text: string): boolean => {
  const textToLowerCase = text.toLowerCase();
  return (
    (item.sku.toLowerCase() === textToLowerCase ||
      item.name.toLowerCase().includes(textToLowerCase)) &&
    textToLowerCase !== ""
  );
};
