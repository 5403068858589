import { CartForm } from "../../../stores/cart/CartState";
import CartStateSimulationRequest from "../../../services/cart/cartStateSimulation/domains/CartStateSimulationRequest";

export default function StoresToSimulateCartRequestConverter(
  cartForm: CartForm,
): CartStateSimulationRequest {
  return {
    deliveryWindowId: cartForm?.deliveryWindowId || null,
    delivery: {
      method: "STANDARD",
      date:
        cartForm?.deliveryDate?.split("T")[0] ||
        new Date().toISOString().split("T")[0],
    },
    payment: {
      method: cartForm?.paymentMethod,
      vendorMethod: cartForm?.paymentMethodCode || null,
      term: cartForm?.paymentTerm,
    },
  };
}
