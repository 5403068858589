import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { CampaignB2OProducts } from "domains/CampaignB2OProducts";
import axios from "../../config/axios/axiosInstance";
import { CampaignsB2OProductsResponse } from "./domains/CampaignsResponse";
import { getApiHost } from "../host/HostService";
import {
  TLP_MULTICONTRACT_ITEM_SERVICE,
  isFeatureEnabledV2,
} from "../../config/featureToggles";
import { TLP_ITEM_SERVICE } from "../../config/services";
import CampaignsProductsResponseToCampaignsProductsConverter from "../../converters/campaigns/CampaignsProductsResponseToCampaignsProductsConverter";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getCampaignsProductsB2O(
  tag: string,
  country: string,
  accountId: string,
  vendorId: string,
  vendorName: string,
  headers = {},
): Promise<CampaignB2OProducts> {
  const log = useLogService();

  const isMulticontractCampaignServiceEnable = isFeatureEnabledV2(
    TLP_MULTICONTRACT_ITEM_SERVICE,
    country,
  );

  const CAMPAINGS_ITEM_ENDPOINT = isMulticontractCampaignServiceEnable
    ? "/tlp-item/v2"
    : "/tlp-item";

  const urlmarket = `${getApiHost(
    TLP_ITEM_SERVICE,
  )}${CAMPAINGS_ITEM_ENDPOINT}/${accountId}/campaign/items`;

  const body = [{ campaignTag: tag }];

  let params;

  if (isMulticontractCampaignServiceEnable) {
    params = {
      ...params,
      vendorId,
    };
  }

  const getHeaders = () => {
    const vendorData = !isMulticontractCampaignServiceEnable
      ? {
          vendorId,
          vendorName,
        }
      : {};

    return { ...vendorData, ...headers };
  };

  return axios
    .post<Array<CampaignsB2OProductsResponse>>(`${urlmarket}`, body, {
      params,
      headers: getHeaders(),
    })
    .then((response) => {
      log.info(
        `Convert CampaignsB2OProducts received from ${urlmarket} to our model`,
      );

      return CampaignsProductsResponseToCampaignsProductsConverter(
        response.data,
      );
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
