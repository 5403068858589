import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      marginTop: 14,
    },
    tabsContainer: { position: "relative", overflow: "auto" },
    tabsRoot: {
      display: "flex",
      overflow: "auto",
      minHeight: 48,
    },
    tabs: {
      width: "100%",
      position: "relative",
      minHeight: 0,
      paddingLeft: 5,
      margin: 0,
      "& .admin-portal-tlp-beta-mfe-MuiTabs-fixed": {
        overflowX: "auto !important",
        "&::-webkit-scrollbar": {
          height: "8px !important",
        },
      },
      "& .tlp-MuiTabs-indicator": {
        height: "0px",
      },
      "& .tlp-MuiTab-wrapper": {
        margin: "12px 0 12px",
      },
    },
    tabItem: {
      height: 44,
      minHeight: 0,
      minWidth: 0,
      padding: "8px 16px",
      borderRadius: "5px 5px 0px 0px",
    },

    tabItemText: {
      fontWeight: 600,
      color: "black",
      textTransform: "none",
      fontSize: "16px",
      fontFamily: "Barlow !important",
    },

    active: {
      borderBottom: "2px solid #000",
    },
    tabPanel: {
      flex: 1,
      padding: "0px 0px 20px 0px",
    },
    tabRewards: { marginBottom: "10px", fontSize: "12px", marginTop: "8px" },
    description: {
      fontSize: "12px",
      paddingLeft: "2px",
    },
    descriptionContainer: {
      display: "flex",
      paddingBottom: "11px",
    },

    descriptionTitle: {
      color: "#343434",
    },
    missionsBoldText: {
      fontWeight: "bold",
    },
    missionsDescriptionContainer: { marginBottom: "10px", marginTop: "8px" },
    missionsDescription: { width: "95%", paddingBottom: "11px" },
  }),
);
