import React, { useState } from "react";
import { ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import expandableDataStyles from "./ExpandableDataStyles";
import Tag from "../tag/Tag";

interface Props {
  items: Array<string>;
  testId: string;
  isTag?: boolean;
}

const ExpandableData: React.FC<Props> = ({ items, testId, isTag }) => {
  const { t } = useTranslation();
  const classes = expandableDataStyles();

  const [showMoreOpen, setShowMore] = useState(false);

  const showMoreButton = () => (
    <ButtonBase
      className={classes.button}
      onClick={() => {
        setShowMore(true);
      }}
      data-testid="more-button"
    >
      <ExpandMoreIcon classes={{ root: classes.icon }} />
      {t("GlobalMessage.MORE_LABEL")}
    </ButtonBase>
  );

  const showLessButton = () => (
    <ButtonBase
      className={classes.button}
      onClick={() => {
        setShowMore(false);
      }}
      data-testid="less-button"
    >
      <ExpandLessIcon classes={{ root: classes.icon }} />
      {t("GlobalMessage.LESS_LABEL")}
    </ButtonBase>
  );

  const showExpandable = () => {
    if (showMoreOpen) {
      return showLessButton();
    }
    return showMoreButton();
  };

  const defaultItems = items.slice(0, 3);
  const moreItems = items.slice(3);

  if (!items || items.length === 0) {
    return (
      <div className={classes.container} data-testid={testId}>
        {t("GlobalMessage.NO_DATA_AVAILABLE")}
      </div>
    );
  }

  return (
    <div className={classes.container} data-testid={testId}>
      {defaultItems.map((item) =>
        isTag ? (
          <Tag key={item} text={item} data-testid={`tag-${item}`} />
        ) : (
          <div className={classes.item} key={item} data-testid={`item-${item}`}>
            {item}
          </div>
        ),
      )}

      {showMoreOpen &&
        moreItems.map((item) =>
          isTag ? (
            <Tag key={item} text={item} data-testid={`tag-${item}`} />
          ) : (
            <div
              className={classes.item}
              key={item}
              data-testid={`item-${item}`}
            >
              {item}
            </div>
          ),
        )}

      {items.length > 3 && showExpandable()}
    </div>
  );
};

export default ExpandableData;
