import * as React from "react";

function OrderCartIcon(props: any): any {
  return (
    <svg
      width={32}
      height={32}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M25.24 11.069h-7.067a4.032 4.032 0 00-4.038 4.025 4.032 4.032 0 004.038 4.025h4.039v3.018a1.01 1.01 0 01-1.01 1.007H5.048a3.025 3.025 0 01-3.029-3.019V5.031a3.025 3.025 0 013.03-3.018h14.134c.557 0 1.01.45 1.01 1.006v1.006H5.047c-.558 0-1.01.45-1.01 1.006s.452 1.006 1.01 1.006h16.154a1.01 1.01 0 011.01 1.007V8.07c0 .556.452 1.006 1.01 1.006.557 0 1.009-.45 1.009-1.006V7.044a2.751 2.751 0 00-2.02-2.847V3.02A3.025 3.025 0 0019.184 0H5.048C2.26 0 .001 2.253 0 5.031v15.094c0 2.778 2.26 5.03 5.048 5.031h16.154a3.027 3.027 0 003.029-3.018v-3.02h1.01c.557 0 1.009-.45 1.009-1.005v-6.038c0-.556-.452-1.006-1.01-1.006zm-1.01 6.037h-6.057a2.016 2.016 0 01-2.02-2.012c0-1.112.905-2.013 2.02-2.013h6.058v4.025z"
          id="OrderCartIcon__a"
        />
      </defs>
      <g transform="translate(4.375 4.922)" fill="none" fillRule="evenodd">
        <mask id="OrderCartIcon__b" fill="#fff">
          <use xlinkHref="#OrderCartIcon__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#OrderCartIcon__a" />
        <g mask="url(#OrderCartIcon__b)" fill="#000">
          <path d="M-4.375-4.922h35v35h-35z" />
        </g>
      </g>
    </svg>
  );
}

export default OrderCartIcon;
