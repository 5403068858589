import { useLogService } from "admin-portal-shared-services";

import axios from "../../../config/axios/axiosInstance";
import { GROW_ADMIN_CONFIG_SERVICE } from "../../../config/services";
import CallDuration from "../../../stores/callDuration/CallDurationState";
import { getApiHost } from "../../host/HostService";

const MIN_DURATION_CALL_V3_URL = "/v3/min-duration-call";

export async function getCallDurationV3(): Promise<CallDuration> {
  const log = useLogService();

  const urlAgentCall = `${getApiHost(
    GROW_ADMIN_CONFIG_SERVICE,
  )}${MIN_DURATION_CALL_V3_URL}`;

  try {
    const response = await axios.get<CallDuration>(`${urlAgentCall}`);
    return response.data;
  } catch (error) {
    log.error("CallDurationServiceV3 - get error: ", error);
    throw error;
  }
}
