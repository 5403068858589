import {
  GROW_CART_VARIANTS_STOCK_CALCULATION,
  isFeatureEnabledV2,
} from "../../config/featureToggles";
import { getMessageType } from "../../config/utils/functions";
import { CartComboItem } from "../../domains/cart/CartComboItem";
import { CartInfo } from "../../domains/cart/CartInfo";
import { LineItems } from "../../domains/cart/LineItems";
import { MinimumOrder } from "../../domains/cart/MinimumOrder";
import CartStateSimulationResponse from "../../services/cart/cartStateSimulation/domains/CartStateSimulationResponse";
import { MessageResponse } from "../../services/cart/domains/MessageResponse";
import CartSimulationResponse, {
  Item as CartItemResponse,
} from "../../services/cartSimulation/domains/CartSimulationResponse";
import PocinformationStore from "../../stores/callList/pocInformation/PocInformationStore";
import GlobalStore from "../../stores/global/GlobalStore";
import PromotionsStore from "../../stores/promotions/PromotionsStore";
import { getEmpties } from "./fuctions";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function CartSimulationResponseToCartInfoConverter(
  data: CartSimulationResponse | CartStateSimulationResponse,
  accountId: string,
  paymentMethod: string,
  paymentTerm: number,
): CartInfo {
  if (!data) {
    return {
      accountId,
      lineItems: [],
      combos: [],
      vendorName: "",
    };
  }

  const { pocInformation } = PocinformationStore.getState();
  const { combos: combosStore } = PromotionsStore.getState();
  const { user } = GlobalStore.getState();

  const isVariantsStockCalculationEnabled = isFeatureEnabledV2(
    GROW_CART_VARIANTS_STOCK_CALCULATION,
    user.keyToggle,
  );

  const minimumOrder = <MinimumOrder>{
    minOrderPercent: data.minimumOrder?.percent,
    minOrderMessage: data.minimumOrder?.message,
  };

  const getInventory = (
    inventoryCount: number,
    itemCount: number | undefined,
  ): number => {
    if (!isVariantsStockCalculationEnabled) {
      return inventoryCount;
    }

    if (!itemCount || !inventoryCount || itemCount <= 0) {
      return inventoryCount;
    }

    return Math.floor(inventoryCount / itemCount);
  };

  const lineItems = data.items?.map(
    (orderItem: CartItemResponse) =>
      <LineItems>{
        price: 0,
        unitPrice: orderItem.subtotal,
        unitPriceInclTax: orderItem.total,
        quantity: orderItem.quantity,
        quantityChanged: false,
        discountAmount: orderItem.discountAmount,
        deposit: 0,
        subtotal: orderItem.subtotal,
        taxAmount: 0,
        total: orderItem.total,
        totalExclDeposit: orderItem.total,
        sku: orderItem.sku,
        pricingReason: [],
        pricingReasonDetail: [],
        hasInventory: false,
        freeGood: false,
        originalPrice: orderItem.subtotal,
        outputMessages: [],
        inventoryCount: getInventory(
          orderItem.inventoryCount,
          orderItem.package?.itemCount,
        ),
        availabilityCount: getInventory(
          orderItem.inventoryCount,
          orderItem.package?.itemCount,
        ),
        id: orderItem.platformId,
        platformId: orderItem.platformId,
        itemName: orderItem.name,
        packageId: orderItem.package?.packageId,
        maxOrderQuantity: orderItem.maxOrderQuantity || undefined,
        quantityMultiplier: orderItem.quantityMultiplier || undefined,
        unitOfMeasurement: orderItem.package?.unitOfMeasurement,
        nextUnitOfMeasurement: orderItem.package?.nextUnitOfMeasurement,
      },
  );

  const empties = getEmpties(data);

  const combos = data.combos?.map((comboItem) => {
    const comboAux = combosStore.find(
      (comboIt) => comboIt.platformId === comboItem.platformId,
    );

    return <CartComboItem>{
      id: comboAux?.vendorComboId ?? comboItem.platformId,
      platformId: comboItem.platformId,
      itemQuantity: comboItem.quantity,
      type: comboItem.type,
      originalPrice: comboItem.points,
      price: comboItem.total,
      total: comboItem.total,
      title: comboAux?.title ?? comboItem.title,
      items: comboAux?.items ?? comboItem.items,
      messages: [],
    };
  });

  const outputMessages = data.messages?.map(
    (message) =>
      <MessageResponse>{
        text: message.text,
        type: getMessageType(message.type),
        subtype: message.module,
      },
  );

  return {
    accountId,
    interestReason: [],
    price: 0,
    paymentMethod,
    paymentTerm,
    total: data.summary.total,
    subtotal: data.summary.subtotal,
    taxAmount: data.summary.taxAmount,
    discountAmount: data.summary.discountAmount,
    totalPallets: data.summary.palletQuantity,
    minimumOrder,
    lineItems,
    pricingReason: [],
    pricingReasonDetail: [],
    accountStatus: "ACTIVE",
    combos,
    outputMessages,
    allowPaymentMethod: true,
    allowPaymentMethodMessages: [],
    empties,
    cartId: data.id,
    freeGoods: data.freegoods ?? [],
    maxVehicleCapacity: pocInformation?.maxVehicleCapacity,
    pointsEarned: data.summary.charges?.find(
      (item) => item.type === "LOAN_DEDUCTION",
    )?.total,
    interestAmount: data.summary.charges?.find(
      (item) => item.type === "PAYMENT_METHOD_FEE",
    )?.total,
    overprice: data.summary.charges?.find((item) => item.type === "OVERPRICE")
      ?.total,
    vendorName: data.vendorName,
  };
}
