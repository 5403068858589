import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      padding: "5px",
      border: "1px solid black",
      borderRadius: "4px",
      margin: "15px 0px",
    },
    message: {
      color: "#505050",
      fontWeight: 500,
    },
    totalPoints: {
      fontWeight: 600,
      color: "red",
      paddingLeft: "1px",
      paddingRight: "1px",
    },
    title: {
      color: "#505050",
      fontWeight: 700,
      fontSize: "12px",
    },
    loading: {
      margin: "auto",
    },
    newContainer: {
      margin: "16px 0px",
      width: "100%",
      display: "flex",
    },
  }),
);
