import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    labelContainer: {
      marginTop: "23px",
      marginBottom: "14px",
    },
    labelItem: {
      display: "flex",
      margin: "8px 0px",
      alignItems: "center",
    },
    labelIcon: {
      borderRadius: "20px",
      marginRight: "8px",
      width: "20px",
      height: "20px",
      textAlign: "center",
    },
    selectedIcon: {
      backgroundColor: "#212121",
      color: "#ffffff",
      padding: "1px",
    },
    regularIcon: {
      border: "1px solid #000000",
    },
    flexibleIcon: {
      border: "1px solid #0371E2",
      color: "#0371E2",
    },
    labelText: {
      fontFamily: "Work Sans",
      fontSize: "10px",
    },
    labelTextFlexibleIcon: {
      color: "#0371E2",
    },
  }),
);
