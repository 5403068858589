import React from "react";
import { useTranslation } from "react-i18next";
import ComboTagStyle from "./ComboTag.Style";

const ComboTag = (): React.ReactElement => {
  const classes = ComboTagStyle();
  const { t } = useTranslation();

  return (
    <div className={classes.tag}>
      <span data-testid="tag-title" className={classes.text}>
        {t("ComboTag.COMBO_TAG_LABEL")}
      </span>
    </div>
  );
};

export default ComboTag;
