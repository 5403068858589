import React from "react";
import { Snackbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";

interface Props {
  open: boolean;
  message?: string;
  handleClose: () => void;
  severity: string;
}

const ToastNotification: React.FC<Props> = ({
  open,
  message,
  handleClose,
  severity,
}: Props) => {
  const IconButtonComponent = (
    <IconButton aria-label="close" color="inherit" onClick={handleClose}>
      <CloseIcon />
    </IconButton>
  );

  if (severity === "error") {
    return (
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={message}
        action={IconButtonComponent}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          elevation={6}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }
  if (severity === "success") {
    return (
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={message}
        action={IconButtonComponent}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          elevation={6}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }

  return <></>;
};

ToastNotification.defaultProps = {
  message: "",
};

export default ToastNotification;
