import {
  OrdersItems,
  LastMarketplaceOrdersResponse,
  LastMarketplaceOrdersResult,
  MarketplaceOrders,
} from "../../domains/lastMaketplaceOrders/LastMarketplaceOrders";
import { OrdersItemsResponse } from "../../services/lastOrders/domains/OrdersItemsResponse";
import { OrdersComboItemsResponse } from "../../services/lastOrders/domains/OrdersComboItemsResponse";

const LastMarketplaceOrdersResponseToLastMarketplaceResult = (
  data: LastMarketplaceOrdersResponse,
): LastMarketplaceOrdersResult => {
  const lastOrders: Array<MarketplaceOrders> = [];
  const marketplaceOrders: Array<MarketplaceOrders> = [];

  data.lastOrders.forEach((each) => {
    const aux: MarketplaceOrders = {
      accountId: each.accountId,
      orderNumber: each.orderNumber,
      status: each.status,
      createDate: each.placementDate,
      qtyProducts: each.items?.length,
      updatedAt: each.updatedAt,
      deliveryDate: each.deliveryDate,
      total: each.total,
      channel: each.channel,
      items: itemsByOrders(each.items, each.combos),
      paymentMethod: each.paymentMethod,
      hasMarketplace: each.hasMarketplaceItem,
    };

    lastOrders.push(aux);
  });

  data.marketplaceOrders.forEach((each) => {
    const aux: MarketplaceOrders = {
      accountId: each.accountId,
      orderNumber: each.orderNumber,
      status: each.status,
      createDate: each.placementDate,
      qtyProducts: each.items?.length,
      updatedAt: each.updatedAt,
      deliveryDate: each.deliveryDate,
      total: each.total,
      channel: each.channel,
      items: itemsByOrders(each.items, each.combos),
      paymentMethod: each.paymentMethod,
      hasMarketplace: each.hasMarketplaceItem,
    };

    marketplaceOrders.push(aux);
  });

  return {
    lastOrders,
    marketplaceOrders,
  };
};

const itemsByOrders = (
  orderItems: Array<OrdersItemsResponse>,
  orderCombos: Array<OrdersComboItemsResponse>,
) => {
  const setQuantity = (value: number | string) => {
    return value;
  };

  const items: Array<OrdersItems> = [];
  if (orderItems && orderItems.length > 0) {
    orderItems.forEach((item: OrdersItemsResponse) => {
      const aux: OrdersItems = {
        productCode: item.sku,
        productName: item.name,
        price: item.price,
        originalPrice: item.originalPrice,
        quantity: setQuantity(item.quantity),
        totalPrice: item.total,
        originalTotalPrice: item.originalPrice * Number(item.quantity),
        marketplace: item.marketplace,
      };

      items.push(aux);
    });
  }

  if (orderCombos && orderCombos.length > 0) {
    orderCombos.forEach((combo: OrdersComboItemsResponse) => {
      const aux: OrdersItems = {
        productCode: combo.id,
        productName: combo.title,
        price: combo.price,
        originalPrice: combo.originalPrice,
        quantity: setQuantity(combo.quantity),
        totalPrice: combo.price * Number(combo.quantity),
        originalTotalPrice: combo.originalPrice * Number(combo.quantity),
        marketplace: combo.marketplace,
      };

      items.push(aux);
    });
  }

  return items;
};

export default LastMarketplaceOrdersResponseToLastMarketplaceResult;
