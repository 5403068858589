import { useStore } from "effector-react";
import { MISSION_TAGS } from "../../../../config/constants";
import { filterMissionsTag } from "../../../../config/utils/functions";
import {
  isFeatureEnabledV2,
  TLP_SPONSORED_MISSION_TAB,
  TLP_SERVICE_MISSION,
  GROW_MISSION_REWARDS_REDEMPTION,
  GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
} from "../../../../config/featureToggles";
import GlobalStore from "../../../../stores/global/GlobalStore";
import useMissionsCards, { CardProps } from "./useMissionsCards";
import useGetCampaignsHook from "../campaignsComponent/useGetCampaignsHook";

const caseLocalMissions = ({
  isLocalGlobalMissionsOrdersEnabled,
  missions,
  localMissionsObject,
}) => {
  if (isLocalGlobalMissionsOrdersEnabled) missions.add(localMissionsObject);
};

export default function useMissionsList(
  allMissionsTags: Array<string>,
  validB2OTags: Array<string>,
): Array<CardProps> {
  const {
    toplineObject,
    promoteAlgoSellingObject,
    promoteRewardsRedemptionObject,
    promoteRewardsChallengesObject,
    promoteMarketPlaceObject,
    b2oObject,
    sponsoredObject,
    servicesObject,
    localMissionsObject,
  } = useMissionsCards();
  const [campaigns] = useGetCampaignsHook();
  const { user } = useStore(GlobalStore);

  const isLocalGlobalMissionsOrdersEnabled = isFeatureEnabledV2(
    GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
    user.zone,
  );

  const isSponsoredMissionsTabsEnabled = isFeatureEnabledV2(
    TLP_SPONSORED_MISSION_TAB,
    user.zone,
  );

  const isServicesMissionsEnabled = isFeatureEnabledV2(
    TLP_SERVICE_MISSION,
    user.zone,
  );

  const isWipRewardsRedemptionEnable = isFeatureEnabledV2(
    GROW_MISSION_REWARDS_REDEMPTION,
    user.zone,
  );

  const missions = new Set<CardProps>();

  const filteredMissions = filterMissionsTag(allMissionsTags, validB2OTags);

  filteredMissions.forEach((mission) => {
    switch (mission.includes("#b2o") ? "#b2o" : mission.toLowerCase()) {
      case MISSION_TAGS.TAG_TOPLINEGAP.toLowerCase(): {
        missions.add(toplineObject);
        break;
      }
      case MISSION_TAGS.TAG_PROMOTE_ALGO_SELLING.toLowerCase(): {
        missions.add(promoteAlgoSellingObject);
        break;
      }
      case MISSION_TAGS.TAG_PROMOTE_MARKETPLACE.toLowerCase(): {
        missions.add(promoteMarketPlaceObject);
        break;
      }
      case MISSION_TAGS.TAG_MARKETPLACEACTIVATION.toLowerCase(): {
        missions.add(promoteMarketPlaceObject);
        break;
      }
      case MISSION_TAGS.TAG_PROMOTEREWARDSCHALLENGES.toLowerCase(): {
        missions.add(promoteRewardsChallengesObject);
        break;
      }
      case MISSION_TAGS.TAG_NO_REWARDS_REDEMPTION.toLowerCase(): {
        if (!isWipRewardsRedemptionEnable)
          missions.add(promoteRewardsRedemptionObject);
        break;
      }

      case "#b2o": {
        missions.add(b2oObject);
        break;
      }
      case MISSION_TAGS.TAG_SPONSORED.toLowerCase(): {
        /* istanbul ignore else */
        if (isSponsoredMissionsTabsEnabled) missions.add(sponsoredObject);

        break;
      }
      case MISSION_TAGS.TAG_SERVICE.toLowerCase(): {
        if (isServicesMissionsEnabled) missions.add(servicesObject);
        break;
      }
      case MISSION_TAGS.TAG_LOCAL_MISSIONS.toLowerCase(): {
        caseLocalMissions({
          isLocalGlobalMissionsOrdersEnabled,
          missions,
          localMissionsObject,
        });
        break;
      }
      default:
        break;
    }
  });

  if (campaigns.length) missions.add(b2oObject);

  return [...missions];
}
