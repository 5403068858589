/* eslint-disable react/destructuring-assignment */
import RankedPhones from "../../domains/phoneRanking/RankedPhones";
import RankedPhonesResponse from "../../services/phoneRanking/domains/RankedPhonesResponse";

export default function RankedPhonesResponseToRankedPhonesConverter(
  data: RankedPhonesResponse,
): RankedPhones | null {
  if (data == null || Object.keys(data).length === 0) {
    return null;
  }
  const rankedPhones: RankedPhones = {
    accountId: data.accountId,
    phones: data.phones.map((phone) => {
      return {
        phoneNumber: phone.phoneNumber,
        score: phone.score,
        phoneType: phone.phoneType,
        phoneContact: phone.phoneContact,
      };
    }),
  };
  return rankedPhones;
}
