import { createStore } from "effector";
import { getScript, servicesScriptError400 } from "./ScriptEvents";
import ScriptState from "./ScriptState";

const initialState = <ScriptState>{
  loading: false,
  done: true,
  error: false,
  script: "",
};

const ScriptStore = createStore(initialState)
  .on(getScript, (state: ScriptState) => ({
    ...state,
    loading: true,
    done: false,
    error: false,
  }))
  .on(getScript.done, (state: ScriptState, { result }) => ({
    ...state,
    ...result,
    loading: false,
    done: true,
    error: false,
  }))
  .on(servicesScriptError400, (state: ScriptState) => {
    return { ...state, loading: false, done: true, error: false };
  })
  .on(getScript.fail, (state: ScriptState) => ({
    ...state,
    loading: false,
    done: true,
    error: true,
  }));

export default ScriptStore;
