import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import OrderCartStyles from "../../OrderCartStyles";

interface Props {
  handleFormChange: (cartFormChanges: Record<string, string>) => void;
  hasPONumberRequirement?: boolean;
  poNumber?: string;
}

const PurchaseOrder: React.FunctionComponent<Props> = ({
  handleFormChange,
  hasPONumberRequirement,
  poNumber,
}) => {
  const { t } = useTranslation();
  const classes = OrderCartStyles();
  const [poNumberState, setPoNumberState] = useState(poNumber);

  return (
    <Box className={classes.container}>
      <Typography className={classes.infoTitle}>
        {t("OrderTaking.PURCHASE_ORDER")}
      </Typography>
      {!hasPONumberRequirement && (
        <Typography className={classes.infoHint}>
          {t("OrderTaking.OPTIONAL")}
        </Typography>
      )}
      <textarea
        id="poNumber"
        name="poNumber"
        placeholder={t("OrderTaking.PURCHASE_ORDER_PLACEHOLDER")}
        className={classes.text}
        value={poNumberState}
        onChange={(e) => {
          setPoNumberState(e.currentTarget.value);
        }}
        onBlur={() => {
          handleFormChange({ poNumber: poNumberState ?? "" });
        }}
        data-testid="order-taking-purchase-order"
        maxLength={35}
      />
    </Box>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.poNumber === nextProps.poNumber;
};

export default React.memo(PurchaseOrder, areEqual);
