import React from "react";
import { TableCell } from "@material-ui/core";
import { ProductListStyles } from "../../routes/call/components/lastOrders/productList/ProductListStyles";
import Data from "../data/Data";

interface Props {
  classes: string;
  value: string | number;
  dataTest: string;
  alignment: "left" | "right";
  type: "currency" | "text";
  tag?: React.ReactNode;
  originalValue?: number;
}

function OriginalAndDiscountPrices({
  classes,
  value,
  dataTest,
  alignment,
  type,
  tag,
  originalValue,
}: Readonly<Props>): React.ReactElement {
  const styledClasses = ProductListStyles();

  return (
    <TableCell
      align={alignment}
      className={styledClasses.tableCellWithDiscount}
      data-testid={`${dataTest}-with-discount`}
    >
      <span
        className={styledClasses.steppedDiscountText}
        data-testid="original-value"
      >
        <Data value={originalValue} type="currency" ellipsis />
      </span>
      <span
        className={classes}
        style={{ display: "block" }}
        data-testid="discount-value"
      >
        <Data value={value} type={type} ellipsis />
      </span>
      {tag}
    </TableCell>
  );
}

export default OriginalAndDiscountPrices;
