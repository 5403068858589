import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    noItems: {
      fontSize: "16px",
      color: theme.palette.secondary.main,
      paddingBottom: "14px",
      textAlign: "center",
    },
    searchContainer: {
      marginTop: "20px",
    },
    itemsFound: {
      fontSize: "12px",
      fontWeight: "bold",
      color: theme.palette.secondary.main,
      paddingLeft: "2px",
    },
  }),
);
