import { makeStyles } from "@material-ui/core/styles";

const TabIdsStyles = makeStyles(({ palette }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "0",
    width: "250px",
    margin: "0 0 13px",
    padding: "6px 20px 8px 20px",
    backgroundColor: "#343434",
  },
  tabs: {
    display: "flex",
    height: "50px",
  },
  tabButtonWrapper: {
    width: "4.375rem",
    display: "flex",
    alignItems: "flex-end",
  },
  tabButton: {
    display: "block",
    width: "100%",
    padding: 0,
    color: "#9A9A9A",
    borderBottom: "2px solid #505050",
    fontSize: "12px",
    paddingBottom: "0px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  content: {
    display: "inline-block",
    color: "white",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  active: {
    borderBottom: `2px solid ${palette.primary.light}`,
    color: "white",
  },
  carouselItem: {
    fontSize: "12px",
    fontWeight: 500,
    color: palette.common.white,
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: "2%",
  },
  carousel: {
    marginInline: "-3%",
    marginTop: "6px",
    ">.tlp-Carousel-active-410": {
      color: "yellow",
    },
  },
  id: {
    fontSize: "12px",
    fontWeight: 500,
    color: palette.common.white,
    paddingTop: "8px",
  },

  carouselButton: {
    marginRight: "10px",
    marginLeft: "10px",
    fontSize: "14px",
    width: "20px",
    height: "20px",
  },

  carouselIcon: {
    fontSize: "16px",
    color: "#9A9A9A",
  },
  carouselIconActive: {
    color: "white",
  },
  activeItem: {
    color: "yellow",
    backgroundColor: "yellow",
  },
}));

export default TabIdsStyles;
