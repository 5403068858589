import React, { useCallback } from "react";
import { useStore } from "effector-react";
import { Typography, Box, FormControl } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FormikErrors, FormikTouched } from "formik";
import { PocCallOrigin } from "../../../../../config/constants";
import Select from "../../../../../components/select/Select";
import CallSummaryFormStyles from "./CallSumaryFormStyles";
import {
  isFeatureEnabled,
  TLP_SEARCH_BY_POC,
  TLP_SEARCH_BY_POC_ADMIN,
} from "../../../../../config/featureToggles";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import CallCounter from "../../../../../components/callCounter/CallCounter";
import CallCounterStore from "../../../../../stores/callCounter/CallCounterStore";
import { callLaterOptions } from "../callTypification/classifications";
import { FormValues } from "../formTypes/FormTypes";
import OrdersStore from "../../../../../stores/order/OrdersStore";
import {
  CLASSIFICATION_VALUES,
  CLASSIFICATIONS,
} from "../../../../../domains/Mission";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";

interface Props {
  values: FormValues;
  errors: FormikErrors<FormValues>;
  touched: FormikTouched<FormValues>;
  handleChange: (e: React.ChangeEvent<TargetValue>) => void;
  setClassificationValue: (e: string) => void;
  hasMission?: boolean;
}

interface TargetValue {
  name?: string;
  value: string;
}

const CallSummaryForm: React.FunctionComponent<Props> = ({
  values,
  errors,
  touched,
  handleChange,
  setClassificationValue,
  hasMission,
}) => {
  const { t } = useTranslation();
  const classes = CallSummaryFormStyles();
  const globalState = useStore(GlobalStore);
  const callCounter = useStore(CallCounterStore);
  const { isTypedPoc, pocCallOrigin } = useStore(AgentCallStore);
  const { orderInfo, ordersList, hasTechnicalProblem } = useStore(OrdersStore);

  const isSearchByPocEnabled = isFeatureEnabled(
    TLP_SEARCH_BY_POC,
    TLP_SEARCH_BY_POC_ADMIN,
    globalState.user.keyToggle,
  );

  const filterClassifications = () => {
    let classifications = CLASSIFICATIONS;

    if (
      isSearchByPocEnabled &&
      isTypedPoc &&
      pocCallOrigin === PocCallOrigin.SEARCH_BY_POC
    ) {
      classifications = classifications.filter(
        (each) =>
          each.value !== CLASSIFICATION_VALUES.CALL_LATER_TODAY &&
          each.value !== CLASSIFICATION_VALUES.NO_ANSWER_TRY_AGAIN,
      );
    }
    return classifications;
  };

  const hasPlacedOrder = () => {
    return !!orderInfo?.orderId || ordersList?.length > 0;
  };

  const callLater = useCallback((classification: string): boolean => {
    return (
      classification === CLASSIFICATION_VALUES.CALL_LATER_TODAY ||
      classification === CLASSIFICATION_VALUES.NO_ANSWER_TRY_AGAIN
    );
  }, []);

  const getClassificationComments = () => {
    if (hasPlacedOrder() && hasTechnicalProblem) {
      return `${t("NEWCallSummary.ORDER_PLACED_DURING_CALL")} - ${ordersList
        .map((value) => value.orderId)
        .join(", ")}`;
    }
    return values.classificationDetails;
  };

  return (
    <Box
      className={classes.box}
      style={
        hasMission
          ? { width: "370px", padding: "0 14px 21px 0" }
          : { width: "100%", padding: "0 0 21px 0" }
      }
    >
      <Typography className={classes.title}>
        {t("NEWCallSummary.CALL_TYPIFICATION_LABEL")}
      </Typography>
      <div style={{ width: "100%" }}>
        <CallCounter value={callCounter.count} />
      </div>
      <span
        className={classes.labelText}
        data-testid="classification-contact-label"
      >
        {t("NEWCallSummary.CONTACT_CLASSIFICATION")}
      </span>

      <FormControl
        error={!!errors.classification && touched.classification}
        data-testid="classification-call-summary-modal-form-control"
      >
        <Select
          name="classification"
          value={values.classification}
          options={filterClassifications()}
          onChange={(event) => {
            const targetValue = event as React.ChangeEvent<TargetValue>;
            handleChange(targetValue);
            setClassificationValue(targetValue.target.value);
          }}
          error={errors.classification}
          touched={touched.classification}
          testId="classification-call-summary-modal-select"
          disabled={hasPlacedOrder()}
        />
      </FormControl>

      {callLater(values.classification) && (
        <Box className={classes.inputContainer}>
          <div className={classes.label}>
            <span className={classes.labelText} data-testid="call-later-label">
              {t("CallTypification.CALL_LATER_LABEL")}
            </span>

            <Select
              name="callLater"
              value={values.callLater}
              options={callLaterOptions}
              onChange={(event) => {
                /* istanbul ignore next */
                handleChange(event as React.ChangeEvent<TargetValue>);
              }}
              error={errors.callLater}
              touched={touched.callLater}
              testId="call-summary-form-call-later-select"
            />
          </div>
        </Box>
      )}

      <span className={classes.labelText} data-testid="general-comments-label">
        {t("NEWCallSummary.GENERAL_COMMENTS")}
      </span>

      <textarea
        id="call-typification-modal-description"
        name="classificationDetails"
        placeholder={t("NEWCallSummary.COMMENTS_PLACEHOLDER")}
        className={classes.textarea}
        value={getClassificationComments()}
        onChange={handleChange}
        data-testid="classification-general-Comments-value"
        disabled={hasPlacedOrder() && hasTechnicalProblem}
      />
    </Box>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.values.classification === nextProps.values.classification &&
    prevProps.values.classificationDetails ===
      nextProps.values.classificationDetails &&
    prevProps.values.callLater === nextProps.values.callLater &&
    prevProps.errors.classification === nextProps.errors.classification &&
    prevProps.touched.classification === nextProps.touched.classification
  );
};

export default React.memo(CallSummaryForm, areEqual);
