import React from "react";
import { Box } from "@material-ui/core";
import ServerErrorMainPage from "./ServerErrorMainPage";

function ServerErrorRoute(): React.ReactElement {
  return (
    <Box data-testid="route-error" height="100%">
      <ServerErrorMainPage />
    </Box>
  );
}

export default ServerErrorRoute;
