import { createStore } from "effector";
import { getUserConfig, setVendorId } from "./GlobalAdminConfigEvents";
import GlobalAdminConfigState from "./GlobalAdminConfigState";

const initialState = <GlobalAdminConfigState>{
  isLoading: false,
  error: false,
  done: false,
  userConfig: {
    email: "",
    segmentation: [""],
    vendorId: "",
    missionPriority: [],
  },
  buConfig: {
    missionPriority: [""],
  },
};

const GlobalAdminConfigStore = createStore(initialState)
  .on(getUserConfig, (state: GlobalAdminConfigState) => ({
    ...state,
    isLoading: true,
    error: false,
    done: false,
  }))
  .on(getUserConfig.fail, (state: GlobalAdminConfigState) => ({
    ...state,
    isLoading: false,
    done: true,
    error: true,
  }))
  .on(getUserConfig.done, (state: GlobalAdminConfigState, { result }) => ({
    ...state,
    ...result,
    isLoading: false,
    done: true,
    error: false,
    userConfig: { ...result.userConfig },
    buConfig: { ...result.buConfig },
  }))
  .on(setVendorId, (state: GlobalAdminConfigState, value: string) => ({
    ...state,
    userConfig: {
      ...state.userConfig,
      vendorId: value,
    },
  }));

export default GlobalAdminConfigStore;
