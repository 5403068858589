import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";

import { getUserInfos } from "@config/utils/functions";
import { isFeatureEnabled } from "grow-shared-services";
import axios from "../../config/axios/axiosInstance";
import {
  GROW_MULTICONTRACT_AGENT_SURVEY,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../config/featureToggles";
import { AGENT_SURVEY_SERVICE } from "../../config/services";
import AgentSurveyResponseToAgentSurvey from "../../converters/agentSurvey/AgentSurveyResponseToAgentSurvey";
import { AgentSurvey } from "../../domains/AgentSurvey";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../host/HostService";
import {
  AgentSurveyResponse,
  AgentSurveyResponseV3,
} from "./domains/AgentSurveyResponse";

const AGENT_SURVEY_ENDPOINT = "/agent-survey";
const AGENT_SURVEY_ENDPOINT_V3 = "/v3/surveys";
const SURVEY_ENDPOINT = "/survey";
const RESPONDED_ENDPOINT = "/responded";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

const getUri = (
  isOrganizationConfigEnabled: boolean,
  agentId?: string,
  vendorId?: string,
): string => {
  const isMultiContractEnabled = isFeatureEnabled(
    GROW_MULTICONTRACT_AGENT_SURVEY,
  );

  if (isOrganizationConfigEnabled) {
    return `${getApiHost(AGENT_SURVEY_SERVICE)}${AGENT_SURVEY_ENDPOINT_V3}`;
  }

  const vendorIdParam = getVendorIdParam(vendorId, isMultiContractEnabled);
  const v2EndPoint = getV2EndPoint(isMultiContractEnabled);
  const baseUrl = `${getApiHost(AGENT_SURVEY_SERVICE)}${AGENT_SURVEY_ENDPOINT}`;

  return `${baseUrl}${v2EndPoint}/${agentId}${SURVEY_ENDPOINT}${vendorIdParam}`;
};

export function firstSurveyForAgent(agentId: string): Promise<AgentSurvey> {
  const log = useLogService();

  const { vendorId } = getUserInfos();

  const isOrganizationConfigEnabled = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const queryParams = {
    limit: 1,
  };

  const uri = getUri(isOrganizationConfigEnabled, agentId, vendorId);

  return axios
    .get<AgentSurveyResponse | AgentSurveyResponseV3>(uri, {
      params: isOrganizationConfigEnabled ? queryParams : {},
    })
    .then((response) => {
      log.info(
        `Convert Agent Survey Response received from ${uri} to our model`,
      );
      return AgentSurveyResponseToAgentSurvey(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}

export function updateSurvey(surveyId: string, agentId: string): Promise<void> {
  const log = useLogService();

  const { vendorId } = getUserInfos();

  const isOrganizationConfigEnabled = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  let uri = getUri(isOrganizationConfigEnabled, agentId, vendorId);

  if (isOrganizationConfigEnabled) {
    uri = `${uri}/${surveyId}${RESPONDED_ENDPOINT}`;

    return axios
      .post(uri)
      .then(() => {
        log.info(`Agent Survey Response V3 Updated from ${uri}`);
      })
      .catch((error) => {
        log.error(error);
        throw error;
      });
  }

  return axios
    .patch(uri, { surveyId })
    .then(() => {
      log.info(`Agent Survey Response Updated from ${uri}`);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
