import React from "react";

function RewardsChallengesStatisticsCompletedIcon(): JSX.Element {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ marginRight: "8px" }}
    >
      <defs>
        <path
          id="RewardsChallengesCompletedIcon-1"
          d="M7.93758833,6.9725 L0.856460124,8.00751393 L0.781050882,8.02131054 C0.0170202878,8.18992721 -0.271688197,9.15326552 0.303355535,9.71335811 L5.42608833,14.703 L4.21747972,21.7529567 L4.20745885,21.8266688 C4.12922006,22.6067479 4.95714124,23.1811908 5.66853961,22.8070735 L12.0010883,19.4765 L18.3336371,22.8070735 L18.4006408,22.8393907 C19.1183232,23.1549485 19.9205695,22.5451625 19.784697,21.7529567 L18.5755883,14.703 L23.6988211,9.71335811 L23.7517871,9.65793698 C24.2715091,9.07307512 23.9400089,8.12361165 23.1457165,8.00751393 L16.0640883,6.9725 L12.8977957,0.557376085 C12.5309603,-0.185792028 11.4712164,-0.185792028 11.104381,0.557376085 L7.93758833,6.9725 Z M12.0010883,3.259 L14.503381,8.32862392 L14.5425389,8.40041421 C14.6960917,8.65645835 14.9572188,8.83189363 15.2554601,8.87548607 L20.8525883,9.693 L16.8033555,13.6376419 L16.7470785,13.6971269 C16.5506722,13.9225256 16.4644575,14.2255582 16.5154797,14.5230433 L17.4705883,20.093 L12.4665396,17.4619265 L12.3857497,17.4239343 C12.1123282,17.3099576 11.8005306,17.3226217 11.5356371,17.4619265 L6.53108833,20.093 L7.48669695,14.5230433 L7.49722984,14.441836 C7.52352337,14.1440296 7.41503872,13.8482378 7.19882113,13.6376419 L3.14908833,9.693 L8.74671654,8.87548607 C9.07207073,8.82793068 9.35325594,8.62347141 9.49879568,8.32862392 L12.0010883,3.259 Z"
        />
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-787.000000, -495.000000)">
          <g transform="translate(333.000000, 100.000000)">
            <g transform="translate(1.000000, 299.000000)">
              <g transform="translate(0.000000, 72.000000)">
                <g transform="translate(16.000000, 24.000000)">
                  <g transform="translate(437.000000, 0.000000)">
                    <g transform="translate(3.998912, 4.500000)">
                      <mask
                        id="RewardsChallengesCompletedIcon-mask-2"
                        fill="white"
                      >
                        <use xlinkHref="#RewardsChallengesCompletedIcon-1"></use>
                      </mask>
                      <g fill-rule="nonzero"></g>
                      <g
                        mask="url(#RewardsChallengesCompletedIcon-mask-2)"
                        fill="#000000"
                      >
                        <g
                          transform="translate(-3.998912, -4.500000)"
                          id="Rectangle"
                        >
                          <rect x="0" y="0" width="32" height="32"></rect>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default RewardsChallengesStatisticsCompletedIcon;
