import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    list: {
      overflow: "hidden !important",
      "&:hover": {
        overflow: "auto !important",
      },
    },
  }),
);
