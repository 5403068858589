import {
  setFlexibleMissionsIds,
  setLocalMissionsIds,
  setSponsoredMissionsIds,
} from "@stores/sponsored/SponsoredEvents";
import {
  setEffectiveMissionsEvent,
  getEffectiveMissions,
} from "@stores/globalAndLocalMissions/EffectivenessMissionEvents";
import { getPrioritizedMissions } from "@services/priotizedMissions/GetPrioritizedMissionsService";

export const execute = async (
  accountId: string,
  vendorId: string,
  missionTypeFilter?: string,
): Promise<{ missions: string[]; missionsTabs: string[] }> => {
  return getPrioritizedMissions(accountId, vendorId, missionTypeFilter)
    .then((response) => {
      setSponsoredMissionsIds(response?.sponsoredMissionsIds);
      setLocalMissionsIds(response?.localMissionIds);
      setFlexibleMissionsIds(response?.flexibleMissionIds);

      setEffectiveMissionsEvent(
        getEffectiveMissions({
          sponsoredMissions: [],
          localMissions: [],
          globalCallListMissions: response?.missions,
        }),
      );
      return {
        missions: response?.missions,
        missionsTabs: response?.missionsTabs,
      };
    })
    .catch((error) => {
      throw error;
    });
};
