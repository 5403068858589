import React from "react";

function SimpleTypeIcon(): JSX.Element {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="___Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
          transform="translate(8.000000, 11.000000)"
        >
          <path
            d="M26.4615385,0 C29.076541,0 31.2143539,2.03723586 31.3749327,4.61166313 L31.3846154,4.92307692 L31.384,11.076 L43.0769231,11.0769231 C45.7965257,11.0769231 48,13.2803974 48,16 L48,40.6153846 L47.988954,40.8251648 C47.8286415,42.3280019 45.9608646,43.0332588 44.8484183,41.9208125 L39.2363077,36.3064615 L21.5384615,36.3076923 C18.8188589,36.3076923 16.6153846,34.104218 16.6153846,31.3846154 L16.615,25.23 L8.76369231,25.2295385 L3.15158175,30.8438894 C2.03913544,31.9563358 0.171358504,31.2510788 0.0110460411,29.7482417 L0,29.5384615 L0,4.92307692 C0,2.30807441 2.03723586,0.170261527 4.61166313,0.00968272929 L4.92307692,0 L26.4615385,0 Z M43.0769231,14.7692308 L21.5384615,14.7692308 C20.8580642,14.7692308 20.3076923,15.3196026 20.3076923,16 L20.3076923,31.3846154 C20.3076923,32.0650128 20.8580642,32.6153846 21.5384615,32.6153846 L40,32.6153846 L40.2426658,32.6313953 C40.6427294,32.6844339 41.0169104,32.8675931 41.3054279,33.1561106 L44.3076923,36.1569231 L44.3076923,16 C44.3076923,15.3196026 43.7573205,14.7692308 43.0769231,14.7692308 Z M26.4615385,3.69230769 L4.92307692,3.69230769 C4.30453385,3.69230769 3.79344989,4.14716046 3.70563746,4.74105359 L3.69230769,4.92307692 L3.69230769,25.08 L6.6945721,22.0791875 C6.9830896,21.79067 7.35727061,21.6075109 7.75733419,21.5544722 L8,21.5384615 L16.615,21.538 L16.6153846,16 C16.6153846,13.2803974 18.8188589,11.0769231 21.5384615,11.0769231 L27.692,11.076 L27.6923077,4.92307692 C27.6923077,4.30453385 27.2374549,3.79344989 26.6435618,3.70563746 L26.4615385,3.69230769 Z"
            id="path-1"
            fill="#000000"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default SimpleTypeIcon;
