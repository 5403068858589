import { createEffect, createEvent } from "effector";
import { execute } from "../../usecase/script/ScriptUseCase";

interface GetScriptService {
  countryId: string;
  vendorId?: string;
}

export const getScript = createEffect({
  async handler(props: GetScriptService) {
    return execute(props.countryId, props.vendorId);
  },
});

export const servicesScriptError400 = createEvent("servicesScriptError400");
