import { Button } from "@hexa-ui/components";
import { ThemeProvider } from "@hexa-ui/theme";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  testId: string;
  onClickAction: () => void;
  variant?: "primary" | "secondary";
  maxValue?: number;
}

const AddButton = ({
  testId,
  onClickAction,
  variant,
  maxValue,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme="grow">
      <Button
        size="small"
        variant={variant}
        onClick={onClickAction}
        data-testid={testId}
        disabled={maxValue === 0}
      >
        {t("RecommendedProducts.ADD_TO_CART")}
      </Button>
    </ThemeProvider>
  );
};

export default AddButton;
