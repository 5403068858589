import React from "react";

export default function IconShield(): JSX.Element {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-testid="icon-shield"
    >
      <defs>
        <path
          d="M18.8702761,0.138918158 C19.3642074,-0.0463060527 19.9085199,-0.0463060527 20.4024511,0.138918158 L20.4024511,0.138918158 L37.8569966,6.6843727 C38.7085684,7.00371211 39.2727273,7.81779369 39.2727273,8.72727273 L39.2727273,8.72727273 L39.2727273,24 C39.2727273,30.4787967 35.6819789,36.3137628 29.8003737,41.4601673 C27.7964945,43.2135617 25.6584347,44.763655 23.5199977,46.1001781 C23.3767063,46.1897352 23.2368155,46.2762726 23.10051,46.3597701 L23.10051,46.3597701 L22.3271775,46.8241129 L21.3479068,47.3817009 L20.6121024,47.7696593 C19.9978606,48.0767802 19.2748667,48.0767802 18.6606249,47.7696593 L18.6606249,47.7696593 L17.9248205,47.3817009 L16.9455497,46.8241129 L15.7527296,46.1001781 C13.6142926,44.763655 11.4762328,43.2135617 9.47235358,41.4601673 C3.59074837,36.3137628 0,30.4787967 0,24 L0,24 L0,8.72727273 C0,7.81779369 0.56415891,7.00371211 1.41573067,6.6843727 L1.41573067,6.6843727 Z M19.6363636,4.512 L4.36363636,10.2392727 L4.36363636,24 C4.36363636,28.7843738 7.09542831,33.4075501 11.7749826,37.666841 L11.7749826,37.666841 L12.3458282,38.1761963 C14.1601308,39.7637111 16.1129802,41.1795268 18.0654522,42.3998219 L18.0654522,42.3998219 L19.0486243,42.9971236 L19.6363636,43.3352727 L20.224103,42.9971236 L21.207275,42.3998219 C23.1597471,41.1795268 25.1125964,39.7637111 26.926899,38.1761963 C31.9543847,33.7771463 34.9090909,28.9757488 34.9090909,24 L34.9090909,24 L34.9090909,10.2392727 L19.6363636,4.512 Z M27.7245966,18.0935852 C28.5111071,18.8800957 28.5716079,20.1176907 27.906099,20.9735988 L27.7245966,21.1791421 L18.9973239,29.9064148 C18.2108134,30.6929253 16.9732184,30.7534261 16.1173103,30.0879172 L15.911767,29.9064148 L11.5481307,25.5427784 C10.6960777,24.6907254 10.6960777,23.3092746 11.5481307,22.4572216 C12.3346411,21.6707111 13.5722362,21.6102103 14.4281442,22.2757192 L14.6336875,22.4572216 L17.4545455,25.2763636 L24.6390398,18.0935852 C25.4910928,17.2415322 26.8725436,17.2415322 27.7245966,18.0935852 Z"
          id="shield-path-1"
        />
      </defs>
      <g
        id="___Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ZZ_Color-Override/01_Primary/BEESBlack"
          transform="translate(12.000000, 8.000000)"
        >
          <mask id="shield-mask-2" fill="white">
            <use xlinkHref="#shield-path-1" />
          </mask>
          <g id="Mask" fillRule="nonzero" />
          <g
            id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
            mask="url(#shield-mask-2)"
            fill="#000000"
            fillOpacity="0.9"
          >
            <g transform="translate(-12.000000, -8.000000)" id="Rectangle">
              <rect x="0" y="0" width="64" height="64" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
