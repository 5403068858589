import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { useStore } from "effector-react";
import { DELIVERY_METHODS } from "../../../../../../domains/fulfillment/Fulfillment";
import { getCountryByToken } from "../../../../../../config/utils/functions";
import PocInformationStore from "../../../../../../stores/callList/pocInformation/PocInformationStore";
import DeliveryWindowsStore from "../../../../../../stores/deliveryWindows/DeliveryWindowsStore";

import CartItemStore from "../../../../../../stores/cart/CartItemStore";
import GlobalStore from "../../../../../../stores/global/GlobalStore";
import { deliveryDateChanged } from "../../../../../../config/typewriter";
import DeliveryDate from "../../../deliveryDate/DeliveryDate";
import { useUpdateCartHook } from "../../hooks/useUpdateCartHook";
import OrderCartStyles from "../../OrderCartStyles";
import DeliveryDateLabel from "./Components/DeliveryDateLabel/DeliveryDateLabel";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_FLEXIBLE_DELIVERY_FEE,
  TLP_FLEXIBLE_DELIVERY_FEE_ADMIN,
  GROW_CART_SERVICE_V4,
} from "../../../../../../config/featureToggles";
import DeliveryRange from "./Components/DeliveryRange/DeliveryRange";

interface CartDeliveryDateProps {
  handleFormChange: (cartFormChanges: Record<string, string>) => void;
  isAlternativeDateSelected: (value?: string) => boolean;
  deliveryDate: string;
}

const CartDeliveryDate: React.FC<CartDeliveryDateProps> = ({
  isAlternativeDateSelected,
  handleFormChange,
  deliveryDate,
}) => {
  const { t } = useTranslation();
  const classes = OrderCartStyles();
  const { updateCart } = useUpdateCartHook();
  const { user } = GlobalStore.getState();
  const CartItemState = useStore(CartItemStore);
  const { pocInformation } = useStore(PocInformationStore);
  const { deliveryWindows, deliveryMethod, deliveryRange } =
    useStore(DeliveryWindowsStore);
  const [currentAlternativeDateMessage, setCurrentAlternativeDateMessage] =
    useState("");

  const { minDays, maxDays } = deliveryRange;

  const isFlexibleDeliveryFeeEnabled = isFeatureEnabled(
    TLP_FLEXIBLE_DELIVERY_FEE,
    TLP_FLEXIBLE_DELIVERY_FEE_ADMIN,
    user.keyToggle,
  );

  const isDeliveryWindowsMultiContractEnabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    getCountryByToken(),
  );

  const shouldShowDeliveryArea = () => {
    if (isDeliveryWindowsMultiContractEnabled) {
      return deliveryWindows && deliveryWindows.length > 0;
    }
    return (
      pocInformation.deliveryWindows &&
      pocInformation.deliveryWindows.length > 0
    );
  };

  const onChangeDeliveryDate = (value: string, deliveryWindowId?: string) => {
    handleFormChange({
      deliveryDate: value,
      deliveryWindowId: deliveryWindowId ?? "",
    });
    if (CartItemState.totalItems && CartItemState.totalItems > 0) {
      updateCart("deliveryDate", value, { deliveryWindowId });
    }
    deliveryDateChanged({
      call_id: "AgentCallStates.callId",
      poc_id: "AgentCallStates.clientId",
      is_alternative_date: isAlternativeDateSelected(value),
    });
  };

  const checkoutMessage =
    !!CartItemState.cart.outputMessages &&
    CartItemState.cart.outputMessages.filter(
      (value) => value.subtype === "FLEXIBLE_DELIVERY_DATE",
    )[0];

  useEffect(() => {
    setCurrentAlternativeDateMessage("");

    /* istanbul ignore next */
    if (isFlexibleDeliveryFeeEnabled && checkoutMessage) {
      setCurrentAlternativeDateMessage(checkoutMessage.text);
    } else {
      setCurrentAlternativeDateMessage(
        t("OrderTaking.DELIVERY_ALTERNATIVE_DATE"),
      );
    }
  }, [isFlexibleDeliveryFeeEnabled, t, checkoutMessage]);

  const renderDeliveryArea = (children) => (
    <Box
      className={classes.container}
      data-testid="cart-delivery-date-container"
    >
      <Typography className={classes.infoTitles}>
        {t("OrderTaking.DELIVERY_DATE")}
      </Typography>
      {children}
    </Box>
  );

  const renderDeliveryDate = () => (
    <>
      <DeliveryDate
        value={deliveryDate}
        onChange={onChangeDeliveryDate}
        deliveryWindows={pocInformation.deliveryWindows}
        deliveryWindowsNew={deliveryWindows}
      />

      <DeliveryDateLabel />

      {isAlternativeDateSelected() && currentAlternativeDateMessage && (
        <Typography
          data-testid="order-cart-alternative-message"
          className={classes.alternativeDateMessage}
        >
          {currentAlternativeDateMessage}
        </Typography>
      )}
    </>
  );

  const renderDeliveryRange = () => {
    return (
      <DeliveryRange
        data-testid="delivery-range"
        minDays={minDays}
        maxDays={maxDays}
      />
    );
  };

  if (shouldShowDeliveryArea()) {
    return renderDeliveryArea(renderDeliveryDate());
  }

  const shouldShowDeliveryRangeMessage = () => {
    return (
      deliveryMethod === DELIVERY_METHODS.DELIVERY_RANGE &&
      minDays &&
      minDays > -1 &&
      maxDays &&
      maxDays > -1
    );
  };

  if (shouldShowDeliveryRangeMessage()) {
    return renderDeliveryArea(renderDeliveryRange());
  }

  return <></>;
};

/* istanbul ignore next */
const areEqual = (
  prevProps: CartDeliveryDateProps,
  nextProps: CartDeliveryDateProps,
) => {
  return prevProps.deliveryDate === nextProps.deliveryDate;
};

export default React.memo(CartDeliveryDate, areEqual);
