import React, { useState, useEffect } from "react";
import { Alert } from "@hexa-ui/components";
import { useTranslation } from "react-i18next";
import ToastAlertStyles from "./ToastAlert.styles";

export interface ToastErrorStructure {
  message: string;
  type: "error" | "success" | "info" | "warning";
  codeError?: string;
}

interface ToastAlertProps {
  toastError: ToastErrorStructure;
  setToastError: React.Dispatch<React.SetStateAction<ToastErrorStructure>>;
}

const ToastAlert = ({
  toastError,
  setToastError,
}: ToastAlertProps): JSX.Element => {
  const { type, message, codeError } = toastError;
  const classes = ToastAlertStyles();
  const { t } = useTranslation();
  const [alertTimer, setAlertTimer] = useState<boolean>(true);

  /* istanbul ignore next */
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertTimer(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const closeAlert = () => {
    setToastError({
      ...toastError,
      message: "",
    });
  };

  if (!alertTimer) {
    closeAlert();
    return <></>;
  }

  return (
    <Alert
      showCloseButton
      className={classes.container}
      message={`${message} \n ${
        type === "error" ? t("CustomToast.ERROR_REQUEST_TRACE_ID") : ""
      } ${codeError}`}
      type={type}
      onClose={closeAlert}
      actions={[
        {
          action: () => {
            closeAlert();
          },
          name:
            type === "error" ? t("PHONE_RANKING_TOAST_ALERTS.TRY_AGAIN") : "",
        },
      ]}
    />
  );
};

export default ToastAlert;
