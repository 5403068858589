import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      boxSizing: "border-box",
      padding: "32px 33px 16px 31px",
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem",
    },
    containerRelative: {
      position: "relative",
    },
    content: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "427px",
    },
    callSummaryLabel: {
      fontSize: 16,
      color: "#505050",
      fontWeight: "bold",
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      borderBottomColor: "#9e9e9e",
      paddingBottom: "8px",
    },
    missionsEffectivenessContentDisable: {
      pointerEvents: "none",
      opacity: 0.5,
    },
    missionsEffectivenessContent: {
      width: "521px",
      height: "auto",
    },
    missionsEffectivenessLabel: {
      fontSize: 16,
      color: palette.text.primary,
      fontWeight: "bold",
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      borderBottomColor: "#9e9e9e",
      paddingBottom: "8px",
      fontFamily: "Barlow",
    },
    finalActionsContent: {
      width: "100%",
      height: "122px",
    },
    finalActionsContentV2: {
      height: "inherit",
    },
    finalActionsLabel: {
      fontSize: 16,
      color: palette.text.primary,
      fontWeight: 600,
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      borderBottomColor: "#dfdede",
      paddingBottom: "8px",
    },
    divider: {
      height: "404px",
      margin: "0 24px 0px 24px",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#9e9e9e",
    },
    closeButton: {
      position: "absolute",
      right: 0,
      top: "35px",
      marginRight: "33px",

      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.05)",
      },

      "& svg": {
        width: "16px",
        height: "16px",
        color: "rgba(0,0,0,0.9)",
      },
    },
  }),
);
