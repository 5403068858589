import React, { useState } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import CollapsibleTitle from "../../../../components/collapsableTitle/CollapsibleTitle";
import CustomSwitch from "../../../../components/CustomSwitch/CustomSwitch";
import LastOrderIcon from "../../../../assets/newIcons/LastOrdersIcon";
import LastOrder from "../lastOrders/LastOrders";
import { callUxButtonClicked } from "../../../../config/typewriter";
import { getValueOrNull } from "../../../../config/utils/functions";
import { ANALYTICS_ROUTE_NAMES } from "../../../../config/constants";
import RefreshTableButton from "../../../../components/refreshTableButton/RefreshTableButton";
import LastMarketplaceOrdersStore from "../../../../stores/lastMarketplaceOrders/LastMarketplaceOrdersStore";
import { getLastMarketplaceOrders } from "../../../../stores/lastMarketplaceOrders/LastMarketplaceOrdersEvents";
import MissionLastMarketplaceOrdersContainerStyles from "./MissionLastMarketplaceOrdersContainer.styles";

interface MissionLastMarketplaceOrdersContainerProps {
  zone: string;
  callTab: string;
  callId: string;
  clientId: string;
}

const MissionLastMarketplaceOrdersContainer = ({
  zone,
  callTab,
  callId,
  clientId,
}: MissionLastMarketplaceOrdersContainerProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = MissionLastMarketplaceOrdersContainerStyles();

  const { lastOrders, marketplaceOrders, isLoading, error } = useStore(
    LastMarketplaceOrdersStore,
  );

  const [lastMarketplaceOrdersOpen, setLastMarketplaceOrdersOpen] =
    useState(false);
  const [isMarketplaceSwitch, setIsMarketplaceSwitch] = useState(true);

  const triggerEventCallUx = (
    buttonName: string,
    buttonLabel: string,
    screenSection: string,
    filterOption: string | null,
  ) => {
    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: buttonName,
      button_label: buttonLabel,
      screen_section: screenSection,
      filter_option: filterOption,
      call_id: callId,
      order_date: null,
      position: null,
    });
  };

  const refreshLastOrders = () => {
    getLastMarketplaceOrders(clientId);
    triggerEventCallUx(
      "Refresh",
      "call-last-orders-refresh",
      "Last Orders",
      null,
    );
  };

  const handleOpenLastMarketeplaceOrders = () => {
    const buttonName = lastMarketplaceOrdersOpen
      ? "last-orders-component-collapse"
      : "last-orders-component-expand";

    const buttonLabel = lastMarketplaceOrdersOpen ? "collapse" : "expand";

    triggerEventCallUx(
      buttonName,
      buttonLabel,
      "Marketplace Last Orders",
      null,
    );

    setLastMarketplaceOrdersOpen(!lastMarketplaceOrdersOpen);
  };

  const handleSwitchChange = () => {
    setIsMarketplaceSwitch(!isMarketplaceSwitch);

    triggerEventCallUx(
      "marketplace-only-switch",
      "Marketplace only",
      "Marketplace Last Orders",
      isMarketplaceSwitch ? "marketplace only" : "all results",
    );
  };
  const marketplaceSwitch = (
    <div className={classes.marketplaceSwithContainer}>
      <span className={classes.marketplaceSwithLabel}>
        {t("LastOrders.MARKETPLACE_SWITCH_LABEL")}
      </span>
      <CustomSwitch
        onChange={() => handleSwitchChange()}
        checked={isMarketplaceSwitch}
      />
    </div>
  );

  return (
    <CollapsibleTitle
      icon={<LastOrderIcon />}
      title={t("LastOrders.LAST_ORDERS_LABEL")}
      subtitleContent={marketplaceSwitch}
      testId="last-order-collapse"
      open={lastMarketplaceOrdersOpen}
      handleOpen={handleOpenLastMarketeplaceOrders}
      hasError={error}
      refreshButton={
        <RefreshTableButton
          buttonTestId="last-marketplace-order-button"
          containerTestId="last-marketplace-order-container"
          titleUpdate={t("LastOrders.UPDATE_LIST")}
          refreshFunction={refreshLastOrders}
        />
      }
    >
      <LastOrder
        isLastMarketplaceOrder
        keyToggle={zone}
        isInMissions
        orders={isMarketplaceSwitch ? marketplaceOrders : lastOrders}
        isLoading={isLoading}
        error={error}
        errorFunction={() => getLastMarketplaceOrders(clientId)}
        data-testId="last-order-component"
      />
    </CollapsibleTitle>
  );
};

export default MissionLastMarketplaceOrdersContainer;
