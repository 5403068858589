/* istanbul ignore file */
import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    button: {
      minWidth: 140,
      height: 40,
      borderRadius: 20,
      border: "solid 1px #979797",
    },
    buttonLabel: {
      padding: "10px",
      color: "#707372",
    },
  }),
);
