import React from "react";
import ReactCountryFlag from "react-country-flag";
import { useStore } from "effector-react";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { isFeatureEnabled } from "grow-shared-services";
import GlobalAdminConfigStore from "../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import AgentIdentificationStyles from "./AgentIdentificationStyles";
import {
  getEnvironment,
  getUserInfos,
} from "../../../../config/utils/functions";
import mercasidLogo from "../../../../assets/images/mercasidd.svg";
import gloriaPELogo from "../../../../assets/images/gloria.svg";
import gloriaCOLogo from "../../../../assets/images/gloria_CO.png";
import nestleBrasilLogo from "../../../../assets/images/nestle-brasil.svg";
import nestleEquadorLogo from "../../../../assets/images/nestle-equador.svg";
import grupoPaoDeAcucarLogo from "../../../../assets/images/gpa-brasil.png";
import canaryIslandFlag from "../../../../assets/images/canary-island.png";
import nestleCaviteLogo from "../../../../assets/images/nestle-cavite-logo.png";
import nestleActiserveLogo from "../../../../assets/images/nestle-actiserve-logo.png";
import pepsicoLogo from "../../../../assets/images/pepsico-logo.svg";
import {
  mercaSIDVendorId,
  gloriaPEVendorId,
  gloriaCOVendorId,
  nestleBrasilVendorId,
  nestleEquadorVendorId,
  grupoPaoDeAcucarBrasilVendorId,
  nestleActiservePhilippinesVendorId,
  nestleCavitePhilippinesVendorId,
  pepsicoBrasilVendorId,
} from "../../../../config/constants";
import {
  GROW_COMPANIES_LOGO,
  GROW_ORGANIZATION_ACTIVATION,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";

export default function AgentIdentification(): React.ReactElement {
  const classes = AgentIdentificationStyles();

  const { user } = useStore(GlobalStore);
  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);
  const { vendorId } = GlobalAdminConfigState.userConfig;
  const { orgLogoUrl } = getUserInfos();

  const isCompaniesLogoEnabled = isFeatureEnabledV2(
    GROW_COMPANIES_LOGO,
    user.zone,
  );

  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  const env = getEnvironment();

  const isMercaSID = mercaSIDVendorId.includes(vendorId);
  const isGloriaPE = gloriaPEVendorId.includes(vendorId);
  const isGloriaCO = gloriaCOVendorId.includes(vendorId);
  const isNestleBrasil = nestleBrasilVendorId.includes(vendorId);
  const isNestleEquador = nestleEquadorVendorId.includes(vendorId);
  const isGrupoPaoDeAcucarBrasil =
    grupoPaoDeAcucarBrasilVendorId.includes(vendorId);
  const isNestleCavite = nestleCavitePhilippinesVendorId.includes(vendorId);
  const isNestleActiserve =
    nestleActiservePhilippinesVendorId.includes(vendorId);
  const isPepsico = pepsicoBrasilVendorId.includes(vendorId);

  const renderEnv = () => {
    return `${env} - ${localStorage.getItem("version")}`;
  };

  const handleAccountOrgLogo = () => {
    if (!orgLogoUrl) {
      return (
        <ReactCountryFlag
          className={classes.image}
          data-testid="orgLogo-img-notFound"
        />
      );
    }
    return (
      <img
        src={orgLogoUrl}
        width="100%"
        height="100%"
        data-testid="orgLogo-img"
        alt="logo of the organization"
      />
    );
  };

  const handleAccountLogo = () => {
    if (isCompaniesLogoEnabled) {
      if (isMercaSID)
        return (
          <img
            src={mercasidLogo}
            width={100}
            height={100}
            data-testid="mercasid-logo"
            alt="mercasid-logo"
          />
        );

      if (isGloriaPE)
        return (
          <img
            src={gloriaPELogo}
            width={65}
            height={65}
            data-testid="gloria-pe-logo"
            alt="gloria-pe-logo"
          />
        );
      if (isGloriaCO)
        return (
          <img
            src={gloriaCOLogo}
            width={65}
            height={65}
            data-testid="gloria-co-logo"
            alt="gloria-co-logo"
          />
        );
      if (isNestleBrasil)
        return (
          <img
            src={nestleBrasilLogo}
            width={65}
            height={65}
            data-testid="nestle-brasil-logo"
            alt="nestle-brasil-logo"
          />
        );
      if (isNestleEquador)
        return (
          <img
            src={nestleEquadorLogo}
            width={65}
            height={65}
            data-testid="nestle-equador-logo"
            alt="nestle-equador-logo"
          />
        );
      if (isGrupoPaoDeAcucarBrasil)
        return (
          <img
            src={grupoPaoDeAcucarLogo}
            width={65}
            height={65}
            data-testid="grupo-pao-de-acucar-logo"
            alt="grupo-pao-de-acucar-logo"
          />
        );
      if (isNestleCavite)
        return (
          <img
            src={nestleCaviteLogo}
            width={65}
            height={65}
            data-testid="nestle-cavite-logo"
            alt="nestle-cavite-logo"
          />
        );
      if (isNestleActiserve)
        return (
          <img
            src={nestleActiserveLogo}
            width={65}
            height={65}
            data-testid="nestle-actiserve-logo"
            alt="nestle-actiserve-logo"
          />
        );

      if (isPepsico)
        return (
          <img
            src={pepsicoLogo}
            width={85}
            height={85}
            data-testid="pepsico-logo"
            alt="pepsico-logo"
          />
        );
    }

    if (user.zone === "ES")
      return (
        <img
          src={canaryIslandFlag}
          width={65}
          height={65}
          data-testid="canary-island-flag"
          alt="canary-island-flag"
        />
      );

    return (
      <ReactCountryFlag
        className={classes.image}
        countryCode={user.zone}
        svg
        title={user.zone}
        data-testid="agent-flag-component"
      />
    );
  };

  const renderImgWrapper = () => {
    if (isOrganizationEnabled) {
      return (
        <div className={classes.imageContainer}>{handleAccountOrgLogo()}</div>
      );
    }
    if (user.zone) {
      return (
        <div className={classes.imageContainer}>{handleAccountLogo()}</div>
      );
    }

    return <></>;
  };

  return (
    <div className={classes.identificationContainer}>
      {renderImgWrapper()}
      <div className={classes.infosContainer}>
        {user.login && (
          <Typography
            data-testid="agent-email-value"
            className={classNames(classes.identificationText, classes.email)}
          >
            {user.login}
          </Typography>
        )}
        {env && env !== "PROD" && (
          <Typography
            data-testid="agent-env-value"
            className={classNames(
              classes.identificationText,
              classes.environment,
            )}
          >
            {renderEnv()}
          </Typography>
        )}
      </div>
    </div>
  );
}
