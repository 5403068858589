import React from "react";

export default function IconGlobe(): JSX.Element {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-testid="icon-globe"
    >
      <defs>
        <path
          d="M26.4,-2.13162821e-14 C40.9803174,-2.13162821e-14 52.8,11.8196826 52.8,26.4 C52.8,40.9803174 40.9803174,52.8 26.4,52.8 C11.8196826,52.8 -3.62376795e-13,40.9803174 -3.62376795e-13,26.4 C-3.62376795e-13,11.8196826 11.8196826,-2.13162821e-14 26.4,-2.13162821e-14 Z M14.5264516,28.7994735 L4.93177653,28.7995247 C5.92301819,37.7682486 12.4053878,45.0820794 20.9424861,47.3046175 C17.2921275,41.8151636 15.0753081,35.462964 14.5264516,28.7994735 Z M47.8682235,28.7995247 L38.2798034,28.7997284 C37.7417779,35.4311208 35.5224425,41.7966302 31.8588878,47.3035297 C40.3951167,45.0815101 46.8770204,37.7678996 47.8682235,28.7995247 Z M33.4685935,28.7994745 L19.3381714,28.7994782 C19.9279607,35.1708419 22.2877757,41.247482 26.1428993,46.3426061 L26.1428993,46.3426061 L26.4,46.676 L26.6571007,46.3428141 C30.527137,41.2292462 32.8902738,35.1342534 33.4685935,28.7994745 Z M20.9411122,5.49647031 L20.8777393,5.51234653 C12.3729439,7.75484986 5.92099107,15.0538952 4.93188696,23.9994764 L14.5202777,23.9992713 C15.0584453,17.368239 17.2777417,11.0030929 20.9411122,5.49647031 Z M26.4,6.123 L26.1428993,6.4571859 C22.2730502,11.5705065 19.9099549,17.6651569 19.3314904,23.9996061 L33.461736,23.9995216 C32.8717923,17.6285222 30.5120226,11.5522514 26.6571007,6.4573939 L26.6571007,6.4573939 L26.4,6.123 Z M31.8575139,5.49538247 L31.9181457,5.58681267 C35.5322247,11.0550188 37.7275146,17.3733504 38.273466,23.9995266 L47.868113,23.9994764 C46.8765005,15.0312083 40.3942952,7.71783813 31.8575139,5.49538247 Z"
          id="globe-path-1"
        />
      </defs>
      <g
        id="___Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="02_Icons/globe">
          <g
            id="ZZ_Color-Override/Primary/Black"
            transform="translate(5.600000, 5.600000)"
          >
            <mask id="globe-mask-2" fill="white">
              <use xlinkHref="#globe-path-1" />
            </mask>
            <g id="Mask" fillRule="nonzero" />
            <g
              id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
              mask="url(#globe-mask-2)"
              fill="#000000"
              fillOpacity="0.9"
            >
              <g transform="translate(-5.600000, -5.600000)" id="Rectangle">
                <rect x="0" y="0" width="64" height="64" />
              </g>
            </g>
          </g>
          <g id="ZZ_Color-Override/Primary/Black" />
          <g id="ZZ_Color-Override/Primary/Black" />
        </g>
      </g>
    </svg>
  );
}
