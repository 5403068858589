import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Promotions as PromotionsType } from "../../../../../domains/promotions/Promotions";
import { PromotionsPrice } from "../../../../../domains/promotions/domains/PromotionsPrice";
import Data from "../../../../../components/data/Data";
import cardStyles from "./CardStyles";

interface Props {
  data: PromotionsType;
}

const SteppedDiscountSection: React.FC<Props> = ({ data }) => {
  const classes = cardStyles();
  const { t } = useTranslation();
  const testIdPrefix = "stepped-discount";

  const getStep = (item: PromotionsPrice) => {
    return ` ${item.stepStart} ${t("Promotions.TO")} ${item.stepEnd}`;
  };

  const getPercentage = (price: number) => {
    const difference = data.originalPrice - price;
    return `${Math.round((difference / data.originalPrice) * 100)}% ${t(
      "Promotions.OFF",
    )}`;
  };

  return (
    <div>
      <div
        className={classNames(
          classes.skuContainer,
          classes.steppedDiscountContainer,
        )}
      >
        <div className={classes.skuColor}>{data.sku}</div>
        <Data
          value={data.originalPrice}
          type="currency"
          testId={`${testIdPrefix}${data.sku}-original-price`}
          className={classNames(
            classes.originalPriceSteppedDiscount,
            classes.originalPrice,
          )}
        />
      </div>
      {data.prices?.map((price) => (
        <div
          className={classNames(classes.priceContainer, classes.uppercase)}
          key={price.id}
        >
          <span
            data-testid={`${testIdPrefix}-quantity-${price.stepStart}-to-${price.stepEnd}`}
          >
            {t("Promotions.QUANTITY")}:{getStep(price)}
          </span>
          <div data-testid={`${testIdPrefix}-percentage-${price.id}`}>
            {getPercentage(price.discountedPrice)}
          </div>
          <Data
            value={price.discountedPrice}
            type="currency"
            className={classes.price}
            testId={`${testIdPrefix}-${price.id}`}
            key={`${testIdPrefix}-${price.id}`}
          />
        </div>
      ))}
    </div>
  );
};

export default SteppedDiscountSection;
