import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import GlobalStore from "../../../../stores/global/GlobalStore";
import { callUxButtonClicked } from "../../../../config/typewriter";
import { getValueOrNull } from "../../../../config/utils/functions";
import { ANALYTICS_ROUTE_NAMES } from "../../../../config/constants";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";
import MissionsFocusSkuComponent from "../missionsFocusSkuComponent/MissionsFocusSkuComponent";
import * as LastOrdersUseCase from "../../../../usecase/lastOrders/LastOrdersUseCase";
import LastOrdersStore from "../../../../stores/lastOrders/LastOrdersStore";
import MissionLastOrderContainer from "../missionLastOrderContainer/MissionLastOrderContainer";
import MissionLastMarketplaceOrdersContainer from "../MissionLastMarketplaceOrdersContainer/MissionLastMarketplaceOrdersContainer";
import MissionRecommendContainer from "../missionRecommendContainer/MissionRecommendContainer";
import GlobalAdminConfigStore from "../../../../stores/globaAdminConfig/GlobalAdminConfigStore";

import {
  TLP_RECOMENDED_PRODUCTS,
  TLP_RECOMENDED_PRODUCTS_ADMIN,
  TLP_LAST_MARKETPLACE_ORDERS,
  TLP_LAST_MARKETPLACE_ORDERS_ADMIN,
  isFeatureEnabled,
} from "../../../../config/featureToggles";

const MarketplaceComponent: React.FC = () => {
  const { t } = useTranslation();

  const [lastOrderOpen, setLastOrderOpen] = useState(true);
  const [recommendProductsOpen, setRecommendProductsOpen] = useState(true);
  const [missionsProductsOpen, setMissionsProductsOpen] = useState(true);

  const { callTab } = useStore(CallTabStore);
  const { callId, clientId } = useStore(AgentCallStore);
  const { orders, isLoading, error } = useStore(LastOrdersStore);
  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { vendorId } = userConfig;
  const { user } = useStore(GlobalStore);

  const isRecommendEnabled = isFeatureEnabled(
    TLP_RECOMENDED_PRODUCTS,
    TLP_RECOMENDED_PRODUCTS_ADMIN,
    user.keyToggle,
  );

  const isMarketplaceLastOrders = isFeatureEnabled(
    TLP_LAST_MARKETPLACE_ORDERS,
    TLP_LAST_MARKETPLACE_ORDERS_ADMIN,
    user.zone,
  );

  const callLastOrders = useCallback(() => {
    LastOrdersUseCase.execute(clientId, vendorId);
  }, [clientId, vendorId]);

  const handleOpenLastOrder = () => {
    const buttonName = lastOrderOpen
      ? "last-orders-component-collapse"
      : "last-orders-component-expand";
    const buttonLabel = !lastOrderOpen ? "expand" : "collapse";
    const screenSection = "Last Orders";
    callEventCallUx(buttonName, buttonLabel, screenSection);
    setLastOrderOpen(!lastOrderOpen);
  };

  const handleOpenMissionsProducts = () => {
    const buttonName = missionsProductsOpen
      ? "target-products-component-collapse"
      : "target-products-component-expand";
    const buttonLabel = !missionsProductsOpen ? "expand" : "collapse";
    const screenSection = "Target products";
    callEventCallUx(buttonName, buttonLabel, screenSection);
    setMissionsProductsOpen(!missionsProductsOpen);
  };

  const handleOpenRecommendProducts = () => {
    const buttonName = recommendProductsOpen
      ? "recommended-products-component-collapse"
      : "recommended-products-component-expand";
    const screenSection = "Marketplace Recommended Products";
    const buttonLabel = !recommendProductsOpen ? "expand" : "collapse";

    callEventCallUx(buttonName, buttonLabel, screenSection);

    setRecommendProductsOpen((prevState) => !prevState);
  };

  const refreshLastOrders = () => {
    callLastOrders();
    callEventCallUx("Refresh", "call-last-orders-refresh", "Last Orders");
  };

  const callEventCallUx = (
    buttonName: string,
    buttonLabel: string,
    screenSection: string,
  ) => {
    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: buttonName,
      button_label: buttonLabel,
      screen_section: screenSection,
      filter_option: null,
      call_id: callId,
      order_date: null,
      position: null,
      mission: null,
    });
  };

  return (
    <div style={{ flex: 1 }}>
      {isRecommendEnabled && (
        <MissionRecommendContainer
          handleOpenRecommendProducts={handleOpenRecommendProducts}
          recommendProductsOpen={recommendProductsOpen}
          title={t("Marketplace.RECOMMEND_PRODUCTS_TITLE")}
        />
      )}

      {isMarketplaceLastOrders ? (
        <MissionLastMarketplaceOrdersContainer
          zone={user.keyToggle}
          callTab={callTab}
          callId={callId}
          clientId={clientId}
          data-testid="mission-last-order-marketplace-container"
        />
      ) : (
        <MissionLastOrderContainer
          error={error}
          handleOpenLastOrder={handleOpenLastOrder}
          lastOrderOpen={lastOrderOpen}
          refreshLastOrders={refreshLastOrders}
          title={t("LastOrders.LAST_ORDERS_LABEL")}
          zone={user.keyToggle}
          titleUpdate={t("LastOrders.UPDATE_LIST")}
          orders={orders}
          isLoading={isLoading}
          errorFunction={callLastOrders}
          data-testid="mission-last-order-container"
        />
      )}
      {!isRecommendEnabled && (
        <MissionsFocusSkuComponent
          handleOpen={handleOpenMissionsProducts}
          open={missionsProductsOpen}
        />
      )}
    </div>
  );
};

export default MarketplaceComponent;
