/* eslint-disable global-require */
import React, { useState } from "react";
import { Collapse, IconButton, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import Tooltip from "@material-ui/core/Tooltip";
import useProductCard from "./hooks/useProductCard/useProductCard";
import ChevronDown from "../../assets/ChevronDown";
import ChevronUp from "../../assets/ChevronUp";
import { Promotions } from "../../domains/promotions/Promotions";
import { Product } from "../../domains/products/Product";
import FormatStockExhibition from "../../helpers/FormatStockExhibition";
import Card from "../card/Card";
import ControlledAddition from "../controlledAddition/ControlledAddition";
import Data from "../data/Data";
import ProductCardV2Styles from "./ProductCardV2Styles";
import {
  isFeatureEnabledV2,
  GROW_ITEM_TITLE_TOOLTIP,
  GROW_LOCAL_TAGS_COMPONENT,
} from "../../config/featureToggles";
import GlobalStore from "../../stores/global/GlobalStore";
import TooltipInfo from "../productCardTooltipInfo/TooltipInfo";
import useTooltipRules from "../ProductCardV3/hooks/useToolTipRules/useToolTipRules";
import { SponsoredMission } from "../../domains/sponsored/SponsoredMission";

interface Props {
  product: Product;
  mission?: SponsoredMission;
  description: string;
  unit?: string;
  promotion?: Promotions;
  isPromotion?: boolean;
  showStock?: boolean;
  showAddButton?: boolean;
  showAddedInfoText?: boolean;
  showPromoTag?: boolean;
  showPromoPrice?: boolean;
  hasStyleBorder?: boolean;
  minValue?: number;
  maxValue?: number;
  unitPrice?: number;
  addAction?: (itemQuantity: number, itemId?: string) => void;
  itemQuantityChangeAnalytics?: () => void;
  comboDetailsAnalytics?: (Movement: string) => void;
}

const offeringImagePlaceholder =
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  require("../../assets/images/ProductPlaceholder.png") as string;

const ProductCardV2: React.FunctionComponent<Props> = ({
  product,
  mission,
  description,
  unit = "un",
  promotion,
  isPromotion = false,
  showStock = false,
  showAddButton = false,
  showAddedInfoText = false,
  showPromoTag = false,
  showPromoPrice = false,
  hasStyleBorder = false,
  minValue,
  maxValue,
  unitPrice,
  addAction,
  comboDetailsAnalytics,
  itemQuantityChangeAnalytics,
}) => {
  const classes = ProductCardV2Styles();
  const { t } = useTranslation();
  const {
    user: { zone },
  } = useStore(GlobalStore);
  const [src, setSrc] = useState(product.image || "");
  const price = product.price || unitPrice;

  const {
    discount,
    handleChangeQuantity,
    handleAddAction,
    renderSuggestedQuantity,
    itemQuantity,
    setItemQuantity,
  } = useProductCard({
    product,
    mission,
    promotion,
    minValue,
    addAction,
    itemQuantityChangeAnalytics,
  });

  const { handleTitle, isTooltipDisabled } = useTooltipRules(product);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const sku = product.sku || product.vendorComboId || product.id;

  const isItemTitleTooltipEnabled = isFeatureEnabledV2(
    GROW_ITEM_TITLE_TOOLTIP,
    zone,
  );

  const isLocalTagsComponentEnabled = isFeatureEnabledV2(
    GROW_LOCAL_TAGS_COMPONENT,
    zone,
  );

  const renderIcon = () => {
    /* istanbul ignore else */
    if (isCollapsed) return <ChevronUp />;

    return <ChevronDown />;
  };

  const handleCollapseDetails = () => {
    setIsCollapsed(!isCollapsed);

    /* istanbul ignore else */
    if (comboDetailsAnalytics)
      comboDetailsAnalytics(isCollapsed ? "collapse" : "expand");
  };

  const calculateStock = (stockItem) => {
    if (stockItem > 0) {
      return (
        <div className={classes.recommendedCardItemStock}>
          {FormatStockExhibition(stockItem)} <br />
          {t("RecommendedProducts.IN_STOCK")}
        </div>
      );
    }

    return (
      <div data-testId="div-out-of-stock" className={classes.outOfStock}>
        {t("RecommendedProducts.OUT_OF_STOCK")}
      </div>
    );
  };

  const renderOriginalPrice = () => {
    if (discount && isPromotion && showPromoPrice)
      return (
        <Data
          type="currency"
          className={classes.recommendedCardInfoLineThrough}
          value={price}
          testId={`${sku}-original-price-value`}
        />
      );

    return null;
  };

  return (
    <>
      <Card
        className={
          hasStyleBorder
            ? classes.recommendedCardContainer
            : classes.cardNoBorder
        }
        data-testid={`${sku}-container`}
      >
        <div className={classes.stockInformation}>
          <img
            src={src || offeringImagePlaceholder}
            className={
              showStock && product.stock === 0
                ? classes.recommendedCardItemImageOvershadowed
                : classes.recommendedCardItemImage
            }
            alt={`${sku}-product`}
            data-testid={`${sku}-product-image`}
            /* istanbul ignore next */
            onError={() => setSrc(offeringImagePlaceholder)}
          />
          {showStock && product.stock != null && product.stock >= 0 && (
            <div
              data-testid={`${sku}-stock-text`}
              className={classes.recommendedCardStockContainer}
            >
              {calculateStock(product.stock)}
            </div>
          )}
        </div>

        <div>
          <div className={classes.recommendedCardInfoContainer}>
            <div>
              {isItemTitleTooltipEnabled ? (
                <Tooltip
                  arrow
                  placement="top"
                  classes={{
                    tooltip: classes.tooltipTitleContainer,
                    arrow: classes.tooltipArrow,
                  }}
                  disableHoverListener={isTooltipDisabled()}
                  title={
                    <TooltipInfo
                      comboDetails={product.comboDetails}
                      itemName={description}
                      fullContainerDescription={
                        product.fullContainerDescription
                      }
                      fullPackageDescription={product.fullPackageDescription}
                    />
                  }
                  data-testid="item-card-title-tooltip"
                >
                  <span className={classes.recommendedCardInfoTitleTooltip}>
                    {handleTitle()}
                  </span>
                </Tooltip>
              ) : (
                <Data
                  value={description}
                  className={classes.recommendedCardInfoTitle}
                  testId={`${sku}-description-text`}
                  hasTooltip
                />
              )}

              <Data
                value={sku}
                className={classes.recommendedCardInfoSKU}
                testId={`${sku}-sku-text`}
                hasTooltip
              />

              <div className={classes.tagsContainer}>
                {isPromotion && showPromoTag && (
                  <Typography
                    className={classes.recommendedCardInfoPromo}
                    variant="h6"
                    data-testid={`${sku}-promo-text`}
                  >
                    Promo
                  </Typography>
                )}

                {product.isCombo && (
                  <div className={classes.divFlexAndCenterAlign}>
                    {!isLocalTagsComponentEnabled && (
                      <Typography
                        className={classes.recommendedCardInfoCombo}
                        variant="h6"
                        data-testid="combo-label-text"
                      >
                        Combo
                      </Typography>
                    )}

                    {product.comboDetails?.length && (
                      <IconButton
                        aria-label="toggle dropdown visibility"
                        onClick={handleCollapseDetails}
                        edge="end"
                        className={classes.collapseIcon}
                        data-testid="collapse-combo-icon"
                      >
                        {renderIcon()}
                      </IconButton>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className={classes.productDetails}>
              {product.comboDetails?.length && (
                <Collapse
                  in={isCollapsed}
                  data-testid={`combo-${sku}-collapse`}
                >
                  {product.comboDetails?.map((combo) => (
                    <div
                      className={classes.comboDetails}
                      key={`collapse-details-${sku}`}
                    >
                      <Data
                        value={combo.name}
                        className={classes.comboTitle}
                        testId={`combo-collapse-product-title-${combo.id}`}
                        hasTooltip
                      />
                      {combo.packageName && (
                        <Data
                          value={combo.packageName}
                          className={classes.comboDescription}
                          data-testid={`combo-collapse-product-unity-${combo.id}`}
                        />
                      )}
                    </div>
                  ))}
                </Collapse>
              )}

              {product.suggestedQuantity && (
                <Typography
                  variant="subtitle1"
                  className={classes.recommendedCardInfoSuggested}
                  data-testid={`${sku}-suggested-quantity`}
                >
                  {renderSuggestedQuantity()}
                </Typography>
              )}

              <div className={classes.recommendedCardPriceContainer}>
                {renderOriginalPrice() && (
                  <Typography
                    variant="subtitle1"
                    className={classes.strikethroughPriceText}
                    data-testid={`${sku}-strikethrough-price-text`}
                  >
                    {renderOriginalPrice()}
                  </Typography>
                )}

                <Typography
                  variant="h2"
                  className={classes.recommendedCardInfoPrice}
                  data-testid={`${sku}-price-container`}
                >
                  <Data
                    type="currency"
                    /* istanbul ignore next */
                    className={
                      isPromotion && showPromoTag
                        ? classes.recommendedCardInfoPriceWithPromo
                        : classes.recommendedCardInfoPrice
                    }
                    value={showPromoPrice ? discount || price : price}
                    testId={`${sku}-price-value`}
                  />
                  <Typography
                    className={classes.recommendedCardInfoPriceUnit}
                    data-testid={`${sku}-price-unit`}
                  >
                    /{unit}
                  </Typography>
                </Typography>
              </div>
            </div>
          </div>
          {showAddButton && (
            <div
              className={classes.recommendedCardActions}
              data-testid={`recommended-controlled-addition-container-${sku}`}
            >
              <ControlledAddition
                testId="recommended-controlled-addition"
                addAction={handleAddAction}
                itemQuantityChangeAction={handleChangeQuantity}
                showCartLink={showAddedInfoText}
                minValue={minValue}
                maxValue={maxValue}
                itemQuantity={itemQuantity}
                setItemQuantity={setItemQuantity}
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default ProductCardV2;
