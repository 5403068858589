export interface Mission {
  tag: string;
  type: string;
  typification: string;
  reason: string;
  comments: string;
  effectiveness: boolean | undefined;
  stopCallingWhen?: string;
  missionId?: string;
  missionTitle?: string;
}

export interface OptionsMission {
  value: string;
  label: string;
}

export const CLASSIFICATION_VALUES = {
  EMPTY: "",
  SUCCESS_ON_CONTACT: "Success on contact",
  NO_ANSWER_DO_NOT_CALL_AGAIN: "No Answer",
  NO_ANSWER_TRY_AGAIN: "No Answer - try again",
  RESPONSIBLE_NOT_AVAILABLE: "Responsible Not Available",
  INCORRECT_PHONE_NUMBER: "Incorrect Phone Number",
  POC_CLOSED_DUPLICATE: "Poc Closed Duplicated",
  CALL_LATER_TODAY: "Call Later Today",
};

export const CLASSIFICATIONS = [
  {
    value: CLASSIFICATION_VALUES.EMPTY,
    label: "NEWCallSummary.EMPTY",
  },
  {
    value: CLASSIFICATION_VALUES.SUCCESS_ON_CONTACT,
    label: "NEWCallSummary.SUCCESS_ON_CONTACT",
  },
  {
    value: CLASSIFICATION_VALUES.NO_ANSWER_DO_NOT_CALL_AGAIN,
    label: "NEWCallSummary.NO_ANSWER_DO_NOT_CALL_AGAIN",
  },
  {
    value: CLASSIFICATION_VALUES.NO_ANSWER_TRY_AGAIN,
    label: "NEWCallSummary.NO_ANSWER_TRY_AGAIN",
  },
  {
    value: CLASSIFICATION_VALUES.RESPONSIBLE_NOT_AVAILABLE,
    label: "NEWCallSummary.RESPONSIBLE_NOT_AVAILABLE",
  },
  {
    value: CLASSIFICATION_VALUES.INCORRECT_PHONE_NUMBER,
    label: "NEWCallSummary.INCORRECT_PHONE_NUMBER",
  },
  {
    value: CLASSIFICATION_VALUES.POC_CLOSED_DUPLICATE,
    label: "NEWCallSummary.POC_CLOSED_DUPLICATE",
  },
  {
    value: CLASSIFICATION_VALUES.CALL_LATER_TODAY,
    label: "NEWCallSummary.CALL_LATER_TODAY",
  },
];

export enum GAPReasonValues {
  EMPTY = "",
  NO_CREDIT_AVAILABLE = "NO_CREDIT_AVAILABLE",
  FINANCIAL_REASON = "FINANCIAL_REASON",
  LOW_DEMAND = "LOW_DEMAND",
  TECHNICAL_PROBLEM = "TECHNICAL_PROBLEM",
  LACK_OF_KNOWLEDGE = "LACK_OF_KNOWLEDGE",
  FORGOT = "FORGOT",
  BETTER_PRICE = "BETTER_PRICE",
  EXPERIENCE_ISSUES_BEES = "EXPERIENCE_ISSUES_BEES",
  EXPERIENCE_ISSUES_LOGISTICS = "EXPERIENCE_ISSUES_LOGISTICS",
  PRODUCTS_OUT_STOCK = "PRODUCTS_OUT_STOCK",
  ANOTHER_SUPPLIER = "ANOTHER_SUPPLIER",
  CLIENT_UPSET = "CLIENT_UPSET",
}

export enum MarketPlaceValues {
  EMPTY = "",
  PRODUCTS_NOT_RELEVANT = "PRODUCTS_NOT_RELEVANT",
  BETTER_PRICE = "BETTER_PRICE",
  NOT_INTERESTED_MARKETPLACE = "NOT_INTERESTED_MARKETPLACE",
  NOT_INTERESTED = "NOT_INTERESTED",
  SERVICE_COMPLAINT = "SERVICE_COMPLAINT",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  DECISION_MAKER = "DECISION_MAKER",
  FINANCIAL_REASON = "FINANCIAL_REASON",
  PRODUCTS_OUT_STOCK = "PRODUCTS_OUT_STOCK",
  EXPERIENCE_ISSUES_BEES = "EXPERIENCE_ISSUES_BEES",
  EXPERIENCE_ISSUES_LOGISTICS = "EXPERIENCE_ISSUES_LOGISTICS",
  LOW_DEMAND = "LOW_DEMAND",
}

export const GAPReason = [
  {
    value: GAPReasonValues.EMPTY,
    label: "GapReason.EMPTY",
  },
  {
    value: GAPReasonValues.NO_CREDIT_AVAILABLE,
    label: "GapReason.NO_CREDIT_AVAILABLE",
  },
  {
    value: GAPReasonValues.FINANCIAL_REASON,
    label: "GapReason.FINANCIAL_REASON",
  },
  {
    value: GAPReasonValues.LOW_DEMAND,
    label: "GapReason.LOW_DEMAND",
  },
  {
    value: GAPReasonValues.TECHNICAL_PROBLEM,
    label: "GapReason.TECHNICAL_PROBLEM",
  },
  {
    value: GAPReasonValues.LACK_OF_KNOWLEDGE,
    label: "GapReason.LACK_OF_KNOWLEDGE",
  },
  {
    value: GAPReasonValues.FORGOT,
    label: "GapReason.FORGOT",
  },
  {
    value: GAPReasonValues.ANOTHER_SUPPLIER,
    label: "GapReason.ANOTHER_SUPPLIER",
  },
  {
    value: GAPReasonValues.CLIENT_UPSET,
    label: "GapReason.CLIENT_UPSET",
  },
];

export const GAPReasonReviewed = [
  {
    value: GAPReasonValues.EMPTY,
    label: "GapReason.EMPTY",
  },
  {
    value: GAPReasonValues.FINANCIAL_REASON,
    label: "GapReason.FINANCIAL_REASON",
  },
  {
    value: GAPReasonValues.LOW_DEMAND,
    label: "GapReason.LOW_DEMAND",
  },
  {
    value: GAPReasonValues.BETTER_PRICE,
    label: "GapReason.BETTER_PRICE",
  },
  {
    value: GAPReasonValues.EXPERIENCE_ISSUES_BEES,
    label: "GapReason.EXPERIENCE_ISSUES_BEES",
  },
  {
    value: GAPReasonValues.EXPERIENCE_ISSUES_LOGISTICS,
    label: "GapReason.EXPERIENCE_ISSUES_LOGISTICS",
  },
  {
    value: GAPReasonValues.PRODUCTS_OUT_STOCK,
    label: "GapReason.PRODUCTS_OUT_STOCK",
  },
];

export const MarketplaceOptions = [
  {
    value: MarketPlaceValues.EMPTY,
    label: "Marketplace.EMPTY",
  },
  {
    value: MarketPlaceValues.PRODUCTS_NOT_RELEVANT,
    label: "Marketplace.PRODUCTS_NOT_RELEVANT",
  },
  {
    value: MarketPlaceValues.BETTER_PRICE,
    label: "Marketplace.BETTER_PRICE",
  },
  {
    value: MarketPlaceValues.NOT_INTERESTED,
    label: "Marketplace.NOT_INTERESTED",
  },
  {
    value: MarketPlaceValues.SERVICE_COMPLAINT,
    label: "Marketplace.SERVICE_COMPLAINT",
  },
  {
    value: MarketPlaceValues.OUT_OF_STOCK,
    label: "Marketplace.OUT_OF_STOCK",
  },
  {
    value: MarketPlaceValues.DECISION_MAKER,
    label: "Marketplace.DECISION_MAKER",
  },
  {
    value: MarketPlaceValues.FINANCIAL_REASON,
    label: "Marketplace.FINANCIAL_REASON",
  },
];

export const MarketplaceOptionsReviewed = [
  {
    value: MarketPlaceValues.EMPTY,
    label: "Marketplace.EMPTY",
  },
  {
    value: MarketPlaceValues.FINANCIAL_REASON,
    label: "Marketplace.FINANCIAL_REASON",
  },
  {
    value: MarketPlaceValues.PRODUCTS_OUT_STOCK,
    label: "Marketplace.PRODUCTS_OUT_STOCK",
  },
  {
    value: MarketPlaceValues.EXPERIENCE_ISSUES_BEES,
    label: "Marketplace.EXPERIENCE_ISSUES_BEES",
  },
  {
    value: MarketPlaceValues.EXPERIENCE_ISSUES_LOGISTICS,
    label: "Marketplace.EXPERIENCE_ISSUES_LOGISTICS",
  },
  {
    value: MarketPlaceValues.LOW_DEMAND,
    label: "Marketplace.LOW_DEMAND",
  },
  {
    value: MarketPlaceValues.NOT_INTERESTED,
    label: "Marketplace.NOT_INTERESTED_MARKETPLACE",
  },
  {
    value: MarketPlaceValues.BETTER_PRICE,
    label: "Marketplace.BETTER_PRICE",
  },
];

export enum MarketPlaceClassificationValues {
  EMPTY = "",
  UPLIFT_ON_CALL = "Uplifted",
  UPLIFT_AFTER_CALL = "Uplifted after call",
}

export const MarketPlaceClassification = [
  {
    value: MarketPlaceClassificationValues.EMPTY,
    label: "MarketplaceActivationMissionClassification.EMPTY",
  },
  {
    value: MarketPlaceClassificationValues.UPLIFT_ON_CALL,
    label: "MarketplaceActivationMissionClassification.UPLIFT_ON_CALL",
  },
  {
    value: MarketPlaceClassificationValues.UPLIFT_AFTER_CALL,
    label: "MarketplaceActivationMissionClassification.UPLIFT_AFTER_CALL",
  },
];

export enum TopLineMissionClassificationValues {
  EMPTY = "",
  UPLIFT_ON_CALL = "Uplifted",
  UPLIFT_ON_CALL_BY_AGENT = "Uplift on call by Agent",
  UPLIFT_AFTER_CALL = "Uplifted after call",
}

export const TopLineMissionClassification = [
  {
    value: TopLineMissionClassificationValues.EMPTY,
    label: "TopLineMissionClassification.EMPTY",
  },
  {
    value: TopLineMissionClassificationValues.UPLIFT_ON_CALL,
    label: "TopLineMissionClassification.UPLIFT_ON_CALL",
  },
  {
    value: TopLineMissionClassificationValues.UPLIFT_AFTER_CALL,
    label: "TopLineMissionClassification.UPLIFT_AFTER_CALL",
  },
];

export const TopLineMissionClassificationOrder = [
  {
    value: TopLineMissionClassificationValues.UPLIFT_ON_CALL_BY_AGENT,
    label: "TopLineMissionClassification.UPLIFT_ON_CALL",
  },
];

export const LastContactsClassification = [
  {
    value: TopLineMissionClassificationValues.EMPTY,
    label: "TopLineMissionClassification.EMPTY",
  },
  {
    value: TopLineMissionClassificationValues.UPLIFT_ON_CALL,
    label: "TopLineMissionClassification.UPLIFT_ON_CALL",
  },
  {
    value: TopLineMissionClassificationValues.UPLIFT_ON_CALL_BY_AGENT,
    label: "TopLineMissionClassification.UPLIFT_ON_CALL",
  },
  {
    value: TopLineMissionClassificationValues.UPLIFT_AFTER_CALL,
    label: "TopLineMissionClassification.UPLIFT_AFTER_CALL",
  },
  {
    value: MarketPlaceClassificationValues.UPLIFT_ON_CALL,
    label: "MarketplaceActivationMissionClassification.UPLIFT_ON_CALL",
  },
  {
    value: MarketPlaceClassificationValues.UPLIFT_AFTER_CALL,
    label: "MarketplaceActivationMissionClassification.UPLIFT_AFTER_CALL",
  },
];

export enum ChallengesValues {
  EMPTY = "",
  CLIENT_FORGOT_TO_ACCEPT = "CLIENT_FORGOT_TO_ACCEPT",
  UNINTERESTING_PRODUCTS = "UNINTERESTING_PRODUCTS",
  FINANCIAL_REASON = "FINANCIAL_REASON",
  LOW_DEMAND = "LOW_DEMAND",
  NOT_INTERESTED = "NOT_INTERESTED",
  NOT_INTERESTED_CHALLENGES = "NOT_INTERESTED_CHALLENGES",
  PRODUCTS_IN_STOCK = "PRODUCTS_IN_STOCK",
  PRODUCTS_OUT_STOCK = "PRODUCTS_OUT_STOCK",
  LOW_REWARDS_POINTS = "LOW_REWARDS_POINTS",
  NOT_EASY_TO_FIND = "NOT_EASY_TO_FIND",
  CUSTOMER_DID_NOT_KNOW = "CUSTOMER_DID_NOT_KNOW",
  THERE_IS_NOT_STOCK = "THERE_IS_NOT_STOCK",
  NO_CAMERA_AVAILABLE = "NO_CAMERA_AVAILABLE",
  PHOTOS_ALWAYS_DECLINED = "PHOTOS_ALWAYS_DECLINED",
  NONE_PREVIOUS_OPTIONS = "NONE_PREVIOUS_OPTIONS",
  ISSUES_WITH_PHOTOS = "ISSUES_WITH_PHOTOS",
}

export const ChallengesReasonOptions = [
  {
    value: ChallengesValues.EMPTY,
    label: "ChallengesReason.EMPTY",
  },
  {
    value: ChallengesValues.CLIENT_FORGOT_TO_ACCEPT,
    label: "ChallengesReason.CLIENT_FORGOT_TO_ACCEPT",
  },
  {
    value: ChallengesValues.UNINTERESTING_PRODUCTS,
    label: "ChallengesReason.UNINTERESTING_PRODUCTS",
  },
  {
    value: ChallengesValues.FINANCIAL_REASON,
    label: "ChallengesReason.FINANCIAL_REASON",
  },
  {
    value: ChallengesValues.PRODUCTS_IN_STOCK,
    label: "ChallengesReason.PRODUCTS_IN_STOCK",
  },
  {
    value: ChallengesValues.LOW_REWARDS_POINTS,
    label: "ChallengesReason.LOW_REWARDS_POINTS",
  },
  {
    value: ChallengesValues.NOT_EASY_TO_FIND,
    label: "ChallengesReason.NOT_EASY_TO_FIND",
  },
  {
    value: ChallengesValues.CUSTOMER_DID_NOT_KNOW,
    label: "ChallengesReason.CUSTOMER_DID_NOT_KNOW",
  },
  {
    value: ChallengesValues.THERE_IS_NOT_STOCK,
    label: "ChallengesReason.THERE_IS_NOT_STOCK",
  },
  {
    value: ChallengesValues.NO_CAMERA_AVAILABLE,
    label: "ChallengesReason.NO_CAMERA_AVAILABLE",
  },
  {
    value: ChallengesValues.PHOTOS_ALWAYS_DECLINED,
    label: "ChallengesReason.PHOTOS_ALWAYS_DECLINED",
  },
  {
    value: ChallengesValues.NONE_PREVIOUS_OPTIONS,
    label: "ChallengesReason.NONE_PREVIOUS_OPTIONS",
  },
];

export const ChallengesReasonReviewedOptions = [
  {
    value: ChallengesValues.EMPTY,
    label: "ChallengesReason.EMPTY",
  },
  {
    value: ChallengesValues.UNINTERESTING_PRODUCTS,
    label: "ChallengesReason.UNINTERESTING_PRODUCTS",
  },
  {
    value: ChallengesValues.FINANCIAL_REASON,
    label: "ChallengesReason.FINANCIAL_REASON",
  },
  {
    value: ChallengesValues.LOW_DEMAND,
    label: "ChallengesReason.LOW_DEMAND",
  },
  {
    value: ChallengesValues.PRODUCTS_OUT_STOCK,
    label: "ChallengesReason.PRODUCTS_OUT_STOCK",
  },
  {
    value: ChallengesValues.NOT_INTERESTED_CHALLENGES,
    label: "ChallengesReason.NOT_INTERESTED_CHALLENGES",
  },
  {
    value: ChallengesValues.ISSUES_WITH_PHOTOS,
    label: "ChallengesReason.ISSUES_WITH_PHOTOS",
  },
];

export enum SponsoredReasonValues {
  EMPTY = "",
  PRODUCTS_NOT_RELEVANT = "PRODUCTS_NOT_RELEVANT",
  BETTER_PRICE = "BETTER_PRICE",
  NOT_INTERESTED = "NOT_INTERESTED",
  SERVICE_COMPLAINT = "SERVICE_COMPLAINT",
  PRODUCTS_OUT_STOCK = "PRODUCTS_OUT_STOCK",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  DECISION_MAKER = "DECISION_MAKER",
  FINANCIAL_REASON = "FINANCIAL_REASON",
  EXPERIENCE_ISSUES_BEES = "EXPERIENCE_ISSUES_BEES",
  EXPERIENCE_ISSUES_LOGISTICS = "EXPERIENCE_ISSUES_LOGISTICS",
}

export const SponsoredReasonOptions = [
  {
    value: SponsoredReasonValues.EMPTY,
    label: "SponsoredReason.EMPTY",
  },
  {
    value: SponsoredReasonValues.PRODUCTS_NOT_RELEVANT,
    label: "SponsoredReason.PRODUCTS_NOT_RELEVANT",
  },
  {
    value: SponsoredReasonValues.BETTER_PRICE,
    label: "SponsoredReason.BETTER_PRICE",
  },
  {
    value: SponsoredReasonValues.NOT_INTERESTED,
    label: "SponsoredReason.NOT_INTERESTED",
  },
  {
    value: SponsoredReasonValues.SERVICE_COMPLAINT,
    label: "SponsoredReason.SERVICE_COMPLAINT",
  },
  {
    value: SponsoredReasonValues.OUT_OF_STOCK,
    label: "SponsoredReason.OUT_OF_STOCK",
  },
  {
    value: SponsoredReasonValues.DECISION_MAKER,
    label: "SponsoredReason.DECISION_MAKER",
  },
  {
    value: SponsoredReasonValues.FINANCIAL_REASON,
    label: "SponsoredReason.FINANCIAL_REASON",
  },
];

export const SponsoredReasonOptionsReviewed = [
  {
    value: SponsoredReasonValues.EMPTY,
    label: "SponsoredReason.EMPTY",
  },
  {
    value: SponsoredReasonValues.FINANCIAL_REASON,
    label: "SponsoredReason.FINANCIAL_REASON",
  },
  {
    value: SponsoredReasonValues.PRODUCTS_NOT_RELEVANT,
    label: "SponsoredReason.PRODUCTS_NOT_RELEVANT",
  },
  {
    value: SponsoredReasonValues.BETTER_PRICE,
    label: "SponsoredReason.BETTER_PRICE",
  },
  {
    value: SponsoredReasonValues.EXPERIENCE_ISSUES_BEES,
    label: "SponsoredReason.EXPERIENCE_ISSUES_BEES",
  },
  {
    value: SponsoredReasonValues.EXPERIENCE_ISSUES_LOGISTICS,
    label: "SponsoredReason.EXPERIENCE_ISSUES_LOGISTICS",
  },
  {
    value: SponsoredReasonValues.PRODUCTS_OUT_STOCK,
    label: "SponsoredReason.PRODUCTS_OUT_STOCK",
  },
];

export enum ServicesValues {
  EMPTY = "",
  NOT_ENOUGH_POINTS = "NOT_ENOUGH_POINTS",
  NOT_INTERESTED = "NOT_INTERESTED",
  PREFERS_TO_USE_POINTS_FOR_PRODUCTS = "PREFERS_TO_USE_POINTS_FOR_PRODUCTS",
  DOESNT_APPLY_TO_THE_POC = "DOESNT_APPLY_TO_THE_POC",
  PRODUCTS_OUT_STOCK = "PRODUCTS_OUT_STOCK",
}

export const ServicesOptions = [
  {
    value: ServicesValues.EMPTY,
    label: "ServicesGapReasons.EMPTY",
  },
  {
    value: ServicesValues.DOESNT_APPLY_TO_THE_POC,
    label: "ServicesGapReasons.DOESNT_APPLY_TO_THE_POC",
  },
  {
    value: ServicesValues.NOT_ENOUGH_POINTS,
    label: "ServicesGapReasons.NOT_ENOUGH_POINTS",
  },
  {
    value: ServicesValues.NOT_INTERESTED,
    label: "ServicesGapReasons.NOT_INTERESTED",
  },
  {
    value: ServicesValues.PREFERS_TO_USE_POINTS_FOR_PRODUCTS,
    label: "ServicesGapReasons.PREFERS_TO_USE_POINTS_FOR_PRODUCTS",
  },
];
export default Mission;
