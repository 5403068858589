import React from "react";

function WarningIcon(props: any) {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.98302 1.26036C7.41059 0.555472 8.17518 0.125 8.99961 0.125C9.82404 0.125 10.5886 0.555472 11.0185 1.2641L17.6839 12.3918C18.103 13.1175 18.1055 14.0111 17.6905 14.7392C17.2755 15.4673 16.5053 15.9204 15.6587 15.9297L2.33189 15.9297C1.49389 15.9204 0.723721 15.4673 0.30872 14.7392C-0.106282 14.0111 -0.10378 13.1175 0.321679 12.3809L6.98302 1.26036ZM9.67181 2.07585C9.52928 1.84088 9.27442 1.69739 8.99961 1.69739C8.7248 1.69739 8.46994 1.84088 8.32967 2.07211L1.67697 13.1781C1.53728 13.42 1.53644 13.7178 1.67478 13.9605C1.81311 14.2032 2.06984 14.3543 2.34053 14.3573L15.6501 14.3574C15.9294 14.3543 16.1861 14.2032 16.3244 13.9605C16.4628 13.7178 16.4619 13.42 16.3286 13.1889L9.67181 2.07585ZM8.99961 4.92295C9.4028 4.92295 9.7351 5.22646 9.78052 5.61746L9.78581 5.70915V8.85394C9.78581 9.28814 9.43381 9.64013 8.99961 9.64013C8.59642 9.64013 8.26412 9.33663 8.2187 8.94562L8.21341 8.85394V5.70915C8.21341 5.27495 8.56541 4.92295 8.99961 4.92295ZM9.79367 11.9987C9.79367 11.5645 9.44168 11.2125 9.00747 11.2125L8.90792 11.2178C8.51692 11.2632 8.21341 11.5955 8.21341 11.9987C8.21341 12.4329 8.56541 12.7849 8.99961 12.7849L9.09916 12.7796C9.49016 12.7342 9.79367 12.4019 9.79367 11.9987Z"
        fill="#9F5C00"
      />
    </svg>
  );
}

export default React.memo(WarningIcon);
