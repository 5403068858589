/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Box, CircularProgress } from "@material-ui/core";

import { getAddress } from "@config/utils/functions";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_MISSIONS_LAYOUT,
  TLP_MISSIONS_LAYOUT_ADMIN,
  TLP_NEW_CREDIT_PAYMENT_INFO,
} from "@config/featureToggles";
import { useAnalytics } from "@analytics/useAnalytics";
import PocInfoContainer from "@components/pocInfoContainer/PocInfoContainer";

import PocCredit from "../pocCredit/PocCredit";
import PocPaymentInfo from "../pocPaymentInfo/PocPaymentInfo";
import LoyaltyProgram from "../header/loyaltyProgram/LoyaltyProgram";
import PhonesInformation from "./phonesInformation/PhonesInformation";
import AdditionalInformationInfo from "./AdditionalInformationInfo";
import PocInfoItem from "./PocInfoItem";
import PocTags from "./PocTags";
import { PocInfoStyles } from "./PocInfoStyles";

function PocInfo({
  isLoaded,
  pocInformation,
  tags,
  keyToggle,
}: any): React.ReactElement {
  const classes = PocInfoStyles();
  const { owner, emailAccount, additionalInformation } = pocInformation;

  const { dispatchPocEvent } = useAnalytics();

  const isMissionsEnabled = isFeatureEnabled(
    TLP_MISSIONS_LAYOUT,
    TLP_MISSIONS_LAYOUT_ADMIN,
    keyToggle,
  );

  const isNewCreditPaymentInfoEnabled = isFeatureEnabledV2(
    TLP_NEW_CREDIT_PAYMENT_INFO,
    keyToggle,
  );

  if (!isLoaded)
    return (
      <CircularProgress
        data-testid="poc-info-loading-circle"
        size={50}
        className={classes.loading}
      />
    );

  return (
    <Box className={classes.gridContainer}>
      <Box className={classes.box}>
        <div className={classes.containerLoyaltyProgram}>
          <Box className={classes.borderContainer}>
            <LoyaltyProgram />
          </Box>
        </div>
        {
          /* istanbul ignore next */
          !isMissionsEnabled && tags && tags.length > 0 && (
            <PocTags tags={tags} testId="poc-tags" keyToggle={keyToggle} />
          )
        }
        <PocInfoContainer>
          <PocInfoItem
            titleId="PocInformationHeaderTemplate.OWNER_LABEL"
            text={owner}
            testId="poc-info-owner-item"
            dispatchEvent={dispatchPocEvent}
          />
        </PocInfoContainer>

        <PhonesInformation />

        <PocInfoContainer>
          <PocInfoItem
            titleId="PocInformationHeaderTemplate.ADDRESS_LABEL"
            text={getAddress(pocInformation)}
            testId="poc-info-address-item"
            dispatchEvent={dispatchPocEvent}
          />
        </PocInfoContainer>
        <PocInfoContainer>
          <PocInfoItem
            titleId="PocInformationHeaderTemplate.EMAIL_LABEL"
            items={emailAccount}
            testId="poc-info-email-item"
            dispatchEvent={dispatchPocEvent}
          />
        </PocInfoContainer>
        <AdditionalInformationInfo
          data={additionalInformation}
          dispatchEvent={dispatchPocEvent}
        />
        <PocCredit
          isNewCreditPaymentInfoEnabled={isNewCreditPaymentInfoEnabled}
          data-testid="poc-credit-info-component"
        />
        {isNewCreditPaymentInfoEnabled && (
          <PocPaymentInfo data-testid="poc-payment-info-component" />
        )}
      </Box>
    </Box>
  );
}
export default React.memo(PocInfo);
