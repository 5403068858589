import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PhoneRankingTagStyles from "./PhoneRankingTag.styles";

interface PhoneRankingTagProps {
  calledType: "called" | "copied" | "inactivated";
}

function PhoneRankingTag({ calledType }: PhoneRankingTagProps): JSX.Element {
  const { tag, calledTag, copiedTag, inactivatedTag } = PhoneRankingTagStyles();
  const { t } = useTranslation();

  const getText = (calledType: string): string => {
    switch (calledType) {
      case "called":
        return "CALLED_TAG";
      case "copied":
        return "COPIED_TAG";
      case "inactivated":
        return "INACTIVATED_TAG";
      default:
        return "";
    }
  };

  return (
    <span
      className={classNames(tag, {
        [calledTag]: calledType === "called",
        [copiedTag]: calledType === "copied",
        [inactivatedTag]: calledType === "inactivated",
      })}
      data-testid="phone-rank-tag"
    >
      {t(`PHONE_RANKING.${getText(calledType)}`)}
    </span>
  );
}

export default PhoneRankingTag;
