import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";

import axiosInstance from "../../../config/axios/axiosInstance";
import SearchResponseToSearchConverter from "../../../converters/search/SearchResponseToSearchConverter";
import { GROW_BFF_SERVICE } from "../../../config/services";
import { getApiHost } from "../../host/HostService";
import { SearchDomain, SearchRequest, SearchResponse } from "../domains/Search";

export const SEARCH_BFF = "/catalog/search";

axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function SearchBFFCatalog(
  request: SearchRequest,
): Promise<SearchDomain> {
  const log = useLogService();

  const endpoint = `${getApiHost(GROW_BFF_SERVICE)}/v1/accounts/${
    request.accountId
  }${SEARCH_BFF}`;

  const projections = [
    "PRICE",
    "AVAILABILITY",
    "VARIANTS",
    "ASSORTMENT",
    "SINGLE_PROMOTION",
  ];

  const queryParams = {
    page: request.page,
    pageSize: request.pageSize,
    query: request.valueTyped,
    projection: projections.join(","),
  };

  return axiosInstance
    .get<SearchResponse>(endpoint, {
      params: queryParams,
      headers: {
        vendorId: request.vendorId,
      },
    })
    .then((response) => {
      log.info(`Convert Search received from ${endpoint} to our model`);
      return SearchResponseToSearchConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
