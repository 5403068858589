/* istanbul ignore file */
import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      height: 131,
      width: 250,
      backgroundColor: "#212121",
      marginBottom: 11,
      display: "flex",

      flexDirection: "column",
      justifyContent: "center",
    },
    button: {
      color: "#0371e2 !important",
      backgroundColor: "#212121",
      textDecoration: "underline",
      height: 12,
      fontSize: "12px !important",
      padding: 0,
    },
    buttonContainer: {
      marginBottom: 14,
      marginLeft: 38,
    },
    carousel: {
      height: 90,
      margin: "5px 28px 0px 17px",
      display: "flex",
      alignItems: "center",

      "& div": {
        display: "flex",
        alignItems: "center",
      },
    },
    carouselItem: {
      marginLeft: 21,
      alignItems: "baseline",
      flexDirection: "column",
    },

    carouselButton: {
      width: 12,
      height: 12,
    },

    arrowIconsActive: {
      color: "#ffffff",
      width: 12,
      height: 12,
      marginTop: 22,
    },
    arrowIconsInactive: {
      color: "#7a7a7a",
      width: 12,
      height: 12,
      marginTop: 22,
    },

    phone: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.5,
      color: "#f2f2f2",
      fontFamily: "Work Sans",
      alignSelf: "start",
    },

    infos: {
      fontSize: 12,
      lineHeight: 1.5,
      color: "#f2f2f2",
      fontFamily: "Work Sans",
      alignSelf: "start",
    },

    phoneTitle: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.5,
      color: "#f2f2f2",
      fontFamily: "Work Sans",
      alignSelf: "baseline",
      marginBottom: 5,
    },

    carouselItems: {
      display: "flex",
      flexDirection: "column",
      alignItems: "baseline",
    },

    noPhones: {
      fontFamily: "Work Sans",
      textAlign: "center",
      marginTop: 20,
      width: 180,
      marginLeft: 10,
      color: "#FFFFFF",
    },

    error: {
      marginLeft: 28,
    },

    loading: {
      display: "flex",
      justifyContent: "center",
    },
  }),
);
