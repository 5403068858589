import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useStore } from "effector-react";
import { duration } from "moment";
import Timer from "react-compound-timer";

import { Clock } from "@hexa-ui/icons";

import { getResponseDate } from "../../../../../config/utils/functions";
import {
  TLP_CTI,
  TLP_CTI_ADMIN,
  isFeatureEnabled,
} from "../../../../../config/featureToggles";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import useWebSocket from "../../callTimerInfo/hooks/useWebSocket";
import CallDurationStyles from "./CallStatus.styles";

const CallStatus: React.FC<{ hasMission?: boolean }> = ({
  hasMission,
}): JSX.Element => {
  const classes = CallDurationStyles();
  const { t } = useTranslation();

  const { isConnected } = useWebSocket();
  const agentCallStates = useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);

  const ctiEnabled = isFeatureEnabled(TLP_CTI, TLP_CTI_ADMIN, user.zone);

  const callDurationLabelText = `${t("NEWCallSummary.CALL_DURATION_LABEL")}:`;
  const callStatusLabelText = `${t("NEWCallSummary.CALL_STATUS_LABEL")}:`;

  const callStatusValue = useMemo(() => {
    return isConnected ? "Online" : "Offline";
  }, [isConnected]);

  const getValue = (value: number) => (value < 10 ? `0${value}` : value);

  const initialTimerHandler = useCallback(() => {
    const time = getResponseDate();
    const updatedInitialTime = duration(
      Math.abs(time?.diff(agentCallStates.startCall)),
    );

    return updatedInitialTime?.asMilliseconds() ?? 0;
  }, [agentCallStates.startCall]);

  return (
    <div
      className={classes.callDurationContainer}
      data-testid="call-duration-container"
      style={hasMission ? { justifyContent: "center" } : undefined}
    >
      <Clock size="xlarge" fill="solid" />

      <div
        className={classes.callDurationInfoContainer}
        data-testid="call-duration-info-container"
      >
        <div
          className={classes.callDurationInfoDetailsContainer}
          data-testid="call-duration-info-details-container-duration"
        >
          <span data-testid="call-duration-info-duration-label">
            {callDurationLabelText}
          </span>
          <span>
            <Timer
              initialTime={initialTimerHandler()}
              formatValue={(value) => {
                /* istanbul ignore next */
                return `${getValue(value)}`;
              }}
              startImmediately={initialTimerHandler() > 0}
              data-testid="call-duration-info-duration-value"
            >
              <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />
            </Timer>
          </span>
        </div>

        {ctiEnabled && (
          <div
            className={classes.callDurationInfoDetailsContainer}
            data-testid="call-duration-info-details-container-status"
          >
            <span data-testid="call-duration-info-status-label">
              {callStatusLabelText}
            </span>
            <span
              className={classNames({
                [classes.onlineValue]: callStatusValue === "Online",
                [classes.offlineValue]: callStatusValue === "Offline",
              })}
              data-testid="call-duration-info-status-value"
            >
              {callStatusValue}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CallStatus;
