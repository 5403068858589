import axiosRetry from "axios-retry";
import { useLogService } from "admin-portal-shared-services";
import qs from "qs";
import axios from "../../../config/axios/axiosInstance";
import { FlexibleMissionsResponseToMissionsConfigConverter } from "../../../converters/flexibleMissions/FlexibleMissionsResponseToMissionsConfigConverter";
import { LocalGlobalFilterEnum } from "../../../config/constants";
import { FlexibleMissionsConfigObject } from "../../../domains/flexibleMission/FlexibleMissionsConfigObject";
import { getApiHost } from "../../host/HostB2BService";
import GlobalAdminConfigStore from "../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import { getCountryByToken } from "../../../config/utils/functions";
import {
  isFeatureEnabledV2,
  GROW_TECH_SEARCHPOC_BY_USERCONFIG_BEESGR_16675,
} from "../../../config/featureToggles";

const ENDPOINT =
  "/grow-flexible-missions-service/grow-flexible-missions/missions/search-by-poc";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

interface HeaderParams {
  requestTraceId: string;
  authorization: string;
  country: string;
}

export const getMissionsPriorityBySearchByPoc = async (
  vendorAccountId: string,
  vendorId?: string,
  headers?: HeaderParams,
): Promise<FlexibleMissionsConfigObject> => {
  const log = useLogService();

  const isFilterByUserOrBuConfig = isFeatureEnabledV2(
    GROW_TECH_SEARCHPOC_BY_USERCONFIG_BEESGR_16675,
    getCountryByToken(),
  );

  const url = `${getApiHost()}${ENDPOINT}`;

  const vendorIdParam = { vendorId };

  let params: {
    accountId: string;
    localGlobalFilter: LocalGlobalFilterEnum;
    vendorId?: string;
    buConfigPriority?: Array<string>;
    userConfigPriority?: Array<string>;
  } = {
    accountId: vendorAccountId,
    localGlobalFilter: LocalGlobalFilterEnum.BOTH,
    ...(vendorId ? vendorIdParam : {}),
  };

  if (isFilterByUserOrBuConfig) {
    const { buConfig, userConfig } = GlobalAdminConfigStore.getState();

    params = {
      ...params,
      buConfigPriority: buConfig.missionPriority,
      userConfigPriority: userConfig.missionPriority,
    };
  }

  const getHeaders = () => {
    if (!headers) return {};
    return headers;
  };

  try {
    const response = await axios.get(url, {
      params,
      headers: getHeaders(),
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    log.info(`Convert Missions received from ${url} to our model`);
    return FlexibleMissionsResponseToMissionsConfigConverter(response.data);
  } catch (error) {
    log.error(error);
    throw error;
  }
};
