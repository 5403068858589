import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    selectOutline: {
      height: "32px",
      color: "#212121",
      width: "100%",
      "& fieldset": {
        borderColor: "#dfdede",
        borderRadius: 16,
        borderWidth: "1px !important",
      },
    },
    select: {
      padding: "0px 10px 0px 10px",
      boxSizing: "content-box",
      display: "flex",
      alignItems: "center",
      borderRadius: 16,
      backgroundColor: "white",
      height: "100%",
    },
    focusedSelect: {
      "&:focus": {
        backgroundColor: "white",
        borderRadius: 16,
        height: "100%",
      },
    },
    errorMessage: {
      fontSize: "10px",
      color: "#e02020",
    },
    selectItem: {
      color: "#212121",
    },
    menuItemText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    emptyInput: {
      color: "#707372",
      fontWeight: 500,

      "&:focus": {
        backgroundColor: "white",
        borderRadius: 16,
        height: "100%",
      },
    },
  }),
);
