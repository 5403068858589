import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      paddingTop: "16px",
    },
    titleContainer: {
      width: "222px",
      borderRadius: "8px 0 0 8px",
      padding: " 5px 0px 5px 7px",
      marginLeft: "21px",
      backgroundColor: "#343434",
    },
    dataContainer: {
      paddingTop: "16px",
      marginLeft: "28px",
    },
    itemContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      paddingTop: "4px",
    },
    oldItemContainer: {
      paddingTop: "12px",
    },
    itemContainerAccumulativeDiscount: {
      display: "flex",
      flexFlow: "row nowrap",
      paddingTop: "8px",
    },
    text: {
      fontFamily: "Work Sans",
      fontSize: "12px",
      lineHeight: "18px",
      color: "rgba(255,255,255,0.95)",
      width: "100%",
    },
    boldText: {
      fontWeight: 600,
    },
    value: {
      letterSpacing: "0.03px",
      textAlign: "right",
    },
    spacement: {
      marginTop: "12px",
    },
    divider: {
      marginTop: "12px",
      marginBottom: "12px",
      backgroundColor: "#979797",
    },
  }),
);
