import React from "react";
import { useStore } from "effector-react";
import { Box } from "@material-ui/core";
import CallTimerInfo from "../callTimerInfo/CallTimerInfo";
import CallInfoStyles from "./CallInfoStyles";
import {
  TAB_GAP_ROUTE,
  TAB_CUSTOMER_ROUTE,
} from "../../../../config/constants";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";

const CallInfo: React.FunctionComponent = () => {
  const classes = CallInfoStyles();
  const { callTab } = useStore(CallTabStore);

  return (
    <>
      <Box
        className={
          callTab === TAB_GAP_ROUTE || callTab === TAB_CUSTOMER_ROUTE
            ? classes.callInfoMissions
            : classes.callInfo
        }
      >
        <CallTimerInfo />
      </Box>
    </>
  );
};

export default CallInfo;
