import { ProductVariants } from "@domains/Catalog";
import { Select } from "@hexa-ui/components";
import React from "react";
import {
  PackageDescriptionContainer,
  PackageDividerContainer,
  PackageNameContainer,
} from "./VariantsSelect.styles";

interface VariantsSelectProps {
  sortedProductsBySalesRanking: ProductVariants[];
  selectedVariant: ProductVariants;
  handleChangeVariant: (platformId: string) => void;
}

const VariantsSelect = ({
  sortedProductsBySalesRanking,
  selectedVariant,
  handleChangeVariant,
}: VariantsSelectProps): JSX.Element => {
  return (
    <Select.Root
      onChange={handleChangeVariant}
      value={selectedVariant.platformId}
      shape="square"
      size="small"
    >
      {sortedProductsBySalesRanking.map((product: ProductVariants) => (
        <Select.Option key={product.platformId} value={product.platformId}>
          <PackageNameContainer>
            <Select.Label>
              {product.unitOfMeasurement ?? product.packageId}
            </Select.Label>
          </PackageNameContainer>

          {product.fullPackageDescription && (
            <>
              <PackageDividerContainer> - </PackageDividerContainer>

              <PackageDescriptionContainer>
                {product.fullPackageDescription}
              </PackageDescriptionContainer>
            </>
          )}
        </Select.Option>
      ))}
    </Select.Root>
  );
};

export default VariantsSelect;
