import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";

import { TLP_ACCOUNT_SERVICE } from "../../../config/services";
import VendorStore from "../../../stores/vendor/VendorStore";
import { getApiHost, getVendorIdParam } from "../../host/HostService";
import { SendCustomerNotesServiceV2Request } from "../domains/SendCustomerNotesServiceV2Request";

const ACCOUNT_SERVICE = "/tlp-account";
const ACCOUNTS_NOTES = "/v2/accounts/notes";

export async function SendCustomerNotesServiceV2(
  body: SendCustomerNotesServiceV2Request,
): Promise<void> {
  const log = useLogService();
  const { vendor } = VendorStore.getState();

  const vendorId = vendor?.id;

  const apiHost = getApiHost(TLP_ACCOUNT_SERVICE);
  const vendorIdParam = getVendorIdParam(vendorId);

  const url = `${apiHost}${ACCOUNT_SERVICE}${ACCOUNTS_NOTES}${vendorIdParam}`;

  try {
    await axios.post(`${url}`, body);
    log.info(`${url} returned success`);
  } catch (error) {
    log.error(error);
    throw error;
  }
}
