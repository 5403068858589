import React, { useEffect } from "react";

/* istanbul ignore next */
export const useWindowResize = (
  firstWindowWidth: number | undefined,
): number | undefined => {
  const [windowWidth, setWindowWidth] = React.useState(firstWindowWidth);

  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setWindowWidth((event?.target as Window)?.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", (event) => {
        setWindowWidth((event?.target as Window)?.innerWidth);
      });
    };
  }, []);

  return windowWidth;
};
