import { createStore } from "effector";
import * as CartComboEvents from "./CartComboEvents";
import CartComboState from "./CartComboState";
import { CartComboItem } from "../../domains/cart/CartComboItem";

const initialState = <CartComboState>{
  done: false,
  error: false,
  cartComboItems: [],
};

const mergeItems = (
  cartComboItem: CartComboItem,
  newCartComboItems: Array<CartComboItem>,
) => {
  const maxQuantity = 9999;
  const index = findItemIndex(newCartComboItems, cartComboItem);

  let totalQuantity = 0;

  if (index !== -1) {
    const item = { ...newCartComboItems[index] };
    const quantity = Number(item.itemQuantity);
    /* istanbul ignore next */
    if (quantity + (cartComboItem.itemQuantity || 0) <= maxQuantity) {
      totalQuantity = quantity + (cartComboItem.itemQuantity || 0);
    } else {
      totalQuantity = maxQuantity;
    }
    item.itemQuantity = totalQuantity;
    newCartComboItems[index] = { ...item };
  } else if (cartComboItem.originalQuantity) {
    newCartComboItems.push(cartComboItem);
  } else {
    cartComboItem.originalQuantity = cartComboItem.itemQuantity;
    newCartComboItems.push(cartComboItem);
  }
};

const findItemIndex = (
  cartComboItems: Array<CartComboItem>,
  each: CartComboItem,
) => {
  return cartComboItems.findIndex((eachCartItems) => {
    if (eachCartItems.id === each.id) return true;

    return (
      eachCartItems.platformId && eachCartItems.platformId === each.platformId
    );
  });
};

const cartComboStore = createStore(initialState)
  .on(
    CartComboEvents.updateComboQuantity,
    (state: CartComboState, itemQuantity: CartComboItem) => {
      const newCartItems = [...state.cartComboItems];
      const index = newCartItems.findIndex(
        (item) =>
          item.id === itemQuantity.id ||
          (item.platformId && item.platformId === itemQuantity.platformId),
      );

      newCartItems[index] = itemQuantity;
      newCartItems[index].updated = false;

      return {
        ...state,
        cartComboItems: [...newCartItems],
      };
    },
  )
  .on(
    CartComboEvents.removeItem,
    (
      state: CartComboState,
      cartComboItem: CartComboItem & {
        toggle?: boolean;
      },
    ) => {
      const newCartItems = [...state.cartComboItems];
      /* istanbul ignore next */
      if (cartComboItem.toggle) {
        const newComboAux = newCartItems.map((item) => {
          if (item.platformId === cartComboItem.platformId) {
            item.itemQuantity = 0;
          }
          return item;
        });

        return {
          ...state,
          cartComboItems: [...newComboAux],
        };
      }

      const index = newCartItems.findIndex(
        (item) => item.id === cartComboItem.id,
      );

      if (index !== -1) {
        newCartItems.splice(index, 1);
      }

      return {
        ...state,
        cartComboItems: [...newCartItems],
      };
    },
  )
  .on(
    CartComboEvents.addItem,
    (state: CartComboState, cartComboItem: CartComboItem) => {
      const newCartItems = [...state.cartComboItems];
      mergeItems(cartComboItem, newCartItems);

      return {
        ...state,
        cartComboItems: [...newCartItems],
      };
    },
  )
  .on(
    CartComboEvents.addCombosSimulation,
    (state: CartComboState, listCombos: Array<CartComboItem>) => {
      return {
        ...state,
        cartComboItems: [...listCombos],
      };
    },
  )
  .on(CartComboEvents.clearComboCart, (state: CartComboState) => ({
    ...state,
    cartComboItems: [],
  }))
  .reset(CartComboEvents.resetCombos);

export default cartComboStore;
