import React, { useState } from "react";

import { Divider, Typography } from "@material-ui/core";
import { useStore } from "effector-react";

import { CURRENCY } from "../../../../../../config/currency";
import {
  TLP_SERVICE_CATALOG_FIXED_PRICE,
  TLP_SERVICE_CATALOG_VARIABLE_PRICE,
  isFeatureEnabledV2,
  TLP_SERVICE_CONTENT_BELOW_CARD,
} from "../../../../../../config/featureToggles";
import ProcessedServiceMission from "../../../../../../domains/servicesMissions/ProcessedServiceMission";
import GlobalStore from "../../../../../../stores/global/GlobalStore";
import IconX from "../icons/IconX";
import PresetCalculator from "../presetCalculator/PresetCalculator";
import VariablePrice, {
  PayWithProps,
} from "../VariablePrice/VariablePriceComponent";
import ServicesContentStyle from "./ServicesContentStyle";

interface Props {
  title: string;
  line: number;
  serviceMission: ProcessedServiceMission;
  notEnrolled: boolean;
  loyaltyProgramValue: number | undefined;
  onCloseCallback: () => void;
}

const ServiceContent: React.FC<Props> = (props) => {
  const {
    title,
    line,
    serviceMission,
    notEnrolled,
    loyaltyProgramValue,
    onCloseCallback,
  } = props;

  const { user } = useStore(GlobalStore);

  const serviceContentBelowCard = isFeatureEnabledV2(
    TLP_SERVICE_CONTENT_BELOW_CARD,
    user.keyToggle,
  );

  const [variablePrice, setVariablePrice] = useState<BigInt>(BigInt(0));
  const [payWith, setPayWith] = useState<PayWithProps>({
    type: "points",
    currency: "",
  });

  const isServiceFixedPriceEnabled = isFeatureEnabledV2(
    TLP_SERVICE_CATALOG_FIXED_PRICE,
    user.keyToggle,
  );

  const isServiceVariablePriceEnabled = isFeatureEnabledV2(
    TLP_SERVICE_CATALOG_VARIABLE_PRICE,
    user.keyToggle,
  );

  const classes = ServicesContentStyle();

  const remainingBalance = loyaltyProgramValue
    ? loyaltyProgramValue - serviceMission.presetValue
    : undefined;

  const remainingVariableBalance = loyaltyProgramValue
    ? loyaltyProgramValue - Number(variablePrice.valueOf())
    : undefined;

  const variablePriceRender = () => {
    if (
      isServiceVariablePriceEnabled &&
      serviceMission.pricingMethod === "VARIABLE"
    )
      return (
        <>
          <VariablePrice
            getVariablePrice={setVariablePrice}
            getPayWith={setPayWith}
          />
          <PresetCalculator
            notEnrolled={notEnrolled}
            pricingPresetValue={variablePrice}
            payWith={payWith}
            pricingMethod="Services Variable Price"
            remainingBalance={remainingVariableBalance}
          />
        </>
      );

    return <></>;
  };

  const princingMethodCalculator = () => {
    if (isServiceFixedPriceEnabled && serviceMission.pricingMethod === "PRESET")
      return (
        <PresetCalculator
          notEnrolled={notEnrolled}
          pricingPresetValue={serviceMission.presetValue}
          pricingMethod="Services Fixed Price"
          payWith={{
            type: "points",
            currency: CURRENCY[user.zone].prefix,
          }}
          remainingBalance={remainingBalance}
        />
      );

    return <></>;
  };

  return (
    <>
      <div
        className={classes.rectangle}
        data-testid="service-content"
        style={
          serviceContentBelowCard
            ? {
                gridRowStart: line + 1,
                gridRowEnd: line + 2,
                gridColumnStart: 1,
                gridColumnEnd: 5,
              }
            : { marginTop: "20px" }
        }
      >
        <div className={classes.header}>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
          <button
            className={classes.closingX}
            onClick={onCloseCallback}
            type="button"
            data-testid="service-content-close-button"
          >
            <IconX />
          </button>
        </div>

        <div className={classes.body}>
          <div className={classes.description}>
            {serviceMission.description}
          </div>

          <Divider orientation="vertical" variant="middle" flexItem />

          <div className={classes.calculator}>
            {variablePriceRender()}

            {princingMethodCalculator()}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceContent;
