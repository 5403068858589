import React, { useState, useMemo } from "react";
import { useStore } from "effector-react";
import { Typography } from "@material-ui/core";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import CustomerNotesStore from "../../../../../../stores/customerNotes/CustomerNotesStore";
import Button from "../../../../../../components/button/Button";
import useStyle from "./style";
import Loading from "../../../loading/Loading";
import { getNotesCharactersLength } from "../../../../../../config/utils/functions";

const TEXTAREA_MAX_LENGTH = 200;

interface NoteProps {
  onClick: (value: string) => void;
  lastNote?: string;
  lastUpdatedEmail: string | null;
  lastUpdatedDate: string | null;
  lastUpdatedTime: string | null;
  isGetNotesLoading: boolean;
}

const Note: React.FC<NoteProps> = ({
  onClick,
  lastUpdatedDate,
  lastUpdatedTime,
  lastNote,
  lastUpdatedEmail,
  isGetNotesLoading,
}) => {
  const [notes, setNotes] = useState("");
  const [isNoteChanged, setIsNotChanged] = useState(false);
  const { isLoadingSendNotes } = useStore(CustomerNotesStore);
  const { t } = useTranslation();
  const classes = useStyle();

  const handleNotes = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIsNotChanged(true);
    setNotes(event.target?.value);
  };

  const onClickNotes = () => {
    if (lastNote && !isNoteChanged) {
      setNotes(lastNote);
    }
  };

  const getPlaceholder = useMemo(() => {
    if (lastNote) {
      return !isNoteChanged ? `“${lastNote}”` : "";
    }

    return lastUpdatedEmail ? "" : t("CUSTOMER_PAGE.FIRST_NOTE");
  }, [lastUpdatedEmail, lastNote, isNoteChanged, t]);

  /* istanbul ignore next */
  const getNotes = (lastNoteValue) => {
    if (isNoteChanged) return notes;

    return lastNoteValue;
  };

  return isGetNotesLoading ? (
    <Loading />
  ) : (
    <div className={classes.container}>
      <div className={classes.dateTime}>
        <Typography
          variant="h3"
          component="span"
          data-testid="customer-notes-date"
        >
          {lastUpdatedDate &&
            Moment(lastUpdatedDate).format(t("Formater.DATE_FORMATTER"))}
        </Typography>
        <Typography
          className={classes.time}
          variant="h3"
          data-testid="customer-notes-times"
        >
          {lastUpdatedTime}
        </Typography>
      </div>
      <div className={classes.textareaContainer}>
        <textarea
          id="customer-notes-textarea"
          name="customerNotes"
          placeholder={getPlaceholder}
          className={classes.textarea}
          value={notes}
          onChange={handleNotes}
          onClick={onClickNotes}
          maxLength={TEXTAREA_MAX_LENGTH}
          data-testid="customer-notes-textarea"
        />
        <div className={classes.textareaInfos}>
          <Typography
            variant="h3"
            component="span"
            data-testid="customer-notes-last-updated-by"
          >
            {lastUpdatedEmail &&
              `${t("CUSTOMER_PAGE.LAST_UPDATED_BY")} ${lastUpdatedEmail}`}
          </Typography>
          <Typography
            variant="h3"
            component="span"
            data-testid="customer-notes-textarea-length"
          >
            {getNotesCharactersLength(notes, isNoteChanged, lastNote)}/
            {TEXTAREA_MAX_LENGTH}
          </Typography>
        </div>
      </div>
      <div>
        <Button
          className={classes.registerNoteBtn}
          onClick={() => onClick(getNotes(lastNote))}
          data-testid="customer-notes-update-btn"
          loading={isLoadingSendNotes}
          variant="outlined"
        >
          {t("CUSTOMER_PAGE.UPDATE_NOTE_BTN")}
        </Button>
      </div>
    </div>
  );
};

Note.defaultProps = {
  lastNote: "",
};

export default Note;
