import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    callInfo: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 0 15px 0px",
    },
    callInfoMissions: {
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "23px",
    },
  }),
);
