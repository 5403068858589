import React, { useEffect, useState } from "react";
import classNames from "classnames";
import QuantityInputStyles from "./QuantityControlStyles";
import PlusIconControl from "../../assets/PlusIconControl";
import MinusIconControl from "../../assets/MinusIconControl";

interface IQuantityControlProps {
  value: number;
  onChange: (value: number) => void;
  testId: string;
  maxValue?: number;
  minValue?: number;
  isOrderVisualization?: boolean;
  quantityMultiplier?: number;
  setItemQuantity: (itemQuatity: number) => void;
}

const QuantityControl: React.FunctionComponent<IQuantityControlProps> = ({
  value,
  testId,
  maxValue = 9999,
  minValue = 1,
  isOrderVisualization = false,
  quantityMultiplier = 1,
  onChange,
  setItemQuantity,
}) => {
  const classes = QuantityInputStyles();
  const [tempValue, setTempValue] = useState(value);

  const roundConsideringMultiplier = (
    value: number,
    multiplier: number,
    roundDown = false,
  ) => {
    return (
      (roundDown ? Math.floor : Math.round)(value / multiplier) * multiplier
    );
  };

  const calculatedMaxValue = roundConsideringMultiplier(
    maxValue,
    quantityMultiplier,
    true,
  );

  useEffect(() => {
    setTempValue(value);

    /* istanbul ignore else */
    if (setItemQuantity) setItemQuantity(value);
  }, [value, setItemQuantity]);

  const isMinusButtonDisabled = value <= minValue;
  const isPlusButtonDisabled = value >= calculatedMaxValue;
  const isInputDisabled = isMinusButtonDisabled && isPlusButtonDisabled;

  const handleChange = (newValue: number) => {
    let alteredValue = Math.round(newValue);

    if (alteredValue < minValue) alteredValue = minValue;
    if (alteredValue > calculatedMaxValue) alteredValue = calculatedMaxValue;

    alteredValue = roundConsideringMultiplier(alteredValue, quantityMultiplier);

    onChange(alteredValue);

    /* istanbul ignore else */
    if (setItemQuantity) setItemQuantity(alteredValue);

    setTempValue(alteredValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputedNumber = Number(e.target.value);

    if (inputedNumber > calculatedMaxValue) {
      setTempValue(calculatedMaxValue);
      return;
    }

    setTempValue(Number(e.target.value));
  };

  const validateKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleChange(tempValue);
      return;
    }

    const allowedControlKeys = [
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "Backspace",
      "Delete",
    ];
    if (allowedControlKeys.includes(event.key)) {
      return;
    }

    const isNumber = /^\d$/;
    if (!isNumber.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div
      className={classNames(
        classes.boxContent,
        isOrderVisualization
          ? classes.orderViewBoxContentSize
          : classes.defaultBoxContentSize,
      )}
      data-testid={`${testId}-container`}
    >
      <button
        type="button"
        className={classNames(
          classes.iconCircle,
          isMinusButtonDisabled && classes.buttonDisabled,
        )}
        onClick={() => {
          handleChange(Number(value) - quantityMultiplier);
        }}
        data-testid={`${testId}-minus-icon`}
      >
        <MinusIconControl />
      </button>

      <input
        type="text"
        value={tempValue}
        onKeyDown={(event) => validateKeyPress(event)}
        onBlur={() => handleChange(tempValue)}
        onChange={(e) => handleInputChange(e)}
        className={classNames(
          classes.quantity,
          isInputDisabled && classes.inputDisabled,
        )}
        data-testid={`${testId}-input`}
      />

      <button
        type="button"
        className={classNames(
          classes.iconCircle,
          isPlusButtonDisabled && classes.buttonDisabled,
        )}
        onClick={() => {
          handleChange(Number(value) + quantityMultiplier);
        }}
        data-testid={`${testId}-plus-icon`}
      >
        <PlusIconControl />
      </button>
    </div>
  );
};

export default QuantityControl;
