import { createStore } from "effector";
import {
  getSearchItems,
  resetSearchItems,
  getSearchMoreItems,
} from "./SearchApiCatalogEvents";
import { SearchCatalogState } from "./SearchCatalogState";

const initialState = <SearchCatalogState>{
  loading: false,
  loadingSearchMore: false,
  done: false,
  doneMoreItems: false,
  error: false,
  errorSearchMore: false,
  totalElements: 0,
  totalPages: 0,
  firstNewItem: 0,
  searchItems: [],
};

const SearchCatalogStore = createStore(initialState)
  .on(getSearchItems, (state) => ({
    ...state,
    error: false,
    done: false,
    loading: true,
  }))
  .on(getSearchItems.done, (state: SearchCatalogState, { result }) => {
    return {
      ...state,
      done: true,
      error: false,
      loading: false,
      totalElements: result.pagination.totalElements,
      totalPages: result.pagination.totalPages,
      firstNewItem: 0,
      searchItems: [...result.items],
    };
  })
  .on(getSearchItems.fail, (state: SearchCatalogState) => ({
    ...state,
    done: true,
    error: true,
    loading: false,
  }))
  .on(getSearchMoreItems, (state) => ({
    ...state,
    error: false,
    done: false,
    loadingSearchMore: true,
    errorSearchMore: false,
  }))
  .on(getSearchMoreItems.done, (state: SearchCatalogState, { result }) => {
    const items = state.searchItems.concat(result.items);
    return {
      ...state,
      done: true,
      error: false,
      loading: false,
      doneMoreItems: true,
      loadingSearchMore: false,
      totalElements: result.pagination.totalElements,
      totalPages: result.pagination.totalPages,
      firstNewItem: state.searchItems.length,
      searchItems: [...items],
    };
  })
  .on(getSearchMoreItems.fail, (state) => {
    return {
      ...state,
      error: false,
      done: false,
      loadingSearchMore: false,
      errorSearchMore: true,
    };
  })
  .reset(resetSearchItems);

export default SearchCatalogStore;
