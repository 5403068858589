import TargetsKPI from "@domains/agentKPI/TargetsKPI";
import TargetsKPIDomain from "@domains/agentKPI/TargetsKPIDomain";
import TargetsKPIResponse from "@services/agentKPI/domains/TargetsKPIResponse";

export default function TargetsKPIResponseToTargetsKPIConverter(
  data?: TargetsKPIResponse,
): TargetsKPIDomain | undefined {
  if (!data) return undefined;
  const targets = <TargetsKPI>{
    id: data.id,
    targetCallList: data.targetCallList,
    targetAverageHandleTime: data.targetAverageHandleTime,
    targetToplineUplift: data.targetToplineUplift,
    monthWorkDays: data.monthWorkDays,
    country: data.country,
    vendorId: data.vendorId,
  };

  return <TargetsKPIDomain>{
    targets,
  };
}
