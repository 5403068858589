import { createEffect } from "effector";
import { getLoyaltyRewardsScoreV2 } from "../../services/loyaltyBusinessRewards/LoyaltyBusinessRewardsService";
import * as LoyaltyProgramUseCase from "../../usecase/loyaltyProgram/LoyaltyProgramUseCase";

export const getRewardsEffect = createEffect({
  async handler(accountId: string) {
    return LoyaltyProgramUseCase.execute(accountId);
  },
});

export const getRewardsEffectV2 = createEffect({
  async handler(accountId: string) {
    return getLoyaltyRewardsScoreV2(accountId);
  },
});
