import { Item } from "../../../services/catalog/bff/domains/CatalogBFFResponse";
import Catalog, {
  CatalogItem,
  ItemContainer,
  Price,
  PriceDetails,
  PriceDetailsItem,
} from "../../../domains/Catalog";
import MarketplaceStore from "../../../stores/marketplace/MarketplaceStore";
import { hasMarketplace } from "../../../config/utils/functions";

const CatalogResponseToCatalogConverterBFF = (items: Item[]): Catalog => {
  const marketplaceState = MarketplaceStore.getState();

  const { marketplaceList } = marketplaceState;

  const catalogItems = items.map((item) => {
    const itemData = item.item;
    const vendorItemId = item.vendorItemId ?? itemData.sku;
    const isMarketplace = hasMarketplace(vendorItemId, marketplaceList);

    return {
      pricePerUoM: item.price.priceDetails?.uom?.price ?? 0,
      sku: itemData.sku,
      price: item.price.price,
      originalPrice: item.price.originalPrice,
      discountedPrice: 0,
      stockAvailable: item.inventory.stockAvailable,
      name: itemData.itemName,
      image: itemData.itemImage,
      brandName: itemData.brandName,
      classification: itemData.classification,
      itemQuantity: 0,
      packagename: itemData.package?.name ?? "",
      variant: itemData.variant,
      packageId: itemData.package?.packageId,
      marketplace: isMarketplace,
      inventoryCount: item.inventory.inventoryCount,
      availabilityCount: item.availability.count,
      palletQuantity: itemData.palletQuantity,
      fullPackageDescription: itemData.package?.fullPackageDescription ?? "",
      fullContainerDescription: itemData.container.fullContainerDescription,
      id: itemData.id,
      vendorItemId,
      container: <ItemContainer>{
        name: itemData.container.name,
        returnable: itemData.container.returnable,
        unitOfMeasurement: itemData.container.unitOfMeasurement,
        itemSize: itemData.container.itemSize,
        fullContainerDescription: itemData.container.fullContainerDescription,
        material: itemData.container.material,
      },
      priceData: <Price>{
        pricePerUoM: item.price.priceDetails?.uom?.price ?? 0,
        pricePerContainer: item.price.pricePerContainer,
        price: item.price.price,
        priceDetails: <PriceDetails>{
          pack: <PriceDetailsItem>{
            preferredPrice:
              item.price.priceDetails?.pack?.preferredPrice ?? false,
            price: item.price.priceDetails?.pack?.price ?? 0,
          },
          container: <PriceDetailsItem>{
            preferredPrice:
              item.price.priceDetails?.container?.preferredPrice ?? false,
            price: item.price.priceDetails?.container?.price ?? 0,
          },
          uom: <PriceDetailsItem>{
            preferredPrice:
              item.price.priceDetails?.uom?.preferredPrice ?? false,
            price: item.price.priceDetails?.uom?.price ?? 0,
          },
        },
      },
      platformId: item.platformId,
    } as CatalogItem;
  });

  return {
    items: catalogItems,
  };
};

export { CatalogResponseToCatalogConverterBFF };
