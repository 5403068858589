import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "../../../../components/modal/Modal";
import modalStopAutoDialerStyles from "./ModalStopAutoDialerStyles";
import Button from "../../../../components/button/Button";

interface Props {
  open: boolean;
  close: () => void;
  onConfirm: () => void;
}

const ModalStopAutoDialer: React.FC<Props> = ({ open, close, onConfirm }) => {
  const classes = modalStopAutoDialerStyles({});
  const { t } = useTranslation();
  const description = t("HomeMainPage.MODAL_STOP_CALLING.DESCRIPTION");
  const wordToHighLight = description.slice(
    description.indexOf("(") + 1,
    description.indexOf(")"),
  );
  const splicedWord = description
    .replace(")", "")
    .replace("(", "")
    .split(wordToHighLight);

  return (
    <Modal open={open} onClose={close} borderRadius="16px">
      <div className={classes.containerModal}>
        <header className={classes.modalHeaderContainer}>
          <Typography
            className={classes.modalHeaderTitle}
            variant="h2"
            data-testid="stop-calling-auto-dialer-modal-title"
          >
            {t("HomeMainPage.MODAL_STOP_CALLING.TITLE")}
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={close}
            data-testid="stop-calling-auto-dialer-modal-close-button"
          >
            <CloseIcon className={classes.icon} htmlColor="rgba(0,0,0,0.8)" />
          </IconButton>
        </header>
        <Typography
          data-testid="stop-calling-auto-dialer-description"
          className={classes.text}
          variant="body2"
        >
          {`${splicedWord[0]}`}
          <span
            className={classes.wordToHighLight}
          >{`${wordToHighLight}`}</span>
          {`${splicedWord[1]}`}
        </Typography>
        <div className={classes.containerBox}>
          <Button
            variant="outlined"
            onClick={() => close()}
            className={classes.button}
            testId="stop-calling-auto-dialer-button-cancel"
          >
            {t("HomeMainPage.MODAL_STOP_CALLING.CANCEL_BUTTON")}
          </Button>
          <Button
            color="secondary"
            className={classes.button}
            onClick={onConfirm}
            testId="stop-calling-auto-dialer-button-yes"
          >
            {t("HomeMainPage.MODAL_STOP_CALLING.STOP_BUTTON")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalStopAutoDialer;
