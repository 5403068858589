import React from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import {
  StatistcsSession,
  StatistcsSessionDescription,
  StatistcsContainer,
  StatistcsItem,
  StatistcsDescription,
} from "./RewardsChallengesStatistics.styles";
import RewardsChallengesStatisticsCompletedIcon from "../../../../../../assets/newIcons/RewardsChallengesStatisticsCompletedIcon";
import RewardsChallengesStatisticsFailedIcon from "../../../../../../assets/newIcons/RewardsChallengesStatisticsFailedIcon";
import RewardsChallengesStatisticsStartedIcon from "../../../../../../assets/newIcons/RewardsChallengesStatisticsStartedIcon";

interface RewardsChallengesStatisticsInterface {
  started: number;
  failed: number;
  completed: number;
  isLoading: boolean | undefined;
}

const RewardsChallengesStatistics = ({
  started,
  failed,
  completed,
  isLoading,
}: RewardsChallengesStatisticsInterface): JSX.Element => {
  const { t } = useTranslation();

  const title = (
    numberValue: number,
    singleValue: string,
    pluralValue: string,
  ) => {
    if (numberValue > 1) {
      return t(`REWARDS_CHALLENGES_MISSION_TAB.${pluralValue}`);
    }

    return t(`REWARDS_CHALLENGES_MISSION_TAB.${singleValue}`);
  };

  return (
    <StatistcsContainer>
      <StatistcsSession>
        <StatistcsItem data-testId="rewards-challenges-statistics-started">
          <RewardsChallengesStatisticsStartedIcon />
          {isLoading ? (
            <Skeleton width={83} height={18} />
          ) : (
            `${started} ${title(
              started,
              "STATISTICS_STARTED",
              "STATISTICS_STARTS",
            )}`
          )}
        </StatistcsItem>
        <StatistcsItem data-testId="rewards-challenges-statistics-failed">
          <RewardsChallengesStatisticsFailedIcon />
          {isLoading ? (
            <Skeleton width={83} height={18} />
          ) : (
            `${failed} ${title(
              failed,
              "STATISTICS_FAILED",
              "STATISTICS_FAILEDS",
            )}`
          )}
        </StatistcsItem>
        <StatistcsItem data-testId="rewards-challenges-statistics-completed">
          <RewardsChallengesStatisticsCompletedIcon />
          {isLoading ? (
            <Skeleton width={83} height={18} />
          ) : (
            `${completed} ${title(
              completed,
              "STATISTICS_COMPLETED",
              "STATISTICS_COMPLETES",
            )}`
          )}
        </StatistcsItem>
      </StatistcsSession>
      <StatistcsSessionDescription>
        <StatistcsDescription data-testId="rewards-challenges-statistics-description">
          {isLoading ? (
            <Skeleton width={280} height={20} />
          ) : (
            t("REWARDS_CHALLENGES_MISSION_TAB.STATISTICS_DESCRIPTION")
          )}
        </StatistcsDescription>
      </StatistcsSessionDescription>
    </StatistcsContainer>
  );
};

export default RewardsChallengesStatistics;
