import React from "react";

function RewardsChallengesStatisticsIcon(): JSX.Element {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ marginLeft: "-6px" }}
    >
      <defs>
        <path
          d="M17.3333333,8 C18.0171145,8 18.5806762,8.51472025 18.6576964,9.1778385 L18.6666667,9.33333333 L18.6666667,22.6666667 C18.6666667,23.4030463 18.069713,24 17.3333333,24 C16.6495522,24 16.0859905,23.4852797 16.0089703,22.8221615 L16,22.6666667 L16,9.33333333 C16,8.59695367 16.5969537,8 17.3333333,8 Z M9.33333333,0 C10.0171145,0 10.5806762,0.514720254 10.6576964,1.1778385 L10.6666667,1.33333333 L10.6666667,22.6666667 C10.6666667,23.4030463 10.069713,24 9.33333333,24 C8.64955221,24 8.08599045,23.4852797 8.00897031,22.8221615 L8,22.6666667 L8,1.33333333 C8,0.596953667 8.59695367,0 9.33333333,0 Z M1.33333333,13.3333333 C2.01711445,13.3333333 2.58067621,13.8480536 2.65769636,14.5111718 L2.66666667,14.6666667 L2.66666667,22.6666667 C2.66666667,23.4030463 2.069713,24 1.33333333,24 C0.649552214,24 0.0859904524,23.4852797 0.00897030843,22.8221615 L0,22.6666667 L0,14.6666667 C0,13.930287 0.596953667,13.3333333 1.33333333,13.3333333 Z"
          id="RewardsChallengesStatisticsIcon-1"
        ></path>
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          id="2.0-Mission.-Challenges"
          transform="translate(-334.000000, -419.000000)"
        >
          <g id="Mission-content" transform="translate(333.000000, 100.000000)">
            <g id="Statistics" transform="translate(1.000000, 299.000000)">
              <g
                id="02-Icons/bar-chart-2"
                transform="translate(0.000000, 20.000000)"
              >
                <g
                  id="ZZ_Color-Override/Primary/Black"
                  transform="translate(6.500000, 4.000000)"
                >
                  <mask
                    id="RewardsChallengesStatisticsIcon-mask-2"
                    fill="white"
                  >
                    <use xlinkHref="#RewardsChallengesStatisticsIcon-1"></use>
                  </mask>
                  <g id="Mask" fill-rule="nonzero"></g>
                  <g
                    id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
                    mask="url(#RewardsChallengesStatisticsIcon-mask-2)"
                    fill="#000000"
                    fill-opacity="0.9"
                  >
                    <g
                      transform="translate(-6.500000, -4.000000)"
                      id="Rectangle"
                    >
                      <rect x="0" y="0" width="32" height="32"></rect>
                    </g>
                  </g>
                </g>
                <g id="ZZ_Color-Override/Primary/Black"></g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default RewardsChallengesStatisticsIcon;
