import { makeStyles, createStyles } from "@material-ui/core";

const MonthlyKpisStyles = makeStyles(({ palette }) =>
  createStyles({
    container: {
      borderRadius: "15px",
      paddingBottom: 10,
      flexGrow: 1,
      margin: "10px 10px 10px 0",
      backgroundColor: palette.common.white,
      boxShadow: "0 4px 5px 0 rgba(0, 0, 0, 0.16)",
    },
    headerContainer: {
      fontSize: "14px",
      backgroundColor: "white",
      padding: "6px 24px",
      fontWeight: "normal",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      border: `1px solid ${palette.grey[100]}`,
      borderRadius: "10px 10px 0px 0px",
      color: palette.text.primary,
    },
    divider: {
      color: palette.grey[200],
      width: "calc(100% - 10px)",
    },
    loadingContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }),
);

export default MonthlyKpisStyles;
