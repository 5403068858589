import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    skeletonContainer: {
      marginTop: "32px",
      paddingBottom: "8px",
      "& .tlp-MuiSkeleton-text": {
        transform: "scale(1)",
      },
    },
    tabsSkeletonSection: {
      display: "flex",
      gap: "8px",
    },
    cardContainer: {
      height: "auto",
      display: "flex",
      alignItems: "center",
      margin: "16px 0 0 0",
      boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.16)",
    },
    openedMissionContainer: {
      height: "auto",
      display: "flex",
      flexDirection: "column",
      gap: "18px",
      margin: "16px 0 0 0",
      padding: "16px",
      boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.16)",
    },
    iconSkeleton: {
      margin: "16px",
    },
    missionInfoSkeleton: {
      display: "flex",
      flexDirection: "column",
      gap: "6px",
    },
    subMissionHeader: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      width: "100%",
    },
    descriptionSkeleton: {
      width: "100%",
    },
    subMissionTitleSkeleton: {
      margin: "12px 16px",
    },
    missionProductsContainer: {
      display: "flex",
      gap: "96px",
    },
    productContainer: {
      height: "152px",
      display: "grid",
      columnGap: "16px",
      gridTemplateRows: "1fr 1fr 1fr",
      gridTemplateColumn: "106px 1fr",
    },
    productImageSkeleton: {
      gridRowStart: 1,
    },
    productTitleContainer: {
      gridRowStart: 1,
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    productTagsContainer: {
      gridRowStart: 3,
      gridColumnStart: 2,
      display: "flex",
      gap: "16px",
    },
  }),
);
