import React from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import RetryIcon from "../../../assets/RetryIcon";
import UpdateCardButtonStyles from "./UpdateCardButtonStyles";

interface Props {
  click: () => void;
}

function UpdateCardButton({ click }: Props): React.ReactElement {
  const classes = UpdateCardButtonStyles();
  const { t } = useTranslation();
  return (
    <Button
      data-testid="update-cards-kpis-button"
      className={classes.updateButton}
      onClick={click}
    >
      <span className={classes.updateButtonText}>
        {t("HomeMainPage.UPDATE_CARD_BUTTON")}
      </span>
      <div className={classes.icon}>
        <RetryIcon />
      </div>
    </Button>
  );
}

export default UpdateCardButton;
