import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    root: {
      alignSelf: "flex-start",
      backgroundColor: "white",
      color: "#505050",
      fontWeight: "bold",
      borderRadius: "6px",
      marginBottom: "10px",
      height: "1.6rem",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    },
    tooltip: {
      backgroundColor: "black",
    },
    arrow: {
      color: "black",
    },
  }),
);
