import { styled } from "@bees/vision-tokens";

const PackageNameContainer = styled("div", {
  fontWeight: "bold",
  fontSize: "0.875rem",
});

const PackageDividerContainer = styled("div", {
  margin: "0 5px",
  fontWeight: "bold",
  fontSize: "0.875rem",
});

const PackageDescriptionContainer = styled("div", {
  color: "#808080",
  fontSize: "0.75rem",
});

export {
  PackageDescriptionContainer,
  PackageDividerContainer,
  PackageNameContainer,
};
