import { useLogService } from "admin-portal-shared-services";
import axios from "@config/axios/axiosInstance";
import { getUserInfos } from "@config/utils/functions";
import { VendorPocByAccountId } from "@domains/PocInformation";
import { GROW_BFF_SERVICE } from "../../../config/services";
import { getApiHost } from "../../host/HostService";

const POCS_ENDPOINT = "/accounts/";

export const SearchVendorPocByAccountId = (
  accountId: string,
): Promise<VendorPocByAccountId[]> => {
  const log = useLogService();

  const endpoint = `${getApiHost(
    GROW_BFF_SERVICE,
  )}${POCS_ENDPOINT}${accountId}`;

  const { country, orgId, groupId } = getUserInfos();

  return axios
    .get<VendorPocByAccountId[]>(`${endpoint}`, {
      headers: {
        country,
        orgId,
        groupId,
      },
    })
    .then((response) => {
      log.info(
        `Get poc names by vendors related to a certain accountid received from ${POCS_ENDPOINT} to our model`,
      );
      return response.data;
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
};
