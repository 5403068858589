import { makeStyles, createStyles } from "@material-ui/core";

const phoneIcon = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "28px",
  height: "28px",
  borderRadius: "100%",
  fontSize: "12px",
};

const GetNextPocButtonStyles = makeStyles(({ palette }) =>
  createStyles({
    nextPocButton: {
      width: "270px",
      height: "40px",
      borderRadius: "3px",
      display: "flex",
      cursor: "pointer",
      textTransform: "none",
      backgroundColor: palette.secondary.main,
      padding: "0px 8px 0px 16px",
      justifyContent: "space-between",
    },
    label: {
      fontSize: "16px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      fontFamily: "Barlow",
      fontWeight: "normal",
      whiteSpace: "nowrap",
    },
    nextPocButtonOptions: {
      minWidth: "203px",
      height: "40px",
      borderRadius: "3px",
      cursor: "pointer",
      textTransform: "none",
      backgroundColor: "white",
      outlineColor: "black",
      outlineStyle: "solid",
      outlineWidth: "1px",
      display: "flex",
      alignItems: "center",
      padding: "8px",
      justifyContent: "center",
    },
    labelOptions: {
      fontSize: "16px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      fontFamily: "Work Sans",
      fontWeight: 500,
      color: "black",
      whiteSpace: "nowrap",
    },
    phoneIconYellow: {
      ...phoneIcon,
      backgroundColor: "yellow",
    },
    phoneIconGreen: {
      ...phoneIcon,
      backgroundColor: palette.primary.light,
    },
  }),
);
export default GetNextPocButtonStyles;
