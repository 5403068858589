import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CheckMarkIcon from "../../assets/CheckMarkIcon";
import {
  MAX_VALUE_INPUT,
  MIN_VALUE_INPUT,
  TAB_ORDERS_ROUTE,
} from "../../config/constants";
import { setCallTab } from "../../stores/navigation/callTab/CallTabEvents";
import AddButton from "../addButton/AddButton";
import Button from "../button/Button";
import QuantityControl from "../quantityControl/QuantityControl";
import ControlledAdditionStyles from "./ControlledAddition.styles";

interface Props {
  testId: string;
  showCartLink?: boolean;
  timeToShowAdded?: number;
  minValue?: number;
  maxValue?: number;
  addAction: () => void;
  itemQuantityChangeAction: (alteredQuantity: number) => void;
  variant?: "primary" | "secondary";
  itemQuantity?: number;
  setItemQuantity: (itemQuantity: number) => void;
}

const ControlledAddition = ({
  testId,
  showCartLink,
  timeToShowAdded,
  minValue,
  maxValue,
  addAction,
  itemQuantityChangeAction,
  variant,
  itemQuantity,
  setItemQuantity,
}: Props): JSX.Element => {
  const classes = ControlledAdditionStyles();
  const [showAdded, setShowAdded] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const renderAddedText = () => {
    if (!showCartLink)
      return (
        <Typography
          variant="h3"
          data-testid={`${testId}-added-text`}
          className={classes.addedItemText}
        >
          {t("RecommendedProducts.ADDED_TO_CART")}
        </Typography>
      );

    const translation = t("RecommendedProducts.ADDED_TO_CART");

    const splitSpaces = translation.split(" ");
    const cardWord = splitSpaces[splitSpaces.length - 1];
    const Added = splitSpaces[splitSpaces.length - 3];
    const AddedTo = splitSpaces[splitSpaces.length - 2];

    return (
      <Box className={classes.inheritDivText}>
        <Typography
          variant="h3"
          data-testid={`${testId}-added-text`}
          className={classes.addedItemText}
        >
          {Added}
        </Typography>
        <Typography
          variant="h3"
          data-testid={`${testId}-added-text`}
          className={classes.addedItemSubText}
        >
          {itemQuantity}{" "}
          {itemQuantity === 1 ? t("Empties.ITEM") : t("Empties.ITEMS")}
        </Typography>
        <Typography
          variant="h3"
          data-testid={`${testId}-added-text`}
          className={classes.addedItemText}
        >
          {AddedTo}
        </Typography>
        <Button
          className={classes.addedItemTextLink}
          variant="outlined"
          onClick={() => {
            setCallTab(TAB_ORDERS_ROUTE);
            history.push({
              pathname: TAB_ORDERS_ROUTE,
            });
          }}
          data-testid={`${testId}-added-order-link`}
        >
          {cardWord}
        </Button>
      </Box>
    );
  };

  const handleAddAction = () => {
    addAction();

    setShowAdded(true);
    if (itemQuantity && minValue) {
      setItemQuantity(itemQuantity);

      setTimeout(() => {
        setItemQuantity(minValue);
        setShowAdded(false);
      }, (timeToShowAdded as number) * 1000);
    }
  };

  return (
    <Box className={classes.recommendedCardActions}>
      <Box data-test-id={testId} className={classes.addContainer}>
        {!showAdded && (
          <Box className={classes.inheritDiv}>
            <QuantityControl
              value={itemQuantity as number}
              onChange={itemQuantityChangeAction}
              testId={`${testId}-quantity-control`}
              maxValue={maxValue as number}
              minValue={minValue as number}
              setItemQuantity={setItemQuantity}
            />
            <Box className={classes.addButton}>
              <AddButton
                testId={`${testId}-add-button`}
                onClickAction={handleAddAction}
                variant={variant}
                maxValue={maxValue}
              />
            </Box>
          </Box>
        )}
        {showAdded && (
          <Box
            className={classes.inheritDiv}
            data-testid={`${testId}-added-text-container`}
          >
            <CheckMarkIcon
              height={24}
              width={24}
              data-testid={`${testId}-check-icon`}
            />
            {renderAddedText()}
          </Box>
        )}
      </Box>
    </Box>
  );
};

ControlledAddition.defaultProps = {
  showCartLink: false,
  timeToShowAdded: 4,
  minValue: MIN_VALUE_INPUT,
  maxValue: MAX_VALUE_INPUT,
};

export default ControlledAddition;
