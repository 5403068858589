import axios from "@config/axios/axiosInstance";
import { Reedeemables } from "@domains/redeem/Redeem";

import { getUserInfos } from "@config/utils/functions";
import RedeemablesResponseToRedeemablesDomainConverter from "@converters/redeemables/RedeemablesResponseToRedeemablesDomainConverter";
import { getApiHost, getEndPoint } from "../host/HostB2BService";
import { GetRedeemablesResponse } from "./model/GetRedeemablesResponse";

const REWARDS_SERVICE_ENDPOINT =
  "/rewards-business-service/redeemables/accounts";

export function getRedeemables(pocId: string): Promise<Reedeemables> {
  const { vendorId } = getUserInfos();
  const endpoint = `${getApiHost()}${getEndPoint()}${REWARDS_SERVICE_ENDPOINT}/${pocId}`;

  const headers = {
    // This api is not working properly when this header is informed
    "Accept-Language": "",
  };

  const params = {
    vendorIds: vendorId,
  };

  return axios
    .get<GetRedeemablesResponse>(endpoint, {
      params,
      headers,
    })
    .then((response) => {
      return RedeemablesResponseToRedeemablesDomainConverter(response?.data);
    });
}
