import React from "react";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Button } from "@hexa-ui/components";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import VendorPocByAccountIdStore from "@stores/callList/VendorPocByAccount/VendorPocByAccountStore";
import { setPocVendorId } from "@config/utils/functions";
import GlobalStore from "@stores/global/GlobalStore";
import { searchPocByAccountId } from "@stores/callList/pocInformation/PocInformationEvents";
import { SearchedPoc } from "@domains/PocInformation";
import { resetVendorsPoc } from "@stores/callList/VendorPocByAccount/VendorPocByAccountEffect";
import useStyles from "./VendorSelectDialog.styles";
import VendorRadioButton from "./components/VendorRadioButton/VendorRadioButton";

interface Props {
  testId?: string;
  open: boolean;
  onClose: (_?: unknown, reason?: string) => void;
  handleGoToPoc: (poc: SearchedPoc) => void;
}

const VendorSelectDialog: React.FC<Props> = ({
  open,
  handleGoToPoc,
  onClose,
  testId,
}) => {
  const globalState = useStore(GlobalStore);

  const { vendorPoc } = useStore(VendorPocByAccountIdStore);
  const vendorToPocId = {};
  vendorPoc?.forEach((vendor) => {
    vendorToPocId[vendor.vendorId] = vendor.accountId;
  });

  const classes = useStyles();
  const { t } = useTranslation();

  const [vendorId, setVendorId] = React.useState<string>("");
  const [pocId, setPocId] = React.useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVendorId(event.target.value);
    setPocId(vendorToPocId[event.target.value]);
  };

  const handleCall = async () => {
    setPocVendorId(vendorId);

    const searchPocRequest = {
      accountId: pocId,
      vendorId,
      country: globalState.user.zone,
      isSearchPoc: true,
    };
    const poc = await searchPocByAccountId(searchPocRequest);
    handleGoToPoc(poc);
    resetVendorsPoc(null);
    onClose();
  };

  const handleCancel = () => {
    setVendorId("");
    onClose();
  };

  return (
    <Dialog data-testid={testId} open={open} onClose={onClose} scroll="paper">
      <Container className={classes.container}>
        <h2 className={classes.title}>{t("VendorSelectDialoger.TITLE")}</h2>
        <Typography className={classes.text}>
          {t("VendorSelectDialoger.DESCRIPTION")}
        </Typography>

        <DialogContent style={{ maxHeight: "214px", padding: 0 }}>
          <RadioGroup value={vendorId} onChange={handleChange}>
            {vendorPoc?.map((vendor) => (
              <VendorRadioButton
                key={vendor.vendorId}
                vendorId={vendor.vendorId}
                vendorName={vendor.vendorName}
                pocName={vendor.accountName}
              />
            ))}
          </RadioGroup>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel} variant="secondary">
            {t("VendorSelectDialoger.CANCEL")}
          </Button>
          <Button onClick={handleCall} variant="primary" disabled={!vendorId}>
            {t("VendorSelectDialoger.CALL_POC")}
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

export default VendorSelectDialog;
