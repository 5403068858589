import { CreditResponse } from "../../services/pocInformation/domains/CreditResponse";
import { CreditAccountsResponse } from "../../services/creditPocInformation/domains/CreditAccountsResponse";

interface CreditAccountsContentResponse {
  content: Array<CreditAccountsResponse>;
}
const CreditResponseToPocInformationCreditInfoConverter = (
  data: CreditAccountsContentResponse,
): CreditResponse => {
  if (data.content.length > 0) {
    return {
      balance: data.content[0]?.balance,
      overdue: data.content[0]?.overdue,
      available: data.content[0]?.available,
      total: data.content[0]?.total,
      consumption: data.content[0]?.consumption,
      updatedAt: data.content[0]?.updatedAt,
    };
  }
  return {} as CreditResponse;
};

export default CreditResponseToPocInformationCreditInfoConverter;
