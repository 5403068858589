import { createStore } from "effector";
import * as CatalogEvents from "./CatalogEvents";
import CatalogState from "./CatalogState";
import CatalogDb from "../../routes/call/catalogDb/CatalogDb";

const initialState = <CatalogState>{
  done: false,
  error: false,
  hasBeenAddedToDb: false,
  palletQuantity: 0,
};

const CatalogStore = createStore(initialState)
  .on(CatalogEvents.getCatalogEffect.done, (state: CatalogState) => ({
    ...state,
    done: true,
    error: false,
    palletQuantity: state.palletQuantity,
  }))
  .on(CatalogEvents.getCatalogV3MultipleEffect.done, (state: CatalogState) => ({
    ...state,
    done: true,
    error: false,
    palletQuantity: state.palletQuantity,
  }))
  .on(CatalogEvents.getCatalogEffect.fail, (state: CatalogState) => ({
    ...state,
    done: true,
    error: true,
    palletQuantity: state.palletQuantity,
  }))
  .on(CatalogEvents.getCatalogV3MultipleEffect.fail, (state: CatalogState) => ({
    ...state,
    done: true,
    error: true,
    palletQuantity: state.palletQuantity,
  }))
  .on(CatalogEvents.clearCatalog, (state: CatalogState) => {
    new CatalogDb().catalog.toCollection().delete();

    return {
      ...state,
      hasBeenAddedToDb: false,
    };
  })
  .on(
    CatalogEvents.setHasBeenAddedToDb,
    (state: CatalogState, hasBeenAddedToDb: boolean) => ({
      ...state,
      hasBeenAddedToDb,
    }),
  );

export default CatalogStore;
