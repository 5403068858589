/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable-next-line no-useless-escape */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Client, Message } from "paho-mqtt";
import { WebSocketStatus } from "./WebsocketEventData";
import SoftphoneWSDomain, { WebSocketType } from "./SoftphoneWSDomain";

const INVALID_URI = "Invalid uri";
const INVALID_WEBSOCKET_TYPE = "Invalid websocket type";

export class SoftphoneWS {
  private uri: string;

  private type: WebSocketType;

  private host: string | undefined;

  private port: number | undefined;

  private topic: string | undefined;

  private clientID: string | undefined;

  private client: WebSocket | Client | undefined;

  /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
  /* istanbul ignore next */
  get readyState() {
    /* eslint-disable-next-line no-underscore-dangle */
    return this.isConnected() ? WebSocketStatus.OPENED : WebSocketStatus.CLOSE;
  }

  /* eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  onopen(evt?: any): void {
    // This is intentional
  }

  /* istanbul ignore next */
  /* eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
  onmessage(evt: { data: any }): void {
    // This is intentional
  }

  /* istanbul ignore next */
  /* eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */
  onerror(evt: { error: any }): void {
    // This is intentional
  }

  /* eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
  onclose(evt?: any): void {
    // This is intentional
  }

  constructor(websocket: SoftphoneWSDomain) {
    const regex =
      /^ws:\/\/(echo\.websocket\.org|((localhost|((25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(25[0-5]|2[0-4]\d|[01]?\d\d?)):\d{4,5}))$/;
    if (!regex.test(websocket.uri)) throw new Error(INVALID_URI);

    this.uri = websocket.uri;

    if (
      this.uri.includes("echo.websocket.org") &&
      websocket.type !== WebSocketType.WEBSOCKET
    )
      throw new Error(INVALID_URI);

    this.type = websocket.type;

    this.startConnect();
  }

  /* istanbul ignore next */
  startConnect(): void {
    /* eslint-disable-next-line @typescript-eslint/no-this-alias */
    const softphone = this;

    switch (this.type) {
      case WebSocketType.WEBSOCKET: {
        this.client = new WebSocket(this.uri);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.client.onopen = (evt) => {
          softphone.onopen();
          // console.log("WEBSOCKET - SoftphoneWS.onopen");
        };

        this.client.onmessage = (evt) => {
          const payload = JSON.parse(evt.data);
          softphone.onmessage({ data: payload });
          // console.log("WEBSOCKET - SoftphoneWS.onmessage: ", { data: payload });
        };

        this.client.onerror = (evt) => {
          softphone.onerror(evt as any);
          // console.log("WEBSOCKET - SoftphoneWS.onerror: ", evt);
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.client.onclose = (evt) => {
          softphone.onclose();
          // console.log("WEBSOCKET - SoftphoneWS.onclose");
        };
        break;
      }
      case WebSocketType.MQTT: {
        const splited = this.uri.split(":");

        this.host = splited[1].substr(2);
        this.port = Number(splited[2]);

        this.topic = `softphone_SOFTPHONE_BR`;

        this.clientID = `agent_tlp_SOFTPHONE_BR`;
        // Initialize new Paho client connection
        this.client = new Client(this.host, Number(this.port), this.clientID);

        // Connect the client, if successful, call onConnect function
        this.client.connect({
          /* eslint-disable-next-line @typescript-eslint/no-unused-vars */

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onSuccess: (evt) => {
            const topic = <string>softphone.topic;
            (<Client>softphone.client).subscribe(topic);
            softphone.onopen();
            // console.log("MQTT - SoftphoneWS.onopen");
          },
          onFailure: (evt) => {
            /* eslint-disable-next-line no-console */

            softphone.onerror(evt as any);
            if (evt.errorCode === 7) {
              softphone.onclose();
            }
            // console.log("MQTT - SoftphoneWS.onerror: ", evt);
          },
        });

        this.client.onMessageArrived = (evt) => {
          const payload = JSON.parse(evt.payloadString);
          softphone.onmessage({ data: payload });
          // console.log("MQTT - SoftphoneWS.onmessage: ", { data: payload });
        };

        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        this.client.onConnectionLost = (evt) => {
          softphone.onclose();
          // console.log("MQTT - SoftphoneWS.onclose");
        };
        break;
      }
      default:
        throw new Error(INVALID_WEBSOCKET_TYPE);
    }
  }

  /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
  /* istanbul ignore next */
  send(data: any): void {
    const stringData = JSON.stringify(data);
    if (this.type === WebSocketType.MQTT) {
      const message = new Message(stringData);
      message.destinationName = <string>this.topic;
      (<Client>this.client).send(message);
      // console.log("MQTT - SoftphoneWS.send: ", stringData);
    } else {
      (<WebSocket>this.client).send(stringData);
      // console.log("WEBSOCKET - SoftphoneWS.send: ", stringData);
    }
  }

  /* istanbul ignore next */
  close(): void {
    if (this.type === WebSocketType.MQTT) {
      (<Client>this.client).disconnect();
      // console.log("MQTT - SoftphoneWS.close");
    } else {
      (<WebSocket>this.client).close();
      // console.log("WEBSOCKET - SoftphoneWS.close");
    }
  }

  isConnected(): boolean {
    let connected = false;
    if (this.type === WebSocketType.MQTT) {
      connected = (<Client>this.client).isConnected();
    } else {
      connected = (<WebSocket>this.client).readyState === WebSocket.OPEN;
    }
    return connected;
  }
}
