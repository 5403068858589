import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    dialog: {
      padding: "32px",
      width: "370px",
      margin: 0,
    },
    dialogTitle: {
      padding: 0,
      "& h2": {
        fontSize: "16px",
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        paddingBottom: "8px",
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
      },
    },
    dialogContent: {
      padding: 0,
    },
    dialogContentText: {
      padding: "20px 0 40px 0",
      margin: 0,
      fontSize: "16px",
      color: theme.palette.text.secondary,
      display: "flex",
      flexDirection: "column",
    },
    message: {
      paddingBottom: "20px",
    },
    confirmMessage: {
      fontWeight: 500,
    },
    dialogActions: {
      padding: 0,
    },
    button: {
      flexGrow: 1,
      height: "40px",
      display: "flex",
      justifyContent: "center",
      fontWeight: 600,
    },
    buttonCTI: {
      flexGrow: 1,
      height: "40px",
      display: "flex",
      justifyContent: "center",
      fontWeight: 600,
      fontSize: 14,
    },
    cancelButton: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.text.primary,
    },
    confirmButton: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      width: 125,
    },
    errorMessage: {
      fontSize: 16,
      color: theme.palette.error.main,
    },
    continueMessage: {
      fontSize: 16,
      color: theme.palette.text.secondary,
    },
    modalSubTitle: {
      marginTop: 20,
      marginBottom: 4,
      fontSize: 12,
      color: theme.palette.text.secondary,
      fontWeight: "bold",
    },
    modalSubTitleError: {
      fontSize: 12,
      color: theme.palette.error.main,
      fontWeight: 500,
    },
    disabledButton: {
      backgroundColor: "#eaeaea",
    },
  }),
);
