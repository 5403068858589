import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";

interface Props {
  dailyBalance?: number | null;
  monthlyBalance?: number | null;
  remainingDays?: number | null;
  testid?: string;
}

const ShowMoreInfo = ({
  dailyBalance,
  monthlyBalance,
  remainingDays,
  testid,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const getDailyBalance = (dailyBalance: number, monthlyBalance: number) => {
    if (dailyBalance < 0) return 0;
    if (monthlyBalance < dailyBalance) {
      if (monthlyBalance < 0) return 0;
      return monthlyBalance;
    }
    return dailyBalance;
  };
  const getMonthlyBalance = (monthlyBalance: number) => {
    if (monthlyBalance < 0) return 0;
    return monthlyBalance;
  };
  return (
    <div data-testid={testid}>
      {remainingDays != null && remainingDays >= 0 && (
        <Typography variant="h3" data-testid={`${testid}-remainingDays`}>
          {`• ${t("Promotions.AVAILABLE_DAYS").replace(
            "X",
            remainingDays.toString(),
          )}`}
        </Typography>
      )}
      {dailyBalance != null && monthlyBalance != null && (
        <Typography variant="h3" data-testid={`${testid}-balance`}>
          {`• ${t("Promotions.AVAILABLE_MONTH")
            .replace(
              "X",
              getDailyBalance(dailyBalance, monthlyBalance).toString(),
            )
            .replace("Y", getMonthlyBalance(monthlyBalance).toString())}`}
        </Typography>
      )}
    </div>
  );
};

export default ShowMoreInfo;
