import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import SponsoredCard from "../sponsoredCard/SponsoredCard";
import SponsoredCardSkeleton from "../sponsoredCard/SponsoredCardSkeleton";
import SponsoredStore from "../../../../stores/sponsored/SponsoredStore";
import PromotionsStore from "../../../../stores/promotions/PromotionsStore";
import SponsoredComponentSkeleton from "../sponsoredComponent/SponsoredComponentSkeleton";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/v2/ErrorHandlerRetry";
import SponsoredComponentStyles from "../sponsoredComponent/SponsoredComponent.styles";
import GlobalStore from "../../../../stores/global/GlobalStore";
import pocInformationStore from "../../../../stores/callList/pocInformation/PocInformationStore";
import {
  isFeatureEnabledV2,
  GROW_LOCAL_HEADER_REDESIGN,
  GROW_ADMIN_LOCAL_MISSIONS_STEP2,
} from "../../../../config/featureToggles";
import useGlobalAndLocalComponent from "../sponsoredComponent/hooks/useGlobalAndLocalComponent";
import {
  getFlexibleMissionsByIdsEffect,
  getSponsoredProductsEvent,
} from "../../../../stores/sponsored/SponsoredEvents";
import { SponsoredMission } from "../../../../domains/sponsored/SponsoredMission";
import CollapseTitleGlobalAndLocal from "../sponsoredComponent/components/CollapseTitleGlobalAndLocal";
import { useWindowResize } from "../../../../hooks/useWindowResize";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import { errorMessageViewed } from "../../../../config/typewriter";

const SIZE_LIMIT_ITEMS = 20;

const LocalMissionsComponent: React.FC = () => {
  const windowWidth = useWindowResize(window?.innerWidth);

  const classes = SponsoredComponentStyles();
  const {
    isSponsoredMissionsLoading,
    isSponsoredProductsLoading,
    sponsoredMissionsError,
    localMissionsIds,
    localMissions,
    sponsoredProductsError,
    isFlexibleMissionsLoading,
    flexibleMissionsError,
    flexibleMissionsIds,
    done: sponsoredMissionsDone,
  } = useStore(SponsoredStore);
  const { done: promotionsDone, error: promotionError } =
    useStore(PromotionsStore);
  const { user } = useStore(GlobalStore);
  const { pocInformation } = useStore(pocInformationStore);
  const {
    handleGetSponsoredMissionsRetry,
    handleTryAgain,
    sponsoredItemsOutOfSync,
    handleEventErrorMessageViewed,
    dispatchEventLocalLoad,
  } = useGlobalAndLocalComponent();
  const { dispatchPocEvent } = useAnalytics();
  const { t } = useTranslation();
  const [openSponsoredMissionIndex, setOpenSponsoredMissionIndex] =
    useState<number>(0);

  const isHeaderRedesign = isFeatureEnabledV2(
    GROW_LOCAL_HEADER_REDESIGN,
    user.keyToggle,
  );

  const isLocalMissionsStep2Enabled = isFeatureEnabledV2(
    GROW_ADMIN_LOCAL_MISSIONS_STEP2,
    user.keyToggle,
  );

  useEffect(() => {
    const unwatchGetFlexibleMissionsByIdsEffectFail =
      getFlexibleMissionsByIdsEffect.fail.watch(() => {
        dispatchPocEvent(errorMessageViewed, {
          screen_name: "Missions",
          error_type: "Failed to retrieve flexible missions by id",
          error_message: "It was not possible to load the global missions.",
          screen_section: "Campaigns Tab",
        });
      });

    /* istanbul ignore next */
    return () => {
      unwatchGetFlexibleMissionsByIdsEffectFail();
    };
  }, [dispatchPocEvent]);

  const loadMissions = useCallback(() => {
    const localMission = localMissions[openSponsoredMissionIndex];

    if (
      sponsoredItemsOutOfSync(openSponsoredMissionIndex, localMissions) &&
      sponsoredMissionsDone &&
      !sponsoredProductsError &&
      localMission.missionType === "coverage"
    ) {
      getSponsoredProductsEvent({
        sponsoredId: localMission.id,
        items: localMission.items,
        combos: localMission.combos,
      });
    }
  }, [
    localMissions,
    openSponsoredMissionIndex,
    sponsoredItemsOutOfSync,
    sponsoredMissionsDone,
    sponsoredProductsError,
  ]);

  useEffect(() => {
    loadMissions();
  }, [loadMissions]);

  const isSponsoredMissionDetailOk =
    sponsoredItemsOutOfSync(openSponsoredMissionIndex, localMissions) ===
      false &&
    promotionsDone &&
    sponsoredMissionsDone;

  useEffect(() => {
    handleEventErrorMessageViewed();
  }, [handleEventErrorMessageViewed]);

  const loadSkeleton =
    isSponsoredProductsLoading || (!promotionsDone && !promotionError);

  const renderCollapseChildren = (
    mission: SponsoredMission,
    isRedesign: boolean,
  ) => {
    let localMissionProducts = [...(mission.products || [])];

    if (
      isLocalMissionsStep2Enabled &&
      mission.effectiveness?.type === "SPECIFIED_QUANTITY"
    ) {
      localMissionProducts = localMissionProducts.filter(
        /* istanbul ignore next */
        (missionProduct) =>
          missionProduct.suggestedQuantity &&
          missionProduct.suggestedQuantity > 0,
      );
    }

    return loadSkeleton && !isSponsoredMissionDetailOk ? (
      <SponsoredCardSkeleton isRedesign={isHeaderRedesign} />
    ) : (
      <SponsoredCard
        mission={mission}
        products={localMissionProducts}
        hasError={sponsoredMissionsError}
        tryAgain={() => handleTryAgain(openSponsoredMissionIndex, mission)}
        pocInformation={pocInformation}
        isRedesign={isRedesign}
        isSponsored={false}
        windowWidth={windowWidth}
      />
    );
  };

  const shouldRenderError = useMemo(
    () =>
      (!isSponsoredMissionsLoading && sponsoredMissionsError) ||
      (!isFlexibleMissionsLoading && flexibleMissionsError),
    [
      flexibleMissionsError,
      isFlexibleMissionsLoading,
      isSponsoredMissionsLoading,
      sponsoredMissionsError,
    ],
  );

  const handleRetry = useCallback(() => {
    /* istanbul ignore else */
    if (sponsoredMissionsError) {
      handleGetSponsoredMissionsRetry(localMissionsIds);
    }
    /* istanbul ignore else */
    if (flexibleMissionsError) {
      getFlexibleMissionsByIdsEffect(flexibleMissionsIds);
    }
  }, [
    flexibleMissionsError,
    flexibleMissionsIds,
    handleGetSponsoredMissionsRetry,
    localMissionsIds,
    sponsoredMissionsError,
  ]);

  /* istanbul ignore next */
  const errorString = useMemo(() => {
    if (sponsoredMissionsError && flexibleMissionsError) {
      return "LocalMission.LOCAL_AND_GLOBAL_MISSIONS_REQUEST_FAILURE_MESSAGE";
    }
    if (flexibleMissionsError) {
      return "LocalMission.GLOBAL_MISSIONS_REQUEST_FAILURE_MESSAGE";
    }
    if (sponsoredMissionsError) {
      return "LocalMission.LOCAL_MISSIONS_REQUEST_FAILURE_MESSAGE";
    }
    return "";
  }, [flexibleMissionsError, sponsoredMissionsError]);

  return (
    <>
      {shouldRenderError && (
        <div className={classes.errorHandlerWrapper}>
          <ErrorHandlerRetry
            onClick={handleRetry}
            description={t(errorString)}
            className={isHeaderRedesign ? classes.containerError : ""}
          />
        </div>
      )}
      {isSponsoredMissionsLoading ? (
        <SponsoredComponentSkeleton />
      ) : (
        localMissions?.slice(0, SIZE_LIMIT_ITEMS).map((mission, index) => {
          dispatchEventLocalLoad(mission, false);
          return (
            <CollapseTitleGlobalAndLocal
              mission={mission}
              index={index}
              openSponsoredMissionIndex={openSponsoredMissionIndex}
              setOpen={setOpenSponsoredMissionIndex}
              key={`collapse-card-${mission.id}`}
            >
              {renderCollapseChildren(mission, isHeaderRedesign)}
            </CollapseTitleGlobalAndLocal>
          );
        })
      )}
    </>
  );
};

export default React.memo(LocalMissionsComponent);
