import axiosRetry from "axios-retry";
import { useLogService } from "admin-portal-shared-services";
import GlobalAdminConfigDomain from "domains/globaAdminConfig/globaAdminConfig";
import GlobalAdminConfigServiceResponse from "./domains/GlobalAdminConfigServiceResponse";
import { getCountryByToken } from "../../config/utils/functions";
import GlobalAdminConfigConverter from "../../converters/globalAdminConfig/GlobalAdminConfigConverter";
import { getApiHost } from "../host/HostService";
import axios from "../../config/axios/axiosInstance";
import { GROW_ADMIN_CONFIG_SERVICE } from "../../config/services";
import {
  GROW_ADMIN_MISSIONS_MANAGEMENT_V2,
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_GROW_ADMIN_CONFIG_SERVICE,
} from "../../config/featureToggles";

const ENDPOINT = "/grow-admin-config/user";
const ENDPOINT_V2 = "/grow-admin-config/v2/user";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

interface GetUserConfigHeader {
  country: string;
  requestTraceId: string;
  authorization: string;
}

export function getUserConfig(
  useId: string,
  vendorId?: string,
  headers?: GetUserConfigHeader,
): Promise<GlobalAdminConfigDomain> {
  const log = useLogService();

  const isMulticontractServiceEnable = isFeatureEnabledV2(
    TLP_MULTICONTRACT_GROW_ADMIN_CONFIG_SERVICE,
    getCountryByToken(),
  );

  const isNewMissionsManagementEnabled = isFeatureEnabledV2(
    GROW_ADMIN_MISSIONS_MANAGEMENT_V2,
    getCountryByToken(),
  );

  const endpoint = isMulticontractServiceEnable ? ENDPOINT_V2 : ENDPOINT;
  const url = `${getApiHost(
    GROW_ADMIN_CONFIG_SERVICE,
  )}${endpoint}/${useId?.toLowerCase()}/config`;

  const params = vendorId && isMulticontractServiceEnable ? { vendorId } : {};

  return axios
    .get<GlobalAdminConfigServiceResponse>(url, {
      params,
      headers: headers || {},
    })
    .then((response) => {
      log.info(`Convert GlobalAdminConfig received from ${url} to our model`);
      return GlobalAdminConfigConverter(
        response.data,
        isNewMissionsManagementEnabled,
        vendorId,
      );
    })
    .catch((error) => {
      log.error(error);

      throw error;
    });
}
