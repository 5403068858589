import React, { ReactNode, FunctionComponent } from "react";
import TableFooterBase from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";

interface Props {
  children: ReactNode;
  testId: string;
  className: string;
}

const TableListFooter: FunctionComponent<Props> = ({
  children,
  testId,
  className,
}) => {
  return (
    <TableFooterBase
      className={className}
      data-testid={`${testId}-table-footer`}
    >
      <TableRow>{children}</TableRow>
    </TableFooterBase>
  );
};

export default TableListFooter;
