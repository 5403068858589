import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      flexGrow: 1,
      marginTop: "16px",
      border: "solid 1px #eaeaea",
      maxHeight: "209px",
      overflow: "hidden",
      "&:hover": {
        overflow: "auto",
      },
    },
    containerTotalizer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      flexGrow: 1,
    },
  }),
);
