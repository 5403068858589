import {
  useMemo,
  useState,
  KeyboardEvent,
  MouseEvent,
  useCallback,
} from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import {
  isFeatureEnabledV2,
  GROW_WIP_FREE_GOODS,
} from "../../../../../config/featureToggles";

import FormatedFreeGoods, {
  FormatedFreeGoodsItem,
} from "../../../../../domains/cart/FormatedFreeGoods";
import CartItemStore from "../../../../../stores/cart/CartItemStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import { editSelectedFreeGoodItem } from "../../../../../stores/cart/CartItemEvents";
import { SelectedFreeGoods } from "../../../../../domains/cart/SelectedFreeGoods";
import { freeGoodUxButtonClicked } from "../../../../../config/typewriter";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import { useAnalytics } from "../../../../../analytics/useAnalytics";

interface UseCartFreeGoodsHookProps {
  showFreeGoodsCartSection: boolean;
  hasItemsToAdd: boolean;
  toggleDrawer: (
    open: boolean,
    name: string,
    text: string,
  ) => (event: KeyboardEvent | MouseEvent) => void;
  isDrawerOpen: boolean;
  formatedFreeGoodItems: FormatedFreeGoods[];
  formatedSelectedItems: FormatedFreeGoodsItem[];
}

const imagePlaceholder =
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  require("../../../../../assets/images/ProductPlaceholder.png") as string;

export const useCartFreeGoodsHook = (): UseCartFreeGoodsHookProps => {
  const { t } = useTranslation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { freeGoods, selectedFreeGoods } = useStore(CartItemStore);
  const { user } = useStore(GlobalStore);
  const { dispatchGenericEvent } = useAnalytics();
  const { callId } = useStore(AgentCallStore);

  const isFreeGoodEnabled = isFeatureEnabledV2(
    GROW_WIP_FREE_GOODS,
    user.keyToggle,
  );

  const showFreeGoodsCartSection = useMemo(() => {
    return isFreeGoodEnabled && freeGoods?.length > 0;
  }, [freeGoods, isFreeGoodEnabled]);

  const toggleDrawer =
    (open: boolean, name: string, text: string) =>
    (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      dispatchGenericEvent(freeGoodUxButtonClicked, {
        call_id: callId,
        button_label: text,
        button_name: name,
      });
      setIsDrawerOpen(open);
    };

  const getTotalSelectedItemsCount = (
    freeGoodHash: string,
    selectedFreeGoods: SelectedFreeGoods[],
  ): number => {
    return selectedFreeGoods.reduce((acc: number, current) => {
      if (current.freeGoodHashCode === freeGoodHash) {
        return acc + current.quantity;
      }

      return acc;
    }, 0);
  };

  const getSelectedItemCount = (
    selectedFreeGoods: SelectedFreeGoods[],
    itemPlatformId: string,
    freeGoodHash: string,
  ) => {
    const selectedFreeGood = selectedFreeGoods.find(
      (sItem) =>
        sItem.itemPlatformId === itemPlatformId &&
        sItem.freeGoodHashCode === freeGoodHash,
    );

    return selectedFreeGood?.quantity ?? 0;
  };

  const handleEditSelectFreeGoodItem = (
    quantity: number,
    freeGoodHashCode: string,
    itemPlatformId: string,
    sku: string,
  ) => {
    const editFreeGood = {
      freeGoodHashCode,
      itemPlatformId,
      quantity,
      sku,
    } as SelectedFreeGoods;

    editSelectedFreeGoodItem(editFreeGood);
  };

  const productsRemainingTitle = useCallback(
    (quantityRemaining: number, quantitySelected: number) => {
      if (quantityRemaining === 1) {
        return {
          title: `${t(
            "OrderTaking.FREE_GOODS_SECTION.CARD.SELECT",
          )} ${quantityRemaining} ${t(
            "OrderTaking.FREE_GOODS_SECTION.CARD.OF_THE_FOLLOWING",
          )}`,
          subtitle: `${t(
            "OrderTaking.FREE_GOODS_SECTION.CARD.ADD",
          )} ${quantityRemaining} ${t(
            "OrderTaking.FREE_GOODS_SECTION.CARD.FREE_GOOD",
          )} ${t("OrderTaking.FREE_GOODS_SECTION.CARD.FROM_LIST")}.`,
        };
      }

      if (quantityRemaining > 1) {
        return {
          title: `${t(
            "OrderTaking.FREE_GOODS_SECTION.CARD.SELECT",
          )} ${quantityRemaining} ${t(
            "OrderTaking.FREE_GOODS_SECTION.CARD.FREE_GOODS",
          )}`,
          subtitle: `${t("OrderTaking.FREE_GOODS_SECTION.CARD.ADD")} ${t(
            "OrderTaking.FREE_GOODS_SECTION.CARD.COMBINATION_OF",
          )} ${quantityRemaining} ${t(
            "OrderTaking.FREE_GOODS_SECTION.CARD.FREE_GOODS",
          )} ${t("OrderTaking.FREE_GOODS_SECTION.CARD.FROM_LIST")}.`,
        };
      }

      return {
        title: `${quantitySelected} ${t(
          "OrderTaking.FREE_GOODS_SECTION.CARD.SELECTED",
        )}`,
        subtitle: `${t("OrderTaking.FREE_GOODS_SECTION.CARD.LIMIT_REACHED")}.`,
      };
    },
    [t],
  );

  const formatedFreeGoodItems = useMemo(() => {
    return freeGoods?.map((f) => {
      const selectedItemsCount = getTotalSelectedItemsCount(
        f.hashCode,
        selectedFreeGoods,
      );

      const remainingQuantity = f.availableQuantity - selectedItemsCount;

      return {
        ...f,
        availableQuantity: remainingQuantity,
        displayTitle: productsRemainingTitle(
          remainingQuantity,
          selectedItemsCount,
        ).title,
        displaySubtitle: productsRemainingTitle(
          remainingQuantity,
          selectedItemsCount,
        ).subtitle,
        items: f.items.map((item) => {
          const freeGoodItemSelectedQuantity = getSelectedItemCount(
            selectedFreeGoods,
            item.platformId,
            f.hashCode,
          );

          const itemAvailableQuantity = Math.min(
            freeGoodItemSelectedQuantity + remainingQuantity,
            item.availability,
          );

          const imageUrl = item.imageUrl ?? imagePlaceholder;

          return {
            ...item,
            freeGoodHashCode: f.hashCode,
            imageUrl,
            selectedQuantity: freeGoodItemSelectedQuantity,
            availability: item.availability,
            maxQuantity: itemAvailableQuantity,
            onChangeQuantity: (newQuantity) =>
              handleEditSelectFreeGoodItem(
                newQuantity,
                f.hashCode,
                item.platformId,
                item.sku,
              ),
          } as FormatedFreeGoodsItem;
        }),
      };
    });
  }, [freeGoods, productsRemainingTitle, selectedFreeGoods]);

  const formatedSelectedItems = useMemo(() => {
    return formatedFreeGoodItems
      ?.flatMap((f) => f.items)
      .filter((item) => {
        return item.selectedQuantity > 0;
      });
  }, [formatedFreeGoodItems]);

  const hasItemsToAdd = useMemo(() => {
    return freeGoods?.some((f) => {
      const selectedItemsCount = getTotalSelectedItemsCount(
        f.hashCode,
        selectedFreeGoods,
      );

      const remainingQuantity = f.availableQuantity - selectedItemsCount;

      const itemsSelectedTotal = f.items?.reduce((acc: number, current) => {
        return (
          acc +
          getSelectedItemCount(
            selectedFreeGoods,
            current.platformId,
            f.hashCode,
          )
        );
      }, 0);

      const itemsAvailableCount = f.items?.reduce((acc: number, current) => {
        return acc + current.availability;
      }, 0);

      const remainingItemsQuantity = itemsAvailableCount - itemsSelectedTotal;

      return (
        remainingQuantity > 0 && remainingItemsQuantity >= remainingQuantity
      );
    });
  }, [freeGoods, selectedFreeGoods]);

  return {
    showFreeGoodsCartSection,
    hasItemsToAdd,
    toggleDrawer,
    isDrawerOpen,
    formatedFreeGoodItems,
    formatedSelectedItems,
  };
};
