import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import callOptionsStyles from "./CallOptionsStyles";
import GetNextPocButton from "../header/getNextPocButton/GetNextPocButton";
import CallInAutoDialerButton from "../header/callInAutoDialerButton/CallInAutoDialerButton";

interface Props {
  title: string;
  firstLabel: string;
  secondLabel: string;
  click: () => void;
  autoDialClick: () => void;
}

const CallOptions: React.FC<Props> = ({
  title,
  firstLabel,
  secondLabel,
  click,
  autoDialClick,
}) => {
  const classes = callOptionsStyles({});

  return (
    <Card className={classes.containerBox} elevation={0}>
      <CardHeader
        title={title}
        classes={{ title: classes.overwriteTitle }}
        className={classes.title}
      />
      <div className={classes.cardContent}>
        <GetNextPocButton
          loading={false}
          label={firstLabel}
          click={click}
          newStyle
        />
        <CallInAutoDialerButton
          loading={false}
          label={secondLabel}
          click={autoDialClick}
        />
      </div>
    </Card>
  );
};

export default CallOptions;
