import { useLogService } from "admin-portal-shared-services";
import axios from "axios";
import axiosRetry from "axios-retry";

import axiosInstance from "@config/axios/axiosInstance";

import { getUserInfos } from "@config/utils/functions";
import {
  GROW_TECH_CART_AUTO_UPDATE,
  GROW_TECH_CART_BFF,
  isFeatureEnabledV2,
} from "../../../config/featureToggles";
import { GROW_BFF_SERVICE } from "../../../config/services";
import CartIdResponseToCartInfoConverter from "../../../converters/cart/cartId/CartIdResponseToCartInfoConverter";
import StoresToUpsertItemRequestConverter from "../../../converters/cart/upsertItem/StoresToUpsertItemRequestConverter";
import { CartInfo } from "../../../domains/cart/CartInfo";
import AgentCallState from "../../../stores/agentCall/AgentCallState";
import CartItemState from "../../../stores/cart/CartItemState";
import CartComboState from "../../../stores/cartCombo/CartComboState";
import GlobalStore from "../../../stores/global/GlobalStore";
import { getApiHost } from "../../host/HostService";
import CartIdResponse from "../cartId/domain/CartIdResponse";
import UpdateCanceledError from "../domains/UpdateCanceledError";

const CARTS = "/carts";

/* istanbul ignore next */
axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function UpsertItemToCart(
  agentCallState: AgentCallState,
  cartComboState: CartComboState,
  cartItemsState: CartItemState,
): Promise<CartInfo | null> {
  const log = useLogService();
  const { user } = GlobalStore.getState();

  const { vendorId } = getUserInfos();

  const isAutoUpdateEnabled = isFeatureEnabledV2(
    GROW_TECH_CART_AUTO_UPDATE,
    user.keyToggle,
  );
  const isCartBffEnable = isFeatureEnabledV2(
    GROW_TECH_CART_BFF,
    user.keyToggle,
  );
  if (!isAutoUpdateEnabled || !isCartBffEnable) {
    return Promise.resolve(null);
  }

  const accountId = agentCallState.clientId;

  const request = StoresToUpsertItemRequestConverter(
    cartComboState,
    cartItemsState,
  );

  const endpoint = `${getApiHost(
    GROW_BFF_SERVICE,
  )}/accounts/${accountId}${CARTS}`;

  return axiosInstance
    .patch<CartIdResponse>(endpoint, request)
    .then((response) => {
      log.info(
        `Convert CartId Response received from ${endpoint} to our model`,
      );
      return CartIdResponseToCartInfoConverter({
        accountId,
        vendorId,
        data: response.data,
      });
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        throw new UpdateCanceledError();
      }
      log.error(error);
      throw error;
    });
}
