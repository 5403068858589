import React from "react";

const EndSimulatorBtn: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g fill="#D00505" transform="translate(-911 -88) translate(911 88)">
            <circle cx="20" cy="20" r="20" />
          </g>
          <g stroke="#FFF" strokeLinecap="round" strokeWidth="2.625">
            <path
              d="M-3.5 3.5L3.5 10.5 10.5 3.5"
              transform="translate(-911 -88) translate(911 88) translate(13 13) rotate(-90 3.5 7)"
            />
            <path
              d="M3.5 3.5L10.5 10.5 17.5 3.5"
              transform="translate(-911 -88) translate(911 88) translate(13 13) matrix(0 -1 -1 0 17.5 17.5)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EndSimulatorBtn;
