import { createStyles, makeStyles } from "@material-ui/core";

export const SalesStructureStyles = makeStyles(() =>
  createStyles({
    gridContainer: {
      flex: "1",
      display: "flex",
      overflow: "auto",
      marginRight: "-10px",
      height: "100%",
    },
    loading: {
      margin: "6rem",
    },
    box: {
      height: "90%",
      padding: "0 20px 24px 0",
      overflowY: "auto",
      overflowX: "hidden",
      width: 254,
      position: "relative",
    },

    container: {
      padding: "25px 24px",
    },
    text: {
      color: "#f2f2f2",
      fontSize: "12px",
      wordBreak: "break-word",
      fontWeight: "normal",
      letterSpacing: "0.03px",
      paddingBottom: "1px",
      textTransform: "none",
    },
    title: {
      fontSize: "12px",
      fontWeight: "bold",
      color: "#FFFFFF",
      paddingBottom: "6px",
      wordBreak: "break-word",
      textTransform: "none",
    },
    representativeContainer: {
      display: "flex",
      flexDirection: "column",
      paddingBottom: "16px",
    },
  }),
);
