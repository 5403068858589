import React, { useEffect, useState } from "react";

import { Grid, ButtonBase } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import classNames from "classnames";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import { TextButton } from "@hexa-ui/components";
import { getVendorName } from "grow-shared-services";
import { InteractiveCombo } from "@domains/promotions/InteractiveCombo";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import Button from "../../../../components/button/Button";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/ErrorHandlerRetry";
import {
  PROMOTIONS_TYPES,
  PROMOTIONS_ABA_TYPES,
  ANALYTICS_ROUTE_NAMES,
  LINK_CALL,
} from "../../../../config/constants";
import {
  GROW_NEW_OFFERINGS_PROMOTIONS_CARDS,
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_TAB_PROMOTIONS,
  TLP_TAB_PROMOTIONS_ADMIN,
  GROW_INTERACTIVE_COMBO,
} from "../../../../config/featureToggles";
import {
  offeringsUxButtonClicked,
  promotionsTabInteraction,
  promotionsListLoaded,
} from "../../../../config/typewriter";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import GlobalAdminConfigStore from "../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import { getPromotionsV3Effect } from "../../../../stores/promotions/PromotionsEvents";
import PromotionsStore from "../../../../stores/promotions/PromotionsStore";
import { usePromotionsHook } from "../../tabsContent/missions/hooks/usePromotionsHook";
import LoadingBox from "../offerings/loadingBox/LoadingBox";
import Card from "./card/Card";
import { PromotionCard } from "./PromotionCard/PromotionCard";
import Search from "./search/Search";
import PromotionsStyles from "./PromotionsStyles";
import { ComboInteractive } from "./ComboInteractive/ComboInteractive";

export enum BUTTON_LABELS {
  EXPAND = "expand_all",
  COLLAPSE = "collapse_all",
}

interface Props {
  showDealsTab: boolean;
}

const Promotions = ({ showDealsTab }: Props): JSX.Element => {
  const { t } = useTranslation();
  const classes = PromotionsStyles();
  const AgentCallStates = useStore(AgentCallStore);
  const PromotionStates = useStore(PromotionsStore);
  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);
  const { user } = useStore(GlobalStore);
  const [expandAll, setExpandAll] = useState(false);
  const [selectedTab, setSelectedTab] = useState("none");

  const isPromotionsTabEnabled = isFeatureEnabled(
    TLP_TAB_PROMOTIONS,
    TLP_TAB_PROMOTIONS_ADMIN,
  );

  const isNewPromotionsCardEnabled = isFeatureEnabledV2(
    GROW_NEW_OFFERINGS_PROMOTIONS_CARDS,
    user.keyToggle,
  );

  const isGrowInteractiveComboIsEnabled = isFeatureEnabledV2(
    GROW_INTERACTIVE_COMBO,
  );

  const { dispatchGenericEvent, dispatchPocEvent } = useAnalytics();

  const { formatedPromotions, changeSelectablePromotion } = usePromotionsHook();

  const promotionsLength = PromotionStates.searchResults.promotions.length;
  const combosLength = PromotionStates.searchResults.combos.length;

  if (window.localStorage.getItem("shouldDispatchPromotions") === "true") {
    if (!PromotionStates.error && PromotionStates.done) {
      const analyticsData = {
        search_query: "",
        list_quantity_combo: PromotionStates.combos.length,
        list_quantity_promo: PromotionStates.promotions.length,
      };
      dispatchPocEvent(promotionsListLoaded, analyticsData);
      window.localStorage.setItem(
        "shouldDispatchPromotions",
        "false" as string,
      );
    }
  }

  const noCombosResults =
    combosLength === 0 &&
    PromotionStates.searchResults &&
    PromotionStates.combos?.length === 0;

  const noPromotionsResults =
    promotionsLength === 0 &&
    PromotionStates.searchResults &&
    PromotionStates.promotions?.length === 0;

  /* istanbul ignore next */
  useEffect(() => {
    if (selectedTab === "none") {
      if (!noPromotionsResults) {
        setSelectedTab("promotion");
        changeSelectablePromotion(PROMOTIONS_ABA_TYPES.PROMOTION);
      } else if (!noCombosResults) {
        setSelectedTab("combo");
        changeSelectablePromotion(PROMOTIONS_ABA_TYPES.COMBO);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noPromotionsResults, noCombosResults]);

  const handleExpandAll = () => {
    setExpandAll((prevState) => !prevState);
    const getButtonName = () =>
      expandAll ? t("Promotions.RETRACT_ALL") : t("Promotions.EXPAND_ALL");
    dispatchGenericEvent(offeringsUxButtonClicked, {
      button_name: getButtonName(),
      button_label: !expandAll ? BUTTON_LABELS.EXPAND : BUTTON_LABELS.COLLAPSE,
      promotions_section: selectedTab,
    });
  };

  const reloadPromotions = () => {
    const { vendorId } = GlobalAdminConfigState.userConfig;
    const vendorName = getVendorName(vendorId);

    getPromotionsV3Effect({
      accountId: AgentCallStates?.clientId,
      country: user.zone,
      vendorName,
      vendorAccountId: AgentCallStates?.vendorAccountId,
      legacyAccountId: AgentCallStates?.legacyAccountId,
    });
  };

  const handleTabChange = (tab: string) => {
    dispatchGenericEvent(promotionsTabInteraction, {
      promotions_section: tab,
      tab_label: tab,
    });
    setSelectedTab(tab);

    changeSelectablePromotion(tab as PROMOTIONS_ABA_TYPES);

    setExpandAll(false);
  };

  const noResultsText = (testId: string) => (
    <div className={classes.noPromotionsText} data-testid={testId}>
      {t("Promotions.NO_PROMOTIONS")}
    </div>
  );

  const renderCombosResult = () => {
    let { combos } = PromotionStates.searchResults;
    if (isGrowInteractiveComboIsEnabled)
      combos = combos.filter(
        (item) => item.type !== PROMOTIONS_TYPES.COMBO_INTERACTIVE,
      );

    return combos.map((combo) => (
      <Card
        data={combo}
        type={PROMOTIONS_ABA_TYPES.COMBO}
        key={combo.id}
        allExpanded={expandAll}
        data-testid="combos-container"
      />
    ));
  };

  const getCardKey = (id: string, index: number) => `${id}-${index}`;

  const renderPromotionsResult = () => {
    if (noPromotionsResults) {
      return noResultsText("no-promotions-text");
    }

    return (
      <div className={classes.box} data-testid="promotions-container">
        {PromotionStates.searchResults.promotions.map((promotion, index) => (
          <Card
            data={promotion}
            type={PROMOTIONS_ABA_TYPES.PROMOTION}
            key={getCardKey(promotion.id, index)}
            allExpanded={expandAll}
          />
        ))}
      </div>
    );
  };

  const renderSectionPromotions = () => {
    let viewReturn = [] as JSX.Element[];
    if (
      isGrowInteractiveComboIsEnabled &&
      selectedTab === PROMOTIONS_ABA_TYPES.COMBO
    ) {
      if (formatedPromotions.length === 0) {
        return noResultsText("no-formatted-promotions-text");
      }
      viewReturn = [
        ...formatedPromotions
          .filter((item) => item.type === PROMOTIONS_TYPES.COMBO_INTERACTIVE)
          .map((combo) => {
            return (
              <ComboInteractive
                key={combo.key}
                interactiveCombo={combo as unknown as InteractiveCombo}
                allExpanded={expandAll}
              />
            );
          }),
        ...renderCombosResult(),
      ];
    } else if (isNewPromotionsCardEnabled) {
      if (formatedPromotions.length === 0) {
        return noResultsText("no-formatted-promotions-text");
      }
      viewReturn = [
        ...viewReturn,
        ...formatedPromotions
          .filter((item) => item.type !== PROMOTIONS_TYPES.COMBO_INTERACTIVE)
          .map((promotion) => {
            return (
              <PromotionCard
                key={promotion.key}
                promotion={promotion}
                isExpanded={expandAll}
              />
            );
          }),
      ];
    } else if (selectedTab === PROMOTIONS_ABA_TYPES.COMBO) {
      viewReturn = [...viewReturn, ...renderCombosResult()];
    }
    if (viewReturn.length > 0) {
      return (
        <div
          className={classes.listCardsContainer}
          data-testid={`${selectedTab}-card-list-container`}
        >
          {viewReturn.map((view) => view)}
        </div>
      );
    }

    return renderPromotionsResult();
  };

  const renderExpandOrCollapseButton = () => {
    const buttonLabel = expandAll
      ? t("Promotions.RETRACT_ALL")
      : t("Promotions.EXPAND_ALL");

    if (
      !isGrowInteractiveComboIsEnabled &&
      isNewPromotionsCardEnabled &&
      formatedPromotions.length > 0
    ) {
      return (
        <TextButton
          onClick={handleExpandAll}
          size="small"
          className={classes.expandAndCollapseButton}
          data-testid={`${!expandAll ? "expand" : "collapse"}-button`}
        >
          {buttonLabel}
        </TextButton>
      );
    }

    return (
      <>
        {(promotionsLength !== 0 && selectedTab === "promotion") ||
        (combosLength !== 0 && selectedTab === "combo") ? (
          <ButtonBase
            className={classes.expandButton}
            onClick={handleExpandAll}
            data-testid="expandAll-button"
          >
            {expandAll ? (
              <RemoveIcon
                className={classes.icon}
                data-testid="expandAll-remove-icon"
              />
            ) : (
              <AddIcon
                className={classes.icon}
                data-testid="expandAll-add-icon"
              />
            )}
            <div className={classes.expandButtonLabel}>{buttonLabel}</div>
          </ButtonBase>
        ) : null}
      </>
    );
  };

  const renderPromotionsButton = () =>
    isPromotionsTabEnabled &&
    !noPromotionsResults && (
      <Button
        className={classNames(classes.tabTitle, {
          [classes.tabTitleActive]: selectedTab === "promotion",
        })}
        data-testid="tab-promotions-button"
        onClick={() => handleTabChange("promotion")}
      >
        {`${t("Promotions.PROMOTIONS")} (${promotionsLength})`}
      </Button>
    );

  const renderComboButton = () =>
    !noCombosResults && (
      <Button
        className={classNames(classes.tabTitle, {
          [classes.tabTitleActive]: selectedTab === "combo",
        })}
        disabled={!isPromotionsTabEnabled}
        data-testid="tab-combos-button"
        onClick={() => handleTabChange("combo")}
      >
        {`${t("Promotions.COMBOS")} (${combosLength})`}
      </Button>
    );

  if (PromotionStates.error) {
    return (
      <ErrorHandlerRetry
        onClick={reloadPromotions}
        testId="promotions"
        screenName={ANALYTICS_ROUTE_NAMES.get(LINK_CALL)}
        screenSection="Promotions"
      />
    );
  }

  if (!PromotionStates.done) {
    return <LoadingBox />;
  }

  if (!showDealsTab) {
    return noResultsText("no-deals-text");
  }

  return (
    <>
      <Search />

      <Grid item container className={classes.tabTitleContainer}>
        {renderPromotionsButton()}
        {renderComboButton()}
      </Grid>
      <div className={classes.detailsContainer}>
        {renderExpandOrCollapseButton()}
        {renderSectionPromotions()}
      </div>
    </>
  );
};

export default Promotions;
