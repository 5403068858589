import { styled } from "@bees/vision-tokens";

export const ChallengeImage = styled("section", {
  width: "100%",
  height: 312,
  maxHeight: 312,
  margin: "25px 0px",
});

export const TakePhotoContainer = styled("section", {
  display: "flex",
  gap: 4,
  width: "100%",
  maxHeight: 520,
  marginBottom: "1.2rem",
});

export const SampleContainer = styled("div", {
  display: "flex",
  gap: 6,
  flex: 1,
  flexDirection: "column",
  alignContent: "center",
  borderRadius: "8px 8px 0 0",
});

export const GoodPhoto = styled("img", {
  minHeight: 470,
  height: 470,
  objectFit: "fill",
  width: "100%",
  marginTop: "25px",
  borderBottom: "8px solid #176D38",
  borderRadius: "8px 8px 4px 4px",
});

export const GoodMark = styled("img", {
  alignSelf: "center",
  width: 24,
  height: 24,
});

export const BadPhoto = styled("img", {
  minHeight: 470,
  height: 470,
  objectFit: "fill",
  width: "100%",
  marginTop: "25px",
  borderBottom: "8px solid #C9201D",
  borderRadius: "8px 8px 4px 4px",
});

export const BadMark = styled("img", {
  alignSelf: "center",
  height: "24px",
  width: "24px",
});

export const Image = styled("img", {
  objectFit: "fill",
  width: "100%",
  height: "100%",
  borderRadius: "8px",
});
