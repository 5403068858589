import React, { ReactElement, FunctionComponent } from "react";
import TableRowBase from "@material-ui/core/TableRow";
import TableListViewStyle from "./TableListViewStyle";

interface Props {
  rowContent: ReactElement;
  testId: string;
}

const TableListRow: FunctionComponent<Props> = ({ rowContent, testId }) => {
  const classes = TableListViewStyle();

  return (
    <>
      <TableRowBase className={classes.row} data-testid={`${testId}-table-row`}>
        {rowContent}
      </TableRowBase>
    </>
  );
};

export default TableListRow;
