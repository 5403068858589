import Moment from "moment";

import * as GapCalculationUseCase from "@usecase/gap/GapCalculationUseCase";
import AgentCall from "@domains/AgentCall";
import { useLogService } from "admin-portal-shared-services";
import {
  FAILED_GAP_CALCULATION,
  FAILED_UPDATE_LOCAL_STORAGE,
} from "@config/logMessages";
import {
  CALL_ID,
  RESPONSEDATE_IN_MILLIS,
  CURRENTDATE_IN_MILLIS,
} from "../../../config/constants";
import {
  GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
  GROW_ORGANIZATION_ACTIVATION,
  isFeatureEnabledV2,
} from "../../../config/featureToggles";
import {
  getAccountIdFromAgentCallResponse,
  setPocVendorId,
} from "../../../config/utils/functions";
import * as GetOpenCallService from "../../../services/nextPoc/getOpenCall/GetOpenCallService";
import * as GetOpenCallServiceV3 from "../../../services/nextPoc/getOpenCallV3/GetOpenCallService";
import GlobalStore from "../../../stores/global/GlobalStore";
import * as getOpenCallEvents from "../../../stores/nextPoc/getOpenCall/GetOpenCallEvents";
import * as OrdersEvents from "../../../stores/order/OrdersEvents";
import {
  getSponsoredMissionsEvent,
  setSponsoredMissionsIds,
} from "../../../stores/sponsored/SponsoredEvents";
import {
  getTagsEvent,
  getTabsEvent,
  resetTags,
} from "../../../stores/tags/TagsEvents";

export function execute(
  userId: string,
  vendorId?: string,
  vendorName?: string,
): Promise<void> {
  const { user } = GlobalStore.getState();
  const log = useLogService();

  const isLocalAndGlobalMissionsServiceEnable = isFeatureEnabledV2(
    GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
    user.keyToggle,
  );

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    user.keyToggle,
  );

  const updateLocalStorageValues = (response: AgentCall) => {
    try {
      localStorage.setItem(CALL_ID, response.callId);
      if (response.reponseDate) {
        localStorage.setItem(
          RESPONSEDATE_IN_MILLIS,
          response.reponseDate.format("x"),
        );
        localStorage.setItem(CURRENTDATE_IN_MILLIS, Moment.utc().format("x"));
      }
    } catch (e) {
      log.error(FAILED_UPDATE_LOCAL_STORAGE, e);
    }
  };

  const getGapCalculation = async (response: AgentCall) => {
    try {
      const accountId = getAccountIdFromAgentCallResponse(response);
      const gap = await GapCalculationUseCase.execute(
        response.countryCode,
        response.vendorId,
        accountId,
      );
      getOpenCallEvents.getOpenCallDone({
        ...response,
        gap,
      });
    } catch (e) {
      log.error(FAILED_GAP_CALCULATION, e);
    }
  };

  getOpenCallEvents.getOpenCall(null);

  let headers = {};
  if (vendorId && vendorName) headers = { vendorId, vendorName };

  if (isOrganizationConfigEnabled) {
    return GetOpenCallServiceV3.getOpenCall(userId)
      .then((response) => {
        updateLocalStorageValues(response);
        getGapCalculation(response);
        setPocVendorId(response.vendorId);

        if (!isLocalAndGlobalMissionsServiceEnable) {
          if (response.tagsList && response.tagsList.length > 0)
            getTagsEvent(response.tagsList);
          else {
            getTagsEvent([]);
          }
          /* istanbul ignore else */
          if (response.tabs && response.tabs.length > 0)
            getTabsEvent(response.tabs);
          /* istanbul ignore else */
          if (
            response.tabs?.some((tab) => tab === "sponsored") &&
            response.sponsoredMissionsIds?.length
          ) {
            setSponsoredMissionsIds(response.sponsoredMissionsIds);
            getSponsoredMissionsEvent({
              clientId: response.clientId,
              missionsIds: response.sponsoredMissionsIds,
            });
          }
        } else {
          resetTags(null);
        }

        OrdersEvents.setOrdersOnGetOpenCall({
          ordersList: response.ordersList ?? [],
          hasTechnicalProblem: response.hasTechnicalProblem ?? false,
        });
      })
      .catch(() => {
        getOpenCallEvents.getOpenCallFail(null);
        /* istanbul ignore else */
        if (!isLocalAndGlobalMissionsServiceEnable) {
          getTagsEvent([]);
          getTabsEvent([]);
        }
      });
  }

  return GetOpenCallService.getOpenCall(userId, headers)
    .then((response) => {
      updateLocalStorageValues(response);
      getGapCalculation(response);

      if (isOrganizationConfigEnabled) {
        setPocVendorId(response.vendorId);
      }

      if (!isLocalAndGlobalMissionsServiceEnable) {
        if (response.tagsList && response.tagsList.length > 0)
          getTagsEvent(response.tagsList);
        else {
          getTagsEvent([]);
        }
        /* istanbul ignore else */
        if (response.tabs && response.tabs.length > 0)
          getTabsEvent(response.tabs);
        /* istanbul ignore else */
        if (
          response.tabs?.some((tab) => tab === "sponsored") &&
          response.sponsoredMissionsIds?.length
        ) {
          setSponsoredMissionsIds(response.sponsoredMissionsIds);
          getSponsoredMissionsEvent({
            clientId: response.clientId,
            missionsIds: response.sponsoredMissionsIds,
          });
        }
      } else {
        resetTags(null);
      }

      OrdersEvents.setOrdersOnGetOpenCall({
        ordersList: response.ordersList || [],
        hasTechnicalProblem: response.hasTechnicalProblem || false,
      });
    })
    .catch(() => {
      getOpenCallEvents.getOpenCallFail(null);
      /* istanbul ignore else */
      if (!isLocalAndGlobalMissionsServiceEnable) {
        getTagsEvent([]);
        getTabsEvent([]);
      }
    });
}
