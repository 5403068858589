import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { InputBase, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "../../assets/newIcons/SearchIcon";
import { offeringsSearchInteraction } from "../../config/typewriter";
import { useAnalytics } from "../../analytics/useAnalytics";
import { ENTER_KEY_CODE } from "../../config/constants";
import searchOfferingsBarStyles from "./SearchOfferingsBarStyles";

interface Props {
  value: string;
  handleSearchValue: (value: string) => void;
  onSearch: (type: string) => void;
  clearSearch?: () => void;
  testId: string;
  isPromotions?: boolean;
}

export enum SEARCH_OFFERINGS_METHOD {
  ENTER_KEY = "enter",
  GO_BUTTON = "go_button",
  SEARCH_FIELD = "search_field",
  CLEAR_BUTTON = "clear-button",
}

const SearchOfferingsBar: React.FC<Props> = ({
  value,
  handleSearchValue,
  onSearch,
  clearSearch,
  testId,
  isPromotions,
}) => {
  const classes = searchOfferingsBarStyles();
  const { dispatchGenericEvent } = useAnalytics();
  const firstRender = useRef(true);
  const [isSearched, setIsSearched] = useState(false);

  const setIsSearchedWithValue = () => {
    if (value && value !== "") {
      setIsSearched(true);
    }
  };

  const handleKeyDown = () => {
    setIsSearchedWithValue();
    onSearch(SEARCH_OFFERINGS_METHOD.ENTER_KEY);
  };

  const clearHandle = () => {
    setIsSearched(false);
    handleSearchValue("");
    dispatchGenericEvent(offeringsSearchInteraction, {
      button_label: SEARCH_OFFERINGS_METHOD.CLEAR_BUTTON,
      search_query: value,
      filter_option: "",
      screen_section: isPromotions ? "Promotions" : "To Recommend",
    });
  };

  const clearRef = useRef(clearSearch);
  const onSearchRef = useRef(onSearch);

  useEffect(() => {
    /* istanbul ignore next */
    if (value === "" && !firstRender.current) {
      if (clearRef.current) {
        clearRef.current();
      } else {
        onSearchRef.current(SEARCH_OFFERINGS_METHOD.GO_BUTTON);
      }
    }
    firstRender.current = false;
  }, [value]);

  function renderClearButton(): JSX.Element {
    if (isSearched || value) {
      return (
        <IconButton
          className={classes.searchClearOfferingsContainer}
          onClick={() => {
            clearHandle();
          }}
          data-testid="clear-button"
        >
          <CloseIcon style={{ width: 14, height: 14 }} />
        </IconButton>
      );
    }
    return <></>;
  }

  return (
    <div className={classes.searchOfferingsInputContainer}>
      <SearchIcon />
      <InputBase
        value={value}
        onChange={(e) => {
          handleSearchValue(e.target.value);
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.keyCode === ENTER_KEY_CODE) handleKeyDown();
        }}
        data-testid={`${testId}-input`}
        className={classes.searchInputOfferings}
      />

      {renderClearButton()}
    </div>
  );
};

export default React.memo(SearchOfferingsBar);
