import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { Grid, Button } from "@material-ui/core";
import UpdatePriceStyles from "./UpdatePriceStyles";
import CartItemStore from "../../../../../stores/cart/CartItemStore";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import { CURRENCY } from "../../../../../config/constants";
import { productQuantityEdited } from "../../../../../config/typewriter";
import { getCredit } from "../../../../../stores/credit/CreditEvents";
import {
  isFeatureEnabled,
  TLP_CREDIT_LIMIT,
} from "../../../../../config/featureToggles";

interface Props {
  onClicked: () => void;
  loading: boolean;
}

const UpdatePrice: React.FunctionComponent<Props> = ({
  onClicked,
  loading,
}) => {
  const { t } = useTranslation();
  const CartItemState = useStore(CartItemStore);
  const AgentCallStates = useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);
  const classes = UpdatePriceStyles();

  const isCreditLimitEnabled = isFeatureEnabled(
    TLP_CREDIT_LIMIT,
    user.keyToggle,
  );

  const handleClick = () => {
    onClicked();
    if (isCreditLimitEnabled) {
      getCredit(AgentCallStates.clientId);
    }
    CartItemState.cartItems.forEach((each) => {
      if (each.itemQuantity !== each.originalQuantity && !each.updated) {
        const displayInventoryCountOrAvailabilityCount =
          each.availabilityCount || each.inventoryCount || 0;

        productQuantityEdited({
          call_id: AgentCallStates.callId,
          country: user.zone,
          currency: CURRENCY[user.zone]?.currency,
          original_quantity: each.originalQuantity || null,
          poc_id: AgentCallStates.clientId,
          quantity: each.itemQuantity || 0,
          screen_section: "Order Cart",
          is_out_of_stock: displayInventoryCountOrAvailabilityCount === 0,
          combo_id: null,
          is_combo: false,
          edit_method: "Arrow or Input",
        });
      }
    });
  };

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="center"
      className={classes.updateTitle}
    >
      <Grid item>{t("OrderTaking.MSG_REFRESH")}</Grid>
      <Grid item className={classes.buttonContainer}>
        {loading ? (
          <CircularProgress size={20} disableShrink />
        ) : (
          <Button
            onClick={() => {
              handleClick();
            }}
            data-testid="update-cart-button"
            endIcon={<AutorenewIcon />}
            className={classes.updateButton}
          >
            {t("OrderTaking.REFRESH_LIST")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default UpdatePrice;
