import React from "react";
import { useTranslation } from "react-i18next";
import { EmptyItem as EmptyItemType } from "../../../../../domains/cart/EmptyItem";
import EmptyItemStyles from "./EmptyItemStyles";

interface Props {
  item: EmptyItemType[];
}

const TotalizerEmpties: React.FunctionComponent<Props> = ({ item }) => {
  const classes = EmptyItemStyles();
  const { t } = useTranslation();

  const total = item.length > 0 ? item.length : 0;

  return (
    <div className={classes.header} style={{ display: "flex", flex: 1 }}>
      <div
        className={classes.itemsNumber}
        style={{ justifyContent: "flex-end" }}
        data-testid="totalizer-empties-items"
      >
        {`${t("Empties.ITEMS")} ${total}`}
      </div>
    </div>
  );
};

export default React.memo(TotalizerEmpties);
