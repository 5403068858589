import React, { ReactElement, ReactNode, cloneElement } from "react";
import { Box } from "@material-ui/core";
import { Paragraph } from "@hexa-ui/components";
import styles from "./PromotionCardHeader.styles";

interface PromotionCardHeaderProps {
  icon: ReactElement;
  title: string;
  description?: string;
  children?: ReactNode;
}

export default function PromotionCardHeader({
  icon,
  title,
  description,
  children,
}: PromotionCardHeaderProps): JSX.Element {
  const classes = styles();

  return (
    <div>
      <Box className={classes.container}>
        {cloneElement(icon, {
          className: classes.icon,
        })}

        <div>
          <Paragraph
            size="small"
            className={classes.title}
            data-testid="promotion-card-header-title"
          >
            {title}
          </Paragraph>
          {description && (
            <Paragraph
              size="small"
              className={classes.description}
              data-testid="promotion-card-header-description"
            >
              {description}
            </Paragraph>
          )}
        </div>
      </Box>

      {children && (
        <div
          className={classes.child}
          data-testid="promotion-card-header-child"
        >
          {children}
        </div>
      )}
    </div>
  );
}
