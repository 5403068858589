import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { HeaderStyles } from "../HeaderStyles";

interface Props {
  textId: string;
  testId: string;
}

const Subtitle: React.FunctionComponent<Props> = ({ textId, testId }) => {
  const { t } = useTranslation();

  const footerClasses = HeaderStyles();

  const subtitle = () => {
    return t(textId);
  };

  return (
    <Typography
      variant="subtitle2"
      classes={{ root: footerClasses.subtitle }}
      data-testid={testId}
    >
      {subtitle()}
    </Typography>
  );
};

export default Subtitle;
