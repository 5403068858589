import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      borderLeft: "1px solid #DBDADA",
      paddingLeft: ".5rem",
    },
    contentContainer: {
      borderBottom: "1px solid #DBDADA",
      marginTop: ".25rem",
      paddingBottom: ".25rem",

      "&:first-child": {
        marginTop: 0,
      },

      "&:last-child": {
        borderBottom: 0,
        paddingBottom: 0,
      },
    },
    percentagePriceContainer: {
      display: "flex",
      alignItems: "baseline",
      gap: ".5rem",
    },
    uppercase: {
      textTransform: "uppercase",
    },
  }),
);
