import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    listItem: {
      backgroundColor: palette.common.white,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: 0,
      borderBottom: "1px solid #DBDADA",
    },
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: ".5rem",
    },
    contentFlex: {
      display: "flex",
      gap: "0.5rem",
    },
    infoContainer: {
      flexDirection: "column",
    },
    productDetails: {
      display: "grid",
      marginBottom: "0.5rem",
    },
    productName: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "0.75rem",
      fontWeight: 600,
      fontFamily: "barlow",
      lineHeight: "1rem",
      fontStyle: "normal",
    },
    additionalInfo: {
      display: "flex",
    },
    productSku: {
      color: "#757575",
      fontFamily: "Work Sans",
      fontWeight: 400,
      lineHeight: "1.125rem",
    },
    fullPackageDescription: {
      marginLeft: "10px",
      color: "#757575",
      fontFamily: "Work Sans",
      fontWeight: 400,
      lineHeight: "1.125rem",
    },
    packType: {
      height: "2rem",
      marginBottom: "0.75rem",
    },
    minQuantity: {
      lineHeight: "1.125rem",
      marginBottom: "0.375rem",
    },
    quantityContainer: {
      marginTop: "0.25rem",
      width: "100%",
    },
    quantity: {
      height: "2rem",
      display: "flex",
      gap: "1rem",
      alignItems: "center",
      marginBottom: "0.25rem",
      "& button[role='combobox']": {
        margin: 0,
        width: "122px",
      },
    },
    addButton: {
      width: "134px",
      height: "34px",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
    },
    variantsSelectContainer: {
      marginBottom: "0.5rem",
    },
  }),
);
