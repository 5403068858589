import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Box, Typography } from "@material-ui/core";
import ItemCardStyles from "./ItemCardStyles";
import Data from "../data/Data";

// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
const itemCardDefaultImage = require("../../assets/images/ProductPlaceholder.png");

interface Props {
  item: {
    itemName: string;
    image?: string;
  };
  testId: string;
  children: React.ReactNode;
}

const ItemCard: React.FC<Props> = ({ item, testId, children }) => {
  const classes = ItemCardStyles();
  const [src, setSrc] = useState(item.image);

  return (
    <Box>
      <Card className={classes.card} data-testId={testId}>
        <div className={classes.image}>
          <img
            src={src || itemCardDefaultImage}
            onError={() => setSrc(itemCardDefaultImage)}
            alt=""
          />
        </div>

        <CardContent className={classes.contentContainer}>
          <Typography className={classes.title} data-testId="item-card-title">
            <Data
              ellipsis
              ellipsisSize={55}
              value={item.itemName.toUpperCase()}
            />
          </Typography>

          {children}
        </CardContent>
      </Card>
    </Box>
  );
};

export default ItemCard;
