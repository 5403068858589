import { createStyles, makeStyles } from "@material-ui/core";
import styled from "styled-components";

const ProductCardV3Styles = makeStyles(({ palette, breakpoints }) =>
  createStyles({
    cardWrapper: {
      [breakpoints.down("lg")]: {
        width: "100%",
      },
      [breakpoints.up("xl")]: {
        width: "100%",
      },
    },
    recommendedCardItemImage: {
      width: "6.625rem",
      height: "6.625rem",
      marginRight: "8px",
    },
    divFlexAndCenterAlign: {
      display: "flex",
      alignItems: "center",
    },
    recommendedCardInformationSKU: {
      color: palette.text.secondary,
      marginTop: "3px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      whiteSpace: "normal",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      width: "fit-content",
      fontSize: "12px",
    },
    comboTitle: {
      color: "rgba(0,0,0,0.9)",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "normal",
      width: "fit-content",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
    comboDetails: {
      display: "grid",
      marginBottom: "4px",
    },
    comboDescription: {
      color: palette.text.secondary,
      fontFamily: "Work Sans",
      fontSize: "12px",
      letterSpacing: "0.03px",
      lineHeight: "18px",
    },
    recommendedCardInformationSuggested: {
      color: palette.text.secondary,
      fontFamily: "Work Sans",
      fontSize: "12px",
      letterSpacing: "0.03px",
      lineHeight: "18px",
    },
    recommendedCardInformationSuggestedRedesign: {
      color: palette.text.secondary,
      fontFamily: "Work Sans",
      fontSize: "12px",
      letterSpacing: "0.03px",
      lineHeight: "18px",
      marginTop: "6px",
    },
    recommendedCardInformationLineThrough: {
      textDecoration: "line-through",
      color: palette.text.secondary,
    },
    strikethroughPriceText: {},
    noRenderStrikethroughPriceText: {
      color: "transparent",
    },
    recommendedCardInformationPrice: {
      color: "black",
    },
    recommendedCardInformationPriceWithPromo: {
      color: "#176D38",
    },
    recommendedCardInformationPriceUnit: {
      color: palette.text.secondary,
      display: "inline",
      fontSize: 14,
      fontFamily: "Work Sans",
    },
    recommendedCardItemImageOvershadowed: {
      width: "6.625rem",
      height: "6.625rem",
      marginRight: "8px",
      opacity: "40%",
    },
    recommendedCardStockContainer: {
      height: "32px",
      marginTop: "10px",
      marginRight: "8px",
    },
    recommendedCardInformationTitle: {
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "25vh",
      fontWeight: 600,
    },
    recommendedCardInformationPromo: {
      borderRadius: 6.5,
      width: 41,
      height: 13,
      fontSize: 10,
      fontWeight: 600,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 4px",
      color: "rgba(255,255,255,0.95)",
      background: "#6A882D",
      verticalAlign: "middle",
      marginRight: "2px",
    },
    recommendedCardInformationCombo: {
      borderRadius: 6.5,
      width: 41,
      height: 13,
      fontSize: 10,
      fontWeight: 600,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 4px",
      color: "rgba(255,255,255,0.95)",
      background: "#007A78",
      verticalAlign: "middle",
      marginRight: "2px",
    },
    addButton: {
      minWidth: "59px",
      padding: "0 8px",
      fontSize: "14px",
      fontWeight: 500,
      borderRadius: "16px",
      height: "32px",
      marginLeft: "8px",
      backgroundColor: "#000000",
      fill: "solid",
    },
    addContainer: {
      display: "inline-flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    addedItemText: {
      marginLeft: "4px",
      height: "18px",
      fontSize: "12px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "18px",
    },
    addedItemTextLink: {
      marginLeft: "4px",
      height: "18px",
      fontSize: "12px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "18px",
      color: "#0371e2",
      textDecoration: "underline",
    },
    collapseIcon: {
      marginLeft: "5px",
      height: "fit-content",
    },
    tooltipTitleContainer: {
      margin: 0,
      padding: 0,
      borderRadius: "8px",
      maxWidth: "300px",
      backgroundColor: palette.common.black,
      boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.24)",
    },
    tooltipText: {
      fontSize: "14px",
      fontFamily: "Work Sans",
      fontWeight: 500,
      color: "rgba(255, 255, 255, 0.95)",
    },
    tooltipArrow: {
      color: palette.common.black,
      boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.24)",
    },
    stockTooltip: {
      margin: "6px 0 0 0",
      padding: 0,
      borderRadius: "8px",
      maxWidth: "220px",
      backgroundColor: palette.common.black,
      boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.24)",
    },
    comboTitleText: {
      fontWeight: 600,
      display: "inline",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    recommendedCardInformationTitleTooltip: {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: 600,
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
    recommendedCardShowMoreInfo: {
      display: "inline",
      marginLeft: ".25rem",
      whiteSpace: "nowrap",
      background: palette.background.paper,
      color: "#0363C4",
      fontSize: "12px",
      cursor: "pointer",
    },
    stockContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    InfoOutlined: {
      width: "18px",
      height: "18px",
    },
    pricePerUnit: {
      marginLeft: "0.8rem",
      marginTop: "1.2px",
      color: "rgba(0,0,0,0.55)",
    },
    discountRangesValues: {
      marginLeft: 4,
      marginBottom: "-15px",
    },
    productTop: {
      paddingTop: "32px",
    },
    steppedDiscountPrice: {
      display: "flex",
      marginTop: 15,
    },
    steppedDiscountPriceSpan: {
      margin: "0 4px",
      color: "#176D38",
    },
    packagingInfoLabel: {
      fontWeight: 500,
      color: palette.text.secondary,
    },
    packagingInfoText: {
      fontWeight: 500,
      color: "#141414",
    },
  }),
);

const ProductDetails = styled.div`
  margin-top: 8px;
`;

const StockInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RecommendedCardInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const RecommendedCardActions = styled.div`
  height: 32px;
  margin-top: 10px;
  display: flex;
`;

const RecommendedCardPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoStock = styled.div`
  font-size: 0.75rem;
  padding: 0.5rem 0;
  color: rgba(0, 0, 0, 0.55);
`;

const InfoOutStock = styled.div`
  font-size: 0.75rem;
  padding: 0.5rem 0;
  color: #c9201d;
`;

const PackagingInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 0.6rem 0;
  gap: 4px;
`;

const ProductCardContainer = styled.div`
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  display: flex;
  padding-top: 8px;
  padding-bottom: 16px;
`;

const ProductPriceContainer = styled.div`
  display: flex;
  align-items: center;
`;

export {
  ProductCardV3Styles,
  ProductDetails,
  StockInformation,
  RecommendedCardInformationContainer,
  RecommendedCardActions,
  RecommendedCardPriceContainer,
  ProductCardContainer,
  InfoStock,
  InfoOutStock,
  PackagingInfo,
  ProductPriceContainer,
};
