import { AgentSurvey } from "../../domains/AgentSurvey";
import {
  AgentSurveyResponse,
  AgentSurveyResponseV3,
} from "../../services/agentSurvey/domains/AgentSurveyResponse";

export default function AgentSurveyResponseToAgentSurvey(
  responseData: AgentSurveyResponse | AgentSurveyResponseV3,
): AgentSurvey {
  const responseDataV3 = (responseData as AgentSurveyResponseV3).data;
  const isResponseV3 = Array.isArray(responseDataV3);

  if (isResponseV3) {
    const { surveyId, link: surveyLink } = responseDataV3[0];
    return { surveyId, surveyLink };
  }

  const { surveyId, surveyLink } = responseData as AgentSurveyResponse;
  return { surveyId, surveyLink };
}
