import Catalog, {
  CatalogItem,
  ItemContainer,
  Price,
  PriceDetails,
  PriceDetailsItem,
} from "../../../domains/Catalog";
import CatalogResponseV3, {
  Item,
} from "../../../services/catalog/v3/domains/CatalogResponseV3";
import MarketplaceStore from "../../../stores/marketplace/MarketplaceStore";
import { hasMarketplace } from "../../../config/utils/functions";

const CatalogResponseToCatalogConverterV3 = (
  data: CatalogResponseV3,
): Catalog => {
  return { items: enrichCatalogDomain(data.items) };
};

const CatalogMultipleConverterV3 = (items: Item[]): Catalog => {
  return { items: enrichCatalogDomain(items) };
};

const enrichCatalogDomain = (items: Item[]): Array<CatalogItem> => {
  const marketplaceState = MarketplaceStore.getState();

  const { marketplaceList } = marketplaceState;

  const catalogItems = items?.map((item) => {
    const itemData = item.item;
    const vendorItemId = itemData.sourceData?.vendorItemId ?? itemData.sku;

    const isMarketplace = hasMarketplace(vendorItemId, marketplaceList);

    return <CatalogItem>{
      sku: itemData.sku,
      price: item.price.price,
      originalPrice: item.price.originalPrice,
      stockAvailable: item.inventory.stockAvailable,
      name: itemData.itemName,
      image: itemData.itemImage,
      brandName: itemData.brandName,
      classification: itemData.classification,
      packagename: itemData.package.name,
      marketplace: isMarketplace,
      inventoryCount: item.inventory.inventoryCount,
      availabilityCount: item.availability.count,
      palletQuantity: itemData.palletQuantity,
      fullPackageDescription: itemData.package?.fullPackageDescription,
      fullContainerDescription: itemData.container.fullContainerDescription,
      pricePerUoM: item.price.pricePerUoM,
      id: itemData.id,
      vendorItemId,
      container: <ItemContainer>{
        name: itemData.container.name,
        returnable: itemData.container.returnable,
        unitOfMeasurement: itemData.container.unitOfMeasurement,
        itemSize: itemData.container.itemSize,
        fullContainerDescription: itemData.container.fullContainerDescription,
        material: itemData.container.material,
      },
      priceData: <Price>{
        pricePerUoM: item.price.pricePerUoM,
        pricePerContainer: item.price.pricePerContainer,
        price: item.price.price,
        priceDetails: <PriceDetails>{
          pack: <PriceDetailsItem>{
            preferredPrice: item.price.priceDetails.pack.preferredPrice,
            price: item.price.priceDetails.pack.price,
          },
          container: <PriceDetailsItem>{
            preferredPrice: item.price.priceDetails.container.preferredPrice,
            price: item.price.priceDetails.container.price,
          },
          uom: <PriceDetailsItem>{
            preferredPrice: item.price.priceDetails.uom.preferredPrice,
            price: item.price.priceDetails.uom.price,
          },
        },
      },
      platformId: item.platformId,
    };
  });

  return catalogItems;
};

export { CatalogResponseToCatalogConverterV3, CatalogMultipleConverterV3 };
