import { styled } from "@bees/vision-tokens";

const StatistcsContainer = styled("div", {
  boxShadow: "0 3px 5px 0 rgb(0 0 0 / 16%)",
  borderRadius: "15px 15px 15px 15px",
  backgroundColor: "#ffffff",
  alignItems: "center",
  marginTop: 11,
  marginBottom: 25,
});

const StatistcsSession = styled("div", {
  display: "flex",
  margin: "25px 20px 18px",
});

const StatistcsSessionDescription = styled("div", {
  marginLeft: 20,
  marginRight: 20,
  marginBottom: 20,
});

const StatistcsItem = styled("div", {
  flex: 1,
  display: "flex",
  alignItems: "center",
  fontSize: 16,
  paddingLeft: 12,
});

const StatistcsDescription = styled("div", {
  flex: 1,
  display: "flex",
  alignItems: "center",
  color: "rgba(0,0,0,0.55)",
});

export {
  StatistcsContainer,
  StatistcsSession,
  StatistcsSessionDescription,
  StatistcsItem,
  StatistcsDescription,
};
