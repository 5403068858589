import React from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "@hexa-ui/components";

interface DeliveryRangeProps {
  minDays: number;
  maxDays: number;
}

const DeliveryRange = ({
  minDays,
  maxDays,
}: DeliveryRangeProps): JSX.Element => {
  const { t } = useTranslation();

  const message = `${t(
    "OrderTaking.DELIVERY_RANGE_1",
  )} ${minDays}-${maxDays} ${t("OrderTaking.DELIVERY_RANGE_2")}`;

  return (
    <Alert
      data-testid="range-alert"
      css={{ width: "100%" }}
      message={message}
    />
  );
};

export default DeliveryRange;
