import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "space-between",

      "& > div > div:first-child": {
        maxWidth: "7.5rem",
        minWidth: "7.5rem",
      },
    },
    stockText: {
      marginTop: "-0.625rem",
      textAlign: "center",
    },
    steppedDiscountStockText: {
      textAlign: "left",
    },
    lineThrough: {
      textDecoration: "line-through",
    },
    steppedDiscountText: {
      fontFamily: "Work Sans",
      color: "#757575",
      fontSize: "0.75rem",
      fontWeight: 400,
      textDecoration: "line-through",
    },
  }),
);
