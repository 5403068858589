import React from "react";
import { TableCell, Typography } from "@material-ui/core";
import { ProductListStyles } from "../ProductListStyles";

interface Props {
  label: string;
  dataTest: string;
  alignLeft?: boolean;
}

function TableCellHeader({
  label,
  dataTest,
  alignLeft,
}: Props): React.ReactElement {
  const styledClasses = ProductListStyles();

  return (
    <TableCell
      align={alignLeft ? "left" : "right"}
      classes={{ root: styledClasses.tableHeader }}
      data-testid={dataTest}
    >
      <Typography
        component="h5"
        variant="h5"
        className={styledClasses.cellTitle}
        data-testid={`${dataTest}-text`}
      >
        {label}
      </Typography>
    </TableCell>
  );
}

TableCellHeader.defaultProps = {
  alignLeft: false,
};

export default TableCellHeader;
