import React from "react";
import { useHistory } from "react-router";
import { Box, Grid, Divider, Typography, ButtonBase } from "@material-ui/core";
import GenericErrorPageStyles from "./ServerErrorStyles";
import {
  LINK_HOME,
  ANALYTICS_ROUTE_NAMES,
  LINK_SERVER_ERROR,
} from "../../../config/constants";
import { errorMessageViewed } from "../../../config/typewriter";
import { useAnalytics } from "../../../analytics/useAnalytics";

interface Props {
  errorCode: string;
  errorMessage: string;
  buttonLabel: string;
}

function GenericErrorPage({
  errorCode,
  errorMessage,
  buttonLabel,
}: Props): React.ReactElement {
  const classes = GenericErrorPageStyles();
  const history = useHistory();
  const { dispatchPocEvent } = useAnalytics();

  const goToHomePage = () => {
    history.push(LINK_HOME);
  };
  React.useEffect(() => {
    dispatchPocEvent(
      errorMessageViewed,
      {
        error_message: errorMessage,
        error_type: errorCode,
        screen_name: ANALYTICS_ROUTE_NAMES.get(LINK_SERVER_ERROR),
        screen_section: null,
        is_all_products: null,
        total_gap: null,
        total_vol_gap: null,
      },
      { time_of_day: true, is_resumed: true },
    );
  }, [errorMessage, errorCode, dispatchPocEvent]);

  return (
    <Box
      data-testid="page-not-found"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      className={classes.container}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography
            component="h1"
            className={classes.errorCode}
            data-testid="error-code-label"
          >
            {errorCode}
          </Typography>
        </Grid>
        <Grid item>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item>
          <Typography
            component="h5"
            className={classes.errorMessage}
            data-testid="error-message-label"
          >
            {errorMessage}
          </Typography>
        </Grid>
        <Grid item>
          <ButtonBase
            type="button"
            onClick={() => goToHomePage()}
            className={classes.reloadButton}
            data-testid="error-action-button"
          >
            {buttonLabel}
          </ButtonBase>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GenericErrorPage;
