import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CTIModalGeneric from "../modal/CTIModalGeneric";

interface Props {
  open: boolean;
  onClose: () => void;
  connectWebSocket?: () => void;
  dispatchErrorModalViewed: () => void;
  retryCount: number;
  loading: boolean;
  retryDisabled: boolean;
}

function CTILostConnectionModal({
  onClose,
  open,
  connectWebSocket,
  dispatchErrorModalViewed,
  retryCount,
  loading,
  retryDisabled,
  ...props
}: Props): React.ReactElement {
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      dispatchErrorModalViewed();
    }
  }, [dispatchErrorModalViewed, open]);

  return (
    <CTIModalGeneric
      {...props}
      titleModal={t("CTIbrazil.MODAL_TITLE_CALL")}
      messageFailConect={t("CTIbrazil.MODAL_LOST_CONNECTION")}
      messageWhithoutCti=""
      attemptsLabel={t("CTIbrazil.ATTEMPTS")}
      mainButton={t("CTIbrazil.BTN_REC")}
      secondaryButton={t("CTIbrazil.CONTINUE")}
      onClose={onClose}
      open={open}
      connectWebSocket={connectWebSocket}
      retryCount={retryCount}
      loading={loading}
      retryDisabled={retryDisabled}
    />
  );
}

CTILostConnectionModal.defaultProps = {
  connectWebSocket: undefined,
};

export default CTILostConnectionModal;
