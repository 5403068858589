import { getApiHost } from "@services/host/HostService";
import { SMART_CALL_QUEUES } from "@config/services";
import axios from "@config/axios/axiosInstance";
import {
  useAuthenticationService,
  useLogService,
} from "admin-portal-shared-services";
import { v4 as guid } from "uuid";
import GapCalculationToGapConverter from "@converters/gap/GapCalculationToGapConverter";
import { Gap } from "@stores/agentCall/AgentCallState";
import GapCalculation from "./domains/GapCalculation";

export const SERVICE_NAME = "GapCalculationService";
export const GET_CAP_CALCULATION_V3 = "getGapCalculationV3";

export function getGapCalculationV3(
  country: string,
  vendorId: string,
  accountId: string,
  newGapCalculation: boolean,
): Promise<Gap> {
  const log = useLogService();

  const url = `${getApiHost(SMART_CALL_QUEUES)}/v3/accounts/${accountId}/gap`;

  const headers = {
    country,
    Authorization: useAuthenticationService().getAuthHeader(),
    requestTraceId: guid(),
    vendorId,
  };

  const params = {
    newGapCalculation,
  };

  return axios
    .get<GapCalculation>(url, {
      headers,
      params,
    })
    .then((response) => {
      log.info(
        `${SERVICE_NAME}.${GET_CAP_CALCULATION_V3} - converting calculation received from ${url} to our model`,
      );

      return GapCalculationToGapConverter(response.data);
    })
    .catch((error) => {
      log.error(
        `${SERVICE_NAME}.${GET_CAP_CALCULATION_V3} - GET ${url} throws: `,
        error,
      );
      throw error;
    });
}
