import React, { useEffect } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider, Day } from "@material-ui/pickers";
import Moment from "moment";
import classNames from "classnames";
import { DeliveryWindowsResponse } from "../../../../services/pocInformation/domains/DeliveryWindowsResponse";
import DeliveryWindows from "../../../../domains/deliveryWindow/DeliveryWindows";
import { DATE_FORMAT_STRING } from "../../../../config/constants";
import GlobalStore from "../../../../stores/global/GlobalStore";
import DeliveryDateStyle from "./DeliveryDateStyle";
import {
  GROW_CART_SERVICE_V4,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";
import { getCountryByToken } from "../../../../config/utils/functions";

interface Props {
  deliveryWindows?: Array<DeliveryWindowsResponse>;
  deliveryWindowsNew?: Array<DeliveryWindows>;
  value: string;
  onChange: (date: string, deliveryWindowId?: string) => void;
}

const DeliveryDate: React.FunctionComponent<Props> = ({
  value,
  deliveryWindows,
  deliveryWindowsNew,
  onChange,
}) => {
  const classes = DeliveryDateStyle();
  const { t } = useTranslation();
  const globalState = useStore(GlobalStore);
  const [, forceUpdate] = React.useState({});

  const isDeliveryWindowsMultiContractEnabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    getCountryByToken(),
  );

  const disableDates = (date: Moment.Moment | null) => {
    if (!isDeliveryWindowsMultiContractEnabled) {
      const availableDate = deliveryWindows?.find((d) => {
        const startDate = Moment(d.startDate);
        const endDate = Moment(d.endDate);

        const momentDate = Moment(date);
        const isBetween = momentDate.isBetween(startDate, endDate, "day");

        return (
          isBetween ||
          momentDate.isSame(startDate, "day") ||
          momentDate.isSame(endDate, "day")
        );
      });

      return availableDate === undefined;
    }

    const availableDate = deliveryWindowsNew?.find((d) => {
      const deliveryWindow = Moment(d.deliveryDate);
      const momentDate = Moment(date);

      return momentDate.isSame(deliveryWindow, "day");
    });

    return availableDate === undefined;
  };

  /* istanbul ignore next */
  const getDeliveryWindowsId = (date: string): string => {
    return (
      deliveryWindowsNew?.find((item) => item.deliveryDate === date)
        ?.deliveryWindowId ?? ""
    );
  };

  /* istanbul ignore next */
  const renderDayInPicker = (
    date,
    selectedDate,
    _dayInCurrentMonth: boolean,
    dayComponent,
  ) => {
    if (isDeliveryWindowsMultiContractEnabled) {
      if (
        deliveryWindowsNew?.find(
          (delivery) =>
            delivery.alternative &&
            delivery.deliveryDate === date.format("YYYY-MM-DD") &&
            _dayInCurrentMonth,
        )
      ) {
        return (
          <div className={classes.boxAlterantiveDay}>
            <Day
              {...dayComponent}
              className={classNames(classes.alternativeDay, {
                [classes.activeSelected]:
                  selectedDate.format("YYYY-MM-DD") ===
                  date.format("YYYY-MM-DD"),
              })}
            >
              {date.format("D")}
            </Day>
          </div>
        );
      }
    } else if (
      deliveryWindows?.find(
        (delivery) =>
          delivery.alternative &&
          delivery.startDate === date.format("YYYY-MM-DD") &&
          _dayInCurrentMonth,
      )
    ) {
      return (
        <div className={classes.boxAlterantiveDay}>
          <Day
            {...dayComponent}
            className={classNames(classes.alternativeDay, {
              [classes.activeSelected]:
                selectedDate.format("YYYY-MM-DD") === date.format("YYYY-MM-DD"),
            })}
          >
            {date.format("D")}
          </Day>
        </div>
      );
    }
    return dayComponent;
  };

  useEffect(() => {
    Moment.updateLocale("newLocale", {
      months: t("Calendar.MONTHS").split("_"),
      weekdaysShort: t("Calendar.WEEKDAYS_SHORT").split("_"),
    });
    forceUpdate({});
  }, [t, globalState.language]);

  return (
    <MuiPickersUtilsProvider
      libInstance={Moment}
      utils={MomentUtils}
      locale="newLocale"
    >
      <DatePicker
        name="deliveryDate"
        disableToolbar
        orientation="landscape"
        variant="static"
        openTo="date"
        value={value.split("T")[0]}
        onChange={(date) => {
          if (isDeliveryWindowsMultiContractEnabled) {
            onChange(
              Moment(date).format(DATE_FORMAT_STRING),
              getDeliveryWindowsId(Moment(date).format("YYYY-MM-DD")),
            );
            return;
          }

          onChange(Moment(date).format(DATE_FORMAT_STRING));
        }}
        shouldDisableDate={disableDates}
        renderDay={renderDayInPicker}
        data-testid="deliverydate-datepicker"
      />
    </MuiPickersUtilsProvider>
  );
};

export default React.memo(DeliveryDate);
