import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      width: "100%",
      height: 42,
      display: "flex",
      alignItems: "baseline",
    },
  }),
);
