import React from "react";
import { Tooltip as MaterialTooltip } from "@material-ui/core";
import { useTooltipStyles } from "./TooltipCard.styles";

interface Props {
  children: React.ReactElement;
  value: string | React.ReactElement;
  testid?: string;
}

const Tooltip = ({ children, value, testid, ...props }: Props): JSX.Element => {
  const classes = useTooltipStyles();

  return (
    <MaterialTooltip
      title={value}
      classes={{ tooltip: classes.tooltip }}
      data-testid={testid}
      {...props}
    >
      {children}
    </MaterialTooltip>
  );
};

export default Tooltip;
