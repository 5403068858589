/* istanbul ignore file */
import { makeStyles, createStyles } from "@material-ui/core/styles";

const NewPhoneModalStyles = makeStyles(() =>
  createStyles({
    container: {
      width: "42.5rem",
      height: "20rem",
      padding: "0.70rem 0.75rem 2rem 2rem",
    },
    header: {
      display: "flex",
      alignItems: "flex-start",
    },
    closeButton: {
      display: "flex",
      marginLeft: "auto",
    },
    title: {
      fontSize: "1.25rem",
    },
    headerMessageContainer: {
      width: 1053,
      height: "auto",
      position: "absolute",
      top: 0,
    },
  }),
);

export default NewPhoneModalStyles;
