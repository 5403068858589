import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    challengesCardContainer: {
      marginTop: "15px",
      display: "grid",
      gap: "16px",
      gridTemplateColumns: "repeat(3, [col-start] auto [col-end])",
      justifyContent: "space-between",
    },
    centralizedContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "2rem",
    },
    noDataAvailable: {
      fontSize: "16px",
      color: palette.secondary.main,
      paddingBottom: "14px",
      textAlign: "center",
    },
  }),
);
