import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import axios from "../../../config/axios/axiosInstance";
import {
  GROW_ORGANIZATION_ACTIVATION,
  TLP_MULTICONTRACT_AGENTS_KPI_SERVICE,
  isFeatureEnabledV2,
} from "../../../config/featureToggles";
import { AGENTS_KPI_SERVICE } from "../../../config/services";
import MonthlyKPIResponseToMonthlyKPIConverter from "../../../converters/agentKPI/MonthlyKPIResponseToMonthlyKPIConverter";
import MonthlyKPIResponseV3ToMonthlyKPIConverter from "../../../converters/agentKPI/MonthlyKPIResponseV3ToMonthlyKPIConverter";
import MonthlyKPIDomain from "../../../domains/agentKPI/MonthlyKPIDomain";
import GlobalStore from "../../../stores/global/GlobalStore";
import VendorStore from "../../../stores/vendor/VendorStore";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../../host/HostService";
import MonthlyKPIResponse, {
  MonthlyKPIResponseV3,
} from "../domains/MonthlyKPIResponse";

export const AGENTS_KPI_ENDPOINT = "/agents-kpi";
export const AGENTS_KPI_ENDPOINT_V3 = "/v3/agents";
export const MONTHLY_KPI_ENDPOINT = "get-monthly-kpi";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});
export function getMonthlyKPI(
  agentId: string,
  country: string,
  vendorId: string,
  vendorName: string,
  authorization?: string,
  requestTraceId?: string,
): Promise<MonthlyKPIDomain | undefined> {
  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();
  const log = useLogService();

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const isTlpAgentsKpiServiceMultiContractEnabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_AGENTS_KPI_SERVICE,
    user.zone,
  );
  const vendorID = vendor?.id;
  const apiV2 = getV2EndPoint(isTlpAgentsKpiServiceMultiContractEnabled);
  const vendorIdParam = getVendorIdParam(
    vendorID,
    isTlpAgentsKpiServiceMultiContractEnabled,
  );

  const endpoint = isOrganizationConfigEnabled
    ? `${AGENTS_KPI_ENDPOINT_V3}/${agentId}/kpis/monthly`
    : `${AGENTS_KPI_ENDPOINT}${apiV2}/${agentId}/${MONTHLY_KPI_ENDPOINT}${vendorIdParam}`;

  const urlagents = `${getApiHost(AGENTS_KPI_SERVICE)}${endpoint}`;

  const getHeaders = () => {
    if (authorization && requestTraceId)
      return {
        country,
        vendorId,
        vendorName,
        authorization,
        requestTraceId,
      };
    return {
      country,
      vendorId,
      vendorName,
    };
  };

  if (isOrganizationConfigEnabled) {
    return axios
      .get<MonthlyKPIResponseV3>(urlagents)
      .then((response) => {
        log.info("Convert MonthlyKPI score received from to model");
        return MonthlyKPIResponseV3ToMonthlyKPIConverter(response.data);
      })
      .catch((error) => {
        log.error(error);
        throw error;
      });
  }

  return axios
    .get<MonthlyKPIResponse>(urlagents, {
      headers: getHeaders(),
    })
    .then((response) => {
      log.info("Convert MonthlyKPI score received from to model");
      return MonthlyKPIResponseToMonthlyKPIConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
