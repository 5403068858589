import React from "react";
import { useTranslation } from "react-i18next";
import useMissionsProductsStyles from "./style";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/ErrorHandlerRetry";
import { ANALYTICS_ROUTE_NAMES, LINK_CALL } from "../../../../config/constants";
import LoadingComponent from "../../../../components/loadingView/LoadingView";
import MissionsProductsContainer from "./missionsProductsContainer/MissionsProductsContainer";

interface Props {
  Loading: boolean;
  error: boolean;
  Items?: CampaignsItems;
  onRetryHandler: () => void;
  screenSection: string;
}
type CampaignsItems = Array<{
  sku: string;
  productName: string;
  unitPrice: number;
  image: string;
  tag: string;
  campaignName: string;
  platformId?: string;
}>;

const MissionsProducts: React.FC<Props> = ({
  Loading,
  error,
  Items,
  onRetryHandler,
  screenSection,
}) => {
  const classes = useMissionsProductsStyles();
  const { t } = useTranslation();

  const noResultsText = () => (
    <div
      className={classes.noMissionsProductsText}
      data-testid="no-missions-products-items-find"
    >
      {t("TargetProducts.NOT_FOUND")}
    </div>
  );

  const renderProductsOrNoProductsFound = () => {
    if (!Items || Items.length === 0) {
      return noResultsText();
    }
    return <MissionsProductsContainer campaignItems={Items} />;
  };

  const renderContent = () =>
    error ? (
      <ErrorHandlerRetry
        onClick={onRetryHandler}
        testId="missions-products-products"
        screenName={ANALYTICS_ROUTE_NAMES.get(LINK_CALL)}
        screenSection={screenSection}
      />
    ) : (
      renderProductsOrNoProductsFound()
    );

  return (
    <div data-testid="missions-products-container">
      {Loading ? (
        <div className={classes.loading}>
          <LoadingComponent />
        </div>
      ) : (
        renderContent()
      )}
    </div>
  );
};

export default MissionsProducts;
