import { createStore } from "effector";
import { CampaignsB2OProductsState } from "./CampaignsB2OProductsState";
import {
  getCampaignsB2OProducts,
  setCampaignB2OProducts,
  resetCampaignB2OProducts,
  clearCampaignB2OProductsDb,
} from "./CampaignsB2OProductsEvents";
import CampaignsB2OProductsDb from "../../../routes/call/campaignsB2OProductsDB/CampaignB2OProductDB";

const initialState = <CampaignsB2OProductsState>{
  campaignsProducts: [],
  loading: false,
  error: false,
  done: false,
  clientId: "",
};

const CampaignsB2OProductsStore = createStore(initialState)
  .on(getCampaignsB2OProducts.done, (state, value) => {
    return {
      ...state,
      campaignsProducts: value.result.campaignsProducts,
      loading: false,
      error: false,
      done: true,
    };
  })
  .on(getCampaignsB2OProducts.fail, (state) => {
    return {
      ...state,
      isLoading: false,
      loading: false,
      error: true,
      done: true,
    };
  })
  .on(getCampaignsB2OProducts, (state) => {
    return {
      ...state,
      loading: true,
      error: false,
      done: false,
    };
  })
  .on(clearCampaignB2OProductsDb, () => {
    new CampaignsB2OProductsDb().CampaignB2OProducts.toCollection().delete();
    return {
      ...initialState,
    };
  })
  .on(setCampaignB2OProducts, (state, value) => {
    return {
      ...state,
      campaignsProducts: value,
    };
  })
  .reset(resetCampaignB2OProducts);

export default CampaignsB2OProductsStore;
