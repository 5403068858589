import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "16px",
      maxHeight: "380px",
      overflowY: "auto",
    },
    innerContent: {
      display: "flex",
      flexDirection: "row",
    },
    innerLeft: {
      width: "60%",
    },
    innerRight: {
      width: "40%",
    },
    missionsTitle: {
      width: "60%",
      fontSize: "12px",
      fontWeight: 600,
      color: palette.text.secondary,
      paddingBottom: "12px",
    },
    completionTitle: {
      width: "40%",
      fontSize: "12px",
      fontWeight: 600,
      color: palette.text.secondary,
      paddingBottom: "12px",
    },
    noMissionsText: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "330px",
      fontSize: "12px",
      fontWeight: "bold",
      color: palette.secondary.main,
      textAlign: "center",
    },
  }),
);
