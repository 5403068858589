import * as React from "react";

function ScriptIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      {...props}
    >
      <defs>
        <path
          id="path-1"
          d="M26.402 0h-1.2C21.662-.009 18.165.82 15 2.418a22.794 22.794 0 00-12.597 20.38l.015.883a22.504 22.504 0 001.668 7.71l.294.682L.127 44.837l-.07.255c-.393 1.779 1.315 3.378 3.106 2.78l12.764-4.256.682.297a22.51 22.51 0 008.6 1.683A22.796 22.796 0 0045.586 32.99 22.502 22.502 0 0048 22.792v-1.194C47.37 10.105 38.452.986 27.175.048L26.402 0zm-1.206 4.8l1.105-.003h-.031c9.135.503 16.43 7.798 16.934 16.934l-.004 1.067a17.718 17.718 0 01-1.902 8.037 18.002 18.002 0 01-16.097 9.962 17.716 17.716 0 01-8.036-1.903l-.293-.124a2.4 2.4 0 00-1.548-.01l-9.128 3.042 3.044-9.126a2.4 2.4 0 00-.134-1.84 17.71 17.71 0 01-1.903-8.032 18 18 0 019.953-16.098 17.722 17.722 0 018.04-1.906z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(8 8)">
          <mask id="mask-2" fill="#fff">
            <use xlinkHref="#path-1"></use>
          </mask>
          <g fill="#000" fillOpacity="0.9" mask="url(#mask-2)">
            <path d="M0 0H64V64H0z" transform="translate(-8 -8)"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ScriptIcon;
