import { useCallback, useMemo, useState } from "react";

import { useStore } from "effector-react";

import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import pocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import { addItem as addPromotionItem } from "../../../../../stores/cart/CartItemEvents";
import { addItem as addComboItem } from "../../../../../stores/cartCombo/CartComboEvents";
import PromotionsStore from "../../../../../stores/promotions/PromotionsStore";
import { PROMOTIONS_ABA_TYPES } from "../../../../../config/constants";
import { Combo } from "../../../../../domains/promotions/Combo";
import { FormatedPromotion } from "../../../../../domains/promotions/FormatedPromotion";
import { Promotions } from "../../../../../domains/promotions/Promotions";
import {
  offeringsProductAdded,
  offeringsProductQuantityEdited,
} from "../../../../../config/typewriter";
import GetRemainingDays from "../../../../../helpers/GetRemainingDays";

interface UsePromotionsHookProps {
  formatedPromotions: FormatedPromotion[];
  itemQuantity: number;
  showCartLink: boolean;
  changeSelectablePromotion: (key: PROMOTIONS_ABA_TYPES) => void;
  addItemToCart: (promotion: FormatedPromotion) => void;
  handleChangeQuantity: (alteredQuantity: number) => void;
  showMoreInfoComponentProps: (promotion: FormatedPromotion) => {
    dailyBalance?: number | null;
    monthlyBalance?: number | null;
    remainingDays?: number | null;
  };
}

const formatPromotion = (promotion: Promotions) => ({
  key: `${promotion.id}-${promotion.platformId}`,
  platformId: promotion.platformId,
  itemId: promotion.itemId,
  sku: promotion.sku,
  itemName: promotion.itemName,
  name: promotion.title,
  type: promotion.type,
  items: [
    {
      id: promotion.id,
      sku: promotion.sku,
      title: promotion.itemName,
      description: `${promotion.fullContainerDescription} • ${promotion.fullPackageDescription}`,
      quantity: promotion.palletQuantity,
    },
  ],
  titleFreegoodSection:
    promotion.freeGoods?.flatMap((item) => item.freegoodItems).length > 1
      ? "Promotions.GET_THESE_FREE_GOODS"
      : "Promotions.GET_THIS_FREE_GOOD",
  orderItemsLabel: "Promotions.ORDER_THIS_PRODUCT",
  freegoodsCombo: [],
  freegoods: promotion.freeGoods,
  freegoodsPromotion: promotion.freeGoods
    ?.flatMap((item) => item.freegoodItems)
    .map((freegoodItem) => {
      return {
        sku: freegoodItem?.sku,
        title: freegoodItem?.name,
        description: `${freegoodItem?.fullContainerDescription} • ${freegoodItem?.fullPackageDescription}`,
        quantity: freegoodItem?.quantity ?? 0,
      };
    }),
  description: promotion.description,
  originalPrice: promotion.originalPrice,
  discountedPrice:
    promotion.prices?.length === 1
      ? promotion.prices?.[0]?.discountedPrice
      : undefined,
  steppedDiscountedPrice:
    promotion.prices && promotion.prices?.length > 1
      ? promotion.prices
      : undefined,
  stockCount: promotion.availability?.count ?? 0,
  inventoryCount: promotion.inventoryCount,
  palletQuantity: promotion.palletQuantity,
  packageUnitCount: promotion.packageUnitCount,
  packageItemCount: promotion.packageItemCount,
  availability: promotion.availability,
  limit: promotion.limit,
  remainingDays: undefined,
  endDate: promotion.endDate,
  isCombo: false,
  isMarketplace: !!promotion.marketplace,
});

const formatCombo = (combo: Combo) => ({
  key: `${combo.id}-${combo.vendorComboId}`,
  platformId: combo.platformId,
  itemId: combo.vendorComboId ?? "",
  id: combo.id,
  sku: "",
  itemName: combo.title,
  name: combo.title,
  type: combo.type,
  titleFreegoodSection:
    combo.freeGoods?.length > 1
      ? "Promotions.GET_THESE_FREE_GOODS"
      : "Promotions.GET_THIS_FREE_GOOD",
  orderItemsLabel:
    combo.items?.length > 1
      ? "Promotions.ORDER_THESE_PRODUCTS"
      : "Promotions.ORDER_THIS_PRODUCT",
  items: combo.items?.map((item) => {
    return {
      id: item.id,
      sku: item.sku,
      title: item.title,
      description: `${item.fullContainerDescription} • ${item.fullPackageDescription}`,
    };
  }),
  sharedItems: combo?.sharedItems?.map((sharedItem) => {
    const itemsById = combo.items.reduce(
      (acc, item) => ({
        ...acc,
        [item.platformId]: {
          id: item.id,
          title: item.title,
          description: `${item.fullContainerDescription} • ${item.fullPackageDescription}`,
          discountValue: item.price?.discountValue,
          originalValue: item.price?.originalValue,
        },
      }),
      {},
    );
    return {
      minimumQuantity: sharedItem.minimumValue,
      sharedModel: sharedItem.sharedModel,
      items: [
        ...sharedItem.itemIds
          .map((itemId) => itemsById[itemId])
          .filter(Boolean),
      ],
    };
  }),

  freegoodsCombo: combo.freeGoods?.map((freegoodItem) => {
    return {
      sku: freegoodItem.sku,
      title: freegoodItem.name,
      description: `${freegoodItem.fullContainerDescription} • ${freegoodItem.fullPackageDescription}`,
      quantity: freegoodItem.quantity,
    };
  }),
  freegoodsPromotion: [],
  description: combo.description,
  originalPrice: combo.originalPrice,
  discountedPrice: combo.price,
  steppedDiscountedPrice: undefined,
  stockCount: combo.availability?.count,
  inventoryCount: combo.availability?.count,
  palletQuantity: combo.availability?.count,
  limit: combo.limit,
  remainingDays: combo.remainingDays,
  endDate: combo.endDate,
  isCombo: true,
  isMarketplace: !!combo.marketplace,
});

export const usePromotionsHook = (): UsePromotionsHookProps => {
  const { searchResults } = useStore(PromotionsStore);
  const { pocInformation } = useStore(pocInformationStore);
  const { callId, clientId } = useStore(AgentCallStore);

  const [selectablePromotion, setSelectablePromotion] =
    useState<PROMOTIONS_ABA_TYPES>(PROMOTIONS_ABA_TYPES.PROMOTION);
  const [itemQuantity, setItemQuantity] = useState(1);
  const [showCartLink, setShowCartLink] = useState(false);

  const changeSelectablePromotion = useCallback(
    (selectedOffering: PROMOTIONS_ABA_TYPES): void => {
      setSelectablePromotion(selectedOffering);
    },
    [],
  );

  const formatAnalyticsData = useCallback(
    (promotion: FormatedPromotion, quantity: number) => {
      return {
        call_id: callId,
        combo_id: promotion.itemId,
        is_out_of_stock: !(
          promotion.inventoryCount && promotion.inventoryCount > 0
        ),
        poc_id: clientId,
        promotions_section: promotion.type,
        quantity,
        sku: promotion.sku,
      };
    },
    [callId, clientId],
  );

  const calculatePrice = useCallback(
    (originalPrice?: number, discountedPrice?: number) => {
      if (discountedPrice) {
        return discountedPrice * itemQuantity;
      }

      return originalPrice && originalPrice * itemQuantity;
    },
    [itemQuantity],
  );

  const handleShowCartLink = useCallback(() => {
    setShowCartLink(true);

    setTimeout(() => {
      setItemQuantity(1);
      setShowCartLink(false);
    }, 4000);
  }, []);

  const addItemToCart = useCallback(
    (promotion: FormatedPromotion) => {
      const { itemId, name, originalPrice, discountedPrice, isCombo } =
        promotion;

      const item = {
        ...promotion,
        id: itemId,
        title: name,
        returnable: false,
        notFound: false,
        segment: "",
        itemQuantity,
        total: calculatePrice(originalPrice, discountedPrice) ?? 0,
        price: calculatePrice(originalPrice, discountedPrice) ?? 0,
        unitPrice: originalPrice ?? 0,
      };

      if (isCombo) {
        addComboItem({ ...item });
      } else {
        addPromotionItem({
          ...item,
          maxVehicleCapacity: pocInformation.maxVehicleCapacity,
        });
      }

      handleShowCartLink();
      offeringsProductAdded(formatAnalyticsData(promotion, itemQuantity));
    },
    [
      calculatePrice,
      formatAnalyticsData,
      handleShowCartLink,
      itemQuantity,
      pocInformation.maxVehicleCapacity,
    ],
  );

  const handleChangeQuantity = useCallback(
    (alteredQuantity: number) => {
      setItemQuantity(alteredQuantity);

      offeringsProductQuantityEdited({
        poc_id: clientId,
        call_id: callId,
      });
    },
    [callId, clientId],
  );

  const isTooltipComboItem = (promotion: FormatedPromotion) => {
    return (
      promotion.isCombo &&
      ((promotion.remainingDays && promotion.remainingDays >= 0) ||
        (promotion.limit?.totalDailyBalance &&
          promotion.limit?.totalMonthlyBalance))
    );
  };

  const showMoreInfoComponentProps = (promotion: FormatedPromotion) => {
    return {
      dailyBalance: isTooltipComboItem(promotion)
        ? promotion.limit?.totalDailyBalance
        : null,
      monthlyBalance: isTooltipComboItem(promotion)
        ? promotion.limit?.totalMonthlyBalance
        : null,
      remainingDays: isTooltipComboItem(promotion)
        ? promotion.remainingDays
        : GetRemainingDays(promotion.endDate),
    };
  };

  const formatedPromotions = useMemo(
    () =>
      selectablePromotion === PROMOTIONS_ABA_TYPES.PROMOTION
        ? searchResults.promotions.map(formatPromotion)
        : searchResults.combos.map(formatCombo),
    [searchResults.combos, searchResults.promotions, selectablePromotion],
  );

  return {
    formatedPromotions,
    itemQuantity,
    showCartLink,
    changeSelectablePromotion,
    addItemToCart,
    handleChangeQuantity,
    showMoreInfoComponentProps,
  };
};
