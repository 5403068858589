import NewOfferings from "../../../domains/newOfferings/NewOfferings";
import NewOfferingsDb from "./NewOfferingsDb";

interface INewOfferingsQuery {
  findByName(name: string): Promise<NewOfferings[]>;
  findAll(): Promise<NewOfferings[]>;
}

class NewOfferingsQueryDb implements INewOfferingsQuery {
  private db: NewOfferingsDb;

  constructor(db: NewOfferingsDb) {
    if (db == null) {
      throw new Error("db cannot be null");
    }
    this.db = db;
  }

  async findByName(name: string): Promise<NewOfferings[]> {
    const filterRegex = new RegExp(name.toLowerCase());

    const resultQuery = await this.db.newOfferings
      .orderBy("score")
      .filter((offering) => {
        return filterRegex.test(offering.name.toLowerCase());
      })
      .toArray();

    const items: Array<NewOfferings> = resultQuery.map(
      (item) => <NewOfferings>{ ...item },
    );

    return items;
  }

  async findAll(): Promise<NewOfferings[]> {
    const resultQuery = await this.db.newOfferings.orderBy("score").toArray();

    const items: Array<NewOfferings> = resultQuery.map(
      (item) => <NewOfferings>{ ...item },
    );

    return items;
  }
}

export default NewOfferingsQueryDb;
