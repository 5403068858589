import MarketplaceStore from "../../stores/marketplace/MarketplaceStore";
import NewOfferings from "../../domains/newOfferings/NewOfferings";
import { NewOfferingsResponseItem } from "../../services/newOfferings/domains/NewOfferingsResponseItem";
import { hasMarketplace } from "../../config/utils/functions";

const NewOfferingsResponseToNewOfferingsConverter = (
  data: Array<NewOfferingsResponseItem> = [],
): Array<NewOfferings> => {
  const { marketplaceList } = MarketplaceStore.getState();

  const items = data
    .flatMap((item) => item.items)
    .map((item) => {
      const {
        itemName: name,
        sku,
        score,
        itemImage: image,
        price: { unitPrice },
        discountedPrices,
        suggestedQuantity: quantity,
      } = item;

      let total = 0;
      let unitPriceValue = 0;
      if (discountedPrices) {
        total = discountedPrices.unitPrice * quantity;
        unitPriceValue = discountedPrices.unitPrice;
      } else {
        total = unitPrice * quantity;
        unitPriceValue = unitPrice;
      }

      const isMarketplace = hasMarketplace(sku, marketplaceList);

      return <NewOfferings>{
        name,
        price: unitPriceValue,
        sku,
        quantity,
        score,
        image,
        total,
        hasMarketplace: isMarketplace,
      };
    });

  return items.sort((itemA, itemB) => (itemA.score > itemB.score ? 1 : -1));
};

export default NewOfferingsResponseToNewOfferingsConverter;
