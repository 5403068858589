import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { Box, CircularProgress } from "@material-ui/core";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/ErrorHandlerRetry";
import Table from "./table/Table";
import LastOrdersStyles from "./LastOrdersStyles";
import {
  isFeatureEnabled,
  TLP_FOCUS_SKU,
  TLP_FOCUS_SKU_ADMIN,
} from "../../../../config/featureToggles";
import { ANALYTICS_ROUTE_NAMES } from "../../../../config/constants";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import { errorMessageViewed } from "../../../../config/typewriter";
import Orders from "../../../../domains/Orders";
import { MarketplaceOrders } from "../../../../domains/lastMaketplaceOrders/LastMarketplaceOrders";

interface Props {
  keyToggle: string;
  isInMissions?: boolean;
  orders: Orders[] | MarketplaceOrders[];
  isLoading: boolean;
  error: boolean;
  isLastMarketplaceOrder?: boolean;
  errorFunction: () => void;
}

function LastOrders({
  keyToggle,
  isLastMarketplaceOrder,
  isInMissions,
  orders,
  isLoading,
  error,
  errorFunction,
}: Props): React.ReactElement {
  const { t } = useTranslation();

  const isFocusSkuEnabled = isFeatureEnabled(
    TLP_FOCUS_SKU,
    TLP_FOCUS_SKU_ADMIN,
    keyToggle,
  );

  const classes = LastOrdersStyles({
    isFocusSkuEnabled,
    isInMissions,
  });

  const { callTab } = useStore(CallTabStore);
  const { dispatchPocEvent } = useAnalytics();

  useEffect(() => {
    if (orders && orders.length > 0) {
      const ordersWithoutItemPrice = orders.filter(
        (order) =>
          !order.items ||
          order.items.length === 0 ||
          order.items.some((item) => !item.productName),
      );

      if (ordersWithoutItemPrice.length > 0) {
        const isAllProducts = orders.length === ordersWithoutItemPrice.length;
        dispatchPocEvent(
          errorMessageViewed,
          {
            error_message: "No data available",
            error_type: " no-data-available-sku",
            screen_name: ANALYTICS_ROUTE_NAMES.get(callTab),
            screen_section: "Last Orders",
            is_all_products: isAllProducts,
            total_gap: null,
            total_vol_gap: null,
          },
          { time_of_day: true, is_resumed: true },
        );
      }
    }
  }, [orders, callTab, dispatchPocEvent]);

  if (isLoading && !error) {
    return (
      <Box className={classes.tableNavigationContainer}>
        <Box className={classes.loadingContainer}>
          <CircularProgress data-testid="gap-loading" />
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <ErrorHandlerRetry
        onClick={errorFunction}
        screenName={ANALYTICS_ROUTE_NAMES.get(callTab)}
        screenSection={
          isLastMarketplaceOrder ? "Marketplace Last Orders" : "Last Orders"
        }
      />
    );
  }

  if ((!orders || orders.length === 0) && !error) {
    return (
      <Box className={classes.noPreviousOrder} data-testid="no-previous-order">
        {t("LastOrders.NO_PREVIOUS_ORDER")}
      </Box>
    );
  }

  return (
    <Box
      data-testid="call-last-orders"
      className={classes.tableNavigationContainer}
    >
      <Table orders={orders} isLastMarketplaceOrder={isLastMarketplaceOrder} />
    </Box>
  );
}

LastOrders.defaultProps = {
  isInMissions: false,
  isLastMarketplaceOrder: false,
};

export default LastOrders;
