import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      backgroundColor: palette.grey[50],
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.15)",
      borderRadius: "5px",
      padding: "8px 10px",
      width: "170px",
      boxSizing: "border-box",
      marginLeft: "30px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      color: palette.primary.main,
      paddingBottom: "10px",
      lineHeight: "normal",
    },
    button: {
      color: "white",
      backgroundColor: palette.primary.main,
      textTransform: "none",
      padding: "4px",
      height: "28px",
      fontSize: "12px",
      width: "95%",
      borderRadius: "3px",
      fontWeight: 600,
    },
    gapReasonTitle: {
      fontWeight: "bold",
      color: "#7a7a7a",
      fontSize: "12px",
      marginBottom: "6px",
    },
  }),
);
