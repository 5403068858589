import Chip from "@material-ui/core/Chip";
import React from "react";
import { useTranslation } from "react-i18next";
import GenericTagStyles from "./GenericTagStyles";

interface GenericProps {
  label?: string;
  translationKey?: string;
  style?: React.CSSProperties;
  testId: string;
}

const GenericTag: React.FunctionComponent<GenericProps> = ({
  label,
  translationKey,
  style,
  testId,
}) => {
  const { chip } = GenericTagStyles();
  const { t } = useTranslation();
  let tagLabel = label;
  if (translationKey) {
    tagLabel = t(translationKey);
  }

  return (
    <Chip
      size="small"
      classes={{ root: chip }}
      label={tagLabel}
      color="primary"
      style={{ ...style }}
      data-testid={`generic-tag-${testId}`}
    />
  );
};

export default GenericTag;
