/* eslint-disable no-console */
import { useLogService } from "admin-portal-shared-services";
import { useState, useCallback, useRef, useEffect } from "react";
import { useStore } from "effector-react";
import Moment from "moment";
import {
  setCallLogsEvent,
  setTempCallLogsEvent,
} from "../../../../../stores/callLogs/CallLogsEvents";
import {
  handleCallEvent,
  getValueOrNull,
  formarterCTIWithZero as formatterCTIWithZero,
  getPhoneSanitized,
  getResponseDate,
} from "../../../../../config/utils/functions";
import {
  setAttemptRetry,
  resetAttemptRetry,
  setIsCTIFailedOpen,
  setCalledNumber,
  setIsConnected as setIsConnectedEvent,
  setCtiEvent,
} from "../../../../../stores/cti/CtiEvents";
import * as SoftphoneCtiUseCase from "../../../../../usecase/cti/SoftphoneCtiUseCase";
import * as SoftphoneWSEvents from "../../../../../usecase/cti/webSocket/SoftphoneWSEvents";
import {
  WS_EVENTS,
  ANALYTICS_ROUTE_NAMES,
  ANALYTICS_REGISTRATION_ERROR_TYPE,
} from "../../../../../config/constants";
import { SoftphoneWS } from "../../../../../domains/cti/SoftphoneWS";
import {
  isFeatureEnabled,
  TLP_CTI_PING_PONG,
  TLP_CTI_PING_PONG_ADMIN,
  TLP_CTI,
  TLP_CTI_ADMIN,
  TLP_CALL_AUTO_DIALER,
  GROW_BEES_CTI_CALL_LOGS,
  GROW_BEES_CTI_CALL_LOGS_TEMP,
  isFeatureEnabledV2,
} from "../../../../../config/featureToggles";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import {
  WebsocketEventData,
  WebSocketStatus,
} from "../../../../../domains/cti/WebsocketEventData";
import useCallTimer from "../useCallTimer";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import {
  callStarted,
  callFailed,
  ctiDisconnected,
} from "../../../../../config/typewriter";
import CallTabStore from "../../../../../stores/navigation/callTab/CallTabStore";
import CallLogsStore from "../../../../../stores/callLogs/CallLogsStore";
import CtiStore from "../../../../../stores/cti/CtiStore";
import { CTI } from "./ctiDomain";
import {
  setIsPhoneCalled,
  setLastCalledOrCopiedPhone,
} from "../../../../../stores/phoneRankingStore/phoneRankedEvents";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import PhonesRankedStore from "../../../../../stores/phoneRankingStore/phoneRankedStore";
import GlobalAdminStore from "../../../../../stores/globaAdminConfig/GlobalAdminConfigStore";

const useWebSocket = (): CTI => {
  const { user } = useStore(GlobalStore);
  const { callTab } = useStore(CallTabStore);
  useStore(CallLogsStore);

  const {
    originalPhones,
    attemptRetry,
    shouldContinueWithoutCTI,
    isCTIFailedOpen,
    ctiEvent,
  } = useStore(CtiStore);
  const agentCallStates = useStore(AgentCallStore);
  const [CTILoading, setCTILoading] = useState<boolean>(false);
  const [connectionError, setConnectionError] = useState<boolean>(false);
  const [autoDialerEvent, setAutoDialerEvent] = useState<WebsocketEventData>();
  const [isAutoDialerCall, setIsAutoDialerCall] = useState<boolean>(false);
  const [isCTILostConnectionOpen, setIsCTILostConnectionOpen] =
    useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isCallStatusOpen, setIsCallStatusOpen] = useState<boolean>(false);
  const [webSocketState, setWebSocket] = useState<WebSocket | SoftphoneWS>();
  const [needClassification, setNeedClassification] = useState<boolean>(false);
  const { userConfig } = useStore(GlobalAdminStore);
  const { resetTimer, isConnected: isConnectedCallTimer } = useCallTimer();
  const { dispatchPocEvent } = useAnalytics();

  const refWebSocket = useRef(webSocketState);
  const isClosePingPong = useRef(false);
  const pingPong = useRef<NodeJS.Timeout>();
  const logger = useLogService();
  const ctiEnabled = isFeatureEnabled(TLP_CTI, TLP_CTI_ADMIN, user.zone);
  const isEnabledCtiCallLogsEvents = isFeatureEnabledV2(
    GROW_BEES_CTI_CALL_LOGS,
    user.zone,
  );

  const isEnabledCtiCallLogsTempEvents = isFeatureEnabledV2(
    GROW_BEES_CTI_CALL_LOGS_TEMP,
    user.keyToggle,
  );

  const isCallInAutoDialerEnabled = isFeatureEnabledV2(
    TLP_CALL_AUTO_DIALER,
    user.keyToggle,
  );

  const ctiPingPong = isFeatureEnabled(
    TLP_CTI_PING_PONG,
    TLP_CTI_PING_PONG_ADMIN,
    user.keyToggle,
  );

  const logCti = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (message: string, evt: any) => {
      logger.info(message, JSON.stringify(evt));
    },
    [logger],
  );

  /* istanbul ignore next */
  const getFormattedPhoneNumber = (phoneNumber: string) => {
    const phoneAux = formatterCTIWithZero(phoneNumber);
    if (phoneAux) {
      return phoneAux.toString();
    }

    return null;
  };

  /* istanbul ignore next */
  const dispatchCallStartedEvent = useCallback(
    (
      screenSection: string,
      isTyped: boolean | null | undefined,
      phoneNumber?: string,
    ) => {
      const phonesRanked = PhonesRankedStore.getState().phones;
      const selectedPhone = phonesRanked.filter(
        (phone) => getPhoneSanitized(phone.phoneNumber) === phoneNumber,
      );
      let score: number | null = null;
      let index: number | null = null;
      if (selectedPhone) {
        const phone = selectedPhone[0];
        if (phone) {
          score = phone.score;
          index = phonesRanked.indexOf(phone) + 1;
        }
      }
      dispatchPocEvent(
        callStarted,
        {
          is_cti_connected: isConnected,
          screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
          screen_section: screenSection,
          phone_rank: index,
          phone_score: score,
          vendor_id: userConfig.vendorId,
        },
        { time_of_day: true },
      );
    },
    [dispatchPocEvent, isConnected, callTab, userConfig.vendorId],
  );

  const handleOpen = (evt: WebsocketEventData) => {
    setCTILoading(false);
    setIsCTIFailedOpen(false);
    setIsCTILostConnectionOpen(false);
    setIsConnected(true);
    setIsConnectedEvent(true);
    resetAttemptRetry();
    console.log("Callback open WS: ", evt);
  };

  const handleError = useCallback(
    (
      evt: WebsocketEventData,
      callback?: (arg0?: string | null, arg1?: string) => void,
      section?: string,
    ) => {
      setCTILoading(false);
      setConnectionError(true);
      setIsCTIFailedOpen(true);
      setIsConnected(false);
      setIsConnectedEvent(false);
      if (ctiPingPong && isClosePingPong.current) {
        console.log("ctiPingPong enable");
        setIsCTILostConnectionOpen(true);
      }

      if (ctiPingPong && pingPong && pingPong.current) {
        clearTimeout(pingPong.current);
      }
      /* istanbul ignore next */
      if (callback) {
        callback(null, section);
      }

      console.log("Callback closed WS: ", evt);
    },
    [ctiPingPong],
  );

  const getStatusConnectionCTI = useCallback((wsState: SoftphoneWS) => {
    console.log("getStatusConnectionCTI");

    if (wsState) {
      console.log("Websocket send message GetSatus");
      setTimeout(() => {
        SoftphoneWSEvents.sendMessageWS(
          wsState,
          "",
          "",
          false,
          undefined,
          true,
        );
      }, 10000);
      pingPong.current = setTimeout(() => {
        console.log("Websocket lost connection GetSatus_");
        setIsConnected(false);
        setIsConnectedEvent(false);
        setIsCTIFailedOpen(true);

        if (refWebSocket && refWebSocket.current) {
          console.log("GetSatus webSocket was lost!");
          isClosePingPong.current = true;
          refWebSocket.current.close();
        }
      }, 15000);
    } else {
      setIsConnected(false);
      setIsConnectedEvent(false);
    }
  }, []);

  const getCallDuration = (
    startCall: string | undefined,
    responseDate?: Moment.Moment,
  ) => {
    const startCallDate = Moment.utc(startCall);
    const date = responseDate || getResponseDate();
    return date.diff(startCallDate, "seconds");
  };

  const closeWebSocket = useCallback(() => {
    if (
      refWebSocket.current &&
      refWebSocket.current.readyState === WebSocketStatus.OPENED
    ) {
      refWebSocket.current.close();
      console.log("WebSocket was closed!");
    } else {
      console.log("WebSocket isn't open!");
    }
  }, [refWebSocket]);

  const handleCallLogs = useCallback(
    ({ evento, numero, ani, comando }: WebsocketEventData) => {
      const isCallLogEvent =
        evento === "MakeCall" ||
        evento === "DisconnectCall" ||
        evento === "MakeCall_Fail";

      const isTempCallLogEvent =
        isEnabledCtiCallLogsTempEvents &&
        comando !== "MakeCall" &&
        agentCallStates.callId &&
        !isCallLogEvent;

      if (isEnabledCtiCallLogsEvents) {
        if (isCallLogEvent) {
          setCallLogsEvent(handleCallEvent(numero ?? ani, comando ?? evento));
          return;
        }
      }
      if (isTempCallLogEvent) {
        setTempCallLogsEvent(
          handleCallEvent(agentCallStates.callId, evento ?? comando),
        );
      }
    },
    [
      isEnabledCtiCallLogsEvents,
      agentCallStates,
      isEnabledCtiCallLogsTempEvents,
    ],
  );

  const handleIncomingCallEventFromAutoDialer = useCallback(
    (evt: WebsocketEventData) => {
      if (evt) {
        logCti(
          `#### ${user.zone} Auto Dialer Mode Event -> ${
            evt.comando || evt.evento
          }`,
          evt,
        );
        if (evt.phone && evt.phone !== "") {
          setLastCalledOrCopiedPhone(evt.phone);
        }
        setAutoDialerEvent(evt);
      } else {
        logCti(`#### ${user.zone} Auto Dialer Mode Error`, evt);
      }
    },
    [logCti, user.zone, setAutoDialerEvent],
  );

  const handleDisconnectCallEvent = useCallback(
    (evt: WebsocketEventData) => {
      const { phones, lastCalledOrCopiedPhone } = PhonesRankedStore.getState();

      const calledNumber =
        getFormattedPhoneNumber(lastCalledOrCopiedPhone || "") || "";

      setCalledNumber(calledNumber);
      const phone = phones.find(
        (each) => getPhoneSanitized(each.phoneNumber) === calledNumber,
      );

      setNeedClassification(phones && !!phone);

      const calledPhone = phones.filter(
        (each) => getPhoneSanitized(each.phoneNumber) === calledNumber,
      );

      const phoneSource: Array<string> | null =
        calledPhone[0]?.phoneType.replace(/\s+/g, "").split(",") || null;

      setIsPhoneCalled(phone?.phoneNumber || "");
      ctiDisconnected({
        call_id: agentCallStates.callId,
        poc_id: agentCallStates.clientId,
        action: null,
        screen_section: "CTI Modal",
        country: agentCallStates.countryCode,
        call_duration: getCallDuration(agentCallStates.startCall),
        phone_source: phoneSource,
        phone_rank: phones.indexOf(calledPhone[0]) + 1,
        phone_score: calledPhone[0]?.score || null,
      });

      setIsCallStatusOpen(true);
      logCti(`#### ${user.zone} CTI -> Finish: ${evt.evento}`, phone);
    },
    [agentCallStates, user, logCti],
  );

  const handleMakeCallFailEvent = useCallback(
    (evt: WebsocketEventData) => {
      const { phones, lastCalledOrCopiedPhone } = PhonesRankedStore.getState();

      const selectedPhone = phones.filter(
        (phone) =>
          getPhoneSanitized(phone.phoneNumber) === lastCalledOrCopiedPhone,
      );

      let score: number | null = null;
      let index: number | null = null;

      /* istanbul ignore next */
      if (selectedPhone) {
        const phone = selectedPhone[0];
        if (phone) {
          score = phone.score;
          index = phones.indexOf(phone) + 1;
        }
      }

      dispatchPocEvent(
        callFailed,
        {
          screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
          error_type: ANALYTICS_REGISTRATION_ERROR_TYPE,
          error_message: "Make Call Fail",
          screen_section: "CTI Modal",
          phone_rank: index,
          phone_score: score,
        },
        {
          time_of_day: true,
          is_resumed: true,
        },
      );

      logCti(`#### ${user.zone} CTI -> Finish: ${evt.evento}`, selectedPhone);

      setIsCallStatusOpen(true);
    },
    [callTab, dispatchPocEvent, user, logCti],
  );

  const handleEvents = useCallback(
    (evt: WebsocketEventData) => {
      if (evt.keepAlive === WS_EVENTS.PONG) {
        console.log("Websocket pong is alive!");
        if (ctiPingPong && pingPong && pingPong.current) {
          setIsConnected(true);
          setIsConnectedEvent(true);
          clearTimeout(pingPong.current);
          getStatusConnectionCTI(refWebSocket.current as SoftphoneWS);
        }
      }

      if (evt.evento === WS_EVENTS.DISCONNECT_CALL) {
        handleDisconnectCallEvent(evt);
      }

      if (evt.evento === WS_EVENTS.INCOMING_CALL && isCallInAutoDialerEnabled) {
        handleIncomingCallEventFromAutoDialer(evt);
      }

      if (evt.evento === WS_EVENTS.MAKE_CALL_FAIL) {
        handleMakeCallFailEvent(evt);
      }
    },
    [
      ctiPingPong,
      getStatusConnectionCTI,
      handleDisconnectCallEvent,
      handleIncomingCallEventFromAutoDialer,
      handleMakeCallFailEvent,
      isCallInAutoDialerEnabled,
    ],
  );

  const handleOnMessage = useCallback(
    (evt: WebsocketEventData) => {
      try {
        if (evt.evento === WS_EVENTS.KEEP_ALIVE) {
          setIsConnected(true);
          setIsConnectedEvent(true);
          console.log(WS_EVENTS.KEEP_ALIVE);
        } else {
          logCti(`#### ${user.zone} CTI -> ${evt.comando || evt.evento}`, evt);
          setCtiEvent(evt);
        }
        handleEvents(evt);
      } catch (e) {
        logCti(`#### ${user.zone} CTI ERROR -> `, e);
        closeWebSocket();
        setIsConnected(false);
        setIsConnectedEvent(false);
      }
    },
    [user.zone, closeWebSocket, handleEvents, logCti],
  );

  const connectWebSocket = useCallback(
    (eventDispatch?: () => void, section?: string) => {
      setAttemptRetry();
      setCTILoading(true);
      setConnectionError(false);
      console.log("Try to connect to Websocket SoftphoneWSEvents!");
      const ws = SoftphoneCtiUseCase.connectWS();
      SoftphoneWSEvents.onOpenWS(ws, handleOpen);
      SoftphoneWSEvents.onErrorWS(ws, handleError, eventDispatch, section);
      SoftphoneWSEvents.onMessageWS(ws, false, handleOnMessage, section);

      setWebSocket(ws);
      refWebSocket.current = ws;
      console.log("ConnectWebSocket");

      if (ctiPingPong) getStatusConnectionCTI(ws);
    },
    [handleOnMessage, handleError, getStatusConnectionCTI, ctiPingPong],
  );

  const refConnectWebSocket = useRef(connectWebSocket);

  /* istanbul ignore next */
  useEffect(() => {
    if (
      (ctiEnabled && !shouldContinueWithoutCTI) ||
      (isCallInAutoDialerEnabled && isAutoDialerCall)
    ) {
      refConnectWebSocket.current();
    }
    return () => {
      closeWebSocket();
    };
  }, [
    closeWebSocket,
    ctiEnabled,
    isAutoDialerCall,
    isCallInAutoDialerEnabled,
    shouldContinueWithoutCTI,
  ]);

  /* istanbul ignore next */
  useEffect(() => {
    if (
      Object.keys(ctiEvent).length &&
      agentCallStates.callId &&
      ctiEvent.evento !== WS_EVENTS.KEEP_ALIVE
    ) {
      handleCallLogs(ctiEvent);
      setCtiEvent({});
    }
  }, [agentCallStates, agentCallStates.callId, ctiEvent, handleCallLogs]);

  /* istanbul ignore next */
  useEffect(() => {
    if (!isConnectedCallTimer && !connectionError) {
      setIsConnected(false);
      setIsConnectedEvent(false);
      setIsCTIFailedOpen(false);
      setIsCTILostConnectionOpen(true);
      if (ctiPingPong && refWebSocket && refWebSocket.current) {
        console.log("refWebSocket closed");
        refWebSocket.current.close();
      }
    }
  }, [
    isConnectedCallTimer,
    connectionError,
    ctiPingPong,
    setIsConnected,
    setIsCTILostConnectionOpen,
    refWebSocket,
  ]);

  const makeCallWebSocket = (
    phoneNumber: string,
    wasTyped: boolean,
    section?: string,
  ) => {
    if (
      refWebSocket.current &&
      refWebSocket.current.readyState === WebSocketStatus.OPENED
    ) {
      const formattedPhoneNumber = getFormattedPhoneNumber(phoneNumber);

      SoftphoneWSEvents.sendMessageWS(
        refWebSocket.current,
        formattedPhoneNumber || phoneNumber,
        section || "",
        wasTyped,
        dispatchCallStartedEvent,
      );

      const makeCallEvent: WebsocketEventData = {
        evento: "MakeCall",
        numero: formattedPhoneNumber ?? phoneNumber,
      };

      handleCallLogs(makeCallEvent);

      setLastCalledOrCopiedPhone(formattedPhoneNumber || phoneNumber);
      setCalledNumber(formattedPhoneNumber || phoneNumber);
    } else {
      console.log("WebSocket is closed!", refWebSocket.current);
    }
  };

  const callCti = () => {
    return (
      ctiEnabled &&
      webSocketState &&
      webSocketState.readyState === WebSocketStatus.OPENED
    );
  };

  return {
    callCti,
    connectWebSocket,

    makeCallWebSocket,
    CTILoading,
    setCTILoading,

    connectionError,
    setConnectionError,

    webSocketState,
    setWebSocket,

    setNeedClassification,
    needClassification,

    isCTILostConnectionOpen,
    setIsCTILostConnectionOpen,

    isConnected,
    setIsConnected,

    isCallStatusOpen,
    setIsCallStatusOpen,

    resetTimer,
    isConnectedCallTimer,

    refWebSocket,
    isClosePingPong,
    pingPong,
    originalPhones,
    attemptRetry,
    ctiPingPong,
    shouldContinueWithoutCTI,
    isCTIFailedOpen,
    dispatchCallStartedEvent,
    getStatusConnectionCTI,
    handleOnMessage,
    handleCallLogs,
    handleError,
    handleOpen,
    setAutoDialerEvent,
    setIsAutoDialerCall,
    autoDialerEvent,
  };
};

export default useWebSocket;
