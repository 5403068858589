import React, { useContext, useState } from "react";
import { useStore } from "effector-react";
import { ANALYTICS_ROUTE_NAMES, POC_INFO_TAB } from "../config/constants";
import { callUxButtonClicked } from "../config/typewriter";
import { getValueOrNull } from "../config/utils/functions";
import CallTabStore from "../stores/navigation/callTab/CallTabStore";
import AgentCallStore from "../stores/agentCall/AgentCallStore";

export const CollapsibleSideNavContext = React.createContext({
  collapsibleIsOpen: true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleCollapsible: (_value: string) => {
    // This is intentional
  },
});

export const CollapsibleSideNavContextProvider: React.FunctionComponent = ({
  children,
}) => {
  const [collapsibleIsOpen, setcollapsibleIsOpen] = useState(true);
  const { callTab } = useStore(CallTabStore);
  const { callId } = useStore(AgentCallStore);

  /* istanbul ignore next */
  const callEventCallUx = (value: string) => {
    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: "collapsible-button",
      button_label: collapsibleIsOpen ? "collapse" : "expand",
      screen_section:
        value === POC_INFO_TAB ? "POC Details" : "Sales Structure",
      filter_option: "",
      call_id: callId,
      order_date: null,
      position: null,
    });
  };

  const handleCollapsible = (value: string) => {
    callEventCallUx(value);
    setcollapsibleIsOpen((prevOpen) => !prevOpen);
  };

  return (
    <CollapsibleSideNavContext.Provider
      value={{ collapsibleIsOpen, handleCollapsible }}
    >
      {children}
    </CollapsibleSideNavContext.Provider>
  );
};

type CollapsibleSideNavContextType = () => {
  collapsibleIsOpen: boolean;
  handleCollapsible: (value: string) => void;
};

export const useCollapsibleSideNavContext: CollapsibleSideNavContextType = () =>
  useContext(CollapsibleSideNavContext);
