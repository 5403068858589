import { createEffect, createEvent } from "effector";
import { execute } from "../../usecase/customerNotes/SendCustomerNotesUseCase";
import { executeGetCustomerNotes } from "../../usecase/customerNotes/GetCustomerNotesUseCase";

interface GetCustomerNotesProps {
  clientId: string;
  vendorAccountId: string | undefined;
}

interface SendCustomerNotesProps {
  clientId: string;
  vendorAccountId: string | undefined;
  agentEmail: string;
  note: string;
  isFloatingComponent?: boolean;
}

export const sendCustomerNotes = createEffect({
  async handler(sendCustomerNotesProps: SendCustomerNotesProps) {
    const { clientId, vendorAccountId, agentEmail, note, isFloatingComponent } =
      sendCustomerNotesProps;

    return execute(
      clientId,
      vendorAccountId,
      agentEmail,
      note,
      isFloatingComponent,
    );
  },
});

export const setCustomerNotes = createEvent<string>("setCustomerNotes");

export const getCustomerNotes = createEffect({
  async handler(getCustomerNotesProps: GetCustomerNotesProps) {
    const { clientId, vendorAccountId } = getCustomerNotesProps;

    return executeGetCustomerNotes(clientId, vendorAccountId);
  },
});
