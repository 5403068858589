import React, { useState, useEffect } from "react";
import { Divider, Card, Typography } from "@material-ui/core";
import { CalendarClock, Check, X } from "@hexa-ui/icons";
import { useTranslation } from "react-i18next";
import Modal from "../modal/Modal";
import ShowMoreCardInfosModalStyles from "./ShowMoreCardInfosModal.styles";
import { ComboItemDetail } from "../../domains/products/Product";
import ImageContainer from "../CustomImageContainer/CustomImageContainer";

const offeringImagePlaceholder =
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  require("../../assets/images/ProductPlaceholder.png") as string;

interface ShowMoreCardInfosModalInterface {
  isOpen: boolean;
  onClose: () => void;
  comboDetails: ComboItemDetail[];
  price: number;
  endDate?: string;
  totalDailyBalance?: number | null;
  totalMonthlyBalance?: number | null;
}

const ShowMoreCardInfosModal: React.FC<ShowMoreCardInfosModalInterface> = ({
  isOpen,
  onClose,
  comboDetails,
  price,
  endDate,
  totalDailyBalance,
  totalMonthlyBalance,
}) => {
  const classes = ShowMoreCardInfosModalStyles();
  const { t } = useTranslation();
  const [daysRemaining, setDaysRemaining] = useState<number | null>(null);

  useEffect(() => {
    /* istanbul ignore else */
    const formattedDate = endDate && new Date(endDate);
    if (
      formattedDate &&
      Object.prototype.toString.call(formattedDate) === "[object Date]"
    ) {
      const diffTime = Math.abs(formattedDate.getTime() - new Date().getTime());
      const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      /* istanbul ignore else */
      if (days) setDaysRemaining(days);
    }
  }, [endDate]);

  return (
    <Modal open={isOpen} borderRadius="8px" data-testid="show-more-modal">
      <aside className={classes.container}>
        <header
          className={classes.header}
          data-testid="show-more-header-section"
        >
          <Typography variant="h1" className={classes.title}>
            Combo
          </Typography>
          <div
            onClick={onClose}
            data-testid="show-more-close-modal-button"
            role="presentation"
            className={classes.closeButtonContainer}
          >
            <X style={{ width: "12px", height: "12px" }} />
          </div>
        </header>
        <main className={classes.productsContainer}>
          {comboDetails?.map((combo) => {
            return (
              <Card
                className={classes.productCardContainer}
                elevation={0}
                key={combo.id}
              >
                <ImageContainer
                  src={combo.image}
                  alt={`${combo.sku}-combo`}
                  data-testid={`${combo.sku}-combo-image`}
                  width="46px"
                  height="46px"
                  errorImage={offeringImagePlaceholder}
                />
                <div className={classes.cardInfosContainer}>
                  <Typography variant="h2" className={classes.cardTitleLabel}>
                    {combo.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.cardSkuLabel}
                  >
                    {combo.sku}
                  </Typography>
                  <Typography variant="h2" style={{ fontWeight: 500 }}>
                    {`${combo.unit} ${t("Combos.UNIT")}`}
                  </Typography>
                </div>
              </Card>
            );
          })}
        </main>
        <section className={classes.totalPriceContainer}>
          <Typography variant="h2" style={{ fontWeight: 500 }}>{`${t(
            "Combos.TOTAL_PRICE_TEXT",
          )}${price || ""}`}</Typography>
        </section>
        <Divider />
        <footer className={classes.footerSection}>
          {!!daysRemaining && (
            <div
              className={classes.bottomSection}
              data-testid="days-remaining-container"
            >
              <CalendarClock />
              <Typography variant="h2" className={classes.footerText}>{`${t(
                "Combos.AVAILABLE_TEXT",
              )} ${daysRemaining} ${t("Combos.DAYS")}`}</Typography>
            </div>
          )}
          <div className={classes.bottomSection}>
            <Check />
            <Typography variant="h2" className={classes.footerText}>{`${t(
              "Combos.CAN_STILL_BUY_TEXT",
            )} ${
              totalDailyBalance && totalDailyBalance >= 0
                ? totalDailyBalance
                : 0
            } ${t("Combos.TODAY_TEXT")} ${
              totalMonthlyBalance && totalMonthlyBalance >= 0
                ? totalMonthlyBalance
                : 0
            } ${t("Combos.MONTH_TEXT")}`}</Typography>
          </div>
        </footer>
      </aside>
    </Modal>
  );
};

export default ShowMoreCardInfosModal;
