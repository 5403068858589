import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import GlobalStore from "../stores/global/GlobalStore";

function FormatStockExhibition(stock: number): string {
  const { user } = useStore(GlobalStore);
  const { t } = useTranslation();

  const fixedStock = Number(stock.toFixed(0));

  const thousandSeparator =
    t(`Formater.${user.zone}.THOUSAND_SEPARATOR`) || ",";

  /* istanbul ignore else */
  if (fixedStock >= 1000000000)
    return `${"999.999".replace(".", thousandSeparator)} mi+`;

  /* istanbul ignore else */
  if (fixedStock > 999999 && fixedStock <= 999999999) {
    return `${Math.floor(fixedStock / 1000000)
      .toString()
      .replace(".", thousandSeparator)} mi`;
  }

  /* istanbul ignore else */
  if (fixedStock > 999) {
    const converted = Number(
      (fixedStock / 1000).toString().match(/^-?\d+(?:\.\d{0,3})?/)?.[0],
    );

    return `${converted.toFixed(3).toString().replace(".", thousandSeparator)}`;
  }

  return fixedStock.toString();
}

export default FormatStockExhibition;
