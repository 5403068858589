import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  targetColor?: string;
}

const callOptionsStyles = makeStyles<Theme, Props>(() =>
  createStyles({
    containerModal: {
      display: "flex",
      flexDirection: "column",
      minWidth: "475px",
      padding: "1.5rem",
    },
    modalHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "41px",
    },
    modalHeaderTitle: {
      width: "65%",
      fontFamily: "Barlow",
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    containerBox: {
      display: "flex",
      justifyContent: "space-between",
    },
    text: {
      fontSize: "1rem",
      fontFamily: "Work Sans",
      lineHeight: "1.5rem",
      letterSpacing: "0.1px",
      marginBottom: "2.1875rem",
    },
    button: {
      width: "120px",
      height: "48px",
      padding: "1.1875rem 1.5rem",
      fontSize: "1.125rem",
      fontFamily: "Work Sans",
      border: "solid 1px #000",
      fontWeight: 500,
      borderRadius: "1.5rem !important",
      whiteSpace: "nowrap",
    },
    closeButton: {
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    wordToHighLight: { color: "rgba(201,32,29,0.9)" },
  }),
);

export default callOptionsStyles;
