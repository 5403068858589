import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    label: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 600,
      color: "#0000008c",
    },
    status: {
      fontWeight: 600,
      color: "#C9201D",
      marginLeft: "4px",
    },
  }),
);
