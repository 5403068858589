import React from "react";

interface CheckMarkIconInterface {
  width?: number;
  height?: number;
}

const CheckMarkIcon = ({ width, height }: CheckMarkIconInterface) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M32,8 C45.254834,8 56,18.745166 56,32 C56,45.254834 45.254834,56 32,56 C18.745166,56 8,45.254834 8,32 C8,18.745166 18.745166,8 32,8 Z M32,12.3636364 C21.1551358,12.3636364 12.3636364,21.1551358 12.3636364,32 C12.3636364,42.8448642 21.1551358,51.6363636 32,51.6363636 C42.8448642,51.6363636 51.6363636,42.8448642 51.6363636,32 C51.6363636,21.1551358 42.8448642,12.3636364 32,12.3636364 Z M41.767767,24.232233 C42.7016294,25.1660955 42.7422321,26.6549584 41.8895751,27.6370817 L41.767767,27.767767 L30.767767,38.767767 C29.8339045,39.7016294 28.3450416,39.7422321 27.3629183,38.8895751 L27.232233,38.767767 L22.232233,33.767767 C21.2559223,32.7914562 21.2559223,31.2085438 22.232233,30.232233 C23.1660955,29.2983706 24.6549584,29.2577679 25.6370817,30.1104249 L25.767767,30.232233 L29,33.465 L38.232233,24.232233 C39.2085438,23.2559223 40.7914562,23.2559223 41.767767,24.232233 Z"
        id="checkbox-path-1"
      ></path>
    </defs>
    <g id="___Symbols" stroke="none">
      <g id="02_Icons/checkmark-2">
        <mask id="checkbox-mask-2" fill="white">
          <use xlinkHref="#checkbox-path-1"></use>
        </mask>
        <g id="Combined-Shape" fillRule="nonzero"></g>
        <g
          id="Group"
          mask="url(#checkbox-mask-2)"
          fill="#000000"
          fillOpacity="0.9"
        >
          <g id="Rectangle">
            <rect x="0" y="0" width="64" height="64"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

CheckMarkIcon.defaultProps = {
  width: 64,
  height: 64,
};

export default CheckMarkIcon;
