import AgentCallStore from "@stores/agentCall/AgentCallStore";
import CartItemStore from "@stores/cart/CartItemStore";
import { getRulesEffect } from "@stores/loyaltyBusiness/LoyaltyBusinessEvents";
import loyaltyBusinessStore from "@stores/loyaltyBusiness/LoyaltyBusinessStore";
import { useStore } from "effector-react";
import { useCallback, useMemo, useState } from "react";

interface UseRewardsPoints {
  getRewardRulesRequest: () => void;
  totalRewardsPoints: number;
}

export const useRewardsPoints = (): UseRewardsPoints => {
  const AgentCallState = useStore(AgentCallStore);
  const CartItemState = useStore(CartItemStore);
  const { rules } = useStore(loyaltyBusinessStore);
  const [updatedLoyaltyPlatformIds, setUpdatedLoyaltyPlatformIds] =
    useState("");

  const getRewardRulesRequest = useCallback(() => {
    const currentPlatformId = [
      ...new Set(CartItemState.cartItems?.map((item) => item.platformId)),
    ].toString();

    const sameAsLastPlatformId =
      currentPlatformId === updatedLoyaltyPlatformIds;

    if (!sameAsLastPlatformId) {
      setUpdatedLoyaltyPlatformIds(currentPlatformId);

      const platformIds = CartItemState.cartItems.map(
        (item) => item.platformId,
      );

      const filtered = platformIds.filter((el) => el !== undefined);

      getRulesEffect({
        accountId: AgentCallState.clientId,
        platformIds: filtered,
      });
    }
  }, [
    CartItemState.cartItems,
    updatedLoyaltyPlatformIds,
    AgentCallState.clientId,
  ]);

  const totalRewardsPoints = useMemo(() => {
    let totalPoints = 0;

    if (rules?.length && CartItemState.cartItems?.length) {
      CartItemState.cartItems.forEach(({ platformId, price }) => {
        const rule = rules.find((rule) => rule.platformId === platformId);

        if (rule)
          totalPoints += Math.floor(price / rule.amountSpent) * rule.points;
      });
    }
    return totalPoints;
  }, [rules, CartItemState.cartItems]);

  return {
    getRewardRulesRequest,
    totalRewardsPoints,
  };
};
