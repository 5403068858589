type missionTag = {
  [key: string]: string;
};

export const missionTypesByTag: missionTag = {
  "#TopLineGAP": "Gap",
  "#PromoteMarketPlace": "marketplace",
  "#PromoteAlgoSelling": "algo selling reactivation",
  "#norewardsredemption": "rewards redemption",
  "#NoRewardsRedemption": "rewards redemption",
  "#promoterewardschallenges": "rewards challenges",
  "#b2o": "b2o campaign",
  "#marketplaceactivation": "marketplace activation",
  "#digital_services": "digital services",
  "#sponsored": "sponsored",
  "#LocalMissions": "local",
  "#flexible_missions": "global",
};
