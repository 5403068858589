import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { GROW_BFF_SERVICE } from "@config/services";
import axios from "../../config/axios/axiosInstance";
import { FlexibleMissionsResponseToMissionsConfigConverter } from "../../converters/flexibleMissions/FlexibleMissionsResponseToMissionsConfigConverter";
import { FlexibleMissionsConfigObject } from "../../domains/flexibleMission/FlexibleMissionsConfigObject";
import { getUserInfos } from "../../config/utils/functions";
import { getApiHost } from "../host/HostService";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export const getPrioritizedMissions = async (
  accountId: string,
  vendorId: string,
  missionTypeFilter?: string,
): Promise<FlexibleMissionsConfigObject> => {
  const log = useLogService();

  const url = `${getApiHost(
    GROW_BFF_SERVICE,
  )}/accounts/${accountId}/prioritized-missions`;

  const { missionPriority } = getUserInfos();

  const requestBody = {
    groupMissionPriorities: missionPriority,
    missionTypeFilter,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: { vendorId },
    });
    log.info(`Convert Missions received from ${url} to our model`);
    return FlexibleMissionsResponseToMissionsConfigConverter(response.data);
  } catch (error) {
    log.error(error);
    throw error;
  }
};
