import React, { useState, useEffect, useRef, useCallback } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import CollapsibleTitle from "../../../../components/collapsableTitle/CollapsibleTitle";
import RedeemIcon from "../../../../assets/newIcons/RedeemItemsIcon";
import InfoDate from "../../../../components/infoDate/InfoDate";
import useRewardsStyle from "./style";
import AgendCallStore from "../../../../stores/agentCall/AgentCallStore";
import RedeemStore from "../../../../stores/redeem/RedeemStore";
import {
  getEnrolledSince,
  getLastRedeem,
  getRedeemItems,
} from "../../../../stores/redeem/RedeemEvents";
import LoadingView from "../../../../components/loadingView/LoadingView";
import RedeemItemsDb from "../../redeemItemsDb/RedeemItemsDb";
import RedeemItemsQueryDb from "../../redeemItemsDb/RedeemItemsQueryDb";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/ErrorHandlerRetry";
import { callUxButtonClicked } from "../../../../config/typewriter";
import ItemCard from "../../../../components/itemCard/ItemCard";
import Data from "../../../../components/data/Data";

const RewardsComponent: React.FC = () => {
  const [rewardsProductsOpen, setRewardsProductsOpen] = useState(true);
  const {
    enrolledSince,
    lastRedeem,
    loadingEnrolledSince,
    loadingLastRedeem,
    loadingRedeemItems,
    noDataAvailableError,
    retryError,
    redeemItems,
  } = useStore(RedeemStore);
  const { clientId, callId } = useStore(AgendCallStore);
  const { t } = useTranslation();
  const db = useRef(new RedeemItemsDb());

  const handleRewardsProductsOpen = () => {
    const buttonName = rewardsProductsOpen
      ? "redeem-items-component-collapse"
      : "redeem-items-component-expand";
    const screenSection = "Redeem Items";
    const isExpanded = !rewardsProductsOpen;

    callUxButtonClicked({
      screen_name: "Call - GAP and Status",
      button_name: buttonName,
      button_label: isExpanded ? "expand" : "collapse",
      screen_section: screenSection,
      filter_option: null,
      call_id: callId,
      order_date: null,
      position: null,
    });
    setRewardsProductsOpen((prevState) => !prevState);
  };

  const classes = useRewardsStyle();
  const isLoading = () =>
    loadingEnrolledSince || loadingLastRedeem || loadingRedeemItems;

  const saveQueryDbRedeemItems = useCallback(async () => {
    try {
      const query = new RedeemItemsQueryDb(db.current);
      await query.getAll();
    } catch (err) {
      // do nothing
    }
  }, []);

  const handleRedeemInfo = useCallback(() => {
    getEnrolledSince(clientId);
    getLastRedeem(clientId);
    saveQueryDbRedeemItems();

    /* istanbul ignore next */
    const unWatchGetRedeemItems = getRedeemItems.done.watch(
      async ({ result }) => {
        try {
          await db.current.redeemItems.bulkPut(result.redeemItems);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn(err);
        }

        /* istanbul ignore next */
        if (typeof unWatchGetRedeemItems === "function") {
          unWatchGetRedeemItems();
        }
      },
    );
  }, [clientId, saveQueryDbRedeemItems]);

  useEffect(() => {
    handleRedeemInfo();
  }, [handleRedeemInfo, redeemItems]);

  const retryHandler = () => {
    getRedeemItems(clientId);
  };

  const renderNoItems = () => {
    if (!redeemItems?.length && !retryError) {
      return (
        <div
          className={classes.noItemsContainer}
          data-testid="rewards-no-items-available"
        >
          <span className={classes.noItemsMessage}>
            {t("Promotions.NO_PROMOTIONS")}
          </span>
        </div>
      );
    }
    return null;
  };

  const renderContent = () => {
    return isLoading() ? (
      <div className={classes.content}>
        <LoadingView />
      </div>
    ) : (
      <>
        <div
          className={classes.redeemInfosContainer}
          data-testid="redeem-infos-container"
        >
          <InfoDate
            title={t("Rewards.ENROLED_TITLE")}
            date={enrolledSince}
            error={noDataAvailableError}
          />
          <InfoDate
            title={t("Rewards.LAST_REDEEM_TITLE")}
            date={lastRedeem}
            subtitle={t("Rewards.NEVER_REDEEMED")}
            error={noDataAvailableError}
          />
        </div>
        {retryError && (
          <div className={classes.content}>
            <ErrorHandlerRetry
              onClick={retryHandler}
              testId="redeem-items"
              screenName="Call - GAP and Status"
              screenSection="Redeem Items"
            />
          </div>
        )}
        {redeemItems && (
          <Grid container spacing={2}>
            {redeemItems.map((item) => (
              <Grid item xs={6} key={item.title} data-testid="rewards-product">
                <ItemCard
                  item={{ itemName: item.title, image: item.image }}
                  testId={item.id}
                >
                  <div className={classes.pointsContainer}>
                    <p
                      data-testid={`reward-points-${item.title}`}
                      className={classes.pointsValue}
                    >
                      <Data type="number" value={item.points} />
                      {` ${t("LoyaltProgram.POINTS")}`}
                    </p>
                  </div>
                </ItemCard>
              </Grid>
            ))}
          </Grid>
        )}
        {renderNoItems()}
      </>
    );
  };

  return (
    <div>
      <CollapsibleTitle
        open={rewardsProductsOpen}
        handleOpen={handleRewardsProductsOpen}
        icon={<RedeemIcon />}
        title={t("Rewards.REDEEM_TITLE")}
        testId="rewards-collapse-button"
      >
        {renderContent()}
      </CollapsibleTitle>
    </div>
  );
};

export default React.memo(RewardsComponent);
