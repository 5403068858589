import React, { useState, useRef, memo } from "react";
import { Box, Divider, Drawer } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Button from "@components/button/Button";
import { X as CloseIcon } from "@hexa-ui/icons";
import { ComboInterface } from "@services/promotions/domains/CombosResponseV2";
import * as CartItemEvents from "@stores/cart/CartItemEvents";
import { COMBO_ITEM_TYPE } from "@config/constants";
import { useStore } from "effector-react";
import cartItemStore from "@stores/cart/CartItemStore";
import Data from "@components/data/Data";
import Product from "./components/Products/Product";
import ComboInteractiveTrayStyles from "./ComboInteractiveTray.styles";
import { sendItemsToCart, getItemsToBeInTruck } from "./helper/util";

interface ComboInteractiveTray {
  combos: ComboInterface[];
  drawerOpen: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
}

export function ComboInteractiveTray({
  combos,
  drawerOpen,
  setDrawerOpen,
}: Readonly<ComboInteractiveTray>): JSX.Element {
  const cartItem = useStore(cartItemStore);
  const { t } = useTranslation();
  const classes = ComboInteractiveTrayStyles();
  const [totalCombo, setTotalCombo] = useState(0);
  const totalComboRef = useRef(
    [] as Array<{
      platformId: string;
      total: number;
      itemQuantity: number;
      comboId: string;
    }>,
  );

  const addToTruck = () => {
    sendItemsToCart(getItemsToBeInTruck(totalComboRef, combos));

    CartItemEvents.updateSingleCartSimulationEffect.done.watch(() => {
      setDrawerOpen(false);
    });
  };

  const getDrawerHeader = () => (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <div className={classes.drwHeaderTxt}>
        {t("ComboInteractive.INTERACTIVE_COMBO")}
      </div>

      <CloseIcon
        onClick={() => setDrawerOpen(false)}
        data-testid="icon_close"
        style={{
          background: "#d9d9d9",
          borderRadius: "10rem",
          padding: "4px",
          cursor: "pointer",
        }}
      />
    </Box>
  );

  const getDrawerFooter = () => (
    <Box display="flex" flexDirection="row" justifyContent="end" marginTop={5}>
      <Button
        type="button"
        variant="outlined"
        data-testid="cancel_btn"
        className={classes.cancelBtn}
        onClick={() => setDrawerOpen(false)}
      >
        {t("OrderTaking.CANCEL_CLEAN")}
      </Button>

      <Button
        type="button"
        className={classes.button}
        loading={cartItem.isLoadingBff}
        data-testid="add-to-truck-button"
        onClick={() => {
          addToTruck();
        }}
      >
        {t("ComboInteractive.ADD_TO_TRUCK")}
      </Button>
    </Box>
  );

  const getInstruction = (
    sharedModel: string,
    minimumValue: number,
    sharedItemsIndex: number,
  ) => {
    if (sharedModel === COMBO_ITEM_TYPE.QUANTITY)
      return t("ComboInteractive.INSTRUCTION_QUANTITY", {
        num: minimumValue,
        section: sharedItemsIndex + 1,
      });
    if (sharedModel === COMBO_ITEM_TYPE.AMOUNT)
      return t("ComboInteractive.INSTRUCTION_AMOUNT", { num: minimumValue });
    return <></>;
  };

  const getDrawerCombos = (combo: ComboInterface) => {
    return combo?.sharedItems?.map(
      ({ minimumValue, itemIds, sharedModel }, sharedItemsIndex) => {
        const fullItemsFromSharedItems = combo.items.filter(
          ({ platformId }) => platformId && itemIds.indexOf(platformId) !== -1,
        );
        const indexOfLastItem = fullItemsFromSharedItems.length - 1;
        const hideDividerOnLastItem = (fullItemsFromSharedItemsIndex: number) =>
          fullItemsFromSharedItemsIndex === indexOfLastItem ? (
            <></>
          ) : (
            <Divider className={classes.divider} />
          );

        return (
          <>
            <div className={classes.section}>
              {t("ComboInteractive.SECTION_LABEL")} {sharedItemsIndex + 1}
            </div>
            <div>
              {getInstruction(sharedModel, minimumValue, sharedItemsIndex)}
            </div>
            <div className={classes.productContainer}>
              {fullItemsFromSharedItems.map(
                (item, fullItemsFromSharedItemsIndex) => (
                  <>
                    <Product
                      comboId={combo.id}
                      item={item}
                      totalComboRef={totalComboRef}
                      setTotalCombo={setTotalCombo}
                    />
                    {hideDividerOnLastItem(fullItemsFromSharedItemsIndex)}
                  </>
                ),
              )}
            </div>
          </>
        );
      },
    );
  };

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      BackdropProps={{ style: { opacity: 0 } }}
      PaperProps={{ style: { borderRadius: 10 } }}
      data-testid="interactive-combo-drawer"
    >
      <Box style={{ padding: "25px", maxWidth: "500px", minWidth: "400px" }}>
        {getDrawerHeader()}

        {combos.map((combo) => (
          <Box key={combo.id} marginBottom={3}>
            <div className={classes.combosTitle}>{combo.title}</div>
            <div className={classes.geralDescription}>{combo.description}</div>
            {getDrawerCombos(combo)}
            <div className={classes.geralDescription}>
              {t("ComboInteractive.COMBO_AVAILABLE", {
                remainingDays: combo.remainingDays,
              })}
            </div>
            <div className={classes.geralDescription}>
              {t("ComboInteractive.TOTAL_COST")}{" "}
              <Data type="currency" value={totalCombo} />.
            </div>
          </Box>
        ))}
        {getDrawerFooter()}
      </Box>
    </Drawer>
  );
}
memo(ComboInteractiveTray);
