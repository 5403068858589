import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  targetColor?: string;
}

const callOptionsStyles = makeStyles<Theme, Props>(() =>
  createStyles({
    containerBox: {
      display: "flex",
      flexDirection: "column",
      border: "1px solid #ccc",
      borderRadius: "15px",
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.16)",
      overflowX: "auto",
      height: "auto",
      padding: "16px 16px 24px 16px",
      margin: "8px",
    },
    title: {
      display: "flex",
      padding: "8px",
      alignSelf: "left",
      marginBottom: "42px",
    },
    overwriteTitle: {
      fontFamily: "Barlow !important",
      fontWeight: 600,
      fontSize: "16px",
    },
    cardContent: {
      gap: "24px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  }),
);

export default callOptionsStyles;
