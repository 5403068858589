import { B2BTrainerInfo } from "../../services/b2bTrainer/domains/B2BTrainerInfo";
import * as B2BTrainerService from "../../services/b2bTrainer/B2BTrainerService";

export function execute(
  username: string,
  country: string,
): Promise<B2BTrainerInfo> {
  return B2BTrainerService.executeB2BTrainer(username, {
    country,
  });
}

export function executeDelete(
  publicKey: string,
  country: string,
): Promise<void> {
  return B2BTrainerService.deleteB2bTrainerEmulator(publicKey, {
    country,
  });
}

export function executeGet(
  agentId: string,
  country: string,
): Promise<B2BTrainerInfo> {
  return B2BTrainerService.getB2BTrainerEmulator(agentId, {
    country,
  });
}
