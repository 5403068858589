import React, { useEffect, useCallback } from "react";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";
import Timelinebase from "@material-ui/lab/Timeline";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import {
  Typography,
  IconButton,
  Box,
  Collapse,
  Divider,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import Moment from "moment";
import TimelineStyles from "./Timeline.Style";
import { isNoHitClassification } from "../../../../../../config/utils/functions";
import LastContacts from "../../../../../../domains/customer/LastContact";
import AllMissions from "../mission/AllMissions";
import CommentsAndOrder from "../commentsAndOrder/CommentsAndOrder";
import { customerUxButtonClicked } from "../../../../../../config/typewriter";
import { useAnalytics } from "../../../../../../analytics/useAnalytics";
import AgentCallStore from "../../../../../../stores/agentCall/AgentCallStore";
import { LAST_CONTACTS_TYPIFICATION } from "../../../../../../domains/LastContactsValues";

interface Props {
  lastContacts: Array<LastContacts>;
}

const Timeline: React.FunctionComponent<Props> = ({ lastContacts }) => {
  const classes = TimelineStyles();
  const { t } = useTranslation();
  const [collapseItems, setCollapseItems] = React.useState(
    new Map<number, boolean>(),
  );
  const { dispatchGenericEvent } = useAnalytics();
  const { callId } = useStore(AgentCallStore);

  useEffect(() => {
    const expandedItems = new Map();

    lastContacts.forEach((_, index) => expandedItems.set(index, false));
    expandedItems.set(0, true);

    setCollapseItems(expandedItems);
  }, [setCollapseItems, lastContacts]);

  const formatDate = () => {
    return t("Formater.DATE_FORMATTER");
  };

  const handleCollapse = useCallback(
    (lastContact: LastContacts, indexClicked: number) => {
      const expandedItems = new Map(collapseItems);
      const isExpanded = !collapseItems.get(indexClicked);

      expandedItems.set(indexClicked, isExpanded);
      setCollapseItems(expandedItems);

      dispatchGenericEvent(customerUxButtonClicked, {
        call_id: callId,
        button_name: isExpanded
          ? "customer_single_call_expand"
          : "customer_single_call_collapse",
        button_label: isExpanded ? "expand" : "collapse",
        screen_section: "Last Contacts",
        typification: lastContact.callTypification,
      });
    },
    [setCollapseItems, collapseItems, callId, dispatchGenericEvent],
  );

  const renderSeparator = (index: number) => {
    return index !== lastContacts.length - 1;
  };

  const renderIcon = (index: number) => {
    if (collapseItems.get(index)) return <KeyboardArrowUp />;
    return <KeyboardArrowDown />;
  };

  const callTypificationTranslation = (value: string) => {
    const translationPath = LAST_CONTACTS_TYPIFICATION.find((each) => {
      const formattedText = each.text.trim().toUpperCase();
      const formattedValue = value
        ?.trim()
        ?.replace(/\s*\/\s*/g, " ")
        ?.toUpperCase();
      return formattedText === formattedValue;
    });

    return t(translationPath?.translation || "");
  };

  return (
    <Box>
      <Timelinebase
        test-id="timeline-last-contacts"
        classes={{ root: classes.timelineBaseContainer }}
      >
        {lastContacts.map((lastContact, index) => (
          <TimelineItem
            classes={{ root: classes.item }}
            test-id="timeline-item-last-contacts"
            key={`timeline-item-${index + lastContact.agentId}`}
          >
            <TimelineOppositeContent
              classes={{ root: classes.oppositeContainer }}
            >
              <Typography variant="h3" component="span">
                {Moment(lastContact.endCallDate).format(formatDate())}
              </Typography>
              <Typography className={classes.time} variant="h3">
                {lastContact.endCallTime}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                classes={{
                  root: isNoHitClassification(lastContact.callTypification)
                    ? classes.dotSeparatorNoHit
                    : classes.dotSeparatorHit,
                }}
              />
              {renderSeparator(index) && (
                <TimelineConnector classes={{ root: classes.connector }} />
              )}
            </TimelineSeparator>
            <TimelineContent classes={{ root: classes.content }}>
              <Box className={classes.boxCollapse}>
                <Typography className={classes.title}>
                  {callTypificationTranslation(lastContact.callTypification)}
                </Typography>
                <IconButton
                  aria-label="toggle dropdown visibility"
                  onClick={() => handleCollapse(lastContact, index)}
                  edge="end"
                  classes={{ label: classes.icon }}
                  test-id="collapse-button-timeline-last-contacts"
                >
                  {renderIcon(index)}
                </IconButton>
              </Box>

              <Collapse
                in={collapseItems.get(index)}
                data-testid={`collapse-${index}-timeline-last-contacts`}
              >
                <Box test-id="collapse-box-timeline-last-contacts">
                  <CommentsAndOrder
                    id={
                      lastContact.endCallDate +
                      lastContact.endCallTime +
                      lastContact.agentId
                    }
                    email={lastContact.agentId}
                    comment={lastContact.typificationDescription}
                    orders={lastContact.orderInfo}
                  />

                  {!!lastContact.missions?.length &&
                    !isNoHitClassification(lastContact.callTypification) && (
                      <AllMissions missions={lastContact.missions} />
                    )}
                </Box>
                <Divider className={classes.divider} />
              </Collapse>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timelinebase>
    </Box>
  );
};

export default Timeline;
