import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.grey[50],
      boxShadow: "1px 10px 9px -9px rgba(143,143,143,1)",
      height: 60,
      display: "flex",
      alignItems: "center",
      padding: "0px 63px 0 50px",
    },
    buttonsContainer: {
      height: "100%",
    },
    container: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      height: "100%",
    },
    logo: {
      height: "42px",
      display: "flex",
      alignItems: "center",
      width: 200,
    },
    tabsContainer: {
      display: "flex",
      alignItems: "flex-end",
      transform: "translate(24%, -100%)",
      zIndex: 1000,
      position: "absolute",
      width: "max-content",
      [theme.breakpoints.up(992)]: {
        marginLeft: 180,
      },
    },
    tabButton: {
      color: theme.palette.text.secondary,
      backgroundColor: "#fff",
      width: "140px",
      height: "40px",
      marginRight: "1px",
      borderRadius: "5px 5px 0 0 ",
      fontWeight: 600,
      textTransform: "capitalize",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    activeTab: {
      borderBottom: "2px solid #000",
      color: theme.palette.text.primary,
    },
    tabIcon: {
      display: "flex",
    },
    tabText: {
      display: "flex",
      alignItems: "center",
      fontFamily: "Barlow",
      paddingBottom: 12,
      fontSize: "16px",
    },
    notActivatedTabIcon: {
      "&& g": {
        fill: theme.palette.common.white,
      },
    },
    settingsContainer: {
      alignSelf: "center",
    },
    settingsButton: {
      cursor: "pointer",
      height: 40,
      borderRadius: 20,
      borderColor: theme.palette.grey.A100,
    },
    settingsIcon: {
      height: 16,
      width: 16,
      marginLeft: 4,
    },
    settingsLanguage: {
      fontSize: 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  }),
);
