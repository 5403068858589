import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Typography } from "@material-ui/core";
import { Button } from "@hexa-ui/components";
import { X } from "@hexa-ui/icons";
import Modal from "../../../../../../../../components/modal/Modal";
import InactivateConfirmationModalStyles from "./InactivateConfirmationModal.styles";
import { classificationTypes } from "../../ClassificationTypeEnum";

interface InactivateConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleInactivateButtonClick: (classification: classificationTypes) => void;
  phoneNumber: string;
}

const InactivateConfirmationModal = ({
  isOpen,
  handleInactivateButtonClick,
  onClose,
  phoneNumber,
}: InactivateConfirmationModalProps): JSX.Element => {
  const classes = InactivateConfirmationModalStyles();
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} borderRadius="24px">
      <div className={classes.container}>
        <IconButton
          onClick={onClose}
          data-testid="closeButton"
          className={classes.closeButton}
        >
          <X size="medium" />
        </IconButton>
        <Typography className={classes.title}>
          {t("PHONE_RANKING.INACTIVATE_MODAL_TITLE")} {phoneNumber}?
        </Typography>
        <div className={classes.actionsContainer}>
          <Button
            className={classes.inactivateButton}
            size="medium"
            variant="primary"
            onClick={() =>
              handleInactivateButtonClick(classificationTypes.INACTIVATE)
            }
          >
            {t("PHONE_RANKING.INACTIVATE")}
          </Button>
          <Button
            className={classes.cancelButton}
            size="medium"
            variant="secondary"
            onClick={onClose}
          >
            {t("PHONE_RANKING.CANCEL_MODAL")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InactivateConfirmationModal;
