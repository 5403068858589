import React from "react";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function IconStarFilled() {
  return (
    <div
      style={{
        transform: "translate(-4px, 1px)",
        display: "inline-block",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="12"
        height="12"
        viewBox="0 0 12 12"
      >
        <defs>
          <path
            id="star-filled-a"
            d="M4.87807031,2.31417587 L6.88578728,2.60528742 C7.03950985,2.62757662 7.14605764,2.77026235 7.12376843,2.92398492 C7.1148277,2.98564674 7.08566449,3.04259417 7.04085656,3.08588814 L5.58984375,4.48787461 L5.58984375,4.48787461 L5.93271454,6.47100167 C5.95917758,6.62406095 5.85655104,6.76959254 5.70349176,6.79605558 C5.6427168,6.8065632 5.58017286,6.79680193 5.52549008,6.76827474 L3.7265625,5.82980087 L3.7265625,5.82980087 L1.92763492,6.76827474 C1.78991857,6.84011932 1.62003587,6.7867197 1.5481913,6.64900335 C1.5196641,6.59432058 1.50990283,6.53177664 1.52041046,6.47100167 L1.86328125,4.48787461 L1.86328125,4.48787461 L0.412268438,3.08588814 C0.300562553,2.97795653 0.297502893,2.79990517 0.405434496,2.68819929 C0.448728465,2.64339136 0.505675891,2.61422815 0.567337718,2.60528742 L2.57505469,2.31417587 L2.57505469,2.31417587 L3.47476246,0.506040494 C3.54395979,0.36697517 3.71279004,0.310335976 3.85185536,0.379533312 C3.90667011,0.406808513 3.95108734,0.451225746 3.97836254,0.506040494 L4.87807031,2.31417587 L4.87807031,2.31417587 Z"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <circle cx="6" cy="6" r="6" fill="#000" fillOpacity=".55" />
          <g transform="translate(2.26 2.414)">
            <mask id="star-filled-b" fill="#fff">
              <use xlinkHref="#star-filled-a" />
            </mask>
            <use xlinkHref="#star-filled-a" fill="#000" />
            <g fill="#FFF" fillOpacity=".95" mask="url(#star-filled-b)">
              <rect width="9" height="9" transform="translate(-.76 -.914)" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
