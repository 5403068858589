import React, { ReactNode, FunctionComponent } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHeadBase from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

interface Props {
  children: ReactNode;
  testId: string;
}

const TableHead: FunctionComponent<Props> = ({ children, testId }) => {
  return (
    <TableHeadBase data-testid={`${testId}-table-head`}>
      <TableRow>
        <TableCell style={{ padding: "8px", border: "0px" }} />
        {children}
      </TableRow>
    </TableHeadBase>
  );
};

export default TableHead;
