import React from "react";
import { Card, Divider } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import SponsoredCard from "./SponsoredCard.styles";

interface Props {
  isRedesign?: boolean;
}

const SponsoredCardSkeleton: React.FC<Props> = ({ isRedesign }: Props) => {
  const classes = SponsoredCard({ isRedesign });

  const cardProduct = () => {
    return (
      <div className={classes.productCardSkeleton}>
        <Skeleton width={106} height={106} />
        <div className={classes.productInfoSkeleton}>
          <Skeleton width={157} height={53} style={{ marginBottom: "8px" }} />
          <Skeleton
            width={98.39}
            height={18.25}
            style={{ marginBottom: "38px" }}
          />
          <div className={classes.productButtonSkeleton}>
            <Skeleton width={79} height={35} style={{ marginRight: "16px" }} />
            <Skeleton width={106} height={35} />
          </div>
        </div>
      </div>
    );
  };

  return isRedesign ? (
    <Card
      className={classes.cardContainerRedesign}
      elevation={0}
      data-testid="card-redesign-skeleton"
    >
      <div>
        <Skeleton width={135} height={17} className={classes.titleSkeleton} />
        <Skeleton width="100%" height={72} />
      </div>
      <Divider className={classes.separator} />
      <Skeleton width={135} height={17} className={classes.titleSkeleton} />
      <div className={classes.productsContainerSkeleton}>
        {cardProduct()}
        {cardProduct()}
      </div>
    </Card>
  ) : (
    <Card className={classes.cardContainer} data-testid="card-skeleton">
      <div>
        <Skeleton width={135} height={17} className={classes.titleSkeleton} />
        <Skeleton width="100%" height={72} />
      </div>
      <Divider className={classes.separator} />
      <Skeleton width={135} height={17} className={classes.titleSkeleton} />
      <div className={classes.productsContainerSkeleton}>
        <div className={classes.productCardSkeleton}>
          <Skeleton width={106} height={106} />
          <div className={classes.productInfoSkeleton}>
            <Skeleton width={163} height={18.25} />
            <Skeleton width={111} height={18.25} />
            <Skeleton width={90} height={18.25} />
            <Skeleton width={99} height={18.25} />
          </div>
        </div>
        <div className={classes.productCardSkeleton}>
          <Skeleton width={106} height={106} />
          <div className={classes.productInfoSkeleton}>
            <Skeleton width={163} height={18.25} />
            <Skeleton width={111} height={18.25} />
            <Skeleton width={90} height={18.25} />
            <Skeleton width={99} height={18.25} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default SponsoredCardSkeleton;
