import { createStore } from "effector";
import { getUpdateKPIEffect } from "./UpdateKPIEvents";
import UpdateKPIState from "./UpdateKPIState";
import UpdateKPI from "../../domains/agentKPI/UpdateKPI";

const initialState = <UpdateKPIState>{
  done: false,
  isLoading: true,
  error: false,
  updateKPI: <UpdateKPI>{
    date: undefined,
    pocAssigned: null,
    hitRate: null,
    toplineUplift: 0,
    topLineVolume: 0,
    totalGap: 0,
  },
};

const updateKPIStore = createStore(initialState)
  .on(getUpdateKPIEffect.done, (state: UpdateKPIState, { result }) => ({
    ...state,
    ...result,
    done: true,
    isLoading: false,
    error: false,
  }))
  .on(getUpdateKPIEffect.fail, (state: UpdateKPIState) => ({
    ...state,
    done: true,
    isLoading: false,
    error: true,
  }))
  .on(getUpdateKPIEffect, (state: UpdateKPIState) => ({
    ...state,
    done: false,
    isLoading: true,
    error: false,
  }));

export default updateKPIStore;
