import { createStyles, makeStyles } from "@material-ui/core/styles";

const cardUpliftStyles = makeStyles(({ palette }) =>
  createStyles({
    containerBox: {
      height: "240px",
      borderRadius: "15px",
      margin: "16px 0px 10px 0",
      minWidth: "211px",
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.16)",
    },
    overwriteTitle: {
      fontSize: "14px",
      fontWeight: "normal",
    },
    title: {
      height: "31px",
      display: "flex",
      alignItems: "center",
      padding: "0px 24px 0px 24px",
      color: palette.text.primary,
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.grey[100]}`,
      borderRadius: "5px 5px 0px 0px",
    },
    actionButton: {
      width: "20px",
      height: "20px",
      padding: 0,
      margin: 0,
      minHeight: 0,
      minWidth: 0,
    },
    actionButtonIcon: {
      fontSize: "17px",
      color: "#7A7A7A",
    },
    percentage: {
      letterSpacing: "0px",
      color: palette.text.primary,
      fontSize: "25px",
      height: "52px",
      fontWeight: "bold",
      fontFamily: "Barlow",
    },
    percentageSecondary: {
      letterSpacing: "0px",
      color: palette.text.primary,
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Barlow",
    },
    moneyValue: {
      letterSpacing: "0px",
      color: palette.info.main,
      fontSize: "25px",
      height: "52px",
      fontWeight: "bold",
      fontFamily: "Barlow",
    },
    moneyValueSecondary: {
      letterSpacing: "0px",
      color: palette.info.contrastText,
      fontSize: "18px",
      fontWeight: "bold",
      fontFamily: "Barlow",
    },
    divider: {
      height: "209px",
      width: "1px",
      backgroundColor: palette.grey[100],
    },
    mainValue: {
      letterSpacing: "0px",
      color: palette.secondary.main,
      fontSize: "23px",
      height: "30px",
      fontWeight: "bold",
    },
    mainLabel: {
      marginTop: "-12px",
      color: palette.text.secondary,
    },
    labelOverwrite: {
      opacity: "1",
    },
    secondaryLabel: {
      whiteSpace: "nowrap",
      marginTop: "0px",
      color: palette.text.secondary,
    },
    cardContent: {
      backgroundColor: palette.grey[50],
      padding: "0px",
    },
    gridContent: {
      flexDirection: "row",
      display: "flex",
    },
    gridBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "16px",
      marginBottom: "16px",
      width: "33%",
      justifyContent: "center",
    },
    gridBoxSecondary: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "16px",
      marginBottom: "16px",
      width: "100%",
      justifyContent: "center",
    },
    labelBox: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignItems: "center",
    },
    centerBox: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignItems: "center",
      margin: "16px 0px",
    },
  }),
);

export default cardUpliftStyles;
