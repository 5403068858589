import React, { ReactNode } from "react";
import useStyles from "./PocInfoContainerStyles";

interface Props {
  children: ReactNode;
  className?: string;
}

function PocInfoContainer({ className, children }: Props): React.ReactElement {
  const classes = useStyles();

  return <div className={`${classes.container} ${className}`}>{children}</div>;
}

PocInfoContainer.defaultProps = {
  className: "",
};

export default PocInfoContainer;
