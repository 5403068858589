import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      backgroundColor: "#ffffff",
      width: "100%",
      padding: "1.25rem",
      display: "flex",
      flexDirection: "column",
      marginBottom: "1.56rem",
      borderRadius: "24px",
      boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.16)",
    },
    title: {
      fontWeight: 600,
      fontSize: "1.25rem",
      marginBottom: "1rem",
      color: palette.primary.main,
    },
    loading: {
      marginTop: "1rem",
    },
    errorContent: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
  }),
);
