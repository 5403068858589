import React from "react";
import { Modal as ModalBase, Box } from "@material-ui/core";
import { ModalProps } from "@material-ui/core/Modal";

import ModalStyles from "./ModalStyles";

interface GenericModalProps extends ModalProps {
  borderRadius?: string;
}

const Modal: React.FunctionComponent<GenericModalProps> = ({
  open,
  onClose,
  children,
  borderRadius,
  ...props
}) => {
  const classes = ModalStyles({ borderRadius });
  return (
    <ModalBase
      open={open}
      onClose={onClose}
      className={classes.modal}
      disableBackdropClick
      {...props}
    >
      <Box className={classes.container}>{children}</Box>
    </ModalBase>
  );
};

export default Modal;
