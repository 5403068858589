import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    accordion: {
      borderRadius: "10px",
      margin: "0 0 16px 0",
      boxShadow: " 0 4px 4px 0 rgba(0, 0, 0, 0.16)",

      "&.Mui-expanded": {
        margin: "0 0 16px 0",
      },
      "&:before": {
        height: 0,
      },
      "&.tlp-MuiAccordion-rounded:last-child": {
        borderRadius: "10px",
      },
    },
    accordionSummaryRoot: {
      padding: 0,
    },
    ControlledAddition: {
      margin: "13px 0 18px 0",
    },
    accordionSummaryContent: {
      display: "flex",
      flexDirection: "column",
      margin: 0,

      "&.Mui-expanded": {
        margin: 0,
      },
    },
    skuColor: {
      color: palette.text.secondary,
    },
    marginRight: {
      marginRight: "4px",
    },
    skuContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "8px",
    },
    accordionExpandedBackground: {
      backgroundColor: palette.grey[50],
      borderRadius: "10px",
    },
    title: {
      fontSize: "14px",
      fontWeight: 500,
      color: palette.secondary.main,
      padding: "8px 16px",
      borderBottom: `solid 1px ${palette.grey[100]}`,
      lineHeight: 1.14,
      cursor: "default",
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
    },
    expandButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "8px 16px",
    },
    icon: {
      borderRadius: "3px",
      backgroundColor: palette.secondary.main,
      color: palette.common.white,
      height: "15px",
      width: "15px",
      marginRight: "11px",
    },
    expandButtonLabel: {
      fontSize: "14px !important",
      fontWeight: 500,
      color: palette.secondary.main,
      fontStretch: "normal",
    },
    accordionDetails: {
      display: "flex",
      flexDirection: "column",
    },
    description: {
      fontSize: "12px",
      lineHeight: "normal",
      color: palette.text.secondary,
      fontWeight: 500,
    },
    skuDescription: {
      color: palette.text.secondary,
      fontWeight: 500,
      fontFamily: "Work Sans",
      fontSize: "11px",
      lineHeight: "normal",
      margin: "5px 0",
    },
    itemsContainer: {
      padding: "12px 0 0 0",
    },
    itemsName: {
      color: palette.text.primary,
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "normal",
      marginBottom: "8px",
    },
    promotionsItemsName: {
      color: palette.text.secondary,
      fontWeight: 500,
      fontSize: 11,
      lineHeight: "normal",
      letterSpacing: "normal",
      marginRight: 11,
    },
    uppercase: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "5px",
    },
    priceContainer: {
      display: "flex",
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "normal",
      marginBottom: "5px",
      color: palette.secondary.main,
    },
    originalPrice: {
      color: palette.error.main,
      textDecoration: "line-through",
      marginRight: "4px",
      fontWeight: 600,
    },
    originalPriceSteppedDiscount: {
      marginLeft: "8px",
    },
    price: {
      color: "#007a78",
      marginLeft: "4px",
      fontWeight: 600,
    },
    promotionsPrice: {
      color: "#007a78",
      fontSize: 11,
      letterSpacing: "normal",
      fontWeight: 600,
    },
    steppedDiscountContainer: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "normal",
      marginBottom: "8px",
      color: palette.secondary.main,
    },
    freeGoodContainer: {
      display: "flex",
      flexDirection: "column",
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "normal",
      color: palette.secondary.main,
    },
    freeGoodItemsContainer: {
      marginTop: "4px",
    },
    optionFreeGoodTitle: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "normal",
      color: palette.secondary.main,
      margin: "8px 0 0 0",
      letterSpacing: "normal",
    },
    freeGoodTitle: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "normal",
      color: palette.secondary.main,
      marginBottom: "4px",
      marginTop: "2px",
      textDecoration: "underline",
    },
    steppedContainer: {
      margin: "0px 0px 5px 0px",
    },
    freeGoodItem: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "normal",
      color: "#148cc6",
      marginBottom: "4px",
    },
    marketplace: {
      width: 68,
    },
    buttonAddCart: {
      width: "125px",
      fontSize: "14px",
      margin: "8px 111px 12px 0",
      padding: "8px 16px 8px",
      border: "solid 1px #000",
      backgroundColor: "#fff",
      color: "#212121",
    },
    promoAddButtonSection: {
      marginTop: "16px",
      marginBottom: "6px",
    },
    showMoreWhenCombos: {
      color: "#0363C4",
      marginTop: 15,
      textDecoration: "underline",
      textDecorationColor: "#0363C4",
    },
    showMoreWhenPromotions: {
      color: "#0363C4",
      marginTop: "2px",
      marginBottom: "10px",
      textDecoration: "underline",
      textDecorationColor: "#0363C4",
      padding: "0px",
    },
  }),
);
