import React from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FormikErrors, FormikTouched } from "formik";
import { useStore } from "effector-react";
import MissionsEffectivenessStyles from "./styles";
import MissionCompletion from "../completion/MissionCompletion";
import { Mission } from "../../../../../domains/Mission";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import Missions from "../missions/Missions";

import { FormValues } from "../formTypes/FormTypes";
import {
  isFeatureEnabledV2,
  TLP_SERVICE_MISSION_EFFECTIVENESS_CALL_SUMMARY,
} from "../../../../../config/featureToggles";

interface Props {
  testId: string;
  errors: FormikErrors<FormValues>;
  touched: FormikTouched<FormValues>;
  missions: Array<Mission>;
  expanded: Map<string, boolean>;
  handleExpanded: (tag: string, isExpanded: boolean) => void;
  setFieldValue: (
    field:
      | "classification"
      | "callLater"
      | "classificationDetails"
      | "missions",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
}

const MissionsEffectiveness: React.FunctionComponent<Props> = ({
  testId,
  missions,
  setFieldValue,
  expanded,
  handleExpanded,
  errors,
  touched,
}) => {
  const missionKey = (index: number) => `mission-effectiveness-${index}`;
  const { user } = useStore(GlobalStore);

  const { t } = useTranslation();
  const classes = MissionsEffectivenessStyles();
  const isCallSummaryDigitalServicesEffectivenessEnabled = isFeatureEnabledV2(
    TLP_SERVICE_MISSION_EFFECTIVENESS_CALL_SUMMARY,
    user.keyToggle,
  );

  const handleChangeCampaignType = (
    e: React.ChangeEvent<{
      name?: string;
      value: string;
    }>,
    tag: string,
    usage: string,
  ) => {
    const updatedMissions = [...missions];
    const index = updatedMissions.findIndex((mi) => mi.tag === tag);
    const exists = updatedMissions[index];
    if (exists) {
      if (usage === "classification") {
        exists.typification = e.target.value;
      }

      if (usage === "gapreason") {
        exists.reason = e.target.value;
      }

      if (usage === "comments") {
        exists.comments = e.target.value;
      }
    }
    updatedMissions[index] = exists;
    setFieldValue("missions", updatedMissions);
  };

  const handleChangeCompletion = (tag: string, completed: boolean) => {
    const index = missions.findIndex((mi) => mi.tag === tag);

    if (index !== -1) {
      missions[index].effectiveness = completed;
      handleExpanded?.(tag, true);
    }

    setFieldValue("missions", [...missions]);
  };

  const noResultsText = () => (
    <div className={classes.noMissionsText} data-testid="no-missios-find">
      {t("NEWCallSummary.NOT_FOUND")}
    </div>
  );

  const validateExpanded = (tag: string) => {
    const isExpanded = expanded?.get(tag) ?? false;
    return isExpanded;
  };

  const hasMissionError = (index) =>
    errors?.missions
      ? (errors?.missions[index] as FormikErrors<Mission>)
      : undefined;

  const hasTouchedMissionError = (index) =>
    touched?.missions ? touched?.missions[index] : undefined;

  const renderContentOrNoMissionsFound = () =>
    missions?.length > 0 ? (
      <>
        <Box className={classes.innerContent}>
          <Box className={classes.missionsTitle}>
            {t("NEWCallSummary.MISSIONS_LABEL")}
          </Box>
          <Box className={classes.completionTitle}>
            {t("NEWCallSummary.COMPLETION_LABEL")}
          </Box>
        </Box>
        {missions
          .map((item, index) => {
            if (
              !isCallSummaryDigitalServicesEffectivenessEnabled &&
              item.tag === "#digital_services"
            ) {
              return null;
            }

            return (
              <Box className={classes.innerContent} key={missionKey(index)}>
                <Box
                  className={classes.innerLeft}
                  key={`mission-effectiveness-mission-${item.tag}`}
                >
                  <Missions
                    key={item.tag}
                    values={item}
                    errors={hasMissionError(index)}
                    touched={hasTouchedMissionError(index)}
                    handleChange={handleChangeCampaignType}
                    isExpanded={validateExpanded(item.tag)}
                    handleExpanded={handleExpanded}
                  />
                </Box>
                <Box
                  className={classes.innerRight}
                  key={`mission-effectiveness-completion-${item.tag}`}
                >
                  <MissionCompletion
                    key={`mission-completion-${item.tag}`}
                    completed={item.effectiveness}
                    tag={item.tag}
                    errors={hasMissionError(index)}
                    touched={hasTouchedMissionError(index)}
                    handleChange={handleChangeCompletion}
                  />
                </Box>
              </Box>
            );
          })
          .filter((mission) => mission)}
      </>
    ) : (
      noResultsText()
    );

  return (
    <Box data-testid={testId} className={classes.content}>
      {renderContentOrNoMissionsFound()}
    </Box>
  );
};

export default React.memo(MissionsEffectiveness);
