import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      textAlign: "right",
      flexWrap: "wrap",
      marginLeft: "-3px",
    },
    addedItemText: {
      marginLeft: "4px",
      height: "18px",
      fontSize: "10px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "18px",
    },
    addedItemSubText: {
      marginLeft: "4px",
      height: "18px",
      fontSize: "10px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "18px",
      textTransform: "lowercase",
    },
    addedItemTextLink: {
      marginLeft: "4px",
      height: "18px",
      fontSize: "10px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "18px",
      color: "#0371e2",
      textDecoration: "underline",
    },
  }),
);
