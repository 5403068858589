import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    searchOfferingsTextField: {
      color: palette.secondary.main,
      "&.Mui-focused fieldset.tlp-MuiOutlinedInput-notchedOutline": {
        borderColor: palette.secondary.main,
        borderWidth: "1px",
      },
      "& fieldset": {
        borderColor: palette.secondary.main,
        borderRight: 0,
      },
    },
    searchOfferingsIconColor: {
      color: palette.secondary.main,
    },
    searchOfferingsInputContainer: {
      display: "flex",
      marginBottom: "16px",
      height: "40px",
      padding: 5,
      borderRadius: "20px",
      backgroundColor: "#f2f2f2",
      border: 0,
      flex: 1,
      alignItems: "center",
      paddingLeft: 10,
    },
    searchOfferingsIconContainer: {
      border: `1px solid ${palette.secondary.main}`,
      borderRadius: "0px 3px 3px 0px",
      padding: "2px 10px 0 10px",
    },
    searchOfferingsClearContainer: {
      fontSize: "12px",
      width: "150px",
      border: `1px solid ${palette.secondary.main}`,
      borderRight: "none",
      borderLeft: "none",
      textDecoration: "underline",
      fontWeight: "bold",
      paddingRight: "3px",
      color: "#505050",
      maxWidth: "165px",
      whiteSpace: "nowrap",
      borderRadius: "0px",
    },

    searchInputOfferings: {
      flex: 1,
      color: "black",
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.14,
      marginLeft: 10,
    },
    searchClearOfferingsContainer: {
      fontSize: "12px",
      backgroundColor: "#dfdede",
      width: 24,
      height: 24,
    },
  }),
);
