import { useState, useCallback } from "react";
import { useAnalytics } from "../../../analytics/useAnalytics";
import { AgentSurveyUpdateSurveyEffect } from "../../../stores/agentSurvey/AgentSurveyEvents";
import { npsAnswered } from "../../../config/typewriter";

interface UseEnpsAgents {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseModal: (surveyId: string, agentId: string) => void;
}

function useEnpsAgents(): UseEnpsAgents {
  const { dispatchGenericEvent } = useAnalytics();
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = useCallback(
    (surveyId, agentId) => {
      dispatchGenericEvent(npsAnswered, {});
      AgentSurveyUpdateSurveyEffect({ surveyId, agentId });
      setModalOpen(false);
    },
    [dispatchGenericEvent],
  );

  return {
    modalOpen,
    setModalOpen,
    handleCloseModal,
  };
}

export default useEnpsAgents;
