import { createStore } from "effector";
import { SponsoredMission } from "../../domains/sponsored/SponsoredMission";
import { MISSION_TYPES } from "../../config/constants";
import {
  getSponsoredMissionsEvent,
  getSponsoredProductsEvent,
  getFlexibleMissionsByIdsEffect,
  setSponsoredMissionsIds,
  setLocalMissionsIds,
  setFlexibleMissionsIds,
  resetGetSponsoredMissionsEventIsDone,
  resetSponsoredEvents,
} from "./SponsoredEvents";
import {
  getEffectiveMissions,
  setEffectiveMissionsEvent,
} from "../globalAndLocalMissions/EffectivenessMissionEvents";

import SponsoredState from "./SponsoredState";

const initialState = <SponsoredState>{
  done: false,
  getSponsoredMissionsEventIsDone: false,
  isSponsoredMissionsLoading: false,
  sponsoredMissionsError: false,
  isSponsoredProductsLoading: false,
  sponsoredProductsError: false,
  flexibleMissionsDone: false,
  isFlexibleMissionsLoading: false,
  flexibleMissionsError: false,
  sponsoredMissions: [],
  localMissions: [],
  sponsoredMissionsIds: [],
  localMissionsIds: [],
  flexibleMissionsIds: [],
  flexibleMissions: [],
};

const SponsoredStore = createStore(initialState)
  .on(getSponsoredMissionsEvent, (state) => ({
    ...state,
    isSponsoredMissionsLoading: true,
    done: false,
    sponsoredMissionsError: false,
  }))
  .on(getSponsoredMissionsEvent.done, (state, { result }) => {
    const localsOnlyFlexible = [...state.localMissions].filter(
      ({ missionType }) => missionType === MISSION_TYPES.FLEXIBLE,
    );

    const newState = [...localsOnlyFlexible, ...result.localMissions];

    return {
      ...state,
      sponsoredMissions: result.sponsoredMissions,
      localMissions: newState,
      sponsoredMissionsError: false,
      isSponsoredMissionsLoading: false,
      done: true,
      getSponsoredMissionsEventIsDone: true,
    };
  })
  .on(resetGetSponsoredMissionsEventIsDone, (state) => ({
    ...state,
    getSponsoredMissionsEventIsDone: false,
  }))
  .on(getSponsoredMissionsEvent.fail, (state) => ({
    ...state,
    sponsoredMissionsError: true,
    isSponsoredMissionsLoading: false,
    done: true,
  }))
  .on(getSponsoredProductsEvent, (state) => ({
    ...state,
    isSponsoredProductsLoading: true,
    sponsoredProductsError: false,
    done: false,
  }))
  .on(getSponsoredProductsEvent.done, (state, { result }) => {
    /* istanbul ignore next */
    const sponsoredMissions = state.sponsoredMissions.map((mission) => {
      if (mission.id === result.sponsoredId) {
        return { ...mission, products: result.products };
      }
      return mission;
    });

    /* istanbul ignore next */
    const localMissions = state.localMissions.map((mission) => {
      if (mission.id === result.sponsoredId) {
        return { ...mission, products: result.products };
      }
      return mission;
    });

    /* istanbul ignore next */
    return {
      ...state,
      sponsoredMissions,
      localMissions,
      sponsoredProductsError: false,
      isSponsoredProductsLoading: false,
      done: true,
    };
  })
  .on(getSponsoredProductsEvent.fail, (state) => ({
    ...state,
    sponsoredProductsError: true,
    isSponsoredProductsLoading: false,
    done: true,
  }))
  .on(setSponsoredMissionsIds.done, (state, { result }) => ({
    ...state,
    sponsoredMissionsIds: result,
  }))
  .on(setLocalMissionsIds.done, (state, { result }) => ({
    ...state,
    localMissionsIds: result,
  }))
  .on(setFlexibleMissionsIds, (state, flexibleMissionsIds) => ({
    ...state,
    flexibleMissionsIds,
  }))
  .on(getFlexibleMissionsByIdsEffect, (state) => ({
    ...state,
    flexibleMissionsDone: false,
    isFlexibleMissionsLoading: true,
    flexibleMissionsError: false,
    flexibleMissions: [],
  }))
  .on(getFlexibleMissionsByIdsEffect.done, (state, { result }) => {
    const convertedFlexibles: SponsoredMission[] = result.map(
      ({ missionId, missionName, endDate, script, effectiveness }) => ({
        id: missionId,
        missionTitle: missionName,
        missionType: MISSION_TYPES.FLEXIBLE,
        endDate: new Date(endDate),
        scriptText: script,
        flexibleEffectiveness: effectiveness,
      }),
    );

    const localsWithoutFlexible = [...state.localMissions].filter(
      ({ missionType }) => missionType !== MISSION_TYPES.FLEXIBLE,
    );

    const newState = [...localsWithoutFlexible, ...convertedFlexibles];

    setEffectiveMissionsEvent(
      getEffectiveMissions({
        sponsoredMissions: [],
        localMissions: newState,
      }),
    );

    return {
      ...state,
      localMissions: newState,
      flexibleMissionsDone: true,
      isFlexibleMissionsLoading: false,
      flexibleMissionsError: false,
      flexibleMissions: result,
    };
  })
  .on(getFlexibleMissionsByIdsEffect.fail, (state) => ({
    ...state,
    flexibleMissionsDone: true,
    isFlexibleMissionsLoading: false,
    flexibleMissionsError: true,
  }))
  .reset(resetSponsoredEvents);

export default SponsoredStore;
