import React from "react";
import { useTranslation } from "react-i18next";
import CardInfo from "../../../components/cardInfo/CardInfo";
import CheckIcon from "../../../layouts/components/navigation/CheckIcon";
import palette from "../../../theme/Palette";

interface Props {
  target?: number | null;
  countKPI?: number | null;
}

function HitRate({ target, countKPI }: Props): React.ReactElement {
  const { t } = useTranslation();

  return (
    <CardInfo
      key="card-info"
      title={t("HitRate.TITLE")}
      mainLabel={t("HitRate.COMPLETED_TEXT")}
      secondaryLabel={t("HitRate.TARGET_TEXT")}
      mainValue={countKPI}
      secondaryValue={target}
      testId="card-hit-rate"
      actionButton={<CheckIcon />}
      targetColor={palette.info.contrastText}
    />
  );
}

HitRate.defaultProps = {
  countKPI: undefined,
  target: undefined,
};

export default HitRate;
