import { isPromotionComboTypeValid } from "@config/utils/functions";
import { ConvertedComboInterface } from "@converters/promotions/promotionsV3/domains/CombosResponseToCombosInfoConverterDomain";
import { getVendorName } from "grow-shared-services";
import {
  GROW_ORGANIZATION_ACTIVATION,
  GROW_UPGRADE_CATALOG_V3,
  isFeatureEnabledV2,
} from "../../config/featureToggles";
import { SponsoredProductConverter } from "../../converters/sponsoredProduct/SponsoredProductConverter";
import Catalog from "../../domains/Catalog";
import { Product } from "../../domains/products/Product";
import { SponsoredItemsOrCombosResponse } from "../../domains/sponsored/SponsoredMissionServiceResponse";
import { getBFFCatalog } from "../../services/catalog/bff/CatalogBFFService";
import { getCatalogV2 } from "../../services/catalog/v2/CatalogV2Service";
import { getCatalogV3 } from "../../services/catalog/v3/CatalogV3Service";
import AgentCallStore from "../../stores/agentCall/AgentCallStore";
import GlobalAdminConfigStore from "../../stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "../../stores/global/GlobalStore";
import PromotionsStore from "../../stores/promotions/PromotionsStore";

export async function execute(
  sponsoredId: string,
  sponsoredProducts: SponsoredItemsOrCombosResponse[],
  sponsoredCombos: SponsoredItemsOrCombosResponse[],
): Promise<{ sponsoredId: string; products: Product[] }> {
  const { clientId } = AgentCallStore.getState();
  const { combos, error } = PromotionsStore.getState();
  const { user } = GlobalStore.getState();
  const GlobalAdminConfigState = GlobalAdminConfigStore.getState();
  const { vendorId } = GlobalAdminConfigState.userConfig;
  const vendorName = getVendorName(vendorId);

  if (sponsoredCombos.length > 0 && error) {
    throw new Error();
  }

  const isCatalogV3 = isFeatureEnabledV2(
    GROW_UPGRADE_CATALOG_V3,
    user.keyToggle,
  );
  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    user.keyToggle,
  );

  /* istanbul ignore next */
  const accountCombos: Array<ConvertedComboInterface> = combos.filter(
    (combo) =>
      sponsoredCombos.some(
        (sponsoredCombo) =>
          sponsoredCombo.comboPlatformId === combo.platformId ||
          sponsoredCombo.id === combo.vendorComboId ||
          sponsoredCombo.comboId === combo.vendorComboId,
      ) && isPromotionComboTypeValid(combo.type),
  );

  let allProducts = <Catalog>{};

  /* istanbul ignore else */
  if (sponsoredProducts.length > 0) {
    if (isCatalogV3) {
      /* istanbul ignore next */
      if (isOrganizationConfigEnabled) {
        const vendorItemIds = sponsoredProducts
          .map((each) => each.id)
          .toString();

        allProducts = await getBFFCatalog(clientId, vendorId, vendorItemIds);
      } else {
        const platformIds = sponsoredProducts
          .map((each) => each.itemPlatformId)
          .toString();

        allProducts = await getCatalogV3(
          clientId,
          platformIds,
          user.zone,
          vendorName,
        );
      }
    } else {
      const itemsToSearch = sponsoredProducts.map((each) => each.id).toString();
      allProducts = await getCatalogV2(clientId, itemsToSearch);
    }
  }

  return {
    sponsoredId,
    products: SponsoredProductConverter(
      sponsoredProducts,
      accountCombos,
      sponsoredCombos,
      allProducts,
      isCatalogV3,
      isOrganizationConfigEnabled,
    ),
  };
}
