import { createStyles, makeStyles } from "@material-ui/core";

export const HeaderStyles = makeStyles(({ palette }) =>
  createStyles({
    container: {
      backgroundColor: "white",
      display: "flex",
      margin: "0 0 24px 0",
      height: "72px",
      boxSizing: "border-box",
    },
    infoContainer: {
      paddingLeft: "10px",
    },
    title: {
      fontWeight: "bold",
      fontSize: "12px",
      color: "#f69b30",
    },
    subtitle: {
      fontWeight: 600,
      fontSize: "12px",
      color: palette.text.secondary,
    },
    mockButton: {
      backgroundColor: "#f69b30",
    },
  }),
);
