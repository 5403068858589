import React from "react";
import PhoneRankingTag from "../../../PhoneRankingTag/PhoneRankingTag";

interface PhoneRankingTagsProps {
  isCalled: boolean | undefined;
  sanitizedPhone: string;
  lastCalledPhone: string;
  lastCopiedPhone: string;
  isConnected: boolean;
  isInactive: boolean;
}

const PhoneRankingTags = ({
  isCalled,
  sanitizedPhone,
  lastCalledPhone,
  lastCopiedPhone,
  isConnected,
  isInactive,
}: PhoneRankingTagsProps): JSX.Element => {
  const renderPhoneRankingTags = () => (
    <>
      {(isCalled || sanitizedPhone === lastCalledPhone) && (
        <PhoneRankingTag data-testid="tag-called" calledType="called" />
      )}
      {!isConnected && sanitizedPhone === lastCopiedPhone && (
        <PhoneRankingTag data-testid="tag-copied" calledType="copied" />
      )}
      {isInactive && (
        <PhoneRankingTag
          data-testid="tag-inactivated"
          calledType="inactivated"
        />
      )}
    </>
  );

  return renderPhoneRankingTags();
};

export default PhoneRankingTags;
