import MonthlyKPI from "../../domains/agentKPI/MonthlyKPI";
import MonthlyKPIDomain from "../../domains/agentKPI/MonthlyKPIDomain";
import { MonthlyKPIResponseV3 } from "../../services/agentKPI/domains/MonthlyKPIResponse";

export default function MonthlyKPIResponseV3ToMonthlyKPIConverter({
  monthly,
}: MonthlyKPIResponseV3): MonthlyKPIDomain | undefined {
  if (!monthly) return undefined;

  const monthlyKPI = <MonthlyKPI>{
    monthlyYear: monthly.monthlyYear,
    monthlyPocAssigned: monthly.monthlyPocAssigned ?? 0,
    monthlyHitRate: monthly.monthlyHitRate ?? 0,
    monthlyTotalGap: monthly.monthlyTotalGap,
    monthlyAverageHandleTime: monthly.monthlyAverageHandleTime,
    monthlyToplineUplift: monthly.monthlyToplineUplift,
  };

  return <MonthlyKPIDomain>{
    monthly: monthlyKPI,
  };
}
