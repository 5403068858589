import React, { useEffect } from "react";
import { useStore } from "effector-react";
import { Typography, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./style";
import Timeline from "./timeline/Timeline";
import { getLastContacts } from "../../../../../stores/lastContacts/LastContactsEvents";
import LastContactsStore from "../../../../../stores/lastContacts/LastContactsStore";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import Loading from "../../loading/Loading";
import EmptyLastContacts from "./emptyLastContacts/EmptyLastContacts";
import ErrorRetry from "../../../../../components/errorRetry/ErrorRetry";

const LastContacts: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { clientId } = useStore(AgentCallStore);
  const { lastContacts, isLoading, error } = useStore(LastContactsStore);

  useEffect(() => {
    getLastContacts(clientId);
  }, [clientId]);

  const retryHandler = () => {
    getLastContacts(clientId);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={classes.loading}>
          <Loading />
        </div>
      );
    }

    if (!lastContacts.length && !error) {
      return <EmptyLastContacts />;
    }

    return (
      <Box>
        <Timeline lastContacts={lastContacts} />
      </Box>
    );
  };

  const renderError = () => {
    return (
      <div className={classes.errorContent}>
        <ErrorRetry
          onClick={retryHandler}
          testId="last-contacts-retry"
          screenName="Customer"
          screenSection="Last Contacts"
        />
      </div>
    );
  };

  return (
    <div className={classes.container} data-testid="last-contacts-container">
      <Typography className={classes.title}>
        {t("CUSTOMER_PAGE.LAST_CONTACTS")}
      </Typography>

      {error ? renderError() : renderContent()}
    </div>
  );
};

export default LastContacts;
