import { removeListLocalStorage, urlLogout } from "../utils/functions";
import { UPDATE_LOCAL_STORAGE_LOGOUT } from "../constants";

export function Logout(): void {
  const portalLocalStorage = localStorage.getItem("portal-config");

  removeListLocalStorage(UPDATE_LOCAL_STORAGE_LOGOUT);

  if (portalLocalStorage) {
    window.location.href = urlLogout();
  }
}
