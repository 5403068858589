import GapCalculation from "@services/gap/domains/GapCalculation";
import { Gap, GapItem } from "@stores/agentCall/AgentCallState";

export default function GapCalculationToGapConverter(
  data: GapCalculation,
): Gap {
  return <Gap>{
    topline: <GapItem>{
      total: data?.gap,
      achieved: data?.totalSold,
      target: data?.forecast,
    },
    volume: <GapItem>{
      total: data?.gapHl,
      achieved: data?.totalSoldHl,
      target: data?.forecastHl,
    },
  };
}
