import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import EarnRule from "@domains/EarnRule";
import axios from "../../config/axios/axiosInstance";
import { getApiHost } from "../host/HostB2BService";
import { EarnRuleResponse } from "./domains/LoyaltyBusinessResponse";
import LoyaltyBusinessResponseToEarnRulesConverter from "../../converters/LoyaltyBusinessResponseToEarnRulesConverter";

export const LOYALTY_BUSINESS_V2 =
  "/api/v1/loyalty-business-service/v2/programs/accounts";

export const EARN_RULE = "earn-rules";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getLoyaltyBusinessRulesV2(
  accountId: string,
  platformIds: Array<string | undefined>,
): Promise<Array<EarnRule>> {
  const log = useLogService();

  const endpoint = `${getApiHost()}${LOYALTY_BUSINESS_V2}`;
  const platformIdsParameter = platformIds
    .map((sku) => `platformIds=${sku}`)
    .join("&");

  return axios
    .get<Array<EarnRuleResponse>>(
      `${endpoint}/${accountId}/${EARN_RULE}?${platformIdsParameter}`,
    )
    .then((response) => {
      log.info(
        `Convert Loyalty  Business rules received from ${endpoint} to our model`,
      );
      return LoyaltyBusinessResponseToEarnRulesConverter(response.data);
    })
    .catch((error) => {
      log.error(
        "LoyaltyBusinessService V2 - getLoyaltyBusinessRules error: ",
        error,
      );
      throw error;
    });
}
