import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import axios from "../../config/axios/axiosInstance";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../host/HostService";
import RankedPhones from "../../domains/phoneRanking/RankedPhones";
import converter from "../../converters/phoneRanking/RankedPhonesResponseToRankedPhonesConverter";
import {
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_ACCOUNT_SERVICE,
} from "../../config/featureToggles";
import GlobalStore from "../../stores/global/GlobalStore";
import { TLP_ACCOUNT_SERVICE } from "../../config/services";
import VendorStore from "../../stores/vendor/VendorStore";

export const ACCOUNT_SERVICE = `/tlp-account`;
export const RANKED_PHONES_ENDPOINT = "/ranked-phones";

const execute = async (
  accountId: string,
  vendorId: string,
  vendorName: string,
  phones: Array<string>,
  headers = {},
): Promise<RankedPhones | null> => {
  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();
  const log = useLogService();

  const isTLPAccountMulticontract = isFeatureEnabledV2(
    TLP_MULTICONTRACT_ACCOUNT_SERVICE,
    user.zone,
  );
  const vendorIdMetadata = vendor?.id;
  const apiHost = getApiHost(TLP_ACCOUNT_SERVICE);
  const v2EndPoint = getV2EndPoint(isTLPAccountMulticontract);
  const vendorIdParam = getVendorIdParam(
    vendorIdMetadata,
    isTLPAccountMulticontract,
  );

  const url = `${apiHost}${ACCOUNT_SERVICE}${v2EndPoint}/${accountId}${RANKED_PHONES_ENDPOINT}${vendorIdParam}`;

  try {
    const response = await axios({
      method: "post",
      url,
      data: phones,
      headers: {
        ...headers,
        vendorId,
        vendorName,
      },
    });

    log.info(`Convert RankedPhonesResponse from ${url} to our model`);
    return converter(response.data);
  } catch (error) {
    log.error(error);
    throw error;
  }
};

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export default execute;
