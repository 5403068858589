import React from "react";
import BEEIcon from "../../../../assets/BEE";
import BeeLoadingStyle from "./BeeLoading.style";
import BouncingDotsLoader from "../bouncingDotsLoader/bouncingDotsLoader";

interface Props {
  testid: string;
}

const BeeLoading: React.FC<Props> = ({ testid }) => {
  const classes = BeeLoadingStyle();
  return (
    <div
      className={classes.container}
      data-testid={`${testid}-bee-loading-component`}
    >
      <BEEIcon />
      <BouncingDotsLoader color="#000" />
    </div>
  );
};

export default BeeLoading;
