import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import axios from "../../config/axios/axiosInstance";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../host/HostService";
import {
  LastMarketplaceOrdersResponse,
  LastMarketplaceOrdersResult,
} from "../../domains/lastMaketplaceOrders/LastMarketplaceOrders";
import LastMarketplaceOrdersResponseToLastMarketplaceResult from "../../converters/lastMarketplaceOrders/LastMarketplaceOrdersResponseToLastMarketplaceResult";
import GlobalStore from "../../stores/global/GlobalStore";
import { CALL_LIST_SERVICE } from "../../config/services";
import {
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_CALL_LIST_SERVICE,
} from "../../config/featureToggles";
import VendorStore from "../../stores/vendor/VendorStore";

export const CALL_LIST = "/call-list";
export const LAST_MARKETPLACE_ORDERS_ENDPOINT = "/last-marketplace-orders";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getLastMarketplaceOrders(
  accountId: string,
  headers = {},
): Promise<LastMarketplaceOrdersResult> {
  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();
  const log = useLogService();

  const isCallListV2Enabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_CALL_LIST_SERVICE,
    user.zone,
  );

  const vendorId = vendor?.id;
  const v2EndPoint = getV2EndPoint(isCallListV2Enabled);
  const vendorIdParam = getVendorIdParam(vendorId, isCallListV2Enabled);

  const baseUrl = `${getApiHost(CALL_LIST_SERVICE)}${CALL_LIST}`;

  const urlLastMarketplace = `${baseUrl}${v2EndPoint}/${accountId}${LAST_MARKETPLACE_ORDERS_ENDPOINT}${vendorIdParam}`;

  return axios
    .get<LastMarketplaceOrdersResponse>(`${urlLastMarketplace}`, {
      headers,
    })
    .then((response) => {
      log.info(
        `Convert last orders marketplace received from ${urlLastMarketplace} to our model`,
      );
      return LastMarketplaceOrdersResponseToLastMarketplaceResult(
        response.data,
      );
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
