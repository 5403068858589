import { useLogService } from "admin-portal-shared-services";
import { SoftphoneWS } from "../../domains/cti/SoftphoneWS";
import SoftphoneWSDomain from "../../domains/cti/SoftphoneWSDomain";

/* istanbul ignore next */
export function connectWebSocket(webSocket: SoftphoneWSDomain): SoftphoneWS {
  const log = useLogService();

  log.info(`Try to connect web sockect with uri ${webSocket.uri}`);
  return new SoftphoneWS(webSocket);
}
