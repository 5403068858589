import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    offeringList: {
      borderBottom: "1px solid #dedede",
      padding: "0px 0px 0px 1px",
    },
    offeringImage: {
      width: "80px",
      height: "90px",
      borderRadius: "3px",
      backgroundColor: theme.palette.grey[50],
      marginRight: "16px",
      "& img": {
        objectFit: "contain",
        width: "80px",
        height: "100%",
      },
    },
    offeringName: {
      fontSize: "12px",
      fontWeight: 500,
      color: theme.palette.secondary.main,
      marginBottom: 5,
    },
    offeringQuantity: {
      fontSize: "12px",
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
    offeringPrice: {
      fonstSize: "14px",
      fontWeight: "bold",
      color: "#d00505",
      textAlign: "right",
      whiteSpace: "nowrap",
    },
    offeringUnitPrice: {
      fontWeight: 600,
      color: theme.palette.info.main,
    },
    offeringSku: {
      fontWeight: 500,
      color: theme.palette.grey[200],
    },
    offeringQuantityContainer: {
      width: "60px",
      height: "30px",
      borderRadius: "3px",
      border: `1px solid ${theme.palette.secondary.main}`,
      marginRight: "16px",
      textAlign: "center",
      paddingTop: "4px",
    },
    offeringContainer: {
      width: "100%",
      height: "auto",
    },
    offeringTotalWrapper: {
      marginTop: "10px",
      marginBottom: "5px",
      alignItems: "center",
    },
    offeringTotalWrapperWithMarketplace: {
      marginTop: "5px",
      marginBottom: "0px",
      alignItems: "center",
    },
  }),
);
