import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Card, Divider } from "@material-ui/core";
import TabsMissionsSkeletonStyles from "./TabsMissionsSkeleton.styles";

const TabsMissionsSkeleton: React.FC = () => {
  const classes = TabsMissionsSkeletonStyles();

  const productContainer = (
    <div className={classes.productContainer}>
      <Skeleton
        width={106}
        height={106}
        className={classes.productImageSkeleton}
      />
      <div className={classes.productTitleContainer}>
        <Skeleton width={158} height={54} />
        <Skeleton width={98} height={18} />
      </div>
      <div className={classes.productTagsContainer}>
        <Skeleton width={78} height={34} />
        <Skeleton width={106} height={34} />
      </div>
    </div>
  );

  return (
    <div className={classes.skeletonContainer} data-testid="skeleton-component">
      <div className={classes.tabsSkeletonSection}>
        <Skeleton width={128} height={24} />
        <Skeleton width={128} height={24} />
        <Skeleton width={128} height={24} />
      </div>
      <Card className={classes.cardContainer}>
        <Skeleton className={classes.iconSkeleton} width={55} height={52} />
        <div className={classes.missionInfoSkeleton}>
          <Skeleton width={140} height={24} />
          <Divider />
          <Skeleton width={230} height={24} />
        </div>
      </Card>
      <Card className={classes.openedMissionContainer}>
        <div className={classes.subMissionHeader}>
          <Skeleton width={154} height={16} />
          <Skeleton width="100%" height={72} />
        </div>
        <Divider />
        <Skeleton width={154} height={24} />
        <div className={classes.missionProductsContainer}>
          {productContainer}
          {productContainer}
        </div>
      </Card>
      <Card className={classes.cardContainer}>
        <Skeleton
          className={classes.subMissionTitleSkeleton}
          width={156}
          height={24}
        />
      </Card>
      <Card className={classes.cardContainer}>
        <Skeleton
          className={classes.subMissionTitleSkeleton}
          width={210}
          height={24}
        />
      </Card>
    </div>
  );
};

export default TabsMissionsSkeleton;
