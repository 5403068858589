import { CartComboItem } from "../../../domains/cart/CartComboItem";
import UpdateCartSimulationRequest from "../../../services/cart/updateCartSimulation/domains/UpdateCartSimulationRequest";
import { ComboItem } from "../../../services/checkout/domains/v2/CheckoutRequestV2";
import CartItemState from "../../../stores/cart/CartItemState";
import CartState from "../../../stores/cart/CartState";
import CartComboState from "../../../stores/cartCombo/CartComboState";

export default function StoresToUpdateCartSimulationRequestConverter(
  cartItemStates: CartItemState,
  cartStates: CartState,
  cartComboState: CartComboState,
  isOrderTakingEmptiesEnabled: boolean,
): UpdateCartSimulationRequest {
  return {
    cartId: cartItemStates.cart?.cartId ?? null,
    deliveryWindowId: cartStates.cartForm?.deliveryWindowId || null,
    delivery: {
      method: "STANDARD",
      date:
        cartStates.cartForm?.deliveryDate?.split("T")[0] ||
        new Date().toISOString().split("T")[0],
    },
    payment: {
      vendorMethod: cartStates.cartForm?.paymentMethodCode || null,
      method: cartStates.cartForm?.paymentMethod,
      term: cartStates.cartForm?.paymentTerm,
    },
    ...getItemsRequest(cartItemStates),
    ...getCombosRequest(cartComboState.cartComboItems),
    ...updateEmpties(isOrderTakingEmptiesEnabled, cartItemStates),
  };
}

function getItemsRequest(cartItemStates) {
  return {
    items:
      cartItemStates.cartItems?.map((each) => {
        return {
          platformId: each.platformId,
          quantity: each.itemQuantity,
        };
      }) ?? [],
  };
}

function getCombosRequest(cartComboItems) {
  return {
    combos:
      cartComboItems?.map((each: CartComboItem) => {
        return {
          platformId: each.platformId,
          quantity: each.itemQuantity,
        } as ComboItem;
      }) ?? [],
  };
}

function updateEmpties(isOrderTakingEmptiesEnabled, cartItemStates) {
  if (isOrderTakingEmptiesEnabled) {
    const emptiesList = cartItemStates?.empties;
    return {
      empties:
        emptiesList?.map((each) => {
          return {
            id: each.groupId,
            quantity: each.quantity,
          };
        }) ?? [],
    };
  }
  return null;
}
