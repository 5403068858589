/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { ButtonBase, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import EnpsAgentsStyles from "./EnpsAgentsStyles";
import EnpsIcon from "../../../assets/EnpsIcon";
import Modal from "../../../components/modal/Modal";
import useEnpsAgents from "./useEnpsAgents";

interface Props {
  form: string;
  surveyId: string;
  error: boolean;
  agentId: string;
}

const EnpsAgents: React.FunctionComponent<Props> = ({
  form,
  error,
  surveyId,
  agentId,
}) => {
  const { t } = useTranslation();
  const classes = EnpsAgentsStyles();
  const { modalOpen, setModalOpen, handleCloseModal } = useEnpsAgents();

  return (
    <>
      <div
        className={classes.enpsButton}
        data-testid="enps-button"
        onClick={() => setModalOpen(true)}
      >
        <EnpsIcon />
      </div>
      <Modal open={modalOpen} data-testid="enps-modal">
        <div className={classes.container}>
          <div className={classes.header} data-testid="enps-header">
            {t("NPS.NPS_TITLE")}
          </div>
          <div
            className={classes.formContainer}
            data-testid="enps-iframe-container"
          >
            {error ? (
              <div
                data-testid="enps-error-message"
                className={classes.errorMessage}
              >
                {t("NPS.NPS_ERROR")}
              </div>
            ) : (
              <iframe
                className={classes.iframe}
                title="enps-form"
                data-testid="enps-iframe-form"
                src={form}
              />
            )}
          </div>
          <Box display="flex" flexDirection="row">
            <ButtonBase
              onClick={() => setModalOpen(false)}
              className={classNames(classes.button, classes.closeButton)}
              data-testid="enps-close-button"
            >
              {t("NPS.NPS_CLOSE")}
            </ButtonBase>
            <ButtonBase
              onClick={() => handleCloseModal(surveyId, agentId)}
              className={classNames(classes.button, classes.submitButton)}
              data-testid="enps-submit-button"
            >
              {t("NPS.NPS_SUBMIT")}
            </ButtonBase>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(EnpsAgents);
