import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import axios from "../../config/axios/axiosInstance";
import { getApiHost } from "../host/HostService";
import {
  MarketplaceItem,
  MarketplaceResponse,
} from "../../domains/Marketplace";
import {
  GROW_ORGANIZATION_ACTIVATION,
  TLP_MULTICONTRACT_ITEM_SERVICE,
  isFeatureEnabledV2,
} from "../../config/featureToggles";
import { TLP_ITEM_SERVICE } from "../../config/services";
import MarketplaceResponseToMarketplaceItemConverter from "../../converters/marketplace/MarketplaceResponseToMarketplaceItemConverter";

export const MARKETPLACE_ENDPOINT = "/tlp-item/marketplace";
export const MARKETPLACE_ENDPOINT_V2 = "/tlp-item/v2/marketplace";
export const MARKETPLACE_ENDPOINT_V3 = "/v3/items/marketplace";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getMarketplace(
  country: string,
  vendorId?: string,
  orgId?: string,
  requestTraceId?: string,
): Promise<Array<MarketplaceItem>> {
  const log = useLogService();
  let MARKETPLACE_PATH: string;

  const isItemMulticontractService = isFeatureEnabledV2(
    TLP_MULTICONTRACT_ITEM_SERVICE,
    country,
  );
  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
  );

  MARKETPLACE_PATH = isItemMulticontractService
    ? MARKETPLACE_ENDPOINT_V2
    : MARKETPLACE_ENDPOINT;

  if (isOrganizationConfigEnabled) MARKETPLACE_PATH = MARKETPLACE_ENDPOINT_V3;

  const urlmarket = `${getApiHost(TLP_ITEM_SERVICE)}${MARKETPLACE_PATH}`;

  let params = isItemMulticontractService
    ? { vendorId: vendorId ?? "" }
    : undefined;

  const headers = isOrganizationConfigEnabled
    ? { requestTraceId, orgId, country }
    : { requestTraceId, country };

  params = isOrganizationConfigEnabled ? undefined : params;

  return axios
    .get<Array<MarketplaceResponse>>(`${urlmarket}`, {
      params,
      headers,
    })
    .then((response) => {
      log.info(`Convert New Offerings received from ${urlmarket} to our model`);
      return MarketplaceResponseToMarketplaceItemConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
