import * as React from "react";

function OfferingsIcon(props: any): any {
  return (
    <svg
      width={32}
      height={32}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M13.028.375c7.195 0 13.028 6.044 13.028 13.5s-5.833 13.5-13.028 13.5S0 21.331 0 13.875 5.833.375 13.028.375zm0 2.455c-5.887 0-10.66 4.945-10.66 11.045S7.142 24.92 13.029 24.92s10.66-4.945 10.66-11.045S18.914 2.83 13.027 2.83zm0 .818c.607 0 1.108.474 1.176 1.084l.008.143V6.92h2.764c.654 0 1.184.55 1.184 1.228 0 .63-.457 1.148-1.046 1.219l-.138.008h-2.764v3.272h.79c2.18 0 3.948 1.832 3.948 4.092 0 2.184-1.652 3.968-3.731 4.084l-.217.007-.79-.001v2.046c0 .678-.53 1.227-1.184 1.227-.607 0-1.108-.474-1.176-1.084l-.008-.143-.001-2.046H8.291c-.655 0-1.185-.549-1.185-1.227 0-.63.457-1.148 1.046-1.219l.139-.008h3.552v-3.273h-.789c-2.18 0-3.948-1.831-3.948-4.09 0-2.185 1.652-3.969 3.731-4.085l.217-.007h.789V4.875c0-.678.53-1.227 1.185-1.227zm1.974 11.454h-.79v3.273h.79c.872 0 1.58-.733 1.58-1.636 0-.85-.627-1.55-1.428-1.63l-.152-.007zm-3.159-5.727h-.789c-.872 0-1.58.733-1.58 1.636 0 .85.627 1.55 1.428 1.63l.152.007.789-.001V9.375z"
          id="OfferingsIcon__a"
        />
      </defs>
      <g transform="translate(4.343 3.938)" fill="none" fillRule="evenodd">
        <mask id="OfferingsIcon__b" fill="#fff">
          <use xlinkHref="#OfferingsIcon__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#OfferingsIcon__a" />
        <g mask="url(#OfferingsIcon__b)" fill="#000">
          <path d="M-4.343-3.938h34.741v36h-34.74z" />
        </g>
      </g>
    </svg>
  );
}

export default OfferingsIcon;
