import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    skeletonContainer: {
      "& .tlp-MuiSkeleton-text": {
        transform: "scale(1)",
      },
    },
    skeletonContainerRedesign: {
      backgroundColor: theme.palette.background.paper,
      height: "auto",
      display: "flex",
      marginBottom: 25,
      borderRadius: "8px",
      boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.16)",
      padding: "16px",
      flexDirection: "column",
      "& .tlp-MuiSkeleton-text": {
        transform: "scale(1)",
      },
    },
    collapsibleSkeleton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: "19px",
    },
    sponsoredTitleCollapse: {
      marginLeft: 5,
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "24px",
      fontFamily: "Barlow",
      letterSpacing: 0,
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "81vh",
    },
    icon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#F2F2F2",
      borderRadius: "50%",
      height: 24,
      width: 24,
    },
    containerError: {
      border: "1px solid #e1e1e1",
      borderRadius: 8,
      padding: 20,
      background: "#fff",
    },
    footer: {
      borderTop: `1px solid ${theme.palette.grey.A100}`,
      padding: "14px 0 7px 0",
      marginLeft: "1rem",
      marginRight: "1rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    flexibleFooter: {
      "& .tlp-MuiTypography-subtitle1": {
        color: "#141414",
        fontSize: "12px",
        fontFamily: "Work Sans",
        fontWeight: 400,
        lineHeight: "18px",
      },
    },
    justifyRight: {
      justifyContent: "right",
    },
    errorHandlerWrapper: {
      marginBottom: "1rem",
    },
  }),
);
