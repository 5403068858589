import { getCampaigns } from "../../services/campaigns/CampaignsService";
import { Campaigns, GetCampaignsPayload } from "../../domains/Campaigns";

export const execute = (
  getCampaignsPayload: GetCampaignsPayload,
): Promise<Campaigns> => {
  const {
    vendorId,
    vendorName,
    isCampaignFocusVendorEnabled,
    isMultiContractCampaignServiceEnabled,
    vendorAccountId,
  } = getCampaignsPayload;

  let vendorNameHeaderParam = "";
  let vendorIdHeaderParam = "";

  if (isMultiContractCampaignServiceEnabled) {
    vendorIdHeaderParam = vendorId || "";
  } else if (vendorId && vendorName && isCampaignFocusVendorEnabled) {
    vendorIdHeaderParam = vendorId;
    vendorNameHeaderParam = vendorName;
  }

  return getCampaigns({
    DDC: getCampaignsPayload.DDC,
    segment: getCampaignsPayload.segment,
    accountId: getCampaignsPayload.accountId,
    isTypedPoc: getCampaignsPayload.isTypedPoc || false,
    vendorId: vendorIdHeaderParam,
    country: getCampaignsPayload.country,
    vendorName: vendorNameHeaderParam,
    vendorAccountId,
    requestTraceId: getCampaignsPayload.requestTraceId,
    orgId: getCampaignsPayload.orgId,
  });
};
