import React, { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

const ArrowRight: React.FunctionComponent<Props> = ({ style }) => (
  <svg
    style={style}
    width="14px"
    height="14px"
    viewBox="0 0 64 64"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M1.00420532,1.00420532 C2.24015033,-0.23173969 4.18494252,-0.326812383 5.52994095,0.718987242 L5.85293754,1.00420532 L26.4243661,21.5756339 C27.6603111,22.8115789 27.7553838,24.7563711 26.7095842,26.1013695 L26.4243661,26.4243661 L5.85293754,46.9957947 C4.51399711,48.3347351 2.34314575,48.3347351 1.00420532,46.9957947 C-0.23173969,45.7598497 -0.326812383,43.8150575 0.718987242,42.4700591 L1.00420532,42.1470625 L19.1485714,24 L1.00420532,5.85293754 C-0.23173969,4.61699252 -0.326812383,2.67220033 0.718987242,1.32720191 L1.00420532,1.00420532 Z"
        id="path-1-arrow-right"
      ></path>
    </defs>
    <g
      id="___Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="ZZ_Color-Override/Primary/Black"
        transform="translate(18.000000, 8.000000)"
      >
        <mask id="mask-2-arrow-right" fill="white">
          <use xlinkHref="#path-1-arrow-right"></use>
        </mask>
        <g id="Mask-arrow-right" fillRule="nonzero"></g>
        <g
          id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
          mask="url(#mask-2-arrow-right)"
          fill="#000000"
          fillOpacity="0.9"
        >
          <g transform="translate(-18.000000, -8.000000)" id="Rectangle">
            <rect x="0" y="0" width="64" height="64"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowRight;
