import styled from "styled-components";

const PocAccepted = styled.div`
  display: flex;
`;

const InfoContainer = styled.div`
  display: flex;
  padding-top: 0.2rem;
  padding-left: 0.5rem;
`;

export { PocAccepted, InfoContainer };
