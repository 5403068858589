import { createStore } from "effector";
import {
  resetCallLogsEvent,
  setCallLogsEvent,
  setTempCallLogsEvent,
} from "./CallLogsEvents";
import CallLogsState, { CallLogs } from "./CallLogsState";

const initialState = <CallLogsState>{
  done: false,
  error: false,
  callLogs: [] as Array<CallLogs>,
  tempCallLogs: [] as Array<CallLogs>,
};

const CallLogsStore = createStore(initialState)
  .on(setCallLogsEvent, (state: CallLogsState, newCallLog: CallLogs) => {
    if (!newCallLog.phone || newCallLog.phone.trim() === "") {
      const filteredCallLogs = state.callLogs.filter((callLog) => {
        return callLog.events[callLog.events.length - 1].type === "MakeCall";
      });

      if (filteredCallLogs.length > 0) {
        newCallLog.phone = filteredCallLogs[filteredCallLogs.length - 1].phone;
      }
    }

    const phoneNumberIndex = state.callLogs.findIndex(
      (callLog) => callLog.phone === newCallLog.phone,
    );

    if (phoneNumberIndex >= 0) {
      const updatedCallLog = state.callLogs[phoneNumberIndex];
      updatedCallLog.events.push(newCallLog.events[0]);
      state.callLogs[phoneNumberIndex] = updatedCallLog;
      return {
        ...state,
        done: true,
        error: false,
      };
    }

    return {
      ...state,
      callLogs: [...state.callLogs, newCallLog],
      done: true,
      error: false,
    };
  })
  .on(setTempCallLogsEvent, (state: CallLogsState, newCallLog: CallLogs) => {
    const callIdIndex = state.tempCallLogs.findIndex(
      (tempCallLogs) => tempCallLogs.callId === newCallLog.callId,
    );

    if (callIdIndex >= 0) {
      const updatedCallLog = state.tempCallLogs[callIdIndex];
      updatedCallLog.events.push(newCallLog.events[0]);
      state.tempCallLogs[callIdIndex] = updatedCallLog;
      return {
        ...state,
        done: true,
        error: false,
      };
    }
    return {
      ...state,
      tempCallLogs: [...state.tempCallLogs, newCallLog],
      done: true,
      error: false,
    };
  })
  .reset(resetCallLogsEvent);

export default CallLogsStore;
