import { Alert } from "@hexa-ui/components";
import { CircularProgress } from "@material-ui/core";
import loyaltyBusinessStore from "@stores/loyaltyBusiness/LoyaltyBusinessStore";
import { useStore } from "effector-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RewardsPointsCardStyles from "./RewardsPointsCardStyles";
import { useRewardsPoints } from "./hooks/useRewardsPoints/useRewardsPoints";

interface Props {
  cartUpdating: boolean;
  isCartNewExhibition: boolean;
}

function RewardsPointsCard({
  cartUpdating,
  isCartNewExhibition,
}: Props): React.ReactElement {
  const classes = RewardsPointsCardStyles();
  const { t } = useTranslation();
  const { loading } = useStore(loyaltyBusinessStore);

  const { getRewardRulesRequest, totalRewardsPoints } = useRewardsPoints();

  useEffect(() => {
    if (!cartUpdating) {
      getRewardRulesRequest();
    }
  }, [cartUpdating]);

  const getRewardsPointsMessage = () => {
    // Separate the warning for bold css style
    const title = t("OrderTaking.LOYALTY_LOST_POINTS").substring(
      0,
      t("OrderTaking.LOYALTY_LOST_POINTS").indexOf(" "),
    );

    // Separate the string before & after the number
    const text = t("OrderTaking.LOYALTY_LOST_POINTS").split("[Total points]");

    const firstMessage = text[0].substring(text[0].indexOf(" ") + 1);
    const secondMessage = text[1];

    return (
      <>
        {totalRewardsPoints ? (
          <div data-testid="rewards-points-card-text">
            <span className={classes.title}>{`${title} `}</span>
            <span>{`${firstMessage} `}</span>
            <span
              className={classes.totalPoints}
              data-testid="rewards-points-card-total-points"
            >
              {totalRewardsPoints}
            </span>
            <span>{`${secondMessage}`}</span>
          </div>
        ) : (
          <span data-testid="rewards-points-card-total-points-error">{`${t(
            "OrderTaking.LOYALTY_LOST_POINTS_ERROR",
          )}`}</span>
        )}
      </>
    );
  };
  return (
    <div
      className={isCartNewExhibition ? classes.newContainer : classes.container}
      data-testid="rewards-points-card-container"
    >
      {!loading && !cartUpdating ? (
        <>
          {isCartNewExhibition ? (
            <Alert
              data-testid="rewards-points-card-alert"
              css={{ width: "100%" }}
              message={getRewardsPointsMessage() as never}
            />
          ) : (
            <span className={classes.message}>{getRewardsPointsMessage()}</span>
          )}
        </>
      ) : (
        <CircularProgress size={20} className={classes.loading} />
      )}
    </div>
  );
}

export default RewardsPointsCard;
