import { createStyles, Theme, makeStyles } from "@material-ui/core";

interface Props {
  isRedesign?: boolean;
}

export default makeStyles<Theme, Props>((theme: Theme) =>
  createStyles({
    productCard: {
      display: "flex",
      justifyContent: "center",
    },
    productCardAndHorizontalDivider: {
      display: "flex",
      flexDirection: "column",
    },
    oddQuantityProductCard: {
      display: "flex",
      justifyContent: "center",
      width: "49%",
      marginTop: "14px",
      "&:nth-child(odd)": {
        backgroundImage:
          "linear-gradient(#dfdede, #dfdede), linear-gradient(#dfdede, #dfdede), linear-gradient(#fff, #fff)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "1px 94%, 94% 1px, calc(100% - 2px) 100%",
        backgroundPosition: "right top, bottom left, 1px 0",
      },
      "&:nth-child(even)": {
        backgroundImage:
          "linear-gradient(#dfdede, #dfdede), linear-gradient(#fff, #fff)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "94% 1px, calc(100% - 1px) 100%",
        backgroundPosition: "bottom right, 1px 0",
      },
      "&:last-child": {
        backgroundImage: "none",
      },
    },
    soloProductCard: {
      display: "flex",
      justifyContent: "center",
      width: "50%",
      marginTop: "14px",
    },
    scrollContainer: {
      maxHeight: "335px",
      overflowY: "scroll",
    },
    productCardsContainerWithTwoColumns: {
      display: "grid",
      gridTemplateColumns: "1fr 20px 1fr",
      justifyItems: "center",
    },
    productCardsContainerWithThreeColumns: {
      display: "grid",
      gridTemplateColumns: "1fr 20px 1fr 20px 1fr",
      justifyItems: "center",
    },
    verticalDivider: {
      height: "212px",
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      margin: "14px 0",
      "&:last-child": {
        display: "none",
      },
    },
    cardContainer: {
      backgroundColor: theme.palette.background.paper,
      height: "auto",
      display: "flex",
      marginBottom: 25,
      borderRadius: "8px",
      boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.16)",
      padding: "16px",
      flexDirection: "column",
      "& .tlp-MuiSkeleton-text": {
        transform: "scale(1)",
      },
    },
    cardContainerRedesign: {
      backgroundColor: theme.palette.background.paper,
      height: "auto",
      display: "flex",
      padding: "16px",
      flexDirection: "column",
      "& .tlp-MuiSkeleton-text": {
        transform: "scale(1)",
      },
    },
    separator: {
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      marginTop: "0.9rem",
      marginBottom: "0.9rem",
    },
    separatorRedesign: {
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      marginTop: "0.9rem",
      marginBottom: "0.4rem",
    },
    subtitle: {
      fontSize: "16px",
      fontFamily: "Work Sans",
      marginBottom: "0.5rem",
    },
    description: {
      fontSize: "1rem",
      marginBottom: "0.3rem",
    },
    descriptionRedesign: {
      fontSize: "0.9rem",
      marginBottom: "0.3rem",
      overflowY: "scroll",
      maxHeight: "6rem",
    },
    titleSkeleton: {
      marginBottom: "16px",
    },
    cardSkeletonContainer: {
      display: "flex",
      height: "106px",
    },
    productsContainerSkeleton: {
      display: "flex",
      width: "100%",
    },
    productCardSkeleton: {
      width: "50%",
      display: "flex",
      padding: ({ isRedesign }) => (isRedesign ? "16px 16px 16px 0px" : "16px"),
    },
    productInfoSkeleton: {
      marginLeft: "14px",
      display: "flex",
      flexDirection: "column",
      height: "106px",
      justifyContent: "space-between",
    },
    noProductsTypography: {
      color: theme.palette.text.secondary,
      fontWeight: 500,
      "& h2": {
        fontSize: "16px",
      },
    },
    productButtonSkeleton: {
      display: "flex",
    },
    containerError: {
      border: 0,
    },
    separatorProduct: {
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      margin: "1rem",
      marginTop: "0.6rem",
      marginBottom: "0.09rem",
    },
  }),
);
