import React from "react";
import classNames from "classnames";
import InputNumber from "rc-input-number";
import QuantityInputStyles from "./QuantityInputStyles";
import UpArrow from "../../assets/UpArrow";
import DownArrow from "../../assets/DownArrow";

interface Props {
  value?: number;
  notFound?: boolean;
  onChange: (value: number | string | undefined) => void;
  testId?: string;
  disabled?: boolean;
  maxValue: number;
  minValue: number;
}

const QuantityInput: React.FunctionComponent<Props> = ({
  value,
  notFound,
  onChange,
  testId,
  disabled,
  maxValue,
  minValue,
}) => {
  const classes = QuantityInputStyles();
  const upHandler = <UpArrow />;
  const downHandler = <DownArrow />;

  const handleChange = (newValue: string | number | undefined) => {
    if (!newValue) {
      onChange(1);
    } else {
      onChange(newValue);
    }
  };

  return (
    <InputNumber
      min={minValue}
      max={maxValue}
      precision={0}
      name="quantity"
      value={value}
      onChange={handleChange}
      upHandler={upHandler}
      downHandler={downHandler}
      disabled={disabled || notFound}
      className={classNames(classes.inputQuantity, {
        [classes.disabledLabel]: disabled || notFound,
      })}
      data-testid={testId}
    />
  );
};

QuantityInput.defaultProps = {
  testId: "",
};

export default QuantityInput;
