import Catalog, { CatalogItem } from "../../../domains/Catalog";
import CatalogResponseV2 from "../../../services/catalog/v2/domains/CatalogResponseV2";
import MarketplaceStore from "../../../stores/marketplace/MarketplaceStore";
import { hasMarketplace } from "../../../config/utils/functions";

const CatalogResponseToCatalogConverterV2 = (
  data: CatalogResponseV2,
): Catalog => {
  const marketplaceState = MarketplaceStore.getState();

  const { marketplaceList } = marketplaceState;

  const catalogItems = data.items?.map((uniqueItem) => {
    const vendorItemId = uniqueItem.sourceData?.vendorItemId ?? uniqueItem.sku;

    const isMarketplace = hasMarketplace(vendorItemId, marketplaceList);

    return <CatalogItem>{
      sku: uniqueItem.sku,
      price: uniqueItem.price,
      stockAvailable: uniqueItem.stockAvailable,
      name: uniqueItem.itemName,
      image: uniqueItem.itemImage,
      brandName: uniqueItem.brandName,
      classification: uniqueItem.classification,
      packagename: uniqueItem.package.name,
      marketplace: isMarketplace,
      inventoryCount: uniqueItem.inventoryCount,
      availabilityCount: uniqueItem.availability.count,
      palletQuantity: uniqueItem.palletQuantity,
      fullPackageDescription: uniqueItem.package?.fullPackageDescription,
      fullContainerDescription: uniqueItem.container.fullContainerDescription,
      pricePerUoM: uniqueItem.pricePerUoM,
      id: uniqueItem.id,
      vendorItemId,
    };
  });

  return { items: catalogItems };
};

export default CatalogResponseToCatalogConverterV2;
