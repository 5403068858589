import React from "react";
import { useTranslation } from "react-i18next";
import GenericErrorPage from "../generic/GenericErrorPage";

function ServerErrorMainPage(): React.ReactElement {
  const { t } = useTranslation();

  return (
    <GenericErrorPage
      errorCode={t("ServerError.ERROR_CODE")}
      errorMessage={t("ServerError.ERROR_MESSAGE")}
      buttonLabel={t("ServerError.REFRESH_BUTTON")}
    />
  );
}

export default ServerErrorMainPage;
