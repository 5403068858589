import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    boxAlterantiveDay: {
      width: "24px",
      height: "21px",
      textAlign: "center",
    },
    alternativeDay: {
      color: "#0371E2",
      width: "20px",
      height: "20px",
      border: "1px solid #0371E2",
      borderRadius: "20px",
      "& span p": {
        fontSize: "11px",
        fontWeight: 500,
      },
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.15)",
      },
    },
    activeSelected: {
      backgroundColor: palette.primary.main,
      borderColor: palette.primary.main,
      color: "white",
      "&:hover": {
        backgroundColor: palette.primary.main,
      },
    },
  }),
);
