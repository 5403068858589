import { createEvent, createEffect } from "effector";
import UpdatePhone from "../../services/phoneRanking/UpdatePhone/UpdatePhoneService";
import { execute } from "../../usecase/phoneRanking/PhonesRankedUseCase";
import { execute as updateRankedPhones } from "../../usecase/phoneRanking/UpdateRankedPhonesUseCase";
import UpdateRankedPhonesRequest from "../../domains/phoneRanking/UpdateRankedPhonesRequest";
import { Phone, PhoneSimple } from "../../domains/phoneRanking/RankedPhones";

interface GetPhonesRankedProps {
  accountId: string;
  vendorId: string;
  vendorName: string;
  phones: string[];
}
interface UpdatePhoneEffectProps {
  accountId: string;
  currentNumber: string;
  phone: PhoneSimple;
  vendorName: string;
}

interface UpdatePhoneRankingRequestParams {
  accountId: string;
  vendorId: string;
  vendorName: string;
  requestBody: Array<UpdateRankedPhonesRequest>;
}

export const getPhonesRanked = createEffect({
  async handler({
    accountId,
    vendorId,
    vendorName,
    phones,
  }: GetPhonesRankedProps) {
    return execute(accountId, vendorId, vendorName, phones);
  },
});

export const updatePhoneEffect = createEffect({
  async handler({
    accountId,
    currentNumber,
    phone,
    vendorName,
  }: UpdatePhoneEffectProps) {
    return UpdatePhone(accountId, currentNumber, phone, vendorName);
  },
});

const updateService = () =>
  createEffect({
    async handler({
      accountId,
      vendorId,
      vendorName,
      requestBody,
    }: UpdatePhoneRankingRequestParams) {
      return updateRankedPhones(accountId, vendorId, vendorName, requestBody);
    },
  });

export const setIsPhoneCalled = createEvent<string>("setIsPhoneCalled");

export const setCalledPhones = createEvent<string[]>("setCalledPhones");

export const resetPhonesRanking = createEvent("resetPhonesRanking");

export const updatePhoneRanking = updateService();

export const addNewPhone = updateService();

export const setPhonesRanking = createEvent<Phone[]>("setPhonesRanking");

export const setLastCopiedPhone = createEvent<string>("setLastCopiedPhone");
export const setIsModalOpen = createEvent<boolean>("setIsModalOpen");
export const setLastCalledOrCopiedPhone = createEvent<string>(
  "setLastCalledOrCopiedPhone",
);
