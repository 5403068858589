import { getValueEmpty } from "../../../config/utils/functions";
import { CartComboItem } from "../../../domains/cart/CartComboItem";
import CartRequestV3 from "../../../services/cart/v3/domains/CartRequestV3";
import AgentCallState from "../../../stores/agentCall/AgentCallState";
import CartItemState from "../../../stores/cart/CartItemState";
import CartState from "../../../stores/cart/CartState";
import CartComboState from "../../../stores/cartCombo/CartComboState";
import { TIMEZONE } from "../../../config/timeZone";

export default function StoresToCartRequestV3Converter(
  agentCallState: AgentCallState,
  cartState: CartState,
  cartComboState: CartComboState,
  cartItemsState: CartItemState,
  userZone: string,
): CartRequestV3 {
  const lineItems = cartItemsState.cartItems
    .filter((each) => each.notFound === false)
    .map((each) => {
      return {
        itemQuantity: each.itemQuantity,
        sku: each.sku,
        id: each.id,
        quantity: each.itemQuantity,
      };
    });
  const comboItems = getCombosRequest(cartComboState.cartComboItems);
  return {
    accountId: getValueEmpty(agentCallState.clientId),
    paymentTerm: 0,
    paymentMethod: cartState.cartForm.paymentMethod,
    deliveryDate:
      cartState.cartForm.deliveryDate.split("T")[0] ||
      new Date().toISOString().split("T")[0],
    items: lineItems,
    empties: [],
    combos: comboItems,
    language: TIMEZONE[userZone]?.locale,
  };
}

const getCombosRequest = (cartComboItems) => {
  return cartComboItems.map((each: CartComboItem) => {
    return {
      id: each.id,
      quantity: each.itemQuantity,
    };
  });
};
