import { createStore } from "effector";
import {
  AgentSurveyFirstSurveyForAgentEffect,
  AgentSurveyUpdateSurveyEffect,
} from "./AgentSurveyEvents";
import AgentSurveyState from "./AgentSurveyState";

const initialState = <AgentSurveyState>{
  error: false,
  isLoading: false,
  isLoaded: false,
  surveyId: "",
  surveyLink: "",
};

const setAgentSurveyState = (state: AgentSurveyState) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: false,
  };
};

const AgentSurveyStore = createStore(initialState) //
  .on(AgentSurveyFirstSurveyForAgentEffect.done, (state, { result }) => {
    return {
      ...state,
      surveyId: result.surveyId,
      surveyLink: result.surveyLink,
      isLoading: false,
      isLoaded: true,
      error: false,
    };
  })
  .on(AgentSurveyFirstSurveyForAgentEffect, setAgentSurveyState)
  .on(AgentSurveyFirstSurveyForAgentEffect.fail, (state) => {
    return { ...state, isLoading: false, isLoaded: false, error: true };
  })
  .on(AgentSurveyUpdateSurveyEffect.done, () => {
    return {
      ...initialState,
    };
  })
  .on(AgentSurveyUpdateSurveyEffect, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: true,
    error: false,
  }))
  .on(AgentSurveyUpdateSurveyEffect.fail, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: true,
  }));

export default AgentSurveyStore;
