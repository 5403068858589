import React from "react";
import { Box } from "@material-ui/core";
import Loading from "../../../../../components/loadingView/LoadingView";
import LoadingBoxStyles from "./LoadingBox.styles";

const LoadingBox: React.FunctionComponent = () => {
  const classes = LoadingBoxStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.loadingContainer}>
        <Loading data-testid="promotions-loading" />
      </Box>
    </Box>
  );
};

export default LoadingBox;
