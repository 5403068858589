import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      width: "99%",
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      gap: ".625rem",
      borderRadius: 12,
      boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.16)",
      backgroundColor: palette.common.white,
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      lineHeight: "1.125rem",
      gap: "6px",
      height: "auto",
      flexDirection: "row",
    },
    title: {
      fontFamily: "Barlow",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "20px",
    },
    selectButton: {
      width: "fit-content",
    },
    freeGoodsCountQuantity: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      lineHeight: "1.125rem",
      borderRadius: "9999px",
      backgroundColor: "#81FFAD",
      fontSize: ".75rem",
      marginLeft: ".5rem",
      padding: "0rem .25rem",
    },
    editButton: {
      color: "#0363C4",
      border: "none",
      background: "none",
      cursor: "pointer",
      textDecoration: "none",
      height: "1.5rem",
      padding: "0rem",
      margin: "0rem",
      minWidth: "unset",
      "&:hover": {
        color: "#0363C4",
        textDecoration: "none",
        background: "none",
        boxShadow: "none",
      },
    },
    errorMessage: {
      display: "flex",
      fontSize: "12px",
      fontWeight: 400,
      gap: "4px",
      color: "#C9201D",
      height: "24px",
      justifyContent: "center",
      alignItems: "center",
    },
    selectedItemsContainer: {
      maxHeight: "17.5rem",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      gap: ".5rem",
    },
  }),
);
