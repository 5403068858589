import { SponsoredMissionResponseV3 } from "@services/missions/getMissionsV3/domains/getMissionsV3Response";
import {
  LocalMissionList,
  SponsoredMission,
} from "../../../domains/sponsored/SponsoredMission";
import {
  GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
  isFeatureEnabledV2,
} from "../../../config/featureToggles";
import { getCountryByToken } from "../../../config/utils/functions";

export const SponsoredMissionsConverterV3 = (
  sponsoredMissionResponse: SponsoredMissionResponseV3[],
): LocalMissionList => {
  const isLocalAndGlobalMissionsServiceEnable = isFeatureEnabledV2(
    GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
    getCountryByToken(),
  );

  if (sponsoredMissionResponse === undefined) {
    return {
      localMissions: [],
      sponsoredMissions: [],
    };
  }

  const filteredSponsoredMissionsList: SponsoredMission[] = [];
  const filteredLocalMissionsList: SponsoredMission[] = [];

  sponsoredMissionResponse.forEach((mission) => {
    const sponsoredMission = {
      id: mission.missionId,
      missionTitle: mission.missionName,
      missionType: mission.missionType,
      endDate: new Date(mission.endDate),
      scriptText: mission.script || undefined,
      items: mission.items || undefined,
      combos: mission.combos || undefined,
      sponsorPartnerId: mission.sponsorPartnerId || undefined,
      effectiveness: mission.effectiveness || {},
      stopCallingWhen: mission.stopCallingWhen,
    };

    if (sponsoredMission.sponsorPartnerId) {
      filteredSponsoredMissionsList.push(sponsoredMission);
    } else {
      filteredLocalMissionsList.push(sponsoredMission);
    }
  });

  if (isLocalAndGlobalMissionsServiceEnable) {
    return {
      localMissions: filteredLocalMissionsList,
      sponsoredMissions: filteredSponsoredMissionsList,
    };
  }

  return {
    localMissions: [],
    sponsoredMissions: filteredSponsoredMissionsList.concat(
      filteredLocalMissionsList,
    ),
  };
};
