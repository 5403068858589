import { createStore } from "effector";
import * as OfferingEvents from "./OfferingEvents";
import OfferingState from "./OfferingState";
import { OFFERINGS_SECTION } from "../../../config/constants";

const initialState: OfferingState = {
  section: OFFERINGS_SECTION.PROMOTIONS,
  options: [],
};

const OfferingStore = createStore(initialState)
  .on(
    OfferingEvents.setOfferingSection,
    (state: OfferingState, tab: OFFERINGS_SECTION) => ({
      ...state,
      section: tab,
    }),
  )
  .on(OfferingEvents.setOptions, (state, options) => ({
    ...state,
    options,
  }));

export default OfferingStore;
