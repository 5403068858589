import { hasMarketplace } from "../../../config/utils/functions";
import { PromotionsFreeGood } from "../../../domains/promotions/domains/PromotionsFreeGood";
import {
  FreeGood,
  FreeGoodItem,
  Range as PromotionRange,
  Promotion,
} from "../../../services/promotions/domains/PromotionsResponseV3";
import MarketplaceStore from "../../../stores/marketplace/MarketplaceStore";
import {
  PromotionsInfoV3,
  Range,
  Pack,
  Uom,
  Container,
} from "./domains/PromotionsV3ResponseToPromotionsInfoConverterDomain";

const getRanges = (ranges: PromotionRange[]): Range[] => {
  if (!ranges || ranges?.length === 0) return [];

  return ranges.map((range) => {
    return <Range>{
      discountRate: range.discountRate,
      stepStart: range.stepStart,
      stepEnd: range.stepEnd,
      pack: <Pack>{
        originalPrice: range.pack?.originalPrice,
        discountPrice: range.pack.discountPrice,
        discountAmount: range.pack.discountAmount,
        lowestDiscountPrice: range.pack?.lowestDiscountPrice,
        highestDiscountPrice: range.pack?.highestDiscountPrice,
        preferredPrice: range.pack.preferredPrice,
        promotionalPrice: range.pack?.promotionalPrice,
      },
      uom: <Uom>{
        originalPrice: range.uom?.originalPrice,
        discountPrice: range.uom?.discountPrice,
        discountAmount: range.uom?.discountAmount,
        lowestDiscountPrice: range.uom?.lowestDiscountPrice,
        highestDiscountPrice: range.uom?.highestDiscountPrice,
        preferredPrice: range.uom?.preferredPrice,
      },
      container: <Container>{
        originalPrice: range.container?.originalPrice,
        discountPrice: range.container?.discountPrice,
        discountAmount: range.container?.discountAmount,
        lowestDiscountPrice: range.container?.lowestDiscountPrice,
        highestDiscountPrice: range.container?.highestDiscountPrice,
        preferredPrice: range.container?.preferredPrice,
      },
    };
  });
};

export default function PromotionsV3ResponseToPromotionsInfoConverter(
  data: Promotion[],
  vendorId: string,
): PromotionsInfoV3[] {
  const { marketplaceList } = MarketplaceStore.getState();

  const newPromotions = data
    .filter((promotion) => promotion.vendorId === vendorId)
    .map((promotion: Promotion, i: number) => {
      let marketplace = false;

      const promotionsPrices = promotion.item.itemPrice.ranges.map(
        (range: Range, j: number) => {
          return {
            id: `price_${i}_${j}`,
            stepStart: range.stepStart,
            stepEnd: range.stepEnd,
            discountedPrice: range?.pack?.discountPrice,
            discountedRate: range.discountRate,
          };
        },
      );

      const promotionsFreeGood: PromotionsFreeGood[] = promotion.freegoods?.map(
        (freeGood: FreeGood, k: number) => {
          const freeGoodItems = freeGood.freegoodItems?.map(
            (item: FreeGoodItem, l: number) => {
              if (!marketplace) {
                marketplace = hasMarketplace(item.sku, marketplaceList);
              }

              return {
                id: `freegood_item_${i}_${k}_${l}`,
                sku: item.sku,
                title: item.name,
                amount: item.packageItemCount,
              };
            },
          );

          return {
            id: `freegood_${i}_${k}`,
            stepStart: freeGood.stepStart,
            stepEnd: freeGood.stepEnd,
            items: freeGoodItems,
          };
        },
      );

      if (!marketplace) {
        marketplace = hasMarketplace(promotion.item.sku, marketplaceList);
      }

      const limit = promotion.item.maxQuantity
        ? { daily: promotion.item.maxQuantity }
        : undefined;

      return <PromotionsInfoV3>{
        id: `promotions_${i}`,
        sku: promotion.item.sku,
        itemId: promotion.item.itemId,
        itemName: promotion.item.name,
        packageUnitCount: promotion.item.packageUnitCount,
        packageItemCount: promotion.item.packageItemCount,
        originalPrice: promotion.item.itemPrice?.pack?.originalPrice,
        discountId: undefined,
        title: promotion.title,
        description: promotion.description,
        type: promotion.type,
        prices: promotionsPrices,
        legacyFreeGoods: promotionsFreeGood,
        freeGoods: promotion.freegoods,
        marketplace,
        limit,
        inventoryCount: promotion.item.inventoryCount,
        palletQuantity: undefined,
        availability: promotion.item.availability,
        startDate: promotion.startDate,
        endDate: promotion.endDate,
        fullPackageDescription: promotion.item.fullPackageDescription,
        fullContainerDescription: promotion.item.fullContainerDescription,
        ranges: getRanges(promotion.item.itemPrice.ranges),
        pack: <Pack>{
          originalPrice: promotion.item.itemPrice.pack?.originalPrice,
          discountPrice: promotion.item.itemPrice.pack.discountPrice,
          discountAmount: promotion.item.itemPrice.pack.discountAmount,
          lowestDiscountPrice:
            promotion.item.itemPrice.pack?.lowestDiscountPrice,
          highestDiscountPrice:
            promotion.item.itemPrice.pack?.highestDiscountPrice,
          preferredPrice: promotion.item.itemPrice.pack.preferredPrice,
          promotionalPrice: promotion.item.itemPrice.pack?.promotionalPrice,
        },
        uom: <Uom>{
          originalPrice: promotion.item.itemPrice.uom?.originalPrice,
          discountPrice: promotion.item.itemPrice.uom?.discountPrice,
          discountAmount: promotion.item.itemPrice.uom?.discountAmount,
          lowestDiscountPrice:
            promotion.item.itemPrice.uom?.lowestDiscountPrice,
          highestDiscountPrice:
            promotion.item.itemPrice.uom?.highestDiscountPrice,
          preferredPrice: promotion.item.itemPrice.uom?.preferredPrice,
        },
        container: <Container>{
          originalPrice: promotion.item.itemPrice.container?.originalPrice,
          discountPrice: promotion.item.itemPrice.container?.discountPrice,
          discountAmount: promotion.item.itemPrice.container?.discountAmount,
          lowestDiscountPrice:
            promotion.item.itemPrice.container?.lowestDiscountPrice,
          highestDiscountPrice:
            promotion.item.itemPrice.container?.highestDiscountPrice,
          preferredPrice: promotion.item.itemPrice.container?.preferredPrice,
        },
        containerName: promotion.item.containerName,
        containerItemSize: promotion.item.containerItemSize,
        containerUnit: promotion.item.containerUnit,
        platformId: promotion.item.platformId,
      };
    });

  return newPromotions;
}
