import axiosRetry from "axios-retry";
import { useLogService } from "admin-portal-shared-services";
import axios from "../../../config/axios/axiosInstance";
import {
  isFeatureEnabledV2,
  TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
} from "../../../config/featureToggles";
import { FlexibleMissionsResponseToMissionsConfigConverter } from "../../../converters/flexibleMissions/FlexibleMissionsResponseToMissionsConfigConverter";
import GlobalAdminConfigStore from "../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "../../../stores/global/GlobalStore";
import { FlexibleMissionsConfigObject } from "../../../domains/flexibleMission/FlexibleMissionsConfigObject";
import { getApiHost } from "../../host/HostB2BService";
import { GetMissionsPriorityRequest } from "../domains/GetMissionsPriorityRequest";

const ENDPOINT =
  "/grow-flexible-missions-service/grow-flexible-missions/missions";
const ENDPOINT_V2 =
  "/grow-flexible-missions-service/grow-flexible-missions/v2/missions";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

interface HeaderParams {
  requestTraceId: string;
  authorization: string;
  country: string;
}

export const getMissionsPriority = async (
  tagsString: string,
  localMissionsIdsString: string,
  vendorAccountId: string,
  vendorId?: string,
  headers?: HeaderParams,
): Promise<FlexibleMissionsConfigObject> => {
  const log = useLogService();
  const GlobalAdminConfigState = GlobalAdminConfigStore.getState();

  const { user } = GlobalStore.getState();

  const isMulticontractGrowMissionsV2Enable = isFeatureEnabledV2(
    TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
    user.keyToggle,
  );

  const endpoint = isMulticontractGrowMissionsV2Enable ? ENDPOINT_V2 : ENDPOINT;
  const url = `${getApiHost()}${endpoint}`;
  const missionsConfigParams = {
    globals: tagsString,
    ids: localMissionsIdsString,
  };

  const params = isMulticontractGrowMissionsV2Enable
    ? { vendorId: vendorId || "" }
    : {};

  const getHeaders = () => {
    if (!headers) return {};
    return headers;
  };

  const requestBody: GetMissionsPriorityRequest = {
    tags: missionsConfigParams.globals.split(","),
    ids: missionsConfigParams.ids.split(","),
    buConfigPriority: GlobalAdminConfigState.buConfig.missionPriority,
    userConfigPriority: GlobalAdminConfigState.userConfig.missionPriority,
    accountId: vendorAccountId,
  };

  try {
    const response = await axios.post(url, requestBody, {
      params,
      headers: getHeaders(),
    });
    log.info(`Convert Flexible Missions received from ${url} to our model`);
    return FlexibleMissionsResponseToMissionsConfigConverter(response.data);
  } catch (error) {
    log.error(error);
    throw error;
  }
};
