import React from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Moment from "moment";
import TableCellCustom from "../../../../../components/collapsibleTable/TableCellCustom/TableCellCustom";
import LastOrdersStyles from "../LastOrdersStyles";
import CollapsibleTable, {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "../../../../../components/collapsibleTable/CollapsibleTable";
import ProductList from "../productList/ProductList";
import LastOrdersState from "../../../../../domains/LastOrders";
import CHANNELS, { channels } from "../Channels";
import { callUxButtonClicked } from "../../../../../config/typewriter";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import Orders from "../../../../../domains/Orders";
import palette from "../../../../../theme/Palette";

interface TableProps extends LastOrdersState {
  isLastMarketplaceOrder?: boolean;
}

const translations = {
  PARTIAL_DELIVERY: "LastOrders.STATUS_PARTIAL_DELIVERY",
  INVOICED: "LastOrders.STATUS_INVOICED",
  PENDING: "LastOrders.STATUS_PENDING",
  PENDING_PAYMENT: "LastOrders.STATUS_PENDING_PAYMENT",
  PENDING_CANCELLATION: "LastOrders.STATUS_PENDING_CANCELLATION",
  PLACED: "LastOrders.STATUS_PLACED",
  MODIFIED: "LastOrders.STATUS_MODIFIED",
  CANCELLED: "LastOrders.STATUS_CANCELLED",
  CONFIRMED: "LastOrders.STATUS_CONFIRMED",
  AVAILABLE: "LastOrders.STATUS_AVAILABLE",
  DELIVERED: "LastOrders.STATUS_DELIVERED",
  IN_TRANSIT: "LastOrders.STATUS_IN_TRANSIT",
  DENIED: "LastOrders.STATUS_DENIED",
};

function Table({
  orders,
  isLastMarketplaceOrder,
}: TableProps): React.ReactElement {
  const { t } = useTranslation();
  const classes = LastOrdersStyles({});
  const [rowExpanded, setRowExpanded] = React.useState("");
  const { dispatchGenericEvent } = useAnalytics();
  const { callId } = useStore(AgentCallStore);

  const getShortenedChannel = (channel: string) => {
    const shortenedChannel = CHANNELS.find((each) => each.value === channel);

    return shortenedChannel?.label || channels.NOT_IDENTIFIED;
  };

  const onRowExpanded = (id: string, orderDate: string, position: number) => {
    setRowExpanded((prevId) => (prevId === id ? "" : id));
    const formatedOrderDate = Moment(orderDate).format("YYYY-MM-DD");

    dispatchGenericEvent(callUxButtonClicked, {
      button_label: rowExpanded === id ? "collapse" : "expand",
      button_name:
        rowExpanded === id
          ? "single-last-order-collapse"
          : "single-last-order-expand",
      filter_option: null,
      screen_name: "Call - GAP and Status",
      screen_section: isLastMarketplaceOrder
        ? "Marketplace Last Orders"
        : "Last Orders",
      call_id: callId,
      order_date: formatedOrderDate,
      position,
    });
  };

  const descLabel = (label: string) => {
    return t(label);
  };

  const qtyProductsFormater = (qtdy: string | number, label: string) => {
    if (qtdy) {
      return `${qtdy} ${t(label)}`;
    }

    return qtdy;
  };

  const getCellClasses = (id: string) => {
    let cellClasses = "";
    if (rowExpanded === id) {
      cellClasses = classes.rowHighlighted;
    } else {
      cellClasses = classes.defaultRow;
    }
    return cellClasses;
  };

  const getPaymentMethod = (type: string) => {
    let typePayment = "";
    switch (type) {
      case "CASH":
        typePayment = t("LastOrders.CASH_LABEL");
        break;
      case "CREDIT":
        typePayment = t("LastOrders.CREDIT_LABEL");
        break;
      case "BANK_SLIP":
        typePayment = t("LastOrders.BANK_SLIP_LABEL");
        break;
      case "BANK_SLIP_INSTALLMENT":
        typePayment = t("LastOrders.BANK_SLIP_INSTALLMENT_LABEL");
        break;
      case "CHECK":
        typePayment = t("LastOrders.CHECK_LABEL");
        break;
      case "CREDIT_CARD_POS":
        typePayment = t("LastOrders.CREDIT_CARD_POS_LABEL");
        break;
      default:
        typePayment = "-";
        break;
    }
    return typePayment;
  };

  const getStatus = (status: string) => t(translations[status]) || status;

  function formatDate(inputDate) {
    const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;

    if (dateFormatRegex.test(inputDate)) {
      return inputDate;
    }

    const isoWithTimezoneRegex =
      /(\d{4})-(\d{2})-(\d{2})T\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}$/;
    const matches = inputDate.match(isoWithTimezoneRegex);

    if (matches) {
      const year = matches[1];
      const month = matches[2];
      const day = matches[3];
      return `${day}/${month}/${year}`;
    }

    const date = new Date(inputDate);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  const getDeliveryDate = (
    order: Orders,
  ): { value: string; isDate?: boolean } => {
    const { deliveryDate, deliveryMaxDate } = order;

    if (deliveryDate) {
      return { value: formatDate(deliveryDate) };
    }
    if (deliveryMaxDate) {
      return {
        value: `${descLabel("LastOrders.DELIVERY_MAX_DATE_START")} ${
          order.deliveryMaxDate
        } ${descLabel("LastOrders.DELIVERY_MAX_DATE_END")}`,
      };
    }
    return { value: "" };
  };

  return (
    <CollapsibleTable className={classes.table} testId="last-orders">
      <TableHead testId="last-orders">
        <TableCell
          head
          text={descLabel("LastOrders.CHANNEL_LABEL")}
          testId="last-orders-channel"
        />
        <TableCell
          head
          text={descLabel("LastOrders.ORDER_CODE_LABEL")}
          testId="last-orders-order-code"
        />
        <TableCell
          head
          text={descLabel("LastOrders.STATUS_LABEL")}
          testId="last-orders-status"
        />
        <TableCell
          head
          text={descLabel("LastOrders.PAYMENT_LABEL")}
          testId="last-orders-payment-method"
        />
        <TableCell
          head
          text={descLabel("LastOrders.CREATION_DATE_LABEL")}
          testId="last-orders-creation-date"
        />
        <TableCell
          head
          text={descLabel("LastOrders.N_PRODUCTS_LABEL")}
          testId="last-orders-n-product"
        />
        <TableCell
          head
          text={descLabel("LastOrders.DELIVERY_DATE_LABEL")}
          testId="last-orders-delivery-date"
        />
        <TableCell
          className={classes.alignRight}
          head
          text={descLabel("LastOrders.TOTAL_LABEL")}
          testId="last-orders-total"
        />
      </TableHead>

      <TableBody testId="last-orders">
        {orders?.map((row, index) => (
          <TableRow
            key={row.orderNumber}
            rowKey={row.orderNumber}
            expanded={rowExpanded === row.orderNumber}
            onRowExpanded={() =>
              onRowExpanded(row.orderNumber, row.createDate, index + 1)
            }
            rowContent={
              <>
                <TableCellCustom
                  className={classNames(getCellClasses(row.orderNumber))}
                  customStyle={{
                    color: palette.text.secondary,
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                  text={getShortenedChannel(row.channel)}
                  testId="last-orders-order-channel"
                  title={row.channel}
                />
                <TableCell
                  className={getCellClasses(row.orderNumber)}
                  text={row.orderNumber}
                  testId="last-orders-order-code-value"
                />
                <TableCell
                  className={getCellClasses(row.orderNumber)}
                  text={getStatus(row.status)}
                  testId="last-orders-status-value"
                />
                <TableCell
                  className={getCellClasses(row.orderNumber)}
                  text={getPaymentMethod(row.paymentMethod)}
                  testId="last-orders-payment-value"
                />
                <TableCell
                  className={getCellClasses(row.orderNumber)}
                  type="text"
                  text={formatDate(row.createDate)}
                  testId="last-orders-creation-date-value"
                />
                <TableCell
                  className={getCellClasses(row.orderNumber)}
                  text={qtyProductsFormater(
                    row.qtyProducts,
                    "LastOrders.PRODUCTS_TEXT",
                  )}
                  testId="last-orders-n-product-value"
                />
                <TableCell
                  className={getCellClasses(row.orderNumber)}
                  type="text"
                  text={getDeliveryDate(row).value}
                  testId="last-orders-delivery-date-value"
                />
                <TableCell
                  className={getCellClasses(row.orderNumber)}
                  text={row.total}
                  type="currency"
                  testId="last-orders-total-value"
                />
              </>
            }
            subRowContent={
              <ProductList content={row.items} dataTest="last-orders" />
            }
            testId="last-orders"
          />
        ))}
      </TableBody>
    </CollapsibleTable>
  );
}

Table.defaultProps = {
  isLastMarketplaceOrder: false,
};

export default Table;
