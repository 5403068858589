import { getMissionsPriority } from "../../services/missions/getMissionsPriority/getMissionsPriority";
import { getMissionsPriorityBySearchByPoc } from "../../services/missions/getMissionsPriorityBySearchByPoc/getMissionsPriorityBySearchByPoc";
import {
  setFlexibleMissionsIds,
  setLocalMissionsIds,
  setSponsoredMissionsIds,
} from "../../stores/sponsored/SponsoredEvents";
import { PocCallOrigin } from "../../config/constants";
import {
  getEffectiveMissions,
  setEffectiveMissionsEvent,
} from "../../stores/globalAndLocalMissions/EffectivenessMissionEvents";

export const execute = async (
  tags: string[],
  localMissionIds: string[],
  vendorAccountId: string,
  pocCallOrigin?: string,
  vendorId?: string,
): Promise<{ missions: string[]; missionsTabs: string[] }> => {
  const tagString = tags.toString();
  const localMissionsIdsString = localMissionIds.toString();

  if (
    pocCallOrigin === PocCallOrigin.SEARCH_BY_POC ||
    pocCallOrigin === PocCallOrigin.AUTO_DIALER
  ) {
    return getMissionsPriorityBySearchByPoc(vendorAccountId, vendorId)
      .then((response) => {
        setSponsoredMissionsIds(response?.sponsoredMissionsIds);
        setLocalMissionsIds(response?.localMissionIds);
        setFlexibleMissionsIds(response?.flexibleMissionIds);

        setEffectiveMissionsEvent(
          getEffectiveMissions({
            sponsoredMissions: [],
            localMissions: [],
            globalCallListMissions: response?.missions,
          }),
        );

        return {
          missions: response?.missions,
          missionsTabs: response?.missionsTabs,
        };
      })
      .catch((error) => {
        throw error;
      });
  }
  return getMissionsPriority(
    tagString,
    localMissionsIdsString,
    vendorAccountId,
    vendorId,
  )
    .then((response) => {
      setSponsoredMissionsIds(response?.sponsoredMissionsIds);
      setLocalMissionsIds(response?.localMissionIds);
      setFlexibleMissionsIds(response?.flexibleMissionIds);
      return {
        missions: response?.missions,
        missionsTabs: response?.missionsTabs,
      };
    })
    .catch((error) => {
      throw error;
    });
};
