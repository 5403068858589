import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    errorContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      color: "#7c7c7c",
      height: "100%",
    },
    errorText: {
      fontSize: "18px",
      color: "#7c7c7c",
      paddingBottom: "16px",
    },
    buttonLabel: {
      fontSize: "11px",
      color: "#7a7a7a",
      paddingRight: "12px",
      fontWeight: 400,
    },
  }),
);
