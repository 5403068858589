import * as React from "react";

function RewardsTabChallengesIcon() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stopColor="#FEFE55" offset="0%"></stop>
          <stop stopColor="#F2F209" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="📄-Missions"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="2.0-Mission.-Challenges---First-version"
          transform="translate(-351.000000, -205.000000)"
          fillRule="nonzero"
        >
          <g id="Mission-content" transform="translate(333.000000, 100.000000)">
            <g id="Group" transform="translate(1.905021, 0.000000)">
              <g id="mission-header" transform="translate(0.095025, 93.000000)">
                <g
                  id="loudspeaker64x64"
                  transform="translate(15.999953, 12.000000)"
                >
                  <ellipse
                    id="Oval"
                    fill="url(#linearGradient-1)"
                    cx="26.5"
                    cy="25"
                    rx="26.5"
                    ry="25"
                  ></ellipse>
                  <g id="surface1" transform="translate(0.000000, 0.781250)">
                    <path
                      d="M21.3342854,45.6800186 L17.2252085,44.8865653 L14.8475887,47.2063592 L6.74265482,41.1802278 L12.1481007,37.7051078 L17.8956325,31.7799199 C18.8588112,30.7868652 20.5615589,30.9300905 21.3225591,32.0686561 L28.1319328,39.425336 C29.1610216,40.5372373 28.8698845,42.2559418 27.524184,43.0109223 L23.5125572,45.2613892 C22.8122106,45.6602109 22.1454258,45.6941127 21.3342854,45.6800186 Z"
                      id="Path"
                      fill="#FFE7CF"
                    ></path>
                    <path
                      d="M28.1319328,39.425336 L26.4704295,37.630067 L19.5413661,41.5200071 C18.8410195,41.9188289 18.1742346,41.9527306 17.3626899,41.9386366 L13.7639117,41.5737166 L10.6552142,44.0889219 L14.8479931,47.2063592 L17.2252085,44.8861844 L21.3342854,45.6800186 C22.1454258,45.6937316 22.812615,45.6602109 23.5125572,45.2613892 L27.524184,43.0109223 C28.8698845,42.2559418 29.1610216,40.5372373 28.1319328,39.425336 Z"
                      id="Path"
                      fill="#EFD5BD"
                    ></path>
                    <path
                      d="M27.931776,39.5822745 C27.1295315,38.4101881 26.1522004,39.0032783 25.3499559,37.830811 C24.5477114,36.6587247 25.5250425,36.0652535 24.722798,34.8931672 C23.9201491,33.7206998 22.9432223,34.3141709 22.1409779,33.1417036 C21.338329,31.9688555 22.3156601,31.3753843 21.5130113,30.202917 C21.0225262,29.4864092 20.1341535,28.0069216 19.5931237,27.8035111 L15.2628634,29.8189511 L22.7984626,41.0149091 C23.5335838,42.0894804 25.0543708,42.3991672 26.1950623,41.7062763 L27.6369997,40.8309255 C28.0773446,40.5631398 28.2204871,40.0085223 27.9382458,39.5921784 C27.936224,39.5887501 27.9342022,39.5857027 27.931776,39.5822745 Z"
                      id="Path"
                      fill="#424242"
                    ></path>
                    <path
                      d="M15.4755553,31.5521305 L12.073699,33.5294788 C11.428345,33.9046835 10.5820255,33.7157479 10.1837338,33.1078019 L8.26061136,30.1716817 C7.86231963,29.5637357 8.06288076,28.7664732 8.70823473,28.3912684 L12.1100911,26.4139202 C12.5095959,26.1819409 13.0332384,26.2985019 13.2798961,26.6748495 L15.7525398,30.4501332 C15.9991978,30.8264807 15.8750601,31.3197703 15.4755553,31.5521305 Z"
                      id="Path"
                      fill="#424242"
                    ></path>
                    <path
                      d="M17.5628466,21.0627765 L10.6580448,25.0765152 C9.74904997,25.6044683 9.46721295,26.7266544 10.027652,27.5825784 L13.4327432,32.7813547 C13.9935865,33.6372786 15.1844182,33.9031598 16.093413,33.3748258 L22.998215,29.361468 L44.8379468,24.8830086 L31.6077865,4.68365974 L17.5628466,21.0627765 Z"
                      id="Path"
                      fill="#FFC981"
                    ></path>
                    <path
                      d="M41.7207578,20.1238123 L19.9934373,24.7744468 C19.9934373,24.7744468 12.5128307,29.1222511 11.4433062,29.7442912 L13.4327432,32.7813547 C13.9931821,33.6372786 15.1844182,33.9031598 16.0930086,33.3748258 L22.998215,29.361468 L44.8379468,24.8830086 L41.7207578,20.1238123 Z"
                      id="Path"
                      fill="#FFB042"
                    ></path>
                    <path
                      d="M22.998215,29.361468 L16.0930086,33.3748258 C15.1844182,33.9031598 13.9931821,33.6372786 13.4327432,32.7813547 L10.027652,27.5825784 C9.46680866,26.7266544 9.74904997,25.6044683 10.6576403,25.0765152 L17.5628466,21.0627765 L22.998215,29.361468 Z"
                      id="Path"
                      fill="#FF6F71"
                    ></path>
                    <path
                      d="M19.9934373,24.7744468 C19.9934373,24.7744468 12.5128307,29.1222511 11.4433062,29.7442912 L13.4327432,32.7813547 C13.9931821,33.6372786 15.1844182,33.9031598 16.0930086,33.3748258 L19.5454096,31.3681469 L22.998215,29.361468 L19.9934373,24.7744468 Z"
                      id="Path"
                      fill="#C9201D"
                    ></path>
                    <path
                      d="M46.1480639,26.3183097 L45.2055074,26.8660705 C44.717044,27.1498549 44.0765424,27.0070104 43.7752963,26.5468608 L29.5524393,4.83221802 C29.2507889,4.37168743 29.4024228,3.76869344 29.8912906,3.48452815 L30.8338471,2.93676732 C31.3223104,2.65260204 31.9628121,2.79544652 32.2640581,3.25597702 L46.486915,24.9706198 C46.7885655,25.4307694 46.6365273,26.0341444 46.1480639,26.3183097 Z"
                      id="Path"
                      fill="#FF6F71"
                    ></path>
                    <path
                      d="M46.486915,24.9706198 L44.1473048,21.3979848 L41.4352817,22.9746066 L43.7752963,26.5468608 C44.0765424,27.0070104 44.717044,27.1498549 45.2055074,26.8660705 L46.1480639,26.3183097 C46.6365273,26.0341444 46.7885655,25.4307694 46.486915,24.9706198 Z"
                      id="Path"
                      fill="#C9201D"
                    ></path>
                    <path
                      d="M25.7886834,42.5385833 C26.3026212,43.3312748 27.4016639,43.5815384 28.243131,43.0970099 L30.845169,41.5984764 C31.6862318,41.1143289 31.95149,40.0797541 31.4375522,39.2874435 C30.9236143,38.494752 29.8245717,38.2448694 28.9835089,38.7290169 L26.3810665,40.2275505 C25.5404081,40.711698 25.2751499,41.7462727 25.7886834,42.5385833 Z"
                      id="Path"
                      fill="#FFE7CF"
                    ></path>
                    <path
                      d="M31.4375522,39.2874435 C31.2268821,38.9625201 30.9171446,38.7305405 30.5681844,38.6014091 C30.6595691,39.2417334 30.3538751,39.902627 29.7307608,40.2614523 L27.1283184,41.7599859 C26.631768,42.0456748 26.0466633,42.0738627 25.5440473,41.8879745 C25.5759915,42.1119545 25.6556499,42.3336491 25.7886834,42.5389643 C26.3026212,43.3316558 27.4016639,43.5815384 28.243131,43.0970099 L30.845169,41.5984764 C31.6862318,41.1143289 31.9510857,40.0797541 31.4375522,39.2874435 Z"
                      id="Path"
                      fill="#EFD5BD"
                    ></path>
                    <path
                      d="M24.6742751,39.2295439 C25.1958958,40.0344248 26.3115172,40.2881166 27.1655193,39.7963507 L29.7214607,38.3244815 C30.5750585,37.8330965 30.8443603,36.7829041 30.323144,35.9784041 C29.8015233,35.1739041 28.6859021,34.9202123 27.8318999,35.4119782 L25.2755542,36.8838474 C24.4219563,37.3752324 24.1526545,38.4254248 24.6742751,39.2295439 Z"
                      id="Path"
                      fill="#FFE7CF"
                    ></path>
                    <path
                      d="M30.323144,35.9784041 C30.1153044,35.6580516 29.8128452,35.4268341 29.4703548,35.2931316 C29.5572916,35.9391695 29.2471497,36.6031105 28.6191831,36.9646022 L26.0628375,38.4364715 C25.5488996,38.7324452 24.9411508,38.7568239 24.4239781,38.555318 C24.4551137,38.7872974 24.5363894,39.0169913 24.6742751,39.2295439 C25.1958958,40.0344248 26.3115172,40.2881166 27.1655193,39.7963507 L29.7214607,38.3244815 C30.5750585,37.8330965 30.8443603,36.7829041 30.323144,35.9784041 Z"
                      id="Path"
                      fill="#EFD5BD"
                    ></path>
                    <path
                      d="M22.7689444,36.3357057 C23.2905651,37.1402057 24.4061865,37.3942783 25.2601886,36.9025125 L27.8165342,35.4302623 C28.6701321,34.9388774 28.9390296,33.888685 28.4178133,33.0845659 C27.8961926,32.279685 26.7805714,32.0259932 25.9265692,32.5177591 L23.3702235,33.9896284 C22.5166256,34.4810133 22.2473238,35.5312058 22.7689444,36.3357057 Z"
                      id="Path"
                      fill="#FFE7CF"
                    ></path>
                    <path
                      d="M28.4178133,33.0845659 C28.2144216,32.770689 27.9200497,32.541757 27.5864551,32.4069118 C27.6616654,33.0449504 27.351928,33.696321 26.7320486,34.0532418 L24.1761072,35.5251111 C23.6548909,35.8252748 23.0374375,35.8462253 22.5162213,35.6359583 C22.5445262,35.8763179 22.6258021,36.1151539 22.7685401,36.3353248 C23.2905651,37.1402057 24.405782,37.3938974 25.2601886,36.9021316 L27.8165342,35.4302623 C28.6701321,34.9388774 28.9390296,33.888685 28.4178133,33.0845659 Z"
                      id="Path"
                      fill="#EFD5BD"
                    ></path>
                    <path
                      d="M20.9727902,33.3675884 C21.4944109,34.1720884 22.6100321,34.4257801 23.4640344,33.9340142 L26.0203801,32.4621449 C26.8739778,31.9707601 27.1432797,30.9205677 26.621659,30.1160677 C26.1000383,29.3115677 24.9844171,29.0578759 24.1304149,29.5496417 L21.5740691,31.021511 C20.7204714,31.5128959 20.4515738,32.5630884 20.9727902,33.3675884 Z"
                      id="Path"
                      fill="#FFE7CF"
                    ></path>
                    <path
                      d="M26.621659,30.1160677 C26.3818753,29.7458148 26.0155277,29.4940276 25.6071271,29.3759429 C25.7632088,30.0646436 25.4554931,30.8001973 24.7818342,31.1883533 L22.2254885,32.6602226 C21.7645214,32.9257229 21.2275352,32.9721949 20.7487763,32.8335405 C20.7904251,33.0175242 20.8632094,33.1980795 20.9727902,33.3675884 C21.4944109,34.1720884 22.6100321,34.4257801 23.4640344,33.9340142 L26.0203801,32.4621449 C26.8739778,31.9707601 27.1432797,30.9205677 26.621659,30.1160677 Z"
                      id="Path"
                      fill="#EFD5BD"
                    ></path>
                    <path
                      d="M24.8077131,35.4325478 L24.3338065,35.7052855 C23.5877677,36.1349616 22.6237803,35.9288846 22.1797962,35.2455168 L21.3945347,34.0357195 C21.009991,33.4437721 20.1733761,33.2677877 19.530044,33.6437543 C18.8842857,38.1088815 12.1481007,37.7051078 12.1481007,37.7051078 L16.3222792,31.7825863 C16.5976463,31.3338642 16.9886597,30.9544694 17.4593312,30.6794461 L19.8151158,29.3031874 C20.9602551,28.6342945 22.449098,28.9466477 23.1344833,30.0002684 L25.3548082,33.4136795 C25.799601,34.0981902 25.5545607,35.0024907 24.8077131,35.4325478 Z"
                      id="Path"
                      fill="#FFE7CF"
                    ></path>
                    <path
                      d="M25.3544038,33.4140604 L25.3362077,33.3858725 C25.2076222,33.5805218 25.0305138,33.7515543 24.8077131,33.8799238 L24.3334021,34.1530424 C23.5877677,34.5823377 22.6237803,34.3766416 22.1797962,33.6928928 L21.3945347,32.4834764 C21.009991,31.891148 20.1733761,31.7155446 19.530044,32.0911303 C19.2591248,33.9637259 18.0436273,35.7582331 16.6870092,36.9874573 C18.0593973,36.4195077 19.2736816,35.4146446 19.530044,33.6437543 C20.1733761,33.2677877 21.009991,33.4437721 21.3945347,34.0357195 L22.1797962,35.2455168 C22.6237803,35.9288846 23.5877677,36.1349616 24.3334021,35.7056664 L24.8077131,35.4325478 C25.5545607,35.0024907 25.799601,34.0981902 25.3544038,33.4140604 Z"
                      id="Path"
                      fill="#EFD5BD"
                    ></path>
                    <path
                      d="M14.978196,47.3522511 L8.3386524,39.9197684 L10.5250112,38.1865889 C10.593752,38.1321175 10.696863,38.1404977 10.7550904,38.2052539 L17.1843684,45.4027099 C17.2421915,45.4674661 17.2332957,45.5646002 17.1645549,45.6194525 L14.978196,47.3522511 Z"
                      id="Path"
                      fill="#FFFFFF"
                    ></path>
                    <path
                      d="M17.183964,45.4027099 L13.7639117,41.5737166 L11.4526064,43.4059349 L14.978196,47.352632 L17.1641505,45.6194525 C17.2332957,45.5646002 17.2421915,45.4674661 17.183964,45.4027099 Z"
                      id="Path"
                      fill="#DFDEDE"
                    ></path>
                    <path
                      d="M42.3689422,12.4940137 C42.1040884,12.4940137 41.8457042,12.3683106 41.6969008,12.1405213 C41.4688433,11.791219 41.5844894,11.3341167 41.955285,11.1192787 L46.2261047,8.64445086 C46.5969002,8.42961273 47.0821287,8.53855547 47.3097818,8.88785773 C47.5378392,9.23677914 47.4221931,9.69388141 47.0518019,9.90871945 L42.7809821,12.3835473 C42.6519922,12.4585883 42.5096585,12.4940137 42.3689422,12.4940137 Z"
                      id="Path"
                      fill="#424242"
                    ></path>
                    <path
                      d="M52.2130162,18.5532851 C52.1875416,18.5532851 52.1616627,18.5521423 52.1357839,18.5498567 L45.874314,17.9765743 C45.4412476,17.9369588 45.1242316,17.5743244 45.1662847,17.1659797 C45.2083379,16.7583969 45.5936902,16.4589948 46.0267567,16.4993723 L52.2882265,17.0722738 C52.721293,17.1118893 53.038309,17.4749046 52.9962558,17.8828684 C52.9566288,18.2664534 52.6137339,18.5532851 52.2130162,18.5532851 Z"
                      id="Path"
                      fill="#424242"
                    ></path>
                    <path
                      d="M38.7851252,7.00764379 C38.6901013,7.00764379 38.5934599,6.99126427 38.4996491,6.95698165 C38.0940788,6.80842336 37.8935177,6.37798545 38.0512171,5.99630511 L40.3317912,0.472986049 C40.4894904,0.0909248166 40.946414,-0.0976298897 41.3515798,0.0505474019 C41.7571499,0.199105685 41.9581154,0.629162702 41.8004162,1.01122403 L39.5198421,6.53416201 C39.398535,6.82785023 39.1005237,7.00764379 38.7851252,7.00764379 Z"
                      id="Path"
                      fill="#424242"
                    ></path>
                    <path
                      d="M15.4521026,47.2829238 C14.5539796,46.2904549 13.8803873,45.5461032 13.4313258,45.0498688 C12.2856927,43.7838894 10.5672431,41.8849203 8.27597698,39.3529616 C8.21896265,39.289729 8.12029942,39.2771588 8.04711081,39.3240117 L0.404357109,45.0073165 C0.149207747,45.1970139 0,45.486893 0,45.7935323 L0,48.1057079 C0,48.6588016 0.475928292,49.1071429 1.06345925,49.1071429 L13.5657767,49.1071429 C13.8876449,49.1071429 14.1925302,48.9696312 14.3947087,48.7330808 L15.4448242,47.5015712 C15.5066907,47.4413859 15.5095213,47.3465373 15.4521026,47.2829238 Z"
                      id="Path"
                      fill="#585858"
                    ></path>
                    <path
                      d="M15.4521026,47.2829238 L12.0494375,43.5224959 L7.03985722,49.1071429 L13.5657767,49.1071429 C13.8876449,49.1071429 14.1925302,48.9696312 14.3947087,48.7330808 L15.4448242,47.5015712 C15.5066907,47.4413859 15.5095213,47.3465373 15.4521026,47.2829238 Z"
                      id="Path"
                      fill="#424242"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RewardsTabChallengesIcon;
