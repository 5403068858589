import { CheckoutMessage } from "services/checkout/domains/CheckoutMessage";
import { CheckoutInfoV2 } from "../../../domains/CheckoutInfoV2";
import { CheckoutResponseV2 } from "../../../services/checkout/domains/v2/CheckoutResponseV2";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function CheckoutResponseV2ToCheckoutInfoConverter(
  data: CheckoutResponseV2,
): CheckoutInfoV2 {
  const messages = data.messages?.map(
    (message) =>
      <CheckoutMessage>{
        text: message.text,
        type: message.type,
      },
  );

  return {
    orders: data.orders,
    messages,
  };
}
