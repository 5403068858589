import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  targetColor?: string;
}

const cardInfoStyles = makeStyles<Theme, Props>(({ palette }) =>
  createStyles({
    containerBox: {
      height: "145px",
      borderRadius: "15px",
      margin: "16px 20px 10px 0",
      minWidth: "211px",
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.16)",
    },
    overwriteTitle: {
      fontSize: "14px",
      fontWeight: "normal",
    },
    title: {
      height: "31px",
      display: "flex",
      alignItems: "center",
      padding: "0px 24px 0px 24px",
      color: palette.text.primary,
      backgroundColor: palette.common.white,
      fontWeight: "bold",
      border: `1px solid ${palette.grey[100]}`,
      borderRadius: "5px 5px 0px 0px",
    },
    actionButton: {
      width: "20px",
      height: "20px",
      padding: 0,
      margin: 0,
      minHeight: 0,
      minWidth: 0,
    },
    minute: {
      fontSize: "22px",
      marginLeft: "5px",
    },
    actionButtonIcon: {
      fontSize: "16px",
      color: "#7A7A7A",
    },
    percentage: {
      letterSpacing: "0px",
      color: palette.secondary.main,
      fontSize: "42px",
      height: "52px",
      fontWeight: "bold",
      fontFamily: "Barlow",
    },
    divider: {
      height: "26px",
      backgroundColor: palette.secondary.main,
      margin: "0px 9px 4px 9px",
    },
    mainValue: {
      letterSpacing: "0px",
      color: palette.secondary.main,
      fontSize: "23px",
      height: "30px",
      fontWeight: "bold",
      fontFamily: "Barlow",
    },
    mainValueTime: {
      letterSpacing: "0px",
      color: palette.secondary.main,
      fontSize: "42px",
      height: "52px",
      fontWeight: "bold",
    },
    mainLabel: {
      marginTop: "5px",
      color: palette.text.secondary,
    },
    primaryValue: {
      letterSpacing: "0px",
      color: (props) =>
        props.targetColor ? props.targetColor : palette.info.main,
      fontSize: "23px",
      fontWeight: "bold",
      alignContent: "right",
      height: "30px",
    },
    labelOverwrite: {
      opacity: "1",
    },
    secondaryLabel: {
      fontWeight: "bold",
      whiteSpace: "nowrap",
      marginTop: "5px",
    },
    cardContent: {
      backgroundColor: palette.grey[50],
      padding: "24px",
    },
  }),
);

export default cardInfoStyles;
