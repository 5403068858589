import React from "react";
import { FormikErrors, FormikTouched } from "formik";
import { useStore } from "effector-react";
import GenericMissions from "../genericMissions/GenericMissions";
import {
  Mission,
  GAPReason,
  GAPReasonReviewed,
  TopLineMissionClassification,
  TopLineMissionClassificationOrder,
  GAPReasonValues,
} from "../../../../../../../domains/Mission";
import GlobalStore from "../../../../../../../stores/global/GlobalStore";
import {
  isFeatureEnabledV2,
  TLP_CALL_SUMMARY_REASONS_LIST,
  TLP_CALL_SUMMARY_TOPLINEGAP_CLIENT_UPSET_REASON,
} from "../../../../../../../config/featureToggles";

import OrdersStore from "../../../../../../../stores/order/OrdersStore";

interface TargetValue {
  name?: string;
  value: string;
}

interface Props {
  handleChange: (
    e: React.ChangeEvent<TargetValue>,
    tag: string,
    usage: string,
  ) => void;
  values: Mission;
  errors?: FormikErrors<Mission>;
  touched?: FormikTouched<Mission>;
}

const ToplineGapMission: React.FunctionComponent<Props> = ({
  values,
  errors,
  handleChange,
  touched,
}) => {
  const { orderInfo, ordersList, hasTechnicalProblem } = useStore(OrdersStore);

  const classification = () => {
    return hasPlacedOrder()
      ? TopLineMissionClassificationOrder
      : TopLineMissionClassification;
  };

  const hasPlacedOrder = () => {
    return !!orderInfo?.orderId || ordersList?.length > 0;
  };

  const isClassificationEmpty = () => {
    return values.typification === "";
  };

  const isGapEmpty = () => {
    return values.reason === "";
  };

  const {
    user: { zone },
  } = useStore(GlobalStore);

  const isReasonListReviwedEnabled = isFeatureEnabledV2(
    TLP_CALL_SUMMARY_REASONS_LIST,
    zone,
  );

  const isClientUpsetReasonEnabled = isFeatureEnabledV2(
    TLP_CALL_SUMMARY_TOPLINEGAP_CLIENT_UPSET_REASON,
    zone,
  );

  const handleReasons = () => {
    return !isClientUpsetReasonEnabled
      ? GAPReason.filter((data) => data.value !== GAPReasonValues.CLIENT_UPSET)
      : GAPReason;
  };

  return (
    <GenericMissions
      values={values}
      handleChange={handleChange}
      errors={errors}
      touched={touched}
      disableClassification={hasPlacedOrder()}
      disableGapReason={hasPlacedOrder() && hasTechnicalProblem}
      classification={classification()}
      gapReason={
        isReasonListReviwedEnabled ? GAPReasonReviewed : handleReasons()
      }
      isClassificationEmpty={isClassificationEmpty()}
      isGapEmpty={isGapEmpty()}
    />
  );
};

export default ToplineGapMission;
