import React, { Suspense, useEffect } from "react";
import { useStore } from "effector-react";
import { BrowserRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { toast, ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import AppHeader from "../components/appHeader/AppHeader";
import ToastNotification from "../components/toastNotification/ToastNotification";
import GlobalStore from "../stores/global/GlobalStore";
import * as GlobalUseCase from "../usecase/global/GlobalUseCase";
import useIsMounted from "../hooks/useIsMounted";
import ToastfyCustomStyles from "./ToastifyOverride.styles";
import Routes from "./Routes";

const AppRouter: React.FunctionComponent = () => {
  useEffect(() => {
    injectStyle();
  }, []);

  const globalState = useStore(GlobalStore);
  const classes = ToastfyCustomStyles();

  const didMount = useIsMounted();

  if (!didMount.isMounted().current) {
    return null;
  }

  return (
    <BrowserRouter>
      <ToastContainer
        className={classes.toastContainer}
        toastClassName={classes.toast}
        bodyClassName={classes.toastBody}
        position={toast.POSITION.TOP_CENTER}
        autoClose={7000}
        hideProgressBar
        draggable={false}
        closeButton={<CloseIcon className={classes.closeIcon} />}
      />

      <ToastNotification
        open={globalState.toast.open}
        handleClose={GlobalUseCase.toastHide}
        message={globalState.toast.message}
        severity={globalState.toast.severity}
      />
      <AppHeader />

      <Suspense fallback={<div>Loading...</div>}>
        <Routes />
      </Suspense>
    </BrowserRouter>
  );
};
export default AppRouter;
