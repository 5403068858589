import React from "react";

const SadFaceIcon: React.FunctionComponent = () => {
  return (
    <svg width={34} height={34} xmlns="http://www.w3.org/2000/svg">
      <g
        transform="translate(1 1)"
        stroke="#C9201D"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle strokeWidth={2} cx={16} cy={16} r={16} />
        <path d="M22.4 22.4S20 19.2 16 19.2s-6.4 3.2-6.4 3.2" strokeWidth={2} />
        <path strokeWidth={3} d="M11.2 11.2h.016M20.8 11.2h.016" />
      </g>
    </svg>
  );
};

export default SadFaceIcon;
