import React from "react";

const EnpsIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="95"
      height="94"
      viewBox="0 0 95 94"
    >
      <defs>
        <path
          id="path-1"
          d="M43 75c1.87 0 3.697-8.94 5.464-9.292 1.821-.362 3.578-.9 5.254-1.598a28.034 28.034 0 009.081-6.103A28.095 28.095 0 0071 38.122C71 22.59 58.464 10 43 10S15 22.59 15 38.122a28.095 28.095 0 008.201 19.885 28.034 28.034 0 009.081 6.103 27.699 27.699 0 005.254 1.598C39.303 66.059 41.13 75 43 75z"
        />
        <filter
          id="filter-2"
          width="119.6%"
          height="116.9%"
          x="-9.8%"
          y="-5.4%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="1.5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1234 -541)">
          <g transform="translate(1236.77 544.004)">
            <g transform="rotate(-49 43 42.5)">
              <use fill="#000" filter="url(#filter-2)" xlinkHref="#path-1" />
              <use fill="#505050" xlinkHref="#path-1" />
            </g>
            <circle cx="32" cy="30" r="4" fill="#FFF" />
            <circle cx="48" cy="30" r="4" fill="#FFF" />
            <path
              stroke="#FFF"
              strokeLinecap="round"
              strokeWidth="3"
              d="M26 41c0 7.18 6.268 13 14 13s14-5.82 14-13"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default EnpsIcon;
