import React from "react";
import { IconButton, TextField, Typography } from "@material-ui/core";
import { XCircle } from "@hexa-ui/icons";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import { useStore } from "effector-react";
import { getVendorName } from "grow-shared-services";
import {
  TLP_PHONE_RANKING_VENDOR,
  isFeatureEnabledV2,
} from "../../../../../../../../../config/featureToggles";
import GlobalStore from "../../../../../../../../../stores/global/GlobalStore";
import {
  getVendorNameWithFT,
  returnVendorIdWithFT,
} from "../../../../../../../../../config/utils/functions";
import { PhoneSimple } from "../../../../../../../../../domains/phoneRanking/RankedPhones";
import PocInformationStore from "../../../../../../../../../stores/callList/pocInformation/PocInformationStore";
import GlobalAdminConfigStore from "../../../../../../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import AgentCallStore from "../../../../../../../../../stores/agentCall/AgentCallStore";
import {
  updatePhoneEffect,
  getPhonesRanked,
} from "../../../../../../../../../stores/phoneRankingStore/phoneRankedEvents";
import Modal from "../../../../../../../../../components/modal/Modal";
import Button from "../../../../../../../../../components/button/Button";
import { phoneEdited } from "../../../../../../../../../config/typewriter";
import { useAnalytics } from "../../../../../../../../../analytics/useAnalytics";
import EditPhoneModalStyles from "./EditPhoneModal.styles";

interface EditPhoneModalProps extends PhoneSimple {
  isOpen: boolean;
  onClose: () => void;
}

const EditPhoneModal = ({
  phoneContact,
  phoneNumber,
  isOpen,
  onClose,
}: EditPhoneModalProps): JSX.Element => {
  const classes = EditPhoneModalStyles();
  const { t } = useTranslation();
  const { dispatchGenericEvent } = useAnalytics();

  const { clientId, callId } = useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);
  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { pocInformation } = useStore(PocInformationStore);
  const vendorName = getVendorName(userConfig.vendorId);

  const isPhoneRankingVendor = isFeatureEnabledV2(
    TLP_PHONE_RANKING_VENDOR,
    user.keyToggle,
  );

  const validationSchema = yup.object({
    phoneContact: yup.string(),
    phoneNumber: yup.string().required(t("GapReason.REASON_REQUIRED_MESSAGE")),
  });

  const handleChangePhone = (ev) => {
    const onlyNums = ev.target.value.replace(/\D/g, "");
    ev.target.value = onlyNums;
    formik.handleChange(ev);
  };

  /* istanbul ignore next */
  const handleSubmit = (values: PhoneSimple) => {
    updatePhoneEffect({
      accountId: clientId,
      currentNumber: phoneNumber,
      phone: {
        phoneNumber: values.phoneNumber,
        phoneContact: values.phoneContact,
      },
      vendorName,
    });
    const unwatchUpdatePhoneEffectDone = updatePhoneEffect.done.watch(() => {
      dispatchGenericEvent(phoneEdited, {
        call_id: callId,
        poc_id: clientId,
        phone_number: phoneNumber,
      });
      const vendorId = returnVendorIdWithFT(
        userConfig?.vendorId,
        isPhoneRankingVendor,
      );
      const vendorName = getVendorNameWithFT(vendorId, isPhoneRankingVendor);
      getPhonesRanked({
        accountId: clientId,
        vendorId,
        vendorName,
        phones: pocInformation?.phones || [],
      });
      onClose();
      if (typeof unwatchUpdatePhoneEffectDone === "function") {
        unwatchUpdatePhoneEffectDone();
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      phoneContact,
      phoneNumber,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  /* istanbul ignore next */
  return (
    <Modal open={isOpen} borderRadius="24px">
      <div className={classes.container}>
        <IconButton
          onClick={onClose}
          data-testid="closeButton"
          className={classes.closeButton}
        >
          <XCircle size="xlarge" />
        </IconButton>
        <Typography variant="h1" className={classes.title}>
          {t("EditPhoneModal.EDIT_NUMBER")}
        </Typography>
        <TextField
          name="phoneContact"
          id="phoneContact"
          className={classes.input}
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.inputPlaceholder,
            },
          }}
          placeholder={t("PHONE_RANKING.CONTACT_NAME")}
          value={formik.values.phoneContact}
          onChange={formik.handleChange}
          error={
            formik.touched.phoneContact && Boolean(formik.errors.phoneContact)
          }
          helperText={formik.touched.phoneContact && formik.errors.phoneContact}
          data-testid="phoneContact"
        />
        <TextField
          name="phoneNumber"
          id="phoneNumber"
          className={classes.input}
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.inputPlaceholder,
            },
          }}
          placeholder={t("PHONE_RANKING.PHONE_NUMBER")}
          value={formik.values.phoneNumber}
          onChange={handleChangePhone}
          error={
            formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
          }
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          data-testid="phoneNumber"
        />
        <Button
          title={t("EditPhoneModal.SAVE_NUMBER")}
          onClick={formik.submitForm}
          className={classes.saveButton}
          data-testid="saveButton"
        >
          {t("EditPhoneModal.SAVE_NUMBER")}
        </Button>
      </div>
    </Modal>
  );
};

export default EditPhoneModal;
