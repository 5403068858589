import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";

import axios from "../../config/axios/axiosInstance";
import {
  isFeatureEnabledV2,
  TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
} from "../../config/featureToggles";
import { getCountryByToken } from "../../config/utils/functions";
import ServiceMission from "../../domains/servicesMissions/ServiceMission";
import VendorStore from "../../stores/vendor/VendorStore";
import { getV2EndPoint, getVendorIdParam } from "../host/HostService";

export const GROW_MISSIONS_ADMIN = "/grow-missions-service/grow-missions/admin";
export const SERVICES = "/services";
/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export async function getServicesMissions(): Promise<ServiceMission[]> {
  const log = useLogService();

  const { vendor } = VendorStore.getState();

  const isGrowMissionsV2Enabled = isFeatureEnabledV2(
    TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
    getCountryByToken(),
  );

  const v2EndPoint = getV2EndPoint(isGrowMissionsV2Enabled);
  const vendorIdParam = getVendorIdParam(vendor.id, isGrowMissionsV2Enabled);

  const endpoint = `${v2EndPoint}${SERVICES}${vendorIdParam}`;

  const url = `${GROW_MISSIONS_ADMIN}${endpoint}`;

  return axios
    .get<ServiceMission[]>(`${url}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      log.error("getServicesMissions error: ", error);
      throw error;
    });
}
