import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { Edit2 } from "@hexa-ui/icons";
import { Phone } from "domains/phoneRanking/RankedPhones";
import { conditionalClass } from "../../../../../../../../config/utils/functions";
import { INPUTTED_PHONE } from "../../../../../../../../config/constants";
import EditButtonStyles from "./EditButton.styles";
import EditPhoneModal from "./EditPhoneModal/EditPhoneModal";

interface EditButtonProps {
  phone: Phone;
  isInactive: boolean;
}

const EditButton = ({ phone, isInactive }: EditButtonProps): JSX.Element => {
  const classes = EditButtonStyles();
  const { phoneType, phoneContact, phoneNumber } = phone;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const editButtonClick = () => setIsOpen(true);

  const onCloseModal = () => setIsOpen(false);

  const isInputted = phoneType === INPUTTED_PHONE;

  const isDisabled = isInactive || !isInputted;

  return (
    <>
      <IconButton
        className={`${classes.editButton} ${conditionalClass(
          isDisabled,
          classes.editButtonDisabled,
        )}`}
        data-testid="editButton"
        disabled={isDisabled}
        onClick={editButtonClick}
      >
        <Edit2 />
      </IconButton>
      {isOpen && (
        <EditPhoneModal
          phoneContact={phoneContact}
          phoneNumber={phoneNumber}
          isOpen={isOpen}
          onClose={onCloseModal}
          data-testid="editPhoneModal"
        />
      )}
    </>
  );
};

export default EditButton;
