import React, { ReactNode, FunctionComponent } from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableListViewStyle from "./TableListViewStyle";

import TableListHead from "./TableListHead";
import TableListRow from "./TableListRow";
import TableListCell from "./TableListCell";
import TableListBody from "./TableListBody";
import TableListFooter from "./TableListFooter";
import TableSortListCell from "./TableSortListCell";

interface Props {
  className?: string;
  children: ReactNode;
  testId: string;
  style?: Record<string, unknown>;
  stickyHeader?: boolean;
}

const TableListView: FunctionComponent<Props> = ({
  className,
  children,
  testId,
  style,
  stickyHeader,
}) => {
  const classes = TableListViewStyle();
  return (
    <TableContainer
      component={Paper}
      className={`${classes.tableContainer} ${className}`}
      data-testid={`${testId}-table-container`}
      style={style}
    >
      <Table
        aria-label="table"
        data-testid={`${testId}-table`}
        stickyHeader={stickyHeader}
      >
        {children}
      </Table>
    </TableContainer>
  );
};

TableListView.defaultProps = {
  className: "",
};

export default TableListView;

export {
  TableListHead,
  TableListRow,
  TableListCell,
  TableListBody,
  TableListFooter,
  TableSortListCell,
};
