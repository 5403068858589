import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import classNames from "classnames";
import { Phone } from "domains/phoneRanking/RankedPhones";
import AgentCallStore from "../../../../../../stores/agentCall/AgentCallStore";
import Button from "../../../../../../components/button/Button";
import { setCalledPhones } from "../../../../../../stores/phoneRankingStore/phoneRankedEvents";
import { useAnalytics } from "../../../../../../analytics/useAnalytics";
import { phoneClassified } from "../../../../../../config/typewriter";
import RateAgainButtonStyles from "./RateAgainButton.styles";

interface RateAgainButtonProps {
  isConnected: boolean;
  calledPhones: string[];
  item: Phone;
}

function RateAgainButton({
  isConnected,
  calledPhones,
  item,
}: RateAgainButtonProps): JSX.Element {
  const classes = RateAgainButtonStyles();
  const { t } = useTranslation();
  const { dispatchGenericEvent } = useAnalytics();
  const { callId, clientId } = useStore(AgentCallStore);

  const handleRateAgain = useCallback(() => {
    /* istanbul ignore next */ // The rate again button only shown when cti is disabled
    if (!isConnected) {
      const newCalledPhones = [...calledPhones];

      const index = newCalledPhones.indexOf(item.phoneNumber);

      if (index > -1) {
        newCalledPhones.splice(index, 1);
      }

      dispatchGenericEvent(phoneClassified, {
        call_id: callId,
        poc_id: clientId,
        phone_number: item.phoneNumber,
        phone_classification: "Rate Again",
        phone_source: item.phoneType,
        phone_rank: index + 1,
      });

      setCalledPhones(newCalledPhones);
    }
  }, [
    callId,
    clientId,
    calledPhones,
    isConnected,
    dispatchGenericEvent,
    item.phoneNumber,
    item.phoneType,
  ]);

  return (
    <Button
      className={classNames(classes.button)}
      variant="outlined"
      data-testid="rate-again-button"
      onClick={handleRateAgain}
    >
      <span className={classNames(classes.buttonLabel)}>
        {t("PHONE_RANKING.RATE_AGAIN")}
      </span>
    </Button>
  );
}

export default RateAgainButton;
