import classNames from "classnames";
import React from "react";
import { PACKAGE_TYPE } from "../../config/constants";
import { ProductVariants } from "../../domains/Catalog";
import PackageTypeButtonsStyles from "./PackageTypeButtons.styles";

interface PackageTypeButtonsProps {
  packageTypeValue: PACKAGE_TYPE;
  sortedProductsBySalesRanking: ProductVariants[];
  onClick: (platformId: string) => void;
}

const PackageTypeButtons = ({
  packageTypeValue,
  sortedProductsBySalesRanking,
  onClick,
}: PackageTypeButtonsProps): JSX.Element => {
  const classes = PackageTypeButtonsStyles();

  return (
    <div
      className={classes.container}
      data-testid="package-type-buttons-container"
    >
      {sortedProductsBySalesRanking.map((product: ProductVariants) => {
        if (
          !product.packageId ||
          !product.platformId ||
          !product.unitOfMeasurement
        )
          return null;
        return (
          <button
            key={product.platformId}
            type="button"
            className={classNames(
              classes.box,
              packageTypeValue === product.packageId && classes.selected,
            )}
            value={product.packageId}
            onClick={() => onClick(product.platformId)}
            data-testid={`${product.packageId}-button`}
          >
            {product.unitOfMeasurement}
          </button>
        );
      })}
    </div>
  );
};

export default PackageTypeButtons;
