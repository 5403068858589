import { useLogService } from "admin-portal-shared-services";
import axios, { CancelToken } from "axios";
import axiosInstance from "@config/axios/axiosInstance";
import GlobalStore from "@stores/global/GlobalStore";
import VendorStore from "@stores/vendor/VendorStore";
import {
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_AGENT_CALL_SERVICE,
} from "@config/featureToggles";
import { AGENT_CALL_SERVICE } from "@config/services";
import UpdateCanceledError from "@services/cart/domains/UpdateCanceledError";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../host/HostService";
import { AgentCallRequestBody, AgentCallRequestBodyV3 } from "./domains/types";

const AGENT_ENDPOINT = "/agent-call";

export async function update(
  requestBody: AgentCallRequestBody,
  callId: string,
  cancelToken: CancelToken,
  headers = {},
): Promise<string> {
  const log = useLogService();

  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();

  const isAgentCallV2Enabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_AGENT_CALL_SERVICE,
    user.zone,
  );

  const vendorIdMetadata = vendor?.id;

  const v2EndPoint = getV2EndPoint(isAgentCallV2Enabled);
  const vendorIdParam = getVendorIdParam(
    vendorIdMetadata,
    isAgentCallV2Enabled,
  );

  const urlagentcall = `${getApiHost(
    AGENT_CALL_SERVICE,
  )}${AGENT_ENDPOINT}${v2EndPoint}/${callId}${vendorIdParam}`;

  return axiosInstance
    .patch(urlagentcall, requestBody, {
      headers: {
        ...headers,
      },
      cancelToken,
    })
    .then((response) => {
      log.info("Agent call Patched Successfully");
      return response.headers.date;
    })
    .catch((error) => {
      /* istanbul ignore next */
      if (axios.isCancel(error)) {
        log.error("AgentCallService - cancel request: true");
        throw new UpdateCanceledError();
      }

      log.error("AgentCallService - update error: ", error);
      throw error;
    });
}

export async function updateV3(
  requestBody: AgentCallRequestBodyV3,
  callId: string,
  cancelToken: CancelToken,
  headers = {},
): Promise<string> {
  const log = useLogService();

  const urlagentcall = `${getApiHost(
    AGENT_CALL_SERVICE,
  )}/v3/agent-calls/${callId}`;

  return axiosInstance
    .patch(urlagentcall, requestBody, {
      headers,
      cancelToken,
    })
    .then((response) => {
      log.info("Agent call Patched V3 Successfully");
      return response.headers.date;
    })
    .catch((error) => {
      /* istanbul ignore next */
      if (axios.isCancel(error)) {
        log.error("AgentCallService - cancel request: true");
        throw new UpdateCanceledError();
      }

      log.error("AgentCallService - update error: ", error);
      throw error;
    });
}
