import { Product } from "../../../../domains/products/Product";

interface Props {
  isTooltipDisabled: () => boolean;
  handleTitle: () => string;
}

const useTooltipRules = (product: Product): Props => {
  const { isCombo } = product;

  const handleTitle = () => {
    let title = "";

    if (product.comboDetails !== undefined && product.comboDetails.length) {
      product.comboDetails.forEach((combo, index) => {
        if (index === 0) {
          title += combo.name;
        } else {
          title += ` + ${combo.name}`;
        }
      });

      return title;
    }

    if (product.name ?? product.productName)
      title += product.name ?? product.productName;
    if (product.fullPackageDescription)
      title += ` • ${product.fullPackageDescription} `;
    if (product.fullContainerDescription)
      title += ` • ${product.fullContainerDescription} `;

    return title;
  };

  const isTooltipDisabled = () => {
    return !isCombo && handleTitle().length < 50;
  };

  return {
    handleTitle,
    isTooltipDisabled,
  };
};

export default useTooltipRules;
