import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useTabsStyle from "./style";
import {
  TLP_SERVICE_MISSION_SCRIPT,
  GROW_TECH_LOCAL_TO_CAMPAIGN_POC_ACCEPTANCE,
  isFeatureEnabledV2,
  TLP_SERVICE_CATALOG_COMPONENT,
  GROW_AGENT_UNLINK_TOPLINE_OF_SUGGESTED_ORDER,
} from "../../../../config/featureToggles";
import GlobalStore from "../../../../stores/global/GlobalStore";
import MissionTopLineGapIcon from "../../../../assets/newIcons/missions/ToplineGap";
import MissionQuickOrderIcon from "../../../../assets/newIcons/missions/QuickOrder";
import MissionMarketplaceIcon from "../../../../assets/newIcons/missions/MarketPlace";
import MissionsSponsoredIcon from "../../../../assets/newIcons/missions/cards/Sponsored";
import MissionsServiceIcon from "../../../../assets/newIcons/missions/cards/Services";
import MissionRewardsIcon from "../../../../assets/newIcons/missions/NewRewards";
import MissionCampaignsIcon from "../../../../assets/newIcons/missions/Campaigns";
import RewardsTabChallengesIcon from "../../../../assets/newIcons/missions/RewardsTabChallengesIcon";
import MissionInformation from "./missionInformation/MissionInformation";
import GapAndOrderComponent from "../gapAndOrderComponent/GapAndOrderComponent";
import RewardsChallengesComponent from "../RewardsChallengesComponent/RewardsChallengesComponent";
import MarketplaceComponent from "../marketplaceComponent/MarketplaceComponent";
import CampaignsComponent from "../campaignsComponent/CampaignsComponent";
import RewardsComponent from "../rewardsComponent/RewardsComponent";
import GeneralComponent from "../generalComponent/GeneralComponent";
import MissionsDescription from "./missionsDescription/MissionsDescription";
import SponsoredComponent from "../sponsoredComponent/SponsoredComponent";
import ScriptComponent from "../scriptComponent/ScriptComponent";
import ServiceComponent from "../ServiceCatalogComponent/ServiceCatalogComponent";
import LocalMissions from "../../../../assets/newIcons/missions/cards/LocalMissions";
import LocalMissionsComponent from "../localMissionsComponent/LocalMissionsComponent";
import GapBoard from "../header/gapBoard/GapBoard";

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  value: string;
  index: string;
}

export enum TABSLIST {
  GENERAL = "general",
  TOPLINE_GAP = "toplineGap",
  QUICK_ORDER = "quickOrder",
  MARKETPLACE = "marketplace",
  MARKETPLACE_ACTIVATION = "marketplaceactivation",
  REWARDS = "rewards",
  REWARDS_REDEMPTIONS = "rewardsRedemption",
  REWARDS_AND_REDEMPTIONS = "rewardsAndRedemption",
  CAMPAIGNS = "campaigns",
  SPONSORED = "sponsored",
  SERVICE = "service",
  LOCAL_MISSIONS = "localMissions",
}

const { user } = GlobalStore.getState();

export function TabPanel(props: Readonly<TabPanelProps>): React.ReactElement {
  const classes = useTabsStyle();

  const { children, ...other } = props;
  return (
    <div role="tabpanel" {...other} className={classes.tabPanel}>
      <Box style={{ padding: "0px 5px" }}>{children}</Box>
    </div>
  );
}

const getMarketPlaceTab = (tag: string) => {
  return (
    <TabPanel value={tag} index={tag}>
      <MissionInformation
        Icon={MissionMarketplaceIcon}
        title="MISSIONS.marketplace"
        description={
          <MissionsDescription description="MissionsDescription.MARKETPLACE_DESCRIPTION.INFO_TEXT" />
        }
      />
      <MarketplaceComponent />
    </TabPanel>
  );
};

const getSponsoredTab = (tag: string) => (
  <TabPanel value={tag} index={tag}>
    <MissionInformation
      Icon={MissionsSponsoredIcon}
      title="MISSIONS.sponsored"
      description="MissionsDescription.SPONSORED_DESCRIPTION"
    />
    <SponsoredComponent />
  </TabPanel>
);

const getLocalMissionsTab = (tag: string) => {
  const { user } = GlobalStore.getState();
  const isLocalToCampaignsEnabled = isFeatureEnabledV2(
    GROW_TECH_LOCAL_TO_CAMPAIGN_POC_ACCEPTANCE,
    user.keyToggle,
  );
  return (
    <TabPanel value={tag} index={tag}>
      <MissionInformation
        Icon={LocalMissions}
        title={
          isLocalToCampaignsEnabled
            ? "MISSIONS.campaigns"
            : "MISSIONS.localMissions"
        }
        description="MissionsDescription.LOCAL_MISSIONS_DESCRIPTION"
      />
      <LocalMissionsComponent />
    </TabPanel>
  );
};

const RewardsDescriptionChallengeRedemption: React.FC = () => {
  const { t } = useTranslation();
  const classes = useTabsStyle();

  return (
    <div className={classes.tabRewards}>
      <div className={classes.descriptionContainer}>
        <Typography className={classes.description}>
          <b className={classes.descriptionTitle}>
            {t("MissionsDescription.REWARDS_REDEMPTION_DESCRIPTION_TITLE")}
          </b>
          &nbsp;
          {t("MissionsDescription.REWARDS_REDEMPTION_DESCRIPTION")}
        </Typography>
      </div>
      <div className={classes.descriptionContainer}>
        <Typography className={classes.description}>
          <b className={classes.descriptionTitle}>
            {t("MissionsDescription.REWARDS_CHALLENGES_DESCRIPTION_TITLE")}
          </b>
          &nbsp;
          {t("MissionsDescription.REWARDS_CHALLENGES_DESCRIPTION")}
        </Typography>
      </div>
    </div>
  );
};

const getDescription = new Map<string, JSX.Element>([
  [
    TABSLIST.REWARDS,
    <MissionsDescription
      key="MissionsDescription.REWARDS_CHALLENGES_DESCRIPTION_TITLE"
      boldText="MissionsDescription.REWARDS_CHALLENGES_DESCRIPTION_TITLE"
      description="MissionsDescription.REWARDS_CHALLENGES_DESCRIPTION"
    />,
  ],
  [
    TABSLIST.REWARDS_REDEMPTIONS,
    <MissionsDescription
      key="MissionsDescription.REWARDS_REDEMPTION_DESCRIPTION_TITLE"
      boldText="MissionsDescription.REWARDS_REDEMPTION_DESCRIPTION_TITLE"
      description="MissionsDescription.REWARDS_REDEMPTION_DESCRIPTION"
    />,
  ],
  [
    TABSLIST.REWARDS_AND_REDEMPTIONS,
    <RewardsDescriptionChallengeRedemption key="MissionsDescription.REWARDS_REDEMPTION_DESCRIPTION_TITLE" />,
  ],
]);

const getRewardsTab = (tag: string) => {
  if (tag === "rewards") {
    return (
      <TabPanel value={tag} index={tag}>
        <MissionInformation
          Icon={RewardsTabChallengesIcon}
          title="MISSIONS.CHALLENGES"
          description="MissionsDescription.REWARDS_CHALLENGES_DESCRIPTION"
        />
        <RewardsChallengesComponent />
      </TabPanel>
    );
  }
  return (
    <TabPanel value={tag} index={tag}>
      <MissionInformation
        Icon={MissionRewardsIcon}
        title="MISSIONS.REDEMPTION"
        description={getDescription.get(tag)}
      />
      <RewardsComponent />
    </TabPanel>
  );
};

export const tabs: { [key: string]: (tag: string) => JSX.Element } = {
  general: (tag: string) => (
    <TabPanel value={tag} index={tag}>
      <GeneralComponent />
    </TabPanel>
  ),

  toplineGap: (tag: string) => {
    const isUnlinkToplineOfSuggestedOrder = isFeatureEnabledV2(
      GROW_AGENT_UNLINK_TOPLINE_OF_SUGGESTED_ORDER,
      user.keyToggle,
    );

    return (
      <TabPanel value={tag} index={tag}>
        <MissionInformation
          Icon={MissionTopLineGapIcon}
          title="MISSIONS.toplineGap"
          description="MissionsDescription.TOPLINE_GAP_DESCRIPTION"
        />
        {isUnlinkToplineOfSuggestedOrder && (
          <div style={{ paddingBottom: 16 }}>
            <GapBoard />
          </div>
        )}
        <GapAndOrderComponent />
      </TabPanel>
    );
  },

  quickOrder: (tag: string) => (
    <TabPanel value={tag} index={tag}>
      <MissionInformation
        Icon={MissionQuickOrderIcon}
        title="MISSIONS.quickOrder"
        description="MissionsDescription.QUICK_ORDER_DESCRIPTION"
      />
    </TabPanel>
  ),

  sponsored: (tag) => getSponsoredTab(tag),

  localMissions: (tag) => getLocalMissionsTab(tag),

  service: (tag: string) => {
    const isServiceMissionScriptEnabled = isFeatureEnabledV2(
      TLP_SERVICE_MISSION_SCRIPT,
      user.keyToggle,
    );

    const isServiceMissionCatalogEnabled = isFeatureEnabledV2(
      TLP_SERVICE_CATALOG_COMPONENT,
      user.keyToggle,
    );

    return (
      <TabPanel value={tag} index={tag}>
        <MissionInformation
          Icon={MissionsServiceIcon}
          title="MISSIONS.service"
          description="MissionsDescription.SERVICE_DESCRIPTION"
          style={{ marginBottom: "0px" }}
        />

        {isServiceMissionScriptEnabled && <ScriptComponent />}

        {isServiceMissionCatalogEnabled && <ServiceComponent />}
      </TabPanel>
    );
  },

  marketplace: (tag) => getMarketPlaceTab(tag),

  marketplaceactivation: (tag) => getMarketPlaceTab(tag),

  rewards: (tag: string) => getRewardsTab(tag),

  rewardsRedemption: (tag: string) => getRewardsTab(tag),

  rewardsAndRedemption: (tag: string) => getRewardsTab(tag),

  campaigns: (tag: string) => (
    <TabPanel value={tag} index={tag}>
      <MissionInformation
        Icon={MissionCampaignsIcon}
        title="MISSIONS.campaigns"
        description="MissionsDescription.CAMPAIGNS_DESCRIPTION"
      />
      <CampaignsComponent />
    </TabPanel>
  ),
};
