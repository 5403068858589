import SuggestedOrdersResponseToSuggestedOrdersConverterV2 from "@converters/suggestedOrders/SuggestedOrdersResponseToSuggestedOrdersConverterV2";
import SuggestedOrdersDomainV2 from "@domains/suggestedOrders/SuggestedOrdersDomainV2";
import {
  useAuthenticationService,
  useLogService,
} from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { v4 as guid } from "uuid";
import axios from "../../config/axios/axiosInstance";
import {
  GROW_AGENT_UNLINK_TOPLINE_OF_SUGGESTED_ORDER,
  TLP_MULTICONTRACT_CALL_LIST_SERVICE,
  isFeatureEnabledV2,
} from "../../config/featureToggles";
import { CALL_LIST_SERVICE, GROW_BFF_SERVICE } from "../../config/services";
import SuggestedOrdersResponseToSuggestedOrdersConverter from "../../converters/suggestedOrders/SuggestedOrdersResponseToSuggestedOrdersConverter";
import SuggestedOrderDomain from "../../domains/suggestedOrders/SuggestedOrdersDomain";
import GlobalStore from "../../stores/global/GlobalStore";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../host/HostService";
import SuggestedOrderResponse from "./domains/SuggestedOrderResponse";
import { SuggestedOrderResponseV2 } from "./domains/SuggestedOrderResponseV2";

export const CALL_LIST_ENDPOINT = `/call-list`;
export const ACCOUNTS_ENDPOINT = `/accounts`;
export const SUGGESTED_ORDER_ENDPOINT = "suggested-order";
export const RECOMENDATION_ENDPOINT = "recommendations";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
  retryCondition: (error) => {
    if (error) {
      return error.response?.status === 400 || error.response?.status === 401;
    }

    return false;
  },
});

export function getSuggestedOrders(
  accountId: string,
  country: string,
  vendorId?: string,
  vendorName?: string,
): Promise<SuggestedOrderDomain | SuggestedOrdersDomainV2> {
  const { user } = GlobalStore.getState();
  const log = useLogService();
  const token = useAuthenticationService().getAuthHeader();

  const isCallListV2Enabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_CALL_LIST_SERVICE,
    user.zone,
  );

  const isUnlinkToplineOfSuggestedOrder = isFeatureEnabledV2(
    GROW_AGENT_UNLINK_TOPLINE_OF_SUGGESTED_ORDER,
  );

  const v2EndPoint = getV2EndPoint(isCallListV2Enabled);
  const vendorIdParam = getVendorIdParam(vendorId, isCallListV2Enabled);

  const baseUrl = `${getApiHost(CALL_LIST_SERVICE)}${CALL_LIST_ENDPOINT}`;

  const endpoint = `${v2EndPoint}${ACCOUNTS_ENDPOINT}/${accountId}/${SUGGESTED_ORDER_ENDPOINT}${vendorIdParam}`;

  const queryParams = {
    useCase: "QUICK_ORDER",
  };

  let urltlp = `${baseUrl}${endpoint}`;

  if (isUnlinkToplineOfSuggestedOrder) {
    urltlp = `${getApiHost(
      GROW_BFF_SERVICE,
    )}/v1${ACCOUNTS_ENDPOINT}/${accountId}/${RECOMENDATION_ENDPOINT}`;
  }

  return axios
    .get<Array<SuggestedOrderResponse | SuggestedOrderResponseV2>>(urltlp, {
      headers: {
        country,
        Authorization: token,
        requestTraceId: guid(),
        vendorId,
        vendorName,
      },
      params: isUnlinkToplineOfSuggestedOrder ? queryParams : {},
    })
    .then((response) => {
      log.info(
        `Convert SuggestedOrder score received from ${urltlp} to our model`,
      );
      if (isUnlinkToplineOfSuggestedOrder) {
        return SuggestedOrdersResponseToSuggestedOrdersConverterV2(
          response.data as SuggestedOrderResponseV2[],
        );
      }
      return SuggestedOrdersResponseToSuggestedOrdersConverter(
        response.data as SuggestedOrderResponse[],
      );
    })
    .catch((error) => {
      log.error("SuggestedOrdersService - getSuggestedOrders error: ", error);
      throw error;
    });
}
