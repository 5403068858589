import React from "react";

function CheckWithDot(): JSX.Element {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <circle id="path-1-check-dot" cx="32" cy="32" r="32"></circle>
        <path
          d="M29.1914337,0.524767017 C29.891123,-0.174922339 31.0255436,-0.174922339 31.725233,0.524767017 C32.3711001,1.17063411 32.4207822,2.18692309 31.8742792,2.88977817 L31.725233,3.05856632 L12.0168996,22.7668996 C11.3710326,23.4127667 10.3547436,23.4624488 9.6518885,22.9159459 L9.48310035,22.7668996 L0.524767017,13.8085663 C-0.174922339,13.108877 -0.174922339,11.9744564 0.524767017,11.274767 C1.17063411,10.6288999 2.18692309,10.5792178 2.88977817,11.1257208 L3.05856632,11.274767 L10.75,18.9647917 L29.1914337,0.524767017 Z"
          id="path-3"
        ></path>
      </defs>
      <g
        id="___Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Checkmark">
          <g id="ZZ_Color-Override/Primary/Black">
            <mask id="mask-2-check-dot" fill="white">
              <use xlinkHref="#path-1-check-dot"></use>
            </mask>
            <g id="Mask"></g>
            <g
              id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
              mask="url(#mask-2-check-dot)"
              fill="#81FFAD"
            >
              <rect id="Rectangle" x="0" y="0" width="64" height="64"></rect>
            </g>
          </g>
          <g id="Group-5" transform="translate(11.000000, 11.000000)">
            <g
              id="ZZ_Color-Override/Primary/Black"
              transform="translate(5.375000, 10.078125)"
            >
              <mask id="mask-4" fill="white">
                <use xlinkHref="#path-3"></use>
              </mask>
              <use
                id="Mask"
                fill="#000000"
                fill-rule="nonzero"
                xlinkHref="#path-3"
              ></use>
              <g
                id="ZZ_Color-Override/01_Primary/BEESBlack"
                mask="url(#mask-4)"
                fill="#000000"
              >
                <g transform="translate(-5.375000, -10.078125)" id="Rectangle">
                  <rect x="0" y="0" width="43" height="43"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default CheckWithDot;
