import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      padding: "5px 3px 5px 5px",
      display: "grid",
      gridTemplateColumns: "auto 1fr auto auto",
    },
    noStyle: {
      padding: "0px",
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    quantityContainer: {
      width: "56px",
      height: "25px",
      borderRadius: "3px",
      border: "1px solid #505050",
    },
    title: {
      display: "grid",
      whiteSpace: "nowrap",
      fontSize: "10px",
      color: palette.text.primary,
      padding: "0px 5px 0px 7px",
    },
    skuTitle: {
      fontSize: "12px",
      color: palette.text.secondary,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left",
      lineHeight: 1,
    },
    cartItemPrice: {
      fontSize: "10px",
      textAlign: "right",
      color: "#525252",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      height: "100%",
      padding: "0px 5px 0px 5px",
      display: "flex",
      justifyContent: "flex-end",
    },
    cartItemNotFound: {
      fontSize: "10px",
      fontWeight: 500,
      color: palette.error.main,
      marginRight: "5px",
    },
    price: {
      textAlign: "right",
      color: palette.text.primary,
      fontWeight: 600,
      fontSize: 12,
    },
    disabledLabel: {
      opacity: "30%",
    },
    deleteButtonContainer: {
      padding: "0px 10px",
    },
    deleteButton: {
      color: "#ccc",
    },
    inputQuantity: {
      width: "100%",
      contain: "content",
      display: "flex",
      borderRadius: "3px",
      flexDirection: "row-reverse",
      "&& input": {
        height: "23px",
        width: "100%",
        border: "none",
        paddingLeft: "8px",
        outline: "none",
      },
      "& .rc-input-number-handler-wrap": {
        backgroundColor: "white",
        height: "23px",
        width: "100%",
        display: "flex",
        padding: "2px",
        flexDirection: "column",
        justifyContent: "center",
        "& span": {
          cursor: "pointer",
          justifyContent: "center",
          height: "10px",
          width: "100%",
          display: "flex",
          alignItems: "center",
        },
      },
    },
    stockoutWarning: {
      fontSize: "10px",
      color: "#b1b1b1",

      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "right",
    },
    itemNameEllipsis: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: 12,
      color: palette.text.primary,
      fontWeight: "normal",
      lineHeight: 1.2,
    },
  }),
);
