import React from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import RewardsTabChallengesIcon from "../../../../assets/newIcons/missions/RewardsTabChallengesIcon";
import MissionTopLineGapIcon from "../../../../assets/newIcons/missions/ToplineGap";
import MissionQuickOrderIcon from "../../../../assets/newIcons/missions/cards/QuickOrder";
import MissionMarketplaceIcon from "../../../../assets/newIcons/missions/cards/MarketPlace";
import MissionRewardsNewIcon from "../../../../assets/newIcons/missions/NewRewards";
import SponsoredIcon from "../../../../assets/newIcons/missions/cards/Sponsored";
import ServicesIcon from "../../../../assets/newIcons/missions/cards/Services";
import Campaigns from "../../../../assets/newIcons/missions/Campaigns";
import LocalMissions from "../../../../assets/newIcons/missions/cards/LocalMissions";
import {
  GROW_TECH_LOCAL_TO_CAMPAIGN_POC_ACCEPTANCE,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";
import GlobalStore from "../../../../stores/global/GlobalStore";

export interface CardProps {
  index: number;
  title: string;
  tooltip: string;
  tag?: string;
  svg: JSX.Element;
}

interface Props {
  toplineObject: CardProps;
  promoteAlgoSellingObject: CardProps;
  promoteRewardsRedemptionObject: CardProps;
  promoteRewardsChallengesObject: CardProps;
  promoteMarketPlaceObject: CardProps;
  b2oObject: CardProps;
  sponsoredObject: CardProps;
  servicesObject: CardProps;
  localMissionsObject: CardProps;
}

const useMissionsCard = (): Props => {
  const { t } = useTranslation();
  const {
    user: { keyToggle },
  } = useStore(GlobalStore);

  const isLocalToCampaignsEnabled = isFeatureEnabledV2(
    GROW_TECH_LOCAL_TO_CAMPAIGN_POC_ACCEPTANCE,
    keyToggle,
  );

  const toplineObject: CardProps = {
    index: 1,
    title: t("MISSIONS.CARD.toplineGap.title"),
    tooltip: t("MISSIONS.CARD.toplineGap.tooltip"),
    tag: t("MISSIONS.CARD.toplineGap.tag"),
    svg: <MissionTopLineGapIcon />,
  };

  const promoteAlgoSellingObject: CardProps = {
    index: 2,
    title: t("MISSIONS.CARD.quickOrder.title"),
    tooltip: t("MISSIONS.CARD.quickOrder.tooltip"),
    tag: t("MISSIONS.CARD.quickOrder.tag"),
    svg: <MissionQuickOrderIcon />,
  };

  const promoteRewardsRedemptionObject: CardProps = {
    index: 3,
    title: t("MISSIONS.CARD.rewardsRedemption.title"),
    tooltip: t("MISSIONS.CARD.rewardsRedemption.tooltip"),
    tag: t("MISSIONS.CARD.rewardsRedemption.tag"),
    svg: <MissionRewardsNewIcon />,
  };

  const promoteRewardsChallengesObject: CardProps = {
    index: 4,
    title: t("MISSIONS.CARD.rewardsChallenges.title"),
    tooltip: t("MISSIONS.CARD.rewardsChallenges.tooltip"),
    tag: t("MISSIONS.CARD.rewardsChallenges.tag"),
    svg: <RewardsTabChallengesIcon />,
  };

  const promoteMarketPlaceObject: CardProps = {
    index: 5,
    title: t("MISSIONS.CARD.marketplace.title"),
    tooltip: t("MISSIONS.CARD.marketplace.tooltip"),
    tag: t("MISSIONS.CARD.marketplace.tag"),
    svg: <MissionMarketplaceIcon />,
  };

  const b2oObject: CardProps = {
    index: 6,
    title: t("MISSIONS.CARD.b2o.title"),
    tooltip: t("MISSIONS.CARD.b2o.tooltip"),
    tag: t("MISSIONS.CARD.b2o.tag"),
    svg: <Campaigns />,
  };

  const sponsoredObject: CardProps = {
    index: 7,
    title: t("MISSIONS.CARD.sponsored.title"),
    tooltip: t("MISSIONS.CARD.sponsored.tooltip"),
    svg: <SponsoredIcon />,
  };

  const servicesObject: CardProps = {
    index: 8,
    title: t("MISSIONS.CARD.services.title"),
    tooltip: t("MISSIONS.CARD.services.tooltip"),
    tag: t("MISSIONS.CARD.services.tag"),
    svg: <ServicesIcon />,
  };

  const localMissionsObject: CardProps = {
    index: 10,
    title: t(
      isLocalToCampaignsEnabled
        ? "MISSIONS.CARD.campaigns.title"
        : "MISSIONS.CARD.localMissions.title",
    ),
    tooltip: t(
      isLocalToCampaignsEnabled
        ? "MISSIONS.CARD.campaigns.tooltip"
        : "MISSIONS.CARD.localMissions.tooltip",
    ),
    svg: <LocalMissions />,
  };

  return {
    toplineObject,
    promoteAlgoSellingObject,
    promoteRewardsRedemptionObject,
    promoteRewardsChallengesObject,
    promoteMarketPlaceObject,
    b2oObject,
    sponsoredObject,
    servicesObject,
    localMissionsObject,
  };
};

export default useMissionsCard;
