import React, { useEffect, useRef, useState, useCallback } from "react";
import { useStore } from "effector-react";
import SearchOfferingsBar, {
  SEARCH_OFFERINGS_METHOD,
} from "../../../../../components/searchBar/SearchOfferingsBar";
import PromotionsStore from "../../../../../stores/promotions/PromotionsStore";
import { onSearch } from "../../../../../stores/promotions/PromotionsEvents";
import {
  offeringsSearchInteraction,
  promotionsListLoaded,
} from "../../../../../config/typewriter";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import { Combo } from "../../../../../domains/promotions/Combo";
import { Promotions } from "../../../../../domains/promotions/Promotions";

export enum SEARCH_BUTTON_LABEL {
  SEARCH_FIELD = "search_field",
  GO_BUTTON = "go_button",
}

interface SearchResults {
  combos: Array<Combo>;
  promotions: Array<Promotions>;
}

const Search: React.FC = () => {
  const promotionStates = useStore(PromotionsStore);
  const [value, setValue] = useState("");
  const { dispatchGenericEvent, dispatchPocEvent } = useAnalytics();

  const hasSkuOrName = (
    name: string,
    sku: string,
    comboId: string,
    searchText: string,
  ): boolean => {
    const textToLowerCase = searchText.toLowerCase();
    return (
      (sku.toLowerCase().includes(textToLowerCase) ||
        name.toLowerCase().includes(textToLowerCase) ||
        comboId.toLowerCase().includes(textToLowerCase)) &&
      textToLowerCase !== ""
    );
  };

  const searchByNameOrSku = useCallback(
    (searchMethod: string) => {
      const analyticsData = {
        search_query: value,
        list_quantity_combo: 0,
        list_quantity_promo: 0,
      };

      if (value) {
        const combosResult = promotionStates.combos.filter((combo) => {
          return (
            combo.items.filter((comboItem) =>
              hasSkuOrName(comboItem.title, comboItem.sku, combo.id, value),
            ).length > 0
          );
        });

        const searchResult = promotionStates.promotions.filter((promotion) => {
          return hasSkuOrName(
            promotion.itemName,
            promotion.sku,
            promotion.id,
            value,
          );
        });

        onSearch({
          searchText: value,
          combos: combosResult,
          promotions: searchResult,
        });

        analyticsData.list_quantity_combo = combosResult.length;
        analyticsData.list_quantity_promo = searchResult.length;
      } else {
        onSearch({
          searchText: value,
          combos: promotionStates.combos,
          promotions: promotionStates.promotions,
        });

        analyticsData.list_quantity_combo = promotionStates.combos.length;
        analyticsData.list_quantity_promo = promotionStates.promotions.length;
      }
      if (value !== "") {
        dispatchGenericEvent(offeringsSearchInteraction, {
          button_label:
            searchMethod === SEARCH_OFFERINGS_METHOD.ENTER_KEY
              ? SEARCH_OFFERINGS_METHOD.SEARCH_FIELD
              : SEARCH_OFFERINGS_METHOD.GO_BUTTON,
          search_query: value,
          screen_section: "Promotions",
          filter_option: "",
        });

        dispatchPocEvent(promotionsListLoaded, analyticsData, {
          removeProps: [
            "DDC",
            "total_gap",
            "total_vol_gap",
            "credit_available",
            "poc_potential",
            "poc_recurrence",
          ],
        });
      }
    },
    [
      dispatchGenericEvent,
      dispatchPocEvent,
      promotionStates.combos,
      promotionStates.promotions,
      value,
    ],
  );

  const searchResultsRef = useRef<SearchResults>({
    combos: [],
    promotions: [],
  });
  searchResultsRef.current = { ...promotionStates.searchResults };

  useEffect(() => {
    if (!promotionStates.searchText) {
      onSearch({
        searchText: "",
        combos: promotionStates.combos,
        promotions: promotionStates.promotions,
      });
    } else {
      setValue(promotionStates.searchText);
    }
  }, [
    dispatchPocEvent,
    promotionStates.combos,
    promotionStates.promotions,
    promotionStates.searchText,
  ]);

  return (
    <div data-testid="promotions-search-section">
      <SearchOfferingsBar
        value={value}
        handleSearchValue={setValue}
        onSearch={searchByNameOrSku}
        testId="promotions-search"
        isPromotions
      />
    </div>
  );
};

export default Search;
