import { createStore } from "effector";
import { CampaignsState } from "./CampaignsState";
import {
  getCampaigns,
  resetCampaign,
  setValidCampaigns,
} from "./CampaignsEvents";

const initialState = <CampaignsState>{
  campaigns: [],
  focus: [],
  validCampaigns: [],
  loading: false,
  error: false,
  done: false,
};

const CampaignsStore = createStore(initialState)
  .on(getCampaigns.done, (state, value) => {
    return {
      ...state,
      campaigns: value.result.campaigns,
      focus: value.result.focus,
      loading: false,
      error: false,
      done: true,
    };
  })
  .on(getCampaigns.fail, (state) => {
    return {
      ...state,
      loading: false,
      error: true,
      done: true,
    };
  })
  .on(getCampaigns, (state) => {
    return {
      ...state,
      loading: true,
      error: false,
      done: false,
    };
  })
  .on(
    setValidCampaigns,
    (state: CampaignsState, validCampaigns: Array<string>) => {
      return {
        ...state,
        validCampaigns,
      };
    },
  )
  .reset(resetCampaign);

export default CampaignsStore;
