import axios, { CancelTokenSource } from "axios";

import { CartInfo } from "../../../domains/cart/CartInfo";
import * as UpdateCartSimulationService from "../../../services/cart/updateCartSimulation/UpdateCartSimulationService";
import AgentCallState from "../../../stores/agentCall/AgentCallState";
import CartItemState from "../../../stores/cart/CartItemState";
import CartState from "../../../stores/cart/CartState";
import CartComboState from "../../../stores/cartCombo/CartComboState";

let debounceUpdateSimulation: NodeJS.Timeout;
let cancelLastRequest: CancelTokenSource;

export function execute(
  agentCallState: AgentCallState,
  cartState: CartState,
  cartComboState: CartComboState,
  cartItemsState: CartItemState,
): Promise<CartInfo | null> {
  if (isCartEmpty(cartItemsState, cartComboState)) {
    return Promise.resolve(null);
  }

  if (debounceUpdateSimulation) {
    clearTimeout(debounceUpdateSimulation);
  }
  if (cancelLastRequest) {
    cancelLastRequest.cancel();
  }

  return new Promise((resolve) => {
    debounceUpdateSimulation = setTimeout(() => {
      cancelLastRequest = axios.CancelToken.source();
      resolve(
        UpdateCartSimulationService.UpdateCartSimulation(
          agentCallState,
          cartState,
          cartComboState,
          cartItemsState,
          cancelLastRequest.token,
        ),
      );
    }, 1000);
  });
}

function isCartEmpty(cartItemsState, cartComboState) {
  const isUndefinedOrEmpty = (array) =>
    array === undefined || array.length === 0;

  return (
    isUndefinedOrEmpty(cartItemsState.cartItems) &&
    isUndefinedOrEmpty(cartComboState.cartComboItems) &&
    isUndefinedOrEmpty(cartItemsState.empties)
  );
}
