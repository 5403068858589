import React from "react";
import { FormikErrors, FormikTouched } from "formik";
import { useStore } from "effector-react";
import {
  TLP_CALL_SUMMARY_REASONS_LIST,
  isFeatureEnabledV2,
} from "../../../../../../../config/featureToggles";
import GenericMissions from "../genericMissions/GenericMissions";
import {
  Mission,
  ChallengesReasonOptions,
  ChallengesReasonReviewedOptions,
} from "../../../../../../../domains/Mission";
import GlobalStore from "../../../../../../../stores/global/GlobalStore";

interface TargetValue {
  name?: string;
  value: string;
}

interface Props {
  handleChange: (
    e: React.ChangeEvent<TargetValue>,
    tag: string,
    usage: string,
  ) => void;
  values: Mission;
  errors?: FormikErrors<Mission>;
  touched?: FormikTouched<Mission>;
}

const PromoteRewardsChallenges: React.FunctionComponent<Props> = ({
  handleChange,
  values,
  errors,
  touched,
}) => {
  const {
    user: { zone },
  } = useStore(GlobalStore);

  const isReasonListReviwedEnabled = isFeatureEnabledV2(
    TLP_CALL_SUMMARY_REASONS_LIST,
    zone,
  );

  const isGapEmpty = () => {
    return values.reason === "";
  };

  return (
    <GenericMissions
      values={values}
      handleChange={handleChange}
      errors={errors}
      touched={touched}
      showClassification={false}
      disableGapReason={false}
      gapReason={
        isReasonListReviwedEnabled
          ? ChallengesReasonReviewedOptions
          : ChallengesReasonOptions
      }
      isGapEmpty={isGapEmpty()}
    />
  );
};

export default PromoteRewardsChallenges;
