import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    tabTitle: {
      marginRight: "8px",
      padding: "2px",
      backgroundColor: "white",
      fontSize: "13px",
      fontWeight: 500,
      borderBottom: "2px solid transparent",
      color: palette.secondary.main,
      cursor: "pointer",
      borderRadius: "0 !important",
      fontFamily: "Barlow !important",
    },
    tabTitleActive: {
      borderBottom: `2px solid ${palette.primary.light}`,
    },
    tabTitleContainer: {
      marginBottom: "2px",
    },
  }),
);
