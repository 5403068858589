import { makeStyles, createStyles } from "@material-ui/core";

const CallInAutoDialerButtonStyles = makeStyles(({ palette }) =>
  createStyles({
    callInAutoDialerButton: {
      minWidth: "214px",
      height: "40px",
      borderRadius: "3px",
      display: "flex",
      cursor: "pointer",
      textTransform: "none",
      backgroundColor: palette.secondary.main,
      justifyContent: "space-evenly",
      outlineColor: "black",
      outlineStyle: "solid",
      outlineWidth: "1px",
      whiteSpace: "nowrap",
      gap: "8px",
    },
    label: {
      fontSize: "16px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      fontFamily: "Work Sans",
      fontWeight: 500,
    },
    phoneIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "28px",
      minWidth: "28px",
      height: "28px",
      borderRadius: "100%",
      backgroundColor: "white",
      fontSize: "12px",
    },
  }),
);
export default CallInAutoDialerButtonStyles;
