import { createStore } from "effector";
import * as CreateSearchedPocEvents from "./CreateSearchedPocEvents";
import { CreateSearchedPocState } from "./CreateSearchedPocState";

const initialState = <CreateSearchedPocState>{
  loading: "idle",
  error: false,
  done: false,
};

/* istanbul ignore next */
const createSearchedPocStore = createStore(initialState)
  .on(CreateSearchedPocEvents.createSearchedPocEffect, (state) =>
    Object.assign({}, state, {
      ...state,
      loading: "loading",
      done: false,
      error: false,
    }),
  )
  .on(CreateSearchedPocEvents.createSearchedPocEffect.done, (state) =>
    Object.assign({}, state, {
      ...state,
      loading: "success",
      done: true,
      error: false,
    }),
  )
  .on(CreateSearchedPocEvents.createSearchedPocEffect.fail, (state) =>
    Object.assign({}, state, {
      ...state,
      loading: "error",
      done: false,
      error: true,
    }),
  )
  .on(CreateSearchedPocEvents.resetCreateSearchedPoc, (state) =>
    Object.assign({}, state, {
      ...state,
      loading: "idle",
      done: true,
      error: false,
    }),
  );

export default createSearchedPocStore;
