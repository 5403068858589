/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { useCallback, useMemo, useState } from "react";

import { ListItem, Typography } from "@material-ui/core";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { ListChildComponentProps } from "react-window";

import GlobalStore from "@stores/global/GlobalStore";
import Button from "../../../../../components/button/Button";
import CombinedQuantitySelect from "../../../../../components/combinedQuantitySelect/CombinedQuantitySelect";
import PackageTypeButtons from "../../../../../components/packageTypeButtons/PackageTypeButtons";
import QuantityControl from "../../../../../components/quantityControl/QuantityControl";
import { CURRENCY } from "../../../../../config/constants";
import {
  productAdded,
  productQuantityEdited,
} from "../../../../../config/typewriter";
import { CatalogItem, ProductVariants } from "../../../../../domains/Catalog";
import { useProductCommonsHook } from "../../../../../hooks/useProductCommonsHook";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import pocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import { updateOrAddItem } from "../../../../../stores/cart/CartItemEvents";
import cartItemStore from "../../../../../stores/cart/CartItemStore";
import ProductItemStyles from "./ProductItemV2.styles";
import ImageSection from "./components/ImageSection/ImageSection";
import PriceSection from "./components/PriceSection/PriceSection";
import StockSection from "./components/StockSection/StockSection";
import VariantsSelect from "./components/VariantsSelect/VariantsSelect";

interface ProductItemV2Props {
  productItem: CatalogItem;
  childComponentProps: ListChildComponentProps;
}

const ProductItemV2: React.FunctionComponent<ProductItemV2Props> = ({
  productItem,
  childComponentProps,
}) => {
  const classes = ProductItemStyles();
  const { t } = useTranslation();

  const AgentCallStates = useStore(AgentCallStore);
  const GlobalStates = useStore(GlobalStore);
  const { pocInformation } = useStore(pocInformationStore);
  const { deleteIsLoading } = useStore(cartItemStore);

  const { getProductsWithVariants, shouldShowQuantitySelector } =
    useProductCommonsHook();

  const allProducts = getProductsWithVariants(productItem);

  const [selectedVariant, setSelectedVariant] = useState<ProductVariants>(
    allProducts[0],
  );

  const [itemQuantity, setItemQuantity] = useState(
    selectedVariant.defaultItemQuantity,
  );

  const displayInventoryCountOrAvailabilityCount =
    selectedVariant.availabilityCount ?? selectedVariant.inventoryCount;

  const sortedProductsBySalesRanking = useMemo(() => {
    return [...allProducts].sort((a, b) => a.salesRanking - b.salesRanking);
  }, [allProducts]);

  const nextProductVariation = useMemo(() => {
    const currentIndex = sortedProductsBySalesRanking.findIndex(
      (item) => item.salesRanking === selectedVariant.salesRanking,
    );

    if (currentIndex < sortedProductsBySalesRanking.length - 1) {
      return sortedProductsBySalesRanking[currentIndex + 1];
    }

    return null;
  }, [sortedProductsBySalesRanking, selectedVariant]);

  const setAnalyticsObjectToCart = (value: number) => {
    return {
      name: selectedVariant.itemName,
      quantity: value,
      sku: selectedVariant.sku,
      call_id: AgentCallStates.callId,
      country: GlobalStates.user.zone,
      currency: CURRENCY[GlobalStates.user.zone]?.currency,
      original_quantity: selectedVariant.defaultItemQuantity,
      poc_id: AgentCallStates.clientId,
      quantity_per_pallet: selectedVariant.palletQuantity,
      is_out_of_stock: displayInventoryCountOrAvailabilityCount === 0,
    };
  };

  const addToCart = () => {
    const item = {
      ...selectedVariant,
      returnable: false,
      notFound: false,
      segment: "",
      itemQuantity,
      nextUnitOfMeasurement: nextProductVariation?.unitOfMeasurement,
      total:
        (selectedVariant.discountedPrice ?? selectedVariant.price) *
        itemQuantity,
    };

    updateOrAddItem({
      ...item,
      maxVehicleCapacity: pocInformation.maxVehicleCapacity,
    });
    productAdded(setAnalyticsObjectToCart(itemQuantity));
  };

  const setAnalyticsObjectToProductQuantity = (value: number) => {
    return {
      call_id: AgentCallStates.callId,
      country: GlobalStates.user.zone,
      currency: CURRENCY[GlobalStates.user.zone]?.currency,
      original_quantity: selectedVariant.defaultItemQuantity,
      poc_id: AgentCallStates.clientId,
      quantity: value,
      is_out_of_stock: displayInventoryCountOrAvailabilityCount === 0,
      combo_id: null,
      is_combo: false,
      edit_method: "Arrow or Input",
    };
  };

  const changeQuantity = (value: number, maxOrderQuantity?: number) => {
    if (
      maxOrderQuantity &&
      nextProductVariation &&
      value === maxOrderQuantity
    ) {
      handleChangeVariant(nextProductVariation.platformId);
    } else {
      setItemQuantity(value);
    }

    productQuantityEdited({
      ...setAnalyticsObjectToProductQuantity(value),
      screen_section: "Product Search Results",
    });
  };

  const handleChangeVariant = (platformId: string) => {
    const variantToChange =
      allProducts.find((item) => item.platformId === platformId) ??
      selectedVariant;

    setSelectedVariant(variantToChange);
    setItemQuantity(variantToChange.defaultItemQuantity);
  };

  const renderVariantSelection = useCallback(() => {
    let component: null | JSX.Element = null;

    if (allProducts.length > 3) {
      component = (
        <div className={classes.variantsSelectContainer}>
          <VariantsSelect
            sortedProductsBySalesRanking={sortedProductsBySalesRanking}
            selectedVariant={selectedVariant}
            handleChangeVariant={handleChangeVariant}
          />
        </div>
      );
    } else if (productItem.variants) {
      component = (
        <div className={classes.packType}>
          <PackageTypeButtons
            sortedProductsBySalesRanking={sortedProductsBySalesRanking}
            packageTypeValue={selectedVariant.packageId}
            onClick={handleChangeVariant}
          />
        </div>
      );
    }

    return component;
  }, [
    allProducts,
    productItem.variants,
    sortedProductsBySalesRanking,
    selectedVariant,
    handleChangeVariant,
  ]);

  return (
    <ListItem
      className={classes.listItem}
      style={{
        ...childComponentProps.style,
      }}
      data-testid="list-item"
    >
      <div className={classes.container}>
        <div className={classes.contentFlex}>
          <ImageSection productItem={productItem} />
          <div className={classes.infoContainer}>
            <div className={classes.productDetails}>
              <Typography
                className={classes.productName}
                variant="h6"
                title={selectedVariant.itemName}
                data-testid="product-item-name"
              >
                {selectedVariant.itemName}
              </Typography>
              <div className={classes.additionalInfo}>
                <Typography
                  className={classes.productSku}
                  data-testid="product-sku"
                >
                  {selectedVariant.sku ?? ""}
                </Typography>

                <Typography
                  className={classes.fullPackageDescription}
                  data-testid="full-package-description"
                >
                  {selectedVariant.fullPackageDescription ?? ""}
                </Typography>
              </div>
            </div>

            {renderVariantSelection()}

            {selectedVariant.discountedPrice ? (
              <PriceSection
                price={selectedVariant.discountedPrice * itemQuantity}
                olderPrice={selectedVariant.price * itemQuantity}
                unitPrice={selectedVariant.skuPrice}
              />
            ) : (
              <PriceSection
                price={selectedVariant.price * itemQuantity}
                unitPrice={selectedVariant.skuPrice}
              />
            )}
          </div>
        </div>

        <div className={classes.quantityContainer}>
          <div className={classes.quantity}>
            {shouldShowQuantitySelector(
              selectedVariant.maxOrderQuantity,
              selectedVariant.quantityMultiplier,
            ) ? (
              <CombinedQuantitySelect
                selectedQuantity={itemQuantity}
                maxOrderQuantity={selectedVariant.maxOrderQuantity!}
                multiplierQuantity={selectedVariant.quantityMultiplier}
                unitOfMeasurement={nextProductVariation?.unitOfMeasurement}
                testId={`product-item-select-${selectedVariant.sku}`}
                onChange={(value) => {
                  changeQuantity(
                    Number(value),
                    selectedVariant.maxOrderQuantity,
                  );
                }}
              />
            ) : (
              <QuantityControl
                value={itemQuantity}
                onChange={(value) => {
                  changeQuantity(
                    Number(value),
                    selectedVariant.maxOrderQuantity,
                  );
                }}
                testId={`${selectedVariant.platformId}-cart-item-quantity-control`}
                minValue={selectedVariant.quantityMultiplier}
                maxValue={selectedVariant.maxOrderQuantity}
                data-testid={`${selectedVariant.platformId}-cart-item-quantity-control`}
                quantityMultiplier={selectedVariant.quantityMultiplier}
                isOrderVisualization
              />
            )}
            <Button
              testId={`product-item-add-button-${selectedVariant.sku}`}
              className={classes.addButton}
              loading={deleteIsLoading}
              onClick={() => {
                addToCart();
              }}
            >
              {t("OrderTaking.ADD_CART_BUTTON")}
            </Button>
          </div>
          <StockSection stock={selectedVariant.inventoryCount} />
        </div>
      </div>
    </ListItem>
  );
};

export default ProductItemV2;
