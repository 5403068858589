import { getUserInfos, hasMarketplace } from "../../../config/utils/functions";
import { ComboFreeGood } from "../../../domains/promotions/ComboFreeGood";
import GetRemainingDays from "../../../helpers/GetRemainingDays";
import {
  ComboItem,
  Combos,
  CombosResponse,
} from "../../../services/promotions/domains/CombosResponse";
import MarketplaceStore from "../../../stores/marketplace/MarketplaceStore";
import { ConvertedComboInterface } from "./domains/CombosResponseToCombosInfoConverterDomain";

export default function ComboResponseToCombosInfoConverter(
  data: CombosResponse,
): ConvertedComboInterface[] {
  const { marketplaceList } = MarketplaceStore.getState();

  const { vendorId } = getUserInfos();

  return data.combos
    .filter((combo) => combo.vendorId === vendorId)
    .map((combo: Combos) => {
      let marketplace = false;
      const comboItems = combo.items.map((comboItem: ComboItem) => {
        const vendorItemId = returnSku(comboItem);

        /* istanbul ignore else */
        if (!marketplace) {
          marketplace = hasMarketplace(vendorItemId, marketplaceList);
        }

        return {
          id: comboItem.itemId,
          vendorItemId,
          sku: comboItem.sku,
          title: comboItem.name,
          fullPackageDescription: comboItem.fullPackageDescription,
          fullContainerDescription: comboItem.fullContainerDescription,
          image: comboItem.image,
          unit: comboItem.packageItemCount,
        };
      });

      const freeGoods: ComboFreeGood[] = combo.freegoods.map((freeGood) => {
        const vendorItemId = returnSku(freeGood);

        if (!marketplace) {
          marketplace = hasMarketplace(vendorItemId, marketplaceList);
        }

        return {
          id: freeGood.itemId,
          vendorItemId,
          sku: freeGood.sku,
          title: freeGood.name,
          amount: freeGood.quantity,
        };
      });

      const totalDailyBalance = getTotalBalance(
        combo.limit?.total?.daily,
        combo.limit?.consumed?.daily,
      );
      const totalMonthlyBalance = getTotalBalance(
        combo.limit?.total?.monthly,
        combo.limit?.consumed?.monthly,
      );

      return {
        id: combo.comboId,
        vendorComboId: combo.vendorComboId,
        type: combo.type,
        title: combo.title,
        image: combo.image,
        startDate: combo.startDate,
        endDate: combo.endDate,
        remainingDays: GetRemainingDays(combo.endDate),
        description: combo.description,
        price: combo.price?.discountPrice,
        originalPrice: combo.price?.originalPrice,
        limit: {
          daily: combo.limit?.consumed?.daily,
          totalDailyBalance,
          monthly: combo.limit?.consumed?.monthly,
          totalMonthlyBalance,
          balance: getBalance(totalDailyBalance, totalMonthlyBalance),
        },
        marketplace,
        items: comboItems,
        legacyFreeGoods: freeGoods,
        freeGoods: combo.freegoods,
        beesAccountId: combo.beesAccountId,
        platformId: combo.platformId,
        availability: combo.availability,
        score: combo.score,
        storeId: combo.storeId,
      };
    });
}

const getBalance = (
  dailyBalance: number | null,
  monthlyBalance: number | null,
): number | null => {
  if (dailyBalance === null) {
    if (monthlyBalance === null) {
      return null;
    }
    return monthlyBalance;
  }

  if (monthlyBalance === null) {
    return dailyBalance;
  }

  return Math.min(monthlyBalance, dailyBalance);
};

const getTotalBalance = (total?: number, consumed?: number) => {
  if (total === undefined || consumed === undefined) {
    return null;
  }
  const positiveNumber: number = consumed < 0 ? consumed * -1 : consumed;
  return total - positiveNumber;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const returnSku = (comboAndFreeGood: any) => {
  /* istanbul ignore next */
  return comboAndFreeGood.sourceData?.vendorItemId ?? comboAndFreeGood.sku;
};
