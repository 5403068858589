import React from "react";

import { Box } from "@material-ui/core";
import { useStore } from "effector-react";

import {
  GROW_CHECKOUT_CART_PO_NUMBER_FIELD,
  isFeatureEnabledV2,
} from "../../../../../config/featureToggles";
import { getCountryByToken } from "../../../../../config/utils/functions";
import PocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import { updateCartForm } from "../../../../../stores/cart/CartEvents";
import { CartForm } from "../../../../../stores/cart/CartState";
import CartStore from "../../../../../stores/cart/CartStore";
import PaymentMethod from "../../paymentMethod/PaymentMethod";
import OrderCartStyles from "../OrderCartStyles";
import CartDeliveryDate from "./cartDeliveryDate/CartDeliveryDate";
import CartDetails from "./cartDetails/CartDetails";
import PurchaseOrder from "./purchaseOrder/PurchaseOrder";

interface Props {
  isAlternativeDateSelected: (value?: string) => boolean;
}

const PaymentCartForm: React.FunctionComponent<Props> = ({
  isAlternativeDateSelected,
}) => {
  const classes = OrderCartStyles();
  const { pocInformation } = useStore(PocInformationStore);
  const CartStates = useStore(CartStore);

  const cartFormRef = React.useRef<CartForm>({
    paymentMethod: "",
    paymentTerm: 0,
    details: "",
    deliveryDate: "",
    paymentMethodCode: "",
  });

  const isCheckoutCartPoNumberFieldEnabled = isFeatureEnabledV2(
    GROW_CHECKOUT_CART_PO_NUMBER_FIELD,
    getCountryByToken(),
  );

  cartFormRef.current = CartStates.cartForm;

  const handleFormChange = (
    cartFormChanges: Record<string | number, string | number>,
  ) => {
    updateCartForm(cartFormChanges);
  };

  return (
    <Box className={classes.leftContainer}>
      <PaymentMethod
        handleFormChange={handleFormChange}
        paymentMethod={CartStates.cartForm.paymentMethod}
      />
      {isCheckoutCartPoNumberFieldEnabled && (
        <PurchaseOrder
          handleFormChange={handleFormChange}
          hasPONumberRequirement={pocInformation.hasPONumberRequirement}
          poNumber={CartStates.cartForm.poNumber}
        />
      )}
      <CartDeliveryDate
        isAlternativeDateSelected={isAlternativeDateSelected}
        handleFormChange={handleFormChange}
        deliveryDate={CartStates.cartForm.deliveryDate}
      />
      <CartDetails
        handleFormChange={handleFormChange}
        details={CartStates.cartForm.details}
      />
    </Box>
  );
};

export default React.memo(PaymentCartForm);
