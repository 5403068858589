import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    title: {
      color: "rgba(0, 0, 0, 0.9)",
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontWeight: "bold",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      textAlign: "center",
      display: "block",
    },
    icon: {
      height: "24px",
      width: "24px",
    },
    card: {
      flexDirection: "column",
      display: "flex",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
      borderRadius: "8px",
      backgroundColor: "#FFFFFF",
      cursor: "pointer",
    },
    noFocus: {
      border: "1px solid rgba(0,0,0,0.15)",
    },
    atFocus: {
      border: "1px solid #000000",
    },
  }),
);
