import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Drawer } from "@material-ui/core";

import { Heading, IconButton, Paragraph } from "@hexa-ui/components";
import { ArrowLeft } from "@hexa-ui/icons";

import FreeGoodsItemsList from "../freeGoodsItemsList/FreeGoodsItemsList";
import FreeGoodsDrawerStyles from "./FreeGoodsDrawerStyles";

interface FreeGoodsDrawerProps {
  toggleDrawer: (
    open: boolean,
    name: string,
    text: string,
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  isDrawerOpen: boolean;
}

const FreeGoodsDrawer = ({
  toggleDrawer,
  isDrawerOpen,
}: FreeGoodsDrawerProps): JSX.Element => {
  const classes = FreeGoodsDrawerStyles();
  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={toggleDrawer(false, "action-esc-key-to-close", "no-text")}
      className={classes.drawerModal}
    >
      <div className={classes.titleSection}>
        <IconButton
          size="medium"
          variant="tertiary"
          icon={ArrowLeft}
          onClick={toggleDrawer(false, "button-arrow-back-to-close", "no-text")}
        />
        <Heading size="H3" data-testid="free-goods-title">
          {t("OrderTaking.FREE_GOODS_SECTION.TITLE")}
        </Heading>
      </div>

      <Paragraph
        size="basis"
        css={{ marginTop: "20px" }}
        data-testid="free-goods-subtitle"
      >
        {t("OrderTaking.FREE_GOODS_SECTION.SUBTITLE")}
      </Paragraph>

      <Box marginTop={4}>
        <FreeGoodsItemsList />
      </Box>
    </Drawer>
  );
};

export default FreeGoodsDrawer;
