/* eslint-disable react/destructuring-assignment */
import Moment from "moment";
import { CustomerNote } from "../../domains/customerNote/customerNote";
import CustomerNoteResponse from "../../services/customerNotes/domains/CustomerNoteResponse";

export default function CustomerNotesResponseToCustomerNotesConverter(
  data: CustomerNoteResponse,
): CustomerNote | null {
  if (data && data.customerNote) {
    const lastUpdatedDate = Moment.utc(data.customerNote?.updateAt).format(
      "YYYY-MM-DD",
    );
    const lastUpdatedTime = Moment.utc(data.customerNote?.updateAt).format(
      "HH:mm",
    );

    return {
      agentEmail: data.customerNote.agentEmail,
      lastUpdatedDate,
      lastUpdatedTime,
      note: data.customerNote.note ?? "",
    };
  }

  return null;
}
