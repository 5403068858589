import { createStore } from "effector";
import * as CallCounterEvents from "./CallCounterEvents";
import CallCounterState from "./CallCounterState";

const initialState: CallCounterState = {
  count: null,
};

const CallCounterStore = createStore(initialState).on(
  CallCounterEvents.setCallCounter,
  (state: CallCounterState, count: number | null) => ({
    ...state,
    count,
  }),
);
export default CallCounterStore;
