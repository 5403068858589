import React, { useState } from "react";

interface Props {
  width: string;
  height: string;
  alt: string;
  src: string;
  errorImage: string;
}

const ImageContainer = ({
  src,
  width,
  height,
  alt,
  errorImage,
}: Props): JSX.Element => {
  const [image, setImage] = useState<string>(src);

  // istanbul ignore next
  return (
    <img
      src={image || errorImage}
      onError={() => setImage(errorImage)}
      alt={alt}
      style={{ width, height }}
      data-testId={`${src}-image`}
    />
  );
};

export default ImageContainer;
