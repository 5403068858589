import { isPromotionComboTypeValid } from "@config/utils/functions";
import { useStore } from "effector-react";
import { getVendorName } from "grow-shared-services";
import { useCallback } from "react";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import {
  callUxButtonClicked,
  campaignsProductsLoaded,
  errorMessageViewed,
  markMissionAccomplished,
  retryButtonClicked,
  sponsoredProductsLoaded,
  unmarkMissionAccomplished,
} from "../../../../../config/typewriter";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import { getPromotionsV3Effect } from "../../../../../stores/promotions/PromotionsEvents";
import {
  getSponsoredMissionsEvent,
  getSponsoredProductsEvent,
} from "../../../../../stores/sponsored/SponsoredEvents";

import { CURRENCY, MISSION_TYPES } from "../../../../../config/constants";
import {
  GROW_LOCAL_CALL_SUMARY,
  isFeatureEnabledV2,
} from "../../../../../config/featureToggles";
import { EFFECTIVENESS_TYPES_STRINGS } from "../../../../../domains/flexibleMission/FlexibleMission";
import { SponsoredMission } from "../../../../../domains/sponsored/SponsoredMission";
import pocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import GlobalAdminConfigStore from "../../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import PromotionsStore from "../../../../../stores/promotions/PromotionsStore";
import SponsoredStore from "../../../../../stores/sponsored/SponsoredStore";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useGlobalAndLocalComponent = () => {
  const { dispatchGenericEvent } = useAnalytics();
  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);
  const { vendorId } = GlobalAdminConfigState.userConfig;
  const { clientId, callId, legacyAccountId, vendorAccountId } =
    useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);
  const vendorName = getVendorName(vendorId);

  const { sponsoredMissionsError, sponsoredProductsError } =
    useStore(SponsoredStore);
  const { pocInformation } = useStore(pocInformationStore);
  const {
    error: promotionError,
    done: promotionsDone,
    combos: promoCombos,
    promotions,
  } = useStore(PromotionsStore);

  const callSponsoredProductEvent = (mission: SponsoredMission) => {
    getSponsoredProductsEvent({
      sponsoredId: mission.id,
      items: mission.items,
      combos: mission.combos,
    });
  };

  const isLocalCallSumaryEnabled = isFeatureEnabledV2(
    GROW_LOCAL_CALL_SUMARY,
    user.keyToggle,
  );

  const handleTryAgain = (
    openSponsoredMissionIndex: number,
    mission: SponsoredMission,
  ) => {
    const promotionHasError = promotionsDone && promotionError;

    if (promotionHasError) {
      getPromotionsV3Effect({
        accountId: clientId,
        country: user.zone,
        vendorName,
        vendorAccountId,
        legacyAccountId,
      });

      callSponsoredProductEvent(openSponsoredMissionIndex, mission);
    } else {
      callSponsoredProductEvent(openSponsoredMissionIndex, mission);
    }

    dispatchGenericEvent(retryButtonClicked, {
      error_type: "no_connection",
      error_message: "It was not possible to load the sponsored products",
      screen_name: "Call - GAP and Status",
      screen_section: "Single Sponsored Mission",
    });
  };

  const handleGetSponsoredMissionsRetry = (sponsoredMissionsIds: string[]) => {
    getSponsoredMissionsEvent({
      clientId,
      missionsIds: sponsoredMissionsIds,
      vendorId,
    });

    dispatchGenericEvent(retryButtonClicked, {
      error_type: "no_connection",
      error_message: "It was not possible to load the sponsored missions",
      screen_name: "Call - GAP and Status",
      screen_section: "Single Sponsored Mission",
    });
  };

  const handleCollapsibleAnalytics = (
    buttonLabel: "expand" | "collapse",
    missionIndex: number,
    missionTitle: string,
  ) => {
    dispatchGenericEvent(callUxButtonClicked, {
      button_label: buttonLabel,
      button_name: `${missionTitle}-${buttonLabel}`,
      call_id: callId,
      filter_option: null,
      mission: "Sponsored",
      order_date: null,
      position: missionIndex + 1,
      screen_name: "Call - GAP and Status",
      screen_section: missionTitle,
    });
  };

  const handleOpenOrCloseSponsoredMission = (
    index: number,
    openSponsoredMissionIndex: number,
    setOpenSponsoredMissionIndex: (index: number) => void,
    missionTitle: string,
  ) => {
    if (openSponsoredMissionIndex === index) {
      setOpenSponsoredMissionIndex(-1);
      handleCollapsibleAnalytics("collapse", index, missionTitle);
    } else {
      setOpenSponsoredMissionIndex(index);
      handleCollapsibleAnalytics("expand", index, missionTitle);
    }
  };

  const sponsoredItemsOutOfSync = (
    openSponsoredMissionIndex: number,
    sponsoredOrLocalMissions: SponsoredMission[],
  ) => {
    const sponsoredOrLocalMission =
      sponsoredOrLocalMissions[openSponsoredMissionIndex];

    /* istanbul ignore else */
    if (sponsoredOrLocalMission) {
      let comboIsOutOfSync = false;

      /* istanbul ignore else */
      if (
        sponsoredOrLocalMission?.combos &&
        sponsoredOrLocalMission?.combos?.length > 0 &&
        promotionError
      )
        comboIsOutOfSync = true;

      /* istanbul ignore next */
      if (!comboIsOutOfSync) {
        const mergeCombos = promoCombos
          .filter((pc) =>
            sponsoredOrLocalMission?.combos?.some(
              (c) =>
                (c.comboPlatformId === pc.platformId ||
                  c.id === pc.vendorComboId ||
                  c.comboId === pc.vendorComboId) &&
                isPromotionComboTypeValid(pc.type),
            ),
          )
          .map((founded) => {
            return founded.platformId ?? founded.id;
          });

        comboIsOutOfSync =
          mergeCombos.length > 0
            ? !sponsoredOrLocalMission?.products?.some((c) =>
                mergeCombos.some(
                  (oc) => c.platformId === oc || c.comboId === oc,
                ),
              )
            : false;
      }

      /* istanbul ignore next */
      return (
        (sponsoredOrLocalMission.items || sponsoredOrLocalMission.combos) &&
        (!sponsoredOrLocalMission.products || comboIsOutOfSync)
      );
    }

    return false;
  };

  const handleMarkAndUnmarkAnalytics = (pocAccepted: boolean, mission) => {
    const isFlexibleMission = mission.missionType === MISSION_TYPES.FLEXIBLE;
    const nullIfLocal = (value) => {
      if (!isFlexibleMission) {
        return null;
      }
      return value;
    };
    const nullIfFlexible = (value) => {
      if (isFlexibleMission) {
        return null;
      }
      return value;
    };
    /* istanbul ignore next */
    return !pocAccepted
      ? dispatchGenericEvent(markMissionAccomplished, {
          call_id: callId,
          poc_id: clientId,
          type_mission: isFlexibleMission
            ? "Flexible mission"
            : "Local mission",
          local_mission_title: nullIfFlexible(mission.missionTitle),
          local_mission_id: nullIfFlexible(mission.id),
          flexile_mission_title: nullIfLocal(mission.missionTitle),
          flexible_mission_id: nullIfLocal(mission.id),
          sponsor_partner: mission.sponsorPartnerId
            ? mission.sponsorPartnerId
            : null,
        })
      : dispatchGenericEvent(unmarkMissionAccomplished, {
          call_id: callId,
          poc_id: clientId,
          local_mission_title: nullIfFlexible(mission.missionTitle),
          local_mission_id: nullIfFlexible(mission.id),
          flexile_mission_title: nullIfLocal(mission.missionTitle),
          flexible_mission_id: nullIfLocal(mission.id),
          sponsor_partner: mission.sponsorPartnerId
            ? mission.sponsorPartnerId
            : null,
        });
  };

  const handleEventErrorMessageViewed = useCallback(() => {
    if (sponsoredMissionsError || sponsoredProductsError) {
      dispatchGenericEvent(errorMessageViewed, {
        error_type: "no_connection",
        error_message: "It was not possible to load the sponsored missions",
        screen_name: "Call - GAP and Status",
        screen_section: "Single Sponsored Mission",
        is_all_products: null,
        currency: CURRENCY[user.zone]?.currency || null,
        call_id: callId,
        call_trigger: null,
        country: user?.zone || null,
        poc_id: pocInformation?.id || null,
        DDC: pocInformation?.deliveryCenterId || null,
        poc_segment: pocInformation?.segment || null,
        poc_subsegment: pocInformation?.subSegment || null,
        is_resumed: true,
        time_of_day: new Date().getHours(),
      });
    }
  }, [
    callId,
    dispatchGenericEvent,
    pocInformation?.deliveryCenterId,
    pocInformation?.id,
    pocInformation?.segment,
    pocInformation?.subSegment,
    sponsoredMissionsError,
    sponsoredProductsError,
    user.zone,
  ]);

  const products = (mission: SponsoredMission) => {
    const productsMapped = mission.products?.map((item) => ({
      sku: item.sku,
      combo_id: item.comboId,
      is_out_of_stock: item.stock === 0,
      is_promotion: promotions.some((promotion) => promotion.sku === item.sku),
    }));

    return productsMapped;
  };

  const dispatchEventsponsoredProductsLoad = (mission: SponsoredMission) => {
    dispatchGenericEvent(sponsoredProductsLoaded, {
      call_id: callId,
      poc_id: clientId,
      Sponsored_mission: mission.missionTitle,
      products: products(mission),
    });
  };

  const dispatchEventLocalLoad = (
    mission: SponsoredMission,
    isSponsored: boolean,
  ) => {
    const isFlexible = mission?.missionType === MISSION_TYPES.FLEXIBLE;

    const effectivenessCriteria = isFlexible
      ? EFFECTIVENESS_TYPES_STRINGS.CREDIT_AS_PAYMENT
      : mission?.effectiveness?.type;

    const extraParams = {
      flexile_mission_title: mission?.missionTitle,
      flexible_mission_id: mission?.id,
    };

    dispatchGenericEvent(campaignsProductsLoaded, {
      Local_mission_title: mission.missionTitle,
      Local_mission_id: mission.id,
      sponsor_partner: mission.sponsorPartnerId,
      effectiveness_criteria: effectivenessCriteria,
      call_id: callId,
      poc_id: clientId,
      sponsored_tag: isSponsored,
      products: products(mission),
      ...(isFlexible ? extraParams : {}),
    });
  };

  return {
    handleGetSponsoredMissionsRetry,
    handleTryAgain,
    handleOpenOrCloseSponsoredMission,
    sponsoredItemsOutOfSync,
    handleEventErrorMessageViewed,
    callSponsoredProductEvent,
    isLocalCallSumaryEnabled,
    handleMarkAndUnmarkAnalytics,
    dispatchEventLocalLoad,
    dispatchEventsponsoredProductsLoad,
  };
};

export default useGlobalAndLocalComponent;
