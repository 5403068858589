import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import gapStatusStyles from "./GapStatusStyles";

interface Props {
  orderSku: number;
  testId: string;
}

const GapStatus: React.FunctionComponent<Props> = ({ orderSku, testId }) => {
  const { t } = useTranslation();
  const classes = gapStatusStyles();

  return (
    <Grid container direction="row">
      <Typography
        data-testid={testId.concat("-label")}
        className={classes.label}
      >
        {t("SuggestedOrder.GAP_STATUS_LABEL")}:
      </Typography>
      <Typography
        data-testid={testId.concat("-description")}
        className={classes.status}
      >
        {orderSku === 0
          ? t("SuggestedOrder.GAP_NO_ORDER")
          : t("SuggestedOrder.GAP_OUT_PARAMETER")}
      </Typography>
    </Grid>
  );
};

export default GapStatus;
