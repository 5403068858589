import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "center",
    },
    loadErrorIcon: {
      width: 180,
    },
    button: {
      fontSize: 18,
      fontFamily: "Work Sans",
      height: 48,
      borderRadius: 24,
      padding: "0.75rem 1.5rem",
    },
    subtitle: {
      fontSize: 16,
      fontFamily: "Work Sans",
      marginTop: 6,
    },
    title: {
      color: palette.info.dark,
    },
    buttonContainer: {
      marginTop: 32,
    },
    infoContainer: {
      marginLeft: 55,
    },
  }),
);
