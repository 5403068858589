import { createEffect } from "effector";
import * as AgentMonthlyKPIUseCase from "../../usecase/agentKPI/AgentMonthlyKPIUseCase";

interface User {
  login: string;
  zone: string;
  vendorId: string;
  vendorName: string;
}

export const getMonthlyKPIEffect = createEffect({
  async handler(user: User) {
    return AgentMonthlyKPIUseCase.execute(
      user.login,
      user.zone,
      user.vendorId,
      user.vendorName,
    );
  },
});
