import { createEffect, createEvent } from "effector";
import { CheckoutDomain } from "../../services/checkout/domains/CheckoutDomain";
import { CheckoutDomainV2 } from "../../services/checkout/domains/v2/CheckoutDomainV2";
import * as CheckoutUseCase from "../../usecase/checkout/v2/CheckoutUseCase";

export const showErrorMessage =
  createEvent<{ message: string; type: string }[]>("showErrorMessage");

export const checkoutV2Effect = createEffect({
  async handler(checkout: CheckoutDomainV2) {
    return CheckoutUseCase.execute(checkout);
  },
});

export const checkoutV3Effect = createEffect({
  async handler(checkout: CheckoutDomain) {
    return CheckoutUseCase.executeV3(checkout);
  },
});
