import * as React from "react";

interface Props {
  width?: number;
  height?: number;
}

const ErrorIcon: any = ({ width, height }: Props) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.61273 0H12.3873C12.6043 0 12.8124 0.0862011 12.9658 0.23964L17.7604 5.03419C17.9138 5.18762 18 5.39573 18 5.61273V12.3873C18 12.6043 17.9138 12.8124 17.7604 12.9658L12.9658 17.7604C12.8124 17.9138 12.6043 18 12.3873 18H5.61273C5.39573 18 5.18762 17.9138 5.03419 17.7604L0.23964 12.9658C0.0862011 12.8124 0 12.6043 0 12.3873V5.61273C0 5.39573 0.0862011 5.18762 0.23964 5.03419L5.03419 0.23964C5.18762 0.0862011 5.39573 0 5.61273 0ZM16.3636 5.95227L12.0477 1.63636H5.95146L1.63636 5.95146V12.0477L5.95227 16.3636H12.0469L16.3636 12.0469V5.95227ZM9 4.90909C9.41959 4.90909 9.76542 5.22494 9.81268 5.63186L9.81818 5.72727V9C9.81818 9.45187 9.45187 9.81818 9 9.81818C8.58041 9.81818 8.23458 9.50233 8.18732 9.09542L8.18182 9V5.72727C8.18182 5.2754 8.54813 4.90909 9 4.90909ZM9.82636 12.2727C9.82636 11.8209 9.46005 11.4545 9.00818 11.4545L8.90458 11.46C8.49767 11.5073 8.18182 11.8531 8.18182 12.2727C8.18182 12.7246 8.54813 13.0909 9 13.0909L9.1036 13.0854C9.51051 13.0381 9.82636 12.6923 9.82636 12.2727Z"
        fill="#C9201D"
      />
    </svg>
  );
};

ErrorIcon.defaultProps = {
  width: 18,
  height: 18,
};

export default ErrorIcon;
