import { makeStyles, createStyles } from "@material-ui/core";

const AgentIdentificationStyles = makeStyles(({ palette }) =>
  createStyles({
    identificationContainer: {
      display: "flex",
      alignItems: "center",
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      width: "65px",
      height: "65px",
      borderRadius: "50%",
      border: "3px solid white",
      boxShadow: "2px 4px 5px 0px rgba(190,190,190,1)",
    },
    image: {
      flexShrink: 0,
      minWidth: "140%",
      minHeight: "140%",
    },
    infosContainer: {
      marginLeft: "20px",
    },
    identificationText: {
      fontWeight: "bold",
      fontFamily: "Barlow",
    },
    email: {
      fontSize: "20px",
      color: palette.text.primary,
      whiteSpace: "nowrap",
    },
    environment: {
      fontSize: "16px",
      color: palette.text.secondary,
    },
  }),
);
export default AgentIdentificationStyles;
