import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import Rewards from "domains/Rewards";
import axios from "../../config/axios/axiosInstance";
import { getApiHost } from "../host/HostB2BService";
import LoyaltyBusinessRewardsResponse from "./domains/LoyaltyBusinessRewardsResponse";
import LoyaltyResponseToRewardsConverter from "../../converters/LoyaltyResponseToRewardsConverter";

export const REWARDS_ENDPOINT_V2 =
  "/api/v1/loyalty-business-service/v2/rewards";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getLoyaltyRewardsScoreV2(accountId: string): Promise<Rewards> {
  const log = useLogService();
  const endpoint = `${getApiHost()}${REWARDS_ENDPOINT_V2}`;

  return axios
    .get<LoyaltyBusinessRewardsResponse>(`${endpoint}/${accountId}`)
    .then((response) => {
      log.info(`Convert loyalty score received from ${endpoint} to our model`);
      return LoyaltyResponseToRewardsConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
