import React from "react";

const CheckIcon: React.FunctionComponent = () => (
  <svg width={17} height={17} viewBox="0 0 17 17">
    <path
      d="M5.015 6.885l-1.19 1.19L7.65 11.9l8.5-8.5-1.19-1.19-7.31 7.31-2.635-2.635zM15.3 8.5a6.8 6.8 0 11-6.8-6.8 6.629 6.629 0 011.87.255L11.73.6A10.345 10.345 0 008.5 0 8.5 8.5 0 1017 8.5z"
      fill="#7A7A7A"
      fillRule="evenodd"
    />
  </svg>
);

export default CheckIcon;
