import React, { useEffect } from "react";
import {
  FixedSizeList,
  ListChildComponentProps,
  ListItemKeySelector,
} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useStore } from "effector-react";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import { useCollapsibleSideNavContext } from "../../../../../contexts/CollapsibleSideNavContext";
import NewOfferings from "../../../../../domains/newOfferings/NewOfferings";
import NewOfferingItem from "../newOfferingItem/NewOfferingItem";
import { toRecommendListLoaded } from "../../../../../config/typewriter";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import {
  isFeatureEnabled,
  TLP_MARKETPLACE,
  TLP_MARKETPLACE_ADMIN,
} from "../../../../../config/featureToggles";

import NewOfferingsVirtualizedTableStyles from "./NewOfferingsVirtualizedTableStyles";

interface Props {
  offerings: Array<NewOfferings>;
  searchValue?: string;
  firstRender: React.MutableRefObject<boolean>;
}

interface ListProps {
  height: number;
  width: number;
  itemKey: ListItemKeySelector | undefined;
  itemData: Array<NewOfferings>;
  row: React.FunctionComponent<ListChildComponentProps>;
  collapsibleIsOpen: boolean;
}

export const List: React.FC<ListProps> = ({
  height,
  width,
  itemKey,
  itemData,
  row,
  collapsibleIsOpen,
}) => {
  const { list } = NewOfferingsVirtualizedTableStyles();
  return (
    <FixedSizeList
      className={list}
      height={height}
      width={width}
      itemSize={collapsibleIsOpen ? 140 : 110}
      itemKey={itemKey}
      itemCount={itemData?.length}
      itemData={itemData}
    >
      {row}
    </FixedSizeList>
  );
};

const NewOfferingsVirtualizedTable: React.FunctionComponent<Props> = ({
  offerings,
  searchValue,
  firstRender,
}) => {
  const { collapsibleIsOpen } = useCollapsibleSideNavContext();
  const { dispatchPocEvent } = useAnalytics();
  const { user } = useStore(GlobalStore);

  /* istanbul ignore next */
  const itemKey = (index: number) => offerings[index].sku;

  const isMarketplaceEnabled = isFeatureEnabled(
    TLP_MARKETPLACE,
    TLP_MARKETPLACE_ADMIN,
    user.keyToggle,
  );

  useEffect(() => {
    const offeringsLength = offerings?.length || 0;
    if (offeringsLength !== 0 && firstRender.current) {
      firstRender.current = false;
      dispatchPocEvent(
        toRecommendListLoaded,
        {
          list_quantity: offeringsLength,
          search_query: searchValue || null,
          filter_option: "",
          is_resumed: false,
        },
        {
          removeProps: [
            "DDC",
            "total_gap",
            "total_vol_gap",
            "is_resumed",
            "credit_available",
            "poc_recurrence",
            "poc_potential",
          ],
        },
      );
    }
  }, [offerings, dispatchPocEvent, searchValue, firstRender]);

  /* istanbul ignore next */
  const Row = (props: ListChildComponentProps) => {
    const { index } = props;
    const item = offerings[index];
    return (
      <NewOfferingItem
        offeringItem={item}
        childComponentProps={props}
        displayMarketplace={isMarketplaceEnabled}
      />
    );
  };

  /* istanbul ignore next */
  return (
    <AutoSizer style={{ height: "50vh" }}>
      {({ width, height }) => (
        <List
          height={height}
          width={width}
          itemKey={itemKey}
          itemData={offerings}
          row={Row}
          collapsibleIsOpen={collapsibleIsOpen}
        />
      )}
    </AutoSizer>
  );
};

export default React.memo(NewOfferingsVirtualizedTable);
