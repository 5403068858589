/* eslint-disable react/button-has-type */
import React, { useCallback } from "react";
import classNames from "classnames";
import Button from "../button/Button";
import TabTitleStyles from "./TabTitleStyles";

type Props = {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  selected: number;
  onClick: () => void;
};

const TabTitle: React.FC<Props> = ({
  title,
  setSelectedTab,
  index,
  selected,
  onClick,
}) => {
  const classes = TabTitleStyles();
  /* istanbul ignore next */
  const handleOnClick = useCallback(() => {
    setSelectedTab(index);
    onClick();
  }, [setSelectedTab, index, onClick]);

  return (
    <Button
      className={classNames(classes.tabTitle, {
        [classes.tabTitleActive]: index === selected,
      })}
      onClick={handleOnClick}
    >
      {title}
    </Button>
  );
};

export default TabTitle;
