import React, { useCallback, useRef } from "react";
import { useStore } from "effector-react";
import {
  Grid,
  IconButton,
  Typography,
  Collapse,
  Tooltip,
} from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import {
  ConvertAndFormatTag,
  tagNameFormatterGlobal,
} from "../../../../../helpers/TagNameFormatter";
import ChatBubbleIcon from "../../../../../assets/ChatBubbleIcon";
import { Mission } from "../../../../../domains/Mission";
import MissionsStyles from "./MissionsStyles";
import ToplineGapMission from "./components/topLineGapMission/ToplineGapMission";
import MarketplaceActivationMission from "./components/marketplaceActivation/MarketplaceActivation";
import PromoteRewardsChallenges from "./components/promoterewardschallenges/PromoteRewardsChallenges";
import {
  isFeatureEnabled,
  TLP_CHANGE_TAG_VISUALIZATION,
  TLP_CHANGE_TAG_VISUALIZATION_ADMIN,
  TLP_CALL_SUMMARY_PROMOTE_MARKET_PLACE_TAG,
  TLP_CALL_SUMMARY_PROMOTE_MARKET_PLACE_TAG_ADMIN,
  TLP_CALL_SUMMARY_REWARDS_CHALLENGES_FEEDBACK,
  TLP_CALL_SUMMARY_REMOVE_FIELDS,
  isFeatureEnabledV2,
  TLP_SPONSORED_MISSION_TAB,
} from "../../../../../config/featureToggles";
import DigitalServices from "./components/digitalServices/DigitalServices";
import Sponsored from "./components/sponsored/Sponsored";
import SponsoredStore from "../../../../../stores/sponsored/SponsoredStore";
import { missionTypesByTag } from "../../../../../domains/missions/MissionTypesByTag";

interface TargetValue {
  name?: string;
  value: string;
}

interface Props {
  handleChange: (
    e: React.ChangeEvent<TargetValue>,
    tag: string,
    usage: string,
  ) => void;
  values: Mission;
  errors?: FormikErrors<Mission>;
  touched?: FormikTouched<Mission>;
  isExpanded: boolean;
  handleExpanded: (tag: string, isExpanded: boolean) => void;
}

const Missions: React.FC<Props> = ({
  handleChange,
  values,
  errors,
  touched,
  isExpanded,
  handleExpanded,
}) => {
  const { t } = useTranslation();
  const classes = MissionsStyles();
  const { tag } = values;
  const {
    user: { zone },
  } = useStore(GlobalStore);
  const tagTitleRef = useRef<HTMLHeadingElement>(null);
  const { sponsoredMissions } = useStore(SponsoredStore);

  const isChangeTagEnabled = isFeatureEnabled(
    TLP_CHANGE_TAG_VISUALIZATION,
    TLP_CHANGE_TAG_VISUALIZATION_ADMIN,
    zone,
  );

  const isPromoteMarketPlaceTagSummaryEnabled = isFeatureEnabled(
    TLP_CALL_SUMMARY_PROMOTE_MARKET_PLACE_TAG,
    TLP_CALL_SUMMARY_PROMOTE_MARKET_PLACE_TAG_ADMIN,
    zone,
  );

  const isCallSummaryRewardsChallengesFeedBackEnabled = isFeatureEnabledV2(
    TLP_CALL_SUMMARY_REWARDS_CHALLENGES_FEEDBACK,
    zone,
  );

  const isSponsoredMissionTabEnabled = isFeatureEnabledV2(
    TLP_SPONSORED_MISSION_TAB,
    zone,
  );

  const isCallSummaryRemoveFieldsEnabled = isFeatureEnabledV2(
    TLP_CALL_SUMMARY_REMOVE_FIELDS,
    zone,
  );

  const isRequired = () => {
    if (
      values.tag === t("tags.#TopLineGAP") ||
      values.tag === t("tags.#marketplaceactivation") ||
      values.tag === t("tags.#digital_services") ||
      (values.tag === t("tags.#PromoteMarketPlace", { lng: "en" }) &&
        isPromoteMarketPlaceTagSummaryEnabled) ||
      (values.tag === t("tags.#promoterewardschallenges", { lng: "en" }) &&
        isCallSummaryRewardsChallengesFeedBackEnabled) ||
      (values.type === getMissionType("#sponsored") &&
        isSponsoredMissionTabEnabled)
    ) {
      return true;
    }

    return false;
  };
  const renderForm = () => {
    return (
      <>
        {values.tag === t("tags.#TopLineGAP") && (
          <ToplineGapMission
            handleChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
          />
        )}
        {values.tag === t("tags.#marketplaceactivation") && (
          <MarketplaceActivationMission
            handleChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
          />
        )}
        {values.tag === t("tags.#PromoteMarketPlace", { lng: "en" }) &&
          isPromoteMarketPlaceTagSummaryEnabled && (
            <MarketplaceActivationMission
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
            />
          )}
        {values.tag === t("tags.#promoterewardschallenges", { lng: "en" }) &&
          isCallSummaryRewardsChallengesFeedBackEnabled && (
            <PromoteRewardsChallenges
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
            />
          )}
        {values.tag === t("tags.#digital_services") && (
          <DigitalServices
            handleChange={handleChange}
            values={values}
            errors={errors}
            touched={touched}
          />
        )}
        {values.type === getMissionType("#sponsored") &&
          isSponsoredMissionTabEnabled &&
          sponsoredMissions.length > 0 && (
            <Sponsored
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
            />
          )}

        {(isCallSummaryRemoveFieldsEnabled && !values.effectiveness) ||
        !isCallSummaryRemoveFieldsEnabled ? (
          <textarea
            key={`textarea-${values.tag}`}
            id={`missions-description-${values.tag}`}
            name="description"
            placeholder={t("NEWCallSummary.COMMENTS_PLACEHOLDER")}
            className={classes.textArea}
            value={values.comments}
            onChange={(event) => handleChange(event, tag, "comments")}
            data-testid="missions-description"
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  const hasFieldErrors = () => {
    return !isExpanded && errors && touched && isRequired();
  };

  const getIconClasses = () => {
    let iconClasses = classes.iconComments;
    if (values.effectiveness === undefined) {
      iconClasses = `${iconClasses}, ${classes.disabledIcon}`;
    }
    return iconClasses;
  };

  const getTagTitleClasses = () => {
    let tagTitleClasses = classes.tagTitleWithEllipsis;
    if (hasFieldErrors()) {
      tagTitleClasses = `${tagTitleClasses}, ${classes.hasFieldErrors}`;
    }
    return tagTitleClasses;
  };

  const isTooltipDisabled = () => {
    /* istanbul ignore if */
    if (tagTitleRef.current && tagTitleRef.current.offsetWidth > 213) {
      return false;
    }
    return true;
  };

  const getMissionType = useCallback((tag: string) => {
    return missionTypesByTag[tag];
  }, []);

  const renderMissionTag = () => {
    const missionTagText = isChangeTagEnabled
      ? ConvertAndFormatTag(t, values.tag)
      : tagNameFormatterGlobal(values.tag);

    const tooltipClassesProps = {
      tooltip: classes.tooltipStyle,
      arrow: classes.tooltipArrow,
    };

    return (
      <Tooltip
        disableHoverListener={isTooltipDisabled()}
        title={missionTagText}
        arrow
        placement="top"
        classes={{ ...tooltipClassesProps }}
      >
        <Typography
          key={`typography-${values.tag}`}
          component="span"
          variant="subtitle1"
          className={getTagTitleClasses()}
          ref={tagTitleRef}
        >
          {missionTagText}
        </Typography>
      </Tooltip>
    );
  };

  const renderButtonIconArrowDown = () => {
    const shouldShowButtonIconArrowDown = values.effectiveness === false;

    return shouldShowButtonIconArrowDown ? (
      <IconButton
        key={`icon-button-${values.tag}`}
        disabled={values.effectiveness === undefined}
        onClick={() => handleExpanded(values.tag, !isExpanded)}
      >
        <KeyboardArrowDown />
      </IconButton>
    ) : (
      <></>
    );
  };

  return (
    <Grid
      key={`grid-1-${values.tag}`}
      className={classNames(classes.container, {
        [classes.inputWithError]:
          !!errors?.typification && touched?.typification,
      })}
    >
      <Grid className={classes.header} key={`grid-2-${values.tag}`}>
        {renderMissionTag()}
        {!isCallSummaryRemoveFieldsEnabled ? (
          <IconButton
            key={`icon-button-${values.tag}`}
            disabled={values.effectiveness === undefined}
            onClick={() => handleExpanded(values.tag, !isExpanded)}
            className={getIconClasses()}
          >
            <ChatBubbleIcon hasErrors={hasFieldErrors()} />
          </IconButton>
        ) : (
          renderButtonIconArrowDown()
        )}
      </Grid>
      <Collapse in={isExpanded} key={`collapse-${values.tag}`}>
        {isExpanded && renderForm()}
      </Collapse>
    </Grid>
  );
};

export default React.memo(Missions);
