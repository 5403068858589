import React, { useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, ButtonBase } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { useStore } from "effector-react";
import { getVendorName } from "grow-shared-services";
import pocInformationStore from "../../../../stores/callList/pocInformation/PocInformationStore";
import { ANALYTICS_ROUTE_NAMES } from "../../../../config/constants";
import GlobalStore from "../../../../stores/global/GlobalStore";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";
import CampaignsB2OProductsStore from "../../../../stores/campaigns/campaignsB2OProducts/CampaignsB2OProductsStore";
import PlusIcon from "../../../../assets/PlusIcon";
import MinusIcon from "../../../../assets/MinusIcon";
import Dropdown from "./components/dropdown/Dropdown";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_TAB_CAMPAIGNS,
  TLP_TAB_CAMPAIGNS_ADMIN,
  TLP_MISSIONS_LAYOUT,
  TLP_MISSIONS_LAYOUT_ADMIN,
  TLP_CAMPAIGN_FOCUS_VENDOR,
  TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
} from "../../../../config/featureToggles";
import CampaignsStyles from "./Campaigns.styles";
import Banner from "./components/banner/Banner";
import Content from "./components/content/Content";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import {
  callUxButtonClicked,
  campaignViewed,
  errorMessageViewed,
} from "../../../../config/typewriter";
import Loading from "../../../../components/loadingView/LoadingView";
import CampaignsB2OProductsDB from "../../campaignsB2OProductsDB/CampaignB2OProductDB";
import CampaignsB2OProductsQueryDB from "../../campaignsB2OProductsDB/CampaignsB2OProductsQueryDB";
import {
  getCampaignsB2OProducts,
  setCampaignB2OProducts,
} from "../../../../stores/campaigns/campaignsB2OProducts/CampaignsB2OProductsEvents";
import GlobalAdminConfigStore from "../../../../stores/globaAdminConfig/GlobalAdminConfigStore";

// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
const campaignLogo = require("../../../../assets/images/campaign-logo.png");

interface Campaign {
  campaignTag: string;
  campaignName: string;
  campaignType: string;
  campaignScript: string;
  campaignDescription: string;
  campaignImage: string;
}

interface Props {
  userZone: string;
  campaigns: Campaign[];
  selectedCampaign: Campaign | null;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  setSelectedCampaign: (campaign: Campaign | null) => void;
}

function Campaigns({
  userZone,
  campaigns,
  selectedCampaign,
  setSelectedCampaign,
  isOpen,
  setIsOpen,
}: Props): React.ReactElement {
  const isFocusSkuEnabled = isFeatureEnabled(
    TLP_TAB_CAMPAIGNS,
    TLP_TAB_CAMPAIGNS_ADMIN,
    userZone,
  );

  const isMissionsEnabled = isFeatureEnabled(
    TLP_MISSIONS_LAYOUT,
    TLP_MISSIONS_LAYOUT_ADMIN,
    userZone,
  );

  const { t } = useTranslation();

  const { callId, clientId, gap } = useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);
  const { callTab } = useStore(CallTabStore);
  const { pocInformation } = useStore(pocInformationStore);
  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { dispatchGenericEvent, dispatchPocEvent } = useAnalytics();

  const isCampaignFocusVendorEnabled = isFeatureEnabledV2(
    TLP_CAMPAIGN_FOCUS_VENDOR,
    user.keyToggle,
  );

  const isMultiContractCampaignServiceEnabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
    user.keyToggle,
  );

  useEffect(() => {
    dispatchGenericEvent(campaignViewed, {
      call_id: callId,
      poc_id: clientId,
      campaign_tag: selectedCampaign?.campaignTag || null,
      campaign_type: "B2O",
      button_label: "tab-campaign",
      campaign_name: selectedCampaign?.campaignName || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const db = useRef(new CampaignsB2OProductsDB());
  const { error, loading } = useStore(CampaignsB2OProductsStore);

  const handleOpenCollapsible = () => {
    dispatchGenericEvent(callUxButtonClicked, {
      screen_name: "Call - GAP and Status",
      screen_section: "Campaigns",
      button_label: isOpen ? "collapse" : "expand",
      button_name: isOpen
        ? "campaign-collapse-button"
        : "campaign-expand-button",
      filter_option: "",
      call_id: callId,
      order_date: null,
      position: null,
    });
    setIsOpen(!isOpen);
  };
  const classes = CampaignsStyles({
    isFocusSkuEnabled,
    isMissionsEnabled,
  });

  const handleChangeCampaign = (value: Campaign | null) => {
    dispatchGenericEvent(campaignViewed, {
      call_id: callId,
      poc_id: clientId,
      campaign_tag: value?.campaignTag,
      campaign_type: "B2O",
      button_label: "campaign-selection-dropdown",
      campaign_name: value?.campaignName,
    });

    handleSelectCampaign(value);
  };

  const handleSelectCampaign = useCallback(
    async (value: Campaign | null) => {
      setSelectedCampaign(value);

      const query = new CampaignsB2OProductsQueryDB(db.current);

      const productsInDB = await query.findByTag(value?.campaignTag);

      if (productsInDB?.length > 0) {
        setCampaignB2OProducts(productsInDB);
      } else {
        const { vendorId } = userConfig;
        const vendorName = getVendorName(vendorId);

        getCampaignsB2OProducts({
          country: user.zone,
          tag: value?.campaignTag || "",
          accountId: clientId,
          vendorId,
          vendorName,
          isCampaignFocusVendorEnabled,
          isMultiContractCampaignServiceEnabled,
        });

        /* istanbul ignore next */
        const unWatchGetCampaignsB2OProduct =
          getCampaignsB2OProducts.done.watch((response) => {
            db.current.CampaignB2OProducts.bulkPut(
              response.result.campaignsProducts,
            );
            if (typeof unWatchGetCampaignsB2OProduct === "function") {
              unWatchGetCampaignsB2OProduct();
            }
          });
      }
    },
    [
      user.zone,
      clientId,
      setSelectedCampaign,
      userConfig,
      isCampaignFocusVendorEnabled,
      isMultiContractCampaignServiceEnabled,
    ],
  );

  const handleRenderContent = () => {
    if (loading) {
      return (
        <Box className={classes.campaignContainer}>
          <Box className={classes.loadingContainer}>
            <Loading data-testid="campaigns-loading" />
          </Box>
        </Box>
      );
    }
    if (selectedCampaign) {
      return (
        <AccordionDetails className={classes.accordionDetails}>
          <Box flexDirection="column">
            {!isMissionsEnabled && (
              <Banner data-testid="campaigns-banner" url={campaignLogo} />
            )}

            <Box flexDirection="column" className={classes.contentContainer}>
              <Content
                title={t("CAMPAIGNS.DESCRIPTION")}
                text={selectedCampaign?.campaignDescription}
              />
              <div className={classes.divider} />
              <Content
                title={t("CAMPAIGNS.SCRIPT")}
                text={selectedCampaign?.campaignScript}
              />
            </Box>
          </Box>
        </AccordionDetails>
      );
    }
    return (
      <Box className={classes.campaignsEmpty}>{t("Campaigns.NOT_FOUND")}</Box>
    );
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (error) {
      dispatchPocEvent(
        errorMessageViewed,
        {
          error_message: "No data available",
          error_type: "No data available",
          screen_name: ANALYTICS_ROUTE_NAMES.get(callTab),
          screen_section: "Campaings",
          is_all_products: true,
          call_id: callId,
          call_trigger: null,
          country: user.zone,
          poc_id: clientId,
          DDC: pocInformation.deliveryCenterId || null,
          DDC_name: pocInformation.distributionCenterName || null,
          poc_segment: pocInformation.segment || null,
          poc_subsegment: pocInformation.subSegment || null,
          total_gap: null,
          total_vol_gap: gap.volume.total,
          currency: null,
          credit_available: pocInformation.availableCredit || null,
        },
        { time_of_day: true, is_resumed: true },
      );
    }
  }, [
    error,
    user.zone,
    callId,
    clientId,
    gap.topline.total,
    gap.volume.total,
    callTab,
    pocInformation.deliveryCenterId,
    pocInformation.distributionCenterName,
    pocInformation.segment,
    pocInformation.subSegment,
    pocInformation.availableCredit,
    dispatchPocEvent,
  ]);

  useEffect(() => {
    handleSelectCampaign(campaigns ? campaigns[0] : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion
      classes={{ root: classes.accordion }}
      expanded={isOpen}
      data-testid="accordion-campaign-collapsible"
    >
      <div className={classes.expandableData}>
        <div className={classes.headerContainer}>
          <ButtonBase
            className={classes.expandButton}
            onClick={() => handleOpenCollapsible()}
            data-testid="collapsible-container-expand-button-campaign"
          >
            {isOpen ? (
              <MinusIcon data-testid="collapsible-container-remove-icon" />
            ) : (
              <PlusIcon data-testid="collapsible-container-add-icon" />
            )}
          </ButtonBase>
          <Dropdown
            items={campaigns || []}
            onChange={handleChangeCampaign}
            value={selectedCampaign}
          />
        </div>
        <span className={classes.b2o}>{t("Campaigns.B2O")}</span>
      </div>
      {handleRenderContent()}
    </Accordion>
  );
}

/* istanbul ignore next */
export default React.memo(Campaigns, (prevProps, nextProps) => {
  const compareCampaigns = (
    prevCampaigns: Campaign[],
    nextCampaigns: Campaign[],
  ) => {
    if (!prevCampaigns && !nextCampaigns) return true;

    if (!prevCampaigns || !nextCampaigns) return false;

    if (prevCampaigns.length === 0 && nextCampaigns.length === 0) return true;

    return (
      prevCampaigns.length === nextCampaigns.length &&
      prevCampaigns.every(
        (campaign, index) =>
          campaign?.campaignTag === nextCampaigns[index]?.campaignTag,
      )
    );
  };

  return (
    prevProps.userZone === nextProps.userZone &&
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.selectedCampaign?.campaignTag ===
      nextProps.selectedCampaign?.campaignTag &&
    compareCampaigns(prevProps.campaigns, nextProps.campaigns)
  );
});
