import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface Props {
  isFocusSkuEnabled?: boolean;
  isMissionsEnabled?: boolean;
}

export default makeStyles<Theme, Props>(({ palette }) =>
  createStyles({
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    contentContainer: {
      padding: "0px 8px 8px 24px",
      backgroundColor: ({ isMissionsEnabled }) =>
        isMissionsEnabled ? "#f8f8f8" : "#eaeaea",
    },
    title: {
      marginLeft: 10,
      fontWeight: 500,
      fontSize: "18px",
      letterSpacing: 0,
      color: palette.secondary.main,
      opacity: 1,
    },
    expandButton: {
      background: "#ffffff",
      width: "15px",
      height: "15px",
      borderRadius: "3px",
      marginLeft: "8px",
      marginRight: "9px",
    },
    campaignContainer: {
      padding: "6px 0 8px 0",
      backgroundColor: "white",
      minHeight: "50%",
      maxHeight: (props) => (props.isFocusSkuEnabled ? "50%" : "100%"),
      display: "flex",
      flexDirection: "column",
      borderRadius: "12px",
    },
    campaignsEmpty: {
      fontSize: "18px",
      color: "#7c7c7c",
      height: "100%",
      padding: "30px 0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    headerContainer: {
      display: "flex",
      alignItems: "center",
    },
    expandableData: {
      background: "#212121",
      height: "32px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
    accordionDetails: {
      display: "contents",
    },
    accordion: {
      borderRadius: "12px",
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.16)",

      "&.tlp-MuiAccordion-rounded:last-child": {
        borderRadius: "12px",
      },
    },
    b2o: {
      background: "#f8f8f8",
      width: "40px",
      height: "18px",
      borderRadius: "9.5px",
      display: "flex",
      alignItems: "center",
      fontSize: "12px",
      justifyContent: "center",
      color: "#000000",
      fontWeight: "bold",
      marginRight: "24px",
    },
    divider: {
      borderBottom: `1px solid ${palette.grey[200]}`,
    },
    dropdown: {
      textAlign: "left",
    },
    loadingContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    errorContainer: {
      height: "40vh",
    },
  }),
);
