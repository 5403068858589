import React from "react";
import { Box, Grid } from "@material-ui/core";
import { useStore } from "effector-react";
import TabContent from "../components/tabContent/TabContent";
import B2BTrainerStyles from "./B2BTrainerStyles";
import AppSimulator from "../components/appSimulator/AppSimulator";
import EmulatorStore from "../../../stores/emulator/EmulatorStore";

const B2BTrainer: React.FunctionComponent = () => {
  const classes = B2BTrainerStyles();
  const { emulatorLink, isLoading } = useStore(EmulatorStore);

  return (
    <TabContent testId="b2b-trainer">
      {!emulatorLink && !isLoading && (
        <Box justifyContent="center" alignItems="center" width="100%">
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            className={classes.b2bTrainerContainer}
          >
            <AppSimulator />
          </Grid>
        </Box>
      )}
    </TabContent>
  );
};

export default B2BTrainer;
