import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";
import {
  LastRedeemConvertedReturn,
  LastRedeemResponse,
} from "@domains/redeem/Redeem";

import LastRedeemResponseToLastRedeemConverter from "../../converters/lastRedeem/LastRedeemResponseToLastRedeemConverter";
import { getApiHost, getEndPoint } from "../host/HostB2BService";

export const REWARDS_SERVICE = "/rewards-service/rewards";

export function getLastRedeem(
  pocId: string,
): Promise<LastRedeemConvertedReturn> {
  const log = useLogService();

  const endpoint = `${getApiHost()}${getEndPoint()}${REWARDS_SERVICE}`;
  return axios
    .get<LastRedeemResponse>(
      `${endpoint}/${pocId}/transactions?type=REDEMPTION&page=0&pageSize=1`,
    )
    .then((response) => {
      log.info(`Convert New Offerings received from ${endpoint} to our model`);
      return LastRedeemResponseToLastRedeemConverter(response?.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
