import { EmptyItem } from "domains/cart/EmptyItem";
import { Empty } from "domains/cart/Empty";
import { SelectedFreeGoods } from "domains/cart/SelectedFreeGoods";
import { CartInfo } from "../../domains/cart/CartInfo";
import { CartItem, TotalTrucks } from "../../domains/cart/CartItem";
import FreeGoods from "../../domains/cart/FreeGoods";

interface CartItemState {
  totalItems: number;
  done: boolean;
  isLoading: boolean;
  isLoadingBff: boolean;
  error: boolean;
  cart: CartInfo;
  cartItems: Array<CartItem>;
  shouldAddSuggested: boolean;
  totalPallets: number;
  maxVehicleCapacity?: number;
  totalTrucks?: TotalTrucks;
  empties: Array<Empty>;
  minimumOrder?: MinimumOrder;
  suggestedOrdersLoaded: boolean;
  promoItensLoaded: boolean;
  freeGoods: Array<FreeGoods>;
  selectedFreeGoods: Array<SelectedFreeGoods>;
  highlightTitle?: boolean;
  deleteIsLoading: boolean;
}

interface MinimumOrder {
  isBelowMinimumOrder?: boolean;
  minOrderMessage?: string;
}

/* istanbul ignore next line */
export const getQuantity = (item: EmptyItem, empties: Array<Empty>): number => {
  const itemQuantity =
    empties?.find((empty) => item.groupId === empty.groupId)?.quantity || 0;

  return itemQuantity < item.minimumRequired ||
    itemQuantity > (item.emptiesBalance ?? 0)
    ? item.quantity
    : itemQuantity;
};

export default CartItemState;
