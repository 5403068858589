import React from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import { Typography, IconButton } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import classNames from "classnames";
import { useStore } from "effector-react";
import PhonesInformationStyles from "./PhonesInformationStyles";
import Button from "../../../../../components/button/Button";
import {
  setIsModalOpen,
  getPhonesRanked,
} from "../../../../../stores/phoneRankingStore/phoneRankedEvents";
import ErrorRetry from "../../../../../components/errorRetry/ErrorRetry";
import { callUxButtonClicked } from "../../../../../config/typewriter";
import PhonesRankedStore from "../../../../../stores/phoneRankingStore/phoneRankedStore";
import PocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import CallTabStore from "../../../../../stores/navigation/callTab/CallTabStore";
import CTIStore from "../../../../../stores/cti/CtiStore";
import { ANALYTICS_ROUTE_NAMES } from "../../../../../config/constants";
import {
  getValueOrNull,
  getVendorNameWithFT,
  returnVendorIdWithFT,
} from "../../../../../config/utils/functions";
import Loading from "../../loading/Loading";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import GlobalAdminConfigStore from "../../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import {
  isFeatureEnabledV2,
  TLP_PHONE_RANKING_VENDOR,
} from "../../../../../config/featureToggles";

const PhonesInformation: React.FunctionComponent = () => {
  const classes = PhonesInformationStyles();
  const { t } = useTranslation();
  const { user } = useStore(GlobalStore);
  const { phones, error, loading } = useStore(PhonesRankedStore);
  const { pocInformation } = useStore(PocInformationStore);
  const agentCallState = useStore(AgentCallStore);
  const { callTab } = useStore(CallTabStore);
  const { isConnected } = useStore(CTIStore);
  const { userConfig } = GlobalAdminConfigStore.getState();

  const isPhoneRankingVendor = isFeatureEnabledV2(
    TLP_PHONE_RANKING_VENDOR,
    user.keyToggle,
  );

  const noItems = () => {
    return (
      <div>
        <Typography className={classes.noPhones}>
          {t("PHONE_VISUALIZATION_360.NO_PHONES")}
        </Typography>
      </div>
    );
  };

  const reloadPhones = () => {
    const vendorId = returnVendorIdWithFT(
      userConfig?.vendorId,
      isPhoneRankingVendor,
    );
    const vendorName = getVendorNameWithFT(vendorId, isPhoneRankingVendor);
    const phones = pocInformation?.phones ? [...pocInformation.phones] : [];
    getPhonesRanked({
      accountId: agentCallState.clientId,
      vendorId,
      vendorName,
      phones,
    });
  };

  const uniquePhone = phones?.length <= 1;

  const renderError = () => {
    return (
      <div className={classes.error}>
        <ErrorRetry
          onClick={reloadPhones}
          testId="error-phone-ranking-360-view"
          screenName={ANALYTICS_ROUTE_NAMES.get(callTab)}
          screenSection="POC Details - Phones"
          message={t("ErrorRetry.RETRY_INFORMATION_BUTTON_LABEL")}
          size="32px"
        />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className={classes.loading}>
        <Loading color="#F2F2F2" />
      </div>
    );
  };

  const renderType = (type: string) => {
    const types = type.replace(/\s+/g, "").split(",");

    if (types.length === 1) {
      return (
        <Typography className={classes.infos} key={`carousel-item-${type}`}>
          {t(`PHONE_VISUALIZATION_360.${type}`)}
        </Typography>
      );
    }

    return (
      <Typography className={classes.infos} key={`carousel-item-${type}`}>
        {types.map((type, index) =>
          index + 1 === types.length
            ? t(`PHONE_VISUALIZATION_360.${type}`)
            : `${t(`PHONE_VISUALIZATION_360.${type}`)}, `,
        )}
      </Typography>
    );
  };

  const handleEvent = (button: string | null, label: string) => {
    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: button,
      button_label: label,
      screen_section: "POC Details",
      filter_option: null,
      call_id: agentCallState.clientId,
      order_date: null,
      position: null,
      mission: null,
    });
  };

  const renderContent = () => {
    if (loading) {
      return renderLoading();
    }

    if (error) {
      return renderError();
    }

    return (
      <div>
        <Carousel
          autoPlay={false}
          className={classes.carousel}
          indicators={false}
          data-testid="carousel-phones-information"
          NavButton={({ prev, onClick }) => {
            return (
              <IconButton
                onClick={() => {
                  onClick();
                  handleEvent(
                    null,
                    `phone-info-carousel-${prev ? "prev" : "next"}-button`,
                  );
                }}
                className={classes.carouselButton}
                disabled={uniquePhone}
                data-testid={
                  prev
                    ? "carousel-phones-information-prev-button"
                    : "carousel-phones-information-next-button"
                }
              >
                {prev ? (
                  <ArrowBackIosIcon
                    className={classNames(classes.arrowIconsActive, {
                      [classes.arrowIconsInactive]: uniquePhone,
                    })}
                    data-testid="back-phones-button-icon"
                  />
                ) : (
                  <ArrowForwardIosIcon
                    className={classNames(classes.arrowIconsActive, {
                      [classes.arrowIconsInactive]: uniquePhone,
                    })}
                    data-testid="foward-phones-button-icon"
                  />
                )}
              </IconButton>
            );
          }}
        >
          {phones?.length === 0
            ? noItems()
            : phones?.map((item, index) => {
                return (
                  <div
                    key={`carousel-item-${item.phoneNumber}`}
                    className={classes.carouselItems}
                  >
                    <Typography className={classes.phoneTitle}>
                      {t("PHONE_VISUALIZATION_360.TITLE")} {index + 1}
                    </Typography>

                    <div className={classes.carouselItem}>
                      <Typography className={classes.infos}>
                        {item.phoneContact}
                      </Typography>
                      <Typography className={classes.phone}>
                        {item.phoneNumber}
                      </Typography>
                      {renderType(item.phoneType)}
                    </div>
                  </div>
                );
              })}
        </Carousel>

        {!isConnected && (
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              variant="outlined"
              onClick={() => {
                setIsModalOpen(true);
                handleEvent("Phone ranking", "open-phone-ranking-button");
              }}
              data-testid="phones-information-link"
            >
              {t("PHONE_RANKING.PHONE_CLASSIFICATION")}
            </Button>
          </div>
        )}
      </div>
    );
  };

  return <div className={classes.container}>{renderContent()}</div>;
};

export default PhonesInformation;
