import React, { useCallback, useEffect, useState } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import BeeLoading from "../beeLoading/BeeLoading";
import ServicesCard from "./components/ServicesCard/ServicesCard";
import ServiceComponentStyle from "./ServiceCatalogComponentStyle";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/v2/ErrorHandlerRetry";
import CollapsibleTitle from "../../../../components/collapsableTitle/CollapsibleTitle";
import GridIcon from "../../../../assets/newIcons/GridIcon";
import ServicesMissionsStore from "../../../../stores/servicesMission/ServicesMissionsStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import { getServicesMissionsEvent } from "../../../../stores/servicesMission/ServicesMissionsEvents";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import { callUxButtonClicked } from "../../../../config/typewriter";
import ServiceContent from "./components/ServicesContent/ServicesContent";
import LoyaltyProgramStore from "../../../../stores/loyaltyProgram/LoyaltyProgramStore";
import ServiceMissions from "../../../../domains/servicesMissions/ServicesMissions";
import {
  TLP_SERVICE_CONTENT_BELOW_CARD,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";

const ServiceComponent: React.FC = () => {
  const classes = ServiceComponentStyle();

  const { callId } = useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);

  const serviceContentBelowCard = isFeatureEnabledV2(
    TLP_SERVICE_CONTENT_BELOW_CARD,
    user.keyToggle,
  );

  const loyaltyProgramStates = useStore(LoyaltyProgramStore);
  const loadingLoyaltyProgram = loyaltyProgramStates.loading;
  const { servicesMissions, isLoading, error } = useStore(
    ServicesMissionsStore,
  );

  const processedServicesMissions: ServiceMissions = {};

  servicesMissions
    .filter((value) => value.availability)
    .forEach((serviceMission, index) => {
      processedServicesMissions[serviceMission.title] = {
        index,
        icon: serviceMission.icon,
        description: serviceMission.description,
        pricingMethod: serviceMission.pricingMethod,
        presetValue: serviceMission.presetValue,
      };
    });

  /* istanbul ignore next */
  const loyaltyProgramValue = loyaltyProgramStates.error
    ? undefined
    : loyaltyProgramStates.balance;

  const notEnrolled =
    loyaltyProgramStates.dateRegistered === undefined &&
    !loyaltyProgramStates.error;

  const { t } = useTranslation();
  const [missionOnFocus, setMissionOnFocus] = useState("");
  const [serviceMissionOpen, setServiceMissionOpen] = useState(true);

  const handleOpenCollapse = () => {
    const action = serviceMissionOpen ? "collapse" : "expand";
    callUxButtonClicked({
      button_label: action,
      button_name: `available-services-${action}`,
      call_id: callId,
      filter_option: null,
      screen_name: "Call - GAP and Status",
      screen_section: "Available services",
      mission: "Services",
      position: null,
      order_date: null,
    });
    setServiceMissionOpen(!serviceMissionOpen);
  };

  const refreshServicesMissions = useCallback(() => {
    getServicesMissionsEvent();
  }, []);

  useEffect(() => {
    refreshServicesMissions();
  }, [refreshServicesMissions]);

  const Content = () => {
    const Cards = Object.keys(processedServicesMissions).map((value) => {
      return (
        <ServicesCard
          key={value}
          title={value}
          icon={processedServicesMissions[value].icon}
          focus={missionOnFocus === value}
          onClickCallback={() => {
            callUxButtonClicked({
              button_label: value,
              button_name: "single-available-service",
              call_id: callId,
              filter_option: null,
              screen_name: "Call - GAP and Status",
              screen_section: "Available services",
              mission: "Services",
              position: null,
              order_date: null,
            });
            setMissionOnFocus(value);
          }}
        />
      );
    });

    if (error) {
      return (
        <div data-testId="services-component-error-retry-container">
          <ErrorHandlerRetry
            onClick={refreshServicesMissions}
            description={t("SERVICES_MISSION_TAB.REQUEST_FAILURE_MESSAGE")}
            event={{
              screenName: "Call - GAP and Status",
              screenSection: "Available services",
              errorType: "Information not found",
              errorMessage: "Information not found",
            }}
            className={classes.containerError}
          />
        </div>
      );
    }
    if (Object.keys(processedServicesMissions).length === 0) {
      return (
        <div
          data-testid="services-component-no-items"
          className={classes.centralizedContainer}
        >
          <Typography className={classes.noDataAvailable}>
            {t("SERVICES_MISSION_TAB.EMPTY_STATE")}
          </Typography>
        </div>
      );
    }
    if (serviceContentBelowCard) {
      return (
        <div
          className={classes.container}
          data-testid="services-container-cards"
        >
          {Cards}

          {processedServicesMissions[missionOnFocus] && (
            <ServiceContent
              key={missionOnFocus}
              title={missionOnFocus}
              line={Math.ceil(
                (processedServicesMissions[missionOnFocus].index + 1) / 4,
              )}
              serviceMission={processedServicesMissions[missionOnFocus]}
              onCloseCallback={() => setMissionOnFocus("")}
              notEnrolled={notEnrolled}
              loyaltyProgramValue={loyaltyProgramValue}
            />
          )}
        </div>
      );
    }
    return (
      <>
        <div className={classes.row} data-testid="services-row-cards">
          {Cards}
        </div>

        {processedServicesMissions[missionOnFocus] && (
          <ServiceContent
            key={missionOnFocus}
            title={missionOnFocus}
            line={Math.ceil(
              (processedServicesMissions[missionOnFocus].index + 1) / 4,
            )}
            serviceMission={processedServicesMissions[missionOnFocus]}
            onCloseCallback={() => setMissionOnFocus("")}
            notEnrolled={notEnrolled}
            loyaltyProgramValue={loyaltyProgramValue}
          />
        )}
      </>
    );
  };

  return (
    <div style={{ marginTop: 20 }}>
      <CollapsibleTitle
        icon={<GridIcon />}
        title={t("SERVICES_MISSION_TAB.AVAILABLE_SERVICES")}
        open={serviceMissionOpen}
        handleOpen={handleOpenCollapse}
        testId="available-services-collapsible-title"
        hasError={error}
        hasSeparator={false}
      >
        {isLoading || loadingLoyaltyProgram ? (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            data-testId="services-component-loading"
          >
            <BeeLoading testid="bee-loading-component" />
          </div>
        ) : (
          <Content />
        )}
      </CollapsibleTitle>
    </div>
  );
};

export default ServiceComponent;
