/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchedPoc } from "@domains/PocInformation";
import { Box, CircularProgress } from "@material-ui/core";
import { SalesRepresentativeResponse } from "@services/pocInformation/domains/SalesRepresentativeResponse";
import FulfillmentStore from "@stores/fulfillment/FulfillmentStore";
import { useStore } from "effector-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import SalesStructureContainer from "../../../../components/salesStructureContainer/SalesStructureContainer";
import { ANALYTICS_ROUTE_NAMES, LINK_CALL } from "../../../../config/constants";
import { errorMessageViewed } from "../../../../config/typewriter";
import SalesStructureItem from "./SalesStructureItem";
import { SalesStructureStyles } from "./SalesStructureStyles";

interface SalesStructureProps {
  pocInformation: SearchedPoc;
  isLoaded: boolean;
}

function SalesStructure({
  pocInformation,
  isLoaded,
}: Readonly<SalesStructureProps>): React.ReactElement {
  const classes = SalesStructureStyles();

  const { dispatchPocEvent } = useAnalytics();
  const { t } = useTranslation();

  const { fulfillment } = useStore(FulfillmentStore);

  const isContractless = fulfillment[0] && fulfillment[0].contractId === null;

  const {
    deliveryCenterId,
    salesOrg,
    salesAreaName,
    salesRegionCode,
    salesRegionName,
    salesSubregionCode,
    salesSubregionName,
    supervision,
    salesAreaRepName,
    salesZone,
    salesZoneName,
    distributionCenterName,
    additionalInformation,
  } = pocInformation;

  const salesRepresentative: SalesRepresentativeResponse | null =
    additionalInformation?.salesRepresentative?.[0] || null;

  /* istanbul ignore next */
  useEffect(() => {
    if (
      !salesRepresentative?.name ||
      !salesRepresentative?.phone ||
      !salesRepresentative?.email ||
      !pocInformation?.representatives
    ) {
      dispatchPocEvent(
        errorMessageViewed,
        {
          error_message: "No data available",
          error_type: "sales-structure-representative-item",
          screen_name: ANALYTICS_ROUTE_NAMES.get(LINK_CALL),
          screen_section: "Sales Structure",
          is_all_products: null,
          DDC: null,
          DDC_name: null,
          poc_id: null,
          total_gap: null,
          total_vol_gap: null,
        },
        { time_of_day: true, is_resumed: true },
      );
    }
  }, [
    dispatchPocEvent,
    pocInformation,
    salesRepresentative?.email,
    salesRepresentative?.name,
    salesRepresentative?.phone,
  ]);

  if (!isLoaded)
    return (
      <CircularProgress
        data-testid="loading-circle"
        size={50}
        className={classes.loading}
      />
    );

  return (
    <Box className={classes.gridContainer}>
      <Box className={classes.box}>
        <SalesStructureContainer>
          {!isContractless && (
            <SalesStructureItem
              titleId={t(
                "PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_DDC",
              )}
              code={deliveryCenterId}
              text={distributionCenterName}
              testId="sales-structure-ddc-item"
              dispatchEvent={dispatchPocEvent}
            />
          )}
        </SalesStructureContainer>

        <SalesStructureContainer>
          <div
            className={classes.representativeContainer}
            data-testId="sales-structure-representative-item"
          >
            <span
              className={classes.title}
              data-testId="sales-structure-representative-title-item"
            >
              {t("PocInformationHeaderTemplate.ADDITIONAL_INFORMATION_NAME")}
            </span>
            <span
              className={classes.text}
              data-testId="sales-structure-representative-name-item"
            >
              {salesRepresentative?.name ??
                pocInformation?.representatives?.name}
            </span>
            <span
              className={classes.text}
              data-testId="sales-structure-representative-phone-item"
            >
              {salesRepresentative?.phone ??
                pocInformation?.representatives?.phone}
            </span>
            <span
              className={classes.text}
              data-testId="sales-structure-representative-email-item"
            >
              {salesRepresentative?.email ??
                pocInformation?.representatives?.email}
            </span>
          </div>
        </SalesStructureContainer>
        {salesOrg || salesAreaName ? (
          <SalesStructureContainer>
            <SalesStructureItem
              titleId={t("SALES_STRUCTURE.SALES_ORGANIZATION")}
              code={salesOrg ?? salesAreaName}
              text={salesAreaName ?? salesOrg}
              testId="sales-structure-organization-item"
              dispatchEvent={dispatchPocEvent}
            />
          </SalesStructureContainer>
        ) : (
          <></>
        )}
        {salesRegionCode || salesRegionName ? (
          <SalesStructureContainer>
            <SalesStructureItem
              titleId={t("SALES_STRUCTURE.SALES_DIRECTION")}
              code={salesRegionCode}
              text={salesRegionName}
              testId="sales-structure-direction-item"
              dispatchEvent={dispatchPocEvent}
            />
          </SalesStructureContainer>
        ) : (
          <></>
        )}
        {salesSubregionCode || salesSubregionName ? (
          <SalesStructureContainer>
            <SalesStructureItem
              titleId={t("SALES_STRUCTURE.SALES_MANAGEMENT")}
              code={salesSubregionCode}
              text={salesSubregionName}
              testId="sales-structure-management-item"
              dispatchEvent={dispatchPocEvent}
            />
          </SalesStructureContainer>
        ) : (
          <></>
        )}
        {supervision || salesAreaRepName ? (
          <SalesStructureContainer>
            <SalesStructureItem
              titleId={t("SALES_STRUCTURE.SALES_SUPERVISON")}
              code={supervision}
              text={salesAreaRepName}
              testId="sales-structure-supervision-item"
              dispatchEvent={dispatchPocEvent}
            />
          </SalesStructureContainer>
        ) : (
          <></>
        )}
        {salesZone || salesZoneName ? (
          <SalesStructureContainer>
            <SalesStructureItem
              titleId={t("SALES_STRUCTURE.SALES_ZONE")}
              code={salesZone}
              text={salesZoneName}
              testId="sales-structure-zone-item"
              dispatchEvent={dispatchPocEvent}
            />
          </SalesStructureContainer>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}
export default React.memo(SalesStructure);
