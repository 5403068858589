import React from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import classNames from "classnames";
import { resetItemCart } from "@stores/cart/CartItemEvents";
import { resetCombos } from "@stores/cartCombo/CartComboEvents";
import { resetRewards } from "@stores/RewardsChallenge/RewardsChallengeEvents";
import {
  isFeatureEnabledV2,
  GROW_CALL_SUMMARY_NEW_REGISTER_BUTTONS,
  TLP_CALL_AUTO_DIALER,
} from "@config/featureToggles";
import Button from "@components/button/Button";
import { resetCreateSearchedPoc } from "@stores/searchedPoc/CreateSearchedPocEvents";
import GlobalStore from "@stores/global/GlobalStore";
import AgentCallStore from "@stores/agentCall/AgentCallStore";
import { EndCallActions, PocCallOrigin } from "@config/constants";
import {
  createCallDurationEffect,
  setCurrentPocCallDuration,
} from "@stores/callDuration/CallDurationEvent";
import GlobalAdminConfigStore from "@stores/globaAdminConfig/GlobalAdminConfigStore";
import useStyles from "./styles";

interface Props {
  actionAfterCall: string;
  isSearchByPocEnable: boolean;
  loading?: boolean;
  onChange: (action: string) => void;
  onClose: () => void;
  getTimer: () => number;
  hasMission?: boolean;
}

const FinalActions: React.FC<Props> = ({
  onChange,
  actionAfterCall,
  onClose,
  isSearchByPocEnable,
  loading,
  getTimer,
  hasMission,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useStore(GlobalStore);
  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);
  const agentCallState = useStore(AgentCallStore);
  const { pocCallOrigin } = agentCallState;

  const isNewRegisterButtonsEnabled = isFeatureEnabledV2(
    GROW_CALL_SUMMARY_NEW_REGISTER_BUTTONS,
    user.keyToggle,
  );

  const isAutodialerNewButtonsEnabled = isFeatureEnabledV2(
    TLP_CALL_AUTO_DIALER,
    user.keyToggle,
  );
  const isSearchByPoc = pocCallOrigin === PocCallOrigin.SEARCH_BY_POC;
  const isAutoDialer = pocCallOrigin === PocCallOrigin.AUTO_DIALER;
  const isGetNextClient = !isAutoDialer && !isSearchByPoc;
  const handleChange = (event: React.SyntheticEvent) => {
    onChange((event.target as HTMLInputElement).value);
  };

  const renderActionAfterCallLabel = () => {
    if (isNewRegisterButtonsEnabled) {
      if ((isAutoDialer && !isAutodialerNewButtonsEnabled) || isSearchByPoc) {
        return t("CallTypification.ACTION_AFTER_CALL_LABEL_V2_SEARCH_BY_POC");
      }
      return t("CallTypification.ACTION_AFTER_CALL_LABEL_V2");
    }
    return t("CallTypification.ACTION_AFTER_CALL_LABEL");
  };

  const renderRegisterButton = () => {
    return (
      <Button
        type="submit"
        loading={loading}
        className={classNames(classes.button, classes.submitButton)}
        data-testid="call-summary-submit-button"
      >
        {t("NEWCallSummary.REGISTER")}
      </Button>
    );
  };

  const renderCancelButton = () => {
    return (
      <Button
        onClick={() => {
          onClose();
        }}
        className={classNames(classes.button, classes.closeButton)}
        data-testid="call-summary-close-button"
      >
        {t("NEWCallSummary.CANCEL")}
      </Button>
    );
  };

  const handleCallDuration = () => {
    setCurrentPocCallDuration(getTimer());

    createCallDurationEffect({
      vendorId: GlobalAdminConfigState.userConfig.vendorId,
    }).catch(() => {
      // do nothing
    });
  };

  const renderGoHomeButton = () => {
    let translationButton = t("NEWCallSummary.GO_HOME");
    if (isAutodialerNewButtonsEnabled && isAutoDialer) {
      translationButton = t("NEWCallSummary.ACTION_TAKE_BREAK");
    }

    const goHomeButtonCondition =
      ((isAutoDialer || !isSearchByPoc) && isAutodialerNewButtonsEnabled) ||
      isGetNextClient;
    const goHomeButtonTypedPocCondition =
      isSearchByPoc && !isAutodialerNewButtonsEnabled;
    const endCallAction = isAutodialerNewButtonsEnabled
      ? EndCallActions.GO_HOME_TAKE_BREAK
      : EndCallActions.GO_HOME;

    return (
      <Button
        type="submit"
        loading={loading}
        className={classNames(classes.button, {
          [classes.goHomeButton]: goHomeButtonCondition,
          [classes.goHomeButtonTypedPoc]: goHomeButtonTypedPocCondition,
        })}
        data-testid="call-summary-go-home-button"
        onClick={(e) => {
          handleChange(e);
          resetCreateSearchedPoc();
          if (isAutoDialer) {
            handleCallDuration();
          }
        }}
        value={endCallAction}
      >
        {translationButton}
      </Button>
    );
  };

  const renderNextPocButton = () => {
    const checkNextPocButtonLength =
      t("NEWCallSummary.CALL_NEXT_POC").length > 13;

    const endCallAction =
      isAutodialerNewButtonsEnabled && !isGetNextClient
        ? EndCallActions.GO_HOME
        : EndCallActions.NEXT_POC;

    return (
      <Button
        type="submit"
        loading={loading}
        className={classNames(
          classes.button,
          classes.nextPocButton,
          checkNextPocButtonLength && classes.nextPocButtonWidth,
        )}
        data-testid="call-summary-next-poc-button"
        onClick={(e) => {
          handleChange(e);
          resetCreateSearchedPoc();
          resetItemCart();
          resetCombos();
          resetRewards();
          if (isAutoDialer) {
            handleCallDuration();
          }
        }}
        value={endCallAction}
      >
        {t("NEWCallSummary.CALL_NEXT_POC")}
      </Button>
    );
  };

  const renderActionButtons = () => {
    if (isNewRegisterButtonsEnabled) {
      if (
        (isSearchByPocEnable || !isAutodialerNewButtonsEnabled) &&
        isSearchByPoc
      ) {
        return renderGoHomeButton();
      }

      const shouldRenderNextPocButton =
        (isAutoDialer && isAutodialerNewButtonsEnabled) || isGetNextClient;
      return (
        <>
          {renderGoHomeButton()}
          {shouldRenderNextPocButton && renderNextPocButton()}
        </>
      );
    }

    return (
      <>
        {renderCancelButton()}
        {renderRegisterButton()}
      </>
    );
  };

  return (
    <Box
      className={classNames(
        classes.actionAfterCallContainer,
        isNewRegisterButtonsEnabled && classes.actionAfterCallContainerV2,
      )}
      style={!hasMission ? { flexWrap: "wrap", gap: "20px" } : undefined}
    >
      <div
        className={classNames(
          classes.label,
          isNewRegisterButtonsEnabled && classes.labelV2,
        )}
      >
        {renderActionAfterCallLabel()}
      </div>
      <Box
        className={classNames(
          classes.actionButtonsContainer,
          isNewRegisterButtonsEnabled && classes.actionButtonsContainerV2,
        )}
        style={
          hasMission && isNewRegisterButtonsEnabled
            ? { width: "inherit" }
            : undefined
        }
      >
        <Box className={classes.radioGroup}>
          {!isNewRegisterButtonsEnabled && (
            <FormControl component="fieldset" data-testid="actions-form">
              <RadioGroup
                aria-label="action-after-call"
                name="action-after-call"
                value={actionAfterCall}
                onChange={handleChange}
                className={classes.optionsContainer}
              >
                <FormControlLabel
                  value={EndCallActions.NEXT_POC}
                  control={<Radio className={classes.options} />}
                  label={t("NEWCallSummary.CALL_NEXT_POC")}
                  data-testid="action-next-poc-button"
                  classes={{ label: classes.options }}
                  disabled={isSearchByPocEnable && isSearchByPoc}
                />
                <FormControlLabel
                  value={EndCallActions.GO_HOME}
                  control={<Radio className={classes.options} />}
                  label={t("NEWCallSummary.GO_HOME")}
                  data-testid="action-go-home-button"
                  classes={{ label: classes.options }}
                  disabled={isSearchByPocEnable && isSearchByPoc}
                />
              </RadioGroup>
            </FormControl>
          )}
        </Box>
        <Box display="flex" flexDirection="row">
          {renderActionButtons()}
        </Box>
      </Box>
    </Box>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.actionAfterCall === nextProps.actionAfterCall &&
    prevProps.loading === nextProps.loading
  );
};

export default React.memo(FinalActions, areEqual);
