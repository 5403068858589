import React from "react";
import CollapsibleTitle from "../../../../components/collapsableTitle/CollapsibleTitle";
import LastOrderIcon from "../../../../assets/newIcons/LastOrdersIcon";
import LastOrder from "../lastOrders/LastOrders";
import Orders from "../../../../domains/Orders";
import RefreshTableButton from "../../../../components/refreshTableButton/RefreshTableButton";

interface MissionLastOrderContainerProps {
  title: string;
  lastOrderOpen: boolean;
  handleOpenLastOrder: () => void;
  error: boolean;
  refreshLastOrders: () => void;
  zone: string;
  titleUpdate: string;
  orders: Orders[];
  isLoading: boolean;
  errorFunction: () => void;
}

const MissionLastOrderContainer = ({
  title,
  refreshLastOrders,
  error,
  handleOpenLastOrder,
  lastOrderOpen,
  zone,
  titleUpdate,
  orders,
  isLoading,
  errorFunction,
}: MissionLastOrderContainerProps): JSX.Element => {
  return (
    <CollapsibleTitle
      icon={<LastOrderIcon />}
      title={title}
      testId="last-order-collapse"
      open={lastOrderOpen}
      handleOpen={handleOpenLastOrder}
      hasError={error}
      refreshButton={
        <RefreshTableButton
          refreshFunction={refreshLastOrders}
          titleUpdate={titleUpdate}
          buttonTestId="call-last-orders-refresh"
          containerTestId="call-last-orders-container-update"
        />
      }
    >
      <LastOrder
        keyToggle={zone}
        isInMissions
        orders={orders}
        isLoading={isLoading}
        error={error}
        errorFunction={errorFunction}
      />
    </CollapsibleTitle>
  );
};

export default MissionLastOrderContainer;
