import { CampaignB2OProducts } from "domains/CampaignB2OProducts";
import { getCampaignsProductsB2O } from "../../../services/campaigns/CampaignsProductsB2OService";

export const execute = (
  tag: string,
  country: string,
  accountId: string,
  vendorId?: string,
  vendorName?: string,
  isCampaignFocusVendorEnabled?: boolean,
  isMultiContractCampaignServiceEnabled?: boolean,
): Promise<CampaignB2OProducts> => {
  let vendorIdHeaderParam = "";
  let vendorNameHeaderParam = "";

  if (isMultiContractCampaignServiceEnabled) {
    vendorIdHeaderParam = vendorId || "";
  } else if (vendorId && vendorName && isCampaignFocusVendorEnabled) {
    vendorIdHeaderParam = vendorId;
    vendorNameHeaderParam = vendorName;
  }

  return getCampaignsProductsB2O(
    tag,
    country,
    accountId,
    vendorIdHeaderParam,
    vendorNameHeaderParam,
  );
};
