import { createStore } from "effector";
import {
  b2bTrainerEmulatorEffect,
  b2bTrainerDeleteEmulatorEffect,
  b2bTrainerGetEmulatorEffect,
} from "./EmulatorEvents";
import EmulatorState from "./EmulatorState";

const initialState = <EmulatorState>{
  error: false,
  isLoading: false,
  isLoaded: false,
  country: "",
  emulatorLink: "",
  publicKey: "",
};

const setEmulatorState = (state: EmulatorState) => {
  return {
    ...state,
    isLoading: true,
    isLoaded: false,
    error: false,
  };
};

const setEmulatorStateWhenFail = (state: EmulatorState) => {
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    error: true,
  };
};

const EmulatorStore = createStore(initialState)
  .on(b2bTrainerEmulatorEffect.done, (state, { result }) => {
    return {
      ...state,
      country: result.country,
      emulatorLink: result.emulatorURL,
      publicKey: result.publicKey,
      isLoading: false,
      isLoaded: true,
      error: false,
    };
  })
  .on(b2bTrainerEmulatorEffect, setEmulatorState)
  .on(b2bTrainerEmulatorEffect.fail, setEmulatorStateWhenFail)
  .on(b2bTrainerDeleteEmulatorEffect.done, () => {
    return initialState;
  })
  .on(b2bTrainerDeleteEmulatorEffect, setEmulatorState)
  .on(b2bTrainerDeleteEmulatorEffect.fail, (state) => ({
    ...state,
    isLoading: false,
    isLoaded: true,
    error: true,
  }))
  .on(b2bTrainerGetEmulatorEffect.done, (state, { result }) => {
    return {
      ...state,
      country: result.country,
      emulatorLink: result.emulatorURL,
      publicKey: result.publicKey,
      isLoading: false,
      isLoaded: !!result.emulatorURL,
      error: false,
    };
  })
  .on(b2bTrainerGetEmulatorEffect, (state) => ({
    ...state,
    isLoading: true,
    isLoaded: false,
    error: false,
  }))
  .on(b2bTrainerGetEmulatorEffect.fail, setEmulatorStateWhenFail);

export default EmulatorStore;
