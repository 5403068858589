import React from "react";
import styles from "./SkuClipBoardButton.styles";
import { FormatedItem } from "../../domains/promotions/FormatedPromotion";
import { FormatedFreeGoodsItem } from "../../domains/cart/FormatedFreeGoods";

type SkuClipBoardButtonProps = {
  item: FormatedItem | FormatedFreeGoodsItem;
  maxSkuLength?: number;
};

export default function SkuClipBoardButton({
  item,
  maxSkuLength = 8,
}: SkuClipBoardButtonProps): JSX.Element {
  const classes = styles();

  const renderCopySkuToClipBoardButton = (sku: string) => {
    return (
      <button
        className={classes.clipBoardButton}
        type="button"
        onClick={() => navigator.clipboard.writeText(item.sku)}
        data-testid="copy-to-clipboard-button"
      >
        {sku}
      </button>
    );
  };

  const formatDescription = () => {
    const formattedSku =
      item.sku.length > maxSkuLength
        ? `...${item.sku.substring(item.sku.length - maxSkuLength)}`
        : item.sku;

    return (
      <>
        {renderCopySkuToClipBoardButton(formattedSku)}
        {`• ${item.description}`}
      </>
    );
  };

  return <>{formatDescription()}</>;
}
