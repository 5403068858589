import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    box: {
      boxSizing: "border-box",
      height: "auto",
      padding: "0 14px 21px 0",
      display: "flex",
      flexDirection: "column",
      maxHeight: "445px",
      overflow: "auto",
    },
    title: {
      fontSize: "16px",
      fontWeight: "bold",
      color: palette.text.primary,
      paddingBottom: "8px",
      marginBottom: "16px",
      borderBottom: `1px solid ${palette.grey[200]}`,
    },
    labelText: {
      marginTop: "17px",
      marginBottom: "8px",
      fontSize: "14px",
      fontWeight: 600,
      color: palette.text.primary,
    },
    textarea: {
      resize: "none",
      height: "233px",
      borderRadius: "3px",
      border: `solid 1px ${palette.text.secondary}`,
      padding: "10px",
      outline: "none",
      "&:focus": {
        border: `solid 1px ${palette.common.black}`,
      },
    },
    label: {
      fontSize: "12px",
      fontWeight: 600,
      color: palette.text.secondary,
      display: "flex",
      flexDirection: "column",
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column",
    },
  }),
);
