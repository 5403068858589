import React from "react";
import { useStore } from "effector-react";
import classNames from "classnames";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import Ellipsis from "react-ellipsis-text";
import PocinformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_OUT_OF_STOCK_ORDER_CART,
  TLP_OUT_OF_STOCK_ORDER_CART_ADMIN,
  TLP_DISPLAY_STOCK_CATALOG,
  GROW_CART_SERVICE_V4,
} from "../../../../../config/featureToggles";
import DeleteIcon from "../../../../../assets/DeleteIcon";
import { CartItem as CartItemType } from "../../../../../domains/cart/CartItem";
import {
  changeItemQuantity,
  removeItem,
} from "../../../../../stores/cart/CartItemEvents";
import Data from "../../../../../components/data/Data";
import CartItemStyles from "./CartItemStyles";
import QuantityInput from "../../../../../components/quantityInput/QuantityInput";
import { productRemoved } from "../../../../../config/typewriter";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import {
  CURRENCY,
  MAX_VALUE_INPUT,
  MIN_VALUE_INPUT,
} from "../../../../../config/constants";
import { getValueEmpty } from "../../../../../config/utils/functions";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import SuggestedOrdersStore from "../../../../../stores/suggestedOrders/SuggestedOrdersStore";
import StockoutWarning from "../../../../../components/stockout/StockoutWarning";
import NoStock from "../../../../../components/stockout/NoStock";

interface Props {
  item: CartItemType;
  shouldUpdatePrice: () => void;
  position: number;
}

const CartItem: React.FunctionComponent<Props> = ({
  item,
  shouldUpdatePrice,
  position,
}) => {
  const classes = CartItemStyles();
  const { t } = useTranslation();
  const callStore = useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);
  const { suggestedOrders } = useStore(SuggestedOrdersStore);
  const GlobalStates = useStore(GlobalStore);
  const { pocInformation } = useStore(PocinformationStore);

  const isStockSuggested = isFeatureEnabled(
    TLP_OUT_OF_STOCK_ORDER_CART,
    TLP_OUT_OF_STOCK_ORDER_CART_ADMIN,
    GlobalStates.user.keyToggle,
  );

  const isDisplayStockCatalog = isFeatureEnabledV2(
    TLP_DISPLAY_STOCK_CATALOG,
    GlobalStates.user.keyToggle,
  );

  const isCartSimulationV4Enabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    GlobalStates.user.keyToggle,
  );

  const displayInventoryCountOrAvailabilityCount =
    item.availabilityCount ?? item.inventoryCount;

  const shouldDisplayNoStockWarning =
    isStockSuggested && displayInventoryCountOrAvailabilityCount === 0;

  const shouldDisplayStockoutWarning =
    (isStockSuggested &&
      displayInventoryCountOrAvailabilityCount === undefined) ||
    (displayInventoryCountOrAvailabilityCount ?? 0) < item.itemQuantity;

  const sendRemoveItemEvent = () => {
    productRemoved({
      call_id: callStore.callId,
      country: callStore.countryCode,
      currency: CURRENCY[user.zone]?.currency,
      name: getValueEmpty(item.itemName),
      original_quantity:
        suggestedOrders.find((el) => el.sku === item.sku)?.gapBoxes || null,
      poc_id: callStore.clientId,
      quantity: item.itemQuantity,
      sku: item.sku,
      is_out_of_stock: displayInventoryCountOrAvailabilityCount === 0,
      combo_id: null,
      is_combo: false,
      remove_method: "Remove Icon",
    });
  };
  return (
    <Grid item container direction="row" className={classes.container}>
      <Grid item className={classes.quantityContainer}>
        <QuantityInput
          maxValue={MAX_VALUE_INPUT}
          minValue={MIN_VALUE_INPUT}
          value={item.itemQuantity}
          notFound={item.notFound}
          data-testid="cart-item-input-quantity"
          onChange={(value) => {
            changeItemQuantity({
              ...item,
              itemQuantity: Number(value),
              maxVehicleCapacity: pocInformation.maxVehicleCapacity,
              position,
              toggle: isCartSimulationV4Enabled,
            });
            shouldUpdatePrice();
          }}
        />
      </Grid>
      <Grid item container direction="column" className={classes.title}>
        <Data value={item.itemName} className={classes.itemNameEllipsis} />
        <Grid container className={classes.noStyle}>
          {shouldDisplayNoStockWarning ? (
            <NoStock isDisplayStockCatalog={isDisplayStockCatalog} />
          ) : (
            <Grid>
              <Typography className={classes.skuTitle} variant="subtitle1">
                <Tooltip title={item.sku}>
                  <Ellipsis text={item.sku} length={40} />
                </Tooltip>
              </Typography>
              {isStockSuggested &&
                displayInventoryCountOrAvailabilityCount > 0 &&
                (isDisplayStockCatalog || shouldDisplayStockoutWarning) && (
                  <StockoutWarning
                    value={displayInventoryCountOrAvailabilityCount}
                    itemQuantity={item.itemQuantity}
                    isDisplayStockCatalogEnabled={isDisplayStockCatalog}
                  />
                )}
            </Grid>
          )}
          {item.notFound && (
            <Typography
              className={classes.cartItemNotFound}
              data-testid="not-found-title"
            >
              {t("OrderTaking.NOT_FOUND_CART")}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        container
        className={classes.cartItemPrice}
        alignItems="flex-start"
      >
        <Data
          value={item.total.toFixed(2)}
          type="currency"
          className={classNames(classes.price, {
            [classes.disabledLabel]: item.notFound === true,
          })}
        />
      </Grid>
      <Grid item className={classes.deleteButtonContainer}>
        <IconButton
          onClick={() => {
            removeItem({
              ...item,
              maxVehicleCapacity: pocInformation.maxVehicleCapacity,
              position,
              toggle: isCartSimulationV4Enabled,
            });
            shouldUpdatePrice();
            sendRemoveItemEvent();
          }}
          aria-label="delete"
          disabled={item.notFound === true}
          className={classNames(classes.deleteButton, {
            [classes.disabledLabel]: item.notFound === true,
          })}
          data-testid="delete-item"
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(CartItem);
