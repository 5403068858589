import React, { ReactNode, FunctionComponent } from "react";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import CollapsibleTableStyles from "./CollapsibleTableStyles";

import TableHead from "./TableHead";
import TableRow from "./TableRow";
import TableCell from "./TableCell";
import TableBody from "./TableBody";

interface Props {
  className?: string;
  children: ReactNode;
  testId: string;
}

const CollapsibleTable: FunctionComponent<Props> = ({
  className,
  children,
  testId,
}) => {
  const classes = CollapsibleTableStyles();
  return (
    <TableContainer
      component={Paper}
      className={`${classes.tableContainer} ${className}`}
      data-testid={`${testId}-table-container`}
    >
      <Table aria-label="collapsible table" data-testid={`${testId}-table`}>
        {children}
      </Table>
    </TableContainer>
  );
};

CollapsibleTable.defaultProps = {
  className: "",
};

export default CollapsibleTable;

export { TableHead, TableRow, TableCell, TableBody };
