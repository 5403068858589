import { createEffect, createEvent } from "effector";
import * as GetGlobalAndLocalMissionsUseCase from "@usecase/missions/GetGlobalAndLocalMissionsUseCase";
import * as GetMissionsPriorityUseCase from "@usecase/flexibleMissions/FlexibleGetMissionsPriorityUseCase";
import * as GetPrioritizedMissionsUseCase from "@usecase/prioritizedMissions/GetPrioritizedMissionsUseCase";
import { PocCallOrigin, LocalGlobalFilterEnum } from "@config/constants";
import {
  GROW_FLEXIBLE_MISSIONS,
  GROW_ORGANIZATION_ACTIVATION,
  isFeatureEnabledV2,
} from "@config/featureToggles";
import GlobalStore from "@stores/global/GlobalStore";

export const getTagsEvent = createEvent<Array<string>>("getTagsEvent");

export const getTabsEvent = createEvent<Array<string>>("getTabsEvent");

export const resetTags = createEvent<null>("resetTags");

interface GetGlobalAndLocalMissionsEventRequest {
  tagsList: string[];
  localMissionIds: string[];
  vendorAccountId: string;
  clientId: string;
  pocCallOrigin?: PocCallOrigin;
  vendorId?: string;
  vendorName?: string;
  LocalGlobalMissionsType?: LocalGlobalFilterEnum;
}

interface TagEventsResult {
  missions: string[];
  missionsTabs: string[];
}

export const getGlobalAndLocalMissionsEvent = createEffect({
  async handler(
    props: GetGlobalAndLocalMissionsEventRequest,
  ): Promise<TagEventsResult> {
    const { user } = GlobalStore.getState();

    const isOrganizationConfigEnabled = isFeatureEnabledV2(
      GROW_ORGANIZATION_ACTIVATION,
      user.keyToggle,
    );

    const isGrowFlexibleMissionsEnabled = isFeatureEnabledV2(
      GROW_FLEXIBLE_MISSIONS,
      user.keyToggle,
    );

    if (isOrganizationConfigEnabled) {
      return GetPrioritizedMissionsUseCase.execute(
        props.clientId,
        props.vendorId ?? "",
        props.LocalGlobalMissionsType,
      );
    }

    if (isGrowFlexibleMissionsEnabled) {
      return GetMissionsPriorityUseCase.execute(
        props.tagsList,
        props.localMissionIds,
        props.vendorAccountId,
        props.pocCallOrigin,
        props.vendorId,
      );
    }
    return GetGlobalAndLocalMissionsUseCase.execute(
      props.tagsList,
      props.localMissionIds,
      props.vendorAccountId,
      props.pocCallOrigin,
      props.vendorId,
      props.vendorName,
      props.LocalGlobalMissionsType,
    );
  },
});
