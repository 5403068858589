import { AxiosResponse } from "axios";
import { getPackageTypeByVendorItemId } from "../config/utils/functions";
import { OrdersResponse } from "../services/lastOrders/domains/OrdersResponse";
import { OrdersItemsResponse } from "../services/lastOrders/domains/OrdersItemsResponse";
import { OrdersComboItemsResponse } from "../services/lastOrders/domains/OrdersComboItemsResponse";
import LastOrders from "../domains/LastOrders";
import Orders from "../domains/Orders";
import OrdersItems from "../domains/OrdersItems";

export default function OrderResponseToLastOrdersConverter(
  response: AxiosResponse,
): LastOrders {
  const ordersResponse: Array<OrdersResponse> = response.data.content;

  const orders: Array<Orders> = [];

  ordersResponse.forEach((order) => {
    const itemsOrder = itemsByOrders(order.items, order.combos);

    const aux: Orders = {
      accountId: order.beesAccountId || order.accountId,
      orderNumber: order.orderNumber,
      status: order.status,
      createDate: order.placementDate,
      qtyProducts: itemsOrder.get("countItems"),
      updatedAt: order.audit?.updateAt || order.updatedAt,
      total: order.summary?.total || order.total,
      channel: order.channel,
      deliveryDate: order.delivery?.date || order.deliveryDate,
      deliveryMaxDate: order.delivery?.distributionCenter?.maxDays,
      items: itemsOrder.get("items"),
      paymentMethod: order.payment?.paymentMethod || order.paymentMethod,
    };

    orders.push(aux);
  });

  return {
    orders,
  };
}

const itemsByOrders = (
  orderItems: Array<OrdersItemsResponse>,
  orderCombos: Array<OrdersComboItemsResponse>,
) => {
  const mapItems = new Map();
  const countProducts = orderItems?.length || 0;

  const setQuantity = (value: number | string) => {
    return value;
  };

  const items: Array<OrdersItems> = [];
  if (orderItems?.length > 0) {
    orderItems.forEach((item: OrdersItemsResponse) => {
      const aux: OrdersItems = {
        productCode: item.vendorItemId || item.sku,
        productName: item.name,
        price: item.summaryItem?.price || item.price,
        quantity: setQuantity(item.quantity),
        totalPrice: item.summaryItem?.total || item.total,
        packageId: getPackageTypeByVendorItemId(item.vendorItemId ?? item.sku),
        packageName: item.package?.name,
      };

      items.push(aux);
    });
  }

  if (orderCombos?.length > 0) {
    orderCombos.forEach((combo: OrdersComboItemsResponse) => {
      const aux: OrdersItems = {
        productCode: combo.vendorComboId || combo.id,
        productName: combo.title,
        price: combo.price,
        quantity: setQuantity(combo.quantity),
        totalPrice: combo.price * Number(combo.quantity),
        packageId: getPackageTypeByVendorItemId(
          combo.vendorComboId ?? combo.id,
        ),
        packageName: combo.package?.name,
      };

      items.push(aux);
    });
  }

  const products = `${countProducts}`;

  mapItems.set("items", items);
  mapItems.set("countItems", products);

  return mapItems;
};
