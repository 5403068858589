import React, { useCallback, useEffect, useRef } from "react";

import classNames from "classnames";
import { useStore } from "effector-react";

import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_TOPLINEGAP_AUTOMATICALLY_ADD_SUGGESTED_ORDER,
  TLP_TOPLINEGAP_AUTOMATICALLY_ADD_SUGGESTED_ORDER_ADMIN,
  TLP_OFFERINGS_PROMOTIONS_ADD_TO_CART,
  GROW_UPGRADE_CATALOG_V3,
  GROW_SEARCH_CATALOG_V3,
  GROW_BEES_PRODUCT_SEARCH,
} from "../../../../config/featureToggles";
import { useCollapsibleSideNavContext } from "../../../../contexts/CollapsibleSideNavContext";
import { CatalogItem } from "../../../../domains/Catalog";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import pocInformationStore from "../../../../stores/callList/pocInformation/PocInformationStore";
import * as CartItemEvents from "../../../../stores/cart/CartItemEvents";
import CartItemStore from "../../../../stores/cart/CartItemStore";
import {
  getCatalogEffect,
  getCatalogV3MultipleEffect,
  setHasBeenAddedToDb,
} from "../../../../stores/catalog/CatalogEvents";
import CatalogStore from "../../../../stores/catalog/CatalogStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import SuggestedOrderStore from "../../../../stores/suggestedOrders/SuggestedOrdersStore";
import CatalogDb from "../../catalogDb/CatalogDb";
import { useUpdateCartHook } from "../../components/orderCart/hooks/useUpdateCartHook";
import OrderCart from "../../components/orderCart/OrderCart";
import SearchCatalog from "../../components/searchCatalog/SearchCatalog";
import OrderStyles from "./OrdersStyle";

const Orders: React.FunctionComponent = () => {
  const classes = OrderStyles();
  const { collapsibleIsOpen } = useCollapsibleSideNavContext();
  const { suggestedOrders, getSuggestedOrdersComplete } =
    useStore(SuggestedOrderStore);
  const { hasBeenAddedToDb } = useStore(CatalogStore);
  const GlobalStates = useStore(GlobalStore);
  const { pocInformation } = useStore(pocInformationStore);
  const hasBeenAddedToDbRefe = useRef(hasBeenAddedToDb);
  const catalogDb = useRef(new CatalogDb());
  const { clientId, legacyAccountId, tagsList } = AgentCallStore.getState();
  const {
    cartItems,
    shouldAddSuggested,
    promoItensLoaded,
    suggestedOrdersLoaded,
  } = useStore(CartItemStore);

  const { setNeedToUpdate } = useUpdateCartHook();

  const isEnabledProductSuggestedOrder = isFeatureEnabled(
    TLP_TOPLINEGAP_AUTOMATICALLY_ADD_SUGGESTED_ORDER,
    TLP_TOPLINEGAP_AUTOMATICALLY_ADD_SUGGESTED_ORDER_ADMIN,
    GlobalStates.user.keyToggle,
  );

  const isAddPromoItemEnabled = isFeatureEnabledV2(
    TLP_OFFERINGS_PROMOTIONS_ADD_TO_CART,
    GlobalStates.user.keyToggle,
  );

  const isCatalogV3Enabled = isFeatureEnabledV2(
    GROW_UPGRADE_CATALOG_V3,
    GlobalStates.user.keyToggle,
  );

  const isSearchCatalogV3Enabled = isFeatureEnabledV2(
    GROW_SEARCH_CATALOG_V3,
    GlobalStates.user.keyToggle,
  );

  const isBeesProductSearchEnabled = isFeatureEnabledV2(
    GROW_BEES_PRODUCT_SEARCH,
    GlobalStates.user.keyToggle,
  );

  const isMissionToplineGap = tagsList && tagsList.includes("#TopLineGAP");

  const getCatalogEffectVersion = useCallback(() => {
    return isSearchCatalogV3Enabled
      ? getCatalogV3MultipleEffect
      : getCatalogEffect;
  }, [isSearchCatalogV3Enabled]);

  useEffect(() => {
    // Create Catalog IndexedDB when getCatalog returns
    const unWatchGetCatalogDone = getCatalogEffectVersion().done.watch(
      ({ result }) => {
        catalogDb.current.catalog.bulkPut(result.items).then(() => {
          setHasBeenAddedToDb(true);
        });
      },
    );

    if (isBeesProductSearchEnabled) {
      setHasBeenAddedToDb(true);
    } else if (!hasBeenAddedToDbRefe.current) {
      /* istanbul ignore next */
      const accountInformation = isSearchCatalogV3Enabled
        ? clientId
        : legacyAccountId || clientId;
      getCatalogEffectVersion()(accountInformation);
    }

    /* istanbul ignore next */
    return () => {
      unWatchGetCatalogDone();
    };
  }, [
    clientId,
    catalogDb,
    isSearchCatalogV3Enabled,
    legacyAccountId,
    getCatalogEffectVersion,
    isBeesProductSearchEnabled,
  ]);

  /* istanbul ignore next */
  useEffect(() => {
    if (getSuggestedOrdersComplete && !suggestedOrdersLoaded) {
      if (
        isEnabledProductSuggestedOrder &&
        isMissionToplineGap &&
        suggestedOrders.length > 0
      ) {
        if (isCatalogV3Enabled) {
          const cartItems = suggestedOrders
            .filter((each) => each.gapBoxes > 0)
            .map((each) => {
              return {
                price: each.unitPrice,
                unitPrice: each.unitPrice,
                sku: each.sku,
                itemName: each.mc,
                itemQuantity: each.gapBoxes,
                returnable: false,
                notFound: false,
                segment: each.segment,
                originalQuantity: each.gapBoxes,
                total: each.totalPrice,
                inventoryCount: Number(each?.inventoryCount),
                palletQuantity: Number(each?.palletQuantity),
                maxVehicleCapacity: pocInformation.maxVehicleCapacity,
                totalTrucks: undefined,
                id: each?.id,
                platformId: each.itemPlatformId,
                availabilityCount: each?.availability?.count,
              };
            });

          CartItemEvents.setItemCart(cartItems);
          CartItemEvents.setShouldAddSuggested(false);
          CartItemEvents.setSuggestedOrdersLoaded(true);
          setNeedToUpdate(true);
        } else if (hasBeenAddedToDb && !isCatalogV3Enabled) {
          const suggestedOrdersSku = suggestedOrders.map((each) => {
            return each.sku;
          });
          let catalogValues: Array<CatalogItem>;
          catalogDb.current.catalog
            .where("sku")
            .anyOf(suggestedOrdersSku)
            .toArray()
            .then((result) => {
              catalogValues = result;
              const notFound = suggestedOrdersSku.filter((each) => {
                return !catalogValues.find((eachCatalogValue) => {
                  return eachCatalogValue.sku === each;
                });
              });
              const cartItems = suggestedOrders
                .filter((each) => each.gapBoxes > 0)
                .map((each) => {
                  return {
                    price: each.unitPrice,
                    unitPrice: each.unitPrice,
                    sku: each.sku,
                    itemName: each.mc,
                    itemQuantity: each.gapBoxes,
                    returnable: false,
                    notFound: notFound.includes(each.sku),
                    segment: each.segment,
                    originalQuantity: each.gapBoxes,
                    total: each.totalPrice,
                    inventoryCount: Number(
                      result.find((stock) => stock.sku === each.sku)
                        ?.inventoryCount,
                    ),
                    palletQuantity: Number(
                      result.find((item) => item.sku === each.sku)
                        ?.palletQuantity,
                    ),
                    maxVehicleCapacity: pocInformation.maxVehicleCapacity,
                    totalTrucks: undefined,
                    id: result.find((id) => id.sku === each.sku)?.id,
                    availabilityCount: Number(
                      result.find((stock) => stock.sku === each.sku)
                        ?.availabilityCount,
                    ),
                  };
                });
              CartItemEvents.setItemCart(cartItems);
              CartItemEvents.setShouldAddSuggested(false);
              CartItemEvents.setSuggestedOrdersLoaded(true);
              setNeedToUpdate(true);
            });
        }
      } else {
        CartItemEvents.setShouldAddSuggested(false);
        CartItemEvents.setSuggestedOrdersLoaded(true);
      }
    }
  }, [
    clientId,
    hasBeenAddedToDb,
    suggestedOrders,
    isEnabledProductSuggestedOrder,
    pocInformation,
    getSuggestedOrdersComplete,
    setNeedToUpdate,
    isMissionToplineGap,
    isCatalogV3Enabled,
    suggestedOrdersLoaded,
  ]);

  /* istanbul ignore next */
  useEffect(() => {
    if (isAddPromoItemEnabled && !promoItensLoaded) {
      if (hasBeenAddedToDb && !shouldAddSuggested) {
        const cartItemsSku = [...cartItems]
          .filter((item) => !item.palletQuantity)
          .map((each) => {
            return each.sku;
          });

        /* istanbul ignore else */
        if (cartItemsSku.length > 0)
          catalogDb.current.catalog
            .where("sku")
            .anyOf(cartItemsSku)
            .toArray()
            .then((result) => {
              const cartItemsUpdated = [...cartItems].map((each) => {
                const catalogItem = result.find(
                  (cItem) => cItem.sku === each.sku,
                );

                /* istanbul ignore else */
                if (!each.palletQuantity && catalogItem?.palletQuantity)
                  each.palletQuantity = Number(catalogItem.palletQuantity);

                return each;
              });

              CartItemEvents.setItemCart(cartItemsUpdated);
            });
        CartItemEvents.setPromoItensLoaded(true);
      }
    } else {
      CartItemEvents.setPromoItensLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBeenAddedToDb, shouldAddSuggested, isAddPromoItemEnabled]);

  return (
    <div className={classes.container}>
      <div
        className={classNames({
          [classes.searchWithCollapsibleOpen]: collapsibleIsOpen,
          [classes.searchWithCollapsibleClose]: !collapsibleIsOpen,
        })}
      >
        <SearchCatalog catalog={catalogDb.current.catalog} />
      </div>
      <div className={classes.cart}>
        <OrderCart />
      </div>
    </div>
  );
};

export default Orders;
