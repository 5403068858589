import { createEffect } from "effector";
import { execute } from "../../usecase/marketplace/MarketplaceUseCase";

interface GetMarketplaceDTO {
  country: string;
  vendorId?: string;
  orgId?: string;
  requestTraceId?: string;
}

export const getMarketplace = createEffect({
  async handler({
    country,
    vendorId,
    orgId,
    requestTraceId,
  }: GetMarketplaceDTO) {
    return execute(country, vendorId, orgId, requestTraceId);
  },
});
