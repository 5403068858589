import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import qs from "qs";

import axios from "../../config/axios/axiosInstance";
import { GROW_BFF_SERVICE, TLP_ACCOUNT_SERVICE } from "../../config/services";
import { howIsAgent } from "../../config/utils/functions";
import AccountsResponseToPocInformationConverter from "../../converters/AccountsResponseToPocInformationConverter";
import {
  AccountsResponseToSearchedPocConverter,
  AccountsResponseToSearchedPocConverterV2,
} from "../../converters/AccountsResponseToSearchedPocConverter";
import PocInformation, { SearchedPoc } from "../../domains/PocInformation";
import { getApiHost, getEndPoint } from "../host/HostB2BService";
import { getApiHost as getApiB2b } from "../host/HostService";
import { AccountsResponse } from "./domains/AccountsResponse";
import {
  GROW_ORGANIZATION_ACTIVATION,
  isFeatureEnabledV2,
} from "../../config/featureToggles";

const ACCOUNT_METADATA_ENDPOINT = "/account-business/accounts";
const FULFILLMENT_VALIDATION_POC_ENDPOINT = "/tlp-account/accounts";
const ACCOUNTS_ENDPOINT = "/accounts/";
const INFO = "/info";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1000;
  },
  retryCondition: (error) => {
    if (error) {
      return error.response?.status === 400 || error.response?.status === 401;
    }

    return false;
  },
});

export const findByAccountId = (
  accountId: string,
  vendorId?: string,
  isContractless = false,
  isSearchedByPoc = false,
): Promise<PocInformation | SearchedPoc> => {
  const log = useLogService();

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const mountUrl = () => {
    if (isOrganizationConfigEnabled) {
      return `${getApiB2b(
        GROW_BFF_SERVICE,
      )}${ACCOUNTS_ENDPOINT}${accountId}${INFO}`;
    }

    if (isContractless) {
      return `${getApiB2b(
        TLP_ACCOUNT_SERVICE,
      )}${FULFILLMENT_VALIDATION_POC_ENDPOINT}`;
    }

    return `${getApiHost()}${getEndPoint()}${ACCOUNT_METADATA_ENDPOINT}?accountId=${accountId}${createProjection(
      isSearchedByPoc,
    )}`;
  };

  const mountParams = () => {
    if (isOrganizationConfigEnabled) {
      return {
        headers: { vendorId },
      };
    }

    if (isContractless) {
      /* istanbul ignore next */
      return {
        params: vendorId ? howIsAgent(accountId, vendorId) : "",
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      };
    }

    return {};
  };

  return axios
    .get<AccountsResponse[] | AccountsResponse>(mountUrl(), mountParams())
    .then((response) => {
      log.info(
        `Get accounts by accountid where received from ${mountUrl()} to our model`,
      );

      if (isOrganizationConfigEnabled) {
        return AccountsResponseToSearchedPocConverterV2(
          response.data as AccountsResponse,
        );
      }

      if (isSearchedByPoc && !isContractless) {
        return AccountsResponseToSearchedPocConverter(
          response.data as AccountsResponse[],
        );
      }

      return AccountsResponseToPocInformationConverter(
        response.data as AccountsResponse[],
        isContractless,
      );
    })
    .catch((error) => {
      log.error("PocInformationService: ", error);
      throw error;
    });
};

/* istanbul ignore next */
function createProjection(isSearchedByPoc = false) {
  if (isSearchedByPoc) return "&projection=OWNER,NAME";

  return "&projection=FULL,METADATA,CONTACTS";
}
