import SuggestedOrdersDomainV2 from "@domains/suggestedOrders/SuggestedOrdersDomainV2";
import { createStore } from "effector";
import SuggestedOrdersDomain from "../../domains/suggestedOrders/SuggestedOrdersDomain";
import {
  SORT_DIRECTION,
  SUGGESTED_ORDER_FIELDS,
} from "../../routes/call/components/suggestedOrder/SuggestedOrderConstants";
import {
  getSuggestedOrders,
  getSuggestedOrdersDone,
  getSuggestedOrdersFail,
  getSuggestedOrdersFinally,
  resetSuggestedOrders,
  setOrderBy,
  setOrderDirection,
  setSuggestedOrders,
  setSuggestedOrdersTotals,
} from "./SuggestedOrdersEvents";
import SuggestedOrdersState from "./SuggestedOrdersState";

const initialState = <SuggestedOrdersState>{
  isLoading: false,
  isLoaded: false,
  error: false,
  suggestedOrders: [],
  totals: {
    bought: 0,
    suggested: 0,
    gap: 0,
    totalsLoaded: false,
  },
  orderBy: SUGGESTED_ORDER_FIELDS.GAP_BOXES,
  orderDirection: SORT_DIRECTION.DESC,
  totalSuggested: 0,
  targetSuggested: 0,
  getSuggestedOrdersComplete: false,
};

const SuggestedOrdersStore = createStore(initialState)
  .on(getSuggestedOrders, (state: SuggestedOrdersState) => ({
    ...state,
    isLoading: true,
    error: false,
  }))
  .on(
    getSuggestedOrdersDone,
    (
      state: SuggestedOrdersState,
      suggestedOrders: SuggestedOrdersDomain | SuggestedOrdersDomainV2,
    ) => ({
      ...state,
      ...suggestedOrders,
      isLoaded: true,
      isLoading: false,
      error: false,
    }),
  )
  .on(getSuggestedOrdersFail, (state: SuggestedOrdersState) => ({
    ...state,
    isLoaded: true,
    isLoading: false,
    error: true,
  }))
  .on(getSuggestedOrdersFinally, (state: SuggestedOrdersState) => ({
    ...state,
    getSuggestedOrdersComplete: true,
  }))
  .on(
    setSuggestedOrdersTotals,
    (state: SuggestedOrdersState, totals: SuggestedOrdersState["totals"]) => {
      return {
        ...state,
        totals,
        isLoaded: true,
        isLoading: false,
        error: false,
      };
    },
  )
  .on(
    setSuggestedOrders,
    (
      state: SuggestedOrdersState,
      suggestedOrders: SuggestedOrdersState["suggestedOrders"],
    ) => {
      return {
        ...state,
        suggestedOrders,
        isLoaded: true,
        isLoading: false,
        error: false,
      };
    },
  )
  .on(
    setOrderBy,
    (state: SuggestedOrdersState, orderBy: SuggestedOrdersState["orderBy"]) => {
      return {
        ...state,
        orderBy,
        isLoaded: true,
        isLoading: false,
        error: false,
      };
    },
  )
  .on(
    setOrderDirection,
    (
      state: SuggestedOrdersState,
      orderDirection: SuggestedOrdersState["orderDirection"],
    ) => {
      return {
        ...state,
        orderDirection,
        isLoaded: true,
        isLoading: false,
        error: false,
      };
    },
  )
  .reset(resetSuggestedOrders);

export default SuggestedOrdersStore;
