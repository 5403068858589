import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      borderRadius: "5px",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
      backgroundColor: palette.grey[50],
    },
  }),
);
