import { createStore } from "effector";
import {
  resetOrdersStore,
  setHasTechnicalProblem,
  setOrderReason,
  addOrdersInfoToList,
  setOrdersOnGetOpenCall,
} from "./OrdersEvents";
import OrdersState from "./OrdersState";

const initialState = <OrdersState>{
  ordersList: [],
  orderInfo: {
    orderId: "",
    orderReason: "",
  },
  hasTechnicalProblem: false,
};

const OrdersStore = createStore(initialState)
  .on(setOrderReason, (state: OrdersState, value: string) => {
    return {
      ...state,
      orderInfo: {
        orderId: "",
        orderReason: value,
      },
    };
  })
  .on(addOrdersInfoToList, (state: OrdersState, orderInfoValue) => {
    return {
      ...state,
      ordersList: [...state.ordersList, orderInfoValue],
    };
  })
  .on(setOrdersOnGetOpenCall, (state: OrdersState, ordersOnGetOpenCall) => {
    return {
      ...state,
      ordersList: ordersOnGetOpenCall.ordersList,
      hasTechnicalProblem: ordersOnGetOpenCall.hasTechnicalProblem,
    };
  })
  .on(setHasTechnicalProblem, (state: OrdersState, value: boolean) => ({
    ...state,
    hasTechnicalProblem: value,
  }))
  .reset(resetOrdersStore);

export default OrdersStore;
