import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles<Theme>(() =>
  createStyles({
    container: {
      display: "flex",
    },
    title: {
      fontSize: "16px",
      fontWeight: 500,
      padding: "10px 0 0 0",
      color: "#000000",
    },
    text: {
      fontSize: "12px",
      padding: "10px 0 10px 0",
      color: "#000000",
    },
  }),
);
