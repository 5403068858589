import * as React from "react";

function ToplineGap() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="174.916%" id="prefix__c">
          <stop stopColor="#9CFFFD" offset="0%" />
          <stop stopColor="#7CE5E3" offset="100%" />
        </linearGradient>
        <ellipse
          id="prefix__a"
          cx={24.682}
          cy={23.448}
          rx={24.682}
          ry={23.448}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1.645 1.563)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <g mask="url(#prefix__b)" fill="url(#prefix__c)">
            <path d="M0 0h49.365v46.896H0z" />
          </g>
        </g>
        <g fillRule="nonzero">
          <path
            d="M40.612 9.037v22.598L30.986 48.78H11.363c-.766 0-1.388-.59-1.388-1.319v-31.83c-.864-.521-1.388-1.425-1.388-2.398V9.037c0-1.457-1.243-2.638-2.777-2.638h-.04c-.79 0-1.424-.626-1.387-1.383.035-.71.678-1.255 1.426-1.255h29.248c3.068 0 5.554 2.362 5.554 5.276z"
            fill="#FFEBD0"
          />
          <path
            d="M40.612 9.037v22.598L30.986 48.78H25.34V3.761h9.718c3.068 0 5.554 2.362 5.554 5.276z"
            fill="#FFD7A0"
          />
          <path
            d="M9.975 16.95H3.033c-.767 0-1.388-.59-1.388-1.319V7.718c0-2.186 1.865-3.957 4.165-3.957s4.165 1.771 4.165 3.957v9.232z"
            fill="#FFCA80"
          />
          <path
            d="M29.505 21.083c0 1.72-1.16 3.186-2.777 3.73v1.546c0 .728-.622 1.319-1.388 1.319-.767 0-1.389-.591-1.389-1.32v-1.547a4.141 4.141 0 01-2.218-1.749c-.384-.63-.158-1.437.506-1.801.664-.365 1.513-.15 1.897.48.249.408.71.661 1.204.661.765 0 1.388-.592 1.388-1.319s-.623-1.319-1.388-1.319c-.154-.004-.316.014-.528-.032-2.048-.247-3.637-1.913-3.637-3.925 0-1.719 1.16-3.185 2.776-3.73v-1.546c0-.728.622-1.319 1.389-1.319.766 0 1.388.591 1.388 1.32v1.547a4.141 4.141 0 012.219 1.749c.384.63.157 1.438-.507 1.802-.663.365-1.513.15-1.897-.481a1.41 1.41 0 00-1.203-.66c-.766 0-1.389.591-1.389 1.318 0 .727.623 1.32 1.389 1.32h.3c.141 0 .277.02.405.056 1.962.32 3.46 1.946 3.46 3.9z"
            fill="#34C2BF"
          />
          <path
            d="M29.505 21.083c0 1.72-1.16 3.186-2.777 3.73v1.546c0 .728-.622 1.319-1.388 1.319v-5.276c.765 0 1.388-.592 1.388-1.319s-.623-1.319-1.388-1.319v-2.638h.3c.141 0 .277.02.405.057 1.962.32 3.46 1.946 3.46 3.9zm-4.165-6.595V9.212c.766 0 1.388.591 1.388 1.32v1.547a4.141 4.141 0 012.219 1.749c.384.63.157 1.438-.507 1.802-.663.365-1.513.15-1.897-.481a1.41 1.41 0 00-1.203-.66z"
            fill="#007A78"
          />
          <path
            d="M23.951 32.954h-8.33c-.767 0-1.388-.59-1.388-1.32 0-.728.621-1.318 1.388-1.318h8.33c.767 0 1.389.59 1.389 1.319 0 .728-.622 1.319-1.389 1.319zm0 5.276h-8.33c-.767 0-1.388-.591-1.388-1.32 0-.728.621-1.318 1.388-1.318h8.33c.767 0 1.389.59 1.389 1.319 0 .728-.622 1.319-1.389 1.319zm0 5.275h-8.33c-.767 0-1.388-.59-1.388-1.319 0-.728.621-1.319 1.388-1.319h8.33c.767 0 1.389.591 1.389 1.32 0 .728-.622 1.318-1.389 1.318z"
            fill="#34C2BF"
          />
          <path
            d="M47.646 30.316h-4.165c-.766 0-1.388.59-1.388 1.319v5.276h-5.554v-5.276c0-.728-.622-1.32-1.388-1.32h-4.165c-.767 0-1.389.592-1.389 1.32v15.827c0 .728.622 1.32 1.389 1.32h16.66c.767 0 1.389-.592 1.389-1.32V31.635c0-.728-.622-1.32-1.389-1.32z"
            fill="#FFB64C"
          />
          <path
            d="M49.035 31.635v15.827c0 .728-.622 1.32-1.389 1.32h-8.33V36.91h2.777v-5.276c0-.728.622-1.32 1.388-1.32h4.165c.767 0 1.389.592 1.389 1.32z"
            fill="#FF9100"
          />
          <path
            d="M43.481 30.316v6.595c0 .728-.622 1.319-1.388 1.319h-5.554c-.766 0-1.388-.591-1.388-1.32v-6.594h8.33z"
            fill="#FF0"
          />
          <path
            d="M43.481 30.316v6.595c0 .728-.622 1.319-1.388 1.319h-2.777v-7.914h4.165z"
            fill="#D8D81E"
          />
        </g>
      </g>
    </svg>
  );
}

export default ToplineGap;
