import React from "react";

export default function GridIcon(): JSX.Element {
  return (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M14,0 L8,0 C3.581722,0 0,3.581722 0,8 L0,14 C0,18.418278 3.581722,22 8,22 L14,22 C18.418278,22 22,18.418278 22,14 L22,8 C22,3.581722 18.418278,0 14,0 Z M18,14 C18,16.209139 16.209139,18 14,18 L8,18 C5.790861,18 4,16.209139 4,14 L4,8 C4,5.790861 5.790861,4 8,4 L14,4 C16.209139,4 18,5.790861 18,8 L18,14 Z M40,0 L34,0 C29.581722,0 26,3.581722 26,8 L26,14 C26,18.418278 29.581722,22 34,22 L40,22 C44.418278,22 48,18.418278 48,14 L48,8 C48,3.581722 44.418278,0 40,0 Z M44,14 C44,16.209139 42.209139,18 40,18 L34,18 C31.790861,18 30,16.209139 30,14 L30,8 C30,5.790861 31.790861,4 34,4 L40,4 C42.209139,4 44,5.790861 44,8 L44,14 Z M14,26 L8,26 C3.581722,26 0,29.581722 0,34 L0,40 C0,44.418278 3.581722,48 8,48 L14,48 C18.418278,48 22,44.418278 22,40 L22,34 C22,29.581722 18.418278,26 14,26 Z M18,40 C18,42.209139 16.209139,44 14,44 L8,44 C5.790861,44 4,42.209139 4,40 L4,34 C4,31.790861 5.790861,30 8,30 L14,30 C16.209139,30 18,31.790861 18,34 L18,40 Z M40,26 L34,26 C29.581722,26 26,29.581722 26,34 L26,40 C26,44.418278 29.581722,48 34,48 L40,48 C44.418278,48 48,44.418278 48,40 L48,34 C48,29.581722 44.418278,26 40,26 Z M44,40 C44,42.209139 42.209139,44 40,44 L34,44 C31.790861,44 30,42.209139 30,40 L30,34 C30,31.790861 31.790861,30 34,30 L40,30 C42.209139,30 44,31.790861 44,34 L44,40 Z"
          id="grid-79B6CE4F-16B6-49EA-9218-35497F3F3398"
        ></path>
      </defs>
      <g
        id="___Symbols"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
          transform="translate(8.000000, 8.000000)"
        >
          <mask id="grid-mask-2" fill="white">
            <use xlinkHref="#grid-79B6CE4F-16B6-49EA-9218-35497F3F3398"></use>
          </mask>
          <g id="Mask" fill-rule="nonzero"></g>
          <g
            mask="url(#grid-mask-2)"
            fill="#000000"
            fill-opacity="0.9"
            id="Rectangle"
          >
            <g transform="translate(-8.000000, -8.000000)">
              <rect x="0" y="0" width="64" height="64"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
