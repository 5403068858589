import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "effector-react";
import { v4 as guid } from "uuid";
import { getVendorName } from "grow-shared-services";

import { getCartIdEffect, resetItemCart } from "@stores/cart/CartItemEvents";
import { getPaymentMethodInformation } from "@stores/paymentsInformation/PaymentMethodInfoEvents";
import { DELIVERY_METHODS } from "@domains/fulfillment/Fulfillment";
import * as SuggestedOrdersUseCase from "@usecase/suggestedOrders/SuggestedOrdersUseCase";
import * as SuggestedOrdersEvents from "@stores/suggestedOrders/SuggestedOrdersEvents";
import * as PocInformationUseCase from "@usecase/pocInformation/PocInformationUseCase";
import * as LastOrdersUseCase from "@usecase/lastOrders/LastOrdersUseCase";
import AgentCallStore from "@stores/agentCall/AgentCallStore";
import GlobalStore from "@stores/global/GlobalStore";
import EmulatorStore from "@stores/emulator/EmulatorStore";
import PhoneRankedStore from "@stores/phoneRankingStore/phoneRankedStore";
import PocInformationStore from "@stores/callList/pocInformation/PocInformationStore";
import DeliveryWindowsStore from "@stores/deliveryWindows/DeliveryWindowsStore";
import { getRewardsChallengeEffectV2 } from "@stores/RewardsChallenge/RewardsChallengeEvents";

import {
  getRewardsEffect,
  getRewardsEffectV2,
} from "@stores/loyaltyProgram/LoyaltyProgramEvents";
import {
  getDeliveryWindowsEffect,
  setDeliveryMethod,
  setDeliveryRange,
} from "@stores/deliveryWindows/DeliveryWindowsEvents";
import {
  b2bTrainerDeleteEmulatorEffect,
  b2bTrainerGetEmulatorEffect,
} from "@stores/emulator/EmulatorEvents";
import { getPromotionsV3Effect } from "@stores/promotions/PromotionsEvents";
import { getCampaigns } from "@stores/campaigns/CampaignsEvents";
import {
  isFeatureEnabled,
  TLP_B2B_TRAINER,
  TLP_B2B_TRAINER_ADMIN,
  TLP_SUGGESTED_ORDER_HIDDEN,
  TLP_SUGGESTED_ORDER_HIDDEN_ADMIN,
  TLP_MULTI_VENDOR,
  TLP_MULTI_VENDOR_ADMIN,
  TLP_LAST_MARKETPLACE_ORDERS,
  TLP_LAST_MARKETPLACE_ORDERS_ADMIN,
  TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT,
  TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT_ADMIN,
  isFeatureEnabledV2,
  TLP_PHONE_RANKING_VENDOR,
  TLP_CAMPAIGN_FOCUS_VENDOR,
  TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
  TLP_MULTICONTRACT_CALL_LIST_SERVICE,
  GROW_BEES_ACCOUNTS_MULTICONTRACT,
  GROW_CART_SERVICE_V4,
  GROW_MULTICONTRACT_REWARDS,
  GROW_POC360_CREDIT_SERVICE,
  GROW_POC360_CONTRACTS,
  GROW_TECH_CART_BFF,
  GROW_ORGANIZATION_ACTIVATION,
  GROW_ALWAYS_SHOW_REWARDS_CHALLENGE,
} from "@config/featureToggles";
import { updateCartForm } from "@stores/cart/CartEvents";
import { PocCallOrigin, isCallRoute } from "@config/constants";
import {
  getValueEmpty,
  returnVendorId,
  getVendorNameWithFT,
  returnVendorIdWithFT,
  getUserInfos,
} from "@config/utils/functions";
import * as CallCounterUseCase from "@usecase/callCounter/CallCounterUseCase";
import { getPhonesRanked } from "@stores/phoneRankingStore/phoneRankedEvents";
import PaymentMethodInfoStore from "@stores/paymentsInformation/PaymentMethodInfoStore";
import { getLastMarketplaceOrders } from "@stores/lastMarketplaceOrders/LastMarketplaceOrdersEvents";
import { useMountMissions } from "@hooks/useMountMissions";
import { getFulfillmentEffect } from "@stores/fulfillment/FulfillmentEvents";
import { paymentMethodsEnum } from "./components/paymentMethod/PaymentMethodsEnum";
import CallMainPage from "./CallMainPage";

function CallRoute(): React.ReactElement {
  const history = useHistory();
  const { user } = useStore(GlobalStore);
  const AgentCallStates = useStore(AgentCallStore);
  const { pocInformation, isLoaded } = useStore(PocInformationStore);

  const { deliveryWindows } = useStore(DeliveryWindowsStore);
  const { done: hasPaymentMethodDone, paymentMethodInfo } = useStore(
    PaymentMethodInfoStore,
  );
  const { publicKey } = useStore(EmulatorStore);

  const { done: phoneRankedDone } = PhoneRankedStore.getState();

  const { vendorId, orgId } = getUserInfos();
  const vendorName = getVendorName(vendorId);

  useMountMissions(
    AgentCallStates.tagsList,
    AgentCallStates.localMissionIds,
    AgentCallStates.isLoaded,
    AgentCallStates.vendorAccountId,
    AgentCallStates.clientId,
    AgentCallStates.pocCallOrigin,
  );

  const b2bTrainerEnabled = isFeatureEnabled(
    TLP_B2B_TRAINER,
    TLP_B2B_TRAINER_ADMIN,
    user.keyToggle,
  );

  const isSuggestedOrderHidden = isFeatureEnabled(
    TLP_SUGGESTED_ORDER_HIDDEN,
    TLP_SUGGESTED_ORDER_HIDDEN_ADMIN,
    user.keyToggle,
  );

  const isMultiVendor = isFeatureEnabled(
    TLP_MULTI_VENDOR,
    TLP_MULTI_VENDOR_ADMIN,
  );

  const isMarketplaceLastOrders = isFeatureEnabled(
    TLP_LAST_MARKETPLACE_ORDERS,
    TLP_LAST_MARKETPLACE_ORDERS_ADMIN,
    user.zone,
  );

  const isMarketplaceLastOrdersEndpoint = isFeatureEnabled(
    TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT,
    TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT_ADMIN,
    user.zone,
  );

  const isPhoneRankingVendor = isFeatureEnabledV2(
    TLP_PHONE_RANKING_VENDOR,
    user.keyToggle,
  );

  const isCampaignFocusVendorEnabled = isFeatureEnabledV2(
    TLP_CAMPAIGN_FOCUS_VENDOR,
    user.keyToggle,
  );

  const isMultiContractCampaignServiceEnabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_CAMPAIGN_SERVICE,
    user.keyToggle,
  );

  const isCallListV2Enabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_CALL_LIST_SERVICE,
    user.keyToggle,
  );

  const isFindBeesContracts = isFeatureEnabledV2(
    GROW_BEES_ACCOUNTS_MULTICONTRACT,
    user.keyToggle,
  );

  const isDeliveryWindowsMultiContractEnabled = isFeatureEnabledV2(
    GROW_CART_SERVICE_V4,
    user.keyToggle,
  );

  const isGrowMulticontractRewardsEnabled = isFeatureEnabledV2(
    GROW_MULTICONTRACT_REWARDS,
    user.keyToggle,
  );

  const isGrowPoc360CreditEnabled = isFeatureEnabledV2(
    GROW_POC360_CREDIT_SERVICE,
    user.keyToggle,
  );

  const isGrowPoc360ContractEnabled = isFeatureEnabledV2(
    GROW_POC360_CONTRACTS,
    user.keyToggle,
  );

  const isCartBffEnabled = isFeatureEnabledV2(
    GROW_TECH_CART_BFF,
    user.keyToggle,
  );

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const isAlwaysShowRewardsChallengeEnable = isFeatureEnabledV2(
    GROW_ALWAYS_SHOW_REWARDS_CHALLENGE,
  );

  useEffect(() => {
    if (AgentCallStates.isLoaded && AgentCallStates.clientId) {
      /* istanbul ignore else */
      if (isMultiVendor) {
        CallCounterUseCase.execute(
          AgentCallStates.clientId,
          vendorId,
          vendorName,
        );
      } else {
        CallCounterUseCase.execute(AgentCallStates.clientId);
      }
    }
  }, [
    AgentCallStates.clientId,
    AgentCallStates.isLoaded,
    isMultiVendor,
    vendorId,
    vendorName,
  ]);

  useEffect(() => {
    if (isLoaded && pocInformation.id) {
      const phones = pocInformation?.phones ? [...pocInformation.phones] : [];

      if (!phoneRankedDone) {
        getPhonesRanked({
          accountId: AgentCallStates.clientId,
          vendorId: returnVendorIdWithFT(vendorId, isPhoneRankingVendor),
          vendorName: getVendorNameWithFT(vendorId, isPhoneRankingVendor),
          phones,
        });
      }
    }
  }, [
    AgentCallStates.clientId,
    isLoaded,
    isPhoneRankingVendor,
    phoneRankedDone,
    pocInformation.id,
    pocInformation.phones,
    vendorId,
  ]);

  useEffect(() => {
    if (
      isLoaded &&
      AgentCallStates.clientId &&
      isAlwaysShowRewardsChallengeEnable
    ) {
      getRewardsChallengeEffectV2({
        accountId: AgentCallStates.clientId,
        vendorId,
      });
    }
  }, [
    isLoaded,
    AgentCallStates.clientId,
    vendorId,
    isAlwaysShowRewardsChallengeEnable,
  ]);

  const callSugestedOrder = () => {
    /* istanbul ignore next */
    if (!isSuggestedOrderHidden) {
      if (isCallListV2Enabled) {
        SuggestedOrdersUseCase.execute(
          AgentCallStates.clientId,
          user.zone,
          vendorId,
          "",
        );
      } else {
        SuggestedOrdersUseCase.execute(
          AgentCallStates.clientId,
          user.zone,
          vendorId || "",
          vendorName || "",
        );
      }
    } else {
      SuggestedOrdersEvents.getSuggestedOrdersFinally(null);
    }
  };

  const callOrderService = () => {
    if (isMarketplaceLastOrdersEndpoint && isMarketplaceLastOrders) {
      getLastMarketplaceOrders(AgentCallStates.clientId);
    } else if (isMarketplaceLastOrders || isMarketplaceLastOrdersEndpoint) {
      getLastMarketplaceOrders(AgentCallStates.clientId);
      LastOrdersUseCase.execute(AgentCallStates.clientId, vendorId);
    } else {
      LastOrdersUseCase.execute(AgentCallStates.clientId, vendorId);
    }
  };

  const callCampaignsService = useCallback(() => {
    const body = {
      DDC: getValueEmpty(pocInformation.deliveryCenterId),
      accountId: AgentCallStates.clientId,
      segment: getValueEmpty(pocInformation.segment),
      isTypedPoc: AgentCallStates.isTypedPoc,
      PocCallOrigin: AgentCallStates.pocCallOrigin,
      country: user.zone,
      isCampaignFocusVendorEnabled,
      isMultiContractCampaignServiceEnabled,
      vendorAccountId: AgentCallStates.vendorAccountId,
    };

    const bodyMultiContract = {
      vendorId:
        isMultiContractCampaignServiceEnabled || isOrganizationConfigEnabled
          ? vendorId
          : returnVendorId(vendorId),
      vendorName:
        isMultiContractCampaignServiceEnabled || isOrganizationConfigEnabled
          ? undefined
          : vendorName,
    };
    const bodyOrganization = {
      orgId,
      requestTraceId: guid(),
      vendorId,
    };

    getCampaigns({
      ...body,
      ...bodyMultiContract,
      ...bodyOrganization,
    });
  }, [
    pocInformation.deliveryCenterId,
    pocInformation.segment,
    AgentCallStates.clientId,
    AgentCallStates.isTypedPoc,
    AgentCallStates.pocCallOrigin,
    AgentCallStates.vendorAccountId,
    isCampaignFocusVendorEnabled,
    isMultiContractCampaignServiceEnabled,
    vendorId,
    vendorName,
  ]);

  const loadCallMainPageData = useCallback(async () => {
    if (AgentCallStates.clientId) {
      window.localStorage.setItem("shouldDispatchPromotions", "true" as string);

      if (isGrowMulticontractRewardsEnabled) {
        getRewardsEffectV2(AgentCallStates.clientId);
      } else {
        getRewardsEffect(AgentCallStates.clientId);
      }

      getFulfillmentEffect(AgentCallStates.clientId);

      callOrderService();

      const unWatchGetPromotionsV3Effect = getPromotionsV3Effect.finally.watch(
        () => {
          callSugestedOrder();

          /* istanbul ignore next */
          if (typeof unWatchGetPromotionsV3Effect === "function") {
            unWatchGetPromotionsV3Effect();
          }
        },
      );

      SuggestedOrdersEvents.resetSuggestedOrders(null);

      if (b2bTrainerEnabled) {
        const info = await b2bTrainerGetEmulatorEffect({
          agentId: user.login,
          country: user.zone,
        });
        const createDateFormat = new Date(info.createDate);
        const today = new Date();
        if (createDateFormat.getDate() !== today.getDate()) {
          const deleteData = {
            publicKey,
            country: user.zone,
          };
          b2bTrainerDeleteEmulatorEffect(deleteData);
        }
      }
    }
  }, [
    AgentCallStates.clientId,
    b2bTrainerEnabled,
    callOrderService,
    callSugestedOrder,
    isGrowMulticontractRewardsEnabled,
    publicKey,
    user.login,
    user.zone,
  ]);

  const shouldUseBeesContracts = useCallback(() => {
    const isGetNextClient = !(
      AgentCallStates.pocCallOrigin === PocCallOrigin.SEARCH_BY_POC ||
      AgentCallStates.pocCallOrigin === PocCallOrigin.AUTO_DIALER
    );

    return isFindBeesContracts && isGetNextClient;
  }, [isFindBeesContracts, AgentCallStates.pocCallOrigin]);

  /* istanbul ignore next */
  const executeBeesContract = useCallback(
    (vendorId, isContractless = false) => {
      PocInformationUseCase.executeBeesContract(
        AgentCallStates.clientId,
        vendorId,
        isContractless,
      ).then(() => {
        if (isGrowPoc360CreditEnabled) {
          PocInformationUseCase.executeCreditPocInformation(
            AgentCallStates.clientId,
            vendorId,
          );
        }
      });
    },
    [AgentCallStates.clientId, isGrowPoc360CreditEnabled, vendorId, vendorId],
  );

  const executeDefault = useCallback(
    (vendorId, isContractless = false) => {
      const id =
        isOrganizationConfigEnabled || isContractless
          ? AgentCallStates.clientId
          : AgentCallStates.legacyAccountId ?? AgentCallStates.clientId;

      PocInformationUseCase.execute(id, vendorId, isContractless).then(() => {
        if (isGrowPoc360CreditEnabled) {
          PocInformationUseCase.executeCreditPocInformation(
            AgentCallStates.clientId,
            vendorId,
          );
        }
      });
    },
    [
      AgentCallStates.clientId,
      AgentCallStates.legacyAccountId,
      isGrowPoc360CreditEnabled,
      vendorId,
      vendorId,
    ],
  );

  /* istanbul ignore next */
  function getDeliveryMethod(fulfillment) {
    return fulfillment && fulfillment.length > 0
      ? fulfillment[0].vendorConfiguration?.checkout?.deliveryWindowMethod
      : DELIVERY_METHODS.DELIVERY_WINDOW;
  }

  function getDeliveryRange(fulfillment) {
    return fulfillment && fulfillment.length > 0
      ? fulfillment[0].deliveryTime
      : null;
  }

  const shouldGetDeliveryWindows = useCallback(
    (deliveryMethod) => {
      return isDeliveryWindowsMultiContractEnabled && deliveryMethod;
    },
    [isDeliveryWindowsMultiContractEnabled],
  );

  /* istanbul ignore next */
  const useFulfillment = useCallback(
    ({ result: fulfillment }) => {
      let isContractless = false;
      let deliveryMethod = DELIVERY_METHODS.DELIVERY_WINDOW;

      if (fulfillment[0] && isGrowPoc360ContractEnabled) {
        isContractless = fulfillment[0].contractId === null;
      }

      if (shouldUseBeesContracts()) {
        executeBeesContract(vendorId, isContractless);
      } else {
        executeDefault(vendorId, isContractless);
      }

      if (fulfillment && fulfillment.length > 0) {
        deliveryMethod = getDeliveryMethod(fulfillment);
        if (deliveryMethod) {
          setDeliveryMethod(deliveryMethod);
        }

        const deliveryRange = getDeliveryRange(fulfillment);
        if (deliveryRange) {
          setDeliveryRange(deliveryRange);
        }
      }
      if (shouldGetDeliveryWindows(deliveryMethod)) {
        getDeliveryWindowsEffect(AgentCallStates.clientId);
      }
    },
    [
      isGrowPoc360ContractEnabled,
      vendorId,
      vendorId,
      shouldUseBeesContracts,
      executeBeesContract,
      executeDefault,
      shouldGetDeliveryWindows,
      AgentCallStates.clientId,
    ],
  );

  const useFulfillmentFail = useCallback(() => {
    if (shouldUseBeesContracts()) {
      executeBeesContract(vendorId);
    } else {
      executeDefault(vendorId);
    }

    if (isDeliveryWindowsMultiContractEnabled) {
      getDeliveryWindowsEffect(AgentCallStates.clientId);
    }
  }, [
    vendorId,
    shouldUseBeesContracts,
    executeBeesContract,
    executeDefault,
    isDeliveryWindowsMultiContractEnabled,
    AgentCallStates.clientId,
  ]);

  /* istanbul ignore next */
  useEffect(() => {
    const unwatchGetFulfillmentEffectDone =
      getFulfillmentEffect.done.watch(useFulfillment);
    const unwatchGetFulfillmentEffectFail =
      getFulfillmentEffect.fail.watch(useFulfillmentFail);

    return () => {
      unwatchGetFulfillmentEffectDone();
      unwatchGetFulfillmentEffectFail();
    };
  }, [useFulfillment, useFulfillmentFail]);

  useEffect(() => {
    if (isCallRoute(history.location.pathname)) {
      loadCallMainPageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, AgentCallStates.clientId]);

  useEffect(() => {
    if (isLoaded && pocInformation.id) {
      getPromotionsV3Effect({
        accountId: AgentCallStates.clientId,
        country: user.zone,
        vendorName,
        vendorAccountId: AgentCallStates.vendorAccountId,
        legacyAccountId: AgentCallStates.legacyAccountId,
      });

      if (isCartBffEnabled) {
        resetItemCart();
        getCartIdEffect({
          accountId: AgentCallStates.clientId,
          vendorId,
        });
      }

      callCampaignsService();
    }
  }, [
    isLoaded,
    AgentCallStates.clientId,
    AgentCallStates.vendorAccountId,
    user.zone,
    vendorName,
    AgentCallStates.legacyAccountId,
    callCampaignsService,
    vendorId,
    isCartBffEnabled,
    pocInformation.id,
  ]);

  useEffect(() => {
    if (AgentCallStates.isLoaded && AgentCallStates.clientId) {
      getPaymentMethodInformation({
        accountId: AgentCallStates.clientId,
        vendorId,
      });
    }
  }, [AgentCallStates.clientId, vendorId, AgentCallStates.isLoaded]);

  // Set deliveryDate using pocInformation.deliveryWindows
  useEffect(() => {
    if (!isLoaded || !hasPaymentMethodDone) {
      return;
    }
    const paymentInitial = () => {
      const { paymentMethods, groupedPaymentMethodInfos } = paymentMethodInfo;

      if (!paymentMethods?.length) {
        return { paymentMethod: "", paymentMethodCode: "" };
      }

      const paymentMethodKey = paymentMethods.includes(paymentMethodsEnum.CASH)
        ? paymentMethodsEnum.CASH
        : paymentMethods[0];

      const { paymentMethod, vendorPaymentMethod: paymentMethodCode } =
        groupedPaymentMethodInfos[paymentMethodKey][0];

      return {
        paymentMethod,
        paymentMethodCode,
      };
    };

    if (isDeliveryWindowsMultiContractEnabled) {
      let initialDeliverDate = "";
      let initialDeliverId = "";
      if (deliveryWindows && deliveryWindows.length > 0) {
        initialDeliverDate = deliveryWindows[0].deliveryDate;
        initialDeliverId = deliveryWindows[0].deliveryWindowId;
      }

      updateCartForm({
        paymentTerm: 0,
        details: "",
        deliveryDate: initialDeliverDate,
        deliveryWindowId: initialDeliverId,
        ...paymentInitial(),
      });

      return;
    }

    const initialDeliverDate =
      pocInformation.deliveryWindows &&
      pocInformation.deliveryWindows.length > 0
        ? pocInformation.deliveryWindows[0].startDate
        : "";
    updateCartForm({
      paymentTerm: 0,
      details: "",
      deliveryDate: initialDeliverDate,
      ...paymentInitial(),
    });
  }, [
    pocInformation.deliveryWindows,
    pocInformation.paymentMethods,
    isDeliveryWindowsMultiContractEnabled,
    AgentCallStates.clientId,
    deliveryWindows,
    isLoaded,
  ]);

  return <CallMainPage />;
}

export default CallRoute;
