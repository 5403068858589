import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

const backDropLoadStyles = makeStyles((theme: Theme) =>
  createStyles({
    BackDrop: {
      zIndex: theme.zIndex.drawer + 999,
      color: theme.palette.primary.main,
    },
    circular: {
      color: "dimgray",
    },
  }),
);

export default backDropLoadStyles;
