/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import CollapsibleTitle from "../../../../components/collapsableTitle/CollapsibleTitle";
import FocusSkuIcon from "../../../../assets/newIcons/FocusSkuIcon";
import CampaignStore from "../../../../stores/campaigns/CampaignsStore";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import PocInformationStore from "../../../../stores/callList/pocInformation/PocInformationStore";
import { addItem } from "../../../../stores/cart/CartItemEvents";
import RecommendedProductsStore from "../../../../stores/recommendedMarketplaceProducts/RecommendedMarketplaceProductsStore";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_MARKETPLACE_INVENTORY_AND_ENFORCEMENT,
  TLP_MARKETPLACE_PROMO_VISIBILITY,
  TLP_MARKETPLACE_PROMO_VISIBILITY_ADMIN,
  TLP_RECOMENDED_PRODUCTS_STOCK,
  TLP_RECOMENDED_PRODUCTS_STOCK_ADMIN,
  TLP_RECOMMENDED_PRODUCTS_ADD_CART,
  TLP_RECOMMENDED_PRODUCTS_ADD_CART_ADMIN,
  TLP_RECOMMENDED_PRODUCTS_DISPLAY_PROMO_PRICE,
  TLP_RECOMMENDED_PRODUCTS_ORDER_PAGE_LINK,
  TLP_RECOMMENDED_PRODUCTS_ORDER_PAGE_LINK_ADMIN,
  GROW_MARKETPLACE_CARD_V3,
} from "../../../../config/featureToggles";
import ProductCardV3 from "../../../../components/ProductCardV3/ProductCardV3";
import ProductCardV2 from "../../../../components/ProductCardV2/ProductCardV2";
import { getRecommendedMarketplaceProductsEffect } from "../../../../stores/recommendedMarketplaceProducts/RecommendedMarketplaceProductsEvents";
import MissionRecommendContainerStyles from "./MissionRecommendContainer.styles";
import { useAnalytics } from "../../../../analytics/useAnalytics";
import {
  marketplaceRecommendedProductsLoaded,
  marketplaceProductAdded,
  marketplaceProductQuantityEdited,
} from "../../../../config/typewriter";
import ErrorHandlerRetry from "../../../../components/errorHandlerRetry/ErrorHandlerRetry";
import { ANALYTICS_ROUTE_NAMES, LINK_CALL } from "../../../../config/constants";

interface MissionRecommendContainerProps {
  title: string;
  recommendProductsOpen: boolean;
  handleOpenRecommendProducts: () => void;
}

const MissionRecommendContainer = ({
  title,
  recommendProductsOpen,
  handleOpenRecommendProducts,
}: MissionRecommendContainerProps): JSX.Element => {
  const { dispatchGenericEvent } = useAnalytics();
  const { t } = useTranslation();
  const { done: isCampaignDone } = useStore(CampaignStore);
  const { clientId: accountId, callId } = useStore(AgentCallStore);
  const {
    user: { zone, keyToggle },
  } = useStore(GlobalStore);
  const { recommendedProducts, loading, error, done } = useStore(
    RecommendedProductsStore,
  );
  const { pocInformation } = useStore(PocInformationStore);
  const classes = MissionRecommendContainerStyles();

  const isMarketPlaceV3Enabled = isFeatureEnabledV2(
    GROW_MARKETPLACE_CARD_V3,
    zone,
  );

  const isAddCartActionsEnabled = isFeatureEnabled(
    TLP_RECOMMENDED_PRODUCTS_ADD_CART,
    TLP_RECOMMENDED_PRODUCTS_ADD_CART_ADMIN,
    zone,
  );

  const isAddedTextOrderLinkEnabled = isFeatureEnabled(
    TLP_RECOMMENDED_PRODUCTS_ORDER_PAGE_LINK,
    TLP_RECOMMENDED_PRODUCTS_ORDER_PAGE_LINK_ADMIN,
    zone,
  );

  const isPromoVisibilityEnabled = isFeatureEnabled(
    TLP_MARKETPLACE_PROMO_VISIBILITY,
    TLP_MARKETPLACE_PROMO_VISIBILITY_ADMIN,
    zone,
  );

  const isRecommendedStockEnabled = isFeatureEnabled(
    TLP_RECOMENDED_PRODUCTS_STOCK,
    TLP_RECOMENDED_PRODUCTS_STOCK_ADMIN,
    zone,
  );

  const isRecommendedProductsDisplayPromoPriceEnabled = isFeatureEnabledV2(
    TLP_RECOMMENDED_PRODUCTS_DISPLAY_PROMO_PRICE,
    zone,
  );

  const isMarketplaceInventoryAndEnforcementEnabled = isFeatureEnabledV2(
    TLP_MARKETPLACE_INVENTORY_AND_ENFORCEMENT,
    keyToggle,
  );

  /* istanbul ignore next */
  const displayAvailabilityCountorInventorycount = (
    inventoryCount: number,
    availabilityCount: number,
  ) => {
    if (isMarketplaceInventoryAndEnforcementEnabled && inventoryCount !== -1) {
      return availabilityCount;
    }
    return inventoryCount;
  };

  /* istanbul ignore next */
  useEffect(() => {
    const unwatchGetRecommendedMarketplaceProducts =
      getRecommendedMarketplaceProductsEffect.done.watch(({ result }) => {
        const productsMapped = result.map((item) => ({
          sku: item.sku,
          priority: item.isRecommended ? item.priority : null,
          is_out_of_stock:
            displayAvailabilityCountorInventorycount(
              item.stock ?? 0,
              item.availability?.count || 0,
            ) <= 0,
          is_focus_sku: item.isFocus,
          is_recommended: item.isRecommended,
          is_promotion: item.isPromotion,
        }));
        dispatchGenericEvent(marketplaceRecommendedProductsLoaded, {
          call_id: callId,
          poc_id: accountId,
          products: productsMapped,
        });
      });

    return () => {
      if (typeof unwatchGetRecommendedMarketplaceProducts === "function") {
        unwatchGetRecommendedMarketplaceProducts();
      }
    };
  }, [
    accountId,
    callId,
    dispatchGenericEvent,
    displayAvailabilityCountorInventorycount,
  ]);

  useEffect(() => {
    if (isCampaignDone) {
      getRecommendedMarketplaceProductsEffect({ accountId, country: zone });
    }
  }, [accountId, isCampaignDone, zone]);

  const onRetryHandler = () => {
    getRecommendedMarketplaceProductsEffect({ accountId, country: zone });
  };

  const renderRecommendedContent = () => {
    if (!recommendedProducts.length) {
      return (
        <Box data-testid="no-recommed-products">
          <Typography className={classes.noItemsTypography} variant="h2">
            {t("Marketplace.NO_RECOMMEND_PRODUCTS_FOUND")}
          </Typography>
        </Box>
      );
    }

    return recommendedProducts.map((item, index) => {
      const addToCardAnalyticsObject = (itemQuantity: number) => {
        /* istanbul ignore next */
        return {
          name: item.name,
          quantity: itemQuantity,
          sku: item.sku ?? "",
          call_id: callId,
          poc_id: accountId,
          is_out_of_stock:
            displayAvailabilityCountorInventorycount(
              item.inventoryCount,
              item.availability?.count || 0,
            ) === 0,
          priority: item.priority,
          is_focus_sku: Boolean(item.isFocus),
          is_recommended: Boolean(item.isRecommended),
          is_promotion: item.isPromotion,
        };
      };

      const handleItemChangeAnalytics = () => {
        marketplaceProductQuantityEdited({
          call_id: callId,
          poc_id: accountId,
        });
      };

      const handleAddToCard = (itemQuantity: number) => {
        const productItemId = item.id || item.sku;
        /* istanbul ignore next */
        const finalItem = {
          id: productItemId,
          pricePerUoM: item.pricePerUoM,
          price: item.unitPrice ?? 0,
          unitPrice: item.unitPrice ?? 0,
          sku: productItemId ?? "",
          itemName: item.name,
          itemQuantity,
          returnable: false,
          notFound: false,
          segment: "",
          total: (item.unitPrice ?? 0) * itemQuantity,
          inventoryCount: displayAvailabilityCountorInventorycount(
            item.inventoryCount ?? 0,
            item.availability?.count || 0,
          ),
          palletQuantity: item.palletQuantity,
          availabilityCount: item.availability?.count,
          platformId: item.platformId,
        };

        addItem({
          ...finalItem,
          maxVehicleCapacity: pocInformation.maxVehicleCapacity ?? 0,
        });
        marketplaceProductAdded(addToCardAnalyticsObject(itemQuantity ?? 0));
      };

      /* istanbul ignore else */
      if (isMarketPlaceV3Enabled) {
        return (
          <>
            <div className={classes.productCardAndHorizontalDivider}>
              {index > 1 && <Divider />}
              <ProductCardV3
                data-testid={`${item.sku}-recommended-product-card`}
                product={item}
                promotion={item.promotion}
                showPromoTag
                showPromoPrice
                isMarketplaceProduct
                itemQuantityChangeAnalytics={handleItemChangeAnalytics}
              />
            </div>
            {(index + 1) % 2 !== 0 && (
              <Divider
                orientation="vertical"
                className={classes.verticalDivider}
              />
            )}
          </>
        );
      }
      return (
        <Grid
          item
          xs={6}
          key={item.sku}
          data-testid={`${item.sku}-recommended-item-container`}
        >
          <ProductCardV2
            data-testid={`${item.sku}-recommended-product-card`}
            product={item}
            description={item.name}
            unitPrice={item.unitPrice}
            isPromotion={item.isPromotion}
            promotion={item.promotion}
            showStock={isRecommendedStockEnabled}
            showAddButton={isAddCartActionsEnabled}
            showAddedInfoText={isAddedTextOrderLinkEnabled}
            showPromoTag={isPromoVisibilityEnabled}
            showPromoPrice={isRecommendedProductsDisplayPromoPriceEnabled}
            addAction={(itemQuantity) => handleAddToCard(itemQuantity)}
            itemQuantityChangeAnalytics={handleItemChangeAnalytics}
            hasStyleBorder
          />
        </Grid>
      );
    });
  };

  return (
    <CollapsibleTitle
      icon={<FocusSkuIcon />}
      title={title}
      testId="recommended-products-collapse"
      open={recommendProductsOpen}
      handleOpen={handleOpenRecommendProducts}
      hasError={error}
    >
      {(loading || !isCampaignDone) && (
        <Box
          className={classes.content}
          data-testid="recommended-products-container-loading"
        >
          <CircularProgress data-testid="recommended-loading" />
        </Box>
      )}
      {error && (
        <Box className={classes.content}>
          <ErrorHandlerRetry
            onClick={onRetryHandler}
            testId="missions-recommend-products"
            screenName={ANALYTICS_ROUTE_NAMES.get(LINK_CALL)}
            screenSection="Marketplace Recommended Products"
          />
        </Box>
      )}
      {done && isCampaignDone && !error && (
        <Grid className={classes.container} container xs={12} spacing={1}>
          {isMarketPlaceV3Enabled ? (
            <div className={classes.productsContainer}>
              {renderRecommendedContent()}
            </div>
          ) : (
            renderRecommendedContent()
          )}
        </Grid>
      )}
    </CollapsibleTitle>
  );
};

export default MissionRecommendContainer;
