import { isFeatureEnabled } from "grow-shared-services";
import { GROW_ORGANIZATION_ACTIVATION } from "@config/featureToggles";
import { getMissionsV3 } from "@services/missions/getMissionsV3/getMissionsV3";
import { getSponsoredMissionsService } from "../../services/sponsored/getSponsoredMissions";
import { LocalMissionList } from "../../domains/sponsored/SponsoredMission";
import {
  setEffectiveMissionsEvent,
  getEffectiveMissions,
} from "../../stores/globalAndLocalMissions/EffectivenessMissionEvents";

export async function execute(
  clientId: string,
  missionsIds: string[],
  vendorId?: string,
): Promise<LocalMissionList> {
  const formattedMissionsIds = missionsIds.toString();
  let sponsoredMissions;

  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);
  const ids = missionsIds;

  if (isOrganizationEnabled) {
    sponsoredMissions = await getMissionsV3(ids, vendorId, "LOCAL");
  } else {
    sponsoredMissions = await getSponsoredMissionsService(
      clientId,
      formattedMissionsIds,
      vendorId,
    );
  }

  setEffectiveMissionsEvent(getEffectiveMissions(sponsoredMissions));
  return sponsoredMissions;
}
