import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      padding: "1rem",
      display: "flex",
      borderRadius: "0.5rem",
      backgroundColor: palette.background.default,
    },
    time: {
      color: palette.text.secondary,
      textAlign: "end",
    },
    dateTime: {
      width: "fit-content",
      display: "flex",
      flexDirection: "column",
      marginRight: "0.5rem",
    },
    textarea: {
      resize: "none",
      width: "100%",
      borderRadius: "0.2rem",
      height: "3rem",
      border: `solid 1px ${palette.grey.A100}`,
      padding: "0.43rem",
      outline: "none",
      "&:focus": {
        border: `solid 1px ${palette.common.black}`,
      },
    },
    textareaContainer: {
      marginRight: "1rem",
      flex: 1,
    },
    textareaInfos: {
      display: "flex",
      justifyContent: "space-between",
      color: palette.text.secondary,
    },
    registerNoteBtn: {
      backgroundColor: palette.background.paper,
      border: `solid 1px ${palette.common.black}`,
      color: palette.common.black,
      padding: "0.5rem 1rem",
      fontSize: "0.875rem",
    },
  }),
);
