export enum DELIVERY_METHODS {
  DELIVERY_RANGE = "DELIVERY_RANGE",
  DELIVERY_WINDOW = "DELIVERY_WINDOW",
}

export interface FulfillmentContent {
  contractId: string;
  deliveryCenterId: string;
  priceListId: string;
  vendorId: string;
  liquorLicense: LiquorLicense[];
  vendorConfiguration: VendorConfiguration;
  vendorBusinessModel: string;
  deliveryTime: DeliveryRange;
  pickupAllowed: boolean;
  audience: {
    tradeModel: string;
  };
  isAlternativeCoverageType: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shippingDays: any;
}

export interface LiquorLicense {
  expirationDate: string;
  number: string;
  status: string;
  type: string;
  description: string | null;
}

export interface VendorConfiguration {
  enforcement: {
    enabled: boolean;
  };
  inventory: {
    solutionType: string;
    model: string;
  };
  assortment: {
    enabledSegmentedProducts: null;
  };
  items: {
    display: {
      strategy: string;
    };
  };
  checkout: {
    deliveryWindowMethod: DELIVERY_METHODS;
  };
  fulfillment: {
    coverageType: string;
    alternativeCoverageType: string;
  };
  account: {
    newContractRegistrationEnabled: boolean;
  };
  recommendation: {
    hasOOSReplacement: boolean;
    hasUpsellEnabled: boolean;
    suggestedOrderUseCase: string;
  };
}

export interface DeliveryRange {
  minDays: number;
  maxDays: number;
}
