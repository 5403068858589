import React from "react";
import {
  Image,
  ChallengeImage,
  TakePhotoContainer,
  SampleContainer,
  GoodPhoto,
  GoodMark,
  BadPhoto,
  BadMark,
} from "./ImageContainer.styles";

/* eslint-disable @typescript-eslint/no-var-requires, global-require */
const itemCardDefaultImage = require("../../../../../../assets/images/ChallengePlaceholder.svg");
const badCheck = require("../../../../../../assets/images/BadCheckmark.svg");
const goodCheck = require("../../../../../../assets/images/GoodCheckmark.svg");
/* eslint-enable @typescript-eslint/no-var-requires, global-require */

interface Props {
  type: string;
  img?: string;
  badSample?: string;
  goodSample?: string;
}

const ImageContainer = ({
  type,
  img,
  badSample,
  goodSample,
}: Props): JSX.Element => {
  const [image, setImage] = React.useState(img);
  const [goodPhoto, setGoodPhoto] = React.useState(goodSample);
  const [badPhoto, setBadPhoto] = React.useState(badSample);

  if (type === "TAKE_PHOTO") {
    return (
      <TakePhotoContainer data-testId="take-photo-image-section">
        <SampleContainer>
          <GoodPhoto
            src={goodPhoto}
            onError={() => setGoodPhoto(itemCardDefaultImage)}
            data-testId="challenge-image-good-photo"
          />
          <GoodMark src={goodCheck} />
        </SampleContainer>

        <SampleContainer>
          <BadPhoto
            src={badPhoto}
            onError={() => setBadPhoto(itemCardDefaultImage)}
            data-testId="challenge-image-bad-photo"
          />
          <BadMark src={badCheck} />
        </SampleContainer>
      </TakePhotoContainer>
    );
  }

  return (
    <ChallengeImage data-testId="image-section">
      <Image
        src={image}
        onError={() => setImage(itemCardDefaultImage)}
        data-testId="challenge-image"
      />
    </ChallengeImage>
  );
};

export default ImageContainer;

ImageContainer.defaultProps = {
  img: "",
  badSample: "",
  goodSample: "",
};
