import React from "react";
import { ButtonBase } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import classNames from "classnames";
import AppHeaderStyles from "./AppHeaderStyles";

interface Props extends ButtonProps {
  active: boolean;
  text: string;
  testId: string;
}

const TabButton: React.FunctionComponent<Props> = ({
  text,
  active,
  testId,
  ...props
}) => {
  const classes = AppHeaderStyles();

  return (
    <ButtonBase
      className={classNames(classes.tabButton, { [classes.activeTab]: active })}
      data-testid={testId}
      {...props}
    >
      <>
        <span className={classes.tabText}>{text}</span>
      </>
    </ButtonBase>
  );
};

export default TabButton;
