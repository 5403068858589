import React from "react";
import classNames from "classnames";
import ButtonBase, { ButtonBaseProps } from "@material-ui/core/ButtonBase";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import ButtonStyles from "./ButtonStyles";

interface Props extends ButtonBaseProps {
  loading?: boolean;
  loadingProps?: CircularProgressProps; // it's possible to pass any prop of material CircularProgress using this prop
  variant?: "contained" | "outlined" | "reset";
  testId?: string;
  disabled?: boolean;
}

const Button: React.ForwardRefExoticComponent<Props> = React.forwardRef(
  (
    {
      loading,
      disabled,
      variant,
      className,
      loadingProps,
      testId,
      children,
      ...props
    },
    ref,
  ) => {
    const classes = ButtonStyles();

    return (
      <ButtonBase
        disabled={loading || disabled}
        className={classNames(className, classes.rounded, {
          [classes.button]: !className,
          [classes.contained]: variant === "contained",
          [classes.outlined]: variant === "outlined",
        })}
        data-testid={testId}
        ref={ref}
        {...props}
      >
        {loading ? (
          <CircularProgress
            size={15}
            className={classNames(loadingProps?.className, {
              [classes.loading]: !loadingProps?.className,
              [classes.loadingContained]: variant === "contained",
              [classes.loadingOutlined]: variant === "outlined",
            })}
            {...loadingProps}
          />
        ) : (
          children
        )}
      </ButtonBase>
    );
  },
);

Button.defaultProps = {
  variant: "contained",
  loading: false,
  loadingProps: {},
  testId: "",
};

export default Button;
