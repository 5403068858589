import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { useStore } from "effector-react";

import Loading from "../../components/loadingView/LoadingView";
import { LINK_SERVER_ERROR } from "../../config/constants";
import * as GlobalAdminConfigEvents from "../../stores/globaAdminConfig/GlobalAdminConfigEvents";
import GlobalAdminConfigStore from "../../stores/globaAdminConfig/GlobalAdminConfigStore";

import HomeMainPage from "./HomeMainPage";
import HomeMainPageStyles from "./HomeMainPageStyles";

function HomeRoute(): React.ReactElement {
  const classes = HomeMainPageStyles();

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);

  const history = useHistory();

  useEffect(() => {
    const userConfigHasDone =
      GlobalAdminConfigState.done && !GlobalAdminConfigState.error;

    if (userConfigHasDone) {
      setInitialDataLoaded(true);
    }
  }, [GlobalAdminConfigState]);

  useEffect(() => {
    const unWatchGetUserConfigFail =
      GlobalAdminConfigEvents.getUserConfig.fail.watch(() => {
        history.push(`${LINK_SERVER_ERROR}`);
      });

    /* istanbul ignore next */
    return () => {
      if (typeof unWatchGetUserConfigFail === "function") {
        unWatchGetUserConfigFail();
      }
    };
  }, [history]);

  if (initialDataLoaded) return <HomeMainPage />;

  return (
    <div data-testid="loading-home-main-page" className={classes.loading}>
      <Loading />
    </div>
  );
}

export default HomeRoute;
