import React, { ReactNode, FunctionComponent } from "react";
import TableBodyBase from "@material-ui/core/TableBody";

interface Props {
  children: ReactNode;
  testId: string;
}

const TableBody: FunctionComponent<Props> = ({ children, testId }) => {
  return (
    <TableBodyBase data-testid={`${testId}-table-body`}>
      {children}
    </TableBodyBase>
  );
};

export default TableBody;
