import { createEffect, createEvent } from "effector";
import AgentCalls from "../../domains/AgentCall";
import { execute } from "../../usecase/searchedPoc/CreateSearchedPocUseCase";
import CreateSearchedPocRequest from "../../services/searchedPoc/domains/CreateSearchedPocRequest";

export const createSearchedPocEffect = createEffect({
  /* istanbul ignore next */
  async handler(request: CreateSearchedPocRequest) {
    return execute(request);
  },
});

export const resetCreateSearchedPoc = createEvent<void>(
  "resetCreateSearchedPoc",
);

export const createSearchedPocFail = createEvent<AgentCalls>(
  "createSearchedPocFail",
);

export const createSearchedPocDone = createEvent<AgentCalls>(
  "createSearchedPocDone",
);
