import { createEvent } from "effector";
import AgentCall from "../../domains/AgentCall";
import Message from "../../domains/Message";

export const onChangeGapReason = createEvent<AgentCall>("onChangeGapReason");

export const onTryUpdateAgentCall = createEvent("onTryUpdateAgentCall");

export const onChangeAgentCall = createEvent<AgentCall>("onChangeAgentCall");

export const showAgentCallSuccessMessage = createEvent<string>(
  "showAgentCallSuccessMessage",
);

export const updateAgentCallFail = createEvent<Message>("updateAgentCallFail");

export const resetAgentCall = createEvent("resetAgentCall");

export const setIsResumed = createEvent<boolean>("setIsResumed");

export const setGapReason = createEvent<string>("setGapReason");
