import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Typography,
} from "@material-ui/core";

import LoginInformationIcon from "../../../../assets/LoginInformationIcon";
import CopyIcon from "../../../../assets/CopyIcon";

import B2bLoginInformationStyles from "./B2bLoginInformationStyles";

import { useAnalytics } from "../../../../analytics/useAnalytics";
import { b2BLoginInformationCopied } from "../../../../config/typewriter";

interface Props {
  login: string;
  password: string;
  testId?: string;
}

const BUTTON_LABEL = "copy-login-information";
const BUTTON_NAME = {
  COPY_USER: "Copy User",
  COPY_PASS: "Copy Password",
};

const B2bLoginInformation: React.FunctionComponent<Props> = ({
  login,
  password,
  testId,
}: Props) => {
  const classes = B2bLoginInformationStyles();
  const { t } = useTranslation();
  const { dispatchGenericEvent } = useAnalytics();

  const handleCopyButton = (buttonName: string) => {
    dispatchGenericEvent(b2BLoginInformationCopied, {
      button_label: BUTTON_LABEL,
      button_name: buttonName,
    });
    return false;
  };

  return (
    <Box className={classes.loginInformationBox} data-testid={testId}>
      <Grid>
        <Grid className={classes.headerTitle} data-testid={`${testId}-header`}>
          <LoginInformationIcon />
          <Typography className={classes.labelTitle}>
            {t("B2b_Trainer_Login_Information.LOGIN_INFORMATION")}
          </Typography>
        </Grid>
      </Grid>

      <Divider
        orientation="horizontal"
        classes={{ vertical: classes.divider }}
        data-testid={`${testId}-divider`}
      />

      <Grid>
        <Grid className={classes.gridLogin}>
          <Typography
            className={classes.labelUser}
            component="span"
            data-testid={`${testId}-label-login`}
          >
            {t("B2b_Trainer_Login_Information.USER")}
          </Typography>

          <Typography
            className={classes.valueLogin}
            component="span"
            data-testid={`${testId}-value-login`}
          >
            {`${login}`}

            <Typography className={classes.copyIcon} component="span">
              <CopyToClipboard text={login}>
                <IconButton
                  onClick={() => handleCopyButton(BUTTON_NAME.COPY_USER)}
                >
                  <CopyIcon />
                </IconButton>
              </CopyToClipboard>
            </Typography>
          </Typography>
        </Grid>

        <Grid className={classes.gridPassword}>
          <Typography
            className={classes.labelPassword}
            component="span"
            data-testid={`${testId}-label-password`}
          >
            {t("B2b_Trainer_Login_Information.PASSWORD")}
          </Typography>
          <Typography
            className={classes.valuePassword}
            component="span"
            data-testid={`${testId}-value-password`}
          >
            <InputBase
              type="password"
              disabled
              defaultValue={password}
              className={classes.valuePassword}
            />

            <Typography className={classes.copyIcon} component="span">
              <CopyToClipboard text={password}>
                <IconButton
                  onClick={() => handleCopyButton(BUTTON_NAME.COPY_PASS)}
                >
                  <CopyIcon />
                </IconButton>
              </CopyToClipboard>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

B2bLoginInformation.defaultProps = {
  testId: undefined,
};

export default B2bLoginInformation;
