import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      padding: "1rem",
      display: "flex",
      flexDirection: "column",
      borderRadius: "1rem",
      backgroundColor: palette.common.white,
      boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.16)",

      "&:last-child": {
        marginBottom: "3.57rem",
      },
    },
    tagContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "0.25rem",

      "& > div": {
        marginBottom: ".5rem",
      },
    },
    baseText: {
      lineHeight: "1rem",
    },
    collapseContainer: {
      marginTop: "0.5rem",
      display: "flex",
      alignItems: "center",
      gap: ".125rem",
    },
    iconButton: {
      height: "1rem",
      width: "1rem",

      "& svg": {
        fill: "#047AF1",
      },
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1rem",
      gap: "1rem",
    },
    containerPrices: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "0.60rem",
    },
    steppedDiscountContainer: {
      justifyContent: "inherit",
      gap: ".5rem",
    },
    padding: {
      padding: "0 .40rem",
    },
    footerSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    showMoreText: {
      color: "#0363C4",
      cursor: "text",
    },
  }),
);
