import { createStore } from "effector";
import { WebsocketEventData } from "../../domains/cti/WebsocketEventData";
import { formarterCTIWithZero } from "../../config/utils/functions";
import {
  setCtiData,
  setCalledNumber,
  setAttemptRetry,
  resetCti,
  setWasTyped,
  resetAttemptRetry,
  setShouldContinueWithoutCTI,
  setIsCTIFailedOpen,
  setIsConnected,
  setCtiEvent,
} from "./CtiEvents";
import CtiState from "./CtiState";

interface Phones {
  phone: string;
  rank: string;
}

const initialState = <CtiState>{
  originalPhones: [],
  phones: [],
  calledNumbers: [],
  lastCalledPhone: "",
  attemptRetry: 0,
  wasTyped: false,
  ctiEvent: {} as WebsocketEventData,
  shouldContinueWithoutCTI: false,
  isCTIFailedOpen: false,
  isConnected: false,
};

const CtiStore = createStore(initialState)
  .on(setCtiEvent, (state, CtiEvent: WebsocketEventData) => {
    return {
      ...state,
      ctiEvent: CtiEvent,
    };
  })
  .on(setCtiData, (state, originalPhones) => ({
    ...state,
    originalPhones,
  }))
  .on(setCalledNumber, (state, calledNumbers: string) => {
    const calleds = [...state.calledNumbers];
    const { originalPhones } = state;
    const { phones } = state;
    let phonesList: Array<Phones> = [];

    if (phones && phones.length > 0) {
      phonesList = phones.filter(
        (phone) =>
          formarterCTIWithZero(phone.phone)?.toString() !== calledNumbers,
      );
    } else if (originalPhones && state.calledNumbers.length === 0) {
      phonesList = originalPhones.filter(
        (phone) =>
          formarterCTIWithZero(phone.phone)?.toString() !== calledNumbers,
      );
    }

    const isInOriginalPhones = originalPhones?.find(
      (phone) =>
        formarterCTIWithZero(phone.phone)?.toString() === calledNumbers,
    );

    if (isInOriginalPhones) {
      calleds.push(`[${isInOriginalPhones.rank}] ${calledNumbers}`);
    } else {
      calleds.push(`[NOTRANKED] ${calledNumbers}`);
    }

    return {
      ...state,
      calledNumbers: [...calleds],
      phones: phonesList,
      lastCalledPhone: calledNumbers,
    };
  })
  .on(setAttemptRetry, (state) => ({
    ...state,
    attemptRetry: state.attemptRetry + 1,
  }))
  .on(resetAttemptRetry, (state) => ({
    ...state,
    attemptRetry: 0,
  }))
  .on(setWasTyped, (state, wasTyped: boolean) => ({
    ...state,
    wasTyped,
  }))

  .on(
    setShouldContinueWithoutCTI,
    /* istanbul ignore next */
    (state, shouldContinueWithoutCTI: boolean) => ({
      ...state,
      shouldContinueWithoutCTI,
    }),
  )

  .on(
    setIsCTIFailedOpen,
    /* istanbul ignore next */ (state, isCTIFailedOpen: boolean) => ({
      ...state,
      isCTIFailedOpen,
    }),
  )
  .on(setIsConnected, (state, value: boolean) => ({
    ...state,
    isConnected: value,
  }))
  .reset(resetCti);

export default CtiStore;
