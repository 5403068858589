import { useStore } from "effector-react";
import PromotionsStore from "../../../../../stores/promotions/PromotionsStore";
import { SponsoredMission } from "../../../../../domains/sponsored/SponsoredMission";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import { Product } from "../../../../../domains/products/Product";
import PocInformation from "../../../../../domains/PocInformation";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import {
  sponsoredProductAdded,
  campaignsProductAdded,
} from "../../../../../config/typewriter";
import { ComboProduct } from "../../../../../domains/cart/CartComboItem";
import { CartItem } from "../../../../../domains/cart/CartItem";
import { addItem as addCombo } from "../../../../../stores/cartCombo/CartComboEvents";
import {
  MAX_VALUE_INPUT,
  MISSION_TYPES,
} from "../../../../../config/constants";
import { addItem } from "../../../../../stores/cart/CartItemEvents";
import {
  isFeatureEnabledV2,
  TLP_SPONSORED_PRODUCT_SHOW_STOCK,
  TLP_SPONSORED_PRODUCT_ADD_CART,
  TLP_SPONSORED_COMBO_ADD_CART,
} from "../../../../../config/featureToggles";
import { EFFECTIVENESS_TYPES_STRINGS } from "../../../../../domains/flexibleMission/FlexibleMission";

interface Props {
  isMarketplaceProduct?: boolean;
  products?: Product[];
  item?: Product;
  pocInformation?: PocInformation;
}

interface HookProps {
  showStock: (isCombo: boolean) => boolean;
  showAddButton: (isCombo: boolean) => boolean;
  addAction: (
    productItem: Product,
    mission?: SponsoredMission,
  ) =>
    | ((itemQuantity: number, itemId?: string | undefined) => void)
    | undefined;
  maxValue: (productItem: Product) => number | undefined;
}

const useProductCardContainer = ({
  products,
  pocInformation,
  isMarketplaceProduct,
  item,
}: Props): HookProps => {
  const {
    user: { zone },
  } = useStore(GlobalStore);
  const { dispatchGenericEvent } = useAnalytics();
  const { callId, clientId: accountId } = useStore(AgentCallStore);
  const { promotions } = useStore(PromotionsStore);

  const isSponsoredProductShowStockEnabled = isFeatureEnabledV2(
    TLP_SPONSORED_PRODUCT_SHOW_STOCK,
    zone,
  );

  const isControlledAdditionEnabled = isFeatureEnabledV2(
    TLP_SPONSORED_PRODUCT_ADD_CART,
    zone,
  );

  const isSponsoredComboAddToCartEnabled = isFeatureEnabledV2(
    TLP_SPONSORED_COMBO_ADD_CART,
    zone,
  );

  const dispatchEventLocalOrSponsored = (localMission, item, quantity) => {
    if (localMission.sponsorPartnerId) {
      return dispatchGenericEvent(sponsoredProductAdded, {
        call_id: callId,
        poc_id: accountId,
        name: item.itemName || item.title,
        quantity,
        combo_id: item.isCombo ? item.id : null,
        sku: item.isCombo ? null : item.sku,
        is_out_of_stock: item.stock === 0,
        is_promotion: promotions.some(
          (promotion) => promotion.sku === item.sku,
        ),
      });
    }

    const isFlexible = localMission?.missionType === MISSION_TYPES.FLEXIBLE;

    const effectivenessCriteria = isFlexible
      ? EFFECTIVENESS_TYPES_STRINGS.CREDIT_AS_PAYMENT
      : localMission?.effectiveness?.type;

    const extraParams = {
      flexile_mission_title: localMission?.missionTitle,
      flexible_mission_id: localMission?.id,
    };

    return dispatchGenericEvent(campaignsProductAdded, {
      Local_mission_title: localMission.missionTitle,
      Local_mission_id: localMission.id,
      sponsor_partner: localMission.sponsorPartnerId,
      effectiveness_criteria: effectivenessCriteria,
      call_id: callId,
      poc_id: accountId,
      name: item.itemName || item.title,
      quantity,
      sku: item.sku,
      combo_id: item.id,
      is_out_of_stock: item.stock === 0,
      is_promotion: promotions.some((promotion) => promotion.sku === item.sku),
      minimum_quantity: item.suggestedQuantity,
      ...(isFlexible ? extraParams : {}),
    });
  };

  const handleAddComboToCart = (
    data: Product,
    quantity: number,
    localMission?: SponsoredMission,
  ) => {
    const item = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: data.comboId!,
      platformId: data.platformId,
      title: data.name,
      description: data.description,
      price: data.price,
      limit: data.limit,
      itemQuantity: quantity,
      isCombo: true,
      items:
        data.comboDetails?.map((item) => {
          return {
            id: item.id,
            platformId: item.platformId,
            title: item?.name,
            fullPackageDescription: item?.packageName,
          } as ComboProduct;
        }) || /* istanbul ignore next */ [],
    };

    addCombo({ ...item });

    dispatchEventLocalOrSponsored(localMission, item, quantity);
  };

  const handleAddToCard = (
    itemQuantity: number,
    itemId?: string,
    localMission?: SponsoredMission,
  ) => {
    const itemToAdd = isMarketplaceProduct
      ? item
      : products?.find((item) => item.id === itemId);

    /* istanbul ignore else */
    if (itemToAdd) {
      const finalItem: CartItem = {
        id: itemId,
        platformId: itemToAdd.platformId,
        price: itemToAdd.price,
        unitPrice: itemToAdd.price,
        sku: itemToAdd.sku || itemToAdd.id,
        itemName: itemToAdd.name,
        itemQuantity,
        returnable: false,
        notFound: false,
        segment: "",
        total: itemToAdd.price * itemQuantity,
        inventoryCount: itemToAdd.inventoryCount,
        palletQuantity: itemToAdd.palletQuantity,
        stock: itemToAdd.stock,
      };

      addItem({
        ...finalItem,
        maxVehicleCapacity: pocInformation?.maxVehicleCapacity,
      });

      if (isMarketplaceProduct) {
        return true;
      }

      dispatchEventLocalOrSponsored(localMission, finalItem, itemQuantity);
    }
    return null;
  };

  const showStock = (isCombo: boolean) => {
    return !isCombo && isSponsoredProductShowStockEnabled;
  };

  const showAddButton = (isCombo: boolean) => {
    return (
      (isCombo && isSponsoredComboAddToCartEnabled) ||
      (!isCombo && isControlledAdditionEnabled)
    );
  };

  const maxValue = (productItem: Product) =>
    productItem.isCombo
      ? productItem.limit?.balance || MAX_VALUE_INPUT
      : undefined;

  const addAction = (productItem: Product, mission?: SponsoredMission) => {
    return productItem.isCombo
      ? (quantity) => handleAddComboToCart(productItem, quantity, mission)
      : (quantity) => handleAddToCard(quantity, productItem.id, mission);
  };

  return {
    showStock,
    showAddButton,
    addAction,
    maxValue,
  };
};

export default useProductCardContainer;
