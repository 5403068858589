import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import { GROW_BFF_SERVICE } from "@config/services";
import { isFeatureEnabled, getVendorName } from "grow-shared-services";
import NewOfferingsResponseV3ToNewOfferingsConverter from "@converters/newOfferings/NewOfferingsResponseV3ToNewOfferingsConverter";
import { getUserInfos } from "../../config/utils/functions";
import { getStoreIdHost } from "../host/HostStoreId";
import { getV2EndPoint, getApiHost } from "../host/HostService";
import axios from "../../config/axios/axiosInstance";
import { getApiHost as getApiB2bHost } from "../host/HostB2BService";
import {
  NewOfferingsItemV2,
  NewOfferingsResponseItem,
  NewOfferingsResponseV2,
} from "./domains/NewOfferingsResponseItem";
import NewOfferingsResponseToNewOfferingsConverter from "../../converters/newOfferings/NewOfferingsResponseToNewOfferingsConverter";
import NewOfferingsResponseV2ToNewOfferingsConverter from "../../converters/newOfferings/NewOfferingsResponseV2ToNewOfferingsConverter";
import NewOfferings from "../../domains/newOfferings/NewOfferings";
import NewOfferingsRequest from "./domains/NewOfferingsRequest";
import {
  GROW_ORGANIZATION_ACTIVATION,
  TLP_MULTICONTRACT_GLOBAL_RECOMMENDATION_BUSINESS_SERVICE,
} from "../../config/featureToggles";

export const NEW_OFFERINGS_ENDPOINT = "/api/v1/global-recommendation-business";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getNewOfferings(
  newOfferingsRequest: NewOfferingsRequest,
): Promise<Array<NewOfferings>> {
  const log = useLogService();

  const { vendorId, country: countryCode } = getUserInfos();
  const { useCase, useCaseType, useCaseId, accountId } = newOfferingsRequest;
  const vendorIds = [vendorId];
  const vendorIdsParam = vendorId ? `&vendorIds=${vendorIds}` : "";

  const storeId = getStoreIdHost(countryCode, getVendorName(vendorId));

  const isGlobalRecommendationBusinessV2Enabled = isFeatureEnabled(
    TLP_MULTICONTRACT_GLOBAL_RECOMMENDATION_BUSINESS_SERVICE,
  );

  const isOrganizationConfigEnabled = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  const endpoint = `${getApiB2bHost()}${NEW_OFFERINGS_ENDPOINT}${getV2EndPoint(
    isGlobalRecommendationBusinessV2Enabled,
  )}?useCase=${useCase}&useCaseType=${useCaseType}`;
  const endpointV1 = `${endpoint}&useCaseId=${useCaseId}`;
  const endpointV2 = `${endpoint}&accountId=${accountId}&storeId=${storeId}${vendorIdsParam}`;

  const endpointV3 = `${getApiHost(
    GROW_BFF_SERVICE,
  )}/v1/accounts/${accountId}/recommendations`;

  const formatedLog = (endpoint: string) =>
    log.info(`Convert New Offerings received from ${endpoint} to our model`);

  if (isOrganizationConfigEnabled) {
    return axios
      .get<NewOfferingsItemV2[]>(endpointV3, {
        headers: {
          vendorId,
        },
      })
      .then((response) => {
        formatedLog(endpointV3);
        return NewOfferingsResponseV3ToNewOfferingsConverter(response?.data);
      })
      .catch((error) => {
        log.error(error);
        throw error;
      });
  }

  if (isGlobalRecommendationBusinessV2Enabled) {
    return axios
      .get<NewOfferingsResponseV2>(endpointV2)
      .then((response) => {
        formatedLog(endpointV2);
        return NewOfferingsResponseV2ToNewOfferingsConverter(
          response?.data.list,
        );
      })
      .catch((error) => {
        log.error(error);
        throw error;
      });
  }

  return axios
    .get<Array<NewOfferingsResponseItem>>(endpointV1)
    .then((response) => {
      formatedLog(endpointV1);
      return NewOfferingsResponseToNewOfferingsConverter(response?.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
