import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    pocInfo: {
      padding: 24,
      lineHeight: 3,
    },
    vendorName: {
      fontSize: 12,
      gap: 4,
      display: "flex",
      alignItems: "center",
      color: "#fff",
    },
    pocName: {
      fontSize: "20px",
      fontWeight: 600,
      color: palette.secondary.contrastText,
      wordBreak: "break-word",
    },
  }),
);
