import React from "react";
import { TableCell } from "@material-ui/core";
import { ProductListStyles } from "../ProductListStyles";
import Data from "../../../../../../components/data/Data";
import OriginalAndDiscountPrices from "../../../../../../components/originalAndDiscountPrices/OriginalAndDiscountPrices";

interface Props {
  classes: string;
  value: string | number;
  dataTest: string;
  numeric?: boolean;
  alignLeft?: boolean;
  tag?: React.ReactNode;
  originalPrice?: number;
  originalTotalPrice?: number;
}

function TableCellItem({
  classes,
  value,
  dataTest,
  numeric,
  alignLeft,
  tag,
  originalPrice,
  originalTotalPrice,
}: Readonly<Props>): React.ReactElement {
  const styledClasses = ProductListStyles();
  const alignment: "left" | "right" = alignLeft ? "left" : "right";
  const originalPriceWithDiscount = originalPrice && originalPrice !== value;
  const originalTotalPriceWithDiscount =
    originalTotalPrice && originalTotalPrice !== value;
  const type: "currency" | "text" = numeric ? "currency" : "text";

  const props = {
    classes,
    value,
    tag,
    dataTest,
    type,
    alignment,
  };

  if (originalPriceWithDiscount) {
    return (
      <OriginalAndDiscountPrices
        {...props}
        originalValue={originalPrice}
        key={dataTest}
        data-testid={`${dataTest}-price`}
      />
    );
  }

  if (originalTotalPriceWithDiscount) {
    return (
      <OriginalAndDiscountPrices
        {...props}
        originalValue={originalTotalPrice}
        data-testid={`${dataTest}-total-price`}
      />
    );
  }

  return (
    <TableCell
      align={alignment}
      data-testid={dataTest}
      className={styledClasses.backgroundColor}
    >
      <span className={classes} data-testid={`${dataTest}-text`}>
        <Data value={value} type={type} ellipsis />
      </span>
      {tag}
    </TableCell>
  );
}

TableCellItem.defaultProps = {
  numeric: false,
  alignLeft: false,
  tag: <></>,
};

export default TableCellItem;
