import { isFeatureEnabled } from "grow-shared-services";
import * as PocInformationEvents from "../../stores/callList/pocInformation/PocInformationEvents";
import * as PocInformationService from "../../services/pocInformation/PocInformationService";
import * as CreditPocInformationService from "../../services/creditPocInformation/CreditPocInformationService";
import { findBeesContracts } from "../../services/pocInformation/beesContracts/BeesContractsService";
import SearchPocRequest from "../../services/pocInformation/domains/SearchPocRequest";
import PocInformation from "../../domains/PocInformation";
import { GROW_ORGANIZATION_ACTIVATION } from "../../config/featureToggles";

export function execute(
  accountId: string,
  vendorId?: string,
  isContractless?: boolean,
  isSearchedPoc?: boolean,
): Promise<void> {
  return PocInformationService.findByAccountId(
    accountId,
    vendorId,
    isContractless,
    isSearchedPoc,
  )
    .then((response) => {
      const responsePocInformation = response as PocInformation;
      PocInformationEvents.findByAccountId(responsePocInformation);
    })
    .catch(() => {
      PocInformationEvents.pocInformationFail(null);
    });
}

export function executeCreditPocInformation(
  accountId: string,
  vendorId: string,
): Promise<void> {
  return CreditPocInformationService.creditPocInformation(accountId, vendorId)
    .then((response) => {
      PocInformationEvents.setCreditPocInformation(response);
    })
    .catch(() => {
      PocInformationEvents.pocInformationFail(null);
    });
}

export function executeBeesContract(
  accountId: string,
  vendorId?: string,
  isContractless?: boolean,
  isSearchedPoc?: boolean,
): Promise<void> {
  const request = {
    accountId,
    vendorId,
  } as SearchPocRequest;

  const isOrganizationConfigEnabled = isFeatureEnabled(
    GROW_ORGANIZATION_ACTIVATION,
  );

  return findBeesContracts(request)
    .then((response) => {
      const responseContracts = response as PocInformation;

      const id = isOrganizationConfigEnabled
        ? responseContracts.accountId
        : responseContracts.legacyAccountId ||
          responseContracts.vendorAccountId;

      if (!id) {
        PocInformationEvents.pocInformationFail(null);
        return;
      }

      PocInformationService.findByAccountId(
        id,
        vendorId,
        isContractless,
        isSearchedPoc,
      )
        .then((responseAccount) => {
          const responsePocInformation = responseAccount as PocInformation;

          if (responsePocInformation.accountId) {
            responsePocInformation.accountId = responseContracts.accountId;
            responsePocInformation.customerAccountId =
              responseContracts.customerAccountId;
            responsePocInformation.legacyAccountId =
              responseContracts.legacyAccountId;
            responsePocInformation.hasPONumberRequirement =
              responseContracts.hasPONumberRequirement;

            PocInformationEvents.findByAccountId(responsePocInformation);
          } else {
            PocInformationEvents.pocInformationFail(null);
          }
        })
        .catch(() => PocInformationEvents.pocInformationFail(null));
    })
    .catch(() => {
      PocInformationEvents.pocInformationFail(null);
    });
}
