import React from "react";

const HeadPhoneIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="16"
    viewBox="0 0 14 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#7A7A7A" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M6.906 0c3.812 0 6.905 3.094 6.905 6.906v3.727c0 .762-.62 1.382-1.38 1.382l-.462-.001v.288c0 1.27-1.032 2.302-2.301 2.302H7.874c-.249.245-.59.396-.968.396-.765 0-1.381-.617-1.381-1.381s.616-1.381 1.38-1.381c.765 0 1.382.617 1.382 1.38 0 .023 0 .044-.002.066l1.383-.001c.549 0 1.024-.322 1.247-.787-.105.026-.214.04-.326.04-.762 0-1.381-.62-1.381-1.382V7.81c0-.762.62-1.381 1.38-1.381.6 0 1.113.384 1.303.92h.54c.16 0 .316.028.46.08v-.523C12.89 3.609 10.202.92 6.906.92 3.609.92.92 3.609.92 6.906l-.001.523c.144-.052.3-.08.461-.08h.54c.19-.536.701-.92 1.302-.92.761 0 1.38.62 1.38 1.38v3.745c0 .762-.619 1.381-1.38 1.381-.6 0-1.112-.384-1.302-.92h-.54C.62 12.015 0 11.395 0 10.633V6.906C0 3.094 3.094 0 6.906 0z"
              transform="translate(-1267 -248) translate(935 241) translate(332 8)"
            />
            <path
              d="M6.927-.357c3.824 0 6.89 3.438 6.828 7.636l-.008.253-1.427-.064c.158-3.528-2.338-6.397-5.393-6.397-3.018 0-5.59 2.81-5.497 6.155l.01.229-1.425.09C-.256 3.294 2.998-.357 6.927-.357z"
              transform="translate(-1267 -248) translate(935 241) translate(332 8)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default HeadPhoneIcon;
