import { createStore } from "effector";
import { VendorPocByAccountId } from "@domains/PocInformation";
import {
  resetVendorsPoc,
  searchVendorPocByAccountId,
} from "./VendorPocByAccountEffect";

interface VendorPocByAccountIdState {
  searchedAccountId: string;
  vendorPoc: VendorPocByAccountId[];
  numberOfPocs: number;
  error: boolean;
}

const initialState = <VendorPocByAccountIdState>{
  searchedAccountId: "",
  vendorPoc: [],
  numberOfPocs: 0,
  error: false,
};

const VendorPocByAccountIdStore = createStore(initialState)
  .on(searchVendorPocByAccountId.done, (_, value) => {
    return {
      searchedAccountId: value.params,
      vendorPoc: value.result,
      numberOfPocs: value.result.length,
      error: false,
    };
  })
  .on(searchVendorPocByAccountId.fail, (state, value) => {
    return {
      searchedAccountId: value.params,
      vendorPoc: [],
      numberOfPocs: 0,
      error: true,
    };
  })
  .reset(resetVendorsPoc);

export default VendorPocByAccountIdStore;
