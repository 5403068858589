import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import { useStore } from "effector-react";
import { isFeatureEnabled } from "grow-shared-services";

import { Grid, Container } from "@material-ui/core";

import { useAnalytics } from "../../analytics/useAnalytics";
import {
  LINK_CALL,
  TAB_PARAMETER,
  ANALYTICS_ROUTE_NAMES,
  TAB_B2B_TRAINER_ROUTE,
  TAB_CUSTOMER_ROUTE,
  TAB_GAP_ROUTE,
  TAB_ORDERS_ROUTE,
} from "../../config/constants";
import {
  TLP_B2B_TRAINER,
  TLP_ORDERS,
  TLP_CUSTOMER_PAGE,
} from "../../config/featureToggles";
import { callTabMenuInteraction } from "../../config/typewriter";
import * as CallTabEvents from "../../stores/navigation/callTab/CallTabEvents";
import CallTabStore from "../../stores/navigation/callTab/CallTabStore";
import AppHeaderStyles from "./AppHeaderStyles";
import TabButton from "./TabButton";

const AppHeader: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [showTabs, setShowTabs] = useState(false);
  const { callTab } = useStore(CallTabStore);
  const classes = AppHeaderStyles();

  const { dispatchGenericEvent } = useAnalytics();
  const b2bTrainerEnabled = isFeatureEnabled(TLP_B2B_TRAINER);

  const isCustomerPageEnabled = isFeatureEnabled(TLP_CUSTOMER_PAGE);

  const ordersEnabled = isFeatureEnabled(TLP_ORDERS);

  const onTabClick = (tab: string, tabLabel: string) => {
    CallTabEvents.setCallTab(tab);
    history.push({
      pathname: tab,
    });

    const completeCurrentTabLink = `${LINK_CALL}?${TAB_PARAMETER}=${callTab}`;
    const completeNextTabLink = `${LINK_CALL}?${TAB_PARAMETER}=${tab}`;

    dispatchGenericEvent(callTabMenuInteraction, {
      screen_name: ANALYTICS_ROUTE_NAMES.get(completeCurrentTabLink),
      tab_name: ANALYTICS_ROUTE_NAMES.get(completeNextTabLink),
      tab_label: tabLabel,
    });
  };

  useEffect(() => {
    const setTabs = () => {
      const shouldShowTabs =
        location.pathname === LINK_CALL ||
        location.pathname === TAB_CUSTOMER_ROUTE ||
        location.pathname === TAB_GAP_ROUTE ||
        location.pathname === TAB_B2B_TRAINER_ROUTE ||
        location.pathname === TAB_ORDERS_ROUTE;

      setShowTabs(shouldShowTabs);
    };
    setTabs();
  }, [location]);

  return (
    <div className={classes.container}>
      <Grid container item>
        {showTabs && (
          <Container
            className={classes.tabsContainer}
            data-testid="call-button-tabs"
          >
            {isCustomerPageEnabled && (
              <TabButton
                active={callTab === TAB_CUSTOMER_ROUTE}
                text={t("HeaderTab.CUSTOMER")}
                onClick={() => {
                  onTabClick(TAB_CUSTOMER_ROUTE, t("HeaderTab.CUSTOMER"));
                }}
                testId="customer-page-tab"
              />
            )}

            <TabButton
              active={callTab === TAB_GAP_ROUTE}
              text={t("NEWCallSummary.MISSIONS_LABEL")}
              onClick={() => {
                onTabClick(TAB_GAP_ROUTE, t("HeaderTab.GAP_STATUS"));
              }}
              testId="gap-button-tab"
            />
            {b2bTrainerEnabled && (
              <TabButton
                active={callTab === TAB_B2B_TRAINER_ROUTE}
                text={t("HeaderTab.B2B_TRAINER")}
                onClick={() => {
                  onTabClick(TAB_B2B_TRAINER_ROUTE, t("HeaderTab.B2B_TRAINER"));
                }}
                testId="b2b-trainer-button-tab"
              />
            )}
            {ordersEnabled && (
              <TabButton
                active={callTab === TAB_ORDERS_ROUTE}
                text={t("HeaderTab.ORDERS")}
                onClick={() => {
                  onTabClick(TAB_ORDERS_ROUTE, t("HeaderTab.ORDERS"));
                }}
                testId="orders-button-tab"
              />
            )}
          </Container>
        )}
      </Grid>
    </div>
  );
};

export default AppHeader;
