import { useLogService } from "admin-portal-shared-services";
import axios from "axios";
import axiosRetry from "axios-retry";

import axiosInstance from "@config/axios/axiosInstance";

import { GROW_BFF_SERVICE } from "../../../config/services";
import CartIdResponseToCartInfoConverter from "../../../converters/cart/cartId/CartIdResponseToCartInfoConverter";
import { CartInfo } from "../../../domains/cart/CartInfo";
import { getApiHost } from "../../host/HostService";
import UpdateCanceledError from "../domains/UpdateCanceledError";
import CartIdResponse from "./domain/CartIdResponse";

const CARTS = "/carts";

/* istanbul ignore next */
axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function CartIdByAccountId(
  accountId: string,
  vendorId: string,
): Promise<CartInfo | null> {
  const log = useLogService();

  const endpoint = `${getApiHost(
    GROW_BFF_SERVICE,
  )}/accounts/${accountId}${CARTS}`;

  return axiosInstance
    .get<CartIdResponse>(endpoint)
    .then((response) => {
      log.info(
        `Convert CartId Response received from ${endpoint} to our model`,
      );
      return CartIdResponseToCartInfoConverter({
        accountId,
        vendorId,
        data: response.data,
      });
    })
    .catch((error) => {
      /* istanbul ignore next */
      if (axios.isCancel(error)) {
        throw new UpdateCanceledError();
      }

      log.error(error);
      return CartIdResponseToCartInfoConverter({
        accountId,
        vendorId,
        data: null,
      });
    });
}
