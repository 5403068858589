import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CustomBodyCell, RankTableStyles } from "../../RankTableStyles.styles";

interface PhoneTypeCellProps {
  type: string;
}

const PhoneTypeCell = ({ type }: PhoneTypeCellProps): JSX.Element => {
  const { t } = useTranslation();

  const classes = RankTableStyles();

  const renderType = () => {
    const types = type.replace(/\s+/g, "").split(",");
    return types.map((type, index) => {
      const itemIndex = `${index}-${type}`;
      return (
        <Typography className={classes.type} key={itemIndex}>
          {t(`PHONE_RANKING.${type}`)}
        </Typography>
      );
    });
  };

  return (
    <CustomBodyCell className={classes.customCell}>
      {renderType()}
    </CustomBodyCell>
  );
};

export default PhoneTypeCell;
