import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import axios from "../../config/axios/axiosInstance";
import Rewards from "./domains/Rewards";
import { getApiHost } from "../host/HostB2BService";
import LoyaltyResponse from "../domains/LoyaltyResponse";
import LoyaltyResponseToRewardsConverter from "../../converters/LoyaltyResponseToRewardsConverter";

export const REWARDS_ENDPOINT = "/api/v1/rewards-service/rewards";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getLoyaltyProgramScore(accountId: string): Promise<Rewards> {
  const log = useLogService();
  const endpoint = `${getApiHost()}${REWARDS_ENDPOINT}`;

  return axios
    .get<LoyaltyResponse>(`${endpoint}/${accountId}`)
    .then((response) => {
      log.info(`Convert Loyalty score received from ${endpoint} to our model`);
      return LoyaltyResponseToRewardsConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
