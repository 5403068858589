import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between",
    },
    searchWithCollapsibleClose: {
      width: "38%",
    },
    searchWithCollapsibleOpen: {
      width: "38%",
    },
    cart: {
      width: "62%",
      margin: "0 0 30px 20px",
    },
  }),
);
