import React, { useMemo, useRef, useState } from "react";
import ArrowLeft from "../../../../../../assets/ArrowLeft";
import ArrowRight from "../../../../../../assets/ArrowRight";
import TagCarouselStyles from "./TagCarouselStyles";

interface Props {
  tags: JSX.Element[];
}

function TagCarousel({ tags }: Props): JSX.Element {
  const scrollLength = 250;
  const spaceOccupiedByACharInTheTag = 8;
  const extraSpaceInTheTag = 40;

  const tagTitleLengths = tags.map((tag) => {
    return getTitleLength(tag);
  });

  const totalTitleLength = tagTitleLengths.reduce((acc, len) => acc + len, 0);
  const averageTitleLength = totalTitleLength / tags.length;
  const averageTagSize =
    averageTitleLength * spaceOccupiedByACharInTheTag + extraSpaceInTheTag;

  const classes = TagCarouselStyles({
    contentContainerWidth: useMemo(
      () => tags.length * averageTagSize,
      [averageTagSize, tags.length],
    ),
  });

  const maximumQuantityOfRightScrolls = useMemo(
    () => Math.floor((tags.length * averageTagSize) / scrollLength),
    [averageTagSize, tags.length],
  );

  const carouselRef = useRef<HTMLDivElement>(null);

  const [numberOfLeftScrolls, setNumberOfLeftScrolls] = useState(0);
  const [numberOfRightScrolls, setNumberOfRightScrolls] = useState(
    maximumQuantityOfRightScrolls,
  );

  function handleLeftScroll() {
    /* istanbul ignore else */
    if (carouselRef.current) {
      carouselRef.current.scrollLeft -= scrollLength;
      setNumberOfRightScrolls(numberOfRightScrolls + 1);
      setNumberOfLeftScrolls(numberOfLeftScrolls - 1);
    }
  }

  function handleRightScroll() {
    /* istanbul ignore else */
    if (carouselRef.current) {
      carouselRef.current.scrollLeft += scrollLength;
      setNumberOfLeftScrolls(numberOfLeftScrolls + 1);
      setNumberOfRightScrolls(numberOfRightScrolls - 1);
    }
  }

  /* istanbul ignore next */
  function getTitleLength(tag) {
    if (!tag.props.title) {
      return 0;
    }

    if (typeof tag.props.title === "string") {
      return tag.props.title.length;
    }

    if (Array.isArray(tag.props.title.props.children)) {
      let totalLength = 5;
      for (const titlePart of tag.props.title.props.children) {
        if (titlePart.props && titlePart.props.value) {
          totalLength += titlePart.props.value.toString().length;
        } else if (typeof titlePart === "string") {
          totalLength += titlePart.length;
        }
      }
      return totalLength;
    }

    return 0;
  }

  return (
    <div className={classes.mainContainer}>
      {numberOfLeftScrolls > 0 && (
        <button
          data-testid="left-button"
          type="button"
          className={classes.leftButton}
          onClick={handleLeftScroll}
        >
          <ArrowLeft style={{ cursor: "pointer" }} />
        </button>
      )}
      <div
        ref={carouselRef}
        className={classes.scrollableContainer}
        data-testid="scrollable-container"
      >
        <div
          className={classes.contentContainer}
          data-testid="content-container"
        >
          {tags}
        </div>
      </div>
      {numberOfRightScrolls > 0 && (
        <button
          data-testid="right-button"
          type="button"
          className={classes.rightButton}
          onClick={handleRightScroll}
        >
          <ArrowRight style={{ cursor: "pointer" }} />
        </button>
      )}
    </div>
  );
}

export default TagCarousel;
