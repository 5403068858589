import { createEffect } from "effector";
import * as AgentSurveyUseCase from "../../usecase/agentSurvey/AgentSurveyUseCase";

interface FirstData {
  agentId: string;
}

export const AgentSurveyFirstSurveyForAgentEffect = createEffect({
  async handler(firstData: FirstData) {
    return AgentSurveyUseCase.executeFirstSurveyForAgent(firstData.agentId);
  },
});

interface UpdateData {
  surveyId: string;
  agentId: string;
}

export const AgentSurveyUpdateSurveyEffect = createEffect({
  async handler(updateData: UpdateData) {
    return AgentSurveyUseCase.executeUpdateSurvey(
      updateData.surveyId,
      updateData.agentId,
    );
  },
});
