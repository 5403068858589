import * as React from "react";

function FocusSkuIcon(props: any): any {
  return (
    <svg
      width={32}
      height={32}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M9.257 0c.496 0 .91.375.96.868l.7 7.01a2.51 2.51 0 011.619 2.343V13.5h1.928v-3.279c0-1.069.673-1.984 1.618-2.344l.701-7.01a.964.964 0 01.96-.867h3.086c.495 0 .91.375.959.868l.701 7.01a2.51 2.51 0 011.618 2.343V13.5h1.929c.532 0 .964.432.964.964v11.572a.965.965 0 01-.964.964H.964A.965.965 0 010 26.036V14.464c0-.532.432-.964.964-.964h1.929v-3.279c0-1.069.673-1.984 1.618-2.344l.701-7.01A.964.964 0 016.172 0zm15.814 15.429H1.93v9.642h23.14V15.43zm-8.678 1.928a.965.965 0 010 1.929h-5.786a.965.965 0 010-1.929h5.786zM19.957 1.93h-1.341l-.685 6.846a.964.964 0 01-.96.868.579.579 0 00-.578.578V13.5h5.786v-3.279a.58.58 0 00-.579-.578.964.964 0 01-.96-.868l-.683-6.846zm-11.572 0h-1.34l-.686 6.846a.964.964 0 01-.959.868.58.58 0 00-.579.578V13.5h5.786v-3.279a.579.579 0 00-.578-.578.964.964 0 01-.96-.868l-.684-6.846z"
          id="FocusSkuIcon__a"
        />
      </defs>
      <g transform="translate(4.5 4.5)" fill="none" fillRule="evenodd">
        <mask id="FocusSkuIcon__b" fill="#fff">
          <use xlinkHref="#FocusSkuIcon__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#FocusSkuIcon__a" />
        <g mask="url(#FocusSkuIcon__b)" fill="#000">
          <path d="M-4.5-4.5h36v36h-36z" />
        </g>
      </g>
    </svg>
  );
}

export default FocusSkuIcon;
