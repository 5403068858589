import NewOfferings from "../../domains/newOfferings/NewOfferings";
import { NewOfferingsItemV2 } from "../../services/newOfferings/domains/NewOfferingsResponseItem";

const NewOfferingsResponseToNewOfferingsConverterV3 = (
  list: NewOfferingsItemV2[],
): Array<NewOfferings> => {
  const newOfferings = list?.map((item, idx) => {
    const {
      name,
      platformId,
      price,
      isMarketplace,
      promotion,
      vendorItemId: sku,
      imageURL: image,
      suggestedQuantity: quantity,
      priority,
    } = item;

    return <NewOfferings>{
      name,
      sku,
      quantity,
      image,
      platformId,
      score: priority ?? idx,
      price: promotion ? price.price : price.originalPrice,
      total: promotion
        ? price.price * quantity
        : price.originalPrice * quantity,
      hasMarketplace: !!isMarketplace,
    };
  });
  return newOfferings ?? [];
};

export default NewOfferingsResponseToNewOfferingsConverterV3;
