import styled from "styled-components";

interface Props {
  backgroundColor: string;
}

interface TagContainerProps {
  backgroundColor?: string;
}

const TagContainer = styled.div<TagContainerProps>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "rgba(0, 0, 0, 0.05)"};
  border-radius: 16px;
  display: flex;
  padding: 4px 4px 4px;
  height: 24px;
  width: fit-content;
`;

const IconContainer = styled.div<Props>`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
`;

const Text = styled.span`
  font-weight: 500;
  margin-right: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
`;

export { TagContainer, IconContainer, Text };
