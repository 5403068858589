import React from "react";

function Services() {
  return (
    <svg
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stop-color="#F5F338" offset="0%"></stop>
          <stop stop-color="#EEEB00" offset="100%"></stop>
        </linearGradient>
        <rect id="path-2" x="0" y="0" width="48" height="48" rx="16"></rect>
      </defs>
      <g
        id="🙍-Customer-page"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="2.0-Customer-page"
          transform="translate(-1051.000000, -917.000000)"
        >
          <g id="Group-7" transform="translate(1035.000000, 258.000000)">
            <g id="Group-8-Copy" transform="translate(0.000000, 647.000000)">
              <g id="Bitmap" transform="translate(16.000000, 12.000000)">
                <mask id="mask-3" fill="white">
                  <use xlinkHref="#path-2"></use>
                </mask>
                <use
                  id="Mask"
                  fill="url(#linearGradient-1)"
                  xlinkHref="#path-2"
                ></use>
                <g id="Brand-/-Buzz-/-Open-Wing" mask="url(#mask-3)">
                  <g transform="translate(6.018953, 10.534438)">
                    <g
                      id="ABI-Bees_Assets_Mascot-4_sRGB"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      transform="translate(-0.000000, 0.000000)"
                    >
                      <path
                        d="M5.74344796,17.9164868 C5.57518703,17.9496441 5.4018328,17.96518 5.22528277,17.96518 C3.35998441,17.96518 0,16.066045 0,12.6063466 C0,11.1434808 0.616823604,9.66793627 1.76950089,8.53765703 C2.41923413,7.90054896 3.01659511,7.59759429 13.3096679,5.4750682 L5.74344796,17.9164868 Z M26.2137225,4.88651515 L27.9832762,19.2677146 C27.9832762,19.2677146 28.6633578,19.832788 30.1628375,19.832788 C32.4282682,19.832788 35.9773097,18.7457526 35.9771511,14.4593748 C35.9771511,12.6103502 35.5458657,11.7433532 33.4839672,10.0290602 L26.2137225,4.88651515 Z"
                        id="Shape"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      ></path>
                      <path
                        d="M19.4081604,26.9647635 C18.0440731,26.9647635 13.0817795,24.4359168 10.5345433,19.4732713 L10.5339558,19.4726952 L10.3381584,19.0759474 L10.3381584,19.0759474 L10.3383288,19.0761029 L10.5341262,19.4728508 C10.5341262,19.4728508 10.5341262,19.4728508 10.5347136,19.4734268 C14.497446,22.7653861 24.2868757,22.7922299 28.2819127,19.4734268 C25.7345413,24.4359168 20.7722477,26.9647635 19.4081604,26.9647635 Z M9.30346013,16.0163496 L9.21099671,15.5382937 L9.21099671,15.5382937 C9.07705532,14.7675427 9.00362254,13.9581966 9.00362254,13.1108314 C9.00362254,11.6482479 9.15871257,10.402258 9.43188251,9.33945118 L9.43188251,9.33829909 C9.53202032,9.42695207 12.2768328,11.783378 19.4081487,11.8014764 C26.6715318,11.7830429 29.3844324,9.33887514 29.3844324,9.33887514 L29.3844324,9.33887514 L29.3844501,9.33945118 C29.6576024,10.402258 29.8126983,11.6482479 29.8126983,13.1108314 C29.8126983,13.7887236 29.7657013,14.4422835 29.6783245,15.0712163 L29.6047366,15.5388698 L29.6047366,15.5388698 L29.6048776,15.5390368 C29.5590556,15.5833925 26.6164631,18.4077397 19.4083132,18.4290535 C12.2001632,18.4077397 9.25757072,15.5833925 9.21174866,15.5390368 C9.32375813,16.1824123 9.47835498,16.7988225 9.66847356,17.388317 L9.53248499,16.9409897 C9.44652693,16.6395108 9.36989091,16.3312996 9.30346013,16.0163496 Z M27.5156137,6.05757397 C24.5279598,8.18209111 13.8273668,8.15742518 11.1308057,5.92752437 L11.1308057,5.92752437 L11.1308292,5.91369925 C11.140816,2.15672024 15.210167,0 19.4081663,0 C23.6061656,0 27.6755224,2.15672024 27.6855093,5.91369925 L27.6855093,5.92752437 Z"
                        id="Combined-Shape"
                        fill="#000000"
                        fill-rule="nonzero"
                      ></path>
                      <path
                        d="M16.9916874,3.64753006 C16.83638,4.29666023 16.4235585,4.61265661 16.0290306,4.5222633 C15.6345027,4.43187 15.4069198,3.96845741 15.5622507,3.32015675 C15.7167298,2.67102659 16.1943131,2.0813848 16.5896752,2.17177811 C16.984209,2.26217142 17.1461841,2.99843446 16.9916874,3.64753006 Z M20.083131,2.59851936 C19.6778173,2.60179149 19.3547072,3.28512157 19.3596948,3.95135457 C19.3655165,4.61754725 19.6969216,5.01498517 20.1022353,5.01174465 C20.5075725,5.00850464 20.8331558,4.60617032 20.8273341,3.93993732 C20.8223406,3.27371008 20.4884447,2.59527064 20.083131,2.59851936 Z"
                        id="Shape"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      ></path>
                    </g>
                    <path
                      d="M19.4116268,18.4339248 C12.2057657,18.4126242 9.26410767,15.5900131 9.21830016,15.5456848 C9.47024144,16.9923897 9.93771289,18.3026668 10.5402577,19.4770805 C10.5402577,19.4770805 10.5402577,19.4770805 10.540845,19.4776562 C14.5023191,22.7675918 24.2886401,22.7944191 28.2824085,19.4776562 C28.2829958,19.4770805 28.2829958,19.4770805 28.2829958,19.4770805 C28.8855406,18.3026668 29.3530121,16.9923897 29.6049533,15.5456848 C29.5591458,15.5900131 26.6174878,18.4126242 19.4116268,18.4339248 Z M29.3845781,9.34875874 L29.3845781,9.34933443 C29.3845781,9.34933443 26.672539,11.7919997 19.4114623,11.8104219 C12.1503915,11.7919997 9.43834649,9.34933443 9.43834649,9.34933443 L9.43834649,9.34875874 C9.81420294,7.88362015 10.4138114,6.7834709 11.1367478,5.9400807 C13.8863667,8.21318134 24.9607361,8.19336599 27.6861768,5.9400807 C28.0479387,6.36205789 28.3779876,6.84794851 28.6657527,7.41097623 C28.9529305,7.97515533 29.1966499,8.61647729 29.3845781,9.34875874 Z"
                      id="Shape"
                      fill="#FFFF00"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Services;
