import { createStore } from "effector";
import { getLastContacts } from "./LastContactsEvents";
import LastContactsState from "./LastContactsState";

const initialState = <LastContactsState>{
  isLoading: false,
  error: false,
  lastContacts: [],
};

const LastContactsStore = createStore(initialState)
  .on(getLastContacts, (state: LastContactsState) => ({
    ...state,
    isLoading: true,
    error: false,
  }))
  .on(
    getLastContacts.done,
    (state: LastContactsState, { result: lastContacts }) => ({
      ...state,
      lastContacts,
      isLoading: false,
      error: false,
    }),
  )
  .on(getLastContacts.fail, (state: LastContactsState) => ({
    ...state,
    isLoaded: true,
    isLoading: false,
    error: true,
  }));

export default LastContactsStore;
