/* istanbul ignore file */
import React from "react";
import TextField from "@material-ui/core/TextField";
import { useStore } from "effector-react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  newPhoneAdded,
  errorMessageViewed,
} from "../../../../../../../config/typewriter";
import {
  TLP_PHONE_RANKING_VENDOR,
  isFeatureEnabledV2,
} from "../../../../../../../config/featureToggles";
import {
  getValueOrNull,
  getVendorNameWithFT,
  returnVendorIdWithFT,
} from "../../../../../../../config/utils/functions";
import { ANALYTICS_ROUTE_NAMES } from "../../../../../../../config/constants";
import { useAnalytics } from "../../../../../../../analytics/useAnalytics";
import {
  addNewPhone,
  getPhonesRanked,
} from "../../../../../../../stores/phoneRankingStore/phoneRankedEvents";
import PhoneRankedStore from "../../../../../../../stores/phoneRankingStore/phoneRankedStore";
import CallTabStore from "../../../../../../../stores/navigation/callTab/CallTabStore";
import PocInformationStore from "../../../../../../../stores/callList/pocInformation/PocInformationStore";
import AgentCallStore from "../../../../../../../stores/agentCall/AgentCallStore";
import GlobalStore from "../../../../../../../stores/global/GlobalStore";
import GlobalAdminConfigStore from "../../../../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import Button from "../../../../../../../components/button/Button";
import NewPhoneFormStyles from "./NewPhoneFormStyles";
import CustomToast, {
  TOAST_TYPES,
} from "../../../../../../../components/CustomToast/CustomToast";

interface Phone {
  contactName: string;
  phoneNumber: string;
}

interface Props {
  handleCloseOnSuccess: () => void;
  setCurrentPage: (value: number) => void;
}

function NewPhoneForm({
  handleCloseOnSuccess,
  setCurrentPage,
}: Props): React.ReactElement {
  const classes = NewPhoneFormStyles();
  const { t } = useTranslation();
  const { dispatchPocEvent, dispatchGenericEvent } = useAnalytics();

  const { clientId, callId } = useStore(AgentCallStore);
  const { user } = useStore(GlobalStore);
  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { pocInformation } = useStore(PocInformationStore);
  const { callTab } = useStore(CallTabStore);
  const { phones } = useStore(PhoneRankedStore);

  const isPhoneRankingVendor = isFeatureEnabledV2(
    TLP_PHONE_RANKING_VENDOR,
    user.keyToggle,
  );

  const validationSchema = yup.object({
    contactName: yup.string(),
    phoneNumber: yup.string().required(t("GapReason.REASON_REQUIRED_MESSAGE")),
  });

  const handleChangePhone = (ev) => {
    const onlyNums = ev.target.value.replace(/\D/g, "");
    ev.target.value = onlyNums;
    formik.handleChange(ev);
  };

  const handleSubmit = (values: Phone) => {
    const phone = phones.find(
      (each) => each.phoneNumber === values.phoneNumber,
    );
    const phoneTypeText = phone ? `, ${phone.phoneType}` : "";
    handleAddNewPhoneNumber([
      {
        phoneNumber: values.phoneNumber,
        classification: "INPUTTED",
        contactName: values.contactName,
        phoneType: `INPUTTED_PHONE${phoneTypeText}`,
      },
    ]);

    const unwatchUpdatePhoneRankingFail = addNewPhone.fail.watch(
      ({ error }) => {
        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: t("PHONE_RANKING.ERROR_SAVE"),
          errorCode: error?.config?.headers?.requestTraceId,
          InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
        });

        handleErrorMessageViewed();

        // istanbul ignore next
        if (typeof unwatchUpdatePhoneRankingFail === "function") {
          unwatchUpdatePhoneRankingDone();
          unwatchUpdatePhoneRankingFail();
        }
      },
    );

    // istanbul ignore next
    const unwatchUpdatePhoneRankingDone = addNewPhone.done.watch(() => {
      const vendorId = returnVendorIdWithFT(
        userConfig?.vendorId,
        isPhoneRankingVendor,
      );
      const vendorName = getVendorNameWithFT(vendorId, isPhoneRankingVendor);

      CustomToast({
        type: TOAST_TYPES.SUCCESS,
        message: t("PHONE_RANKING.SAVED"),
      });

      getPhonesRanked({
        accountId: clientId,
        vendorId,
        vendorName,
        phones: pocInformation?.phones || [],
      });
      handleCloseOnSuccess();
      setCurrentPage(1);
      dispatchGenericEvent(newPhoneAdded, {
        call_id: callId,
        poc_id: clientId,
        phone_number: values.phoneNumber,
      });

      // istanbul ignore next
      if (typeof unwatchUpdatePhoneRankingDone === "function") {
        unwatchUpdatePhoneRankingFail();
        unwatchUpdatePhoneRankingDone();
      }
    });
  };

  /* istanbul ignore next */
  const handleErrorMessageViewed = () => {
    dispatchPocEvent(
      errorMessageViewed,
      {
        error_message: "Something went wrong. The phone was not saved.",
        error_type: "phonerank save new number error",
        screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
        screen_section: "CTI Modal",
        is_all_products: null,
        total_gap: null,
        total_vol_gap: null,
      },
      { time_of_day: true, is_resumed: true },
    );
  };

  /* istanbul ignore next */
  const handleAddNewPhoneNumber = (requestBody) => {
    const vendorId = returnVendorIdWithFT(
      userConfig?.vendorId,
      isPhoneRankingVendor,
    );
    const vendorName = getVendorNameWithFT(vendorId, isPhoneRankingVendor);

    addNewPhone({
      accountId: clientId,
      vendorId,
      vendorName,
      requestBody,
    });
  };

  const formik = useFormik({
    initialValues: {
      contactName: "",
      phoneNumber: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className={classes.container}>
      <TextField
        name="contactName"
        id="contactName"
        className={classes.input}
        variant="outlined"
        InputProps={{
          classes: {
            input: classes.inputPlaceholder,
          },
        }}
        placeholder={t("PHONE_RANKING.CONTACT_NAME")}
        value={formik.values.contactName}
        onChange={formik.handleChange}
        error={formik.touched.contactName && Boolean(formik.errors.contactName)}
        helperText={formik.touched.contactName && formik.errors.contactName}
        data-testid="add-new-phone-modal-name-input"
      />
      <TextField
        name="phoneNumber"
        id="phoneNumber"
        className={classes.input}
        variant="outlined"
        InputProps={{
          classes: {
            input: classes.inputPlaceholder,
          },
        }}
        placeholder={t("PHONE_RANKING.PHONE_NUMBER")}
        value={formik.values.phoneNumber}
        onChange={handleChangePhone}
        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
        data-testid="add-new-phone-modal-phone-input"
      />
      <Button
        title={t("PHONE_RANKING.SAVE_PHONE")}
        onClick={formik.submitForm}
        className={classes.saveButton}
        data-testid="add-new-phone-modal-save-button"
      >
        {t("PHONE_RANKING.SAVE_PHONE")}
      </Button>
    </div>
  );
}

export default NewPhoneForm;
