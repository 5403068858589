import { Grid, IconButton } from "@material-ui/core";
import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import TooltipIcon from "../../../../assets/TooltipIcon";
import MissionCardStyles from "./MissionCardStyles";
import {
  TLP_SPONSORED_MISSION_TAB,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";
import GlobalStore from "../../../../stores/global/GlobalStore";

interface Props {
  title: string;
  tag?: string;
  tooltip: string;
  svg: Component;
  index: number;
}
const MissionCardComponent: React.FC<Props> = ({
  title,
  tag,
  tooltip,
  index,
  svg,
}) => {
  const { user } = GlobalStore.getState();

  const classes = MissionCardStyles();

  const isSponsoredMissionsTabsEnabled = isFeatureEnabledV2(
    TLP_SPONSORED_MISSION_TAB,
    user.zone,
  );

  /* istanbul ignore else */
  if (isSponsoredMissionsTabsEnabled) {
    return (
      <div className={classes.container} data-testid="mission-card">
        <Grid item container direction="column" className={classes.card}>
          <Grid>
            <span className={classes.svg}>{svg}</span>
          </Grid>

          <Grid
            item
            container={Boolean(tag)}
            className={`${!tag && classes.verticalCenter}`}
            direction="row"
            data-testid={`mission-card-${index}`}
          >
            <div className={classes.titleContainer}>
              <span
                className={classes.title}
                data-testid={`mission-card-title-${index}`}
              >
                {title}
              </span>
              <Tooltip
                title={tooltip}
                className={classes.tooltip}
                data-testid={`mission-card-tooltip-${index}`}
              >
                <IconButton>
                  <TooltipIcon />
                </IconButton>
              </Tooltip>
            </div>
            {tag && (
              <Grid>
                <span
                  className={classes.tag}
                  data-testid={`mission-card-tag-${index}`}
                >
                  {tag}
                </span>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <div className={classes.container} data-testid="mission-card">
      <Grid item container direction="column" className={classes.card}>
        <Grid>
          <span className={classes.svg}>{svg}</span>
        </Grid>

        <Grid
          item
          container
          direction="row"
          data-testid={`mission-card-${index}`}
        >
          <div className={classes.titleContainer}>
            <span
              className={classes.title}
              data-testid={`mission-card-title-${index}`}
            >
              {title}
            </span>
            <Tooltip
              title={tooltip}
              className={classes.tooltip}
              data-testid={`mission-card-tooltip-${index}`}
            >
              <IconButton>
                <TooltipIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Grid>
            <span
              className={classes.tag}
              data-testid={`mission-card-tag-${index}`}
            >
              {tag}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MissionCardComponent;
