import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      width: "100%",
    },
    radioTitle: {
      fontSize: "12px",
      fontWeight: 600,
      color: palette.text.primary,
      marginBottom: "3px",
    },
    radioInput: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#7c7c7c",
      height: "27px",
    },
    radioInputExtra: {
      height: "60px",
    },
    root: {
      "&& div": {
        color: "#525252",
      },
    },
    radioGroup: {
      marginBottom: "10px",
    },
    radioGroupMargin: {
      marginLeft: "10px",
    },
    containerSelect: {
      display: "flex",
    },
    retryButton: {
      display: "block",
      minHeight: "1.875rem",
    },
  }),
);
