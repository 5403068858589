export default function ToastConverter(
  message: string,
  severity: string,
  open: boolean,
): {
  message: string;
  severity: string;
  open: boolean;
} {
  return {
    message,
    severity,
    open: open || false,
  };
}
