import React from "react";
import { IconButton } from "@material-ui/core";
import { Phone as PhoneIcon } from "@hexa-ui/icons";
import CallButtonStyles from "./CallButton.styles";
import { setLastCalledOrCopiedPhone } from "../../../../../../../../stores/phoneRankingStore/phoneRankedEvents";
import { conditionalClass } from "../../../../../../../../config/utils/functions";

interface CallButtonProps {
  sanitizedPhone: string;
  isConnected: boolean;
  index: number;
  disableButton: boolean;
  needClassification: boolean;
  makeCallWebSocket: (
    phone: string,
    isTyped: boolean,
    section?: string,
  ) => void;
  onClose: () => void;
}

const CallButton = ({
  sanitizedPhone,
  isConnected,
  index,
  disableButton,
  needClassification,
  makeCallWebSocket,
  onClose,
}: CallButtonProps): JSX.Element => {
  const classes = CallButtonStyles();

  const renderCallButton = () =>
    isConnected ? (
      <IconButton
        className={`${classes.iconButton} ${conditionalClass(
          disableButton || needClassification,
          classes.iconButtonDisabled,
        )}`}
        disabled={disableButton || needClassification}
        data-testid={`rank-table-call-button-${index}`}
        onClick={() => {
          makeCallWebSocket(sanitizedPhone, false, "CTI Modal");
          setLastCalledOrCopiedPhone(sanitizedPhone);
          onClose();
        }}
      >
        <PhoneIcon data-testid={`rank-table-call-enabled-${index}`} />
      </IconButton>
    ) : (
      <></>
    );

  return renderCallButton();
};

export default CallButton;
