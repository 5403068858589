import React from "react";

const MoneyIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-304 -638) translate(135 592) translate(169 46)">
              <circle cx="19" cy="19" r="18" stroke="#505050" strokeWidth="2" />
              <path
                fill="#505050"
                fillRule="nonzero"
                d="M20.446 30.23v-2.454c1.522-.146 2.724-.659 3.607-1.538s1.325-2.014 1.325-3.405c0-.822-.138-1.526-.415-2.112-.277-.586-.66-1.099-1.148-1.538-.488-.44-1.068-.832-1.74-1.178-.67-.346-1.385-.68-2.141-1.001-.757-.322-1.307-.66-1.648-1.014-.342-.354-.513-.832-.513-1.434 0-.594.148-1.06.446-1.398.297-.337.714-.506 1.25-.506.603 0 1.067.228 1.392.683.326.456.489 1.107.489 1.954h4.126c0-1.547-.426-2.822-1.276-3.827-.85-1.005-2.02-1.618-3.51-1.837V7h-1.94v2.576c-1.547.146-2.784.665-3.711 1.556-.928.891-1.392 2.024-1.392 3.4 0 .822.13 1.522.39 2.1.261.577.635 1.086 1.124 1.525.488.44 1.07.826 1.745 1.16.676.334 1.412.663 2.21.989.797.325 1.355.671 1.672 1.037.317.366.476.871.476 1.514 0 .578-.159 1.031-.476 1.361-.317.33-.753.494-1.306.494-.757 0-1.34-.242-1.752-.726-.41-.484-.616-1.19-.616-2.118H13c0 1.685.474 3.042 1.422 4.071.948 1.03 2.31 1.638 4.083 1.825v2.466h1.941z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MoneyIcon;
