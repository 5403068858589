import React from "react";
import { ButtonBase, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Modal from "../../../components/modal/Modal";
import WarningOutOfStockStyles from "../WarningOutOfStockStyles";

interface Props {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  error: string;
}

const ErrorOrderModal: React.FunctionComponent<Props> = ({
  isOpen,
  setIsOpen,
  error,
}) => {
  const { t } = useTranslation();
  const classes = WarningOutOfStockStyles();

  return (
    <>
      <Modal open={isOpen} data-testid="error-order-modal">
        <div className={classes.container}>
          <div
            className={classes.header}
            data-testid="error-order-modal-header"
          >
            {t("ErrorModalOrder.ERROR")}
          </div>
          <div
            className={classes.formContainer}
            data-testid="error-order-modal-iframe-container"
          >
            <div
              data-testid="error-order-modal-message"
              className={classes.message}
            >
              {error}
            </div>
          </div>
          <Box display="flex" flexDirection="row">
            <ButtonBase
              onClick={() => setIsOpen(false)}
              className={classes.closeButton}
              data-testid="error-order-modal-button"
            >
              {t("ErrorModalOrder.BUTTON")}
            </ButtonBase>
          </Box>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(ErrorOrderModal);
