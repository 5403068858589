/* istanbul ignore file */
import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface Props {
  collapsibleIsOpen?: boolean;
}

export default makeStyles<Theme, Props>(({ breakpoints }) =>
  createStyles({
    mainContainer: {
      width: "calc(100% - 64px)",
      display: "flex",
      height: "100%",
      position: "fixed",
    },
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      overflow: "auto",
      marginBottom: 60,
    },
    newLayout: {
      width: "100%",
      margin: "0px 35px",
    },
    mainContent: {
      flex: 1,
      overflowY: "auto",
      position: "relative",
      height: "100%",
      alignContent: "start",
      flexDirection: "row",
    },
    callInfoHidden: {
      display: "none",
    },
    iFrameHidden: {
      display: "none",
    },
    iframeHidden: {
      display: "none",
    },
    stopSimulator: {
      fontSize: 16,
      marginTop: 10,
      marginLeft: 16,
      alignSelf: "flex-start",
      display: "flex",
      borderRadius: "100%",
      border: "1px solid white",
      "&:hover span": {
        visibility: "visible",
        opacity: 1,
      },
    },
    tooltiptext: {
      visibility: "hidden",
      width: "125px",
      backgroundColor: "#555",
      color: "#fff",
      textAlign: "center",
      borderRadius: "6px",
      padding: "5px 0",
      position: "absolute",
      zIndex: 1,
      bottom: "125%",
      left: "50%",
      marginLeft: "-60px",
      opacity: "0",
      transition: "opacity 0.3s",
      "&::after": {
        content: "",
        position: "absolute",
        top: "100%",
        left: "50%",
        marginLeft: "-5px",
        borderWidth: "5px",
        borderStyle: "solid",
        borderColor: "#555 transparent transparent transparent",
      },
    },
    stopSimaltorHidden: {
      display: "none",
    },
    iFrameContainer: {
      position: "fixed",
      left: 0,
      right: 0,
      margin: "40px auto",
      width: "400px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
    },
    iframe: {
      border: "none",
    },
    loadingIcon: {
      height: "100%",
      marginTop: "270px",
    },
    gridB2bLoginInformation: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "70px",
      paddingLeft: "60px",
    },

    messageContainer: {
      width: "950px",
      marginBottom: "8px",
      display: "flex",
      position: "fixed",
      left: ({ collapsibleIsOpen }) => (collapsibleIsOpen ? "26vw" : "15vw"),
      margin: "0px auto",
      flexDirection: "column",
      zIndex: 99,
      [breakpoints.up(1440)]: {
        left: ({ collapsibleIsOpen }) => (collapsibleIsOpen ? "28vw" : "22vw"),
      },
    },
  }),
);
