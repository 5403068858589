import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import VendorStore from "../../../stores/vendor/VendorStore";
import {
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_CALL_LIST_SERVICE,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../../config/featureToggles";
import axios from "../../../config/axios/axiosInstance";
import AgentCalls from "../../../domains/AgentCall";
import { getApiHost, getVendorIdParam } from "../../host/HostService";
import NextPocRequestV2 from "../domains/NextPocRequestV2";
import NextPocRequestV3 from "../domains/NextPocRequestV3";
import { NextPOCResponse } from "../domains/NextPOCResponse";
import NextPOCResponseToAgentCallConverter from "../../../converters/nextPoc/getNextPoc/NextPOCResponseToAgentCallConverter";
import { CALL_LIST_SERVICE } from "../../../config/services";
import {
  getCountryByToken,
  getUserInfos,
} from "../../../config/utils/functions";
import GetNextPocPayload from "../../../domains/GetNextPocPayload";

export const TLP_CALL_LIST_V2 = "/call-list/next-poc/v2";
export const TLP_CALL_LIST_V3 = "/call-list/next-poc/v3";

export const URL_V3 = "/v3/call-list/next-poc";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 1,
  retryDelay: () => {
    return 1000;
  },
  retryCondition: (error) => {
    if (error) {
      return error.response?.status === 400 || error.response?.status === 401;
    }

    return false;
  },
});

export function getNextPoc(
  request: GetNextPocPayload,
  keyToggle: string,
): Promise<AgentCalls> {
  const log = useLogService();
  const { vendor } = VendorStore.getState();
  const vendorId = vendor?.id;

  const isMultiContract = isFeatureEnabledV2(
    TLP_MULTICONTRACT_CALL_LIST_SERVICE,
    keyToggle,
  );

  const isOrganizationEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    getCountryByToken(),
  );

  const endpoint = createUrl(isMultiContract, isOrganizationEnabled);
  const nextPocRequest = createPayload(request, isOrganizationEnabled);
  const vendorIdParam = getVendorIdParam(vendorId, isMultiContract);

  const urltlp = `${getApiHost(CALL_LIST_SERVICE)}${endpoint}${
    !isOrganizationEnabled ? vendorIdParam : ""
  }`;

  return axios
    .post<NextPOCResponse>(urltlp, nextPocRequest)
    .then((response) => {
      log.info(`Convert GetNexPoc received from ${endpoint} to our model`);
      return NextPOCResponseToAgentCallConverter(response.data);
    })
    .catch((error) => {
      log.error("GetNextPocService - getNextPoc error: ", error);
      throw error;
    });
}

const createUrl = (
  isMultiContract: boolean,
  isOrganization: boolean,
): string => {
  if (isOrganization) {
    return URL_V3;
  }

  return isMultiContract ? TLP_CALL_LIST_V3 : TLP_CALL_LIST_V2;
};

const createPayload = (request: GetNextPocPayload, isOrganization: boolean) => {
  const userConfig = {
    segmentations: request.userConfigRequest?.segmentation,
    missionPriority: request.userConfigRequest?.missionPriority,
  };

  const buConfig = {
    missionPriority: request.buConfigRequest?.missionPriority,
  };

  if (isOrganization) {
    const { groupId, orgId, country, userEmail, vendors, missionPriority } =
      getUserInfos();

    return <NextPocRequestV3>{
      groupId,
      orgId,
      userEmail,
      vendors,
      country,
      missionPriority,
    };
  }

  return <NextPocRequestV2>{
    email: request.email,
    vendorId: request.userConfigRequest?.vendorId,
    vendorName: request.userConfigRequest?.vendorName,
    userConfigRequest: userConfig,
    buConfigRequest: buConfig,
  };
};
