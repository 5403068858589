import { createEffect, createEvent } from "effector";

import { RedeemItem } from "../../domains/redeem/Redeem";
import { execute as GetEnrolledSinceUseCase } from "../../usecase/redeem/GetEnrolledSinceUseCase";
import { execute as GetLastRedeemUseCase } from "../../usecase/redeem/GetLastRedeemUseCase";
import { execute as GetRedeemablesUseCase } from "../../usecase/redeem/GetRedeemablesUseCase";

export const getEnrolledSince = createEffect({
  async handler(pocId: string) {
    return GetEnrolledSinceUseCase(pocId);
  },
});

export const getLastRedeem = createEffect({
  async handler(pocId: string) {
    return GetLastRedeemUseCase(pocId);
  },
});

export const getRedeemItems = createEffect({
  async handler(pocId: string) {
    return GetRedeemablesUseCase(pocId);
  },
});

export const setRedeemItems = createEvent<Array<RedeemItem>>("setRedeemItems");

export const clearRedeemItems = createEvent("clearRedeemItems");
