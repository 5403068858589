export const sitStoreIds: { [key: string]: string } = {
  BR: "a2a5433c-aba3-4850-932b-91bbf90924fc",
  BRF_BR: "07c29196-a02f-4bc1-9584-3bd0908a0560",
  GPA_BR: "4d7f8e2f-2711-40eb-8bd7-7c0f242ede05",
  NESTLE_BR: "15c92719-e80d-41d6-9d58-a53616a0d060",
  CO: "6d9ccf42-96b6-4121-a1d9-24920c656086",
  EC: "fa76813d-0633-4afc-8596-ed699e13df6b",
  NESTLE_EC: "07018b64-07d4-43cf-9153-ea37957a50b9",
  ES: "73c80609-e5ab-4f29-9add-87d1f749d8a3",
  DO: "7b7efc58-6c96-43e6-95fe-0640d2aee5f9",
  MERCASID_DO: "ede4cfb1-0dd2-45f9-ae79-41851484d8ff",
  ZA: "dd345f1a-ce4b-4622-a2b6-ab1b89429c36",
  PE: "7937d20b-b838-4682-827e-0f75b9380506",
  GLORIA_PE: "5080831a-3cbe-424f-a0c3-a0b3ff8f49ad",
  GLORIA_CO: "a6fd4781-81fc-484a-99da-7b39ee015f98",
  MX: "c5ac68ef-34e0-4649-b70b-6cd7153bfeef",
  PA: "85ac0e19-9793-4cd0-861b-44dd048efc4a",
  HN: "9c604d7f-ae87-439b-8f17-e9d8849dd4d1",
  SV: "f0f0b8bf-233e-4420-ae37-089569f028f3",
  UY: "552adebf-bec9-4cd0-ac91-02079b32077c",
  PY: "9c601bf6-d238-4041-8146-5ef2f81210c9",
  AR: "ff79a8f1-80ac-4475-90e5-a76f0a0eeee7",
  NESTLEACTISERVE_PH: "c0bbdd19-77f8-4459-baf8-f1467d70bb44",
  NESTLECAVITE_PH: "0517c9a4-6a2b-436c-ac6b-89dbdce33a40",
  NESTLERUKUN_ID: "58e0fd51-02e1-4fe9-9717-ef8a22e2be8e",
  NESTLECIPTA_ID: "d831e350-d9ea-4d7b-ae2d-2db572d530fa",
  PEPSICO_BR: "07aad9ae-5dd9-4c3e-8089-fa5512c15fea",
};
