import * as Service from "../../services/phoneRanking/UpdateRankedPhonesService";
import RankedPhones from "../../domains/phoneRanking/RankedPhones";
import UpdateRankedPhonesRequest from "../../domains/phoneRanking/UpdateRankedPhonesRequest";

export const execute = (
  accountId: string,
  vendorId: string,
  vendorName: string,
  request: Array<UpdateRankedPhonesRequest>,
): Promise<RankedPhones | null> => {
  return Service.execute(accountId, vendorId, vendorName, request);
};
