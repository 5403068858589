import { getRecommendedMarketplaceProducts } from "../../services/recommendedMarketplaceProducts/RecommendedMarketplaceProductsService";
import { Product } from "../../domains/products/Product";

export function execute({
  accountId,
  country,
}: {
  accountId: string;
  country: string;
}): Promise<Product[]> {
  return getRecommendedMarketplaceProducts(accountId, country);
}
