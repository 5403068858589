import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import { useStore } from "effector-react";

import {
  LINK_CALL,
  LINK_HOME,
  LINK_SERVER_ERROR,
  BASE_NAME,
  TAB_GAP_ROUTE,
  TAB_ORDERS_ROUTE,
  TAB_CUSTOMER_ROUTE,
  TAB_B2B_TRAINER_ROUTE,
} from "../config/constants";
import {
  TLP_CUSTOMER_PAGE,
  isFeatureEnabledV2,
} from "../config/featureToggles";
import { CallRouteWrapper } from "../routes/call/CallRouteWrapper";
import ServerErrorRoute from "../routes/error/internalError/ServerErrorRoute";
import HomeRoute from "../routes/home/HomeRoute";
import GlobalStore from "../stores/global/GlobalStore";
import * as CallTabEvents from "../stores/navigation/callTab/CallTabEvents";

const Routes: React.FunctionComponent = () => {
  const { user } = useStore(GlobalStore);

  const isCustomerPageEnabled = isFeatureEnabledV2(
    TLP_CUSTOMER_PAGE,
    user.keyToggle,
  );

  /* istanbul ignore next */
  const routeAux = isCustomerPageEnabled ? TAB_CUSTOMER_ROUTE : TAB_GAP_ROUTE;
  CallTabEvents.setCallTab(routeAux);

  return (
    <Switch>
      <Route path={[BASE_NAME, LINK_HOME]} exact component={HomeRoute} />
      <Route
        path={[
          TAB_CUSTOMER_ROUTE,
          TAB_B2B_TRAINER_ROUTE,
          TAB_GAP_ROUTE,
          TAB_ORDERS_ROUTE,
        ]}
        exact
        component={CallRouteWrapper}
      />
      <Redirect from={LINK_CALL} to={routeAux} />
      <Route path={LINK_SERVER_ERROR} exact component={ServerErrorRoute} />
    </Switch>
  );
};
export default Routes;
