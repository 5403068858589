import React from "react";
import { Box } from "@material-ui/core";

interface Props {
  testId: string;
  children: React.ReactNode;
}

const TabContent: React.FunctionComponent<Props> = ({ testId, children }) => {
  return (
    <Box style={{ display: "flex", height: "100%" }} data-testid={testId}>
      {children}
    </Box>
  );
};

export default TabContent;
