import React, { useState, useEffect, useCallback } from "react";

import { Grid } from "@material-ui/core";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { VendorByAutodialerProps } from "@domains/AutoDialerEvents";
import { ScreenSection, useNextPocHook } from "@hooks/useNextPocHook";
import * as GapCalculationUseCase from "@usecase/gap/GapCalculationUseCase";
import {
  resetVendorsPoc,
  searchVendorPocByAccountId,
} from "@stores/callList/VendorPocByAccount/VendorPocByAccountEffect";
import VendorPocByAccountIdStore from "@stores/callList/VendorPocByAccount/VendorPocByAccountStore";
import { setCallTab } from "@stores/navigation/callTab/CallTabEvents";

import { getVendorName } from "grow-shared-services";
import AgentCall from "@domains/AgentCall";
import { useLogService } from "admin-portal-shared-services";
import { FAILED_GAP_CALCULATION } from "@config/logMessages";
import { useAnalytics, CALL_TRIGGER } from "../../../analytics/useAnalytics";
import CustomToast, {
  TOAST_TYPES,
} from "../../../components/CustomToast/CustomToast";
import PocInfoModal from "../../../components/pocInfoModal/PocInfoModal";
import SearchBar, {
  SEARCH_METHOD,
} from "../../../components/searchBar/SearchBar";
import VendorSelectModal from "../../../components/VendorSelectModal/VendorSelectDialog";
import {
  CALL_ID,
  LINK_CALL,
  SEGMENTATION_COUNTRIES,
  ANALYTICS_ROUTE_NAMES,
  LINK_HOME,
  TAB_CUSTOMER_ROUTE,
  PocCallOrigin,
} from "../../../config/constants";
import {
  TLP_AGENT_IDENTIFICATION_ADMIN,
  TLP_AGENT_IDENTIFICATION,
  TLP_SEARCH_BY_POC,
  TLP_SEARCH_BY_POC_ADMIN,
  TLP_CUSTOMER_PAGE,
  isFeatureEnabled,
  TLP_CALL_AUTO_DIALER,
  isFeatureEnabledV2,
  GROW_ORGANIZATION_ACTIVATION,
} from "../../../config/featureToggles";
import {
  homeHeaderInteraction,
  callGetNextPoc,
  callGetNextPocFailed,
  searchByPocInteraction,
  errorMessageViewed,
  callInAutoDialer,
  stopCalling,
  callInAutoDialerFailed,
  autoDialerPopScreen,
} from "../../../config/typewriter";
import {
  getValueOrNull,
  setPocVendorId,
  getUserInfos,
  getAccountIdFromAgentCallResponse,
} from "../../../config/utils/functions";
import {
  SearchedPoc,
  VendorPocByAccountId,
} from "../../../domains/PocInformation";
import AgentCallStore from "../../../stores/agentCall/AgentCallStore";
import {
  setAutoDialerEvent,
  showCallNavigationButtonEvent,
  showModalStopAutoDialerEvent,
} from "../../../stores/autodialer/AutoDialerEvents";
import AutoDialerStore from "../../../stores/autodialer/AutoDialerStore";
import { searchPocByAccountId } from "../../../stores/callList/pocInformation/PocInformationEvents";
import GlobalAdminConfigStore from "../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "../../../stores/global/GlobalStore";
import * as GetNextPocEvents from "../../../stores/nextPoc/getNextPoc/GetNextPocEvents";
import {
  createSearchedPocEffect,
  createSearchedPocDone,
  resetCreateSearchedPoc,
} from "../../../stores/searchedPoc/CreateSearchedPocEvents";
import createSearchedPocStore from "../../../stores/searchedPoc/CreateSearchedPocStore";
import VendorStore from "../../../stores/vendor/VendorStore";
import useWebSocket from "../../call/components/callTimerInfo/hooks/useWebSocket";
import CallOptions from "../CallOptions/CallOptions";
import ModalStopAutoDialer from "../components/modalStopAutoDialer/ModalStopAutoDialer";
import StopAutoDialer from "../components/stopAutoDialer/StopAutoDialer";
import AgentIdentification from "./agentIdentification";
import GetNextPocButton from "./getNextPocButton/GetNextPocButton";
import HeaderStyles from "./HeaderStyles";

function Header(): React.ReactElement {
  const log = useLogService();
  const { t } = useTranslation();
  const classes = HeaderStyles();
  const history = useHistory();
  const agentCallStates = useStore(AgentCallStore);
  const { autoDialerEnabled, showModalStopAutoDialer } =
    useStore(AutoDialerStore);
  const { loading } = useStore(createSearchedPocStore);
  const { dispatchPocEvent, dispatchGenericEvent } = useAnalytics();
  const globalState = useStore(GlobalStore);
  const [pocSearched, setPocSearched] = useState<string>("");
  const [pocCallOrigin, setPocCallOrigin] = useState<string | undefined>("");
  const [openModal, setOpenModal] = useState(false);
  const [openVendorSelectDialog, setOpenVendorSelectDialog] = useState(false);
  const [searchedPoc, setSearchedPoc] = useState<SearchedPoc>();

  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { vendor } = VendorStore.getState();
  const { searchedAccountId, numberOfPocs, vendorPoc, error } = useStore(
    VendorPocByAccountIdStore,
  );
  const { agentNotInCall, getNextPoc } = useNextPocHook();

  const [vendorByAutodialer, setVendorByAutodialer] =
    useState<VendorByAutodialerProps>({});

  const { vendorId, missionPriority } = getUserInfos();

  const [callPocId, setCallPocId] = useState<string>("");

  const isCustomerPageEnabled = isFeatureEnabledV2(
    TLP_CUSTOMER_PAGE,
    globalState.user.keyToggle,
  );

  const isAgentIdentificationEnabled = isFeatureEnabled(
    TLP_AGENT_IDENTIFICATION,
    TLP_AGENT_IDENTIFICATION_ADMIN,
  );

  const isSearchByPocEnabled = isFeatureEnabled(
    TLP_SEARCH_BY_POC,
    TLP_SEARCH_BY_POC_ADMIN,
    globalState.user.keyToggle,
  );

  const isCallInAutoDialerEnabled = isFeatureEnabledV2(
    TLP_CALL_AUTO_DIALER,
    globalState.user.keyToggle,
  );

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    globalState.user.keyToggle,
  );

  const { autoDialerEvent } = useWebSocket();

  const isCallIdUndefined =
    localStorage.getItem(CALL_ID) === undefined ||
    localStorage.getItem(CALL_ID) === null ||
    localStorage.getItem(CALL_ID) === "undefined" ||
    localStorage.getItem(CALL_ID) === "";

  const notCallGetNextPoc =
    SEGMENTATION_COUNTRIES.indexOf(globalState.user.keyToggle) > -1 &&
    (userConfig.segmentation === null ||
      userConfig.segmentation === undefined ||
      userConfig.segmentation.length === 0);

  /* istanbul ignore next */
  const matchVendorFromAutodialer = useCallback(
    (vendors: VendorPocByAccountId[]) => {
      const isVendorIdPresentInAutoDialerEvent =
        Object.keys(vendorByAutodialer).length !== 0 && searchedAccountId;

      const matchVendor =
        vendors && isVendorIdPresentInAutoDialerEvent
          ? vendors.find(
              (vendor: VendorPocByAccountId) =>
                vendor.vendorId === vendorByAutodialer.id,
            )
          : ({} as VendorPocByAccountId);

      if (isVendorIdPresentInAutoDialerEvent && !matchVendor) {
        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: t("PocTyped.ID_AND_SELLER_INVALID"),
        });

        dispatchGenericEvent(callInAutoDialerFailed, {
          error_message: t("PocTyped.ID_AND_SELLER_INVALID"),
        });

        resetVendorsPoc(null);

        return null;
      }

      return matchVendor;
    },
    [vendorPoc],
  );

  /* istanbul ignore next */
  const handleVendorSelection = useCallback(
    (vendorFromAutodialer) => {
      const isSinglePocAndSearched = numberOfPocs === 1 && searchedAccountId;

      const isVendorFromAutodialer =
        Object.keys(vendorFromAutodialer).length !== 0;

      if (isSinglePocAndSearched || isVendorFromAutodialer) {
        const selectedVendorId = isVendorFromAutodialer
          ? vendorFromAutodialer
          : vendorPoc[0];

        setPocVendorId(selectedVendorId?.vendorId);
        const searchPocRequest = {
          accountId: selectedVendorId?.accountId,
          vendorId: selectedVendorId?.vendorId,
          country: globalState.user.zone,
          isSearchPoc: true,
        };
        searchPocByAccountId(searchPocRequest).then((pocInfo) => {
          setSearchedPoc(pocInfo);
          if (pocCallOrigin !== PocCallOrigin.AUTO_DIALER) {
            setOpenModal(true);
          }
        });
      } else if (numberOfPocs > 1 && searchedAccountId) {
        setOpenVendorSelectDialog(true);
      }
    },
    [vendorPoc],
  );

  /* istanbul ignore next */
  useEffect(() => {
    if ((numberOfPocs < 1 && searchedAccountId) || error) {
      CustomToast({
        type: TOAST_TYPES.ERROR,
        message: t("PocTyped.ID_INVALID"),
      });

      if (pocCallOrigin === PocCallOrigin.AUTO_DIALER) {
        dispatchGenericEvent(callInAutoDialerFailed, {
          error_message: t("PocTyped.ID_INVALID"),
        });
      }

      dispatchPocEvent(
        errorMessageViewed,
        {
          screen_name: "Agent KPIs",
          screen_section: "Search by POC Modal",
          error_type: "Information not found",
          error_message: "The ID provided is not a valid POC.",
          is_all_products: null,
          total_gap: null,
          total_vol_gap: null,
        },
        { time_of_day: true, is_resumed: true },
      );

      resetVendorsPoc(null);

      return;
    }

    const vendorFromAutodialer = matchVendorFromAutodialer(vendorPoc);

    if (!vendorFromAutodialer) return;

    handleVendorSelection(vendorFromAutodialer);
  }, [searchedAccountId, numberOfPocs, vendorPoc]);

  useEffect(() => {
    if (isCallInAutoDialerEnabled) {
      if (!isCallIdUndefined && agentCallStates.clientId) {
        showCallNavigationButtonEvent(true);
        return;
      }
      showCallNavigationButtonEvent(false);
    }
  }, [isCallIdUndefined, agentCallStates.clientId, isCallInAutoDialerEnabled]);

  const dispatchAnalyticsPocEvent = useCallback(
    (errorMessage: string, errorType: string, screenSection: string) => {
      dispatchPocEvent(
        errorMessageViewed,
        {
          error_message: errorMessage,
          error_type: errorType,
          screen_name: ANALYTICS_ROUTE_NAMES.get(LINK_HOME),
          screen_section: screenSection,
          is_all_products: null,
          total_gap: null,
          total_vol_gap: null,
        },
        { time_of_day: true, is_resumed: true },
      );
    },
    [dispatchPocEvent],
  );

  /* istanbul ignore next */
  const handleGoToCallPage = () => {
    if (pocCallOrigin === PocCallOrigin.AUTO_DIALER) {
      dispatchGenericEvent(autoDialerPopScreen, {
        call_id: localStorage.getItem(CALL_ID),
        country: globalState.user.zone,
        poc_call_origin: PocCallOrigin.AUTO_DIALER,
        poc_id: callPocId,
        vendor_id: vendor.id,
        phone_number: autoDialerEvent?.phone,
      });
    }
    if (isCustomerPageEnabled) {
      setCallTab(TAB_CUSTOMER_ROUTE);
      history.push({
        pathname: TAB_CUSTOMER_ROUTE,
      });
    }
    history.push({
      pathname: LINK_CALL,
    });
  };

  const enableAutoDialer = () => {
    setAutoDialerEvent(!autoDialerEnabled);
    setPocSearched("");
    dispatchGenericEvent(callInAutoDialer, {
      button_label: "Call in auto dialer",
    });
  };

  /* istanbul ignore next */
  useEffect(() => {
    const unSearchPocByAccountId = searchPocByAccountId.fail.watch(
      ({ error }) => {
        CustomToast({
          type: TOAST_TYPES.ERROR,
          message: t("ServerError.ERROR_MESSAGE_TRY_AGAIN"),
          errorCode: error?.config?.headers?.requestTraceId,
          InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
        });

        dispatchAnalyticsPocEvent(
          t("ServerError.ERROR_MESSAGE_TRY_AGAIN"),
          "go to poc",
          ScreenSection.HOME_HEADER,
        );
      },
    );

    return () => {
      unSearchPocByAccountId();
    };
  }, [t, dispatchAnalyticsPocEvent]);

  const getButtonLabel = () => {
    if (isCallIdUndefined && !agentCallStates.clientId) {
      if (isCallInAutoDialerEnabled) {
        return t("HomeMainPage.CALL_MANUALY");
      }
      if (isOrganizationConfigEnabled) {
        return t("HomeMainPage.CALL_NEXT_POC");
      }
      return t("HomeMainPage.GET_NEXT_POC");
    }
    return t("HomeMainPage.HAS_OPEN_CALL");
  };

  const autoDialerButtonLabel = () => {
    return t("HomeMainPage.CALL_IN_AUTO_DIALER");
  };

  const title = () => {
    return t("HomeMainPage.CALL_POC_OPTIONS");
  };

  /* istanbul ignore next */
  const getValue = (orderSku: number) => {
    return orderSku === 0
      ? CALL_TRIGGER.NO_ORDER
      : CALL_TRIGGER.OUT_OF_PARAMETER;
  };

  const handleHeaderInteraction = () => {
    if (getButtonLabel() === t("HomeMainPage.HAS_OPEN_CALL")) {
      homeHeaderInteraction({
        button_label: "get-next-poc-button",
        button_name: getButtonLabel(),
        button_selection: "",
      });
    }
  };

  /* istanbul ignore next */
  const checkUnwatchNextPocFunction = (
    unWatchGetNextPocEvent,
    unWatchGetNextPocFail,
  ) => {
    if (
      typeof unWatchGetNextPocEvent === "function" &&
      typeof unWatchGetNextPocFail === "function"
    ) {
      unWatchGetNextPocEvent();
      unWatchGetNextPocFail();
    }
  };

  const getActions = () => {
    const isSearchByPocOrAutoDialer =
      agentCallStates.pocCallOrigin === PocCallOrigin.SEARCH_BY_POC ||
      agentCallStates.pocCallOrigin === PocCallOrigin.AUTO_DIALER;

    if (notCallGetNextPoc && !isSearchByPocOrAutoDialer) {
      CustomToast({
        type: TOAST_TYPES.ERROR,
        message: t("HomeMainPage.NO_SEGMENTATION"),
      });

      dispatchAnalyticsPocEvent(t("HomeMainPage.NO_SEGMENTATION"), "", "");

      return;
    }

    const shouldShowGroupWithoutMissionToast =
      isOrganizationConfigEnabled &&
      !(Array.isArray(missionPriority) && missionPriority.length > 0);

    if (shouldShowGroupWithoutMissionToast) {
      CustomToast({
        type: TOAST_TYPES.WARNING,
        message: t("HomeMainPage.GROUP_WITHOUT_MISSION"),
      });
      return;
    }

    handleHeaderInteraction();

    if (agentNotInCall) {
      getNextPoc(ScreenSection.HOME_HEADER);

      const unWatchGetNextPocEvent = GetNextPocEvents.getNextPocDone.watch(
        (response) => {
          if (response.clientId) {
            dispatchPocEvent(
              callGetNextPoc,
              {
                screen_name: "Agent KPIs",
                screen_section:
                  /* istanbul ignore next */
                  response.screenSection === ScreenSection.CALL
                    ? ScreenSection.SIDEBAR
                    : response.screenSection,
                call_id: getValueOrNull(response.callId),
                call_trigger: getValue(response.orderSku),
                country: response.countryCode,
                poc_id: response.clientId,
                total_gap: getValueOrNull(response.gap.topline.total),
                total_vol_gap: getValueOrNull(response.gap.volume.total),
                vendor_id: userConfig?.vendorId,
              },
              {
                time_of_day: true,
                removeProps: [
                  "DDC",
                  "poc_segment",
                  "credit_available",
                  "poc_recurrence",
                  "poc_potential",
                  "poc_subsegment",
                  "marketing_tag",
                ],
              },
            );
            showCallNavigationButtonEvent(true);
            handleGoToCallPage();
          } else {
            CustomToast({
              type: TOAST_TYPES.WARNING,
              message: t("HomeMainPage.CALL_LIST_COMPLETE"),
            });

            dispatchAnalyticsPocEvent(
              "The Call List is complete, all POCs have been contacted",
              "call list complete",
              ScreenSection.HOME_HEADER,
            );

            dispatchGenericEvent(
              callGetNextPocFailed,
              {
                screen_name: "Agent KPIs",
                screen_section: ScreenSection.HOME_HEADER,
                error_type: "call list complete",
                error_message:
                  "The Call List is complete, all POCs have been contacted",
              },
              { time_of_day: true },
            );
          }

          checkUnwatchNextPocFunction(
            unWatchGetNextPocEvent,
            unWatchGetNextPocFail,
          );
        },
      );

      const unWatchGetNextPocFail = GetNextPocEvents.getNextPocFail.watch(
        (message) => {
          dispatchGenericEvent(
            callGetNextPocFailed,
            {
              screen_name: "Agent KPIs",
              screen_section: ScreenSection.HOME_HEADER,
              error_type: "unable to get next poc error",
              error_message: "Service unavailable. Try again later.",
            },
            { time_of_day: true },
          );

          CustomToast({
            type: TOAST_TYPES.ERROR,
            message: t(message ? message[0] : ""),
            errorCode: message ? message[1] : "",
            InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
          });

          dispatchAnalyticsPocEvent(
            t(message ? message[0] : ""),
            "get next poc error",
            ScreenSection.HOME_HEADER,
          );

          checkUnwatchNextPocFunction(
            unWatchGetNextPocEvent,
            unWatchGetNextPocFail,
          );
        },
      );
    } else {
      showCallNavigationButtonEvent(true);
      handleGoToCallPage();
    }
  };

  const getGapCalculation = async (response: AgentCall) => {
    try {
      const accountId = getAccountIdFromAgentCallResponse(response);
      const gap = await GapCalculationUseCase.execute(
        response.countryCode,
        response.vendorId,
        accountId,
      );
      createSearchedPocDone({ ...response, gap });
    } catch (e) {
      log.error(FAILED_GAP_CALCULATION, e);
    }
  };

  const handleGoToPoc = useCallback(
    (selectedPoc?: SearchedPoc) => {
      const pocInfo = selectedPoc ?? searchedPoc;
      createSearchedPocEffect({
        id: pocInfo?.accountId,
        accountId: pocInfo?.accountId,
        agentId: globalState.user?.login,
        vendorId: userConfig.vendorId,
        vendorName: getVendorName(userConfig.vendorId),
        vendorAccountId: pocInfo?.vendorAccountId,
        customerAccountId: pocInfo?.customerAccountId,
        legacyAccountId: pocInfo?.legacyAccountId,
        pocCallOrigin,
      });
      /* istanbul ignore next */
      const unWatchCreateSearchedPoc = createSearchedPocEffect.done.watch(
        (data) => {
          localStorage.setItem(CALL_ID, data.result.callId);
          getGapCalculation(data.result);

          if (isOrganizationConfigEnabled) {
            setPocVendorId(data.result.vendorId);
          }

          dispatchPocEvent(
            callGetNextPoc,
            {
              call_id: getValueOrNull(data.result.callId),
              screen_name: "Agent KPIs",
              screen_section: "Search by POC Modal",
              vendor_id: userConfig?.vendorId,
            },
            {
              removeProps: [
                "DDC",
                "poc_segment",
                "credit_available",
                "poc_recurrence",
                "poc_potential",
                "poc_subsegment",
                "marketing_tag",
              ],
            },
          );
          showCallNavigationButtonEvent(true);

          if (pocCallOrigin === PocCallOrigin.AUTO_DIALER) {
            CustomToast({
              type: TOAST_TYPES.SUCCESS,
              message: t("CUSTOMER_PAGE.CONNECTED_TO_POC_WITH_AUTO_DIALER"),
            });
          }

          handleGoToCallPage();

          if (typeof unWatchCreateSearchedPoc === "function") {
            unWatchCreateSearchedPoc();
            unWatchCreateSearchedPocFail();
          }
        },
      );
      /* istanbul ignore next */
      const unWatchCreateSearchedPocFail = createSearchedPocEffect.fail.watch(
        ({ error }) => {
          dispatchGenericEvent(callGetNextPocFailed, {
            screen_name: "Agent KPIs",
            screen_section: "Search by POC Modal",
            error_type: "unable to get next poc error",
            error_message: "Something went wrong. Please try again later.",
          });
          dispatchPocEvent(errorMessageViewed, {
            screen_name: "Agent KPIs",
            screen_section: "Search by POC Modal",
            error_type: "unable to get next poc error",
            error_message: "Something went wrong. Please try again later.",
            is_all_products: null,
            total_gap: null,
            total_vol_gap: null,
          });

          dispatchGenericEvent(callInAutoDialerFailed, {
            error_message: t("ServerError.ERROR_MESSAGE_TRY_AGAIN"),
          });

          CustomToast({
            type: TOAST_TYPES.ERROR,
            message: t("ServerError.ERROR_MESSAGE_TRY_AGAIN"),
            errorCode: error?.config?.headers?.requestTraceId,
            InforErrorType: t("CustomToast.ERROR_REQUEST_TRACE_ID"),
          });

          dispatchAnalyticsPocEvent(
            t("ServerError.ERROR_MESSAGE_TRY_AGAIN"),
            "unable to create an searched poc",
            ScreenSection.HOME_HEADER,
          );

          if (typeof unWatchCreateSearchedPocFail === "function") {
            unWatchCreateSearchedPocFail();
            unWatchCreateSearchedPoc();
          }
        },
      );
    },
    [
      dispatchAnalyticsPocEvent,
      dispatchGenericEvent,
      dispatchPocEvent,
      globalState.user?.login,
      history,
      pocCallOrigin,
      searchedPoc,
      t,
      userConfig.vendorId,
    ],
  );

  const executePocSearch = useCallback(
    (pocId: string) => {
      setCallPocId(pocId);
      if (isOrganizationConfigEnabled) {
        searchVendorPocByAccountId(pocId);
      } else {
        const searchPocRequest = {
          accountId: pocId,
          vendorId,
          country: globalState.user.zone,
          isSearchPoc: true,
        };
        searchPocByAccountId(searchPocRequest);
      }
    },
    [globalState.user.zone, userConfig.vendorId, vendor?.id],
  );

  const handleSearchInteraction = useCallback(
    (searchMethod?: string) => {
      const watchSearchPoc = searchPocByAccountId.done.watch((response) => {
        const pocInfo = response.result;
        /* istanbul ignore next */
        if (pocInfo == null || pocInfo.accountId == null) {
          CustomToast({
            type: TOAST_TYPES.ERROR,
            message: t("PocTyped.ID_INVALID"),
          });

          dispatchGenericEvent(callInAutoDialerFailed, {
            error_message: t("PocTyped.ID_INVALID"),
          });

          dispatchPocEvent(
            errorMessageViewed,
            {
              screen_name: "Agent KPIs",
              screen_section: "Search by POC Modal",
              error_type: "Information not found",
              error_message: "The ID provided is not a valid POC.",
              is_all_products: null,
              total_gap: null,
              total_vol_gap: null,
            },
            { time_of_day: true, is_resumed: true },
          );
        } else {
          setSearchedPoc(pocInfo);
          /* istanbul ignore else */
          if (searchMethod) {
            setOpenModal(true);
          }
        }
        /* istanbul ignore next */
        if (typeof watchSearchPoc === "function") {
          watchSearchPoc();
        }
      });
    },
    [dispatchPocEvent, t, dispatchGenericEvent],
  );

  const handleSearchByPocSearched = useCallback(
    async (searchMethod: string) => {
      setPocCallOrigin(PocCallOrigin.SEARCH_BY_POC);
      searchByPocInteraction({
        button_label:
          searchMethod === SEARCH_METHOD.ENTER_KEY
            ? "search_field"
            : SEARCH_METHOD.SEARCH_BUTTON,
        search_query: pocSearched,
      });

      executePocSearch(pocSearched);
      if (!isOrganizationConfigEnabled) {
        handleSearchInteraction(searchMethod);
      }
    },
    [pocSearched, executePocSearch, handleSearchInteraction],
  );

  /* istanbul ignore next */
  useEffect(() => {
    if (loading === "error") {
      setPocCallOrigin("");
      setSearchedPoc(undefined);
      resetCreateSearchedPoc();
    } else if (
      pocCallOrigin === PocCallOrigin.AUTO_DIALER &&
      loading === "idle" &&
      searchedPoc
    ) {
      handleGoToPoc();
      if (isOrganizationConfigEnabled) resetVendorsPoc(null);
    }
  }, [handleGoToPoc, pocCallOrigin, searchedPoc, loading]);

  /* istanbul ignore next */
  const stopAutoDialerClick = () => {
    showModalStopAutoDialerEvent(true);
    dispatchGenericEvent(stopCalling, {
      button_label: "Stop calling",
    });
  };

  const renderBoxDialer = useCallback(() => {
    /* istanbul ignore next */
    if (
      isCallInAutoDialerEnabled &&
      autoDialerEnabled &&
      isCallIdUndefined &&
      !agentCallStates.clientId
    ) {
      return (
        <StopAutoDialer
          executePocSearch={executePocSearch}
          handleSearchInteraction={handleSearchInteraction}
          onStopAutoDialerClick={stopAutoDialerClick}
          setPocCallOrigin={setPocCallOrigin}
          setVendorByAutodialer={setVendorByAutodialer}
        />
      );
    }

    if (
      isCallInAutoDialerEnabled &&
      isCallIdUndefined &&
      !agentCallStates.clientId
    ) {
      return (
        <CallOptions
          title={title()}
          data-testid="call-options-container"
          firstLabel={getButtonLabel()}
          secondLabel={autoDialerButtonLabel()}
          click={getActions}
          autoDialClick={enableAutoDialer}
        />
      );
    }

    return (
      <GetNextPocButton
        loading={agentCallStates.isLoading}
        label={getButtonLabel()}
        click={getActions}
        newStyle={
          isCallInAutoDialerEnabled &&
          isCallIdUndefined &&
          !agentCallStates.clientId
        }
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoDialerEnabled,
    agentCallStates.isLoading,
    getButtonLabel,
    autoDialerButtonLabel,
    isCallInAutoDialerEnabled,
    title,
  ]);

  const handleDlgClose = (_?: unknown, reason?: string) => {
    /* istanbul ignore next */
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpenVendorSelectDialog(false);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent={
        isAgentIdentificationEnabled ? "space-between" : "flex-end"
      }
      classes={{ root: classes.headerContainer }}
      data-testid="header-container"
    >
      <Grid
        container
        direction={isCallInAutoDialerEnabled ? "column" : "row"}
        style={{
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "space-between",
        }}
      >
        {isAgentIdentificationEnabled && (
          <Grid item>
            <AgentIdentification />
          </Grid>
        )}

        {isSearchByPocEnabled && isCallIdUndefined && (
          <Grid
            container
            style={{
              alignContent: isCallInAutoDialerEnabled ? "end" : "center",
              justifyContent: "flex-end",
            }}
          >
            <div
              className={
                isCallInAutoDialerEnabled
                  ? classes.searchCallOptions
                  : classes.search
              }
            >
              <SearchBar
                pocSearched={pocSearched}
                setPocSearched={setPocSearched}
                onSearchByPocSearched={handleSearchByPocSearched}
                disabled={autoDialerEnabled}
                testId="header-search-next-poc"
                placeHolder={t("Search_Component.PLACEHOLDER")}
                cleanButton={false}
              />
            </div>
            <PocInfoModal
              open={openModal}
              pocId={searchedPoc?.vendorAccountId ?? searchedPoc?.accountId}
              name={searchedPoc?.name}
              owner={searchedPoc?.owner}
              email={searchedPoc?.email}
              onClose={() => setOpenModal(false)}
              goToPoc={handleGoToPoc}
            />
          </Grid>
        )}
      </Grid>
      {isOrganizationConfigEnabled && (
        <VendorSelectModal
          open={openVendorSelectDialog}
          onClose={handleDlgClose}
          handleGoToPoc={handleGoToPoc}
          data-testid="vendor-select-modal"
        />
      )}
      <Grid
        item
        style={{
          minWidth:
            isCallInAutoDialerEnabled &&
            isCallIdUndefined &&
            !agentCallStates.clientId
              ? "41.66%"
              : undefined,
          alignSelf: "center",
        }}
      >
        {renderBoxDialer()}
      </Grid>
      <ModalStopAutoDialer
        data-testid="modal-stop-auto-dialer"
        open={showModalStopAutoDialer}
        close={
          /* istanbul ignore next */
          () => {
            showModalStopAutoDialerEvent(false);
            dispatchGenericEvent(stopCalling, {
              button_label: "Go back",
            });
          }
        }
        onConfirm={
          /* istanbul ignore next */
          () => {
            showModalStopAutoDialerEvent(false);
            enableAutoDialer();
            dispatchGenericEvent(stopCalling, {
              button_label: "Yes, stop",
            });
          }
        }
      />
    </Grid>
  );
}

export default Header;
