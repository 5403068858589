import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface Props {
  borderRadius?: string;
}

export default makeStyles<Theme, Props>(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      backgroundColor: "white",
      borderRadius: ({ borderRadius }) => borderRadius || "11px",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      "&:focus": {
        outline: "none",
      },
    },
  }),
);
