import { MICROSERVICES_NAME } from "@config/services";
import { getEuropeClusterOrUrlParam } from "@config/utils/functions";

export function getApiHost(api: string): string | undefined {
  return `${getEndPoint()}/${MICROSERVICES_NAME.get(api)}`;
}

export function getEndPoint(): string {
  return getEuropeClusterOrUrlParam("/api/v1");
}

export function getVendorIdParam(
  vendorId: string | undefined,
  featureToggle = true,
): string {
  const queryParam = "?vendorId=";
  const vendorIdOrEmpty = vendorId ? `${queryParam}${vendorId}` : queryParam;
  return featureToggle ? vendorIdOrEmpty : "";
}

export function getV2EndPoint(featureToggle?: boolean): string {
  return featureToggle ? "/v2" : "";
}
