import Moment from "moment";
import axios, { CancelTokenSource } from "axios";
import { AgentCallRequestBodyV3 } from "@services/agentCall/domains/types";
import UpdateCanceledError from "@services/cart/domains/UpdateCanceledError";
import GlobalStore from "@stores/global/GlobalStore";
import * as AgentCallEvents from "@stores/agentCall/AgentCallEvents";
import * as OrdersEvents from "@stores/order/OrdersEvents";
import * as AgentCallService from "@services/agentCall/AgentCallService";
import {
  GROW_ORGANIZATION_ACTIVATION,
  isFeatureEnabledV2,
} from "@config/featureToggles";
import { usageTypes } from "@config/usageTypes";
import {
  getUserInfos,
  isNoHitClassification,
  isStrike,
} from "@config/utils/functions";
import AgentCall from "@domains/AgentCall";
import PocInformation from "@domains/PocInformation";
import { gapReasonValues } from "../../routes/call/components/header/gapReason/gapReasons";

let cancelTokenSource: CancelTokenSource;

export async function update(
  agentCall: AgentCall,
  pocInformation: PocInformation,
  vendorId?: string,
  vendorName?: string,
): Promise<void> {
  const { user } = GlobalStore.getState();

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    user.keyToggle,
  );

  const hasTopLineGap =
    agentCall.missions &&
    agentCall.missions.some((value) => value.tag === "#TopLineGAP") &&
    isStrike(agentCall.callTypification);
  AgentCallEvents.onTryUpdateAgentCall();

  const customSponsoredMissionIds = (
    agentCall: AgentCall,
  ): string[] | undefined => {
    const sponsoredMissionsIds = agentCall.sponsoredMissionsIds ?? [];

    const filteredIds = sponsoredMissionsIds.filter(
      (mission) => mission,
    ) as string[];

    const missions = isNoHitClassification(agentCall.callTypification)
      ? []
      : filteredIds;

    return missions.length > 0 ? missions : undefined;
  };

  const customMissionsIds = customSponsoredMissionIds(agentCall);

  if (cancelTokenSource) {
    cancelTokenSource.cancel();
  }

  cancelTokenSource = axios.CancelToken.source();
  if (isOrganizationConfigEnabled) {
    const { orgId } = getUserInfos();

    const headersV3 = {
      orgId,
      vendorId,
    };

    const bodyV3: AgentCallRequestBodyV3 = {
      ...buildRequestBodyV3(
        agentCall,
        pocInformation,
        hasTopLineGap,
        customMissionsIds,
      ),
      orgId,
    };

    return AgentCallService.updateV3(
      bodyV3,
      agentCall.callId,
      cancelTokenSource.token,
      headersV3,
    )
      .then((responseDate) => {
        handleSuccess(agentCall, responseDate);
      })
      .catch((error) => {
        handleError(
          error,
          agentCall.usage,
          agentCall.startServiceDate,
          agentCall.startCall,
          agentCall.isTypedPoc,
        );
      });
  }

  let headers = {};
  if (vendorId && vendorName) headers = { vendorId, vendorName };

  const body = {
    ...buildRequestBody(
      agentCall,
      pocInformation,
      hasTopLineGap,
      customMissionsIds,
    ),
  };

  return AgentCallService.update(
    body,
    agentCall.callId,
    cancelTokenSource.token,
    headers,
  )
    .then((responseDate) => {
      handleSuccess(agentCall, responseDate);
    })
    .catch((error) => {
      handleError(
        error,
        agentCall.usage,
        agentCall.startServiceDate,
        agentCall.startCall,
        agentCall.isTypedPoc,
      );
    });
}

const buildRequestBody = (
  agentCall: AgentCall,
  pocInformation: PocInformation,
  hasTopLineGap: boolean | undefined,
  customSponsoredMissionIds: string[] | undefined,
) => {
  switch (agentCall.usage) {
    case usageTypes.AGENT_CALL_SERVICE.GAP_REASON:
      return {
        ...(agentCall.gapReason ? { gapReason: agentCall.gapReason } : {}),
        gapDescription: agentCall.gapDescription,
      };
    case usageTypes.AGENT_CALL_SERVICE.END_CALL:
      return {
        callTypification: agentCall.callTypification || "ERROR",
        typificationDescription: agentCall.typificationDescription,
        ...(agentCall.gapReason ? { gapReason: agentCall.gapReason } : {}),
        gapDescription: agentCall.gapDescription,
        callLaterTime: agentCall.callLater,
        endCall: agentCall.endCall,
        callLaterHour: agentCall.callLaterHour,
        agentId: agentCall.agentId,
        accountId: pocInformation?.vendorAccountId ?? agentCall.clientId,
        pocGap: hasTopLineGap ? agentCall.gap.topline.total : 0.0,
        duration: agentCall.duration,
        campaignsTagB2O: agentCall.campaignsTagB2O,
        customMissions: customSponsoredMissionIds,
        missions: agentCall.missions ?? [],
        callLogs: agentCall.callLogs,
        pocCallOrigin: agentCall.pocCallOrigin,
      };
    case usageTypes.AGENT_CALL_SERVICE.START_CALL:
      return {
        callStartCall: true,
        startCall: agentCall.startCall,
        phone: agentCall.phone,
      };
    // default is checkout
    default:
      return {
        ...(agentCall.orderInfo?.orderId
          ? { orderInfo: agentCall.orderInfo }
          : {}),
        ...(agentCall.hasTechnicalProblem
          ? { gapReason: gapReasonValues.TECHNICAL_PROBLEM }
          : {}),
        phone: agentCall.phone,
      };
  }
};

const handleSuccess = (agentCall: AgentCall, responseDate) => {
  AgentCallEvents.onChangeAgentCall({
    ...agentCall,
    reponseDate: Moment(Date.parse(responseDate)),
  });

  if (agentCall.hasTechnicalProblem) {
    AgentCallEvents.setGapReason(gapReasonValues.TECHNICAL_PROBLEM);
  }

  if (agentCall.usage === usageTypes.CHECKOUT && agentCall.orderInfo) {
    OrdersEvents.addOrdersInfoToList(agentCall.orderInfo);
  }
};

const handleError = (
  error,
  usage?: string,
  startServiceDate?: string,
  startCall?: string,
  isTypedPoc?: boolean,
) => {
  if (error instanceof UpdateCanceledError) {
    return;
  }

  AgentCallEvents.updateAgentCallFail({
    error,
    message: "AgentCall.UPDATE_ERROR",
    usage,
    startServiceDate,
    startCall,
    isTypedPoc,
  });
};

const buildRequestBodyV3 = (
  agentCall: AgentCall,
  pocInformation: PocInformation,
  hasTopLineGap: boolean | undefined,
  customSponsoredMissionIds: string[] | undefined,
): AgentCallRequestBodyV3 => {
  switch (agentCall.usage) {
    case usageTypes.AGENT_CALL_SERVICE.GAP_REASON:
      return {
        ...(agentCall.gapReason ? { gapReason: agentCall.gapReason } : {}),
        gapDescription: agentCall.gapDescription,
      };
    case usageTypes.AGENT_CALL_SERVICE.END_CALL:
      return {
        callTypification: agentCall.callTypification || "ERROR",
        typificationDescription: agentCall.typificationDescription,
        ...(agentCall.gapReason ? { gapReason: agentCall.gapReason } : {}),
        gapDescription: agentCall.gapDescription,
        callLaterTime: agentCall.callLater,
        endCall: agentCall.endCall,
        callLaterHour: agentCall.callLaterHour,
        agentId: agentCall.agentId,
        accountId: pocInformation?.vendorAccountId ?? agentCall.clientId,
        pocGap: hasTopLineGap ? agentCall.gap.topline.total : 0.0,
        duration: agentCall.duration,
        campaignsTagB2O: agentCall.campaignsTagB2O,
        customMissions: customSponsoredMissionIds,
        missions: agentCall.missions ?? [],
        callLogs: agentCall.callLogs,
      };
    case usageTypes.AGENT_CALL_SERVICE.START_CALL:
      return {
        isCallStartCall: true,
        startCall: agentCall.startCall,
        phone: agentCall.phone,
      };
    // default is checkout
    default:
      return {
        ...(agentCall.orderInfo?.orderId
          ? { orderInfo: agentCall.orderInfo }
          : {}),
        ...(agentCall.hasTechnicalProblem
          ? { gapReason: gapReasonValues.TECHNICAL_PROBLEM }
          : {}),
        phone: agentCall.phone,
      };
  }
};
