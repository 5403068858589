import React from "react";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import { useProductCommonsHook } from "../../../../../hooks/useProductCommonsHook";
import { ProductListStyles } from "./ProductListStyles";
import TableCellItem from "./TableCellItem/TableCellItem";
import TableCellHeader from "./TableCellHeader/TableCellHeader";
import GenericTag from "../../../../../components/tag/GenericTag";
import { PROPS_TAG } from "../../../../../components/tag/constants";

interface Product {
  productCode: string;
  productName: string;
  price: number;
  quantity: string | number;
  totalPrice: number;
  originalPrice?: number;
  originalTotalPrice?: number;
  marketplace?: boolean;
  packageId?: string;
  packageName?: string;
}

interface Content {
  content: Array<Product>;
  dataTest: string;
}

const { MARKETPLACE } = PROPS_TAG;

function ProductList({ content, dataTest }: Content): React.ReactElement {
  const { t } = useTranslation();
  const classes = ProductListStyles();
  const { getDisplayPackageType } = useProductCommonsHook();

  const hasPackageType = content.find((el) => el.packageId);

  const quantityFormater = (qtdy: string | number, label?: string) => {
    if (qtdy && label) {
      return `${qtdy} ${t(label)}`;
    }

    return qtdy;
  };

  return (
    <Table
      size="small"
      className={classes.table}
      data-testid={`${dataTest}-internal-products`}
    >
      <TableHead
        classes={{ root: classes.backgroundColor }}
        data-testid={`${dataTest}-internal-products-header`}
      >
        <TableRow data-testid={`${dataTest}-internal-header-row`}>
          <TableCellHeader
            alignLeft
            dataTest={`${dataTest}-internal-header-product-code`}
            label={t("PocInformationLastOrders.PRODUCT_CODE")}
          />
          <TableCellHeader
            alignLeft
            dataTest={`${dataTest}-internal-header-product-name`}
            label={t("PocInformationLastOrders.PRODUCT_NAME")}
          />
          <TableCellHeader
            dataTest={`${dataTest}-internal-header-quantity`}
            label={t("PocInformationLastOrders.QUANTITY")}
          />
          {hasPackageType && (
            <TableCellHeader
              dataTest={`${dataTest}-internal-header-package-type`}
              label={t("SuggestedOrder.PACKAGE_LABEL")}
              alignLeft
            />
          )}
          <TableCellHeader
            dataTest={`${dataTest}-internal-header-price`}
            label={t("PocInformationLastOrders.PRICE")}
          />
          <TableCellHeader
            dataTest={`${dataTest}-internal-header-total-price`}
            label={t("PocInformationLastOrders.TOTAL_PRICE")}
          />
        </TableRow>
      </TableHead>
      <TableBody data-testid={`${dataTest}-internal-products-body`}>
        {content.map((row, index) => (
          <TableRow
            className={index === content.length - 1 ? classes.row : ""}
            key={row.productCode}
            data-testid={`${dataTest}-internal-body-row-${row.productCode}`}
          >
            <TableCellItem
              alignLeft
              classes={classes.cell}
              value={row.productCode}
              dataTest={`${dataTest}-internal-body-code`}
            />

            <TableCellItem
              alignLeft
              classes={classes.cell}
              value={row.productName}
              dataTest={`${dataTest}-internal-body-name`}
              tag={
                row.marketplace && (
                  <GenericTag
                    translationKey={MARKETPLACE.translationKey}
                    style={{
                      backgroundColor: MARKETPLACE.backgroundColor,
                      marginLeft: 8,
                    }}
                    testId={`tag-${MARKETPLACE.testId}`}
                  />
                )
              }
            />

            <TableCellItem
              classes={classes.cell}
              value={quantityFormater(
                row.quantity,
                row.packageId ? undefined : "PocInformationLastOrders.UNITS",
              )}
              dataTest={`${dataTest}-internal-body-quantity`}
            />
            {hasPackageType && (
              <TableCellItem
                classes={classes.cell}
                value={
                  getDisplayPackageType(row.packageId, row.packageName) ?? "-"
                }
                dataTest={`${dataTest}-internal-body-package-type`}
                alignLeft
              />
            )}
            <TableCellItem
              classes={classes.cellPrice}
              dataTest={`${dataTest}-internal-body-price`}
              value={row.price}
              originalPrice={row.originalPrice}
              numeric
            />
            <TableCellItem
              dataTest={`${dataTest}-internal-body-total-price`}
              classes={classes.cellTotalPrice}
              value={row.totalPrice}
              originalTotalPrice={row.originalTotalPrice}
              numeric
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default ProductList;
