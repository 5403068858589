import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";
import {
  EnrolledSinceCovertedReturn,
  EnrolledSinceResponse,
} from "@domains/redeem/Redeem";

import EnrolledSinceResponseToEnrolledSinceConverter from "../../converters/enrolledSince/EnrolledSinceResponseToEnrolledSinceConverter";
import { getApiHost, getEndPoint } from "../host/HostB2BService";

export const LOYALTY_BUSINESS_REWARDS = "/loyalty-business-service/rewards";

export function getEnrolledSince(
  pocId: string,
): Promise<EnrolledSinceCovertedReturn> {
  const log = useLogService();

  const endpoint = `${getApiHost()}${getEndPoint()}${LOYALTY_BUSINESS_REWARDS}`;
  return axios
    .get<EnrolledSinceResponse>(`${endpoint}/${pocId}`)
    .then((response) => {
      log.info(`Convert New Offerings received from ${endpoint} to our model`);
      return EnrolledSinceResponseToEnrolledSinceConverter(response?.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
