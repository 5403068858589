import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";

import {
  GROW_ORGANIZATION_ACTIVATION,
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_CALL_LIST_SERVICE,
} from "../../config/featureToggles";
import { CALL_LIST_SERVICE } from "../../config/services";
import NextPOCResponseToAgentCallConverter from "../../converters/nextPoc/getNextPoc/NextPOCResponseToAgentCallConverter";
import AgentCalls from "../../domains/AgentCall";
import GlobalStore from "../../stores/global/GlobalStore";
import { getUserInfos } from "../../config/utils/functions";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../host/HostService";
import { NextPOCResponse } from "../nextPoc/domains/NextPOCResponse";
import CreateSearchedPocRequest from "./domains/CreateSearchedPocRequest";

export const CALL_LIST = "/call-list";
export const CREATE_ENDPOINT = "/create-search-poc";
export const CREATE_ENDPOINT_V3 = "/v3/call-list/agent-call";

function getUri(
  isOrganizatioEnabled: boolean,
  isCallListV2Enabled: boolean,
  vendorId?: string,
) {
  if (isOrganizatioEnabled) {
    return `${getApiHost(CALL_LIST_SERVICE)}${CREATE_ENDPOINT_V3}`;
  }
  const vendorIdParam = getVendorIdParam(vendorId, isCallListV2Enabled);
  const v2EndPoint = getV2EndPoint(isCallListV2Enabled);
  const baseUrl = `${getApiHost(CALL_LIST_SERVICE)}${CALL_LIST}`;
  return `${baseUrl}${v2EndPoint}${CREATE_ENDPOINT}${vendorIdParam}`;
}

export const createSearchedPoc = (
  request: CreateSearchedPocRequest,
  headers = {},
): Promise<AgentCalls> => {
  const log = useLogService();
  const { user } = GlobalStore.getState();
  const { vendorId } = getUserInfos();

  const isCallListV2Enabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_CALL_LIST_SERVICE,
    user.zone,
  );
  const isOrganizatioEnabled = isFeatureEnabledV2(GROW_ORGANIZATION_ACTIVATION);

  const uri = getUri(isOrganizatioEnabled, isCallListV2Enabled, vendorId);
  const requestHeaders = isOrganizatioEnabled
    ? {
        ...headers,
        vendorId,
      }
    : headers;

  return axios
    .post<NextPOCResponse>(
      uri,
      {
        agentId: request.agentId,
        accountId: request.accountId,
        vendorId: request.vendorName ? request.vendorId : undefined,
        vendorName: request.vendorName,
        vendorAccountId: request.vendorAccountId,
        customerAccountId: request.customerAccountId,
        legacyAccountId: request.legacyAccountId,
        pocCallOrigin: request.pocCallOrigin,
      },
      { headers: requestHeaders },
    )
    .then((response) => {
      log.info(`Convert NextPOCResponse received from ${uri} to our model`);
      return NextPOCResponseToAgentCallConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
};
