import { createStore } from "effector";
import NewOfferingsDb from "../../routes/call/newOfferingsDb/NewOfferingsDb";
import NewOfferingsDomain from "../../domains/newOfferings/NewOfferings";
import * as NewOfferingsEvents from "./NewOfferingsEvents";
import NewOffetingsState from "./NewOffetingsState";

const initialState = <NewOffetingsState>{
  done: false,
  error: false,
  hasBeenAddedToDb: false,
  offerings: [],
};

const NewOfferingsStore = createStore(initialState)
  .on(
    NewOfferingsEvents.getNewOfferingsEffect.done,
    (state: NewOffetingsState) => ({
      ...state,
      done: true,
      error: false,
    }),
  )
  .on(
    NewOfferingsEvents.getNewOfferingsEffect.fail,
    (state: NewOffetingsState) => ({
      ...state,
      done: true,
      error: true,
    }),
  )
  .on(NewOfferingsEvents.clearNewOfferings, (state: NewOffetingsState) => {
    new NewOfferingsDb().newOfferings.toCollection().delete();

    return {
      ...state,
      hasBeenAddedToDb: false,
    };
  })
  .on(
    NewOfferingsEvents.setHasBeenAddedToDb,
    (state: NewOffetingsState, hasBeenAddedToDb: boolean) => ({
      ...state,
      hasBeenAddedToDb,
    }),
  )
  .on(
    NewOfferingsEvents.setOfferings,
    (state, offerings: NewOfferingsDomain[]) => ({
      ...state,
      offerings,
    }),
  );

export default NewOfferingsStore;
