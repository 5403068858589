import { useTranslation } from "react-i18next";
import { PACKAGE_TYPE } from "../config/constants";

export default function GetDisplayPackageType(
  packageTypeId?: string,
  packageName?: string,
): string | null {
  const { t } = useTranslation();

  switch (packageTypeId) {
    case PACKAGE_TYPE.DS:
      return t("COMMONS_PRODUCTS_INFOS.PACKAGE_TYPES.DISPLAY");

    case PACKAGE_TYPE.CS:
      return t("COMMONS_PRODUCTS_INFOS.PACKAGE_TYPES.BOX");

    case PACKAGE_TYPE.EA:
      return t("COMMONS_PRODUCTS_INFOS.PACKAGE_TYPES.UNIT");

    default:
      return packageName ?? null;
  }
}
