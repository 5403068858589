import React from "react";

const UpArrow: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="5"
    viewBox="0 0 10 5"
  >
    <g fill="none">
      <g fill="#7C7C7C">
        <g>
          <g>
            <path
              d="M59 5L64 10 54 10z"
              transform="translate(-1013 -427) translate(951 342) translate(8 80)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UpArrow;
