import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    disabledLabel: {
      opacity: "30%",
    },
    inputQuantity: {
      width: "100%",
      contain: "content",
      display: "flex",
      height: "100%",
      borderRadius: "3px",
      flexDirection: "row-reverse",
      "&& input": {
        height: "100%",
        width: "100%",
        border: "none",
        paddingLeft: "8px",
        outline: "none",
      },
      "& .rc-input-number-input-wrap": {
        height: "100%",
      },
      "& .rc-input-number-handler-wrap": {
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
        padding: "2px",
        flexDirection: "column",
        justifyContent: "center",
        "& span": {
          cursor: "pointer",
          justifyContent: "center",
          height: "10px",
          width: "100%",
          display: "flex",
          alignItems: "center",
        },
      },
    },
  }),
);
