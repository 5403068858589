import classNames from "classnames";
import SuggestedOrdersTableView from "domains/suggestedOrders/SuggestedOrdersTableView";
import { useStore } from "effector-react";
import { isFeatureEnabled } from "grow-shared-services";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import TableListView, {
  TableListBody,
  TableListCell,
  TableListFooter,
  TableListHead,
  TableListRow,
  TableSortListCell,
} from "../../../../../components/tableListView/TableListView";
import { useProductCommonsHook } from "../../../../../hooks/useProductCommonsHook";
import { setSuggestedOrdersTotals } from "../../../../../stores/suggestedOrders/SuggestedOrdersEvents";
import tableViewStyles from "./TableViewStyles";

import GenericTag from "../../../../../components/tag/GenericTag";
import { PROPS_TAG } from "../../../../../components/tag/constants";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import { SUGGESTED_ORDER_FIELDS } from "../SuggestedOrderConstants";
import EnhancedTableToolbar from "../enhancedTableToolbar/EnhancedTableToolbar";

import {
  GROW_AGENT_UNLINK_TOPLINE_OF_SUGGESTED_ORDER,
  TLP_PRICE_PER_KG_SUGGESTED_ORDER,
  TLP_SUM_SUGGESTED_ORDER_PRODUCTS,
} from "../../../../../config/featureToggles";

const { MARKETPLACE, PROMOTION } = PROPS_TAG;

function TableView({
  suggestedOrder,
  sortHandler,
  orderBy,
  orderDirection,
  gapOnlyHandler,
  total,
}: SuggestedOrdersTableView): React.ReactElement {
  const { t } = useTranslation();
  const classes = tableViewStyles();

  const [totalBought, setTotalBought] = useState(0);
  const [totalSuggested, setTotalSuggested] = useState(0);
  const [totalStock, setTotalStock] = useState(0);
  const [totalGap, setTotalGap] = useState(0);
  const [hasVariants, setHasVariants] = useState(false);

  const { orderSku } = useStore(AgentCallStore);

  const { getDisplayPackageType } = useProductCommonsHook();

  const { suggestedOrders } = suggestedOrder;

  const isSumSuggestedOrderProductsEnabled = isFeatureEnabled(
    TLP_SUM_SUGGESTED_ORDER_PRODUCTS,
  );

  const isUnlinkToplineOfSuggestedOrder = isFeatureEnabled(
    GROW_AGENT_UNLINK_TOPLINE_OF_SUGGESTED_ORDER,
  );

  const isPricePerKGSuggestedOrderEnabled = isFeatureEnabled(
    TLP_PRICE_PER_KG_SUGGESTED_ORDER,
  );

  const getTotalColumn = () => {
    if (isUnlinkToplineOfSuggestedOrder) {
      return <></>;
    }

    return isSumSuggestedOrderProductsEnabled ? (
      <TableListCell
        align="right"
        footer
        type="currency"
        text={total}
        testId="suggested-order-last-footer"
        style={{ paddingRight: "12px" }}
      />
    ) : (
      <TableListCell
        align="right"
        footer
        isEmpty
        testId="suggested-order-last-footer"
        style={{ paddingRight: "12px" }}
      />
    );
  };

  useMemo(() => {
    setTotalBought(
      suggestedOrders
        .map((row) => row.boxesOrder)
        .reduce((totalOrders, boxesOrder) => totalOrders + boxesOrder, 0),
    );
    setTotalSuggested(
      suggestedOrders
        .map((row) => row.suggestedOrder)
        .reduce((totalOrders, order) => totalOrders + order, 0),
    );
    setTotalStock(
      suggestedOrders
        .map((row) => row.inventoryCount ?? 0)
        .reduce((totalOrders, stock) => totalOrders + stock, 0),
    );
    setTotalGap(
      suggestedOrders
        .map((row) => row.gapBoxes)
        .reduce((totalOrders, gapBoxes) => totalOrders + gapBoxes, 0),
    );
    setHasVariants(suggestedOrders?.some((row) => row.variant));
  }, [suggestedOrders]);

  const getTags = useCallback((row) => {
    const { isMarketplace, isPromotion } = row;
    const tags: JSX.Element[] = [];
    if (isMarketplace) {
      tags.push(
        <GenericTag
          translationKey={MARKETPLACE.translationKey}
          style={{
            backgroundColor: MARKETPLACE.backgroundColor,
          }}
          testId={`suggested-order-${row.sku}-${MARKETPLACE.testId}`}
        />,
      );
    }
    if (isPromotion) {
      tags.push(
        <GenericTag
          translationKey={PROMOTION.translationKey}
          style={{
            backgroundColor: PROMOTION.backgroundColor,
          }}
          testId={`suggested-order-${row.sku}-${PROMOTION.testId}`}
        />,
      );
    }
    return tags;
  }, []);

  const getSubText = (row) => {
    const hasSegment = row.segment ? ` - ${row.segment}` : "";
    return `${row.sku}${hasSegment}`;
  };

  useEffect(() => {
    setSuggestedOrdersTotals({
      bought: totalBought,
      suggested: totalSuggested,
      gap: totalGap,
      totalsLoaded: true,
    });
  }, [totalBought, totalSuggested, totalGap]);

  return (
    <>
      {!isUnlinkToplineOfSuggestedOrder && (
        <EnhancedTableToolbar
          orderSku={orderSku}
          gapOnlyHandler={gapOnlyHandler}
        />
      )}
      <TableListView
        className={classes.table}
        testId="suggested-order"
        stickyHeader
      >
        <TableListHead testId="suggested-order">
          <TableSortListCell
            id={SUGGESTED_ORDER_FIELDS.MC}
            className={classes.alignLeft}
            text={`${t("SuggestedOrder.NAME_LABEL")}`}
            testId="suggested-order-name"
            sortHandler={sortHandler}
            orderBy={orderBy}
            orderDirection={orderDirection}
          />
          {isPricePerKGSuggestedOrderEnabled && (
            <TableSortListCell
              id={SUGGESTED_ORDER_FIELDS.PRICE_PER_UOM}
              text={`${t("SuggestedOrder.PRICE_PER_UoM_LABEL")}`}
              testId="suggested-order-pricePerUoM"
              sortHandler={sortHandler}
              orderBy={orderBy}
              orderDirection={orderDirection}
            />
          )}
          <TableSortListCell
            id={SUGGESTED_ORDER_FIELDS.UNIT_PRICE}
            text={`${t("SuggestedOrder.UNIT_PRICE_LABEL")}`}
            testId="suggested-order-unit-price"
            sortHandler={sortHandler}
            orderBy={orderBy}
            orderDirection={orderDirection}
          />
          {!isUnlinkToplineOfSuggestedOrder && (
            <TableSortListCell
              id={SUGGESTED_ORDER_FIELDS.BOXES_ORDER}
              text={`${t("SuggestedOrder.BOUGHT_LABEL")}`}
              testId="suggested-order-bought"
              style={{ textAlign: "center" }}
              sortHandler={sortHandler}
              orderBy={orderBy}
              orderDirection={orderDirection}
            />
          )}
          {hasVariants && (
            <TableSortListCell
              id={SUGGESTED_ORDER_FIELDS.PACKAGE_TYPE}
              text={`${t("SuggestedOrder.PACKAGE_LABEL")}`}
              testId="suggested-order-package"
              style={{ textAlign: "left" }}
              hideSortIcon
            />
          )}
          <TableSortListCell
            id={SUGGESTED_ORDER_FIELDS.SUGGESTED_ORDER}
            text={`${t("SuggestedOrder.SUGGESTED_LABEL")}`}
            testId="suggested-order-suggested"
            style={{ textAlign: "center" }}
            sortHandler={sortHandler}
            orderBy={orderBy}
            orderDirection={orderDirection}
          />
          <TableSortListCell
            id={SUGGESTED_ORDER_FIELDS.INVENTORY_COUNT}
            text={`${t("SuggestedOrder.STOCK_LABEL")}`}
            testId="suggested-order-stock"
            style={{ textAlign: "center" }}
            sortHandler={sortHandler}
            orderBy={orderBy}
            orderDirection={orderDirection}
          />
          {!isUnlinkToplineOfSuggestedOrder && (
            <TableSortListCell
              id={SUGGESTED_ORDER_FIELDS.GAP_BOXES}
              text={`${t("SuggestedOrder.GAP_LABEL")}`}
              testId="suggested-order-gap"
              style={{ textAlign: "center" }}
              sortHandler={sortHandler}
              orderBy={orderBy}
              orderDirection={orderDirection}
            />
          )}
          {!isUnlinkToplineOfSuggestedOrder && (
            <TableSortListCell
              id={SUGGESTED_ORDER_FIELDS.TOTAL_PRICE}
              text={`${t("SuggestedOrder.TOTAL_GAP_LABEL")}`}
              testId="suggested-order-total-gap"
              sortHandler={sortHandler}
              orderBy={orderBy}
              orderDirection={orderDirection}
            />
          )}
        </TableListHead>

        <TableListBody testId="suggested-order">
          {suggestedOrders.map((row) => (
            <TableListRow
              key={row.itemPlatformId ?? row.sku}
              rowContent={
                <>
                  <TableListCell
                    className={classNames(
                      classes.alignLeft,
                      classes.productName,
                    )}
                    text={row.mc}
                    subText={getSubText(row)}
                    testId="suggested-order-title-value"
                    tags={getTags(row)}
                    stock={{
                      value: row.stock?.valueStockOut,
                      option: row.stock?.stockAction,
                    }}
                  />
                  {isPricePerKGSuggestedOrderEnabled && (
                    <TableListCell
                      type="currency"
                      text={row.pricePerUoM}
                      testId="suggested-order-pricePerUoM-value"
                      style={{ paddingRight: "12px" }}
                    />
                  )}
                  <TableListCell
                    type="currency"
                    text={row.unitPrice}
                    testId="suggested-order-unit-price-value"
                    style={{ paddingRight: "12px" }}
                  />
                  {!isUnlinkToplineOfSuggestedOrder && (
                    <TableListCell
                      align="center"
                      text={row.boxesOrder}
                      testId="suggested-order-bought-value"
                    />
                  )}
                  {hasVariants && (
                    <TableListCell
                      align="center"
                      text={
                        getDisplayPackageType(
                          row.package?.packageId,
                          row.package?.name,
                        ) || "-"
                      }
                      testId="suggested-order-package-value"
                    />
                  )}
                  <TableListCell
                    align="center"
                    text={row.suggestedOrder}
                    testId="suggested-order-suggested-value"
                  />
                  <TableListCell
                    align="center"
                    color={row.inventoryCount ? "default" : "smallRed"}
                    text={
                      row.inventoryCount || `${t("SuggestedOrder.NO_STOCK")}`
                    }
                    testId="suggested-order-stock-value"
                  />
                  {!isUnlinkToplineOfSuggestedOrder && (
                    <TableListCell
                      align="center"
                      color={row.gapBoxes > 0 ? "red" : "green"}
                      text={row.gapBoxes}
                      testId="suggested-order-gap-value"
                    />
                  )}
                  {!isUnlinkToplineOfSuggestedOrder && (
                    <TableListCell
                      text={row.totalPrice}
                      type="currency"
                      testId="suggested-order-total-gap-value"
                      style={{ paddingRight: "12px" }}
                    />
                  )}
                </>
              }
              testId="suggested-order"
            />
          ))}
        </TableListBody>

        <TableListFooter
          testId="suggested-order"
          className={classes.tableFooter}
        >
          <TableListCell
            footer
            isEmpty
            testId="suggested-order-title-empty-footer"
          />

          {isPricePerKGSuggestedOrderEnabled && (
            <TableListCell
              footer
              isEmpty
              testId="suggested-order-pricePerUoM-empty-footer"
            />
          )}
          <TableListCell
            footer
            text={`${t("SuggestedOrder.TOTAL")}:`}
            testId="suggested-order-total-footer"
            style={{ paddingRight: "12px" }}
          />
          {!isUnlinkToplineOfSuggestedOrder && (
            <TableListCell
              align="center"
              footer
              text={totalBought}
              testId="suggested-order-bought-footer"
            />
          )}
          {hasVariants && (
            <TableListCell
              align="center"
              footer
              isEmpty
              testId="suggested-order-package-footer"
            />
          )}
          <TableListCell
            align="center"
            footer
            text={totalSuggested}
            testId="suggested-order-suggested-footer"
          />
          <TableListCell
            align="center"
            footer
            text={totalStock}
            testId="suggested-order-stock-footer"
          />
          {!isUnlinkToplineOfSuggestedOrder && (
            <TableListCell
              align="center"
              footer
              text={totalGap}
              testId="suggested-order-gap-footer"
            />
          )}
          {getTotalColumn()}
        </TableListFooter>
      </TableListView>
    </>
  );
}

export default React.memo(TableView);
