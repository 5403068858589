/* eslint-disable react/jsx-no-bind */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { ButtonBase, Box, Typography } from "@material-ui/core";
import { getVendorName } from "grow-shared-services";
import { FormValues } from "./GapReasonForm";
import GapModal from "./GapModal";
import GapReasonStyles from "./GapReasonStyles";
import * as AgentCallUseCase from "../../../../../usecase/agentCall/AgentCallUseCase";
import * as AgentCallEvents from "../../../../../stores/agentCall/AgentCallEvents";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import { usageTypes } from "../../../../../config/usageTypes";
import { ANALYTICS_ROUTE_NAMES } from "../../../../../config/constants";
import {
  callUxButtonClicked,
  callGapReasonRegistered,
} from "../../../../../config/typewriter";
import { getValueOrNull } from "../../../../../config/utils/functions";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import CallTabStore from "../../../../../stores/navigation/callTab/CallTabStore";
import {
  isFeatureEnabled,
  TLP_MULTI_VENDOR,
  TLP_MULTI_VENDOR_ADMIN,
} from "../../../../../config/featureToggles";
import GlobalAdminConfigStore from "../../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import PocInformationStore from "../../../../../stores/callList/pocInformation/PocInformationStore";

const GapReason: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const classes = GapReasonStyles();
  const [open, setOpen] = useState(false);
  const agentCallStates = useStore(AgentCallStore);
  const { dispatchPocEvent } = useAnalytics();
  const { callTab } = useStore(CallTabStore);
  const { pocInformation } = useStore(PocInformationStore);
  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);

  const isMultiVendor = isFeatureEnabled(
    TLP_MULTI_VENDOR,
    TLP_MULTI_VENDOR_ADMIN,
  );

  const handleOpen = () => {
    callUxButtonClicked({
      screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
      button_name: t("GapReason.INSERT"),
      button_label: "insert-gap-reason-button",
      screen_section: "Gap and Status Header",
      filter_option: "",
      call_id: agentCallStates.callId,
      order_date: null,
      position: null,
    });
    setOpen((prevOpen) => !prevOpen);
  };

  /* istanbul ignore next */
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const unWatchUpdateAgentCallSuccess =
      AgentCallEvents.onChangeAgentCall.watch((result) => {
        if (result.usage === usageTypes.AGENT_CALL_SERVICE.GAP_REASON) {
          dispatchPocEvent(
            callGapReasonRegistered,
            {
              screen_name: getValueOrNull(ANALYTICS_ROUTE_NAMES.get(callTab)),
              reason: result.gapReason,
              reason_description: result.gapDescription,
            },
            {
              is_resumed: true,
            },
          );
        }
      });
    /* istanbul ignore next */
    return () => {
      if (typeof unWatchUpdateAgentCallSuccess === "function") {
        unWatchUpdateAgentCallSuccess();
      }
    };
  }, [dispatchPocEvent, callTab]);

  function onSubmit(values: FormValues) {
    /* istanbul ignore next */
    if (isMultiVendor) {
      const { vendorId } = GlobalAdminConfigState.userConfig;
      const vendorName = getVendorName(vendorId);
      AgentCallUseCase.update(
        {
          ...agentCallStates,
          gapReason: values.gapReason,
          gapDescription: values.gapDescription,
          usage: usageTypes.AGENT_CALL_SERVICE.GAP_REASON,
        },
        pocInformation,
        vendorId,
        vendorName,
      );
    } else {
      AgentCallUseCase.update(
        {
          ...agentCallStates,
          gapReason: values.gapReason,
          gapDescription: values.gapDescription,
          usage: usageTypes.AGENT_CALL_SERVICE.GAP_REASON,
        },
        pocInformation,
      );
    }
    setOpen((prevOpen) => !prevOpen);
  }

  return (
    <Box className={classes.container}>
      <Typography
        variant="subtitle1"
        classes={{ root: classes.gapReasonTitle }}
        data-testid="gap-reason-label"
      >
        {t("GapReason.GAP_REASON_LABEL")}
      </Typography>
      <ButtonBase
        onClick={handleOpen}
        classes={{ root: classes.button }}
        data-testid="insert-gap-reason-button"
      >
        {t("GapReason.INSERT")}
      </ButtonBase>
      <GapModal open={open} onClose={handleClose} onSubmit={onSubmit} />
    </Box>
  );
};

export default GapReason;
