import React from "react";

function RewardsChallengesStatisticsStartedIcon(): JSX.Element {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ marginRight: "8px" }}
    >
      <defs>
        <path
          d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12,2.18181818 C6.57756791,2.18181818 2.18181818,6.57756791 2.18181818,12 C2.18181818,17.4224321 6.57756791,21.8181818 12,21.8181818 C17.4224321,21.8181818 21.8181818,17.4224321 21.8181818,12 C21.8181818,6.57756791 17.4224321,2.18181818 12,2.18181818 Z M12,4.36363636 C12.5594573,4.36363636 13.0205533,4.78477112 13.0835697,5.32732241 L13.0909091,5.45454545 L13.0909091,11.3258182 L16.8515057,13.2060794 C17.3518995,13.4562763 17.5759791,14.0391591 17.3897065,14.5526136 L17.3393751,14.6696876 C17.0891782,15.1700814 16.5062954,15.394161 15.992841,15.2078883 L15.875767,15.1575569 L11.5121306,12.9757388 C11.1836128,12.8114798 10.9626508,12.4947664 10.917604,12.1362058 L10.9090909,12 L10.9090909,5.45454545 C10.9090909,4.852053 11.3975075,4.36363636 12,4.36363636 Z"
          id="RewardsChallengesStatisticsStartedIcon-1"
        ></path>
      </defs>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          id="2.0-Mission.-Challenges"
          transform="translate(-350.000000, -495.000000)"
        >
          <g id="Mission-content" transform="translate(333.000000, 100.000000)">
            <g id="Statistics" transform="translate(1.000000, 299.000000)">
              <g id="Card" transform="translate(0.000000, 72.000000)">
                <g id="List" transform="translate(16.000000, 24.000000)">
                  <g
                    id="ZZ_Color-Override/Primary/Black"
                    transform="translate(4.000000, 4.000000)"
                  >
                    <mask
                      id="RewardsChallengesStatisticsStartedIcon-mask-2"
                      fill="white"
                    >
                      <use xlinkHref="#RewardsChallengesStatisticsStartedIcon-1"></use>
                    </mask>
                    <g id="Mask" fill-rule="nonzero"></g>
                    <g
                      id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
                      mask="url(#RewardsChallengesStatisticsStartedIcon-mask-2)"
                      fill="#000000"
                      fill-opacity="0.9"
                    >
                      <g
                        transform="translate(-4.000000, -4.000000)"
                        id="Rectangle"
                      >
                        <rect x="0" y="0" width="32" height="32"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default RewardsChallengesStatisticsStartedIcon;
