import { createStore } from "effector";
import {
  getOpenCall,
  getOpenCallDone,
  getOpenCallFail,
} from "../nextPoc/getOpenCall/GetOpenCallEvents";
import {
  getNextPoc,
  getNextPocDone,
  getNextPocFail,
} from "../nextPoc/getNextPoc/GetNextPocEvents";
import {
  onChangeAgentCall,
  resetAgentCall,
  updateAgentCallFail,
  setIsResumed,
  onTryUpdateAgentCall,
  setGapReason,
} from "./AgentCallEvents";
import AgentCallState from "./AgentCallState";
import AgentCall from "../../domains/AgentCall";
import { createSearchedPocDone } from "../searchedPoc/CreateSearchedPocEvents";

const initialState = <AgentCallState>{
  error: false,
  isLoading: false,
  isLoaded: false,
  updateCallLoading: false,
  usage: "",
  agentId: "",
  callId: "",
  clientId: "",
  countryCode: "",
  date: "",
  callLater: "",
  callTypification: "",
  emulatorLink: "",
  typificationDescription: "",
  gapReason: "",
  gapDescription: "",
  startServiceDate: "",
  startCall: "",
  endCall: "",
  orderSku: 0,
  isResumed: false,
  pocCallOrigin: undefined,
  screenSection: "",
  tagsList: [],
  localMissionIds: [],
  gap: {
    topline: {
      total: 0,
      achieved: 0,
      target: 0,
    },
    volume: {
      total: 0,
      achieved: 0,
      target: 0,
    },
  },
  isTypedPoc: false,
  vendorAccountId: "",
  legacyAccountId: "",
};

const setAgentCallStateWhenDone = (state: AgentCallState, call: AgentCall) => {
  return {
    ...state,
    ...call,
    isLoaded: true,
    isLoading: false,
  };
};

const setAgentCallStateWhenFail = (state: AgentCallState) => {
  return {
    ...state,
    isLoaded: true,
    isLoading: false,
  };
};

const AgentCallStore = createStore(initialState)
  .on(getOpenCall, (state) => ({ ...state, isLoading: true }))
  .on(getNextPoc, (state) => ({ ...state, isLoading: true }))
  .on(getOpenCallDone, setAgentCallStateWhenDone)
  .on(getNextPocDone, setAgentCallStateWhenDone)
  .on(getOpenCallFail, setAgentCallStateWhenFail)
  .on(getNextPocFail, setAgentCallStateWhenFail)
  .on(onTryUpdateAgentCall, (state: AgentCallState) => ({
    ...state,
    updateCallLoading: true,
  }))
  .on(onChangeAgentCall, (state: AgentCallState, call: AgentCall) => ({
    ...state,
    ...call,
    isLoaded: true,
    isLoading: false,
    updateCallLoading: false,
  }))
  .on(updateAgentCallFail, (state: AgentCallState, message) => ({
    ...state,
    isLoaded: true,
    isLoading: false,
    updateCallLoading: false,
    usage: message.usage || "",
    startServiceDate: message.startServiceDate,
    isTypedPoc: message.isTypedPoc,
  }))
  .on(setIsResumed, (state: AgentCallState, value: boolean) => ({
    ...state,
    isResumed: value,
  }))
  .on(setGapReason, (state: AgentCallState, gapReason: string) => {
    return {
      ...state,
      gapReason,
    };
  })
  .on(createSearchedPocDone, (state: AgentCallState, call: AgentCall) => ({
    ...state,
    ...call,
    done: true,
    error: false,
  }))
  .reset(resetAgentCall);

export default AgentCallStore;
