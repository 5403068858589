import {
  CampaignB2OProducts,
  Products,
} from "../../domains/CampaignB2OProducts";

import { CampaignsB2OProductsResponse } from "../../services/campaigns/domains/CampaignsResponse";

export default function CampaignsProductsResponseToCampaignsProductsConverter(
  data: Array<CampaignsB2OProductsResponse>,
): CampaignB2OProducts {
  if (!data) {
    return { campaignsProducts: [] };
  }

  const campaignsProducts = data.map((item) => {
    return <Products>{
      id: item.id,
      campaignTag: item.campaignTag,
      sku: item.sku,
      unitPrice: item.unitPrice,
      pricePerUoM: item.pricePerUoM,
      productName: item.productName,
      image: item.image,
      campaignAndSku: `${item.campaignTag}_${item.sku}`,
      inventoryCount: item.inventoryCount,
      palletQuantity: item.palletQuantity,
      stock: item.stock,
      platformId: item.itemPlatformId,
    };
  });

  return {
    campaignsProducts,
  };
}
