import React, { useMemo } from "react";

import { Select } from "@hexa-ui/components";

interface CombinedQuantitySelectProps {
  selectedQuantity: number;
  testId: string;
  onChange: (value: number) => void;
  unitOfMeasurement?: string | null;
  maxOrderQuantity: number;
  multiplierQuantity?: number;
}

interface Quantities {
  value: number;
  dataTestId: string;
  unitOfMeasurement?: string | null;
}

const CombinedQuantitySelect = ({
  selectedQuantity,
  testId,
  onChange,
  unitOfMeasurement,
  maxOrderQuantity,
  multiplierQuantity = 1,
}: CombinedQuantitySelectProps): JSX.Element => {
  const handleOnChange = (newValue: string) => {
    onChange(Number(newValue));
  };

  const generateQuantitiesOptions = (
    maxOrder: number,
    multiplier: number,
    unitOfMeasurement?: string | null,
  ): Quantities[] => {
    const quantities: Quantities[] = [];

    for (let i = multiplier; i <= maxOrder; i += multiplier) {
      quantities.push({
        value: i,
        dataTestId: `option-${i}`,
        unitOfMeasurement:
          i === maxOrder && unitOfMeasurement ? unitOfMeasurement : undefined,
      });
    }

    if (maxOrder > 0 && multiplier > 0 && maxOrder % multiplier !== 0) {
      quantities.push({
        value: maxOrder,
        dataTestId: `option-${maxOrder}`,
        unitOfMeasurement,
      });
    }

    return quantities;
  };

  const quantitiesMapping = useMemo(
    () =>
      generateQuantitiesOptions(
        maxOrderQuantity,
        multiplierQuantity,
        unitOfMeasurement,
      ),
    [maxOrderQuantity, multiplierQuantity, unitOfMeasurement],
  );

  const renderOptionText = (option: Quantities) => {
    return option.unitOfMeasurement && unitOfMeasurement
      ? `${option.value} (1 ${unitOfMeasurement})`
      : String(option.value);
  };

  return (
    <Select.Root
      value={String(selectedQuantity)}
      onChange={(val: string) => handleOnChange(val)}
      data-testid={testId}
      shape="pill"
      width={122}
      size="small"
    >
      {quantitiesMapping.map((option: Quantities) => (
        <Select.Option
          key={option.dataTestId}
          data-testid={option.dataTestId}
          value={String(option.value)}
        >
          {renderOptionText(option)}
        </Select.Option>
      ))}
    </Select.Root>
  );
};

export default CombinedQuantitySelect;
