import React, { useState } from "react";
import TabTitle from "./TabTitle";

type Props = {
  children?: JSX.Element[] | null;
};

const Tabs: React.FunctionComponent<Props> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div>
      {children &&
        children.map((item, index) => {
          if (item.type !== React.Fragment) {
            return (
              <TabTitle
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                title={item?.props?.title}
                index={index}
                setSelectedTab={setSelectedTab}
                selected={selectedTab}
                onClick={item?.props?.onClick}
              />
            );
          }
          return null;
        })}
      {children && children[selectedTab]}
    </div>
  );
};

export default Tabs;
