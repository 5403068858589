import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";
import SoftphoneStatusStyles from "./SoftphoneStatusStyles";

interface Props {
  isConnected: boolean;
  style?: React.CSSProperties;
}

const SoftphoneStatus: React.FunctionComponent<Props> = ({
  isConnected,
  style,
}) => {
  const { container, title, online, offline } = SoftphoneStatusStyles();
  const { t } = useTranslation();

  return (
    <Box className={container} style={style}>
      <Typography classes={{ root: title }}>
        {`${t("CallTypification.STATUS_CONNECTION")}:`}
      </Typography>
      {isConnected ? (
        <Box className={online}>{t("CallTypification.STATUS_ONLINE")}</Box>
      ) : (
        <Box className={offline}>{t("CallTypification.STATUS_OFFLINE")}</Box>
      )}
    </Box>
  );
};

export default SoftphoneStatus;
