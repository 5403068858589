import { useLogService } from "admin-portal-shared-services";
import { CallLogs } from "stores/callLogs/CallLogsState";
import axios from "../../config/axios/axiosInstance";
import { getApiHost } from "../host/HostService";
import { AGENT_CALL_SERVICE } from "../../config/services";

const TEMPCALLLOG_ENDPOINT = "/agent-call/temp-call-logs";

export async function updateTempCallLogs(
  requestBody: Array<CallLogs>,
): Promise<void> {
  const log = useLogService();

  const urlAgentCall = `${getApiHost(
    AGENT_CALL_SERVICE,
  )}${TEMPCALLLOG_ENDPOINT}`;
  try {
    await axios.post(urlAgentCall, requestBody[0]);
  } catch (error) {
    log.error("TempCallLogsService - update error: ", error);
    throw error;
  }
}
