import React, { useState, useEffect, useCallback } from "react";
import { useStore } from "effector-react";
import { useTranslation } from "react-i18next";

import { Box, Grid } from "@material-ui/core";

import { useAnalytics } from "../../../../analytics/useAnalytics";
import { ANALYTICS_ROUTE_NAMES } from "../../../../config/constants";
import { errorMessageViewed } from "../../../../config/typewriter";
import CartItemStore from "../../../../stores/cart/CartItemStore";
import * as CartItemEvents from "../../../../stores/cart/CartItemEvents";
import CallTabStore from "../../../../stores/navigation/callTab/CallTabStore";
import CartComboStore from "../../../../stores/cartCombo/CartComboStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import Button from "../../../../components/button/Button";
import ClearCartModal from "./clearCartModal/ClearCartModal";
import OrderSummaryStyles from "./OrderSummaryStyles";

import {
  GROW_NEW_CART_EXHIBITION,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";
import CartItemV2 from "./cartItemV2/CartItemV2";
import CartItem from "./cartItem/CartItem";
import CartComboItem from "./cartComboItem/CartComboItem";
import { useUpdateCartHook } from "../orderCart/hooks/useUpdateCartHook";
import { useOrderSummaryHook } from "./hooks/useOrderSummaryHook";

const OrderSummary: React.FunctionComponent = () => {
  const classes = OrderSummaryStyles();
  const { t } = useTranslation();
  const { dispatchPocEvent } = useAnalytics();

  const { cartItems } = useStore(CartItemStore);
  const { cartComboItems } = useStore(CartComboStore);
  const { callTab } = useStore(CallTabStore);
  const GlobalStates = useStore(GlobalStore);

  const [showClearCartModal, setShowClearCartModal] = useState(false);

  const { formatedItems, shouldUpdatePrice } = useOrderSummaryHook();
  const { isCartLoading } = useUpdateCartHook();

  const loadingUpdateCart = isCartLoading();

  const isNewCartExhibitionEnabled = isFeatureEnabledV2(
    GROW_NEW_CART_EXHIBITION,
    GlobalStates.user.keyToggle,
  );

  const closeClearCartModal = () => {
    setShowClearCartModal(false);
  };

  const openClearCartModal = () => {
    setShowClearCartModal(true);
  };

  const calculateTotalItems = useCallback(() => {
    const total =
      cartItems
        .filter((item) => item.notFound === false)
        .map((item) => item.itemQuantity)
        .reduce((currentTotal, quantity) => currentTotal + quantity, 0) +
      (cartComboItems
        .map((item) => item.itemQuantity)
        .reduce(
          (currentTotal, quantity) => (currentTotal || 0) + (quantity || 0),
          0,
        ) || 0);

    CartItemEvents.setTotalItems(total);

    return total;
  }, [cartItems, cartComboItems]);

  useEffect(() => {
    calculateTotalItems();
  }, [calculateTotalItems]);

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      const itemsWithNotFound = cartItems.filter((item) => item.notFound);
      /* istanbul ignore next */
      if (itemsWithNotFound.length > 0) {
        const isAllItems = cartItems.length === itemsWithNotFound.length;
        dispatchPocEvent(
          errorMessageViewed,
          {
            error_message: "Not found",
            error_type: "Product not found",
            screen_name: ANALYTICS_ROUTE_NAMES.get(callTab),
            screen_section: "Order Cart",
            is_all_products: isAllItems,
            total_gap: null,
            total_vol_gap: null,
          },
          { time_of_day: true, is_resumed: true },
        );
      }
    }
  }, [cartItems, callTab, dispatchPocEvent]);

  const renderProductsAndCombos = () => {
    if (isNewCartExhibitionEnabled) {
      return formatedItems.map((item) => {
        return (
          <CartItemV2
            key={item.displayCartItem.platformId}
            cartItem={item.displayCartItem}
            onChangeQuantity={(newQuantity) =>
              item.onChangeQuantity(newQuantity)
            }
            onRemove={() => item.onRemove()}
          />
        );
      });
    }

    return (
      <>
        {cartItems
          .filter((each) => each.itemQuantity !== 0)
          .map((each, position) => (
            <CartItem
              shouldUpdatePrice={shouldUpdatePrice}
              item={each}
              key={each.sku}
              position={position}
            />
          ))}
        {cartComboItems
          .filter((each) => each.itemQuantity !== 0)
          .map((each, position) => (
            <CartComboItem
              shouldUpdatePrice={shouldUpdatePrice}
              item={each}
              key={each.id}
              position={position}
            />
          ))}
      </>
    );
  };

  const renderClearCartButton = () => {
    if (isNewCartExhibitionEnabled) {
      return (
        <Button
          variant="reset"
          className={classes.newClearCartButton}
          onClick={openClearCartModal}
          testId="open-clear-cart-modal-button"
          disabled={loadingUpdateCart}
        >
          {t("OrderTaking.CLEAR_CART")}
        </Button>
      );
    }

    return (
      <Grid item>
        <div className={classes.clearCartContainer}>
          <Button
            variant="reset"
            className={classes.clearCartButton}
            onClick={openClearCartModal}
            testId="open-clear-cart-modal-button"
            disabled={loadingUpdateCart}
          >
            {t("OrderTaking.CLEAR_CART")}
          </Button>
        </div>
      </Grid>
    );
  };

  return (
    <Box className={classes.container}>
      <div
        className={isNewCartExhibitionEnabled ? classes.newList : classes.list}
        data-testid="grid-cart"
      >
        {renderProductsAndCombos()}
      </div>

      <Grid
        container
        className={classes.footer}
        direction="column"
        justifyContent="space-between"
      >
        {(cartItems?.length > 0 || cartComboItems?.length > 0) &&
          renderClearCartButton()}
      </Grid>
      <ClearCartModal open={showClearCartModal} onClose={closeClearCartModal} />
    </Box>
  );
};

export const OrderSummaryToTest = OrderSummary;

export default React.memo(OrderSummary);
