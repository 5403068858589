import { useLogService } from "admin-portal-shared-services";
import axios, { CancelToken } from "axios";
import axiosRetry from "axios-retry";

import axiosInstance from "@config/axios/axiosInstance";

import CartResponseToCartInfoConverterV3 from "../../../converters/cart/v3/CartResponseToCartInfoConverterV3";
import StoresToCartRequestV3Converter from "../../../converters/cart/v3/StoresToCartRequestV3Converter";
import { CartInfo } from "../../../domains/cart/CartInfo";
import AgentCallState from "../../../stores/agentCall/AgentCallState";
import CartItemState from "../../../stores/cart/CartItemState";
import CartState from "../../../stores/cart/CartState";
import CartComboState from "../../../stores/cartCombo/CartComboState";
import GlobalStore from "../../../stores/global/GlobalStore";
import { getEndPoint, getApiHost } from "../../host/HostB2BService";
import UpdateCanceledError from "../domains/UpdateCanceledError";
import CartRequestV3 from "./domains/CartRequestV3";
import CartResponseV3 from "./domains/CartResponseV3";

const CART_SERVICE = "/cart-service";

/* istanbul ignore next */
axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function updateCart(request: CartRequestV3): Promise<CartInfo> {
  const log = useLogService();
  const endpoint = `${getApiHost()}${getEndPoint()}${CART_SERVICE}/v3`;

  return axiosInstance
    .post<CartResponseV3>(
      endpoint,
      {
        ...request,
        deliveryCenterId: undefined,
      },
      {
        headers: {
          "Accept-Language": request.language || "en-US",
        },
      },
    )
    .then((response) => {
      log.info(`Convert Cart Response received from ${endpoint} to our model`);
      return CartResponseToCartInfoConverterV3(
        response.data,
        request.accountId,
        request.paymentMethod,
        request.paymentTerm,
      );
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}

export function autoSaveCart(
  agentCallState: AgentCallState,
  cartState: CartState,
  cartComboState: CartComboState,
  cartItemsState: CartItemState,
  cancelRequestToken: CancelToken,
): Promise<CartInfo | null> {
  const { user } = GlobalStore.getState();

  const request = StoresToCartRequestV3Converter(
    agentCallState,
    cartState,
    cartComboState,
    cartItemsState,
    user.zone,
  );
  if (!request.items?.length && !request.combos?.length) {
    return Promise.resolve(null);
  }

  const log = useLogService();
  const endpoint = `${getApiHost()}${getEndPoint()}${CART_SERVICE}/v3`;

  return axiosInstance
    .post<CartResponseV3>(endpoint, request, {
      headers: {
        "Accept-Language": request.language || "en-US",
      },
      cancelToken: cancelRequestToken,
    })
    .then((response) => {
      log.info(`Convert Cart Response received from ${endpoint} to our model`);
      return CartResponseToCartInfoConverterV3(
        response.data,
        request.accountId,
        request.paymentMethod,
        request.paymentTerm,
      );
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        throw new UpdateCanceledError();
      }
      log.error(error);
      throw error;
    });
}
