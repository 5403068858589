import React, { ReactNode } from "react";
import SalesStructureContainerStyles from "./SalesStructureContainerStyles";

interface Props {
  children: ReactNode;
}

function salesStructureContainer({ children }: Props): React.ReactElement {
  const classes = SalesStructureContainerStyles();

  return <div className={classes.container}>{children}</div>;
}

export default salesStructureContainer;
