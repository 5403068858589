import CheckoutRequestV1Mobile from "../../../../services/checkout/domains/v1Mobile/CheckoutRequestV1Mobile";
import CheckoutRequestV2 from "../../../../services/checkout/domains/v2/CheckoutRequestV2";
import AnalyticsDomain from "../../../../domains/AnalyticsDomain";

interface BodyCheckoutType {
  request: CheckoutRequestV1Mobile | CheckoutRequestV2;
  analytics: AnalyticsDomain;
}

export function orderCompletedEventData(
  bodyCheckout: BodyCheckoutType,
  orderNumber: string,
): Record<string, unknown> {
  const { request, analytics } = bodyCheckout;

  return {
    had_technical_problem: analytics?.hadTechnicalProblem || false,
    details_free_text: analytics.details,
    order_id: orderNumber || "",
    volume_hectoliters: null,
    payment_method: request.paymentMethod,
    revenue: analytics?.revenue || null,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    total: (request as any)?.total || null,
    order_reason: analytics?.reasonOfTheOrder || null,
    points_lost: analytics?.pointsLost || 0,
    accumulative_discount: analytics?.accumulativeDiscount,
    shipping_cases: analytics?.shippingCases,
    shipping_pallets: analytics?.shippingPallets,
    shipping_trucks: analytics?.shippingTrucks,
    free_good: analytics?.freeGood,
  };
}
