/* istanbul ignore file */
import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    container: {
      width: "550px",
      padding: "32px",
    },
    closeButton: {
      float: "right",
    },
    title: {
      fontSize: "20px",
    },
    input: {
      marginTop: "16px",
      "& .tlp-MuiInputBase-root": {
        height: "48px",
        width: "485px",
        fontSize: "16px",
        "& fieldset": {
          borderRadius: "8px",
        },
      },
      "& .tlp-MuiInputBase-root fieldset": {
        border: "1px solid rgba(0,0,0,0.15)",
      },
    },
    saveButton: {
      float: "right",
      height: "40px",
      width: "145px",
      marginTop: "32px",
      marginBottom: "32px",
      borderRadius: "20px",
      backgroundColor: "#000000",
      color: "rgba(255,255,255,0.95)",
    },
    inputPlaceholder: {
      "&&::placeholder": {
        color: "#000000",
      },
    },
  }),
);
