import React from "react";

const GeneralIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="55px"
      height="60px"
      viewBox="0 0 55 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M28.5947857,0.633589286 C27.1315714,-0.211196429 25.3288571,-0.211196429 23.8657143,0.633589286 L2.3645,13.0473036 C0.901357143,13.8920893 2.53765263e-15,15.4532321 2.53765263e-15,17.1428036 L2.53765263e-15,41.9701607 C2.53765263e-15,43.6597321 0.901357143,45.2209464 2.3645,46.0656607 L23.8657143,58.479375 C25.3288571,59.3241607 27.1315714,59.3241607 28.5947857,58.479375 L50.0959286,46.0656607 C51.5590714,45.2209464 52.4604286,43.6597321 52.4604286,41.9701607 L52.4604286,17.1428036 C52.4604286,15.4532321 51.5590714,13.8920893 50.0959286,13.0473036 L28.5947857,0.633589286 Z"
          id="path-1"
        />
        <filter colorInterpolationFilters="auto" id="filter-3">
          <feColorMatrix
            in="SourceGraphic"
            type="matrix"
            values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        id="(current)-mission-tab"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="sp70---Mission---general-"
          transform="translate(-355.000000, -170.000000)"
        >
          <g id="mkt-place-icon" transform="translate(355.000000, 174.000000)">
            <circle id="Oval" fill="#FFFF00" cx="19.74" cy="19.74" r="12.22" />
            <g id="Group" transform="translate(0.855443, -3.819593)">
              <g id="Clipped">
                <mask id="mask-2" fill="white">
                  <use href="#path-1" />
                </mask>
                <g id="Path" />
                <g id="Group" mask="url(#mask-2)" fillRule="nonzero">
                  <g transform="translate(-1.194214, 23.435304)">
                    <path
                      d="M13.1744286,33.2144286 L13.1736429,33.2144286 C6.50521429,35.9567857 0,31.3875 0,24.33 C0,17.2213571 7.42592857,14.4652857 13.6962143,9.96314286 C11.539,12.8473571 10.87,15.4546429 10.7593571,15.7635714 C9.76621429,19.0597143 9.69907143,22.5991429 10.4847857,26.1500714 C10.664,26.8663571 11.1919286,29.5522143 13.1744286,33.2144286 Z"
                      id="Path"
                      fill="#9E9E9E"
                    />
                    <path
                      d="M40.8712143,9.96314286 C42.1939286,11.7313571 43.1802857,13.6811429 43.8072857,15.7597857 C45.1437143,20.2512143 44.5273571,24.1141429 44.0834286,26.1500714 C43.5342143,28.6315 42.6043571,30.9771429 41.3952857,33.2121429 C41.3952857,33.2129286 41.3945,33.2137143 41.3945,33.2137143 C48.0622143,35.956 54.5674286,31.3867857 54.5674286,24.3292143 C54.5666429,17.2213571 47.1415,14.4645 40.8712143,9.96314286 Z"
                      id="Path"
                      fill="#9E9E9E"
                    />
                    <path
                      d="M13.6955714,9.96307143 C13.7635,9.89521429 13.6582143,0.0350714286 27.2834286,5.07530526e-15 C40.9468571,0.0350714286 40.8072143,9.96157143 40.8697857,9.96157143 C36.2327143,13.6863571 18.2060714,13.7665 13.6955714,9.96307143 Z"
                      id="Path"
                      fill="#000000"
                    />
                    <path
                      d="M27.2832857,45.371 C29.4465714,45.371 37.6352857,40.1631429 41.3944286,33.2132143 C34.594,38.191 19.7345714,38.281 13.1759286,33.2192857 C16.6291429,39.8431429 25.0192857,45.371 27.2832857,45.371 Z"
                      id="Path"
                      fill="#DFDFDF"
                    />
                    <path
                      d="M43.8075,15.7600714 C43.4825,16.0446429 38.8942143,19.8281429 27.3560714,19.8579286 C15.4052143,19.8266429 10.7841429,15.786 10.7589286,15.7639286 C9.76578571,19.06 9.69864286,22.5995 10.4843571,26.1503571 C10.6155,26.2777857 15.5340714,30.9744286 27.3575714,31.0102143 C38.2367143,30.9774286 43.2536429,26.8849286 44.0835714,26.1511429 C44.5267857,24.1152143 45.1439286,20.2522857 43.8075,15.7600714 Z"
                      id="Path"
                      fill="#000000"
                    />
                    <path
                      d="M27.3557143,19.8575 C15.4048571,19.8262143 10.7837857,15.7855714 10.7585714,15.7635 C10.8692143,15.4545 11.5389286,12.8472143 13.6954286,9.96307143 C18.2058571,13.7664286 36.2325,13.6863571 40.8695714,9.9615 C42.1930714,11.7305 43.1801429,13.6802143 43.8071429,15.7596429 C43.4822143,16.0442143 38.8939286,19.8277143 27.3557143,19.8575 Z"
                      id="Path"
                      fill="#FFFFFF"
                    />
                    <path
                      d="M44.0834286,26.1503571 C43.2535,26.8841429 38.2365,30.9766429 27.3573571,31.0094286 C15.5338571,30.9735714 10.6152857,26.2777143 10.4841429,26.1495714 C10.6633571,26.8666429 11.192,29.554 13.1760714,33.2192857 C19.7346429,38.281 34.5940714,38.1902857 41.3945,33.2132143 C42.6042857,30.9774286 43.5349286,28.6317857 44.0834286,26.1503571 Z"
                      id="Path"
                      fill="#FFFFFF"
                    />
                    <path
                      d="M25.0790714,6.63664286 C24.8738571,8.35678571 23.9187857,9.28971429 22.8928571,9.16692857 C21.8668571,9.04407143 21.1582143,7.91285714 21.3641429,6.19271429 C21.5693571,4.47257143 22.6105714,2.815 23.6365714,2.93778571 C24.6625714,3.06057143 25.285,4.9165 25.0790714,6.63664286 Z M32.2425714,5.45885714 L29.0120714,5.45885714 C28.6832857,5.45885714 28.4155714,5.72664286 28.4155714,6.05542857 C28.4155714,6.38414286 28.6832857,6.65192857 29.0120714,6.65192857 L32.2425714,6.65192857 C32.5712857,6.65192857 32.8390714,6.38414286 32.8390714,6.05542857 C32.8390714,5.72585714 32.5712857,5.45885714 32.2425714,5.45885714 Z"
                      id="Shape"
                      fill="#FFFFFF"
                    />
                  </g>
                </g>
              </g>
              <g filter="url(#filter-3)">
                <g transform="translate(6.831714, 8.796946)">
                  <path
                    d="M4.78593518,0 C7.51626109,0 9.47726359,1.67935578 9.47726359,5.17683155 C9.47726359,9.79036964 7.04540084,10.9821631 4.77129229,10.9821631 C2.40040343,10.9821631 0,9.79036964 0,5.69596755 C0,1.58474902 1.99257622,0 4.78593518,0 Z M4.78593518,2.82481827 C3.76653876,2.82481827 3.6257154,3.3104358 3.6257154,5.44589454 C3.6257154,7.72011749 3.90736213,8.1278303 4.78593518,8.1278303 C5.69608195,8.1278303 5.89993835,7.7663341 5.89993835,5.44589454 C5.89993835,3.2641048 5.66450823,2.82481827 4.78593518,2.82481827 Z"
                    id="Shape"
                    fill="#B7B7B7"
                    fillRule="nonzero"
                  />
                  <path
                    d="M14.9034818,7.75157681 L14.808875,7.75157681 C14.2603388,7.75157681 14.2603388,7.9239739 14.2603388,8.25389638 L14.2603388,10.1834396 C14.2603388,10.7803659 13.3670085,10.8896155 12.5662258,10.8896155 C11.7338693,10.8896155 10.8867556,10.7951232 10.8867556,10.2296562 L10.8867556,1.3493189 C10.8867556,0.596926325 11.3428586,0.1576398 12.3139792,0.1576398 L14.8866653,0.1576398 C17.2721971,0.1576398 18.9348524,0.958422529 18.9348524,3.8295718 C18.93691,7.16940777 16.8813007,7.75157681 14.9034818,7.75157681 Z M14.2603388,4.75436146 C14.2603388,5.41432082 14.3695885,5.47529471 14.7310847,5.47529471 C15.4058013,5.47529471 15.7504811,5.08222479 15.7504811,3.89054569 C15.7504811,2.85433284 15.3427683,2.47807935 14.745842,2.47807935 C14.3380148,2.47807935 14.2603388,2.50965307 14.2603388,3.1990126 L14.2603388,4.75436146 Z"
                    id="Shape"
                    fill="#B7B7B7"
                    fillRule="nonzero"
                  />
                  <path
                    d="M19.6409124,9.71257932 C19.6409124,9.02310539 19.7817357,8.67636647 19.9709493,8.40936263 L22.1673819,5.41214727 C22.2450578,5.30289763 22.293448,5.20829087 22.293448,5.14525783 C22.293448,4.98761803 22.2156577,4.98761803 21.9487682,4.98761803 L20.3008718,4.98761803 C19.7985522,4.98761803 19.7669785,4.24986834 19.7669785,3.84204113 C19.7669785,3.44897121 19.8133095,2.4295748 20.3008718,2.4295748 L25.1960566,2.4295748 C26.2008101,2.4295748 26.3416335,2.96346808 26.3416335,3.68440133 C26.3416335,4.32754426 26.1839937,4.68915486 26.0115966,4.92447059 L23.9413444,7.78097698 C23.878197,7.87546934 23.8319804,7.9700761 23.8319804,8.03104999 C23.8319804,8.20344707 23.8950134,8.23490639 24.2082339,8.23490639 L25.9801373,8.23490639 C26.4656404,8.23490639 26.4824568,8.90962304 26.4824568,9.48973293 C26.4824568,10.0866593 26.4362402,10.822386 25.9801373,10.822386 L20.4564524,10.822386 C19.9393755,10.8264681 19.6409124,10.4797292 19.6409124,9.71257932 Z"
                    id="Path"
                    fill="#B7B7B7"
                    fillRule="nonzero"
                  />
                  <path
                    d="M27.093454,9.49179208 C27.093454,9.00628895 27.139785,8.50385499 27.2974248,8.22014911 L29.996177,3.43421393 C30.0592101,3.29339057 30.0907838,3.18414092 30.0907838,3.13575077 C30.0907838,2.94665165 30.0444528,2.85410404 29.7775634,2.85410404 L27.9426269,2.85410404 C27.3457006,2.85410404 27.2532674,2.07013775 27.2532674,1.53624447 C27.2532674,0.939318141 27.3478742,0.155351849 27.9426269,0.155351849 L33.1994224,0.155351849 C34.1724878,0.155351849 34.3133111,0.720704456 34.3133111,1.66242494 C34.3133111,2.35178448 34.1724878,2.93395352 33.9686314,3.29339057 L31.6145589,7.46764214 C31.5515259,7.59382262 31.5199522,7.70307226 31.5199522,7.79767902 C31.5199522,7.98689254 31.6145589,8.03310914 31.9130221,8.03310914 L33.7795322,8.03310914 C34.3764586,8.03310914 34.4688918,8.83389187 34.4688918,9.38242804 C34.4688918,9.97935437 34.374285,10.8264681 33.7795322,10.8264681 L28.0204173,10.8264681 C27.3436415,10.8264681 27.093454,10.3073321 27.093454,9.49179208 Z"
                    id="Path"
                    fill="#B7B7B7"
                    fillRule="nonzero"
                  />
                  <path
                    d="M38.7980376,6.52615044 C38.751821,7.02847001 38.1548947,7.21550998 37.3226526,7.21550998 C36.3494728,7.21550998 35.9101863,7.02641085 35.8639697,6.52615044 L35.3783522,1.00246558 C35.4099259,0.186925568 36.351532,0.0924332064 37.3247118,0.0924332064 C38.4701743,0.0924332064 39.2857143,0.186925568 39.2857143,1.00246558 L38.7980376,6.52615044 Z M37.3078953,7.90704306 C38.6257549,7.90704306 39.0020084,8.15723046 39.0020084,9.28793568 C39.0020084,10.6835856 38.6257549,10.887442 37.3078953,10.887442 C36.0214951,10.887442 35.5822086,10.6835856 35.5822086,9.46033276 C35.5822086,8.15928962 36.0214951,7.90704306 37.3078953,7.90704306 Z"
                    id="Shape"
                    fill="#B7B7B7"
                    fillRule="nonzero"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GeneralIcon;
