import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    loadingContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    container: {
      padding: "10px 0 10px 0",
      backgroundColor: "white",
      height: "50%",
      display: "flex",
      flexDirection: "column",
    },
  }),
);
