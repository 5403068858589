import { AccountsResponse } from "@services/pocInformation/domains/AccountsResponse";
import { TYPE_PHONE, TYPE_EMAIL } from "@config/constants";
import { formarterCTIWithZero } from "./functions";

const mergeItems = (
  elements: Array<string>,
  TYPE: string,
  account: AccountsResponse | null,
) => {
  const filteredContacts = account?.contacts?.filter((each) => {
    return each.type === TYPE;
  });
  if (filteredContacts) {
    for (const i in filteredContacts) {
      const value =
        filteredContacts[i].type === TYPE_PHONE
          ? formarterCTIWithZero(filteredContacts[i].value)
          : filteredContacts[i].value;
      if (value && !elements.includes(value)) {
        elements.push(value);
      }
    }
  }
  return elements;
};

export const getPhones = (account: AccountsResponse | null): string[] => {
  let ownerPhone: string | null = null;
  if (account?.owner?.phone) {
    ownerPhone = formarterCTIWithZero(account.owner.phone);
  }

  const phones = ownerPhone && ownerPhone !== "0" ? [ownerPhone] : [];
  return [...new Set(mergeItems(phones, TYPE_PHONE, account))];
};

export const getEmails = (account: AccountsResponse | null): string[] => {
  const emails = account?.owner?.email ? [account?.owner?.email] : [];
  return [...new Set(mergeItems(emails, TYPE_EMAIL, account))];
};
