/* eslint-disable no-console */
import React, { ReactNode, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  useAuthenticationService,
  useUserMetadata,
} from "admin-portal-shared-services";
import { useStore } from "effector-react";
import { useUserConfig, useUserConfigService } from "grow-shared-services";

import { returnValueArray } from "@config/utils/functions";
import * as GlobalAdminConfigEvents from "@stores/globaAdminConfig/GlobalAdminConfigEvents";

import {
  DEFAULT_LANGUAGE,
  getLanguageHubByZone,
} from "@i18n/domains/LanguageOptions";
import AnalyticsService from "../../config/typewriter/AnalyticsService";
import GlobalAdminConfigStore from "../../stores/globaAdminConfig/GlobalAdminConfigStore";
import {
  changeSelectedLanguage,
  setUser,
  setIsIdentified,
} from "../../stores/global/GlobalEvents";
import GlobalStore from "../../stores/global/GlobalStore";
import * as VendorEvents from "../../stores/vendor/VendorEvents";

interface AppbarConfigProps {
  children: ReactNode;
}

/* istanbul ignore next */
const AppbarConfig = ({ children }: AppbarConfigProps): JSX.Element => {
  const authentication = useAuthenticationService();

  const globalState = useStore(GlobalStore);
  const GlobalAdminConfigState = useStore(GlobalAdminConfigStore);
  const { data: userMetaData } = useUserMetadata();
  const { i18n } = useTranslation();

  const userConfigService = useUserConfigService();

  const {
    data: { userEmail, country: userCountry, toggle, vendorId },
  } = useUserConfig();

  const getScreenResolution = () =>
    `${window.screen.width * window.devicePixelRatio} x ${
      window.screen.height * window.devicePixelRatio
    }`;

  const segmentation = useMemo(
    () => authentication.getSegmentation(),
    [authentication],
  );

  useEffect(() => {
    setUser({ login: userEmail, zone: userCountry, keyToggle: toggle });

    GlobalAdminConfigEvents.getUserConfig({
      userId: userEmail,
      vendorId,
    });
  }, []);

  useEffect(() => {
    VendorEvents.setVendorConfig({
      id: vendorId,
    });

    GlobalAdminConfigEvents.setVendorId(vendorId);
  }, [vendorId]);

  useEffect(() => {
    const changeLanguage = () => {
      const preferredLanguage =
        userMetaData?.profile?.preferredLanguage ?? DEFAULT_LANGUAGE;
      userConfigService.setPreferredLanguage(preferredLanguage);

      const language = getLanguageHubByZone(preferredLanguage);
      i18n.changeLanguage(preferredLanguage);
      changeSelectedLanguage(language);
    };

    changeLanguage();
  }, [userMetaData?.profile?.preferredLanguage]);

  useEffect(() => {
    /* istanbul ignore next */
    if (
      !globalState.isIdentified &&
      globalState.user.login &&
      GlobalAdminConfigState.done
    ) {
      // eslint-disable-next-line no-console
      AnalyticsService.identify(globalState.user.login, {
        userId: globalState.user.login,
        id: globalState.user.login,
        country: globalState.user.zone,
        segmentation,
        screen_resolution: getScreenResolution(),
        vendor_id: vendorId,
        mission_priority_1: returnValueArray(
          GlobalAdminConfigState.userConfig?.missionPriority,
          0,
        ),
        mission_priority_2: returnValueArray(
          GlobalAdminConfigState.userConfig?.missionPriority,
          1,
        ),
        mission_priority_3: returnValueArray(
          GlobalAdminConfigState.userConfig?.missionPriority,
          3,
        ),
        mission_priority_4: returnValueArray(
          GlobalAdminConfigState.userConfig?.missionPriority,
          4,
        ),
        mission_priority_5: returnValueArray(
          GlobalAdminConfigState.userConfig?.missionPriority,
          5,
        ),
      });

      setIsIdentified(true);
    }
  }, [
    GlobalAdminConfigState.done,
    GlobalAdminConfigState.userConfig,
    GlobalAdminConfigState.userConfig.missionPriority,
    GlobalAdminConfigState.userConfig.vendorId,
    globalState.isIdentified,
    globalState.user.login,
    globalState.user.zone,
    segmentation,
  ]);

  return <>{children}</>;
};

export default AppbarConfig;
