import { createStore } from "effector";
import {
  setEffectiveMissionsEvent,
  changeEffectivenessByMissionEvent,
  resetEffectiveMissionsEvent,
} from "./EffectivenessMissionEvents";
import { Mission } from "../../domains/Mission";
import EffectivenessMissionState from "./EffectivenessMissionState";

const initialState = <EffectivenessMissionState>{
  done: false,
  isLoaded: false,
  error: false,
  effectivenessMissions: [],
};

const EffectivenessMissionStore = createStore(initialState)
  .on(setEffectiveMissionsEvent, (state) => ({
    ...state,
    isLoaded: true,
    done: false,
    error: false,
  }))
  .on(setEffectiveMissionsEvent.done, (state, { result }) => {
    const auxState = [...state.effectivenessMissions];
    const auxResult = result.map((effectivenessMission: Mission) => {
      /* istanbul ignore next */
      const effectivenessMissionFound = auxState.find(
        (effectivenessMissionResult) =>
          effectivenessMissionResult.missionId ===
            effectivenessMission.missionId &&
          effectivenessMissionResult.tag === effectivenessMission.tag,
      );
      /* istanbul ignore if */
      if (effectivenessMissionFound) return null;
      return effectivenessMission;
    }) as Mission[];

    const effectivenessNotNull = auxResult.filter(
      (effectiveMission) => effectiveMission,
    );

    return {
      ...state,
      effectivenessMissions: [...auxState, ...effectivenessNotNull],
      error: false,
      isLoaded: false,
      done: true,
    };
  })
  .on(changeEffectivenessByMissionEvent, (state, missionId) => {
    const { effectivenessMissions } = state;
    if (effectivenessMissions && effectivenessMissions.length > 0) {
      const effectivenessMissionFound = effectivenessMissions.filter(
        (effectivenessMission) => effectivenessMission.missionId === missionId,
      );
      if (effectivenessMissionFound && effectivenessMissionFound.length > 0)
        effectivenessMissionFound[0].effectiveness =
          !effectivenessMissionFound[0].effectiveness;
    }
    return {
      ...state,
    };
  })
  .reset(resetEffectiveMissionsEvent);

export default EffectivenessMissionStore;
