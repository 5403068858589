import { useLogService } from "admin-portal-shared-services";
import axios, { CancelToken } from "axios";

import axiosInstance from "@config/axios/axiosInstance";

import {
  DEFAULT_LANGUAGE,
  getLanguageByZone,
} from "@i18n/domains/LanguageOptions";
import {
  TLP_ORDER_TAKING_EMPTIES,
  isFeatureEnabledV2,
} from "../../../config/featureToggles";
import { GROW_BFF_SERVICE } from "../../../config/services";
import StoresToUpdateCartSimulationRequestConverter from "../../../converters/cart/updateCartSimulation/StoresToUpdateCartSimulationRequestConverter";
import CartSimulationResponseToCartInfoConverter from "../../../converters/cartSimulation/CartSimulationResponseToCartInfoConverter";
import { CartInfo } from "../../../domains/cart/CartInfo";
import AgentCallState from "../../../stores/agentCall/AgentCallState";
import CartItemState from "../../../stores/cart/CartItemState";
import CartState from "../../../stores/cart/CartState";
import CartComboState from "../../../stores/cartCombo/CartComboState";
import GlobalStore from "../../../stores/global/GlobalStore";
import { getApiHost } from "../../host/HostService";
import CartStateSimulationResponse from "../cartStateSimulation/domains/CartStateSimulationResponse";
import UpdateCanceledError from "../domains/UpdateCanceledError";

const CART_SIMULATION = "/cart-simulation";

export function UpdateCartSimulation(
  agentCallState: AgentCallState,
  cartState: CartState,
  cartComboState: CartComboState,
  cartItemsState: CartItemState,
  cancelRequestToken: CancelToken,
): Promise<CartInfo | null> {
  const { user } = GlobalStore.getState();
  const zone = user?.zone;
  const language = getLanguageByZone(zone)?.value ?? DEFAULT_LANGUAGE;

  const log = useLogService();

  const accountId = agentCallState.clientId;

  const isOrderTakingEmptiesEnabled = isFeatureEnabledV2(
    TLP_ORDER_TAKING_EMPTIES,
    user.keyToggle,
  );

  const request = StoresToUpdateCartSimulationRequestConverter(
    cartItemsState,
    cartState,
    cartComboState,
    isOrderTakingEmptiesEnabled,
  );

  const endpoint = `${getApiHost(
    GROW_BFF_SERVICE,
  )}/accounts/${accountId}${CART_SIMULATION}`;

  return axiosInstance
    .patch<CartStateSimulationResponse>(endpoint, request, {
      headers: {
        "Accept-Language": language,
      },
      cancelToken: cancelRequestToken,
    })
    .then((response) => {
      log.info(
        `Convert CartId Response received from ${endpoint} to our model`,
      );
      return CartSimulationResponseToCartInfoConverter(
        response.data,
        accountId,
        request.payment.method,
        request.payment.term,
      );
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        throw new UpdateCanceledError();
      }
      log.error(error);
      throw error;
    });
}
