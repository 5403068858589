import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import OrderCartStyles from "../../OrderCartStyles";

interface CartDetailsProps {
  handleFormChange: (cartFormChanges: Record<string, string>) => void;
  details: string;
}

const CartDetails: React.FC<CartDetailsProps> = ({
  handleFormChange,
  details,
}) => {
  const { t } = useTranslation();
  const classes = OrderCartStyles();
  const [detailsState, setDetailsState] = useState(details);

  return (
    <Box className={classes.container}>
      <Typography className={classes.infoTitles}>
        {t("OrderTaking.ORDER_DETAILS_LABEL")}
      </Typography>
      <textarea
        id="details"
        name="details"
        placeholder={t("OrderTaking.ORDER_DETAILS")}
        className={classes.textarea}
        value={detailsState}
        onChange={(e) => {
          setDetailsState(e.currentTarget.value);
        }}
        onBlur={() => {
          handleFormChange({ details: detailsState });
        }}
        data-testid="order-taking-details"
        maxLength={255}
      />
    </Box>
  );
};

const areEqual = (prevProps: CartDetailsProps, nextProps: CartDetailsProps) => {
  return prevProps.details === nextProps.details;
};

export default React.memo(CartDetails, areEqual);
