import {
  PaymentMethodInfoItem,
  TermPeriod,
  PaymentTermsStore,
  PaymentMethodInfoStore,
} from "../../domains/paymentMethodInfo/PaymentMethodInfo";
import { groupPaymentInfoArrayBy } from "../../config/utils/functions";

export default function PaymentMethodInfoResponseToPaymentMethodInfoStore(
  data: PaymentMethodInfoItem[],
): PaymentMethodInfoStore {
  const bankSlip = "BANK_SLIP";
  const bankSlipInstallment = "BANK_SLIP_INSTALLMENT";

  const groupedDataByPaymentMethod: Record<
    string,
    Array<PaymentMethodInfoItem>
  > = groupPaymentInfoArrayBy(data, "paymentMethod");
  const paymentTerms: Array<PaymentTermsStore> = [];
  const bankSlipTermsDays: Array<TermPeriod> = [];
  const bankSlipInstallmentTermsDays: Array<TermPeriod> = [];
  const descriptions: Array<string> = [];
  const isEnabled: Array<boolean> = [];
  const ids: Array<string> = [];

  for (const paymentMethod in groupedDataByPaymentMethod) {
    groupedDataByPaymentMethod[paymentMethod].forEach((item) => {
      if (paymentMethod === bankSlip) {
        bankSlipTermsDays.push({
          days: item.paymentTerm,
          vendorPaymentMethod: item.vendorPaymentMethod,
        });
      }

      if (
        paymentMethod === bankSlipInstallment &&
        item.vendorPaymentMethod === "0319"
      ) {
        bankSlipInstallmentTermsDays.push({
          days: "07, 12, 19",
          vendorPaymentMethod: item.vendorPaymentMethod,
        });
      }

      if (
        paymentMethod === bankSlipInstallment &&
        item.vendorPaymentMethod === "0719"
      ) {
        bankSlipInstallmentTermsDays.push({
          days: "07, 19",
          vendorPaymentMethod: item.vendorPaymentMethod,
        });
      }

      descriptions.push(item.description);
      isEnabled.push(item.enabled);
      ids.push(item.id);
    });
  }

  if (groupedDataByPaymentMethod[bankSlip]) {
    paymentTerms.push({
      type: bankSlip,
      termPeriods: bankSlipTermsDays,
    });
  }

  if (groupedDataByPaymentMethod[bankSlipInstallment]) {
    paymentTerms.push({
      type: bankSlipInstallment,
      termPeriods: bankSlipInstallmentTermsDays,
    });
  }

  return {
    vendorId: data[0].vendorId,
    country: data[0].country,
    paymentTerms,
    paymentMethods: Object.keys(groupedDataByPaymentMethod),
    descriptions,
    isEnabled,
    ids,
    groupedPaymentMethodInfos: groupedDataByPaymentMethod,
  };
}
