import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
    },
    image: {
      flexGrow: 1,
      height: "90px",
    },
  }),
);
