import { createStore } from "effector";
import {
  setAutoDialerEvent,
  showCallNavigationButtonEvent,
  showModalStopAutoDialerEvent,
} from "./AutoDialerEvents";
import AutoDialerState from "./AutoDialerState";

const initialState = <AutoDialerState>{
  done: false,
  error: false,
  autoDialerEnabled: false,
  showCallNavigationButton: false,
  showModalStopAutoDialer: false,
};

const AutoDialerStore = createStore(initialState)
  .on(
    setAutoDialerEvent,
    (state: AutoDialerState, autoDialerEnabled: boolean) => ({
      ...state,
      done: true,
      error: false,
      autoDialerEnabled,
    }),
  )
  .on(
    showCallNavigationButtonEvent,
    (state: AutoDialerState, showCallNavigationButton: boolean) => ({
      ...state,
      done: true,
      error: false,
      showCallNavigationButton,
    }),
  )
  .on(
    showModalStopAutoDialerEvent,
    (state: AutoDialerState, showModalStopAutoDialer: boolean) => ({
      ...state,
      done: true,
      error: false,
      showModalStopAutoDialer,
    }),
  );

export default AutoDialerStore;
