import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, breakpoints }) =>
  createStyles({
    container: {
      display: "flex",
      height: "100%",
    },
    promotionsContainer: {
      width: "280px",
      marginLeft: "15px",
      // padding: "45px 0 4em 0",
      [breakpoints.up(1440)]: {
        width: "300px",
      },
    },
    missionsContainer: {
      flex: 1,
    },
    titleContainer: {
      width: "fit-content",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      marginLeft: 5,
      fontSize: "20px",
      color: palette.secondary.main,
      opacity: 1,
    },
    noMissions: {
      backgroundColor: "#ffff00",
      padding: "3px 4px",
      width: "fit-content",
      fontWeight: "bold",
      color: "#505050",
    },
    noMissionsContainer: {
      padding: "12px 4px",
    },
    errorContainer: {
      marginTop: "13rem",
      border: "none !important",
      background: "none !important",
      "& .tlp-MuiTypography-h1": {
        fontSize: "32px",
      },
      "& .tlp-MuiTypography-subtitle1": {
        fontSize: "16px !important",
      },
    },
  }),
);
