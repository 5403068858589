import { useRef, useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useIsMounted = () => {
  const isMounted = () => {
    const didMount = useRef(false);

    useEffect(() => {
      didMount.current = true;

      /* istanbul ignore next */
      return () => {
        didMount.current = false;
      };
    }, []);

    return didMount;
  };

  return { isMounted };
};

export default useIsMounted;
