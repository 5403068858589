import { useMemo } from "react";
import { useStore } from "effector-react";
import { setValidCampaigns } from "../../../../stores/campaigns/CampaignsEvents";
import { getB2OTag } from "../../../../config/utils/functions";
import GlobalStore from "../../../../stores/global/GlobalStore";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import TagsStore from "../../../../stores/tags/TagsStore";
import CampaignsStore from "../../../../stores/campaigns/CampaignsStore";
import CampaignsB2OProductsStore from "../../../../stores/campaigns/campaignsB2OProducts/CampaignsB2OProductsStore";
import { changeOrderNavigationTab } from "../../../../stores/navigation/orderTab/OrderTabEvents";
import { CampaignB2O } from "../../../../stores/campaigns/CampaignsState";
import {
  isFeatureEnabled,
  TLP_SEARCH_BY_POC,
  TLP_SEARCH_BY_POC_ADMIN,
} from "../../../../config/featureToggles";
import {
  OrderNavigationTabName,
  B2O_PREFIX,
  PocCallOrigin,
} from "../../../../config/constants";

export interface Campaign {
  campaignTag: string;
  campaignName: string;
  campaignType: string;
  campaignScript: string;
  campaignDescription: string;
  campaignImage: string;
}

type CampaignsItems = Array<{
  id: string;
  sku: string;
  productName: string;
  unitPrice: number;
  pricePerUoM: number;
  image: string;
  tag: string;
  campaignName: string;
  platformId?: string;
}>;

const useGetCampaignsHook = (
  /* istanbul ignore next */
  changeTabs = false,
): [Campaign[], CampaignsItems] => {
  const { campaigns: allCampaigns } = useStore(CampaignsStore);
  const { campaignsProducts } = useStore(CampaignsB2OProductsStore);

  const { list: campaignTags } = useStore(TagsStore);
  const { isTypedPoc, pocCallOrigin } = useStore(AgentCallStore);
  const {
    user: { zone },
  } = useStore(GlobalStore);
  const isSearchByPocEnabled = isFeatureEnabled(
    TLP_SEARCH_BY_POC,
    TLP_SEARCH_BY_POC_ADMIN,
    zone,
  );

  const isSearchByPocOrAutoDialer =
    isSearchByPocEnabled &&
    isTypedPoc &&
    (pocCallOrigin === PocCallOrigin.SEARCH_BY_POC ||
      pocCallOrigin === PocCallOrigin.AUTO_DIALER);

  const getCampaign = (campaign: CampaignB2O) => {
    return {
      campaignTag: campaign.campaignTag,
      campaignName: campaign.campaignName,
      campaignDescription: campaign.campaignDescription,
      campaignScript: campaign.campaignScript,
      campaignImage: campaign.campaignImage,
      campaignType: campaign.campaignType,
    } as Campaign;
  };

  const campaigns = useMemo(() => {
    if (isSearchByPocOrAutoDialer) {
      const campaignsSearchByPoc =
        allCampaigns?.map(getCampaign) ?? /* istanbul ignore next */ [];
      if (campaignsSearchByPoc.length > 0) {
        /* istanbul ignore else */

        if (changeTabs) {
          changeOrderNavigationTab(OrderNavigationTabName.CAMPAIGNS);
        }
        setValidCampaigns(
          allCampaigns?.map((campaign) => campaign.campaignTag),
        );
      }
      return campaignsSearchByPoc;
    }
    const b2oTags =
      campaignTags?.filter((tag) => tag?.startsWith(B2O_PREFIX)) ??
      /* istanbul ignore next */ [];

    const validCampaigns =
      allCampaigns
        ?.filter((campaign) =>
          b2oTags.some((tag) => tag === getB2OTag(campaign?.campaignTag)),
        )
        ?.map(getCampaign) ?? /* istanbul ignore next */ [];
    /* istanbul ignore next */
    if (validCampaigns.length > 0) {
      const validCampaignTags = validCampaigns.map(
        (campaign) => campaign.campaignTag,
      );
      if (changeTabs) {
        changeOrderNavigationTab(OrderNavigationTabName.CAMPAIGNS);
      }
      setValidCampaigns(validCampaignTags);
    }

    return validCampaigns;
  }, [isSearchByPocOrAutoDialer, campaignTags, allCampaigns, changeTabs]);

  const campaignItems = useMemo(() => {
    return campaignsProducts?.map((each) => {
      return {
        id: each.id,
        sku: each.sku,
        productName: each.productName,
        unitPrice: each.unitPrice,
        platformId: each.platformId,
        pricePerUoM: each.pricePerUoM,
        image: each.image,
        tag: each.campaignTag,
        campaignName: "",
        isB2O: true,
        inventoryCount: each.inventoryCount,
        palletQuantity: each.palletQuantity,
        stock: each.stock,
      };
    });
  }, [campaignsProducts]);
  return [campaigns, campaignItems];
};

export default useGetCampaignsHook;
