import { createEffect, createEvent } from "effector";
import { executeSearchItems } from "../../usecase/searchCatalog/SearchApiCatalogUseCase";

interface SearchItemsProps {
  accountId: string;
  valueTyped: string;
  vendorId?: string;
  page?: number;
  pageSize?: number;
}

export const getSearchItems = createEffect({
  async handler(props: SearchItemsProps) {
    return executeSearchItems(
      props.accountId,
      props.valueTyped,
      props.vendorId,
      props.page,
      props.pageSize,
    );
  },
});

export const resetSearchItems = createEvent("resetSearchItems");

export const getSearchMoreItems = createEffect({
  async handler(props: SearchItemsProps) {
    return executeSearchItems(
      props.accountId,
      props.valueTyped,
      props.vendorId,
      props.page,
      props.pageSize,
    );
  },
});
