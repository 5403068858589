import React from "react";

export default function IconCalendar(): JSX.Element {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-testid="icon-calendar"
    >
      <defs>
        <path
          d="M30.5454545,0 C31.6643691,0 32.5865611,0.842269506 32.712594,1.92737209 L32.7272727,2.18181818 L32.727,4.363 L37.0909091,4.36363636 C40.6272778,4.36363636 43.5086271,7.16810807 43.6322319,10.6743265 L43.6363636,10.9090909 L43.6363636,41.4545455 C43.6363636,45.0695002 40.7058638,48 37.0909091,48 L37.0909091,48 L6.54545455,48 C2.93049982,48 0,45.0695002 0,41.4545455 L0,41.4545455 L0,10.9090909 C0,7.29413618 2.93049982,4.36363636 6.54545455,4.36363636 L6.54545455,4.36363636 L10.909,4.363 L10.9090909,2.18181818 C10.9090909,0.976833273 11.8859242,0 13.0909091,0 C14.2098236,0 15.1320156,0.842269506 15.2580486,1.92737209 L15.2727273,2.18181818 L15.272,4.363 L28.363,4.363 L28.3636364,2.18181818 C28.3636364,0.976833273 29.3404696,0 30.5454545,0 Z M39.272,21.818 L4.363,21.818 L4.36363636,41.4545455 C4.36363636,42.6071397 5.25737418,43.5509903 6.389638,43.6308855 L6.54545455,43.6363636 L37.0909091,43.6363636 C38.295894,43.6363636 39.2727273,42.6595304 39.2727273,41.4545455 L39.2727273,41.4545455 L39.272,21.818 Z M10.909,8.727 L6.54545455,8.72727273 C5.34046964,8.72727273 4.36363636,9.704106 4.36363636,10.9090909 L4.36363636,10.9090909 L4.363,17.454 L39.272,17.454 L39.2727273,10.9090909 C39.2727273,9.75649665 38.3789895,8.81264604 37.2467256,8.73275089 L37.0909091,8.72727273 L32.727,8.727 L32.7272727,10.9090909 C32.7272727,12.1140758 31.7504395,13.0909091 30.5454545,13.0909091 C29.42654,13.0909091 28.504348,12.2486396 28.3783151,11.163537 L28.3636364,10.9090909 L28.363,8.727 L15.272,8.727 L15.2727273,10.9090909 C15.2727273,12.1140758 14.295894,13.0909091 13.0909091,13.0909091 C11.9719945,13.0909091 11.0498026,12.2486396 10.9237696,11.163537 L10.9090909,10.9090909 L10.909,8.727 Z"
          id="calendar-id"
        />
      </defs>
      <g
        id="___Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="02_Icons/calendar">
          <g
            id="ZZ_Color-Override/Primary/Black"
            transform="translate(10.000000, 8.000000)"
          >
            <mask id="calendar-mask-2" fill="white">
              <use xlinkHref="#calendar-id" />
            </mask>
            <g id="Mask" fillRule="nonzero" />
            <g
              id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
              mask="url(#calendar-mask-2)"
              fill="#000000"
              fillOpacity="0.9"
            >
              <g transform="translate(-10.000000, -8.000000)" id="Rectangle">
                <rect x="0" y="0" width="64" height="64" />
              </g>
            </g>
          </g>
          <g id="ZZ_Color-Override/Primary/Black" />
        </g>
      </g>
    </svg>
  );
}
