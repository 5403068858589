import { createStore } from "effector";
import { RecommendedMarketplaceProductsStoreState } from "./RecommendedMarketplaceProductsState";
import * as RecommendedMarketplaceProductsEvents from "./RecommendedMarketplaceProductsEvents";

const initialState: RecommendedMarketplaceProductsStoreState = {
  loading: false,
  error: false,
  done: false,
  recommendedProducts: [],
};

const RecommendedMarketplaceProductsStore = createStore(initialState)
  .on(
    RecommendedMarketplaceProductsEvents.getRecommendedMarketplaceProductsEffect,
    (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        done: false,
      };
    },
  )
  .on(
    RecommendedMarketplaceProductsEvents.getRecommendedMarketplaceProductsEffect
      .done,
    (state, { result }) => {
      return {
        ...state,
        loading: false,
        error: false,
        done: true,
        recommendedProducts: [...result],
      };
    },
  )
  .on(
    RecommendedMarketplaceProductsEvents.getRecommendedMarketplaceProductsEffect
      .fail,
    (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        done: true,
        recommendedProducts: [],
      };
    },
  );

export default RecommendedMarketplaceProductsStore;
