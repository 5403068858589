import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Package, Store } from "@hexa-ui/icons";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";

import ChevronDown from "../../../../../assets/ChevronDown";
import ChevronUp from "../../../../../assets/ChevronUp";
import DeleteIcon from "../../../../../assets/DeleteIcon";
import CombinedQuantitySelect from "../../../../../components/combinedQuantitySelect/CombinedQuantitySelect";
import Data from "../../../../../components/data/Data";
import QuantityControl from "../../../../../components/quantityControl/QuantityControl";
import { DisplayCartItem } from "../../../../../domains/orderSummary/DisplayCartItem";
import { useProductCommonsHook } from "../../../../../hooks/useProductCommonsHook";
import ProductTag from "../../localMissionsTag/components/localMissionsTag/LocalMissionsTag";
import useStyles from "./CartItemV2.styles";

interface CartItemV2Props {
  cartItem: DisplayCartItem;
  onChangeQuantity?: (newQuantity: number) => void;
  onRemove: () => void;
}

export default function CartItemV2({
  cartItem,
  onChangeQuantity,
  onRemove,
}: CartItemV2Props): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const { shouldShowQuantitySelector } = useProductCommonsHook();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const { isCombo, isMarketplace } = cartItem;

  const shouldShowTagsContainer = isCombo || isMarketplace;

  const showQuantitySelector = shouldShowQuantitySelector(
    cartItem.maxQuantity,
    cartItem.quantityMultiplier,
  );

  const formatedPackageTypeLabel = `${t(
    "COMMONS_PRODUCTS_INFOS.PACKAGE_TYPES_LABEL",
  )}: ${cartItem.unitOfMeasurement}`;

  const formatedItemsAvailable = `${cartItem.displayQuantity} ${t(
    "OrderTaking.ORDER_ITEM_INVENTORY_COUNT",
  )}`;

  const renderCollapseIcon = () => {
    if (isCollapsed)
      return (
        <ChevronUp data-testid={`${cartItem.platformId}-collapse-chevron-up`} />
      );
    return (
      <ChevronDown
        data-testid={`${cartItem.platformId}-collapse-chevron-down`}
      />
    );
  };

  const shouldShowDivider = (productIndex: number) => {
    return (
      cartItem.comboItems &&
      cartItem.comboItems.length > 1 &&
      productIndex !== cartItem.comboItems.length - 1
    );
  };

  const renderEditableQuantity = (
    onChangeQuantity: (newQuantity: number) => void,
  ) => {
    return (
      <>
        {showQuantitySelector ? (
          <CombinedQuantitySelect
            selectedQuantity={cartItem.quantity}
            maxOrderQuantity={cartItem.maxQuantity ?? 0}
            testId={`${cartItem.platformId}-cart-item-quantity-selector`}
            onChange={onChangeQuantity}
            multiplierQuantity={cartItem.quantityMultiplier}
            unitOfMeasurement={cartItem?.nextUnitOfMeasurement}
            data-testid={`${cartItem.platformId}-cart-item-quantity-selector`}
          />
        ) : (
          <QuantityControl
            value={cartItem.quantity}
            onChange={onChangeQuantity}
            testId={`${cartItem.platformId}-cart-item-quantity-control`}
            minValue={cartItem.quantityMultiplier}
            quantityMultiplier={cartItem.quantityMultiplier}
            maxValue={cartItem.maxQuantity}
            data-testid={`${cartItem.platformId}-cart-item-quantity-control`}
            isOrderVisualization
          />
        )}
      </>
    );
  };

  return (
    <Box
      className={classes.container}
      data-testid={`${cartItem.platformId}-cart-item-container`}
    >
      {shouldShowTagsContainer && (
        <Box className={classes.tagsContainer}>
          {isCombo && (
            <ProductTag
              title={t("COMMONS_PRODUCTS_INFOS.TAGS.COMBO_LABEL")}
              icon={<Package size="tiny" />}
              data-testid={`${cartItem.platformId}-cart-item-combo-tag`}
              backgroundIconColor="#f7f72a"
              backgroundColor="#F2F2F2"
            />
          )}
          {isMarketplace && (
            <ProductTag
              title={t("COMMONS_PRODUCTS_INFOS.TAGS.MARKETPLACE_LABEL")}
              icon={<Store size="tiny" />}
              data-testid={`${cartItem.platformId}-cart-item-marketplace-tag`}
              backgroundIconColor="#FFAA33"
              backgroundColor="#F2F2F2"
            />
          )}
        </Box>
      )}

      <Box
        className={classes.titleContainer}
        data-testid={`${cartItem.platformId}-cart-item-title-container`}
      >
        <Typography
          className={classes.title}
          data-testid={`${cartItem.platformId}-cart-item-title`}
        >
          {cartItem.title}
        </Typography>

        {cartItem.isCombo && (
          <IconButton
            aria-label="toggle dropdown visibility"
            onClick={() => setIsCollapsed(!isCollapsed)}
            data-testid={`${cartItem.platformId}-collapse-icon-product-details`}
            className={classes.chevronIcon}
          >
            {renderCollapseIcon()}
          </IconButton>
        )}
      </Box>
      <Typography
        className={classNames(classes.defaultText, classes.sku)}
        data-testid={`${cartItem.platformId}-cart-item-id`}
      >
        {cartItem.id}
      </Typography>

      <Collapse
        in={isCollapsed}
        data-testid={`${cartItem.platformId}-products-collapse`}
        className={cartItem.price ? classes.collapseContainer : ""}
      >
        {cartItem.comboItems?.map((comboItem, index) => (
          <Box
            key={`${cartItem.platformId}-combo-item-container-${comboItem.id}`}
            data-testid={`${cartItem.platformId}-combo-item-container-${comboItem.id}`}
          >
            <Box className={classes.comboProductContainerDetails}>
              <Data
                value={comboItem.productName}
                className={classes.comboProductTitle}
                data-testid={`${cartItem.platformId}-combo-item-product-title-${comboItem.id}`}
                hasTooltip
              />
              {comboItem.packageDescription && (
                <Data
                  value={comboItem.packageDescription}
                  className={classes.comboProductDescription}
                  data-testid={`${cartItem.platformId}-combo-item-product-unity-${comboItem.id}`}
                />
              )}
            </Box>
            {shouldShowDivider(index) && (
              <Divider
                className={classes.divider}
                data-testid={`${cartItem.platformId}-combo-item-divider`}
              />
            )}
          </Box>
        ))}
      </Collapse>

      <Box
        className={classes.detailsContainer}
        data-testid={`${cartItem.platformId}-cart-item-details-container`}
      >
        <Box
          className={classes.detailsArea}
          data-testid={`${cartItem.platformId}-cart-item-details-area`}
        >
          {!!cartItem.price && (
            <Data
              value={cartItem.price.toFixed(2)}
              type="currency"
              className={classNames(classes.price)}
              data-testid={`${cartItem.platformId}-cart-item-price`}
            />
          )}

          {cartItem.unitOfMeasurement && (
            <Typography
              className={classes.defaultText}
              data-testid={`${cartItem.platformId}-cart-item-package-type`}
            >
              {formatedPackageTypeLabel}
            </Typography>
          )}

          {!!cartItem.displayQuantity && (
            <Typography
              className={classes.defaultText}
              data-testid={`${cartItem.platformId}-cart-item-available-quantity`}
            >
              {formatedItemsAvailable}
            </Typography>
          )}
        </Box>
        <Box
          className={classes.buttonsArea}
          data-testid={`${cartItem.platformId}-cart-item-buttons-area`}
        >
          {onChangeQuantity ? (
            renderEditableQuantity(onChangeQuantity)
          ) : (
            <Typography
              className={classes.defaultText}
              data-testid={`${cartItem.platformId}-cart-item-available-quantity-readonly`}
            >
              {cartItem.quantity} un
            </Typography>
          )}
          <IconButton
            onClick={() => onRemove()}
            aria-label="delete"
            data-testid={`${cartItem.platformId}-cart-item-delete-icon`}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
