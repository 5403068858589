import { createStore } from "effector";
import { getCredit } from "./CreditEvents";
import { CreditState } from "./CreditState";

const initialState = <CreditState>{
  isLoading: true,
  error: false,
  credit: {
    accountId: "",
    balance: 0,
    overdue: 0,
    available: 0,
    paymentTerms: "",
    total: 0,
    consumption: 0,
    updatedAt: "",
    availableValue: 0,
  },
};

const CreditStore = createStore(initialState)
  .on(getCredit, (state) => ({
    ...state,
    isLoading: true,
    error: false,
  }))
  .on(getCredit.fail, (state) => ({
    ...state,
    loading: false,
    error: true,
  }))
  .on(getCredit.done, (state, value) => ({
    ...state,
    isLoading: false,
    error: false,
    credit: value.result,
  }));

export default CreditStore;
