import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    containerTabs: {
      backgroundColor: "rgba(33, 33, 33, 0.7)",
      width: "250px",
      margin: "9px 0 18px",
      padding: "9px 57px 9px 17px",
      display: "flex",
    },
    tabButton: {
      display: "block",
      color: "#9A9A9A",
      fontSize: "12px",
      paddingBottom: "0px",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px",
      textTransform: "none",
      padding: "0 6px 6px",
      textAlign: "inherit",
      borderBottom: "2px solid transparent",
      lineHeight: "20px",
    },
    active: {
      borderBottom: `2px solid ${palette.primary.light}`,
      color: "white",
      lineHeight: "20px",
    },
  }),
);
