import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface Props {
  isFocusSkuEnabled?: boolean;
  suggestedLength?: number;
  isNewLayout?: boolean;
}

export default makeStyles<Theme, Props>(({ palette }) =>
  createStyles({
    loadingContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    container: {
      padding: "10px 0 10px 0",
      backgroundColor: "#fafafa",
      height: "50%",
      display: "flex",
      flexDirection: "column",
    },
    tableNavigationContainer: {
      display: "grid",
      marginTop: "24px",
      padding: "12px 0 8px 0",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
      borderRadius: "16px",
      height: (props) => {
        if (!props.suggestedLength) {
          return "auto";
        }
        return props.suggestedLength && props.suggestedLength > 7
          ? "525px"
          : "100%";
      },
    },
    listContainer: {
      paddingTop: 16,
      paddingBottom: 16,
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      marginLeft: 10,
      fontWeight: 500,
      fontSize: "18px",
      letterSpacing: 0,
      color: palette.secondary.main,
      opacity: 1,
    },
    right: {
      marginLeft: "auto",
    },
    headerContainer: {
      borderBottom: `1px solid ${palette.grey.A100}`,
      marginTop: "10px",
    },
    headerContainerSuggested: {
      borderBottom: `1px solid ${palette.grey.A100}`,
    },
    suggestedEmpty: {
      fontSize: "16px",
      color: palette.secondary.main,
      paddingBottom: "14px",
      textAlign: "center",
    },
  }),
);
