import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import Data from "../../../../../components/data/Data";
import EmptyItemStyles from "./EmptyItemStyles";
import QuantityInput from "../../../../../components/quantityInput/QuantityInput";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import { emptiesQuantityEdited } from "../../../../../config/typewriter";
import { EmptyItem as Empty } from "../../../../../domains/cart/Empties";

interface Props {
  item: Empty;
  shouldUpdateItem: (groupId: string, quantity: number) => void;
}

const EmptyItem: React.FunctionComponent<Props> = ({
  item,
  shouldUpdateItem,
}) => {
  const classes = EmptyItemStyles();
  const { t } = useTranslation();
  const { dispatchGenericEvent } = useAnalytics();
  const { clientId, callId } = useStore(AgentCallStore);

  const sendEmptiesQuantityEdited = (value: number) => {
    dispatchGenericEvent(emptiesQuantityEdited, {
      call_id: callId,
      poc_id: clientId,
      empty_sku: item.groupId,
      quantity: value,
      empties_balance: item.emptiesBalance,
      empties_required: item.minimumRequired,
    });
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      wrap="nowrap"
      className={classes.container}
    >
      <Grid item container data-testid="empty-item">
        <Grid item container direction="row" className={classes.title}>
          <Grid container className={classes.noStyle}>
            <Grid item container direction="row">
              <Typography className={classes.name}>
                <Data ellipsisSize={25} value={item.emptiesName} />
              </Typography>

              <Typography className={classes.id}>
                <Data ellipsisSize={25} value={item.groupId} />
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction={
                item.emptiesBalance > 0 && item.minimumRequired > 0
                  ? "row"
                  : "column"
              }
            >
              <span
                data-testid="empty-item-balance"
                className={classes.balance}
              >
                {item.emptiesBalance > 0
                  ? `${t("Empties.BALANCE")} ${item.emptiesBalance}`
                  : `${t("Empties.BALANCE")} ${t(
                      "GlobalMessage.NO_DATA_AVAILABLE",
                    )}`}
              </span>
              <span
                data-testid="empty-item-required"
                className={classes.required}
              >
                {item.minimumRequired > 0
                  ? `${t("Empties.REQUIRED")} ${item.minimumRequired}`
                  : `${t("Empties.REQUIRED")} ${t(
                      "GlobalMessage.NO_DATA_AVAILABLE",
                    )}`}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.quantityContainer}>
          <QuantityInput
            maxValue={item.emptiesBalance}
            minValue={item.minimumRequired}
            value={item.quantity}
            data-testid="empty-item-input-quantity"
            onChange={(value) => {
              shouldUpdateItem(item.groupId, Number(value));
              sendEmptiesQuantityEdited(Number(value));
            }}
            disabled={!(item.emptiesBalance > 0 && item.minimumRequired > 0)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(EmptyItem);
