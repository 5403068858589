import { createStore } from "effector";
import * as GlobalEvents from "./GlobalEvents";
import GlobalState from "./GlobalState";
import Toast from "../../domains/Toast";
import ToastConverter from "../../converters/ToastConverter";
import {
  languageMap,
  LanguageOption,
} from "../../i18n/domains/LanguageOptions";

const initialToast = ToastConverter("", "", false);

const initialState: GlobalState = {
  isLoading: false,
  isIdentified: false,
  user: {
    login: "",
    zone: "",
    keyToggle: "",
  },
  language: languageMap.US,
  toast: initialToast,
  segmentation: [],
};

interface User {
  login: string;
  zone: string;
  keyToggle: string;
}

const GlobalStore = createStore(initialState)
  .on(GlobalEvents.loadBackDrop, (state) => ({ ...state, isLoading: true }))
  .on(GlobalEvents.loadBackDropDone, (state) => ({
    ...state,
    isLoading: false,
  }))
  .on(GlobalEvents.toast, (state: GlobalState, toast: Toast) => ({
    ...state,
    toast,
  }))
  .on(GlobalEvents.toastHide, (state: GlobalState) => ({
    ...state,
    toast: ToastConverter("", "", false),
  }))
  .on(
    GlobalEvents.changeSelectedLanguage,
    (state: GlobalState, language: LanguageOption) => ({
      ...state,
      language,
    }),
  )
  .on(GlobalEvents.setUser, (state: GlobalState, user: User) => ({
    ...state,
    user,
  }))
  .on(
    GlobalEvents.setSegmentation,
    (state: GlobalState, segmentation: Array<string>) => ({
      ...state,
      segmentation,
    }),
  )
  .on(GlobalEvents.setIsIdentified, (state: GlobalState, value) => ({
    ...state,
    isIdentified: value,
  }));
export default GlobalStore;
