import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    listItem: {
      borderBottom: "1px solid #dedede",
      minHeight: "160px",
      display: "flex",
      alignItems: "center",
    },
    imageWithPallet: {
      width: "80px",
      height: "65%",
      borderRadius: "3px",
      backgroundColor: theme.palette.grey[50],
      marginRight: "16px",
      "& img": {
        objectFit: "contain",
        width: "80px",
        height: "90%",
        marginBottom: "10px",
        maxHeight: "80px",
      },
    },
    image: {
      width: "80px",
      height: "100%",
      borderRadius: "3px",
      backgroundColor: theme.palette.grey[50],
      marginRight: "16px",
      marginBottom: "5px",
      "& img": {
        objectFit: "contain",
        width: "80px",
        height: "90%",
        maxHeight: "100px",
      },
    },
    itemName: {
      fontSize: "12px",
      fontWeight: 500,
      color: theme.palette.secondary.main,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "250px",
    },
    itemPrice: {
      fonstSize: "14px",
      fontWeight: "bold",
      color: theme.palette.secondary.main,
      textAlign: "right",
    },
    itemUnitPrice: {
      fontSize: "12px",
      fontWeight: 600,
      color: theme.palette.info.main,
    },
    itemPricePerUoM: {
      fontSize: "12px",
      fontWeight: 600,
      color: theme.palette.grey[300],
    },
    itemSku: {
      color: theme.palette.grey[200],
    },
    quantityContainer: {
      width: "60px",
      height: "30px",
      borderRadius: "3px",
      border: `1px solid ${theme.palette.secondary.main}`,
      marginRight: "16px",
    },
    container: {
      width: "100%",
      height: "auto",
    },
    btnWrapper: {
      paddingTop: "11px",
    },
    addButton: {
      minWidth: "65px",
      padding: "0 15px",
      fontSize: "12px",
      fontFamily: "Barlow",
      fontWeight: "bold",
      borderRadius: "3px",
    },
    marketplaceTag: {
      marginLeft: "5px",
    },
  }),
);
