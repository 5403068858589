import { useLogService } from "admin-portal-shared-services";

import axios from "@config/axios/axiosInstance";
import {
  TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
  isFeatureEnabledV2,
} from "@config/featureToggles";
import { CUSTOM_MISSIONS_SERVICE } from "@config/services";
import { getCountryByToken } from "@config/utils/functions";
import { LocalMissionList } from "@domains/sponsored/SponsoredMission";
import { getApiHost } from "@services/host/HostService";

import { SponsoredMissionsConverter } from "../../converters/sponsored/SponsoredMissionsConverter";

export const SPONSORED_ENDPOINT = "/grow-missions";

export async function getSponsoredMissionsService(
  clientId: string,
  missionsIds: string,
  vendorId?: string,
): Promise<LocalMissionList> {
  const log = useLogService();

  const isSponsoredMissionsV2Enabled = isFeatureEnabledV2(
    TLP_CUSTOM_MISSIONS_ENDPOINT_V2,
    getCountryByToken(),
  );

  const baseUrl = `${getApiHost(
    CUSTOM_MISSIONS_SERVICE,
  )}${SPONSORED_ENDPOINT}/`;

  let uri = "";

  if (isSponsoredMissionsV2Enabled) {
    uri = `v2/${clientId}/custom-missions?ids=${missionsIds}&vendorId=${
      vendorId ?? ""
    }`;
  } else {
    uri = `${clientId}/custom-missions?ids=${missionsIds}`;
  }

  return axios
    .get(`${baseUrl}${uri}`)
    .then((response) => {
      log.info(
        `Convert sponsored missions received from ${baseUrl}${uri} to our model`,
      );

      return SponsoredMissionsConverter(response.data?.missions);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
}
