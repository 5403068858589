import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    shippingSetup: {
      marginTop: "20px",
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
    shippingValues: {
      fontWeight: 500,
      flex: 0.5,
    },
  }),
);
