import { createEvent } from "effector";

import { CartComboItem } from "../../domains/cart/CartComboItem";

export const updateComboQuantity = createEvent<CartComboItem>(
  "updateComboQuantity",
);

export const removeItem = createEvent<CartComboItem & { toggle?: boolean }>(
  "removeItem",
);

export const addItem = createEvent<CartComboItem>("addItem");

export const addCombosSimulation = createEvent<Array<CartComboItem>>(
  "addCombosSimulation",
);

export const resetCombos = createEvent("resetCombos");

export const clearComboCart = createEvent("clearComboCart");
