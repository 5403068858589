/* istanbul ignore file */
import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    editButton: {
      height: "32px",
      width: "32px",
      marginRight: "19.5px",
      color: "rgba(255, 255, 255, 0.95) !important",
      backgroundColor: "#212121",
      "&:hover": {
        backgroundColor: "#212121",
      },
    },
    editButtonDisabled: {
      backgroundColor: "#DFDEDE !important",
    },
  }),
);
