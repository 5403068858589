import React from "react";

import { Card, CardContent } from "@material-ui/core";
import { useStore } from "effector-react";

import {
  TLP_SERVICE_CONTENT_BELOW_CARD,
  isFeatureEnabledV2,
} from "../../../../../../config/featureToggles";
import GlobalStore from "../../../../../../stores/global/GlobalStore";
import IconBarcode from "../icons/IconBarcode";
import IconCalendar from "../icons/IconCalendar";
import IconCurrency from "../icons/IconCurrency";
import IconGift from "../icons/IconGift";
import IconGlobe from "../icons/IconGlobe";
import IconPackage from "../icons/IconPackage";
import IconShield from "../icons/IconShield";
import IconStarUnfilled from "../icons/IconStarUnfilled";
import IconStore from "../icons/IconStore";
import IconZap from "../icons/IconZap";
import ServicesCardStyle from "./ServicesCardStyle";

interface Props {
  title: string;
  icon: string;
  focus: boolean;
  onClickCallback: () => void;
}

const ServicesCard: React.FC<Props> = (props) => {
  const { user } = useStore(GlobalStore);

  const serviceContentBelowCard = isFeatureEnabledV2(
    TLP_SERVICE_CONTENT_BELOW_CARD,
    user.keyToggle,
  );

  const { title, icon, focus, onClickCallback } = props;
  const classes = ServicesCardStyle();

  const getIcon = (icon: string): JSX.Element | null => {
    switch (icon) {
      case "calendar":
        return <IconCalendar />;
      case "currency":
        return <IconCurrency />;
      case "gift":
        return <IconGift />;
      case "globe":
        return <IconGlobe />;
      case "package":
        return <IconPackage />;
      case "shield":
        return <IconShield />;
      case "store":
        return <IconStore />;
      case "zap":
        return <IconZap />;
      case "starUnfilled":
        return <IconStarUnfilled />;
      case "barcode":
        return <IconBarcode />;
      default:
        return null;
    }
  };

  const cardIcon = getIcon(icon);
  return (
    <Card
      className={`${classes.card} ${focus ? classes.atFocus : classes.noFocus}`}
      onClick={onClickCallback}
      style={
        /* istanbul ignore next */ serviceContentBelowCard
          ? {}
          : { width: "24%" }
      }
      data-testid="service-card"
    >
      <CardContent>
        {cardIcon && <div style={{ textAlign: "center" }}>{cardIcon}</div>}
        <span className={classes.title}>{title}</span>
      </CardContent>
    </Card>
  );
};

export default ServicesCard;
