import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";

import axios from "../../../config/axios/axiosInstance";
import { AGENT_CALL_SERVICE } from "../../../config/services";
import AgentCallResponseToAgentCallConverter from "../../../converters/nextPoc/getOpenCall/AgentCallResponseToAgentCallConverter";
import AgentCalls from "../../../domains/AgentCall";
import { getApiHost } from "../../host/HostService";
import { AgentCallResponse } from "../domains/AgentCallResponse";

export const OPEN_CALL = "/open-call";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export function getOpenCall(
  userId: string,
  headers?: {
    country: string;
    requestTraceId: string;
    authorization: string;
    orgId: string;
  },
): Promise<AgentCalls> {
  const log = useLogService();

  const baseURL = `${getApiHost(AGENT_CALL_SERVICE)}/`;
  const endpoint = `${baseURL}v3${OPEN_CALL}/${userId}`;

  return axios
    .get<AgentCallResponse>(endpoint, {
      headers,
    })
    .then((response) => {
      log.info(`Convert agentCalls received from ${endpoint} to our model`);
      return AgentCallResponseToAgentCallConverter(
        response.data,
        response.headers.date,
      );
    })
    .catch((error) => {
      log.error("GetOpenCallService - getOpenCall error: ", error);
      throw error;
    });
}
