import React from "react";

const RewardBadgeIcon: React.FunctionComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="20"
    viewBox="0 0 13 20"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF" fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M11.299 6.222l.831-1.385-1.349-.887.148-1.608-1.6-.213L8.764.616l-1.533.5L6.065 0 4.902 1.119 3.366.616 2.803 2.13l-1.6.213.149 1.608-1.351.887.832 1.385-.832 1.383 1.351.886-.151 1.608 1.6.212.563 1.513 1.536-.5 1.164 1.119 1.162-1.12 1.534.5.564-1.513 1.6-.212-.148-1.609 1.349-.886-.826-1.382zm-3.78 6.364l-.684.658-.77.74-.77-.74-.683-.658-.9.3-.907.3v5.963l3.2-2.013 3.321 2.013V13.18l-.908-.3-.899-.294z"
                  transform="translate(-95 -191) translate(55 56) translate(24 32) translate(0 70) translate(16 33)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default RewardBadgeIcon;
