/* eslint-disable react/no-unused-prop-types */
import React from "react";
import classNames from "classnames";
import { Collapse, IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CollapsibleTitleStyles from "./CollapsibleTitle.styles";
import Data from "../data/Data";

interface CollapsableTitleProps {
  icon: React.ReactElement;
  title: string;
  subtitleContent?: React.ReactElement;
  hasSeparator?: boolean;
  handleOpen: () => void;
  open: boolean;
  testId?: string;
  hasError?: boolean;
  refreshButton?: React.ReactNode;
  alternativeTitleStyle?: string;
  isRedesign?: boolean;
}

const CollapsibleTitle: React.FC<CollapsableTitleProps> = (
  CollapsableTitleProps,
) => {
  const {
    icon,
    title,
    subtitleContent,
    hasSeparator,
    handleOpen,
    open,
    testId,
    hasError,
    refreshButton,
    children,
    alternativeTitleStyle,
    isRedesign,
  } = CollapsableTitleProps;

  const classes = CollapsibleTitleStyles({ isRedesign });

  return (
    <div className={isRedesign ? classes.containerRedesign : classes.container}>
      <div className={classes.CollapsibleTitleContainer}>
        <div
          className={classes.titleContainer}
          data-testid="collapsibleTitle-icon-and-title-container"
        >
          {icon}

          <Data
            value={title}
            className={alternativeTitleStyle || classes.title}
            testId="typography-title"
            hasTooltip
          />
        </div>

        {!hasError && refreshButton}

        <IconButton
          onClick={handleOpen}
          data-testid={testId}
          className={classNames(classes.expand, {
            [classes.expandOpen]: open,
          })}
        >
          <KeyboardArrowDownIcon className={classes.icon} />
        </IconButton>
      </div>

      {subtitleContent}

      {hasSeparator && (
        <div
          className={classes.separator}
          data-testid="collapsible-title-separator"
        />
      )}

      <Collapse
        classes={{ wrapperInner: classes.childrenContainer }}
        unmountOnExit
        in={open}
        timeout="auto"
      >
        {children}
      </Collapse>
    </div>
  );
};

export default CollapsibleTitle;

CollapsibleTitle.defaultProps = {
  subtitleContent: <></>,
  hasSeparator: true,
  testId: "",
  hasError: false,
  refreshButton: <></>,
};
