import React from "react";
import { Typography } from "@material-ui/core";
import HappyFaceIcon from "../../../../../../../../assets/phoneRanking/scoreIcons/HappyFaceIcon";
import MediumFaceIcon from "../../../../../../../../assets/phoneRanking/scoreIcons/MediumFaceIcon";
import SadFaceIcon from "../../../../../../../../assets/phoneRanking/scoreIcons/SadFaceIcon";
import { CustomBodyCell, RankTableStyles } from "../../RankTableStyles.styles";

interface ScoreCellProps {
  score: number;
}

const ScoreCell = ({ score }: ScoreCellProps): JSX.Element => {
  const classes = RankTableStyles();

  const renderScore = () => {
    if (score === null || score === undefined) {
      return <Typography data-testid="score-typography">-</Typography>;
    }
    if (score > 0) {
      return <HappyFaceIcon data-testid="score-happy" />;
    }
    if (score <= -10) {
      return <SadFaceIcon data-testid="score-sad" />;
    }

    return <MediumFaceIcon data-testid="score-medium" />;
  };

  return (
    <CustomBodyCell>
      <div className={classes.icons}>{renderScore()}</div>
    </CustomBodyCell>
  );
};

export default ScoreCell;
