import React from "react";
import Data from "../data/Data";
import { ProductCardV3Styles } from "../ProductCardV3/ProductCardV3Styles";

interface Props {
  renderNewItemPriceNormalDiscount: boolean;
  productPrice: number;
  sku: string;
  discount: number | undefined;
  showPromo: boolean;
  showPromoPrice: boolean;
  originalPrice: number | undefined;
}

const ItemPriceNormalDiscount: React.FC<Props> = ({
  renderNewItemPriceNormalDiscount,
  productPrice,
  sku,
  showPromo,
  discount,
  showPromoPrice,
  originalPrice,
}) => {
  const classes = ProductCardV3Styles();
  const itemPriceNormalDiscount = showPromoPrice
    ? discount || productPrice
    : productPrice;

  const valueNewPriceNormalDiscount =
    productPrice != null ? productPrice : originalPrice;

  if (renderNewItemPriceNormalDiscount) {
    return (
      <Data
        type="currency"
        className={
          productPrice != null
            ? classes.recommendedCardInformationPriceWithPromo
            : classes.recommendedCardInformationPrice
        }
        value={valueNewPriceNormalDiscount}
        testId={`${sku}-price-value`}
      />
    );
  }

  return (
    <Data
      type="currency"
      className={
        showPromo
          ? classes.recommendedCardInformationPriceWithPromo
          : classes.recommendedCardInformationPrice
      }
      value={itemPriceNormalDiscount}
      testId={`${sku}-price-value`}
    />
  );
};

export default ItemPriceNormalDiscount;
