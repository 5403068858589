import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette, breakpoints }) =>
  createStyles({
    row: {
      height: 48,
      borderBottomColor: palette.grey[100],
    },
    tableContainer: {
      boxShadow: "none",
      borderRadius: "0px",
      backgroundColor: palette.common.white,
      overflow: "auto",
      padding: "0px 0px 4px 0px",

      "&::-webkit-scrollbar": {
        height: "8px",
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#DBDADA",
        borderRadius: "10px",
      },
    },
    tableBaseText: {
      color: "#0000008c",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
      letterSpacing: 0,
      display: "block",
    },
    tableItemTitleText: {
      color: "#141414",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    tableHeaderText: {
      fontWeight: 600,
    },
    tableFooterText: {
      fontWeight: 600,
      color: "#FFFFFF",
    },
    redText: {
      color: "#C9201D",
      fontWeight: 700,
    },
    smallRedText: {
      color: "#C9201D",
      fontWeight: 400,
    },
    greenText: {
      color: "#007A78",
      fontWeight: 700,
    },
    tableItemCell: {
      padding: "4px 8px",
      background: "#FAFAFA",
      height: "44px",
      fontWeight: 400,
      borderBottom: "1px solid #DEDEDE",
    },
    tableHeaderCell: {
      padding: "8px",
      background: "#FFFFFF",
      height: "18px",
      lineHeight: "18px",
      border: 0,
      boxShadow: "8px 0px 8px 0px rgba(0, 0, 0, 0.25)",

      "& svg": {
        opacity: "1 !important",
        color: "#999999",
      },
      "&:hover svg": {
        color: "#141414",
      },
    },
    tableHeaderCellActive: {
      "& svg": {
        color: "#141414 !important",
      },
    },
    tableFooterCell: {
      color: "#ffffff",
      border: 0,
      backgroundColor: "#343434",
      height: "24px",
      padding: 0,
      marginRight: "5px",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tag: {
      paddingLeft: "5px",
      display: "flex",
      alignItems: "center",
    },
    containerSubTextTag: {
      display: "flex",
      flexWrap: "wrap",
      [breakpoints.down(1440)]: {
        maxWidth: "250px",
      },
      [breakpoints.up(1440)]: {
        maxWidth: "320px",
      },
      [breakpoints.up(1800)]: {
        maxWidth: "400px",
      },
    },
  }),
);
