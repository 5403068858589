import * as React from "react";

function QuickOrder() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stopColor="#F5F338" offset="0%" />
          <stop stopColor="#EEEB00" offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="BEES-Grow---customer-page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Customer-page---no-last-contacts"
          transform="translate(-1050.000000, -404.000000)"
        >
          <g id="Group-12" transform="translate(1035.000000, 258.000000)">
            <g id="Group" transform="translate(0.000000, 130.000000)">
              <g transform="translate(15.678832, 16.000000)" id="quick-order">
                <ellipse
                  id="Oval"
                  fill="url(#linearGradient-1)"
                  cx="24.4948905"
                  cy="25"
                  rx="24.4948905"
                  ry="25"
                />
                <g id="add-to-basket" fillRule="nonzero">
                  <path
                    d="M27.4307792,42.5672997 L10.9116869,42.5672997 C9.40496707,42.5672997 7.19430752,41.493083 7.02781914,39.9647657 L6.69608625,20.8887287 L12.8062099,17.1677399 L34.5438519,17.1677399 L45.4389857,20.8887287 L44.1766401,32.4769873 L32.4288753,32.8866545 L27.4307792,42.5672997 Z"
                    id="Path"
                    fill="#FFB042"
                  />
                  <path
                    d="M8.76513499,39.8827346 L6.29067747,17.1678376 L3.14538657,17.1678376 L5.61974842,39.8827346 C5.7862368,41.4111496 7.05202693,42.5672997 8.5587468,42.5672997 L11.7041334,42.5672997 C10.1974135,42.5672997 8.93162337,41.4110519 8.76513499,39.8827346 L8.76513499,39.8827346 Z"
                    id="Path"
                    fill="#F4A73B"
                  />
                  <path
                    d="M47.0760258,20.8887287 L4.55068249,20.8887287 C3.4937683,20.8887287 1.61130137,20.0143164 1.61130137,18.9356075 L1.24387873,15.82243 C1.24387873,14.7437212 3.36009918,13.8693088 4.41691768,13.8693088 L47.0760258,13.8693088 C48.1329399,13.8693088 48.9896853,14.7437212 48.9896853,15.82243 L48.9896853,18.9357052 C48.9896853,20.0143164 48.1329399,20.8887287 47.0760258,20.8887287 Z"
                    id="Path"
                    fill="#E69D37"
                  />
                  <path
                    d="M3.14538657,18.8767209 L3.14538657,15.8814143 C3.14538657,14.770186 4.02806206,13.8693088 5.11683868,13.8693088 L1.9714521,13.8693088 C0.882675483,13.8693088 0,14.7700883 0,15.8813166 L0,18.8766233 C0,19.9878516 0.882675483,20.8887287 1.9714521,20.8887287 L5.11683868,20.8887287 C4.02796637,20.8887287 3.14538657,19.9879492 3.14538657,18.8767209 Z"
                    id="Path"
                    fill="#D98D24"
                  />
                  <path
                    d="M24.6624792,38.5314677 L24.3272061,38.5314677 C23.2702919,38.5314677 22.4135465,37.6570554 22.4135465,36.5783465 L22.4135465,26.6406706 C22.4135465,25.5619618 23.2702919,24.6875494 24.3272061,24.6875494 L24.6624792,24.6875494 C25.7193934,24.6875494 26.5761388,25.5619618 26.5761388,26.6406706 L26.5761388,36.5783465 C26.5761388,37.6570554 25.7193934,38.5314677 24.6624792,38.5314677 Z"
                    id="Path"
                    fill="#F49751"
                  />
                  <path
                    d="M15.2112973,38.5314677 L14.8760241,38.5314677 C13.81911,38.5314677 12.9623646,37.6570554 12.9623646,36.5783465 L12.9623646,26.6406706 C12.9623646,25.5619618 13.81911,24.6875494 14.8760241,24.6875494 L15.2112973,24.6875494 C16.2682115,24.6875494 17.1249569,25.5619618 17.1249569,26.6406706 L17.1249569,36.5783465 C17.1249569,37.6570554 16.2681158,38.5314677 15.2112973,38.5314677 Z"
                    id="Path"
                    fill="#F49751"
                  />
                  <path
                    d="M43.8548583,35.4304972 L44.1765444,32.477085 C42.4531026,31.2766967 40.3718065,30.5737684 38.132442,30.5737684 C37.4121405,30.5737684 36.7082009,30.6464245 36.0271294,30.7849985 C35.3048186,30.9319708 34.9284975,33.5604813 34.2657971,33.8475901 C33.5261677,34.1679997 32.5084835,32.1633161 31.86425,32.636948 C29.1523074,34.6304988 27.3834161,37.8805901 27.3834161,41.5444501 C27.3834161,41.8893713 27.3998736,42.2304839 27.4304922,42.5673973 L29.8476356,42.5673973 L36.3786687,35.3710247 L43.8548583,35.4304972 Z"
                    id="Path"
                    fill="#FFA95F"
                  />
                  <path
                    d="M33.9461203,33.2382163 L33.0718649,33.6132156 L31.8647285,32.6373386 L31.8647285,26.6406706 C31.8647285,25.5619618 32.7214739,24.6875494 33.778388,24.6875494 L34.1136612,24.6875494 C35.1705754,24.6875494 36.0273208,25.5619618 36.0273208,26.6406706 L36.0273208,30.7855844 L33.9461203,33.2382163 Z"
                    id="Path"
                    fill="#F49751"
                  />
                  <path
                    d="M33.8361806,38.5315654 L34.0559644,38.5315654 C35.144741,38.5315654 36.0274165,37.6306882 36.0274165,36.5194599 L36.0274165,30.7855844 C34.4988809,31.096619 33.0862174,31.7394888 31.8648241,32.6373386 L31.8648241,36.5194599 C31.8647285,37.6306882 32.7474039,38.5315654 33.8361806,38.5315654 L33.8361806,38.5315654 Z"
                    id="Path"
                    fill="#E09322"
                  />
                  <path
                    d="M41.6722338,16.3370775 C41.8567106,17.2488921 42.7304876,17.835512 43.6238795,17.6472312 C44.5172715,17.4589503 45.0920392,16.5671551 44.9075624,15.6553405 L42.0128652,1.3450169 C41.8544142,0.561815309 41.178701,0 40.3951531,0 C39.3486684,0 38.5658859,0.980662147 38.7775367,2.0266562 L41.6722338,16.3370775 Z"
                    id="Path"
                    fill="#707372"
                  />
                  <path
                    d="M4.08212294,15.6553405 C3.89984687,16.5566083 4.45920956,17.4383448 5.33499587,17.6403952 C6.23805183,17.8487933 7.13900276,17.2192046 7.32625435,16.2938158 L10.2121487,2.02675385 C10.4237038,0.980662147 9.64101698,1.38777607e-15 8.59453224,1.38777607e-15 C7.81098432,1.38777607e-15 7.13527112,0.561815309 6.97691579,1.3450169 L4.08212294,15.6553405 Z"
                    id="Path"
                    fill="#707372"
                  />
                  <path
                    d="M38.1326334,33.1796227 C33.557169,33.1796227 30.6750063,36.8745374 30.6750063,41.5443525 C30.6750063,46.2141676 33.5570733,49.8963869 38.1326334,49.8963869 C38.4499181,49.8963869 38.7628972,49.9817383 39.0709007,49.9462892 C43.2046924,49.4707042 46.4173441,45.8904377 46.4173441,41.5443525 C46.4173441,37.2044195 43.2137823,33.628157 39.0885063,33.1444665 C38.7747618,33.1077478 38.4558505,33.1796227 38.1326334,33.1796227 L38.1326334,33.1796227 Z"
                    id="Path"
                    fill="#FF6F71"
                  />
                  <path
                    d="M32.0622181,41.5443525 C32.0622181,37.257935 35.1880854,33.7187818 39.2397811,33.1662438 C38.8774297,33.1168298 38.5082848,33.0889002 38.1326334,33.0889002 C33.557169,33.0889002 29.8479226,36.8745374 29.8479226,41.5444501 C29.8479226,46.2142652 33.5570733,50 38.1326334,50 C38.5082848,50 38.877334,49.9720704 39.2397811,49.9226564 C35.1880854,49.3699231 32.0622181,45.8308675 32.0622181,41.5443525 L32.0622181,41.5443525 Z"
                    id="Path"
                    fill="#F03D40"
                  />
                  <path
                    d="M42.1906442,40.7898617 L38.8718801,40.7898617 L38.8718801,37.402759 C38.8718801,36.9860606 38.5409126,36.6482683 38.1326334,36.6482683 C37.7243541,36.6482683 37.3933867,36.9860606 37.3933867,37.402759 L37.3933867,40.7898617 L34.0746225,40.7898617 C33.6662476,40.7898617 33.3353758,41.127654 33.3353758,41.5443525 C33.3353758,41.9610509 33.6663433,42.2988432 34.0746225,42.2988432 L37.393291,42.2988432 L37.393291,45.6860436 C37.393291,46.102742 37.7242584,46.4405343 38.1325377,46.4405343 C38.540817,46.4405343 38.8717844,46.102742 38.8717844,45.6860436 L38.8717844,42.2988432 L42.1905485,42.2988432 C42.5989235,42.2988432 42.9297952,41.9610509 42.9297952,41.5443525 C42.9297952,41.127654 42.5989235,40.7898617 42.1906442,40.7898617 L42.1906442,40.7898617 Z"
                    id="Path"
                    fill="#ECEAEC"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default QuickOrder;
