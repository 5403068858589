import React from "react";

const PercetegeIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g transform="translate(-168 -638) translate(135 592) translate(33 46)">
              <circle cx="19" cy="19" r="18" stroke="#505050" strokeWidth="2" />
              <path
                fill="#505050"
                fillRule="nonzero"
                d="M14.98 18.264c1.204 0 2.16-.333 2.868-1 .708-.668 1.062-1.551 1.062-2.65v-.879c0-1.147-.354-2.057-1.062-2.728C17.14 10.336 16.176 10 14.955 10c-1.204 0-2.165.338-2.88 1.013-.717.676-1.075 1.563-1.075 2.661v.867c0 1.156.364 2.065 1.093 2.728.728.664 1.69.995 2.886.995zm.78 8.435l8.68-13.891-1.917-.965-8.679 13.892 1.917.964zm-.78-10.657c-.416 0-.741-.132-.977-.396-.236-.265-.354-.609-.354-1.032V13.7c0-.456.118-.816.354-1.08.236-.265.553-.397.952-.397.415 0 .737.136.964.409.228.272.342.62.342 1.043v.733c0 1.09-.427 1.635-1.282 1.635zm8.447 12.232c1.212 0 2.173-.334 2.88-1.001.709-.667 1.063-1.554 1.063-2.661v-.855c0-1.18-.358-2.1-1.075-2.758-.716-.66-1.68-.99-2.893-.99-1.196 0-2.154.334-2.874 1.002-.72.667-1.08 1.554-1.08 2.66v.88c0 1.155.363 2.065 1.092 2.728.728.663 1.69.995 2.887.995zm0-2.222c-.375 0-.692-.144-.952-.433-.26-.289-.391-.625-.391-1.007V23.61c.016-.423.142-.759.378-1.007.236-.248.55-.373.94-.373.407 0 .729.133.965.397.236.265.354.613.354 1.044l-.013 1.257c-.065.749-.492 1.123-1.281 1.123z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PercetegeIcon;
