import UpdateCartSimulationResponse from "services/cart/updateCartSimulation/domains/UpdateCartSimulationResponse";
import { Empties, EmptyItem } from "../../domains/cart/Empties";
import CartSimulationResponse from "../../services/cartSimulation/domains/CartSimulationResponse";

export const getEmpties = (
  data: CartSimulationResponse | UpdateCartSimulationResponse,
): Empties => {
  return {
    totalAmount: data.empties?.totalExpiredAmount,
    extraAmount: data.empties?.totalExpiredAmount,
    minimumRequired: data.empties?.totalMinimumQuantity,
    defaultValue: data.empties?.totalMinimumQuantity,
    currentValue: data.empties?.totalBalance,
    emptiesList: data.empties?.emptiesList?.map(
      (empty) =>
        <EmptyItem>{
          emptiesBalance: empty.balance,
          emptiesName: empty.name,
          expiredAmount: empty.expiredAmount,
          extraAmount: empty.expiredAmount,
          minimumRequired: empty.minimumQuantity,
          quantity: empty.quantity,
          groupId: empty.id,
        },
    ),
  };
};
