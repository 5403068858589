import { makeStyles, createStyles } from "@material-ui/core/styles";

const styles = makeStyles(({ palette }) =>
  createStyles({
    container: {
      boxSizing: "border-box",
      width: "370px",
      height: "auto",
      maxHeight: "708px",
      overflowY: "auto",
      padding: "32px",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontSize: "16px",
      fontWeight: "bold",
      color: palette.text.primary,
      paddingBottom: "8px",
      marginBottom: "16px",
      borderBottom: `1px solid ${palette.grey[200]}`,
    },
    buttonsContainer: {
      display: "flex",
      width: "100%",
      padding: "0",
      marginTop: "20px",
    },
    button: {
      height: "40px",
      fontSize: "14px",
      fontWeight: 600,
      display: "flex",
      flex: 1,
    },
    cancelButton: {
      backgroundColor: palette.grey[100],
      color: palette.text.secondary,
      fontWeight: "bold",
      marginRight: "5px",
    },
    goPocButton: {
      backgroundColor: palette.text.primary,
      color: palette.common.white,
      margingLeft: "5px",
    },
    fieldTitle: {
      fontSize: "12px",
      fontWeight: "bold",
      color: "#000000",
    },
    fieldValue: {
      fontSize: "14px",
      color: "#505050",
    },
    fieldContainer: {
      marginTop: "18px",
    },
    pocId: {
      color: "#7a7a7a",
      fontSize: "12px",
      fontWeight: "bold",
    },
  }),
);

export default styles;
