export interface WebsocketEventData {
  length?: number;
  comando?: string;
  numero?: string;
  phone?: string;
  // eslint-disable-next-line camelcase
  poc_id?: string;
  gid?: string;
  ani?: string;
  dnis?: string;
  evento?: string;
  status?: WebSocketStatus;
  isTyped?: boolean | null;
  keepAlive?: string | null;
  // eslint-disable-next-line camelcase
  vendor_id?: string | null;
}

export enum WebSocketStatus {
  DONE = "done",
  HEADERS_RECEIVED = "headers_received",
  LOADING = "loading",
  OPENED = "opened",
  UNSENT = "unsent",
  CLOSE = "close",
  ERROR = "error",
}
