import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    row: {
      marginTop: "8px",
      display: "flex",
      gap: "8px",
      flexWrap: "wrap",
      minWidth: "650px",
    },
    container: {
      marginTop: "8px",
      display: "grid",
      gridTemplateColumns: "24% 24% 24% 24%",
      gap: "8px 1.4%",
      minWidth: "650px",
    },
    content: {
      backgroundColor: "#FFFFFF",
      borderRadius: "24px",
      boxShadow: "0 4px 16px 0 rgba(0,0,0,0.16)",
      height: "500px",
      marginTop: "24px",
      marginRight: "16px",
    },
    centralizedContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: "2rem",
    },
    noDataAvailable: {
      fontSize: "16px",
      color: palette.secondary.main,
      paddingBottom: "14px",
      textAlign: "center",
    },
    containerError: {
      border: "1px solid #e1e1e1",
      borderRadius: 8,
      padding: 20,
      background: "#fff",
    },
  }),
);
