import { useLogService } from "admin-portal-shared-services";
import qs from "qs";

import axios from "@config/axios/axiosInstance";

import {
  GROW_SEARCH_BY_POC_FULFILLMENT_VALIDATION,
  GROW_SEARCH_BY_POC_WITH_CUSTOMER_ACCOUNT_ID,
  isFeatureEnabledV2,
} from "../../config/featureToggles";
import { TLP_ACCOUNT_SERVICE, GROW_BFF_SERVICE } from "../../config/services";
import {
  getCountryByToken,
  howIsAgent,
  howIsAgentCustomerAccount,
} from "../../config/utils/functions";
import {
  AccountsResponseToSearchedPocConverter,
  AccountsResponseToSearchedPocConverterV2,
} from "../../converters/AccountsResponseToSearchedPocConverter";
import { SearchedPoc } from "../../domains/PocInformation";
import { getEndPoint, getApiHost as getApiB2b } from "../host/HostB2BService";
import { getApiHost } from "../host/HostService";
import { AccountsResponse } from "./domains/AccountsResponse";
import SearchPocRequest from "./domains/SearchPocRequest";

const POCS_ENDPOINT = "/accounts/";
const FULFILLMENT_VALIDATION_POC_ENDPOINT = "/tlp-account/accounts";
const INFO = "/info";

export const searchPocByAccountId = (
  searchPoc: SearchPocRequest,
): Promise<SearchedPoc> => {
  const log = useLogService();
  const isSearchByPocFulfillmentValidationEnable = isFeatureEnabledV2(
    GROW_SEARCH_BY_POC_FULFILLMENT_VALIDATION,
    getCountryByToken(),
  );
  const isSearchByPocCustomerAccountIdEnable = isFeatureEnabledV2(
    GROW_SEARCH_BY_POC_WITH_CUSTOMER_ACCOUNT_ID,
    getCountryByToken(),
  );

  const endpoint = isSearchByPocFulfillmentValidationEnable
    ? FULFILLMENT_VALIDATION_POC_ENDPOINT
    : POCS_ENDPOINT;

  const apiHost = isSearchByPocFulfillmentValidationEnable
    ? getApiHost(TLP_ACCOUNT_SERVICE)
    : getApiB2b() + getEndPoint();
  return axios
    .get<Array<AccountsResponse>>(`${apiHost}${endpoint}`, {
      params: isSearchByPocCustomerAccountIdEnable
        ? howIsAgentCustomerAccount(searchPoc.accountId, searchPoc.vendorId)
        : howIsAgent(searchPoc.accountId, searchPoc.vendorId),

      paramsSerializer: /* istanbul ignore next */ (params) => {
        return qs.stringify(params);
      },
    })
    .then((response) => {
      log.info(
        `Get accounts by accountid were received from ${POCS_ENDPOINT} to our model`,
      );
      return AccountsResponseToSearchedPocConverter(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
};

export const searchPocByAccountIdV2 = (
  searchPoc: SearchPocRequest,
): Promise<SearchedPoc> => {
  const log = useLogService();

  const endpoint = `${getApiHost(GROW_BFF_SERVICE)}${POCS_ENDPOINT}${
    searchPoc.accountId
  }${INFO}`;

  return axios
    .get<AccountsResponse>(`${endpoint}`, {
      headers: { vendorId: searchPoc.vendorId },
    })
    .then((response) => {
      log.info(
        `Get account info by accountid received from ${POCS_ENDPOINT} to our model`,
      );
      return AccountsResponseToSearchedPocConverterV2(response.data);
    })
    .catch((error) => {
      log.error(error);
      throw error;
    });
};
