import { createStyles, makeStyles } from "@material-ui/core";

const B2bLoginInformationStyles = makeStyles(() =>
  createStyles({
    gridLogin: {
      marginTop: "16px",
    },
    loginInformationBox: {
      display: "flex",
      flexDirection: "column",
      height: "240px",
      width: "250px",
      borderRadius: "5px",
      margin: "5px 20px 10px 0",
      minWidth: "211px",
    },
    headerTitle: {
      display: "flex",
      width: "310px",
    },
    labelTitle: {
      height: "21px",
      margin: "3px 52px 10px 16px",
      fontFamily: "Work Sans",
      fontSize: "18px",
      color: "#505050",
      fontWeight: "bold",
    },
    divider: {
      height: "1px",
      width: "250px",
      backgroundColor: "#585858",
      margin: "8px 0px 16px",
    },
    labelUser: {
      width: "200px",
      height: "14px",
      margin: "16px 17px 8px 0",
      fontFamily: "Work Sans",
      fontSize: "14px",
      color: "#505050",
      fontWeight: "bold",
    },
    valueLogin: {
      display: "flex",
      paddingTop: "4px",
      width: "250px",
      fontSize: "15px",
    },
    copyIcon: {
      paddingLeft: "17px",
      marginBottom: "4px",
    },
    gridPassword: {
      marginTop: "18px",
    },
    labelPassword: {
      width: "200px",
      height: "14px",
      margin: "23px 17px 8px 0",
      fontFamily: "Work Sans",
      fontSize: "14px",
      color: "#505050",
      fontWeight: "bold",
      marginTop: "18px",
    },
    valuePassword: {
      display: "flex",
      paddingTop: "4px",
      width: "100px",
      alignItems: "center",
      fontSize: "20px",
      marginTop: "-5px",
    },
  }),
);

export default B2bLoginInformationStyles;
