import React from "react";
import { Box } from "@material-ui/core";
import { Paragraph } from "@hexa-ui/components";
import SkuClipBoardButton from "../../../../../components/skuClipBoardButton/SkuClipBoardButton";
import style from "./PromotionCardItem.styles";
import { FormatedItem } from "../../../../../domains/promotions/FormatedPromotion";

interface PromotionCardItemProps {
  item: FormatedItem;
}

const maxCharactersTitle = 60;

export default function PromotionCardItem({
  item,
}: PromotionCardItemProps): JSX.Element {
  const classes = style();

  const formatTitleExhibition = (title: string): string => {
    const formattedTitle =
      title?.length > maxCharactersTitle
        ? `${title?.substring(0, maxCharactersTitle)}...`
        : title;

    return formattedTitle;
  };

  return (
    <div>
      <div>
        <Paragraph
          className={classes.title}
          data-testid="promotion-card-item-title"
        >
          {formatTitleExhibition(item.title)}
        </Paragraph>
      </div>
      <Box className={classes.containerDescription}>
        <div>
          <span
            className={classes.description}
            data-testid="promotion-card-item-sku"
          >
            <SkuClipBoardButton item={item} />
          </span>
        </div>
      </Box>
    </div>
  );
}
