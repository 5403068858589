import { createEffect, createEvent } from "effector";
import { Mission } from "../../domains/Mission";
import {
  LocalMissionList,
  SponsoredMission,
} from "../../domains/sponsored/SponsoredMission";
import {
  MISSION_TYPES,
  FILTERED_GLOBAL_CALL_LIST,
} from "../../config/constants";
import { missionTypesByTag } from "../../domains/missions/MissionTypesByTag";

interface EffectiveMissionTypes {
  type: string;
  tag: string;
}
const getEffectiveMission = (
  mission: SponsoredMission | undefined,
  effectiveTypes: EffectiveMissionTypes,
) => {
  return <Mission>{
    tag: effectiveTypes.tag,
    type: effectiveTypes.type,
    typification: "",
    reason: "",
    comments: "",
    effectiveness: false,
    missionId: mission?.id,
    missionTitle: mission?.missionTitle,
  };
};
export const getEffectiveMissions = (list: LocalMissionList): Mission[] => {
  const effectiveMissions: Mission[] = [];
  if (list?.sponsoredMissions && list?.sponsoredMissions?.length > 0) {
    list.sponsoredMissions.forEach((sponsoredMission) => {
      effectiveMissions.push(
        getEffectiveMission(sponsoredMission, {
          type: "sponsored",
          tag: "#LocalMissions",
        }),
      );
    });
  }
  if (list?.localMissions && list?.localMissions?.length > 0) {
    let typesAux: EffectiveMissionTypes = {
      type: "local",
      tag: "#LocalMissions",
    };
    list.localMissions.forEach((localMission) => {
      if (localMission.missionType === MISSION_TYPES.FLEXIBLE) {
        typesAux = {
          type: "global",
          tag: "#flexible_missions",
        };
      }
      effectiveMissions.push(getEffectiveMission(localMission, typesAux));
    });
  }

  if (
    list?.globalCallListMissions &&
    list?.globalCallListMissions?.length > 0
  ) {
    const filteredWithoutLocalMissions = list.globalCallListMissions.filter(
      (mission) => {
        return (
          mission !== FILTERED_GLOBAL_CALL_LIST.FLEXIBLE_MISSIONS &&
          mission !== FILTERED_GLOBAL_CALL_LIST.LOCAL_MISSIONS &&
          mission !== FILTERED_GLOBAL_CALL_LIST.SPONSORED
        );
      },
    ) as Array<string>;

    filteredWithoutLocalMissions?.forEach((globalTag: string) => {
      effectiveMissions.push(
        getEffectiveMission(undefined, {
          type: missionTypesByTag[globalTag],
          tag: globalTag,
        }),
      );
    });
  }

  return effectiveMissions;
};

export const setEffectiveMissionsEvent = createEffect({
  async handler(effectiveMissions: Mission[]) {
    return effectiveMissions;
  },
});

export const changeEffectivenessByMissionEvent = createEvent<string>(
  "changeEffectivenessByMissionEvent",
);

export const resetEffectiveMissionsEvent = createEvent(
  "resetEffectiveMissionsEvent",
);
