import React, { ReactNode, FunctionComponent } from "react";
import TableHeadBase from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

interface Props {
  children: ReactNode;
  testId: string;
}

const TableListHead: FunctionComponent<Props> = ({ children, testId }) => {
  return (
    <TableHeadBase data-testid={`${testId}-table-head`}>
      <TableRow>{children}</TableRow>
    </TableHeadBase>
  );
};

export default TableListHead;
