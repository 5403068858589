import { getUserInfos } from "@config/utils/functions";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import { useStore } from "effector-react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentMethodInfoStore as IPaymentMethod } from "@domains/paymentMethodInfo/PaymentMethodInfo";
import Button from "../../../../components/button/Button";
import Select from "../../../../components/select/Select";
import {
  TLP_PAYMENT_VENDOR,
  isFeatureEnabledV2,
} from "../../../../config/featureToggles";
import AgentCallStore from "../../../../stores/agentCall/AgentCallStore";
import CartItemStore from "../../../../stores/cart/CartItemStore";
import GlobalAdminConfigStore from "../../../../stores/globaAdminConfig/GlobalAdminConfigStore";
import GlobalStore from "../../../../stores/global/GlobalStore";
import { getPaymentMethodInformation } from "../../../../stores/paymentsInformation/PaymentMethodInfoEvents";
import PaymentMethodInfoStore from "../../../../stores/paymentsInformation/PaymentMethodInfoStore";
import BeeLoading from "../beeLoading/BeeLoading";
import { useUpdateCartHook } from "../orderCart/hooks/useUpdateCartHook";
import PaymentMethodStyles from "./PaymentMethodStyles";
import { paymentMethodsEnum, paymentMethodsLabels } from "./PaymentMethodsEnum";

interface Options {
  label: string;
  value: number | string;
  vendorPaymentMethod?: string;
  paymentTerm?: number;
}

interface Props {
  handleFormChange: (
    cartFormChanges: Record<string | number, string | number>,
  ) => void;
  paymentMethod: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getLabel = (
  element: string,
  paymentMethodInfo: IPaymentMethod,
  t: typeof useTranslation,
) => {
  if (element !== paymentMethodsEnum.BANK_SLIP)
    return t(paymentMethodsLabels[element]);

  const isOnlyOneBankSlip =
    paymentMethodInfo?.groupedPaymentMethodInfos[paymentMethodsEnum.BANK_SLIP]
      ?.length === 1 &&
    !paymentMethodInfo?.groupedPaymentMethodInfos[
      paymentMethodsEnum.BANK_SLIP
    ][0]?.paymentTerm;

  if (isOnlyOneBankSlip)
    return t(
      paymentMethodsLabels[
        paymentMethodInfo?.groupedPaymentMethodInfos[
          paymentMethodsEnum.BANK_SLIP
        ][0]?.vendorPaymentMethod
      ],
    );

  return t(paymentMethodsLabels[element]);
};

const PaymentMethod: React.FunctionComponent<Props> = ({
  handleFormChange,
  paymentMethod,
}) => {
  const { t } = useTranslation();
  const classes = PaymentMethodStyles();
  const { updateCart } = useUpdateCartHook();
  const { user } = useStore(GlobalStore);
  const { userConfig } = useStore(GlobalAdminConfigStore);
  const { paymentMethodInfo, done, isLoading, error } = useStore(
    PaymentMethodInfoStore,
  );
  const { clientId } = useStore(AgentCallStore);
  const CartItemStates = useStore(CartItemStore);

  const { vendorId } = getUserInfos();

  const [valueMethod, setValueMethod] = useState("");
  const [paymentTermError, setPaymentTermError] = useState("");
  const [paymentTermTouched, setPaymentTermTouched] = useState(false);
  const [paymentTermSelected, setPaymentTermSelected] = useState<
    string | number
  >(0);

  const paymentMethodsPix = [
    paymentMethodsEnum.GPA_PIX,
    paymentMethodsEnum.PIX_AT_DELIVERY,
  ];

  const isPaymentVendor = isFeatureEnabledV2(
    TLP_PAYMENT_VENDOR,
    user.keyToggle,
  );

  useEffect(() => {
    const paymentMethods = paymentMethodInfo?.paymentMethods;

    let updatedValueMethod = valueMethod;

    if (
      isPaymentVendor &&
      paymentMethods &&
      paymentMethods.includes(paymentMethodsEnum.BANK_SLIP)
    ) {
      updatedValueMethod = paymentMethodsEnum.BANK_SLIP;
      setValueMethod(updatedValueMethod);
      handleFormChange({
        paymentMethod: updatedValueMethod,
        paymentTerm: 0,
        paymentMethodCode: "",
      });
      setPaymentTermSelected(0);
    } else {
      updatedValueMethod = paymentMethod;
      setValueMethod(paymentMethod);
      handleFormChange({
        paymentMethod: updatedValueMethod,
        paymentTerm: 0,
        paymentMethodCode: "",
      });
      setPaymentTermSelected(0);
    }

    if (
      isPaymentVendor &&
      done &&
      !!paymentMethodInfo?.groupedPaymentMethodInfos
    ) {
      const allPaymentMethodInfos =
        paymentMethodInfo.groupedPaymentMethodInfos[updatedValueMethod];
      if (allPaymentMethodInfos?.length === 1) {
        const { paymentTerm, vendorPaymentMethod } = allPaymentMethodInfos[0];
        handleFormChange({
          paymentMethod: updatedValueMethod,
          paymentTerm,
          paymentMethodCode: vendorPaymentMethod,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentVendor, done, paymentMethodInfo]);

  /* istanbul ignore next */
  const retryAction = useCallback(() => {
    getPaymentMethodInformation({
      accountId: clientId,
      vendorId,
    });
  }, [clientId, vendorId, userConfig.vendorId]);

  const isBankSlip = useCallback(() => {
    const isOnlyOneBankSlip =
      paymentMethodInfo?.groupedPaymentMethodInfos[paymentMethodsEnum.BANK_SLIP]
        ?.length === 1 &&
      !paymentMethodInfo?.groupedPaymentMethodInfos[
        paymentMethodsEnum.BANK_SLIP
      ][0]?.paymentTerm;

    if (isOnlyOneBankSlip) return false;

    const paymentMethodsIncludesBankSlip =
      paymentMethodInfo?.paymentMethods?.includes(paymentMethodsEnum.BANK_SLIP);

    return (
      paymentMethodsIncludesBankSlip &&
      paymentMethod === paymentMethodsEnum.BANK_SLIP
    );
  }, [paymentMethod, paymentMethodInfo?.paymentMethods]);

  const isBankSlipInstallment = useCallback(() => {
    const paymentMethodsIncludesBankSlipInstallment =
      paymentMethodInfo?.paymentMethods?.includes(
        paymentMethodsEnum.BANK_SLIP_INSTALLMENT,
      );

    return (
      paymentMethodsIncludesBankSlipInstallment &&
      paymentMethod === paymentMethodsEnum.BANK_SLIP_INSTALLMENT
    );
  }, [paymentMethod, paymentMethodInfo?.paymentMethods]);

  const handleMethodChange = (value: string) => {
    let paymentMethodCode = "";
    let paymentTerm = 0;
    if (isPaymentVendor) {
      const allPaymentMethodInfos =
        paymentMethodInfo.groupedPaymentMethodInfos[value];
      if (allPaymentMethodInfos?.length >= 1) {
        paymentMethodCode = allPaymentMethodInfos[0].vendorPaymentMethod;
        paymentTerm = allPaymentMethodInfos[0].paymentTerm;
      }
    }
    handleFormChange({
      paymentMethod: value,
      paymentTerm,
      paymentMethodCode,
    });
    if (
      CartItemStates.totalItems &&
      CartItemStates.totalItems > 0 &&
      !(value === paymentMethodsEnum.BANK_SLIP) &&
      !(value === paymentMethodsEnum.BANK_SLIP_INSTALLMENT)
    ) {
      updateCart("paymentMethod", value);
    }

    setValueMethod(value);
    setPaymentTermSelected(0);
  };

  const paymentTermIsValid = useCallback(() => {
    /* istanbul ignore next */
    if (paymentMethod && isBankSlip()) {
      setPaymentTermError(t("OrderTaking.PAYMENT_TERM_REQUIRED").toString());
      setPaymentTermTouched(true);
    }

    setPaymentTermTouched(false);
    setPaymentTermError("");
  }, [t, paymentMethod, isBankSlip]);

  const getVendorPaymentMethod = (value: string, options: Array<Options>) => {
    const op = options.find((element) => element.value === value);
    return op?.vendorPaymentMethod || "";
  };

  /* istanbul ignore next */
  const getBankSlipInstallmentTerm = (
    value: string,
    options: Array<Options>,
  ): number => {
    const op = options.find((element) => element.value === value);
    return op?.paymentTerm || 19;
  };

  /* istanbul ignore next */
  const handleTermChange = (value: string, options: Array<Options>) => {
    paymentTermIsValid();
    const paymentTerm = isBankSlipInstallment()
      ? getBankSlipInstallmentTerm(value, options)
      : value;

    const paymentMethodCode = getVendorPaymentMethod(value, options);

    handleFormChange({
      paymentTerm,
      paymentMethodCode,
    });

    /* istanbul ignore next */
    if (
      CartItemStates.totalItems &&
      CartItemStates.totalItems > 0 &&
      (isBankSlip() || isBankSlipInstallment())
    ) {
      updateCart("paymentMethod", valueMethod, {
        paymentTerm: paymentTerm.toString(),
        paymentMethodCode,
      });
    }
  };

  const getClasses = () => {
    let classRadio = `${classes.radioGroupMargin}`;

    if (isBankSlip() || isBankSlipInstallment()) {
      classRadio = `${classRadio} ${classes.radioGroup}`;
    }

    return classRadio;
  };

  const getMethods = () => {
    let paymentMethods: string[];

    /* istanbul ignore next */
    paymentMethods =
      paymentMethodInfo?.paymentMethods?.filter(
        (paymentMethod) => !paymentMethodsPix.includes(paymentMethod),
      ) ?? [];

    paymentMethods = paymentMethods?.filter((paymentMethod) =>
      paymentMethodInfo.paymentMethods.includes(paymentMethod),
    );

    paymentMethods = movesPaymentMethodToLastPosition(
      paymentMethods,
      paymentMethodsEnum.BANK_SLIP,
    );

    paymentMethods = movesPaymentMethodToLastPosition(
      paymentMethods,
      paymentMethodsEnum.BANK_SLIP_INSTALLMENT,
    );

    return paymentMethods;
  };

  const movesPaymentMethodToLastPosition = (
    paymentMethods: string[],
    paymentMethod: string,
  ) => {
    const methodIndex = paymentMethods?.findIndex(
      (element) => element === paymentMethod,
    );

    if (methodIndex !== -1 && methodIndex !== paymentMethods.length - 1) {
      paymentMethods?.push(paymentMethods?.splice(methodIndex, 1)[0]);
    }

    return paymentMethods;
  };

  const getPaymentTerms = (typePayment: string) => {
    const filteredValues: Array<Options> = [
      {
        label: t("OrderTaking.PAYMENT_TERM_EMPTY"),
        value: 0,
      },
    ];

    const { groupedPaymentMethodInfos } = paymentMethodInfo;
    groupedPaymentMethodInfos[typePayment]?.forEach((paymentMethod) => {
      filteredValues.push({
        label: paymentMethod.description,
        value:
          paymentMethod.installments?.join(", ") ?? paymentMethod.paymentTerm,
        vendorPaymentMethod: paymentMethod.vendorPaymentMethod,
        paymentTerm: paymentMethod.paymentTerm,
      });
    });

    return filteredValues;
  };

  /* istanbul ignore next */
  const getClassesRadio = (element: string) => {
    if (element === paymentMethodsEnum.CREDIT_CARD_POS) {
      return `${classes.radioInput}, ${classes.radioInputExtra}`;
    }
    return classes.radioInput;
  };

  const paymentGeneric = (options: Array<Options>) => {
    /* istanbul ignore next */
    return (
      <FormControl
        error={
          /* istanbul ignore next */ !!paymentTermError && paymentTermTouched
        }
        className={classes.containerSelect}
      >
        <Select
          name="paymentTerm"
          value={paymentTermSelected}
          options={options}
          onChange={(e: React.ChangeEvent<unknown>) => {
            const selectEvent = e.target as HTMLInputElement;
            setPaymentTermSelected(selectEvent.value);
            handleTermChange(selectEvent.value, options);
          }}
          onClose={() => {
            paymentTermIsValid();
          }}
          error={paymentTermError}
          touched={paymentTermTouched}
          testId="payment-terms-select"
        />
      </FormControl>
    );
  };

  /* istanbul ignore next */
  const cleanPaymentMethods = () => {
    const { paymentMethods } = paymentMethodInfo;

    paymentMethods?.forEach((item) => {
      if (paymentMethodsLabels[item] === undefined) {
        const index = paymentMethods?.findIndex((element) => {
          return element === item;
        });
        paymentMethods?.splice(index, 1);
      }
    });
  };

  const renderComponents = () => {
    cleanPaymentMethods();
    return (
      <>
        <FormControl className={classes.container}>
          <Typography className={classes.radioTitle}>
            {t("OrderTaking.PAYMENT_METHOD")}
          </Typography>
          {error ? (
            <Button
              className={classes.retryButton}
              onClick={retryAction}
              data-testid="payment-terms-retry-button"
            >
              {t("ErrorRetry.TRY_AGAIN_BUTTON")}
            </Button>
          ) : (
            <RadioGroup
              name="paymentMethod"
              value={valueMethod}
              onChange={(e) => {
                handleMethodChange(e.target.value);
              }}
              className={getClasses()}
            >
              {getMethods()?.map((element: string) => (
                <FormControlLabel
                  key={element}
                  className={getClassesRadio(element)}
                  value={element}
                  control={
                    <Radio
                      data-testid={`radio-option-${element}`}
                      disableRipple
                      classes={{ root: classes.root }}
                      size="small"
                    />
                  }
                  label={getLabel(element, paymentMethodInfo, t)}
                />
              ))}
            </RadioGroup>
          )}
        </FormControl>
        {isBankSlip() &&
          paymentGeneric(getPaymentTerms(paymentMethodsEnum.BANK_SLIP))}
        {isBankSlipInstallment() &&
          paymentGeneric(
            getPaymentTerms(paymentMethodsEnum.BANK_SLIP_INSTALLMENT),
          )}
      </>
    );
  };

  return (
    <>
      {isLoading && <BeeLoading testid="payment-terms-loading" />}
      {done && renderComponents()}
    </>
  );
};

/* istanbul ignore next */
const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.paymentMethod === nextProps.paymentMethod;
};

export default React.memo(PaymentMethod, areEqual);
