/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import SalesStructureItemStyle from "./SalesStructureItemStyles";
import Data from "../../../../components/data/Data";
import { ANALYTICS_ROUTE_NAMES, LINK_CALL } from "../../../../config/constants";
import { errorMessageViewed } from "../../../../config/typewriter";

interface Props {
  titleId: string;
  text?: string;
  code?: number | string;
  testId: string;
  dispatchEvent: (
    event: any,
    config: Record<string, unknown>,
    optionalData?: any,
  ) => void;
}
const SalesStructureItem: React.FunctionComponent<Props> = ({
  titleId,
  text,
  code,
  testId,
  dispatchEvent,
}) => {
  const { t } = useTranslation();
  const classes = SalesStructureItemStyle();
  const title = () => {
    return t(titleId);
  };

  useEffect(() => {
    if (!text || !code) {
      dispatchEvent(
        errorMessageViewed,
        {
          error_message: "No data available",
          error_type: testId,
          screen_name: ANALYTICS_ROUTE_NAMES.get(LINK_CALL),
          screen_section: "Sales Structure",
          is_all_products: null,
          DDC: null,
          DDC_name: null,
          poc_id: null,
          total_gap: null,
          total_vol_gap: null,
        },
        { time_of_day: true, is_resumed: true },
      );
    }
  }, [dispatchEvent, testId, text, code]);

  return (
    <Grid
      container
      direction="column"
      data-testid={testId}
      className={classes.container}
    >
      <div data-testid="sales-structure-item-title" className={classes.title}>
        {title()}
      </div>
      {code && (
        <Data
          value={code}
          className={classes.code}
          testId="sales-structure-item-code"
        />
      )}
      {text ? (
        <Data
          value={text}
          className={classes.value}
          testId="sales-structure-item-text"
        />
      ) : null}
    </Grid>
  );
};
export default React.memo(SalesStructureItem);
