import * as UpdateKPIService from "../../services/agentKPI/updateKPI/UpdateKPIService";
import UpdateKPIDomain from "../../domains/agentKPI/UpdateKPIDomain";

export function execute(
  agentId: string,
  country: string,
  vendorId: string,
  vendorName: string,
): Promise<UpdateKPIDomain> {
  return UpdateKPIService.getUpdateKPI(agentId, country, vendorId, vendorName);
}
