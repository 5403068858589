import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MissionInformationStyles from "./MissionInformationStyles";

interface PropsStyle {
  marginBottom: string;
}
interface Props {
  Icon: React.FunctionComponent;
  title: string;
  description: string | React.ReactNode;
  style?: PropsStyle;
}

const MissionInformation: React.FC<Props> = ({
  Icon,
  title,
  description,
  style,
}) => {
  const classes = MissionInformationStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.container} style={style}>
      <div className={classes.iconContainer}>
        <Icon />
      </div>

      <div style={{ width: "100%" }}>
        <Typography className={classes.title}>{t(title)}</Typography>
        {typeof description === "string" ? (
          <div className={classes.descriptionContainer}>
            <Typography className={classes.description} variant="subtitle1">
              {t(description)}
            </Typography>
          </div>
        ) : (
          <span style={{ width: "95%" }}>{description}</span>
        )}
      </div>
    </Box>
  );
};

export default MissionInformation;
