import React from "react";
import { useTranslation } from "react-i18next";
import CardInfo from "../../../components/cardInfo/CardInfo";
import HeadPhoneIcon from "../../../assets/HeadPhoneIcon";
import palette from "../../../theme/Palette";

interface Props {
  target?: string | null;
  countKPI?: string;
}

function AverageHandleTime({ target, countKPI }: Props): React.ReactElement {
  const { t } = useTranslation();

  return (
    <CardInfo
      key="card-info"
      title={t("AverageHandleTime.TITLE")}
      mainLabel={t("AverageHandleTime.COMPLETED_TEXT")}
      secondaryLabel={t("AverageHandleTime.TARGET_TEXT")}
      mainValue={countKPI}
      secondaryValue={target}
      testId="card-average-handle-time"
      time
      actionButton={<HeadPhoneIcon />}
      targetColor={palette.info.contrastText}
    />
  );
}

AverageHandleTime.defaultProps = {
  countKPI: undefined,
};

export default AverageHandleTime;
