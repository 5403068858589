import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    recommendedCardContainer: {
      boxSizing: "border-box",
      display: "flex",
      padding: "16px",
      boxShadow: "0 4px 8px 0 rgb(0 0 0 / 8%)",
      borderRadius: "8px",
    },
    cardNoBorder: {
      boxShadow: "none",
      borderRadius: "0",
      boxSizing: "border-box",
      display: "flex",
      paddingTop: "16px",
      paddingBottom: "3px",
    },
    recommendedCardItemImage: {
      width: "6.625rem",
      height: "6.625rem",
      marginRight: "8px",
    },
    recommendedCardInfoContainer: {
      display: "flex",
      flexDirection: "column",
      minHeight: "121px",
      justifyContent: "space-evenly",
    },
    divFlexAndCenterAlign: {
      display: "flex",
      alignItems: "center",
    },
    recommendedCardInfoSKU: {
      color: palette.text.secondary,
      marginTop: "3px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      whiteSpace: "normal",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
      width: "fit-content",
      fontSize: "12px",
    },
    comboTitle: {
      color: "rgba(0,0,0,0.9)",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "normal",
      width: "fit-content",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
    comboDetails: {
      display: "grid",
      marginBottom: "4px",
    },
    comboDescription: {
      color: palette.text.secondary,
      fontFamily: "Work Sans",
      fontSize: "12px",
      letterSpacing: "0.03px",
      lineHeight: "18px",
    },
    recommendedCardInfoSuggested: {
      color: palette.text.secondary,
      fontFamily: "Work Sans",
      fontSize: "12px",
      letterSpacing: "0.03px",
      lineHeight: "18px",
    },
    recommendedCardInfoLineThrough: {
      textDecoration: "line-through",
      color: palette.text.secondary,
    },
    productDetails: {
      marginTop: "8px",
    },
    recommendedCardPriceContainer: {
      display: "flex",
      flexDirection: "column",
    },
    strikethroughPriceText: {},
    noRenderStrikethroughPriceText: {
      color: "transparent",
    },
    recommendedCardInfoPrice: {
      color: "black",
    },
    recommendedCardInfoPriceWithPromo: {
      color: "#176D38",
    },
    recommendedCardInfoPriceUnit: {
      color: palette.text.secondary,
      display: "inline",
      fontSize: 14,
      fontFamily: "Work Sans",
    },
    recommendedCardItemStock: {
      width: "104px",
      marginBottom: "10px",
      fontFamily: "Work Sans",
      fontSize: "14px",
      letterSpacing: "0",
      fontWeight: 400,
      lineHeight: "14px",
      height: "inherit",
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    stockInformation: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    recommendedCardItemImageOvershadowed: {
      width: "6.625rem",
      height: "6.625rem",
      marginRight: "8px",
      opacity: "40%",
    },
    outOfStock: {
      width: "104px",
      color: "#C9201D",
      fontFamily: "Work Sans",
      fontSize: "14px",
      letterSpacing: "0.1px",
      lineHeight: "20px",
      fontWeight: 400,
      height: "inherit",
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
    },
    recommendedCardStockContainer: {
      height: "32px",
      marginTop: "10px",
      marginRight: "8px",
    },
    recommendedCardInfoTitle: {
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "25vh",
      fontWeight: 600,
    },
    recommendedCardInfoPromo: {
      borderRadius: 6.5,
      width: 41,
      height: 13,
      fontSize: 10,
      fontWeight: 600,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 4px",
      color: "rgba(255,255,255,0.95)",
      background: "#6A882D",
      verticalAlign: "middle",
      marginRight: "2px",
    },
    tagsContainer: {
      display: "flex",
      marginTop: "3px",
    },
    recommendedCardInfoCombo: {
      borderRadius: 6.5,
      width: 41,
      height: 13,
      fontSize: 10,
      fontWeight: 600,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "1px 4px",
      color: "rgba(255,255,255,0.95)",
      background: "#007A78",
      verticalAlign: "middle",
      marginRight: "2px",
    },
    recommendedCardActions: {
      height: 32,
      marginTop: "10px",
      display: "flex",
    },
    addButton: {
      minWidth: "59px",
      padding: "0 8px",
      fontSize: "14px",
      fontWeight: 500,
      borderRadius: "16px",
      height: "32px",
      marginLeft: "8px",
      backgroundColor: "#000000",
      fill: "solid",
    },
    addContainer: {
      display: "inline-flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    addedItemText: {
      marginLeft: "4px",
      height: "18px",
      fontSize: "12px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "18px",
    },
    addedItemTextLink: {
      marginLeft: "4px",
      height: "18px",
      fontSize: "12px",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "18px",
      color: "#0371e2",
      textDecoration: "underline",
    },
    collapseIcon: {
      marginLeft: "5px",
      height: "fit-content",
    },
    tooltipTitleContainer: {
      margin: 0,
      padding: 0,
      borderRadius: "8px",
      maxWidth: "300px",
      backgroundColor: palette.common.black,
      boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.24)",
    },
    tooltipText: {
      fontSize: "14px",
      fontFamily: "Work Sans",
      fontWeight: 500,
      color: "rgba(255, 255, 255, 0.95)",
    },
    tooltipArrow: {
      color: palette.common.black,
      boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.24)",
    },
    recommendedCardInfoTitleTooltip: {
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "25vh",
      fontWeight: 600,
      width: "fit-content",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
  }),
);
