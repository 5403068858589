import * as React from "react";
import NumberFormat from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
  suffix?: string;
  prefix?: string;
  isAllowed: (
    values:
      | {
          formattedValue: string;
          value: string;
        }
      | undefined,
  ) => boolean;
}

const NumberFormatCustom = React.forwardRef<NumberFormat, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, value, suffix, prefix, isAllowed, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        value={value}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowNegative={false}
        thousandSeparator
        isNumericString
        suffix={suffix}
        prefix={prefix}
        allowEmptyFormatting={false}
        isAllowed={isAllowed || undefined}
      />
    );
  },
);

export default NumberFormatCustom;
