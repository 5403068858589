import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@material-ui/core";
import { Button, Heading } from "@hexa-ui/components";

import { useStore } from "effector-react";
import ErrorIcon from "../../../../../assets/newIcons/ErrorIcon";
import CartItemStore from "../../../../../stores/cart/CartItemStore";
import { useCartFreeGoodsHook } from "../hooks/useCartFreeGoodsHook";
import FreeGoodsDrawer from "../freeGoodsDrawer/FreeGoodsDrawer";
import styles from "./FreeGoodsCartSection.styles";
import CartItemV2 from "../../orderSummary/cartItemV2/CartItemV2";
import AgentCallStore from "../../../../../stores/agentCall/AgentCallStore";
import { useAnalytics } from "../../../../../analytics/useAnalytics";
import { freeGoodRemoved } from "../../../../../config/typewriter";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import { CURRENCY } from "../../../../../config/constants";

const FreeGoodsCartSection = (): JSX.Element => {
  const classes = styles();
  const { t } = useTranslation();
  const { toggleDrawer, isDrawerOpen, hasItemsToAdd, formatedSelectedItems } =
    useCartFreeGoodsHook();
  const { user } = GlobalStore.getState();
  const { currency } = CURRENCY[user.zone];

  const selectedFreeGoodsCount = useMemo(() => {
    return formatedSelectedItems?.reduce(
      (total, item) => total + item.selectedQuantity,
      0,
    );
  }, [formatedSelectedItems]);
  const CartItemStates = useStore(CartItemStore);
  const { dispatchGenericEvent } = useAnalytics();
  const { callId, clientId } = useStore(AgentCallStore);

  const renderSelectedFreeGoods = () => {
    return (
      <div
        className={classes.selectedItemsContainer}
        data-testid="cart-free-goods-items-container"
      >
        {formatedSelectedItems?.map((selectedFreeGood) => {
          return (
            <CartItemV2
              key={`${selectedFreeGood.freeGoodHashCode}-${selectedFreeGood.platformId}`}
              data-testid="cart-free-goods-item"
              cartItem={{
                id: selectedFreeGood.sku,
                platformId: selectedFreeGood.platformId,
                title: selectedFreeGood.name,
                quantity: selectedFreeGood.selectedQuantity,
                displayQuantity: selectedFreeGood.availability,
                isCombo: false,
              }}
              onRemove={() => {
                selectedFreeGood.onChangeQuantity(0);
                dispatchGenericEvent(freeGoodRemoved, {
                  call_id: callId,
                  country: user.zone,
                  poc_id: clientId,
                  currency,
                  name: selectedFreeGood.name,
                  quantity: selectedFreeGood.selectedQuantity,
                  sku: selectedFreeGood.sku,
                });
              }}
            />
          );
        })}
      </div>
    );
  };

  return (
    <Box className={classes.container} data-testid="cart-free-goods-container">
      <div className={classes.titleContainer}>
        <div>
          <Heading
            size="H5"
            data-testid="cart-free-goods-title"
            style={{
              color: CartItemStates.highlightTitle ? "#C9201D" : "black",
              height: "24px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("OrderTaking.FREE_GOODS_SECTION.TITLE")}
            {selectedFreeGoodsCount > 0 && (
              <span
                className={classes.freeGoodsCountQuantity}
                data-testid="cart-free-goods-quantity-circle"
              >
                {selectedFreeGoodsCount}
              </span>
            )}
          </Heading>
          {CartItemStates.highlightTitle && (
            <div className={classes.errorMessage}>
              <ErrorIcon width="12" height="12" />
              <span data-testid="cart-free-goods-warning">
                {t("OrderTaking.FREE_GOODS_SECTION.FREE_GOODS_WARNING")}
              </span>
            </div>
          )}
        </div>
        {selectedFreeGoodsCount > 0 && (
          <Button
            className={classes.editButton}
            onClick={toggleDrawer(
              true,
              t("OrderTaking.FREE_GOODS_SECTION.EDIT_BUTTON"),
              "cart-free-goods-edit-button",
            )}
            data-testid="cart-free-goods-edit-button"
            name="cart-free-goods-edit-button"
          >
            {t("OrderTaking.FREE_GOODS_SECTION.EDIT_BUTTON")}
          </Button>
        )}
      </div>

      {selectedFreeGoodsCount > 0 && renderSelectedFreeGoods()}

      {hasItemsToAdd && (
        <Button
          size="small"
          variant="secondary"
          className={classes.selectButton}
          name="cart-free-goods-select-button"
          data-testid="cart-free-goods-select-button"
          onClick={toggleDrawer(
            true,
            "cart-free-goods-select-button",
            t("OrderTaking.FREE_GOODS_SECTION.SELECT_BUTTON_LABEL"),
          )}
        >
          {t("OrderTaking.FREE_GOODS_SECTION.SELECT_BUTTON_LABEL")}
        </Button>
      )}
      <FreeGoodsDrawer
        toggleDrawer={toggleDrawer}
        isDrawerOpen={isDrawerOpen}
      />
    </Box>
  );
};

export default FreeGoodsCartSection;
