import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      right: "16px",
      height: 200,
      position: "fixed",
      bottom: "7px",
      zIndex: 2,
    },
  }),
);
