/* eslint-disable @typescript-eslint/no-unused-vars */
import { isFeatureEnabled as sharedIsFeatureEnabled } from "grow-shared-services";

export function isFeatureEnabled(
  userFeatureToggle: string,
  adminFeatureToggle: string,
  country?: string,
): boolean {
  return sharedIsFeatureEnabled(userFeatureToggle);
}

export function isFeatureEnabledV2(toggle: string, country?: string): boolean {
  return sharedIsFeatureEnabled(toggle);
}

export const TLP_POC_LIST = "tlp-poc-list";
export const TLP_POC_CREATE = "tlp-poc-create";

// TOGGLE - BUSINESS
export const TLP_B2B_TRAINER = "tlp-b2b-trainer";
export const TLP_B2B_TRAINER_ADMIN = "tlp-b2b-trainer-admin";

// TOGGLE - BUSINESS
export const TLP_ORDERS = "tlp-orders";
export const TLP_ORDERS_ADMIN = "tlp-orders-admin";

export const TLP_PROMOTIONS = "tlp-promotions";
export const TLP_PROMOTIONS_ADMIN = "tlp-promotions-admin";
export const TLP_TAB_PROMOTIONS = "tlp-tab-promotions";
export const TLP_TAB_PROMOTIONS_ADMIN = "tlp-tab-promotions-admin";
export const TLP_CTI = "tlp-cti";
export const TLP_CTI_ADMIN = "tlp-cti-admin";
export const TLP_CTI_PING_PONG = "tlp-cti-ping-pong";
export const TLP_CTI_PING_PONG_ADMIN = "tlp-cti-ping-pong-admin";

export const TLP_CALL_SUMMARY_FLOW_ORDER_PLACEMENT =
  "tlp-call-summary-flow-order-placement";
export const TLP_CALL_SUMMARY_FLOW_ORDER_PLACEMENT_ADMIN =
  "tlp-call-summary-flow-order-placement-admin";

export const TLP_REWARDS_PROGRAM_TRACK_POINTS =
  "tlp-rewards-program-track-points";
export const TLP_REWARDS_PROGRAM_TRACK_POINTS_ADMIN =
  "tlp-rewards-program-track-points-admin";
export const TLP_KPI_AVERAGE_HANDLE_TIME = "tlp-kpi-average-handle-time";
export const TLP_KPI_AVERAGE_HANDLE_TIME_ADMIN =
  "tlp-kpi-average-handle-time-admin";

// MOCK AHT DEV
export const TLP_ENPS_AGENTS = "tlp-enps-agents";
export const TLP_ENPS_AGENTS_ADMIN = "tlp-enps-agents-admin";

export const TLP_MARKETPLACE = "tlp-marketplace";
export const TLP_MARKETPLACE_ADMIN = "tlp_marketplace-admin";

// TOGGLE - BUSINESS
export const TLP_ERP_POC_INFO = "tlp-erp-poc-info";
export const TLP_ERP_POC_INFO_ADMIN = "tlp-erp-poc-info-admin";

export const TLP_FOCUS_SKU = "tlp-focus-sku";
export const TLP_FOCUS_SKU_ADMIN = "tlp-focus-sku-admin";

export const TLP_MONTHLY_KPI_HIT_RATE = "tlp-monthly-kpi-hit-rate";
export const TLP_MONTHLY_KPI_HIT_RATE_ADMIN = "tlp-monthly-kpi-hit-rate-admin";

export const TLP_MONTHLY_KPI_CALL_LIST = "tlp-monthly-kpi-call-list";
export const TLP_MONTHLY_KPI_CALL_LIST_ADMIN =
  "tlp-monthly-kpi-call-list-admin";

export const TLP_MONTHLY_KPI_TOPLINE_UPLIFT = "tlp-monthly-kpi-topline-uplift";

export const TLP_KPI_TOPLINE_UPLIFT = "tlp-kpi-topline-uplift";

export const TLP_MONTHLY_KPI_AVERAGE_HANDLE_TIME =
  "tlp-monthly-kpi-average-handle-time";
export const TLP_MONTHLY_KPI_AVERAGE_HANDLE_TIME_ADMIN =
  "tlp-monthly-kpi-average-handle-time-admin";

export const TLP_AGENT_IDENTIFICATION = "tlp-agent-identification";
export const TLP_AGENT_IDENTIFICATION_ADMIN = "tlp-agent-identification-admin";

export const TLP_ORDER_TAKING = "tlp-order-taking";
export const TLP_ORDER_TAKING_ADMIN = "tlp-order-taking-admin";

export const TLP_ORDER_TAKING_EMPTIES = "tlp-order-taking-empties";
export const TLP_ORDER_TAKING_EMPTIES_ADMIN = "tlp-order-taking-empties-admin";

export const TLP_SUGGESTED_ORDER_HIDDEN = "tlp-suggested-order-hidden";
export const TLP_SUGGESTED_ORDER_HIDDEN_ADMIN =
  "tlp-suggested-order-hidden-admin";

export const TLP_OUT_OF_STOCK_SUGGESTED_ORDER =
  "tlp-out-of-stock-suggested-order";
export const TLP_OUT_OF_STOCK_SUGGESTED_ORDER_ADMIN =
  "tlp-out-of-stock-suggested-order-admin";

export const TLP_OUT_OF_STOCK_SEARCH_CATALOG =
  "tlp-out-of-stock-search-catalog";
export const TLP_OUT_OF_STOCK_SEARCH_CATALOG_ADMIN =
  "tlp-out-of-stock-search-catalog-admin";

export const TLP_OUT_OF_STOCK_ORDER_CART = "tlp-out-of-stock-order-cart";
export const TLP_OUT_OF_STOCK_ORDER_CART_ADMIN =
  "tlp-out-of-stock-order-cart-admin";

export const TLP_TAB_CAMPAIGNS = "tlp-tab-campaigns";
export const TLP_TAB_CAMPAIGNS_ADMIN = "tlp-tab-campaigns-admin";

export const TLP_SEARCH_BY_POC = "tlp-search-by-poc";
export const TLP_SEARCH_BY_POC_ADMIN = "tlp-search-by-poc-admin";

export const TLP_DELIVERY_WINDOW_VALIDATION = "tlp-delivery-window-validation";
export const TLP_DELIVERY_WINDOW_VALIDATION_ADMIN =
  "tlp-delivery-window-validation-admin";

export const TLP_PALLET_ITEMS = "tlp-pallet-items";
export const TLP_PALLET_ITEMS_ADMIN = "tlp-pallet-items-admin";

export const TLP_GAP_REASON = "tlp-gap-reason";
export const TLP_GAP_REASON_ADMIN = "tlp-gap-reason-admin";

export const TLP_MISSIONS_LAYOUT = "tlp-missions-layout";
export const TLP_MISSIONS_LAYOUT_ADMIN = "tlp-missions-layout-admin";

export const TLP_GAP_BOARD = "tlp-gap-board";
export const TLP_GAP_BOARD_ADMIN = "tlp-gap-board-admin";

export const TLP_ORDER_ITEM_OUT_STOCK = "tlp-order-item-out-stock";
export const TLP_ORDER_ITEM_OUT_STOCK_ADMIN = "tlp-order-item-out-stock-admin";

export const TLP_EMPTIES_CHECKOUT = "tlp-empties-checkout";
export const TLP_EMPTIES_CHECKOUT_ADMIN = "tlp-empties-checkout-admin";

export const TLP_SUM_SUGGESTED_ORDER_PRODUCTS =
  "tlp-sum-suggested-order-products";
export const TLP_SUM_SUGGESTED_ORDER_PRODUCTS_ADMIN =
  "tlp-sum-suggested-order-products-admin";

export const TLP_CUSTOMER_PAGE = "tlp-customer-page";
export const TLP_CUSTOMER_PAGE_ADMIN = "tlp-customer-page-admin";

export const TLP_ORDER_TAKING_EMPTIES_V2 = "tlp-order-taking-empties-v2";
export const TLP_ORDER_TAKING_EMPTIES_V2_ADMIN =
  "tlp-order-taking-empties-v2-admin";

export const TLP_FLOATING_CUSTOMER_NOTES = "tlp-floating-customer-notes";
export const TLP_FLOATING_CUSTOMER_NOTES_ADMIN =
  "tlp-floating-customer-notes-admin";

export const TLP_SALES_STRUCTURE = "tlp-sales-structure";
export const TLP_SALES_STRUCTURE_ADMIN = "tlp-sales-structure-admin";

export const TLP_TAX_ID_POC_INFO = "tlp-tax-id-poc-info";
export const TLP_TAX_ID_POC_INFO_ADMIN = "tlp-tax-id-poc-info-admin";

export const TLP_LIQUOR_LICENSE_POC_INFO = "tlp-liquor-license-poc-info";
export const TLP_LIQUOR_LICENSE_POC_INFO_ADMIN =
  "tlp-liquor-license-poc-info-admin";

export const TLP_AUTO_UPDATE_DAILY_KPIS = "tlp-auto-update-daily-kpis";
export const TLP_AUTO_UPDATE_DAILY_KPIS_ADMIN =
  "tlp-auto-update-daily-kpis-admin";

export const TLP_MULTI_VENDOR = "tlp-multi-vendor";
export const TLP_MULTI_VENDOR_ADMIN = "tlp-multi-vendor-admin";

export const TLP_ERROR_MESSAGE_ORDER_MODAL = "tlp-error-message-order-modal";
export const TLP_ERROR_MESSAGE_ORDER_MODAL_ADMIN =
  "tlp-error-message-order-modal-admin";

export const TLP_RECOMENDED_PRODUCTS = "tlp-recomended-products";
export const TLP_RECOMENDED_PRODUCTS_ADMIN = "tlp-recomended-products-admin";

export const TLP_CHANGE_TAG_VISUALIZATION = "tlp-change-tag-visualization";
export const TLP_CHANGE_TAG_VISUALIZATION_ADMIN =
  "tlp-change-tag-visualization-admin";
export const TLP_CALL_SUMMARY_PROMOTE_MARKET_PLACE_TAG =
  "tlp-call-summary-promote-market-place-tag";
export const TLP_CALL_SUMMARY_PROMOTE_MARKET_PLACE_TAG_ADMIN =
  "tlp-call-summary-promote-market-place-tag-admin";

export const TLP_RECOMENDED_PRODUCTS_STOCK = "tlp-recomended-products-stock";
export const TLP_RECOMENDED_PRODUCTS_STOCK_ADMIN =
  "tlp-recomended-products-stock-admin";

export const TLP_PRICE_PER_KG = "tlp-price-per-kg";
export const TLP_PRICE_PER_KG_ADMIN = "tlp-price-per-kg-admin";

export const TLP_LAST_MARKETPLACE_ORDERS = "tlp-last-marketplace-orders";
export const TLP_LAST_MARKETPLACE_ORDERS_ADMIN =
  "tlp-last-marketplace-orders-admin";

export const TLP_MARKETPLACE_PROMO_VISIBILITY =
  "tlp-marketplace-promo-visibility";
export const TLP_MARKETPLACE_PROMO_VISIBILITY_ADMIN =
  "tlp-marketplace-promo-visibility-admin";

export const TLP_TOPLINEGAP_AUTOMATICALLY_ADD_SUGGESTED_ORDER =
  "tlp-toplinegap-automatically-add-suggested-order";
export const TLP_TOPLINEGAP_AUTOMATICALLY_ADD_SUGGESTED_ORDER_ADMIN =
  "tlp-toplinegap-automatically-add-suggested-order-admin";

export const TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT =
  "tlp-last-marketplace-orders-endpoint";
export const TLP_LAST_MARKETPLACE_ORDERS_ENDPOINT_ADMIN =
  "tlp-last-marketplace-orders-endpoint-admin";

export const TLP_RECOMMENDED_PRODUCTS_ADD_CART =
  "tlp-recommended-products-add-cart";
export const TLP_RECOMMENDED_PRODUCTS_ADD_CART_ADMIN =
  "tlp-recommended-products-add-cart-admin";

export const TLP_RECOMMENDED_PRODUCTS_ORDER_PAGE_LINK =
  "tlp-recommended-products-order-page-link";
export const TLP_RECOMMENDED_PRODUCTS_ORDER_PAGE_LINK_ADMIN =
  "tlp-recommended-products-order-page-link-admin";

export const TLP_DISPLAY_VENDOR_ACCOUNT_ID = "tlp-display-vendor-account-id";
export const TLP_DISPLAY_VENDOR_ACCOUNT_ID_ADMIN =
  "tlp-display-vendor-account-id-admin";

export const TLP_REWARDS_MISSIONS_TAB = "tlp-rewards-missions-tab";
export const TLP_REWARDS_MISSIONS_TAB_ADMIN = "tlp-rewards-missions-tab-admin";

export const TLP_REWARDS_CHALLENGES_STATISTICS =
  "tlp-rewards-challenges-statistics";

export const TLP_CART_DISPLAY_COMBO_DESCRIPTION =
  "tlp-cart-display-combo-description";
export const TLP_CART_DISPLAY_COMBO_DESCRIPTION_ADMIN =
  "tlp-cart-display-combo-description-admin";

export const TLP_REWARDS_CHALLENGES_DETAILS = "tlp-rewards-challenges-details";

export const TLP_OFFERINGS_COMBO_ADD_TO_CART =
  "tlp-offerings-combo-add-to-cart";

export const TLP_CALL_SUMMARY_TOPLINEGAP_CLIENT_UPSET_REASON =
  "tlp-call-summary-toplinegap-client-upset-reason";

export const TLP_OFFERINGS_PROMOTIONS_ADD_TO_CART =
  "tlp-offerings-promotions-add-to-cart";

export const TLP_PRICE_PER_KG_SUGGESTED_ORDER =
  "tlp-price-per-kg-suggested-order";

export const TLP_RECOMMENDED_PRODUCTS_DISPLAY_PROMO_PRICE =
  "tlp-recommended-products-display-promo-price";

export const TLP_OFFERINGS_COMBO_REDIRECT_LINK =
  "tlp-offerings-combo-redirect-link";

export const TLP_OFFERINGS_PROMOTIONS_REDIRECT_LINK =
  "tlp-offerings-promotions-redirect-link";

export const TLP_PAYMENT_VENDOR = "tlp-payment-vendor";

export const TLP_CREDIT_LIMIT = "tlp-credit-limit";

export const TLP_SPONSORED_MISSION_TAB = "tlp-sponsored-mission-tab";

export const TLP_KPI_VENDOR = "tlp-kpi-vendor";

export const TLP_KPI_VENDOR_ADMIN = "tlp-kpi-vendor-admin";

export const TLP_FLEXIBLE_DELIVERY_FEE = "tlp-flexible-delivery-fee";

export const TLP_FLEXIBLE_DELIVERY_FEE_ADMIN =
  "tlp-flexible-delivery-fee-admin";

export const TLP_CALL_SUMMARY_REWARDS_CHALLENGES_FEEDBACK =
  "tlp-call-summary-rewards-challenges-feedback";

export const TLP_SERVICE_MISSION = "tlp-service-mission";

export const TLP_SERVICE_MISSION_EFFECTIVENESS_CALL_SUMMARY =
  "tlp-service-mission-effectiveness-call-summary";

export const TLP_PHONE_RANKING_VENDOR = "tlp-phone-ranking-vendor";

export const TLP_CAMPAIGN_FOCUS_VENDOR = "tlp-campaign-focus-vendor";

export const TLP_SERVICE_MISSION_SCRIPT = "tlp-service-mission-script";

export const TLP_SERVICE_CATALOG_COMPONENT = "tlp-service-catalog-component";

export const TLP_SPONSORED_PRODUCT_SHOW_STOCK =
  "tlp-sponsored-product-show-stock";

export const TLP_SERVICE_CATALOG_FIXED_PRICE =
  "tlp-service-catalog-fixed-price";

export const TLP_SUGGESTED_INVENTORY_AND_ENFORCEMENT =
  "tlp-suggested-inventory-and-enforcement";

export const TLP_SPONSORED_PRODUCT_ADD_CART = "tlp-sponsored-product-add-cart";

export const TLP_SPONSORED_COMBO_ADD_CART = "tlp-sponsored-combo-add-cart";

export const TLP_DISPLAY_STOCK_CATALOG = "tlp-display-stock-catalog";

export const TLP_SERVICE_CATALOG_VARIABLE_PRICE =
  "tlp-service-catalog-variable-price";

export const TLP_DISPLAY_ADDITIONAL_METADATA_INFO =
  "tlp-display-additional-metadata-info";

export const TLP_OFFERINGS_PROMOTIONS_STOCK = "tlp-offerings-promotions-stock";

export const TLP_OFFERINGS_COMBOS_CONSUMPTION =
  "tlp-offerings-combos-consumption";

export const TLP_NEW_CREDIT_PAYMENT_INFO = "tlp-new-credit-payment-info";

export const TLP_CALL_SUMMARY_REMOVE_FIELDS = "tlp-call-summary-remove-fields";

export const TLP_SPONSORED_PRODUCTS_ORDER_PAGE_LINK =
  "tlp-sponsored-products-order-page-link";

export const TLP_CAMPAIGNS_PRODUCTS_ADD_TO_CART =
  "tlp-campaigns-products-add-to-cart";

export const TLP_SERVICE_CONTENT_BELOW_CARD = "tlp-service-content-below-card";

export const TLP_CAMPAIGNS_PRODUCTS_SHOW_STOCK =
  "tlp-campaigns-products-show-stock";

export const TLP_CALL_SUMMARY_REASONS_LIST = "tlp-call-summary-reasons-list";

export const TLP_MARKETPLACE_INVENTORY_AND_ENFORCEMENT =
  "tlp-marketplace-inventory-and-enforcement";

export const TLP_MULTICONTRACT_ACCOUNT_SERVICE =
  "tlp-multicontract-account-service";

export const TLP_CUSTOM_MISSIONS_ENDPOINT_V2 =
  "tlp-custom-missions-endpoint-v2";

export const TLP_MULTICONTRACT_CAMPAIGN_SERVICE =
  "tlp-multicontract-campaign-service";

export const TLP_MULTICONTRACT_AGENTS_KPI_SERVICE =
  "tlp-multicontract-agents-kpi-service";

export const TLP_MULTICONTRACT_GROW_ADMIN_CONFIG_SERVICE =
  "tlp-multicontract-grow-admin-config-service";

export const TLP_MULTICONTRACT_AGENT_CALL_SERVICE =
  "tlp-multicontract-agent-call-service";

export const TLP_MULTICONTRACT_CALL_LIST_SERVICE =
  "tlp-multicontract-call-list-service";

export const TLP_MULTICONTRACT_ITEM_SERVICE = "tlp-multicontract-item-service";

export const TLP_MULTICONTRACT_GLOBAL_RECOMMENDATION_BUSINESS_SERVICE =
  "tlp-multicontract-global-recommendation-business-service";

export const GROW_ADMIN_MISSIONS_MANAGEMENT_V2 =
  "grow-admin-missions-management-v2-BEESGR-5955";

export const GROW_MISSION_REWARDS_REDEMPTION =
  "grow-mission-rewards-redemption-BEESGR-8819";

export const GROW_LOCAL_SCRIPT_REDESIGN_BEESGR_8281 =
  "grow-local-script-redesign-BEESGR-8281";

export const GROW_ITEM_TITLE_TOOLTIP = "grow-item-title-tooltip-BEESGR-8281";

export const GROW_LOCAL_HEADER_REDESIGN =
  "grow-local-header-redesign-BEESGR-8281";

export const GROW_LOCAL_TAGS_COMPONENT =
  "grow-local-tags-component-BEESGR-8051";

export const GROW_LOCAL_GLOBAL_MISSIONS_ORDER =
  "grow-local-global-missions-order-BEESGR-9089";

export const GROW_LOCAL_PRODUCTS_REDESIGN =
  "grow-local-products-redesign-BEESGR-8281";

export const GROW_LOCAL_CALL_SUMARY = "grow-local-call-summary-BEESGR-8058";

export const GROW_ADMIN_LOCAL_MISSIONS_STEP2 =
  "grow-admin-local-missions-step2-BEESGR-8796";

export const GROW_LOCAL_COMBO_REGULAR_DISCOUNT =
  "grow-local-combo-regular-discount-BEESGR-8057";

export const GROW_LOCAL_REGULAR_DISCOUNT =
  "grow-local-regular-discount-BEESGR-5871";
export const GROW_LOCAL_AGENT_SPECIALIZED =
  "grow-local-agent-specialized-BEESGR-9836";

export const GROW_LOCAL_SEARCH_BY_POC = "grow-local-search-by-poc-BEESGR-8756";

export const GROW_GLOBAL_SEARCH_BY_POC = "grow-global-search-by-poc";

export const GROW_UPGRADE_CATALOG_V3 = "grow-upgrade-catalog-v3-BEESGR-9380";

export const GROW_LOCAL_PRICE_PER_UNIT =
  "grow-local-price-per-unit-BEESGR-9380";
export const GROW_LOCAL_STEPPED_DISCOUNT =
  "grow-local-stepped-discount-BEESGR-5871";

export const GROW_COMBOS_SERVICE_V3 = "grow-combosV3-BEESGR-4704";

export const GROW_MARKETPLACE_CARD_V3 = "grow-marketplace-cardv3-BEESGR-11052";

export const GROW_CART_SERVICE_V4 = "grow-cart-service-v4-BEESGR-4697";

export const GROW_SEARCH_CATALOG_V3 =
  "grow-search-catalog-v3-inorder-BEESGR-5622";

export const GROW_BEES_PRODUCT_SEARCH = "grow-bees-product-search-BEESGR-9380";

export const GROW_CHECKOUT_V3 = "grow-checkout-v3-BEESGR-4698";

export const GROW_BEES_ACCOUNTS_MULTICONTRACT =
  "grow-bees-accounts-multicontract-BEESGR-11267";
export const GROW_BEES_CTI_CALL_LOGS =
  "grow-cti-save-makecall-disconnectcall-events-BEESGR-11153";

export const GROW_MULTICONTRACT_REWARDS =
  "grow-multicontract-rewards-BEESGR-11143";

export const GROW_NEW_ERROR_HANDLING =
  "grow-wip-new-error-handling-BEESGR-8756";

export const GROW_CUSTOMER_NOTES_V2 = "grow-customer-notes-v2-BEESGR-11138";
export const GROW_BEES_CTI_CALL_LOGS_TEMP =
  "grow-tech-call-logs-temp-BEESGR-11153";

export const GROW_CALL_SUMMARY_NEW_REGISTER_BUTTONS =
  "grow-call-summary-new-register-buttons-BEESGR-11137";

export const GROW_CTI_START_CALL_AUTOMATICALLY =
  "grow-cti-start-call-automatically-BEESGR-11137";

export const GROW_EXCLUDE_MKTPLACE_FROM_CALL_SUMMARY =
  "grow-exclude-mktplace-from-call-summary-BEESGR-11137";

export const GROW_EXCLUDE_TOPLINE_FROM_CALL_SUMMARY =
  "grow-exclude-topline-from-call-summary-BEESGR-11137";

export const GROW_EXCLUDE_REWARDS_FROM_CALL_SUMMARY =
  "grow-exclude-rewards-from-call-summary-BEESGR-11137";

export const GROW_EXCLUDE_SERVICE_FROM_CALL_SUMMARY =
  "grow-exclude-service-from-call-summary-BEESGR-11137";

export const GROW_CALL_SUMMARY_CTI_CALL_DURATION =
  "grow-call-summary-call-duration-BEESGR-11127";

export const GROW_NON_CTI_START_CALL_AUTOMATICALLY =
  "grow-non-cti-start-call-automatically-BEESGR-11137";

export const GROW_CUSTOMER_NOTES = "grow-customer-notes-BEESGR-11138";

export const GROW_COMPANIES_LOGO = "grow-companies-logo-BEESGR-12178";

export const TLP_CALL_AUTO_DIALER = "grow-autodialer-mode-BEESGR-11137";

export const GROW_ALWAYS_SHOW_REWARDS_CHALLENGE =
  "grow-always-show-rewards-challenge-beesgr-12131";

export const GROW_MULTICONTRACT_AGENT_SURVEY =
  "grow-multicontract-agent-survey-BEESGR-11143";

export const GROW_DISABLE_FOCUS_SKU = "grow-disable-focus-sku";

export const GROW_TECH_DISABLE_FREE_GOODS =
  "grow-tech-disable-free-goods-beesoe-124836";

export const GROW_NEW_CART_EXHIBITION = "grow-new-cart-exhibition-BEESGR-11054";

export const GROW_TECH_CART_AUTO_UPDATE =
  "grow-tech-cart-auto-update-BEESGR-11054";

export const GROW_SEARCH_BY_POC_FULFILLMENT_VALIDATION =
  "grow-search-by-poc-fullfillment-validation-BEESGR-12983";

export const GROW_SEARCH_BY_POC_WITH_CUSTOMER_ACCOUNT_ID =
  "grow-search-by-poc-with-customer-account-id-BEESGR-10961";

export const GROW_CART_VARIANTS_STOCK_CALCULATION =
  "grow-cart-variants-stock-calculation-BEESGR-11054";

export const GROW_POC360_CREDIT_SERVICE =
  "grow-poc360-credit-service-BEESGR-12981";

export const GROW_CHECKOUT_CART_PO_NUMBER_FIELD =
  "grow-checkout-cart-po-number-field-BEESGR-10961";

export const GROW_TECH_LOCAL_TO_CAMPAIGN_POC_ACCEPTANCE =
  "grow-tech-local-to-campaign-and-poc-acceptance-text-BEESGR-11052";

export const GROW_POC360_CONTRACTS = "grow-wip-poc-360-contracts";

export const GROW_FLEXIBLE_MISSIONS = "grow-flexible-mission-BEESGR-11052";

export const GROW_CATALOG_VARIANTS_STOCK_CALCULATION =
  "grow-catalog-variants-stock-calculation-BEESGR-11054";

export const GROW_HIDE_VOLUME_GAP = "grow-hide-volume-gap-BEESGR-11054";

export const GROW_WIP_FREE_GOODS = "grow-wip-free-goods";

export const GROW_DISPLAY_FULL_PACKAGE_FIELD =
  "grow-display-full-package-field";

export const GROW_TECH_EFFECTIVENESS_BUG =
  "grow-tech-temp-effectiveness-bug-BEESGR-14032";

export const GROW_NEW_OFFERINGS_PROMOTIONS_CARDS =
  "grow-tech-new-offerings-promotions-cards-BEESGR-13474";

export const GROW_TECH_CART_BFF = "grow-tech-cart-bff-BEESGR-14575";

export const GROW_TECH_INCORRECT_PHONE_ON_VALID_CALL =
  "grow-tech-incorrect-phone-on-valid-call-BEESGR-15083";

export const GROW_EXCLUDE_NOREWARDS_FROM_CALL_SUMMARY =
  "grow-exclude-norewards-from-call-summary-BEESGR-14625";

export const GROW_ORGANIZATION_ACTIVATION = "grow-organization-activation";

export const GROW_TECH_SEARCHPOC_BY_USERCONFIG_BEESGR_16675 =
  "grow-tech-search-poc-by-userconfig-BEESGR-16675";

export const GROW_TEMP_ACCEPT_LANGUAGE_CHECKOUT =
  "grow-temp-accept-language-checkout-BEESGR-16911";

export const GROW_AGENT_UNLINK_TOPLINE_OF_SUGGESTED_ORDER =
  "grow-agent-unlink-topline-from-suggested-order";

export const GROW_INTERACTIVE_COMBO = "grow-interactive-combo-20648";

export const GROW_NEW_GAP_CALCULATION_ON = "grow-new-gap-calculation-on";
