import SuggestedOrderV2 from "@domains/suggestedOrders/SuggestedOrderV2";
import SuggestedOrder from "domains/suggestedOrders/SuggestedOrder";

const SuggestedOrderSorting = (
  id: string,
  isAsc: boolean,
  suggestedOrders: Array<SuggestedOrder | SuggestedOrderV2>,
): Array<SuggestedOrder | SuggestedOrderV2> => {
  return suggestedOrders?.sort((itemA, itemB) => {
    const fieldA = getValueByField(id, itemA);
    const fieldB = getValueByField(id, itemB);

    if (fieldA == null) {
      // If the value is empty should show at the end of the list
      return 1;
    }
    if (fieldB == null) {
      // If the value is empty should show at the end of the list
      return -1;
    }

    if (getFieldType(id, itemA) === "string") {
      if (isAsc) {
        return fieldA.localeCompare(fieldB);
      }
      return fieldB.localeCompare(fieldA);
    }

    if (isAsc) {
      return fieldA > fieldB ? 1 : -1;
    }
    return fieldB > fieldA ? 1 : -1;
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValueByField = (field: string, item: any) => item[field];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFieldType = (field: string, item: any) => typeof item[field];

export default SuggestedOrderSorting;
