import React from "react";
import { useTranslation } from "react-i18next";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import TagStyles from "./TagStyles";

interface Props {
  text: string;
}

const Tag: React.FC<Props> = ({ text }) => {
  const classes = TagStyles();
  const { t, i18n } = useTranslation();

  const getTranslation = (textKey: string) => {
    const key = `tags.${textKey}`;
    return i18n.exists(key) ? t(key) : textKey;
  };

  return (
    <Tooltip
      title={text}
      placement="right"
      arrow
      classes={{
        tooltip: classes.tooltip,
      }}
    >
      <div>
        <Chip
          data-testid={text}
          label={getTranslation(text)}
          className={classes.root}
        />
      </div>
    </Tooltip>
  );
};

export default Tag;
