import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    accordion: {
      boxShadow: "none",
      borderRadius: "0px",
      border: `solid 0px ${palette.secondary.main}`,
      margin: "0 0 0px 0",

      "&.Mui-expanded": {
        margin: "0 0 0px 0",
      },
      "&:before": {
        height: 0,
      },
      backgroundColor: palette.common.black,
    },
    accordionDetails: {
      display: "flex",
      flexDirection: "column",
    },
    accordionExpandedBackground: {
      paddingLeft: "0px",
      wordBreak: "break-word",
    },
    expandButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: "8px 0px 8px 28px",
    },
    icon: {
      borderRadius: "3px",
      backgroundColor: palette.common.white,
      color: palette.secondary.main,
      height: "15px",
      width: "15px",
      marginRight: "10px",
    },
    expandButtonLabel: {
      fontWeight: 600,
      color: palette.common.white,
      fontSize: 12,
    },
  }),
);
