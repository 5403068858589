import React, { FunctionComponent } from "react";
import TableCellBase from "@material-ui/core/TableCell";
import { Box } from "@material-ui/core";
import { useStore } from "effector-react";
import classNames from "classnames";
import { STOCKOUT_OPTIONS } from "../../config/constants";
import Data from "../data/Data";
import TableListViewStyle from "./TableListViewStyle";
import StockoutWarning from "../stockout/StockoutWarning";
import {
  isFeatureEnabled,
  TLP_OUT_OF_STOCK_SUGGESTED_ORDER,
  TLP_OUT_OF_STOCK_SUGGESTED_ORDER_ADMIN,
} from "../../config/featureToggles";
import GlobalStore from "../../stores/global/GlobalStore";

interface Props {
  text?: string | number;
  subText?: string;
  head?: boolean;
  className?: string; // To build a class with hight priority use "th&" selector https://sass-lang.com/documentation/style-rules/parent-selector
  color?: string;
  type?: "text" | "currency" | "number";
  testId: string;
  ellipsisText?: number;
  colSpan?: number;
  align?: "left" | "right" | "inherit" | "center" | "justify";
  footer?: boolean;
  isEmpty?: boolean;
  style?: Record<string, unknown>;
  tags?: Array<React.ReactNode> | null;
  stock?: {
    value: number;
    option: STOCKOUT_OPTIONS;
  };
}

const TableListCell: FunctionComponent<Props> = ({
  text,
  subText,
  head,
  className,
  color,
  type,
  testId,
  ellipsisText,
  colSpan,
  align,
  footer,
  isEmpty,
  style,
  tags,
  stock,
}) => {
  const classes = TableListViewStyle();
  const GlobalStates = useStore(GlobalStore);

  const getCellClasses = () => {
    if (head) return classNames(classes.tableHeaderCell, className);
    if (footer) return classNames(classes.tableFooterCell, className);
    return classNames(classes.tableItemCell, className);
  };
  const getCellTextClasses = () => {
    if (head) return classNames(classes.tableBaseText, classes.tableHeaderText);
    if (footer)
      return classNames(classes.tableBaseText, classes.tableFooterText);
    return classes.tableBaseText;
  };
  const isStockSuggested = isFeatureEnabled(
    TLP_OUT_OF_STOCK_SUGGESTED_ORDER,
    TLP_OUT_OF_STOCK_SUGGESTED_ORDER_ADMIN,
    GlobalStates.user.keyToggle,
  );

  const stockDisplayWarn =
    stock && isStockSuggested && stock.option === STOCKOUT_OPTIONS.DISPLAY_WARN;

  const renderStockOut = () => {
    if (stockDisplayWarn)
      return <StockoutWarning value={Number(stock?.value)} />;

    return <></>;
  };
  /* istanbul ignore next */
  function renderGapColor(value) {
    if (value === "red") {
      return classes.redText;
    }
    if (value === "smallRed") {
      return classes.smallRedText;
    }
    if (value === "green") {
      return classes.greenText;
    }
    return "";
  }

  const renderTags = () => {
    if (tags) {
      return tags.map((tag, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box id="tag" key={String(index)} className={classes.tag}>
          {tag}
        </Box>
      ));
    }
    return null;
  };

  const mountText = () => {
    if (subText) {
      return (
        <>
          <Data
            className={classNames(
              classes.tableBaseText,
              classes.tableItemTitleText,
            )}
            hasTooltip
            value={text}
            ellipsisSize={ellipsisText}
          />
          <Box className={classes.containerSubTextTag}>
            <Data className={classes.tableBaseText} value={subText} />
            {renderTags()}
          </Box>
          {stockDisplayWarn ? (
            <Box>
              <StockoutWarning value={Number(stock?.value)} />
            </Box>
          ) : null}
        </>
      );
    }

    return (
      <>
        {!isEmpty ? (
          <>
            <Data
              className={classNames(
                getCellTextClasses(),
                renderGapColor(color),
              )}
              value={text}
            />
            <Box className={classes.containerSubTextTag}>
              {renderStockOut()}
            </Box>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <TableCellBase
      align={align || "right"}
      className={getCellClasses()}
      style={{ ...style, color }}
      data-testid={`${testId}-table-cell`}
      colSpan={colSpan || 1}
    >
      {type === "currency" || type === "number" ? (
        <Data
          className={getCellTextClasses()}
          value={text}
          testId={`${testId}-number`}
          type={type}
        />
      ) : (
        mountText()
      )}
    </TableCellBase>
  );
};

TableListCell.defaultProps = {
  subText: undefined,
  className: "",
  color: "",
  head: false,
  type: "text",
  ellipsisText: 0,
};

export default TableListCell;
