import { Skeleton } from "@material-ui/lab";
import React from "react";

import MissionsListStyles from "./MissionsListStyles";

export const MissionsCardSkeleton = (): JSX.Element => {
  const classes = MissionsListStyles();
  return (
    <div className={classes.gridSkeleton}>
      <Skeleton width={68} height={68} variant="rect" />
      <Skeleton
        width={194}
        height={68}
        variant="rect"
        className={classes.blocoSkeleton}
      />
    </div>
  );
};
