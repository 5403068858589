import React, { ReactElement, FunctionComponent } from "react";
import Collapse from "@material-ui/core/Collapse";
import { ButtonBase } from "@material-ui/core";
import TableCellBase from "@material-ui/core/TableCell";
import TableRowBase from "@material-ui/core/TableRow";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import CollapsibleTableStyles from "./CollapsibleTableStyles";

interface Props {
  rowContent: ReactElement;
  subRowContent: ReactElement;
  testId: string;
  rowKey: string;
  expanded: boolean;
  onRowExpanded: (rowKey: string, orderDate?: string) => void;
}

const TableRow: FunctionComponent<Props> = ({
  rowKey,
  rowContent,
  subRowContent,
  testId,
  expanded,
  onRowExpanded,
}) => {
  const classes = CollapsibleTableStyles();

  const getSubCellClasses = () => {
    let subCellClasses = classes.subCell;
    if (expanded) {
      subCellClasses = `${subCellClasses} ${classes.subCellOpened}`;
    }
    return subCellClasses;
  };

  const getIconCellClasses = () => {
    let iconCellClasses = classes.iconCell;
    if (expanded) {
      iconCellClasses = `${iconCellClasses} ${classes.iconCellExpanded}`;
    }
    return iconCellClasses;
  };

  const openCollapse = () => {
    onRowExpanded(rowKey);
  };

  return (
    <>
      <TableRowBase className={classes.row} data-testid={`${testId}-table-row`}>
        <TableCellBase className={getIconCellClasses()} align="center">
          <ButtonBase
            aria-label="expand row"
            className={classes.iconButton}
            onClick={openCollapse}
            data-testid={`${testId}-table-expand-button`}
          >
            {expanded ? (
              <Remove
                classes={{ root: classes.icon }}
                data-testid={`${testId}-table-remove-icon`}
              />
            ) : (
              <Add
                classes={{ root: classes.icon }}
                data-testid={`${testId}-table-add-icon`}
              />
            )}
          </ButtonBase>
        </TableCellBase>
        {rowContent}
      </TableRowBase>
      <TableRowBase>
        <TableCellBase
          className={getSubCellClasses()}
          colSpan={rowContent?.props?.children?.length + 1 || 1}
        >
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            data-testid={`${testId}-table-collapse`}
          >
            {subRowContent}
          </Collapse>
        </TableCellBase>
      </TableRowBase>
    </>
  );
};

export default TableRow;
