import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import LoadError from "../../loadError/LoadError";
import GlobalStore from "../../../stores/global/GlobalStore";
import {
  errorMessageViewed,
  retryButtonClicked,
} from "../../../config/typewriter";
import {
  isFeatureEnabledV2,
  GROW_NEW_ERROR_HANDLING,
} from "../../../config/featureToggles";
import ErrorHandlerRetryStyles from "./ErrorHandlerRetryStyles";
import Button from "../../button/Button";
import { useAnalytics } from "../../../analytics/useAnalytics";

interface Props {
  onClick: () => void;
  description: string;
  event?: {
    screenName: string | undefined;
    screenSection: string;
    errorMessage: string;
    errorType: string;
  };
  className?: string;
}

const ErrorHandlerRetry: React.FunctionComponent<Props> = ({
  onClick,
  description,
  event,
  className,
}) => {
  const { t } = useTranslation();
  const classes = ErrorHandlerRetryStyles();
  const { dispatchPocEvent, dispatchGenericEvent } = useAnalytics();

  const {
    user: { zone },
  } = useStore(GlobalStore);

  const isNewErrorHandlingEnabled = isFeatureEnabledV2(
    GROW_NEW_ERROR_HANDLING,
    zone,
  );

  const clickAction = () => {
    if (event) {
      dispatchGenericEvent(retryButtonClicked, {
        screen_name: event.screenName,
        screen_section: event.screenSection,
        error_message: event.errorMessage,
        error_type: event.errorType,
      });
    }
    onClick();
  };

  useEffect(() => {
    if (event) {
      dispatchPocEvent(
        errorMessageViewed,
        {
          error_message: event.errorMessage,
          error_type: event.errorType,
          screen_name: event.screenName,
          screen_section: event.screenSection,
          is_all_products: null,
          DDC: null,
          DDC_name: null,
          total_gap: null,
          total_vol_gap: null,
        },
        { time_of_day: true, is_resumed: true },
      );
    }
  }, [dispatchPocEvent, event]);

  return isNewErrorHandlingEnabled ? (
    <LoadError
      testId="load-error-testid"
      className={className}
      description={description}
      onClick={clickAction}
    />
  ) : (
    <Box
      className={`${classes.card} ${className}`}
      data-testid="box-container-testid"
    >
      <Typography variant="h1" data-testid="h1-testid">
        {t("ErrorRetry.ERROR_RETRY_COMPONENT_TITLE")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.description}
        data-testid="subtitle-testid"
      >
        {description}
      </Typography>
      <Button
        className={classes.button}
        onClick={clickAction}
        data-testid="button-testid"
      >
        {t("ErrorRetry.TRY_AGAIN_BUTTON")}
      </Button>
    </Box>
  );
};

export default ErrorHandlerRetry;
