/* eslint-disable global-require */
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import classNames from "classnames";
import { I360ViewTabInteraction } from "../../../../../../../../config/typewriter";
import {
  POC_INFO_TAB,
  SALES_STRUCTURE_TAB,
} from "../../../../../../../../config/constants";
import CollapsibleSideNavStyles from "./InfoTabsStyles";

interface Props {
  callId: string;
  tab: string;
  setTab: (tab: string) => void;
}

function InfoTabs({
  callId,
  tab,
  setTab,
}: Readonly<Props>): React.ReactElement {
  const { t } = useTranslation();
  const classes = CollapsibleSideNavStyles();

  const handleAfterClick = (tabLabel: string) => {
    setTab(tabLabel);
    I360ViewTabInteraction({
      call_id: callId,
      tab_label: tabLabel === POC_INFO_TAB ? "POC Details" : "Sales Structure",
    });
  };

  return (
    <div
      className={classes.containerTabs}
      data-testid="poc-sales-navigator-tabs"
    >
      <Button
        className={classNames(classes.tabButton, {
          [classes.active]: tab === POC_INFO_TAB,
        })}
        onClick={
          /* istanbul ignore next */ () => {
            handleAfterClick(POC_INFO_TAB);
          }
        }
        data-testid="tab-poc-info"
      >
        {t("POC_INFORMATION_TAB.TITLE")}
      </Button>
      <Button
        className={classNames(classes.tabButton, {
          [classes.active]: tab === SALES_STRUCTURE_TAB,
        })}
        onClick={() => {
          handleAfterClick(SALES_STRUCTURE_TAB);
        }}
        data-testid="tab-sales-structure"
      >
        {t("SALES_STRUCTURE_TAB.TITLE")}
      </Button>
    </div>
  );
}
export default InfoTabs;
