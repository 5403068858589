/* istanbul ignore file */
import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    phoneRankingButton: {
      color: "#000000",
      borderRadius: "1.25rem",
      border: "solid 1px #000000",
      backgroundColor: "#ffffff",
      padding: "0.5rem 1rem",
    },
    btnText: {
      lineHeight: "1.5",
      whiteSpace: "nowrap",
    },
  }),
);
