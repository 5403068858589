import React from "react";
import { Box } from "@material-ui/core";
import { useStore } from "effector-react";
import EmptyItemList from "../emptyItemList/EmptyItemList";
import EmptiesStyles from "./EmptiesStyles";
import TotalizerEmpties from "../emptyItem/TotalizerEmpties";
import TotalizerEmptiesRequired from "../emptyItem/TotalizerEmptiesRequired";
import { setEmpty } from "../../../../../stores/cart/CartItemEvents";
import { getQuantity } from "../../../../../stores/cart/CartItemState";
import CartItemStore from "../../../../../stores/cart/CartItemStore";

const Empties: React.FunctionComponent = () => {
  const classes = EmptiesStyles();

  const { totalItems, cart, empties: cartEmpties } = useStore(CartItemStore);
  const emptiesList = cart?.empties?.emptiesList;

  const updateEmpty = (groupId: string, quantity: number) => {
    setEmpty({ groupId, quantity });
  };

  /* istanbul ignore next */
  const getEmpties = () => {
    return (
      emptiesList?.map((empty) => {
        return {
          groupId: empty.groupId,
          emptiesName: empty.emptiesName,
          quantity: getQuantity(empty, cartEmpties),
          emptiesBalance: empty.emptiesBalance,
          expiredAmount: empty.expiredAmount,
          extraAmount: empty.extraAmount,
          minimumRequired: empty.minimumRequired,
        };
      }) || []
    );
  };

  return (
    <div className={classes.containerTotalizer}>
      <TotalizerEmpties
        item={
          cart.empties && cart.empties.emptiesList?.length > 0 && totalItems > 0
            ? cart.empties.emptiesList
            : []
        }
      />
      <Box className={classes.container} data-test-id="test">
        <EmptyItemList shouldUpdateItem={updateEmpty} empties={getEmpties()} />
      </Box>
      <TotalizerEmptiesRequired
        item={
          cart.empties && cart.empties.emptiesList?.length > 0 && totalItems > 0
            ? cart.empties.emptiesList
            : []
        }
      />
    </div>
  );
};

export default React.memo(Empties);
