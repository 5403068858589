import GlobalAdminConfigDomain from "../../domains/globaAdminConfig/globaAdminConfig";
import GlobalAdminConfigServiceResponse from "../../services/globaAdminConfig/domains/GlobalAdminConfigServiceResponse";

export default function GlobalAdminConfigConverter(
  data: GlobalAdminConfigServiceResponse,
  considerNewPrioritization: boolean,
  vendorId?: string,
): GlobalAdminConfigDomain {
  const { buConfig, userConfig } = data;

  const buMissionPriority = considerNewPrioritization
    ? buConfig.newMissionPriority
    : buConfig.missionPriority;
  const agentMissionPriority = considerNewPrioritization
    ? userConfig.newMissionPriority
    : userConfig.missionPriority;

  const test = {
    ...data,
    userConfig: {
      email: userConfig.email,
      segmentation: userConfig.segmentation,
      vendorId,
      missionPriority: agentMissionPriority,
    },
    buConfig: {
      missionPriority: buMissionPriority,
    },
  } as GlobalAdminConfigDomain;

  return test;
}
