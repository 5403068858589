import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@material-ui/core";
import Subtitle from "../subtitle/Subtitle";
import GapStyles from "./GapBoardStyles";
import Data from "../../../../../components/data/Data";
import Card from "../../../../../components/card/Card";
import { CURRENCY } from "../../../../../config/constants";

interface Props {
  titleId: string;
  gapValue?: number;
  achievedValue?: number;
  targetValue?: number;
  testId: string;
  gapType?: "topline" | "volume";
  userZone: string;
}

const Gap: React.FunctionComponent<Props> = ({
  titleId,
  gapValue,
  achievedValue,
  targetValue,
  testId,
  gapType,
  userZone,
}) => {
  const { t } = useTranslation();
  const classes = GapStyles();

  const measure = gapType === "topline" ? CURRENCY[userZone]?.currency : "hL";

  return (
    <Card>
      <Grid container className={classes.gridContainer}>
        <Grid item className={classes.gapValue} data-testid={`${testId}-value`}>
          <div className={classes.titleContainer}>
            <Typography
              variant="subtitle1"
              classes={{ root: classes.title }}
              data-testid={`${testId}-title`}
            >
              {t(titleId)}
            </Typography>

            <span className={classes.measure}>({measure})</span>
          </div>

          <Data
            value={gapValue}
            type={gapType === "volume" ? "number" : "currency"}
            testId={`${testId}-number`}
            toFixed={2}
            emptyMessage="GlobalMessage.N_A"
          />
          {gapType === "volume" && gapValue && (
            <span className={classes.sufix}> hL</span>
          )}
        </Grid>
        <Grid
          item
          data-testid={`${testId}-achieved-value`}
          className={classes.otherValues}
        >
          <Box
            display="flex"
            flex-direction="row"
            className={classes.gapValueContainer}
          >
            <Subtitle
              textId="PocInformationFooter.ACHIEVED_LABEL"
              testId={testId.concat("-achieved")}
            />
            <Data
              className={classes.data}
              value={achievedValue}
              type={gapType === "volume" ? "number" : "currency"}
              suffix={gapType === "volume" ? " hL" : ""}
              testId={`${testId}-achieved-number`}
              toFixed={2}
              emptyMessage="GlobalMessage.N_A"
            />
          </Box>
          <Box
            display="flex"
            flex-direction="row"
            data-testid={`${testId}-target-value`}
            className={classes.gapValueContainer}
          >
            <Subtitle
              textId="PocInformationFooter.TARGET_LABEL"
              testId={`${testId}-target`}
            />
            <Data
              className={classes.data}
              value={targetValue}
              type={gapType === "volume" ? "number" : "currency"}
              suffix={gapType === "volume" ? " hL" : ""}
              testId={`${testId}-target-number`}
              toFixed={2}
              emptyMessage="GlobalMessage.N_A"
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Gap;
