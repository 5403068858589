import { FocusSku } from "@services/campaigns/domains/CampaignsResponse";

export interface CampaignsV3 {
  campaigns: string[];
  focus: Array<FocusSku>;
}
export interface CampaignsResponseV3 {
  campaignItems?: Array<ExtendedFocusSku>;
}

interface ExtendedFocusSku extends FocusSku {
  campaignItems: string[];
}

export default function CampaignsResponseToCampaignsConverterV3(
  data: CampaignsResponseV3,
): CampaignsV3 {
  const focusResponse = data ?? {};

  const getProduct = (product) => {
    return {
      id: product?.id,
      inventoryCount: product?.inventoryCount,
      sku: product?.vendorItemId,
      productName: product?.productName,
      unitPrice: product?.unitPrice,
      image: product?.image,
      stock: product?.stock,
      itemPlatformId: product?.itemPlatformId,
    };
  };

  const campaigns = [];
  const focus =
    focusResponse instanceof Array
      ? (focusResponse.map((campaign: ExtendedFocusSku) => ({
          campaignTag: campaign?.campaignTag,
          campaignName: campaign?.campaignName,
          campaignType: campaign?.campaignType,
          campaignProducts: campaign?.campaignItems?.map(getProduct) || [],
        })) as FocusSku[])
      : [];

  return {
    campaigns,
    focus,
  };
}
