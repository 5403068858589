/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  useEnvService as envService,
  useNavigationService as navigationService,
} from "admin-portal-shared-services";
import typewriter from "./index";
import { loadAnalyticsSnipped } from "./analytics";

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

enum SegmentKey {
  TLP = "TLP_SEGMENT_KEY",
}

class AnalyticsService {
  public static events: typeof typewriter = typewriter;

  public static init(): void {
    // @ts-expect-error as expected
    delete window.analytics;
    loadAnalyticsSnipped();

    const segmentKey = envService().getSegmentKey(SegmentKey.TLP);
    window.analytics.load(segmentKey);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static identify(userId: string, traits: any): void {
    const anonymousId = this.getAnonymousId();

    try {
      window.analytics.identify(
        userId,
        traits,
        anonymousId ? { anonymousId } : undefined,
      );
    } catch (err) {
      console.log("analitycs error: ", err);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private static getAnonymousId(): string | null {
    const anonymousId = localStorage.getItem("ajs_anonymous_id");

    if (anonymousId && typeof anonymousId === "string") {
      return isValidStringJson(anonymousId)
        ? JSON.parse(anonymousId)
        : anonymousId;
    }
    return null;
  }

  public static page(name: string): void {
    try {
      window.analytics.page(name, {
        path: navigationService().getHistory().location.pathname,
      });
    } catch (err) {
      //
    }
  }

  public static unload(): void {
    // @ts-expect-error as expected
    delete window.analytics;
  }
}

const isValidStringJson = (data: string) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
};

export default AnalyticsService;
