import GlobalStore from "../../stores/global/GlobalStore";
import { sendCustomerNotes } from "../../services/customerNotes/SendCustomerNotesService";
import {
  GROW_CUSTOMER_NOTES_V2,
  TLP_MULTICONTRACT_ACCOUNT_SERVICE,
  isFeatureEnabledV2,
} from "../../config/featureToggles";
import { SendCustomerNotesServiceV2 } from "../../services/customerNotes/v2/SendCustomerNotesServiceV2";

export function execute(
  clientId: string,
  vendorAccountId: string | undefined,
  agentEmail: string,
  note: string,
  isFloatingComponent?: boolean,
): Promise<void> {
  const { user } = GlobalStore.getState();

  const isMulticontractEnabled = isFeatureEnabledV2(
    TLP_MULTICONTRACT_ACCOUNT_SERVICE,
    user.zone,
  );

  // Technical/Temporary toggle
  const isCustomerNotesV2Enabled = isFeatureEnabledV2(
    GROW_CUSTOMER_NOTES_V2,
    user.zone,
  );

  const isV2Enabled = isCustomerNotesV2Enabled && isMulticontractEnabled;

  const bodyRequest = {
    customerNote: {
      agentEmail,
      note,
    },
    isFloatingComponent,
  };

  if (isV2Enabled) {
    const validatedVendorAccountId = vendorAccountId ?? clientId;

    return SendCustomerNotesServiceV2({
      ...bodyRequest,
      vendorAccountId: validatedVendorAccountId,
    });
  }

  return sendCustomerNotes({ ...bodyRequest, accountId: clientId });
}
