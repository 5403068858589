import React from "react";
import { useTranslation } from "react-i18next";
import { Toolbar, Switch, Typography } from "@material-ui/core";
import GapStatus from "../gapStatus/GapStatus";
import tableViewStyles from "../table/TableViewStyles";

interface Props {
  orderSku: number;
  gapOnlyHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EnhancedTableToolbar = ({
  orderSku,
  gapOnlyHandler,
}: Props): React.ReactElement => {
  const classes = tableViewStyles();
  const { t } = useTranslation();
  return (
    <Toolbar className={classes.toolbar}>
      <GapStatus orderSku={orderSku} testId="call-suggested-order-gap-status" />

      <div className={classes.gapOnly}>
        <Typography className={classes.subtitle} variant="subtitle1">
          {t("SuggestedOrder.GAP_ONLY_SWITCHER")}
        </Typography>
        <Switch
          color="primary"
          name="gapOnlySwitch"
          data-testid="gap-only-switch"
          classes={{
            switchBase: classes.switchBase,
            track: classes.switchStyles,
            thumb: classes.thumb,
            checked: classes.checked,
          }}
          onChange={(event) => {
            gapOnlyHandler(event);
          }}
        />
        {/* <GapOnlyIcon /> */}
      </div>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
