import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles(() =>
  createStyles({
    freeGoodProductCardContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: "1rem",
      flexGrow: 1,

      "& + &": {
        marginTop: "1.5rem",
      },
    },
    freeGoodProductCard: {
      borderRadius: 8,
      border: "0px 0px 1px 0px",
      boxShadow: "0px 0px 4px 0px #00000029",
      padding: "1rem 1rem .5rem 1rem",
    },
  }),
);
