import React from "react";
import CallInAutoDialerButtonStyles from "./CallInAutoDialerButtonStyles";
import PhoneIcon from "../../../../assets/PhoneOutlineIcon";
import Button from "../../../../components/button/Button";

interface Props {
  label: string;
  loading: boolean;
  click: () => void;
}

function CallInAutoDialerButton({
  label,
  loading,
  click,
}: Props): React.ReactElement {
  const classes = CallInAutoDialerButtonStyles();

  return (
    <>
      <Button
        color="secondary"
        loading={loading}
        onClick={click}
        className={classes.callInAutoDialerButton}
        loadingProps={{ size: 20 }}
        testId="get-next-poc-button"
      >
        <div className={classes.label} data-testid="get-next-poc-button-label">
          {label}
        </div>
        <div className={classes.phoneIcon}>
          <PhoneIcon />
        </div>
      </Button>
    </>
  );
}

export default CallInAutoDialerButton;
