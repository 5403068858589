import React from "react";

function InfoIcon(props: any) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9ZM16.3636 9C16.3636 4.93318 13.0668 1.63636 9 1.63636C4.93318 1.63636 1.63636 4.93318 1.63636 9C1.63636 13.0668 4.93318 16.3636 9 16.3636C13.0668 16.3636 16.3636 13.0668 16.3636 9ZM9 8.18182C9.41959 8.18182 9.76542 8.49767 9.81268 8.90458L9.81818 9V12.2727C9.81818 12.7246 9.45187 13.0909 9 13.0909C8.58041 13.0909 8.23458 12.7751 8.18732 12.3681L8.18182 12.2727V9C8.18182 8.54813 8.54813 8.18182 9 8.18182ZM9.82636 5.72727C9.82636 5.2754 9.46005 4.90909 9.00818 4.90909L8.90458 4.9146C8.49767 4.96186 8.18182 5.30768 8.18182 5.72727C8.18182 6.17914 8.54813 6.54545 9 6.54545L9.1036 6.53995C9.51051 6.49269 9.82636 6.14687 9.82636 5.72727Z"
        fill="#0363C4"
      />
    </svg>
  );
}

export default React.memo(InfoIcon);
