import * as lastOrdersEvents from "../../stores/lastOrders/LastOrdersEvents";
import * as LastOrdersService from "../../services/lastOrders/LastOrdersService";

export function execute(accountId: string, vendorId?: string): Promise<void> {
  lastOrdersEvents.getLastOrders(null);

  return LastOrdersService.getLastOrders(accountId, vendorId)
    .then((response) => {
      lastOrdersEvents.getLastOrdersDone(response);
    })
    .catch(() => {
      lastOrdersEvents.getLastOrdersFail(null);
    });
}
