import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { InputBase } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import SearchIcon from "../../assets/newIcons/SearchIcon";
import { productSearchInteraction } from "../../config/typewriter";
import { useAnalytics } from "../../analytics/useAnalytics";
import { ENTER_KEY_CODE } from "../../config/constants";
import searchBarStyles from "./SearchBarStyles";

interface Props {
  pocSearched: string;
  setPocSearched: (pocSearched: string) => void;
  onSearchByPocSearched: (searchMethod: string) => void;
  clearSearch?: () => void;
  testId: string;
  placeHolder?: string;
  cleanButton?: boolean;
  productSearch?: boolean;
  disabled?: boolean;
}

export enum SEARCH_METHOD {
  ENTER_KEY = "enter",
  SEARCH_BUTTON = "go_button",
}

const SearchBar: React.FC<Props> = ({
  pocSearched,
  setPocSearched,
  clearSearch,
  testId,
  placeHolder,
  cleanButton,
  productSearch,
  disabled,
  onSearchByPocSearched,
}) => {
  const classes = searchBarStyles();
  const { dispatchGenericEvent } = useAnalytics();
  let valueStatic = "";
  const firstRender = useRef(true);
  const [isSearched, setIsSearched] = useState(false);

  const setIsSearchedWithValue = () => {
    if (pocSearched && pocSearched !== "") {
      setIsSearched(true);
    }
  };

  const handleKeyDown = () => {
    setIsSearchedWithValue();
    onSearchByPocSearched(SEARCH_METHOD.ENTER_KEY);
  };

  function renderButton(): JSX.Element {
    if (isSearched && cleanButton) {
      return (
        <IconButton
          className={classes.searchClearContainer}
          onClick={() => {
            valueStatic = pocSearched;
            setPocSearched("");
            setIsSearched(false);
          }}
          data-testid="clear-button"
        >
          <CloseIcon style={{ width: 14, height: 14 }} />
        </IconButton>
      );
    }
    return <></>;
  }

  useEffect(() => {
    /* istanbul ignore next */
    if (pocSearched === "" && !firstRender.current && productSearch) {
      dispatchGenericEvent(productSearchInteraction, {
        button_label: "clear-button",
        button_name: "Clear Search",
        search_query: valueStatic,
      });

      if (clearSearch) {
        clearSearch();
      } else {
        onSearchByPocSearched(SEARCH_METHOD.SEARCH_BUTTON);
      }
      /* else {
       onSearch(SEARCH_METHOD.SEARCH_BUTTON);
      } */
    }
    firstRender.current = false;
  }, [
    pocSearched,
    clearSearch,
    valueStatic,
    dispatchGenericEvent,
    productSearch,
    onSearchByPocSearched,
  ]);

  return (
    <div
      className={classes.searchInputContainer}
      style={disabled ? { cursor: "not-allowed" } : undefined}
    >
      <SearchIcon />
      <InputBase
        disabled={disabled}
        placeholder={placeHolder}
        value={pocSearched}
        onChange={(e) => {
          setPocSearched(e.target.value);
        }}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.keyCode === ENTER_KEY_CODE) handleKeyDown();
        }}
        data-testid={`${testId}-input`}
        classes={{
          input: classNames({
            [classes.searchInput]: !disabled,
            [classes.searchInputBlocked]: disabled,
          }),
        }}
      />
      {renderButton()}
    </div>
  );
};

SearchBar.defaultProps = {
  cleanButton: true,
  placeHolder: "",
  disabled: false,
};
export default SearchBar;
