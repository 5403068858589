import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      gap: "8px",
    },
    box: {
      padding: "8px 16px",
      backgroundColor: "#FFFFFF",
      border: "1px solid #DBDADA",
      borderRadius: "8px",
      fontFamily: "Work Sans",
      fontWeight: 500,
      fontSize: "14px",
      color: "#141414",
      cursor: "pointer",
      userSelect: "none",
      boxSizing: "border-box",
    },
    selected: {
      border: "2px solid #047AF1",
    },
    disabled: {
      cursor: "not-allowed",
      backgroundColor: "#DBDADA",
      color: "#999999",
    },
  }),
);
