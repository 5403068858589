import { createStore } from "effector";
import {
  sendCustomerNotes,
  getCustomerNotes,
  setCustomerNotes,
} from "./CustomerNotesEvents";
import CustomerNotesState from "./CustomerNotesState";

const initialState = <CustomerNotesState>{
  isLoadingSendNotes: false,
  errorSendNotes: false,
  doneSendNotes: false,

  isLoadingGetNotes: false,
  doneGetNotes: false,
  errorGetNotes: false,
  customerNotes: null,
};

const CustomerNotesStore = createStore(initialState)
  .on(sendCustomerNotes, (state) => ({
    ...state,
    isLoadingSendNotes: true,
  }))
  .on(sendCustomerNotes.fail, (state) => ({
    ...state,
    isLoadingSendNotes: false,
    doneSendNotes: true,
    errorSendNotes: true,
  }))
  .on(sendCustomerNotes.done, (state) => ({
    ...state,
    isLoadingSendNotes: false,
    doneSendNotes: true,
    errorSendNotes: false,
  }))

  .on(getCustomerNotes, (state: CustomerNotesState) => ({
    ...state,
    isLoadingGetNotes: true,
    doneGetNotes: false,
    errorGetNotes: false,
  }))
  .on(getCustomerNotes.fail, (state: CustomerNotesState) => ({
    ...state,
    isLoadingGetNotes: false,
    doneGetNotes: true,
    errorGetNotes: true,
  }))
  .on(getCustomerNotes.done, (state: CustomerNotesState, { result }) => ({
    ...state,
    isLoadingGetNotes: false,
    doneGetNotes: true,
    errorGetNotes: false,
    customerNotes: result,
  }))
  .on(setCustomerNotes, (state: CustomerNotesState, payload) => ({
    ...state,
    customerNotes: {
      ...state.customerNotes,
      agentEmail: state.customerNotes?.agentEmail,
      note: payload,
    },
  }));

export default CustomerNotesStore;
