import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ListItem, Grid, Box, Typography } from "@material-ui/core";
import { ListChildComponentProps } from "react-window";
import { useCollapsibleSideNavContext } from "../../../../../contexts/CollapsibleSideNavContext";
import Data from "../../../../../components/data/Data";
import NewOfferingItemStyles from "./NewOfferingItemStyles";
import NewOfferings from "../../../../../domains/newOfferings/NewOfferings";
import GenericTag from "../../../../../components/tag/GenericTag";
import { PROPS_TAG } from "../../../../../components/tag/constants";

const { MARKETPLACE } = PROPS_TAG;

const offeringImagePlaceholder =
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  require("../../../../../assets/images/ProductPlaceholder.png") as string;

interface Props {
  offeringItem: NewOfferings;
  childComponentProps: ListChildComponentProps;
  displayMarketplace: boolean;
}
const NewOfferingItem: React.FunctionComponent<Props> = ({
  offeringItem,
  childComponentProps,
  displayMarketplace,
}) => {
  const classes = NewOfferingItemStyles();
  const { collapsibleIsOpen } = useCollapsibleSideNavContext();
  const { t } = useTranslation();

  const [src, setSrc] = useState(offeringItem.image);
  const totalItemsClass = () => {
    return displayMarketplace && offeringItem.hasMarketplace
      ? classes.offeringTotalWrapperWithMarketplace
      : classes.offeringTotalWrapper;
  };
  return (
    <ListItem
      className={classes.offeringList}
      style={childComponentProps.style}
    >
      <Box className={classes.offeringContainer}>
        <Grid item container direction="row" wrap="nowrap">
          <div className={classes.offeringImage}>
            <img
              src={src || offeringImagePlaceholder}
              onError={() => setSrc(offeringImagePlaceholder)}
              alt=""
            />
          </div>
          <Grid item container direction="column">
            <Grid
              item
              container
              direction={collapsibleIsOpen ? "column" : "row"}
              justifyContent="space-between"
              alignItems="flex-start"
              data-testid="offering-item-name-grid"
            >
              <Data
                ellipsisSize={collapsibleIsOpen ? 50 : 30}
                className={classes.offeringName}
                value={offeringItem.name}
                type="text"
                testId={`offering-item-name-${offeringItem.sku}`}
              />
            </Grid>
            {displayMarketplace && offeringItem.hasMarketplace && (
              <GenericTag
                translationKey={MARKETPLACE.translationKey}
                style={{
                  width: "60%",
                  maxWidth: "80px",
                  marginBottom: 5,
                  backgroundColor: MARKETPLACE.backgroundColor,
                }}
                testId={`new-offerings-${MARKETPLACE.testId}`}
              />
            )}
            <Typography
              className={classes.offeringUnitPrice}
              variant="subtitle2"
            >
              <span>{`${t("NewOfferings.PRICE_UNIT_PRODUCT")}: `}</span>
              <Data
                value={offeringItem.price}
                type="currency"
                testId={`offering-item-unit-price-${offeringItem.sku}`}
              />
            </Typography>
            <Typography
              className={classes.offeringSku}
              data-testid={`offering-item-sku-${offeringItem.sku}`}
              variant="subtitle2"
            >
              {offeringItem.sku}
            </Typography>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              className={totalItemsClass()}
            >
              <Grid item className={classes.offeringQuantityContainer}>
                <Data
                  className={classes.offeringQuantity}
                  value={offeringItem.quantity}
                  testId={`offering-item-quantity-input-${offeringItem.sku}`}
                />
              </Grid>
              <Data
                className={classes.offeringPrice}
                value={offeringItem.quantity * offeringItem.price}
                type="currency"
                testId={`offering-item-total-price-${offeringItem.sku}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ListItem>
  );
};

export default NewOfferingItem;
