import React, { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
}

const ArrowLeft: React.FunctionComponent<Props> = ({ style }) => (
  <svg
    style={style}
    width="14px"
    height="14px"
    viewBox="0 0 64 64"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M21.5756339,1.00420532 C22.9145743,-0.334735107 25.0854257,-0.334735107 26.4243661,1.00420532 C27.6603111,2.24015033 27.7553838,4.18494252 26.7095842,5.52994095 L26.4243661,5.85293754 L8.28,24 L26.4243661,42.1470625 C27.6603111,43.3830075 27.7553838,45.3277997 26.7095842,46.6727981 L26.4243661,46.9957947 C25.1884211,48.2317397 23.2436289,48.3268124 21.8986305,47.2810128 L21.5756339,46.9957947 L1.00420532,26.4243661 C-0.23173969,25.1884211 -0.326812383,23.2436289 0.718987242,21.8986305 L1.00420532,21.5756339 L21.5756339,1.00420532 Z"
        id="path-1-arrow-left"
      ></path>
    </defs>
    <g
      id="___Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="ZZ_Color-Override/Primary/Black"
        transform="translate(18.000000, 8.000000)"
      >
        <mask id="mask-2-arrow-left" fill="white">
          <use xlinkHref="#path-1-arrow-left"></use>
        </mask>
        <g id="Mask-arrow-left" fillRule="nonzero"></g>
        <g
          id="ZZ_Color-Override/05_Interface/Neutrals/1-LabelPrimary"
          mask="url(#mask-2-arrow-left)"
          fill="#000000"
          fillOpacity="0.9"
        >
          <g transform="translate(-18.000000, -8.000000)" id="Rectangle">
            <rect x="0" y="0" width="64" height="64"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowLeft;
