import React, { useCallback } from "react";
import { IconButton } from "@material-ui/core";
import { Copy } from "@hexa-ui/icons";
import { useStore } from "effector-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyButtonStyles from "./CopyButton.styles";
import {
  setLastCalledOrCopiedPhone,
  setLastCopiedPhone,
} from "../../../../../../../../stores/phoneRankingStore/phoneRankedEvents";
import { conditionalClass } from "../../../../../../../../config/utils/functions";
import { phoneCopied } from "../../../../../../../../config/typewriter";
import AgentCallStore from "../../../../../../../../stores/agentCall/AgentCallStore";
import { useAnalytics } from "../../../../../../../../analytics/useAnalytics";

interface CopyButtonProps {
  sanitizedPhone: string;
  score: number;
  index: number;
  disableButton: boolean;
  isConnected: boolean;
  setNeedClassification: (value: boolean) => void | undefined;
  handleCopyButtonClick: () => void;
}

const CopyButton = ({
  sanitizedPhone,
  score,
  index,
  disableButton,
  isConnected,
  setNeedClassification,
  handleCopyButtonClick,
}: CopyButtonProps): JSX.Element => {
  const classes = CopyButtonStyles();

  const agentCallState = useStore(AgentCallStore);
  const { dispatchGenericEvent } = useAnalytics();

  const onCopyNumber = useCallback(
    (index, score) => {
      const { clientId: accountId } = agentCallState;
      const { callId } = agentCallState;

      dispatchGenericEvent(phoneCopied, {
        call_id: callId,
        poc_id: accountId,
        phone_rank: index + 1,
        phone_score: score,
      });
    },
    [dispatchGenericEvent, agentCallState],
  );

  const renderCopyButton = () => {
    return !isConnected ? (
      <CopyToClipboard
        data-testid={`rank-table-copy-to-clipboard-container-${index}`}
        text={sanitizedPhone}
        onCopy={() => {
          onCopyNumber(index, score);
          handleCopyButtonClick();
          setLastCopiedPhone(sanitizedPhone);
          setLastCalledOrCopiedPhone(sanitizedPhone);
          setNeedClassification(false);
        }}
      >
        <IconButton
          className={`${classes.iconButton} ${conditionalClass(
            disableButton,
            classes.iconButtonDisabled,
          )}`}
          disabled={disableButton}
          data-testid={`rank-table-copy-to-clipboard-button-${index}`}
        >
          <Copy />
        </IconButton>
      </CopyToClipboard>
    ) : (
      <></>
    );
  };

  return renderCopyButton();
};

export default CopyButton;
