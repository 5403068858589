import * as GapCalculationUseCase from "@usecase/gap/GapCalculationUseCase";
import { useLogService } from "admin-portal-shared-services";
import AgentCall from "@domains/AgentCall";
import { FAILED_GAP_CALCULATION } from "@config/logMessages";
import { CALL_ID } from "../../../config/constants";
import {
  AGENT_CALL_SERVICE_UNAVAILABLE,
  BU_CONFIG_UNAVAILABLE,
} from "../../../config/errorCodes";
import {
  GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
  GROW_ORGANIZATION_ACTIVATION,
  isFeatureEnabledV2,
} from "../../../config/featureToggles";
import {
  getAccountIdFromAgentCallResponse,
  setPocVendorId,
} from "../../../config/utils/functions";
import GetNextPocPayload from "../../../domains/GetNextPocPayload";
import * as GetNextPocService from "../../../services/nextPoc/getNextPoc/GetNextPocService";
import GlobalStore from "../../../stores/global/GlobalStore";
import * as getNextPocEvents from "../../../stores/nextPoc/getNextPoc/GetNextPocEvents";
import {
  getSponsoredMissionsEvent,
  setSponsoredMissionsIds,
} from "../../../stores/sponsored/SponsoredEvents";
import { getTagsEvent, getTabsEvent } from "../../../stores/tags/TagsEvents";
import VendorStore from "../../../stores/vendor/VendorStore";

export function execute(
  request: GetNextPocPayload,
  screenSection: string,
  keyToggle: string,
): Promise<void> {
  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();
  const log = useLogService();

  const isLocalAndGlobalMissionsServiceEnable = isFeatureEnabledV2(
    GROW_LOCAL_GLOBAL_MISSIONS_ORDER,
    user.keyToggle,
  );

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    user.keyToggle,
  );

  const getGapCalculation = async (
    response: AgentCall,
    screenSection: string,
  ) => {
    try {
      const accountId = getAccountIdFromAgentCallResponse(response);
      const gap = await GapCalculationUseCase.execute(
        response.countryCode,
        response.vendorId,
        accountId,
      );
      getNextPocEvents.getNextPocDone({
        ...response,
        screenSection,
        gap,
      });
    } catch (e) {
      log.error(FAILED_GAP_CALCULATION, e);
    }
  };

  getNextPocEvents.getNextPoc(null);

  return GetNextPocService.getNextPoc(request, keyToggle)
    .then((response) => {
      localStorage.setItem(CALL_ID, response.callId);
      getGapCalculation(response, screenSection);

      if (isOrganizationConfigEnabled) {
        setPocVendorId(response.vendorId);
      }

      if (!isLocalAndGlobalMissionsServiceEnable) {
        if (response.tagsList && response.tagsList.length > 0)
          getTagsEvent(response.tagsList);
        else {
          getTagsEvent([]);
        }
        /* istanbul ignore else */
        if (response.tabs && response.tabs.length > 0)
          getTabsEvent(response.tabs);
        /* istanbul ignore else */
        if (
          response.tabs?.some((tab) => tab === "sponsored") &&
          response.sponsoredMissionsIds?.length
        ) {
          setSponsoredMissionsIds(response.sponsoredMissionsIds);
          getSponsoredMissionsEvent({
            clientId: response.clientId,
            missionsIds: response.sponsoredMissionsIds,
            vendorId: vendor?.id,
          });
        }
      }
    })
    .catch((error) => {
      let message;
      const code = error?.response?.data.code;
      const infoError = error?.config?.headers?.requestTraceId;

      if (code === AGENT_CALL_SERVICE_UNAVAILABLE) {
        message = "NextPoc.AGENT_CALL_SERVICE_UNAVAILABLE";
      } else if (code === BU_CONFIG_UNAVAILABLE) {
        message = "NextPoc.BU_CONFIG_UNAVAILABLE";
      } else {
        message = "NextPoc.CALL_LIST_SERVICE_UNAVAILABLE";
      }

      getNextPocEvents.getNextPocFail([message, infoError]);
      /* istanbul ignore else */
      if (!isLocalAndGlobalMissionsServiceEnable) {
        getTagsEvent([]);
        getTabsEvent([]);
      }
    });
}
