import CurrencyCountry from "../domains/CurrencyCountry";

export const CURRENCY: { [key: string]: CurrencyCountry } = {
  DO: { currency: "DOP", prefix: "RD$" },
  BR: { currency: "BRL", prefix: "R$" },
  US: { currency: "USD", prefix: "$" },
  EC: { currency: "USD", prefix: "$" },
  ES: { currency: "EUR", prefix: "€" },
  CO: { currency: "COP", prefix: "$" },
  ZA: { currency: "ZAR", prefix: "R" },
  PE: { currency: "PEN", prefix: "S/" },
  MX: { currency: "MXN", prefix: "$" },
  PA: { currency: "PAB", prefix: "B/." },
  HN: { currency: "HNL", prefix: "L" },
  SV: { currency: "USD", prefix: "$" },
  UY: { currency: "UYU", prefix: "$" },
  PY: { currency: "PYG", prefix: "Gs." },
  AR: { currency: "ARS", prefix: "$" },
  PH: { currency: "PHP", prefix: "₱" },
  ID: { currency: "IDR", prefix: "Rp" },
};
