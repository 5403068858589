import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    container: {
      margin: 8,
    },
    content: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    noItemsTypography: {
      color: "#707372",
      fontWeight: 500,
    },
    productsContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 20px 1fr",
      justifyItems: "center",
      width: "100%",
    },
    verticalDivider: {
      height: "212px",
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      margin: "14px 0",
      "&:last-child": {
        display: "none",
      },
    },
    productCardAndHorizontalDivider: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  }),
);
