import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles(({ palette }) =>
  createStyles({
    title: {
      whiteSpace: "nowrap",
      fontSize: "10px",
      fontWeight: 500,
      color: palette.text.primary,
      padding: "0px 5px 0px 7px",
      flex: 1,
    },
    cartItemNotFound: {
      fontSize: "10px",
      fontWeight: 500,
      color: palette.error.main,
      marginRight: "5px",
    },
    comboTitleContainer: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gap: "7px",
    },
    comboDeleteButton: {
      color: "#ccc",
      padding: "0px 10px",
    },
    comboItemNameEllipsis: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: 12,
      color: palette.text.primary,
      fontWeight: "normal",
      lineHeight: 1.2,
    },
    comboContainer: {
      marginLeft: "56px",
      padding: "0px 13px",
      width: "auto",
    },
    comboItemDescription: {
      color: "rgba(0,0,0,0.55)",
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: "18px",
      letterSpacing: "0.03px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      whiteSpace: "normal",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      width: "fit-content",
      marginBottom: "5px",
    },
    comboProductContainer: {
      marginTop: "4px",
    },
    comboProductContainerDetails: {
      display: "grid",
    },
    comboProductTitle: {
      color: "rgba(0,0,0,0.9)",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "normal",
      width: "fit-content",
      display: "-webkit-box",
      WebkitLineClamp: 1,
      WebkitBoxOrient: "vertical",
    },
    comboProductDescription: {
      color: "rgba(0,0,0,0.55)",
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "0.03px",
    },
    divider: {
      marginTop: "4px",
    },
    skuTitle: {
      fontSize: "12px",
      color: palette.text.secondary,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textAlign: "left",
      lineHeight: 1,
      marginRight: "4px",
    },
    tagPosition: {
      marginTop: "2px",
      display: "grid",
      gridTemplateColumns: "fit-content(calc(100% - 45px)) 45px",
    },
    iconButton: {
      width: "fit-content",
      justifyContent: "start",
    },
  }),
);
