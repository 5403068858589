import { Product } from "../../../../domains/products/Product";

interface PropsReturned {
  priceAndName: {
    pricePerUnit?: number;
    namePerUnit?: string;
  };
  discountRangesValues?: {
    quantity: string;
    discount: number;
    price: number;
  }[];
}

interface PropsProductCardV3 {
  product: Product;
  itemQuantity: number;
}

interface PriceDetails {
  preferredPrice: boolean;
  price: number;
}

const useProductCardV3 = ({
  product,
  itemQuantity,
}: PropsProductCardV3): PropsReturned => {
  const auxPriceDetailsContainer = product.priceDetails?.container;
  const auxPriceDetailsUom = product.priceDetails?.uom;

  const discountRangesValues = product.prices?.map((price) => {
    return {
      quantity: `${price.stepStart}-${price.stepEnd}`,
      discount: price.discountAmount,
      price: price.discountedPrice,
    };
  });

  const priceDetailsAvailable = (priceDetails?: PriceDetails) => {
    return (
      priceDetails?.preferredPrice &&
      priceDetails?.price !== null &&
      priceDetails?.price > 0
    );
  };

  const controlPriceAndName = (
    priceDetailsContainer?: PriceDetails,
    priceDetailsUom?: PriceDetails,
  ) => {
    const priceDetailsContainerAvailable = priceDetailsAvailable(
      priceDetailsContainer,
    );
    const priceDetailsUomAvailable = priceDetailsAvailable(priceDetailsUom);

    if (priceDetailsContainerAvailable && !priceDetailsUomAvailable) {
      return {
        pricePerUnit: priceDetailsContainer?.price,
        namePerUnit: product.container?.name,
      };
    }

    if (priceDetailsUomAvailable && !priceDetailsContainerAvailable) {
      return {
        pricePerUnit: priceDetailsUom?.price,
        namePerUnit: product.container?.unitOfMeasurement,
      };
    }

    return { pricePerUnit: -1, namePerUnit: "" };
  };

  if (product.isOnSale && product.discountType === "STEPPED_DISCOUNT") {
    const indexRange = product.ranges.findIndex(
      (item) => itemQuantity >= item.stepStart && itemQuantity <= item.stepEnd,
    );

    if (indexRange === -1) {
      return {
        priceAndName: controlPriceAndName(
          auxPriceDetailsContainer,
          auxPriceDetailsUom,
        ),
        discountRangesValues,
      };
    }
    const priceDetailsContainer = product.ranges[indexRange].container;
    const priceDetailsUom = product.ranges[indexRange].uom;

    return {
      priceAndName: controlPriceAndName(priceDetailsContainer, priceDetailsUom),
      discountRangesValues,
    };
  }

  return {
    priceAndName: controlPriceAndName(
      auxPriceDetailsContainer,
      auxPriceDetailsUom,
    ),
    discountRangesValues,
  };
};

export default useProductCardV3;
