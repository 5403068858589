import { createStore } from "effector";
import {
  getLastOrders,
  getLastOrdersDone,
  getLastOrdersFail,
} from "./LastOrdersEvents";
import LastOrdersState from "./LastOrdersState";
import LastOrders from "../../domains/LastOrders";

const initialState = <LastOrdersState>{
  isLoading: false,
  error: false,
  orders: [],
};

const lastOrdersStore = createStore(initialState)
  .on(getLastOrders, (state) => ({ ...state, isLoading: true }))
  .on(getLastOrdersDone, (state: LastOrdersState, orders: LastOrders) => ({
    ...state,
    ...orders,
    isLoading: false,
    error: false,
  }))
  .on(getLastOrdersFail, (state: LastOrdersState) => ({
    ...state,
    isLoading: false,
    error: true,
  }));

export default lastOrdersStore;
