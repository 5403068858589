import SoftphoneWSDomain, {
  WebSocketType,
} from "../../domains/cti/SoftphoneWSDomain";
import * as SoftphoneCtiService from "../../services/cti/SoftphoneCtiService";
import { SoftphoneWS } from "../../domains/cti/SoftphoneWS";
import { WEB_SOCKECT_URI } from "../../config/constants";

export function connectWS(): SoftphoneWS {
  const uri = WEB_SOCKECT_URI;
  const websocket = <SoftphoneWSDomain>{
    uri,
    type:
      process.env.NODE_ENV === "development"
        ? WebSocketType.MQTT
        : WebSocketType.WEBSOCKET,
  };
  return SoftphoneCtiService.connectWebSocket(websocket);
}
