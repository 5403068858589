import { createStore } from "effector";

import RedeemItemsDb from "../../routes/call/redeemItemsDb/RedeemItemsDb";
import * as RedeemEvents from "./RedeemEvents";
import RedeemState from "./RedeemState";

const initialState: RedeemState = {
  enrolledSince: "",
  noDataAvailableError: false,
  retryError: false,
  lastRedeem: "",
  loadingEnrolledSince: false,
  loadingLastRedeem: false,
  loadingRedeemItems: false,
  redeemItems: [],
  redeemItemsIds: [],
};

const RedeemStore = createStore(initialState)
  .on(RedeemEvents.getEnrolledSince, (state: RedeemState) => ({
    ...state,
    loadingEnrolledSince: true,
    noDataAvailableError: false,
  }))
  .on(RedeemEvents.getEnrolledSince.done, (state: RedeemState, { result }) => {
    return {
      ...state,
      loadingEnrolledSince: false,
      enrolledSince: result?.enrolledSince,
    };
  })
  .on(RedeemEvents.getEnrolledSince.fail, (state: RedeemState) => ({
    ...state,
    loadingEnrolledSince: false,
    noDataAvailableError: true,
  }))
  .on(RedeemEvents.getLastRedeem, (state: RedeemState) => ({
    ...state,
    loadingLastRedeem: true,
    noDataAvailableError: false,
  }))
  .on(RedeemEvents.getLastRedeem.done, (state: RedeemState, { result }) => {
    return {
      ...state,
      loadingLastRedeem: false,
      lastRedeem: result?.lastRedeem,
    };
  })
  .on(RedeemEvents.getLastRedeem.fail, (state: RedeemState) => ({
    ...state,
    loadingLastRedeem: false,
    noDataAvailableError: true,
  }))
  .on(RedeemEvents.getRedeemItems, (state: RedeemState) => ({
    ...state,
    loadingRedeemItems: true,
    retryError: false,
  }))
  .on(RedeemEvents.getRedeemItems.done, (state: RedeemState, { result }) => {
    const redeemItems = result?.redeemableItems || [];
    const itemsId = redeemItems.map((item) => item.id);
    return {
      ...state,
      loadingRedeemItems: false,
      redeemItems,
      redeemItemsIds: itemsId,
      retryError: false,
    };
  })
  .on(RedeemEvents.getRedeemItems.fail, (state: RedeemState) => ({
    ...state,
    loadingRedeemItems: false,
    retryError: true,
  }))
  .on(RedeemEvents.setRedeemItems, (state: RedeemState, value) => {
    const itemsId = value.map((item) => item.id);
    return {
      ...state,
      loadingRedeemItems: false,
      retryError: false,
      redeemItems: value,
      redeemItemsIds: itemsId,
    };
  })
  .on(RedeemEvents.clearRedeemItems, (state: RedeemState) => {
    new RedeemItemsDb().redeemItems.toCollection().delete();

    return {
      ...state,
      redeemItems: [],
      redeemItemsIds: [],
      retryError: false,
      loadingRedeemItems: false,
    };
  });

export default RedeemStore;
