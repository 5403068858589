import { createEffect, createEvent } from "effector";
import { GetFlexibleMissionsUseCase } from "@usecase/missions/getFlexibleMissionsUseCase/GetFlexibleMissionsUseCase";
import { SponsoredItemsOrCombosResponse } from "../../domains/sponsored/SponsoredMissionServiceResponse";
import { execute as getSponsoredMissionsUC } from "../../usecase/sponsored/GetSponsoredMissionsUC";
import { execute as getSponsoredProductsDetailUC } from "../../usecase/sponsored/GetSponsoredProductsDetailUC";

interface GetSponsoredMissionEventRequest {
  clientId: string;
  missionsIds: string[];
  vendorId?: string;
}

export const getSponsoredMissionsEvent = createEffect({
  async handler(props: GetSponsoredMissionEventRequest) {
    return getSponsoredMissionsUC(
      props.clientId,
      props.missionsIds,
      props.vendorId,
    );
  },
});

export const setSponsoredMissionsIds = createEffect({
  async handler(missionsIds: string[]) {
    return missionsIds;
  },
});

export const setLocalMissionsIds = createEffect({
  async handler(missionsIds: string[]) {
    return missionsIds;
  },
});

export const setFlexibleMissionsIds = createEvent<string[]>(
  "setFlexibleMissionsIds",
);

export const resetGetSponsoredMissionsEventIsDone = createEvent(
  "resetGetSponsoredMissionsEventIsDone",
);

export interface GetSponsoredProductsDetailEventRequest {
  sponsoredId: string;
  items?: SponsoredItemsOrCombosResponse[];
  combos?: SponsoredItemsOrCombosResponse[];
}

export const getSponsoredProductsEvent = createEffect({
  async handler(props: GetSponsoredProductsDetailEventRequest) {
    return getSponsoredProductsDetailUC(
      props.sponsoredId,
      props.items || [],
      props.combos || [],
    );
  },
});

export const getFlexibleMissionsByIdsEffect = createEffect({
  async handler(flexibleMissionIds: string[]) {
    return GetFlexibleMissionsUseCase(flexibleMissionIds);
  },
});

export const resetSponsoredEvents = createEvent("resetSponsoredEvents");
