import { createEffect, createEvent } from "effector";
import * as DeliveryWindowsUseCase from "../../usecase/deliveryWindows/DeliveryWindowsUseCase";
import {
  DELIVERY_METHODS,
  DeliveryRange,
} from "../../domains/fulfillment/Fulfillment";

export const getDeliveryWindowsEffect = createEffect({
  async handler(accountId: string) {
    return DeliveryWindowsUseCase.execute(accountId);
  },
});

export const setDeliveryMethod =
  createEvent<DELIVERY_METHODS>("setDeliveryMethod");

export const setDeliveryRange = createEvent<DeliveryRange>("setDeliveryRange");
