import { useLogService } from "admin-portal-shared-services";
import axiosRetry from "axios-retry";
import axios from "../../config/axios/axiosInstance";
import LastOrders from "../../domains/LastOrders";
import { getApiHost } from "../host/HostB2BService";
import { OrdersResponse } from "./domains/OrdersResponse";
import OrderResponseToLastOrdersConverter from "../../converters/OrderResponseToLastOrdersConverter";

export const ORDER_TRANSPARENCY_ENDPOINT =
  "/api/v1/order-transparency-service/v3";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
  retryCondition: (error) => {
    if (error) {
      return error.response?.status === 400 || error.response?.status === 401;
    }

    return false;
  },
});

interface RequestParams {
  beesAccountId: string;
  orderBy?: string;
  sort?: string;
  page: number;
  pageSize: number;
  vendorId?: string;
}

export function getLastOrders(
  beesAccountId: string,
  vendorId?: string,
): Promise<LastOrders> {
  const log = useLogService();

  const endpoint = `${getApiHost()}${ORDER_TRANSPARENCY_ENDPOINT}`;

  const params: RequestParams = {
    beesAccountId,
    page: 0,
    pageSize: 5,
    sort: "desc",
    orderBy: "placementDate",
    vendorId,
  };

  return axios
    .get<Array<OrdersResponse>>(`${endpoint}`, {
      params,
    })
    .then((response) => {
      log.info(`Convert orders received from ${endpoint} to our model`);
      return OrderResponseToLastOrdersConverter(response);
    })
    .catch((error) => {
      log.error("LastOrdersService - getLastOrders error: ", error);
      throw error;
    });
}
