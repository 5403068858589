import { createTheme } from "@material-ui/core/styles";

import palette from "./Palette";
import typography from "./Typography";
import overrides from "./Overrides";

const theme = createTheme({
  palette,
  typography,
  overrides,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1367,
      xl: 1920,
    },
  },
});

export default theme;
