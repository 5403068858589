import React from "react";
import { Box } from "@material-ui/core";
import style from "./BannerStyles";

const Banner: React.FunctionComponent<Props> = ({ url, testId }) => {
  const classes = style();

  return (
    <Box className={classes.container} data-testid={testId}>
      <img className={classes.image} src={url} alt="Campaign banner" />
    </Box>
  );
};

Banner.defaultProps = {
  testId: "banner-component",
};

interface Props {
  url: string;
  testId?: string;
}

export default React.memo(Banner);
