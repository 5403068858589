import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, TextLink } from "@hexa-ui/components";
import { ThemeProvider } from "@hexa-ui/theme";
import { Check } from "@hexa-ui/icons";
import { Typography } from "@material-ui/core";
import { useStore } from "effector-react";
import CheckWithDot from "../../../../../assets/newIcons/CheckWithDot";
import { PocAccepted, InfoContainer } from "./SponsoredCardFooter.styles";
import GlobalStore from "../../../../../stores/global/GlobalStore";
import {
  GROW_TECH_LOCAL_TO_CAMPAIGN_POC_ACCEPTANCE,
  isFeatureEnabledV2,
} from "../../../../../config/featureToggles";

interface Props {
  handleSetPocAccepted?: () => void;
  pocAccepted?: boolean;
}

export function SponsoredCardFooter({
  handleSetPocAccepted,
  pocAccepted,
}: Props): React.ReactElement {
  const { t } = useTranslation();
  const {
    user: { keyToggle },
  } = useStore(GlobalStore);

  const isPocAcceptanceEnabled = isFeatureEnabledV2(
    GROW_TECH_LOCAL_TO_CAMPAIGN_POC_ACCEPTANCE,
    keyToggle,
  );

  const iconCheck = useCallback(() => {
    return <Check size="medium" />;
  }, []);

  return !pocAccepted ? (
    <ThemeProvider theme="grow">
      <Button
        icon={iconCheck}
        size="small"
        variant="primary"
        leading
        style={{ padding: "0.4rem", paddingRight: "1rem" }}
        data-testid="button-poc-accepted"
        onClick={handleSetPocAccepted}
      >
        {t(
          isPocAcceptanceEnabled
            ? "LocalMission.MARK_DONE"
            : "LocalMission.POC_ACCEPTED",
        )}
      </Button>
    </ThemeProvider>
  ) : (
    <PocAccepted>
      <CheckWithDot />
      <Typography
        variant="h2"
        style={{
          paddingTop: "0.2rem",
          paddingLeft: "0.5rem",
          fontWeight: "500",
        }}
      >
        {t("LocalMission.MISSION_ACCOMPLISHED")}
      </Typography>
      <InfoContainer>
        <TextLink
          hasUnderline={false}
          size="small"
          data-testid="button-undo-poc-accepted"
          onClick={handleSetPocAccepted}
        >
          {t("LocalMission.UNDO")}
        </TextLink>
      </InfoContainer>
    </PocAccepted>
  );
}
