import { Contacts } from "../../services/pocInformation/domains/Contacts";

export const MOCK_EMAIL_PHONE: Array<Contacts> = [
  {
    type: "EMAIL",
    value: "teste1@mailinator.com",
  },
  {
    type: "EMAIL",
    value: "teste2@mailinator.com",
  },
  {
    type: "EMAIL",
    value: "teste3@mailinator.com",
  },
  {
    type: "EMAIL",
    value: "teste4@mailinator.com",
  },
  {
    type: "EMAIL",
    value: "teste5-break-ext-abinbev@mailinator.com",
  },
  {
    type: "PHONE",
    value: "2679872341",
  },
  {
    type: "PHONE",
    value: "3679872341",
  },
  {
    type: "PHONE",
    value: "4679872341",
  },
  {
    type: "PHONE",
    value: "5679872341",
  },
  {
    type: "PHONE",
    value: "7679872341",
  },
  {
    type: "PHONE",
    value: "8679872341",
  },
  {
    type: "PHONE",
    value: "1579872341",
  },
  {
    type: "PHONE",
    value: "1479872341",
  },
  {
    type: "PHONE",
    value: "9679872341",
  },
  {
    type: "PHONE",
    value: "1079872341",
  },
  {
    type: "PHONE",
    value: "1179872341",
  },
  {
    type: "PHONE",
    value: "1279872341",
  },
];
