import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    wrapper: {
      height: "100%",
      flex: 1,
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      marginTop: "20px",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      fontSize: "20px",
      whiteSpace: "nowrap",
      height: "100%",
      fontWeight: 600,
      color: theme.palette.secondary.main,
      textTransform: "none",
      margin: 0,
      marginLeft: "5px",
    },

    headerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      paddingBottom: "9px",
      marginBottom: "16px",
    },
    container: {
      marginTop: "20px",
    },
    containerDelivery: {
      marginTop: "20px",
      overflow: "hidden",
    },
    infoTitles: {
      color: theme.palette.secondary.main,
      fontWeight: "bold",
      fontSize: "12px",
      marginBottom: "8px",
    },
    infoTitle: {
      color: theme.palette.secondary.main,
      fontWeight: "bold",
      fontSize: "12px",
      marginBottom: "4px",
    },
    infoHint: {
      color: theme.palette.text.secondary,
      fontSize: "12px",
      marginBottom: "8px",
    },
    adress: {
      color: "#7c7c7c",
      fontSize: "12px",
      fontWeight: 500,
      textTransform: "none",
    },
    line: {
      border: "1px solid #f49a30",
    },
    cartContainer: {
      display: "flex",
    },
    leftContainer: {
      maxWidth: "200px",
      paddingRight: "30px",
    },
    cartSummaryArea: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      gap: "1rem",
    },
    cartSummaryContainer: {
      width: "99%",
      borderRadius: 12,
      boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.16)",
      padding: "1rem",
      backgroundColor: "#fff",
    },
    text: {
      width: "170px",
      height: "30px",
      borderRadius: "3px",
      fontSize: "10px",
      resize: "none",
      padding: "10px",
      outline: "none",
      overflow: "hidden",
      "&:focus": {
        border: `solid 1px ${theme.palette.common.black}`,
      },
      "&::placeholder": {
        color: "#d0d0d0",
      },
    },
    textarea: {
      width: "170px",
      borderRadius: "3px",
      fontSize: "10px",
      resize: "none",
      height: "72px",
      padding: "10px",
      outline: "none",
      "&:focus": {
        border: `solid 1px ${theme.palette.common.black}`,
      },
      "&::placeholder": {
        color: "#d0d0d0",
      },
    },
    buttonContainer: {
      marginTop: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      lineHeight: "24px",
      padding: "8px 16px",
      borderRadius: "3px",
      width: "100%",
      color: "white",
      height: "40px",
    },
    disabledButton: {
      backgroundColor: "#eaeaea",
    },
    activeButton: {
      backgroundColor: theme.palette.secondary.main,
    },
    alternativeDateMessage: {
      borderRadius: "3px",
      backgroundColor: "#FBE9E9",
      color: "#212121",
      fontSize: "10px",
      marginTop: "10px",
      padding: "8px",
      textAlign: "justify",
      fontFamily: "Work Sans",
    },
    shippingSetup: {
      marginTop: "20px",
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
    shippingValues: {
      fontWeight: 500,
      flex: 0.5,
    },
    emptyList: {
      display: "flex",
      width: "100%",
      height: "115px",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "16px",
      padding: "5px",
      overflow: "hidden",
      flexGrow: 1,
      fontWeight: 500,
      color: "#505050",
      border: "solid 1px #eaeaea",
      "&:hover": {
        overflow: "auto",
      },
    },
    circularProgressWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "101px",
    },
    cartTitleCounterContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
      marginLeft: "2px",
      gap: "4px",
    },
    counter: {
      background: "#81FFAD !important",
    },
    orderSummaryDivider: {
      marginBottom: "4px",
    },
    fulfilledContainer: {
      display: "flex",
      alignItems: "center",
      gap: "0.25rem",
      margin: "0.375rem 0",
    },
  }),
);
