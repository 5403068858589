import React from "react";
import { useTranslation } from "react-i18next";
import { EmptyItem as EmptyItemType } from "../../../../../domains/cart/EmptyItem";
import EmptyItemStyles from "./EmptyItemStyles";

interface Props {
  item: EmptyItemType[];
}

const TotalizerEmptiesRequired: React.FunctionComponent<Props> = ({ item }) => {
  const classes = EmptyItemStyles();
  const { t } = useTranslation();

  const total = item.reduce(
    (totalEmpties, empties) =>
      totalEmpties + (empties.minimumRequired ? empties.minimumRequired : 0),
    0,
  );

  return (
    <div
      className={classes.totalContainer}
      data-testid="empties-required-total"
    >
      <div className={classes.totalRequired}>
        {t("Empties.REQUIRED_EMPTIES")}
      </div>
      <div
        className={classes.totalEmptiesRequired}
        data-testid="empties-total-container"
      >
        {`${total} ${t("Empties.UNITS")}`}
      </div>
    </div>
  );
};

export default React.memo(TotalizerEmptiesRequired);
