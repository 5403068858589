import { useLogService } from "admin-portal-shared-services";
import { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";

import axios from "@config/axios/axiosInstance";

import {
  isFeatureEnabledV2,
  TLP_MULTICONTRACT_ACCOUNT_SERVICE,
} from "../../../config/featureToggles";
import { TLP_ACCOUNT_SERVICE } from "../../../config/services";
import { PhoneSimple } from "../../../domains/phoneRanking/RankedPhones";
import GlobalStore from "../../../stores/global/GlobalStore";
import VendorStore from "../../../stores/vendor/VendorStore";
import {
  getApiHost,
  getV2EndPoint,
  getVendorIdParam,
} from "../../host/HostService";

export const ACCOUNT_SERVICE = `tlp-account`;
export const RANKED_PHONES_ENDPOINT = "ranked-phones";
export const CONTROLLER = "current-phone";

/* istanbul ignore next */
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => {
    return 1500;
  },
});

export default async function UpdatePhone(
  accountId: string,
  currentNumber: string,
  phone: PhoneSimple,
  vendorName?: string,
): Promise<AxiosResponse> {
  const log = useLogService();
  const { user } = GlobalStore.getState();
  const { vendor } = VendorStore.getState();

  const isTLPAccountMulticontract = isFeatureEnabledV2(
    TLP_MULTICONTRACT_ACCOUNT_SERVICE,
    user.zone,
  );

  const vendorIdMetadata = vendor?.id;
  const v2EndPoint = getV2EndPoint(isTLPAccountMulticontract);
  const vendorIdParam = getVendorIdParam(
    vendorIdMetadata,
    isTLPAccountMulticontract,
  );

  const endpoint = `${ACCOUNT_SERVICE}/${RANKED_PHONES_ENDPOINT}${v2EndPoint}/${accountId}/${CONTROLLER}/${currentNumber}${vendorIdParam}`;
  const url = `${getApiHost(TLP_ACCOUNT_SERVICE)}/${endpoint}`;

  const headers =
    vendorName === "BRF" && !isTLPAccountMulticontract
      ? {
          vendorId: vendorIdMetadata,
          vendorName,
        }
      : undefined;

  try {
    const response = await axios({
      method: "patch",
      url,
      data: phone,
      headers: {
        ...headers,
      },
    });

    return response;
  } catch (error) {
    log.error(error);
    throw error;
  }
}
