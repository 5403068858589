import NewOfferings from "../../../../../domains/newOfferings/NewOfferings";

export const hasSkuOrName = (item: NewOfferings, text: string): boolean => {
  const textToLowerCase = text.toLowerCase();
  return (
    (item.sku.toLowerCase() === textToLowerCase ||
      item.name.toLowerCase().includes(textToLowerCase)) &&
    textToLowerCase !== ""
  );
};
