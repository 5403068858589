import React from "react";

const PlusIcon: React.FunctionComponent = () => {
  return (
    <svg width="9px" height="9px" viewBox="0 0 9 9" version="1.1">
      <g
        id="Bees-Campaigns"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="SPRINT-64----B2O-V2-A-Copy"
          transform="translate(-346.000000, -202.000000)"
          fill="#505050"
        >
          <g id="Group-18" transform="translate(335.000000, 190.000000)">
            <g id="Minus-1" transform="translate(8.000000, 9.000000)">
              <g id="Group-19" transform="translate(3.000000, 3.000000)">
                <rect id="Rectangle" x="0" y="4" width="9" height="1" />
                <rect
                  id="Rectangle-Copy-8"
                  transform="translate(4.500000, 4.500000) rotate(-270.000000) translate(-4.500000, -4.500000) "
                  x="0"
                  y="4"
                  width="9"
                  height="1"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PlusIcon;
