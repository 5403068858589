import { useMemo } from "react";

import { useStore } from "effector-react";

import { getUserInfos } from "@config/utils/functions";

import { TARGET_KPI_TYPES } from "../../../config/constants";
import {
  isFeatureEnabled,
  isFeatureEnabledV2,
  TLP_MONTHLY_KPI_HIT_RATE,
  TLP_MONTHLY_KPI_HIT_RATE_ADMIN,
  TLP_MONTHLY_KPI_CALL_LIST,
  TLP_MONTHLY_KPI_CALL_LIST_ADMIN,
  TLP_MONTHLY_KPI_TOPLINE_UPLIFT,
  TLP_MONTHLY_KPI_AVERAGE_HANDLE_TIME,
  TLP_MONTHLY_KPI_AVERAGE_HANDLE_TIME_ADMIN,
  TLP_CTI,
  TLP_CTI_ADMIN,
  GROW_ORGANIZATION_ACTIVATION,
  TLP_KPI_AVERAGE_HANDLE_TIME,
  TLP_KPI_AVERAGE_HANDLE_TIME_ADMIN,
  TLP_KPI_TOPLINE_UPLIFT,
} from "../../../config/featureToggles";
import MonthlyKPIStore from "../../../stores/agentKPI/MonthlyKPIStore";
import UpdateKPIStore from "../../../stores/agentKPI/UpdateKPIStore";
import GlobalStore from "../../../stores/global/GlobalStore";

interface UseTargetKpis {
  isMonthlyKpiEnabled: boolean;
  dailyCallListTarget?: number | null;
  dailyAverageHandleTimeTarget?: string | null;
  dailyToplineUpliftTarget?: number;
  monthlyCallListTarget?: number;
  monthlyToplineUpliftTarget?: string;
  monthlyAverageHandleTimeTarget?: string | null;
  shouldRenderDailyAht: boolean;
  shouldRenderMonthlyAht: boolean;
  shouldRenderDailyToplineUplift: boolean;
  shouldRenderMonthlyToplineUplift: boolean;
  shouldRenderMonthlyCallListTarget: boolean;
  isTargetVisible: (targetType: TARGET_KPI_TYPES) => boolean;
}

function useTargetKpis(): UseTargetKpis {
  const { user } = useStore(GlobalStore);
  const { updateKPI } = useStore(UpdateKPIStore);
  const { monthly } = useStore(MonthlyKPIStore);
  const { targetKpi } = getUserInfos();

  const isHitRateEnabled = isFeatureEnabled(
    TLP_MONTHLY_KPI_HIT_RATE,
    TLP_MONTHLY_KPI_HIT_RATE_ADMIN,
    user.keyToggle,
  );

  const isMonthlyCallListEnabled = isFeatureEnabled(
    TLP_MONTHLY_KPI_CALL_LIST,
    TLP_MONTHLY_KPI_CALL_LIST_ADMIN,
    user.keyToggle,
  );

  const isMonthlyToplineUpliftEnabled = isFeatureEnabledV2(
    TLP_MONTHLY_KPI_TOPLINE_UPLIFT,
    user.keyToggle,
  );

  const isDailyToplineUpliftEnabled = isFeatureEnabledV2(
    TLP_KPI_TOPLINE_UPLIFT,
    user.keyToggle,
  );

  const isMonthlyAhtEnabled = isFeatureEnabled(
    TLP_MONTHLY_KPI_AVERAGE_HANDLE_TIME,
    TLP_MONTHLY_KPI_AVERAGE_HANDLE_TIME_ADMIN,
    user.keyToggle,
  );

  const isDailyAhtEnabled = isFeatureEnabled(
    TLP_KPI_AVERAGE_HANDLE_TIME,
    TLP_KPI_AVERAGE_HANDLE_TIME_ADMIN,
    user.keyToggle,
  );

  const isOrganizationConfigEnabled = isFeatureEnabledV2(
    GROW_ORGANIZATION_ACTIVATION,
    user.keyToggle,
  );

  const ctiEnabled = isFeatureEnabled(TLP_CTI, TLP_CTI_ADMIN, user.zone);

  const addTrailingZeros = (target: string | null) => {
    if (!target) return null;
    return target.includes(":") ? target : `${target}:00`;
  };

  const addPercentage = (target: string) => {
    if (target) {
      return `${target}%`;
    }
    return target;
  };

  const isTargetVisible = (targetType: TARGET_KPI_TYPES) => {
    if (isOrganizationConfigEnabled && targetKpi) {
      const target = targetKpi.find(
        (target) => target.type === targetType,
      )?.invisible;

      return typeof target === "boolean" ? !target : false;
    }
    return true;
  };

  const isMonthlyKpiEnabled = useMemo(() => {
    return (
      isHitRateEnabled ||
      isMonthlyCallListEnabled ||
      isMonthlyToplineUpliftEnabled ||
      isMonthlyAhtEnabled
    );
  }, [
    isHitRateEnabled,
    isMonthlyCallListEnabled,
    isMonthlyToplineUpliftEnabled,
    isMonthlyAhtEnabled,
  ]);

  const dailyCallListTarget = useMemo(() => {
    if (isOrganizationConfigEnabled && targetKpi) {
      const dailyTarget = targetKpi.find(
        (target) => target.type === TARGET_KPI_TYPES.CALLS,
      )?.daily;

      if (dailyTarget?.length === 0) return null;

      return Number(dailyTarget) || null;
    }
    return updateKPI.targets?.targetCallList;
  }, [
    isOrganizationConfigEnabled,
    targetKpi,
    updateKPI.targets?.targetCallList,
  ]);

  const dailyAverageHandleTimeTarget = useMemo(
    () =>
      isOrganizationConfigEnabled && targetKpi
        ? addTrailingZeros(
            targetKpi.find((target) => target.type === TARGET_KPI_TYPES.AHT)
              ?.daily ?? "",
          )
        : updateKPI.targets?.targetAverageHandleTime,
    [
      isOrganizationConfigEnabled,
      targetKpi,
      updateKPI.targets?.targetAverageHandleTime,
    ],
  );

  const dailyToplineUpliftTarget = useMemo(
    () =>
      isOrganizationConfigEnabled && targetKpi
        ? Number(
            targetKpi.find((target) => target.type === TARGET_KPI_TYPES.UPLIFT)
              ?.daily,
          ) || undefined
        : updateKPI.targets?.targetToplineUplift,
    [
      isOrganizationConfigEnabled,
      targetKpi,
      updateKPI.targets?.targetToplineUplift,
    ],
  );

  const monthlyCallListTarget = useMemo(() => {
    if (isOrganizationConfigEnabled && targetKpi) {
      return Number(
        targetKpi.find((target) => target.type === TARGET_KPI_TYPES.CALLS)
          ?.monthly ?? 0,
      );
    }
    return monthly.targets
      ? monthly.targets?.targetCallList * monthly.targets?.monthWorkDays
      : undefined;
  }, [isOrganizationConfigEnabled, monthly.targets, targetKpi]);

  const monthlyToplineUpliftTarget = useMemo(() => {
    if (isOrganizationConfigEnabled && targetKpi) {
      return addPercentage(
        targetKpi.find((target) => target.type === TARGET_KPI_TYPES.UPLIFT)
          ?.monthly ?? "",
      );
    }
    return monthly.targets
      ? `${monthly.targets.targetToplineUplift}%`
      : undefined;
  }, [isOrganizationConfigEnabled, monthly.targets, targetKpi]);

  const monthlyAverageHandleTimeTarget = useMemo(() => {
    if (isOrganizationConfigEnabled && targetKpi) {
      return addTrailingZeros(
        targetKpi.find((target) => target.type === TARGET_KPI_TYPES.AHT)
          ?.monthly ?? "",
      );
    }
    return monthly.targets?.targetAverageHandleTime;
  }, [
    isOrganizationConfigEnabled,
    monthly.targets?.targetAverageHandleTime,
    targetKpi,
  ]);

  const shouldRenderDailyAht =
    (isTargetVisible(TARGET_KPI_TYPES.AHT) && isOrganizationConfigEnabled) ||
    (isDailyAhtEnabled && !isOrganizationConfigEnabled);

  const shouldRenderMonthlyAht =
    (isTargetVisible(TARGET_KPI_TYPES.AHT) && isOrganizationConfigEnabled) ||
    (isMonthlyAhtEnabled && ctiEnabled && !isOrganizationConfigEnabled);

  const shouldRenderDailyToplineUplift =
    (isTargetVisible(TARGET_KPI_TYPES.UPLIFT) && isOrganizationConfigEnabled) ||
    (isDailyToplineUpliftEnabled && !isOrganizationConfigEnabled);

  const shouldRenderMonthlyToplineUplift =
    (isTargetVisible(TARGET_KPI_TYPES.UPLIFT) && isOrganizationConfigEnabled) ||
    (isMonthlyToplineUpliftEnabled && !isOrganizationConfigEnabled);

  const shouldRenderMonthlyCallListTarget =
    (isTargetVisible(TARGET_KPI_TYPES.CALLS) && isOrganizationConfigEnabled) ||
    (isMonthlyCallListEnabled && !isOrganizationConfigEnabled);

  return {
    isMonthlyKpiEnabled,
    dailyCallListTarget,
    dailyAverageHandleTimeTarget,
    dailyToplineUpliftTarget,
    monthlyCallListTarget,
    monthlyToplineUpliftTarget,
    monthlyAverageHandleTimeTarget,
    isTargetVisible,
    shouldRenderDailyAht,
    shouldRenderMonthlyAht,
    shouldRenderDailyToplineUplift,
    shouldRenderMonthlyToplineUplift,
    shouldRenderMonthlyCallListTarget,
  };
}

export default useTargetKpis;
